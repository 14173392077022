import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import Header from "../global/Header";
import { welcomebar } from "../styles/styles";
import { AuthContext } from "../contexts/AuthContext";

const logo = "../../images/logos/nascp.png";

const Home = () => {
  const { loggedIn, isLoggedIn } = useContext(AuthContext);

  const menuItems = [
    { path: "/", label: "Home" },
    { path: "/national", label: "Data" },
    ...(isLoggedIn.accesslevel >= 2
      ? [{ path: "/backend", label: "Backend" }]
      : []),
  ];

  return (
    <>
      <Header logo={logo} menuItems={menuItems} />
      <Box sx={welcomebar}>
        {loggedIn && (
          <Box>
            Welcome, {isLoggedIn.firstname} {isLoggedIn.lastname}
          </Box>
        )}
        {loggedIn && (
          <Box sx={{ fontSize: "small" }}>
            Access Level: {isLoggedIn.accesslevel}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Home;
