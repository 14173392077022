import React from 'react'
import { Box } from '@mui/material'

const Logo = ({logo}) => {
  return (
    <Box>
        <img
        alt="logo"
        height={75}
        src={logo}
        />
    </Box>
  )
}

export default Logo