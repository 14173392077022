import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import * as d3 from "d3";

const LineChart = ({ sx, data }) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);
  const tooltipRef = useRef(null);
  const [legendHovered, setLegendHovered] = useState(null);

  const handleResize = () => {
    if (data && svgRef.current) {
      const parentElement = parentRef.current;
      const containerWidth = parentElement.clientWidth;
      const containerHeight = parentElement.clientHeight;

      // Set chart dimensions
      const width = containerWidth;
      const height = containerHeight;

      // Create or update SVG
      const svg = d3
        .select(svgRef.current)
        .attr("width", width)
        .attr("height", height);

      svg.selectAll("*").remove();

      // Parse the date format
      const parseDate = d3.timeParse("%Y-%m-%d");
      const formatDate = d3.timeFormat("%B, %Y"); // Format to "November, 2023"

      // X and Y Scales
      const xScale = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => parseDate(d.date)))
        .range([0, width]);

      const yScale = d3
        .scaleLinear()
        .domain([d3.min(data, (d) => +d.value), d3.max(data, (d) => +d.value)])
        .nice()
        .range([height, 0]);

      // Line generator with curve
      const line = d3
        .line()
        .x((d) => xScale(parseDate(d.date)))
        .y((d) => yScale(+d.value))
        .curve(d3.curveCardinal); // Add curve interpolation

      // Draw lines
      const datapoints = Array.from(new Set(data.map((d) => d.datapoint)));

      datapoints.forEach((datapoint) => {
        const dataFiltered = data.filter((d) => d.datapoint === datapoint);
        svg
          .append("path")
          .datum(dataFiltered)
          .attr("fill", "none")
          .attr("stroke", "#006400")
          .attr("stroke-width", 2.5)
          .attr("d", line)
          .attr("opacity", () =>
            legendHovered === null || legendHovered === datapoint ? 1 : 0.3
          );
      });

      // Add a vertical line for hover interaction
      const verticalLine = svg
        .append("line")
        .attr("stroke", "#006400")
        .attr("stroke-width", 0.75)
        .attr("y1", 0)
        .attr("y2", height)
        .style("display", "none");

      // Mouse move event listener
      svg
        .append("rect")
        .attr("width", width)
        .attr("height", height)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("mousemove", function (event) {
          const [mouseX] = d3.pointer(event);
          const xDate = xScale.invert(mouseX);

          // Update vertical line position for smooth motion
          verticalLine
            .attr("x1", mouseX)
            .attr("x2", mouseX)
            .style("display", "block");

          // Find the closest data point
          let closestDataPoint;
          let closestDistance = Infinity;

          data.forEach((d) => {
            const currentDistance = Math.abs(parseDate(d.date) - xDate);
            if (currentDistance < closestDistance) {
              closestDistance = currentDistance;
              closestDataPoint = d;
            }
          });

          if (closestDataPoint) {
            const tooltip = d3.select(tooltipRef.current);
            const tooltipBox = tooltip.node().getBoundingClientRect();
            const tooltipHeight = tooltipBox.height;
            const tooltipWidth = tooltipBox.width;

            // Calculate the center Y position, adjust for tooltip height
            const centerY = (height - tooltipHeight) / 2;

            // Determine the horizontal position for the tooltip
            let tooltipX = mouseX + 35; // Default to right side of the vertical line
            if (mouseX + tooltipWidth + 10 > width) {
              tooltipX = mouseX - tooltipWidth; // If not enough space, position to the left
            }

            // Update tooltip with the closest data point
            tooltip
              .style("left", `${tooltipX}px`)
              .style("top", `${centerY}px`)
              .style("display", "block")
              .html(
                `<strong>Date:</strong> ${formatDate(
                  parseDate(closestDataPoint.date)
                )}<br />
                 <strong>Value:</strong> ${closestDataPoint.value}`
              );
          }
        })
        .on("mouseout", () => {
          verticalLine.style("display", "none");
          d3.select(tooltipRef.current).style("display", "none");
        });
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data, legendHovered]);

  return (
    <Box sx={sx}>
      <svg
        ref={parentRef}
        style={{
          width: "90%",
          height: "80px",
          margin: "auto",
          display: "grid",
          placeItems: "center",
        }}
      >
        <g ref={svgRef} style={{ cursor: "pointer" }} />
      </svg>
      <Box
        ref={tooltipRef}
        style={{
          position: "absolute",
          pointerEvents: "none",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: "5px",
          borderRadius: "0.25em",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
          display: "none",
          color: "black",
          fontSize: "12px",
          fontFamily: "Arial",
        }}
      />
    </Box>
  );
};

export default LineChart;
