// import { transform } from "topojson-client";

import { transform } from "topojson-client";

const header = {
  // top: 0,
  position: "relative",
  height: "100px",
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  paddingLeft: "2.5rem",
  paddingRight: "2.5rem",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const input = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "#00c800",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#006400",
    },
  },
  "& .MuiInputBase-input": {
    color: "white",
    backgroundColor: "transparent",
    fontSize: "0.85em",
    "&:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 1000px transparent inset",
      "-webkit-text-fill-color": "white",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "&:-webkit-autofill:focus": {
      "-webkit-box-shadow": "0 0 0 1000px transparent inset",
      "-webkit-text-fill-color": "white",
    },
    "&:-webkit-autofill:hover": {
      "-webkit-box-shadow": "0 0 0 1000px transparent inset",
      "-webkit-text-fill-color": "white",
    },
  },
  "& .MuiFormLabel-root": {
    color: "white",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "white",
  },
};

const loadingData = {
  position: "absolute",
  width: "30px",
  height: "15px",
  top: "50px",
  right: "6.5%",
  transform: "translate(-50%, -50%)",
};

const slicer = {
  height: "100px",
  display: "flex",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  zIndex: 1200,
  margin: "5px",
  marginTop: "10px",
  borderRadius: "0.5rem",
  padding: "0.5em 1em",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const popperBackground = {
  width: "100%",
  maxHeight: "200px",
  overflowY: "auto",
  border: "1px solid #006400",
  borderRadius: "0.5rem",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.75),
    rgba(248, 248, 255, 0.25)
    )`,
  backdropFilter: "blur(1rem)",
};

const mapSection = {
  display: "grid",
  gridTemplateRows: "4fr 1fr",
  height: "500px",
};

const mapChart = {
  display: "grid",
  placeItems: "center",
  fontSize: "small",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  zIndex: 1200,
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "0.5em 1em",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const chartHeader = {
  position: "absolute",
  transform: "translate(-50%, 0)",
  top: "0",
  left: "50%",
  marginTop: "10px",
  fontSize: "1em",
};

const map = {
  height: "400px",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "1em",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const temp = {
  height: "auto",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "1em",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const pieChart = {
  height: "auto",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "5px",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const guage = {
  height: "auto",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "5px",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const chart = {
  height: "auto",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "5px",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const welcomebar = {
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "centre",
  height: "auto",
  color: "#006400",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "1em",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const info = {
  display: "grid",
  gridTemplateRows: "1fr 1fr 3fr",
  height: "100px",
};

const cardbar11111 = {
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  height: "100px",
};

const cardbar1112 = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr) 2fr",
  height: "100px",
};

const cardbar1121 = {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr) 2fr 1fr",
  height: "100px",
};

const card = {
  position: "relative",
  textAlign: "center",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  backdropFilter: "blur(1rem)",
  margin: "5px",
  borderRadius: "0.5rem",
  padding: "1em",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
};

const value = {
  position: "absolute",
  fontSize: "2.5em",
  fontWeight: "300",
  left: "50%",
  top: "35%",
  transform: "translate(-50%, -50%)",
  color: "#006400",
};

const title = {
  margin: "5px",
  padding: "0.5em",
  fontSize: "1.25em",
  textAlign: "center",
  borderRadius: "0.5rem",
  backdropFilter: "blur(1rem)",
  background: `linear-gradient(
    to right bottom,
    rgba(248, 248, 255, 0.5),
    rgba(248, 248, 255, 0.05)
    )`,
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
  color: "#006400",
};

const subtitle = {
  position: "absolute",
  fontSize: "0.75em",
  fontWeight: "600",
  left: "50%",
  top: "75%",
  transform: "translate(-50%, -50%)",
};

const chartbar = {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
};

const footer = {
  display: "flex",
  width: "auto",
  height: "150px",
  background: "#173a63",
  justifyContent: "space-between",
  alignItems: "center",
  color: "white",
};

const menuBox = {
  flex: 1,
  color: "grey",
  textAlign: "center",
  textDecoration: "none",
  "&:hover": {
    color: "#006400",
  },
};

const activeMenuBox = {
  color: "#006400",
  fontWeight: "600",
};

const buttonBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

// Colour Legend
const colourLegendLeft = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "5px",
  position: "absolute",
  top: "50%",
  right: "0",
  transform: "translateY(-50%)",
  marginLeft: "20px",
};

const colourLegendRight = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
  padding: "5px",
  position: "absolute",
  top: "50%",
  right: "0",
  transform: "translateY(-50%)",
  marginRight: "20px",
};

// Legend Item
const legendItemLeft = {
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
};

const legendItemRight = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: "5px",
};

const legendLabel = {
  fontSize: "0.75em",
};

export {
  header,
  loadingData,
  input,
  slicer,
  popperBackground,
  mapSection,
  mapChart,
  chartHeader,
  map,
  temp,
  pieChart,
  guage,
  chart,
  welcomebar,
  info,
  cardbar11111,
  cardbar1112,
  cardbar1121,
  card,
  value,
  title,
  subtitle,
  chartbar,
  footer,
  menuBox,
  activeMenuBox,
  buttonBox,
  colourLegendLeft,
  colourLegendRight,
  legendItemLeft,
  legendItemRight,
  legendLabel,
};
