import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import * as d3 from "d3";
import ColourLegend from "../components/ColourLegend";

const PieChart = ({
  data,
  colourScale,
  keys,
  colourLegend,
  legendItem,
  offset,
  sx,
}) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleResize = () => {
    if (data && svgRef.current) {
      const parentElement = parentRef.current;
      const containerWidth = parentElement.clientWidth;
      const containerHeight = parentElement.clientHeight;

      // Define the chart dimensions based on the container size
      const chartWidth = containerWidth / 2;
      const chartHeight = containerHeight;
      const radius = Math.min(chartWidth, chartHeight) / 2;

      // Clear the SVG
      const svg = d3
        .select(svgRef.current)
        .attr("width", chartWidth)
        .attr("height", chartHeight);

      svg.selectAll("*").remove();

      // Set up the pie layout
      const pie = d3.pie().value((d) => d.value);
      const arc = d3.arc().innerRadius(1).outerRadius(radius).padAngle(0.02);

      const totalValue = d3.sum(data, (d) => d.value);

      const chart = svg
        .append("g")
        .attr("class", "pie")
        .attr("transform", `translate(${chartWidth / 2}, ${chartHeight / 2})`);

      const slices = chart
        .selectAll("path")
        .data(pie(data))
        .enter()
        .append("path")
        .attr("class", "slice")
        .attr("d", arc)
        .attr("fill", (d, i) => colourScale(keys[i]))
        .on("mouseover", (event, d) => {
          const tooltip = d3.select(tooltipRef.current);

          // X and Y Positions
          const [x, y] = d3.pointer(event);
          const offset = 50;
          const labelWidth = d.data.label.length * 8;
          const valueWidth = (d.data.value * 100).toFixed(2).length * 8 + 5;
          const maxWidth = Math.max(labelWidth, valueWidth);
          const widthPadding = 20;
          const rectWidth = maxWidth + widthPadding;
          const rectHeight = 30;

          tooltip
            .style("left", `${x + offset}px`)
            .style("top", `${y}px`)
            .style("display", "block")
            .html(
              `<strong>${d.data.label}</strong><br/>Value: ${(
                (d.data.value / totalValue) *
                100
              ).toFixed(2)}%`
            )
            .style("width", `${rectWidth}px`)
            .style("height", `${rectHeight}px`);
        })
        .on("mousemove", (event) => {
          const tooltip = d3.select(tooltipRef.current);
          const [x, y] = d3.pointer(event);
          tooltip
            .style("left", `${x + 50}px`) // Move right of the cursor
            .style("top", `${y}px`); // Move top of the cursor
        })
        .on("mouseout", () => {
          d3.select(tooltipRef.current).style("display", "none");
        });

      // Animate the pie when the data changes
      slices
        .transition()
        .duration(500)
        .attrTween("d", function (d) {
          const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
          return function (t) {
            return arc(interpolate(t));
          };
        });
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data, offset, colourScale, keys]);

  return (
    <Box sx={sx}>
      <svg
        ref={parentRef}
        style={{
          height: "80px",
          width: "90%",
          margin: "auto",
          display: "grid",
          placeItems: "center",
        }}
      >
        <g
          ref={svgRef}
          style={{
            cursor: "pointer",
          }}
        />
      </svg>
      <ColourLegend
        keys={keys}
        colourScale={colourScale}
        colourLegend={colourLegend}
        legendItem={legendItem}
      />
      <Box
        ref={tooltipRef}
        style={{
          position: "absolute",
          pointerEvents: "none",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: "5px",
          borderRadius: "0.25em",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
          display: "none",
          color: "black",
          fontSize: "12px",
          fontFamily: "Arial",
        }}
      />
    </Box>
  );
};

export default PieChart;
