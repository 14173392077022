import { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import { fetchInitDataDate } from "../functions.js";
import { prodApi } from "./api.js";
import { useLoading } from "../contexts/LoadingContext.jsx";

// URLs
const url = prodApi.fundersData;

// Full Data
const FundersData = () => {
  const { dispatch } = useContext(DataContext);
  const { startLoading, stopLoading } = useLoading();

  const [fundersData, setFundersData] = useState([]);

  // FETCH AND SET DATA
  useEffect(() => {
    startLoading();
    fetchInitDataDate(url, setFundersData, "date")
      .then(() => {
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, []);

  useEffect(() => {
    dispatch({
      type: "FUNDERS_DATA",
      payload: fundersData,
    });
  }, [fundersData]);

  return null;
};

export default FundersData;
