import { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import { fetchInitDataDate } from "../functions.js";
import { prodApi } from "./api.js";
import { useLoading } from "../contexts/LoadingContext.jsx";

// URLs
const url = prodApi.visitationData;

// Full Data
const VisitationData = () => {
  const { dispatch } = useContext(DataContext);
  const { startLoading, stopLoading } = useLoading();

  const [visitationData, setVisitationData] = useState([]);

  // FETCH AND SET DATA
  useEffect(() => {
    startLoading();
    fetchInitDataDate(url, setVisitationData, "date")
      .then(() => {
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, []);

  useEffect(() => {
    dispatch({
      type: "VISITATION_DATA",
      payload: visitationData,
    });
  }, [visitationData]);

  return null;
};

export default VisitationData;
