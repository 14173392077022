import { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import { fetchInitDataDate } from "../functions.js";
import { prodApi } from "./api.js";
import { useLoading } from "../contexts/LoadingContext.jsx";

// URLs
const url = prodApi.mentorshipData;

// Full Data
const MentorshipData = () => {
  const { dispatch } = useContext(DataContext);
  const { startLoading, stopLoading } = useLoading();

  const [mentorshipData, setMentorshipData] = useState([]);

  // FETCH AND SET DATA
  useEffect(() => {
    startLoading();
    fetchInitDataDate(url, setMentorshipData, "date")
      .then(() => {
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, []);

  useEffect(() => {
    dispatch({
      type: "MENTORSHIP_DATA",
      payload: mentorshipData,
    });
  }, [mentorshipData]);

  return null;
};

export default MentorshipData;
