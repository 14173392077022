import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery, Box } from '@mui/material';
import { menuBox, activeMenuBox, buttonBox } from '../styles/styles';

const MenuBar = ({menuItems}) => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:770px)')

  return (
      <Box
        sx={{
          display: 'flex',
          alignItems:'center',
          padding: '10px 0'
        }}
      >
         {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          <Box
            component={Link}
            to={item.path}
            className="menu-link"
            sx={{
              ...menuBox,
              ...(location.pathname === item.path && activeMenuBox),
              textDecoration: 'none',
              margin: isTab ? '0 10px' : '0 20px',
            }}
          >
            <Box sx={{ ...buttonBox, whiteSpace: 'nowrap', fontSize: isTab ? '10px' : '12px' }}>
              {item.label}
            </Box>
          </Box>
          {index < menuItems.length - 1 && (
            <Box
              sx={{
                margin: isTab ? '0 5px' : '0 10px',
                color: "#000",
                fontSize: isTab ? '12px' : '1rem',
                fontWeight: '200',
              }}
            >
              |
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MenuBar;
