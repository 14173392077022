import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { DataContext } from "../contexts/DataContext.jsx";
import MultiselectDropdown from "./MultiselectDropdown.jsx";
import SingleSelectDropdown from "./SingleSelectDropdown.jsx";
import {
  getUniqueValues,
  sortMonths,
  sortYears,
  sortAlphabetically,
  generateCalendar,
} from "../functions.js";
import TestsData from "../data/TestsData.jsx";
import FundersData from "../data/FundersData.jsx";
import FacilitiesData from "../data/FacilitiesData.jsx";
import MentorshipData from "../data/MentorshipData.jsx";
import MentorsData from "../data/MentorsData.jsx";
import HivCasesData from "../data/HivCasesData.jsx";
import VisitationData from "../data/VisitationData.jsx";
// import HivRetentionData from "../data/HivRetention.jsx";
import { slicer } from "../styles/styles.js";

const startDate = "2023-07-01";
const calendarTable = generateCalendar(startDate);

const SlicerBar = () => {
  const { dispatch, state } = useContext(DataContext);
  const {
    testsData,
    fundersData,
    facilitiesData,
    mentorshipData,
    mentorsData,
    hivCasesData,
    hivRetentionData,
    hivViralLoadData,
    visitationData,
  } = state;

  // Selection State
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedFunders, setSelectedFunders] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);
  const [selectedMentors, setSelectedMentors] = useState([]);
  const [selectedMentorship, setSelectedMentorship] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState(["HIV"]);
  const [selectedTests, setSelectedTests] = useState([]);

  // Display State
  const [displayYears, setDisplayYears] = useState("");
  const [displayMonths, setDisplayMonths] = useState("");
  const [displayStates, setDisplayStates] = useState("");
  const [displayFunders, setDisplayFunders] = useState("");
  const [displayFacilities, setDisplayFacilities] = useState("");
  const [displayMode, setDisplayMode] = useState("");
  const [displayMentors, setDisplayMentors] = useState("");
  const [displayMentorship, setDisplayMentorship] = useState("");
  const [displayPrograms, setDisplayPrograms] = useState("");
  const [displayTests, setDisplayTests] = useState("");

  // Slicers
  const [yearSelectFilter, setYearSelectFilter] = useState([]);
  const [monthSelectFilter, setMonthSelectFilter] = useState([]);
  const [stateSelectFilter, setStateSelectFilter] = useState([]);
  const [funderSelectFilter, setFunderSelectFilter] = useState([]);
  const [facilitySelectFilter, setFacilitySelectFilter] = useState([]);
  const [modeSelectFilter, setModeSelectFilter] = useState([]);
  const [mentorshipSelectFilter, setMentorshipSelectFilter] = useState([]);
  const [mentorsSelectFilter, setMentorsSelectFilter] = useState([]);
  const [programSelectFilter, setProgramSelectFilter] = useState([]);
  const [testSelectFilter, setTestSelectFilter] = useState([]);

  // FILTERED DATA
  // TESTS
  useEffect(() => {
    const newTestsData = testsData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const programMatch =
        selectedPrograms.length === 0 || selectedPrograms.includes(row.program);
      const testMatch =
        selectedTests.length === 0 || selectedTests.includes(row.test);

      return yearMatch && monthMatch && stateMatch && programMatch && testMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_TESTS_DATA",
      payload: newTestsData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedPrograms,
    selectedTests,
    testsData,
    dispatch,
  ]);

  // FUNDERS
  useEffect(() => {
    const newFundersData = fundersData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const funderMatch =
        selectedFunders.length === 0 || selectedFunders.includes(row.funder);

      return yearMatch && monthMatch && stateMatch && funderMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_FUNDERS_DATA",
      payload: newFundersData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFunders,
    fundersData,
    dispatch,
  ]);

  // FACILITIES
  useEffect(() => {
    const newFacilitiesData = facilitiesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const facilitiesMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);

      return yearMatch && monthMatch && stateMatch && facilitiesMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_FACILITIES_DATA",
      payload: newFacilitiesData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFacilities,
    facilitiesData,
    dispatch,
  ]);

  // MENTORSHIP
  useEffect(() => {
    const newMentorshipData = mentorshipData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const facilitiesMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);

      return yearMatch && monthMatch && stateMatch && facilitiesMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_MENTORSHIP_DATA",
      payload: newMentorshipData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFacilities,
    selectedMentorship,
    mentorshipData,
    dispatch,
  ]);

  // MENTORS
  useEffect(() => {
    const newMentorsData = mentorsData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const facilitiesMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);
      const fundersMatch =
        selectedFunders.length === 0 || selectedFunders.includes(row.funder);

      return (
        yearMatch && monthMatch && stateMatch && fundersMatch && facilitiesMatch
      );
    });

    dispatch({
      type: "UPDATE_FILTERED_MENTORS_DATA",
      payload: newMentorsData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFunders,
    selectedFacilities,
    selectedMentors,
    mentorsData,
    dispatch,
  ]);

  // HIV CASES
  useEffect(() => {
    const newHivCasesData = hivCasesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const facilitiesMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);
      // const fundersMatch =
      //   selectedFunders.length === 0 || selectedFunders.includes(row.funder);

      return yearMatch && monthMatch && stateMatch && facilitiesMatch;
    });

    dispatch({
      type: "UPDATE_FILTERED_HIV_CASES_DATA",
      payload: newHivCasesData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFunders,
    selectedFacilities,
    hivCasesData,
    dispatch,
  ]);

  // HIV RETENTION
  useEffect(() => {
    const newHivRetentionData = hivRetentionData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const facilitiesMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);
      const fundersMatch =
        selectedFunders.length === 0 || selectedFunders.includes(row.funder);

      return (
        yearMatch && monthMatch && stateMatch && fundersMatch && facilitiesMatch
      );
    });

    dispatch({
      type: "UPDATE_FILTERED_HIV_RETENTION_DATA",
      payload: newHivRetentionData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFunders,
    selectedFacilities,
    hivRetentionData,
    dispatch,
  ]);

  // HIV VIRAL LOAD
  useEffect(() => {
    const newHivViralLoadData = hivViralLoadData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const facilitiesMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);
      const fundersMatch =
        selectedFunders.length === 0 || selectedFunders.includes(row.funder);

      return (
        yearMatch && monthMatch && stateMatch && fundersMatch && facilitiesMatch
      );
    });

    dispatch({
      type: "UPDATE_FILTERED_HIV_VIRAL_LOAD_DATA",
      payload: newHivViralLoadData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFunders,
    selectedFacilities,
    hivViralLoadData,
    dispatch,
  ]);

  // VISITATION
  useEffect(() => {
    const newVisitationData = visitationData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const facilitiesMatch =
        selectedFacilities.length === 0 ||
        selectedFacilities.includes(row.facility);
      const fundersMatch =
        selectedFunders.length === 0 || selectedFunders.includes(row.funder);

      return (
        yearMatch && monthMatch && stateMatch && fundersMatch && facilitiesMatch
      );
    });

    dispatch({
      type: "UPDATE_FILTERED_VISITATION_DATA",
      payload: newVisitationData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedFunders,
    selectedFacilities,
    visitationData,
    dispatch,
  ]);

  // DROPDOWN FILTERS
  // Year (Multi)
  useEffect(() => {
    const yearFilteredData = calendarTable.filter((row) => {
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);

      return monthMatch;
    });

    setYearSelectFilter(yearFilteredData);
  }, [selectedMonths, dispatch]);

  // Month (Multi)
  useEffect(() => {
    const monthFilteredData = calendarTable.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);

      return yearMatch;
    });

    setMonthSelectFilter(monthFilteredData);
  }, [selectedYears, dispatch]);

  // State (Multi)
  useEffect(() => {
    const stateFilteredData = testsData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const programMatch =
        selectedPrograms.length === 0 || selectedPrograms.includes(row.program);
      const testMatch =
        selectedTests.length === 0 || selectedTests.includes(row.test);

      return yearMatch && monthMatch && programMatch && testMatch;
    });

    setStateSelectFilter(stateFilteredData);
  }, [
    selectedYears,
    selectedMonths,
    selectedPrograms,
    selectedTests,
    testsData,
    dispatch,
  ]);

  // Program (Single)
  useEffect(() => {
    const programFilteredData = testsData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const testMatch =
        selectedTests.length === 0 || selectedTests.includes(row.test);

      return yearMatch && monthMatch && stateMatch && testMatch;
    });

    setProgramSelectFilter(programFilteredData);
    // dispatch({
    //   type: "UPDATE_FILTERED_TESTS_PROGRAM_DATA",
    //   payload: programFilteredData,
    // });
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedTests,
    testsData,
    dispatch,
  ]);

  // Test (Multi)
  useEffect(() => {
    const testFilteredData = testsData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);
      const programMatch =
        selectedPrograms.length === 0 || selectedPrograms.includes(row.program);

      return yearMatch && monthMatch && stateMatch && programMatch;
    });

    setTestSelectFilter(testFilteredData);
  }, [
    selectedYears,
    selectedMonths,
    selectedStates,
    selectedPrograms,
    testsData,
    dispatch,
  ]);

  // Funders (Multi)
  useEffect(() => {
    const funderFilteredData = fundersData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 || selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 || selectedMonths.includes(row.month);
      const stateMatch =
        selectedStates.length === 0 || selectedStates.includes(row.state);

      return yearMatch && monthMatch && stateMatch;
    });

    setFunderSelectFilter(funderFilteredData);
  }, [selectedYears, selectedMonths, selectedStates, fundersData, dispatch]);

  // Select Functionality
  const handleYearSelect = (selectedList) => {
    setSelectedYears(selectedList);
    setDisplayYears(`${selectedList.length} options selected`);
  };

  const handleMonthSelect = (selectedList) => {
    setSelectedMonths(selectedList);
    setDisplayMonths(`${selectedList.length} options selected`);
  };

  const handleStateSelect = (selectedList) => {
    setSelectedStates(selectedList);
    setDisplayStates(`${selectedList.length} options selected`);
  };

  const handleFunderSelect = (selectedList) => {
    setSelectedFunders(selectedList);
    setDisplayFunders(`${selectedList.length} options selected`);
  };

  const handleFacilitySelect = (selectedList) => {
    setSelectedFacilities(selectedList);
    setDisplayFacilities(`${selectedList.length} options selected`);
  };

  const handleProgramSelect = (selectedItem) => {
    setSelectedPrograms([selectedItem]);
    setDisplayPrograms(`${selectedItem} options selected`);
  };

  const handleTestSelect = (selectedList) => {
    setSelectedTests(selectedList);
    setDisplayTests(`${selectedList.length} options selected`);
  };

  const SelectedCountDisplay = ({ selectedOptions }) => {
    const selectedCount = selectedOptions.length;
    return <div>{`${selectedCount} options selected`}</div>;
  };

  return (
    <>
      <TestsData />
      <FundersData />
      <FacilitiesData />
      <MentorshipData />
      <MentorsData />
      <HivCasesData />
      {/* <HivRetentionData /> */}
      <VisitationData />
      <Box sx={slicer}>
        <MultiselectDropdown
          label="Year"
          id="year"
          placeholder="Year"
          options={sortYears(getUniqueValues(yearSelectFilter, "year"))}
          selectedValues={selectedYears}
          onChange={handleYearSelect}
        />
        <MultiselectDropdown
          label="Month"
          id="month"
          placeholder="Month"
          options={sortMonths(getUniqueValues(monthSelectFilter, "month"))}
          selectedValues={selectedMonths}
          onChange={handleMonthSelect}
        />
        <MultiselectDropdown
          label="State"
          id="state"
          placeholder="State"
          options={sortAlphabetically(
            getUniqueValues(stateSelectFilter, "state")
          )}
          selectedValues={selectedStates}
          onChange={handleStateSelect}
        />
        <MultiselectDropdown
          label="Funder"
          id="funder"
          placeholder="Funder"
          options={sortAlphabetically(
            getUniqueValues(funderSelectFilter, "funder")
          )}
          selectedValues={selectedFunders}
          onChange={handleFunderSelect}
        />
        <SingleSelectDropdown
          label="Program"
          id="program"
          placeholder="Program"
          options={sortAlphabetically(
            getUniqueValues(programSelectFilter, "program")
          )}
          selectedValue={selectedPrograms}
          onChange={handleProgramSelect}
        />
      </Box>
    </>
  );
};

export default SlicerBar;
