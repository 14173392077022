import React from "react";
import { Box } from "@mui/material";

const ColourLegend = ({ keys, colourScale, colourLegend, legendItem }) => {
  return (
    <Box sx={colourLegend}>
      {keys.map((key) => (
        <Box key={key} sx={legendItem}>
          <span
            className="color-box"
            style={{ background: colourScale(key) }}
          ></span>
          <span className="legend-label">{key}</span>
        </Box>
      ))}
    </Box>
  );
};

export default ColourLegend;
