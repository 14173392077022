import { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import { fetchInitDataDate } from "../functions.js";
import { prodApi } from "./api.js";
import { useLoading } from "../contexts/LoadingContext.jsx";

// URLs
const url = prodApi.testsData;

// Full Data
const TestsData = () => {
  const { dispatch } = useContext(DataContext);
  const { startLoading, stopLoading } = useLoading();

  const [testsData, setTestsData] = useState([]);

  // FETCH AND SET DATA
  useEffect(() => {
    startLoading();
    fetchInitDataDate(url, setTestsData, "date")
      .then(() => {
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, []);

  useEffect(() => {
    dispatch({
      type: "TESTS_DATA",
      payload: testsData,
    });
  }, [testsData]);

  return null;
};

export default TestsData;
