import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const initialLoggedIn = !!localStorage.getItem("token");
  const initialUser = JSON.parse(localStorage.getItem("user")) || {
    firstname: "",
    lastname: "",
    username: "",
    acesslevel: "",
    initials: "",
  };
  const [loggedIn, setLoggedIn] = useState(initialLoggedIn);
  const [isLoggedIn, setIsLoggedIn] = useState(initialUser);
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Set the logged-in state to true if a token is found in local storage
      setLoggedIn(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        initialUser,
        loggedIn,
        isLoggedIn,
        isAuthorized,
        setLoggedIn,
        setIsLoggedIn,
        setIsAuthorized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
