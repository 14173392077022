import React, { useContext } from "react";
import { Box } from "@mui/material";
import Logo from "../components/Logo";
import MenuBar from "../components/MenuBar";
import LogoutButton from "../components/LogoutButton";
import { AuthContext } from "../contexts/AuthContext";
import LoadingIcon from "../components/LoadingIcon";
import LoginModal from "../modals/LoginModal";
import { useLoading } from "../contexts/LoadingContext";
import { header } from "../styles/styles";

const title = "NATIONAL CLINICAL MENTORSHIP PROGRAM PERFORMANCE DASHBOARD";
const Header = ({ logo, menuItems }) => {
  const { loggedIn, setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const { isLoading } = useLoading();
  const initialUser = {
    firstname: "",
    lastname: "",
    username: "",
    acesslevel: "",
    initials: "",
  };

  let logoutTimeout;

  const handleLogout = () => {
    // Perform logout logic here
    clearTimeout(logoutTimeout);
    logoutTimeout = setTimeout(logoutUser, 5 * 60 * 1000);
  };

  const handleLogoutButton = () => {
    // Perform logout logic here
    setLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLoggedIn(initialUser);
  };

  const logoutUser = () => {
    setLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLoggedIn(initialUser);
  };
  document.addEventListener("mousemove", handleLogout);
  document.addEventListener("keypress", handleLogout);

  return (
    <>
      {loggedIn && (
        <Box sx={header}>
          <Logo logo={logo} />
          <Box
            sx={{ color: "#006400", fontSize: "1.25rem", fontWeight: "400" }}
          >
            {title}
          </Box>
          <MenuBar menuItems={menuItems} />
          <LogoutButton handleLogout={handleLogoutButton} />
        </Box>
      )}
      {!loggedIn && <LoginModal />}
      {isLoading && <LoadingIcon strokeColour="#006400" strokeWidth="20" />}
    </>
  );
};

export default Header;
