import React from "react";
import { Box } from "@mui/material";

const LogoutButton = ({ handleLogout }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
    >
      <span className="eicon-logout" onClick={handleLogout} />
    </Box>
  );
};

export default LogoutButton;
