import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import * as d3 from "d3";
import {
  createColourScale,
  getSegments,
  getUniqueValues,
  formatNumber,
} from "../functions.js";

const MapChart = ({
  data,
  mapData,
  mapColour,
  mapHoveredColour,
  minColour,
  maxColour,
  sx,
  units = "state",
}) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleResize = () => {
    if (data && svgRef.current && mapData) {
      const parentElement = parentRef.current;
      const containerWidth = parentElement.clientWidth;
      const containerHeight = parentElement.clientHeight;

      const datapoints = getUniqueValues(data, units);
      const segmentData = getSegments(data, datapoints, units);
      const colourScale = createColourScale(segmentData, minColour, maxColour);

      // Create the chart dimensions and margins
      const width = containerWidth;
      const height = containerHeight;

      // SVG Container
      const svg = d3
        .select(svgRef.current)
        .attr("width", width)
        .attr("height", height);

      svg.selectAll("*").remove();

      // Projection and Path Generation
      const projection = d3.geoMercator().fitSize([width, height], mapData);
      const path = d3.geoPath(projection);

      // Draw your map features
      svg
        .selectAll("path")
        .data(mapData.features)
        .enter()
        .append("path")
        // .attr('class', 'states')
        .attr("d", path)
        .attr("fill", (feature) => {
          const matchingData = segmentData.find(
            (d) => d.label === feature.properties.name
          );
          return matchingData ? colourScale(matchingData.value) : mapColour;
        })
        .attr("stroke", "black")
        .attr("stroke-width", ".75")
        .on("mouseover", (event, feature) => {
          d3.select(event.target).attr("fill", mapHoveredColour);

          const tooltip = d3.select(tooltipRef.current);

          // Find the corresponding data value
          const matchingData = segmentData.find(
            (d) => d.label === feature.properties.name
          );
          const value = matchingData ? matchingData.value : "No Data";

          // Create a group for the label and value text and rectangle
          // const tooltip = svg.append("g").attr("class", "tooltip");

          // X and Y Positions
          const [x, y] = d3.pointer(event);
          tooltip
            .style("left", `${x + 75}px`) // Move right of the cursor
            .style("top", `${y}px`) // Move top of the cursor
            .style("display", "block")
            .html(
              `<strong>${
                feature.properties.name
              }</strong><br/>Value: ${formatNumber(value)}`
            );

          // Calculate the width and height based on text length
          const labelWidth = feature.properties.name.length * 8;
          const widthPadding = 30;
          const rectWidth = Math.max(labelWidth + widthPadding, 100);
          const rectHeight = 30;

          tooltip
            .style("width", `${rectWidth}px`)
            .style("height", `${rectHeight}px`);
        })
        .on("mousemove", (event) => {
          const tooltip = d3.select(tooltipRef.current);
          const [x, y] = d3.pointer(event);
          tooltip
            .style("left", `${x + 75}px`) // Move right of the cursor
            .style("top", `${y}px`); // Move top of the cursor
        })
        .on("mouseout", (event) => {
          const matchingData = segmentData.find(
            (d) => d.label === event.target.__data__.properties.name
          );
          d3.select(event.target).attr(
            "fill",
            matchingData ? colourScale(matchingData.value) : mapColour
          );
          d3.select(tooltipRef.current).style("display", "none");
        });
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data, mapData]);

  return (
    <Box sx={sx}>
      <svg
        ref={parentRef}
        className="chartsvg"
        style={{
          height: "100%",
          width: "90%",
          margin: "auto",
          display: "grid",
          placeItems: "center",
        }}
      >
        <g
          ref={svgRef}
          style={{
            cursor: "pointer",
          }}
        />
      </svg>
      <Box
        ref={tooltipRef}
        style={{
          position: "absolute",
          pointerEvents: "none",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: "5px",
          borderRadius: "0.25em",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
          display: "none",
          color: "black",
          fontSize: "12px",
          fontFamily: "Arial",
        }}
      />
    </Box>
  );
};

export default MapChart;
