import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import * as topojson from "topojson-client";
import { DataContext } from "../contexts/DataContext";
import { AuthContext } from "../contexts/AuthContext";
import Header from "../global/Header";
import SlicerBar from "../components/SlicerBar";
import MapChart from "../charts/MapChart";
import PieChart from "../charts/PieChart";
import LineChart from "../charts/LineChart";
import Guage from "../charts/Guage";
import Card from "../charts/Card";
import {
  info,
  mapSection,
  temp,
  pieChart,
  guage,
  chart,
  cardbar11111,
  cardbar1112,
  cardbar1121,
  chartbar,
  colourLegendRight,
  legendItemRight,
  legendLabel,
  title,
  map,
} from "../styles/styles";
import { nigeria } from "../data/maps/maps";
import {
  sumValues,
  sumValuesByDataPoint,
  formatNumber,
  getUniqueValues,
  countValid,
  countUniqueDatapoints,
  pieDeliveries,
  extractLabels,
  transformData,
} from "../functions";
import { colourScale } from "../data/colourScale";

const logo = "../../images/logos/nascp.png";

const National = () => {
  const { loggedIn, isLoggedIn } = useContext(AuthContext);
  const { state } = useContext(DataContext);
  const {
    filteredTestsData,
    filteredFacilitiesData,
    filteredFundersData,
    filteredMentorshipData,
    filteredMentorsData,
    mentorsData,
    filteredHivCasesData,
    filteredVisitationData,
  } = state;

  const menuItems = [
    { path: "/", label: "Home" },
    { path: "/national", label: "National" },
    { path: "/states", label: "States" },
    ...(isLoggedIn.accesslevel >= 2
      ? [{ path: "/backend", label: "Backend" }]
      : []),
  ];

  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    const { states } = nigeria.objects;
    const geoJsonData = topojson.feature(nigeria, states);
    setMapData(geoJsonData);
  }, []);

  // Map Chart
  const mapChartTitle = "";
  const mapHoveredColour = "#466D1D";
  const mapHightlightColour = "#960018";

  // Cards
  // Card Data
  const testsUnits = sumValues(filteredTestsData, "value");
  const ncmUniqueValues = getUniqueValues(filteredMentorsData, "ncm");
  const scmUniqueValues = getUniqueValues(filteredMentorsData, "scm");
  const menteeUniqueValues = getUniqueValues(filteredMentorsData, "mentee");
  const facilityUniqueValues = getUniqueValues(filteredMentorsData, "facility");
  const funderUniqueValues = getUniqueValues(filteredMentorsData, "funder");

  // Card Formatted
  const testsRound = formatNumber(testsUnits);
  const scmRound = countValid(scmUniqueValues);
  const ncmRound = countValid(ncmUniqueValues);
  const menteeRound = countValid(menteeUniqueValues);
  const facilityRound = countValid(facilityUniqueValues);
  const funderRound = countValid(funderUniqueValues);

  // PieChart Logic
  const category = getUniqueValues(filteredMentorsData, "category");
  const categoryPieData = countUniqueDatapoints(
    filteredMentorsData,
    "category"
  );
  const categoryKey = extractLabels(categoryPieData);

  // Gauge
  const visitationFacilities = sumValues(filteredVisitationData, "facilities");
  const visitationFacilitiesVisits = sumValues(
    filteredVisitationData,
    "visited"
  );

  // Line Chart
  const lineData = transformData(filteredVisitationData);
  return (
    <>
      <Header logo={logo} menuItems={menuItems} />
      <SlicerBar />
      <Box
        sx={{
          height: "auto",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <Box>
          <Box sx={title}>Mentorship</Box>
          <Box sx={cardbar1112}>
            <Card subtitleData="NCMs" valueData={ncmRound} />
            <Card subtitleData="SCMs" valueData={scmRound} />
            <Card subtitleData="Mentees" valueData={menteeRound} />
            <PieChart
              sx={pieChart}
              data={categoryPieData}
              colourScale={colourScale}
              keys={categoryKey}
              colourLegend={colourLegendRight}
              legendItem={legendItemRight}
              offset={0}
            />
          </Box>
          <Box sx={cardbar1121}>
            <Card subtitleData="Facilities" valueData={facilityRound} />
            <Card subtitleData="Funders" valueData={funderRound} />
            <LineChart
              sx={chart}
              data={lineData}
              colourScale={colourScale}
              colourLegend={colourLegendRight}
            />
            <Guage
              sx={guage}
              cornerRadius={5}
              guageValue={visitationFacilitiesVisits}
              guageTarget={visitationFacilities}
            />
          </Box>
          <MapChart
            sx={map}
            title={mapChartTitle}
            data={filteredTestsData}
            mapData={mapData}
            mapColour="#00640000"
            mapHoveredColour={mapHoveredColour}
            mapHighlightColour={mapHightlightColour}
            minColour="#00640083"
            maxColour="#006400"
            units="state"
          />
        </Box>
        <Box
        // sx={info}
        >
          <Box sx={title}>HIV/AIDS</Box>
          {/* <Box sx={cardbar1112}>
            <Card subtitleData="NCMs" valueData={ncmRound} />
            <Card subtitleData="SCMs" valueData={scmRound} />
            <Card subtitleData="Mentees" valueData={menteeRound} />
            <PieChart
              sx={pieChart}
              data={categoryPieData}
              colourScale={colourScale}
              keys={categoryKey}
              colourLegend={colourLegendRight}
              legendItem={legendItemRight}
              offset={0}
            />
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default National;
