import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataProvider } from "./contexts/DataContext.jsx";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { LoadingProvider } from "./contexts/LoadingContext.jsx";
import Home from "./pages/Home.jsx";
import National from "./pages/National.jsx";
import States from "./pages/States.jsx";
import "../src/styles/index.css";
import "../src/styles/icons.css";

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <DataProvider>
          <LoadingProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/national" element={<National />} />
                <Route path="/states" element={<States />} />
              </Routes>
            </BrowserRouter>
          </LoadingProvider>
        </DataProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
