const nigeria = {
  type: "Topology",
  arcs: [
    [
      [6530, 2342],
      [8, -39],
      [-18, -80],
      [-18, -37],
      [9, -58],
      [45, 9],
      [111, -27],
      [153, -14],
      [30, -13],
      [23, -81],
      [50, -47],
      [66, -35],
    ],
    [
      [6989, 1920],
      [34, -40],
      [-10, -163],
      [31, -80],
      [30, -48],
      [44, -97],
      [-13, -22],
      [-129, 17],
    ],
    [
      [6976, 1487],
      [-20, 70],
      [-60, 7],
      [-43, 29],
      [-54, 97],
      [-50, -5],
      [-14, -104],
      [-54, 16],
      [-28, -23],
      [-20, -63],
      [63, 0],
      [6, -20],
      [-36, -61],
      [-36, -10],
      [-67, 9],
      [-21, -25],
      [-17, -65],
      [-6, -97],
      [-15, -42],
      [9, -65],
      [16, -28],
      [-54, -102],
      [-47, -23],
      [-11, -31],
      [38, -36],
      [-14, -19],
      [0, -68],
      [24, -32],
      [-13, -51],
    ],
    [
      [6452, 745],
      [-31, 32],
      [-66, 29],
      [-32, -7],
      [-54, 14],
      [-108, 1],
      [-41, -21],
      [-67, 9],
      [-68, 50],
      [38, 60],
      [35, 11],
      [-3, 40],
      [57, -2],
      [22, 71],
      [6, 61],
      [30, 53],
      [-18, 44],
      [26, 30],
      [-27, 61],
    ],
    [
      [6151, 1281],
      [37, 149],
      [41, 84],
      [-1, 25],
      [46, 56],
      [43, 87],
      [25, 31],
      [11, 59],
      [23, 45],
      [-32, 92],
      [-19, 108],
      [-10, 143],
      [13, 54],
      [-170, 26],
      [-11, 26],
    ],
    [
      [6147, 2266],
      [-9, 36],
      [24, 44],
      [61, 1],
      [28, 60],
    ],
    [
      [6251, 2407],
      [78, -11],
      [25, -38],
      [127, 20],
      [49, -36],
    ],
    [
      [14755, 9084],
      [-4, -71],
      [-38, -77],
      [-23, -17],
      [-44, -68],
      [-30, -111],
      [-52, -47],
      [-27, -82],
      [7, -59],
      [27, -63],
      [-11, -45],
      [-30, -28],
      [-4, -94],
      [-18, -34],
      [2, -70],
      [-32, -125],
      [-70, -97],
      [-7, -31],
      [-43, -18],
      [-90, 0],
      [-103, -113],
      [6, -39],
      [38, -37],
      [-20, -60],
      [-42, -25],
      [-17, -30],
      [18, -61],
      [67, -34],
      [11, -29],
      [-43, -61],
      [-14, -113],
      [-20, -26],
      [10, -62],
      [-36, -84],
      [-57, -26],
      [-174, -18],
      [-62, -48],
      [-34, -6],
      [-65, -63],
      [-85, -42],
      [-3, -45],
      [55, -11],
      [2, -161],
      [-30, -64],
      [-3, -53],
      [16, -42],
      [-24, -86],
      [6, -32],
      [-60, -104],
      [-57, -50],
      [15, -43],
      [-17, -55],
      [26, -76],
      [-33, -49],
      [-51, 6],
      [-44, -37],
      [-1, -56],
      [-47, -64],
      [-42, 0],
      [-81, -63],
      [-128, 35],
      [-55, -74],
      [-20, -71],
      [-63, -89],
      [-99, 23],
      [-88, -37],
      [-28, -84],
      [23, -88],
      [21, -132],
      [-2, -61],
      [-49, -67],
      [-19, -44],
      [19, -140],
      [-88, -134],
      [-77, -70],
      [-40, -68],
      [-35, -79],
      [-5, -66],
      [-25, -41],
      [12, -126],
      [16, -49],
      [-68, -34],
      [-44, -7],
      [-42, -53],
      [-95, -102],
    ],
    [
      [12294, 4227],
      [-29, 52],
      [-81, 96],
      [-12, 72],
      [13, 151],
      [-19, 100],
      [-14, 26],
      [-80, 71],
      [-56, 95],
      [-91, 55],
      [-49, -19],
      [-37, -104],
      [-32, -22],
      [-23, 22],
      [-82, 112],
      [-24, 55],
      [-70, 109],
      [29, 62],
      [154, 143],
      [91, 105],
      [74, 144],
      [67, 101],
      [51, 42],
      [55, 19],
      [66, 59],
      [22, 44],
      [-23, 70],
      [24, 90],
      [17, 22],
      [28, 82],
      [41, 86],
      [52, 73],
      [4, 47],
      [-13, 73],
      [-49, 78],
      [-87, 24],
      [-114, 74],
      [-16, 44],
      [11, 74],
      [-4, 67],
      [-40, 59],
      [-41, 89],
      [-58, 82],
      [-52, 2],
      [-80, 81],
      [-61, 89],
      [-30, 60],
    ],
    [
      [11726, 7183],
      [52, 67],
      [45, 12],
      [130, 3],
      [74, 37],
      [84, 64],
      [57, 18],
      [77, 89],
      [54, 48],
      [-29, 83],
      [26, 93],
      [0, 106],
      [-16, 54],
    ],
    [
      [12280, 7857],
      [209, -27],
      [19, 38],
      [70, -9],
      [46, 22],
      [1, 48],
      [40, 57],
      [86, 75],
      [72, 35],
      [22, 66],
      [40, 54],
      [45, 16],
      [143, -5],
      [96, 35],
      [70, 47],
      [6, 104],
      [56, 20],
      [62, -39],
      [28, 63],
      [83, 124],
      [187, -4],
      [112, -34],
      [52, -32],
      [46, -69],
      [28, -20],
      [140, -48],
      [64, -13],
      [50, 123],
      [-7, 108],
      [38, 56],
      [61, 120],
      [31, 112],
      [3, 170],
      [38, 7],
      [16, -32],
      [98, -41],
      [50, 11],
      [49, 30],
      [37, 44],
      [34, 19],
      [154, -4],
    ],
    [
      [6976, 1487],
      [32, -35],
      [-85, -5],
      [32, -31],
      [37, 2],
      [40, -30],
      [28, -67],
      [66, -52],
      [32, 55],
      [24, -5],
      [51, -79],
      [-10, -54],
      [40, -42],
      [10, -124],
      [63, -115],
      [77, -43],
      [31, -65],
      [52, -42],
    ],
    [
      [7496, 755],
      [-22, -21],
      [22, -33],
      [65, -57],
      [14, -143],
      [-27, -68],
      [-54, -83],
      [-83, 17],
      [-174, -1],
      [-186, -9],
      [-78, -16],
      [-71, -3],
      [-172, -28],
      [-133, -51],
    ],
    [
      [6597, 259],
      [-23, 50],
      [-37, 17],
      [-38, 49],
      [28, 40],
      [-34, 70],
      [1, 73],
      [34, 65],
      [-29, 28],
      [1, 36],
      [-48, 58],
    ],
    [
      [6147, 2266],
      [-62, -12],
      [-55, -32],
      [-33, 14],
      [-119, 20],
      [-39, -27],
      [-71, -14],
      [-34, -49],
      [-26, -8],
      [-23, -48],
      [-41, 4],
      [-1, -112],
      [-46, 25],
      [-18, -11],
      [-46, 32],
      [-80, -60],
      [-97, -52],
    ],
    [
      [5356, 1936],
      [-59, 37],
    ],
    [
      [5297, 1973],
      [39, 65],
      [-6, 62],
      [14, 54],
      [36, 55],
      [34, 81],
      [15, 122],
      [-21, 43],
      [62, 59],
      [19, 64],
      [-52, 97],
      [-24, 155],
      [-17, 39],
      [-42, 163],
    ],
    [
      [5354, 3032],
      [-5, 42],
    ],
    [
      [5349, 3074],
      [25, 15],
      [147, 40],
      [20, 10],
      [-13, 136],
      [10, 32],
      [121, 121],
      [36, 13],
      [12, -46],
    ],
    [
      [5707, 3395],
      [-1, -94],
      [79, 19],
      [74, -35],
      [74, -16],
      [41, -44],
      [-30, -68],
      [-82, -90],
      [2, -72],
      [-15, -46],
      [67, -14],
      [53, -59],
      [46, -112],
      [-27, -21],
      [20, -81],
      [43, 13],
      [46, -65],
      [0, -100],
      [42, -18],
      [18, -60],
      [45, -22],
      [49, -3],
    ],
    [
      [11161, 9561],
      [21, -14],
      [-30, -67],
      [-40, -47],
      [-89, -76],
      [-50, -78],
      [-40, -15],
      [-58, 50],
      [-42, 4],
      [-67, -55],
      [-56, -79],
      [-39, -7],
      [-41, -40],
      [-10, -30],
      [17, -49],
      [31, -40],
      [5, -50],
      [-33, -3],
      [-32, 42],
      [-24, -5],
      [-52, -155],
      [-17, -14],
      [-61, -100],
      [48, -31],
      [36, -49],
      [23, -93],
      [78, -71],
      [117, -19],
      [82, -23],
      [37, -24],
      [56, -79],
      [-13, -148],
      [42, -79],
      [-50, -74],
      [-6, -71],
      [-24, -25],
      [-129, 2],
      [-22, -43],
      [58, -53],
      [81, -48],
      [49, -50],
      [139, -81],
      [22, -58],
      [-2, -41],
      [23, -98],
      [-15, -40],
      [2, -72],
      [32, -123],
    ],
    [
      [11118, 7242],
      [-40, 1],
      [-93, 31],
      [-50, 3],
      [-91, -19],
      [-53, -54],
      [-78, -34],
      [-112, 33],
      [-97, -73],
    ],
    [
      [10504, 7130],
      [-37, 57],
      [-87, 43],
      [-55, 19],
      [-75, 57],
      [-39, 17],
      [-56, 49],
      [-142, -9],
      [-26, 55],
      [-97, 51],
      [-123, 19],
      [-41, -70],
      [34, -136],
      [-25, -18],
      [-118, -3],
      [-49, -18],
      [-53, -58],
      [-48, -22],
      [-109, -23],
      [-19, 8],
      [-127, -26],
      [-81, 32],
      [-10, -71],
      [-26, -1],
      [-80, 45],
      [-12, 52],
      [-52, 35],
      [-45, 10],
      [-3, 53],
      [-48, 24],
      [5, 76],
      [-23, 33],
      [-41, -47],
      [-118, 86],
      [5, 15],
      [76, 35],
      [55, 37],
      [44, 96],
      [6, 40],
      [-59, 168],
      [-34, 17],
      [-78, 2],
      [-155, -41],
      [-93, -5],
      [-32, 20],
      [27, 151],
      [-1, 71],
      [-58, 34],
      [7, 71],
      [26, 57],
      [-26, 54],
      [-87, 12],
      [-63, 35],
    ],
    [
      [8238, 8318],
      [-52, 53],
      [-60, 9],
      [-14, 37],
      [54, 28],
      [-3, 49],
      [16, 69],
    ],
    [
      [8179, 8563],
      [50, 55],
      [48, 113],
      [4, 29],
      [-45, 84],
      [-49, 38],
      [-21, 44],
      [-7, 49],
      [34, 112],
      [-16, 87],
      [14, 52],
      [30, 55],
      [22, 14],
      [63, -15],
      [29, 16],
      [69, 96],
      [47, 32],
      [68, 83],
      [85, 47],
      [61, 52],
      [68, 30],
    ],
    [
      [8733, 9636],
      [42, 20],
      [118, 5],
      [149, -76],
      [56, -8],
      [46, 37],
      [62, 5],
      [32, -20],
      [16, -87],
      [62, -11],
      [161, 15],
      [70, 31],
      [31, -4],
      [55, -132],
      [35, -32],
      [-45, -55],
      [-5, -115],
      [15, -52],
      [135, -53],
      [114, 21],
      [32, 18],
      [37, -10],
      [41, -46],
      [71, -4],
      [10, 27],
      [-53, 35],
      [-18, 33],
      [45, 82],
      [32, 35],
      [82, -9],
      [62, 51],
      [28, -3],
      [50, 68],
      [-8, 40],
      [-49, -6],
      [-124, 29],
      [-53, -4],
      [-126, 47],
      [-79, 8],
      [-48, -22],
      [-78, 91],
      [-87, 73],
      [5, 111],
      [-59, 35],
      [-21, -26],
      [-43, 9],
      [-16, 87],
      [55, 57],
      [10, 105],
      [-20, 104],
      [-47, 24],
      [-83, -25],
      [-87, 15],
      [-30, 39],
      [60, 85],
      [11, 54],
      [51, -7],
      [56, 26],
      [104, 24],
      [15, 35],
      [45, 42],
      [143, 32],
      [142, 49],
      [10, 27],
      [69, 15],
      [14, 45],
      [-26, 103],
      [31, 136],
      [43, 69],
      [-6, 55],
      [51, 50],
      [46, 79],
      [37, 27],
      [111, 25],
      [66, -9],
      [30, -58],
      [55, 21],
      [47, 57],
      [78, 36],
      [12, 17],
    ],
    [
      [10608, 11228],
      [52, -8],
      [13, -22],
      [145, -50],
      [9, -117],
      [20, -48],
      [-4, -82],
      [7, -60],
      [35, -96],
      [3, -85],
      [13, -114],
      [-18, -91],
      [38, -82],
      [-3, -59],
      [100, -41],
      [3, -50],
      [19, -34],
      [-5, -112],
      [15, -42],
      [15, -231],
      [-28, -109],
      [-61, -22],
      [-14, -39],
      [154, -76],
      [45, 3],
    ],
    [
      [5265, 82],
      [-18, -10],
      [-189, -14],
      [-36, -16],
      [-223, -18],
      [-139, -24],
      [-91, 1],
      [-30, 19],
      [-111, 34],
      [-13, 32],
      [-97, 44],
      [-63, 43],
      [-34, 35],
      [-153, 120],
      [-8, 22],
      [-102, 112],
      [-92, 133],
      [10, 35],
      [-33, 21],
      [-39, 81],
      [-63, 107],
      [2, 35],
      [-20, 70],
      [-80, 161],
      [0, 65],
    ],
    [
      [3643, 1170],
      [62, 10],
      [44, -25],
      [1, -41],
      [44, -1],
      [64, -50],
      [45, 0],
      [65, -51],
      [45, 14],
      [58, -6],
      [27, 42],
      [65, 4],
      [17, 38],
      [65, -4],
      [39, -16],
      [21, 40],
      [40, 11],
      [17, 71],
      [25, 5],
      [78, -82],
      [30, -11],
      [47, 10],
      [10, 52],
      [38, 12],
      [43, -29],
      [48, 44],
      [49, 25],
      [-11, 50],
      [25, 30],
      [62, -14],
      [45, 15],
      [-8, 59],
      [26, 28],
      [55, 24],
      [82, -22],
      [56, 31],
      [53, -4],
      [17, 20],
      [-23, 55],
    ],
    [
      [5109, 1504],
      [102, 0],
      [2, -81],
      [-43, -48],
      [47, -65],
      [-29, -69],
      [-67, 1],
      [-24, -19],
      [2, -49],
      [-91, -199],
      [36, -33],
      [5, -32],
      [-57, -107],
      [5, -51],
      [59, -132],
      [43, -22],
      [66, 53],
      [34, 8],
      [54, -61],
      [-40, -48],
      [4, -80],
      [49, -80],
      [-32, -113],
      [-42, -69],
      [17, -70],
      [40, -25],
      [16, -31],
    ],
    [
      [8714, 4948],
      [6, -17],
      [-79, -94],
      [8, -53],
      [80, 31],
      [110, 17],
      [57, 16],
      [109, 13],
      [50, 19],
      [34, 30],
      [59, 18],
      [89, 7],
      [73, -37],
      [186, -144],
      [47, -53],
      [62, -36],
      [90, -86],
      [-10, -89],
      [27, -78],
      [-24, -24],
      [-16, -82],
      [-72, -214],
      [-21, -34],
      [13, -51],
      [-16, -67],
      [-5, -81],
      [-71, -58],
      [-46, 1],
      [-43, -75],
      [-72, -159],
      [-6, -39],
      [10, -91],
      [-4, -83],
      [-32, -101],
      [-31, -182],
    ],
    [
      [9276, 3072],
      [-17, -76],
      [-57, -43],
      [-70, 3],
      [-34, 14],
    ],
    [
      [9098, 2970],
      [7, 94],
      [-52, 44],
      [-40, 60],
      [-46, 38],
      [-91, 124],
      [-59, -3],
      [-143, -46],
      [-105, -57],
      [-58, 11],
      [-47, 57],
      [-29, 16],
      [28, 58],
      [5, 41],
      [-96, 65],
      [-113, 61],
      [-106, 30],
      [-75, 7],
      [-46, -36],
      [-84, -15],
      [47, -55],
      [-28, -51],
      [-57, -22],
      [-84, -7],
      [-63, -18],
      [-38, 14],
      [-63, -20],
      [-43, -67],
      [-89, -20],
    ],
    [
      [7530, 3273],
      [-21, 27],
      [-51, 128],
      [-63, -19],
      [-55, -32],
      [-33, -59],
      [-103, -3],
      [-39, 19],
      [-36, -26],
      [-17, -55],
      [7, -69],
      [-29, -29],
      [-4, -65],
      [-41, -17],
      [-56, 27],
      [-46, 40],
      [-30, 53],
      [-34, 20],
    ],
    [
      [6879, 3213],
      [-4, 8],
      [67, 140],
      [65, 52],
      [-23, 18],
      [2, 80],
      [-32, 37],
      [-50, 15],
      [-56, -25],
      [-46, 7],
      [-22, -20],
      [-80, -11],
      [-72, 87],
      [-57, 18],
      [-63, 136],
    ],
    [
      [6508, 3755],
      [64, 32],
      [8, 69],
      [51, 77],
      [85, 38],
      [130, 21],
      [47, 110],
      [-59, 19],
      [32, 79],
      [-6, 76],
      [56, 69],
      [-23, 43],
      [-53, -20],
      [-31, 60],
      [-21, 16],
      [16, 53],
      [1, 227],
      [-11, 34],
      [-69, 95],
      [-31, 63],
      [-6, 75],
      [15, 101],
    ],
    [
      [6703, 5092],
      [113, -15],
      [104, -24],
      [106, -13],
      [194, -67],
      [40, -30],
      [62, -19],
      [-50, -32],
      [78, -10],
      [154, -62],
      [130, -60],
      [53, 8],
      [-24, 46],
      [16, 69],
      [-36, 76],
      [-19, 5],
      [-2, 131],
      [37, 84],
      [48, 46],
      [103, 63],
      [52, -13],
      [62, 10],
      [44, -49],
      [213, -61],
      [174, -26],
      [153, 9],
      [43, 20],
      [8, -34],
      [91, -166],
      [64, -30],
    ],
    [
      [12280, 7857],
      [-112, 121],
      [-26, 42],
      [-40, 4],
      [-62, 39],
      [-84, 17],
      [-48, 108],
      [-43, 24],
      [14, 28],
      [78, 42],
      [45, 52],
      [0, 97],
      [20, 86],
      [-15, 45],
      [1, 64],
    ],
    [
      [12008, 8626],
      [61, 15],
      [42, 23],
      [69, 71],
      [-24, 45],
      [-40, 31],
      [25, 64],
      [-28, 65],
      [-45, 62],
      [184, 69],
      [153, 42],
      [87, 79],
      [27, 44],
      [44, 8],
      [38, -15],
      [75, 13],
      [14, 27],
      [-22, 64],
      [-25, 37],
      [-16, 101],
      [7, 36],
      [106, 62],
      [33, 86],
      [44, 67],
      [96, 53],
      [29, 41],
      [48, 91],
      [-34, 45],
      [-65, 35],
      [2, 49],
      [-35, 67],
      [-69, -83],
      [-61, 35],
      [43, 113],
      [31, 15],
      [26, 101],
      [-11, 149],
      [17, 150],
      [61, 235],
      [-7, 59],
      [9, 70],
      [-36, 74],
      [-22, 71],
      [53, 49],
      [85, 43],
      [46, 43],
      [47, 85],
      [-3, 29],
      [47, 15],
      [32, 66],
      [0, 54],
      [-51, 70],
      [-8, 45],
      [-95, 149],
      [40, 123],
      [-15, 124],
    ],
    [
      [13017, 11987],
      [119, -11],
      [19, 33],
      [-8, 39],
      [38, 37],
      [49, 8],
      [11, 50],
      [-14, 37],
      [48, 80],
      [49, -8],
      [17, 28],
      [47, -27],
      [24, 54],
      [65, 15],
      [17, 40],
      [39, 7],
      [49, 48],
      [9, 44],
      [41, 31],
      [47, 61],
      [45, -13],
      [61, 43],
      [52, -4],
      [73, 48],
      [37, -45],
      [18, 28],
      [54, 5],
      [53, -10],
      [69, 35],
      [35, 56],
      [-18, 22],
      [65, 60],
      [0, 20],
      [55, 48],
      [31, 5],
      [164, -4],
      [197, 4],
      [193, -269],
      [99, -156],
      [61, -74],
      [60, -96],
      [190, -262],
      [26, -102],
      [6, -63],
      [57, -302],
      [38, -230],
      [26, -95],
      [-29, -43],
      [2, -63],
      [30, -53],
      [36, -34],
      [65, 13],
      [24, -27],
      [50, 29],
      [76, -19],
      [106, -1],
      [71, -42],
      [-2, -43],
      [42, -23],
      [9, -62],
      [56, -23],
      [37, -48],
      [45, 22],
      [27, -60],
      [-30, -22],
      [-20, -56],
      [15, -36],
      [-37, -29],
      [-1, -39],
      [45, -83],
      [-19, -61],
      [-48, -58],
      [3, -121],
      [-17, -29],
      [-56, -40],
      [5, -33],
      [96, -70],
      [22, -70],
      [-30, -90],
      [-31, -29],
      [-135, -61],
      [-63, -11],
      [-32, -64],
      [-113, -84],
      [-88, -40],
      [-103, -70],
      [-46, -54],
      [-90, 26],
      [-51, 48],
      [-117, 25],
      [-24, -70],
      [-34, -44],
      [-30, -70],
      [-40, -38],
      [-34, -70],
      [-39, -30],
      [-47, -86],
      [-31, -3],
      [-100, -79],
    ],
    [
      [9098, 2970],
      [-31, -145],
      [-48, -45],
      [-84, 27],
      [3, -62],
      [-79, -159],
      [-66, -53],
      [-160, -77],
      [-120, -109],
      [-52, -63],
      [-65, -44],
      [-37, -38],
      [-16, -41],
      [-25, -2],
      [-52, -44],
      [18, -60],
      [-12, -69],
      [-44, -24],
      [3, -30],
      [33, -18],
      [11, -36],
      [47, -45],
      [12, -58],
      [-47, -108],
      [-25, -146],
      [-42, -116],
      [5, -100],
      [-17, -108],
      [-13, -30],
      [-86, -78],
      [-3, -43],
      [-53, -61],
      [10, -16],
      [-59, -69],
      [5, -27],
      [-45, -17],
      [-19, -32],
      [37, -51],
      [-11, -30],
      [-42, -18],
      [-38, 17],
      [-34, -26],
      [-25, -77],
      [13, -49],
      [-67, -26],
      [-29, 22],
      [21, 41],
      [-95, -4],
      [-46, 61],
      [-46, 37],
      [-32, 45],
      [-55, -11],
    ],
    [
      [6989, 1920],
      [41, 102],
      [41, 21],
      [-24, 61],
      [32, 31],
      [14, 38],
      [44, 57],
      [-29, 72],
      [9, 22],
      [61, 26],
      [32, -4],
      [25, -68],
      [41, 18],
      [12, -31],
      [72, 43],
      [63, 58],
      [41, 8],
      [18, -27],
      [38, 25],
      [29, 167],
      [29, 69],
      [50, 11],
      [66, 84],
      [-34, 64],
      [21, 92],
      [51, 15],
      [15, 58],
      [-45, 68],
      [-5, 50],
      [-38, 12],
      [-68, 71],
      [-61, 140],
    ],
    [
      [5297, 1973],
      [-26, -73],
      [20, -38],
      [-49, -110],
      [-57, -17],
      [-15, -23],
      [9, -77],
      [-18, -50],
      [-55, -25],
      [3, -56],
    ],
    [
      [3643, 1170],
      [-46, 62],
      [-2, 89],
      [-32, 105],
      [-8, 115],
      [-92, 42],
      [-61, 53],
      [-61, 88],
      [38, 53],
      [-82, 79],
      [-33, 82],
      [-27, 38],
      [-19, 77],
      [-31, 8],
      [-88, 150],
    ],
    [
      [3099, 2211],
      [44, 68],
      [79, 176],
      [34, 64],
      [45, 61],
    ],
    [
      [3301, 2580],
      [14, -38],
      [86, 16],
      [32, -56],
      [0, -53],
      [49, -26],
      [-30, -92],
      [37, -106],
      [39, 15],
      [73, 68],
      [-30, 20],
      [-12, 46],
      [112, 3],
      [42, 27],
      [31, -17],
      [63, 10],
      [32, -62],
      [46, 2],
      [46, 44],
      [70, 13],
      [15, 26],
      [169, -99],
      [22, 6],
      [29, -58],
      [118, -87],
      [-20, -45],
      [-1, -101],
      [25, -30],
      [77, 0],
      [77, 23],
      [83, 52],
      [26, 50],
      [41, 24],
      [88, 80],
      [38, 75],
      [15, 62],
      [-74, 45],
      [-43, 45],
      [-67, 102],
      [-18, 60],
      [8, 53],
      [-36, 31],
      [4, 75],
      [51, 51],
      [55, -33],
      [61, -72],
      [55, 55],
      [37, 52],
      [102, 36],
      [73, 16],
      [120, 93],
      [28, 32],
      [115, 16],
      [50, -14],
      [30, 17],
    ],
    [
      [6530, 2342],
      [35, -35],
      [122, -53],
      [37, -7],
      [30, 29],
      [-5, 34],
      [-47, 53],
      [-9, 73],
      [60, 27],
      [33, 118],
      [-7, 77],
      [-33, 70],
      [16, 72],
      [41, 74],
      [-6, 119],
      [-21, 119],
      [-39, 69],
      [5, 35],
      [42, -3],
      [42, -55],
      [31, -8],
      [22, 63],
    ],
    [
      [3301, 2580],
      [1, 23],
      [-62, 20],
      [-34, 64],
      [-65, 60],
      [26, 56],
      [-9, 16],
      [33, 53],
      [31, -9],
      [37, 34],
      [-1, 54],
      [41, 42],
      [41, 111],
      [-36, 32],
      [-34, 72],
      [14, 93],
      [38, 64],
      [62, 38],
      [16, 49],
      [62, 100],
      [23, 8],
      [354, -6],
      [45, -21],
      [1, -33],
      [-37, -28],
      [26, -85],
      [-2, -44],
      [20, -35],
      [58, 52],
      [28, 6],
      [36, -26],
      [47, 12],
      [32, 45],
      [42, 20],
      [27, 46],
      [-11, 30],
      [23, 43],
      [17, 68],
      [-19, 64],
      [14, 76],
      [26, 64],
      [53, 34],
      [39, 141],
      [26, 24],
      [-9, 55],
      [36, 51],
      [66, 23],
      [-21, 44],
      [39, 15],
      [-24, 81],
      [25, 36],
      [-52, 63],
      [8, 38],
      [67, 19],
    ],
    [
      [4465, 4432],
      [59, 69],
      [94, 15],
      [47, -94],
      [-61, -43],
      [5, -57],
      [100, -14],
      [54, 44],
      [92, 33],
      [38, -69],
      [72, -64],
      [59, 27],
      [18, -78],
      [42, 7],
      [44, -52],
      [0, -47],
      [20, -34],
      [124, 15],
      [41, 22],
      [42, -74],
      [32, -32],
      [30, -110],
      [-6, -43],
      [-46, -96],
      [3, -89],
      [-8, -45],
      [-83, -154],
      [27, -73],
      [-18, -87],
      [57, -131],
      [6, -104],
    ],
    [
      [4155, 4729],
      [-75, -63],
      [-29, -40],
      [-5, -46],
      [-21, -20],
      [-35, 18],
      [-35, -12],
      [5, -46],
      [-26, -51],
      [3, -98],
      [-14, -47],
      [-98, -149],
      [-33, -21],
      [-69, -9],
      [5, -25],
      [-88, -23],
      [-40, 17],
      [-15, 116],
      [-36, 82],
      [-100, -11],
      [-159, 17],
      [-65, 18],
      [-39, -2],
      [-57, -60],
    ],
    [
      [3129, 4274],
      [-72, 47],
      [-43, 94],
      [-24, 81],
      [-26, 48],
      [-26, 15],
      [40, 147],
      [28, 71],
      [-4, 92],
      [51, 72],
      [55, 38],
      [54, 62],
      [6, 39],
      [30, 46],
    ],
    [
      [3198, 5126],
      [36, -20],
      [44, 52],
      [33, -1],
      [72, -59],
      [46, 5],
      [70, -19],
      [66, 2],
      [38, 39],
      [5, 71],
      [31, 16],
      [148, 11],
    ],
    [
      [3787, 5223],
      [21, 1],
      [-13, -119],
      [97, 65],
      [107, 4],
      [-22, -28],
      [38, -70],
      [-31, -54],
      [-62, -15],
      [21, -117],
      [38, -51],
      [115, 0],
      [52, -55],
      [32, -8],
      [-25, -47],
    ],
    [
      [5707, 3395],
      [22, 28],
      [4, 54],
      [47, 22],
      [5, -80],
      [69, -4],
      [52, 24],
      [52, 66],
      [75, 65],
      [79, 19],
      [8, 83],
      [93, 44],
      [24, 28],
      [51, 21],
      [26, 95],
      [75, 12],
      [32, -9],
      [51, -56],
      [30, -18],
      [6, -34],
    ],
    [
      [6774, 6886],
      [-83, -38],
      [-106, -238],
      [8, -371],
      [-4, -29],
      [-128, -246],
      [-71, -83],
      [-116, -110],
      [-194, -43],
      [-126, -20],
      [-178, -10],
    ],
    [
      [5776, 5698],
      [-209, -8],
    ],
    [
      [5567, 5690],
      [-66, 1],
      [0, 390],
      [5, 78],
      [24, 582],
      [280, 3],
      [46, 7],
      [225, -174],
      [11, 13],
      [225, 321],
    ],
    [
      [6317, 6911],
      [39, 23],
      [79, 5],
      [45, -13],
      [107, 96],
      [110, -27],
      [77, -109],
    ],
    [
      [11726, 7183],
      [-32, -7],
      [-171, 4],
      [-68, 30],
      [-88, -25],
      [-205, 1],
      [-40, 31],
      [-4, 25],
    ],
    [
      [11161, 9561],
      [86, 23],
      [112, -60],
      [70, -13],
      [41, -22],
      [95, 3],
      [75, -161],
      [112, -109],
      [86, -107],
      [23, -46],
      [-2, -79],
      [14, -35],
      [-32, -212],
      [12, -48],
      [-15, -72],
      [5, -50],
      [46, 24],
      [119, 29],
    ],
    [
      [6151, 1281],
      [-29, -27],
      [-102, 22],
      [-12, 31],
      [-55, 16],
      [-30, -29],
      [-72, 33],
      [-72, -21],
      [-82, -11],
      [-131, 36],
      [-75, 66],
      [-20, 31],
      [3, 90],
      [19, 57],
      [-30, 8],
      [1, 37],
      [-24, 40],
      [-23, -7],
      [-103, 6],
      [42, 277],
    ],
    [
      [8733, 9636],
      [9, 55],
      [26, 56],
      [12, 60],
      [68, 20],
      [60, 29],
      [12, 33],
      [43, 44],
      [-80, 18],
      [-57, 48],
      [18, 57],
      [-59, 2],
      [2, 55],
      [23, 46],
      [-22, 20],
      [-98, -17],
      [-19, 12],
      [-3, 83],
      [104, 94],
      [22, 33],
      [-26, 82],
      [47, 44],
      [-38, 14],
      [-13, 32],
      [8, 79],
      [-12, 47],
      [-65, -21],
      [-23, -52],
      [-2, -48],
      [-34, -20],
      [-43, 21],
      [-27, 69],
      [-77, -9],
      [-39, 20],
      [-33, -19],
      [-21, 53],
      [35, 50],
      [-17, 61],
      [-37, 33],
      [-50, 71],
      [21, 46],
      [-46, 3],
      [-26, -43],
      [-21, 51],
      [-47, -10],
      [-37, -44],
      [-45, -76],
      [-25, -3],
      [26, 135],
      [56, 48],
      [46, 74],
      [-7, 17],
      [-63, 28],
      [-77, 21],
      [-30, 31],
      [-8, 39],
      [45, 58],
      [3, 35],
      [-88, 29],
      [-132, 6],
      [-20, -12],
      [-90, -3],
      [-34, -37],
      [-27, -65],
      [-41, -44],
      [-13, -55],
      [-35, 48],
      [-59, 56],
      [-1, 84],
      [-28, 29],
    ],
    [
      [7524, 11337],
      [-5, 55],
      [-55, 10],
      [-52, -13],
      [-17, 19],
      [-68, 24],
      [11, 64],
      [32, 67],
      [-17, 72],
      [33, 28],
      [60, -6],
      [132, 13],
      [63, -26],
      [75, -13],
      [74, 28],
      [91, -9],
      [46, -25],
      [6, -61],
      [22, -45],
      [52, -5],
      [43, -43],
      [25, 6],
      [42, -34],
      [58, 14],
      [22, -17],
      [50, 24],
      [23, -15],
      [89, 31],
      [58, 69],
      [32, 87],
      [86, -5],
      [0, 29],
    ],
    [
      [8535, 11660],
      [241, -12],
      [116, -23],
      [104, 15],
      [141, -17],
      [204, -9],
      [14, 8],
    ],
    [
      [9355, 11622],
      [142, 0],
      [93, 17],
      [54, 21],
      [44, 49],
      [44, 90],
      [92, 39],
      [83, 17],
      [50, 19],
      [53, -10],
      [71, 44],
      [71, -18],
      [33, -55],
      [5, -44],
      [-14, -46],
      [1, -80],
      [127, 28],
      [101, -35],
      [24, -19],
      [76, 8],
      [60, -9],
      [21, -19],
      [-4, -72],
      [29, -53],
      [4, -183],
      [-14, -37],
      [7, -46],
    ],
    [
      [8238, 8318],
      [-63, -11],
      [27, -31],
      [1, -36],
      [-20, -47],
      [-106, -49],
      [-65, -58],
      [-10, -59],
      [-25, -61],
      [4, -29],
      [58, -97],
      [-2, -61],
      [-74, -16],
      [-10, -24],
      [34, -57],
      [-24, -45],
      [15, -31],
      [11, -128],
      [-60, -61],
      [5, -53],
      [-45, -17],
      [-12, -25],
      [17, -41],
      [-17, -72],
      [22, -95],
      [43, -31],
      [21, -56],
      [41, -54],
      [63, -23],
      [-2, -77],
      [-13, -53],
      [-65, -47],
      [28, -30],
    ],
    [
      [8015, 6743],
      [-41, -47],
      [-3, -58],
      [-17, -59],
      [-59, -62],
      [-10, -43],
      [-35, -34],
      [-39, -4],
      [-41, 98],
      [-36, 54],
      [-89, 94],
      [-133, 32],
      [-34, -36],
      [-23, -53],
      [-104, -100],
      [-44, -23],
      [-36, 43],
      [-3, 111],
      [22, 84],
      [-69, 65],
      [-84, 47],
      [-2, 65],
      [-82, 67],
      [-23, 2],
      [-42, -42],
      [-47, -74],
      [-27, -12],
      [-129, 50],
      [-11, -22],
    ],
    [
      [6317, 6911],
      [-37, 23],
      [-53, 0],
      [-68, -24],
      [-19, 36],
      [45, 46],
      [-20, 43],
      [-49, -5],
      [-33, 65],
      [34, 46],
      [62, 48],
      [12, 55],
      [53, 17],
      [7, 41],
      [-37, 44],
      [-38, 20],
      [8, 30],
      [-24, 36],
      [-62, 9],
      [-26, 26],
      [7, 30],
      [55, 19],
      [30, -15],
      [26, 38],
      [10, 70],
      [-21, 96],
      [-10, 114],
      [-31, 31],
      [-100, -16],
      [-56, 11],
      [-44, -13],
      [-54, 22],
      [-150, 7],
      [-69, 39],
      [-25, 56],
      [4, 46],
      [48, 54],
      [45, 2],
      [21, 55],
      [53, 24],
      [48, 56],
      [6, 35],
      [-40, 50],
      [-58, 15],
      [-57, -5],
      [-16, 29],
      [41, 136],
      [-55, 76],
      [-94, 47],
      [-17, 71],
      [-134, 33],
      [-21, -20],
      [24, -51],
      [-29, -13],
      [-84, 8],
      [-80, -26],
      [-63, -39],
      [-25, 37],
      [4, 31],
      [-41, 26],
      [-130, -57],
      [-36, -55],
      [-85, -59],
      [-10, -25],
      [-48, -19],
      [-18, -48],
      [-44, -36],
      [-65, -15],
      [-19, 36],
      [-6, 55],
      [-59, 8],
      [-20, 33],
      [3, 89],
      [38, 10],
      [9, 54],
      [-20, 27],
      [33, 77],
      [-62, 42],
      [29, 53],
      [-18, 43],
      [31, 101],
      [52, 29],
    ],
    [
      [4675, 8976],
      [53, -4],
      [34, 44],
      [10, 68],
      [65, 43],
      [36, 44],
      [88, 29],
      [49, -1],
      [37, -43],
      [44, 31],
      [138, 3],
      [42, 32],
      [52, 2],
      [62, 34],
      [30, 79],
      [38, 59],
      [-13, 37],
      [38, 91],
      [138, 97],
      [32, 56],
    ],
    [
      [5648, 9677],
      [41, 3],
      [57, -34],
      [60, 5],
      [23, -35],
      [-20, -58],
      [-41, -41],
      [77, -49],
      [51, -17],
      [97, -100],
      [80, 27],
      [40, 33],
      [-47, 91],
      [18, 22],
      [75, 20],
      [115, -22],
      [19, 90],
      [31, 63],
      [41, 30],
      [61, 13],
      [27, -14],
      [23, -61],
      [31, 7],
      [94, -55],
      [36, -50],
      [59, 22],
      [101, 23],
      [-1, 38],
      [26, 41],
    ],
    [
      [6822, 9669],
      [97, 2],
      [42, 11],
      [91, 78],
      [11, 23],
      [118, 36],
      [25, -6],
      [91, 47],
      [52, -10],
      [14, -28],
      [-6, -49],
      [-46, -81],
      [7, -20],
      [69, -50],
      [43, -43],
      [51, 10],
      [11, -53],
      [29, -21],
      [56, -68],
      [114, 25],
      [74, -57],
      [17, -34],
      [46, -17],
      [35, -79],
      [72, -43],
      [2, -94],
      [-36, -96],
      [-6, -61],
      [13, -155],
      [13, -67],
      [-80, -4],
      [-25, -22],
      [10, -56],
      [62, -36],
      [68, 29],
      [54, -16],
      [48, -97],
      [82, -23],
      [39, 19],
    ],
    [
      [6822, 9669],
      [-38, 15],
      [-51, 72],
      [-18, 61],
      [5, 45],
      [53, 95],
      [56, 38],
      [152, 48],
      [34, 36],
      [-38, 66],
      [-9, 80],
      [-21, 58],
      [9, 41],
      [-31, 70],
      [-12, 85],
      [6, 64],
      [20, 42],
      [14, 94],
      [-19, 90],
      [27, 89],
      [17, 104],
      [61, 33],
      [131, 42],
      [61, 35],
      [81, 7],
      [29, 26],
      [48, 82],
      [28, 69],
      [-2, 33],
      [33, 29],
      [54, -4],
      [22, 23],
    ],
    [
      [5648, 9677],
      [-2, 68],
      [27, 60],
      [-15, 41],
      [11, 65],
      [-66, 58],
      [1, 63],
      [45, 115],
      [10, 93],
      [160, 31],
      [51, -22],
      [70, 41],
      [46, 65],
      [4, 44],
      [75, -7],
      [56, 37],
      [-33, 34],
      [-10, 37],
      [-75, 33],
      [-84, 74],
      [30, 32],
      [38, 68],
      [15, 64],
      [-15, 57],
      [-48, 86],
      [-17, 96],
      [-33, 70],
      [-20, 143],
      [-15, 240],
      [-26, 190],
      [8, 64],
      [28, 127],
      [-3, 36],
    ],
    [
      [5861, 11880],
      [74, 19],
      [74, 53],
      [57, 71],
      [46, 27],
      [114, -35],
      [81, -10],
      [54, 9],
      [110, 62],
      [351, 216],
      [49, 41],
      [181, -19],
      [105, -4],
      [86, -18],
      [93, -42],
      [43, -45],
      [98, -48],
      [83, -84],
      [98, -72],
      [37, -46],
      [85, 22],
      [45, -3],
      [60, -35],
      [98, -87],
      [18, -50],
      [71, -37],
      [30, -1],
      [328, -110],
      [105, 6],
    ],
    [
      [3044, 10156],
      [46, 12],
      [132, -24],
      [84, -3],
      [44, 27],
      [2, 57],
      [43, -18],
      [100, 0],
      [36, 18],
      [53, -97],
      [139, -20],
      [26, -39],
      [96, -4],
      [25, 27],
      [45, -22],
      [105, -13],
      [78, 47],
      [48, -1],
      [98, -82],
      [6, -41],
      [-27, -52],
      [-9, -70],
      [13, -47],
      [122, 15],
      [65, -1],
      [57, -13],
      [77, -84],
      [86, -60],
      [25, -39],
      [8, -50],
      [-36, -75],
      [-99, -19],
      [-42, -58],
      [-20, -77],
    ],
    [
      [4470, 9350],
      [-46, -6],
      [-84, -98],
      [-107, -12],
      [-88, -37],
      [-31, -34],
      [-141, -57],
      [-83, 5],
      [-163, -12],
      [-31, 82],
      [-2, 33],
      [-59, 25],
      [4, 69],
      [40, 39],
      [-7, 42],
      [-51, 30],
      [-21, 48],
      [-10, 94],
      [-19, 54],
      [-58, 4],
      [-66, -31],
      [-4, -75],
      [-110, -74],
      [-8, 26],
      [75, 54],
      [1, 79],
      [-33, 23],
      [-68, 18],
      [-39, -1],
      [-6, -100],
      [-27, -32],
      [-46, 24],
      [-39, -30],
      [-35, 48],
      [-26, 91],
      [-50, 1],
      [-58, 23],
      [-26, -24],
      [-88, -44],
      [-87, -32],
      [5, -59],
      [-54, -35],
      [-2, -49],
      [59, -61],
      [75, -46],
      [50, 0],
      [92, -43],
      [96, -2],
      [21, -36],
      [60, -3],
      [30, -54],
      [24, -84],
      [-7, -48],
      [-68, -34],
      [5, -42],
      [25, -47],
      [49, -10],
      [33, -30],
      [-10, -49],
      [-59, -27],
      [-40, 11],
      [-25, -36],
      [-39, -6],
      [-51, -39],
      [-43, -1],
      [-63, 26],
      [-64, -3],
      [0, -41],
      [-24, -62],
      [37, -46],
      [-7, -120],
      [46, -64],
      [-17, -121],
      [19, -105],
      [-105, 2],
      [-60, 26],
      [-39, -29],
      [4, -55],
      [-48, -37],
      [2, -19],
      [56, -69],
      [-3, -41],
      [-28, -23],
      [-72, 18],
      [-75, 6],
      [-71, -24],
      [1, 39],
      [54, 109],
      [-10, 61],
      [-25, 26],
      [-68, 37],
      [-15, 146],
      [8, 66],
      [33, 89],
      [129, 56],
      [111, 90],
      [34, 175],
      [7, 61],
      [29, 78],
      [-13, 33],
      [-47, 46],
      [-70, 13],
      [-24, -34],
      [-53, -12],
      [-63, 18],
      [-64, -12],
      [-85, -37],
      [-146, 42],
      [-48, 31],
      [6, 58],
      [-43, 8],
      [-57, 61],
      [-62, -8],
      [-90, 2],
      [-142, -12],
      [-138, 50],
      [-130, 80],
      [-56, 20],
      [-38, -4],
    ],
    [
      [1412, 9328],
      [-94, -19],
      [-43, 74],
      [-55, 74],
      [-111, 97],
      [37, 186],
      [-12, 155],
      [-2, 166],
      [68, 49],
      [80, 39],
      [51, 59],
      [3, 48],
      [-58, 61],
      [-2, 104],
      [57, 54],
      [8, 35],
      [-52, 163],
      [9, 116],
      [22, 80],
      [3, 83],
      [-18, 220],
      [3, 58],
      [114, 102],
      [166, 136],
      [116, 90],
      [140, 222],
      [49, 65],
      [29, 59],
      [36, 187],
      [-1, 149],
    ],
    [
      [1955, 12240],
      [73, -15],
      [39, 21],
      [54, -10],
      [46, -41],
      [78, -22],
      [26, -55],
      [216, -45],
      [59, -48],
      [79, -9],
      [54, 92],
      [51, -14],
      [-22, -68],
      [-14, -93],
      [-49, -47],
      [-8, -25],
      [31, -45],
      [40, -21],
      [42, -78],
      [89, -49],
      [32, -75],
      [-12, -43],
      [-72, -75],
      [10, -67],
      [59, -73],
      [53, -41],
      [2, -29],
      [-37, -69],
      [-73, -64],
      [-15, -111],
      [-52, 17],
      [-13, 24],
      [-107, 42],
      [-15, -34],
      [-14, -85],
      [-64, -66],
      [-24, -150],
      [49, -124],
      [9, -50],
      [-27, -213],
      [-27, -50],
      [10, -80],
      [-54, -46],
      [-16, -34],
      [-75, -42],
      [-31, 5],
      [-17, 40],
      [-52, -7],
      [-17, -19],
      [50, -63],
      [113, -119],
      [47, -11],
      [101, 103],
      [53, -6],
      [33, 21],
      [73, -18],
      [66, 21],
      [46, 32],
      [16, 37],
      [60, -20],
      [35, 22],
      [36, -21],
      [66, 29],
    ],
    [
      [5776, 5698],
      [-19, -92],
      [-47, -123],
      [41, -49],
      [9, -55],
      [41, -79],
      [-26, -70],
      [5, -51],
      [-21, -65],
      [9, -60],
      [-34, -93],
      [18, -42],
      [38, 52],
      [78, 10],
      [269, 112],
      [187, 37],
      [150, 7],
      [104, -13],
      [125, -32],
    ],
    [
      [4465, 4432],
      [6, 68],
      [-60, 49],
      [-35, 45],
      [-25, 139],
      [-83, -27],
      [-55, -4],
      [-58, 27],
    ],
    [
      [3787, 5223],
      [52, 63],
      [4, 46],
      [-17, 20],
      [-81, -4],
      [-64, 45],
      [0, 66],
      [-29, 11],
      [-54, -23],
      [-5, 48],
      [-42, 42],
      [27, 47],
      [73, 86],
      [107, 83],
      [36, 48],
      [28, 3],
      [159, -101],
      [187, -47],
      [89, -8],
      [84, 2],
      [60, -21],
      [112, -21],
      [50, 7],
      [45, 45],
      [19, 169],
      [30, 56],
      [29, 91],
      [52, 94],
    ],
    [
      [4738, 6070],
      [79, 9],
      [47, -15],
      [75, -59],
      [53, -75],
      [36, -109],
      [37, -138],
      [68, -49],
      [32, -50],
      [32, -8],
      [38, -53],
      [26, -9],
      [56, -94],
      [57, -57],
      [39, -15],
      [18, 66],
      [47, 30],
      [15, 85],
      [47, 10],
      [40, 74],
      [-13, 77],
    ],
    [
      [3198, 5126],
      [-24, 45],
      [-89, -74],
      [-9, 39],
      [-72, 53],
      [-79, 14],
      [5, -31],
      [-52, -26],
      [-44, 20],
      [-87, -6],
      [-74, -28],
      [-38, 14],
      [-21, 30],
      [-70, -29],
    ],
    [
      [2544, 5147],
      [-85, 51],
      [-14, 34],
      [-2, 68],
      [-22, 70],
      [-75, 71],
      [-46, 29],
      [-26, 80],
      [-212, 450],
      [-25, 99],
      [-6, 71],
      [47, 75],
      [49, 55],
      [85, 56],
      [18, 52],
      [-24, 23],
      [-85, -41],
      [-57, 4],
      [-266, 88],
      [-53, 58],
      [-37, 97],
      [-31, 33],
      [-78, 21],
      [-50, -8],
      [-74, -49],
      [-22, -35],
      [-15, -101],
      [-31, -46],
      [-86, -44],
      [-175, -71],
      [-183, -117],
      [-142, -52],
      [-60, -30],
      [-98, 36],
      [-81, -80],
      [-119, -54],
      [-125, -134],
      [-159, -2],
      [-59, -67],
    ],
    [
      [120, 5837],
      [-10, 24],
      [-1, 79],
      [-16, 28],
      [15, 60],
      [-3, 49],
      [-23, 50],
      [44, 43],
      [-4, 80],
      [37, 134],
      [-11, 40],
      [1, 99],
      [132, -2],
      [45, 35],
      [57, 3],
      [43, -27],
      [40, 6],
      [95, 37],
      [9, 49],
      [35, 68],
      [1, 68],
      [50, 64],
      [-7, 114],
      [-29, 112],
      [36, 57],
      [44, 23],
      [83, 140],
      [19, 60],
      [66, 3],
      [47, 53],
      [-37, 83],
      [10, 58],
      [29, 29],
      [153, 55],
      [79, -4],
      [49, 49],
      [0, 27],
      [49, 51],
      [16, 94],
      [-2, 56],
      [20, 38],
      [46, 14],
      [15, 32],
      [7, 72],
    ],
    [
      [1349, 8040],
      [41, -49],
      [11, -73],
      [75, -57],
      [37, 32],
      [46, -33],
      [41, 5],
      [35, -47],
      [79, -33],
      [-20, -87],
      [16, -27],
      [75, -2],
      [26, -68],
      [23, 11],
      [60, -18],
      [16, 36],
      [77, -22],
      [24, -57],
      [149, -171],
      [91, -144],
      [49, -36],
      [76, -71],
      [77, -95],
      [145, -107],
      [10, -42],
      [30, -31],
      [131, -53],
      [23, -29],
      [-3, -93],
      [44, -60],
      [58, -2],
      [77, 45],
      [34, 6],
      [114, 49],
      [69, -27],
      [23, -34],
      [126, -76],
      [66, -98],
      [39, -22],
      [46, -5],
      [68, -31],
      [63, 6],
      [28, -19],
      [89, -19],
      [22, -29],
      [88, -23],
      [31, -43],
      [51, -30],
      [55, -57],
      [115, -21],
      [62, -90],
      [29, -16],
      [122, 7],
      [55, -6],
      [140, 22],
      [69, 3],
      [63, -13],
      [24, -42],
      [79, 16],
    ],
    [
      [2252, 2854],
      [-205, 24],
      [-314, 58],
      [-76, 8],
      [-144, 1],
      [-86, -13],
      [-98, -6],
      [-324, -7],
      [-17, -39],
      [-125, 14],
      [-145, 1],
      [-243, -8],
      [-231, -4],
      [-197, -26],
      [-1, 108],
    ],
    [
      [46, 2965],
      [56, -4],
      [73, 15],
      [45, -23],
      [36, 33],
      [40, 12],
      [30, 51],
      [158, -15],
      [134, -3],
      [66, 12],
      [33, 27],
      [-11, 60],
      [59, 58],
      [2, 60],
      [31, 52],
      [67, -31],
      [37, -2],
      [37, -45],
      [105, -1],
      [14, 64],
      [99, 3],
      [85, -10],
      [149, 1],
      [514, -5],
      [-14, -57],
      [-40, -29],
      [22, -71],
      [35, -23],
      [97, 63],
      [61, -16],
      [22, -32],
      [-23, -41],
      [-74, -40],
      [7, -32],
      [56, -18],
      [67, -37],
      [112, 7],
      [19, -17],
      [0, -77],
    ],
    [
      [8015, 6743],
      [14, -50],
      [109, -16],
      [22, -17],
      [-7, -82],
      [53, -47],
      [-2, -53],
      [70, -3],
      [99, -19],
      [57, -1],
      [97, 16],
      [23, -4],
      [19, -49],
      [2, -58],
      [-35, -30],
      [-30, -78],
      [-66, -16],
      [-33, -25],
      [-6, -49],
      [-59, -49],
      [-12, -42],
      [29, -64],
      [28, -25],
      [32, -76],
      [26, -9],
      [12, -55],
      [-16, -32],
      [59, -43],
      [40, 13],
      [14, -31],
      [56, -31],
      [0, -48],
      [42, -14],
      [56, 53],
      [50, -14],
      [70, 4],
      [73, 37],
      [79, -2],
      [152, -75],
      [51, -9],
      [42, 10],
      [81, -58],
    ],
    [
      [9306, 5602],
      [-13, -52],
      [-3, -81],
      [-84, 19],
      [-85, 2],
      [-125, 58],
      [-35, -12],
      [-28, -77],
      [-23, -27],
      [5, -37],
      [82, -47],
      [29, -34],
      [-34, -39],
      [8, -64],
      [30, -54],
      [-15, -22],
      [-78, -60],
      [-14, -22],
      [-209, -105],
    ],
    [
      [4470, 9350],
      [24, -37],
      [9, -52],
      [43, -59],
      [66, -42],
      [61, -103],
      [2, -81],
    ],
    [
      [1349, 8040],
      [-32, 7],
      [-69, 72],
      [-21, 60],
      [62, 140],
      [6, 59],
      [75, 42],
      [127, -71],
      [17, 49],
      [4, 59],
      [21, 71],
      [45, 78],
      [-12, 142],
      [-69, 56],
      [-59, 131],
      [31, 90],
      [-15, 129],
      [-57, 42],
      [9, 132],
    ],
    [
      [1900, 3893],
      [111, 50],
      [20, -116],
      [16, -49],
      [28, -3],
      [66, 56],
      [39, 3],
      [95, -43],
      [10, -87],
      [33, -19],
      [32, 29],
      [86, -14],
      [37, 42],
      [82, 1],
      [43, 10],
    ],
    [
      [2598, 3753],
      [-61, -48],
      [-10, -50],
      [1, -95],
      [-13, -66],
      [-95, -52],
      [-78, -57],
      [-39, -65],
      [-9, -107],
      [96, -37],
      [49, -134],
      [35, 31],
      [12, 65],
      [62, 81],
      [14, -30],
      [-10, -52],
      [38, -119],
      [-53, 11],
      [-10, -77],
      [-41, -30],
      [-62, -16],
      [129, -38],
      [30, -20],
      [-3, -42],
      [-27, -43],
      [-33, 8],
      [-72, 67],
      [-50, -21],
    ],
    [
      [2398, 2817],
      [-146, 37],
    ],
    [
      [46, 2965],
      [15, 38],
      [-18, 38],
      [57, 78],
      [-20, 80],
      [23, 46],
      [46, 51],
      [9, 75],
      [-77, 30],
      [-7, 30],
      [24, 70],
      [-6, 103],
      [-33, 30],
      [15, 90],
      [58, 40],
      [-24, 41],
      [12, 33],
      [-28, 45],
      [17, 51],
      [25, 10],
      [2, 60],
      [-31, 58],
      [-5, 225],
      [70, 5],
      [-3, 90],
      [-13, 29],
      [-47, 29],
      [-25, 55],
      [-4, 76],
      [18, 103],
      [-11, 57],
      [8, 41],
      [-10, 45],
      [-22, 11],
      [-28, 66],
    ],
    [
      [33, 4894],
      [44, 16],
      [56, 42],
      [12, 45],
      [31, 12],
      [52, -30],
      [53, -9],
      [17, -34],
      [-13, -50],
      [3, -72],
      [37, -19],
      [1, -59],
      [47, 28],
      [34, 59],
      [43, 21],
      [27, -59],
      [1, -45],
      [-42, -95],
      [8, -25],
      [-37, -78],
      [46, -74],
      [35, -8],
      [42, -41],
      [24, -111],
      [97, -82],
      [38, -11],
      [98, -9],
      [67, -67],
      [23, 1],
      [35, 65],
      [48, 52],
      [-4, 44],
      [17, 35],
      [68, 47],
      [27, -30],
      [-9, -30],
      [51, -75],
      [57, 62],
      [223, 10],
      [2, -79],
      [15, -2],
      [-2, -118],
      [60, -6],
      [1, -90],
      [41, -124],
      [-17, -25],
      [-73, -33],
      [5, -22],
      [278, 4],
      [36, 35],
      [108, 39],
      [56, -6],
    ],
    [
      [3099, 2211],
      [-43, 58],
      [-255, 242],
      [-139, 118],
      [-212, 161],
      [-52, 27],
    ],
    [
      [2598, 3753],
      [6, 67],
      [28, 45],
      [92, 66],
      [57, 84],
      [2, 133],
      [26, 9],
      [15, 40],
      [37, 18],
      [97, 12],
      [87, -12],
      [62, 10],
      [22, 49],
    ],
    [
      [1900, 3893],
      [-21, 18],
      [12, 74],
      [40, 68],
      [19, 9],
      [15, 99],
      [-2, 102],
      [8, 47],
      [33, 90],
      [-9, 33],
      [-41, 31],
      [-34, 53],
      [-71, 80],
      [-15, 47],
      [52, 34],
      [-9, 123],
      [27, 68],
      [75, -1],
      [85, 23],
      [-26, 59],
      [59, 31],
      [21, 45],
      [34, 7],
      [69, -49],
      [43, -71],
      [43, 13],
      [59, -7],
      [-5, 70],
      [67, 5],
      [100, 54],
      [3, 91],
      [13, 8],
    ],
    [
      [33, 4894],
      [-33, 30],
      [34, 49],
      [28, 138],
      [19, 60],
      [4, 89],
      [23, 38],
      [5, 62],
      [-51, 67],
      [-26, 84],
      [3, 50],
      [22, 42],
      [-10, 41],
      [46, 36],
      [21, 50],
      [2, 107],
    ],
    [
      [10504, 7130],
      [67, -62],
      [16, -75],
      [-25, -28],
      [0, -52],
      [25, -26],
      [2, -118],
      [41, -76],
      [10, -66],
      [24, -30],
      [3, -51],
      [-15, -86],
      [-40, -58],
      [-41, -9],
      [-137, -9],
      [-59, -26],
      [-106, -87],
      [-21, -32],
      [-102, -66],
      [-81, -81],
      [-11, -22],
      [-85, -81],
      [-87, -110],
      [-176, -155],
      [-39, -41],
      [-93, -62],
      [-87, -48],
      [-181, 29],
    ],
    [
      [6597, 259],
      [-21, -18],
      [-98, 1],
      [-106, -21],
      [-120, 6],
      [-17, -31],
      [-173, -54],
      [-79, 3],
      [-9, 29],
      [51, 66],
      [-74, 15],
      [-58, -28],
      [-54, -11],
      [-5, -44],
      [-28, -29],
      [-79, -5],
      [-66, 22],
      [-53, 5],
      [18, -52],
      [-118, -13],
      [-63, 3],
      [-100, -19],
      [-30, -27],
      [-50, 25],
    ],
    [
      [5520, 12101],
      [-68, -60],
      [-79, -45],
      [-101, -23],
      [-106, 24],
      [-163, 55],
      [-31, 27],
      [-88, 18],
      [-63, -3],
      [-14, -33],
      [2, -57],
      [-22, -84],
      [-75, 52],
      [-50, 12],
      [-67, -21],
      [-99, -52],
      [-2, -69],
      [19, -44],
      [-40, -46],
      [10, -94],
      [37, -111],
      [-33, -24],
      [-45, 24],
      [-86, 23],
      [-114, 86],
      [-33, 7],
      [-97, -22],
      [-48, -29],
      [-21, -50],
      [-4, -77],
      [57, -161],
      [32, -114],
      [-2, -50],
      [18, -63],
      [-8, -109],
      [-23, -15],
      [-21, -79],
      [-21, -10],
      [-49, 29],
      [-57, 0],
      [-25, -19],
      [-95, -3],
      [-88, -27],
      [-51, 1],
      [-224, 38],
      [-52, 31],
      [-57, 1],
      [-36, -30],
      [-124, -7],
      [-117, 31],
      [-42, -74],
      [10, -117],
      [-2, -178],
      [-20, -255],
      [-26, -57],
      [9, -81],
      [19, -11],
    ],
    [
      [1955, 12240],
      [0, 286],
      [100, 5],
      [125, 107],
      [131, 128],
      [93, 73],
      [151, 28],
      [60, 19],
      [144, 21],
      [91, 27],
      [97, 13],
      [45, -41],
      [112, -15],
      [140, 20],
      [91, -15],
      [107, 4],
      [65, 31],
      [52, 79],
      [32, 27],
      [98, 38],
      [86, 24],
      [63, -4],
      [73, -39],
      [145, -57],
      [67, -35],
      [320, -111],
      [74, -22],
      [75, 14],
      [160, -46],
      [102, 5],
      [56, -25],
      [140, -85],
      [58, -55],
      [133, -140],
      [39, -49],
      [93, -96],
      [147, -253],
    ],
    [
      [12294, 4227],
      [-42, -64],
      [-26, -114],
      [19, -135],
      [42, -88],
      [-39, -36],
      [-65, 12],
      [-74, -16],
      [-70, -88],
      [-50, -25],
      [-15, -79],
      [-47, -14],
      [-27, -71],
      [34, -25],
      [-12, -83],
      [16, -52],
      [-32, -13],
      [-28, -92],
      [-44, -65],
      [-26, -17],
      [-37, 17],
      [-49, -27],
      [-2, -80],
      [-92, -9],
      [-42, -21],
      [-54, 38],
      [-30, -14],
      [-92, 18],
      [-40, 32],
      [-44, -33],
      [-38, 2],
      [-2, 139],
      [7, 52],
      [-39, 44],
      [-60, 1],
      [-41, -39],
      [-66, 10],
      [-45, 22],
      [-6, 78],
      [-120, 87],
      [-10, 46],
      [34, 92],
      [-84, 48],
      [-15, -17],
      [-43, 48],
      [-26, 3],
      [-41, 37],
      [-31, 46],
      [-42, -3],
      [-39, 32],
      [7, 39],
      [-11, 58],
      [-38, -1],
      [-7, -145],
      [-28, -126],
      [-21, -28],
      [-52, -33],
      [-21, 16],
      [-154, -9],
      [-195, -20],
      [-51, 68],
      [11, 33],
      [-6, 71],
      [-33, 33],
      [-60, -67],
      [-104, -88],
      [-26, -35],
      [-57, -42],
      [-19, -36],
      [-96, -97],
      [-85, 11],
      [-47, 19],
      [-41, -124],
      [10, -37],
      [-60, -158],
      [-15, -67],
      [-55, 2],
      [-89, 24],
    ],
    [
      [9355, 11622],
      [46, 41],
      [150, 154],
      [51, 79],
      [3, 22],
      [81, 51],
      [127, 141],
      [195, 103],
      [84, 33],
      [108, 3],
      [236, 17],
      [158, 59],
      [49, 25],
      [132, 30],
      [171, -1],
      [189, 12],
      [415, 1],
      [143, -7],
      [46, -14],
      [48, 12],
      [92, -29],
      [81, -39],
      [139, -45],
      [229, -50],
      [64, -33],
      [171, -140],
      [56, -23],
      [71, -10],
      [51, 13],
      [20, 22],
      [41, -21],
      [32, 9],
      [31, -38],
      [152, -12],
    ],
    [
      [5520, 12101],
      [39, -63],
      [142, -169],
      [115, 1],
      [45, 10],
    ],
  ],
  transform: {
    scale: [0.0007471318363919137, 0.0007343258519734335],
    translate: [2.66853356300004, 4.274959098000068],
  },
  objects: {
    states: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5, 6]],
          type: "Polygon",
          properties: {
            name: "Abia",
            id: "NG001",
            nameAbbr: "Abia",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 4.695135,
            Shape_Area: 0.396543,
          },
        },
        {
          arcs: [[7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Adamawa",
            id: "NG002",
            nameAbbr: "Adamawa",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 11.525443,
            Shape_Area: 3.113007,
          },
        },
        {
          arcs: [[11, 12, 13, -3]],
          type: "Polygon",
          properties: {
            name: "Akwa Ibom",
            id: "NG003",
            nameAbbr: "Akwa Ibom",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 5.26383,
            Shape_Area: 0.549476,
          },
        },
        {
          arcs: [[-6, 14, 15, 16, 17, 18, 19]],
          type: "Polygon",
          properties: {
            name: "Anambra",
            id: "NG004",
            nameAbbr: "Anambra",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 3.59596,
            Shape_Area: 0.392661,
          },
        },
        {
          arcs: [[20, 21, 22, 23, 24, 25, 26]],
          type: "Polygon",
          properties: {
            name: "Bauchi",
            id: "NG005",
            nameAbbr: "Bauchi",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 13.952005,
            Shape_Area: 4.011018,
          },
        },
        {
          arcs: [[27, 28, 29]],
          type: "Polygon",
          properties: {
            name: "Bayelsa",
            id: "NG006",
            nameAbbr: "Bayelsa",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 5.046708,
            Shape_Area: 0.776768,
          },
        },
        {
          arcs: [[30, 31, 32, 33, 34, 35, 36]],
          type: "Polygon",
          properties: {
            name: "Benue",
            id: "NG007",
            nameAbbr: "Benue",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 9.40808,
            Shape_Area: 2.578363,
          },
        },
        {
          arcs: [[-11, 37, 38, 39]],
          type: "Polygon",
          properties: {
            name: "Borno",
            id: "NG008",
            nameAbbr: "Borno",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 13.714364,
            Shape_Area: 5.987849,
          },
        },
        {
          arcs: [[40, -12, -2, 41, -33]],
          type: "Polygon",
          properties: {
            name: "Cross River",
            id: "NG009",
            nameAbbr: "Cross River",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 8.779796,
            Shape_Area: 1.711218,
          },
        },
        {
          arcs: [[-17, 42, -29, 43, 44, 45]],
          type: "Polygon",
          properties: {
            name: "Delta",
            id: "NG010",
            nameAbbr: "Delta",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 7.372526,
            Shape_Area: 1.394082,
          },
        },
        {
          arcs: [[-42, -1, 46, -34]],
          type: "Polygon",
          properties: {
            name: "Ebonyi",
            id: "NG011",
            nameAbbr: "Ebonyi",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 4.489355,
            Shape_Area: 0.51805,
          },
        },
        {
          arcs: [[-18, -46, 47, 48]],
          type: "Polygon",
          properties: {
            name: "Edo",
            id: "NG012",
            nameAbbr: "Edo",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 7.889425,
            Shape_Area: 1.595809,
          },
        },
        {
          arcs: [[49, 50, 51, 52]],
          type: "Polygon",
          properties: {
            name: "Ekiti",
            id: "NG013",
            nameAbbr: "Ekiti",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 3.395633,
            Shape_Area: 0.471634,
          },
        },
        {
          arcs: [[-47, -7, -20, 53, -35]],
          type: "Polygon",
          properties: {
            name: "Enugu",
            id: "NG014",
            nameAbbr: "Enugu",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 4.319893,
            Shape_Area: 0.624323,
          },
        },
        {
          arcs: [[54, 55, 56, 57]],
          type: "Polygon",
          properties: {
            name: "FCT",
            id: "NG015",
            nameAbbr: "FCT",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 3.498412,
            Shape_Area: 0.607222,
          },
        },
        {
          arcs: [[-38, -10, 58, -21, 59]],
          type: "Polygon",
          properties: {
            name: "Gombe",
            id: "NG016",
            nameAbbr: "Gombe",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 5.955286,
            Shape_Area: 1.438747,
          },
        },
        {
          arcs: [[60, -15, -5]],
          type: "Polygon",
          properties: {
            name: "Imo",
            id: "NG017",
            nameAbbr: "Imo",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 2.878088,
            Shape_Area: 0.414097,
          },
        },
        {
          arcs: [[-26, 61, 62, 63, 64]],
          type: "Polygon",
          properties: {
            name: "Jigawa",
            id: "NG018",
            nameAbbr: "Jigawa",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 11.771497,
            Shape_Area: 1.928873,
          },
        },
        {
          arcs: [[-24, 65, 66, -58, 67, 68, 69, 70]],
          type: "Polygon",
          properties: {
            name: "Kaduna",
            id: "NG019",
            nameAbbr: "Kaduna",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 12.613678,
            Shape_Area: 3.645639,
          },
        },
        {
          arcs: [[-25, -71, 71, -62]],
          type: "Polygon",
          properties: {
            name: "Kano",
            id: "NG020",
            nameAbbr: "Kano",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 8.244195,
            Shape_Area: 1.685607,
          },
        },
        {
          arcs: [[-63, -72, -70, 72, 73]],
          type: "Polygon",
          properties: {
            name: "Katsina",
            id: "NG021",
            nameAbbr: "Katsina",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 9.105637,
            Shape_Area: 1.991947,
          },
        },
        {
          arcs: [[74, 75, 76, 77]],
          type: "Polygon",
          properties: {
            name: "Kebbi",
            id: "NG022",
            nameAbbr: "Kebbi",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 14.968981,
            Shape_Area: 3.035227,
          },
        },
        {
          arcs: [[-56, 78, -36, -54, -19, -49, 79, -53, 80, 81]],
          type: "Polygon",
          properties: {
            name: "Kogi",
            id: "NG023",
            nameAbbr: "Kogi",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 10.476383,
            Shape_Area: 2.368882,
          },
        },
        {
          arcs: [[-81, -52, 82, 83, 84, 85]],
          type: "Polygon",
          properties: {
            name: "Kwara",
            id: "NG024",
            nameAbbr: "Kwara",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 12.046963,
            Shape_Area: 2.766244,
          },
        },
        {
          arcs: [[86, 87]],
          type: "Polygon",
          properties: {
            name: "Lagos",
            id: "NG025",
            nameAbbr: "Lagos",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 4.28777,
            Shape_Area: 0.300166,
          },
        },
        {
          arcs: [[88, 89, -37, -79, -55, -67]],
          type: "Polygon",
          properties: {
            name: "Nasarawa",
            id: "NG026",
            nameAbbr: "Nasarawa",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 9.537188,
            Shape_Area: 2.197927,
          },
        },
        {
          arcs: [[90, -68, -57, -82, -86, 91, -76]],
          type: "Polygon",
          properties: {
            name: "Niger",
            id: "NG027",
            nameAbbr: "Niger",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 18.921559,
            Shape_Area: 5.930956,
          },
        },
        {
          arcs: [[92, 93, 94, -88, 95, 96]],
          type: "Polygon",
          properties: {
            name: "Ogun",
            id: "NG028",
            nameAbbr: "Ogun",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 9.649774,
            Shape_Area: 1.364021,
          },
        },
        {
          arcs: [[-48, -45, 97, -94, 98, -50, -80]],
          type: "Polygon",
          properties: {
            name: "Ondo",
            id: "NG029",
            nameAbbr: "Ondo",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 7.751109,
            Shape_Area: 1.233546,
          },
        },
        {
          arcs: [[-51, -99, -93, 99, -83]],
          type: "Polygon",
          properties: {
            name: "Osun",
            id: "NG030",
            nameAbbr: "Osun",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 4.66014,
            Shape_Area: 0.704549,
          },
        },
        {
          arcs: [[-100, -97, 100, -84]],
          type: "Polygon",
          properties: {
            name: "Oyo",
            id: "NG031",
            nameAbbr: "Oyo",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 8.624586,
            Shape_Area: 2.264918,
          },
        },
        {
          arcs: [[-23, 101, -89, -66]],
          type: "Polygon",
          properties: {
            name: "Plateau",
            id: "NG032",
            nameAbbr: "Plateau",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 8.575527,
            Shape_Area: 2.180996,
          },
        },
        {
          arcs: [[-61, -4, -14, 102, -30, -43, -16]],
          type: "Polygon",
          properties: {
            name: "Rivers",
            id: "NG033",
            nameAbbr: "Rivers",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 5.814545,
            Shape_Area: 0.830196,
          },
        },
        {
          arcs: [[103, -78, 104]],
          type: "Polygon",
          properties: {
            name: "Sokoto",
            id: "NG034",
            nameAbbr: "Sokoto",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 10.503159,
            Shape_Area: 2.679547,
          },
        },
        {
          arcs: [[-59, -9, 105, -31, -90, -102, -22]],
          type: "Polygon",
          properties: {
            name: "Taraba",
            id: "NG035",
            nameAbbr: "Taraba",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 13.696255,
            Shape_Area: 4.802474,
          },
        },
        {
          arcs: [[-39, -60, -27, -65, 106]],
          type: "Polygon",
          properties: {
            name: "Yobe",
            id: "NG036",
            nameAbbr: "Yobe",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 11.096937,
            Shape_Area: 3.726688,
          },
        },
        {
          arcs: [[-73, -69, -91, -75, -104, 107]],
          type: "Polygon",
          properties: {
            name: "Zamfara",
            id: "NG037",
            nameAbbr: "Zamfara",
            parent: "Nigeria",
            parentAbbr: "NG",
            Shape_Leng: 9.466144,
            Shape_Area: 2.782855,
          },
        },
      ],
    },
  },
};

const lagos = {
  type: "Topology",
  arcs: [
    [
      [7243, 4797],
      [27, -134],
      [-9, -72],
      [-79, -19],
      [16, -132],
      [-34, 31],
      [-10, 15],
      [-6, 4],
      [-7, 2],
      [-14, 0],
      [-30, 0],
      [-15, -6],
      [-18, -15],
      [-10, -8],
      [-15, -14],
      [-22, -21],
    ],
    [
      [7017, 4428],
      [-38, 31],
      [-36, 43],
      [-33, 52],
      [-5, 8],
      [-7, 12],
      [-2, 19],
      [-4, 20],
      [-12, 35],
      [-1, 13],
      [-23, 43],
      [-30, 70],
      [9, 54],
      [24, 103],
      [1, 14],
      [1, 21],
      [-1, 12],
      [12, 25],
      [7, 10],
      [42, 171],
      [0, 52],
    ],
    [
      [6921, 5236],
      [87, 0],
      [18, -33],
      [71, -303],
      [-2, -15],
      [18, -24],
      [13, -15],
      [14, -8],
      [14, -2],
      [7, 8],
      [16, 2],
      [10, -14],
      [11, -8],
      [14, -7],
      [11, -4],
      [14, -8],
      [6, -8],
    ],
    [
      [7284, 1240],
      [-37, 48],
      [-68, 22],
      [-74, 23],
      [-31, 62],
      [-16, 89],
      [-15, 45],
      [-14, 58],
      [-2, 82],
    ],
    [
      [7027, 1669],
      [54, 27],
      [34, 39],
      [7, 39],
      [6, 15],
      [10, 4],
      [21, 6],
      [10, 10],
      [4, 15],
      [14, 20],
      [9, 4],
      [6, 4],
      [1, 11],
      [14, 12],
      [9, 2],
      [9, -2],
      [9, 10],
      [51, 56],
      [21, 29],
    ],
    [
      [7316, 1970],
      [32, -70],
      [15, -17],
      [15, 2],
      [5, 7],
      [7, 8],
      [6, 4],
      [5, 6],
      [10, -2],
      [-6, -14],
      [4, -15],
      [2, -16],
      [3, -11],
      [-5, -18],
      [-1, -8],
      [1, -9],
      [8, -16],
      [6, -6],
      [10, 4],
      [8, 8],
      [5, -4],
      [4, -6],
      [2, -13],
      [0, -18],
      [0, -8],
      [5, -9],
      [8, -6],
      [6, -4],
      [20, -4],
      [3, -105],
      [-1, -39],
      [-1, -25],
      [-53, -29],
      [-1, -84],
      [-21, -77],
      [-8, -154],
      [-54, -8],
      [-43, 20],
      [-28, 6],
    ],
    [
      [7017, 4428],
      [8, -6],
      [42, -146],
      [-9, -60],
      [-19, -89],
      [-45, -729],
    ],
    [
      [6994, 3398],
      [-24, -2],
      [0, 68],
      [-47, 74],
      [-28, 88],
      [-34, 0],
      [-11, -4],
      [-7, -10],
      [-7, -21],
      [-19, -24],
      [-25, -25],
      [-33, 0],
      [-62, -33],
      [-7, -41],
      [-12, -136],
      [30, -231],
      [-4, -66],
      [-5, -95],
      [-18, -41],
      [-26, -99],
      [23, -138],
      [24, -93],
      [19, -24],
      [18, -46],
      [-1, -47],
      [-3, -89],
    ],
    [
      [6735, 2363],
      [-29, -28],
      [-44, -145],
      [-30, -18],
      [-74, -15],
      [-85, -39],
      [-37, -18],
      [-30, -25],
      [-105, -37],
      [-28, -2],
    ],
    [
      [6273, 2036],
      [4, 64],
      [-119, 99],
      [-240, 92],
      [-15, 31],
      [-33, 190],
      [-45, 175],
      [-51, 86],
      [-9, 52],
      [-14, 82],
      [-10, 95],
    ],
    [
      [5741, 3002],
      [11, 72],
      [2, 54],
      [1, 66],
      [-4, 53],
      [-10, 42],
      [0, 49],
      [-26, 95],
      [-2, 62],
      [-8, 70],
      [-40, 136],
      [-11, 80],
      [-3, 52],
      [9, 55],
      [28, 78],
      [92, 153],
      [99, 126],
      [157, 113],
      [40, 91],
      [23, 76],
      [25, 80],
      [29, 37],
      [6, 39],
      [7, 95],
      [4, 41],
      [0, 62],
      [-25, 41],
      [0, 62],
      [-5, 114],
      [5, 41],
      [8, 70],
      [5, 43],
      [10, 113],
      [5, 108],
      [30, 84],
      [25, 62],
      [19, 47],
      [14, 39],
      [29, 83],
      [28, 99],
      [57, 160],
    ],
    [
      [6375, 6045],
      [13, -18],
      [19, -33],
      [4, -8],
      [5, -15],
      [64, -97],
      [12, -18],
      [4, -83],
      [12, -22],
      [29, -83],
      [22, -35],
      [13, -12],
      [38, -47],
      [16, -23],
      [13, -8],
      [36, -6],
      [38, -11],
      [21, -8],
      [33, -93],
      [23, -49],
      [131, -140],
    ],
    [
      [7284, 1240],
      [-51, -8],
      [-35, -33],
      [12, -78],
      [39, -31],
      [37, -14],
      [45, -9],
      [34, 13],
      [37, 31],
      [29, 6],
      [30, -19],
      [37, 15],
      [61, -39],
      [39, -33],
      [28, -7],
      [38, 2],
      [15, 27],
      [13, 35],
      [-1, 41],
      [17, 54],
      [37, -12],
      [-12, -95],
      [-15, -50],
      [7, -61],
      [30, -40],
      [10, -41],
      [25, 50],
      [3, 57],
      [-6, 44],
      [-14, 53],
      [30, 39],
      [44, 42],
      [47, -19],
      [43, -4],
      [45, 47],
    ],
    [
      [7982, 1203],
      [34, -132],
      [-56, -393],
      [50, -113],
    ],
    [
      [8010, 565],
      [31, -87],
      [5, -56],
      [10, -55],
      [-78, 2],
      [-42, 20],
      [-151, 19],
      [-144, 43],
      [-362, 68],
      [-291, 35],
      [-533, 15],
      [-353, 0],
      [-350, 0],
      [-32, -2],
    ],
    [
      [5720, 567],
      [-1, 43],
      [0, 89],
      [12, 63],
      [-8, 62],
      [-21, 39],
      [-16, 62],
      [22, 116],
      [-11, 53],
      [5, 58],
      [43, 39],
      [34, 0],
      [63, 0],
      [90, 49],
      [-32, 70],
      [-12, 31],
      [30, -8],
      [79, -4],
      [23, 19],
      [17, 49],
      [-8, 54],
      [-13, 74],
      [9, 12],
      [25, 80],
      [-5, 7],
      [-12, 10],
      [-5, 4],
      [-21, 0],
      [-2, 17],
      [1, 16],
      [4, 8],
      [10, 2],
      [41, -10],
      [77, -37],
      [2, 280],
      [41, 89],
      [92, 33],
    ],
    [
      [6735, 2363],
      [4, -267],
      [89, 41],
      [15, 53],
      [41, 9],
      [33, -23],
      [96, 39],
      [31, 62],
      [15, 80],
      [14, 46],
      [33, 61],
    ],
    [
      [7106, 2464],
      [-10, -111],
      [23, -105],
      [28, -91],
      [18, -66],
      [-2, -66],
      [-16, -111],
      [-90, -158],
      [-30, -87],
    ],
    [
      [7584, 1990],
      [-16, -84],
      [8, -60],
      [6, 0],
      [9, 0],
      [9, -4],
      [2, -10],
      [34, 2],
      [55, 20],
      [31, -10],
      [24, -33],
      [21, -62],
    ],
    [
      [7767, 1749],
      [-26, -35],
      [-25, -31],
      [-17, -57],
      [10, -44],
      [10, -41],
      [34, -57],
      [66, -60],
      [86, -79],
      [65, -96],
      [12, -46],
    ],
    [
      [7316, 1970],
      [24, 20],
      [13, 15],
      [4, 10],
      [1, 10],
      [4, 9],
      [6, -4],
      [6, -5],
      [50, 15],
      [77, 0],
      [28, -25],
      [32, -14],
      [23, -11],
    ],
    [
      [4596, 2435],
      [32, -100],
      [60, -48],
      [0, -72],
      [-1, -43],
      [15, -31],
      [25, -31],
      [14, -37],
      [-3, -83],
      [-6, -41],
      [-6, -39],
      [-22, -27],
      [-30, -27],
      [-44, -4],
      [-53, -61],
      [-59, 12],
      [-40, 18],
      [0, -45],
      [-1, -80],
      [12, -43],
      [14, -46],
      [20, -31],
      [21, -66],
      [16, -49],
      [23, -74],
      [22, -66],
      [23, -50],
      [5, -61],
      [-13, -58],
      [10, -58],
      [26, -66],
      [-8, -95],
      [-11, -78],
      [-9, -68],
      [2, -62],
      [-48, -12],
      [-56, -14],
      [-40, 6],
      [-36, 20],
      [-26, 19],
      [-28, -8],
      [-14, -9],
      [-92, 21],
      [-86, -21],
      [-154, -68],
      [-76, -90],
      [-29, -101],
    ],
    [
      [3945, 468],
      [-52, -2],
      [-220, -15],
      [-204, 15],
      [-129, 6],
      [-274, 6],
      [-217, -12],
      [-321, -35],
      [-252, 0],
      [-263, -21],
      [-316, -14],
      [-167, -56],
      [-270, -27],
      [-262, -76],
      [-204, -21],
      [-186, -41],
      [-229, -74],
      [-246, -49],
      [-113, -19],
      [2, 58],
      [-7, 74],
      [2, 66],
      [1, 55],
      [1, 66],
      [-5, 66],
      [-5, 54],
      [2, 43],
      [-3, 54],
      [2, 45],
      [1, 56],
      [-3, 53],
      [-8, 85],
      [3, 45],
      [2, 45],
      [4, 44],
      [-1, 88],
      [-4, 41],
      [2, 56],
      [-5, 54],
      [3, 55],
      [2, 46],
      [2, 53],
      [-4, 43],
      [0, 77],
      [1, 88],
      [87, 70],
      [41, 56],
      [66, 8],
      [38, -14],
      [113, -15],
      [30, 0],
      [49, -66],
      [55, -101],
      [39, 5],
      [54, 35],
      [70, 16],
      [56, -6],
      [59, -6],
      [57, 20],
      [25, 0],
      [37, -8],
      [57, 15],
      [26, 41],
      [40, 45],
      [49, 39],
      [57, 13],
      [81, -25],
      [78, -54],
      [60, -26],
      [61, -58],
      [55, -89],
      [35, -37],
      [17, -33],
      [105, 17],
      [118, 113],
      [16, 33],
      [10, 35],
      [13, 80],
      [21, 44],
      [21, 144],
      [79, 70],
      [25, 6],
      [58, -21],
      [71, -14],
      [62, 70],
      [46, 47],
      [42, 77],
      [25, 88],
      [17, 56],
      [0, 76],
      [-5, 62],
      [-21, 76],
      [-25, 56],
      [-26, 76],
      [-14, 43],
      [-21, 50],
      [128, 4],
      [44, -4],
      [42, 25],
      [74, 37],
      [107, -33],
      [22, -29],
      [42, -10],
      [86, 30],
      [67, 3],
      [93, -21],
      [81, 14],
      [164, -12],
      [178, -8],
      [75, -46],
      [203, -16],
      [157, -50],
      [9, -14],
      [66, -16],
      [184, -11],
      [207, 25],
      [106, -4],
      [97, -2],
      [110, -21],
      [35, -20],
      [13, 4],
      [55, -19],
      [10, -23],
      [27, 0],
    ],
    [
      [13896, 5866],
      [28, -2],
      [319, -8],
      [365, 43],
      [465, -47],
      [348, 2],
      [263, -15],
      [62, -2],
      [148, -4],
      [-2, -39],
      [13, -33],
      [4, -49],
      [17, -35],
      [-27, -54],
      [-18, -35],
      [13, -70],
      [5, -37],
      [16, -31],
      [-9, -43],
      [31, -93],
      [-43, 21],
      [-11, -35],
      [-10, -48],
      [-19, -66],
      [-29, -37],
      [-12, -58],
      [-33, -41],
      [-29, -4],
      [-26, -6],
      [-24, -27],
      [-47, -47],
      [-25, 0],
      [-19, 33],
      [-27, 22],
      [-31, 13],
      [-32, -15],
      [-11, -39],
      [9, -76],
      [-11, -45],
      [-13, -54],
      [-30, -70],
      [-39, -35],
      [10, -58],
      [29, -33],
      [30, -62],
      [-6, -41],
      [0, -62],
      [-2, -39],
      [20, -76],
      [0, -58],
      [19, -41],
      [21, -111],
      [12, -43],
      [13, -66],
      [19, -39],
      [14, -93],
      [1, -78],
      [9, -38],
      [-18, -53],
      [-6, -83],
      [38, 23],
      [40, -23],
      [30, -2],
      [22, -12],
      [46, -23],
      [31, -24],
      [39, 6],
      [9, 27],
      [15, 33],
      [25, -15],
      [5, -64],
      [-17, -47],
      [27, -12],
      [38, -87],
      [-20, -27],
      [10, -47],
      [83, 12],
      [89, 19],
      [0, 72],
      [13, 64],
      [0, 49],
      [67, 97],
      [40, 31],
      [26, 25],
      [28, 43],
      [47, 45],
      [19, 21],
      [16, 20],
      [20, 19],
      [63, 47],
      [32, 23],
      [33, 17],
      [70, 49],
      [53, 103],
      [48, 72],
      [28, 31],
      [47, 23],
      [32, -21],
      [28, -14],
      [38, -35],
      [39, -21],
      [15, -41],
      [82, -31],
      [84, 12],
      [53, 6],
      [9, 52],
      [22, -31],
      [33, -27],
      [36, -29],
      [48, -41],
      [11, -60],
      [30, -16],
      [25, -101],
      [6, -51],
      [24, -42],
      [22, -39],
      [13, -66],
      [25, -14],
      [40, -68],
      [-27, -76],
      [13, -38],
      [-33, -160],
      [-29, -103],
      [-89, -109],
      [-37, -73],
      [-57, -53],
      [-32, -21],
      [-26, -16],
      [-32, -19],
      [-49, -47],
      [-25, -17],
      [-78, -31],
      [-44, -22],
      [-32, -19],
      [-23, -49],
      [-17, -37],
      [-10, -37],
      [-73, -95],
      [-24, -17],
      [-56, -39],
      [-51, -37],
      [-13, -39],
      [23, -85],
      [15, -45],
      [12, -49],
      [8, -103],
      [10, -93],
      [1, -41],
      [43, 0],
      [40, -9],
      [42, -12],
      [31, -29],
      [32, -37],
      [62, -31],
      [36, -27],
      [41, -10],
      [29, -18],
      [18, -44],
      [77, 0],
      [34, -24],
      [26, 20],
      [24, 23],
      [64, 4],
      [20, -21],
      [24, -6],
      [17, -62],
      [14, -80],
      [22, -95],
      [21, -35],
      [23, -57],
      [26, -17],
      [30, 14],
      [31, 0],
      [27, -14],
      [19, -54],
      [-5, -70],
      [74, -39],
      [22, -31],
      [53, -14],
      [37, 10],
      [9, 37],
      [-7, 54],
      [11, 43],
      [55, 21],
      [53, 20],
      [22, -12],
      [31, -8],
      [46, 6],
      [30, -17],
      [14, -43],
      [31, -33],
      [27, 17],
      [31, -37],
      [40, -15],
      [41, 11],
      [51, -38],
      [36, 11],
      [37, 68],
      [23, 14],
      [60, 0],
      [84, -29],
      [86, -4],
      [99, 33],
      [51, 0],
      [70, -70],
      [15, -37],
      [34, -47],
      [28, -29],
      [9, -41],
      [0, -114],
      [0, -119],
      [0, -484],
      [0, -235],
      [0, -120],
      [-48, -4],
      [-194, 37],
      [-318, 62],
      [-284, 41],
      [-324, 58],
      [-88, 18],
    ],
    [
      [17605, 216],
      [-299, 423],
      [-39, 107],
      [-77, 175],
      [-108, 78],
      [-98, -26],
      [-67, -21],
      [-178, -19],
      [-77, 33],
      [-79, 169],
      [-79, 44],
      [-49, -11],
      [-150, -51],
      [-128, -105],
      [-72, 16],
      [-26, 4],
      [-36, 25],
      [-22, 14],
      [-21, 17],
      [-21, 16],
      [-55, 85],
      [-50, 33],
      [-28, 14],
      [-60, -20],
      [-62, 0],
      [-54, -27],
      [-23, 39],
      [-15, 29],
      [-32, 47],
      [-83, 120],
      [-24, 49],
      [-35, 89],
      [-19, 62],
      [-18, 49],
      [-60, 72],
      [-40, 23],
      [-43, 10],
      [-40, 13],
      [-44, 43],
      [-43, 43],
      [-28, 4],
      [-77, 35],
      [-23, 37],
      [-18, 52],
      [-11, 62],
      [-40, 66],
      [2, 66],
      [-11, 41],
      [-56, 31],
      [-33, 2],
      [-42, -2],
      [0, -1243],
    ],
    [
      [14814, 1028],
      [-124, 35],
      [-262, 76],
      [-42, 7],
    ],
    [
      [14386, 1146],
      [3, 1285],
      [-60, -10],
      [-47, -2],
      [-41, 12],
      [-23, 27],
      [-36, 33],
      [-55, 8],
      [-33, 19],
      [-124, 181],
      [-583, 804],
      [-40, 33],
      [-95, 64],
      [-44, 20],
      [-93, 19],
      [-193, -52],
      [-153, -76],
      [-250, -165],
      [-374, -253],
      [-1143, -806],
    ],
    [
      [11002, 2287],
      [-49, 4],
      [-43, -2],
      [-36, -16],
      [-55, -48],
      [-18, -45],
      [-23, -70],
      [-25, -52],
      [-27, -6],
      [-33, 23],
      [-31, 27],
      [-60, 47],
      [-29, 12],
      [-47, 19],
      [-25, 21],
      [-7, 43],
      [6, 43],
      [33, 29],
      [46, 23],
      [22, 31],
      [0, 61],
      [-8, 48],
      [-27, 125],
      [-3, 52],
      [24, -8],
      [-5, 64],
      [27, 72],
    ],
    [
      [10609, 2784],
      [8, 47],
      [16, 58],
      [9, 47],
      [25, 93],
      [17, 49],
      [14, 68],
      [9, 48],
      [10, 35],
      [13, 80],
      [18, 37],
      [10, 50],
    ],
    [
      [10758, 3396],
      [38, 82],
      [18, 27],
      [10, 37],
      [23, 37],
      [26, 19],
      [37, 31],
      [37, 32],
      [58, 25],
      [49, 56],
      [22, 51],
      [29, 50],
      [29, 51],
      [28, 62],
      [23, 39],
      [25, 29],
      [30, 41],
      [31, 29],
      [24, 29],
      [23, 39],
      [24, 29],
      [18, 25],
      [18, 27],
      [23, 39],
      [23, 49],
      [23, 50],
      [22, 49],
      [-28, 74],
      [-8, 70],
      [-11, 35],
      [-3, 77],
      [-26, 82],
      [-16, 60],
      [-30, 8],
      [-20, 16],
      [-20, 42],
      [-18, 43],
      [-31, 23],
      [-16, 28],
      [-26, 64],
      [-4, 44],
      [7, 72],
      [23, 72],
      [17, 47],
      [29, 72],
      [15, 35],
      [-4, 50],
      [5, 39],
      [6, 39],
      [0, 56],
      [11, 47],
      [48, 105],
      [18, 54],
      [14, 45],
      [14, 68],
    ],
    [
      [11415, 5897],
      [91, 12],
      [1229, -16],
      [1161, -27],
    ],
    [
      [11002, 2287],
      [37, -29],
      [29, -39],
      [13, -58],
      [9, -115],
      [18, -62],
      [-484, -1005],
    ],
    [
      [10624, 979],
      [-147, -4],
      [-495, -21],
      [-400, 6],
      [-353, -41],
      [-241, 14],
      [-233, 0],
      [-237, -14],
      [-315, -14],
      [-72, -27],
      [-87, -97],
      [-5, -52],
      [-2, -55],
      [-10, -35],
      [-17, -74],
    ],
    [
      [7982, 1203],
      [23, 33],
      [28, 48],
      [32, 94],
      [13, 66],
      [11, 85],
      [-8, 37],
      [-15, 68],
      [-10, 64],
      [0, 74],
    ],
    [
      [8056, 1772],
      [54, -2],
      [107, 70],
      [104, 21],
      [178, 8],
      [135, -13],
      [75, -43],
      [87, -37],
      [335, -51],
      [139, 107],
      [534, 175],
      [149, 276],
      [-31, 214],
      [10, 190],
      [218, 255],
      [303, -129],
      [156, -29],
    ],
    [
      [14386, 1146],
      [-173, 28],
      [-309, 33],
      [-124, 42],
      [-188, 8],
      [-211, -8],
      [-192, 14],
      [-224, 6],
      [-163, 0],
      [-125, 0],
      [-129, -6],
      [-171, -41],
      [-206, -35],
      [-63, -41],
      [-138, -27],
      [-158, -41],
      [-103, -7],
      [-296, -28],
      [-237, -56],
      [-200, 8],
      [-225, -14],
      [-127, -2],
    ],
    [
      [17605, 216],
      [-496, 112],
      [-536, 169],
      [-505, 156],
      [-349, 122],
      [-284, 84],
      [-141, 33],
      [-37, 0],
      [-151, 48],
      [-219, 70],
      [-73, 18],
    ],
    [
      [6701, 5986],
      [7, -9],
      [23, -14],
      [63, -49],
      [26, -15],
      [48, -29],
      [101, -64],
      [38, -39],
      [106, -31],
      [56, -8],
      [70, 4],
      [89, 2],
      [25, -18],
      [32, -68],
      [57, -198],
    ],
    [
      [7442, 5450],
      [-15, -17],
      [-37, -51],
      [-45, -62],
      [-22, -29],
      [16, -78],
      [-3, -14],
      [-2, -13],
      [1, -18],
      [0, -21],
      [0, -12],
      [-1, -11],
      [1, -8],
      [4, -10],
      [1, -23],
      [-3, -12],
      [-3, -27],
      [2, -14],
      [-2, -17],
      [-9, -25],
      [-9, -51],
      [-11, -21],
      [-10, -10],
      [-19, -8],
      [-5, -4],
      [-3, -9],
      [0, -10],
      [0, -10],
      [-2, -46],
      [-10, -8],
      [-13, -14],
    ],
    [
      [6375, 6045],
      [5, 15],
      [14, 41],
      [17, 54],
      [0, 8],
      [-7, 29],
      [6, 8],
      [24, -2],
      [38, -19],
      [45, -12],
      [27, -12],
      [94, -99],
      [63, -70],
    ],
    [
      [7641, 5118],
      [3, -22],
      [14, -29],
      [-54, -124],
      [-13, -27],
      [-2, -18],
      [0, -23],
      [-5, -45],
      [-4, -56],
      [13, -105],
      [46, -132],
      [44, -93],
      [47, -105],
      [36, -72],
      [22, -45],
      [-82, -194],
      [-39, -113],
      [-19, -54],
      [-16, -66],
      [-20, -72],
      [2, -35],
      [4, -86],
      [4, -74],
    ],
    [
      [7622, 3528],
      [-8, 0],
    ],
    [
      [7614, 3528],
      [-74, 2],
      [-67, -27],
      [-32, -21],
    ],
    [
      [7441, 3482],
      [-147, 614],
      [-29, -18],
      [-36, -35],
      [10, -97],
      [-2, -151],
      [-67, -115],
      [-39, -6],
      [-39, -8],
      [0, -103],
      [18, -77],
      [-21, -14],
      [-40, -60],
      [-55, -14],
    ],
    [
      [7442, 5450],
      [3, -8],
      [46, -68],
      [33, -29],
      [66, -80],
      [43, -114],
      [8, -33],
    ],
    [
      [10758, 3396],
      [-54, -17],
      [-28, 15],
      [-33, 2],
      [-36, 22],
      [-16, 42],
      [-23, 37],
      [-22, 16],
      [-49, 10],
      [-26, 2],
      [-65, -16],
      [-67, -6],
      [-34, 14],
      [-27, 2],
      [-36, 25],
      [-39, 0],
      [-42, 12],
      [-31, -31],
      [-40, -76],
      [-10, -35],
      [-36, -64],
      [-23, -39],
      [-23, -39],
      [-34, -74],
      [-23, -39],
      [-47, -66],
      [-42, -56],
      [-53, -12],
      [-26, 4],
      [-43, 21],
      [-43, 32],
      [-25, -6],
      [-25, -29],
      [-30, -41],
      [-26, -16],
      [-18, -27],
      [-24, -17],
      [-52, -24],
      [-26, -6],
      [-33, -9],
      [-41, 11],
      [-42, 33],
      [-29, 24],
      [-50, 31],
      [-64, 39],
      [-32, -8],
      [-33, -8],
      [-33, 2],
      [-33, 2],
      [-43, 23],
      [-29, 24],
      [-22, 17],
      [-28, 25],
      [-61, 82],
      [-22, 17],
      [-19, 45],
      [-47, 58],
      [-41, 80],
      [12, 206],
      [18, 140],
      [47, 138],
      [60, 153],
      [18, 131],
      [-46, 114],
      [-30, -31],
      [-58, -17],
      [15, -105],
      [-31, -39],
      [-32, -31],
    ],
    [
      [8732, 4063],
      [-58, 165],
      [-11, 52],
      [-21, 45],
      [-42, 8],
      [-17, 35],
      [-69, 109],
      [-39, 0],
      [-8, 40],
      [-27, 26],
      [0, 83],
      [-52, 101],
      [-5, 216],
      [-3, 136],
    ],
    [
      [8380, 5079],
      [156, 27],
      [107, -21],
      [-14, 46],
      [-24, 49],
      [-21, 146],
      [-34, 107],
      [-58, 134],
      [-26, 44],
      [0, 55],
      [5, 27],
      [37, 35],
      [13, -4],
      [34, 41],
      [11, 6],
      [29, -88],
      [25, -50],
      [102, 11],
      [-4, 53],
      [13, 37],
      [29, 105],
      [-48, 89],
      [-52, 64],
      [846, 45],
      [600, -130],
      [6, -8],
      [119, -2],
      [1184, 0],
    ],
    [
      [8732, 4063],
      [-63, -16],
      [-32, -19],
      [-32, -10],
      [-37, -41],
      [-10, -35],
      [-25, -29],
      [-16, -37],
      [-25, -29],
      [-25, -93],
      [-14, -70],
      [-3, -45],
      [-10, -35],
      [-10, -46],
      [-10, -37],
      [-68, -61],
      [-37, 35],
      [-28, 14],
      [-22, 27],
      [-24, 25],
      [-27, 28],
      [-34, 2],
      [-27, 5],
      [-38, -21],
      [-22, -39],
      [14, -105],
      [15, -29],
      [33, -68],
      [11, -41],
      [7, -97],
      [0, -76],
      [-2, -46],
      [-9, -45],
      [-31, -41],
      [-46, -46],
    ],
    [
      [8085, 2942],
      [-48, 110],
      [-35, 74],
      [-46, 251],
      [-16, 4],
      [-13, 8],
      [-14, 0],
      [-34, -24],
      [-76, -31],
      [-53, 27],
      [-123, 167],
      [-5, 0],
    ],
    [
      [7641, 5118],
      [118, -6],
      [35, -18],
      [32, -19],
      [13, -6],
      [541, 10],
    ],
    [
      [7767, 1749],
      [71, 68],
      [78, 27],
    ],
    [
      [7916, 1844],
      [99, -68],
      [41, -4],
    ],
    [
      [8046, 2885],
      [-13, -66],
      [17, -39],
      [16, -40],
      [22, -92],
      [10, -41],
      [0, -77],
      [-34, -64],
      [-54, -80],
      [-36, -54],
      [-23, -39],
      [2, -97],
      [5, -53],
      [-16, -60],
      [-14, -68],
      [-2, -45],
      [4, -54],
      [-14, -72],
    ],
    [
      [7584, 1990],
      [-3, 62],
      [-4, 66],
      [-6, 54],
      [6, 45],
      [16, 33],
      [25, 33],
      [23, 23],
      [12, 26],
      [2, 35],
      [-9, 38],
      [-19, 68],
      [4, 61],
      [23, 50],
      [9, 47],
      [-42, 165],
    ],
    [
      [7621, 2796],
      [-3, 76],
      [4, 15],
    ],
    [
      [7622, 2887],
      [5, 0],
      [18, -9],
      [15, -4],
      [12, -2],
      [13, 2],
      [11, 11],
      [6, 8],
      [9, 14],
      [9, 19],
      [5, 8],
      [8, 2],
      [8, -4],
      [14, -12],
      [9, -19],
      [6, -12],
      [8, -11],
      [88, 17],
      [104, 0],
      [76, -10],
    ],
    [
      [7614, 3528],
      [0, -97],
      [2, -79],
      [1, -74],
      [0, -82],
      [1, -85],
      [0, -76],
      [0, -76],
      [4, -72],
    ],
    [
      [7621, 2796],
      [-21, -39],
      [-11, 56],
      [-24, 0],
      [-7, -15],
      [-4, -16],
      [0, -25],
      [-7, -39],
      [-12, -21],
      [-6, 0],
      [-12, 2],
      [-14, 4],
      [-5, 4],
      [-10, 11],
      [-6, 2],
      [-9, -2],
      [-8, -4],
      [-11, 2],
      [-6, 2],
      [-9, 6],
      [-10, 2],
      [-6, 0],
      [-9, -4],
      [-8, -8],
      [-5, -17],
      [-1, -10],
      [-6, -17],
      [-20, -16],
      [-10, 6],
      [-27, -2],
      [-28, -4],
      [-30, -6],
      [-13, -11],
      [-26, -41],
      [-6, -35],
      [-6, -16],
      [-21, -27],
      [-5, -8],
      [-16, -25],
      [-23, -43],
      [-8, 8],
      [-9, -6],
      [-6, -6],
      [-9, 0],
      [-5, 8],
      [-7, 4],
      [-4, 8],
      [-2, 10],
      [-4, 13],
    ],
    [
      [7109, 2481],
      [9, 55],
      [8, 52],
      [33, 54],
      [39, 43],
      [37, 37],
      [21, 70],
      [19, 511],
      [14, 54],
      [32, 43],
      [120, 82],
    ],
    [
      [5720, 567],
      [-19, -2],
      [-227, -11],
      [-179, -6],
      [-163, -21],
      [-245, -8],
      [-179, 8],
      [-212, -28],
      [-209, -7],
      [-47, 48],
      [-44, -52],
      [-74, -10],
      [-177, -10],
    ],
    [
      [4596, 2435],
      [60, 25],
      [25, 8],
      [35, -2],
      [46, 0],
      [37, -18],
      [24, 12],
      [73, -2],
      [38, 31],
      [32, 19],
      [52, 14],
      [32, 8],
      [45, -31],
      [93, -20],
      [15, 57],
      [34, 9],
      [38, 22],
      [38, 21],
      [26, 16],
      [25, 7],
      [41, -25],
      [28, 18],
      [28, 27],
      [33, 76],
      [27, 56],
      [21, 43],
      [32, 44],
      [21, 28],
      [39, 15],
      [28, 23],
      [33, 55],
      [46, 31],
    ],
    [
      [7109, 2481],
      [-3, -17],
    ],
    [
      [8085, 2942],
      [-39, -57],
    ],
  ],
  transform: {
    scale: [0.00008741200657441312, 0.00005282448169806801],
    translate: [2.702159893000044, 6.370839257000057],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2]],
          type: "Polygon",
          properties: {
            name: "Agege",
            id: "LG001",
            nameAbbr: "Agege",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.136929,
            Shape_Area: 0.000939,
          },
        },
        {
          arcs: [[3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Ajeromi-Ifelodun",
            id: "LG002",
            nameAbbr: "Ajeromi-Ifelodun",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.136005,
            Shape_Area: 0.001043,
          },
        },
        {
          arcs: [[-2, 6, 7, 8, 9, 10, 11]],
          type: "Polygon",
          properties: {
            name: "Alimosho",
            id: "LG003",
            nameAbbr: "Alimosho",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.589767,
            Shape_Area: 0.015009,
          },
        },
        {
          arcs: [[-4, 12, 13, 14, 15, -9, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Amuwo-Odofin",
            id: "LG004",
            nameAbbr: "Amuwo-Odofin",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.690195,
            Shape_Area: 0.011342,
          },
        },
        {
          arcs: [[18, 19, -13, -6, 20]],
          type: "Polygon",
          properties: {
            name: "Apapa",
            id: "LG005",
            nameAbbr: "Apapa",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.281547,
            Shape_Area: 0.00164,
          },
        },
        {
          arcs: [[21, 22]],
          type: "Polygon",
          properties: {
            name: "Badagry",
            id: "LG006",
            nameAbbr: "Badagry",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.088898,
            Shape_Area: 0.036985,
          },
        },
        {
          arcs: [[23, 24, 25, 26, 27, 28, 29, 30]],
          type: "Polygon",
          properties: {
            name: "Epe",
            id: "LG007",
            nameAbbr: "Epe",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.401748,
            Shape_Area: 0.096547,
          },
        },
        {
          arcs: [[-28, 31, 32, -14, 33, 34]],
          type: "Polygon",
          properties: {
            name: "Eti Osa",
            id: "LG008",
            nameAbbr: "Eti Osa",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.750129,
            Shape_Area: 0.015616,
          },
        },
        {
          arcs: [[[35, -32, -27]], [[36, -25]]],
          type: "MultiPolygon",
          properties: {
            name: "Ibeju-Lekki",
            id: "LG009",
            nameAbbr: "Ibeju-Lekki",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.428596,
            Shape_Area: 0.036954,
          },
        },
        {
          arcs: [[37, 38, -3, -12, 39]],
          type: "Polygon",
          properties: {
            name: "Ifako-Ijaye",
            id: "LG010",
            nameAbbr: "Ifako-Ijaye",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.267549,
            Shape_Area: 0.002703,
          },
        },
        {
          arcs: [[40, 41, 42, 43, -7, -1, -39, 44]],
          type: "Polygon",
          properties: {
            name: "Ikeja",
            id: "LG011",
            nameAbbr: "Ikeja",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.372018,
            Shape_Area: 0.003809,
          },
        },
        {
          arcs: [[-30, 45, 46, 47]],
          type: "Polygon",
          properties: {
            name: "Ikorodu",
            id: "LG012",
            nameAbbr: "Ikorodu",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.869347,
            Shape_Area: 0.032616,
          },
        },
        {
          arcs: [[-47, 48, 49, -41, 50]],
          type: "Polygon",
          properties: {
            name: "Kosofe",
            id: "LG013",
            nameAbbr: "Kosofe",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.402183,
            Shape_Area: 0.006669,
          },
        },
        {
          arcs: [[-34, -20, 51, 52]],
          type: "Polygon",
          properties: {
            name: "Lagos Island",
            id: "LG014",
            nameAbbr: "Lagos Island",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.104108,
            Shape_Area: 0.000703,
          },
        },
        {
          arcs: [[53, -52, -19, 54, 55, 56]],
          type: "Polygon",
          properties: {
            name: "Lagos Mainland",
            id: "LG015",
            nameAbbr: "Lagos Mainland",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.197079,
            Shape_Area: 0.001921,
          },
        },
        {
          arcs: [[57, -56, 58, 59, -43]],
          type: "Polygon",
          properties: {
            name: "Mushin",
            id: "LG016",
            nameAbbr: "Mushin",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.182036,
            Shape_Area: 0.001354,
          },
        },
        {
          arcs: [[-16, 60, -22, 61, -10]],
          type: "Polygon",
          properties: {
            name: "Ojo",
            id: "LG017",
            nameAbbr: "Ojo",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.668769,
            Shape_Area: 0.013216,
          },
        },
        {
          arcs: [[-60, 62, -17, -8, -44]],
          type: "Polygon",
          properties: {
            name: "Oshodi-Isolo",
            id: "LG018",
            nameAbbr: "Oshodi-Isolo",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.315352,
            Shape_Area: 0.003608,
          },
        },
        {
          arcs: [[-50, 63, -57, -58, -42]],
          type: "Polygon",
          properties: {
            name: "Shomolu",
            id: "LG019",
            nameAbbr: "Shomolu",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.13782,
            Shape_Area: 0.000914,
          },
        },
        {
          arcs: [[-55, -21, -5, -18, -63, -59]],
          type: "Polygon",
          properties: {
            name: "Surulere",
            id: "LG020",
            nameAbbr: "Surulere",
            parent: "Lagos",
            parentAbbr: "LG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.213765,
            Shape_Area: 0.001598,
          },
        },
      ],
    },
  },
};

const ogun = {
  type: "Topology",
  arcs: [
    [
      [18490, 23908],
      [0, 9],
      [62, 135],
      [-55, 347],
      [77, 175],
      [125, 229],
      [118, 215],
      [82, 94],
      [117, 215],
      [124, 134],
      [144, 187],
      [95, 73],
      [98, 152],
      [19, 30],
      [43, 182],
      [124, 175],
      [43, 155],
      [90, 168],
      [84, 189],
      [83, 132],
    ],
    [
      [19963, 26904],
      [13, -5],
      [218, -234],
      [154, -77],
      [107, -36],
      [113, -50],
      [80, -28],
      [137, -274],
      [72, -158],
      [32, -102],
      [59, -89],
      [52, -103],
      [376, -427],
      [92, -138],
      [18, -143],
      [-1, -44],
    ],
    [
      [21485, 24996],
      [-20, -16],
      [-91, -209],
      [-136, -120],
      [-156, -86],
      [-142, -11],
      [-135, -45],
      [-257, -118],
      [-141, 9],
      [-147, -65],
      [-16, -7],
      [-244, -124],
      [-47, -40],
      [-251, -125],
      [-129, -52],
      [-195, -17],
      [-14, 0],
      [-80, 8],
      [-182, -30],
      [-222, -17],
      [-236, -30],
      [-154, 7],
    ],
    [
      [13255, 31824],
      [88, -165],
      [304, -331],
      [260, -168],
      [180, -125],
      [219, -161],
      [207, -119],
      [40, -34],
      [205, -180],
      [265, -125],
    ],
    [
      [15023, 30416],
      [7, -24],
      [83, -266],
      [217, -336],
      [51, -164],
      [144, -213],
      [192, -194],
      [186, -159],
      [20, -7],
      [178, -207],
      [299, -229],
      [246, -154],
      [202, 31],
      [235, -24],
      [275, -73],
      [260, -133],
      [215, 9],
      [81, 19],
      [428, -183],
      [287, -141],
      [240, -113],
      [127, -2],
      [206, -180],
      [133, -104],
      [185, -173],
      [137, -247],
      [239, -221],
      [67, -24],
    ],
    [
      [18490, 23908],
      [-74, 4],
      [-148, -18],
      [-131, -195],
      [-187, -282],
      [-33, -104],
    ],
    [
      [17917, 23313],
      [-44, -139],
      [-145, -201],
      [-90, -189],
      [-245, -165],
      [-176, -65],
      [-304, -97],
      [-222, -57],
      [-257, -36],
      [-214, 71],
      [-261, 46],
      [-298, -152],
      [-178, -166],
      [-267, 86],
      [-175, -11],
      [-355, 101],
      [-72, 124],
      [-147, 63],
      [-266, 168],
      [-39, 109],
      [-354, 129],
      [-182, -38],
      [-432, -101],
      [-257, -64],
      [-89, -19],
    ],
    [
      [12848, 22710],
      [-10, 75],
      [67, 407],
      [18, 345],
      [45, 278],
      [-66, 469],
      [-150, 302],
      [-226, 146],
      [-284, 338],
      [-135, 376],
      [-67, 428],
      [-69, 334],
      [-194, 431],
      [-97, 246],
      [-157, 261],
      [-145, 240],
      [-136, 322],
      [-83, 239],
      [3, 217],
      [-43, 218],
      [-30, 238],
      [-149, 376],
      [-71, 198],
      [2, 122],
      [-212, 139],
      [-174, 78],
      [-186, 132],
      [-118, 206],
      [-104, 233],
      [-75, 320],
      [-83, 300],
      [-40, 415],
      [-81, 449],
      [-41, 368],
      [-69, 300],
      [-3, 197],
      [-6, 61],
    ],
    [
      [9679, 32514],
      [120, -43],
      [242, -11],
      [242, -18],
      [261, -72],
      [395, -68],
      [715, -290],
      [437, -35],
      [315, -19],
      [456, -123],
      [335, -20],
      [58, 9],
    ],
    [
      [17415, 11537],
      [-73, 1],
      [-155, -17],
      [-155, -25],
      [-20, -6],
      [7, 33],
      [-21, -41],
      [-41, -80],
      [-56, -108],
      [-69, -128],
      [-56, -94],
      [-83, -141],
      [-83, -169],
      [-69, -128],
      [-90, -154],
      [-70, -149],
      [29, -293],
      [-36, -135],
      [19, -68],
      [25, -116],
      [-8, -102],
      [-22, -108],
      [-15, -95],
      [-8, -95],
      [-29, -109],
      [-27, -54],
      [-35, -40],
      [-34, -47],
      [-102, -79],
      [-82, -67],
      [-83, -107],
      [-122, -73],
      [-95, -53],
      [-88, -53],
      [-102, -66],
      [-109, -73],
      [-34, -33],
      [-68, -40],
      [-35, -81],
      [-28, -74],
      [-8, -81],
      [-29, -102],
      [-1, -109],
      [-22, -81],
      [-15, -68],
      [-8, -135],
      [-15, -75],
      [-1, -68],
      [5, -102],
      [-16, -142],
      [19, -96],
      [-63, -135],
      [-41, -174],
      [-2, -8],
      [-15, -102],
      [-49, -142],
      [-36, -101],
      [-62, -122],
      [0, -6],
      [-103, -94],
      [-109, -73],
      [-108, -80],
      [0, 7],
      [-28, -20],
      [-89, -93],
      [-87, 22],
      [-115, -46],
      [-115, -32],
      [-148, -11],
      [-128, -5],
      [-128, -18],
      [-195, 4],
      [-154, 29],
      [-162, 30],
      [-167, 17],
      [-75, -46],
      [-7, 0],
      [-87, 1],
      [-134, 37],
      [-114, 42],
      [-188, 4],
      [-122, -19],
      [-148, 10],
      [-127, -5],
      [-13, 4],
      [-75, 25],
      [-148, -32],
      [-236, -9],
      [-107, -5],
      [-81, 1],
      [-129, -45],
      [-188, -4],
      [-88, -12],
      [-128, -4],
      [-128, 8],
      [-108, -32],
      [-235, 32],
      [-121, 9],
      [-195, -11],
      [-222, 4],
      [-202, 10],
      [-154, 3],
      [-121, 23],
      [-182, -38],
      [-7, 0],
      [-128, -11],
      [-148, 9],
      [-201, 78],
      [-161, 3],
      [-195, 3],
      [-14, -6],
      [-6, 7],
      [-128, -25],
      [-89, -39],
      [-115, -53],
      [-114, 2],
      [-167, 64],
      [-128, 16],
      [-114, 22],
      [-154, 57],
      [-155, -4],
      [-148, 10],
      [-134, 29],
      [-54, 1],
      [-120, 9],
      [-109, -19],
      [-127, 37],
      [-147, 29],
      [-102, -18],
      [-94, -33],
      [-22, -95],
      [32, -102],
      [12, -65],
    ],
    [
      [6197, 5915],
      [-22, 25],
      [-54, 49],
      [-104, 171],
      [-72, 164],
      [-51, 157],
      [-59, 110],
      [-45, 109],
      [-58, 151],
      [-98, 219],
      [-104, 171],
      [-13, 48],
      [-19, 82],
      [1, 68],
      [29, 121],
      [43, 169],
      [137, 161],
      [103, 127],
      [191, 173],
      [264, 84],
      [239, 268],
      [197, 105],
      [230, 138],
      [179, 228],
      [3, 183],
      [-100, 84],
      [-42, 279],
      [-39, 123],
      [-18, 34],
    ],
    [
      [6915, 9716],
      [-34, 62],
      [15, 115],
      [-57, 204],
      [-52, 103],
      [-31, 136],
      [-66, 110],
      [-6, 20],
      [9, 164],
      [43, 134],
      [14, 89],
      [90, 120],
      [217, 153],
      [326, 164],
      [297, 76],
      [256, 57],
      [135, 38],
      [494, -232],
      [256, 9],
      [314, -128],
      [253, -92],
      [89, 87],
      [4, 203],
      [22, 129],
      [82, 114],
      [70, 114],
      [55, 135],
      [30, 142],
      [130, 174],
      [49, 115],
      [22, 88],
      [101, 79],
      [262, 376],
      [38, 264],
      [51, 230],
      [195, 38],
      [315, -115],
      [181, 18],
      [376, -27],
      [176, 38],
      [49, 73],
    ],
    [
      [11685, 13293],
      [227, -44],
      [229, 50],
      [465, 47],
      [215, 23],
      [322, -66],
      [302, -46],
      [488, -131],
      [369, -74],
      [169, 38],
      [478, 39],
      [377, 82],
      [270, 22],
      [249, 43],
      [344, 35],
      [162, 59],
      [123, 86],
      [299, -195],
      [172, -173],
      [196, -316],
      [116, -341],
      [-17, -203],
      [176, -370],
      [-3, -190],
      [2, -131],
    ],
    [
      [12848, 22710],
      [26, -184],
      [90, -232],
      [41, -367],
      [-105, -304],
      [-302, -341],
      [-438, -101],
      [-279, -179],
      [-419, -182],
      [-214, -329],
      [18, -123],
      [-12, -319],
      [-134, -425],
      [-17, -163],
      [-57, -678],
      [-1, -4],
    ],
    [
      [11045, 18779],
      [-114, -1],
      [-348, 88],
      [-282, 52],
      [-340, 148],
      [-329, -1],
      [-158, -167],
      [-225, -220],
      [-62, -114],
      [-157, -133],
      [-245, -200],
      [-63, -141],
      [-260, -308],
      [-225, -179],
      [-344, -49],
      [-358, -109],
      [-143, -120],
      [-501, -188],
      [-374, -299],
      [-129, -93],
      [-216, -17],
      [-195, 3],
      [0, -6],
      [-74, -26],
      [-87, 22],
      [-235, 58],
      [-248, 18],
      [-296, -29],
      [-210, -71],
      [-312, -185],
      [-264, -124],
      [-237, -98],
      [-117, -154],
      [-123, -100],
      [-5, -1],
    ],
    [
      [3769, 16035],
      [-305, -62],
      [-222, -23],
      [-295, 39],
      [-275, 73],
      [-294, 106],
      [-123, 30],
    ],
    [
      [2255, 16198],
      [137, 586],
      [11, 285],
      [12, 356],
      [6, 165],
      [-106, 468],
      [-610, 63],
      [-201, 129],
      [-15, 46],
      [-38, 117],
      [70, 1361],
      [194, 263],
      [56, 75],
      [410, 426],
      [282, 202],
      [397, 209],
      [249, 209],
      [302, 88],
      [208, 141],
      [-314, 367],
      [-429, 340],
      [-121, 41],
      [-118, 126],
      [-176, 186],
      [-227, 455],
      [1, 189],
      [161, 196],
      [484, 331],
      [236, 331],
      [278, 913],
      [-32, 559],
      [-7, 126],
      [-75, 421],
      [-196, 1110],
      [-355, 407],
      [-160, 82],
      [-174, 190],
      [-33, 109],
      [-4, 197],
      [-2, 83],
      [-1, 104],
      [-11, 561],
      [0, 16],
      [35, 508],
      [-76, 1619],
      [4, 1517],
      [59, -2],
      [216, -6],
      [168, 60],
      [671, -15],
      [289, 134],
      [101, 95],
      [0, 3],
    ],
    [
      [3811, 32740],
      [224, -8],
      [282, -18],
      [237, 77],
      [316, 29],
      [310, 22],
      [329, 28],
      [363, -40],
      [322, -12],
      [579, 17],
      [398, 75],
      [406, 190],
      [303, 42],
      [457, -28],
      [476, -83],
      [479, -334],
      [246, -133],
      [141, -50],
    ],
    [
      [11045, 18779],
      [-15, -124],
      [33, -42],
      [179, -166],
      [212, -187],
      [153, -118],
      [171, -247],
      [86, -90],
      [92, -110],
      [23, -245],
      [-131, -188],
      [-324, -82],
      [-156, -113],
      [-233, -254],
      [-52, -65],
    ],
    [
      [11083, 16748],
      [-215, -270],
      [-72, -250],
      [37, -218],
      [32, -109],
      [70, -232],
      [145, -213],
      [95, -355],
      [137, -274],
      [209, -356],
      [104, -213],
      [111, -225],
      [78, -151],
      [16, -292],
      [-91, -216],
      [-54, -81],
    ],
    [
      [6915, 9716],
      [-276, 45],
      [-322, 80],
      [-134, -11],
      [-308, 101],
      [-359, 244],
      [-177, 254],
      [-292, 276],
      [-31, 184],
      [10, 169],
      [-204, 296],
      [-110, 260],
      [-298, 256],
      [-265, 297],
      [30, 196],
      [-9, 231],
      [-109, 328],
      [-88, 381],
      [63, 162],
      [-96, 348],
      [52, 257],
      [51, 230],
      [4, 265],
      [-62, 361],
      [-87, 415],
      [-56, 286],
      [2, 163],
      [-77, 199],
      [2, 46],
    ],
    [
      [20351, 15333],
      [-151, -60],
      [-189, -52],
      [-195, -23],
      [-189, -17],
      [-236, -44],
      [-175, -4],
      [-249, -9],
      [-196, -78],
      [-343, -55],
      [-188, 64],
      [-40, 35],
      [-158, 158],
      [-153, 105],
      [-193, 126],
      [-180, 98],
      [-260, 113],
      [-249, 18],
      [-248, 38],
      [-141, 36],
      [-449, 55],
      [-128, 16],
      [-530, 30],
      [-296, 5],
      [-417, 20],
      [-261, 52],
      [-356, 33],
      [-308, 87],
      [-269, 5],
      [-221, 45],
      [-159, 91],
      [-107, 89],
      [-146, 139],
      [-98, 124],
      [-194, 98],
      [-280, 113],
      [-295, 46],
      [-228, 45],
      [-243, -10],
      [-148, -45],
      [-189, -37],
      [-213, -35],
    ],
    [
      [17917, 23313],
      [5, -4],
      [125, -131],
      [12, -136],
      [-2, -129],
      [-18, -285],
      [-16, -149],
      [-4, -231],
      [-63, -148],
      [-56, -175],
      [-9, -156],
      [-64, -196],
      [-50, -196],
      [-25, -271],
      [-9, -129],
      [-42, -163],
      [-45, -284],
      [-78, -209],
      [-43, -203],
      [-32, -298],
      [38, -136],
      [7, -14],
      [49, -293],
      [42, -299],
      [122, -342],
      [177, -329],
      [109, -307],
      [83, -280],
      [139, -158],
      [131, -179],
      [165, -227],
      [99, -117],
      [26, -28],
      [131, -233],
      [118, -172],
      [152, -192],
      [165, -180],
      [133, -104],
      [126, -97],
      [99, -110],
      [79, -76],
      [159, -159],
      [72, -117],
      [253, -167],
      [59, -89],
      [115, -82],
    ],
    [
      [20351, 15333],
      [51, -37],
      [126, -124],
      [86, -97],
      [0, -6],
      [38, -150],
      [31, -123],
      [26, -75],
      [39, -89],
      [24, -177],
      [-49, -149],
      [-16, -162],
      [24, -164],
      [-16, -122],
      [-42, -128],
      [-22, -108],
      [-50, -203],
      [-23, -135],
      [-43, -176],
      [-21, -88],
      [-22, -88],
      [-43, -162],
      [-14, -81],
      [-31, -203],
      [-3, -224],
      [-8, -55],
      [39, -116],
      [24, -136],
      [85, -158],
      [99, -150],
      [79, -70],
      [114, -63],
      [118, -165],
      [13, -47],
      [65, -97],
      [93, -62],
      [80, -42],
      [74, -42],
      [120, -30],
      [81, -8],
      [127, -2],
      [108, 18],
      [41, 27],
      [86, 6],
    ],
    [
      [21839, 11070],
      [-56, -217],
      [45, -136],
      [31, -164],
      [59, -123],
      [32, -75],
      [125, -165],
      [112, -125],
      [100, -49],
      [67, -35],
      [160, -118],
    ],
    [
      [22514, 9863],
      [-99, -37],
      [-159, -12],
      [-1767, -131],
      [-48, -20],
      [1, 34],
      [-23, 6],
      [-4, 1],
      [-19, 76],
      [-6, 40],
      [-53, 56],
      [-86, 117],
      [-39, 89],
      [1, 54],
      [-99, 63],
      [-52, 144],
      [-66, 69],
      [-21, 12],
      [-106, 65],
      [-133, 77],
      [-187, 119],
      [-53, 41],
      [-227, 106],
      [-147, 50],
      [-194, 72],
      [-207, 125],
      [-174, 51],
      [-147, 64],
      [-180, 91],
      [-141, 37],
      [-187, 84],
      [-154, 44],
      [-114, 36],
      [-154, 50],
      [-55, 1],
    ],
    [
      [38089, 15374],
      [-198, 64],
      [-100, 22],
      [-260, 147],
      [-120, 64],
      [-260, 153],
      [-145, 166],
      [-53, 48],
      [-100, 97],
      [16, 122],
      [-131, 226],
      [-30, 218],
      [9, 162],
      [69, 88],
      [-10, 25],
    ],
    [
      [36776, 16976],
      [322, 159],
      [712, 341],
      [695, 206],
      [253, 233],
      [580, 133],
      [320, 266],
      [61, -8],
      [323, 1],
      [250, 132],
      [497, -63],
      [340, 76],
    ],
    [
      [41129, 18452],
      [4, -14],
      [151, -227],
      [105, -131],
      [152, -213],
      [-63, -114],
      [-103, -114],
      [-124, -181],
      [-130, -188],
      [-211, -173],
      [-125, -188],
      [-164, -221],
      [-160, -303],
      [-50, -230],
      [91, -171],
      [30, -218],
      [38, -129],
      [-36, -163],
      [-35, -80],
      [-75, -81],
      [-101, 9],
      [-120, 70],
      [-92, 110],
      [-139, 165],
      [-173, 91],
      [-140, 71],
      [-81, 15],
      [-94, -5],
      [-328, 32],
      [-315, 94],
      [-183, -85],
      [-217, -98],
      [-48, -101],
      [-90, -141],
      [-197, -133],
      [-17, -33],
    ],
    [
      [55160, 19827],
      [-84, -340],
      [43, -654],
      [-119, -263],
      [-86, -372],
      [-55, -60],
      [16, -252],
      [-64, -679],
      [-220, -683],
      [-89, -555],
      [-32, -50],
      [-162, -260],
      [-322, -395],
      [-354, -225],
      [-430, -380],
      [-562, -215],
      [-407, -244],
      [-506, -488],
      [-410, -414],
      [-443, -333],
      [-348, -326],
      [-154, -331],
      [-377, -449],
      [-201, -343],
      [-400, -625],
      [-160, -351],
      [-112, -243],
      [35, -367],
      [19, -531],
      [-100, -324],
      [23, -154],
    ],
    [
      [49099, 8921],
      [-160, -108],
      [-203, -51],
      [-181, 30],
      [-67, 2],
      [-147, 50],
      [-67, 14],
      [-248, 25],
      [-168, 50],
      [-180, 44],
      [-162, -18],
      [-237, -118],
      [-232, -186],
      [-197, -139],
      [-286, -226],
      [-119, -290],
      [-170, -106],
      [-162, -44],
      [-214, 24],
      [-202, 17],
      [-289, -2],
      [-158, -187],
      [-284, -59],
    ],
    [
      [44966, 7643],
      [-50, 62],
      [-26, 41],
      [-99, 131],
      [-73, 63],
      [-26, 34],
      [-61, 22],
      [-87, 8],
      [-108, -5],
      [-188, -11],
      [-236, -9],
      [-182, -10],
      [-344, -76],
      [-284, -83],
      [-135, -32],
      [-197, -99],
      [-115, -25],
      [-129, -59],
      [-116, -134],
      [-150, -106],
      [-34, -27],
      [-197, -146],
      [-96, -93],
      [-89, -101],
      [-285, -165],
      [-109, -73],
      [-128, 2],
      [-61, -5],
      [-47, 14],
      [-46, 35],
      [-20, 14],
      [-52, 96],
      [-26, 75],
      [-46, 56],
      [-60, 75],
      [-33, 21],
      [-60, 28],
      [-107, 36],
      [-101, 29],
      [-80, 22],
      [-74, 8],
      [-87, 15],
      [-26, 55],
      [-5, 82],
      [1, 74],
      [21, 102],
      [15, 54],
      [-18, 157],
      [-5, 88],
      [-6, 82],
      [-59, 76],
      [-32, 88],
      [-67, 15],
      [-60, 49],
      [-25, 109],
      [-47, 28],
      [-11, 123],
      [-39, 102],
      [49, 108],
      [-5, 95],
      [29, 129],
      [35, 115],
      [69, 121],
      [76, 87],
      [95, 80],
      [63, 142],
      [27, 20],
      [0, -7],
      [62, 74],
      [68, 46],
      [202, 51],
      [61, 12],
      [123, 107],
      [62, 74],
      [68, 74],
      [42, 60],
      [62, 101],
      [62, 101],
      [89, 114],
      [56, 114],
      [-71, 205],
      [-73, 90],
      [-112, 131],
      [-167, 50],
      [-148, 30],
      [-188, 24],
      [-157, 1],
    ],
    [
      [40638, 10904],
      [5, 47],
      [-132, 138],
      [-86, 69],
      [-41, -19],
      [-173, 118],
      [-6, 34],
      [7, 0],
      [-31, 170],
      [-58, 157],
      [-6, 68],
      [-17, 184],
      [-112, 185],
      [-18, 103],
      [-53, 34],
      [-40, 21],
      [-235, 52],
      [-168, 10],
      [-181, 10],
      [-87, 8],
      [-182, -4],
      [-161, -11],
      [-308, 107],
      [-87, 9],
      [-80, 14],
      [88, 80],
      [115, 60],
      [76, 73],
      [102, 86],
      [81, 54],
      [55, 87],
      [9, 102],
      [-13, 81],
      [69, 101],
      [-65, 123],
      [-175, -4],
      [-88, -39],
      [-155, -18],
      [-195, -10],
      [-175, -31],
      [-228, 18],
      [-101, 1],
      [-154, 64],
      [-46, 21],
      [-99, 111],
      [-46, 129],
      [17, 190],
      [50, 203],
      [9, 149],
      [90, 168],
      [89, 80],
      [163, 120],
      [130, 106],
      [109, 93],
      [41, 40],
      [110, 141],
      [54, 60],
      [43, 108],
      [34, 47],
      [15, 74],
      [-99, 151],
      [-5, 75],
      [-114, 43],
      [-90, 29],
    ],
    [
      [41129, 18452],
      [51, 12],
      [276, 16],
      [316, 1],
      [310, 42],
      [340, 225],
      [334, 280],
      [312, 178],
      [149, 99],
      [309, 382],
      [205, 206],
      [165, 242],
      [130, 161],
      [140, 296],
      [157, 147],
      [220, 329],
      [49, 101],
      [77, 209],
      [44, 216],
      [31, 251],
      [2, 22],
    ],
    [
      [44746, 21867],
      [147, 45],
      [423, 319],
      [264, 138],
      [461, 230],
      [205, 160],
      [459, 94],
      [794, -14],
      [803, -265],
      [208, -473],
      [305, -671],
      [111, -199],
      [177, -343],
      [112, -519],
      [18, -163],
      [90, -273],
      [8, -30],
      [88, -305],
      [282, -406],
      [184, 105],
      [205, 228],
      [428, 231],
      [158, 194],
      [467, 175],
      [518, 12],
      [278, -297],
      [262, -32],
      [244, 77],
      [142, 52],
      [149, 127],
      [461, 182],
      [660, 64],
      [362, -129],
      [501, 196],
      [437, -8],
      [147, -64],
      [-106, -324],
      [-38, -154],
    ],
    [
      [36776, 16976],
      [-37, -18],
      [-403, 14],
      [-289, 24],
    ],
    [
      [36047, 16996],
      [-114, 10],
      [-261, 72],
      [-411, -67],
      [-384, -48],
      [-336, 46],
      [-491, -45],
      [-516, 137],
      [-349, 47],
      [-329, 19],
      [-281, 53],
      [-234, 106],
      [-285, 256],
      [-278, 222],
      [-207, 85],
      [-202, 37],
      [-35, 17],
    ],
    [
      [31334, 17943],
      [120, 246],
      [78, 216],
      [117, 222],
      [133, 290],
      [185, 248],
      [74, 379],
      [178, 228],
      [91, 208],
      [25, 305],
      [79, 318],
      [77, 175],
      [10, 204],
      [-9, 286],
      [-50, 211],
      [14, 139],
    ],
    [
      [32456, 21618],
      [113, 3],
      [800, -6],
      [512, 58],
      [7, 0],
      [56, 129],
      [87, -22],
      [100, -56],
      [508, 168],
      [457, -1],
      [242, -45],
      [498, 52],
      [235, -72],
      [473, 155],
      [293, 206],
      [371, 75],
      [359, 157],
      [302, 348],
      [408, 299],
      [547, 113],
      [261, 50],
      [225, 43],
      [336, -53],
      [823, 129],
      [283, 36],
      [277, 104],
      [25, -3],
      [231, -22],
      [252, 199],
      [284, 91],
      [237, 125],
      [285, 124],
      [253, 288],
      [178, 174],
      [230, 64],
      [441, -185],
      [243, -398],
      [127, -451],
      [14, -395],
      [-33, -387],
      [46, -476],
      [123, -328],
      [502, -125],
      [279, 84],
    ],
    [
      [40638, 10904],
      [-4652, 46],
    ],
    [
      [35986, 10950],
      [19, 33],
      [83, 155],
      [104, 215],
      [70, 135],
      [49, 169],
      [96, 100],
      [69, 121],
      [62, 73],
      [70, 155],
      [104, 229],
      [50, 190],
      [51, 243],
      [42, 67],
      [16, 197],
      [18, 258],
      [37, 250],
      [-82, 334],
      [-123, 274],
      [-51, 157],
      [-153, 97],
      [-148, 51],
      [-180, 43],
      [-295, 46],
      [-155, -17],
      [-155, -25],
      [-149, -79],
      [-96, -73],
      [-48, -47],
      [-150, 268],
      [-6, 68],
      [-4, 142],
      [21, 68],
      [43, 135],
      [123, 167],
      [144, 161],
      [109, 93],
      [137, 147],
      [96, 128],
      [132, 249],
      [37, 203],
      [30, 203],
      [17, 203],
      [78, 263],
      [-51, 197],
    ],
    [
      [30249, 14774],
      [-106, 30],
      [-272, 256],
      [-58, 136],
      [-177, 261],
      [-84, 239],
      [-158, 193],
      [-132, 186],
      [-232, 201],
      [-185, 166],
      [-181, 64],
      [-133, 77],
      [-78, 144],
      [-232, 241],
      [-150, 254],
      [-104, 280],
      [-183, 302],
      [-137, 247],
      [-91, 239],
      [-50, 205],
      [-71, 150],
      [-90, 280],
      [-32, 58],
    ],
    [
      [27313, 18983],
      [6, 4],
      [109, 66],
      [135, 85],
      [35, 108],
      [29, 88],
      [75, 74],
      [82, 87],
      [184, 166],
      [123, 93],
      [28, 61],
      [0, 34],
      [2, 142],
      [7, 14],
      [-95, 158],
      [-16, 27],
      [136, 106],
      [61, 47],
      [107, 78],
    ],
    [
      [28321, 20421],
      [22, -46],
      [175, -370],
      [59, -110],
      [64, -225],
      [152, -145],
      [153, -118],
      [218, -228],
      [259, -235],
      [198, -173],
      [173, -160],
      [172, -172],
      [105, -138],
      [212, -180],
      [173, -152],
      [125, -165],
      [252, -215],
      [132, -145],
      [66, -76],
      [47, -36],
    ],
    [
      [31078, 17332],
      [30, -189],
      [-76, -114],
      [123, -335],
      [-16, -156],
      [-138, -194],
      [7, 0],
      [-62, -94],
      [-92, -277],
      [-100, -365],
      [-85, -297],
      [-226, -261],
      [-118, -202],
      [-76, -74],
    ],
    [
      [9977, 43282],
      [-43, -491],
      [-7, -442],
      [-93, -325],
      [-67, -433],
      [-236, -452],
      [-100, -372],
      [-80, -325],
      [-30, -210],
      [-192, -241],
      [-21, -483],
      [-193, 112],
      [-240, -267],
      [68, -362],
      [26, -455],
      [-5, -361],
      [159, -560],
      [257, -344],
      [104, -206],
      [505, -396],
      [131, -247],
      [526, -356],
      [353, -223],
      [555, -595],
      [129, -403],
      [300, -576],
      [21, -180],
      [32, -283],
      [372, -312],
      [28, -361],
      [805, -1013],
      [145, -193],
      [33, -35],
      [36, -68],
    ],
    [
      [3811, 32740],
      [14, 335],
      [-43, 1314],
      [-154, 353],
      [-126, 76],
      [-75, 46],
      [-314, 353],
      [-369, 234],
      [-14, 9],
      [-11, 5],
      [-36, 12],
      [-167, 53],
      [-149, 178],
      [-102, 378],
      [-1, 4],
      [10, 45],
      [36, 154],
      [-46, 257],
      [-288, 279],
      [-43, 110],
      [-22, 54],
      [-19, 49],
      [-89, 227],
      [-37, 502],
      [-28, 378],
      [28, 379],
      [-106, 739],
      [26, 271],
      [119, 1238],
      [-36, 698],
      [-34, 649],
      [129, 805],
      [-85, 589],
      [-140, 454],
      [-127, 95],
      [-262, 69],
      [-60, 115],
      [-86, 460],
      [-66, 139],
      [-242, 512],
      [-772, 999],
      [-24, 32],
      [82, 38],
      [133, 63],
      [73, 123],
      [656, 399],
      [233, 267],
      [395, 299],
      [484, 339],
      [557, 337],
      [177, 180],
      [307, 213],
      [519, 99],
      [216, 24],
      [908, -22],
      [427, -239],
      [468, -205],
      [303, -359],
      [282, -487],
      [191, -683],
      [22, -728],
      [2, -727],
      [-40, -802],
      [88, -817],
      [125, -593],
      [140, -526],
      [319, -658],
      [297, -373],
      [396, -61],
      [128, 433],
      [132, 664],
      [105, 691],
      [28, 524],
      [46, 325],
      [173, 296],
      [99, 297],
      [218, 214],
      [182, 4],
      [465, -1],
      [213, -106],
      [199, -187],
      [204, -303],
      [52, -524],
      [-13, -374],
      [-14, -160],
    ],
    [
      [6197, 5915],
      [25, -133],
      [-8, -88],
      [-2, -122],
      [-29, -122],
      [-7, -41],
      [-95, -73],
      [-69, -67],
      [-88, -46],
      [-122, -52],
      [-81, -40],
      [-95, -52],
      [-171, -140],
      [-81, -39],
      [-41, -34],
      [-61, -46],
      [-123, -93],
      [-89, -80],
      [-75, -54],
      [-103, -127],
      [-68, -73],
      [-95, -53],
      [-95, -53],
      [-75, -39],
      [-54, -6],
      [-100, 29],
      [-73, 49],
      [-74, 14],
      [-33, 69],
      [-121, 9],
      [-113, 49],
      [-120, 70],
      [-140, 98],
      [-87, 42],
      [-141, 3],
      [-54, 14],
      [-47, -19],
      [-34, -27],
      [-215, 24],
      [-67, -6],
      [-95, -18],
      [-95, -33],
      [-222, -16],
      [-87, -12],
      [-197, -45],
      [-74, -19],
      [-101, -5],
      [-108, -39],
      [-67, -5],
      [-108, 22],
      [-53, 28],
      [-127, 57],
      [-74, 28],
      [-148, 9],
      [-79, 46],
      [13, 92],
      [29, 186],
      [6, 155],
      [8, 197],
      [14, 380],
      [129, 549],
      [135, 236],
      [396, 324],
      [222, 352],
      [75, 386],
      [-247, 394],
      [-57, 165],
      [-196, 568],
      [-132, 651],
      [613, 1027],
      [242, 251],
      [269, 168],
      [434, 395],
      [50, 44],
      [-18, 841],
      [108, 460],
      [-6, 271],
      [-45, 92],
      [-48, 98],
      [-221, 136],
      [-337, -33],
      [-123, -11],
      [-224, 26],
      [-101, 14],
      [-17, 11],
      [-200, 128],
      [-150, 45],
      [-192, 409],
      [-36, 122],
      [-60, 201],
      [-39, 339],
      [55, 203],
      [565, 906],
      [-133, 231],
      [-16, 171],
      [-10, 107],
      [59, 253],
    ],
    [
      [32226, 24855],
      [39, -333],
      [87, -450],
      [-290, -444],
      [-319, -198],
      [-342, -347],
      [-349, -355],
      [-214, -183],
      [-114, -96],
      [-72, -85],
    ],
    [
      [30652, 22364],
      [-207, -16],
      [-88, -69],
      [-123, -97],
      [-29, -101],
      [-110, -148],
      [-108, -100],
      [-170, -106],
      [-184, -112],
      [-41, -19],
      [-101, -46],
      [-142, -59],
      [-176, -65],
      [-210, -98],
      [-224, -125],
      [-101, -53],
      [-136, -113],
      [-48, -40],
      [-29, -135],
      [-9, -82],
      [6, -26],
      [26, -117],
      [-42, -107],
      [-20, -33],
      [-29, -48],
      [-36, -28],
    ],
    [
      [27313, 18983],
      [-212, -142],
      [-109, -93],
      [-102, -80],
      [-61, -60],
      [-17, -26],
      [-31, -48],
      [-34, -55],
      [-15, -25],
      [-56, -53],
      [-108, -101],
      [-27, -47],
      [6, -27],
      [-15, -75],
      [31, -177],
      [-2, -101],
      [25, -130],
      [-21, -95],
      [-23, -135],
      [-73, 35],
      [-108, -12],
      [-80, 9],
      [-100, 56],
      [-134, 36],
      [-195, 3],
      [-27, 1],
      [-28, -61],
      [-35, -115],
      [-55, -73],
      [-56, -135],
      [-76, -101],
      [-82, -73],
      [-136, -106],
      [-75, -81],
      [-129, -72],
      [-156, -65],
      [-108, -26],
      [-81, -73],
      [-149, -79],
      [-75, -53],
      [-82, -73],
      [-42, -67],
      [-2, -122],
      [-89, -87],
      [-101, -53],
      [-66, 56],
      [-95, 8],
      [-179, 119],
      [-81, -47],
      [-102, -52],
      [-109, -59],
      [-76, -121],
      [-21, -102],
      [-76, -93],
      [32, -150],
      [-69, -87],
      [-117, -141],
      [31, -143],
      [-56, -148],
      [-54, -13],
      [-150, -120],
      [-102, -113],
      [-49, -129],
      [-63, -161],
      [-2, -129],
      [-65, -230],
      [-48, -101],
      [-42, -88],
      [-171, -167],
      [-184, -166],
      [-55, -87],
      [-83, -121],
      [-49, -169],
      [88, -375],
      [-125, -229],
      [-104, -195],
      [-98, -256],
      [-71, -209],
      [-78, -264],
      [-2, -136],
      [36, -251],
      [4, -204],
      [24, -177],
      [-2, -6],
    ],
    [
      [21485, 24996],
      [21, 17],
      [194, -10],
      [202, -24],
      [194, -71],
      [166, -118],
      [249, 9],
      [325, -243],
      [234, -86],
      [140, -97],
      [68, 60],
      [0, -14],
      [317, 62],
      [196, 45],
      [257, 124],
      [197, 140],
      [230, 77],
      [248, -25],
      [193, -152],
      [355, -54],
      [309, -26],
      [418, 55],
      [249, -5],
      [469, -69],
      [424, 54],
      [411, 27],
      [559, 51],
      [619, 58],
      [437, -1],
      [485, 60],
      [471, 18],
      [181, -50],
      [678, -32],
      [512, 39],
      [465, 66],
      [268, -26],
    ],
    [
      [24674, 32238],
      [193, 140],
      [139, 243],
      [426, 135],
      [736, 151],
      [403, -21],
      [283, 22],
      [357, -6],
      [417, 61],
      [511, -56],
      [525, -3],
      [422, -102],
      [198, -221],
      [191, -296],
      [162, -383],
      [67, -443],
      [118, -621],
      [26, -449],
      [7, -448],
      [-20, -408],
      [-46, -319],
      [197, -336],
      [521, -240],
      [206, -160],
      [287, -127],
      [390, -55],
      [195, -339],
      [106, -183],
      [-10, -604],
      [-41, -448],
      [275, -467],
      [243, -820],
      [54, -456],
      [14, -124],
    ],
    [
      [15023, 30416],
      [350, -165],
      [487, -246],
      [471, -22],
      [557, -70],
      [412, 87],
      [469, -130],
      [473, 169],
      [345, 62],
      [225, 200],
      [173, 303],
      [233, 288],
      [127, 358],
      [209, 452],
      [341, 286],
      [48, 455],
      [66, 366],
      [197, 499],
      [133, 338],
      [93, 358],
      [130, 130],
      [212, 211],
      [164, 174],
      [259, 186],
      [323, 35],
      [303, 22],
      [236, -31],
      [360, -163],
      [216, -357],
      [208, -472],
      [172, -635],
      [226, -596],
      [370, -400],
      [382, -136],
      [479, 121],
      [202, 145],
    ],
    [
      [35986, 10950],
      [-5961, 59],
      [-582, 6],
    ],
    [
      [29443, 11015],
      [93, 330],
      [19, 319],
      [50, 169],
      [36, 169],
      [-73, 456],
      [75, 107],
      [-120, 491],
      [55, 74],
      [31, 243],
      [65, 264],
      [70, 203],
      [78, 236],
      [90, 148],
      [48, 80],
      [64, 210],
      [82, 93],
      [103, 128],
      [40, 39],
    ],
    [
      [31078, 17332],
      [-13, 83],
      [16, 136],
      [82, 93],
      [104, 162],
      [67, 137],
    ],
    [
      [53205, 193],
      [0, 4],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-25, 0],
      [0, -7],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [-1, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, -1],
      [0, 5],
      [0, 4],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-57, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 4],
      [0, 4],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-56, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, -24],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-82, 0],
      [0, 8],
      [-24, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-32, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-12, 0],
      [-69, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 12],
      [0, 4],
      [-9, 0],
      [1, 49],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -3],
      [0, -5],
      [-90, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 1],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-98, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-14, 0],
      [19, 150],
      [-24, 205],
      [16, 122],
      [9, 129],
      [49, 108],
      [62, 107],
      [2, 143],
      [36, 163],
      [-11, 136],
      [-23, 218],
      [-131, 240],
      [-114, 29],
      [-166, 119],
      [-135, 22],
      [-182, -17],
      [-167, 23],
      [-182, -24],
      [-189, -44],
      [-237, -57],
      [-87, 8],
      [-127, 84],
      [-106, 63],
      [-202, 10],
      [-121, 23],
      [-241, 44],
      [-148, 10],
      [-74, 15],
      [-115, -66],
      [-7, 0],
      [-88, -46],
      [-96, -66],
      [-108, -66],
      [-115, -33],
      [-74, 29],
      [-201, 37],
      [-47, 42],
      [-232, 181],
      [-250, 405],
      [-161, 16],
      [-126, 98],
      [-108, 8],
      [-207, 31],
      [-141, 43],
      [-235, 45],
      [-100, 70],
      [-228, 51],
      [-113, 63],
      [-106, 97],
      [-53, 56],
      [-53, 48],
      [-106, 91],
      [-33, 68],
      [-6, 82],
      [-64, 184],
      [29, 143],
      [49, 107],
      [110, 162],
      [76, 80],
      [258, 145],
      [95, 32],
      [209, 78],
      [122, 60],
      [61, 32],
      [143, 73],
      [81, 46],
      [156, 79],
      [61, 19],
      [129, 59],
      [238, 152],
      [89, 107],
      [42, 82],
      [68, 87],
      [89, 73],
      [8, 95],
      [-19, 89],
      [-5, 41],
      [-13, 34],
      [-26, 75],
      [-6, 48],
      [8, 74],
      [-92, 144],
      [-39, 96],
      [-40, 48],
      [-39, 63],
      [-16, 20],
    ],
    [
      [49099, 8921],
      [38, -249],
      [176, -336],
      [266, -195],
      [505, -403],
      [349, -60],
      [195, -3],
      [435, -123],
      [316, -74],
      [396, -27],
      [350, -20],
      [221, -78],
      [208, 3],
      [237, 64],
      [0, 13],
      [128, -2],
      [120, -43],
      [169, 25],
      [178, 180],
      [489, 325],
      [267, 294],
      [119, 243],
      [97, 223],
      [295, 321],
      [461, 230],
      [71, 2],
      [300, 12],
      [303, -373],
      [97, -273],
      [-7, -394],
      [20, -429],
      [-2, -564],
      [-115, -467],
      [93, -899],
      [137, -322],
      [167, -478],
      [-107, -413],
      [-1, -21],
      [-229, -431],
      [-560, -92],
      [-183, -85],
      [-406, -136],
      [-365, -544],
      [-523, -324],
      [-666, 25],
      [-466, -74],
      [-354, -306],
      [-268, -335],
      [461, -206],
      [695, 111],
      [619, 10],
      [681, -318],
      [540, -322],
      [333, -189],
      [100, -512],
      [-274, -756],
      [-116, -66],
      [-485, -60],
      [-697, 223],
      [-645, -28],
      [-57, -2],
    ],
    [
      [30652, 22364],
      [-167, -197],
      [-157, -541],
      [173, -132],
      [330, 28],
      [250, 64],
      [470, -49],
      [539, 72],
      [366, 9],
    ],
    [
      [29443, 11015],
      [-6146, 60],
      [-135, -4],
      [-95, -19],
      [48, 20],
      [0, 7],
      [-13, 7],
      [-75, -20],
      [-67, 1],
      [-204, -111],
      [-116, -101],
      [14, 0],
      [-48, -33],
      [-61, -40],
      [-95, -80],
      [-97, -120],
      [-36, -149],
      [59, -137],
      [11, -163],
      [91, -165],
      [113, -77],
      [-77, -28],
    ],
  ],
  transform: {
    scale: [0.00003431225247244006, 0.00003393967739679154],
    translate: [2.668431, 6.315402830256831],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2]],
          type: "Polygon",
          properties: {
            name: "Abeokuta South",
            id: "OG001",
            nameAbbr: "Abeokuta South",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.31907346828,
            Shape_Area: 0.00571343116,
          },
        },
        {
          arcs: [[3, 4, -1, 5, 6, 7, 8]],
          type: "Polygon",
          properties: {
            name: "Abeokuta North",
            id: "OG002",
            nameAbbr: "Abeokuta North",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.14129555968,
            Shape_Area: 0.06533803337,
          },
        },
        {
          arcs: [[9, 10, 11, 12]],
          type: "Polygon",
          properties: {
            name: "Ado Odo/Ota",
            id: "OG003",
            nameAbbr: "Ado Odo/Ota",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.21634111052,
            Shape_Area: 0.0736171817,
          },
        },
        {
          arcs: [[-8, 13, 14, 15, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Egbado North",
            id: "OG004",
            nameAbbr: "Egbado North",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.80043531588,
            Shape_Area: 0.16047931853,
          },
        },
        {
          arcs: [[18, 19, -12, 20, -15]],
          type: "Polygon",
          properties: {
            name: "Egbado South",
            id: "OG005",
            nameAbbr: "Egbado South",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.06212917043,
            Shape_Area: 0.05374801966,
          },
        },
        {
          arcs: [[21, -19, -14, -7, 22]],
          type: "Polygon",
          properties: {
            name: "Ewekoro",
            id: "OG006",
            nameAbbr: "Ewekoro",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.1138220505,
            Shape_Area: 0.05012202686,
          },
        },
        {
          arcs: [[23, 24, 25, -13, -20, -22]],
          type: "Polygon",
          properties: {
            name: "Ifo",
            id: "OG007",
            nameAbbr: "Ifo",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.14568438808,
            Shape_Area: 0.03967203508,
          },
        },
        {
          arcs: [[26, 27, 28]],
          type: "Polygon",
          properties: {
            name: "Ijebu North-East",
            id: "OG008",
            nameAbbr: "Ijebu North-East",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.46540531576,
            Shape_Area: 0.01038144992,
          },
        },
        {
          arcs: [[29, 30, 31, 32, -29, 33, 34]],
          type: "Polygon",
          properties: {
            name: "Ijebu East",
            id: "OG009",
            nameAbbr: "Ijebu East",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.14655023401,
            Shape_Area: 0.17968585846,
          },
        },
        {
          arcs: [[-34, -28, 35, 36, 37, 38]],
          type: "Polygon",
          properties: {
            name: "Ijebu North",
            id: "OG010",
            nameAbbr: "Ijebu North",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.20149227342,
            Shape_Area: 0.069034357,
          },
        },
        {
          arcs: [[-27, -33, 39, 40, -36]],
          type: "Polygon",
          properties: {
            name: "Ijebu Ode",
            id: "OG011",
            nameAbbr: "Ijebu Ode",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.82882264857,
            Shape_Area: 0.01449427241,
          },
        },
        {
          arcs: [[41, 42, 43, 44]],
          type: "Polygon",
          properties: {
            name: "Ikenne",
            id: "OG012",
            nameAbbr: "Ikenne",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.48903391315,
            Shape_Area: 0.01156099592,
          },
        },
        {
          arcs: [[45, -9, -18, 46]],
          type: "Polygon",
          properties: {
            name: "Imeko Afon",
            id: "OG013",
            nameAbbr: "Imeko Afon",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.01384070575,
            Shape_Area: 0.12919850434,
          },
        },
        {
          arcs: [[-21, -11, 47, -16]],
          type: "Polygon",
          properties: {
            name: "Ipokia",
            id: "OG014",
            nameAbbr: "Ipokia",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.16758050288,
            Shape_Area: 0.03864065108,
          },
        },
        {
          arcs: [[48, 49, -43, 50, -24, -23, -6, -3, 51]],
          type: "Polygon",
          properties: {
            name: "Obafemi Owode",
            id: "OG015",
            nameAbbr: "Obafemi Owode",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.6969687173,
            Shape_Area: 0.11529459022,
          },
        },
        {
          arcs: [[52, -52, -2, -5, 53]],
          type: "Polygon",
          properties: {
            name: "Odeda",
            id: "OG016",
            nameAbbr: "Odeda",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.65997260227,
            Shape_Area: 0.11403725062,
          },
        },
        {
          arcs: [[-41, 54, 55, -45, 56, -37]],
          type: "Polygon",
          properties: {
            name: "Odogbolu",
            id: "OG017",
            nameAbbr: "Odogbolu",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.92982134371,
            Shape_Area: 0.04432935917,
          },
        },
        {
          arcs: [[57, -31, 58]],
          type: "Polygon",
          properties: {
            name: "Ogun Waterside",
            id: "OG018",
            nameAbbr: "Ogun Waterside",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.64506929021,
            Shape_Area: 0.07800831199,
          },
        },
        {
          arcs: [[-38, -57, -44, -50, 59]],
          type: "Polygon",
          properties: {
            name: "Remo North",
            id: "OG019",
            nameAbbr: "Remo North",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.5168954698,
            Shape_Area: 0.01338286032,
          },
        },
        {
          arcs: [[-56, 60, -25, -51, -42]],
          type: "Polygon",
          properties: {
            name: "Shagamu",
            id: "OG020",
            nameAbbr: "Shagamu",
            parent: "Ogun",
            parentAbbr: "OG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03800913681,
            Shape_Area: 0.0502305021,
          },
        },
      ],
    },
  },
};

const osun = {
  type: "Topology",
  arcs: [
    [
      [24505, 3874],
      [-200, -218],
      [-365, -48],
      [-490, 63],
      [-387, 204],
      [-411, 354],
      [-571, 499],
      [-483, 110],
      [-193, 98],
      [-44, 198],
      [-462, 185],
      [-604, 72],
      [-403, -67],
    ],
    [
      [19892, 5324],
      [-102, 445],
      [-16, 618],
      [171, 194],
      [261, 355],
      [237, 119],
      [212, 227],
      [0, 141],
    ],
    [
      [20655, 7423],
      [1, 327],
      [210, 492],
      [61, 475],
      [330, 476],
      [239, 247],
      [347, 659],
      [-21, 354],
      [-175, 403],
      [-374, 591],
      [-184, 654],
      [-66, 524],
      [-147, 498],
      [-24, 544],
      [-46, 517],
      [-41, 408],
      [227, 702],
      [97, 182],
      [354, 306],
      [227, 295],
      [186, 302],
      [114, 385],
      [4, 251],
      [3, 190],
      [-2, 185],
    ],
    [
      [21975, 17390],
      [52, 11],
      [170, 99],
      [284, 97],
      [242, 30],
      [250, 63],
      [130, 74],
    ],
    [
      [23103, 17764],
      [5, -15],
      [77, -232],
      [70, -286],
      [121, -409],
      [-71, -237],
      [-35, -67],
      [64, -219],
      [164, -274],
      [96, -321],
      [123, -266],
      [123, -281],
      [78, -198],
      [70, -232],
      [44, -218],
      [-74, -372],
      [-97, -223],
      [-125, -208],
      [-146, -317],
      [-107, -378],
      [-65, -318],
      [-78, -223],
      [29, -319],
      [70, -205],
      [110, -280],
      [78, -144],
      [129, -342],
      [190, -349],
      [191, -248],
      [177, -288],
      [131, -213],
      [59, -76],
      [84, -218],
      [126, -131],
      [118, -63],
    ],
    [
      [24832, 9594],
      [-23, -53],
      [-51, -223],
      [-22, -530],
      [5, -136],
      [-111, -229],
      [-163, -494],
      [42, -721],
      [60, -443],
      [41, -253],
      [76, -476],
      [-146, -698],
      [-45, -305],
      [53, -443],
      [55, -334],
      [-59, -339],
      [-39, -43],
    ],
    [
      [16917, 21949],
      [26, -14],
      [159, -159],
      [226, -126],
      [140, -104],
      [169, 65],
      [287, -141],
      [144, -219],
      [152, -166],
      [52, -137],
      [72, -96],
      [86, -90],
      [132, -179],
      [45, -102],
      [20, -41],
      [40, -49],
    ],
    [
      [18667, 20391],
      [-62, -46],
      [-61, -53],
      [-54, 1],
      [-87, 8],
      [-141, 3],
      [-128, -5],
      [-148, 2],
      [-68, -19],
      [-61, -46],
      [-34, -40],
      [-21, -68],
      [38, -109],
      [87, -49],
      [240, -99],
      [300, -196],
      [173, -91],
      [219, -207],
      [165, -180],
      [57, -184],
      [157, -281],
      [25, -122],
      [186, -173],
      [239, -181],
      [81, 26],
      [58, -37],
    ],
    [
      [19827, 18245],
      [108, -68],
      [189, -363],
      [57, -232],
      [-15, -122],
      [24, -197],
      [24, -163],
      [43, -266],
      [119, -90],
      [101, -29],
      [363, 14],
      [265, 206],
      [186, 241],
      [75, 94],
      [249, 16],
      [116, 52],
      [244, 52],
    ],
    [
      [20655, 7423],
      [-153, -16],
      [-220, 65],
      [-314, 134],
      [-311, 324],
      [-313, 189],
      [-76, 313],
      [9, 143],
      [6, 339],
      [-16, 293],
      [-94, 402],
      [-62, 327],
      [118, 249],
      [124, 188],
      [91, 188],
      [145, 283],
      [31, 251],
      [-132, 525],
      [-175, 444],
      [-216, 363],
      [-73, 83],
      [-192, 194],
      [-5, 7],
    ],
    [
      [18827, 12711],
      [-126, 178],
      [-144, 260],
      [-110, 260],
      [-209, 384],
      [-160, 50],
      [-10, 68],
    ],
    [
      [18068, 13911],
      [-9, 55],
      [-71, 130],
      [106, -42],
      [55, 19],
      [49, 155],
      [21, 54],
      [-130, 288],
      [-144, 253],
      [-97, 219],
      [-190, 289],
      [-85, 191],
      [-106, 111],
      [-279, 201],
      [-219, 153],
      [-67, 15],
      [-367, 169],
      [-81, -19],
      [-280, 141],
    ],
    [
      [16174, 16293],
      [-77, 273],
      [-89, 273],
      [-84, 212],
      [-71, 198],
      [-17, 238],
      [-208, 438],
      [-158, 199],
      [-138, 159],
      [-248, 52],
      [-223, -17],
      [-207, -74],
    ],
    [
      [14654, 18244],
      [12, 44],
      [70, 141],
      [63, 175],
      [2, 14],
    ],
    [
      [14801, 18618],
      [127, 59],
      [251, 138],
      [319, 185],
      [211, 186],
      [204, 153],
      [119, 263],
      [72, 318],
      [64, 182],
      [-37, 265],
      [16, 142],
      [-36, 252],
      [-94, 141],
    ],
    [
      [16017, 20902],
      [-77, 114],
      [-45, 143],
      [-111, 178],
      [1, 82],
      [-131, 233],
      [-58, 130],
      [53, 49],
    ],
    [
      [15649, 21831],
      [109, -38],
      [13, -8],
      [40, -14],
      [183, 85],
      [95, 67],
      [249, -5],
      [81, 53],
      [34, 27],
      [27, 20],
      [14, 20],
      [355, -101],
      [54, -1],
      [14, 13],
    ],
    [
      [14654, 18244],
      [-38, -146],
      [-90, -147],
      [-219, -234],
      [-244, -132],
      [-165, -255],
      [-469, -325],
      [-434, -196],
      [-440, -169],
      [-453, -196],
      [-333, -225],
      [-158, -221],
      [-87, -352],
      [-160, -323],
      [-601, -227],
      [-233, -234],
      [-51, -264],
      [34, -387],
      [29, -293],
      [109, -348],
      [-57, -182],
      [-125, -236],
      [-56, -148],
      [-71, -216],
      [-70, -175],
      [-151, -235],
      [-40, -346],
      [223, -404],
      [258, -208],
      [239, -222],
      [307, -168],
      [95, -341],
      [-161, -371],
      [-132, -255],
      [-204, -153],
      [36, -279],
      [102, -355],
      [95, -382],
      [47, -394],
      [7, 0],
      [11, -143],
      [-11, -265],
      [-13, -380],
      [-48, -74],
      [-53, 28],
      [-255, 5],
      [-32, -305],
      [-11, -285],
      [72, -517],
      [-41, -454],
      [-14, -441],
      [-8, -496],
      [-50, -230],
      [127, -423],
      [-252, -186],
      [13, -496],
      [-178, -173],
      [-166, -323],
      [-145, -208],
      [-120, -399],
      [-173, -248],
      [-37, -271],
      [76, -245],
      [135, -390],
      [-71, -223],
      [-11, -257],
      [4, -231],
      [-50, -158],
    ],
    [
      [9692, 712],
      [-9, 29],
      [-90, 273],
      [-17, 164],
      [-113, 518],
      [-176, 343],
      [-111, 199],
      [-306, 671],
      [-208, 473],
      [-803, 265],
      [-794, 14],
      [-459, -94],
      [-204, -160],
      [-461, -230],
      [-265, -138],
      [-422, -319],
      [-148, -44],
      [-278, -84],
      [-503, 124],
      [-123, 328],
      [-46, 477],
      [33, 387],
      [-14, 394],
      [-127, 451],
      [-243, 399],
      [-441, 184],
      [-229, -64],
      [-178, -174],
      [-254, -288],
      [-284, -124],
      [-238, -125],
      [-284, -90],
      [-252, -200],
      [-230, 22],
      [2, 16],
      [149, 935],
      [145, 630],
      [83, 597],
      [187, 758],
      [185, 537],
    ],
    [
      [2166, 7766],
      [221, -137],
      [252, -202],
      [193, -139],
      [119, -144],
      [152, -132],
      [181, -57],
      [134, -16],
      [149, 79],
      [157, 113],
      [323, 55],
      [170, 99],
      [197, 146],
      [324, 69],
      [297, 104],
      [183, 58],
      [316, -13],
      [338, 110],
      [338, 130],
      [250, 104],
      [304, 104],
      [164, 126],
      [86, 318],
      [97, 208],
      [146, 297],
      [104, 208],
      [104, 189],
      [110, 147],
      [-16, 265],
      [71, 47],
    ],
    [
      [7630, 9902],
      [99, 66],
      [224, 118],
      [249, 9],
      [143, 113],
      [142, 79],
      [237, 64],
      [69, 135],
      [-58, 191],
      [-57, 218],
      [-57, 198],
      [2, 115],
      [-206, 146],
      [-12, 68],
      [-46, 83],
      [0, -7],
      [-72, 151],
      [-157, 281],
      [-137, 226],
      [29, 129],
      [55, 128],
      [49, 94],
      [56, 128],
      [-45, 123],
      [-46, 69],
      [-133, 104],
      [-59, 123],
      [35, 94],
      [49, 81],
      [41, 47],
      [35, 74],
      [-26, 82],
      [2, 102],
      [-99, 123],
      [-60, 49],
      [-6, 41],
      [-321, 94],
      [-215, 3],
      [-114, -5],
      [-268, 100],
      [-147, 23],
      [-148, 29],
      [-47, 22],
      [-13, 12],
    ],
    [
      [6597, 14025],
      [89, 68],
      [170, 153],
      [159, 255],
      [172, 228],
      [98, 270],
      [125, 242],
      [131, 175],
      [111, 222],
      [119, 324],
      [-90, 232],
      [-104, 239],
      [-110, 267],
      [146, 310],
      [126, 297],
      [233, 247],
      [104, 208],
      [347, 266],
      [265, 179],
      [218, 166],
      [61, 39],
      [-37, 232],
      [117, 188],
      [176, 45],
      [7, -1],
      [69, 101],
      [350, 55],
      [108, 19],
      [55, 11],
    ],
    [
      [9812, 19062],
      [77, -156],
      [55, -334],
      [51, -218],
      [117, -233],
      [246, -187],
      [208, -11],
      [343, 42],
      [270, 29],
      [371, 75],
      [338, 137],
      [215, 3],
      [281, -100],
      [450, -21],
      [379, 136],
      [485, 60],
      [505, 73],
      [345, 143],
      [253, 118],
    ],
    [
      [9800, 19620],
      [-1, -74],
      [-49, -88],
      [-14, -34],
      [-36, -135],
      [112, -227],
    ],
    [
      [6597, 14025],
      [-68, -52],
      [-143, -99],
      [-210, -112],
      [-307, 155],
      [90, 161],
      [29, 122],
      [-151, 227],
      [-171, 213],
      [-92, 144],
      [-199, 180],
      [-221, 65],
      [-288, 32],
      [-329, 67],
      [-141, -18],
      [-54, -40],
      [-257, -57],
      [-163, -112],
      [-116, -80],
      [-148, -18],
      [-222, -43],
      [-43, -3],
    ],
    [
      [3393, 14757],
      [34, 400],
      [74, 474],
      [-406, 232],
      [-210, 330],
      [-126, 169],
    ],
    [
      [2759, 16362],
      [168, 212],
      [253, 254],
      [165, 241],
      [184, 201],
      [333, 191],
      [226, 241],
      [157, 160],
      [474, 196],
      [345, 163],
      [204, 133],
      [271, 90],
      [108, 73],
      [223, 91],
      [184, 119],
      [197, 99],
      [116, 127],
      [203, 71],
      [232, 206],
      [106, 93],
    ],
    [
      [6908, 19323],
      [30, 27],
      [55, 54],
      [319, 205],
      [352, 170],
      [365, 82],
      [222, 57],
      [143, 113],
      [156, 72],
      [68, 58],
    ],
    [
      [8618, 20161],
      [139, -177],
      [178, -193],
      [73, -49],
      [220, -126],
      [133, -63],
      [135, -23],
      [141, 5],
      [95, 39],
      [68, 46],
    ],
    [
      [23836, 26290],
      [-46, 22],
      [-107, 22],
      [-115, -25],
      [-7, 0],
      [-27, -6],
      [-142, -106],
      [-224, -132],
      [-90, -55],
      [-60, -38],
      [-78, 28],
      [-182, 65],
      [-121, 83],
      [-12, 7],
      [-97, 81],
    ],
    [
      [22528, 26236],
      [-16, 46],
      [-6, 18],
      [-152, 145],
      [-146, 125],
      [-54, 28],
      [-133, 91],
      [-193, 105],
      [-286, 168],
      [-113, 117],
      [-153, 118],
      [-144, 193],
      [-159, 193],
      [-98, 144],
      [-159, 165],
      [-138, 200],
      [-38, 136],
      [-30, 238],
      [-91, 212],
      [16, 109],
      [-13, 75],
    ],
    [
      [20422, 28862],
      [-3, 18],
    ],
    [
      [20419, 28880],
      [164, -69],
      [113, -49],
      [81, -29],
      [185, -159],
      [206, -180],
      [99, -97],
      [142, 59],
      [144, 133],
      [82, 107],
      [61, 54],
      [130, 160],
      [138, 161],
      [43, 216],
      [102, 73],
      [90, 141],
      [83, 135],
      [116, 93],
      [225, 213],
      [30, 162],
      [63, 203],
      [43, 142],
      [30, 183],
      [17, 257],
      [-119, 84],
      [-56, 108],
    ],
    [
      [22631, 30981],
      [151, -35],
      [6, 0],
      [27, -28],
      [119, -97],
      [159, -206],
      [79, -63],
      [180, -104],
      [80, -63],
      [91, -171],
      [-56, -149],
      [37, -177],
      [32, -109],
      [177, -275],
      [165, -226],
      [105, -192],
      [145, -152],
      [71, -185],
      [125, -178],
      [77, -239],
      [77, -232],
      [51, -117],
      [89, 60],
      [103, -267],
      [63, -265],
      [57, -232],
      [-7, -41],
      [-9, -102],
      [-36, -128],
      [-81, -39],
      [-108, -46],
      [-96, -100],
      [-101, -5],
      [-108, -32],
      [-155, -59],
      [-101, -12],
      [-115, -25],
      [-35, -47],
      [-34, -60],
      [-22, -115],
      [-7, -21],
      [6, -7],
      [4, -150],
    ],
    [
      [22528, 26236],
      [-30, 24],
      [-147, 36],
      [-30, -4],
      [-51, -8],
      [-15, -4],
      [-221, -60],
      [-144, 69],
      [-136, 65],
      [-160, 84],
      [-182, -31],
      [-109, -62],
      [-41, -23],
      [-368, 108],
      [-205, 28],
      [-23, 3],
      [-345, -123],
      [-311, -131],
      [-326, -184],
      [-205, -214],
      [-69, -281],
      [-31, -125],
      [-58, -209],
      [-77, -243],
      [-87, -345],
      [-45, -332],
      [-178, -217],
      [-35, -44],
      [-131, -249],
      [-52, -164],
      [-27, -86],
      [-144, -194],
      [-96, 11],
      [-112, 13],
      [-175, -51],
      [-195, 43],
      [-290, -69],
      [-129, -66],
      [-55, -67],
      [-10, -3],
      [-91, -32],
    ],
    [
      [17392, 23099],
      [-47, -17],
      [-26, 62],
      [-53, 62],
      [-39, 89],
      [-125, 165],
      [-65, 178],
      [-45, 89],
      [-65, 150],
      [-63, 246],
      [9, 176],
      [10, 217],
      [20, 153],
    ],
    [
      [16903, 24669],
      [12, 98],
      [8, 101],
      [3, 31],
    ],
    [
      [16926, 24899],
      [128, 164],
      [170, 133],
      [265, 179],
      [144, 167],
      [197, 166],
      [305, 144],
      [212, 187],
      [123, 127],
      [287, 293],
      [71, 244],
      [170, 112],
      [75, 101],
      [55, 74],
      [57, 216],
      [273, 212],
      [14, 61],
      [89, 121],
      [104, 148],
      [186, 288],
      [69, 135],
      [152, 221],
      [116, 148],
      [123, 120],
      [62, 73],
      [36, 108],
      [13, 21],
    ],
    [
      [16017, 20902],
      [-52, -9],
      [-250, -64],
      [-174, 30],
      [-288, 80],
      [-148, -25],
      [-181, 31],
      [-275, 59],
      [-249, 4],
      [-214, 31],
      [-236, -10],
      [-202, -17],
      [-155, -11],
      [-134, 30],
      [-186, 112],
      [-234, 105],
      [-173, 58],
      [-1, -7],
      [-167, 37],
      [-54, 21],
      [-208, 31],
      [-248, -16],
      [-136, -46],
      [-47, -19],
      [-107, 42],
      [-20, 9],
    ],
    [
      [11878, 21358],
      [16, 188],
      [-30, 218],
      [60, 339],
      [44, 243],
      [150, 167],
      [218, 160],
      [121, -23],
      [201, 4],
      [457, -69],
      [228, -4],
      [102, 39],
      [195, 37],
      [75, 46],
      [169, 52],
      [189, 85],
      [156, 79],
      [129, 65],
      [175, 38],
      [230, 84],
      [95, 33],
      [23, 16],
    ],
    [
      [14881, 23155],
      [-16, -24],
      [-42, -73],
      [193, -153],
      [38, -136],
      [18, -96],
      [59, -130],
      [152, -145],
      [146, -138],
      [5, -75],
      [193, -159],
      [-16, -143],
      [6, -41],
      [32, -11],
    ],
    [
      [9800, 19620],
      [0, 27],
      [15, 102],
      [49, 128],
      [11, 16],
    ],
    [
      [9875, 19893],
      [30, 44],
      [37, 156],
      [178, 214],
      [35, 108],
      [89, 67],
      [149, 113],
      [122, 18],
      [208, 23],
      [129, 19],
      [128, 24],
      [74, 13],
      [229, 57],
      [61, 26],
      [88, 46],
      [68, 26],
      [204, 112],
      [82, 94],
      [75, 46],
      [55, 94],
      [-38, 165],
    ],
    [
      [13047, 25278],
      [-18, -44],
      [-69, -101],
      [-43, -169],
      [-75, -107],
      [-56, -122],
      [-43, -155],
      [-30, -176],
      [71, -191],
      [92, -144],
      [107, -23],
      [80, -42],
      [120, -56],
      [47, -21],
      [54, -28],
      [28, 67],
      [-13, 75],
      [43, 128],
      [55, 88],
      [77, 175],
      [159, 269],
      [82, 114],
      [177, 159],
      [130, 100],
      [169, 99],
      [130, 93],
      [211, 186],
      [206, 255],
      [122, 73],
      [123, 133],
      [185, 201],
      [129, 93],
      [116, 120],
      [76, 100],
      [27, 69],
    ],
    [
      [15516, 26496],
      [1, -8],
      [23, -238],
      [16, -279],
      [17, -149],
      [-18, -299],
      [30, -251],
      [-22, -75],
      [4, -170],
      [4, -210],
      [-84, -162],
      [-162, -71],
      [-82, -67],
      [-143, -106],
      [-88, -80],
      [-96, -73],
      [-82, -94],
      [-42, -87],
      [-8, -123],
      [-36, -114],
      [31, -137],
      [59, -150],
      [9, -30],
    ],
    [
      [14847, 23523],
      [29, -100],
      [-8, -101],
      [45, -123],
      [-32, -44],
    ],
    [
      [9875, 19893],
      [-37, 32],
      [-138, 172],
      [-137, 315],
      [-98, 164],
      [-98, 131],
      [-150, 315],
      [-95, 341],
      [-119, 172],
      [-158, 186],
      [-71, 171],
      [77, 202],
      [122, 467],
      [89, 140],
      [172, 235],
      [104, 209],
      [157, 147],
      [193, 247],
      [163, 147],
      [97, 148],
      [-81, 374],
      [-189, 431],
      [14, 14],
      [123, 160],
      [125, 209],
      [143, 140],
      [171, 167],
      [69, 80],
      [68, 80],
      [83, 155],
      [43, 142],
      [-5, 89],
    ],
    [
      [10512, 25875],
      [199, 87],
      [365, 129],
      [118, 209],
      [98, 250],
      [72, 171],
    ],
    [
      [11364, 26721],
      [21, -27],
      [224, -302],
      [191, -248],
      [211, -227],
      [245, -249],
      [99, -117],
      [120, -90],
      [132, -118],
      [67, -29],
      [47, -21],
      [174, -57],
      [61, 13],
      [91, 29],
    ],
    [
      [8618, 20161],
      [-13, 16],
      [-124, 213],
      [-96, 347],
      [-83, 273],
      [-108, 328],
      [-104, 253],
      [-92, 158],
      [-71, 164],
      [-118, 233],
      [84, 175],
      [76, 101],
      [192, 247],
      [136, 141],
      [124, 140],
      [83, 128],
      [62, 121],
      [-148, 9],
      [-288, 80],
      [-100, 29],
      [-248, 38],
      [-220, 139],
      [-152, 159],
      [-237, 289],
      [-323, 427],
      [-291, 270],
      [-233, 187],
      [-173, 84],
      [-162, -17],
      [-256, -44],
      [-241, 66],
      [-195, 17],
      [-53, -6],
      [-337, -22],
      [-282, -8],
      [-204, -92],
      [-202, -78],
      [-149, -52],
      [-217, -91],
      [-121, -39],
      [-217, -57],
      [-155, -52],
      [-175, -31],
      [-122, -45],
      [-34, -40],
      [-176, -52],
      [-236, -43],
      [-141, 23],
      [-88, -33],
      [-277, -83],
      [-140, 56],
      [-374, 190],
      [-200, 92],
      [-242, 50],
    ],
    [
      [1157, 24519],
      [24, 485],
      [-20, 436],
      [156, 459],
      [284, 131],
      [173, -146],
      [30, -10],
      [572, -204],
      [690, -168],
      [557, -105],
      [290, 22],
      [421, 265],
      [385, 68],
      [231, 180],
      [133, 290],
      [65, 291],
      [-16, 238],
      [-38, 136],
      [-5, 143],
      [58, 285],
      [353, 137],
      [143, 133],
      [110, 168],
      [411, 469],
      [192, 187],
      [146, 283],
      [227, 329],
      [238, 139],
      [300, -142],
      [359, -284],
      [413, -239],
      [347, -210],
      [355, -536],
      [290, -399],
      [235, -453],
      [302, -474],
      [247, -548],
      [258, -229],
      [292, 165],
      [147, 64],
    ],
    [
      [18827, 12711],
      [-110, -46],
      [-55, -108],
      [-83, -155],
      [-237, -97],
      [-116, -93],
      [-68, -33],
      [-163, -113],
      [-74, -46],
      [-190, -112],
      [-282, 39],
      [-156, -86],
      [-211, -8],
      [-31, -1],
      [-15, -82],
      [-63, -168],
      [-22, -68],
      [37, -204],
      [105, -192],
      [30, -225],
      [31, -156],
      [111, -199],
      [-36, -135],
      [-15, -156],
      [23, -191],
      [-75, -100],
      [-90, -148],
      [-63, -128],
      [-68, -87],
      [-63, -155],
      [58, -178],
      [-29, -135],
      [-11, -230],
      [-9, -150],
      [-56, -128],
      [-104, -181],
      [-76, -121],
      [-50, -223],
      [-170, -99],
      [-148, -32],
      [-52, 123],
      [-16, 272],
      [31, 217],
      [29, 156],
      [29, 155],
      [29, 129],
      [-24, 177],
      [-298, 263],
      [0, 7],
      [56, 114],
      [90, 182],
      [89, 114],
      [83, 141],
      [77, 161],
      [76, 162],
      [29, 142],
      [-45, 150],
      [-157, 241],
      [-183, 308],
      [-86, 131],
      [-27, 30],
      [-78, 87],
      [-126, 97],
      [-101, -18],
      [-82, -26],
      [-74, -40],
      [-108, -32],
      [-136, -86],
      [-40, 1],
    ],
    [
      [15268, 11339],
      [137, 174],
      [263, 450],
      [66, 393],
      [38, 278],
      [35, 225],
    ],
    [
      [15807, 12859],
      [57, -57],
      [549, 0],
      [550, 334],
      [220, 333],
      [220, 333],
      [220, 334],
      [445, -225],
    ],
    [
      [16518, 1141],
      [-12, -8],
      [-360, -191],
      [-269, -43],
      [-314, -267],
      [-42, 4],
      [37, 153],
      [106, 324],
      [-147, 64],
      [-437, 8],
      [-501, -196],
      [-361, 129],
      [-660, -63],
      [-461, -183],
      [-150, -126],
      [-142, -52],
      [-244, -78],
      [-261, 32],
      [-278, 297],
      [-519, -11],
      [-467, -176],
      [-158, -194],
      [-427, -231],
      [-206, -228],
      [-183, -105],
      [-283, 406],
      [-87, 306],
    ],
    [
      [16174, 16293],
      [96, -280],
      [42, -347],
      [29, -238],
      [-37, -230],
      [-73, -366],
      [-138, -215],
      [-157, -180],
      [-118, -195],
      [-110, -181],
      [2, -306],
      [70, -232],
      [49, -286],
      [-19, -359],
      [-3, -19],
    ],
    [
      [15268, 11339],
      [-90, -114],
      [-31, -284],
      [-182, -446],
      [-308, -340],
      [-178, -187],
      [-239, -268],
      [-227, -288],
      [82, -320],
      [-79, -332],
      [-88, -480],
      [-20, -367],
      [-24, -271],
      [-122, -65],
      [38, -137],
      [-104, -188],
      [-150, -100],
      [-108, -86],
      [-225, -166],
      [-104, -188],
      [-79, -318],
      [-11, -305],
      [-85, -243],
      [-97, -202],
      [-7, 0],
      [-52, -305],
      [-78, -236],
      [-157, -174],
      [-151, -181],
      [-76, -134],
      [-57, -196],
      [52, -136],
      [99, -77],
      [148, 11],
      [140, -97],
      [114, -43],
      [105, -124],
      [100, -56],
      [90, -246],
      [184, -268],
      [243, 50],
      [169, 25],
      [155, 78],
      [224, 112],
      [281, -114],
      [222, 17],
      [194, -17],
      [95, -2],
      [125, -165],
      [1, -312],
      [63, -279],
      [50, -232],
      [136, -335],
      [252, -242],
      [238, -201],
      [227, -133],
      [239, -207],
      [251, -236],
      [62, -48],
    ],
    [
      [19892, 5324],
      [-83, -13],
      [-373, -198],
      [-75, -73],
      [-8, -68],
      [-258, -145],
      [-388, -279],
      [-574, -527],
      [-342, -348],
      [-312, -572],
      [-156, -487],
      [-58, -325],
      [-44, -210],
      [-223, -458],
      [-220, -309],
      [-260, -171],
    ],
    [
      [29115, 28492],
      [-69, -66],
      [-517, -372],
      [-467, -142],
      [-557, -303],
      [-321, -320],
      [-444, -394],
      [-135, -214],
    ],
    [
      [26605, 26681],
      [-19, 18],
      [-99, 90],
      [-79, 89],
      [-73, 83],
      [-64, 212],
      [-25, 82],
      [21, 81],
      [90, 161],
      [103, 134],
      [91, 189],
      [117, 181],
      [130, 140],
      [75, 94],
      [69, 80],
      [149, 93],
      [89, 80],
      [136, 79],
      [41, 53],
      [-45, 130],
      [-65, 151],
      [-18, 156],
      [-73, 56],
      [-37, 204],
      [83, 155],
      [76, 107],
      [110, 188],
      [97, 148],
      [185, 221],
      [76, 94],
      [129, 140],
      [171, 167],
      [225, 166],
      [99, 297],
      [90, 182],
      [63, 89],
    ],
    [
      [28523, 31271],
      [689, -48],
      [786, 381],
      [167, -64],
      [16, -195],
      [7, -77],
      [-176, -521],
      [-249, -838],
      [-105, -624],
      [-188, -452],
      [-355, -341],
    ],
    [
      [16926, 24899],
      [12, 112],
      [79, 284],
      [44, 196],
      [-42, 326],
      [-4, 211],
      [38, 257],
      [-30, 245],
      [-55, 300],
      [-72, 130],
      [-9, 278],
      [-127, 64],
      [-208, 37],
      [-214, 72],
      [-112, 144],
      [-166, 118],
      [-271, 290],
      [-117, 240],
      [-10, 18],
    ],
    [
      [15662, 28221],
      [51, 15],
      [116, 113],
      [128, 46],
      [103, 107],
      [115, 73],
      [89, 73],
      [61, 46],
      [189, 24],
      [209, 23],
      [162, 72],
      [208, -44],
      [314, -107],
      [156, 45],
      [309, 8],
      [336, -19],
      [229, 57],
      [202, 30],
      [271, 84],
      [96, 161],
      [64, 169],
      [115, 100],
      [128, 11],
      [195, 4],
      [182, -10],
      [294, -107],
      [101, 5],
      [54, 26],
      [194, -64],
      [26, -48],
      [65, -137],
      [-8, -82],
      [3, -15],
    ],
    [
      [26605, 26681],
      [-72, -115],
      [-174, -333],
      [-55, -105],
      [-220, -744],
      [-49, -105],
    ],
    [
      [26035, 25279],
      [-56, -9],
      [-169, -51],
      [-254, 85],
      [-93, 104],
      [-113, 49],
      [-227, 99],
      [-85, 151],
      [-179, 180],
      [-65, 150],
      [-168, 23],
      [-107, -5],
      [-135, -25],
      [-148, 3],
      [-173, 84],
      [-139, 132],
      [-88, 41],
    ],
    [
      [22631, 30981],
      [-29, 56],
      [-163, 363],
      [29, 115],
      [-11, 116],
      [-50, 267],
    ],
    [
      [22407, 31898],
      [-6, 32],
      [-29, 293],
      [-4, 91],
      [119, 31],
      [468, -212],
      [659, 2],
      [609, 228],
      [326, 184],
      [617, 269],
      [479, 73],
      [202, 51],
      [181, -58],
      [464, -15],
      [381, -203],
      [173, -499],
      [92, -179],
      [58, -177],
      [373, -646],
      [154, -2],
      [709, 117],
      [91, -7],
    ],
    [
      [19827, 18245],
      [111, 109],
      [184, 119],
      [190, 152],
      [102, 80],
      [169, 45],
      [75, 80],
      [62, 47],
      [114, 25],
      [156, 65],
      [110, 127],
      [96, 154],
      [177, 154],
      [184, 91],
      [95, 108],
      [190, 105],
      [142, 38],
      [110, 121],
      [102, 140],
      [59, 74],
    ],
    [
      [22255, 20079],
      [36, -48],
      [84, -212],
      [117, -246],
      [170, -336],
      [82, -320],
      [150, -288],
      [-11, -272],
      [103, -259],
      [117, -334],
    ],
    [
      [21348, 20238],
      [92, -43],
      [182, 3],
      [269, 43],
      [189, 18],
      [86, -63],
      [89, -117],
    ],
    [
      [18667, 20391],
      [39, -48],
      [27, 0],
      [121, -22],
      [233, -93],
      [128, -2],
      [147, -57],
      [41, 0],
      [167, -44],
      [222, 23],
      [200, -78],
      [329, -19],
      [163, 58],
      [275, 2],
      [189, -10],
      [242, 37],
      [129, 66],
      [29, 34],
    ],
    [
      [13047, 25278],
      [10, 3],
      [41, 40],
      [14, 20],
      [54, 53],
      [125, 222],
      [143, 127],
      [137, 167],
      [47, -14],
      [7, -7],
      [73, -29],
      [75, 81],
      [124, 147],
      [159, 255],
      [133, 331],
      [30, 237],
      [79, 283],
      [37, 251],
      [78, 223],
      [91, 215],
      [151, 208],
      [83, 148],
      [89, 101],
      [77, 168],
      [34, 61],
      [49, 107],
      [12, 15],
    ],
    [
      [14999, 28691],
      [61, -84],
      [65, -123],
      [25, -123],
      [99, -144],
      [79, -97],
      [-1, -40],
      [46, -76],
      [19, -75],
      [32, -102],
      [-22, -115],
      [-22, -122],
      [2, -292],
      [-18, -244],
      [144, -294],
      [8, -264],
    ],
    [
      [7630, 9902],
      [-56, 34],
      [-208, 31],
      [-287, 114],
      [-227, 146],
      [-213, 119],
      [-308, 53],
      [-169, -52],
      [-303, -49],
      [-317, -55],
      [-391, -21],
      [-328, 40],
      [-300, 168],
      [-126, 104],
      [-100, 69],
      [-347, 129],
      [-202, 10],
      [-289, 18],
      [-257, -97],
      [-84, -202],
      [-149, -66],
      [-48, -67],
      [-256, 5],
      [-27, 2],
    ],
    [
      [2638, 10335],
      [48, 158],
      [11, 686],
      [18, 306],
      [80, 373],
      [97, 596],
      [155, 453],
      [164, 520],
      [61, 509],
      [92, 556],
      [13, 81],
      [16, 184],
    ],
    [
      [2166, 7766],
      [20, 58],
      [204, 526],
      [124, 623],
      [-40, 463],
      [63, 563],
      [101, 336],
    ],
    [
      [2759, 16362],
      [-171, 230],
      [-180, 526],
      [-177, 275],
      [-303, 447],
      [-270, 338],
      [-547, 336],
      [-211, 150],
      [-35, 24],
      [-378, 319],
      [-292, 277],
      [-127, 444],
      [-68, 416],
      [452, 454],
      [88, 67],
      [152, 334],
      [50, 111],
      [168, 391],
      [78, 638],
      [138, 659],
    ],
    [
      [1126, 22798],
      [11, -8],
      [371, -319],
      [254, -154],
      [219, -139],
      [145, -220],
      [261, -59],
      [317, 70],
      [309, 21],
      [189, 31],
      [262, -39],
      [308, -39],
      [179, -165],
      [399, -299],
      [123, -267],
      [93, -117],
      [245, -235],
      [124, -192],
      [306, -250],
      [263, -364],
      [291, -257],
      [200, -159],
      [340, -169],
      [281, -93],
      [281, -52],
      [11, -1],
    ],
    [
      [26035, 25279],
      [-188, -401],
      [-58, -665],
      [140, -460],
      [39, -128],
      [204, -744],
      [-131, -623],
      [-81, -221],
    ],
    [
      [25960, 22037],
      [-154, 25],
      [-268, 31],
      [-428, 137],
      [-270, -77],
      [-564, 64],
      [-329, 46],
      [-497, 22],
      [-309, -1],
      [-309, 32],
      [-231, -159],
      [-145, -242],
      [-158, -173],
      [-349, -388],
      [-152, -242],
      [-150, -194],
      [-107, -331],
      [-130, -154],
      [-36, -163],
      [-26, -32],
    ],
    [
      [16917, 21949],
      [88, 80],
      [42, 108],
      [55, 74],
      [56, 94],
      [48, 101],
      [29, 88],
      [61, 107],
      [56, 94],
      [35, 74],
      [35, 95],
      [-31, 190],
      [1, 45],
    ],
    [
      [15662, 28221],
      [-95, 181],
      [-75, 313],
      [-18, 163],
      [-125, 131],
      [-83, 155],
    ],
    [
      [15266, 29164],
      [202, 162],
      [49, 115],
      [95, 494],
      [-34, 354],
      [-90, 307],
      [-33, 477],
      [-88, 368],
      [-6, 9],
      [526, 105],
      [678, -121],
      [674, 77],
      [914, -22],
      [382, -136],
      [720, -6],
      [546, 66],
      [681, 83],
      [616, 234],
      [388, 293],
      [663, 233],
      [170, 45],
      [-1, -98],
      [30, -197],
      [59, -108],
    ],
    [
      [1126, 22798],
      [44, 208],
      [35, 536],
      [-48, 382],
      [-6, 476],
      [6, 119],
    ],
    [
      [16903, 24669],
      [-65, -44],
      [-136, -113],
      [-28, -33],
      [-42, -108],
      [-75, -114],
      [-36, -156],
      [-69, -87],
      [-14, -27],
      [-55, -73],
      [-49, -129],
      [0, -6],
      [-23, -156],
      [-49, -115],
      [-27, -6],
      [-54, -47],
      [-54, -5],
      [-101, -26],
      [-34, 1],
      [-40, -6],
      [-67, 41],
      [-59, 69],
      [-153, 112],
      [-60, 55],
      [-80, 29],
      [-13, -7],
      [-41, -20],
      [-68, -33],
      [-67, 1],
      [-68, -39],
      [-61, -6],
      [-87, -19],
      [-41, -26],
      [-135, -18],
      [-94, -32],
      [-11, -3],
    ],
    [
      [14999, 28691],
      [29, 39],
      [55, 87],
      [62, 94],
      [7, 0],
      [46, 198],
      [68, 55],
    ],
    [
      [25960, 22037],
      [-172, -475],
      [-178, -575],
      [131, -1103],
      [196, -1166],
      [385, -774],
      [240, -589],
      [220, -522],
      [188, -444],
      [307, -576],
      [142, -390],
      [101, -838],
      [152, -573],
      [333, -692],
      [59, -116],
      [210, -407],
      [18, -33],
      [63, -701],
      [-265, -132],
      [-634, -70],
      [-628, -173],
      [-210, -57],
      [-812, -279],
      [-209, -44],
      [-394, -272],
      [-283, -1239],
      [-88, -203],
    ],
    [
      [11364, 26721],
      [19, 45],
      [246, 206],
      [238, 173],
      [164, 133],
      [42, -124],
      [274, 166],
      [76, 73],
      [83, 196],
      [104, 161],
      [199, 228],
      [132, -186],
      [322, 375],
      [143, 120],
      [401, 225],
      [316, 48],
      [366, 130],
      [332, 137],
      [9, 7],
      [50, 36],
      [74, -119],
      [45, -60],
    ],
  ],
  transform: {
    scale: [0.00003431167025598621, 0.00003393965934488098],
    translate: [4.0285582906533035, 6.966766016836017],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Atakumosa East",
            id: "OS001",
            nameAbbr: "Atakumosa East",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.18021846097,
            Shape_Area: 0.0470019083,
          },
        },
        {
          arcs: [[6, 7, 8, -3, 9, 10, 11, 12, 13, 14, 15, 16]],
          type: "Polygon",
          properties: {
            name: "Atakumosa West",
            id: "OS002",
            nameAbbr: "Atakumosa West",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.40154421615,
            Shape_Area: 0.04719136417,
          },
        },
        {
          arcs: [[-14, 17, 18, 19, 20, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Aiyedaade",
            id: "OS003",
            nameAbbr: "Aiyedaade",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.19981942814,
            Shape_Area: 0.08564256225,
          },
        },
        {
          arcs: [[23, -22, 24, 25, 26, 27, 28]],
          type: "Polygon",
          properties: {
            name: "Aiyedire",
            id: "OS004",
            nameAbbr: "Aiyedire",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.75383010853,
            Shape_Area: 0.02297044666,
          },
        },
        {
          arcs: [[29, 30, 31, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Boluwaduro",
            id: "OS005",
            nameAbbr: "Boluwaduro",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.53650493685,
            Shape_Area: 0.01222897911,
          },
        },
        {
          arcs: [[34, 35, 36, 37, -31]],
          type: "Polygon",
          properties: {
            name: "Boripe",
            id: "OS006",
            nameAbbr: "Boripe",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.62186333955,
            Shape_Area: 0.0113035469,
          },
        },
        {
          arcs: [[-16, 38, 39, 40]],
          type: "Polygon",
          properties: {
            name: "Ede North",
            id: "OS007",
            nameAbbr: "Ede North",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.38285759728,
            Shape_Area: 0.00699661262,
          },
        },
        {
          arcs: [[-15, -23, -24, 41, 42, -39]],
          type: "Polygon",
          properties: {
            name: "Ede South",
            id: "OS008",
            nameAbbr: "Ede South",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.57470049054,
            Shape_Area: 0.01884088189,
          },
        },
        {
          arcs: [[43, 44, 45, -40, -43, 46, 47, 48]],
          type: "Polygon",
          properties: {
            name: "Egbedore",
            id: "OS009",
            nameAbbr: "Egbedore",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.90714911845,
            Shape_Area: 0.02460212472,
          },
        },
        {
          arcs: [[-47, -42, -29, 49, 50]],
          type: "Polygon",
          properties: {
            name: "Ejigbo",
            id: "OS010",
            nameAbbr: "Ejigbo",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.20304158862,
            Shape_Area: 0.03210929393,
          },
        },
        {
          arcs: [[51, 52, 53, -11]],
          type: "Polygon",
          properties: {
            name: "Ife East",
            id: "OS011",
            nameAbbr: "Ife East",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.5865223838,
            Shape_Area: 0.00833517407,
          },
        },
        {
          arcs: [[54, -18, -13, 55, -53, 56]],
          type: "Polygon",
          properties: {
            name: "Ife North",
            id: "OS012",
            nameAbbr: "Ife North",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.79027728342,
            Shape_Area: 0.07977401259,
          },
        },
        {
          arcs: [[-2, 57, -57, -52, -10]],
          type: "Polygon",
          properties: {
            name: "Ife South",
            id: "OS013",
            nameAbbr: "Ife South",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.36301041686,
            Shape_Area: 0.05724332845,
          },
        },
        {
          arcs: [[-12, -54, -56]],
          type: "Polygon",
          properties: {
            name: "Ife Central",
            id: "OS014",
            nameAbbr: "Ife Central",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.34921759112,
            Shape_Area: 0.00573703869,
          },
        },
        {
          arcs: [[58, 59, 60]],
          type: "Polygon",
          properties: {
            name: "Ifedayo",
            id: "OS015",
            nameAbbr: "Ifedayo",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.4742866503,
            Shape_Area: 0.00940335565,
          },
        },
        {
          arcs: [[-32, -38, 61, 62]],
          type: "Polygon",
          properties: {
            name: "Ifelodun",
            id: "OS016",
            nameAbbr: "Ifelodun",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.51088867626,
            Shape_Area: 0.01076245285,
          },
        },
        {
          arcs: [[-60, 63, 64, -34, 65, 66]],
          type: "Polygon",
          properties: {
            name: "Ila",
            id: "OS017",
            nameAbbr: "Ila",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.85116507414,
            Shape_Area: 0.02723172003,
          },
        },
        {
          arcs: [[67, 68, -4, -9]],
          type: "Polygon",
          properties: {
            name: "Ilesha East",
            id: "OS018",
            nameAbbr: "Ilesha East",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.35340363049,
            Shape_Area: 0.00678829767,
          },
        },
        {
          arcs: [[69, -68, -8, 70]],
          type: "Polygon",
          properties: {
            name: "Ilesha West",
            id: "OS019",
            nameAbbr: "Ilesha West",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.37051680265,
            Shape_Area: 0.00530560217,
          },
        },
        {
          arcs: [[71, 72, -44]],
          type: "Polygon",
          properties: {
            name: "Irepodun",
            id: "OS020",
            nameAbbr: "Irepodun",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.4009454396,
            Shape_Area: 0.00536623586,
          },
        },
        {
          arcs: [[-21, 73, 74, -25]],
          type: "Polygon",
          properties: {
            name: "Irewole",
            id: "OS021",
            nameAbbr: "Irewole",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.70412921629,
            Shape_Area: 0.02526337268,
          },
        },
        {
          arcs: [[-20, 75, -74]],
          type: "Polygon",
          properties: {
            name: "Isokan",
            id: "OS022",
            nameAbbr: "Isokan",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.52284252836,
            Shape_Area: 0.01601766624,
          },
        },
        {
          arcs: [[-27, 76, 77]],
          type: "Polygon",
          properties: {
            name: "Iwo",
            id: "OS023",
            nameAbbr: "Iwo",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.69149191557,
            Shape_Area: 0.02456999837,
          },
        },
        {
          arcs: [[-30, -65, 78, 79, -71, -7, 80, -35]],
          type: "Polygon",
          properties: {
            name: "Obokun",
            id: "OS024",
            nameAbbr: "Obokun",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.92807974092,
            Shape_Area: 0.04261234426,
          },
        },
        {
          arcs: [[-33, -63, 81, 82, -66]],
          type: "Polygon",
          properties: {
            name: "Odo Otin",
            id: "OS025",
            nameAbbr: "Odo Otin",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.75005457944,
            Shape_Area: 0.02277906394,
          },
        },
        {
          arcs: [[-28, -78, 83, -50]],
          type: "Polygon",
          properties: {
            name: "Ola Oluwa",
            id: "OS026",
            nameAbbr: "Ola Oluwa",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.77367514452,
            Shape_Area: 0.02879769101,
          },
        },
        {
          arcs: [[-62, -37, 84, -45, -73, 85, -82]],
          type: "Polygon",
          properties: {
            name: "Olorunda",
            id: "OS027",
            nameAbbr: "Olorunda",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.49520063503,
            Shape_Area: 0.00758910339,
          },
        },
        {
          arcs: [[86, -5, -69, -70, -80]],
          type: "Polygon",
          properties: {
            name: "Oriade",
            id: "OS028",
            nameAbbr: "Oriade",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.14497653343,
            Shape_Area: 0.04881070256,
          },
        },
        {
          arcs: [[-49, 87, -72]],
          type: "Polygon",
          properties: {
            name: "Orolu",
            id: "OS029",
            nameAbbr: "Orolu",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.38631344592,
            Shape_Area: 0.00711405021,
          },
        },
        {
          arcs: [[-81, -17, -41, -46, -85, -36]],
          type: "Polygon",
          properties: {
            name: "Osogbo",
            id: "OS030",
            nameAbbr: "Osogbo",
            parent: "Osun",
            parentAbbr: "OS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.32295110811,
            Shape_Area: 0.0045452417,
          },
        },
      ],
    },
  },
};

const oyo = {
  type: "Topology",
  arcs: [
    [
      [41147, 24071],
      [7, -221],
      [2, -279],
    ],
    [
      [41156, 23571],
      [-30, 11],
      [-173, 146],
      [-284, -131],
      [-156, -460],
      [20, -435],
      [-24, -485],
      [-6, -120],
      [6, -475],
      [48, -382],
      [-35, -536],
      [-44, -208],
      [-138, -659],
      [-78, -638],
      [-168, -391],
      [-50, -112],
    ],
    [
      [40044, 18696],
      [-14, -20],
      [-131, -202],
      [-273, -267],
      [-163, -112],
      [-223, -30],
      [-680, -29],
      [-181, -24],
      [-263, -57],
      [-273, -219],
      [-230, -92],
      [-464, -5],
      [-549, 138],
      [-476, 97],
      [-328, 80],
      [-314, 87],
      [-342, 114],
      [-367, 136],
      [-532, -39],
      [-256, -50],
      [-32, -11],
    ],
    [
      [33953, 18191],
      [-130, -47],
      [-364, -48],
      [-358, -89],
      [-263, -50],
      [-224, -152],
      [-277, -97],
      [-271, -70],
      [-289, -36],
      [-33, 21],
      [-314, 154],
      [-185, 235],
      [-85, 110],
      [-177, 281],
      [-151, 227],
      [18, 291],
      [145, 222],
      [90, 175],
      [63, 155],
      [-15, 306],
      [-220, 146],
      [-408, 143],
      [-389, 27],
      [-262, 38],
      [-179, 139],
      [-294, 120],
      [-88, 42],
    ],
    [
      [29293, 20434],
      [97, 94],
      [151, 215],
      [103, 147],
      [136, 59],
      [302, -5],
      [251, 104],
      [275, -18],
      [168, -3],
      [301, -114],
      [147, -29],
      [255, -45],
      [-83, 286],
      [24, 237],
      [-85, 178],
      [-116, 281],
      [-94, 422],
      [-229, 391],
      [-247, 554],
      [-100, 387],
    ],
    [
      [30549, 23575],
      [302, 58],
      [109, 46],
      [109, 127],
      [293, 219],
      [292, -202],
      [481, -239],
      [175, 10],
      [301, -100],
      [362, -33],
      [134, -43],
      [269, 29],
      [457, -8],
      [262, -31],
      [455, -164],
      [327, -155],
      [480, -239],
      [158, -214],
      [260, -113],
      [157, -281],
      [219, -166],
      [219, -33],
    ],
    [
      [36370, 22043],
      [43, -6],
      [258, 172],
      [177, 133],
      [340, 239],
      [319, 191],
      [428, 258],
      [406, 203],
      [278, 144],
      [447, 183],
      [204, 180],
      [447, 176],
      [464, -8],
      [559, 105],
      [344, 62],
      [63, -4],
    ],
    [
      [40252, 16361],
      [-228, -156],
      [-150, -140],
      [-199, -241],
      [-490, -365],
      [-382, -327],
      [-129, -85],
      [-172, -269],
      [-125, -263],
      [-65, -270],
      [-104, -195],
      [-100, -351],
      [-4, -279],
      [24, -157],
      [52, -136],
      [-269, 5],
      [-65, -230],
      [-6, -7],
      [-23, -149],
      [-76, -168],
      [-91, -162],
      [-295, -382],
      [-295, -402],
      [-184, -167],
      [-196, -64],
      [-156, -31],
      [-182, -58],
      [-38, -58],
    ],
    [
      [36304, 11254],
      [-102, 115],
      [-246, 214],
      [-71, 151],
      [-81, -26],
      [-128, -5],
      [-75, -39],
      [-188, 10],
      [-41, -40],
      [-27, -34],
      [-90, -52],
    ],
    [
      [35255, 11548],
      [-12, -7],
      [-68, -80],
      [-62, -81],
      [-93, 83],
      [-233, 154],
      [-103, -121],
      [-40, -33],
      [-103, -80],
      [-75, -94],
      [-89, -86],
      [-29, -108],
      [66, -90],
      [-68, -80],
      [-15, -102],
      [-7, -3],
    ],
    [
      [34324, 10820],
      [-27, 31],
      [-18, 116],
      [17, 176],
      [41, 88],
      [35, 87],
      [16, 170],
      [35, 94],
      [64, 196],
      [3, 149],
      [-10, 231],
      [-103, 287],
      [-44, 205],
      [-144, 287],
      [-51, 137],
      [-123, 294],
      [-151, 206],
      [-158, 241],
      [-229, 390],
      [-123, 308],
      [-276, 351],
      [-164, 295],
      [-490, 456],
      [-236, 371],
      [146, 303],
      [90, 202],
      [314, 300],
      [320, 192],
      [230, 131],
      [421, 251],
      [213, 275],
      [-42, 313],
      [57, 196],
      [16, 42],
    ],
    [
      [40044, 18696],
      [-152, -333],
      [-88, -67],
      [-452, -454],
      [68, -416],
      [127, -444],
      [292, -277],
      [378, -319],
      [35, -25],
    ],
    [
      [40849, 27042],
      [-198, -223],
      [-105, -216],
      [-271, -124],
      [-216, -64],
      [-217, -160],
      [-278, -158],
      [-259, -158],
      [-217, -152],
      [-184, -194],
      [-341, -273],
      [-279, -199],
      [-167, -384],
      [-197, -105],
      [-142, -66],
      [-230, -97],
      [-251, -132],
      [-142, -52],
      [-162, -51],
      [-79, 12],
    ],
    [
      [36914, 24246],
      [-26, 193],
      [-157, 234],
      [-218, 261],
      [-35, 347],
      [-124, 240],
      [6, 339],
      [44, 264],
      [-64, 192],
      [-135, 389],
      [-108, 362],
      [-291, 358],
      [-218, 193],
      [-170, 295],
      [-30, 238],
      [-337, 386],
      [-441, 184],
      [-367, 183],
      [-217, 248],
      [-515, 179],
      [-325, 263],
      [-319, 243],
      [-292, 250],
      [-303, 385],
      [-265, 276],
      [-92, 97],
      [-356, 488],
      [-69, 259],
      [-440, 286],
      [-310, -56],
      [-122, -86],
      [-22, 5],
    ],
    [
      [30596, 31741],
      [-12, 390],
      [149, 472],
      [228, 410],
      [235, 370],
      [206, 254],
      [0, 7],
      [177, 160],
      [48, -5],
    ],
    [
      [31627, 33799],
      [120, -11],
      [157, -275],
      [38, -102],
      [96, 120],
      [66, 298],
      [100, 372],
      [93, 378],
      [251, 546],
      [65, 291],
      [87, 385],
      [220, 750],
      [170, 479],
      [-17, 672],
      [-160, 933],
      [-81, 348],
      [-27, 421],
      [-102, 382],
      [-94, 409],
      [-44, 191],
      [-1, 16],
    ],
    [
      [32564, 40402],
      [-8, 296],
      [88, 6],
      [189, 58],
      [291, 130],
      [232, 186],
      [-12, 523],
      [-99, 518],
      [12, 339],
      [54, 454],
      [-8, 306],
      [-19, 469],
      [-75, 408],
      [42, 502],
      [49, 399],
    ],
    [
      [33300, 44996],
      [38, 34],
      [197, 92],
      [350, 0],
      [343, 49],
      [370, -27],
      [376, -6],
      [485, 66],
      [405, 61],
      [271, 152],
      [257, 144],
      [270, 233],
    ],
    [
      [36662, 45794],
      [17, 14],
      [115, 79],
      [309, -18],
      [477, -29],
      [308, -66],
      [301, -101],
      [86, -14],
    ],
    [
      [38275, 45659],
      [-208, -99],
      [-197, -71],
      [-108, -66],
      [-109, -87],
      [-83, -114],
      [-44, -271],
      [7, -373],
      [-104, -222],
      [-57, -216],
      [1, -353],
      [-14, -401],
      [16, -258],
      [137, -295],
      [117, -280],
      [253, -92],
      [275, -59],
      [86, -104],
      [83, -246],
      [111, -266],
      [87, -429],
      [163, -315],
      [84, -219],
      [135, -335],
      [91, -199],
      [-36, -196],
      [163, -288],
      [194, -51],
      [66, -96],
      [145, -186],
      [157, 133],
      [244, -275],
      [222, -405],
      [189, -376],
      [177, -268],
      [-4, -258],
      [-159, -262],
      [-252, -220],
      [-260, -280],
      [-232, -214],
      [-238, -206],
      [-188, -404],
      [-289, -402],
      [-288, -328],
      [-375, -299],
      [-191, -201],
      [-79, -311],
      [-103, -541],
      [-19, -346],
      [6, -509],
      [127, -451],
      [87, -442],
      [286, -209],
      [258, -222],
      [225, -234],
      [206, -146],
      [264, -310],
      [102, -349],
      [94, -429],
      [49, -326],
      [46, -436],
      [61, -415],
      [62, -347],
      [54, -435],
      [283, -338],
      [157, -274],
      [164, -254],
      [158, -213],
      [142, -390],
      [177, -238],
    ],
    [
      [31627, 33799],
      [6, 17],
      [-95, 376],
      [-200, 512],
      [-177, 709],
      [-268, 487],
      [-129, 362],
      [-359, 244],
      [-311, 283],
      [-420, 245],
      [-142, -45],
      [-162, -79],
      [-223, -30],
      [-447, 198],
      [-393, 197],
      [-360, 250],
      [-297, 338],
      [-249, 392],
      [-402, 115],
      [-77, 212],
      [-292, 215],
      [-479, 334],
      [-144, 200],
      [-476, 89],
      [-267, -267],
      [-276, -443],
      [-265, -192],
      [-154, -371],
      [-310, -22],
      [-369, -415],
      [-77, -155],
      [-210, -138],
      [-164, -174],
      [-147, -344],
      [-131, -208],
      [-320, -239],
      [-225, -227],
      [-483, -311],
      [-500, -168],
      [-462, -284],
      [-303, -63],
      [-587, -91],
      [-329, -2],
      [-553, -85],
      [-438, -74],
      [-586, -51],
      [-341, 94],
      [-474, 219],
      [-308, 107],
      [-527, 206],
      [-404, 0],
      [-390, -55],
      [-621, -105],
      [-511, -32],
      [-384, -34],
      [-269, -15],
      [-204, -153],
      [-180, -330],
      [-65, -263],
      [-287, -253],
      [-160, 70],
      [-26, -7],
    ],
    [
      [13224, 34545],
      [-534, -153],
      [-465, -73],
      [-635, -132],
      [-625, -57],
      [-660, -3],
      [-739, 34],
      [-738, 73],
      [-754, -68],
      [-747, -69],
      [-640, -50],
      [-714, -110],
      [-398, -95],
      [-304, -36],
      [-432, -128],
      [-683, 181],
      [-512, -86],
      [-328, 74],
      [-426, -129],
      [-228, -3],
      [-397, -20],
      [-151, -12],
    ],
    [
      [2114, 33683],
      [6, 56],
      [-26, 317],
      [34, 203],
      [52, 69],
      [29, 39],
      [-164, 161],
      [-238, 234],
      [-86, 139],
      [-56, 92],
      [-25, 39],
      [-73, 120],
      [-113, 184],
      [-37, 61],
      [-4, 7],
      [-242, 210],
      [-113, 203],
      [-100, 461],
      [-197, 473],
      [-77, 186],
      [-105, 255],
      [-33, 233],
      [-40, 284],
      [17, 387],
      [2, 49],
      [4, 89],
      [15, 358],
      [4, 75],
      [217, 237],
      [120, 126],
      [155, 521],
      [-34, 244],
      [-45, 295],
      [-127, 436],
      [224, 330],
      [289, 11],
      [46, 2],
      [256, 127],
      [169, 257],
      [22, 108],
    ],
    [
      [1840, 41361],
      [9, -1],
      [450, -35],
      [255, -38],
      [229, 43],
      [332, 137],
      [610, -105],
      [262, -45],
      [377, 34],
      [287, -114],
      [404, 55],
      [348, -88],
      [328, -94],
      [255, -45],
      [183, 85],
      [651, -45],
      [590, -112],
      [450, -48],
      [858, -137],
      [584, -64],
      [464, -22],
      [369, -47],
      [486, -253],
      [443, -82],
      [521, -192],
      [634, 118],
      [463, -83],
      [503, -76],
      [423, -14],
      [616, 268],
      [240, -106],
      [424, 40],
      [226, -174],
      [137, -260],
      [441, -177],
      [82, 100],
      [270, 29],
      [292, -201],
      [488, -192],
      [335, -81],
      [497, -55],
      [545, 78],
      [560, 93],
      [373, 177],
      [284, 137],
      [240, 308],
      [19, 326],
      [-35, 326],
      [155, 439],
      [101, 412],
      [217, 588],
      [147, 336],
      [262, 369],
      [247, 336],
      [253, 301],
      [229, 423],
      [416, 346],
      [448, 319],
      [239, 179],
      [156, 99],
      [76, -22],
    ],
    [
      [22588, 44854],
      [157, -47],
      [108, -32],
      [347, -189],
      [553, -315],
      [386, -197],
      [387, -190],
      [293, -195],
      [340, -196],
      [353, -162],
      [569, -193],
      [609, -153],
      [536, -165],
      [475, -117],
      [616, -112],
      [518, -2],
      [550, -85],
      [504, -63],
      [376, -20],
      [288, -73],
      [483, -62],
      [503, -70],
      [321, -94],
      [394, -189],
      [28, -347],
      [-27, -434],
      [119, -546],
      [190, -204],
    ],
    [
      [42715, 12189],
      [-91, -555],
      [-62, -509],
      [-163, -521],
      [-155, -452],
      [-97, -597],
      [-80, -372],
      [-18, -306],
      [-11, -686],
      [-48, -158],
    ],
    [
      [41990, 8033],
      [-194, 15],
      [-47, 21],
      [-140, 43],
      [-267, 140],
      [-280, 127],
      [-154, 78],
      [-126, 97],
      [-147, 23],
      [-329, 26],
      [-369, 94],
      [-233, 106],
      [-180, 126],
      [-267, 126],
      [-385, 279],
      [-201, 84],
      [-153, 98],
      [-194, 31],
      [-174, 43],
      [-162, -4],
      [-336, -21],
      [-371, -41],
      [-229, -64],
      [-129, -52],
      [-61, -54],
      [7, 0],
      [-24, -230],
      [-32, -61],
    ],
    [
      [36813, 9063],
      [-16, 28],
      [-5, 81],
      [-13, 41],
      [2, 109],
      [-17, 197],
      [27, 47],
      [42, 94],
      [49, 129],
      [49, 94],
      [41, 47],
      [82, 80],
      [48, 73],
      [62, 74],
      [28, 101],
      [-6, 1],
      [-18, 122],
      [-53, 62],
    ],
    [
      [37115, 10443],
      [20, 0],
      [52, -62],
      [49, 80],
      [169, 45],
      [286, 219],
      [366, 184],
      [212, 261],
      [426, 129],
      [486, 154],
      [398, 41],
      [499, 100],
      [492, 93],
      [342, -46],
      [276, 16],
      [364, 47],
      [312, 165],
      [157, 153],
      [358, 117],
      [249, 36],
      [87, 14],
    ],
    [
      [36304, 11254],
      [63, -72],
      [258, -235],
      [-2, -129],
      [99, -79],
    ],
    [
      [36722, 10739],
      [-34, -71],
      [-43, -142],
      [-102, -107],
      [17, -197],
      [-29, -81],
      [-68, -74],
      [-170, -119],
      [-88, -60],
      [-109, -72],
      [-68, -67],
    ],
    [
      [36028, 9749],
      [-53, 69],
      [-158, 213],
      [42, 87],
      [15, 115],
      [-52, 90],
      [-67, 8],
      [-174, 64],
      [-81, -26],
      [-194, 71],
      [-13, 48],
      [-13, 27],
      [-85, 110],
      [-13, 55],
      [15, 88],
      [15, 101],
      [-4, 130],
      [16, 176],
      [23, 169],
      [2, 163],
      [6, 41],
    ],
    [
      [36813, 9063],
      [-3, -7],
      [-77, -195],
      [-29, -156],
      [-37, -64],
    ],
    [
      [36667, 8641],
      [-83, 127],
      [-79, 110],
      [-60, 49],
      [-79, 117],
      [-59, 103],
      [-219, 133],
      [-101, 55],
      [-195, -44],
      [-141, -73],
    ],
    [
      [35651, 9218],
      [13, 75],
      [-45, 137],
      [-22, 35],
    ],
    [
      [35597, 9465],
      [37, 33],
      [102, 79],
      [74, 33],
      [136, 72],
      [82, 67],
    ],
    [
      [36722, 10739],
      [246, -198],
      [147, -98],
    ],
    [
      [35597, 9465],
      [-168, 260],
      [-179, 112],
      [-167, 71],
      [-88, -26],
      [-48, -40],
      [-94, -5],
      [-142, -4],
      [-100, 49],
      [-74, 14],
      [-126, 98],
      [-185, 173],
      [-162, 149],
    ],
    [
      [34064, 10316],
      [-10, 44],
      [41, 53],
      [-5, 75],
      [-19, 89],
      [76, 148],
      [75, 46],
      [102, 49],
    ],
    [
      [34853, 7921],
      [3, 49],
      [30, 162],
      [64, 237],
      [83, 141],
      [117, 168],
      [158, 235],
      [131, 147],
      [163, 133],
      [49, 25],
    ],
    [
      [36667, 8641],
      [-33, -57],
      [-55, -73],
      [-142, -113],
      [-103, -128],
      [-217, -84],
      [-150, -120],
      [-237, -125],
      [-317, -55],
      [-323, -22],
      [-237, 57],
    ],
    [
      [34853, 7921],
      [-4, 1],
      [-148, 23],
      [-122, -86],
      [-151, -8],
    ],
    [
      [34428, 7851],
      [-4, 11],
      [-164, 233],
      [-84, 206],
      [-118, 151],
      [-85, 171],
      [-113, 117],
      [-25, 89],
      [-112, 151],
      [-65, 164],
      [-5, 82],
      [-59, 103],
      [1, 95],
      [43, 149],
      [90, 134],
      [96, 113],
      [34, 54],
      [76, 87],
      [103, 141],
      [-12, 82],
      [54, 60],
      [-15, 72],
    ],
    [
      [20274, 12640],
      [-130, -130],
      [-93, -358],
      [-133, -338],
      [-197, -499],
      [-66, -366],
      [-48, -455],
      [-341, -286],
      [-209, -452],
      [-126, -358],
      [-234, -288],
      [-173, -303],
      [-225, -200],
      [-344, -62],
      [-474, -169],
      [-469, 130],
      [-412, -87],
      [-557, 70],
      [-471, 22],
      [-487, 246],
      [-350, 165],
      [-265, 124],
      [-205, 181],
      [-40, 34],
      [-206, 119],
      [-220, 161],
      [-180, 125],
      [-260, 168],
      [-304, 331],
      [-87, 165],
      [-37, 68],
      [-33, 35],
      [-145, 193],
      [-805, 1013],
      [-28, 361],
      [-372, 312],
      [-32, 283],
    ],
    [
      [11516, 12595],
      [409, 85],
      [385, 68],
      [228, -37],
      [202, 17],
      [376, 27],
      [123, 100],
      [136, 106],
      [244, 98],
      [255, -12],
      [369, -67],
      [425, 81],
      [463, -69],
      [137, 188],
      [158, 201],
      [175, 17],
      [284, 104],
      [296, 22],
      [404, 54],
      [425, 34],
      [497, 25],
      [383, -41],
      [223, 78],
      [275, -32],
      [14, 0],
      [161, 38],
      [331, 83],
      [572, 17],
      [68, 32],
    ],
    [
      [19534, 13812],
      [5, -81],
      [32, -143],
      [57, -171],
      [20, -34],
      [86, -76],
      [152, -200],
      [238, -194],
      [105, -219],
      [45, -54],
    ],
    [
      [24386, 10744],
      [-201, -145],
      [-480, -121],
      [-381, 136],
      [-371, 400],
      [-226, 596],
      [-171, 635],
      [-208, 472],
      [-217, 357],
      [-360, 163],
      [-235, 31],
      [-304, -22],
      [-323, -35],
      [-259, -186],
      [-164, -174],
      [-212, -211],
    ],
    [
      [19534, 13812],
      [-6, 76],
      [22, 88],
      [49, 134],
      [37, 197],
      [49, 155],
      [31, 203],
      [36, 190],
      [29, 135],
      [22, 88],
      [-51, 191],
      [-37, 190],
      [-52, 151],
      [2, 108],
      [-17, 163],
      [-4, 177],
      [-43, 272],
      [42, 122],
      [5, 265],
      [-11, 176],
      [22, 115],
      [50, 156],
      [42, 121],
      [2, 109],
      [-118, 239],
      [-38, 96],
      [-103, 294],
      [-160, 111],
      [4, 27],
    ],
    [
      [19338, 18161],
      [3, -7],
      [216, 65],
      [250, 70],
      [142, 45],
      [196, 51],
      [399, 136],
      [182, 58],
      [379, 129],
      [128, 72],
      [124, 127],
      [210, 112],
      [89, 134],
      [50, 183],
      [-117, 246],
      [16, 136],
      [141, 405],
      [7, 20],
      [56, 108],
      [35, 94],
      [223, 98],
      [228, -45],
      [208, -30],
      [115, 73],
      [331, 96],
      [396, -28],
      [248, -79],
      [301, -73],
      [477, -55],
      [390, 20],
      [311, 97],
      [142, 79],
      [112, 276],
      [-23, 232],
      [30, 223],
      [-49, 266],
      [2, 135],
      [17, 183],
      [178, 215],
      [355, -61],
      [173, -145],
      [440, -218],
      [441, -164],
      [468, -185],
      [394, -162],
      [247, -120],
      [360, -142],
      [354, -149],
      [308, -120],
      [272, -128],
    ],
    [
      [29293, 20434],
      [-26, -26],
      [-123, -113],
      [-178, -174],
      [-83, -188],
      [-110, -141],
      [-150, -154],
      [-112, -229],
      [-290, -62],
      [-68, -61],
      [-199, -247],
      [-132, -263],
      [-126, -344],
      [-283, -29],
      [-239, -199],
      [-239, -248],
      [-245, -199],
      [-353, -177],
      [-346, -239],
      [-181, -350],
      [-27, -420],
      [49, -327],
      [49, -306],
      [14, -354],
      [129, -341],
      [84, -260],
      [80, -422],
      [-145, -283],
      [-159, -221],
      [-83, -134],
      [212, -228],
      [101, -382],
      [-70, -230],
      [-70, -120],
      [-123, -182],
      [-281, -280],
      [-198, -221],
      [-185, -221],
      [-228, -396],
      [-306, -219],
      [-158, -208],
      [-112, -229],
      [-16, -169],
      [19, -94],
    ],
    [
      [16575, 22560],
      [32, -41],
      [72, -137],
      [231, -289],
      [218, -255],
      [204, -262],
      [150, -308],
      [22, -299],
      [-60, -345],
      [-43, -210],
      [-4, -203],
      [110, -321],
      [96, -307],
      [164, -221],
      [300, -188],
      [179, -159],
      [172, -132],
      [260, -181],
      [152, -152],
      [299, -202],
      [193, -153],
      [16, -34],
    ],
    [
      [11516, 12595],
      [-21, 180],
      [-300, 576],
      [-128, 403],
      [-556, 595],
      [-353, 223],
      [-526, 356],
      [-130, 247],
      [-506, 396],
      [-104, 206],
      [-257, 344],
      [-159, 560],
      [5, 360],
      [-26, 456],
      [-68, 362],
      [240, 267],
      [193, -112],
      [21, 483],
      [192, 241],
      [31, 210],
      [79, 325],
      [100, 372],
      [236, 452],
      [67, 433],
      [93, 325],
      [7, 442],
      [44, 491],
    ],
    [
      [9690, 21788],
      [232, 112],
      [186, 296],
      [232, 247],
      [339, 116],
      [316, 70],
      [344, 28],
      [329, 8],
      [397, 0],
      [505, 32],
      [330, 42],
      [229, 50],
      [484, 12],
      [324, 22],
      [281, -26],
      [330, 15],
      [297, 43],
      [464, 19],
      [201, -44],
      [375, -54],
      [215, -45],
      [355, -47],
      [47, -27],
      [73, -97],
    ],
    [
      [34428, 7851],
      [-138, -7],
      [-294, 113],
      [-362, 54],
      [-242, 45],
      [-308, 46],
      [-251, -98],
      [-244, -165],
      [-212, -186],
      [-151, -195],
      [-259, -253],
      [-280, -274],
      [-233, -268],
      [-156, -200],
    ],
    [
      [31298, 6463],
      [-196, 339],
      [-389, 55],
      [-288, 127],
      [-206, 160],
      [-521, 240],
      [-196, 336],
      [45, 319],
      [20, 408],
      [-6, 448],
      [-27, 449],
      [-118, 621],
      [-67, 443],
      [-162, 383],
      [-190, 296],
      [-199, 221],
      [-422, 102],
      [-525, 2],
      [-510, 57],
      [-418, -61],
      [-357, 6],
      [-283, -22],
      [-403, 20],
      [-736, -150],
      [-426, -135],
      [-139, -243],
      [-193, -140],
    ],
    [
      [45721, 56483],
      [-33, -39],
      [-19, -23],
      [-183, -153],
      [-320, -205],
      [-225, -206],
      [-218, -160],
      [-468, -290],
      [-136, -93],
      [-172, -262],
      [-248, -308],
      [-193, -309],
      [-405, -149],
      [-351, -41],
      [-494, -196],
      [-424, -87],
      [-480, -114],
      [-249, -23],
      [-289, -43],
      [-209, 17],
      [-1002, -471],
      [-505, -12],
      [-344, -82],
      [-316, -29],
      [-243, -9],
    ],
    [
      [38195, 53196],
      [-105, 158],
      [-6, 13],
      [-279, 188],
      [-200, 160],
      [-99, 103],
      [-239, 161],
      [-245, 221],
      [-233, 153],
      [-134, 30],
      [-62, -47],
      [-61, -73],
      [-100, 69],
      [-219, 153],
      [-226, 181],
      [-132, 158],
      [-141, -4],
      [-40, 7],
      [-175, 24],
      [-193, 105],
      [-359, 244],
      [-299, 215],
      [-140, 84],
      [-341, 162],
      [-159, 118],
      [-213, 146],
      [-442, 103],
      [-195, 3],
      [-108, -18],
      [-276, -56],
      [-190, -99],
      [-344, -69],
      [-234, 58],
      [-254, 86],
      [-343, 40],
      [-107, 36],
      [-328, 46],
      [-415, 123],
      [-240, 126],
      [-93, 56],
      [-194, 92],
      [-126, 131],
      [-279, 195],
      [-185, 206],
      [-129, 86],
    ],
    [
      [29313, 57070],
      [150, 92],
      [80, 49],
      [409, 299],
      [140, 107],
      [135, 103],
      [-30, 0],
      [429, 339],
      [261, 328],
      [126, 309],
      [89, 215],
      [11, 473],
      [-2, 780],
      [222, 561],
      [182, 318],
      [16, 285],
      [52, 184],
      [133, 197],
      [188, 210],
      [254, 150],
      [321, 159],
      [359, 188],
      [95, 100],
      [114, 86],
      [57, 31],
      [112, 62],
      [62, 33],
      [250, 104],
      [177, 73],
      [110, 12],
      [59, 7],
      [69, 8],
      [267, 31],
      [241, -49],
      [213, 30],
      [298, -135],
      [335, -135],
      [295, -221],
      [25, -20],
      [264, -174],
      [113, -54],
      [171, -80],
      [419, -344],
      [91, -422],
      [9, -38],
      [125, -419],
      [16, -151],
      [15, -141],
      [322, -406],
      [104, -127],
      [122, -150],
      [112, -128],
      [136, -156],
      [87, -76],
      [89, -78],
      [255, -209],
      [313, -180],
      [262, -208],
      [137, -109],
      [186, -161],
      [200, -120],
      [254, -52],
      [253, -167],
      [65, -25],
      [416, -160],
      [363, -107],
      [146, -44],
      [452, -136],
      [71, -21],
      [51, -10],
      [213, -42],
      [113, -18],
      [161, -26],
      [112, -16],
      [11, -1],
      [17, -31],
      [14, -22],
      [21, -32],
      [110, -161],
      [11, -5],
      [270, -115],
      [239, -154],
      [318, -150],
      [130, -61],
      [297, 55],
      [141, 7],
      [81, 3],
      [268, -72],
      [169, -35],
      [153, -32],
      [335, -115],
      [192, -59],
      [42, -13],
      [375, -101],
      [268, -86],
      [221, -14],
      [75, -5],
      [128, 4],
    ],
    [
      [30732, 29297],
      [39, -164],
      [71, -605],
      [205, -628],
      [-56, -570],
      [-194, -370],
      [-269, -430],
      [-168, -438],
      [-49, -481],
      [2, -326],
      [8, -374],
      [154, -464],
      [-22, -536],
      [57, -184],
      [39, -152],
    ],
    [
      [16575, 22560],
      [34, 27],
      [486, 127],
      [176, 106],
      [95, 53],
      [322, -74],
      [477, -8],
      [597, -105],
      [270, 97],
      [493, 148],
      [338, 130],
      [446, 121],
      [134, 385],
      [113, 330],
      [17, 47],
    ],
    [
      [20573, 23944],
      [166, 473],
      [288, 348],
      [198, 228],
      [288, 314],
      [377, 455],
      [467, 209],
      [234, 315],
      [32, 332],
      [-148, 397],
      [-1, 359],
      [154, 385],
      [104, 202],
      [114, 412],
      [184, 146],
      [-37, 211],
      [-90, 267],
      [-104, 232],
      [-97, 298],
    ],
    [
      [22702, 29527],
      [122, -20],
      [350, 35],
      [411, 40],
      [464, 46],
      [348, -148],
      [211, -248],
      [302, -46],
      [356, -20],
      [377, 75],
      [452, 74],
      [304, 104],
      [466, 121],
      [411, 27],
      [295, -33],
      [416, -34],
      [881, 46],
      [67, -35],
      [430, -48],
      [409, -55],
      [382, -67],
      [564, -44],
      [12, 0],
    ],
    [
      [30596, 31741],
      [14, -487],
      [1, -774],
      [-3, -665],
      [124, -518],
    ],
    [
      [22702, 29527],
      [-240, 41],
      [-245, 235],
      [-272, 221],
      [-238, 256],
      [-107, 69],
      [-185, 180],
      [-126, 145],
      [165, 167],
      [276, 42],
      [345, 117],
      [177, 187],
      [76, 141],
      [-108, 348],
      [-222, 384],
      [-192, 200],
      [-220, 140],
      [-284, 297],
      [-530, 97],
      [-420, 211],
      [-365, 318],
      [-110, 220],
      [-212, 248],
      [-253, 147],
      [-289, -43],
      [-411, -20],
      [-324, -90],
      [-425, -108],
      [-49, -535],
      [55, -300],
      [-67, -413],
      [-113, -337],
      [-119, -318],
      [-102, -106],
      [-34, -20],
    ],
    [
      [17534, 31648],
      [-136, -79],
      [-116, -100],
      [-188, 10],
      [-330, 12],
      [-215, -10],
      [-402, 41],
      [-315, 101],
      [-334, 100],
      [-353, 258],
      [-305, 277],
      [-56, 258],
      [-310, 339],
      [-91, 218],
      [-224, 262],
      [-300, 168],
      [-198, 241],
      [-246, 194],
      [-31, 164],
      [49, 94],
      [23, 156],
      [-220, 180],
      [-12, 13],
    ],
    [
      [17534, 31648],
      [90, -226],
      [95, -347],
      [-35, -536],
      [-195, -370],
      [-212, -282],
      [-232, -234],
      [-146, -282],
      [-108, -412],
      [32, -510],
      [31, -591],
      [180, -533],
      [109, -341],
      [194, -479],
      [342, -447],
      [291, -338],
      [222, -363],
      [405, -340],
      [459, -320],
      [359, -230],
      [320, -148],
      [334, -189],
      [186, -105],
      [235, -31],
      [83, -50],
    ],
    [
      [9690, 21788],
      [14, 160],
      [12, 374],
      [-52, 524],
      [-204, 303],
      [-199, 187],
      [-213, 106],
      [-465, 1],
      [-181, -4],
      [-219, -214],
      [-99, -297],
      [-173, -296],
      [-45, -326],
      [-29, -523],
      [-105, -691],
      [-132, -664],
      [-128, -433],
      [-396, 61],
      [-297, 373],
      [-319, 658],
      [-140, 525],
      [-125, 594],
      [-88, 817],
      [40, 802],
      [-2, 727],
      [-22, 728],
      [-191, 683],
      [-282, 487],
      [-303, 359],
      [-468, 205],
      [-427, 239],
      [-908, 22],
      [-216, -24],
      [-519, -99],
      [-306, -213],
      [-178, -180],
      [-557, -337],
      [-484, -339],
      [-394, -299],
      [-234, -267],
      [-656, -399],
      [278, 471],
      [89, 379],
      [134, 135],
      [55, 331],
      [63, 312],
      [146, 455],
      [34, 366],
      [49, 521],
      [-2, 53],
      [390, 1563],
      [75, 237],
      [161, 169],
      [7, 108],
      [-146, 426],
      [27, 216],
      [162, 372],
      [59, 351],
      [-77, 338],
      [68, 311],
      [128, 257],
      [216, 249],
      [121, 257],
      [77, 688],
    ],
    [
      [42744, 12455],
      [-15, -184],
      [-14, -82],
    ],
    [
      [40252, 16361],
      [211, -149],
      [547, -336],
      [270, -338],
      [303, -447],
      [177, -275],
      [180, -526],
      [171, -231],
      [126, -169],
      [210, -330],
      [406, -231],
      [-75, -474],
      [-34, -400],
    ],
    [
      [47581, 30168],
      [-6, 1],
      [-274, 80],
      [-227, 126],
      [-199, 139],
      [-187, 105],
      [-296, -22],
      [-161, 23],
      [-207, 44],
      [-212, 235],
      [-158, 213],
      [-91, 185],
      [-139, 152],
      [-125, 165],
      [-262, -3],
      [-181, 4],
      [-195, 10],
      [-135, -25],
      [-195, -10],
      [-268, 86],
      [-172, 137],
    ],
    [
      [43891, 31813],
      [112, 278],
      [140, 351],
      [363, 462],
      [37, 196],
      [85, 256],
      [112, 270],
      [64, 203],
      [97, 202],
      [130, 181],
      [212, 200],
      [265, 178],
      [353, 205],
      [420, 189],
      [468, 224],
      [447, 223],
      [231, 101],
    ],
    [
      [47427, 35532],
      [-35, -37],
      [-75, -66],
      [-76, -87],
      [-16, -218],
      [71, -170],
      [118, -138],
      [124, -240],
      [65, -157],
      [130, -301],
      [50, -232],
      [117, -253],
      [22, -272],
      [70, -286],
      [44, -157],
      [103, -328],
      [9, -244],
      [63, -293],
      [29, -299],
      [-11, -258],
      [-58, -251],
      [-77, -216],
      [-99, -290],
      [-125, -228],
      [-103, -121],
      [-103, -127],
      [-83, -95],
    ],
    [
      [47581, 30168],
      [-47, -52],
      [-150, -147],
      [-123, -100],
      [-135, -86],
      [-117, -120],
      [-75, -53],
      [-8, -18],
    ],
    [
      [46926, 29592],
      [-194, -12],
      [-290, -111],
      [-385, -61],
      [-275, 52],
      [-248, 32],
      [-226, 126],
      [-308, 120],
      [-144, 206],
      [-165, 187],
      [-264, -125],
      [-229, 18],
      [-146, 104],
      [-226, 160],
      [-156, -65],
      [-128, 2],
    ],
    [
      [43542, 30225],
      [113, 338],
      [51, 250],
      [67, 392],
      [44, 224],
      [53, 331],
      [21, 53],
    ],
    [
      [46926, 29592],
      [-47, -96],
      [66, -90],
      [106, -97],
      [85, -103],
      [60, -89],
      [118, -186],
      [118, -164],
      [132, -159],
      [64, -205],
      [91, -185],
      [-2, -135],
      [121, -444],
      [30, -170],
      [10, -224],
      [205, -201],
      [279, -235],
      [160, -84],
      [248, -93],
      [292, -195],
      [231, -248],
      [258, -317],
      [209, -343],
      [204, -309],
      [317, -331],
      [239, -215],
      [196, -255],
    ],
    [
      [50716, 24419],
      [-72, -172],
      [-98, -250],
      [-118, -208],
      [-365, -130],
      [-199, -86],
      [-147, -64],
      [-292, -165],
      [-258, 229],
      [-248, 548],
      [-301, 474],
      [-236, 453],
      [-289, 399],
      [-355, 536],
      [-347, 210],
      [-413, 238],
      [-359, 285],
      [-300, 141],
      [-238, -138],
      [-227, -329],
      [-146, -284],
      [-192, -186],
      [-411, -469],
      [-110, -168],
      [-143, -134],
      [-353, -136],
      [-58, -285],
      [4, -143],
      [39, -136],
      [16, -239],
      [-65, -291],
      [-133, -290],
      [-231, -179],
      [-385, -69],
      [-421, -264],
      [-290, -22],
      [-557, 104],
      [-690, 169],
      [-572, 203],
    ],
    [
      [41147, 24071],
      [-1, 58],
      [-16, 271],
      [10, 204],
      [4, 285],
      [-108, 389],
      [37, 169],
      [66, 359],
      [-3, 258],
      [-90, 252],
      [21, 19],
    ],
    [
      [41067, 26335],
      [286, 254],
      [374, 245],
      [340, 239],
      [171, 194],
      [139, 283],
      [215, 417],
      [164, 153],
      [295, 369],
      [83, 161],
      [154, 385],
      [126, 303],
      [6, 373],
      [101, 453],
      [21, 61],
    ],
    [
      [44377, 48573],
      [88, -395],
      [79, -504],
      [136, -342],
      [33, -93],
    ],
    [
      [44713, 47239],
      [-140, -86],
      [-170, -113],
      [-204, -159],
      [-170, -119],
      [-231, -160],
      [-159, -275],
      [-104, -202],
      [-165, -215],
      [-226, -233],
      [-311, -124],
      [-425, -88],
      [-443, 15],
      [-499, -60],
      [-330, -48],
      [-384, -55],
      [-430, 28],
      [-529, 97],
      [-425, -33],
      [-442, 62],
      [-428, 149],
      [-223, 39],
    ],
    [
      [36662, 45794],
      [-14, 191],
      [10, 190],
      [-137, 260],
      [-273, 181],
      [-271, 290],
      [-164, 261],
      [-144, 240],
      [-149, 376],
      [-103, 266],
      [33, 373],
      [-7, 374],
      [177, 567],
      [279, 661],
      [239, 213],
      [409, 373],
      [335, 320],
      [275, 369],
      [202, 485],
      [224, 499],
      [306, 273],
      [274, 328],
      [-9, 238],
      [41, 74],
    ],
    [
      [45721, 56483],
      [451, 13],
      [141, 32],
      [27, 6],
      [259, 172],
      [304, 156],
      [69, 35],
      [333, 212],
      [368, 279],
      [48, 0],
      [201, 2],
      [218, -221],
      [146, -152],
      [76, -327],
      [2, -286],
      [-3, -4],
      [-27, -44],
      [-177, -282],
      [-261, -328],
      [-390, -360],
      [-242, -479],
      [-348, -300],
      [-408, -217],
      [-482, -284],
      [-252, -186],
      [-267, -281],
      [-167, -758],
      [-406, -163],
      [-280, -240],
      [-267, -322],
      [-27, -6],
      [-126, -270],
      [-101, -426],
      [-106, -318],
      [-77, -209],
      [16, -266],
      [18, -266],
      [-109, -378],
      [385, -747],
      [85, -552],
      [32, -145],
    ],
    [
      [38797, 1735],
      [-261, -50],
      [-547, -113],
      [-408, -299],
      [-301, -348],
      [-360, -157],
      [-371, -75],
      [-293, -206],
      [-473, -155],
      [-235, 72],
      [-498, -52],
      [-242, 45],
      [-457, 1],
      [-507, -168],
      [-100, 56],
      [-88, 22],
      [-56, -129],
      [-6, 0],
      [-512, -59],
      [-801, 7],
      [-113, -3],
      [-365, -9],
      [-540, -72],
      [-470, 49],
      [-250, -64],
      [-330, -28],
      [-173, 132],
      [157, 541],
      [168, 197],
      [72, 85],
      [113, 96],
      [214, 183],
      [349, 355],
      [342, 347],
      [319, 198],
      [290, 444],
      [-87, 450],
      [-39, 332],
      [-14, 125],
      [-54, 456],
      [-242, 820],
      [-275, 467],
      [40, 448],
      [10, 604],
      [-105, 183],
    ],
    [
      [36667, 8641],
      [61, -92],
      [65, -164],
      [211, -201],
      [152, -172],
      [105, -178],
      [159, -139],
      [207, -126],
      [219, -180],
      [180, -51],
      [207, -119],
      [212, -180],
      [132, -172],
      [138, -185],
      [184, -248],
      [96, -327],
      [76, -294],
      [-37, -223],
      [-58, -243],
      [-50, -197],
      [-11, -223],
      [-5, -326],
      [-37, -244],
      [-51, -223],
      [-18, -305],
      [-44, -217],
      [-20, -380],
      [-17, -291],
      [-46, -312],
      [42, -340],
      [9, -217],
      [57, -266],
      [12, -71],
    ],
    [
      [40767, 1991],
      [-26, 3],
      [-277, -104],
      [-283, -36],
      [-823, -129],
      [-336, 53],
      [-225, -43],
    ],
    [
      [41990, 8033],
      [-101, -336],
      [-63, -563],
      [40, -463],
      [-124, -623],
      [-204, -527],
      [-20, -58],
      [-185, -536],
      [-187, -759],
      [-83, -596],
      [-145, -630],
      [-149, -935],
      [-2, -16],
    ],
    [
      [33300, 44996],
      [5, 41],
      [46, 380],
      [154, 391],
      [80, 318],
      [75, 508],
      [-94, 449],
      [-136, 322],
      [-258, 262],
      [-319, 209],
      [-359, 285],
      [-408, 495],
      [-355, 108],
      [-355, 74],
      [-180, 92],
      [-278, 317],
      [-308, 73],
      [-287, 79],
      [-334, 189],
      [-235, 391],
      [-101, 444],
      [-78, 585],
      [-59, 503],
      [-79, 497],
      [-130, 722],
      [-115, 409],
      [-49, 259],
      [-50, 272],
      [-142, 322],
      [-185, 214],
      [-244, 282],
      [-295, 73],
      [-53, 69],
      [-207, 64],
      [-84, 199],
      [-139, 186],
      [-276, 391],
      [-32, 69],
      [-126, 97],
      [-105, 199],
      [-12, 115],
      [-40, 106],
    ],
    [
      [27153, 56056],
      [71, 53],
      [413, 136],
      [447, 162],
      [524, 256],
      [201, 98],
      [85, 52],
      [187, 114],
      [105, 64],
      [127, 79],
    ],
    [
      [49200, 37038],
      [-104, -58],
      [-196, -98],
      [-231, -132],
      [13, -1],
      [-318, -150],
      [-109, -134],
      [-90, -155],
      [-137, -133],
      [-144, -167],
      [-144, -161],
      [-96, -114],
      [-150, -133],
      [-67, -70],
    ],
    [
      [41067, 26335],
      [-27, 36],
      [-97, 253],
      [-61, 374],
      [-33, 44],
    ],
    [
      [44713, 47239],
      [167, -475],
      [243, -357],
      [280, -189],
      [256, -358],
      [181, -451],
      [115, -403],
      [281, -529],
      [163, -349],
      [169, -329],
      [252, -650],
      [137, -336],
      [115, -321],
      [245, -263],
      [7, -7],
      [167, -471],
      [219, -235],
      [291, -271],
      [102, -375],
      [155, -370],
      [35, -388],
      [78, -178],
      [-52, -305],
      [96, -695],
      [134, -444],
      [135, -397],
      [117, -308],
      [187, -479],
      [212, -268],
    ],
    [
      [36370, 22043],
      [24, 62],
      [133, 317],
      [8, 116],
      [125, 242],
      [56, 121],
      [64, 243],
      [97, 189],
      [19, 298],
      [70, 189],
      [16, 190],
      [-64, 205],
      [-4, 31],
    ],
    [
      [22588, 44854],
      [-109, 1640],
      [-657, 665],
      [-494, 332],
      [-329, 0],
      [-329, 0],
      [-329, 333],
      [0, 499],
      [-164, 332],
      [164, 499],
      [0, 499],
      [823, 831],
      [-165, 832],
      [80, 1220],
    ],
    [
      [21079, 52536],
      [60, 41],
      [56, 102],
      [55, 100],
      [5, -1],
      [129, -22],
      [399, 143],
      [327, 219],
      [33, 22],
      [246, 163],
      [4, 4],
      [286, 240],
      [51, 43],
      [225, 227],
      [169, 85],
      [171, 86],
      [286, 213],
      [156, 108],
      [102, 70],
      [542, 202],
      [47, 38],
      [273, 215],
      [413, 197],
      [271, 104],
      [294, 246],
      [240, 110],
      [132, 60],
      [373, 150],
      [392, 102],
      [325, 243],
      [12, 10],
    ],
    [
      [1840, 41361],
      [32, 158],
      [48, 234],
      [142, 318],
      [230, 290],
      [-202, 210],
      [21, 108],
      [323, 351],
      [-26, 129],
      [9, 122],
      [19, 256],
      [-217, 189],
      [-141, 121],
      [280, 159],
      [225, 231],
      [349, 382],
      [303, 389],
      [345, 157],
      [290, 8],
      [175, 59],
      [108, -23],
      [200, -49],
      [242, -60],
      [479, 73],
      [69, 28],
      [317, 129],
      [140, 46],
      [151, 51],
      [303, 17],
      [54, 4],
      [102, 23],
      [283, 65],
      [303, -19],
      [143, -2],
      [58, -1],
      [17, 88],
      [13, 61],
      [116, -16],
      [58, -8],
      [34, 20],
      [95, 47],
      [122, 58],
      [11, 7],
      [9, 7],
      [231, 111],
      [91, 223],
      [-2, 195],
      [-1, 50],
      [62, 34],
      [352, 196],
      [25, 98],
      [25, 99],
      [14, 9],
      [361, 263],
      [383, 367],
      [437, 336],
      [39, 30],
      [145, 194],
      [244, 80],
      [229, 75],
      [305, 158],
      [4, 3],
      [297, 257],
      [55, 178],
      [2, 5],
      [203, 119],
      [280, 78],
      [65, 18],
      [250, 195],
      [125, 97],
      [84, 124],
      [198, 293],
      [346, 198],
      [172, 181],
      [68, 73],
      [171, 167],
      [132, 75],
      [228, 130],
      [395, 332],
      [191, 99],
      [9, -1],
      [326, -39],
      [163, 79],
      [95, 46],
      [258, 125],
      [355, -81],
      [328, -94],
      [157, -275],
      [272, -256],
      [97, 24],
      [112, 27],
      [39, -88],
      [26, -63],
      [75, -84],
      [110, -123],
      [311, 49],
      [56, 11],
      [72, 14],
      [584, 257],
      [39, 18],
      [192, 187],
      [513, 127],
      [40, 32],
      [314, 254],
      [400, 157],
      [441, 223],
      [332, 164],
      [271, 75],
      [169, 47],
      [293, 232],
      [481, 244],
      [348, 243],
      [123, 87],
    ],
    [
      [54713, 29147],
      [6, -8],
      [88, -368],
      [33, -477],
      [89, -307],
      [35, -354],
      [-96, -495],
      [-48, -115],
      [-203, -162],
      [-67, -54],
      [-46, -198],
      [-7, 0],
      [-62, -94],
      [-55, -87],
      [-30, -39],
      [-44, 60],
      [-74, 119],
      [-51, -37],
      [-9, -6],
      [-331, -137],
      [-366, -130],
      [-317, -49],
      [-400, -224],
      [-144, -120],
      [-322, -375],
      [-131, 186],
      [-199, -228],
      [-104, -161],
      [-83, -196],
      [-76, -73],
      [-274, -166],
      [-43, 124],
      [-163, -134],
      [-238, -172],
      [-246, -207],
      [-19, -44],
    ],
    [
      [49200, 37038],
      [39, -49],
      [181, -431],
      [230, -357],
      [199, -167],
      [396, -537],
      [131, -199],
      [199, -194],
      [274, -134],
      [481, -205],
      [292, -216],
      [385, -319],
      [15, -381],
      [365, -313],
      [18, -537],
      [169, -778],
      [169, -376],
      [123, -295],
      [170, -349],
      [72, -201],
      [121, -340],
      [141, -457],
      [38, -130],
      [58, -130],
      [93, -131],
      [124, -199],
      [119, -118],
      [136, 45],
      [67, -15],
      [174, -50],
      [121, 11],
      [182, -23],
      [231, -316],
    ],
  ],
  transform: {
    scale: [0.00003431222825282607, 0.00003393974395368865],
    translate: [2.6783045771315774, 7.044903043605045],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5, 6]],
          type: "Polygon",
          properties: {
            name: "Afijio",
            id: "OY001",
            nameAbbr: "Afijio",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25956458662,
            Shape_Area: 0.05931646584,
          },
        },
        {
          arcs: [[7, 8, 9, 10, -3, 11]],
          type: "Polygon",
          properties: {
            name: "Akinyele",
            id: "OY002",
            nameAbbr: "Akinyele",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.97024498221,
            Shape_Area: 0.03919892524,
          },
        },
        {
          arcs: [[12, 13, 14, 15, 16, 17, 18, 19]],
          type: "Polygon",
          properties: {
            name: "Atiba",
            id: "OY003",
            nameAbbr: "Atiba",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.00375460322,
            Shape_Area: 0.13664381482,
          },
        },
        {
          arcs: [[20, 21, 22, 23, 24, -16]],
          type: "Polygon",
          properties: {
            name: "Atisbo",
            id: "OY004",
            nameAbbr: "Atisbo",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.0287997688,
            Shape_Area: 0.22620820094,
          },
        },
        {
          arcs: [[25, 26, 27, 28]],
          type: "Polygon",
          properties: {
            name: "Egbeda",
            id: "OY005",
            nameAbbr: "Egbeda",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.60652715849,
            Shape_Area: 0.0154330385,
          },
        },
        {
          arcs: [[29, 30, 31, -9]],
          type: "Polygon",
          properties: {
            name: "Ibadan North",
            id: "OY006",
            nameAbbr: "Ibadan North",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.1942750283,
            Shape_Area: 0.00215796591,
          },
        },
        {
          arcs: [[32, 33, 34, 35, -31, 36, -28]],
          type: "Polygon",
          properties: {
            name: "Ibadan North-East",
            id: "OY007",
            nameAbbr: "Ibadan North-East",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.20174309661,
            Shape_Area: 0.00144544307,
          },
        },
        {
          arcs: [[-32, -36, 37, 38, -10]],
          type: "Polygon",
          properties: {
            name: "Ibadan North-West",
            id: "OY008",
            nameAbbr: "Ibadan North-West",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.24173345345,
            Shape_Area: 0.00234045328,
          },
        },
        {
          arcs: [[39, -34, 40]],
          type: "Polygon",
          properties: {
            name: "Ibadan South-East",
            id: "OY009",
            nameAbbr: "Ibadan South-East",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.17119792003,
            Shape_Area: 0.0019171624,
          },
        },
        {
          arcs: [[-35, -40, 41, 42, -38]],
          type: "Polygon",
          properties: {
            name: "Ibadan South-West",
            id: "OY010",
            nameAbbr: "Ibadan South-West",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.24114516208,
            Shape_Area: 0.00328036091,
          },
        },
        {
          arcs: [[43, 44, 45]],
          type: "Polygon",
          properties: {
            name: "Ibarapa Central",
            id: "OY0011",
            nameAbbr: "Ibarapa Central",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.79560334038,
            Shape_Area: 0.03723085752,
          },
        },
        {
          arcs: [[46, -46, 47, 48, 49]],
          type: "Polygon",
          properties: {
            name: "Ibarapa East",
            id: "OY012",
            nameAbbr: "Ibarapa East",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.27697809546,
            Shape_Area: 0.06710504906,
          },
        },
        {
          arcs: [[50, -48, -45, 51, 52]],
          type: "Polygon",
          properties: {
            name: "Ibarapa North",
            id: "OY013",
            nameAbbr: "Ibarapa North",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25758181293,
            Shape_Area: 0.10178682573,
          },
        },
        {
          arcs: [[-11, -39, -43, 53, 54, -50, -4]],
          type: "Polygon",
          properties: {
            name: "Ido",
            id: "OY014",
            nameAbbr: "Ido",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.57701540541,
            Shape_Area: 0.08738022669,
          },
        },
        {
          arcs: [[55, 56, 57]],
          type: "Polygon",
          properties: {
            name: "Irepo",
            id: "OY015",
            nameAbbr: "Irepo",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.41821040277,
            Shape_Area: 0.0865201983,
          },
        },
        {
          arcs: [[58, -5, -49, -51, 59, 60, 61]],
          type: "Polygon",
          properties: {
            name: "Iseyin",
            id: "OY016",
            nameAbbr: "Iseyin",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.69674531043,
            Shape_Area: 0.11038105472,
          },
        },
        {
          arcs: [[-15, 62, -62, 63, 64, -21]],
          type: "Polygon",
          properties: {
            name: "Atiba",
            id: "OY017",
            nameAbbr: "Atiba",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.8432549292,
            Shape_Area: 0.11210037197,
          },
        },
        {
          arcs: [[65, -60, -53, 66, -22, -65]],
          type: "Polygon",
          properties: {
            name: "Iwajowa",
            id: "OY018",
            nameAbbr: "Iwajowa",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.32300201918,
            Shape_Area: 0.19061349147,
          },
        },
        {
          arcs: [[-66, -64, -61]],
          type: "Polygon",
          properties: {
            name: "Kajola",
            id: "OY019",
            nameAbbr: "Kajola",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.93087987519,
            Shape_Area: 0.04787816546,
          },
        },
        {
          arcs: [[67, -29, -37, -30, -8, 68]],
          type: "Polygon",
          properties: {
            name: "Lagelu",
            id: "OY020",
            nameAbbr: "Lagelu",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.66866728382,
            Shape_Area: 0.02186048909,
          },
        },
        {
          arcs: [[69, 70, 71]],
          type: "Polygon",
          properties: {
            name: "Ogbomosho North",
            id: "OY021",
            nameAbbr: "Ogbomosho North",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.53205952422,
            Shape_Area: 0.0161047984,
          },
        },
        {
          arcs: [[72, 73, 74, -70]],
          type: "Polygon",
          properties: {
            name: "Ogbomosho South",
            id: "OY022",
            nameAbbr: "Ogbomosho South",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.36397501802,
            Shape_Area: 0.00587642524,
          },
        },
        {
          arcs: [[75, 76, -1, 77, 78, -74]],
          type: "Polygon",
          properties: {
            name: "Ogo Oluwa",
            id: "OY023",
            nameAbbr: "Ogo Oluwa",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.09828185506,
            Shape_Area: 0.03869502345,
          },
        },
        {
          arcs: [[79, 80, -19, 81, -56, 82]],
          type: "Polygon",
          properties: {
            name: "Olorunsogo",
            id: "OY024",
            nameAbbr: "Olorunsogo",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.40221203796,
            Shape_Area: 0.0849134946,
          },
        },
        {
          arcs: [[83, -54, -42, -41, 84]],
          type: "Polygon",
          properties: {
            name: "Atiba",
            id: "OY025",
            nameAbbr: "Atiba",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.05879674046,
            Shape_Area: 0.0623802976,
          },
        },
        {
          arcs: [[85, -85, -33, -27, 86]],
          type: "Polygon",
          properties: {
            name: "Ona Ara",
            id: "OY026",
            nameAbbr: "Ona Ara",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.76666532522,
            Shape_Area: 0.02531082746,
          },
        },
        {
          arcs: [[-82, -18, 87, 88, -57]],
          type: "Polygon",
          properties: {
            name: "Orelope",
            id: "OY027",
            nameAbbr: "Orelope",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.33686612208,
            Shape_Area: 0.07532806632,
          },
        },
        {
          arcs: [[89, -71, -75, -79, 90, -20, -81, 91]],
          type: "Polygon",
          properties: {
            name: "Ori Ife",
            id: "OY028",
            nameAbbr: "Ori Ife",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.91300299878,
            Shape_Area: 0.15620449137,
          },
        },
        {
          arcs: [[-7, 92, -13, -91, -78]],
          type: "Polygon",
          properties: {
            name: "Oyo East",
            id: "OY029",
            nameAbbr: "Oyo East",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.53602980112,
            Shape_Area: 0.01074243599,
          },
        },
        {
          arcs: [[-93, -6, -59, -63, -14]],
          type: "Polygon",
          properties: {
            name: "Oyo West",
            id: "OY030",
            nameAbbr: "Oyo West",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.95695995063,
            Shape_Area: 0.0429778266,
          },
        },
        {
          arcs: [[-17, -25, 93, 94, -88]],
          type: "Polygon",
          properties: {
            name: "Saki East",
            id: "OY031",
            nameAbbr: "Saki East",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.6282341232,
            Shape_Area: 0.13568510982,
          },
        },
        {
          arcs: [[-24, 95, -94]],
          type: "Polygon",
          properties: {
            name: "Saki East",
            id: "OY032",
            nameAbbr: "Saki East",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.06156133595,
            Shape_Area: 0.17773058058,
          },
        },
        {
          arcs: [[96, -76, -73, -72, -90, 97]],
          type: "Polygon",
          properties: {
            name: "Surulere",
            id: "OY033",
            nameAbbr: "Surulere",
            parent: "Oyo",
            parentAbbr: "OY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.1599112965,
            Shape_Area: 0.06528583725,
          },
        },
      ],
    },
  },
};

const fct = {
  type: "Topology",
  arcs: [
    [
      [6320, 24425],
      [-45, -34],
      [-331, -210],
      [-227, -120],
      [-277, -228],
      [-343, -89],
      [-68, -103],
      [2, -311],
      [-82, -163],
      [-37, -184],
      [-191, -180],
      [-407, -163],
      [-505, -45],
      [-178, -57],
      [-81, -124],
      [-11, -237],
      [-147, -185],
      [-85, -151],
      [-240, -93],
      [-285, -80],
      [-220, -106],
      [-142, -137],
      [-52, -178],
      [18, -256],
      [-147, -186],
      [199, -266],
      [221, -144],
      [274, -157],
      [110, -92],
      [113, -207],
      [38, -277],
      [-220, -356],
      [-121, -145],
      [-100, -258],
      [-75, -313],
      [123, -315],
      [246, -441],
      [12, -28],
      [45, -110],
      [48, -117],
      [12, -39],
      [-133, -2],
      [3, -172],
      [1, -59],
      [-19, -122],
      [-40, -250],
      [-28, -327],
      [-6, -67],
      [35, -339],
      [83, -314],
      [0, -169],
      [0, -232],
      [132, -138],
      [-10, -224],
      [-17, -66],
      [-4, -15],
    ],
    [
      [3161, 15044],
      [-3, 2],
      [-18, 12],
      [-38, -60],
      [-65, -101],
      [0, -149],
      [0, -306],
      [24, -564],
      [-59, -279],
      [-57, -269],
      [-92, -283],
      [-99, -304],
      [-241, -414],
      [-57, -97],
      [-128, -215],
      [-141, -235],
      [-133, -120],
      [-290, -260],
      [-18, -14],
      [-288, -225],
      [-44, -95],
      [-165, -356],
      [-25, -726],
      [151, -641],
      [16, -672],
      [-111, -616],
      [-77, -142],
      [-47, -87],
      [-42, -71],
      [-62, -104],
      [118, -226],
      [117, -246],
      [-35, -123],
      [-58, -208],
      [-59, -164],
      [-26, -72],
      [-91, -264],
      [176, -750],
      [144, -240],
      [-19, -739],
      [328, -121],
      [174, -64],
      [890, -205],
      [449, -104],
      [196, 15],
      [457, 35],
      [450, -48],
      [142, -69],
      [338, -163],
      [381, -228],
      [146, -87],
      [285, -330],
      [137, -159],
      [217, -70],
      [311, -102],
      [225, -357],
    ],
    [
      [6445, 2564],
      [147, -233],
      [22, -34],
      [295, -26],
      [51, -157],
      [-5, -109],
      [-32, -563],
      [-145, -235],
      [-65, -256],
      [-47, -157],
      [-171, -575],
    ],
    [
      [6495, 219],
      [-126, -14],
      [-272, -179],
      [-165, 6],
      [-306, 9],
      [-451, -12],
      [-443, 55],
      [-362, 74],
      [-339, -40],
      [-59, -7],
      [-223, -58],
      [-18, -23],
      [-3, -4],
      [-208, 24],
      [-248, -7],
      [-48, -1],
      [-296, 53],
      [-262, 18],
      [-84, -13],
      [-280, -42],
      [-316, -2],
      [-184, -39],
      [-79, -17],
      [-69, 22],
      [-115, 36],
      [-64, 21],
      [-60, 17],
      [-26, 7],
      [-68, 19],
      [-3, 1],
      [-265, 99],
      [-146, 370],
      [-326, 827],
      [-446, 1130],
      [1, 89],
      [1, 44],
      [1, 57],
      [9, 53],
      [27, 157],
      [1, 7],
      [144, 188],
      [9, 13],
      [26, 41],
      [-12, 108],
      [-6, 55],
      [-5, 14],
      [-8, 20],
      [-178, 13340],
      [-89, 280],
      [11, 55],
      [2, 12],
      [6, 41],
      [-4, 1],
      [-14, 0],
      [-2, 129],
      [-1, 61],
      [-54, 189],
      [38, 0],
      [-7, 96],
      [-6, 97],
      [-10, 146],
      [29, 676],
      [37, 295],
      [11, 87],
      [20, 457],
      [-6, 185],
      [-12, 436],
      [22, 703],
      [34, 396],
      [-10, 281],
      [-3, 118],
      [-2, 38],
      [-19, 709],
      [-22, 826],
      [6, 430],
      [59, 191],
      [8, 294],
      [162, 178],
      [274, 56],
      [330, 16],
      [309, 36],
      [350, 49],
      [206, 9],
      [282, 15],
      [502, -18],
      [639, 25],
      [288, 56],
      [269, -26],
      [338, -46],
      [539, 16],
      [424, 15],
      [301, 88],
      [288, -41],
      [652, 33],
    ],
    [
      [23639, 19301],
      [-2, -75],
      [65, -311],
      [52, -246],
      [32, -122],
      [27, -106],
      [66, -254],
      [-15, -389],
      [-35, -101],
      [-11, 0],
      [-20, 0],
      [-14, -115],
      [-3, -25],
      [-11, -32],
      [2, -13],
      [4, -24],
      [20, -828],
      [-112, -338],
      [-51, -155],
      [72, -579],
      [-86, -712],
      [-160, -365],
      [-141, -412],
      [-45, -257],
      [-58, -291],
      [-145, -222],
      [-101, -461],
      [-145, -201],
      [-77, -182],
      [-62, -102],
      [-27, -413],
      [-24, -63],
      [-95, -255],
      [-52, -257],
      [-60, -400],
      [-19, -340],
      [-52, -359],
      [-103, -501],
      [-85, -284],
      [-124, -202],
      [-72, -692],
      [-373, -171],
      [-63, -182],
      [-147, -358],
      [-143, -541],
      [-124, -417],
    ],
    [
      [21122, 6948],
      [-67, -24],
      [-116, -148],
      [-204, -84],
      [-236, -71],
      [-208, -10],
      [-155, -11],
      [-223, -71],
      [-134, 64],
      [-281, 72],
      [-104, 226],
      [117, 181],
      [224, 119],
      [354, 292],
      [280, 226],
      [287, 335],
      [273, 212],
      [286, 240],
      [192, 248],
      [50, 196],
      [49, 108],
      [118, 208],
      [105, 304],
      [105, 256],
      [65, 270],
      [70, 176],
      [8, 47],
      [-81, 15],
      [-120, 36],
      [-195, -24],
      [-335, 108],
      [-381, 156],
      [-131, 206],
      [-110, 253],
      [74, 426],
      [-109, 314],
      [-100, 50],
      [-242, 58],
      [-384, -48],
      [-417, -54],
      [-672, 25],
      [-482, 124],
      [-459, -107],
      [-498, -46],
      [-704, 120],
      [-1286, 355],
      [-1353, 729],
      [-1027, 500],
      [-846, 469],
      [-300, 162],
      [-314, 127],
      [-376, 47],
      [-381, 102],
      [-327, 203],
      [-87, 15],
      [-518, 8],
      [-300, 22],
    ],
    [
      [9516, 14660],
      [-40, 3],
      [-29, 2],
      [-278, 276],
      [70, 182],
      [84, 176],
      [178, 194],
      [239, 274],
      [287, 260],
      [201, 376],
      [308, 375],
      [281, 294],
      [195, 411],
      [5, 8],
      [14, 21],
      [-60, 0],
      [8, 16],
      [8, 14],
      [15, 25],
      [60, 85],
      [32, 44],
      [4, 6],
      [8, 11],
      [28, 39],
      [48, 55],
      [49, 55],
      [96, 128],
      [23, 31],
      [35, 47],
      [173, 151],
      [127, 151],
      [94, 219],
      [120, 137],
      [31, 67],
      [17, 21],
      [32, 42],
      [18, 74],
      [3, 14],
      [2, 5],
      [85, 181],
      [-35, 31],
      [23, 95],
      [6, 23],
      [22, 102],
      [15, 21],
      [14, 36],
      [29, 74],
      [70, 293],
      [-10, 279],
      [-8, 99],
      [-21, 230],
      [-20, 42],
    ],
    [
      [12172, 20455],
      [14, 4],
      [6, 1],
      [59, 15],
      [104, 27],
    ],
    [
      [12355, 20502],
      [3, -28],
      [3, -4],
      [43, -44],
      [92, -114],
      [91, -29],
      [35, -11],
      [52, 6],
      [75, 9],
      [116, -39],
      [43, -14],
      [100, 14],
      [366, 119],
      [153, 97],
      [102, 55],
      [118, 63],
      [191, 141],
      [42, 31],
      [187, 165],
      [166, 138],
      [280, 132],
      [307, 163],
      [6, 4],
      [157, 81],
      [70, 36],
      [186, 104],
      [207, 254],
      [127, 144],
      [306, 167],
      [147, -14],
      [192, -17],
      [286, -32],
      [196, 2],
      [116, 2],
      [55, -23],
      [170, -71],
      [226, -93],
      [159, -155],
      [119, -47],
      [67, -26],
      [132, -46],
      [7, -14],
      [124, -7],
      [75, -5],
      [55, 3],
      [85, 6],
      [145, -51],
      [120, -42],
      [157, -41],
      [202, -51],
      [93, -27],
      [101, -7],
      [65, -5],
      [212, -100],
      [100, 8],
      [106, -8],
      [146, -10],
      [135, -43],
      [91, -30],
      [279, -92],
      [92, 18],
      [121, 24],
      [149, -60],
      [129, -53],
      [140, -73],
      [179, 15],
      [133, -107],
      [126, -101],
      [297, -270],
      [153, -26],
      [179, -221],
      [40, -49],
      [62, -106],
      [17, -29],
      [5, -43],
      [14, -121],
      [-11, -9],
      [-56, -46],
      [75, -151],
      [123, -249],
      [-27, -231],
      [46, -102],
      [159, -107],
      [212, -73],
      [86, -3],
      [54, -3],
      [103, -24],
      [89, -22],
      [273, 44],
      [167, 124],
      [137, 59],
      [76, 32],
      [103, 106],
      [52, 52],
    ],
    [
      [25632, 26734],
      [19, -395],
      [45, -139],
      [14, -45],
      [-109, -572],
      [-166, -245],
      [-34, -50],
      [-327, -22],
      [-158, -10],
      [-27, -31],
      [-220, -257],
      [-81, -247],
      [-155, -470],
      [-248, -663],
      [-120, -532],
      [-29, -130],
      [-194, -451],
      [-168, -594],
      [10, -216],
      [14, -281],
      [12, -251],
      [-23, -148],
      [-45, -283],
      [-40, -251],
      [17, -167],
      [6, -59],
      [34, -332],
      [-20, -592],
    ],
    [
      [12355, 20502],
      [23, 6],
      [187, 315],
      [228, 343],
      [18, 27],
      [334, 541],
      [253, 288],
      [137, 158],
      [387, 521],
      [321, 527],
      [267, 316],
      [232, 329],
      [267, 431],
      [306, 480],
      [122, 232],
      [236, 186],
      [301, 514],
      [123, 233],
      [154, 273],
      [143, 117],
      [58, 4],
      [61, 4],
      [266, -11],
      [93, 83],
      [393, 126],
      [718, -27],
      [452, 45],
      [458, 93],
      [439, -17],
      [671, -61],
      [551, -29],
      [286, -58],
      [404, -310],
      [312, -92],
      [280, 104],
      [254, 296],
      [227, 336],
      [114, 218],
      [287, 473],
      [221, 410],
      [240, 166],
      [259, 91],
      [193, -39],
      [311, -167],
      [192, -305],
      [79, -264],
      [118, -340],
      [59, -258],
      [192, -189],
      [53, -115],
      [386, -127],
      [25, -8],
      [34, -11],
      [292, 133],
      [132, 150],
      [29, 21],
      [99, 70],
    ],
    [
      [9516, 14660],
      [-12, -20],
      [-31, -50],
      [-144, -133],
      [-195, -61],
    ],
    [
      [9134, 14396],
      [-176, -55],
      [-572, -11],
      [-543, 91],
      [-356, 447],
      [-330, 366],
      [-592, -58],
      [-852, -271],
      [-629, -220],
      [-458, -73],
      [-599, -10],
      [-327, 94],
      [-353, 223],
      [-186, 125],
    ],
    [
      [6320, 24425],
      [461, 98],
      [365, -135],
      [466, -163],
      [252, -325],
      [599, -458],
      [375, -310],
      [395, -268],
      [310, -386],
      [476, -465],
      [429, -337],
      [376, -269],
      [72, -90],
      [192, -242],
      [318, -351],
      [238, -229],
      [294, -100],
      [230, 59],
      [4, 1],
    ],
    [
      [21122, 6948],
      [-33, -111],
      [-199, -282],
      [-7, 0],
      [-284, -498],
      [-458, -461],
      [-261, -362],
      [-262, -376],
      [-317, -444],
      [-166, -296],
      [-314, -246],
      [-549, -283],
      [-487, -127],
      [-408, -286],
      [-353, -204],
      [-428, -245],
      [-427, -223],
      [-400, -150],
      [-225, -207],
      [-473, -141],
      [-716, -151],
      [-607, -112],
      [-385, -102],
      [-454, -203],
      [-272, -151],
      [-643, -213],
      [-769, -103],
      [-553, -92],
      [-620, -78],
      [-804, -203],
      [-801, -28],
      [-431, -26],
      [-414, -204],
      [-694, -76],
      [-152, -17],
      [-261, -28],
    ],
    [
      [6445, 2564],
      [190, 228],
      [125, 208],
      [273, 253],
      [92, 318],
      [92, 304],
      [180, 329],
      [313, 239],
      [625, 363],
      [678, 348],
      [522, 724],
      [450, 359],
      [328, 762],
      [-134, 478],
      [-109, 307],
      [-225, 241],
      [-127, 485],
      [-41, 367],
      [6, 366],
      [50, 203],
      [180, 309],
      [281, 315],
      [332, 184],
      [595, 207],
      [682, 165],
      [341, 293],
      [59, 359],
      [-103, 286],
      [-229, 405],
      [-315, 440],
      [-400, 217],
      [-541, 240],
      [-229, 371],
      [-190, 288],
      [-308, 155],
      [-109, 314],
      [-285, 215],
      [-334, 156],
      [-26, 31],
    ],
  ],
  transform: {
    scale: [0.000034311928183496, 0.000033939676528755056],
    translate: [6.7463384718515105, 8.403415462965462],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Abaji",
            id: "FCT001",
            nameAbbr: "Abaji",
            parent: "Federal Capital Territory",
            parentAbbr: "FCT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.33436592028,
            Shape_Area: 0.08204462493,
          },
        },
        {
          arcs: [[4, 5, 6, 7, 8]],
          type: "Polygon",
          properties: {
            name: "Abuja Municipal",
            id: "FCT002",
            nameAbbr: "Abuja Municipal",
            parent: "Federal Capital Territory",
            parentAbbr: "FCT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.86746127945,
            Shape_Area: 0.12980011149,
          },
        },
        {
          arcs: [[9, -9, 10]],
          type: "Polygon",
          properties: {
            name: "Bwari",
            id: "FCT003",
            nameAbbr: "Bwari",
            parent: "Federal Capital Territory",
            parentAbbr: "FCT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.38973673543,
            Shape_Area: 0.06709645734,
          },
        },
        {
          arcs: [[-7, 11, 12, -1, 13]],
          type: "Polygon",
          properties: {
            name: "Gwagwalada",
            id: "FCT004",
            nameAbbr: "Gwagwalada",
            parent: "Federal Capital Territory",
            parentAbbr: "FCT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.17096463853,
            Shape_Area: 0.07925842211,
          },
        },
        {
          arcs: [[14, -3, 15, -12, -6]],
          type: "Polygon",
          properties: {
            name: "Kuje",
            id: "FCT005",
            nameAbbr: "Kuje",
            parent: "Federal Capital Territory",
            parentAbbr: "FCT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.9622063345,
            Shape_Area: 0.13976327484,
          },
        },
        {
          arcs: [[-16, -2, -13]],
          type: "Polygon",
          properties: {
            name: "Kwali",
            id: "FCT006",
            nameAbbr: "Kwali",
            parent: "Federal Capital Territory",
            parentAbbr: "FCT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.37393355537,
            Shape_Area: 0.1062983511,
          },
        },
      ],
    },
  },
};

const edo = {
  type: "Topology",
  arcs: [
    [
      [44636, 30645],
      [-148, -11],
      [-553, -153],
      [-446, -115],
      [-552, -85],
      [-254, 140],
      [-331, -90],
      [-535, -249],
      [-97, -175],
      [-2, -155],
      [-11, -265],
      [124, -206],
      [-19, -332],
      [66, -137],
      [55, -300],
      [-15, -122],
      [-527, -187],
      [-374, 169],
      [-419, -95],
      [-358, -116],
      [-278, -151],
      [-157, -174],
      [-79, -270],
      [-130, -161],
      [-124, -215],
      [-97, -141],
      [-184, -167],
      [-57, -31],
    ],
    [
      [39134, 26851],
      [-187, -100],
      [-356, 6],
      [-417, -20],
      [-86, 96],
      [-209, -3],
      [-384, -75],
      [-299, -185],
      [-238, -186],
      [-111, -52],
    ],
    [
      [36847, 26332],
      [-42, 150],
      [-118, 246],
      [-137, 227],
      [-102, 327],
      [-81, 423],
      [-14, 400],
      [-75, 301],
      [-145, 192],
      [-151, 227],
      [17, 230],
      [216, 64],
      [554, 201],
      [459, 101],
      [654, 98],
      [498, 32],
      [411, 74],
      [537, -77],
      [344, 83],
      [318, 143],
      [131, 181],
      [31, 265],
      [25, 318],
      [14, 401],
      [44, 238],
    ],
    [
      [40235, 31177],
      [282, -34],
      [540, 181],
      [341, 314],
      [283, 416],
      [154, 384],
      [338, 537],
      [301, 362],
      [253, 267],
      [365, 510],
      [300, 293],
      [108, 97],
    ],
    [
      [43500, 34504],
      [8, -10],
      [52, -90],
      [39, -89],
      [-41, -80],
      [78, -117],
      [167, -98],
      [207, -78],
      [298, -284],
      [170, -288],
      [115, -368],
      [-30, -258],
      [-77, -134],
      [180, -567],
      [-219, -220],
      [23, -259],
      [30, -231],
      [116, -314],
      [-11, -258],
      [31, -116],
    ],
    [
      [47959, 30618],
      [-47, -254],
      [-5, -320],
      [-147, -371],
      [-110, -562],
      [-73, -815],
      [152, -560],
      [217, -343],
      [48, -334],
      [269, -447],
      [198, -248],
      [198, -262],
      [76, -293],
      [122, -349],
      [31, -558],
      [107, -511],
      [137, -649],
      [-32, -325],
      [44, -185],
      [32, -137],
      [-110, -147],
      [-255, -349],
      [-199, -261],
      [-319, -212],
      [-385, -96],
      [-530, 138],
      [-449, 131],
      [-500, 8],
      [-65, 1],
      [-478, -59],
      [-384, -41],
      [-372, -82],
      [-393, -177],
      [-128, -25],
      [-326, -192],
      [-435, -257],
      [-212, -187],
      [-348, -307],
      [-489, -324],
      [-400, -170],
      [-454, -183],
      [-510, -371],
      [-680, -444],
      [-500, -231],
      [-138, -64],
      [-249, -43],
      [-922, -440],
      [-474, -202],
      [-793, -313],
      [-57, -27],
    ],
    [
      [37622, 18169],
      [-61, 133],
      [35, 60],
      [89, 101],
      [55, 93],
      [55, 68],
      [35, 121],
      [158, 160],
      [42, 536],
      [-245, 208],
      [-254, 113],
      [-57, 259],
      [39, 298],
      [6, 359],
      [136, 154],
      [112, 284],
      [-15, 278],
      [-232, 215],
      [-212, 220],
      [-168, 424],
      [52, 264],
      [62, 542],
      [147, 351],
      [65, 298],
      [179, 220],
      [359, 198],
      [419, 169],
      [406, 122],
      [218, 193],
      [221, 357],
      [102, 514],
      [-19, 455],
      [-50, 245],
      [-87, 470],
      [-80, 200],
    ],
    [
      [44636, 30645],
      [310, 22],
      [173, -145],
      [253, -147],
      [571, -17],
      [653, 23],
      [626, 118],
      [737, 119],
    ],
    [
      [35102, 24641],
      [-53, -336],
      [-61, -426],
      [284, -344],
      [-104, -569],
      [191, -302],
      [-71, -230],
      [-309, -402],
      [-244, -84],
      [-640, -97],
      [-302, 32],
      [-46, 75],
      [-276, -56],
      [-283, -22],
      [-169, -31],
      [-467, -196],
      [-199, -261],
      [-149, -79],
    ],
    [
      [32204, 21313],
      [-86, 103],
      [-106, 104],
      [-105, 124],
      [-104, 205],
      [-70, 293],
      [-135, 383],
      [-157, 260],
      [-124, 206],
      [-135, 383],
      [-82, 381],
      [-23, 245],
      [12, 346],
      [31, 230],
      [152, 263],
      [77, 168],
      [178, 194],
      [-15, 340],
      [79, 351],
      [27, 373],
      [11, 258],
      [29, 156],
      [23, 169],
      [-9, 272],
      [-70, 293],
      [-151, 206],
      [-129, 308],
      [-293, 222],
      [-166, 118],
      [-156, 315],
      [-210, 323],
      [-159, 172],
      [-266, 175],
      [-256, 378],
      [-179, 165],
      [-211, 143],
    ],
    [
      [29426, 29938],
      [363, 113],
      [162, 58],
      [298, 110],
      [102, -1],
    ],
    [
      [30351, 30218],
      [26, -1],
      [229, 51],
      [257, 117],
      [266, 186],
      [332, 171],
      [326, 205],
      [224, 152],
      [555, 262],
      [709, 144],
      [559, 86],
      [61, 12],
    ],
    [
      [33895, 31603],
      [243, 50],
      [391, 75],
      [445, 81],
      [512, 93],
      [575, 221],
      [310, -174],
    ],
    [
      [36371, 31949],
      [-58, -75],
      [-62, -114],
      [-90, -128],
      [-77, -195],
      [11, -164],
      [-90, -147],
      [-128, -32],
      [-155, -4],
      [-181, 3],
      [-183, -58],
      [-117, -202],
      [-25, -291],
      [88, -396],
      [28, -326],
      [-25, -305],
      [-103, -168],
      [-246, -213],
      [-299, -205],
      [-326, -212],
      [-150, -133],
      [-110, -121],
      [-76, -148],
      [-11, -271],
      [45, -123],
      [57, -232],
      [163, -267],
      [24, -184],
      [30, -231],
      [33, -62],
      [98, -138],
      [72, -191],
      [62, -327],
      [-15, -67],
      [50, -239],
      [-65, -284],
      [-50, -148],
      [-238, -207],
      [-177, -119],
      [-224, -145],
      [-338, -137],
      [-197, -99],
      [-163, -78],
      [-103, -175],
      [66, -89],
      [740, 55],
      [101, -15],
      [207, -31],
      [202, 3],
      [148, -2],
      [344, 68],
      [95, 19],
      [149, 42],
    ],
    [
      [49330, 35966],
      [-4, -39],
      [-126, -331],
      [-204, -588],
      [-79, -304],
      [-171, -147],
      [-115, -488],
      [-202, -438],
      [-365, -497],
      [-183, -513],
      [-468, -678],
      [220, -555],
      [240, -147],
      [142, -322],
      [-56, -301],
    ],
    [
      [43500, 34504],
      [-262, 347],
      [-351, 305],
      [-460, 239],
      [-298, -111],
      [-495, 172],
      [-284, -131],
      [-474, -182],
      [-318, -151],
      [-243, -50],
      [-289, 39],
      [-144, 226],
      [11, 258],
      [32, 291],
      [112, 277],
      [79, 331],
      [211, 180],
      [246, 200],
      [347, 285],
      [131, 243],
      [139, 282],
      [-364, 346],
      [-366, 196],
      [-233, 161],
      [-37, 238],
      [123, 140],
      [211, 112],
      [280, 246],
      [362, 354],
      [267, 321],
      [-15, 320],
      [-18, 611],
      [-60, 395],
      [-95, 368],
      [17, 168],
    ],
    [
      [41262, 41530],
      [395, -39],
      [631, -72],
      [592, 44],
      [371, 55],
      [348, -94],
      [304, -297],
      [446, -735],
      [187, -505],
      [340, -576],
      [23, -272],
      [-49, -101],
      [-29, -142],
      [-41, -441],
      [-112, -283],
      [-35, -529],
      [216, -350],
      [385, -299],
      [942, -382],
      [529, -138],
      [603, -119],
      [844, -205],
      [818, -109],
      [360, 25],
    ],
    [
      [43673, 46687],
      [18, -5],
      [90, -246],
      [5, -136],
      [134, -444],
      [166, -132],
      [180, -132],
      [24, -144],
      [-136, -99],
      [161, -458],
      [150, -316],
      [215, -31],
      [250, 57],
      [317, 70],
      [223, 57],
      [379, 129],
      [379, 116],
      [205, -180],
      [253, 247],
      [257, 57],
      [177, 167],
      [290, 35],
      [385, 62],
      [203, 98],
      [379, -312],
      [179, -180],
      [266, -181],
      [75, -321],
      [15, -361],
      [170, -322],
      [234, -181],
      [224, -174],
      [226, -160],
      [43, -293],
      [102, -355],
      [165, -193],
      [59, -117],
      [-28, -376],
      [-15, -214],
      [3, -211],
      [55, -375],
      [60, -443],
      [-136, -507],
      [-2, -54],
      [-11, -354],
      [-147, -358],
      [-188, -370],
      [-87, -427],
      [-126, -256],
      [-106, -226],
      [-109, -233],
      [54, -402],
      [30, -218],
      [21, -326],
      [-18, -347],
      [-34, -427],
      [-21, -219],
    ],
    [
      [41262, 41530],
      [-182, 18],
      [-335, 135],
      [-174, 23],
      [-335, 67],
      [-146, 91],
      [-557, 71],
      [-495, 171],
      [-441, 143],
      [-511, 43],
      [-468, 130],
      [-547, 261],
      [-230, -37],
      [-180, 85],
      [8, 50],
    ],
    [
      [36669, 42781],
      [45, 275],
      [69, 168],
      [36, 122],
      [-38, 557],
      [61, 447],
      [34, 481],
      [-59, 504],
      [80, 345],
      [67, 399],
      [-266, 616],
      [111, 235],
      [-97, 219],
      [246, 234],
      [530, -37],
      [389, -101],
      [379, -244],
      [306, -182],
      [391, -353],
      [240, -113],
      [90, 155],
      [50, 196],
      [2, 95],
      [251, 165],
      [135, 18],
      [381, 272],
      [307, 280],
      [254, 322],
      [171, 187],
      [278, 304],
    ],
    [
      [41112, 48347],
      [96, -11],
      [124, 202],
      [222, 43],
      [181, -77],
      [377, 0],
      [201, -37],
      [155, 24],
      [290, 15],
      [52, -123],
      [70, -232],
      [139, -152],
      [62, -307],
      [180, -139],
      [31, -579],
      [105, -151],
      [106, -97],
      [21, 0],
      [149, -39],
    ],
    [
      [40235, 31177],
      [-54, 6],
      [-369, 47],
      [-281, 120],
      [-266, 127],
      [-450, 49],
      [-281, 66],
      [-229, 3],
      [-332, -170],
      [-145, -202],
      [-265, -185],
      [-251, -152],
      [-196, -85],
      [-261, 73],
      [-50, 265],
      [-124, 219],
      [-55, 361],
      [-165, 180],
      [-90, 50],
    ],
    [
      [33895, 31603],
      [1132, 2738],
      [180, 1281],
      [-180, 3111],
      [-97, 1572],
    ],
    [
      [34930, 40305],
      [181, 96],
      [166, 255],
      [258, 186],
      [206, 241],
      [226, 274],
      [98, 270],
      [202, 438],
      [195, 397],
      [125, 263],
      [82, 56],
    ],
    [
      [37622, 18169],
      [-329, -157],
      [-242, -180],
    ],
    [
      [37051, 17832],
      [-53, 120],
      [-91, 239],
      [-306, 168],
      [-123, 321],
      [-172, 193],
      [-104, 185],
      [-258, 297],
      [-212, 166],
      [-155, 16],
      [-395, 89],
      [-463, 28],
      [-532, -18],
      [-222, -64],
      [-297, -2],
      [-282, -36],
      [-170, -99],
      [-69, -128],
      [-131, -120],
    ],
    [
      [33016, 19187],
      [-11, 75],
      [-45, 157],
      [-17, 184],
      [-177, 301],
      [-244, 304],
      [-138, 185],
      [-69, 293],
      [24, 265],
      [-84, 198],
      [-51, 164],
    ],
    [
      [35102, 24641],
      [14, 84],
      [203, 513],
      [179, 255],
      [306, 239],
      [313, 191],
      [156, 113],
      [251, 145],
      [323, 151],
    ],
    [
      [23794, 15043],
      [-1, -56],
      [13, -55],
      [65, -96],
      [-8, -122],
      [11, -129],
      [-3, -211],
      [18, -136],
      [-42, -74],
      [-263, -83],
      [-215, 30],
      [-162, -24],
      [7, -20],
      [-195, 3],
      [-257, -98],
      [-258, -124],
      [-136, -59],
      [0, -7],
      [-67, -39],
      [-137, -141],
      [-97, -174],
      [-70, -155],
      [-42, -142],
      [-69, -94],
      [-49, -135],
      [-2, -109],
      [-2, -101],
      [-36, -190],
      [-45, -298],
      [-37, -162],
      [-72, -291],
      [-9, -183],
      [-31, -230],
      [32, -109],
      [18, -143],
      [-2, -109],
      [-62, -101],
      [-68, -73],
      [-96, -107],
      [-82, -66],
      [-263, -57],
      [-15, -122],
      [-30, -149],
      [-2, -183],
      [65, -130],
      [90, -219],
      [85, -185],
      [90, -205],
      [65, -212],
      [45, -95],
      [70, -239],
      [51, -184],
      [58, -171],
      [65, -96],
      [64, -226],
      [-57, -168],
      [-19, -55],
    ],
    [
      [21705, 7934],
      [-703, 320],
      [-529, 178],
      [-247, 93],
      [-257, 345],
      [-280, 208],
      [-234, 86],
      [-202, -72],
      [-278, -90],
      [-366, -191],
      [-555, -160],
      [-339, -178],
      [-669, -192],
      [-175, -171],
      [-324, -317],
      [-71, -223],
      [-303, -423],
      [-831, 191],
      [-604, 507],
      [-465, 436],
      [-650, 140],
      [-1140, -197],
      [-120, -42],
    ],
    [
      [12363, 8182],
      [35, 109],
      [140, 385],
      [180, 315],
      [80, 345],
      [142, 480],
      [112, 304],
      [80, 220],
    ],
    [
      [13132, 10340],
      [165, -55],
      [402, -81],
      [295, -66],
      [303, 35],
      [337, 49],
      [238, 186],
      [205, 227],
      [220, 281],
      [145, 269],
      [146, 242],
      [159, 310],
      [102, 466],
      [111, 270],
      [215, 384],
      [146, 302],
      [221, 330],
      [197, 152],
      [223, 64],
      [304, 56],
      [174, -16],
      [303, 42],
      [231, 132],
      [116, 147],
      [104, 182],
      [37, 209],
      [38, 265],
      [24, 237],
      [-21, 380],
      [-17, 204],
      [-49, 300],
      [-57, 191],
      [26, 380],
      [-56, 286],
      [-37, 224],
      [29, 75],
      [197, 132],
      [136, 106],
      [82, 94],
      [111, 195],
      [83, 189],
      [37, 49],
      [94, 125],
      [2, 136],
      [-120, 90],
      [-78, -15],
    ],
    [
      [18655, 18100],
      [-6, 19],
      [217, 1540],
    ],
    [
      [18866, 19659],
      [193, -138],
      [274, -58],
      [255, -32],
      [276, 2],
      [167, -50],
      [165, -193],
      [30, -225],
      [97, -232],
      [112, -165],
      [37, -184],
      [17, -197],
      [65, -178],
      [104, -232],
      [128, -396],
      [71, -144],
      [199, -194],
      [349, -33],
      [342, -13],
      [167, -70],
      [208, -72],
      [253, -119],
      [179, -167],
      [159, -192],
      [205, -194],
      [191, -254],
      [178, -234],
      [238, -215],
      [138, -233],
      [66, -62],
      [-35, -102],
      [100, -40],
    ],
    [
      [13132, 10340],
      [75, 205],
      [-1, 359],
      [289, 444],
      [46, 311],
      [81, 481],
      [134, 344],
      [120, 364],
      [177, 588],
      [59, 325],
      [86, 324],
      [262, 383],
      [248, 362],
      [259, 274],
      [98, 222],
      [180, 296],
      [-15, 60],
    ],
    [
      [15230, 15682],
      [178, 80],
      [198, 159],
      [171, 181],
      [240, 294],
      [211, 180],
      [197, 153],
      [421, 278],
      [326, 177],
      [204, 119],
      [197, 126],
      [211, 193],
      [266, 199],
      [231, 146],
      [223, 104],
      [151, 29],
    ],
    [
      [37051, 17832],
      [-194, -145],
      [-212, -221],
      [-111, -175],
      [-37, -64],
      [-184, -312],
      [-403, -408],
      [-262, -356],
      [-253, -294],
      [-304, -23],
      [-224, 297],
      [-295, 480],
      [-335, 516],
      [-360, 584],
      [-475, 199],
      [-522, 192],
      [-263, -43],
      [-415, -265],
      [-425, -489],
      [-145, -235],
      [-161, -385],
      [-113, -345],
      [-78, -678],
      [7, -448],
      [189, -357],
      [360, -217],
      [327, -169],
      [165, -608],
      [46, -544],
      [-28, -489],
      [70, -654],
      [385, -734],
      [302, -502],
      [268, -473],
      [350, -448],
      [303, -338],
      [271, -365],
      [348, -108],
      [252, -229],
      [283, -358],
      [308, -128],
      [296, -5],
      [447, -252],
      [70, -289],
      [24, -100],
      [41, -443],
      [-95, -420],
      [-68, -249],
      [-67, -244],
      [-149, -501],
      [-64, -162],
      [115, 32],
      [-671, -736],
      [-56, -176],
      [-526, -426],
      [-241, -363],
      [-298, -151],
      [-544, -317],
      [-252, -200],
      [-354, -299],
      [-115, -152],
      [-78, -103],
      [-301, -294],
      [-310, -484],
      [-276, -431],
      [-300, -239],
      [-361, -245],
      [-582, -418],
      [-64, -47],
      [-853, -284],
      [-389, -90],
      [-596, -138],
      [-897, -120],
      [-581, -147],
      [-719, 87],
      [-286, 182],
      [114, 426],
      [88, 176],
      [127, 256],
      [-342, 327],
      [-49, 47],
      [-363, 393],
      [154, 392],
      [226, 234],
      [374, 707],
      [205, 588],
      [-223, 398],
      [-727, 359],
      [-653, 453],
      [-73, 42],
      [-386, 265],
      [-183, 364],
      [-335, 481],
      [-423, 504],
      [-653, 392],
      [-560, 295],
      [-613, 364],
      [-374, 196],
      [-12, 6],
    ],
    [
      [23794, 15043],
      [0, 12],
      [-6, 61],
      [-6, 41],
      [155, -10],
      [99, -103],
      [67, -22],
      [329, -19],
      [348, -87],
      [336, -47],
      [463, -55],
      [437, -8],
      [483, -56],
      [363, -6],
      [390, 34],
      [317, 42],
      [203, 126],
      [266, 213],
      [104, 161],
      [57, 202],
      [164, 188],
      [96, 168],
      [185, 180],
      [314, 259],
      [137, 201],
      [205, 208],
      [257, 104],
      [189, 51],
      [89, 87],
      [217, 71],
      [209, 98],
      [189, 24],
      [129, 93],
      [238, 179],
      [332, 116],
      [276, 50],
      [192, 200],
      [298, 165],
      [150, 153],
      [238, 146],
      [123, 140],
      [213, 261],
      [146, 310],
      [231, 213],
    ],
    [
      [32092, 53041],
      [-15, -34],
      [217, -310],
      [471, -42],
      [303, 63],
      [143, 58],
      [108, 39],
      [131, -213],
      [-3, -163],
      [-75, -87],
      [-48, -116],
      [-85, -208],
      [-89, -107],
      [-154, -364],
      [-108, -406],
      [4, -191],
      [-5, -319],
      [90, -253],
      [43, -246],
      [314, -195],
      [40, -21],
      [152, -153],
      [228, -85],
      [276, 36],
      [261, -93],
      [240, -113],
      [90, 162],
      [434, 169],
      [250, 125],
      [258, 97],
      [548, 174],
      [223, 71],
      [259, 186],
      [10, 4],
      [274, 100],
      [204, 153],
      [245, 152],
      [153, -105],
      [58, -171],
      [277, -358],
      [124, -233],
      [125, -131],
      [304, 35],
      [106, -103],
      [186, -133],
      [73, -76],
      [192, -194],
      [38, -163],
      [256, 29],
      [258, -249],
      [209, -418],
      [191, -262],
      [267, -134],
      [148, -2],
      [243, 77],
      [405, 48],
      [173, -21],
    ],
    [
      [34930, 40305],
      [-178, -94],
      [-140, 50],
      [-184, -93],
      [-200, 31],
      [65, 291],
      [-233, 180],
      [-194, 486],
      [-241, 466],
      [-160, 512],
      [-495, 171],
      [-322, 53],
      [-388, 102],
      [-220, 119],
      [-254, 113],
      [-24, 177],
      [-219, 139],
      [3, 217],
      [-111, 199],
      [-137, 247],
      [-198, 234],
      [-234, 79],
      [-198, -153],
      [-210, -119],
      [-197, -118],
      [-260, 106],
      [-94, 22],
      [-196, -58],
      [-42, -142],
      [-169, -31],
      [-208, 24],
      [-142, -31],
      [-164, -188],
      [-330, -7],
      [-272, 215],
      [-118, 212],
      [-305, 209],
      [-340, 196],
      [-341, 142],
      [-274, 59],
      [-414, 179],
    ],
    [
      [26592, 44501],
      [48, 62],
      [130, 140],
      [197, 126],
      [192, 214],
      [170, 66],
      [121, 52],
      [157, 140],
      [211, 98],
      [68, 53],
      [284, 91],
      [151, 221],
      [84, 203],
      [59, 298],
      [100, 365],
      [72, 312],
      [167, 227],
      [40, 54],
      [-200, 133],
      [-43, 293],
      [-109, 321],
      [-157, 309],
      [-56, 259],
      [13, 374],
      [119, 331],
      [137, 120],
      [119, 277],
      [24, 271],
      [-139, 132],
      [-175, 30],
      [-127, 2],
      [-155, 16],
      [-100, 29],
      [-146, 159],
      [-107, 22],
      [-52, 90],
      [-137, 308],
      [20, 373],
      [65, 285],
      [137, 201],
      [199, 201],
      [157, 160],
      [77, 189],
      [95, 67],
      [61, -15],
      [107, -56],
      [215, 10],
      [208, -11],
      [142, 5],
      [95, 100],
      [143, 45],
      [88, 60],
      [95, 80],
      [37, 53],
      [60, 88],
      [49, 101],
      [116, 141],
      [125, 194],
      [98, 284],
      [177, 147],
      [171, 133],
      [144, 222],
      [118, 201],
      [57, 94],
      [25, -47],
      [339, -258],
      [290, 43],
      [122, 32],
      [216, 64],
      [196, 37],
      [327, -168],
      [124, -193],
      [12, -129],
      [-167, -391],
    ],
    [
      [15230, 15682],
      [-93, 376],
      [-49, 286],
      [-117, 233],
      [-49, 334],
      [23, 176],
      [112, 235],
      [248, 383],
      [451, 440],
      [130, 175],
      [351, 496],
      [349, 402],
      [618, 396],
      [739, -94],
      [315, -53],
      [264, 166],
      [236, 9],
      [76, 40],
    ],
    [
      [18834, 19682],
      [32, -23],
    ],
    [
      [16313, 32499],
      [106, -172],
      [-56, -169],
      [-172, -255],
      [-300, -240],
      [-273, -233],
      [-144, -174],
      [116, -308],
      [95, -389],
      [269, -385],
      [102, -396],
      [47, -402],
      [243, -392],
      [116, -328],
      [350, 14],
      [192, 221],
      [193, 255],
      [143, 127],
      [195, 24],
      [259, 199],
      [343, 416],
      [449, 264],
      [416, -28],
      [215, -31],
      [212, -194],
      [349, -509],
      [381, -169],
      [279, 185],
      [378, 96],
      [602, 207],
      [90, 189],
      [148, 384],
      [22, 35],
    ],
    [
      [21678, 30341],
      [231, 103],
      [190, 125],
      [209, 17],
      [202, 4],
      [176, 98],
      [132, 263],
      [65, 270],
      [182, 38],
      [222, -17],
      [187, -58],
      [382, -88],
      [140, -50],
      [288, -86],
      [214, -78],
      [342, -27],
      [276, 23],
      [199, -33],
    ],
    [
      [25315, 30845],
      [-41, -167],
      [-160, -323],
      [-58, -305],
      [-118, -228],
      [-55, -74],
      [-104, -161],
      [-129, -534],
      [-193, -289],
      [-235, -396],
      [-202, -459],
      [-81, -426],
      [-209, -472],
      [-224, -526],
      [-375, -801],
      [-196, -445],
      [-268, -749],
      [-329, -449],
      [-349, -340],
      [-374, -313],
      [-436, -332],
      [-557, -343],
      [-313, -226],
      [-299, -232],
      [-246, -213],
      [-287, -287],
      [-409, -346],
      [-267, -308],
      [-151, -201],
      [-186, -282],
      [-59, -766],
      [214, -58],
      [187, -92],
      [28, -20],
    ],
    [
      [12363, 8182],
      [-232, -82],
      [-839, 97],
      [-562, 247],
      [-467, -148],
      [-298, -144],
      [-28, -490],
      [236, -405],
      [183, -343],
      [-244, -505],
      [-653, -478],
      [-390, -361],
      [-599, -486],
      [-286, -212],
      [-781, -34],
      [-11, 584],
      [-11, 592],
      [-217, 758],
      [-93, 457],
      [143, 528],
      [78, 291],
      [110, 576],
      [7, 205],
    ],
    [
      [7409, 8829],
      [9, 9],
      [252, 213],
      [170, 112],
      [109, 80],
      [76, 175],
      [-30, 197],
      [-189, -57],
      [-133, 77],
      [-39, 102],
      [-65, 130],
      [-10, 211],
      [49, 128],
      [48, 60],
      [116, 148],
      [22, 115],
      [22, 95],
      [5, 278],
      [28, 88],
      [98, 269],
      [131, 182],
      [108, 4],
      [196, 85],
      [115, 19],
      [-1, -14],
      [68, 26],
      [155, 11],
      [138, -145],
      [217, 58],
      [183, 91],
      [163, 120],
      [143, 120],
      [178, 200],
      [110, 168],
      [137, 147],
      [132, 276],
      [79, 277],
      [-25, 143],
      [-38, 130],
      [-46, 82],
      [-84, 219],
      [-64, 218],
      [-25, 102],
      [23, 143],
      [-52, 143],
      [-84, 185],
      [20, 13],
      [191, 146],
      [252, 240],
      [137, 140],
      [294, 308],
      [97, 168],
      [110, 140],
      [129, 66],
      [101, 19],
      [202, 23],
      [94, -1],
      [161, -30],
      [27, -1],
      [147, -2],
      [87, -42],
      [61, -8],
      [100, -22],
      [94, -36],
      [80, -28],
      [47, -15],
      [46, -14],
      [34, 6],
      [48, 54],
      [41, 53],
      [44, 230],
      [-12, 96],
      [36, 128],
      [42, 81],
      [14, 47],
      [35, 108],
      [172, 221],
      [20, 20],
      [144, 147],
      [96, 141],
      [35, 67],
      [117, 202],
      [57, 162],
      [43, 196],
      [-93, 69],
      [-60, 42],
      [-112, 97],
      [-133, 125],
      [-66, 75],
      [-72, 124],
      [-38, 109],
      [-19, 130],
      [-49, 238],
      [-44, 198],
      [15, 115],
      [-71, 191],
      [15, 61],
      [36, 176],
      [-31, 170],
      [64, 203],
      [-70, 232],
      [-4, 183],
      [77, 223],
      [19, 298],
      [6, -13],
      [164, 160],
      [20, 20],
      [266, 219],
      [96, 100],
      [130, 134],
      [21, 88],
      [-10, 183],
      [-4, 197],
      [2, 129],
      [69, 101],
      [45, 271],
      [76, 175],
      [76, 114],
      [138, 208],
      [80, -35],
      [148, -17],
      [35, 88],
      [115, 453],
      [-25, 102],
      [21, 88],
      [-66, 103],
      [-99, 110],
      [-79, 50],
      [-86, 130],
      [-185, 200],
      [-81, 8],
      [-99, 70],
      [-132, 151],
      [-139, 145],
      [21, 81],
      [-83, 226],
      [-80, 49],
      [-260, 167],
      [-74, -5],
      [-40, 21],
      [-159, 131],
      [-211, 282],
      [-110, 274],
      [-77, 246],
      [-56, 218],
      [-44, 238],
      [-62, 307],
      [-183, 295],
      [-32, 150],
      [-64, 177],
      [-66, 117],
      [-4, 115],
      [-152, 186],
      [-190, 295],
      [-170, 316],
      [-85, 171],
      [-71, 130],
      [-10, 238],
      [51, 264],
      [92, 290],
      [158, 174],
      [142, 86],
      [191, 152],
      [123, 168],
      [118, 222],
      [112, 236],
      [232, 233],
      [50, 176],
      [84, 189],
      [51, 284],
      [79, 284],
      [85, 284],
      [79, 283],
      [187, 377],
      [78, 271],
      [160, 309],
      [38, 278],
      [63, 255],
    ],
    [
      [13025, 33303],
      [254, -4],
      [533, 59],
      [660, 43],
      [358, 82],
      [369, -61],
      [402, -102],
      [166, -139],
      [292, -256],
      [164, -282],
      [90, -144],
    ],
    [
      [7409, 8829],
      [6, 189],
      [-83, 287],
      [-431, 347],
      [-350, 448],
      [-2558, 1332],
      [114, 280],
      [72, 271],
      [-7, -7],
      [-7, 21],
      [-230, 296],
      [-158, 241],
      [-29, 251],
      [-151, 275],
      [-265, 277],
      [-133, 83],
      [-227, 133],
      [-187, 51],
      [-545, -4],
      [-138, 220],
      [-2, 285],
      [-211, 269],
      [-125, 220],
      [-185, 187],
      [-117, 219],
      [-261, 147],
      [-87, 36],
      [-206, 119],
      [-126, 138],
      [-106, 70],
      [-126, 138],
      [-329, 26],
      [-138, 213],
      [-71, 219],
      [57, 169],
      [62, 114],
      [-131, -208],
      [145, 262],
      [230, 71],
      [93, 359],
      [15, 42],
      [70, 208],
      [27, 407],
      [33, 387],
      [214, 323],
      [202, 30],
      [72, 264],
      [264, 104],
      [274, -79],
      [590, 275],
      [258, 111],
      [133, 345],
      [161, 391],
      [49, 536],
      [55, 509],
      [260, 281],
      [252, 200],
      [309, 334],
      [173, 358],
      [218, 574],
      [74, 413],
      [94, 406],
      [-97, 281],
      [-175, 410],
      [-119, 138],
      [-231, 269],
      [-81, 396],
      [-224, 283],
      [-194, 119],
      [-180, 70],
      [-207, 113],
      [-14, 381],
      [66, 379],
      [26, 319],
      [-77, 274],
      [79, 277],
      [299, 192],
      [-108, 9],
      [58, 196],
      [-52, 157],
      [46, 332],
      [12, 360],
      [229, 458],
      [137, 149],
      [219, 240],
      [287, 233],
      [184, 146],
      [105, 250],
      [105, 243],
      [327, 259],
      [425, 101],
      [61, 20],
      [56, 155],
      [17, 197],
      [92, 318],
      [209, 411],
      [152, 228],
      [297, 97],
      [99, 284],
      [-18, 123],
      [-16, 251],
      [-23, 273],
      [215, 370],
      [305, 212],
      [732, 266],
      [481, -158],
      [518, -29],
      [595, 133],
      [478, 46],
      [302, -60],
      [489, -110],
      [661, 77],
      [645, -46],
      [653, 17],
      [611, -92],
      [304, -6],
    ],
    [
      [30351, 30218],
      [-27, 75],
      [2, 102],
      [-11, 204],
      [38, 257],
      [-81, 402],
      [11, 217],
      [-17, 224],
      [12, 326],
      [-96, 300],
      [-377, 7],
      [-363, -1],
      [-89, 294],
      [-102, 341],
      [-75, 341],
      [-116, 320],
      [-103, 301],
      [-207, 506],
      [-118, 199],
      [-326, 216],
      [-123, 280],
      [-62, 341],
      [-31, 197],
      [-22, 252],
      [58, 304],
      [72, 278],
      [109, 120],
      [145, 235],
      [36, 155],
      [133, 290],
      [52, 325],
      [-105, 158],
      [-32, 82],
      [-52, 137],
      [-152, 172],
      [-286, 168],
      [-314, 100],
      [-321, 148],
      [-361, 122],
      [-388, 136],
      [-327, 161],
      [-395, 62],
      [-335, 93],
      [-268, 25],
      [-268, 46],
      [-261, 111],
    ],
    [
      [24808, 39347],
      [-38, 184],
      [-107, 43],
      [-4, 170],
      [78, 216],
      [109, 107],
      [48, 40],
      [84, 209],
      [78, 250],
      [51, 258],
      [125, 242],
      [98, 250],
      [131, 208],
      [125, 202],
      [111, 236],
      [71, 250],
      [36, 143],
      [44, 182],
      [110, 189],
      [97, 141],
      [123, 154],
      [57, 169],
      [-126, 111],
      [23, 190],
      [-24, 191],
      [43, 149],
      [69, 93],
      [111, 250],
      [110, 127],
      [117, 155],
      [34, 45],
    ],
    [
      [29426, 29938],
      [-15, 11],
      [-233, 139],
      [-252, 236],
      [-234, 58],
      [-436, 102],
      [-248, 5],
      [-316, 12],
      [-471, -33],
      [-401, 143],
      [-255, 58],
      [-322, 47],
      [-376, 40],
      [-315, 19],
      [-208, 65],
      [-29, 5],
    ],
    [
      [21678, 30341],
      [109, 174],
      [209, 438],
      [134, 392],
      [88, 447],
      [32, 339],
      [80, 353],
      [18, 305],
      [75, 488],
      [0, 7],
      [155, 419],
      [116, 120],
      [416, -109],
      [146, -132],
      [70, 217],
      [-70, 225],
      [5, 333],
      [136, 86],
      [115, 12],
      [6, 0],
      [48, 46],
      [103, 101],
      [7, 0],
      [2, 169],
      [-77, 219],
      [-138, 186],
      [-199, 187],
      [-106, 111],
      [-112, 124],
      [92, 297],
      [36, 136],
      [21, 101],
      [-126, 98],
      [-2, 285],
      [38, 258],
      [77, 230],
      [52, 264],
      [97, 216],
      [5, 319],
      [165, 167],
      [42, 135],
      [35, 81],
      [178, 208],
      [165, 201],
      [142, 66],
      [137, 133],
      [258, 179],
      [0, -20],
      [175, -10],
      [92, 277],
      [83, 128],
    ],
  ],
  transform: {
    scale: [0.00003431203109538135, 0.00003393968304708161],
    translate: [4.978163384681407, 5.7389534339034345],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4]],
          type: "Polygon",
          properties: {
            name: "Esan North-East",
            id: "ED001",
            nameAbbr: "Esan North-East",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.97879565561,
            Shape_Area: 0.02818909174,
          },
        },
        {
          arcs: [[5, 6, -1, 7]],
          type: "Polygon",
          properties: {
            name: "Esan South-East",
            id: "ED002",
            nameAbbr: "Esan South-East",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.4776705228,
            Shape_Area: 0.1038707169,
          },
        },
        {
          arcs: [[8, 9, 10, 11, 12, 13]],
          type: "Polygon",
          properties: {
            name: "Esan West",
            id: "ED003",
            nameAbbr: "Esan West",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.19592103982,
            Shape_Area: 0.04119309862,
          },
        },
        {
          arcs: [[14, -8, -5, 15, 16]],
          type: "Polygon",
          properties: {
            name: "Etsako Central",
            id: "ED004",
            nameAbbr: "Etsako Central",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.29382075706,
            Shape_Area: 0.05560271293,
          },
        },
        {
          arcs: [[17, -17, 18, 19, 20]],
          type: "Polygon",
          properties: {
            name: "Etsako East",
            id: "ED005",
            nameAbbr: "Etsako East",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.63145480771,
            Shape_Area: 0.10050634846,
          },
        },
        {
          arcs: [[-16, -4, 21, -13, 22, 23, -19]],
          type: "Polygon",
          properties: {
            name: "Etsako West",
            id: "ED006",
            nameAbbr: "Etsako West",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.41477070851,
            Shape_Area: 0.07911367249,
          },
        },
        {
          arcs: [[-7, 24, 25, 26, -9, 27, -2]],
          type: "Polygon",
          properties: {
            name: "Igueben",
            id: "ED007",
            nameAbbr: "Igueben",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.01035194988,
            Shape_Area: 0.03240618631,
          },
        },
        {
          arcs: [[28, 29, 30, 31, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Ikpoba-Okha",
            id: "ED008",
            nameAbbr: "Ikpoba-Okha",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.46478732338,
            Shape_Area: 0.06840112195,
          },
        },
        {
          arcs: [[34, 35, -32]],
          type: "Polygon",
          properties: {
            name: "Oredo Edo",
            id: "ED009",
            nameAbbr: "Oredo Edo",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.74159034157,
            Shape_Area: 0.02135340157,
          },
        },
        {
          arcs: [[36, -29, 37, -26]],
          type: "Polygon",
          properties: {
            name: "Orhionmwon",
            id: "ED010",
            nameAbbr: "Orhionmwon",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.38804956212,
            Shape_Area: 0.18821107362,
          },
        },
        {
          arcs: [[38, -20, -24, 39, 40]],
          type: "Polygon",
          properties: {
            name: "Akoko-Edo",
            id: "ED011",
            nameAbbr: "Akoko-Edo",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.87615835539,
            Shape_Area: 0.10601270203,
          },
        },
        {
          arcs: [[-33, -36, 41, 42]],
          type: "Polygon",
          properties: {
            name: "Egor",
            id: "ED012",
            nameAbbr: "Egor",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.42465504632,
            Shape_Area: 0.00878469318,
          },
        },
        {
          arcs: [[-3, -28, -14, -22]],
          type: "Polygon",
          properties: {
            name: "Esan Central",
            id: "ED013",
            nameAbbr: "Esan Central",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.94638648922,
            Shape_Area: 0.02081298057,
          },
        },
        {
          arcs: [[43, 44, 45, -42, -35, -31, 46, 47, 48]],
          type: "Polygon",
          properties: {
            name: "Ovia North-East",
            id: "ED014",
            nameAbbr: "Ovia North-East",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.11847089277,
            Shape_Area: 0.19286863555,
          },
        },
        {
          arcs: [[-48, 49]],
          type: "Polygon",
          properties: {
            name: "Ovia South-West",
            id: "ED015",
            nameAbbr: "Ovia South-West",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.46364961211,
            Shape_Area: 0.2312220074,
          },
        },
        {
          arcs: [[-23, -12, 50, 51, -40]],
          type: "Polygon",
          properties: {
            name: "Owan East",
            id: "ED016",
            nameAbbr: "Owan East",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.47070661026,
            Shape_Area: 0.10084143237,
          },
        },
        {
          arcs: [[-11, 52, -45, 53, -51]],
          type: "Polygon",
          properties: {
            name: "Owan West",
            id: "ED017",
            nameAbbr: "Owan West",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.1591311524,
            Shape_Area: 0.05746026689,
          },
        },
        {
          arcs: [[-10, -27, -38, -34, -43, -46, -53]],
          type: "Polygon",
          properties: {
            name: "Uhunmwonde",
            id: "ED018",
            nameAbbr: "Uhunmwonde",
            parent: "Edo",
            parentAbbr: "ED",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.68154550003,
            Shape_Area: 0.16505031855,
          },
        },
      ],
    },
  },
};

const delta = {
  type: "Topology",
  arcs: [
    [
      [46932, 36128],
      [-8, -9],
      [-109, -52],
      [-20, -14],
      [-61, -32],
      [-20, -7],
      [-94, 29],
      [-40, 1],
      [-108, 22],
      [-87, -6],
      [-81, 9],
      [-40, 27],
      [-52, 49],
      [-73, 96],
      [-52, 76],
      [-46, 89],
      [-7, 20],
      [-84, 205],
      [-31, 150],
      [-26, 75],
      [-38, 110],
      [-84, 184],
      [-20, 41],
      [-80, 56],
      [-93, 56],
      [-27, 14],
      [-100, 43],
      [-87, 28],
      [-114, 23],
      [-100, 35],
      [-154, 44],
      [-127, 36],
      [-155, 3],
      [-168, -11],
      [-134, 29],
      [-140, 37],
      [-88, 28],
      [-128, -52],
      [-170, -119],
      [-82, -53],
      [-217, -159],
      [-95, -67],
      [-170, -99],
      [-96, -100],
      [-123, -113],
      [-89, -94],
      [-183, -132],
      [-150, -93],
      [-176, -98],
      [-149, -93],
      [-210, -98],
      [-149, -59],
      [-231, -105],
      [-149, -78],
      [-176, -99],
      [-256, -3],
      [-141, -11],
      [-148, 16],
      [-181, 24],
      [-162, -31],
      [-223, -85],
      [-115, -46],
      [-174, 31],
      [-134, 43],
      [-38, -9],
    ],
    [
      [39869, 35830],
      [-48, 71],
      [-63, 314],
      [125, 228],
      [71, 203],
      [103, 154],
      [213, 289],
      [118, 221],
      [116, 100],
      [-24, 184],
      [-77, 266],
      [-10, 224],
      [-2, 258],
      [-122, -4],
      [-107, 22],
      [-77, 171],
      [-84, 239],
      [-158, 200],
      [-106, 110],
      [30, 217],
      [-44, 150],
      [-98, 171],
      [3, 190],
      [-4, 177],
      [-64, 218],
      [-49, 141],
    ],
    [
      [39511, 40344],
      [500, 232],
      [680, 443],
      [510, 372],
      [454, 182],
      [400, 170],
      [490, 325],
      [348, 307],
      [211, 186],
      [435, 258],
      [326, 191],
      [128, 25],
      [393, 177],
      [372, 82],
      [384, 41],
      [479, 60],
      [64, -1],
    ],
    [
      [45685, 43394],
      [-26, -192],
      [-43, -176],
      [-38, -237],
      [-12, -319],
      [-56, -189],
      [106, -457],
      [-69, -141],
      [129, -342],
      [116, -348],
      [12, -28],
      [138, -240],
      [-29, -149],
      [161, -423],
      [0, -428],
      [-50, -196],
      [-151, -194],
      [-86, -277],
      [-130, -188],
      [-73, -379],
      [-118, -215],
      [127, -416],
      [258, -297],
      [79, -110],
      [138, -185],
      [105, -172],
      [186, -132],
      [164, -261],
      [203, -316],
      [206, -259],
    ],
    [
      [46932, 36128],
      [18, -23],
      [226, -173],
      [126, -132],
      [105, -171],
      [49, -286],
      [-36, -156],
      [-35, -67],
      [-97, -155],
      [-130, -188],
      [-51, -209],
      [-37, -217],
      [-53, -399],
      [-38, -271],
      [-12, -346],
      [-140, -344],
      [110, -253],
      [190, -309],
      [199, -139],
      [84, -226],
      [173, -131],
      [145, -159],
      [92, -12],
    ],
    [
      [47820, 31762],
      [-13, -85],
      [-103, -181],
      [-43, -142],
      [-2, -142],
      [-22, -75],
      [-85, -297],
      [-15, -61],
      [-42, -121],
      [-35, -122],
      [-15, -51],
      [-147, 47],
      [-47, 14],
      [-126, 91],
      [-112, 131],
      [-73, 89],
      [-93, 70],
      [-140, 63],
      [-27, 7],
      [-153, 57],
      [-147, 64],
      [-162, -24],
      [-101, -5],
      [-115, -39],
      [-61, -19],
      [-211, -160],
      [-102, -113],
      [-69, -94],
      [-49, -115],
      [-2, -108],
      [46, -69],
      [52, -103],
      [33, -55],
      [33, -68],
      [38, -164],
      [45, -89],
      [32, -143],
      [11, -109],
      [-36, -148],
      [-29, -156],
      [-65, -264],
      [-65, -284],
      [-57, -189],
      [-24, -95],
    ],
    [
      [45522, 28505],
      [-11, 0],
      [-255, 5],
      [-195, 30],
      [-330, -41],
      [-309, 46],
      [-248, 4],
      [-330, 5],
      [-229, -30],
      [-404, -54],
      [-51, -10],
    ],
    [
      [43160, 28460],
      [-313, -65],
      [-270, -57],
      [-445, -114],
      [-277, -50],
      [-362, 34],
      [-222, 3],
      [-121, -11],
      [-262, 52],
      [-93, 56],
      [-6, 41],
      [-71, 205],
      [-32, 122],
      [-24, 130],
      [-20, 48],
      [8, 34],
      [-12, 149],
      [-44, 143],
      [-73, 90],
      [-146, 104],
      [-39, 62],
      [-122, -18],
      [-68, -67],
      [-123, -93],
      [-68, -107],
      [-77, -162],
      [-104, -175],
      [-116, -147],
      [-76, -114],
      [-75, -80],
      [-89, -87],
      [-142, -66],
      [-81, -25],
      [-101, 35],
      [-126, 77],
      [-73, 62],
      [-126, 132],
      [-85, 110],
      [-46, 109],
      [-73, 69],
      [-45, 96],
      [-106, 124],
      [-86, 49],
      [-41, 7],
      [-67, -5],
      [-40, 1],
    ],
    [
      [38180, 29161],
      [109, 93],
      [179, 282],
      [124, 588],
      [118, 256],
      [-63, 205],
      [29, 149],
      [-41, 401],
      [192, 241],
      [164, 167],
      [158, 208],
      [71, 236],
      [-58, 117],
      [-16, 219],
    ],
    [
      [39146, 32323],
      [-12, 162],
      [-91, 212],
      [-100, 42],
      [-29, 292],
      [165, 208],
      [363, 7],
      [145, 256],
      [3, 183],
      [123, 93],
      [101, 59],
      [95, 26],
      [184, 133],
      [190, 125],
      [177, 126],
      [-138, 206],
      [-93, 49],
      [-52, 171],
      [-94, 396],
      [-83, 259],
      [-64, 184],
      [-65, 205],
      [15, 88],
      [-17, 25],
    ],
    [
      [28558, 4418],
      [-12, -22],
      [-1, -34],
      [-9, -170],
      [-35, -80],
      [-14, -41],
      [-42, -81],
      [-34, -47],
      [-49, -101],
      [-21, -88],
      [-8, -27],
      [-9, -44],
    ],
    [
      [28324, 3683],
      [-30, 1],
      [13, -21],
      [-152, 179],
      [-199, 194],
      [-54, 18],
      [-146, 47],
      [-120, 83],
      [-133, 105],
      [-237, -85],
      [-117, -154],
      [-43, -190],
      [-174, -364],
      [-22, -26],
      [-40, -47],
      [-62, -108],
      [-23, -176],
      [-77, -162],
      [-48, -81],
      [-100, -125],
      [-44, -56],
      [-49, -99],
      [-14, -29],
      [-115, -66],
      [-156, -59],
      [-9, -4],
      [-167, -74],
      [-101, -12],
      [-27, 14],
      [-98, -35],
    ],
    [
      [25780, 2351],
      [-405, 2654],
    ],
    [
      [25375, 5005],
      [272, 160],
      [256, -4],
      [182, 44],
      [200, -105],
      [200, -99],
      [103, -266],
      [77, -70],
      [538, 69],
      [529, 0],
      [280, -253],
      [467, -32],
      [79, -31],
    ],
    [
      [23685, 4825],
      [-121, -38],
      [-197, -92],
      [-129, -59],
      [-128, -45],
      [-102, -39],
      [-148, -52],
      [-155, -4],
      [-121, 16],
      [-66, 90],
      [-85, 116],
      [-133, 111],
      [-111, 185],
      [-118, 165],
      [-76, 313],
      [129, 87],
      [14, 13],
      [42, 114],
      [82, 67],
      [102, 114],
      [116, 86],
      [204, 146],
      [156, 85],
      [264, 138],
      [69, 67],
      [230, 118],
      [237, 112],
      [230, 104],
      [184, 126],
      [244, 98],
      [111, 32],
    ],
    [
      [24409, 6999],
      [7, -183],
      [117, -254],
      [112, -131],
      [-125, -235],
      [-91, -229],
      [-117, -195],
      [-190, -146],
      [-264, -84],
      [-270, -70],
      [-176, -31],
      [-141, -18],
      [-141, 3],
      [-116, -93],
      [-69, -142],
      [-22, -129],
      [-51, -223],
      [335, -46],
      [296, 22],
      [182, 10],
    ],
    [
      [21243, 7396],
      [37, 50],
      [0, -13],
      [57, 182],
      [197, 146],
      [319, 198],
      [212, 227],
      [97, 162],
      [204, 180],
      [251, 158],
      [246, 213],
      [197, 112],
      [275, 197],
    ],
    [
      [23335, 9208],
      [-25, -99],
      [-89, -114],
      [-2, -95],
      [-6, 0],
      [-35, -54],
      [-16, -149],
      [73, -96],
      [105, -172],
      [119, -83],
      [283, 49],
      [174, -91],
      [25, -61],
      [-62, -101],
      [-47, -54],
      [-75, -66],
      [-109, -59],
      [-142, -39],
      [-114, -5],
      [-169, -17],
      [-175, -31],
      [-100, 22],
      [-162, -18],
      [-94, -11],
      [-156, -45],
      [-188, -45],
      [-169, -37],
      [-162, -52],
      [-211, -105],
      [-175, -65],
      [-210, -71],
      [-178, -48],
    ],
    [
      [11303, 8304],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [14623, 9933],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-25, 0],
      [0, -7],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [40, 0],
      [0, -9],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [17, 0],
    ],
    [
      [9352, 10088],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -7],
      [0, -10],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 4],
      [0, 4],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -5],
      [0, -4],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [56, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [64, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [40, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [81, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 57],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [56, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [33, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [113, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -90],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-80, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-106, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -148],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [25, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [25, 0],
      [0, 9],
      [32, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [41, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 106],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 41],
      [-16, 0],
      [0, 8],
      [8, 1],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, -1],
      [0, -32],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [130, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -131],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [9, 0],
      [0, -57],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-64, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-162, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-129, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 155],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-162, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 115],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 1],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [138, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -81],
      [9, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [113, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [64, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [48, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [57, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-202, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-105, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-64, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [-1, 8],
      [-7, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -44],
      [0, -22],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 67],
      [0, 31],
      [8, 0],
      [0, 6],
      [0, 10],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 13],
      [0, 4],
      [8, 0],
      [1, 31],
      [8, 1],
      [0, 33],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 58],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 90],
      [-8, 0],
      [0, 82],
      [-9, 0],
      [1, 16],
      [-3, 0],
      [-5, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 1],
      [0, 8],
      [-16, -1],
      [0, 33],
      [-33, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [9, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 65],
      [5, 0],
      [3, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 3],
      [0, 46],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 33],
      [-8, -1],
      [0, 25],
      [-4, 0],
      [-4, 0],
      [1, 41],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [24, 0],
      [0, 24],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 40],
      [9, 0],
      [0, 66],
      [-9, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 57],
      [-9, 0],
      [0, 61],
      [0, 29],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 4],
      [0, 29],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 131],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
    ],
    [
      [14509, 10448],
      [0, -8],
      [57, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-105, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-106, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-97, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-106, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-56, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [33, 0],
      [0, 7],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [89, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [89, 0],
    ],
    [
      [17634, 10505],
      [0, 3],
      [32, 1],
    ],
    [
      [17666, 10509],
      [0, -4],
      [-32, 0],
    ],
    [
      [14736, 10735],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [20751, 10317],
      [35, -133],
      [48, -367],
      [38, -537],
      [117, -260],
      [246, -208],
      [161, 11],
      [183, 71],
      [231, 180],
      [77, 175],
      [77, 182],
      [148, -10],
      [94, -1],
      [127, -16],
      [7, 7],
      [108, 32],
      [93, -56],
      [101, -36],
      [197, 132],
      [35, 115],
      [42, 95],
      [229, 23],
      [188, -3],
      [269, -12],
      [210, 112],
      [163, 65],
      [243, 112],
      [7, 6],
      [115, 39],
      [108, 39],
      [128, -2],
      [174, -44],
      [154, -50],
      [214, -72],
      [267, -93],
      [175, -16],
      [264, 111],
      [164, 180],
      [9, 143],
      [184, 139],
      [137, 154],
      [156, 79],
      [477, -2],
      [-78, -229],
      [23, -238],
      [-14, -75],
      [-55, -80],
      [-28, -20],
      [87, -83],
      [-45, -237],
      [-8, -129],
      [-42, -101],
      [-123, -106],
      [-276, -43],
      [-681, -90],
      [-699, 12],
      [-296, 18],
      [-417, -47],
      [-268, 66],
      [-174, 44],
      [-161, 29],
      [-303, -42],
      [-58, -42],
    ],
    [
      [21243, 7396],
      [-45, -64],
      [-32, -264],
      [172, -200],
      [220, -92],
      [466, 108],
      [460, 175],
      [439, 156],
      [332, 109],
      [170, 133],
      [385, 136],
      [218, 166],
      [271, 152],
      [170, 78],
      [86, -62],
      [-16, -150],
      [-30, -189],
      [-70, -216],
      [-37, -169],
      [7, -204],
    ],
    [
      [23685, 4825],
      [94, 5],
      [175, -36],
      [247, -66],
      [152, -145],
      [154, -43],
      [312, 137],
      [272, 179],
      [264, 138],
      [20, 11],
    ],
    [
      [25780, 2351],
      [-10, -4],
      [-232, -179],
      [-93, -72],
      [-36, -28],
      [-293, -192],
      [-216, -71],
      [-169, -11],
      [-248, 59],
      [-125, 131],
      [-444, 76],
      [-249, -57],
      [-170, -113],
      [-232, -186],
      [-177, -140],
      [-147, -42],
      [-212, -60],
      [-330, -35],
      [-186, -99],
      [-38, -20],
      [-292, -199],
      [-158, -201],
      [-89, -100],
      [-323, -60],
      [-48, -9],
      [-320, -226],
      [-191, -152],
      [-258, -139],
      [-242, -122],
      [-111, -55],
      [-260, 140],
      [-131, 27],
      [-50, 10],
      [-291, -117],
      [-224, -105],
      [-276, 5],
      [-302, 25],
      [-292, 250],
      [-177, 275],
      [-402, 109],
      [-327, 230],
      [-266, 168],
      [-272, 256],
      [-318, 88],
      [-23, 6],
      [-257, -29],
      [-327, 182],
      [-509, 90],
      [-219, 201],
      [-454, 212],
      [-500, 301],
      [-194, 78],
      [-306, 263],
      [-81, 88],
      [-94, 101],
      [-69, 74],
      [-204, 282],
      [-77, 64],
      [-157, 131],
      [19, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, 7],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [73, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 197],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 106],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -50],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [64, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [64, 0],
      [0, -8],
      [122, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [57, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [97, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-40, 0],
      [0, 42],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-170, 0],
      [0, 8],
      [-194, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-114, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [56, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 1],
      [0, 8],
      [8, 0],
      [0, 16],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 106],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [122, 0],
      [0, 9],
      [48, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [89, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [81, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 82],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 139],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [81, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, -16],
      [8, 0],
      [0, -123],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -57],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -57],
      [9, 0],
      [0, -58],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-106, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -98],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 90],
      [-9, 0],
      [0, 74],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 73],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -82],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [154, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [187, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [56, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 131],
      [16, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [121, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [-154, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -147],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-203, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [32, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [33, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [56, 0],
      [0, -16],
      [25, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [25, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, -17],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [89, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -131],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [106, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [41, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [7, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -41],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [130, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 91],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-7, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-17, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 131],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [57, 0],
      [0, -17],
      [24, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -107],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [73, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [97, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [15610, 10784],
      [0, -8],
      [41, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -20],
      [0, -13],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-89, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-32, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-72, 0],
      [0, 8],
      [-130, 0],
      [0, 9],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 42],
      [8, 0],
      [0, 24],
      [73, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [90, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 16],
      [73, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [57, 0],
      [0, 3],
      [0, 6],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [48, 0],
    ],
    [
      [14841, 10997],
      [0, -41],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [72, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [24, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -6],
      [-49, -2],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [56, 0],
      [0, -8],
      [65, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [0, 9],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 12],
      [0, 5],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [-8, 0],
    ],
    [
      [14744, 11038],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [49, 0],
    ],
    [
      [20136, 11529],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -41],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [15, 0],
      [3, -7],
      [6, -2],
      [0, -5],
      [0, -11],
      [9, 1],
      [-1, -17],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [26, 2],
      [-2, -10],
      [17, 0],
      [0, -8],
      [11, 0],
      [37, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
    ],
    [
      [20613, 10784],
      [8, -4],
    ],
    [
      [20621, 10780],
      [0, -4],
      [9, 0],
      [-1, -9],
      [12, 0],
    ],
    [
      [20641, 10767],
      [2, -8],
      [3, 0],
    ],
    [
      [20646, 10759],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-138, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-7, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 32],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-40, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 12],
      [0, 4],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 1],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [33, -1],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [121, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [65, 0],
      [0, 7],
      [8, 1],
      [0, 8],
      [16, 0],
    ],
    [
      [17772, 10514],
      [7, 0],
      [761, 82],
      [582, 201],
      [260, 301],
      [130, 167],
      [266, 219],
      [283, 50],
      [75, -5],
    ],
    [
      [12461, 11117],
      [0, -6],
      [9, 2],
      [-1, -10],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-105, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-146, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-48, 0],
      [0, 5],
      [0, 3],
      [-73, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-72, 0],
      [0, -4],
      [0, -4],
      [-41, 0],
      [0, 3],
      [0, 5],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -6],
      [0, -2],
      [-41, 0],
      [0, -9],
      [-48, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
    ],
    [
      [6559, 12651],
      [102, 63],
      [3, -2],
      [322, 146],
      [384, 81],
      [329, -39],
      [438, 81],
      [506, 93],
      [246, -147],
      [313, -223],
      [309, -5],
      [218, -268],
      [359, -237],
      [533, -281],
      [279, -222],
      [1057, -344],
      [504, -230],
    ],
    [
      [25629, 22540],
      [-67, -20],
      [-445, -94],
      [-164, -127],
      [-227, 72],
      [-329, 26],
      [-346, -164],
      [-172, -275],
      [-209, 3],
      [-253, 147],
      [-299, 223],
      [-49, 272],
      [-144, 247],
      [-273, 174],
      [-216, -16],
      [-175, 128],
    ],
    [
      [22261, 23136],
      [-44, 31],
      [74, 393],
      [-3, 217],
      [-24, 232],
      [-43, 231],
      [-303, -56],
      [-82, -32],
      [-228, 17],
      [-90, 260],
      [-127, 36],
      [-203, -65],
      [-130, -133],
      [-167, 44],
      [-166, 111],
      [-25, 163],
      [-83, 280],
      [9, 81],
      [-95, 9],
      [45, 250],
      [43, 176],
      [-59, 76],
      [-86, 90],
      [-181, 43],
      [-222, -3],
      [-207, 99],
      [-220, 85],
      [-214, 119],
      [-285, 209],
      [-158, 220],
      [-153, 118],
      [-105, 124],
      [-167, 64],
      [-58, 198],
      [-85, 123],
      [-140, 98],
      [-135, -39],
      [-196, -71],
      [-164, -153],
      [-103, -121],
      [-71, -229],
      [-139, -283],
      [-42, -108],
      [-182, -45],
      [-140, 77],
      [-126, 78],
      [-193, 125],
      [-167, 98],
      [-158, 206],
      [10, 170],
      [-7, 0],
      [-66, 76],
      [-119, 151],
      [-126, 131],
      [-188, 3],
      [-106, 84],
      [-50, 34],
    ],
    [
      [15811, 27228],
      [25, 82],
      [69, 121],
      [83, 120],
      [49, 129],
      [21, 67],
      [23, 142],
      [-85, 185],
      [78, 209],
      [-85, 199],
      [-11, 135],
      [63, 169],
      [91, 216],
      [-25, 115],
      [20, 79],
    ],
    [
      [16127, 29196],
      [175, 171],
      [669, 192],
      [339, 178],
      [555, 161],
      [366, 190],
      [278, 91],
      [202, 71],
      [235, -86],
      [279, -208],
      [257, -345],
      [247, -92],
      [529, -179],
      [703, -320],
      [12, -5],
      [374, -197],
      [613, -364],
      [560, -295],
      [653, -392],
      [423, -504],
      [336, -481],
      [182, -364],
      [386, -265],
      [74, -42],
      [652, -453],
      [728, -359],
      [222, -398],
      [-204, -588],
      [-375, -707],
      [-226, -234],
      [-154, -392],
      [363, -393],
      [49, -47],
    ],
    [
      [28721, 21491],
      [83, -154],
      [98, -178],
      [32, -55],
      [40, -55],
      [60, -49],
      [46, -48],
      [47, -14],
      [71, -130],
      [39, -110],
      [72, -96],
      [81, -15],
      [26, -34],
      [73, -76],
      [46, -55],
      [33, -48],
      [92, -145],
      [98, -178],
      [52, -103],
      [58, -136],
      [52, -130],
      [51, -191],
      [57, -178],
      [44, -204],
      [-14, -47],
      [79, -97],
      [74, -49],
      [66, -48],
      [119, -118],
      [26, -48],
      [45, -157],
      [51, -170],
      [32, -89],
      [64, -171],
      [25, -156],
      [19, -48],
      [-29, -122],
      [0, -7],
      [-28, -101],
      [-69, -74],
      [0, -6],
      [-69, -155],
      [-55, -67],
      [-35, -74],
      [-136, -86],
      [-21, -47],
      [-150, -113],
      [-81, -53],
      [-136, -113],
      [-34, -7],
      [-156, -58],
      [-149, -79],
      [-82, -53],
      [-149, -113],
      [-163, -71],
      [-40, -27],
      [-184, -106],
      [-47, -32],
      [-109, -94],
      [-123, -79],
      [-88, -46],
      [-122, -80],
      [-41, -12],
      [-189, -72],
      [-27, -20],
      [-121, -11],
      [-21, 0],
      [-80, -5],
      [-101, 8],
      [-60, 28],
      [-120, 57],
      [-94, 35],
      [-40, 21],
      [-59, 49],
      [-54, 21],
      [-74, 8],
      [-107, 43],
      [-73, 15],
      [-14, 7],
      [-39, 68],
      [-19, 48],
      [0, 7],
      [-7, 14],
      [-6, 27],
      [-46, 55],
      [-40, 48],
      [-66, 76],
      [-26, 7],
      [-33, 41],
      [-80, 70],
      [-46, 55],
      [-46, 109],
      [-39, 62],
      [-98, 144],
      [-7, 7],
      [-86, 117],
      [-26, 21],
      [-13, 0],
      [-34, 21],
      [-92, 37],
    ],
    [
      [26279, 17254],
      [54, 140],
      [57, 176],
      [56, 141],
      [-18, 150],
      [-10, 183],
      [-58, 144],
      [-92, 131],
      [-165, 233],
      [-172, 160],
      [-253, 140],
      [-154, 36],
      [-252, 235],
      [-147, 30],
      [-193, 126],
      [-226, 207],
      [-98, 144],
      [-40, 21],
      [-86, 83],
      [-97, 260],
      [138, 228],
      [43, 122],
      [63, 189],
      [124, 147],
      [129, 106],
      [76, 148],
      [185, 167],
      [109, 114],
      [149, 99],
      [164, 126],
      [-13, 68],
      [21, 54],
      [15, 95],
      [2, 88],
      [1, 61],
      [54, 27],
      [102, 79],
      [97, 46],
    ],
    [
      [25844, 21958],
      [-88, -177],
      [-114, -426],
      [286, -182],
      [719, -87],
      [581, 147],
      [897, 120],
      [596, 138],
    ],
    [
      [33552, 21126],
      [-66, -18],
      [-108, -26],
      [-182, -10],
      [-121, -12],
      [-195, -10],
      [-155, -18],
      [-114, -11],
      [-108, -5],
      [-202, -10],
      [-168, -11],
      [-128, 22],
      [-86, 29],
      [-215, 38],
      [-60, 41],
      [-80, 29],
      [-101, 22],
      [-60, 28],
      [-33, 8],
      [-107, 49],
      [-40, 8],
      [-61, -20],
      [-47, -13],
      [-74, -19],
      [-95, 2],
      [-61, -19],
      [-73, 8],
      [-54, 7],
      [-87, 9],
      [-100, 49],
      [-113, 124],
      [-33, 35],
      [-145, 165],
      [-53, 55],
      [-99, 104],
      [-92, 144],
      [-9, 12],
    ],
    [
      [30027, 21912],
      [583, 418],
      [360, 245],
      [300, 240],
      [276, 430],
      [311, 484],
      [300, 294],
      [78, 103],
    ],
    [
      [32235, 24126],
      [15, -30],
      [32, -109],
      [78, -165],
      [-8, -81],
      [176, -343],
      [84, -164],
      [44, -184],
      [85, -178],
      [45, -130],
      [-8, -95],
      [137, -253],
      [50, -205],
      [164, -274],
      [46, -76],
      [163, -295],
      [7, -13],
      [196, -356],
      [11, -49],
    ],
    [
      [39146, 32323],
      [-62, -14],
      [-122, -52],
      [-128, -66],
      [-82, -32],
      [-128, -46],
      [-176, -38],
      [-108, -32],
      [-135, -72],
      [-135, -11],
      [-121, -32],
      [-122, -32],
      [-242, -16],
      [-182, 3],
      [-162, -52],
      [-115, -38],
      [-209, -65],
      [-68, -46],
      [-158, -174],
      [-136, -100],
      [-156, -112],
      [-195, -4],
      [-100, 43],
      [-174, 50],
      [-153, 112],
      [-198, 267],
      [-118, 172],
      [-99, 111],
      [-118, 158],
      [-100, 117],
      [-131, 172],
      [-72, 144],
      [-91, 185],
      [-93, 89],
      [-92, 117],
      [-99, 104],
      [-86, 117],
      [-105, 165],
      [-53, 48],
      [-151, 199],
      [-61, -5],
      [-108, -46],
      [-81, -46],
      [-127, 29],
      [-102, -25],
      [-95, -39],
      [-74, -40],
      [-114, 50],
      [-54, -13],
      [-53, 1],
      [-93, 83],
      [-26, 48],
      [-19, 96],
      [35, 94],
      [22, 88],
      [34, 60],
      [28, 47],
      [48, 54],
      [34, 67],
      [55, 47],
      [75, 60],
      [75, 73],
      [41, 54],
      [62, 80],
      [48, 81],
      [41, 47],
      [55, 73],
      [49, 74],
      [55, 94],
      [27, 34],
      [48, 19],
      [68, 40],
      [47, 40],
      [42, 53],
      [34, 47],
      [27, 41],
      [28, 60],
      [22, 67],
      [56, 135],
      [55, 122],
      [35, 81],
      [49, 100],
      [41, 74],
      [7, 7],
      [60, -28],
      [27, -21],
      [101, -8],
      [54, 26],
      [161, -16],
      [87, -2],
      [129, 39],
      [88, 46],
      [55, 67],
      [128, 38],
      [115, 39],
      [88, 39],
      [47, 20],
      [101, 5],
      [73, -49],
      [53, -89],
      [31, -143],
      [91, -199],
      [71, -205],
      [25, -102],
      [45, -109],
      [32, -123],
      [74, 6],
      [80, -22],
      [55, 73],
      [76, 88],
      [103, 140],
      [29, 169],
      [42, 88],
      [76, 141],
      [70, 176],
      [42, 94],
      [82, 107],
      [69, 53],
      [81, 40],
      [75, 87],
      [76, 134],
      [42, 94],
      [-12, 102],
      [-78, 145],
      [-133, 110],
      [-119, 97],
      [-172, 207],
      [-174, 57],
      [-94, -18],
      [-99, 89],
      [-107, 91],
      [-106, 42],
      [-86, 124],
      [-123, 260],
      [-85, 178],
      [-37, 211],
      [-63, 158],
    ],
    [
      [35753, 38314],
      [38, 64],
      [110, 174],
      [212, 221],
      [194, 145],
      [242, 181],
      [329, 156],
      [57, 27],
      [793, 313],
      [474, 203],
      [922, 439],
      [250, 43],
      [137, 64],
    ],
    [
      [38180, 29161],
      [-95, -67],
      [-143, -99],
      [-149, -59],
      [-72, 124],
      [-105, 151],
      [-154, 70],
      [-283, -42],
      [-143, -133],
      [-350, -28],
      [-382, 60],
      [-376, 27],
      [-373, -132],
    ],
    [
      [35555, 29033],
      [-70, 289],
      [-447, 252],
      [-296, 6],
      [-308, 127],
      [-283, 358],
      [-252, 229],
      [-348, 108],
      [-270, 365],
      [-304, 338],
      [-350, 448],
      [-268, 474],
      [-302, 501],
      [-385, 734],
      [-70, 654],
      [28, 489],
      [-45, 545],
      [-166, 607],
      [-327, 169],
      [-360, 217],
      [-189, 357],
      [-7, 449],
      [79, 678],
      [113, 345],
      [161, 384],
      [145, 236],
      [425, 488],
      [414, 265],
      [263, 43],
      [522, -192],
      [475, -199],
      [361, -584],
      [335, -515],
      [295, -481],
      [224, -296],
      [303, 22],
      [254, 294],
      [261, 356],
      [403, 408],
      [184, 313],
    ],
    [
      [36879, 12977],
      [127, -29],
      [120, -63],
      [26, -55],
      [153, -98],
      [72, -123],
      [39, -55],
      [26, -69],
      [53, -55],
      [80, -49],
      [40, -61],
      [113, -84],
      [18, -109],
      [5, -81],
      [-15, -95],
      [-54, -40],
      [-35, -67],
      [-8, -75],
      [-48, -46],
      [-74, -40],
      [-48, -40],
      [-41, -33],
      [-41, -40],
      [-54, -33],
      [-54, -13],
      [-27, -26],
      [-14, -14],
      [-47, -33],
      [-53, -47],
      [-83, 9],
      [-80, 42],
      [-153, 84],
      [-199, 159],
      [9, 116],
      [-40, 21],
      [-273, 174],
      [-19, 123],
      [-25, 88],
      [-52, 90],
      [-32, 136],
      [-38, 143],
      [56, 114],
      [55, 61],
      [80, 19],
      [156, 72],
      [75, 53],
      [122, 45],
      [94, -2],
      [88, 26],
    ],
    [
      [39515, 12192],
      [-80, 22],
      [-47, 1],
      [-94, 28],
      [-179, 173],
      [-6, 7],
      [-40, 35],
      [-78, 150],
      [14, 82],
      [-17, 183],
      [-19, 89],
      [-58, 89],
      [-92, 158],
      [-61, -6],
      [-101, 2],
      [-87, 15],
      [-93, 49],
      [-73, 62],
      [1, 41],
      [-66, 103],
      [-72, 117],
      [36, 169],
      [21, 67],
      [9, 143],
      [2, 81],
      [36, 149],
      [21, 47],
      [7, 27],
      [7, 13],
      [55, 115],
      [49, 80],
      [33, -20],
      [108, 45],
      [61, 13],
      [28, 40],
      [35, 149],
      [-144, 199],
      [-79, 144],
      [-47, -26],
      [-87, 42],
      [-133, 63],
      [-114, 36],
      [-94, -12],
      [-153, 105],
      [-34, -6],
      [-119, 117],
      [-101, 15],
      [-60, 15],
      [-81, -5],
      [-33, -7],
      [-69, -66],
      [-68, -60],
      [-123, -100],
      [-75, -74],
      [-102, -93],
      [-75, -87],
      [-42, -53],
      [-68, -60],
      [-81, -53],
      [-68, -53],
      [-96, -87],
      [-68, -60],
      [-61, -26],
      [-47, -19],
      [-190, -99],
      [-54, -13],
      [-129, -72],
      [-122, -66],
      [-81, 15],
      [-39, 28],
      [-114, 56],
      [-133, 84],
      [-40, 41],
      [-158, 173],
      [-40, 61],
      [-53, 1],
      [-74, 35],
      [-46, 29],
      [-93, 96],
      [-20, 34],
      [-111, 158],
      [-72, 110],
      [-47, 62],
      [-46, 28],
      [-127, 97],
      [-34, -40],
      [-34, -47],
      [-42, -108],
      [-8, -54],
      [-21, -81],
      [-42, -115],
      [-42, -67],
      [-69, -134],
      [-42, -108],
      [-56, -122],
      [-63, -134],
      [-122, -114],
      [-21, -6],
      [-108, -53],
      [-41, -46],
      [-42, -81],
      [-61, -74],
      [-116, -106],
      [-123, -107],
      [-89, -114],
      [-90, -147],
      [-35, -102],
      [-49, -80],
      [-83, -148],
      [-75, -101],
      [-159, -255],
      [-77, -182],
      [-34, -47],
      [-110, -154],
      [-42, -74],
      [-89, -107],
      [-34, -27],
      [-61, -40],
      [-82, -59],
      [-47, -40],
      [-41, -40],
      [-63, -135],
      [-27, -6],
      [-34, -7],
      [-67, 2],
      [-41, -20],
      [-54, -33],
      [-34, -13],
      [-48, -40],
      [-68, -74],
      [-48, -46],
      [-41, -68],
      [-145, -194],
      [0, -20],
      [-9, -136],
      [-35, -60],
      [-70, -189],
      [-41, -74],
      [-20, -30],
    ],
    [
      [31535, 10900],
      [-7, 17],
      [-13, 68],
      [-46, 68],
      [-53, 15],
      [-51, 198],
      [-4, 156],
      [-78, 144],
      [-113, 131],
      [-92, 76],
      [-58, 184],
      [-92, 145],
      [-113, 103],
      [-113, 43],
      [-178, 207],
      [-113, 83],
      [-3, 245],
      [-6, 54],
      [37, 244],
      [42, 67],
      [124, 188],
      [69, 87],
      [71, 243],
      [77, 175],
      [82, 142],
      [153, 268],
      [62, 108],
      [76, 107],
      [118, 222],
      [48, 67],
      [183, 106],
      [83, 148],
      [14, 34],
      [276, 15],
      [181, -10],
      [136, 80],
      [95, 79],
      [10, 163],
      [8, 102],
      [-24, 156],
      [-5, 116],
      [15, 115],
      [43, 162],
      [-32, 109],
      [-38, 150],
      [51, 244],
      [21, 47],
      [142, 66],
      [136, 58],
      [81, 26],
      [176, 99],
      [102, 46],
      [209, 30],
      [254, -52],
      [207, -92],
      [154, -57],
      [170, 133],
      [109, 114],
      [42, 67],
      [88, 12],
      [74, 19],
      [178, 208],
      [225, 186],
      [82, 121],
      [21, 6],
      [201, -10],
      [37, 8],
    ],
    [
      [35066, 17559],
      [24, 4],
      [0, 7],
      [40, -14],
      [101, -15],
      [147, -23],
      [54, -15],
      [134, 12],
      [257, 29],
      [87, 12],
      [161, 11],
      [142, 25],
      [128, 11],
      [195, 31],
      [135, 18],
      [203, 57],
      [162, 59],
      [128, -16],
      [181, 24],
      [270, 36],
      [128, -2],
      [215, -17],
      [107, 11],
      [0, 7],
      [143, 66],
      [27, -8],
      [13, 20],
      [116, 87],
    ],
    [
      [38364, 17976],
      [113, -50],
      [127, -77],
      [120, -49],
      [120, -104],
      [45, -109],
      [51, -144],
      [39, -89],
      [189, -404],
      [97, -191],
      [150, -308],
      [58, -158],
      [64, -177],
      [98, -212],
      [111, -192],
      [33, -48],
      [65, -164],
      [102, -301],
      [12, -143],
      [56, -286],
      [97, -239],
      [4, -116],
      [4, -197],
      [30, -251],
      [24, -157],
      [37, -191],
      [7, 0],
      [27, -7],
      [67, -15],
      [47, 13],
      [41, 20],
      [78, -144],
      [-2, -136],
      [-104, -202],
      [-62, -80],
      [-115, -73],
      [-41, 1],
      [110, -280],
      [194, -31],
      [175, 4],
      [108, 32],
      [136, 45],
      [108, 53],
      [128, 31],
      [135, -2],
      [288, -46],
      [155, -2],
      [181, -17],
      [207, -65],
      [134, -70],
      [86, -76],
      [-10, -169],
      [-49, -129],
      [-118, -235],
      [-124, -208],
      [-184, -133],
      [-167, 37],
      [-276, 25],
      [-233, 126],
      [-239, 160],
      [-140, 98],
      [-403, 34],
      [-215, -17],
      [-256, -9],
      [-147, 29],
      [-222, 11],
    ],
    [
      [39515, 12192],
      [-148, -45],
      [-177, -99],
      [-183, -112],
      [-96, -87],
      [-103, -147],
      [-131, -202],
      [-139, -282],
      [-49, -108],
      [-77, -203],
      [-64, -256],
      [-43, -129],
      [3, -265],
      [11, -136],
      [31, -149],
      [18, -123],
      [0, -13],
      [19, -76],
      [12, -74],
      [-9, -156],
      [12, -116],
      [-16, -156],
      [-10, -169],
      [-23, -224],
      [-22, -95],
      [-57, -216],
      [-49, -94],
      [-136, -113],
      [-34, -34],
      [-115, -25],
      [-20, 7],
      [-87, 15],
      [-135, -38],
      [-68, -19],
      [-149, -72],
      [-109, -67],
      [-122, -99],
      [-75, -87],
      [-90, -155],
      [-43, -162],
      [52, -96],
      [9, -65],
    ],
    [
      [37103, 7450],
      [-59, 47],
      [-25, -55],
      [-28, -75],
      [-56, -114],
      [-21, -81],
      [-36, -149],
      [-84, -189],
      [-35, -73],
      [-55, -115],
      [-28, -61],
      [-42, -87],
      [-63, -128],
      [-49, -135],
      [-62, -87],
      [-68, -74],
      [-88, -25],
      [-121, 29],
      [-120, 22],
      [-114, 36],
      [-172, 152],
      [-154, 51],
      [-94, 28],
      [-100, 43],
      [-81, -6],
      [-87, -12],
      [-88, -12],
      [-174, 37],
      [-161, 37],
      [-94, -33],
      [-151, -146],
      [-48, -81],
      [-224, -98],
      [-123, -140],
      [-95, -46],
      [-49, -135],
      [-7, 7],
      [-59, 68],
      [-69, 109],
    ],
    [
      [33919, 5959],
      [-10, 15],
      [-26, 41],
      [-26, 35],
      [-67, 55],
      [7, 7],
      [-26, 27],
      [-106, 104],
      [-13, 28],
      [-40, 27],
      [-119, 111],
      [-53, 69],
      [-60, 62],
      [-72, 69],
      [-107, 77],
      [-33, 14],
      [-120, 90],
      [-66, 49],
      [-80, 62],
      [-106, 70],
      [-53, 55],
      [-40, 55],
      [-92, 97],
      [-66, 76],
      [-87, 62],
      [-66, 69],
      [-132, 131],
      [-51, 198],
      [-86, 90],
      [-106, 42],
      [-92, 158],
      [-78, 178],
      [-25, 130],
      [-71, 150],
      [-50, 238],
      [4, 272],
      [-2, 285],
      [17, 217],
      [-43, 245],
      [-38, 170],
      [-10, 191],
      [-18, 143],
      [-31, 184],
      [51, 223],
      [-11, 136],
      [-67, 76],
      [1, 13],
      [-19, 45],
    ],
    [
      [50163, 28247],
      [-89, -108],
      [-129, -521],
      [-296, -443],
      [-169, -446],
      [-234, -336],
      [-114, -358],
      [-60, -434],
      [-177, -514],
      [-251, -553],
      [-228, -377],
      [54, -395],
      [-99, -352],
      [-116, -507],
      [-310, -450],
      [-242, -431],
      [-252, -594],
      [-101, -386],
      [-260, -274],
      [-162, -79],
      [-48, -111],
      [19, -13],
      [-11, -9],
      [-13, -72],
      [-32, 9],
      [-76, -175],
      [142, -349],
      [84, -280],
      [102, -342],
      [96, -301],
      [88, -416],
      [-61, -406],
      [-181, -371],
      [-95, -474],
      [-51, -265],
      [-155, -405],
      [-262, -430],
      [-161, -90],
      [-29, -16],
      [-199, -178],
      [-211, -188],
      [-286, -160],
      [-80, -45],
      [-24, -41],
      [-142, -248],
      [-162, -419],
      [-65, -278],
      [-27, -427],
      [50, -259],
      [55, -355],
      [-5, -279],
      [44, -204],
      [-20, -408],
      [-119, -317],
      [-224, -98],
      [-293, -213],
      [-310, -42],
      [-400, -170],
      [-163, -92],
      [-266, -247],
      [-90, -155],
      [-13, -13],
      [4, -40],
      [33, -107],
      [33, -59],
      [86, -130],
      [92, -158],
      [26, -44],
      [8, -11],
      [51, -82],
      [91, -192],
      [58, -137],
      [49, -145],
      [48, -142],
      [16, -232],
      [44, -238],
      [-225, -133],
      [-34, -19],
      [-198, -174],
      [-108, -80],
      [-198, -118],
      [-244, -125],
      [-208, -125],
      [-23, -14],
      [-345, -157],
      [-278, -118],
      [-122, -59],
      [-248, -60],
      [-117, -29],
      [-351, -103],
      [-258, -138],
      [-283, -2],
      [-214, 85],
      [-363, 0],
      [-115, -18],
      [-115, -19],
      [-187, 51],
      [-241, 72],
      [-127, 43],
      [-243, -36],
      [-231, -105],
      [-210, -99],
      [-343, 33],
      [-262, -29],
      [-98, -202],
      [-69, -115],
      [-29, -115],
      [-139, -296],
      [-76, -101],
      [-70, -160],
    ],
    [
      [37601, 7155],
      [-95, 47],
      [-127, 91],
      [-106, 56],
      [-40, 69],
      [-47, -34],
      [-83, 66],
    ],
    [
      [38364, 17976],
      [41, 33],
      [82, 114],
      [76, 80],
      [74, 46],
      [95, 40],
      [101, 32],
      [108, 32],
      [74, -15],
      [87, -62],
      [60, -22],
      [100, -9],
      [175, -30],
      [47, -14],
      [357, 89],
      [76, 114],
      [130, 113],
      [137, 208],
      [90, 141],
      [182, 52],
      [88, 18],
      [195, 17],
      [121, -29],
      [207, -71],
      [214, -65],
      [162, 24],
      [149, 66],
      [156, 92],
      [117, 154],
      [103, 161],
      [83, 135],
      [156, 85],
      [101, 33],
      [123, 99],
      [96, 114],
      [63, 176],
      [-11, 108],
      [-5, 116],
      [0, 20],
      [-59, 49],
      [-47, 35],
      [-19, 88],
      [-24, 136],
      [-113, 117],
      [-87, 9],
      [-161, 16],
      [-66, 76],
      [-100, 90],
      [-100, 8],
      [-54, 42],
      [-99, 70],
      [-45, 184],
      [37, 149],
      [22, 155],
      [-39, 110],
      [65, 236],
      [29, 156],
      [29, 94],
      [21, 95],
      [-17, 156],
      [35, 122],
      [56, 101],
      [-31, 170],
      [-33, 62],
      [70, 148],
      [49, 155],
      [56, 122],
      [117, 167],
      [213, 275],
      [144, 181],
      [118, 215],
      [131, 222],
      [97, 161],
      [76, 155],
      [36, 169],
      [36, 149],
      [-57, 191],
      [-86, 96],
      [-73, 83],
      [-147, 57],
      [-79, 42],
      [-127, 50],
      [-168, 50],
      [-153, 78],
      [-167, 77],
      [16, 129],
      [76, 155],
      [77, 175],
      [14, 81],
      [118, 202],
      [103, 141],
      [103, 127],
      [83, 182],
      [78, 216],
      [51, 270],
      [42, 101],
      [91, 196],
      [76, 114],
      [76, 168],
      [138, 208],
      [91, 223],
      [67, 232],
    ],
    [
      [45522, 28505],
      [130, -9],
      [242, -11],
      [215, 10],
      [209, 10],
      [107, -22],
      [87, -42],
      [275, 9],
      [21, 6],
      [108, 32],
      [48, 40],
      [55, 67],
      [41, 60],
      [28, 47],
      [-34, 1],
      [115, 59],
      [48, 74],
      [29, 60],
      [34, 68],
      [41, 74],
      [56, 87],
      [48, 54],
      [40, 26],
      [33, -75],
      [138, -159],
      [39, -75],
      [46, -55],
      [134, -70],
      [208, -10],
      [208, -11],
      [176, 38],
      [269, 2],
      [182, 38],
      [249, 22],
      [147, -56],
      [94, -15],
      [140, -98],
      [133, -84],
      [106, -96],
      [93, -63],
      [133, -91],
      [166, -98],
      [4, -2],
    ],
    [
      [35066, 17559],
      [-190, 103],
      [-39, 89],
      [-98, 158],
      [-53, 69],
      [-106, 62],
      [-47, 35],
      [-33, 28],
      [-86, 76],
      [-33, 48],
      [-113, 90],
      [-52, 69],
      [-53, 96],
      [-38, 116],
      [-31, 164],
      [-151, 247],
      [21, 54],
      [56, 121],
      [9, 176],
      [-77, 232],
      [10, 190],
      [-57, 212],
      [28, 81],
      [2, 81],
      [-130, 301],
      [-84, 239],
      [-7, 18],
    ],
    [
      [33714, 20714],
      [28, 23],
      [75, 59],
      [28, 27],
      [74, 33],
      [54, 40],
      [42, 60],
      [61, 53],
      [68, 46],
      [55, 68],
      [96, 107],
      [27, 33],
      [41, 47],
      [14, 13],
      [34, 47],
      [41, 40],
      [34, 40],
      [28, 34],
      [14, 13],
      [7, 75],
      [21, 40],
      [15, 61],
      [14, 74],
      [-6, 34],
      [-6, 34],
      [0, 21],
      [-26, 21],
      [-13, 54],
      [-11, 123],
      [-66, 89],
      [-12, 68],
      [-6, 48],
      [13, 27],
      [14, 20],
      [48, 26],
      [61, 13],
      [66, -42],
      [26, -28],
      [79, -117],
      [67, -62],
      [113, -63],
      [40, -21],
      [73, -42],
      [73, -42],
      [114, -36],
      [102, 46],
      [108, 25],
      [135, 73],
      [68, 12],
      [81, 26],
      [114, 4],
      [115, 19],
      [128, 45],
      [75, 53],
      [68, 26],
      [101, 33],
      [121, 4],
      [47, -21],
      [66, -42],
      [86, -103],
      [53, -35],
      [33, -28],
      [40, -34],
      [93, -56],
      [108, -36],
      [93, -35],
      [107, -30],
      [101, -28],
      [47, -1],
      [81, 53],
      [102, 39],
      [61, 26],
      [88, 66],
      [55, 60],
      [41, 61],
      [95, 80],
      [177, 105],
      [27, 27],
      [75, 26],
      [128, 66],
      [88, 18],
      [81, 12],
      [101, 19],
      [74, 12],
      [129, 59],
      [108, 39],
      [74, 26],
      [89, 33],
      [115, 59],
      [74, 19],
      [74, 26],
      [47, 13],
      [75, 25],
      [68, 53],
      [34, 27],
      [28, 68],
      [21, 53],
      [15, 95],
      [15, 75],
      [-19, 95],
      [9, 122],
      [-26, 75],
      [8, 95],
      [1, 54],
      [-4, 177],
      [2, 122],
      [2, 95],
      [-52, 103],
      [-39, 116],
      [0, 7],
      [-84, 185],
      [-53, 75],
      [-19, 28],
      [-40, 48],
      [-46, 68],
      [-53, 69],
      [-26, 35],
      [-73, 82],
      [-33, 28],
      [-52, 103],
      [-104, 226],
      [-32, 75],
      [-40, 68],
      [-164, 227],
      [-66, 97],
      [-105, 157],
      [-92, 131],
      [-59, 103],
      [-59, 55],
      [-133, 98],
      [-139, 158],
      [-99, 131],
      [-131, 172],
      [-12, 108],
      [-154, 51],
      [-100, 76],
      [-92, 104],
      [-86, 103],
      [-232, 187],
      [-172, 193],
      [-227, 86],
      [-141, -12],
      [-215, 58],
      [-228, 45],
      [-214, 31],
      [-220, 106],
      [-186, 77],
    ],
    [
      [35457, 27822],
      [69, 249],
      [94, 419],
      [-40, 443],
      [-25, 100],
    ],
    [
      [25629, 22540],
      [343, -327],
      [-128, -255],
    ],
    [
      [26279, 17254],
      [-15, 6],
      [-13, 7],
      [-101, 28],
      [-53, 1],
      [-61, -33],
      [-82, -59],
      [-129, -100],
      [-129, -79],
      [-155, -31],
      [-136, -39],
      [-134, -25],
      [-108, 2],
      [-61, -26],
      [-67, 1],
      [-94, 22],
      [-67, -12],
      [-35, -40],
      [-73, -65],
    ],
    [
      [24766, 16812],
      [-55, 6],
      [-109, -53],
      [-88, -73],
      [-35, -74],
      [-109, -100],
      [-21, -27],
      [-27, -26],
      [-237, -85],
      [-167, 44],
      [-80, 62],
      [-321, 121],
      [-71, 171],
      [-86, 83],
      [-238, 256],
      [-263, 364],
      [-13, 41],
      [-167, 71],
      [-287, 127],
      [-285, -158],
      [-380, -231],
      [-487, -230],
      [-252, -158],
      [-109, -121],
      [-178, -180],
      [-130, -120],
      [-339, -220],
    ],
    [
      [20232, 16302],
      [-14, 9],
      [-13, 34],
      [-158, 145],
      [-167, 78],
      [-160, 104],
      [-167, 71],
      [-164, 268],
      [-137, 246],
      [-138, 207],
      [-92, 103],
      [-60, 182],
    ],
    [
      [18962, 17749],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 28],
    ],
    [
      [19221, 18129],
      [39, 38],
      [157, 174],
      [143, 79],
      [133, -43],
      [100, -77],
      [112, -110],
      [169, 11],
      [115, 66],
      [55, 67],
      [-31, 170],
      [-233, 126],
      [-240, 99],
      [-298, 311],
      [-212, 187],
      [-324, 351],
      [-372, 292],
      [-6, 9],
    ],
    [
      [18528, 19879],
      [203, 124],
      [94, 18],
      [190, 38],
      [230, 111],
      [242, 23],
      [182, 17],
      [277, 98],
      [346, 197],
      [360, 211],
      [190, 126],
      [83, 128],
      [158, 221],
      [97, 154],
      [159, 276],
      [63, 196],
      [43, 162],
      [105, 243],
      [144, 181],
      [220, 274],
      [35, 108],
      [137, 174],
      [175, 177],
    ],
    [
      [50353, 36386],
      [-137, 3],
      [-175, -4],
      [-263, -50],
      [-223, -78],
      [-95, -46],
      [-163, -92],
      [-48, -60],
      [25, -89],
      [-21, -74],
      [66, -110],
      [-96, -120],
      [-10, -157],
      [-98, -222],
      [-62, -134],
      [-69, -122],
      [-78, -222],
      [-50, -223],
      [-10, -170],
      [-77, -236],
      [37, -191],
      [51, -150],
      [37, -259],
      [199, -98],
      [327, -155],
      [11, -190],
      [-3, -157],
      [-70, -195],
      [-238, -139],
      [24, -218],
      [-213, -267],
      [-171, -154],
      [-237, -104],
      [-236, -78],
      [-317, -82],
      [-150, 19],
    ],
    [
      [45685, 43394],
      [501, -9],
      [449, -130],
      [529, -138],
      [385, 95],
      [320, 212],
      [199, 262],
      [254, 349],
      [110, 146],
      [76, -319],
      [130, -696],
      [183, -329],
      [28, -348],
      [96, -307],
      [116, -355],
      [152, -581],
      [61, -409],
      [17, -646],
      [145, -567],
      [339, -311],
      [191, -228],
      [-13, -380],
      [-24, -272],
      [224, -677],
      [57, -633],
      [124, -675],
      [19, -62],
    ],
    [
      [50688, 31689],
      [-84, -262],
      [-68, -482],
      [0, -97],
      [-1, -353],
      [-1, -86],
      [7, -422],
      [-37, -618],
      [-20, -428],
      [-60, -379],
      [-261, -315],
    ],
    [
      [50353, 36386],
      [109, -361],
      [111, -662],
      [54, -402],
      [166, -553],
      [63, -149],
      [125, -296],
      [112, -186],
      [111, -613],
      [-74, -400],
      [-123, -344],
      [-3, -8],
      [-15, -40],
      [-106, -352],
      [-180, -282],
      [-15, -49],
    ],
    [
      [37601, 7155],
      [-27, -63],
      [-78, -195],
      [-41, -68],
      [-57, -230],
      [-58, -189],
      [-13, -27],
      [-75, -26],
      [-62, -94],
      [-55, -74],
      [-224, -105],
      [-182, -44],
      [-378, -75],
      [-107, -12],
      [-102, -12],
      [-188, 37],
      [-108, 145],
      [-76, 103],
      [-177, 51],
      [-51, 14],
      [-297, 0],
      [-66, 0],
      [-102, -26],
      [-55, -87],
      [-114, -365],
      [28, -368],
      [27, -21],
      [90, -246],
      [-21, -278],
      [-4, -61],
      [4, -130],
      [-42, -29],
      [-284, -196],
      [-129, -73],
      [-169, -44],
      [-142, -5],
      [-60, -29],
      [-75, -36],
      [-130, -141],
      [-48, -40],
      [-68, -39],
      [-183, -113],
      [-65, -20],
      [-57, -18],
      [-250, -84],
      [-176, -45],
      [-217, -98],
      [-177, -7],
      [-59, -3],
      [-61, 27],
      [-259, 115],
      [-276, 34],
      [-107, 13],
      [-169, -24],
      [-310, -42],
      [-81, -53],
      [-162, -392],
      [-68, -101],
      [-91, -236],
      [-130, -127],
      [-48, -40],
      [-41, -20],
      [-231, -145],
      [-123, -29],
      [-384, -91],
      [-309, -9],
      [-233, 154],
      [-269, 11],
      [-120, 20],
      [-68, 11],
      [-200, 126],
      [-70, 252],
      [-153, 98],
      [-160, 139],
      [-82, 300],
      [-172, 3],
    ],
    [
      [28558, 4418],
      [43, 79],
      [22, 67],
      [35, 108],
      [42, 95],
      [7, 27],
      [21, 33],
      [55, 67],
      [61, 74],
      [48, 53],
      [34, 47],
      [41, 33],
      [102, 73],
      [55, 27],
      [101, 39],
      [101, -2],
      [222, 37],
      [81, -1],
      [101, -2],
      [135, 25],
      [47, 5],
      [161, 18],
      [54, -1],
      [155, 18],
      [74, 5],
      [0, 7],
      [175, 18],
      [108, -23],
      [53, 0],
      [58, -2],
    ],
    [
      [30750, 5342],
      [30, 0],
      [141, 4],
      [115, 26],
      [134, 31],
      [89, 33],
      [175, 64],
      [169, 45],
      [109, 53],
      [202, 57],
      [101, 32],
      [203, 65],
      [115, 25],
      [162, 31],
      [101, 12],
      [101, 5],
      [175, 11],
      [108, 11],
      [249, 37],
      [121, -9],
      [154, 4],
      [203, 24],
      [94, 32],
      [118, 24],
    ],
    [
      [18528, 19879],
      [-185, 252],
      [-371, 305],
      [-213, 160],
      [-165, 180],
      [-173, 118],
      [9, 129],
      [-6, 68],
      [-44, 191],
      [12, 346],
      [25, 264],
      [84, 202],
      [-171, 234],
      [-270, -50],
      [-161, 44],
      [-201, 3],
      [-163, -85],
      [-8, 4],
    ],
    [
      [16527, 22244],
      [1, 3],
      [-12, 95],
      [-12, 82],
      [-45, 129],
      [-49, 293],
      [-98, 185],
      [-45, 103],
      [-44, 184],
      [-39, 89],
      [-72, 137],
      [-25, 88],
      [-66, 97],
      [-27, 34],
      [-93, 70],
      [-106, 35],
      [-65, 212],
      [76, 100],
      [22, 108],
      [69, 128],
      [9, 109],
      [-193, 119],
      [-133, 83],
      [109, 107],
      [143, 106],
      [8, 116],
      [8, 67],
      [48, 81],
      [29, 108],
      [-58, 123],
      [-235, 72],
      [-167, 30],
      [-74, 35],
      [-167, 44],
      [-133, 111],
      [-265, 235],
      [-111, 199],
      [-125, 131],
      [-113, 111],
      [-25, 122],
      [-44, 157],
      [61, 74],
      [107, -22],
      [121, -23],
      [202, 24],
      [215, 3],
      [169, 31],
      [148, 25],
      [136, 72],
      [137, 161],
      [21, 67],
      [76, 101],
      [10, 33],
    ],
    [
      [24051, 15319],
      [3, -72],
      [65, -96],
      [45, -143],
      [125, -159],
      [106, -137],
      [138, -200],
      [178, -227],
      [218, -248],
      [91, -164],
      [192, -187],
      [138, -179],
      [132, -178],
      [105, -131],
      [73, -83],
      [18, -136],
      [24, -170],
      [-76, -169],
      [-30, -189],
      [-11, -231],
      [-205, -207],
      [-122, -86],
      [-171, -153],
      [-162, -79],
      [-123, -93],
      [-95, -32],
      [-135, -93],
      [-204, -105],
      [-130, -127],
      [-188, -44],
      [-115, 9],
      [-234, 38],
      [-87, 76],
      [-125, 131],
      [-104, 239],
      [-119, 158],
      [-105, 159],
      [-45, 82],
      [-87, 76],
      [-86, 76],
      [-235, 24],
      [-67, 2],
    ],
    [
      [22641, 12271],
      [93, 331],
      [-92, 103],
      [-81, 49],
      [-140, 70],
      [-219, 120],
      [-187, 104],
      [-181, 31],
      [-114, 42],
      [-3, 197],
      [56, 169],
      [23, 183],
      [21, 34],
      [-39, 48],
      [-11, 1],
    ],
    [
      [21767, 13753],
      [52, 66],
      [102, 86],
      [152, -172],
      [180, -98],
      [106, -103],
      [122, 45],
      [56, 155],
      [-36, 279],
      [-23, 80],
    ],
    [
      [22478, 14091],
      [4, 2],
      [114, 5],
      [55, 60],
      [70, 162],
      [77, 175],
      [55, 121],
      [23, 129],
      [50, 162],
      [-66, 137],
      [-73, 42],
      [-59, 82],
      [9, 163],
      [176, 72],
      [107, -36],
      [174, -58],
      [147, -84],
      [248, -31],
      [127, -22],
      [162, 44],
      [170, 99],
      [3, 4],
    ],
    [
      [33552, 21126],
      [8, -33],
      [117, -233],
      [6, -75],
      [31, -71],
    ],
    [
      [30750, 5342],
      [-71, 36],
      [-85, 110],
      [-233, 146],
      [-93, 84],
      [-92, 123],
      [-166, 166],
      [-72, 76],
      [-114, 70],
      [-85, 83],
      [-40, 34],
      [-106, 124],
      [-78, 151],
      [-91, 185],
      [-125, 165],
      [-99, 110],
      [8, 116],
      [49, 114],
      [42, 88],
      [62, 94],
      [49, 155],
      [102, 100],
      [43, 142],
      [-3, 244],
      [-9, 265],
      [18, 265],
      [36, 142],
      [-20, 407],
      [-64, 225],
      [-55, 327],
      [35, 129],
      [-28, 306],
      [-26, 55],
      [-64, 204],
      [-173, 159],
      [-98, 185],
      [-226, 154],
      [-72, 116],
      [-153, 118],
      [-65, 96],
      [-85, 178],
      [-84, 199],
      [45, 264],
      [-50, 252],
      [29, 121],
      [3, 218],
      [-16, 251],
      [82, 67],
      [88, 59],
      [82, 74],
      [102, 107],
      [57, 189],
      [-65, 109],
      [-14, 7],
      [-113, 9],
      [-195, 31],
      [-34, -14],
      [-81, -12],
      [-87, -5],
      [-134, 77],
      [-113, 63],
      [-34, 1],
      [-106, 76],
      [-24, 150],
      [34, 33],
      [34, 20],
      [142, 66],
      [61, 39],
      [48, 54],
      [63, 134],
      [1, 48],
      [-60, 76],
      [-88, -19],
      [-53, 1],
      [-67, 14],
      [-269, -22],
      [-250, -37],
      [-228, 25],
      [-121, 2],
      [-129, -39],
      [-155, -58],
      [-129, -66],
      [-188, 24],
      [-134, 29],
      [-34, -6],
      [-272, 195],
      [-46, 41],
      [-72, 124],
      [109, 113],
      [150, 140],
      [109, 100],
      [102, 60],
      [142, 45],
      [102, 73],
      [95, 66],
      [55, 60],
      [48, 53],
      [48, 68],
      [-13, 54],
      [-19, 41],
      [-20, 7],
      [-94, 36],
      [-241, 31],
      [-33, 14],
      [-120, 57],
      [-40, 34],
      [-109, -39],
      [-107, 9],
      [-181, 51],
      [-74, -20],
      [-121, -4],
      [-94, 1],
      [0, 7],
      [-147, 64],
      [-128, -39],
      [-180, 119],
      [-81, -6],
      [-172, 132],
      [-108, -5],
      [-81, -19],
      [-34, 8],
      [-60, 14],
      [-93, 29],
      [13, 0],
      [-9, 50],
    ],
    [
      [24543, 15764],
      [-16, 86],
      [-11, 156],
      [-17, 211],
      [-31, 150],
      [-19, 89],
      [63, 128],
      [76, 100],
      [122, 79],
      [56, 49],
    ],
    [
      [28721, 21491],
      [390, 90],
      [852, 284],
      [64, 47],
    ],
    [
      [20641, 10767],
      [5, 0],
      [0, -8],
    ],
    [
      [20613, 10784],
      [8, 0],
      [0, -4],
    ],
    [
      [20751, 10317],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 4],
    ],
    [
      [20201, 11524],
      [8, -1],
      [208, -15],
      [134, -43],
      [438, 33],
      [531, 31],
      [299, 185],
      [237, 139],
      [224, 118],
      [333, 198],
      [28, 102],
    ],
    [
      [24051, 15319],
      [107, 137],
      [169, 85],
      [130, 127],
      [62, 80],
      [24, 16],
    ],
    [
      [32235, 24126],
      [115, 152],
      [354, 300],
      [252, 199],
      [544, 317],
      [298, 152],
      [242, 362],
      [525, 426],
      [56, 176],
      [672, 736],
      [-116, -32],
      [64, 162],
      [149, 501],
      [67, 245],
    ],
    [
      [22478, 14091],
      [-22, 77],
      [-36, 245],
      [-97, 260],
      [-150, 260],
      [-186, 159],
      [-217, 296],
      [-160, 91],
      [-211, 207],
      [-301, 148],
      [-260, 154],
      [-60, 14],
      [-127, 44],
      [-107, 56],
      [-219, 139],
      [-93, 61],
    ],
    [
      [7139, 20139],
      [-36, -345],
      [-111, -269],
      [-97, -162],
      [-417, 35],
      [-652, -50],
      [-28, -34],
      [-405, -88],
      [-485, -80],
      [-492, -66],
      [-324, -90],
      [-392, -108],
      [-301, 73],
      [-175, -51],
    ],
    [
      [3224, 18904],
      [0, 7],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-24, 0],
      [0, 25],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 98],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [65, 0],
      [0, 49],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [461, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -74],
      [-9, 0],
      [0, -32],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, -1],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [153, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [40, 1],
      [0, 7],
      [17, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -16],
      [8, -1],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -74],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [105, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [13, 0],
    ],
    [
      [9546, 22534],
      [-8, -5],
    ],
    [
      [9538, 22529],
      [0, -25],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -139],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -156],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-11, 0],
      [-5, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -12],
      [0, -20],
      [-9, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -32],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-73, 0],
      [-8, 0],
      [2, -7],
      [-34, -1],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -32],
      [0, -9],
      [-9, 2],
      [1, -19],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -50],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -3],
    ],
    [
      [8632, 19866],
      [-305, -93],
      [-24, 225],
      [-264, 255],
      [-142, -38],
      [-457, 1],
      [-56, 280],
      [-107, 35],
      [-121, -318],
      [-4, -9],
    ],
    [
      [7152, 20204],
      [-10, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [73, 0],
      [0, -91],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -156],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-64, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-32, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-161, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 81],
      [-9, 0],
      [0, 50],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 98],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 155],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [106, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [97, 0],
      [0, 24],
      [-129, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-73, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-57, 0],
      [0, -7],
      [-65, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -73],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-114, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-153, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 98],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-16, 1],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 58],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 98],
      [-9, 0],
      [0, 73],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [56, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [113, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -246],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -98],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -40],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -82],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -98],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 106],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -57],
      [-8, -1],
      [0, -49],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [211, 0],
      [0, -8],
      [153, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [32, 0],
      [0, -40],
      [24, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 25],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-130, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-227, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, 57],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -82],
      [-9, 0],
      [0, -73],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [97, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 172],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 123],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [16, 0],
      [0, 40],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-16, 0],
      [0, 33],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [16, 0],
      [0, -32],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -65],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -33],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -73],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [170, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [72, 0],
      [0, -9],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -11],
    ],
    [
      [11619, 29268],
      [120, 42],
      [1140, 198],
      [651, -141],
      [464, -436],
      [604, -507],
      [831, -191],
      [303, 423],
      [71, 223],
      [324, 317],
    ],
    [
      [16527, 22244],
      [-47, -78],
      [-216, -23],
      [-162, -59],
      [-208, 10],
      [-154, 23],
      [-148, 30],
      [-167, 37],
      [-152, 152],
      [-125, 185],
      [-45, 157],
      [-32, 110],
      [16, 142],
      [-4, 149],
      [43, 156],
      [-38, 63],
    ],
    [
      [15088, 23298],
      [12, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [48, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, -1],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-65, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 123],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [41, 0],
      [0, 33],
      [-219, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-26, 0],
      [0, -9],
      [-23, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [-1, -9],
    ],
    [
      [14711, 22487],
      [-94, 37],
      [-80, 103],
      [-206, 146],
      [-309, 5],
      [-187, 71],
      [-181, 44],
      [-152, 125],
      [-94, 26],
    ],
    [
      [13408, 23044],
      [97, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-89, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-121, 0],
      [0, -16],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [-1, 8],
      [-7, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-113, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-81, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-72, 0],
      [0, -9],
      [-41, 0],
      [0, -16],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -32],
      [0, -17],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-6, 0],
      [-10, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-170, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-138, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 66],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -7],
      [-65, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [-16, 0],
      [0, 98],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-72, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-57, 0],
      [0, -7],
      [-64, 0],
      [0, 7],
      [-65, 0],
      [0, -7],
      [-73, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-81, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-48, 0],
      [0, -7],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-41, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-32, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -25],
      [-48, 0],
      [0, -7],
      [-57, 0],
      [0, 7],
      [-65, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [-1, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [-2, 16],
      [1355, 3326],
      [1894, 4651],
      [2558, -1333],
      [350, -448],
      [431, -347],
      [83, -287],
      [-6, -189],
      [-7, -205],
      [-110, -576],
      [-78, -291],
      [-143, -528],
      [93, -457],
      [217, -758],
      [11, -592],
      [11, -584],
      [781, 34],
      [286, 213],
      [599, 485],
      [390, 361],
      [653, 478],
      [244, 506],
      [-183, 343],
      [-236, 405],
      [28, 489],
      [299, 144],
      [466, 148],
      [562, -247],
      [839, -96],
      [232, 81],
    ],
    [
      [17432, 14397],
      [-11, 5],
    ],
    [
      [17421, 14402],
      [11, 0],
      [0, -5],
    ],
    [
      [17116, 14696],
      [0, -8],
      [16, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -5],
      [0, -3],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [17683, 15179],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 82],
      [16, 0],
      [0, 98],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
    ],
    [
      [19666, 15228],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -33],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 24],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [20524, 13393],
      [-16, -6],
      [-212, -173],
      [-16, -156],
      [-510, 2],
      [-115, 9],
      [-148, -5],
      [-199, 105],
      [-214, 92],
      [-133, 118],
      [-186, 112],
      [-56, 75],
    ],
    [
      [18719, 13566],
      [0, 91],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -5],
    ],
    [
      [18614, 13709],
      [-63, 84],
      [-234, 93],
      [-253, 133],
      [-321, 94],
      [-190, 223],
    ],
    [
      [17553, 14336],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-7, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [7, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-81, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 131],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [56, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [98, 0],
      [0, 8],
      [235, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-65, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 41],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [105, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [32, 0],
      [0, -16],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [82, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -57],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -18],
    ],
    [
      [15197, 17569],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -180],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-64, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-49, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-88, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [146, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [32, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [48, 0],
    ],
    [
      [16217, 14977],
      [-8, 3],
      [-233, 80],
      [-276, -16],
      [-114, -12],
      [-162, -24],
      [9, 163],
      [96, 107],
      [-125, 138],
      [-51, 177],
      [-133, 84],
      [-151, 260],
      [-198, 221],
      [-94, 167],
    ],
    [
      [14777, 16325],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-4, 0],
      [-4, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 2],
      [0, 6],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [113, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [64, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [64, 0],
      [0, 9],
      [114, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [65, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 98],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 82],
      [-8, 0],
      [0, 98],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [153, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -82],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 131],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 82],
      [-9, 0],
      [0, 65],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [7, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [41, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -49],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [65, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -17],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-57, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-154, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [-17, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-24, 0],
      [0, -57],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 1],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 13],
      [0, 3],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, -24],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -82],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-17, 0],
      [0, -17],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -3],
      [0, -6],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-32, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 50],
      [-8, -1],
      [0, 8],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [1, 8],
      [-9, 0],
      [0, 6],
      [0, 2],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, -1],
      [0, -5],
    ],
    [
      [21767, 13753],
      [-83, 14],
      [-135, -25],
      [-81, -5],
      [-67, 8],
      [-108, 1],
      [-170, -112],
      [-378, -150],
      [-205, -84],
    ],
    [
      [20540, 13400],
      [0, 69],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [73, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 58],
      [-40, 0],
      [0, -9],
      [-8, 0],
      [0, -65],
      [16, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-25, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 65],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-56, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 40],
      [-9, 0],
      [0, 90],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [169, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 40],
      [16, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
    ],
    [
      [18330, 17962],
      [0, -9],
      [33, 0],
      [0, -7],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -49],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [24, 0],
    ],
    [
      [19221, 18129],
      [0, 54],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [97, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [129, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-97, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-89, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [2, -16],
      [-10, -1],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [2, -16],
      [-18, -1],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-81, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-97, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-7, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-72, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -164],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -66],
      [-9, 0],
      [0, -98],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 115],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-65, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-81, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-9, 0],
      [0, -49],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -188],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-72, 0],
      [0, -8],
      [-82, 0],
      [0, -8],
      [-121, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 115],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-17, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [16, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [9, 0],
      [0, 41],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-89, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, -17],
      [-16, 0],
      [0, -41],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 123],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 58],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -107],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -50],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-81, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -7],
      [-32, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -99],
      [-8, 0],
      [0, -114],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -98],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-122, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-25, 0],
      [0, -7],
      [-32, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-235, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-2, 0],
      [-6, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [105, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [5, 0],
      [35, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [-2, 73],
    ],
    [
      [14240, 18248],
      [12, 12],
      [265, 131],
      [102, 93],
      [81, 108],
    ],
    [
      [14700, 18592],
      [77, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 24],
      [-16, 0],
      [0, 17],
      [8, 0],
      [0, 44],
      [0, 5],
      [8, 0],
      [0, 11],
      [0, 13],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [113, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -16],
      [33, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-65, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 32],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 19],
      [0, 6],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [33, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-14, 0],
      [-10, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [14, 1],
      [2, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 106],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 57],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [25, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [4, 0],
    ],
    [
      [16322, 10735],
      [0, -8],
      [41, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [16, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -42],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [32, 0],
    ],
    [
      [17634, 10505],
      [0, -7],
      [-40, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-89, 0],
      [0, -9],
      [-154, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [81, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -5],
    ],
    [
      [15052, 11226],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-105, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [25, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [16, 0],
    ],
    [
      [18581, 11357],
      [0, -8],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [17772, 10514],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [7, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [121, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [80, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
    ],
    [
      [20524, 13393],
      [0, -23],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -82],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-90, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 139],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 24],
    ],
    [
      [11473, 13599],
      [0, -16],
      [17, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -32],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [20201, 11524],
      [0, 5],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-49, 0],
      [0, 16],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-122, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 40],
      [-16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [89, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 22],
    ],
    [
      [15602, 14229],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -106],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [106, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [97, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [97, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 1],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [57, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 1],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -107],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 1],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [96, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [42, 0],
      [38, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -61],
      [0, -54],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -139],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -99],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [122, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [105, 0],
      [0, 8],
      [65, 0],
      [0, 17],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-7, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-130, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-105, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -122],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-57, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-72, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-105, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 17],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-25, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [98, 0],
      [0, 8],
      [64, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 33],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [24, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [48, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [74, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -49],
      [16, 0],
      [0, -9],
      [97, 0],
      [0, 9],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-82, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 40],
      [73, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-40, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-81, 0],
      [0, -16],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 66],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-40, 0],
      [0, 9],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 81],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 74],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [65, 0],
      [0, 16],
      [81, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [105, 0],
    ],
    [
      [11813, 14410],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-154, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 33],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 74],
      [-9, 0],
      [0, 66],
      [9, 0],
      [0, 57],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [145, 0],
    ],
    [
      [17432, 14397],
      [0, -4],
      [11, 0],
      [5, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
    ],
    [
      [18614, 13709],
      [0, -3],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [16, 0],
      [0, -40],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-114, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 106],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 89],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 1],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 40],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [97, 0],
      [0, -8],
      [56, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [154, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 17],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [24, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [7, 0],
      [1, -8],
      [22, 0],
    ],
    [
      [10478, 15187],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [146, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [57, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -99],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [-17, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 106],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 138],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [7, 0],
      [0, 8],
      [-7, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [7, 0],
      [1, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [-1, 8],
      [-7, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
    ],
    [
      [16217, 14977],
      [0, -3],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 1],
      [-1, -10],
      [14, 0],
      [11, 0],
      [0, -8],
      [16, 0],
      [0, -12],
      [0, -4],
      [5, 0],
      [11, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -7],
      [7, -4],
      [1, -6],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-56, 0],
      [0, 17],
      [-41, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-138, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-105, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, -17],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [81, 0],
      [0, -9],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-17, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-56, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [32, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [65, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [9, 0],
      [0, 50],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 164],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-24, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -115],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -50],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-57, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [170, 0],
      [0, -8],
      [121, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 1],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 107],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 66],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 98],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [57, 0],
      [0, 8],
      [137, 0],
      [0, 8],
      [154, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [194, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
    ],
    [
      [6559, 12651],
      [-8, -1],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, -1],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 7],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-4, 0],
      [-4, 0],
      [1, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [6, 0],
      [2, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [48, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [81, 0],
      [0, -9],
      [40, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [56, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [137, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [129, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [81, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [106, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -17],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [106, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [138, 0],
      [0, -8],
      [121, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [130, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [49, 0],
      [0, -9],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -66],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -50],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -89],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -82],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-65, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 180],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -24],
      [-8, 0],
      [0, -50],
      [-9, 0],
      [0, -32],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [40, 0],
      [0, -287],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [97, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, -1],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [73, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [7, 0],
      [1, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [129, 0],
      [0, 8],
      [33, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [16, 1],
      [0, 7],
      [41, 0],
      [0, -7],
      [8, -1],
      [0, -8],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [24, 0],
      [0, 16],
      [81, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [25, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -11],
    ],
    [
      [14121, 17119],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, -2],
      [0, -14],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-162, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-170, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [-40, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-56, 0],
      [0, -17],
      [64, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 90],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-97, 0],
      [0, -8],
      [-25, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -140],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [41, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-65, 0],
      [0, 9],
      [-64, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-64, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-97, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 42],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [49, 0],
      [0, 9],
      [73, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 155],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 16],
      [7, 0],
      [0, 17],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 114],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [64, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-7, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 57],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -82],
      [-7, 0],
      [0, -41],
      [7, 0],
      [1, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -115],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [7, 0],
      [1, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -58],
      [17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-25, 0],
      [0, -9],
      [-81, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [16, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, 7],
      [122, 0],
      [0, 9],
      [121, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 155],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-113, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 42],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [89, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [80, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [40, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [73, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [-9, 0],
      [0, 22],
      [0, 11],
      [-8, 0],
      [0, 66],
      [17, 0],
    ],
    [
      [11902, 18142],
      [0, -9],
      [89, 0],
      [0, -7],
      [90, 0],
      [0, -9],
      [64, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [97, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [40, 0],
      [1, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [170, 0],
      [0, 8],
      [113, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-89, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-48, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 41],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [-1, -8],
      [-7, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -65],
      [16, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -16],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 50],
      [8, 0],
      [0, 89],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-81, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [129, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 139],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [57, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-65, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-97, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [-1, 8],
      [-7, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [24, 0],
      [1, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 41],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [32, 0],
      [0, 7],
      [24, 0],
      [0, 9],
      [162, 0],
    ],
    [
      [14240, 18248],
      [-6, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-57, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -11],
      [0, -6],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, 8],
      [-7, 0],
      [-66, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [-1, 8],
      [0, 2],
      [-7, -1],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 7],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [1, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [48, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [-65, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-56, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -172],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -9],
      [64, 0],
      [0, -8],
      [260, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [21, 0],
    ],
    [
      [8632, 19866],
      [0, -5],
      [64, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 28],
      [0, 13],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [56, -2],
      [0, -5],
      [41, -2],
      [0, 9],
      [40, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -24],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -41],
      [9, 0],
      [0, -33],
      [-9, 0],
      [0, -74],
      [-8, 0],
      [0, -40],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -131],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-40, 0],
      [0, 7],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-121, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-57, 0],
      [0, 9],
      [-56, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-235, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-24, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
    ],
    [
      [7139, 20139],
      [3, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-6, 0],
    ],
    [
      [13271, 21268],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [194, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [73, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [89, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [9, 0],
      [23, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-5, 0],
      [-3, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -303],
      [-8, 0],
      [0, -22],
      [0, -51],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 1],
      [0, -17],
      [-8, 0],
      [0, -6],
      [0, -11],
      [-2, 0],
      [-6, 0],
      [0, -6],
      [0, -10],
      [-7, 0],
      [-17, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-113, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-146, 0],
      [0, -8],
      [-105, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-64, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 82],
      [17, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, 9],
      [81, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-65, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [-1, -9],
      [-7, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-211, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-41, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-98, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-129, 0],
      [0, 8],
      [-97, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-64, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 89],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 131],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 90],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [106, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [48, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [17, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [7, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [89, 0],
    ],
    [
      [9546, 22534],
      [0, -5],
      [-8, 0],
    ],
    [
      [14711, 22487],
      [-16, 1],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-37, 0],
      [-27, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-97, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -53],
      [-1, -3],
      [-7, -1],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -99],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-18, 0],
      [-15, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-129, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-113, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-89, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -32],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-114, 0],
      [0, -8],
      [-137, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-122, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 114],
      [-8, 0],
      [0, 49],
      [-8, 1],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-17, 0],
      [0, 24],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-24, 0],
      [0, 17],
      [-8, 0],
      [0, 106],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 99],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 42],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [1, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [97, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 90],
      [8, 0],
      [1, 24],
      [7, 1],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [122, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [105, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [7, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
    ],
  ],
  transform: {
    scale: [0.00003431193185144082, 0.000033939224624089153],
    translate: [5.003688143081165, 5.023308326890327],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Aniocha North",
            id: "DE001",
            nameAbbr: "Aniocha North",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.97935664122,
            Shape_Area: 0.03807227657,
          },
        },
        {
          arcs: [[4, 5, 6, 7, 8, 9, -1]],
          type: "Polygon",
          properties: {
            name: "Aniocha South",
            id: "DE002",
            nameAbbr: "Aniocha South",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25549167985,
            Shape_Area: 0.07064035602,
          },
        },
        {
          arcs: [[[10, 11, 12, 13]], [[14, 15]], [[16, 17]]],
          type: "MultiPolygon",
          properties: {
            name: "Bomadi",
            id: "DE003",
            nameAbbr: "Bomadi",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.9447277549,
            Shape_Area: 0.01015622681,
          },
        },
        {
          arcs: [
            [[18]],
            [[19]],
            [[20]],
            [[21]],
            [[22, 23]],
            [[24]],
            [[25, -17, 26, -15, 27, -13, 28]],
            [[29]],
            [[30]],
            [[31]],
            [[32, 33, 34, 35, 36, 37]],
            [[38, 39]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Burutu",
            id: "DE004",
            nameAbbr: "Burutu",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 7.05307123573,
            Shape_Area: 0.14147037294,
          },
        },
        {
          arcs: [[40, 41, 42, 43]],
          type: "Polygon",
          properties: {
            name: "Ethiope West",
            id: "DE005",
            nameAbbr: "Ethiope West",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.09247988855,
            Shape_Area: 0.04437860398,
          },
        },
        {
          arcs: [[[44, 45, 46]], [[47, 48, 49]]],
          type: "MultiPolygon",
          properties: {
            name: "Ethiope East",
            id: "DE006",
            nameAbbr: "Ethiope East",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.0423158745,
            Shape_Area: 0.03157739095,
          },
        },
        {
          arcs: [[-10, 50, 51, -2]],
          type: "Polygon",
          properties: {
            name: "Ika North",
            id: "DE007",
            nameAbbr: "Ika North",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.06518174901,
            Shape_Area: 0.04116251168,
          },
        },
        {
          arcs: [[-51, -9, 52, 53]],
          type: "Polygon",
          properties: {
            name: "Ika South",
            id: "DE008",
            nameAbbr: "Ika South",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.43296025487,
            Shape_Area: 0.04419388749,
          },
        },
        {
          arcs: [[[54]], [[55, 56, 57, 58]]],
          type: "MultiPolygon",
          properties: {
            name: "Isoko North",
            id: "DE009",
            nameAbbr: "Isoko North",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.52658740971,
            Shape_Area: 0.0394466331,
          },
        },
        {
          arcs: [[59, 60, 61, -56], [-55]],
          type: "Polygon",
          properties: {
            name: "Isoko South",
            id: "DE010",
            nameAbbr: "Isoko South",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.22108948083,
            Shape_Area: 0.05533663431,
          },
        },
        {
          arcs: [[62, 63, -60, -59, 64, -7, 65]],
          type: "Polygon",
          properties: {
            name: "Ndokwa East",
            id: "DE011",
            nameAbbr: "Ndokwa East",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.42488535439,
            Shape_Area: 0.13946516299,
          },
        },
        {
          arcs: [[-65, -58, 66, 67, 68, -53, -8]],
          type: "Polygon",
          properties: {
            name: "Ndokwa West",
            id: "DE012",
            nameAbbr: "Ndokwa West",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.65500973991,
            Shape_Area: 0.07015657194,
          },
        },
        {
          arcs: [[-41, 69, -46, 70, 71, 72, 73, 74, 75]],
          type: "Polygon",
          properties: {
            name: "Okpe",
            id: "DE013",
            nameAbbr: "Okpe",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03249551858,
            Shape_Area: 0.03558419855,
          },
        },
        {
          arcs: [[76, -5, -4, 77]],
          type: "Polygon",
          properties: {
            name: "Oshimili North",
            id: "DE014",
            nameAbbr: "Oshimili North",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.07730970682,
            Shape_Area: 0.0406468182,
          },
        },
        {
          arcs: [[78, -66, -6, -77, 79]],
          type: "Polygon",
          properties: {
            name: "Oshimili South",
            id: "DE015",
            nameAbbr: "Oshimili South",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.91504186681,
            Shape_Area: 0.02504313805,
          },
        },
        {
          arcs: [[-64, 80, -11, 81, 82, -61]],
          type: "Polygon",
          properties: {
            name: "Patani",
            id: "DE016",
            nameAbbr: "Patani",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.85870136516,
            Shape_Area: 0.0171373163,
          },
        },
        {
          arcs: [[-76, 83, 84, -42]],
          type: "Polygon",
          properties: {
            name: "Sapele",
            id: "DE017",
            nameAbbr: "Sapele",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.93215430818,
            Shape_Area: 0.03534300515,
          },
        },
        {
          arcs: [[85, 86, 87, 88]],
          type: "Polygon",
          properties: {
            name: "Udu",
            id: "DE018",
            nameAbbr: "Udu",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.47803827641,
            Shape_Area: 0.01101177636,
          },
        },
        {
          arcs: [[89, -67, -57, -62, -83, 90, 91, -71, -45, 92, -48]],
          type: "Polygon",
          properties: {
            name: "Ughelli North",
            id: "DE019",
            nameAbbr: "Ughelli North",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.01909507156,
            Shape_Area: 0.06682848472,
          },
        },
        {
          arcs: [
            [[-36, 93]],
            [[-34, 94]],
            [[-91, -82, -14, -28, -16, -27, -18, -26, 95, 96, -86, 97]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Ughelli South",
            id: "DE020",
            nameAbbr: "Ughelli South",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.24317829384,
            Shape_Area: 0.06346740976,
          },
        },
        {
          arcs: [[-90, -50, 98, -68]],
          type: "Polygon",
          properties: {
            name: "Ukwuani",
            id: "DE021",
            nameAbbr: "Ukwuani",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.81284650209,
            Shape_Area: 0.03388005714,
          },
        },
        {
          arcs: [[-92, -98, -89, 99, -72]],
          type: "Polygon",
          properties: {
            name: "Uvwie",
            id: "DE022",
            nameAbbr: "Uvwie",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.47100266907,
            Shape_Area: 0.00810765806,
          },
        },
        {
          arcs: [
            [[100, 101]],
            [[102, 103, 104, 105]],
            [[106, -43, -85, 107, 108, 109, 110]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Warri North",
            id: "DE023",
            nameAbbr: "Warri North",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 5.05033393298,
            Shape_Area: 0.13330666293,
          },
        },
        {
          arcs: [
            [[111, 112]],
            [[113]],
            [[114]],
            [[115]],
            [[116, 117, 118, 119]],
            [[120]],
            [[121, 122]],
            [[-100, -88, 123, 124, -73]],
            [[125]],
            [[-84, -75, 126, 127, 128, -108]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Warri South",
            id: "DE024",
            nameAbbr: "Warri South",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.5631829447,
            Shape_Area: 0.04284424749,
          },
        },
        {
          arcs: [
            [[129]],
            [[-23, 130]],
            [[131]],
            [[132]],
            [[-38, 133]],
            [[-117, 134]],
            [[135]],
            [[-97, 136, -124, -87]],
            [[137]],
            [[138]],
            [[-112, 139, -119, 140]],
            [[141]],
            [[142, -122]],
            [[-40, 143]],
            [[144]],
            [[145]],
            [[-128, 146]],
            [[147, -101, 148, -105]],
            [[149]],
            [[-103, 150]],
            [[-110, 151]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Warri South-West",
            id: "DE025",
            nameAbbr: "Warri South-West",
            parent: "Delta",
            parentAbbr: "DE",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 9.99382828986,
            Shape_Area: 0.11788133183,
          },
        },
      ],
    },
  },
};

const crossriver = {
  type: "Topology",
  arcs: [
    [
      [7397, 37765],
      [7, -57],
      [133, -77],
      [63, -273],
      [96, -307],
      [-71, -229],
      [65, -110],
      [-63, -182],
      [-15, -129],
      [-29, -135],
      [-89, -94],
      [-183, -51],
      [-93, 35],
      [-242, -9],
      [-385, -89],
      [-91, 199],
      [-219, -241],
      [-16, -176],
      [-264, -77],
      [-221, 72],
      [-162, -45],
      [-345, -150],
      [-4, -231],
      [-58, -284],
      [11, -190],
      [10, -177],
      [-44, -250],
      [3, -218],
      [31, -170],
      [12, -116],
      [24, -116],
      [-8, -88],
      [-82, -127],
      [-29, -108],
      [-299, -165],
      [-170, -140],
      [-70, -188],
      [-63, -169],
      [-125, -208],
      [-45, -100],
    ],
    [
      [4367, 32595],
      [-84, 27],
      [-267, 59],
      [-241, 99],
      [-338, -110],
      [-253, -287],
      [-193, -289],
      [-272, -185],
      [-352, -137],
      [-216, -57],
      [-274, 66],
      [-147, 77],
      [-182, -24],
      [-158, 12],
    ],
    [
      [1390, 31846],
      [11, 11],
      [116, 141],
      [88, 59],
      [55, 67],
      [-129, 342],
      [-41, 382],
      [24, 251],
      [-17, 143],
      [-5, 149],
      [96, 108],
      [178, 160],
      [35, 94],
      [196, 72],
      [54, 6],
      [130, 161],
      [-31, 150],
      [0, 13],
      [92, 250],
      [98, 230],
      [315, 388],
      [131, 148],
      [142, 92],
      [152, 208],
      [103, 121],
      [89, 134],
      [-4, 184],
      [84, 155],
      [18, 339],
      [-16, 205],
      [-43, 272],
      [-71, 199],
      [-118, 171],
      [-106, 152],
      [-42, 62],
      [-37, 55],
      [-99, 110],
      [-65, 110],
      [1, 102],
      [-5, 109],
      [9, 115],
      [41, 61],
      [42, 81],
      [150, 120],
      [129, 65],
      [128, 32],
      [101, 5],
      [102, 32],
      [257, 125],
      [204, 132],
      [135, 32],
      [162, 24],
      [27, 4],
      [216, 26],
      [263, 30],
      [235, -45],
      [195, 17],
      [206, -146],
      [98, -186],
      [164, -268],
      [78, -150],
      [38, -171],
      [76, -280],
      [84, -240],
      [118, -205],
      [123, -268],
      [139, -172],
      [140, -57],
      [102, 46],
      [178, 167],
      [37, 190],
      [195, 425],
      [123, 161],
      [238, 125],
      [95, -13],
    ],
    [
      [21014, 30201],
      [146, -7],
      [422, -102],
      [314, -141],
      [408, -129],
      [300, -148],
      [402, -88],
      [557, -98],
      [516, -77],
      [356, -6],
      [490, -29],
      [369, -74],
      [448, -157],
      [368, -74],
      [376, -88],
      [455, -123],
      [676, -175],
      [470, -69],
      [530, -63],
      [266, -65],
    ],
    [
      [28883, 28488],
      [70, -165],
      [17, -26],
      [165, -251],
      [134, -140],
      [175, -183],
      [169, -115],
      [100, -67],
      [170, -167],
      [123, -122],
      [18, -16],
      [19, -19],
      [188, -257],
      [115, -338],
      [30, -501],
      [-73, -238],
      [-280, -292],
      [-138, -455],
      [-356, -485],
      [-63, -86],
      [-219, -373],
      [-14, -46],
      [-49, -163],
      [-95, -314],
      [-23, -159],
      [-42, -302],
      [-77, -158],
      [-3, -5],
      [-39, -42],
      [-373, -400],
      [-113, -204],
      [-53, -292],
      [143, -467],
      [-12, -257],
      [-55, -171],
      [-24, -74],
      [1, -237],
      [56, -208],
      [39, -150],
      [-9, -9],
      [-170, -168],
      [-122, -542],
      [-156, -687],
      [-88, -314],
      [-76, -271],
      [-112, -403],
      [-115, -412],
      [93, -749],
      [7, -57],
      [-4, -85],
      [-18, -471],
      [-3, -90],
      [-9, -222],
      [51, -494],
      [-39, -700],
      [-8, -261],
      [-109, -142],
      [-187, -244],
      [-78, -177],
      [-93, -210],
      [-87, -108],
      [-120, -151],
      [-43, -113],
      [-36, -93],
      [-31, -80],
      [-25, -67],
      [-89, -230],
      [-165, -213],
      [-135, -175],
      [-264, -196],
      [-2, -2],
      [-622, -172],
      [-23, -34],
      [-23, -34],
      [34, -176],
      [-16, -97],
      [-10, -59],
      [-200, -103],
      [-45, -57],
      [-95, -120],
      [-161, -120],
      [-132, -98],
      [-60, -204],
      [-78, -264],
      [-70, -75],
      [-63, -68],
      [-78, -214],
      [-1, -4],
      [16, -64],
      [45, -172],
      [-35, -54],
      [-18, -28],
      [-247, -110],
      [61, -216],
      [-2, -6],
      [-44, -96],
      [-8, -2],
      [-14, -4],
      [-48, -13],
      [-57, -16],
      [-428, -431],
      [-31, -32],
      [4, -29],
      [10, -72],
      [57, -52],
      [10, -9],
      [18, -3],
      [256, -36],
      [4, -6],
      [138, -177],
      [-6, -12],
      [-34, -89],
      [-127, 13],
      [-43, -51],
      [-44, -51],
      [-66, -4],
      [-54, -4],
      [-33, -24],
      [-60, -44],
      [-40, -211],
      [-253, -180],
    ],
    [
      [23556, 8223],
      [-540, 127],
      [-384, -14],
      [-281, 45],
      [-195, -10],
      [-463, 55],
      [-290, -1],
      [-234, 17],
      [-155, 10],
      [-290, -50],
      [-309, 33],
      [-382, 34],
      [-284, -50],
      [-324, -116],
      [-263, -50],
      [-431, -54],
      [-492, -33],
      [-475, 97],
      [-322, 67],
      [-332, 257],
      [-211, 275],
      [-31, 143],
      [-295, 460],
      [-75, 381],
      [-129, 308],
      [-111, 239],
      [-368, 143],
      [-233, 126],
      [-257, 337],
      [-124, 178],
      [-39, 76],
      [-90, 286],
      [-52, 144],
      [-104, 199],
      [-80, 55],
      [-180, 58],
      [-230, -85],
      [-39, 24],
    ],
    [
      [14462, 11934],
      [-241, 144],
      [-139, 125],
      [-258, 276],
      [-111, 226],
      [-124, 232],
      [-148, 377],
      [-190, 295],
      [-80, 62],
      [-119, 145],
      [-231, 241],
      [-138, 206],
      [-293, 168],
      [-93, 117],
      [-602, 167],
      [-355, 87],
      [-611, 31],
      [-268, 73],
      [-307, 154],
      [-145, 186],
      [-160, 71],
      [-193, 91],
      [-265, 249],
      [-186, 159],
      [-340, 196],
      [-476, 70],
      [-476, 123],
      [-300, 107],
      [-225, 262],
      [-145, 179],
      [-212, 139],
      [-205, 255],
      [-52, 177],
    ],
    [
      [6774, 17324],
      [149, 25],
      [439, 81],
      [284, 124],
      [185, 228],
      [280, 260],
      [158, 214],
      [295, 348],
      [206, 282],
      [193, 288],
      [158, 222],
      [199, 275],
      [239, 233],
      [193, 282],
      [75, 60],
      [197, 132],
      [204, 167],
      [41, 433],
      [-34, 381],
      [-39, 490],
      [-243, 397],
      [-148, 410],
      [-215, 438],
      [-182, 370],
      [-90, 273],
      [-154, 430],
      [-243, 338],
      [-191, 322],
      [-189, 342],
      [-223, 378],
      [-161, 383],
      [-163, 362],
      [-2, 306],
      [-136, 287],
      [-38, 164],
      [-23, 211],
      [-165, 220],
      [-89, 314],
      [-60, 435],
      [0, 41],
    ],
    [
      [7481, 28270],
      [96, 93],
      [82, 60],
      [361, -108],
      [161, -23],
      [222, 37],
      [127, -57],
      [325, 137],
      [440, 203],
      [413, 170],
      [467, 202],
      [246, 220],
      [433, 162],
      [312, 212],
      [219, 193],
      [427, 217],
      [359, 184],
      [144, 201],
      [0, -7],
      [305, 172],
      [214, 199],
    ],
    [
      [12834, 30737],
      [80, 74],
      [482, 256],
      [390, 455],
      [543, 310],
      [212, 255],
      [238, 159],
      [325, 143],
      [203, 44],
      [187, -30],
      [302, -32],
      [295, -66],
      [261, -80],
      [153, -104],
      [37, -238],
      [69, -266],
      [58, -151],
      [111, -198],
      [260, -154],
      [179, -56],
    ],
    [
      [17219, 31058],
      [142, -45],
      [329, -53],
      [596, -132],
      [402, -89],
      [536, -118],
      [455, -109],
      [496, -117],
      [308, -74],
      [188, -16],
      [214, -99],
      [129, -5],
    ],
    [
      [23556, 8223],
      [-22, -15],
      [-51, -36],
      [-66, -106],
      [-27, -44],
      [46, -88],
      [35, -67],
      [-28, -55],
      [-32, -61],
      [-280, -198],
      [-194, 13],
      [-59, -156],
      [0, -73],
      [1, -56],
      [106, -259],
      [36, -86],
      [-39, -210],
      [-107, -143],
      [-615, -78],
      [-206, -136],
      [-233, -340],
      [-138, -99],
      [-15, -10],
      [-7, -11],
      [-90, -139],
      [-22, -139],
      [-10, -64],
      [22, -251],
      [149, -195],
      [338, -283],
    ],
    [
      [22048, 4838],
      [-564, -223],
      [-145, -195],
      [-224, -580],
      [-353, -231],
      [-165, -77],
    ],
    [
      [20597, 3532],
      [-24, 52],
      [-69, 144],
      [-188, 73],
      [-174, -63],
      [-299, -314],
      [-3, -4],
      [-9, -10],
      [-24, -43],
    ],
    [
      [19807, 3367],
      [-192, -17],
      [-418, -67],
      [-400, -150],
      [-298, -151],
      [-230, -145],
      [-178, -174],
      [-125, -228],
      [-152, -235],
      [-280, -274],
      [0, -46],
      [-39, 67],
      [-100, 61],
      [-10, -52],
    ],
    [
      [17385, 1956],
      [-33, 0],
      [0, -8],
      [-49, 0],
      [0, -98],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [1, -9],
      [-17, 0],
      [0, -9],
      [0, -7],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-11, 0],
      [-13, 0],
      [0, -8],
      [-65, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-40, 0],
      [0, -5],
      [0, -3],
      [-17, 2],
      [1, -10],
      [-8, 0],
      [-1, 9],
      [-24, -1],
      [0, 8],
      [-108, 0],
      [-29, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-42, 0],
      [-7, 0],
      [0, 4],
      [0, 13],
      [-8, 0],
      [0, 7],
      [-30, 0],
      [-10, 0],
      [0, -7],
      [-8, 0],
      [0, 17],
      [-17, -1],
      [1, 10],
      [-17, -2],
      [1, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 106],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, -1],
      [0, 9],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [3, 0],
      [13, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, 9],
      [178, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [73, 0],
      [0, 24],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-243, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-7, 0],
      [-1, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 25],
      [9, 0],
      [-1, 16],
      [8, -1],
      [0, 18],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [4, 0],
      [4, 0],
      [0, 6],
      [0, 18],
      [9, 0],
      [7, 0],
      [0, 8],
      [-2, 0],
      [-6, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-10, -1],
    ],
    [
      [14096, 2651],
      [-25, 57],
      [-14, 196],
      [2, 236],
      [10, 264],
      [-4, 197],
      [-91, 185],
      [-78, 192],
      [-92, 137],
      [-8, 10],
      [3, 6],
      [-12, 4],
      [-200, 235],
      [-92, 151],
      [-137, 233],
      [-27, 7],
      [-154, 71],
      [-66, 28],
      [-73, 69],
      [-154, 64],
      [-106, 83],
      [-133, 70],
      [-40, 35],
      [-85, 137],
      [0, 54],
      [-19, 42],
      [9, 162],
      [-25, 89],
      [43, 176],
      [116, 100],
      [1, 102],
      [70, 120],
      [28, 109],
      [-20, 20],
      [9, 156],
      [43, 149],
      [36, 115],
      [42, 135],
      [-7, 42],
    ],
    [
      [12846, 6889],
      [109, 17],
      [195, 3],
      [188, -16],
      [161, 11],
      [176, 17],
      [148, 11],
      [141, -2],
      [61, 33],
      [21, 74],
      [9, 102],
      [27, 53],
      [55, 74],
      [-6, 41],
      [-31, 156],
      [50, 149],
      [168, 18],
      [187, -92],
      [147, -57],
      [93, -49],
      [160, -64],
      [161, -16],
      [122, 32],
      [55, 67],
      [56, 128],
      [28, 101],
      [14, 41],
      [15, 54],
      [15, 135],
      [-125, 125],
      [-181, 77],
      [-147, 30],
      [-139, 125],
      [2, 176],
      [9, 142],
      [-113, 71],
      [-87, 8],
      [-182, -4],
      [-181, 3],
      [-147, 70],
      [-173, 99],
      [-60, 75],
      [-65, 137],
      [9, 136],
      [-4, 142],
      [43, 176],
      [23, 183],
      [70, 141],
      [90, 162],
      [83, 188],
      [112, 256],
      [144, 188],
      [50, 176],
      [57, 175],
      [15, 122],
      [-37, 198],
      [-119, 144],
      [-104, 186],
      [-15, 9],
    ],
    [
      [14199, 11626],
      [83, 57],
      [95, 80],
      [56, 128],
      [29, 43],
    ],
    [
      [19807, 3367],
      [-154, -283],
      [-122, -399],
      [-25, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -221],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, 8],
      [113, 0],
      [0, 8],
      [47, 0],
      [59, 0],
      [0, 8],
      [80, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 49],
      [16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 49],
      [8, 0],
      [0, 57],
      [17, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [17, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -32],
      [9, 0],
      [0, -25],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [0, -16],
      [16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -33],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 36],
      [0, 6],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-28, 0],
      [-4, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -7],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -11],
      [0, -25],
      [0, -4],
      [8, -1],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-19, 0],
      [-13, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -4],
      [0, -4],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-10, 0],
      [-30, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [1, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [16, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -115],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -15],
      [0, -10],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-56, 0],
      [-1, -8],
      [-40, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 10],
      [-8, -2],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 6],
      [0, 2],
      [-16, 0],
      [0, -5],
      [0, -3],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-5, 0],
      [-12, 0],
      [0, -7],
      [-7, -3],
      [-1, -7],
      [-12, 0],
      [-4, 0],
      [0, -8],
      [-12, 0],
      [-4, 0],
      [0, -8],
      [-9, 1],
      [1, -18],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-89, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 73],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [73, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [3, 0],
      [5, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, 11],
      [0, 6],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [17, 0],
      [64, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
    ],
    [
      [22048, 4838],
      [79, -66],
      [143, -270],
      [17, -461],
      [-202, -120],
      [-238, -142],
      [-465, -335],
      [-150, -67],
      [-83, -37],
      [-268, -9],
      [-248, 127],
      [-36, 74],
    ],
    [
      [31954, 56983],
      [-20, -102],
      [54, 19],
      [-169, -71],
      [-94, -26],
      [-313, -178],
      [-217, -146],
      [-264, -138],
      [-237, -97],
      [-178, -147],
      [-265, -206],
      [-304, -117],
      [-285, -137],
      [-344, -69],
      [-169, -58],
      [-249, -57],
      [-224, -78],
      [-40, -19],
      [-277, -77],
      [-254, 507],
      [-32, 136],
      [1, 54],
      [-13, 55],
      [-6, 41],
      [8, 33],
      [-77, 260],
      [14, 40],
      [35, 54],
      [69, 128],
      [90, 148],
      [-346, 650],
      [-107, 23],
      [42, 148],
      [70, 169],
      [56, 114],
      [95, 60],
      [96, 86],
      [76, 121],
      [-33, 82],
      [-106, 111],
      [-140, 56],
      [-86, 124],
      [71, 162],
      [49, 121],
      [1, 95],
      [-60, 28],
      [-5, 109],
      [23, 183],
      [-14, 50],
    ],
    [
      [27876, 59227],
      [56, 44],
      [394, 252],
      [368, 346],
      [315, 314],
      [335, 361],
      [395, 305],
      [313, 267],
      [255, 382],
      [310, 484],
      [208, 370],
      [13, 172],
    ],
    [
      [30838, 62524],
      [450, -329],
      [360, -219],
      [100, -61],
      [293, -182],
      [226, -131],
      [28, -16],
      [190, -302],
      [73, -477],
      [-17, -350],
      [-3, -65],
      [-46, -359],
      [-53, -359],
      [-52, -312],
      [46, -288],
      [-149, -61],
      [-26, -386],
      [-8, -93],
    ],
    [
      [32250, 58534],
      [-33, -368],
      [-213, -315],
      [34, -395],
      [-53, -311],
      [-31, -162],
    ],
    [
      [6774, 17324],
      [-289, -49],
      [-255, -23],
      [-122, -18],
      [-295, 59],
      [-429, 76],
      [-454, 197],
      [-368, 129],
      [-394, 142],
      [-273, 175],
      [-79, 117],
      [-131, 213],
      [-28, 305],
      [-69, 314],
      [6, 0],
      [-14, 346],
      [32, 332],
      [58, 278],
      [84, 209],
      [145, 255],
      [111, 188],
      [24, 224],
      [-42, 320],
      [-37, 245],
      [-183, 295],
      [-27, 1],
      [-147, 36],
      [-25, 129],
      [-96, 301],
      [-75, 368],
      [32, 318],
      [146, 283],
      [-82, 381],
      [-41, 334],
      [-96, 307],
      [-164, 233],
      [-294, 175],
      [-430, -6],
      [-283, -22],
      [-485, -94],
      [-317, -55],
      [-336, -53],
    ],
    [
      [1052, 24289],
      [-109, 371],
      [-259, 650],
      [-28, 305],
      [-52, 573],
      [8, 924],
      [75, 523],
      [181, 785],
      [21, 468],
      [-228, 460],
      [-280, 154],
      [7, 14],
      [70, 223],
      [5, 265],
      [5, 333],
      [92, 304],
      [90, 175],
      [194, 232],
      [19, 23],
      [180, 282],
      [166, 303],
      [181, 190],
    ],
    [
      [4367, 32595],
      [71, -23],
      [113, -29],
      [16, -272],
      [129, -362],
      [99, -97],
      [118, -192],
      [64, -218],
      [109, -321],
      [131, -186],
      [158, -254],
      [159, -152],
      [305, -236],
      [204, -316],
      [197, -261],
      [85, -117],
      [212, -200],
      [97, -246],
      [124, -247],
      [69, -279],
      [132, -159],
      [147, -57],
      [74, -35],
      [100, -56],
      [201, -10],
    ],
    [
      [30878, 52546],
      [144, -164],
      [190, -295],
      [419, -265],
      [218, -131],
      [122, -72],
      [175, -82],
      [56, 0],
      [712, 73],
      [92, 10],
      [465, 229],
      [386, 138],
      [1102, 154],
      [1287, 153],
      [156, 145],
    ],
    [
      [36402, 52439],
      [13, -47],
      [241, 138],
      [249, 151],
      [404, 235],
      [377, 215],
      [392, 185],
      [296, 78],
      [325, 95],
      [536, -88],
      [199, -89],
      [171, -51],
      [164, -27],
      [219, -137],
      [135, -98],
      [218, -217],
      [98, -82],
      [41, -114],
      [97, -184],
      [61, -134],
      [140, -173],
      [55, -133],
      [75, -193],
      [249, -427],
      [-3, -120],
      [86, -382],
      [-2, -163],
      [55, -384],
      [-77, -172],
      [-152, -184],
      [-174, -84],
      [247, -502],
      [38, -206],
      [-10, -166],
      [126, -248],
      [90, -114],
      [102, -320],
      [243, -309],
      [219, -156],
      [154, -102],
      [2, -2],
      [789, -541],
      [37, -25],
    ],
    [
      [42927, 47162],
      [-40, -22],
      [-71, -40],
      [-14, -28],
      [-22, -48],
      [-100, 7],
      [-107, 7],
      [0, -1],
      [-20, -54],
      [93, -94],
      [7, -75],
      [-73, -101],
      [-20, -2],
      [-133, -13],
      [-119, -140],
      [-164, -194],
      [-2, -3],
      [-88, -172],
      [-142, -275],
      [-191, -372],
      [-26, -48],
      [-3, -6],
      [-15, -19],
      [-15, -21],
      [-72, -97],
      [-49, -67],
      [-8, -11],
      [-2, -5],
      [-93, -239],
      [-21, -54],
      [-590, -923],
      [-143, -30],
      [-168, -35],
      [-138, -28],
      [-27, -6],
      [-4, -1],
      [-91, -38],
      [-137, -57],
      [-3, -1],
      [-347, -50],
      [-4, -1],
      [-458, -198],
      [-3, -1],
      [-194, -144],
      [-525, -391],
      [-48, -36],
      [-5, -5],
      [-223, -260],
      [-4, -4],
      [-264, -229],
      [-69, -59],
      [0, 0],
      [-168, -450],
      [-9, -24],
      [-56, -151],
      [-212, -570],
      [-427, -426],
      [-37, -37],
      [-30, -28],
      [-401, -366],
      [-421, -384],
      [-3, -1],
      [-41, -15],
      [-46, -16],
      [-397, -347],
      [-500, -437],
      [-128, -111],
      [-52, -47],
      [-321, -307],
      [-37, -36],
      [-324, -310],
      [-3, -2],
      [-180, -119],
      [-1, 0],
      [-5, -5],
      [-322, -293],
      [-696, -635],
      [-54, -49],
      [-3, -3],
      [-78, -103],
      [-65, -84],
      [-117, -71],
      [-147, -88],
      [-129, -78],
      [-120, -72],
      [-166, -100],
    ],
    [
      [32276, 36713],
      [-131, 157],
      [-155, 390],
      [-45, 48],
      [-64, -59],
      [59, 64],
      [-201, 216],
      [-318, 317],
      [-371, 353],
      [-499, 307],
      [-326, 176],
      [-419, 346],
      [-199, 174],
      [-419, 271],
      [-241, 79],
      [-408, 109],
      [-101, 75],
      [-5, 3],
      [-161, 49],
      [-262, 4],
      [-316, 51],
    ],
    [
      [27694, 39843],
      [-113, 18],
      [-85, 144],
      [-118, 205],
      [-56, 280],
      [-315, 453],
      [-215, 431],
      [-201, 72],
      [-358, 332],
      [-299, 209],
      [-227, 85],
      [-334, 135],
      [-352, 305],
      [-153, 57],
      [-245, 248],
      [56, 121],
      [-31, 612],
      [348, 333],
      [113, 745],
      [263, 444],
      [87, 433],
      [-57, 605],
      [-85, 544],
      [-187, 554],
      [-133, 498],
      [-347, 610],
      [-269, 364],
      [-218, 275],
      [-143, 303],
    ],
    [
      [24020, 49258],
      [109, 58],
      [34, 40],
      [48, 60],
      [104, 188],
      [71, 230],
      [57, 230],
      [77, 209],
      [152, 201],
      [110, 202],
      [82, 46],
      [169, 65],
      [191, 160],
      [143, 120],
      [136, 113],
      [62, 94],
      [42, 135],
      [17, 162],
      [-51, 212],
      [397, 407],
      [38, 278],
      [23, 176],
      [117, 174],
      [-52, 137],
      [10, 217],
      [24, 197],
      [103, 140],
      [9, 136],
      [110, 195],
      [98, 229],
      [70, 135],
      [210, 139],
      [164, 153],
      [151, 201],
      [258, 159],
      [117, 140],
      [189, 78],
      [229, 30],
      [86, -130],
      [78, -117],
      [65, -157],
      [59, -116],
      [29, -259],
      [16, -258],
      [55, -341],
      [23, -285],
      [156, -329],
      [118, -192],
      [230, -302],
      [280, -148],
      [350, 14],
      [418, 89],
      [289, 1],
      [424, 20],
      [303, 22],
      [61, -70],
    ],
    [
      [12846, 6889],
      [-17, 101],
      [-120, 70],
      [-53, 48],
      [-107, 43],
      [-60, 48],
      [-80, 69],
      [-146, 71],
      [-80, 69],
      [-59, 69],
      [-47, 49],
      [-66, 28],
      [-107, 29],
      [-109, 54],
    ],
    [
      [11795, 7637],
      [-18, 9],
      [-127, 56],
      [-87, 36],
      [-36, 9],
      [-117, 61],
      [-17, 238],
      [-24, 150],
      [-30, 218],
      [-71, 232],
      [4, 217],
      [44, 244],
      [126, 316],
      [22, 82],
      [136, 119],
      [256, 23],
      [162, 72],
      [96, 94],
      [109, 73],
      [150, 133],
      [-36, 265],
      [30, 183],
      [-91, 219],
      [24, 230],
      [29, 142],
      [62, 94],
      [84, 189],
      [102, 66],
      [384, 89],
      [572, 10],
      [261, -45],
      [249, 16],
      [103, 113],
      [53, 36],
    ],
    [
      [14096, 2651],
      [1, -4],
      [1, -3],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [-3, 34],
      [-105, 229],
      [-169, 220],
      [-359, 265],
      [-148, 109],
      [-127, 93],
      [-315, 140],
      [-118, 118],
      [-663, 616],
      [-436, 497],
      [-391, 442],
      [-19, 21],
      [-415, 516],
      [-444, 511],
    ],
    [
      [9494, 5636],
      [208, 56],
      [189, 72],
      [88, 32],
      [172, 228],
      [144, 194],
      [185, 194],
      [110, 161],
      [152, 263],
      [117, 181],
      [63, 114],
      [170, 167],
      [158, 160],
      [135, 79],
      [102, 80],
      [74, 26],
      [102, 5],
      [74, -13],
      [8, 0],
      [7, -1],
      [5, -2],
      [5, 0],
      [3, 1],
      [4, 0],
      [5, 0],
      [3, 0],
      [5, 1],
      [4, 0],
      [3, 2],
      [6, 1],
    ],
    [
      [32276, 36713],
      [-28, -17],
      [-46, -27],
      [-452, -369],
      [-589, -481],
      [-349, -104],
      [-180, -54],
      [-259, -271],
      [-512, -823],
      [-795, -417],
      [-306, -516],
      [-172, -79],
      [-82, -37],
      [-37, -53],
      [-16, -22],
      [180, -384],
      [10, -22],
      [175, -182],
      [49, -14],
      [306, -86],
      [74, -81],
      [34, -135],
      [-46, -237],
      [-86, -157],
      [-307, -225],
      [-53, -88],
      [-11, -58],
      [-15, -84],
      [215, -250],
      [-6, -122],
      [-206, -340],
      [-692, -890],
      [-94, 19],
      [-175, 257],
      [-59, -142],
      [31, -216],
      [45, -310],
      [7, -43],
      [25, -62],
      [49, -121],
      [23, -18],
      [9, -8],
      [250, -203],
      [71, -153],
      [44, -97],
      [463, -221],
      [38, -88],
      [82, -194],
    ],
    [
      [21014, 30201],
      [-1, 57],
      [43, 142],
      [67, 399],
      [2, 156],
      [-91, 206],
      [-49, 265],
      [-31, 137],
      [50, 209],
      [118, 242],
      [50, 169],
      [105, 270],
      [186, 303],
      [117, 133],
      [77, 203],
      [144, 208],
      [186, 275],
      [89, 46],
      [222, 44],
      [229, 3],
      [154, -37],
      [175, -17],
      [188, -3],
      [203, 98],
      [176, 106],
      [158, 154],
      [68, 100],
      [50, 155],
      [56, 156],
      [56, 128],
      [104, 215],
      [78, 223],
      [36, 176],
      [-43, 211],
      [41, 87],
      [102, 87],
      [130, 127],
      [83, 121],
      [136, 92],
      [7, 7],
      [162, 85],
      [210, 92],
      [204, 112],
      [313, 252],
      [197, 126],
      [273, 260],
      [85, 277],
      [77, 195],
      [118, 236],
      [71, 203],
      [261, 368],
      [154, 364],
      [207, 302],
      [210, 126],
      [171, 180],
      [159, 269],
      [315, 375],
      [22, 167],
    ],
    [
      [17219, 31058],
      [-27, 270],
      [-55, 381],
      [-3, 197],
      [-15, 354],
      [-14, 367],
      [-93, 510],
      [-14, 374],
      [3, 190],
      [-76, 320],
      [-132, 159],
      [-84, 205],
      [-63, 245],
      [16, 149],
      [-14, 7],
      [42, 108],
      [97, 128],
      [103, 154],
      [136, 99],
      [163, 93],
      [251, 158],
      [143, 134],
      [56, 121],
      [29, 156],
      [-97, 212],
      [-123, 280],
      [-225, 242],
      [-4, 142],
      [16, 204],
      [-4, 136],
      [36, 162],
      [89, 87],
      [129, 120],
      [374, 204],
      [319, 225],
      [238, 118],
      [409, 421],
      [45, 223],
      [-89, 341],
      [-124, 260],
      [-178, 221],
      [-314, 107],
      [-260, 133],
      [-141, 3],
      [-290, -70],
      [-352, 271],
      [-80, 55],
      [-255, 494],
      [-24, 136],
      [28, 81],
      [42, 108],
      [-236, 377],
      [-177, 295],
      [-204, 255],
      [-168, 423],
      [-37, 198],
      [-10, 224],
      [-86, 497],
      [8, 129],
      [10, 183],
      [-30, 225],
      [-43, 272],
      [-110, 260],
      [-56, 259],
      [17, 203],
      [253, 261],
      [206, 288],
      [6, 0],
      [103, 80],
      [210, 152],
      [122, 52],
      [171, 161],
      [-37, 190],
      [6, 0],
      [-12, 102],
      [9, 109],
      [-3, 197],
      [21, 108],
      [105, 229],
      [89, 127],
      [150, 120],
    ],
    [
      [17110, 46829],
      [149, 65],
      [232, 234],
      [85, 229],
      [152, 256],
      [62, 107],
      [118, 216],
      [29, 155],
      [-17, 198],
      [-58, 129],
      [-139, 186],
      [-44, 171],
      [-38, 129],
      [-66, 69],
      [61, 157],
    ],
    [
      [17636, 49130],
      [285, 48],
      [101, 11],
      [168, 25],
      [87, -42],
      [201, -65],
      [213, -92],
      [168, -30],
      [307, -161],
      [200, -79],
      [241, -72],
      [342, -60],
      [307, -134],
      [234, -92],
      [294, -73],
      [351, 14],
      [289, 23],
      [317, 55],
      [175, 45],
      [257, 57],
      [250, 84],
      [223, 70],
      [74, 33],
      [115, 25],
      [69, 52],
      [7, 6],
      [101, 55],
      [47, 33],
      [115, 73],
      [123, 72],
      [176, 65],
      [168, 51],
      [203, 72],
      [135, 38],
      [41, 21],
    ],
    [
      [46749, 51900],
      [-25, -6],
      [-52, -12],
      [-43, -161],
      [-59, -214],
      [-16, -61],
      [-7, -25],
      [21, -135],
      [-24, -65],
      [-69, -186],
      [67, -189],
      [-39, -170],
      [-89, -226],
      [-63, -161],
      [-23, -114],
      [-7, -36],
      [-49, -249],
      [-121, -55],
      [-20, -50],
      [-5, -15],
      [0, 0],
      [0, -3],
      [-3, -100],
      [-4, -171],
      [-6, -225],
      [-118, -414],
      [-175, -612],
      [-76, -268],
      [-14, -47],
      [-148, 20],
      [-147, 20],
      [-836, 432],
      [-76, 39],
      [-182, 147],
      [-2, 1],
      [-131, 20],
      [-97, 15],
      [-6, 1],
      [-14, 6],
      [-295, 136],
      [-417, 207],
      [-34, 3],
      [-39, 4],
      [-167, -164],
      [-14, -141],
      [-8, -88],
      [-20, -207],
      [-15, -139],
      [-135, -129],
      [-10, -9],
      [31, -64],
      [40, -83],
      [35, -200],
      [-38, -190],
      [20, -154],
      [-98, -251],
    ],
    [
      [36402, 52439],
      [-26, 99],
      [-31, 120],
      [-86, 146],
      [-202, 365],
      [10, 126],
      [-200, 286],
      [-113, 152],
      [-97, 266],
      [-43, 285],
      [-5, 199],
      [-168, 352],
      [-117, 239],
      [35, 31],
      [152, 134],
      [66, 281],
      [94, 71],
      [-207, 277],
      [237, 68],
      [339, 118],
      [289, 118],
      [340, 170],
      [146, 147],
      [82, 189],
      [167, 94],
      [231, 126],
      [189, 136],
      [89, 172],
      [124, 93],
      [51, 62],
      [-17, 259],
      [66, 60],
      [-11, 162],
      [138, 85],
      [37, 98],
      [-38, 239],
      [74, 111],
      [11, 227],
      [-3, 16],
    ],
    [
      [38005, 58618],
      [155, 50],
      [419, 171],
      [144, 51],
      [187, 22],
      [475, 58],
      [230, -2],
      [272, -46],
      [128, -40],
      [131, 52],
      [415, -38],
      [279, -30],
      [381, -285],
      [322, -59],
      [357, -413],
      [275, -202],
      [265, -355],
      [280, -345],
      [154, -175],
      [443, -441],
      [265, -361],
      [565, -434],
      [151, -300],
      [246, -399],
      [237, -330],
      [281, -307],
      [109, -118],
      [212, -235],
      [237, -364],
      [299, -396],
      [209, -343],
      [314, -367],
      [207, -404],
      [89, -263],
      [11, -70],
    ],
    [
      [12834, 30737],
      [-104, 328],
      [-112, 138],
      [-26, 82],
      [-145, 179],
      [16, 176],
      [-99, 83],
      [-138, 172],
      [-43, 293],
      [-197, 309],
      [-111, 178],
      [-164, 295],
      [-170, 268],
      [-134, 464],
      [-216, 356],
      [-144, 207],
      [-174, 84],
      [-187, 78],
      [-220, 105],
      [-194, 65],
      [-113, 83],
      [-227, 93],
      [-178, 233],
      [16, 176],
      [37, 217],
      [21, 54],
      [84, 182],
      [23, 203],
      [-28, 293],
      [-46, 116],
      [-64, 184],
      [-171, 234],
      [-194, 51],
      [-222, 31],
      [-330, -49],
      [-219, 147],
      [-247, 126],
      [-199, 187],
      [-157, 274],
      [-125, 138],
      [-11, 197],
      [-51, 123],
      [-2, 28],
    ],
    [
      [7869, 37918],
      [210, 104],
      [144, 154],
      [164, 147],
      [116, 73],
      [380, 211],
      [232, 227],
      [43, 142],
      [-4, 156],
      [92, 325],
      [163, 72],
      [316, 15],
      [405, 54],
      [328, -128],
      [225, -235],
      [212, -187],
      [261, -52],
      [398, 435],
      [13, 387],
      [307, 709],
      [-35, 320],
      [149, 480],
      [-67, 436],
      [179, 275],
      [195, 425],
      [33, -7],
      [211, 112],
      [248, 376],
      [339, 552],
      [242, 399],
      [27, 45],
      [333, 198],
      [227, 288],
      [260, 302],
      [280, 267],
      [152, 215],
      [240, 308],
      [309, 342],
      [241, 362],
      [118, 236],
      [0, 439],
    ],
    [
      [15555, 46897],
      [63, -8],
      [257, 43],
      [208, -4],
      [322, -12],
      [249, -32],
      [249, 17],
      [207, -72],
    ],
    [
      [30878, 52546],
      [5, 8],
      [69, 107],
      [56, 108],
      [41, 67],
      [149, 72],
      [109, 60],
      [109, 113],
      [103, 141],
      [104, 195],
      [124, 154],
      [109, 100],
      [131, 195],
      [69, 155],
      [103, 79],
      [55, 94],
      [90, 142],
      [75, 86],
      [117, 155],
      [49, 128],
      [56, 128],
      [83, 209],
      [-3, 217],
      [6, 26],
      [24, 123],
      [-4, 163],
      [-25, 116],
      [-12, 149],
      [-5, 75],
      [-12, 82],
      [-4, 177],
      [-11, 156],
      [-91, 185],
      [-159, 165],
      [-93, 76],
      [-179, 112],
      [-100, 77],
      [-62, 42],
    ],
    [
      [32250, 58534],
      [228, -142],
      [254, -193],
      [85, -89],
      [435, -107],
      [301, -31],
      [114, -14],
      [194, -52],
      [459, -5],
      [280, -6],
      [281, 6],
      [252, 55],
      [512, 148],
      [195, 16],
      [424, 39],
      [376, 137],
      [216, 19],
      [40, 10],
      [335, 81],
      [339, 90],
      [223, 52],
      [212, 70],
    ],
    [
      [9494, 5636],
      [-72, 83],
      [-520, 328],
      [-410, 395],
      [-477, 515],
      [-92, 100],
      [-433, 700],
      [-521, 682],
      [-317, 407],
      [-334, 556],
      [-292, 672],
      [-198, 662],
      [-133, 506],
      [-145, 607],
      [-38, 551],
      [2, 57],
      [29, 650],
      [86, 345],
      [33, 815],
      [177, 493],
      [-38, 585],
      [-149, 417],
      [-325, 257],
      [-582, 187],
      [-552, 452],
      [-391, 181],
      [-170, 80],
      [-314, 121],
      [-869, -46],
      [-432, -54],
      [-501, -202],
      [-443, 35],
      [-243, 364],
      [-126, 560],
      [-395, 149],
      [-233, 575],
      [-76, 280],
      [346, 599],
      [21, 179],
      [10, 92],
      [166, 297],
      [243, 36],
      [158, 13],
      [44, 4],
      [423, -102],
      [669, -229],
      [470, -36],
      [286, 580],
      [-172, 669],
      [-186, 547],
      [-469, 552],
      [-337, 400],
      [-343, 801],
      [-112, 621],
      [-165, 565],
    ],
    [
      [17636, 49130],
      [2, 5],
      [82, 100],
      [3, 163],
      [55, 122],
      [43, 121],
      [-6, 482],
      [8, 102],
      [9, 115],
      [-71, 185],
      [-26, 41],
      [-53, 55],
      [-32, 109],
      [-26, 35],
      [-73, 82],
      [-92, 124],
      [-60, 62],
      [-59, 110],
      [-78, 110],
      [-19, 102],
      [-12, 95],
      [9, 109],
      [69, 107],
      [75, 81],
      [88, 79],
      [62, 74],
      [54, 26],
      [109, 80],
      [329, -40],
      [273, -147],
      [328, -135],
      [354, -148],
      [323, 42],
      [133, 364],
      [-63, 252],
      [-145, 200],
      [-150, 233],
      [-210, 343],
      [-28, 327],
      [78, 270],
      [-9, 265],
      [278, 178],
      [283, 29],
      [502, -144],
      [591, -51],
      [463, -35],
      [359, 163],
      [115, 67],
      [232, 199],
      [205, 193],
      [260, 302],
      [253, 233],
      [301, 320],
      [327, 267],
      [293, 246],
      [408, 312],
      [354, 286],
      [395, 292],
      [28, 40],
      [306, 260],
      [206, 233],
      [328, 341],
      [253, 294],
      [315, 335],
      [298, 178],
      [259, 199],
      [237, 91],
      [325, 151],
      [528, 208],
      [264, 208],
    ],
    [
      [7397, 37765],
      [86, -11],
      [332, 137],
      [54, 27],
    ],
    [
      [15555, 46897],
      [11, 227],
      [-171, 302],
      [-277, 291],
      [-200, 220],
      [-25, 28],
      [-203, 398],
      [-55, 327],
      [47, 434],
      [241, 295],
      [232, 227],
      [307, 253],
      [379, 150],
      [250, 50],
      [153, -104],
      [250, 84],
      [179, 241],
      [118, 257],
      [93, 311],
      [-57, 211],
      [-56, 287],
      [-281, 141],
      [-220, 132],
      [-151, 200],
      [-142, 411],
      [-49, 306],
      [-246, 174],
      [-2, 313],
      [104, 162],
      [-185, 247],
      [24, 204],
      [107, -2],
      [-65, 117],
      [-99, 151],
      [-314, 135],
      [-125, 131],
      [-44, 238],
      [-23, 246],
      [-169, 329],
      [-235, 85],
      [-338, 265],
      [-453, 347],
      [-245, 195],
      [-212, 241],
      [-202, 384],
      [-265, 304],
      [-257, 317],
      [-168, 431],
      [-34, 429],
      [-54, 375],
      [23, 140],
      [92, -74],
      [1317, 310],
      [654, 519],
      [458, 414],
      [639, 832],
      [905, 215],
      [970, -322],
      [1810, -66],
      [1473, 396],
      [428, 292],
      [624, 322],
      [422, 299],
      [-4, 605],
      [-296, 821],
      [-32, 371],
      [-30, 344],
      [715, 572],
      [513, 120],
      [481, 196],
      [548, 160],
      [74, 26],
      [466, 94],
      [377, 14],
      [202, 17],
      [416, -62],
      [548, -227],
      [812, -190],
      [630, -154],
      [568, -234],
      [726, -454],
      [374, -170],
      [606, -398],
      [693, -447],
      [208, -151],
    ],
  ],
  transform: {
    scale: [0.00003431191870942485, 0.00003393985895113396],
    translate: [7.864755309197359, 4.689025021751022],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2]],
          type: "Polygon",
          properties: {
            name: "Abi",
            id: "CR001",
            nameAbbr: "Abi",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.86545265501,
            Shape_Area: 0.02329891402,
          },
        },
        {
          arcs: [[3, 4, 5, 6, 7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Akamkpa",
            id: "CR002",
            nameAbbr: "Akamkpa",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.82174867821,
            Shape_Area: 0.43580793929,
          },
        },
        {
          arcs: [[11, 12, 13, 14, 15, 16, 17, 18, -6]],
          type: "Polygon",
          properties: {
            name: "Akpabuyo",
            id: "CR003",
            nameAbbr: "Akpabuyo",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.5492915877,
            Shape_Area: 0.06428608236,
          },
        },
        {
          arcs: [[[-15, 19]], [[-13, 20]]],
          type: "MultiPolygon",
          properties: {
            name: "Bakassi",
            id: "CR004",
            nameAbbr: "Bakassi",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.80306496655,
            Shape_Area: 0.00745234746,
          },
        },
        {
          arcs: [[21, 22, 23, 24]],
          type: "Polygon",
          properties: {
            name: "Bekwarra",
            id: "CR005",
            nameAbbr: "Bekwarra",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.67959512988,
            Shape_Area: 0.0256081316,
          },
        },
        {
          arcs: [[-8, 25, 26, -2, 27]],
          type: "Polygon",
          properties: {
            name: "Biase",
            id: "CR006",
            nameAbbr: "Biase",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.45091758696,
            Shape_Area: 0.10230997755,
          },
        },
        {
          arcs: [[28, 29, 30, 31, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Boki",
            id: "CR007",
            nameAbbr: "Boki",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.15078879929,
            Shape_Area: 0.2376633969,
          },
        },
        {
          arcs: [[34, 35, -18]],
          type: "Polygon",
          properties: {
            name: "Calabar",
            id: "CR008",
            nameAbbr: "Calabar",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.55894610263,
            Shape_Area: 0.012964537,
          },
        },
        {
          arcs: [[-35, -17, 36, 37]],
          type: "Polygon",
          properties: {
            name: "Calabar South",
            id: "CR009",
            nameAbbr: "Calabar South",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.56970994554,
            Shape_Area: 0.01478847968,
          },
        },
        {
          arcs: [[38, -4, 39, -32]],
          type: "Polygon",
          properties: {
            name: "Etung",
            id: "CR010",
            nameAbbr: "Etung",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.34160973462,
            Shape_Area: 0.07369313517,
          },
        },
        {
          arcs: [[-40, -11, 40, 41, 42, -33]],
          type: "Polygon",
          properties: {
            name: "Ikom",
            id: "CR011",
            nameAbbr: "Ikom",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.95133565787,
            Shape_Area: 0.16342995087,
          },
        },
        {
          arcs: [[43, -30, 44, 45]],
          type: "Polygon",
          properties: {
            name: "Obanliku",
            id: "CR012",
            nameAbbr: "Obanliku",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.38559693431,
            Shape_Area: 0.07891261297,
          },
        },
        {
          arcs: [[-41, -10, 46, 47, 48]],
          type: "Polygon",
          properties: {
            name: "Obubra",
            id: "CR013",
            nameAbbr: "Obubra",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.71509611308,
            Shape_Area: 0.09445768704,
          },
        },
        {
          arcs: [[-29, 49, -25, 50, -45]],
          type: "Polygon",
          properties: {
            name: "Obudu",
            id: "CR014",
            nameAbbr: "Obudu",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.93046915183,
            Shape_Area: 0.03101620862,
          },
        },
        {
          arcs: [[-7, -19, -36, -38, 51, -26]],
          type: "Polygon",
          properties: {
            name: "Odukpani",
            id: "CR015",
            nameAbbr: "Odukpani",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.03339860578,
            Shape_Area: 0.09095412918,
          },
        },
        {
          arcs: [[-50, -34, -43, 52, -22]],
          type: "Polygon",
          properties: {
            name: "Ogoja",
            id: "CR016",
            nameAbbr: "Ogoja",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.77253985704,
            Shape_Area: 0.07964491198,
          },
        },
        {
          arcs: [[-9, -28, -1, 53, -47]],
          type: "Polygon",
          properties: {
            name: "Yakurr",
            id: "CR017",
            nameAbbr: "Yakurr",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.02220548805,
            Shape_Area: 0.05460851914,
          },
        },
        {
          arcs: [[-53, -42, -49, 54, -23]],
          type: "Polygon",
          properties: {
            name: "Yala Cross",
            id: "CR018",
            nameAbbr: "Yala Cross",
            parent: "Cross River",
            parentAbbr: "CR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.24474274854,
            Shape_Area: 0.14384594992,
          },
        },
      ],
    },
  },
};

const imo = {
  type: "Topology",
  arcs: [
    [
      [20077, 9490],
      [-49, -78],
      [-212, -240],
      [-118, -202],
      [-210, -91],
      [-183, -140],
      [-191, -146],
      [-191, -200],
      [-63, -155],
      [4, -143],
      [99, -104],
      [52, -109],
      [59, -124],
      [5, -81],
      [52, -103],
      [38, -150],
      [86, -123],
      [72, -97],
      [172, -159],
      [132, -186],
      [164, -254],
      [146, -131],
      [159, -118],
      [92, -79],
    ],
    [
      [20192, 6277],
      [-132, -306],
      [-68, -67],
      [-251, -560],
      [-177, -513],
      [-123, -569],
      [-7, -21],
      [-109, -351],
    ],
    [
      [19325, 3890],
      [-116, 132],
      [-206, 146],
      [-257, 324],
      [-265, 269],
      [-192, 160],
      [-58, 190],
      [-117, 254],
      [-179, 98],
      [-254, 120],
      [-267, 126],
      [-55, 341],
      [-25, 156],
      [111, 161],
      [98, 236],
      [130, 148],
      [103, 167],
      [76, 94],
      [13, 0],
      [36, 108],
      [-58, 191],
      [-138, 199],
      [-187, 92],
      [-228, -10],
      [-183, -65],
      [-282, 26],
      [-255, 11],
      [-187, 71],
      [-180, 105],
      [-78, 144],
      [-3, 6],
      [-31, 89],
    ],
    [
      [16091, 7979],
      [-70, 198],
      [-114, 376],
      [-79, 149],
    ],
    [
      [15828, 8702],
      [230, 99],
      [367, 191],
      [115, 66],
      [184, 153],
      [209, 24],
      [94, 18],
      [102, 60],
      [96, 100],
      [132, 122],
    ],
    [
      [17357, 9535],
      [4, 4],
      [123, 87],
      [12, -82],
      [25, -102],
      [72, -97],
      [114, -22],
      [141, -3],
      [101, -8],
      [196, 51],
      [129, 73],
      [337, 28],
      [276, 36],
      [228, 3],
      [249, 9],
      [228, -11],
      [115, -8],
      [114, -2],
      [256, -1],
    ],
    [
      [19971, 12023],
      [58, -51],
      [117, -253],
      [-17, -184],
      [10, -217],
      [-62, -121],
      [-43, -183],
      [-50, -169],
      [-15, -108],
      [-29, -135],
      [112, -111],
      [134, -43],
      [153, -58],
    ],
    [
      [20339, 10390],
      [-27, -32],
      [-16, -163],
      [5, -122],
      [-23, -149],
      [-70, -216],
      [-69, -121],
      [-62, -97],
    ],
    [
      [17357, 9535],
      [-16, 39],
      [63, 141],
      [-10, 177],
      [53, -35],
      [42, 87],
      [54, 81],
      [56, 94],
      [3, 204],
      [-56, 252],
      [35, 122],
      [57, 161],
      [-19, 130],
      [-17, 136],
      [-38, 184],
      [158, 208],
      [96, 127],
      [62, 101],
      [15, 81],
      [9, 163],
      [-18, 122],
      [-59, 96],
      [8, 82],
      [25, 55],
    ],
    [
      [17860, 12343],
      [10, -2],
      [436, -34],
      [565, -3],
      [504, -22],
      [257, 131],
      [94, 92],
    ],
    [
      [19726, 12505],
      [9, -19],
      [78, -130],
      [112, -125],
      [18, -183],
      [28, -25],
    ],
    [
      [17860, 12343],
      [-57, 6],
    ],
    [
      [17803, 12349],
      [-13, 41],
      [-51, 184],
      [-104, 199],
      [58, 277],
      [77, 223],
      [78, 230],
      [131, 215],
      [245, 165],
      [291, 124],
      [238, 166],
      [205, 187],
      [192, 268],
      [200, 295],
      [-77, 239],
      [5, 292],
      [-64, 239],
      [-10, 217],
      [-71, 151],
      [-50, 238],
      [-10, 197],
      [-31, 101],
    ],
    [
      [19042, 16597],
      [93, 27],
      [183, 65],
      [257, 104],
      [47, 33],
      [68, 40],
      [61, 19],
      [115, 32],
      [88, 45],
    ],
    [
      [19954, 16962],
      [166, -97],
      [292, -249],
      [189, -363],
      [245, -283],
      [298, -249],
      [7, -1],
      [99, -117],
      [312, -249],
      [62, -95],
    ],
    [
      [21624, 15259],
      [10, -15],
      [59, -137],
      [-65, -216],
      [-204, -159],
      [-59, -346],
      [2, -299],
      [-146, -262],
      [-134, -452],
      [-186, -275],
      [-70, -162],
      [-137, -154],
      [-344, -76],
      [-321, 94],
      [-303, -295],
    ],
    [
      [20339, 10390],
      [34, -13],
      [207, -85],
      [127, -43],
      [113, -97],
      [127, -36],
      [66, -62],
      [25, -123],
      [128, -15],
      [193, -85],
      [160, -78],
      [120, -56],
      [200, -132],
      [127, -77],
      [167, -70],
    ],
    [
      [22133, 9418],
      [-148, -489],
      [-300, -634],
      [-99, -304],
      [-148, -164],
      [-174, -191],
      [-538, -446],
      [-491, -814],
      [-43, -99],
    ],
    [
      [16547, 16232],
      [-6, 2],
      [-120, 104],
      [-360, 210],
      [-151, 63],
    ],
    [
      [15910, 16611],
      [-36, 15],
      [-433, 211],
      [-207, 105],
      [-71, 151],
      [-234, 112],
      [-182, -10],
      [-183, 25],
    ],
    [
      [14564, 17220],
      [-38, 6],
      [-13, 0],
      [-97, 293],
      [74, 393],
      [-220, 126],
      [-101, 29],
      [-99, 90],
      [-133, 70],
      [-118, 179],
      [-174, 77],
      [-108, 73],
    ],
    [
      [13537, 18556],
      [36, 31],
      [170, 112],
      [264, 124],
      [198, 187],
      [305, 151],
      [338, 123],
      [196, 71],
      [310, 63],
      [223, 44],
      [201, -51],
      [273, -134],
      [165, -179],
      [83, -307],
      [25, -102],
      [66, -96],
      [50, -198],
      [153, -166],
      [-17, -217],
      [58, -157],
      [45, -89],
      [79, -97],
      [32, -88],
      [100, -70],
      [60, -59],
    ],
    [
      [16950, 17452],
      [-17, -206],
      [-135, -466],
      [-41, -68],
      [-166, -241],
      [-23, -203],
      [-21, -36],
    ],
    [
      [17532, 21896],
      [120, -460],
      [249, -446],
      [363, -386],
      [136, -356],
      [110, -259],
      [-325, -151],
      [-377, -7],
      [-205, -248],
      [-124, -174],
      [243, 50],
      [50, -19],
    ],
    [
      [17772, 19440],
      [-4, -2],
      [-176, -106],
      [-129, -59],
      [-2, -142],
      [-69, -101],
      [-63, -128],
      [-83, -141],
      [-63, -162],
      [-48, -94],
      [-60, -372],
      [-8, -75],
      [-53, -359],
      [-56, -142],
      [-8, -105],
    ],
    [
      [13537, 18556],
      [-131, 88],
      [-152, 212],
      [-23, 191],
      [-3, 258],
      [146, 283],
      [63, 195],
      [51, 210],
      [9, 149],
      [-19, 55],
      [-31, 170],
      [-86, 90],
      [-133, 118],
      [-133, 117],
      [-56, 110],
    ],
    [
      [13039, 20802],
      [-28, 55],
      [-63, 293],
      [-23, 211],
      [57, 195],
      [49, 156],
      [77, 161],
      [-67, 47],
    ],
    [
      [13041, 21920],
      [161, 7],
      [672, -59],
      [543, -145],
      [137, -41],
      [586, -176],
      [596, -173],
      [642, 138],
      [690, 247],
      [464, 178],
    ],
    [
      [22695, 11605],
      [-361, 221],
      [-969, 66],
      [64, 131],
      [-1458, 0],
    ],
    [
      [21624, 15259],
      [24, 5],
      [73, -62],
      [99, -77],
      [34, -27],
      [138, -200],
      [119, -90],
      [41, -15],
    ],
    [
      [22152, 14793],
      [-137, -175],
      [18, -33],
      [329, -599],
      [361, -536],
      [-96, -502],
      [-34, -475],
      [112, -545],
      [-10, -323],
    ],
    [
      [15828, 8702],
      [-65, 125],
      [-211, 234],
      [-93, 97],
      [-199, 166],
      [-174, 23],
      [-114, 70],
      [-300, 148],
      [-133, 91],
      [-214, 51],
      [-174, 71],
      [-246, 147],
      [-281, 93],
      [-60, 7],
      [-134, 91],
      [-112, 151],
      [-85, 83],
      [-76, 92],
    ],
    [
      [13157, 10442],
      [37, 17],
      [205, 200],
      [252, 214],
      [105, 276],
      [233, 268],
      [179, 261],
      [179, 276],
      [-77, 184],
      [105, 290],
      [233, 275],
      [245, 165],
      [263, 77],
      [156, 59],
      [179, 28],
    ],
    [
      [15451, 13032],
      [216, -103],
      [491, -190],
      [97, -37],
      [204, -309],
      [400, -170],
      [418, 40],
      [230, 119],
      [296, -33],
    ],
    [
      [15451, 13032],
      [-124, 59],
      [-367, 142],
      [-247, 154],
      [-525, -59],
      [-408, 163],
      [-277, 43],
    ],
    [
      [13503, 13534],
      [9, 9],
      [-3, 177],
      [108, 93],
      [104, 174],
      [61, 41],
    ],
    [
      [13782, 14028],
      [299, 198],
      [212, 227],
      [352, 109],
      [303, 77],
      [228, -31],
      [195, -31],
      [237, 105],
      [171, 200],
      [240, 281],
      [95, 80],
      [216, 485],
      [53, 305],
      [95, 80],
      [69, 119],
    ],
    [
      [16547, 16232],
      [414, -161],
      [121, -43],
      [181, -10],
      [149, 31],
      [127, 5],
      [135, 18],
      [135, 25],
      [89, 127],
      [170, 126],
      [204, 92],
      [424, 40],
      [216, 78],
      [109, 32],
      [13, 3],
      [8, 2],
    ],
    [
      [13503, 13534],
      [-65, 10],
      [-383, -7],
      [-400, 2],
    ],
    [
      [12655, 13539],
      [-22, 67],
      [-26, 88],
      [4, 265],
      [-71, 185],
      [-57, 197],
      [36, 122],
      [-124, 260],
      [-3, 245],
      [-11, 163],
      [-45, 116],
      [-17, 204],
      [-38, 130],
      [204, 145],
      [75, 47],
      [189, 44],
      [154, 4],
      [350, 1],
      [148, -16],
      [49, -12],
    ],
    [
      [13450, 15794],
      [17, -57],
      [10, -191],
      [5, -122],
      [-8, -81],
      [0, -46],
    ],
    [
      [13474, 15297],
      [2, -212],
      [-2, -170],
      [-22, -101],
      [77, -226],
      [105, -158],
      [64, -198],
      [31, -143],
      [53, -61],
    ],
    [
      [11346, 13554],
      [216, -25],
      [532, 5],
      [410, 6],
      [151, -1],
    ],
    [
      [13157, 10442],
      [-397, -192],
      [-290, -90],
      [-317, -49],
      [-207, 78],
      [-126, 104],
      [-83, -141],
      [-29, -71],
    ],
    [
      [11708, 10081],
      [-7, 329],
      [-145, 213],
      [-395, 109],
      [-287, 79],
      [-283, 19],
      [-423, 34],
      [-343, -21],
      [-431, -67],
      [-43, 49],
    ],
    [
      [9351, 10825],
      [24, 26],
      [102, 66],
      [77, 189],
      [2, 115],
      [54, 33],
      [82, 128],
      [28, 67],
      [44, 189],
      [-24, 164],
      [56, 141],
      [42, 122],
      [108, 32],
      [42, 128],
      [-63, 246],
      [-65, 143],
      [-57, 205],
      [-91, 192],
      [-65, 150],
      [-1, 16],
    ],
    [
      [9646, 13177],
      [360, 134],
      [494, 223],
      [438, 67],
      [408, -47],
    ],
    [
      [19325, 3890],
      [-54, -176],
      [-264, -947],
      [-126, -698],
      [-284, -953],
      [-18, -42],
      [-192, -444],
      [-105, -178],
      [-147, 12],
      [-223, -118],
      [-408, -224],
      [-432, -122],
      [-584, 92],
      [-334, 148],
      [-269, 18],
      [-428, 171],
      [-163, 35],
      [-5, 1],
      [-342, 74],
      [-511, -18],
      [-492, 2],
      [-244, -112],
      [-431, -26],
      [-365, -123],
      [-190, -99],
      [-283, -63],
      [-439, -87],
      [-584, 119],
      [-261, 79],
      [-58, 11],
    ],
    [
      [11089, 222],
      [56, 259],
      [66, 324],
      [113, 345],
      [-167, 478],
      [-116, 341],
      [113, 324],
      [-17, 218],
      [76, 163],
      [-162, -105],
    ],
    [
      [11051, 2569],
      [197, 170],
      [206, 221],
      [273, 260],
      [237, 105],
      [159, 262],
      [25, 339],
      [18, 284],
      [-147, 78],
      [-37, 104],
    ],
    [
      [11982, 4392],
      [94, 38],
      [338, 136],
      [197, 133],
      [244, 138],
      [70, 155],
      [55, 74],
      [137, 181],
      [185, 173],
      [131, 229],
      [104, 202],
      [37, 202],
      [130, 127],
      [49, 101],
      [108, 93],
      [190, 85],
      [162, 5],
      [214, -24],
      [194, -72],
      [241, -38],
      [310, -12],
      [228, 23],
      [137, 147],
      [123, 114],
      [97, 147],
      [103, 168],
      [56, 135],
      [79, 298],
      [70, 216],
      [10, 162],
      [10, 231],
      [6, 20],
    ],
    [
      [13210, 16498],
      [20, -31],
      [112, -97],
      [-16, -176],
      [80, -63],
      [-37, -202],
      [72, -104],
      [9, -31],
    ],
    [
      [11346, 13554],
      [-11, 60],
      [-74, 416],
      [-150, 260],
      [-114, 484],
      [16, 136],
      [-55, 320],
      [-92, 178],
      [-105, 131],
      [-197, 322],
      [-246, 174],
      [-57, 225],
      [165, 221],
      [85, 216],
      [95, 107],
      [89, 87],
      [271, 131],
      [102, 73],
      [22, 14],
    ],
    [
      [11090, 17109],
      [43, -151],
      [64, -225],
      [111, -158],
      [79, -137],
      [121, -23],
      [121, 32],
      [129, 52],
      [142, 52],
      [121, 32],
      [95, 32],
      [142, 32],
      [114, 25],
      [94, -8],
      [134, -64],
      [100, -49],
      [78, -137],
      [79, -117],
      [115, 59],
      [177, 106],
      [61, 36],
    ],
    [
      [15910, 16611],
      [-118, -106],
      [-243, -64],
      [-42, -108],
      [-8, -81],
      [38, -116],
      [-63, -155],
      [-95, -87],
      [-183, -85],
      [-276, -16],
      [-155, -4],
      [-229, -3],
      [-80, 22],
      [-141, 30],
      [-155, -73],
      [-190, -98],
      [-95, -25],
      [-34, -20],
      [-163, -120],
      [-204, -205],
    ],
    [
      [13210, 16498],
      [129, 76],
      [243, 98],
      [157, 133],
      [237, 98],
      [297, 103],
      [218, 153],
      [73, 61],
    ],
    [
      [22695, 11605],
      [-4, -132],
      [71, -641],
      [-27, -435],
      [-54, -161],
      [-123, -366],
      [-417, -427],
      [-8, -25],
    ],
    [
      [9351, 10825],
      [-99, -108],
      [-257, -77],
      [-203, -98],
      [-264, -64],
      [-256, -77],
      [-111, -41],
    ],
    [
      [8161, 10360],
      [-99, -16],
      [-175, -24],
      [-175, -11],
      [-132, 138],
      [-86, 96],
      [-131, 220],
      [-177, 295],
      [-131, 165],
      [-185, 221],
      [-236, 377],
      [-265, 262],
      [-179, 133],
      [-306, 229],
      [-381, 129],
      [-550, 77],
      [-106, 22],
      [-330, 67],
      [-436, 68],
      [-349, -1],
      [-484, 2],
      [-356, 54],
      [-327, 141],
      [-100, 42],
      [-444, -5],
      [-141, -18],
      [-323, 25],
      [-296, -8],
      [-229, -30],
      [-188, 23],
      [-32, 4],
    ],
    [
      [812, 13037],
      [258, 1262],
      [29, 543],
      [199, 330],
      [311, 42],
      [345, 116],
      [327, 240],
      [234, 342],
      [256, 424],
      [253, 254],
      [172, 255],
      [329, 355],
      [171, 194],
      [299, 185],
      [341, 145],
      [120, 51],
      [289, -39],
      [421, -143],
      [344, -219],
      [62, -40],
      [481, -232],
      [294, -161],
      [234, -79],
      [251, -57],
    ],
    [
      [6832, 16805],
      [-18, -44],
      [116, -308],
      [-17, -217],
      [35, -347],
      [121, -389],
      [149, -356],
      [177, -288],
      [315, -182],
    ],
    [
      [7710, 14674],
      [71, -42],
      [144, -253],
      [237, -303],
      [424, -408],
      [258, -262],
      [307, -155],
      [490, -76],
      [5, 2],
    ],
    [
      [8161, 10360],
      [16, 2],
      [87, -22],
      [9, -292],
      [-112, -290],
      [-165, -235],
      [-121, -391],
      [-92, -291],
      [0, -427],
      [34, -374],
      [152, -180],
      [219, -180],
      [235, -31],
      [287, -127],
      [131, -179],
      [224, -269],
      [16, -312],
      [224, -269],
      [417, -394],
      [262, -59],
      [226, -146],
      [257, -290],
      [89, -313],
      [-152, -297],
      [-21, -434],
      [-55, -522],
      [120, -504],
      [102, -321],
      [73, -503],
      [19, -75],
      [-38, -258],
      [74, -1],
      [190, 99],
      [169, 85],
      [14, 9],
    ],
    [
      [11089, 222],
      [-693, 138],
      [-616, 187],
      [-364, -62],
      [-645, 66],
      [-183, -5],
      [-733, -20],
      [-687, -49],
      [-552, 2],
      [-495, 138],
      [-511, 56],
      [-359, 263],
      [-286, 211],
      [-372, 312],
      [-316, 427],
      [-211, 697],
      [-101, 180],
      [-180, 321],
      [-162, 95],
      [-112, 66],
      [-347, 149],
      [-244, 310],
      [-124, 654],
      [74, 455],
      [153, 323],
      [285, 539],
      [61, 502],
      [51, 652],
      [73, 332],
      [58, 719],
      [-135, 390],
      [-74, 402],
      [-159, 180],
      [-522, 131],
      [-316, 60],
      [-392, -116],
      [-344, -62],
      [-445, -94],
      [-452, -47],
      [-462, 131],
      [-248, 79],
      [-2, 306],
      [70, 168],
      [742, 3629],
    ],
    [
      [19954, 16962],
      [-160, 92],
      [-252, 208],
      [-259, 215],
      [-273, 141],
      [-171, 274],
      [-156, 335],
      [-24, 177],
      [-84, 212],
      [-56, 239],
      [-98, 185],
      [-126, 138],
      [-313, 182],
      [-210, 80],
    ],
    [
      [17532, 21896],
      [166, 62],
      [492, 60],
      [52, -11],
      [55, -11],
      [198, -269],
      [291, -304],
      [400, -217],
      [401, -171],
      [563, -132],
      [668, -331],
      [430, -55],
      [454, -164],
      [200, -132],
      [73, -49],
      [214, -507],
      [226, -643],
      [-11, -700],
      [-17, -604],
      [57, -627],
      [178, -662],
      [-1, -503],
      [-14, -483],
      [-14, -461],
      [-96, -74],
      [-304, -62],
      [-41, -53],
    ],
    [
      [11090, 17109],
      [-8, 26],
      [3, 163],
      [-7, 7],
      [-11, 136],
      [-90, 253],
      [-69, 300],
      [16, 163],
      [-32, 129],
    ],
    [
      [10892, 18286],
      [47, -14],
      [347, -135],
      [199, 207],
      [178, 187],
      [132, 283],
      [111, 201],
      [10, 238],
      [16, 169],
      [16, 143],
      [4, 271],
      [71, 189],
      [30, 224],
      [137, 174],
      [151, 147],
      [109, 93],
      [136, 99],
      [88, 40],
      [60, -2],
      [242, -4],
      [63, 6],
    ],
    [
      [10892, 18286],
      [-227, 72],
      [-163, -45],
      [-288, 12],
      [-288, 59],
      [-154, 57],
      [-87, 60],
    ],
    [
      [9685, 18501],
      [-13, 10],
      [-171, 199],
      [-220, 154],
      [-152, 172],
      [-79, 96],
      [-79, 92],
    ],
    [
      [8971, 19224],
      [124, 409],
      [-15, 360],
      [163, 494],
      [132, 335],
      [22, 56],
      [307, 280],
      [529, 215],
      [425, 102],
      [574, 140],
      [669, 138],
      [51, 12],
      [570, 133],
      [519, 22],
    ],
    [
      [7710, 14674],
      [12, 14],
      [48, 74],
      [177, 139],
      [82, 60],
      [122, 93],
      [129, 86],
      [379, 109],
      [80, -22],
      [128, -2],
      [149, 99],
      [96, 107],
      [83, 175],
      [83, 141],
      [-4, 129],
      [63, 176],
      [42, 142],
      [36, 94],
      [29, 149],
      [90, 175],
      [57, 162],
      [62, 135],
      [30, 155],
      [62, 142],
      [130, 127],
      [-207, 57],
      [-168, 37],
      [-154, 44],
      [-113, 90],
      [-155, -25],
      [-234, 52],
      [-100, 63],
      [-19, 68],
      [-12, 75],
      [96, 120],
      [49, 108],
      [265, 165],
      [115, 100],
      [190, 72],
      [135, 38],
      [122, 104],
    ],
    [
      [6832, 16805],
      [44, -10],
      [965, 195],
      [154, 357],
      [291, 505],
      [201, 404],
      [229, 425],
      [244, 505],
      [11, 38],
    ],
    [
      [12160, 7898],
      [-258, -37],
      [-275, 32],
      [-305, 277],
      [-137, 274],
      [24, 230],
      [-64, 205],
      [106, 331],
      [174, 343],
      [214, 356],
      [69, 172],
    ],
    [
      [11708, 10081],
      [1, -10],
      [157, -268],
      [71, -191],
      [253, -127],
      [220, -153],
      [279, -154],
      [254, -133],
      [85, -171],
      [-99, -250],
      [-281, -321],
      [-128, -79],
      [-203, -65],
      [-123, -92],
      [-34, -169],
    ],
    [
      [11982, 4392],
      [-149, 408],
      [116, 127],
      [-55, 368],
      [83, 514],
      [187, 364],
      [192, 261],
      [235, 349],
      [166, 337],
      [16, 169],
      [-96, 260],
      [-166, 118],
      [-141, 64],
      [-212, 153],
      [2, 14],
    ],
  ],
  transform: {
    scale: [0.00003431199193518002, 0.00003393910943515813],
    translate: [6.624204004365042, 5.179409191599192],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Aboh-Mbaise",
            id: "IM001",
            nameAbbr: "Aboh-Mbaise",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.64607479896,
            Shape_Area: 0.01360196892,
          },
        },
        {
          arcs: [[6, 7, -6, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Ahiazu-Mbaise",
            id: "IM002",
            nameAbbr: "Ahiazu-Mbaise",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.39330140881,
            Shape_Area: 0.00827809713,
          },
        },
        {
          arcs: [[-10, 11, 12, 13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Ehime-Mbano",
            id: "IM003",
            nameAbbr: "Ehime-Mbano",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.4897469913,
            Shape_Area: 0.01222647772,
          },
        },
        {
          arcs: [[-1, -8, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Ezinihitte Mbaise",
            id: "IM004",
            nameAbbr: "Ezinihitte Mbaise",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.37760076849,
            Shape_Area: 0.00803408554,
          },
        },
        {
          arcs: [[18, 19, 20, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Ideato South",
            id: "IM005",
            nameAbbr: "Ideato South",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.35642341593,
            Shape_Area: 0.00694086642,
          },
        },
        {
          arcs: [[23, 24, -22, 25, 26, 27]],
          type: "Polygon",
          properties: {
            name: "Ideato North",
            id: "IM006",
            nameAbbr: "Ideato North",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.6614738634,
            Shape_Area: 0.01515873286,
          },
        },
        {
          arcs: [[28, -11, -16, 29, 30]],
          type: "Polygon",
          properties: {
            name: "Ihitte/Uboma",
            id: "IM007",
            nameAbbr: "Ihitte/Uboma",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.39698525112,
            Shape_Area: 0.00579196221,
          },
        },
        {
          arcs: [[-12, -9, -5, 31, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Ikeduru",
            id: "IM008",
            nameAbbr: "Ikeduru",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.49974492296,
            Shape_Area: 0.01459470487,
          },
        },
        {
          arcs: [[-34, 34, 35, 36, 37, -13]],
          type: "Polygon",
          properties: {
            name: "Isiala Mbano",
            id: "IM009",
            nameAbbr: "Isiala Mbano",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.57978202544,
            Shape_Area: 0.01490271065,
          },
        },
        {
          arcs: [[38, 39, 40, 41, -36]],
          type: "Polygon",
          properties: {
            name: "Isu",
            id: "IM010",
            nameAbbr: "Isu",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.22701891226,
            Shape_Area: 0.00287040684,
          },
        },
        {
          arcs: [[42, -39, -35, -33, 43, 44, 45, 46]],
          type: "Polygon",
          properties: {
            name: "Mbatoli",
            id: "IM011",
            nameAbbr: "Mbatoli",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.57971439429,
            Shape_Area: 0.01557662717,
          },
        },
        {
          arcs: [[47, 48, 49, 50, -3]],
          type: "Polygon",
          properties: {
            name: "Ngor-Okpala",
            id: "IM012",
            nameAbbr: "Ngor-Okpala",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.99481844169,
            Shape_Area: 0.04926284099,
          },
        },
        {
          arcs: [[51, -40, -43, 52, 53]],
          type: "Polygon",
          properties: {
            name: "Njaba",
            id: "IM013",
            nameAbbr: "Njaba",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.42875000822,
            Shape_Area: 0.00656724559,
          },
        },
        {
          arcs: [[-20, 54, -41, -52, 55]],
          type: "Polygon",
          properties: {
            name: "Nkwerre",
            id: "IM014",
            nameAbbr: "Nkwerre",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.25812701491,
            Shape_Area: 0.00318872281,
          },
        },
        {
          arcs: [[-42, -55, -19, -37]],
          type: "Polygon",
          properties: {
            name: "Nwangele",
            id: "IM015",
            nameAbbr: "Nwangele",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.31141098016,
            Shape_Area: 0.00446489002,
          },
        },
        {
          arcs: [[-17, -7, -29, 56]],
          type: "Polygon",
          properties: {
            name: "Obowo",
            id: "IM016",
            nameAbbr: "Obowo",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.32336043978,
            Shape_Area: 0.006158705,
          },
        },
        {
          arcs: [[-46, 57, 58, 59, 60, 61]],
          type: "Polygon",
          properties: {
            name: "Oguta",
            id: "IM017",
            nameAbbr: "Oguta",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.90436070731,
            Shape_Area: 0.03902219199,
          },
        },
        {
          arcs: [[62, -49, 63, -59]],
          type: "Polygon",
          properties: {
            name: "Ohaji/Egbema",
            id: "IM018",
            nameAbbr: "Ohaji/Egbema",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.49923211418,
            Shape_Area: 0.09357127079,
          },
        },
        {
          arcs: [[-30, -15, 64, -24, 65]],
          type: "Polygon",
          properties: {
            name: "Okigwe",
            id: "IM019",
            nameAbbr: "Okigwe",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.72205816065,
            Shape_Area: 0.02170966523,
          },
        },
        {
          arcs: [[-26, -21, -56, -54, 66, 67]],
          type: "Polygon",
          properties: {
            name: "Orlu",
            id: "IM020",
            nameAbbr: "Orlu",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.48021272356,
            Shape_Area: 0.01055794651,
          },
        },
        {
          arcs: [[-68, 68, 69, 70, -27]],
          type: "Polygon",
          properties: {
            name: "Orsu",
            id: "IM021",
            nameAbbr: "Orsu",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.4448175993,
            Shape_Area: 0.01113555749,
          },
        },
        {
          arcs: [[-67, -53, -47, -62, 71, -69]],
          type: "Polygon",
          properties: {
            name: "Oru East",
            id: "IM022",
            nameAbbr: "Oru East",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.59410974818,
            Shape_Area: 0.0116495417,
          },
        },
        {
          arcs: [[-61, 72, -70, -72]],
          type: "Polygon",
          properties: {
            name: "Oru West",
            id: "IM023",
            nameAbbr: "Oru West",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.45519674389,
            Shape_Area: 0.00747933341,
          },
        },
        {
          arcs: [[73, 74]],
          type: "Polygon",
          properties: {
            name: "Owerri Municipal",
            id: "IM024",
            nameAbbr: "Owerri Municipal",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.21459074235,
            Shape_Area: 0.00278231319,
          },
        },
        {
          arcs: [[-4, -51, 75, -75, -44, -32]],
          type: "Polygon",
          properties: {
            name: "Owerri North",
            id: "IM025",
            nameAbbr: "Owerri North",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.66180311034,
            Shape_Area: 0.01615790428,
          },
        },
        {
          arcs: [[-74, -76, -50, -63, -58, -45]],
          type: "Polygon",
          properties: {
            name: "Owerri West",
            id: "IM026",
            nameAbbr: "Owerri West",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.81223171857,
            Shape_Area: 0.02473323605,
          },
        },
        {
          arcs: [[-23, -25, -65, -14, -38]],
          type: "Polygon",
          properties: {
            name: "Unuimo",
            id: "IM027",
            nameAbbr: "Unuimo",
            parent: "Imo",
            parentAbbr: "IM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.36661099286,
            Shape_Area: 0.00696723199,
          },
        },
      ],
    },
  },
};

const kogi = {
  type: "Topology",
  arcs: [
    [
      [39290, 28888],
      [-334, -167],
      [-367, -271],
      [-677, -281],
      [-650, -281],
      [-375, -333],
      [-530, -330],
      [-434, -251],
      [-496, -303],
      [-247, -329],
      [-592, -404],
      [-489, -311],
      [-367, -238],
      [-320, -252],
      [-382, -354],
      [-989, -519],
      [-486, -494],
      [-173, -323],
      [-8, -47],
      [-81, -433],
      [-330, -490],
      [-210, -533],
      [-119, -290],
      [-57, -230],
      [-36, -148],
      [-23, -139],
    ],
    [
      [30518, 21137],
      [-8, -51],
      [-43, -210],
      [-36, -155],
      [-131, -202],
      [-164, -146],
      [-487, -182],
      [-519, -66],
      [-550, 57],
      [-248, 38],
      [0, 7],
      [-301, 100],
      [-408, 123],
      [-531, 450],
      [-563, 526],
      [-705, 494],
      [-428, 136],
      [-615, 221],
      [-477, 8],
      [-529, 138],
      [-351, -123],
      [-284, -70],
      [-7, 1],
      [-100, -5],
      [-167, 70],
      [-238, 256],
      [13, 108],
    ],
    [
      [22641, 22660],
      [224, 30],
      [554, 154],
      [420, 210],
      [382, 332],
      [333, 239],
      [357, 21],
      [466, 135],
      [286, -195],
      [542, -152],
      [290, 90],
      [461, 189],
      [569, 255],
      [642, 261],
      [275, -52],
      [379, 149],
      [359, 177],
      [191, 167],
      [246, 247],
      [141, 432],
      [135, 384],
      [254, 363],
      [408, 312],
      [364, 428],
      [231, 200],
      [457, 406],
      [577, 343],
      [424, 427],
      [603, 282],
      [333, 225],
      [145, 249],
      [321, 327],
      [311, 484],
      [170, 187],
      [128, 398],
      [296, 436],
      [118, 202],
      [180, 123],
    ],
    [
      [35213, 31125],
      [201, -204],
      [577, -98],
      [664, -100],
      [469, -110],
      [26, -55],
      [727, 42],
      [225, -201],
      [492, -361],
      [397, -394],
      [205, -628],
      [94, -128],
    ],
    [
      [38605, 13005],
      [-235, 181],
      [-169, 322],
      [-15, 361],
      [-76, 321],
      [-266, 181],
      [-179, 180],
      [-378, 312],
      [-204, -98],
      [-384, -62],
      [-290, -35],
      [-177, -167],
      [-257, -57],
      [-253, -247],
      [-206, 180],
      [-378, -116],
      [-379, -129],
      [-223, -57],
      [-318, -70],
      [-250, -57],
      [-214, 31],
      [-150, 316],
      [-161, 458],
      [136, 99],
      [-24, 144],
      [-180, 132],
      [-166, 132],
      [-134, 444],
      [-5, 136],
      [-90, 246],
      [-18, 5],
    ],
    [
      [32962, 16091],
      [3, 4],
      [21, 20],
      [105, 269],
      [3, 218],
      [42, 101],
      [37, 176],
      [44, 277],
      [-69, 260],
      [-56, 333],
      [-16, 224],
      [-95, 355],
      [-103, 321],
      [-122, 321],
      [-138, 213],
      [-232, 180],
      [-139, 159],
      [-271, 283],
      [-157, 308],
      [-197, 241],
      [-331, 359],
      [-226, 139],
      [-160, 105],
      [-314, 121],
      [-73, 59],
    ],
    [
      [39290, 28888],
      [189, -257],
      [221, -431],
      [648, -724],
      [496, -477],
      [349, -227],
    ],
    [
      [41193, 26772],
      [14, -187],
      [-208, -852],
      [-317, -456],
      [-562, -1090],
      [-689, -647],
      [-46, -773],
      [15, -720],
      [101, -837],
      [110, -687],
      [39, -503],
      [13, -152],
    ],
    [
      [39663, 19868],
      [26, -331],
      [17, -252],
      [15, -713],
      [-24, -685],
      [114, -851],
      [-123, -921],
      [-335, -822],
      [-56, -94],
      [-237, -539],
      [-236, -472],
      [-186, -730],
      [-33, -453],
    ],
    [
      [72125, 19181],
      [85, -5],
      [999, 180],
      [696, -223],
      [-168, -860],
      [-581, -575],
      [-219, -615],
      [-24, -263],
      [-74, -829],
      [-100, -773],
      [-176, -546],
    ],
    [
      [72563, 14672],
      [-608, 348],
      [-723, 189],
      [-618, 10],
      [-459, -121],
      [-359, -143],
      [-472, -67],
      [-482, 131],
      [-567, 220],
      [-491, 35],
      [-430, 1],
      [-497, 436],
      [-953, 125],
      [-566, -99],
      [-246, -227],
      [-423, -366],
      [-400, -237],
      [-644, 79],
      [-524, 16],
      [-404, 13],
      [-766, 34],
      [-578, -24],
      [-627, -85],
      [-418, -81],
      [-193, -41],
    ],
    [
      [60115, 14818],
      [-108, 94],
      [-58, 157],
      [-18, 170],
      [51, 237],
      [50, 169],
      [136, 86],
      [243, 118],
      [311, 96],
      [270, 50],
      [223, 98],
      [245, 158],
      [266, 261],
      [37, 155],
      [62, 135],
      [125, 263],
      [25, 291],
      [73, 345],
      [-15, 333],
      [21, 106],
    ],
    [
      [62054, 18140],
      [45, 225],
      [-24, 205],
      [46, 318],
      [53, 366],
      [59, 345],
      [6, 380],
      [-6, 489],
      [-17, 591],
      [44, 49],
    ],
    [
      [62260, 21108],
      [106, 118],
      [116, 93],
      [193, 275],
      [347, 259],
      [490, 331],
      [448, 284],
      [154, 385],
      [44, 257],
      [241, 349],
      [255, 389],
      [253, 274],
      [279, 199],
      [473, 155],
      [547, 174],
      [399, 136],
      [353, 217],
      [628, 166],
      [614, 105],
      [653, 57],
      [653, 57],
      [701, 62],
    ],
    [
      [70207, 25450],
      [80, -1109],
      [139, -1002],
      [44, -1006],
      [206, -1044],
      [221, -881],
      [157, -702],
      [598, -500],
      [473, -25],
    ],
    [
      [58001, 31118],
      [-8, 0],
      [-84, -209],
      [-226, -309],
      [-165, -187],
      [-231, -159],
      [-534, -181],
      [-587, -166],
      [-535, -222],
      [-386, -190],
      [-527, -134],
      [-358, -129],
      [-615, -160],
      [-237, -138],
      [-411, -448],
      [-430, -427],
      [-382, -333],
      [-173, -323],
      [-334, -286],
      [-442, -318],
      [-508, -236],
      [-614, -98],
      [-573, -133],
      [-601, -139],
      [-268, 39],
      [-403, 40],
      [-464, -33],
      [-378, -95],
      [-213, -247],
      [-293, -287],
      [-322, -375],
      [-187, -309],
      [-280, -321],
      [-429, -292],
      [-364, -75],
      [-532, -86],
      [-384, -54],
      [-473, -148],
      [-300, -219],
      [-253, -254],
      [-395, -339],
      [-182, -432],
      [-180, -350],
      [-126, -330],
      [6, -469],
      [-56, -521],
      [14, -415],
      [32, -516],
      [-55, -481],
      [-4, -279],
      [18, -109],
      [-8, -88],
      [-15, -37],
    ],
    [
      [42546, 19111],
      [-27, -71],
      [-23, -155],
      [-332, -171],
      [-244, -98],
      [-227, 72],
      [-172, 159],
      [-17, 238],
      [-158, 200],
      [-131, 239],
      [-151, 186],
      [-186, 132],
      [-154, 37],
      [-223, -23],
      [-242, -37],
      [-370, 0],
      [-226, 49],
    ],
    [
      [41193, 26772],
      [-52, 663],
      [39, 1201],
      [134, 412],
      [242, 605],
    ],
    [
      [41556, 29653],
      [207, 134],
      [346, 211],
      [353, 191],
      [400, 251],
      [245, 145],
      [487, 216],
      [387, 184],
      [466, 161],
      [512, 100],
      [452, 47],
      [621, 172],
      [318, 83],
      [378, 129],
      [231, 132],
      [191, 289],
    ],
    [
      [47150, 32098],
      [61, 23],
      [286, 273],
      [650, 628],
      [611, 329],
      [724, 253],
      [643, 281],
      [557, 317],
      [332, 130],
      [568, 215],
      [374, 197],
      [528, 215],
      [631, 322],
      [582, 222],
      [696, 212],
      [690, 199],
      [810, 149],
      [966, 235],
      [1033, 240],
      [1062, 342],
      [592, 70],
      [318, 37],
      [647, 63],
      [122, 19],
    ],
    [
      [60633, 37069],
      [-170, -324],
      [-167, -398],
      [-120, -324],
      [-234, -356],
      [-137, -174],
      [-374, -258],
      [-208, -424],
      [-46, -298],
      [-132, -270],
      [-105, -249],
      [-159, -282],
      [-272, -200],
      [-335, -374],
      [-51, -203],
      [-90, -175],
      [-55, -515],
      [-74, -433],
      [25, -510],
      [72, -184],
    ],
    [
      [62054, 18140],
      [-19, 3],
      [-28, -81],
      [-487, -169],
      [-592, -17],
      [-734, -62],
      [-794, -61],
      [-705, 94],
      [-626, -50],
      [-504, -33],
      [-626, 4],
      [-408, 95],
      [-301, 121],
      [-267, 86],
      [-242, 11],
      [-203, -64],
      [-83, -148],
      [-144, -208],
      [-112, -270],
      [-38, -278],
      [-131, -201],
      [-105, -229],
      [-305, -198],
      [-365, -103],
      [-283, -56],
      [-647, -71],
      [-516, 125],
      [-332, 250],
      [-251, 296],
      [-269, 405],
      [-394, 550],
      [-340, 637],
      [-136, 301],
      [-98, 185],
      [-158, 200],
      [-8, 333],
      [79, 324],
      [-42, 320],
      [-158, 240],
      [-214, 65],
      [-188, -31],
      [-319, -164],
      [-109, -86],
      [-204, -146],
      [-164, -181],
      [-138, -201],
      [-199, -241],
      [-110, -175],
      [3, -230],
      [-30, -183],
      [-63, -155],
      [-38, -292],
      [-110, -174],
      [-285, -138],
      [-437, -13],
      [-450, 42],
      [-350, -8],
      [-195, 4],
      [-343, -22],
      [-338, -89],
      [-496, 29],
      [-418, -47],
      [-547, -106],
      [-581, -221],
      [-220, 133],
      [-272, 201],
      [-99, 151],
      [-145, 186],
      [-199, 146],
      [-179, 193],
      [-285, 209],
      [-140, 77],
      [-87, 8],
      [-121, 16],
      [-11, 129],
      [-55, 4],
    ],
    [
      [58001, 31118],
      [77, -198],
      [290, -365],
      [462, -911],
      [179, -200],
      [359, -658],
      [223, -330],
      [199, -173],
      [439, -740],
      [348, -529],
      [181, -458],
      [21, -768],
      [45, -571],
      [132, -980],
      [320, -596],
      [211, -669],
      [273, -561],
      [84, -239],
      [83, -266],
      [49, -314],
      [111, -191],
      [71, -171],
      [59, -103],
      [43, -19],
    ],
    [
      [40676, 7037],
      [-465, 155],
      [-689, 202],
      [-826, 148],
      [106, 226],
      [125, 256],
      [88, 427],
      [188, 370],
      [147, 358],
      [11, 354],
    ],
    [
      [39361, 9533],
      [524, -61],
      [242, -11],
      [320, -141],
      [405, -299],
      [34, -422],
      [-165, -214],
      [-259, -213],
      [65, -150],
      [-66, -332],
      [-30, -237],
      [17, -190],
      [-14, -34],
      [120, -70],
      [122, -122],
    ],
    [
      [57030, 11594],
      [11, -60],
      [-133, -371],
      [-250, -478],
      [-310, -476],
      [41, -374],
      [103, -294],
      [238, -215],
      [442, -130],
      [295, -59],
      [476, -103],
      [315, -39],
      [253, -161],
      [95, -71],
    ],
    [
      [58606, 8763],
      [-238, -245],
      [-105, -50],
      [-31, -15],
      [-21, -11],
      [-101, -49],
      [-189, -57],
      [-116, -39],
      [-203, -71],
      [-216, -72],
      [-169, -65],
      [-178, -201],
      [-137, -126],
      [-138, -209],
      [-206, -288],
      [-179, -207],
      [-41, -48],
      [-20, -48],
      [-84, -195],
      [-138, -181],
      [-307, -294],
      [-316, -382],
      [-274, -301],
      [-384, -469],
      [-307, -240],
      [-200, -343],
      [-735, -524],
      [-206, -77],
      [-147, -55],
      [-47, -17],
      [-292, -165],
      [-340, -253],
      [-313, -212],
      [-265, -179],
      [-246, -193],
      [-224, -159],
      [-111, -189],
      [-170, -153],
      [-185, -173],
      [-225, -167],
      [-211, -207],
      [-139, -249],
      [-144, -187],
      [-219, -248],
      [-288, -294],
      [-158, -215],
      [-137, -141],
      [-157, -146],
      [-198, -194],
      [-325, -131],
      [-256, -29],
      [-328, 162],
      [-157, 281],
      [-163, 295],
      [-279, 243],
      [-24, 140],
      [-351, 23],
      [18, 313],
      [27, 372],
      [-18, 157],
      [-2, 50],
      [-7, 255],
      [-68, 389],
      [-48, 354],
      [-53, 408],
      [-102, 409],
      [-115, 328],
      [-290, 371],
      [-257, 371],
      [-675, 209],
      [-392, 319],
      [-169, 356],
      [97, 175],
      [196, 78],
      [71, 216],
      [-120, 63],
      [-416, 27],
      [-301, 87],
      [-178, -173],
      [-333, -192],
      [-263, -56],
      [-172, 152],
      [-280, 154],
      [-797, 163],
      [-749, 257],
      [-932, 193],
      [-264, 88],
    ],
    [
      [39361, 9533],
      [2, 54],
      [136, 507],
      [-61, 443],
      [-54, 375],
      [-4, 211],
      [16, 214],
    ],
    [
      [39396, 11337],
      [64, 179],
      [417, 428],
      [82, 66],
      [69, 121],
      [85, 270],
      [126, 317],
      [126, 303],
      [160, 344],
      [52, 304],
      [59, 346],
      [33, 339],
      [156, 99],
      [80, -83],
      [294, -87],
      [236, -377],
      [311, -270],
      [313, -223],
      [708, -277],
      [627, -323],
      [490, -62],
      [675, -256],
      [353, -156],
      [607, -336],
      [592, -383],
      [779, -48],
      [707, 97],
      [675, 131],
      [406, 142],
      [546, 120],
      [172, 255],
      [45, 277],
      [-15, 300],
      [5, 346],
      [-9, 264],
      [104, 175],
      [88, 33],
      [427, -198],
      [327, -155],
      [467, -238],
      [624, -45],
      [544, -16],
      [365, 89],
      [463, -56],
      [490, -42],
      [363, 0],
      [436, -82],
      [414, -177],
      [368, -522],
      [538, -396],
      [433, -238],
      [704, -162],
      [453, 120],
    ],
    [
      [22362, 25845],
      [-213, 21],
      [-304, -137],
      [-887, -379],
      [-745, -347],
      [-589, -268],
      [-487, -281],
    ],
    [
      [19137, 24454],
      [-351, 339],
      [-291, 284],
      [-213, 147],
      [-386, 271],
      [-338, 312],
      [-171, 302],
      [-304, 298],
      [-52, 81],
      [-106, 166],
      [-329, 434],
      [12, 306],
      [11, 319],
      [98, 223],
      [28, 475],
      [50, 244],
      [24, 190],
      [111, 208],
      [-180, 112],
      [-322, 67],
      [-329, 33],
      [-350, -8],
      [-277, -43],
      [-244, -111],
      [-256, -23],
      [-249, -29],
      [-243, -51],
      [-203, -64],
      [-250, -64],
      [-290, -56],
      [-163, 23],
      [-287, 39],
      [-287, 148],
      [-186, 100],
    ],
    [
      [12814, 29126],
      [435, 158],
      [165, 215],
      [217, 552],
      [79, 291],
      [101, 440],
      [-66, 510],
      [-63, 252],
      [-121, 416],
      [-51, 218],
      [-29, 106],
    ],
    [
      [13481, 32284],
      [141, 117],
      [164, 153],
      [103, 168],
      [184, 146],
      [218, 207],
      [351, 496],
      [438, 461],
      [169, 500],
      [339, 591],
      [442, 726],
      [210, 519],
      [77, 189],
      [57, 189],
      [426, 556],
      [710, 687],
      [417, 462],
      [451, 440],
      [528, 215],
      [216, 45],
    ],
    [
      [19122, 39151],
      [-3, -198],
      [149, -342],
      [81, -382],
      [417, -828],
      [415, -530],
      [329, -868],
      [33, -483],
      [42, -706],
      [-222, -872],
      [-160, -724],
      [-250, -919],
      [-187, -784],
      [-176, -500],
      [-262, -382],
      [-167, -350],
      [294, -555],
      [-13, -380],
      [625, -839],
      [273, -215],
      [354, -108],
      [315, -107],
      [296, -352],
      [148, -437],
      [118, -185],
      [37, -205],
      [-30, -176],
      [89, -340],
      [286, -196],
      [286, -222],
      [123, -121],
    ],
    [
      [27418, 29815],
      [-63, -73],
      [-212, -180],
      [-278, -199],
      [-205, -193],
      [-186, -275],
      [-84, -216],
      [-78, -250],
      [-27, -394],
      [-73, -372],
      [45, -557],
      [69, -307],
      [1, -326],
      [-103, -181],
      [-326, -205],
      [-132, 152],
      [-288, 79],
      [-301, 94],
      [-302, 39],
      [-356, 20],
      [-477, 14],
      [-399, -136],
      [-325, -150],
      [-318, -144],
      [-204, -112],
      [-398, -102],
      [-36, 4],
    ],
    [
      [19122, 39151],
      [499, 106],
      [344, 69],
      [78, 263],
      [-121, 430],
      [-68, 381],
      [-24, 598],
      [-12, 102],
      [118, 201],
      [172, 228],
      [-3, 245],
      [-9, 265],
      [-23, 278],
      [53, 380],
      [54, 379],
      [133, 378],
      [249, 383],
      [94, 467],
      [46, 359],
      [-122, 368],
      [-223, 310],
      [-5, 3],
    ],
    [
      [20352, 45344],
      [-33, 153],
      [-49, 293],
      [-28, 333],
      [79, 332],
      [65, 318],
      [82, 257],
    ],
    [
      [20468, 47030],
      [39, -5],
      [471, 40],
      [324, 83],
      [628, 118],
      [93, 345],
      [230, 452],
    ],
    [
      [22253, 48063],
      [9, -12],
      [155, -3],
      [154, -37],
      [202, -30],
      [216, 71],
      [190, 139],
      [150, 99],
      [250, 132],
      [269, -25],
      [282, -32],
      [550, -77],
      [362, -47],
      [471, 19],
      [967, -505],
      [715, -705],
      [312, -250],
      [397, -366],
      [623, -554],
      [443, -504],
      [563, -512],
      [342, -440],
      [570, -967],
      [475, -551],
      [345, -692],
      [243, -405],
      [277, -310],
      [461, -598],
      [432, -714],
      [12, -564],
      [-31, -237],
      [-317, -463],
      [-103, -140],
      [-54, -47],
      [-141, -351],
      [-441, -725],
      [-138, -602],
      [-262, -423],
      [49, -286],
      [237, -758],
      [197, -316],
      [277, -710],
      [207, -554],
      [-116, -480],
      [-483, -365],
      [-494, 225],
      [-598, 398],
      [-369, 468],
      [-626, 798],
      [-446, 320],
      [-537, 444],
      [-418, 353],
      [-272, 236],
      [-584, 471],
      [-696, 637],
      [-340, 175],
      [-254, 113],
      [-361, 88],
      [-527, -147],
      [-273, -281],
      [-120, -344],
      [69, -293],
      [77, -259],
      [169, -336],
      [145, -165],
      [355, -502],
      [23, -265],
      [42, -299],
      [83, -307],
      [-10, -177],
      [83, -293],
      [213, -133],
      [213, -146],
      [126, -50],
      [308, -107],
      [335, -94],
      [312, -256],
      [411, -313],
      [-16, -183],
      [395, -557],
      [-157, -567],
      [77, -212],
      [-78, -277],
      [-25, -237],
      [-69, -155],
      [82, -293],
      [-42, -142],
      [-73, -339],
      [-90, -161],
      [-81, -53],
      [-115, -134],
    ],
    [
      [41556, 29653],
      [38, 96],
      [178, 1029],
      [26, 767],
      [30, 203],
      [-85, 1413],
      [-74, 1258],
      [-25, 536],
      [27, 855],
      [-295, 1295],
      [-178, 621],
      [-229, 452],
      [-184, 241],
      [-184, 241],
      [-391, 387],
      [-230, 350],
      [-441, 523],
      [-165, 634],
      [-282, 419],
      [-188, 889],
    ],
    [
      [38904, 41862],
      [199, -4],
      [170, -69],
      [91, -37],
      [102, 100],
      [55, 67],
      [56, 142],
      [106, 290],
      [16, 120],
      [45, 341],
      [51, 355],
      [17, 120],
      [12, 30],
      [114, 287],
      [118, 223],
      [124, 188],
      [121, 266],
      [5, 10],
      [90, 196],
      [53, 71],
      [37, 50],
      [281, -195],
      [51, -35],
      [132, -186],
      [138, 28],
      [78, 16],
      [-1, 85],
      [-1, 194],
      [-48, 262],
      [-27, 147],
      [0, 129],
      [0, 292],
      [106, 297],
      [230, 98],
      [198, 119],
      [292, 165],
      [74, 26],
      [100, -63],
      [69, 31],
      [5, 2],
      [211, 112],
      [47, 37],
      [171, 136],
      [100, 58],
      [185, 107],
      [96, 311],
      [46, 149],
      [53, 76],
      [106, 152],
      [93, 330],
      [13, 49],
      [7, 15],
      [91, 214],
      [21, 244],
      [84, 13],
      [262, -18],
      [296, -53],
      [48, 1],
      [248, 7],
      [208, -24],
      [3, 4],
      [17, 23],
      [223, 58],
      [60, 7],
      [338, 40],
      [362, -74],
      [444, -55],
      [451, 12],
      [306, -9],
      [164, -6],
      [272, 179],
      [127, 14],
      [261, 28],
      [-4, -14],
      [-164, -630],
      [-118, -609],
      [-243, -526],
      [-221, -343],
      [-262, -390],
      [0, -408],
      [131, -227],
      [43, -252],
      [210, -323],
      [226, -167],
      [219, -201],
      [102, -355],
      [74, -15],
      [409, -523],
      [33, -457],
      [-61, -454],
      [275, -501],
      [-2, -496],
      [-88, -447],
      [99, -573],
      [-122, -453],
      [-116, -542],
      [-79, -318],
      [0, 13],
      [-237, -933],
      [43, -735],
      [-40, -788],
      [-18, -700],
      [-760, -796],
      [-399, -1006],
      [-125, -1071],
      [43, -701],
      [325, -264],
      [400, 146],
    ],
    [
      [35213, 31125],
      [-37, 37],
      [-164, 227],
      [48, 47],
      [-292, 256],
      [-426, -176],
      [-344, -55],
      [-575, -187],
      [-377, -82],
      [-385, -109],
      [-387, -204],
      [-441, -243],
      [-353, -205],
      [-292, -198],
      [-488, -230],
      [-258, -138],
      [-365, -183],
      [-327, -212],
      [-433, -224],
      [-384, -13],
      [-187, 64],
      [-186, 132],
      [-400, 252],
      [-199, 98],
      [-129, -5],
      [-148, -45],
      [-266, 86],
    ],
    [
      [22253, 48063],
      [13, 26],
      [159, 664],
      [81, 46],
      [166, 249],
      [-58, 633],
      [181, 370],
      [39, 360],
      [105, 624],
      [-32, 537],
      [63, 550],
      [129, 555],
      [101, 372],
      [134, 426],
      [100, 358],
      [119, 297],
      [240, 676],
      [177, 575],
      [88, 433],
      [138, 243],
      [245, 179],
      [288, 308],
      [256, 444],
      [139, 276],
      [92, 135],
      [380, -55],
      [342, -94],
      [314, -121],
      [434, -198],
      [419, -313],
      [454, -239],
      [466, -320],
      [257, -331],
      [259, -222],
      [391, -354],
      [285, -310],
      [105, -186],
      [144, -213],
      [3, -4],
      [7, -11],
      [2, -4],
      [17, -24],
      [133, -203],
      [42, -63],
      [128, -193],
      [69, -103],
      [90, -106],
      [161, -190],
      [43, -134],
      [50, -153],
      [2, -3],
      [1, -4],
      [0, -7],
      [192, -187],
      [66, -155],
      [44, -105],
      [94, -457],
      [97, -253],
      [149, -384],
      [165, -587],
      [80, -296],
      [73, -271],
      [136, -342],
      [44, -134],
      [116, -352],
      [174, -478],
      [75, -355],
      [259, -202],
      [27, -21],
      [169, -116],
      [177, -121],
      [364, -209],
      [429, -246],
      [321, -176],
      [225, -203],
      [3, -3],
      [83, -112],
      [244, -316],
      [384, -367],
      [129, -103],
      [210, -168],
      [46, -64],
      [290, -404],
      [336, -421],
      [39, -46],
      [212, -251],
      [284, -338],
      [323, -420],
      [315, -542],
      [282, -420],
      [449, -544],
      [317, -339],
      [9, -10],
      [500, -583],
      [368, -221],
      [85, -52],
      [306, -5],
    ],
    [
      [13481, 32284],
      [-72, 263],
      [-178, 220],
      [-385, 339],
      [-506, 274],
      [-154, 43],
      [-591, 486],
      [-86, 83],
      [-71, 163],
      [-222, 425],
      [-111, 633],
      [-14, 374],
      [-110, 1509],
      [102, 481],
      [10, 223],
      [193, 310],
      [240, 267],
      [425, 502],
      [172, 275],
      [349, 388],
      [161, 357],
      [151, 249],
      [52, 250],
      [12, 380],
      [179, 235],
      [253, 280],
      [279, 233],
      [300, 253],
      [286, 212],
      [354, 286],
      [199, 228],
      [185, 220],
      [549, 303],
      [434, 210],
      [326, 239],
      [306, 185],
      [271, 111],
      [406, 176],
      [386, 190],
      [379, 137],
      [406, 155],
      [413, 177],
      [385, 129],
      [439, 121],
      [419, 170],
      [258, 151],
      [109, 87],
      [-17, 78],
    ],
    [
      [60115, 14818],
      [-313, -66],
      [-289, -15],
      [-257, -111],
      [-231, -159],
      [-392, -102],
      [-200, -302],
      [-284, -572],
      [-23, -529],
      [-38, -285],
      [-179, -302],
      [-157, -154],
      [-219, -227],
      [-205, -200],
      [-245, -186],
      [-53, -14],
    ],
    [
      [39396, 11337],
      [27, 376],
      [-58, 117],
      [-165, 193],
      [-102, 355],
      [-43, 293],
      [-226, 160],
      [-224, 174],
    ],
    [
      [25892, 19910],
      [-11, -4],
      [-258, -186],
      [-224, -71],
      [-547, -174],
      [-258, -97],
      [-250, -125],
      [-434, -169],
      [-90, -162],
      [-240, 113],
      [-261, 93],
      [-277, -36],
      [-227, 85],
      [-152, 153],
      [-40, 21],
      [-314, 195],
      [-43, 246],
      [-90, 253],
      [5, 319],
      [-4, 191],
      [108, 406],
      [154, 364],
      [89, 107],
      [85, 208],
    ],
    [
      [22613, 21640],
      [349, -70],
      [178, -145],
      [321, -108],
      [-107, -180],
      [250, -73],
      [142, 0],
      [0, -72],
      [36, -216],
      [250, -72],
      [428, 72],
      [321, -181],
      [214, -324],
      [143, -217],
      [36, -144],
      [428, -72],
      [107, 72],
      [183, 0],
    ],
    [
      [22641, 22660],
      [-268, -36],
      [-674, -124],
      [-270, -43],
      [-48, -11],
    ],
    [
      [21381, 22446],
      [167, 390],
      [-12, 129],
      [-125, 193],
      [-326, 168],
      [-196, -37],
      [-217, -64],
      [-121, -32],
      [-290, -43],
      [-339, 258],
      [-25, 47],
      [-171, 323],
      [61, 47],
      [-139, 138],
      [-232, 221],
      [-279, 270],
    ],
    [
      [32962, 16091],
      [-150, 39],
      [-20, 0],
      [-106, 97],
      [-105, 151],
      [-31, 579],
      [-180, 139],
      [-62, 307],
      [-139, 152],
      [-70, 232],
      [-53, 123],
      [-289, -15],
      [-155, -24],
      [-201, 37],
      [-377, 0],
      [-181, 77],
      [-222, -43],
      [-125, -202],
      [-96, 11],
      [-172, 21],
      [-405, -48],
      [-243, -77],
      [-148, 2],
      [-267, 134],
      [-191, 262],
      [-209, 418],
      [-258, 249],
      [-257, -29],
      [-37, 163],
      [-192, 194],
      [-73, 76],
      [-186, 133],
      [-106, 103],
      [-304, -35],
      [-126, 131],
      [-123, 233],
      [-277, 359],
      [-59, 170],
      [-152, 105],
      [-245, -152],
      [-204, -153],
      [-274, -100],
    ],
    [
      [22613, 21640],
      [48, 116],
      [75, 87],
      [3, 163],
      [-131, 213],
      [-109, -39],
      [-142, -58],
      [-304, -63],
      [-470, 42],
      [-217, 310],
      [15, 35],
    ],
    [
      [72563, 14672],
      [-107, -333],
      [-321, -1157],
      [-308, -736],
      [-665, -779],
      [-55, -65],
      [-838, -631],
      [-604, -336],
      [-1406, -668],
      [-58, -28],
      [-963, -452],
      [-448, -686],
      [51, -993],
      [-213, -1111],
      [-827, 1],
      [-638, 500],
      [-385, -48],
      [-389, 462],
      [-539, 404],
      [-662, 698],
      [-392, 278],
      [-42, -18],
      [-32, -164],
      [-3, -20],
      [-7, 7],
      [-27, -13],
      [-27, -20],
      [-34, -13],
      [-21, 4],
      [32, 127],
      [-22, 19],
      [-126, 110],
      [-409, 510],
      [-203, -44],
      [-233, 134],
      [-281, 106],
      [-175, 3],
      [-253, 161],
      [-365, -68],
      [-479, -101],
      [-169, -51],
      [-547, -140],
      [-152, -202],
      [-204, -193],
      [-103, -74],
      [-89, -113],
      [-184, -140],
      [-35, -36],
    ],
    [
      [60633, 37069],
      [802, 122],
      [848, 19],
      [745, -87],
      [939, -234],
      [988, -64],
      [1263, -144],
      [1377, -62],
      [-121, -477],
      [-34, -869],
      [157, -717],
      [-58, -1107],
      [159, -961],
      [512, -831],
      [373, -700],
      [714, -753],
      [558, -485],
      [342, -877],
      [33, -322],
      [72, -713],
      [-9, -1012],
      [-94, -1229],
      [8, -116],
    ],
    [
      [12814, 29126],
      [-334, 181],
      [-307, 148],
      [-396, 88],
      [-196, -17],
      [-280, 148],
      [-407, 211],
      [-295, 86],
      [-235, 4],
      [-272, 250],
      [-126, 138],
      [-298, 284],
      [-173, 132],
      [-80, 42],
      [-250, -57],
      [-70, -10],
      [-51, -8],
      [-154, 43],
      [-341, 170],
      [-278, 290],
      [-250, 364],
      [-109, 315],
      [-38, 544],
      [-227, 555],
      [0, 394],
      [39, 346],
      [186, 262],
      [178, 208],
      [211, 159],
      [202, 445],
      [302, 355],
      [359, 178],
      [271, 124],
      [180, 262],
      [-48, 388],
      [-264, 331],
      [-462, 117],
      [-195, 37],
      [-242, -16],
      [-189, -10],
      [-209, -38],
      [-258, -30],
    ],
    [
      [7708, 36539],
      [11, 36],
      [29, 149],
      [-47, 435],
      [-55, 347],
      [-33, 476],
      [-132, 566],
      [21, 441],
      [-33, 476],
      [81, 440],
      [242, 437],
      [193, 302],
      [317, 483],
      [120, 331],
      [268, 389],
      [299, 579],
      [268, 382],
      [57, 610],
      [-66, 918],
      [-2, 699],
      [-78, 619],
      [-65, 531],
      [-181, 472],
      [-36, 299],
      [-111, 185],
      [-1, 373],
      [75, 488],
      [-88, 348],
      [2, 536],
      [51, 344],
    ],
    [
      [8814, 49230],
      [380, -208],
      [381, -109],
      [811, -204],
      [394, -238],
      [464, 5],
      [403, -47],
      [395, -150],
      [433, -259],
      [469, -103],
      [445, 47],
      [384, 27],
      [572, 31],
      [343, -6],
      [209, -17],
      [222, -18],
      [319, -257],
      [255, -31],
      [822, 108],
      [303, -26],
      [719, -26],
      [160, -104],
      [200, -99],
      [705, -134],
      [416, -62],
      [383, -34],
      [201, -58],
      [228, -58],
      [555, -159],
      [83, -11],
    ],
    [
      [5547, 38075],
      [253, 217],
      [101, 468],
      [-159, 581],
      [-35, 286],
      [-191, 275],
      [-528, 220],
      [-579, 444],
      [-708, 271],
      [-426, 286],
      [-54, 240],
      [-9, 40],
      [-746, 488],
      [-126, 125],
      [-265, 249],
      [-64, 239],
      [28, 75],
      [-46, 48],
      [-160, 111],
      [-465, 389],
      [-461, 613],
      [-188, 459],
      [-405, 748],
      [-109, 189],
      [-205, 360],
      [82, 467],
      [171, 195],
      [362, 381],
      [474, 576],
      [477, 787],
      [340, 640],
      [262, 431],
      [706, 783],
      [137, 123],
      [648, 584],
      [255, 424],
      [475, 236],
      [403, 354],
      [368, 319],
      [243, 102],
      [22, 9],
      [182, 65],
      [154, -84],
      [43, -211],
      [277, -372],
      [57, -226],
      [304, -351],
      [245, -270],
      [326, -209],
      [452, -355],
      [433, -293],
      [495, -165],
      [340, -162],
      [81, -44],
    ],
    [
      [7708, 36539],
      [-93, -11],
      [-310, -29],
      [-202, -37],
      [-297, -43],
      [-358, -116],
      [-183, -64],
      [-81, -40],
      [-191, -99],
      [-400, -203],
      [-110, -141],
      [-143, -113],
      [-60, -13],
      [-147, 98],
      [-112, 117],
      [-15, 327],
      [-6, 516],
      [22, 496],
      [47, 448],
      [-36, 245],
      [162, 59],
      [318, 110],
      [34, 29],
    ],
  ],
  transform: {
    scale: [0.00003431247854173264, 0.000033939533823364556],
    translate: [5.345671859033884, 6.777374410516411],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Adavi",
            id: "KG001",
            nameAbbr: "Adavi",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.47086007264,
            Shape_Area: 0.05856073075,
          },
        },
        {
          arcs: [[4, 5, -1, 6, 7, 8]],
          type: "Polygon",
          properties: {
            name: "Ajaokuta",
            id: "KG002",
            nameAbbr: "Ajaokuta",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.51975069916,
            Shape_Area: 0.11222102625,
          },
        },
        {
          arcs: [[9, 10, 11, 12, 13, 14]],
          type: "Polygon",
          properties: {
            name: "Ankpa",
            id: "KG003",
            nameAbbr: "Ankpa",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.50471545022,
            Shape_Area: 0.10354105266,
          },
        },
        {
          arcs: [[15, 16, -8, 17, 18, 19, 20]],
          type: "Polygon",
          properties: {
            name: "Bassa",
            id: "KG004",
            nameAbbr: "Bassa",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.17712290812,
            Shape_Area: 0.15706948461,
          },
        },
        {
          arcs: [[-13, 21, -16, 22]],
          type: "Polygon",
          properties: {
            name: "Dekina",
            id: "KG005",
            nameAbbr: "Dekina",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.09516720574,
            Shape_Area: 0.20357700162,
          },
        },
        {
          arcs: [[23, 24]],
          type: "Polygon",
          properties: {
            name: "Idah",
            id: "KG006",
            nameAbbr: "Idah",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.27665220084,
            Shape_Area: 0.00389924673,
          },
        },
        {
          arcs: [[25, 26, -25, 27, 28]],
          type: "Polygon",
          properties: {
            name: "Igalamela-Odolu",
            id: "KG007",
            nameAbbr: "Igalamela-Odolu",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.05001549049,
            Shape_Area: 0.16278343964,
          },
        },
        {
          arcs: [[29, 30, 31, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Ijumu",
            id: "KG008",
            nameAbbr: "Ijumu",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.41889076054,
            Shape_Area: 0.07472098596,
          },
        },
        {
          arcs: [[34, -34, 35, 36, 37, 38]],
          type: "Polygon",
          properties: {
            name: "Kabba/Bunu",
            id: "KG009",
            nameAbbr: "Kabba/Bunu",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.55850639824,
            Shape_Area: 0.22067025041,
          },
        },
        {
          arcs: [[-19, 39, 40]],
          type: "Polygon",
          properties: {
            name: "Koton-Karfe",
            id: "KG010",
            nameAbbr: "Koton-Karfe",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.74037891989,
            Shape_Area: 0.1253002707,
          },
        },
        {
          arcs: [[-40, -18, -7, -4, 41, -39, 42]],
          type: "Polygon",
          properties: {
            name: "Lokoja",
            id: "KG011",
            nameAbbr: "Lokoja",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.57489931081,
            Shape_Area: 0.27836602585,
          },
        },
        {
          arcs: [[43, -36, -33]],
          type: "Polygon",
          properties: {
            name: "Mopa-Muro",
            id: "KG012",
            nameAbbr: "Mopa-Muro",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.20932482086,
            Shape_Area: 0.07179471389,
          },
        },
        {
          arcs: [[-12, 44, -29, 45, -9, -17, -22]],
          type: "Polygon",
          properties: {
            name: "Ofu",
            id: "KG013",
            nameAbbr: "Ofu",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.40682964804,
            Shape_Area: 0.13523788941,
          },
        },
        {
          arcs: [[46, 47]],
          type: "Polygon",
          properties: {
            name: "Ogori/Magongo",
            id: "KG014",
            nameAbbr: "Ogori/Magongo",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.36485840875,
            Shape_Area: 0.00611189426,
          },
        },
        {
          arcs: [[48, 49, -30, -35, -42, -3]],
          type: "Polygon",
          properties: {
            name: "Okehi",
            id: "KG015",
            nameAbbr: "Okehi",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.43687735254,
            Shape_Area: 0.05959420912,
          },
        },
        {
          arcs: [[-6, 50, -48, 51, -49, -2]],
          type: "Polygon",
          properties: {
            name: "Okene",
            id: "KG016",
            nameAbbr: "Okene",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.13989332222,
            Shape_Area: 0.02368385682,
          },
        },
        {
          arcs: [[52, -26, -45, -11]],
          type: "Polygon",
          properties: {
            name: "Olamaboro",
            id: "KG017",
            nameAbbr: "Olamaboro",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.4871007626,
            Shape_Area: 0.09864442664,
          },
        },
        {
          arcs: [[-23, -21, 53, -14]],
          type: "Polygon",
          properties: {
            name: "Omala",
            id: "KG018",
            nameAbbr: "Omala",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.5921259662,
            Shape_Area: 0.14317292872,
          },
        },
        {
          arcs: [[-37, -44, -32, 54, 55, 56]],
          type: "Polygon",
          properties: {
            name: "Yagba East",
            id: "KG019",
            nameAbbr: "Yagba East",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.15064669735,
            Shape_Area: 0.12415608555,
          },
        },
        {
          arcs: [[57, -56, 58]],
          type: "Polygon",
          properties: {
            name: "Yagba West",
            id: "KG020",
            nameAbbr: "Yagba West",
            parent: "Kogi",
            parentAbbr: "KG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.44287039114,
            Shape_Area: 0.09655265896,
          },
        },
      ],
    },
  },
};

const enugu = {
  type: "Topology",
  arcs: [
    [
      [22511, 2598],
      [59, -519],
      [253, -568],
      [145, -627],
      [-24, -197],
      [-299, -671],
      [-7, -16],
      [-37, 16],
      [-57, -1],
      [-168, -4],
      [-81, 22],
      [24, 102],
      [-82, 35],
      [-422, 516],
      [-516, 545],
      [-362, 40],
      [-243, 378],
      [-353, 223],
      [-339, 210],
      [-256, -16],
      [-386, -184],
      [-379, -156],
      [-505, -59],
      [-183, -63],
      [8, 237],
      [-106, 131],
      [7, 0],
      [-62, 328],
      [-260, 154],
      [-333, 230],
      [-373, 251],
      [-26, 3],
    ],
    [
      [17148, 2938],
      [-6, 245],
      [72, 311],
      [5, 292],
      [-17, 170],
      [44, 271],
      [32, 312],
      [93, 344],
      [219, 268],
      [205, 200],
      [157, 161],
      [111, 154],
      [143, 154],
      [205, 193],
      [170, 140],
      [164, 166],
      [82, 81],
      [22, 26],
    ],
    [
      [18849, 6426],
      [159, -57],
      [223, 23],
      [242, 57],
      [223, 24],
      [221, -11],
      [309, -66],
      [327, -108],
      [133, -77],
      [226, -200],
      [112, -131],
      [92, -131],
      [53, -75],
      [25, -123],
      [11, -129],
      [19, -537],
      [91, -157],
      [78, -172],
      [91, -191],
      [30, -232],
      [64, -184],
      [59, -537],
      [419, -300],
      [-63, -155],
      [65, -82],
      [0, -7],
      [53, -103],
      [139, -104],
      [181, -57],
      [80, -6],
    ],
    [
      [16672, 9276],
      [95, -92],
      [125, -199],
      [219, -214],
      [244, -296],
      [158, -166],
      [172, -199],
      [184, -275],
      [184, -241],
      [91, -225],
      [37, -157],
      [106, -131],
      [111, -165],
      [53, -116],
      [45, -110],
      [166, -91],
      [60, -69],
      [66, -82],
      [61, -22],
    ],
    [
      [17148, 2938],
      [-404, 45],
      [-846, 82],
      [-587, -78],
      [-761, -48],
      [-453, -135],
      [-256, -50],
      [-217, -112],
      [-19, 96],
      [-33, 55],
      [-32, 130],
      [-79, 49],
      [-119, 36],
      [-49, 14],
      [-147, 37],
      [-154, 29],
      [-41, 1],
      [3, 12],
    ],
    [
      [12954, 3101],
      [270, 156],
      [57, 203],
      [55, 87],
      [82, 107],
      [35, 61],
      [-18, 149],
      [30, 197],
      [38, 250],
      [-97, 253],
      [-3, 245],
      [4, 237],
      [-3, 231],
      [23, 183],
      [4, 217],
      [4, 292],
      [33, 366],
      [-104, 239],
      [-83, 239],
      [-51, 178],
      [77, 216],
      [3, 163],
      [-50, 232],
      [-96, 313],
      [-208, 58],
    ],
    [
      [12956, 7973],
      [57, 183],
      [76, 107],
      [70, 196],
      [126, 303],
      [172, 289],
      [120, 317],
      [265, 158],
      [117, 209],
      [125, 235],
      [135, 432],
      [131, 215],
      [49, 142],
      [162, 412],
      [167, 370],
      [89, 128],
      [129, 59],
      [89, 100],
      [7, -7],
      [62, 265],
    ],
    [
      [15104, 12086],
      [2, -8],
      [33, -82],
      [37, -157],
      [72, -157],
      [98, -117],
      [72, -178],
      [97, -226],
      [70, -266],
      [197, -302],
      [98, -185],
      [176, -295],
      [159, -159],
      [105, -185],
      [105, -165],
      [98, -144],
      [86, -117],
      [-7, 0],
      [70, -67],
    ],
    [
      [21141, 19966],
      [30, -287],
      [-44, -216],
      [21, -360],
      [66, -524],
      [120, -457],
      [-10, -231],
      [37, -190],
      [7, -442],
      [140, -491],
      [-69, -107],
      [4, -136],
    ],
    [
      [21443, 16525],
      [-120, 9],
      [-208, 57],
      [-221, 38],
      [-229, 31],
      [-174, 24],
      [-92, -23],
      [-299, -72],
      [-196, -17],
      [-478, -53],
      [-484, -19],
      [-519, -53],
      [-424, -19],
      [-686, -50],
      [-521, 199],
      [-296, 26],
      [-307, 100],
      [-221, 38],
      [-182, -11],
      [-190, -58],
      [-41, 43],
    ],
    [
      [15555, 16715],
      [-25, 26],
      [-46, 55],
      [-19, 41],
      [2, 136],
      [42, 135],
      [-3, 190],
      [45, 292],
      [72, 318],
      [-22, 319],
      [49, 529],
      [-53, 496],
      [96, 494],
      [-69, 320],
      [-32, 80],
    ],
    [
      [15592, 20146],
      [54, 22],
      [182, 44],
      [190, 92],
      [136, 127],
      [142, 65],
      [210, 58],
      [134, -36],
      [99, -63],
      [310, -19],
      [182, 58],
      [142, 79],
      [223, 43],
      [147, -29],
      [202, -31],
      [95, 46],
      [54, 67],
      [36, 122],
      [21, 60],
      [-17, 217],
    ],
    [
      [18134, 21068],
      [154, -29],
      [211, 126],
      [142, 38],
      [174, -30],
      [134, -16],
      [281, -107],
      [153, -97],
      [74, 12],
      [7, 0],
      [153, -105],
      [340, -168],
      [274, -121],
      [347, -114],
      [286, -216],
      [277, -275],
    ],
    [
      [21443, 16525],
      [5, -143],
      [-83, -161],
      [-3, -143],
      [11, -170],
      [-146, -269],
      [-212, -275],
      [-159, -248],
      [-213, -254],
      [-164, -181],
      [-238, -172],
      [3, -231],
      [-224, -132],
      [-164, -174],
      [-164, -153],
      [-20, -73],
    ],
    [
      [19672, 13746],
      [-124, 47],
      [-88, 0],
      [-69, 0],
    ],
    [
      [19391, 13793],
      [74, 262],
      [-254, 258],
      [-382, 514],
      [-1146, 0],
      [128, -128],
      [-1146, -129],
      [-382, 386],
      [-127, 0],
      [-255, 0],
      [-622, -236],
    ],
    [
      [15279, 14720],
      [-1, 21],
      [-6, 104],
      [81, 94],
      [24, 209],
      [-13, 442],
      [12, 26],
      [79, 176],
      [37, 258],
      [-22, 251],
      [42, 149],
      [36, 169],
      [7, 96],
    ],
    [
      [19391, 13793],
      [-195, 0],
      [-235, 0],
      [-353, -712],
      [-587, 0],
      [-1878, 474],
      [-118, 475],
      [-235, 119],
      [-507, 513],
    ],
    [
      [15283, 14662],
      [-4, 58],
    ],
    [
      [9743, 10087],
      [-182, -32],
      [-86, 76],
      [-180, 132],
      [-240, 92],
      [-155, 16],
      [-182, -31],
      [-141, 23],
      [-86, 77],
      [-61, -13],
      [-101, -73],
      [-190, -65],
      [-121, -4],
      [-167, 50],
      [-135, 2],
      [-268, 18],
      [-120, 104],
      [-193, 119],
      [-265, 229],
      [-289, 49],
    ],
    [
      [6581, 10856],
      [35, 23],
      [256, 57],
      [5, 14],
      [52, 155],
      [69, 108],
      [16, 176],
      [-85, 172],
      [-165, 220],
      [-90, 199],
      [-2, 4],
      [-125, 106],
      [-152, 180],
      [-49, 300],
      [-82, 328],
      [-58, 170],
      [-169, 323],
      [-90, 280],
      [-183, 336],
      [-250, 345],
      [-151, 260],
      [-265, 250],
      [-341, 169],
      [-469, 130],
      [-375, 81],
      [-342, 67],
      [-186, 69],
      [-102, 38],
      [-184, 255],
      [19, 394],
    ],
    [
      [3118, 16065],
      [118, -23],
      [229, 17],
      [67, -8],
      [323, 8],
      [243, 57],
      [203, 71],
      [182, 65],
      [116, 66],
      [263, 104],
      [177, 105],
      [103, 121],
      [95, 107],
      [171, 167],
      [130, 167],
      [-10, 177],
      [70, 175],
      [267, 294],
      [76, 141],
      [83, 108],
      [82, 93],
      [75, 87],
      [83, 114],
      [144, 181],
      [73, 122],
    ],
    [
      [6481, 18581],
      [22, -76],
      [97, -232],
      [-97, -169],
      [183, -288],
      [388, -156],
      [207, -92],
      [301, -79],
      [192, -167],
      [241, -85],
      [120, -43],
      [281, -120],
      [248, -38],
      [344, 68],
      [241, -51],
      [295, -73],
      [326, -182],
      [246, -174],
      [322, -67],
      [379, -264],
      [330, -359],
      [298, -324],
      [198, -194],
      [227, -146],
      [382, -448],
      [268, -66],
      [193, -118],
      [343, -454],
      [45, -117],
      [156, -362],
      [50, -232],
      [-84, -596],
      [-221, -349],
      [-141, -459],
      [-555, -194],
      [-350, -49],
      [-385, -109],
      [-346, -190],
      [-372, -110],
      [-401, -258],
      [-102, -120],
      [-170, -112],
      [-212, -187],
      [-89, -100],
      [-41, -74],
      [-83, -114],
      [-28, -88],
      [-44, -216],
      [60, -61],
    ],
    [
      [15752, 24797],
      [279, -304],
      [326, -230],
      [260, -106],
      [300, -155],
      [267, -160],
      [211, -221],
      [192, -221],
      [89, -334],
      [131, -573],
      [-32, -379],
      [-59, -257],
      [114, -477],
      [137, -281],
      [167, -31],
    ],
    [
      [15592, 20146],
      [-75, -30],
      [-20, 0],
      [-310, -63],
      [-512, -38],
      [-457, 14],
      [-180, 78],
      [-289, 19],
      [-180, 105],
      [-131, 158],
      [-179, 200],
      [-166, 132],
      [-180, 118],
      [-287, 80],
      [-344, -28],
      [-293, 174],
      [-213, 154],
      [-400, 190],
      [-171, 206],
      [-284, 311],
      [-374, 176],
      [-130, 87],
    ],
    [
      [10417, 22189],
      [-8, 43],
      [-20, 110],
      [49, 101],
      [35, 108],
      [-3, 190],
      [57, 203],
      [-26, 89],
      [1, 47],
      [81, 46],
      [102, 53],
      [189, 58],
      [257, 70],
      [264, 97],
      [250, 111],
      [71, 78],
    ],
    [
      [11716, 23593],
      [166, -7],
      [403, -7],
      [344, 49],
      [425, 101],
      [304, 90],
      [245, 233],
      [251, 84],
      [514, 243],
      [306, 191],
      [198, 187],
      [176, 45],
      [463, -1],
      [209, -11],
      [32, 7],
    ],
    [
      [17131, 34057],
      [-64, -253],
      [504, -906],
      [338, -1169],
      [448, -1020],
      [50, -63],
    ],
    [
      [18407, 30646],
      [-727, -560],
      [-299, -226],
      [-383, -360],
      [-223, -235],
    ],
    [
      [16775, 29265],
      [-125, -132],
      [-304, -90],
      [-336, 19],
      [-327, 135],
      [-341, 148],
      [-220, 140],
      [-274, 86],
      [-375, 101],
      [-272, 195],
      [-243, 385],
      [-195, 417],
      [-255, 425],
      [-190, 343],
      [-127, 443],
      [-58, 544],
      [-210, 751],
      [2, 543],
      [-134, 53],
    ],
    [
      [12791, 33771],
      [105, 50],
      [238, 245],
      [35, 36],
      [184, 139],
      [89, 114],
      [103, 73],
      [204, 194],
      [152, 201],
      [547, 140],
      [169, 52],
      [479, 100],
      [365, 69],
      [253, -161],
      [175, -3],
      [281, -106],
      [233, -134],
      [203, 44],
      [409, -510],
      [126, -111],
      [22, -18],
      [-32, -128],
    ],
    [
      [16775, 29265],
      [-140, -241],
      [-42, -47],
      [-98, -235],
      [-13, -27],
      [-164, -140],
      [-20, 27],
      [-173, -336],
      [-123, -107],
      [-60, -19],
      [-186, -241],
      [-277, -275],
    ],
    [
      [15479, 27624],
      [-22, 90],
      [-81, 348],
      [-253, 228],
      [-186, 105],
      [-158, 220],
      [-173, 91],
      [-428, 150],
      [-255, 38],
      [-223, -44],
      [-223, -77],
      [-242, -23],
      [-254, 93],
      [-261, 99],
      [-248, 25],
      [-370, -28],
      [-207, 105],
      [-220, 72],
      [-161, 43],
      [-165, 187],
      [-130, 280],
      [-43, 313],
      [27, 373],
      [24, 265],
      [72, 290],
      [32, 298],
      [38, 292],
      [-76, 293],
      [-63, 246],
      [-206, 186],
      [-178, 83],
    ],
    [
      [10846, 32265],
      [20, 48],
      [41, 47],
      [179, 208],
      [206, 288],
      [138, 209],
      [137, 126],
      [178, 201],
      [169, 65],
      [216, 71],
      [203, 72],
      [116, 38],
      [189, 58],
      [101, 49],
      [21, 10],
      [31, 16],
    ],
    [
      [27635, 26197],
      [217, -933],
      [88, -1239],
      [-301, -1171],
      [-268, -729],
      [-443, -808],
      [-63, -348],
      [-336, -98],
      [-506, -73],
      [-517, 36],
      [-457, 42],
      [-457, -26],
      [-382, 88],
      [-746, 12],
      [-476, 110],
      [-45, -304],
      [134, -444],
      [21, -41],
    ],
    [
      [23098, 20271],
      [-904, -305],
      [-1053, 0],
    ],
    [
      [15752, 24797],
      [224, 50],
      [320, 232],
      [263, 43],
      [328, -74],
      [336, -5],
      [331, 75],
      [369, 1],
      [513, 66],
      [391, 88],
      [264, 145],
      [125, 222],
      [40, 6],
      [38, 126],
    ],
    [
      [19294, 25772],
      [5, -4],
      [185, -194],
      [463, -8],
      [614, 105],
      [574, 160],
      [132, 242],
      [158, 242],
      [51, 237],
      [91, 188],
      [217, 125],
      [78, 264],
      [119, 370],
    ],
    [
      [21981, 27499],
      [230, -122],
      [569, -299],
      [989, 350],
      [561, 609],
      [477, 393],
      [4, -1],
      [1319, -165],
      [718, -549],
      [670, -1018],
      [117, -500],
    ],
    [
      [23480, 9050],
      [1, -15],
      [182, -410],
      [63, -300],
      [111, -599],
      [-17, -638],
      [39, -544],
      [207, -479],
      [64, -605],
      [-376, -822],
      [-298, -254],
      [-240, -205],
      [-630, -722],
      [-90, -121],
      [4, -638],
      [11, -100],
    ],
    [
      [16672, 9276],
      [97, -10],
      [309, -5],
      [437, -1],
      [382, -68],
      [315, -94],
      [383, -13],
      [452, 115],
      [399, 142],
      [157, 154],
      [278, 137],
      [125, 256],
      [144, 195],
      [-22, 312],
      [84, 189],
      [-48, 381],
      [-24, 184],
      [-40, 428],
      [-149, 315],
      [-137, 281],
      [-143, 287],
      [-131, 260],
      [-26, 28],
      [-125, 213],
      [-21, 32],
      [157, 33],
      [136, 107],
      [-43, 251],
      [32, 285],
      [22, 76],
    ],
    [
      [23098, 20271],
      [103, -199],
      [55, -320],
      [123, -355],
      [131, -178],
      [174, -492],
      [40, -422],
      [59, -497],
      [78, -646],
      [132, -97],
      [175, -451],
      [-17, -1039],
      [-4, -699],
      [-8, -468],
      [25, -564],
      [-331, -884],
      [-356, -829],
      [-260, -287],
      [-344, -911],
      [-9, -584],
      [509, -511],
      [107, -788],
    ],
    [
      [15104, 12086],
      [2, 12],
      [-75, 348],
      [-128, 355],
      [-110, 294],
      [-43, 258],
      [29, 163],
      [71, 209],
      [83, 168],
      [70, 128],
      [43, 217],
      [69, 66],
      [70, -110],
      [-17, 69],
      [1, 41],
      [-7, 0],
      [48, 87],
      [35, 88],
      [42, 108],
      [-4, 75],
    ],
    [
      [15479, 27624],
      [22, -94],
      [31, -157],
      [-17, -264],
      [66, -90],
      [340, -141],
      [296, 22],
      [276, 9],
      [568, -234],
      [323, 8],
      [355, -74],
      [328, -60],
      [285, -283],
      [369, -47],
      [175, -17],
      [218, -235],
      [180, -195],
    ],
    [
      [11716, 23593],
      [114, 123],
      [-64, 184],
      [-6, 62],
      [23, 156],
      [-234, 126],
      [-163, 301],
      [-200, 112],
      [-192, 180],
      [-72, 103],
      [-5, 122],
      [-72, 117],
      [-31, 164],
      [39, 372],
      [45, 298],
      [48, 475],
      [67, 358],
      [-31, 184],
      [-109, 287],
      [-111, 233],
      [-134, 50],
      [-167, 70],
      [-332, 244],
      [-574, 267],
      [-467, 192],
      [-271, 324],
      [-200, 125],
      [-447, 211],
      [-272, 222],
      [-3, 4],
    ],
    [
      [7895, 29259],
      [206, 77],
      [734, 524],
      [201, 343],
      [307, 240],
      [384, 469],
      [274, 301],
      [316, 382],
      [307, 294],
      [138, 181],
      [84, 195],
    ],
    [
      [9743, 10087],
      [114, -37],
      [212, -147],
      [192, -207],
      [147, -50],
      [178, -213],
      [125, -165],
      [98, -172],
      [144, -253],
      [52, -157],
      [76, -260],
      [82, -327],
      [104, -212],
      [256, 2],
      [340, 239],
      [203, 78],
      [320, -148],
      [211, 139],
      [306, -209],
      [53, -15],
    ],
    [
      [12954, 3101],
      [12, 56],
      [0, 3],
      [3, 187],
      [-133, 111],
      [-126, 111],
      [-267, 127],
      [-511, 43],
      [-435, -285],
      [-346, -171],
      [-342, 81],
      [-230, 179],
      [-109, 85],
      [-446, 320],
      [-171, 214],
      [-185, 255],
      [-14, 367],
      [-68, 355],
      [-130, 281],
      [-251, 330],
      [-143, 295],
      [-162, 411],
      [-201, 486],
      [1, 462],
      [99, 277],
      [65, 284],
      [-279, 236],
      [-349, 67],
      [-71, 212],
      [-20, -20],
      [-98, 172],
      [38, 278],
      [-49, 320],
      [-76, 267],
      [-289, 59],
      [-224, -125],
      [-239, -248],
      [-371, -141],
      [-56, -21],
      [-187, 105],
      [-151, 247],
      [19, 333],
      [192, 214],
      [24, 224],
      [-125, 193],
      [-78, 164],
      [11, 292],
      [95, 63],
    ],
    [
      [18407, 30646],
      [11, -14],
      [559, -702],
      [740, -815],
      [1131, -876],
      [558, -437],
      [575, -303],
    ],
    [
      [6481, 18581],
      [16, 26],
      [-6, 7],
      [76, 175],
      [4, 210],
      [23, 210],
      [10, 156],
      [77, 237],
      [52, 257],
      [9, 156],
      [71, 223],
      [-4, 190],
      [90, 154],
      [205, 241],
      [132, 222],
      [158, 201],
      [171, 201],
      [184, 132],
      [238, 166],
      [203, 132],
      [251, 146],
      [345, 143],
      [229, 16],
      [209, 10],
      [255, -52],
      [308, -45],
      [141, -17],
      [228, -44],
      [195, 37],
      [69, 101],
      [-3, 17],
    ],
    [
      [3118, 16065],
      [0, 7],
      [24, 52],
      [158, 359],
      [5, 306],
      [289, 396],
      [-111, 226],
      [57, 190],
      [-60, 41],
      [353, 579],
      [521, 603],
      [462, 338],
      [333, 205],
      [352, 511],
      [177, 581],
      [226, 629],
      [188, 431],
      [-169, 377],
      [-519, 356],
      [-329, 80],
      [-126, 70],
      [-362, 136],
      [-421, 170],
      [-324, -48],
      [-417, 13],
      [-227, 72],
      [-266, 222],
      [-459, 301],
      [-422, 150],
      [-389, 47],
      [-363, 13],
      [-346, -137],
      [-364, -68],
      [-319, 75],
      [-23, 6],
      [-43, 286],
      [39, 285],
      [-27, 415],
      [6, 407],
      [-15, 327],
      [-207, 193],
      [38, 184],
      [281, 281],
      [69, 161],
      [85, 223],
      [284, 498],
      [92, 332],
      [141, 378],
      [97, 216],
      [197, 112],
      [208, -38],
      [221, -85],
      [72, -151],
      [36, -39],
      [1, -50],
      [18, -157],
      [-26, -373],
      [-18, -312],
      [351, -23],
      [24, -140],
      [279, -243],
      [163, -295],
      [157, -281],
      [327, -162],
      [257, 29],
      [325, 131],
      [198, 193],
      [157, 147],
      [137, 141],
      [158, 214],
      [287, 295],
      [220, 247],
      [144, 188],
      [138, 249],
      [212, 207],
      [225, 166],
      [184, 174],
      [171, 153],
      [111, 189],
      [224, 159],
      [246, 193],
      [265, 179],
      [313, 212],
      [340, 252],
      [292, 165],
      [47, 18],
      [147, 55],
    ],
  ],
  transform: {
    scale: [0.00003431187868797065, 0.000033939499649140396],
    translate: [6.905937278843319, 5.918607979381979],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2]],
          type: "Polygon",
          properties: {
            name: "Aninri",
            id: "EN001",
            nameAbbr: "Aninri",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.69884948203,
            Shape_Area: 0.02383985772,
          },
        },
        {
          arcs: [[3, -2, 4, 5, 6, 7]],
          type: "Polygon",
          properties: {
            name: "Awgu",
            id: "EN002",
            nameAbbr: "Awgu",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.87437752646,
            Shape_Area: 0.03672779903,
          },
        },
        {
          arcs: [[8, 9, 10, 11, 12]],
          type: "Polygon",
          properties: {
            name: "Enugu East",
            id: "EN003",
            nameAbbr: "Enugu East",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.66756019071,
            Shape_Area: 0.02697493728,
          },
        },
        {
          arcs: [[13, 14, 15, 16, -10]],
          type: "Polygon",
          properties: {
            name: "Enugu North",
            id: "EN004",
            nameAbbr: "Enugu North",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.58757915665,
            Shape_Area: 0.0128766164,
          },
        },
        {
          arcs: [[17, 18, -16]],
          type: "Polygon",
          properties: {
            name: "Enugu South",
            id: "EN005",
            nameAbbr: "Enugu South",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.36353319397,
            Shape_Area: 0.00573978099,
          },
        },
        {
          arcs: [[19, 20, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Ezeagu",
            id: "EN006",
            nameAbbr: "Ezeagu",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03475316243,
            Shape_Area: 0.05030090008,
          },
        },
        {
          arcs: [[23, -12, 24, 25, 26]],
          type: "Polygon",
          properties: {
            name: "Igbo-Etiti",
            id: "EN007",
            nameAbbr: "Igbo-Etiti",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.70269481152,
            Shape_Area: 0.02835532651,
          },
        },
        {
          arcs: [[27, 28, 29, 30]],
          type: "Polygon",
          properties: {
            name: "Igbo-eze North",
            id: "EN008",
            nameAbbr: "Igbo-eze North",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.62560943233,
            Shape_Area: 0.02688930149,
          },
        },
        {
          arcs: [[31, 32, 33, -30]],
          type: "Polygon",
          properties: {
            name: "Igbo-eze South",
            id: "EN009",
            nameAbbr: "Igbo-eze South",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.68135379381,
            Shape_Area: 0.01501131102,
          },
        },
        {
          arcs: [[34, 35, -13, -24, 36, 37, 38]],
          type: "Polygon",
          properties: {
            name: "Isi-Uzo",
            id: "EN010",
            nameAbbr: "Isi-Uzo",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.21851128582,
            Shape_Area: 0.07280207998,
          },
        },
        {
          arcs: [[39, -3, -4, 40, -14, -9, -36, 41]],
          type: "Polygon",
          properties: {
            name: "Nkanu East",
            id: "EN011",
            nameAbbr: "Nkanu East",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.56854130394,
            Shape_Area: 0.0674560109,
          },
        },
        {
          arcs: [[-18, -15, -41, -8, 42]],
          type: "Polygon",
          properties: {
            name: "Nkanu West",
            id: "EN012",
            nameAbbr: "Nkanu West",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.67290028317,
            Shape_Area: 0.02245726591,
          },
        },
        {
          arcs: [[43, -37, -27, 44, 45, -33]],
          type: "Polygon",
          properties: {
            name: "Nsukka",
            id: "EN013",
            nameAbbr: "Nsukka",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.1383154578,
            Shape_Area: 0.04067895318,
          },
        },
        {
          arcs: [[46, -6, 47, -20]],
          type: "Polygon",
          properties: {
            name: "Oji-River",
            id: "EN014",
            nameAbbr: "Oji-River",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.92580249291,
            Shape_Area: 0.03034071736,
          },
        },
        {
          arcs: [[-38, -44, -32, -29, 48]],
          type: "Polygon",
          properties: {
            name: "Udenu",
            id: "EN015",
            nameAbbr: "Udenu",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.60639698286,
            Shape_Area: 0.02046017181,
          },
        },
        {
          arcs: [[-25, -11, -17, -19, -43, -7, -47, -23, 49]],
          type: "Polygon",
          properties: {
            name: "Udi",
            id: "EN016",
            nameAbbr: "Udi",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.53912519442,
            Shape_Area: 0.07532598723,
          },
        },
        {
          arcs: [[-26, -50, -22, 50, -45]],
          type: "Polygon",
          properties: {
            name: "Uzo-Uwani",
            id: "EN017",
            nameAbbr: "Uzo-Uwani",
            parent: "Enugu",
            parentAbbr: "EN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.66395960662,
            Shape_Area: 0.07351395505,
          },
        },
      ],
    },
  },
};

const anambra = {
  type: "Topology",
  arcs: [
    [
      [15170, 7941],
      [-37, -86],
      [-2, -122],
      [-16, -135],
      [5, -143],
      [18, -136],
      [11, -136],
      [-42, -149],
      [-42, -101],
      [-50, -175],
      [-63, -162],
      [-45, -51],
    ],
    [
      [14907, 6545],
      [-136, 41],
      [-543, 145],
      [-672, 60],
      [-162, -8],
      [-518, -22],
      [-570, -133],
    ],
    [
      [12306, 6628],
      [-74, 164],
      [157, 187],
      [-213, 126],
      [-179, 112],
      [-254, 147],
      [-200, 98],
      [-341, 115],
      [-355, 80],
      [-219, 147],
      [-120, 90],
      [-85, 171],
      [-6, 0],
      [-52, 117],
      [-180, 71],
      [-30, 272],
      [-24, 170],
      [-9, 251],
      [18, 33],
    ],
    [
      [10140, 8979],
      [58, 102],
      [116, 120],
      [61, 40],
      [155, 51],
      [135, -2],
      [108, 5],
      [170, 146],
      [116, 107],
      [157, 113],
      [256, 84],
      [183, 71],
      [123, 161],
      [114, 87],
    ],
    [
      [11892, 10064],
      [16, 13],
      [283, 35],
      [269, 23],
      [202, -10],
      [294, -73],
      [314, -141],
      [213, -147],
      [178, -173],
      [104, -266],
      [111, -212],
      [89, -280],
      [92, -172],
      [118, -137],
      [214, -133],
      [163, -267],
      [234, -93],
      [301, -93],
      [83, 3],
    ],
    [
      [4132, 12684],
      [123, 344],
      [74, 399],
      [-112, 614],
      [-111, 186],
      [-125, 296],
    ],
    [
      [3981, 14523],
      [202, 180],
      [37, 237],
      [39, 291],
      [-30, 245],
      [17, 244],
      [85, 271],
      [99, 269],
      [146, 283],
      [110, 175],
      [125, 262],
      [229, 431],
      [164, 208],
      [186, 268],
      [5, 312],
      [119, 263],
      [206, 275],
      [204, 166],
      [137, 154],
      [391, 489],
      [99, 283],
      [25, 79],
    ],
    [
      [6576, 19908],
      [179, 67],
      [155, 11],
      [224, 125],
      [306, 212],
      [239, 227],
      [204, 193],
      [131, 181],
      [194, 343],
      [86, 372],
      [-29, 265],
      [66, 318],
      [131, 222],
      [224, 125],
      [176, 65],
      [229, 71],
      [256, 30],
      [242, -38],
      [132, -132],
      [97, -226],
      [84, -232],
      [84, -191],
      [193, -173],
      [29, -232],
      [72, -130],
      [6, -5],
    ],
    [
      [10286, 21376],
      [6, -124],
      [-23, -176],
      [-63, -162],
      [-63, -169],
      [-50, -128],
      [-115, -72],
      [-56, -101],
      [-96, -114],
      [-9, -163],
      [18, -177],
      [-51, -182],
      [-22, -156],
      [-4, -278],
      [-31, -204],
      [-29, -135],
      [1, -380],
      [-23, -196],
      [-57, -183],
      [-17, -230],
    ],
    [
      [9602, 18046],
      [-136, -73],
      [-170, -146],
      [-245, -159],
      [-145, -215],
      [-144, -235],
      [-192, -207],
      [-117, -154],
      [-70, -148],
      [-157, -161],
      [-231, -159],
      [-252, -226],
      [-151, -167],
      [-273, -288],
      [-124, -140],
      [-258, -206],
      [-266, -206],
      [-96, -100],
      [-82, -73],
      [-88, -67],
      [-90, -154],
      [-95, -101],
      [-56, -128],
      [-2, -101],
      [-43, -149],
      [-96, -168],
      [-117, -141],
      [-84, -209],
      [-111, -208],
      [-158, -221],
      [-110, -135],
      [-123, -133],
      [-36, -34],
    ],
    [
      [5284, 13034],
      [-107, -100],
      [-35, -21],
      [-128, -77],
      [-147, 49],
      [-18, -6],
      [-239, -84],
      [-128, -11],
      [-284, -83],
      [-66, -17],
    ],
    [
      [3981, 14523],
      [-63, 149],
      [-166, 553],
      [-55, 402],
      [-110, 661],
      [-109, 362],
      [-19, 62],
      [-124, 675],
      [-57, 633],
      [-225, 677],
      [25, 272],
      [13, 380],
      [-192, 227],
      [-338, 312],
      [-146, 567],
      [-16, 646],
      [-61, 409],
      [-153, 580],
      [-115, 356],
      [-96, 307],
      [-28, 348],
      [-183, 329],
      [-131, 695],
      [-75, 320],
      [-32, 138],
      [109, 73],
      [319, 130],
      [359, 164],
      [168, -23],
      [421, -130],
      [464, -69],
      [369, -61],
      [268, -32],
      [401, 204],
      [272, 220],
      [186, 255],
      [512, 536],
      [4, 4],
      [74, 177],
      [91, 223],
      [19, 353],
      [26, 346],
      [23, 190],
      [-40, 409],
      [73, 365],
      [161, 398],
      [128, 420],
      [141, 446],
      [79, 332],
      [391, 408],
      [90, 202],
      [259, 186],
      [314, 300],
      [461, 203],
      [266, 206],
      [184, 194],
      [282, 376],
      [160, 296],
      [5, 27],
      [208, -193],
      [14, -327],
      [-6, -408],
      [27, -415],
      [-38, -285],
      [43, -286],
      [22, -5],
    ],
    [
      [8834, 29992],
      [-57, -58],
      [-169, -79],
      [103, -239],
      [44, -232],
      [132, -158],
      [-149, -412],
      [-81, -494],
      [72, -524],
      [6, -482],
      [-157, -581],
      [-116, -534],
      [-147, -371],
      [-214, -336],
      [-144, -167],
      [11, -571],
      [-10, -624],
      [-208, -356],
      [-41, -509],
      [-213, -241],
      [-42, -529],
      [-304, -503],
      [-248, -417],
      [-15, -516],
      [-174, -751],
      [-98, -276],
      [-39, -124],
    ],
    [
      [12853, 11592],
      [-75, -57],
      [-233, -302],
      [-226, -281],
      [-118, -202],
      [-124, -194],
      [-70, -162],
      [-124, -168],
      [9, -162],
    ],
    [
      [10140, 8979],
      [-171, 99],
      [-186, 125],
      [-283, 116],
    ],
    [
      [9500, 9319],
      [16, 31],
      [102, 60],
      [47, 26],
      [28, 40],
      [27, 20],
      [151, 140],
      [129, 134],
      [63, 168],
      [35, 74],
      [-30, 211],
      [78, 264],
      [44, 196],
      [-24, 224],
      [-124, 247],
      [17, 169],
      [92, 284],
      [26, 204],
    ],
    [
      [10177, 11811],
      [4, 40],
      [63, 162],
      [84, 147],
      [56, 149],
      [-62, 171],
    ],
    [
      [10322, 12480],
      [78, -29],
      [146, -104],
      [310, 90],
      [125, 188],
      [16, 176],
      [71, 250],
      [-3, 231],
      [212, 200],
      [184, 173],
      [23, 203],
      [171, 167],
      [27, 156],
    ],
    [
      [11682, 14181],
      [63, 6],
      [52, -89],
      [-15, -149],
      [-23, -163],
      [-9, -143],
      [-36, -148],
      [-37, -190],
      [-15, -135],
      [-4, -211],
      [112, -131],
      [125, -199],
      [226, -153],
      [23, -224],
      [213, -167],
      [187, -71],
      [126, -124],
      [20, -14],
      [144, -261],
      [19, -23],
    ],
    [
      [15145, 17500],
      [-94, -63],
      [-12, -292],
      [78, -165],
      [125, -192],
      [-23, -224],
      [-192, -214],
      [-19, -333],
      [151, -248],
    ],
    [
      [15159, 15769],
      [-406, -181],
      [-263, 4],
      [-181, 44],
      [-166, 105],
      [-187, 64],
      [-193, 99],
      [-317, 351],
      [-126, 125],
      [-322, 46],
      [-492, -66],
      [-237, -118],
      [-346, -185],
      [-110, -113],
      [-271, -158],
      [-196, -65],
      [-161, 12],
    ],
    [
      [11185, 15733],
      [-74, 6],
      [-107, 56],
      [-117, 212],
      [-104, 206],
      [-55, 205],
    ],
    [
      [10728, 16418],
      [-9, 33],
      [-52, 117],
      [-51, 164],
      [-37, 197],
      [-113, 104],
      [-118, 192],
      [-160, 104],
      [-166, 105],
      [-50, 266],
      [-158, 152],
      [-148, 94],
    ],
    [
      [9666, 17946],
      [-39, 25],
      [-22, 4],
      [-3, 71],
    ],
    [
      [10286, 21376],
      [160, -133],
      [176, 58],
      [217, 84],
      [271, 165],
      [278, 172],
      [115, 25],
      [279, 186],
      [167, 89],
    ],
    [
      [11949, 22022],
      [186, -69],
      [342, -67],
      [376, -81],
      [469, -131],
      [340, -169],
      [265, -249],
      [151, -261],
      [250, -344],
      [183, -337],
      [90, -280],
      [170, -322],
      [58, -171],
      [82, -328],
      [49, -300],
      [152, -179],
      [124, -106],
      [2, -5],
      [91, -199],
      [165, -220],
      [84, -171],
      [-16, -177],
      [-69, -108],
      [-52, -154],
      [-4, -15],
      [-257, -56],
      [-35, -23],
    ],
    [
      [15159, 15769],
      [187, -105],
      [56, 22],
    ],
    [
      [15402, 15686],
      [-16, -20],
      [-184, -160],
      [-77, -182],
      [-175, -391],
      [-119, -338],
      [-50, -168],
      [-24, -244],
      [-3, -157],
      [-30, -209],
      [-84, -230],
      [-111, -202],
      [-76, -161],
      [-57, -169],
      [-647, -552],
      [-66, -312],
      [-157, -139],
      [-251, -146],
      [-170, -119],
      [-252, -195],
    ],
    [
      [11682, 14181],
      [10, 61],
      [-189, 377],
      [-156, 328],
      [-35, 340],
      [-130, 274],
      [3, 172],
    ],
    [
      [11683, 22708],
      [-20, -394],
      [184, -254],
      [102, -38],
    ],
    [
      [8834, 29992],
      [319, -76],
      [365, 69],
      [345, 137],
      [363, -13],
      [390, -48],
      [421, -150],
      [460, -300],
      [265, -222],
      [228, -72],
      [417, -14],
      [324, 49],
      [421, -171],
      [361, -135],
      [127, -70],
      [328, -81],
      [519, -355],
      [169, -377],
      [-188, -432],
      [-225, -628],
      [-178, -582],
      [-351, -510],
      [-333, -205],
      [-463, -339],
      [-521, -603],
      [-352, -578],
      [59, -42],
      [-57, -189],
      [111, -226],
      [-289, -396],
      [-4, -306],
      [-159, -360],
      [-23, -52],
      [0, -7],
    ],
    [
      [8571, 13261],
      [-68, -17],
      [-161, 16],
      [-134, 37],
      [-148, 9],
      [-126, 24],
    ],
    [
      [7934, 13330],
      [27, 73],
      [159, 242],
      [-44, 218],
      [614, 586],
      [179, 222],
      [127, 371],
      [128, 432],
      [-3, 285],
      [66, 305],
      [11, 257],
      [105, 270],
      [114, 419],
      [-8, 333],
      [111, 236],
      [72, 250],
      [28, 74],
      [46, 43],
    ],
    [
      [10728, 16418],
      [-10, -7],
      [-102, -107],
      [-109, -107],
      [-144, -120],
      [-101, -66],
      [-83, -100],
      [-176, -72],
      [-129, -93],
      [-108, -46],
      [-115, -38],
      [-61, -20],
      [6, -67],
      [52, -83],
      [120, -97],
      [139, -118],
      [12, -115],
      [-2, -95],
      [-2, -95],
      [-15, -156],
      [-42, -47],
      [-54, -67],
      [-176, -38],
      [-128, -59],
      [-110, -127],
      [-164, -146],
      [72, -137],
      [145, -166],
      [-2, -129],
      [-9, -156],
      [-215, 31],
      [-146, 71],
      [-169, -52],
      [-129, -72],
      [-123, -120],
      [-50, -163],
      [-29, -178],
    ],
    [
      [6632, 7135],
      [-36, -70],
      [-44, -203],
      [-15, -115],
      [-49, -81],
      [-27, -47],
      [-102, -66],
      [-157, -113],
      [-96, -148],
      [-177, -112],
      [-156, -79],
      [-55, -2],
    ],
    [
      [5718, 6099],
      [-17, 72],
      [-115, 22],
      [-28, -2],
      [-5, 29],
      [50, 156],
      [-71, 212],
      [-37, 190],
      [3, 190],
      [23, 197],
      [-9, 210],
      [4, 326],
      [31, 230],
      [-50, 225],
      [-92, 117],
      [-78, 165],
      [-153, 104],
      [-116, 355],
      [-115, 376],
      [-150, 274],
      [-63, 245],
      [-43, 279],
      [-16, 231],
      [-45, 157],
      [35, 74],
      [29, -8],
    ],
    [
      [4690, 10525],
      [71, -21],
      [220, -119],
      [153, -131],
      [206, -133],
      [234, -72],
      [168, -37],
      [94, 26],
      [202, 10],
      [230, 71],
      [216, 84],
      [102, 46],
      [109, 80],
      [103, 127],
      [258, 179],
      [216, 44],
      [372, -118],
    ],
    [
      [7644, 10561],
      [-53, -119],
      [-11, -244],
      [-59, -325],
      [-17, -244],
      [-58, -257],
      [-29, -162],
      [-11, -244],
      [-78, -230],
      [3, -238],
      [-64, -236],
      [-164, -181],
      [-138, -187],
      [-104, -175],
      [-77, -236],
      [-77, -203],
      [-75, -145],
    ],
    [
      [8571, 13261],
      [-1, -4],
      [-35, -88],
      [-7, -54],
      [19, -75],
      [113, -63],
      [140, -50],
      [47, -28],
      [141, -3],
      [155, 52],
      [150, 99],
      [47, -14],
      [128, 5],
      [300, -128],
      [119, -158],
      [179, -146],
      [160, -91],
      [96, -35],
    ],
    [
      [10177, 11811],
      [-70, -7],
      [-167, 78],
      [-160, 57],
      [-229, -3],
      [-176, -51],
      [-143, -100],
      [-135, -99],
      [-204, -112],
      [-337, -69],
      [-344, -69],
      [-296, -8],
      [-411, -41],
      [-514, -188],
      [-221, 72],
      [-208, 17],
      [-221, 24],
      [-310, -42],
      [-195, 17],
      [-200, 85],
      [-44, 27],
    ],
    [
      [5592, 11399],
      [47, 163],
      [34, 47],
      [14, 13],
      [21, 27],
      [3, 217],
      [22, 143],
      [56, 100],
      [28, 74],
      [36, 142],
      [83, 162],
      [76, 128],
      [12, 49],
    ],
    [
      [6024, 12664],
      [83, -17],
      [260, -79],
      [216, 51],
      [262, -32],
      [297, 70],
      [401, 278],
      [299, 151],
      [92, 244],
    ],
    [
      [9500, 9319],
      [-11, 5],
      [-20, -3],
    ],
    [
      [9469, 9321],
      [-351, -45],
      [-264, 303],
      [-71, 157],
      [-87, 36],
      [3, 190],
      [-273, 160],
      [-239, 188],
      [-246, 160],
      [-281, 86],
      [-16, 5],
    ],
    [
      [4690, 10525],
      [-113, 273],
    ],
    [
      [4577, 10798],
      [-4, 11],
      [0, 305],
      [-20, 183],
      [-14, 122],
      [324, 327],
    ],
    [
      [4863, 11746],
      [92, -43],
      [181, -64],
      [93, -63],
      [147, -64],
      [113, -49],
      [103, -64],
    ],
    [
      [9728, 5685],
      [-132, -335],
      [-162, -494],
      [14, -360],
      [-123, -409],
      [-12, -38],
      [-243, -505],
      [-229, -424],
      [-202, -405],
      [-290, -505],
      [-154, -357],
      [-965, -194],
      [-45, 10],
      [-250, 56],
      [-235, 79],
      [-293, 161],
      [-481, 233],
      [-62, 40],
      [-344, 218],
      [-422, 143],
      [-288, 39],
      [-120, -51],
    ],
    [
      [4690, 2587],
      [-7, 77],
      [51, 237],
      [42, 142],
      [43, 121],
      [-91, 212],
      [-9, 299],
      [-11, 136],
      [-62, 314],
      [79, 304],
      [37, 244],
      [58, 256],
      [84, 230],
      [118, 188],
      [103, 141],
      [103, 154],
      [143, 147],
      [89, 33],
      [130, 167],
      [-8, 50],
      [90, 57],
      [46, 3],
    ],
    [
      [5718, 6099],
      [14, -58],
      [59, -137],
      [78, -124],
      [78, -150],
      [138, -199],
      [59, -137],
      [145, -125],
      [106, -151],
      [84, -198],
      [590, -105],
      [90, 168],
      [86, 344],
      [4, 238],
      [10, 237],
      [29, 136],
      [158, 174],
      [176, 98],
      [115, 46],
      [107, -22],
      [241, -106],
      [389, -54],
      [261, -66],
      [229, 23],
      [179, -132],
      [382, -102],
      [203, -12],
    ],
    [
      [9469, 9321],
      [-1, -24],
      [-130, -168],
      [-117, -147],
      [-55, -108],
      [-84, -189],
      [-143, -146],
      [-171, -133],
      [-198, -187],
      [-202, -57],
      [-184, -174],
      [-131, -147],
      [-84, -223],
      [-165, -228],
      [-144, -180],
      [-157, -174],
      [-197, -112],
      [-135, -39],
      [-121, 2],
      [-128, -4],
      [-74, 1],
      [-120, 56],
      [-78, 178],
      [-18, 17],
    ],
    [
      [12306, 6628],
      [-52, -12],
      [-668, -138],
      [-574, -140],
      [-426, -101],
      [-528, -216],
      [-307, -280],
      [-23, -56],
    ],
    [
      [4690, 2587],
      [-341, -144],
      [-299, -186],
      [-172, -194],
      [-328, -354],
      [-173, -256],
      [-253, -254],
      [-255, -424],
      [-234, -342],
      [-327, -239],
      [-345, -117],
      [-311, -42],
      [-343, -14],
      [-39, 16],
      [-345, -37],
      [-401, 136],
      [-319, 271],
      [-190, 336],
      [-15, 5],
      [13, 72],
      [10, 9],
      [-19, 13],
      [48, 111],
      [163, 79],
      [260, 274],
      [101, 386],
      [251, 594],
      [242, 430],
      [311, 451],
      [115, 507],
      [100, 352],
      [-54, 395],
      [228, 377],
      [251, 553],
      [176, 514],
      [61, 434],
      [113, 358],
      [235, 336],
      [168, 446],
      [296, 443],
      [130, 521],
      [89, 108],
      [261, 314],
      [60, 380],
      [20, 428],
      [37, 618],
      [-7, 421],
      [0, 87],
    ],
    [
      [3659, 10759],
      [125, -101],
      [655, 0],
      [138, 140],
    ],
    [
      [4863, 11746],
      [-161, 76],
      [-247, 127],
      [-7, 7],
      [-93, 28],
      [-175, 24],
      [-215, -3],
      [-152, -52],
    ],
    [
      [3813, 11953],
      [15, 49],
      [180, 282],
      [106, 352],
      [15, 40],
      [3, 8],
    ],
    [
      [5284, 13034],
      [182, -111],
      [232, -167],
      [194, -65],
      [132, -27],
    ],
    [
      [3659, 10759],
      [1, 353],
      [1, 97],
      [68, 482],
      [84, 262],
    ],
    [
      [19143, 10187],
      [-231, -183],
      [-275, 12],
      [-337, -49],
      [-382, 54],
      [-540, -99],
      [-578, -11],
      [-181, 51],
      [-147, 29],
      [-129, -18],
      [-168, -37],
      [-109, -46],
      [-183, -92],
      [-319, -239],
      [-117, -154],
      [-49, -135],
      [-97, -168],
      [-36, -156],
      [24, -177],
      [8, -332],
      [-37, -210],
      [-22, -129],
      [-68, -157],
    ],
    [
      [15402, 15686],
      [370, 141],
      [240, 247],
      [224, 125],
      [288, -59],
      [76, -266],
      [49, -321],
      [-38, -278],
      [98, -171],
      [21, 20],
      [70, -212],
      [349, -67],
      [279, -236],
      [-65, -285],
      [-99, -277],
      [0, -462],
      [201, -486],
      [161, -411],
      [144, -294],
      [250, -331],
      [130, -281],
      [68, -354],
      [15, -368],
      [184, -255],
      [172, -213],
      [445, -320],
      [109, -85],
    ],
    [
      [21518, 9744],
      [-2, -11],
      [40, -1],
      [154, -30],
      [148, -37],
      [48, -14],
      [-8, -7],
      [-352, -116],
      [-23, -10],
      [-458, -199],
      [-319, -185],
      [-442, -237],
      [-278, -185],
      [-191, -160],
      [-110, -100],
      [-192, -235],
      [-191, -153],
      [-150, -134],
      [-111, -235],
      [-104, -169],
      [-145, -221],
      [-110, -196],
      [-111, -208],
      [-16, -31],
      [-52, 11],
      [-492, -59],
      [-165, -63],
      [-465, -178],
      [-690, -246],
      [-641, -139],
      [-597, 174],
      [-586, 175],
    ],
    [
      [19143, 10187],
      [231, -180],
      [341, -80],
      [346, 171],
      [435, 284],
      [511, -42],
      [267, -127],
      [126, -111],
      [133, -111],
      [-3, -188],
      [0, -3],
      [-12, -56],
    ],
  ],
  transform: {
    scale: [0.000034312109636861194, 0.000033939279349827424],
    translate: [6.612072236843274, 5.693135366981368],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4]],
          type: "Polygon",
          properties: {
            name: "Aguata",
            id: "AN001",
            nameAbbr: "Aguata",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.49146813851,
            Shape_Area: 0.01323873331,
          },
        },
        {
          arcs: [[5, 6, 7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Anambra East",
            id: "AN002",
            nameAbbr: "Anambra East",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.8784025821,
            Shape_Area: 0.030944129,
          },
        },
        {
          arcs: [[-7, 11, 12]],
          type: "Polygon",
          properties: {
            name: "Anambra West",
            id: "AN003",
            nameAbbr: "Anambra West",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.40598920118,
            Shape_Area: 0.06049851676,
          },
        },
        {
          arcs: [[13, -4, 14, 15, 16, 17, 18]],
          type: "Polygon",
          properties: {
            name: "Anaocha",
            id: "AN004",
            nameAbbr: "Anaocha",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.48060136572,
            Shape_Area: 0.00856630801,
          },
        },
        {
          arcs: [[19, 20, 21, 22, 23, -9, 24, 25]],
          type: "Polygon",
          properties: {
            name: "Awka North",
            id: "AN005",
            nameAbbr: "Awka North",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.73188621181,
            Shape_Area: 0.03170607299,
          },
        },
        {
          arcs: [[26, 27, -19, 28, -21]],
          type: "Polygon",
          properties: {
            name: "Awka South",
            id: "AN006",
            nameAbbr: "Awka South",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.50147317367,
            Shape_Area: 0.01394722142,
          },
        },
        {
          arcs: [[29, -25, -8, -13, 30]],
          type: "Polygon",
          properties: {
            name: "Ayamelum",
            id: "AN007",
            nameAbbr: "Ayamelum",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.11896820362,
            Shape_Area: 0.04640853686,
          },
        },
        {
          arcs: [[31, 32, -23, 33]],
          type: "Polygon",
          properties: {
            name: "Dunukofia",
            id: "AN008",
            nameAbbr: "Dunukofia",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.43842675018,
            Shape_Area: 0.00476716052,
          },
        },
        {
          arcs: [[34, 35, 36, 37]],
          type: "Polygon",
          properties: {
            name: "Ekwusigo",
            id: "AN009",
            nameAbbr: "Ekwusigo",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.45258648463,
            Shape_Area: 0.00873164459,
          },
        },
        {
          arcs: [[38, -17, 39, 40, 41, -32]],
          type: "Polygon",
          properties: {
            name: "Idemili North",
            id: "AN010",
            nameAbbr: "Idemili North",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            ID_0: 164,
            ISO: "NGA",
            NAME_0: "Nigeria",
            Shape_Leng: 0.41172883581,
            Shape_Area: 0.00712984749,
          },
        },
        {
          arcs: [[-16, 42, 43, -37, 44, 45, 46, -40]],
          type: "Polygon",
          properties: {
            name: "Idemili South",
            id: "AN011",
            nameAbbr: "Idemili South",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.53205462037,
            Shape_Area: 0.00903875687,
          },
        },
        {
          arcs: [[47, 48, 49]],
          type: "Polygon",
          properties: {
            name: "Ihiala",
            id: "AN012",
            nameAbbr: "Ihiala",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.60657058567,
            Shape_Area: 0.01807518205,
          },
        },
        {
          arcs: [[-39, -34, -22, -29, -18]],
          type: "Polygon",
          properties: {
            name: "Njikoka",
            id: "AN013",
            nameAbbr: "Njikoka",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.4279398216,
            Shape_Area: 0.00765590799,
          },
        },
        {
          arcs: [[50, -38, -44]],
          type: "Polygon",
          properties: {
            name: "Nnewi North",
            id: "AN014",
            nameAbbr: "Nnewi North",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.34854711663,
            Shape_Area: 0.00527004213,
          },
        },
        {
          arcs: [[51, -50, -35, -51, -43, -15, -3]],
          type: "Polygon",
          properties: {
            name: "Nnewi South",
            id: "AN015",
            nameAbbr: "Nnewi South",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.64518941226,
            Shape_Area: 0.01372345857,
          },
        },
        {
          arcs: [[-45, -36, -49, 52, 53]],
          type: "Polygon",
          properties: {
            name: "Ogbaru",
            id: "AN016",
            nameAbbr: "Ogbaru",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.92663996278,
            Shape_Area: 0.03243177803,
          },
        },
        {
          arcs: [[-41, -47, 54, 55, -11, 56]],
          type: "Polygon",
          properties: {
            name: "Onitsha North",
            id: "AN017",
            nameAbbr: "Onitsha North",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.21145925157,
            Shape_Area: 0.00228914775,
          },
        },
        {
          arcs: [[57, -55, -46, -54]],
          type: "Polygon",
          properties: {
            name: "Onitsha South",
            id: "AN018",
            nameAbbr: "Onitsha South",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.15091458952,
            Shape_Area: 0.00139562409,
          },
        },
        {
          arcs: [[58, -5, -14, -28, 59]],
          type: "Polygon",
          properties: {
            name: "Orumba North",
            id: "AN019",
            nameAbbr: "Orumba North",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.85866501048,
            Shape_Area: 0.02966856616,
          },
        },
        {
          arcs: [[60, -1, -59, 61]],
          type: "Polygon",
          properties: {
            name: "Orumba South",
            id: "AN020",
            nameAbbr: "Orumba South",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.6370821588,
            Shape_Area: 0.01885970466,
          },
        },
        {
          arcs: [[-57, -10, -24, -33, -42]],
          type: "Polygon",
          properties: {
            name: "Oyi",
            id: "AN021",
            nameAbbr: "Oyi",
            parent: "Anambra",
            parentAbbr: "AN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.51487912051,
            Shape_Area: 0.01071261689,
          },
        },
      ],
    },
  },
};

const abia = {
  type: "Topology",
  arcs: [
    [
      [6600, 7197],
      [-43, -80],
    ],
    [
      [6557, 7117],
      [-267, 0],
      [255, 722],
      [0, 362],
      [-51, 257],
      [-252, 154],
      [-14, -2],
      [-39, 158],
      [-204, 0],
      [-457, -103],
      [-254, -568],
      [-24, -33],
      [-11, -2],
      [-101, -25],
      [-114, 2],
      [-95, -26],
      [-134, 16],
      [-67, -5],
      [-17, 8],
    ],
    [
      [4711, 8032],
      [10, 26],
      [42, 67],
      [82, 100],
      [56, 121],
      [68, 67],
      [130, 140],
      [171, 181],
      [103, 127],
      [130, 140],
      [164, 127],
      [68, 89],
    ],
    [
      [5735, 9217],
      [20, -3],
      [162, -16],
      [267, -52],
      [194, -78],
      [167, -91],
      [39, -62],
      [132, -172],
      [46, -120],
      [12, -30],
      [-10, -170],
      [-10, -271],
      [43, -211],
      [10, -225],
      [-64, -229],
      [-29, -143],
      [-110, -140],
      [-4, -7],
    ],
    [
      [6557, 7117],
      [-16, -27],
      [-69, -122],
      [-109, -86],
      [-169, -72],
      [-169, -65],
      [-230, -71],
      [-223, -70],
      [-202, -51],
      [-189, -17],
      [-181, 37],
      [-214, 24],
      [-155, 43],
      [-146, 70],
      [-85, 144],
      [-106, 131],
      [4, 224],
      [-29, 286],
      [9, 122],
    ],
    [
      [4278, 7617],
      [62, 87],
      [129, 100],
      [95, 32],
      [88, 60],
      [59, 136],
    ],
    [
      [21395, 21891],
      [28, -305],
      [259, -650],
      [109, -371],
      [166, -565],
      [111, -620],
      [344, -802],
      [336, -400],
      [470, -551],
      [186, -548],
      [171, -669],
      [-285, -579],
      [-471, 35],
      [-669, 229],
      [-422, 102],
      [-44, -3],
      [-75, 135],
      [-198, 241],
      [-378, 374],
      [-266, 222],
      [-352, 271],
      [-274, 147],
      [-536, 111],
      [-388, 143],
      [-313, 182],
      [-300, 216],
      [-298, 304],
      [-385, 320],
      [-162, 370],
      [-111, 634],
      [-243, 371],
      [-318, 332],
      [-474, 225],
      [-409, 69],
      [-718, 141],
      [-316, 39],
      [-384, -13],
      [-438, -95],
      [-115, -368],
    ],
    [
      [14233, 20565],
      [-216, 361],
      [-83, 253],
      [-112, 172],
      [-144, 219],
      [-104, 260],
      [-123, 260],
      [-4, 204],
      [170, 92],
      [223, 51],
      [271, 117],
      [190, 133],
      [198, 173],
      [124, 201],
      [-70, 226],
      [-25, 136],
      [-93, 83],
      [-60, 49],
      [-92, 89],
      [-65, 157],
      [-85, 145],
      [-39, 109],
      [-45, 123],
      [-11, 156],
      [6, 1],
    ],
    [
      [14044, 24335],
      [73, -97],
      [92, -158],
      [193, -91],
      [308, -74],
      [325, 103],
      [204, 153],
      [345, 144],
      [218, 145],
      [234, -52],
      [314, -127],
      [179, -152],
      [166, -125],
      [218, -276],
      [65, -130],
      [92, -124],
      [91, -232],
      [30, -197],
      [116, -301],
      [42, -313],
      [86, -117],
      [58, -151],
      [96, -273],
      [-2, -135],
      [94, -450],
      [73, -442],
      [127, -485],
      [83, -273],
      [30, -218],
      [64, -231],
      [169, 44],
      [223, 71],
      [177, 153],
      [223, 85],
      [413, 156],
      [312, 191],
      [344, 76],
      [317, 35],
      [571, -17],
      [78, 257],
      [85, 256],
      [-21, 395],
      [78, 236],
      [137, 174],
      [231, 123],
    ],
    [
      [14957, 29549],
      [249, -14],
    ],
    [
      [15206, 29535],
      [-3, -82],
      [63, -238],
      [112, -118],
      [52, -157],
      [28, -319],
      [-20, -400],
      [51, -212],
      [35, -306],
      [56, -272],
      [17, -231],
      [-3, -157],
      [-14, -74],
      [-52, -277],
      [-84, -203],
      [-228, -383],
      [-301, -361],
      [-240, -281],
      [-317, -497],
      [-213, -282],
      [-132, -235],
      [-1, -48],
      [-20, 1],
      [52, -68],
    ],
    [
      [14233, 20565],
      [-20, -64],
      [177, -683],
      [84, -668],
      [-12, -326],
      [-73, -345],
      [-64, -244],
      [-240, -302],
      [-109, -514],
      [-76, -133],
    ],
    [
      [13900, 17286],
      [-55, 38],
      [-171, 187],
      [-92, 144],
      [-150, 335],
      [-137, 247],
      [-57, 218],
      [-82, 341],
      [-131, 213],
      [-192, 159],
      [-173, 112],
      [-141, 36],
      [-288, 25],
      [-80, 63],
      [-180, 111],
      [-226, 147],
      [-235, 131],
    ],
    [
      [11510, 19793],
      [8, 2],
      [254, -127],
      [74, -8],
      [53, -28],
      [76, 148],
      [33, 353],
      [6, 366],
      [-37, 265],
      [-41, 347],
      [-204, 269],
      [-132, 165],
      [-407, 190],
      [-141, -4],
      [-374, 190],
      [-340, 141],
      [-252, 222],
      [-227, 153],
      [-129, 314],
      [-182, 391],
      [-110, 239],
      [-111, 246],
      [-137, 220],
      [-158, 206],
      [-65, 164],
      [-130, 294],
      [-70, 185],
      [3, 251],
      [10, 30],
    ],
    [
      [8780, 24977],
      [253, 0],
      [363, 0],
      [445, 54],
      [399, 149],
      [525, 79],
      [364, 48],
      [337, 15],
      [4, 258],
      [-23, 272],
      [56, 101],
      [-56, 320],
      [-91, 212],
      [19, 339],
      [-20, 387],
      [-126, 145],
      [-218, 207],
      [-104, 253],
      [-144, 213],
      [137, 181],
      [-118, 179],
      [28, 101],
      [97, 148],
      [-6, 434],
      [56, 196],
      [90, 134],
      [-145, 207],
      [44, 230],
      [-31, 177],
      [146, 107],
    ],
    [
      [11061, 30123],
      [266, -294],
      [658, -66],
      [753, -67],
      [591, -45],
      [815, 34],
      [671, -127],
      [142, -9],
    ],
    [
      [14235, 14758],
      [-6, -8],
      [-102, -87],
      [-155, -18],
      [-251, -70],
      [-181, 24],
      [-390, 13],
      [-558, 10],
      [-375, 122],
      [-262, 38],
      [-693, -42],
      [-312, -145],
      [-207, -322],
      [-33, -360],
      [56, -105],
    ],
    [
      [10766, 13808],
      [-63, -1],
      [-215, -37],
      [-93, -20],
    ],
    [
      [10395, 13750],
      [18, 15],
      [55, 40],
      [68, 73],
      [-26, 61],
      [-26, 69],
      [-12, 41],
      [-52, 109],
      [-33, 75],
      [-87, 29],
      [-45, 89],
      [1, 68],
      [-39, 62],
      [-67, 69],
      [-45, 102],
      [28, 75],
      [48, 67],
      [88, 59],
      [143, 66],
      [107, 18],
      [169, 11],
      [73, -42],
      [134, -9],
      [136, 66],
      [41, 67],
      [-5, 108],
      [-72, 97],
      [-106, 90],
      [-167, 64],
      [-208, 37],
      [-220, 106],
      [-140, 77],
      [-73, 103],
      [-159, 125],
      [-4, 136],
      [-25, 129],
      [9, 170],
      [35, 94],
      [43, 135],
      [156, 127],
      [96, 73],
      [75, 73],
      [23, 183],
      [16, 142],
      [4, 292],
      [-68, 213],
    ],
    [
      [10282, 17604],
      [32, 39],
      [42, 101],
      [56, 101],
      [36, 203],
      [44, 183],
      [76, 161],
      [42, 122],
      [23, 169],
      [83, 121],
      [16, 129],
      [56, 148],
      [56, 189],
      [25, 83],
    ],
    [
      [10869, 19353],
      [84, 3],
      [89, 101],
      [90, 127],
      [62, 81],
      [102, 79],
      [214, 49],
    ],
    [
      [13900, 17286],
      [-236, -412],
      [-68, -482],
      [151, -213],
      [397, 20],
      [419, 115],
      [235, -58],
      [-186, -663],
      [-142, -501],
      [-235, -334],
    ],
    [
      [10395, 13750],
      [-83, -17],
      [-148, -12],
      [-162, -65],
      [-96, -86],
      [-75, -80],
      [-90, -12],
    ],
    [
      [9741, 13478],
      [-9, 149],
      [-45, 89],
      [-40, 35],
      [-46, 75],
      [-31, 170],
      [-60, 29],
      [-20, 54],
      [55, 54],
      [21, 33],
      [54, 40],
      [42, 74],
      [21, 61],
      [42, 128],
      [9, 136],
      [-18, 88],
      [2, 149],
      [37, 217],
      [16, 122],
      [-227, 119],
      [-235, 38],
      [-161, 3],
      [-135, -4],
      [-295, 11],
      [-270, -15],
      [-357, -49],
      [-263, -42],
      [-201, -4],
      [-243, -50],
      [-162, -31],
      [-271, -97],
      [-155, -32],
      [-202, -57],
      [-258, -125],
      [-303, -63],
      [-264, -104],
      [-288, 66],
      [-201, 72],
      [-187, 71],
      [-295, 45],
      [-261, 46],
      [-324, -63],
      [-195, 50],
    ],
    [
      [4018, 14966],
      [7, 21],
      [124, 569],
      [176, 513],
      [251, 560],
      [68, 67],
      [133, 306],
      [42, 99],
      [491, 814],
      [539, 446],
      [173, 191],
    ],
    [
      [6022, 18552],
      [178, -51],
      [193, -99],
      [172, -165],
      [242, -5],
      [173, -104],
      [235, -59],
      [301, -46],
      [296, -52],
      [174, -10],
      [350, -20],
      [255, -11],
      [262, -4],
      [308, -66],
      [268, -46],
      [167, -111],
      [119, -118],
      [106, -83],
      [39, -89],
      [80, -15],
      [89, 46],
      [115, 93],
      [108, 32],
      [30, 35],
    ],
    [
      [9741, 13478],
      [7, -109],
      [-82, -87],
      [-62, -80],
      [-42, -102],
      [-49, -169],
      [-57, -175],
      [-16, -176],
      [-30, -203],
      [-70, -162],
      [-104, -148],
      [-142, -113],
      [-171, -180],
      [-157, -113],
      [-157, -140],
      [-123, -140],
      [-177, -140],
      [-217, -146],
      [-123, -106],
      [-150, -133],
      [-196, -58],
      [-276, -43],
      [-169, -4],
      [-228, 4],
      [-221, 65],
      [-100, 42],
    ],
    [
      [6629, 10862],
      [-134, 57],
      [-106, 97],
      [-57, 218],
      [44, 190],
      [-10, 238],
      [31, 223],
      [51, 311],
      [12, 299],
      [-22, 319],
      [-10, 211],
      [5, 299],
      [37, 223],
      [-10, 190],
      [15, 136],
      [-19, 88],
      [-32, 76],
      [-160, 98],
      [-114, -19],
      [-142, -38],
      [-136, -72],
      [-62, -95],
      [-54, -53],
      [-171, -167],
      [-110, -147],
      [-103, -134],
      [-117, -148],
      [-90, -175],
      [-76, -120],
      [-145, -249],
      [-77, -169],
      [-90, -168],
      [-204, -186],
      [-225, -173],
      [-231, -132],
      [-222, -10],
      [-182, -58],
      [-242, 25],
      [-215, -37],
      [-93, -11],
    ],
    [
      [3163, 11799],
      [18, 42],
      [284, 953],
      [126, 698],
      [264, 947],
      [55, 176],
      [108, 351],
    ],
    [
      [10905, 31043],
      [-43, -377],
      [22, -347],
      [177, -196],
    ],
    [
      [8780, 24977],
      [-171, 0],
      [-530, 9],
      [-356, 54],
      [-469, 76],
      [-268, 66],
      [-202, 16],
      [-65, 110],
      [-102, 1],
    ],
    [
      [6617, 25309],
      [-18, 33],
      [137, 175],
      [42, 53],
      [303, 63],
      [96, 73],
      [14, 462],
      [14, 483],
      [2, 502],
      [-178, 663],
      [-58, 626],
      [17, 605],
      [11, 700],
      [-225, 643],
      [-214, 506],
      [-74, 49],
    ],
    [
      [6486, 30945],
      [61, 28],
      [210, 85],
      [168, 10],
      [202, 4],
      [160, -91],
      [200, -72],
      [262, -72],
      [260, -106],
      [348, -87],
      [317, 35],
      [249, 36],
      [328, -66],
      [337, 0],
      [235, -3],
      [128, 38],
      [311, 110],
      [210, 78],
      [311, 130],
      [122, 41],
    ],
    [
      [10625, 9762],
      [-2, -55],
      [10, -639],
      [104, -627],
      [207, -561],
      [-107, -259],
      [-82, -200],
      [-129, -494],
      [-236, -424],
      [-267, -295],
      [-205, -92],
    ],
    [
      [9918, 6116],
      [-178, 16],
      [-87, 8],
      [-61, 8],
      [-134, 43],
      [-228, 11],
      [-196, -99],
      [-210, -91],
      [-177, -119],
      [-184, -119],
      [-69, -115],
      [-157, -180],
      [-122, -66],
      [-75, -26],
      [-62, -108],
      [19, -82],
      [-128, -45],
      [-41, 1],
      [-86, 49],
      [-121, 56],
      [-100, 23],
      [-10, -55],
    ],
    [
      [7511, 5226],
      [-38, 1],
      [-65, 116],
      [-19, 89],
      [-19, 61],
      [-100, 84],
      [-118, 171],
      [-72, 144],
      [-39, 55],
      [1, 95],
      [-195, -51],
      [90, 175],
      [-81, -12],
      [-18, 109],
      [-121, 2],
      [15, 122],
      [8, 95],
      [-35, 319],
      [-25, 144],
      [-17, 149],
      [-14, 23],
      [-39, 66],
      [-10, 14],
    ],
    [
      [5735, 9217],
      [35, 45],
      [62, 114],
      [23, 183],
      [-86, 96],
      [2, 123],
      [35, 114],
      [178, 187],
      [123, 127],
      [185, 187],
      [150, 174],
      [76, 121],
      [104, 161],
      [7, 13],
    ],
    [
      [10766, 13808],
      [134, -252],
      [197, -254],
      [116, -315],
      [-17, -244],
      [-64, -231],
      [-113, -358],
      [-116, -467],
      [-89, -535],
      [-74, -427],
      [-96, -509],
      [-19, -454],
    ],
    [
      [15206, 29535],
      [86, -6],
      [874, -29],
      [700, -11],
      [477, -36],
      [269, -5],
      [350, -33],
      [304, -284],
      [280, -603],
      [33, -497],
      [72, -544],
      [329, -496],
      [445, -347],
      [646, -406],
      [418, -360],
      [631, -99],
      [280, -155],
      [228, -459],
      [-20, -469],
      [-181, -785],
      [-76, -522],
      [-8, -924],
      [52, -574],
    ],
    [
      [4278, 7617],
      [-103, -134],
      [-130, -93],
      [-135, -72],
      [-68, -38],
    ],
    [
      [3842, 7280],
      [-6, 39],
      [-78, 198],
      [-85, 111],
      [-105, 171],
      [-178, 268],
      [-139, 131],
      [-186, 85],
      [-61, 33],
    ],
    [
      [3004, 8316],
      [232, 227],
      [223, 465],
      [-62, 742],
      [36, 584],
      [-182, 431],
      [-128, 390],
      [-257, 22],
      [105, 178],
      [192, 444],
    ],
    [
      [7511, 5226],
      [-12, -61],
      [-70, -195],
      [-83, -135],
      [-88, -39],
      [-182, -31],
      [-297, -49],
      [-249, -50],
      [-230, -57],
      [-181, 16],
      [-331, -68],
      [-175, -11],
      [-195, -44],
      [-217, -64],
      [-195, -58],
      [-284, -57],
      [-169, -58],
      [-229, -43],
      [-66, 55],
      [-78, -53],
    ],
    [
      [4180, 4224],
      [-44, 48],
      [-36, 212],
      [-73, 103],
      [-73, 55],
      [-60, 35],
      [-72, 137],
      [-11, 82],
      [-26, 116],
      [-11, 142],
      [90, 162],
      [24, 244],
      [43, 135],
      [16, 162],
      [43, 163],
      [-75, 374],
      [35, 108],
      [-48, 347],
      [-11, 136],
      [-25, 144],
      [-24, 151],
    ],
    [
      [9405, 3290],
      [-163, -445],
      [-139, -296],
      [195, -378],
      [267, -562],
      [252, -441],
      [128, -225],
      [86, -538],
      [56, -252],
      [-55, -54],
      [-29, -39],
      [-99, -60],
      [-114, 96],
      [-418, 354],
      [-477, 464],
      [-427, 259],
      [-414, 204],
      [-541, 207],
      [-284, -91],
      [-567, -112],
      [-309, 80],
      [-401, 129],
      [-555, 275],
      [-281, 100],
      [-187, -72],
    ],
    [
      [4929, 1893],
      [11, 91],
      [51, 237],
      [4, 224],
      [-101, 430],
      [-104, 219],
      [-13, 54],
      [-97, 205],
      [-99, 151],
      [-92, 118],
      [-124, 219],
      [-99, 124],
      [-43, 211],
      [-43, 48],
    ],
    [
      [9918, 6116],
      [-202, -91],
      [-512, -446],
      [42, -368],
      [-220, -309],
      [-7, -408],
      [110, -294],
      [277, -318],
      [12, -557],
      [-13, -35],
    ],
    [
      [4929, 1893],
      [-117, -45],
      [-472, -47],
      [-536, 105],
      [-211, -126],
      [-312, -178],
      [-244, -71],
      [-364, -27],
      [-484, -12],
      [-380, -170],
      [-477, 42],
      [-369, 88],
      [-377, 360],
      [-305, 311],
      [-281, 528],
      [48, 334],
      [28, 188],
      [182, 583],
      [23, 73],
      [121, 419],
      [321, 695],
      [242, 260],
      [93, 101],
      [206, 228],
      [109, 107],
      [175, 404],
      [247, 329],
      [282, 356],
      [253, 281],
      [244, 512],
      [140, 372],
      [290, 423],
    ],
    [
      [10869, 19353],
      [-158, -6],
      [-301, 127],
      [-296, -56],
      [-316, -15],
      [-390, 41],
      [-341, 60],
      [-314, 134],
      [-160, 71],
      [-52, 157],
      [-78, 137],
      [35, 122],
      [-57, 238],
      [72, 264],
      [-110, 260],
      [-255, 11],
      [-343, 6],
      [-310, -22],
      [-230, 79],
    ],
    [
      [7265, 20961],
      [54, 161],
      [27, 435],
      [-70, 640],
      [4, 133],
      [10, 322],
      [-113, 546],
      [35, 475],
      [95, 502],
      [-361, 536],
      [-329, 598],
    ],
    [
      [6022, 18552],
      [148, 163],
      [99, 305],
      [300, 633],
      [149, 489],
      [7, 25],
      [418, 428],
      [122, 366],
    ],
    [
      [11088, 34108],
      [-4, -122],
      [-30, -169],
      [18, -572],
      [69, -735],
      [-21, -482],
      [-189, -432],
      [-22, -516],
      [-4, -37],
    ],
    [
      [6486, 30945],
      [-199, 133],
      [-455, 164],
      [-430, 55],
      [-668, 331],
      [-563, 132],
      [-401, 170],
      [-400, 218],
      [-291, 304],
      [-198, 268],
      [-55, 12],
      [16, 30],
      [111, 209],
      [110, 195],
      [145, 222],
      [104, 168],
      [111, 236],
      [150, 133],
      [191, 153],
      [192, 235],
      [109, 100],
      [192, 160],
      [278, 185],
      [441, 237],
      [320, 185],
      [458, 199],
      [23, 10],
      [351, 117],
      [9, 6],
      [119, -36],
      [80, -49],
      [31, -129],
      [33, -55],
      [19, -96],
      [217, 112],
      [256, 50],
      [453, 135],
      [761, 48],
      [587, 78],
      [847, -82],
      [403, -46],
      [26, -3],
      [373, -251],
      [333, -230],
      [260, -154],
      [62, -327],
      [-6, 0],
      [105, -131],
      [-8, -238],
    ],
  ],
  transform: {
    scale: [0.00003431179699162766, 0.000033939881885454],
    translate: [7.153149068314111, 4.815404759050758],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Aba North",
            id: "AB001",
            nameAbbr: "Aba North",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.28776910379,
            Shape_Area: 0.00147099695,
          },
        },
        {
          arcs: [[4, 5, -2]],
          type: "Polygon",
          properties: {
            name: "Aba South",
            id: "AB002",
            nameAbbr: "Aba South",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.26896358876,
            Shape_Area: 0.00415156525,
          },
        },
        {
          arcs: [[6, 7, 8]],
          type: "Polygon",
          properties: {
            name: "Arochukwu",
            id: "AB003",
            nameAbbr: "Arochukwu",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.20463320471,
            Shape_Area: 0.03137346599,
          },
        },
        {
          arcs: [[9, 10, -8, 11, 12, 13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Bende",
            id: "AB004",
            nameAbbr: "Bende",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.27436233766,
            Shape_Area: 0.04908305233,
          },
        },
        {
          arcs: [[16, 17, 18, 19, 20, -13, 21]],
          type: "Polygon",
          properties: {
            name: "Ikwuano",
            id: "AB005",
            nameAbbr: "Ikwuano",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.70473719644,
            Shape_Area: 0.01932938447,
          },
        },
        {
          arcs: [[-19, 22, 23, 24, 25]],
          type: "Polygon",
          properties: {
            name: "Isiala Ngwa North",
            id: "AB006",
            nameAbbr: "Isiala Ngwa North",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.78958194438,
            Shape_Area: 0.02059083291,
          },
        },
        {
          arcs: [[26, 27, 28, -24]],
          type: "Polygon",
          properties: {
            name: "Isiala Ngwa South",
            id: "AB007",
            nameAbbr: "Isiala Ngwa South",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.79966528704,
            Shape_Area: 0.02122675296,
          },
        },
        {
          arcs: [[29, -15, 30, 31, 32]],
          type: "Polygon",
          properties: {
            name: "Isuikwuato",
            id: "AB008",
            nameAbbr: "Isuikwuato",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.75172879937,
            Shape_Area: 0.02734434955,
          },
        },
        {
          arcs: [[33, 34, 35, -4, 36, -27, -23, -18, 37]],
          type: "Polygon",
          properties: {
            name: "Oboma Ngwa",
            id: "AB009",
            nameAbbr: "Oboma Ngwa",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.83121594995,
            Shape_Area: 0.03041588676,
          },
        },
        {
          arcs: [[-9, -11, 38]],
          type: "Polygon",
          properties: {
            name: "Ohafia Abia",
            id: "AB010",
            nameAbbr: "Ohafia Abia",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03101073364,
            Shape_Area: 0.04619718839,
          },
        },
        {
          arcs: [[-37, -3, -6, 39, 40, 41, -28]],
          type: "Polygon",
          properties: {
            name: "Osisioma Ngwa",
            id: "AB011",
            nameAbbr: "Osisioma Ngwa",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.60890287358,
            Shape_Area: 0.01565043294,
          },
        },
        {
          arcs: [[-1, -36, 42, 43, -40, -5]],
          type: "Polygon",
          properties: {
            name: "Ugwunagbo",
            id: "AB012",
            nameAbbr: "Ugwunagbo",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.46073622763,
            Shape_Area: 0.00862891036,
          },
        },
        {
          arcs: [[44, 45, -43, -35, 46]],
          type: "Polygon",
          properties: {
            name: "Ukwa East",
            id: "AB013",
            nameAbbr: "Ukwa East",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.74700989358,
            Shape_Area: 0.02001035983,
          },
        },
        {
          arcs: [[47, -41, -44, -46]],
          type: "Polygon",
          properties: {
            name: "Ukwa West",
            id: "AB014",
            nameAbbr: "Ukwa West",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.66075328849,
            Shape_Area: 0.02381332884,
          },
        },
        {
          arcs: [[-21, 48, 49, -31, -14]],
          type: "Polygon",
          properties: {
            name: "Umuahia North",
            id: "AB015",
            nameAbbr: "Umuahia North",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.6711977711,
            Shape_Area: 0.01878249251,
          },
        },
        {
          arcs: [[-49, -20, -26, 50]],
          type: "Polygon",
          properties: {
            name: "Umuahia South",
            id: "AB016",
            nameAbbr: "Umuahia South",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.47950837848,
            Shape_Area: 0.0106651987,
          },
        },
        {
          arcs: [[51, -33, 52]],
          type: "Polygon",
          properties: {
            name: "Umu-Nneochi",
            id: "AB017",
            nameAbbr: "Umu-Nneochi",
            parent: "Abia",
            parentAbbr: "AB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.7657733673,
            Shape_Area: 0.03675078102,
          },
        },
      ],
    },
  },
};

const rivers = {
  type: "Topology",
  arcs: [
    [
      [22474, 16784],
      [-48, -334],
      [281, -529],
      [305, -311],
      [377, -360],
      [369, -87],
      [477, -43],
      [380, 170],
      [484, 13],
      [364, 27],
      [244, 71],
      [312, 178],
      [211, 126],
      [536, -105],
      [472, 46],
      [117, 45],
      [187, 73],
      [281, -101],
      [555, -274],
      [401, -130],
      [309, -80],
      [567, 113],
      [284, 90],
      [541, -206],
      [414, -205],
      [427, -258],
      [477, -464],
      [418, -354],
      [114, -96],
    ],
    [
      [32330, 13799],
      [-210, -127],
      [-307, -267],
      [-313, -245],
      [-467, -183],
      [-437, -6],
      [-408, 116],
      [-13, 34],
      [-20, 21],
      [-73, 96],
      [-139, 125],
      [-234, 78],
      [-288, 46],
      [-477, 22],
      [-257, -98],
      [-318, -110],
      [-198, -139],
      [-257, -145],
      [-366, -163],
      [-285, -192],
      [-166, -249],
      [-310, -89],
      [-13, -2],
    ],
    [
      [26774, 12322],
      [-270, -27],
      [-186, 166],
      [-171, 207],
      [-200, 98],
      [-295, 66],
      [-383, 27],
      [-301, 114],
      [-459, 306],
      [-220, 99],
      [-136, 41],
    ],
    [
      [24153, 13419],
      [-105, 31],
      [-556, 112],
      [-419, 306],
      [-388, 128],
      [-453, 232],
      [-106, 57],
    ],
    [
      [22126, 14285],
      [-181, 97],
      [-368, 95],
      [-232, 194],
      [-165, 240],
      [-124, 193],
      [-157, 267],
      [-104, 246],
      [-225, 228],
      [-245, 186],
    ],
    [
      [20325, 16031],
      [222, 52],
      [203, 31],
      [276, 70],
      [223, 43],
      [236, 64],
      [222, 10],
      [42, 67],
      [-26, 69],
      [28, 114],
      [42, 115],
      [8, 54],
      [95, 39],
      [108, 19],
      [155, 4],
      [174, -10],
      [141, 12],
    ],
    [
      [20525, 9821],
      [-58, -23],
      [-81, -12],
      [-142, -25],
      [-122, -66],
      [-82, -80],
      [-90, -56],
    ],
    [
      [19950, 9559],
      [-13, -8],
    ],
    [
      [19937, 9551],
      [-27, 0],
      [0, -8],
      [-8, 0],
      [-2, -15],
    ],
    [
      [19900, 9528],
      [-9, -6],
      [-142, -24],
      [-94, -19],
      [-101, 15],
      [-1361, 944],
    ],
    [
      [18193, 10438],
      [0, 5],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -24],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
    ],
    [
      [20152, 11082],
      [0, -9],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-64, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-80, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [81, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [73, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [17, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [19869, 11237],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-33, 0],
    ],
    [
      [20193, 12228],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [72, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [20023, 12408],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [19812, 12424],
      [0, -57],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-65, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [-1, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, -25],
      [-8, 0],
    ],
    [
      [18129, 10484],
      [-9, 5],
    ],
    [
      [18120, 10489],
      [0, 4],
      [-5, 0],
    ],
    [
      [18115, 10493],
      [-12, 8],
    ],
    [
      [18103, 10501],
      [-8, 6],
    ],
    [
      [18095, 10507],
      [-6, 3],
      [-1, 8],
    ],
    [
      [18088, 10518],
      [132, 24],
      [109, 121],
      [-5, 109],
      [58, 229],
      [-213, 140],
      [-162, -18],
      [-155, -11],
      [-162, -58],
      [-150, -127],
      [-135, -38],
      [-80, 22],
      [-45, 143],
      [-192, 180],
      [-85, 47],
    ],
    [
      [17003, 11281],
      [0, 5],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 10],
    ],
    [
      [16218, 12262],
      [81, 112],
      [22, 109],
      [-4, 122],
      [-53, 62],
      [-16, 7],
    ],
    [
      [16248, 12674],
      [44, 60],
      [29, 68],
    ],
    [
      [16321, 12802],
      [2, -2],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [122, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [56, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [105, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -114],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [0, -24],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
    ],
    [
      [17181, 12974],
      [0, -133],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-80, 0],
      [0, -9],
      [-98, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 74],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 115],
      [-8, 0],
      [0, 98],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 32],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-122, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 57],
      [-3, 0],
    ],
    [
      [16361, 12931],
      [1, 6],
      [74, -29],
      [154, -9],
      [176, 58],
      [175, 4],
      [167, -10],
      [73, 23],
    ],
    [
      [20120, 13464],
      [0, -50],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 6],
    ],
    [
      [20007, 13453],
      [113, 11],
    ],
    [
      [20823, 13505],
      [-17, -128],
      [-21, -68],
      [-8, -115],
      [3, -197],
      [46, -75],
      [79, -83],
      [93, -111],
      [58, -143],
      [-37, -48],
    ],
    [
      [21019, 12537],
      [-45, -59],
      [-82, -33],
      [-75, -73],
      [-68, -47],
      [-81, -66],
      [-2, -109],
      [44, -157],
      [107, -42],
      [154, -44],
      [38, -156],
      [-3, -170],
      [-57, -210],
      [-30, -203],
      [-29, -135],
    ],
    [
      [20890, 11033],
      [-1, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [-16, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 24],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [13, 0],
    ],
    [
      [20886, 11016],
      [-18, -84],
      [11, -170],
      [2, -65],
    ],
    [
      [20881, 10697],
      [0, -16],
    ],
    [
      [20881, 10681],
      [14, -164],
      [11, -183],
      [30, -164],
      [53, -116],
      [-10, -183],
      [-90, -14],
      [0, -3],
    ],
    [
      [20889, 9854],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 90],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 81],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 81],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-24, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [48, 0],
      [0, 16],
      [-56, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-41, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [33, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-16, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 4],
    ],
    [
      [20152, 13467],
      [24, 2],
      [242, 9],
      [229, 3],
      [176, 24],
    ],
    [
      [19927, 13447],
      [11, 0],
      [12, 1],
      [0, -9],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [-1, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-33, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-178, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-105, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [-16, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 58],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 25],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [-48, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 82],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [65, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [1, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -164],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-130, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 123],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -65],
      [9, 0],
      [0, -74],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [16, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [17, 0],
      [0, 49],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-97, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, -16],
      [-17, 0],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 106],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 146],
    ],
    [
      [17198, 12979],
      [93, 30],
      [89, 107],
      [83, 141],
      [50, 176],
      [2, 143],
      [291, 103],
      [215, -3],
      [160, -85],
      [309, -19],
      [308, -59],
      [182, -30],
      [247, -59],
      [195, 4],
      [262, -12],
      [243, 31],
    ],
    [
      [26774, 12322],
      [-15, -79],
      [-91, -202],
      [31, -137],
      [79, -123],
      [-7, -7],
      [4, -163],
      [72, -103],
      [114, -43],
      [93, -83],
      [119, -90],
      [87, -56],
      [113, -63],
      [14, -7],
      [52, -96],
      [78, -164],
      [92, -158],
      [37, -107],
    ],
    [
      [27646, 10641],
      [-276, -79],
      [-128, -38],
      [-243, -78],
      [-230, -70],
      [-168, 3],
      [-298, -97],
      [-168, 9],
      [-100, 29],
      [-215, -10],
      [-230, -91],
      [-231, -125],
      [-162, -58],
      [-187, 71],
      [-120, 90],
      [-121, -21],
    ],
    [
      [24769, 10176],
      [-54, -10],
      [-114, 50],
      [-86, 76],
      [-105, 131],
      [-79, 117],
      [-126, 104],
      [8, 221],
    ],
    [
      [24213, 10865],
      [4, 97],
      [23, 204],
      [17, 230],
      [65, 291],
      [85, 230],
      [93, 331],
      [-38, 190],
      [-206, 106],
      [-132, 185],
      [-105, 158],
      [32, 325],
      [102, 207],
    ],
    [
      [7770, 16369],
      [1, -6],
      [-36, -162],
      [-83, -135],
      [39, -75],
      [66, -55],
      [115, 4],
      [223, 78],
      [128, 5],
      [214, -18],
      [-90, -202],
      [-50, -142],
      [-68, -100],
      [-36, -95],
      [-115, -93],
      [-129, -52],
      [-76, -161],
      [99, -90],
      [120, -43],
      [161, -57],
      [180, -85],
      [132, -172],
      [86, -62],
      [66, -90],
      [20, -20],
      [87, -2],
      [100, -90],
      [120, -49],
      [171, 35],
    ],
    [
      [9215, 14435],
      [-25, -146],
      [-23, -163],
      [4, -170],
      [5, -129],
      [32, -96],
      [15, -21],
    ],
    [
      [9223, 13710],
      [0, -9],
      [7, 0],
    ],
    [
      [9230, 13701],
      [63, -86],
      [99, -117],
      [146, -139],
      [174, -84],
      [117, -199],
      [52, -137],
      [84, -198],
      [65, -178],
      [44, -177],
      [77, -205],
      [73, -83],
      [50, -211],
      [45, -150],
      [5, -136],
      [-10, -190],
      [7, -74],
    ],
    [
      [10321, 11337],
      [-82, 21],
      [-248, 65],
      [-302, 5],
      [-202, 18],
      [-283, -50],
      [-162, -45],
      [-61, -33],
      [-61, -39],
      [-108, -46],
      [-149, -45],
      [-223, -50],
      [-80, 8],
      [-114, 2],
      [-128, 36],
      [-187, 57],
      [-147, 78],
      [-180, 43],
      [-134, 37],
      [-88, -26],
      [-129, -52],
      [-135, -59],
      [-61, -19],
      [-81, -33],
      [-48, -14],
    ],
    [
      [6928, 11196],
      [-190, 406],
      [-404, 367],
      [-404, 34],
      [-532, -25],
      [-2810, -1087],
      [-271, -124],
      [-257, 65],
      [-232, 59],
      [-58, 599],
      [-109, 328],
      [200, 697],
      [-178, 221],
      [-223, -31],
      [-235, 11],
      [-328, 142],
      [-333, 216],
      [-260, 127],
      [-236, 398],
      [-68, 369],
      [50, 597],
      [28, 496],
      [23, 611],
      [92, 277],
      [280, 288],
      [295, 362],
      [228, 349],
      [74, 434],
      [3, 5],
    ],
    [
      [1073, 17387],
      [146, -2],
      [201, -17],
      [342, -67],
      [282, -39],
      [353, -175],
      [153, -132],
      [159, -132],
      [120, -97],
      [185, -146],
      [105, -171],
      [117, -233],
      [105, -192],
      [45, -150],
      [106, -90],
      [273, -141],
      [65, -143],
      [72, -110],
      [229, -4],
      [155, 4],
      [129, 86],
      [81, 73],
      [116, 73],
      [82, 66],
      [122, 87],
      [37, 182],
      [-31, 157],
      [15, 149],
      [-70, 81],
      [-68, 78],
      [-113, 97],
      [-207, 112],
      [-173, 71],
      [-80, 49],
      [-12, 136],
      [3, 169],
      [191, 126],
      [87, 19],
      [21, 40],
      [36, 108],
      [10, 29],
    ],
    [
      [4462, 17338],
      [32, 86],
      [-46, 116],
      [36, 149],
      [23, 176],
      [16, 129],
      [69, 121],
      [49, 121],
      [56, 101],
      [135, 86],
      [184, 99],
      [128, 38],
      [121, 32],
      [276, -25],
      [106, -77],
      [153, -91],
      [193, -152],
      [158, -186],
      [51, -150],
      [79, -131],
      [170, -274],
      [90, -266],
      [56, -253],
      [64, -238],
      [51, -185],
      [166, -138],
      [173, -91],
      [181, -31],
      [202, -10],
      [134, 11],
      [128, 25],
      [74, 39],
    ],
    [
      [5737, 25320],
      [307, -88],
      [330, 49],
      [389, -48],
      [301, -93],
      [233, -181],
      [58, -130],
      [152, -179],
      [187, -71],
      [273, -181],
      [172, -146],
      [200, -118],
      [21, -13],
      [86, -50],
      [53, -22],
      [102, 73],
      [124, 181],
      [110, 148],
      [191, 146],
      [208, 17],
      [179, 9],
    ],
    [
      [9413, 24623],
      [9, -53],
      [44, -150],
      [-37, -230],
      [-2, -136],
      [-49, -128],
      [-37, -176],
      [-69, -135],
      [-23, -149],
      [-49, -169],
      [-37, -175],
      [-22, -150],
      [-63, -161],
      [-58, -224],
      [-43, -155],
      [-64, -230],
      [-77, -195],
      [-57, -176],
      [-43, -169],
      [-72, -311],
      [-64, -209],
      [-206, -316],
      [11, -149],
      [-16, -122],
      [-88, -80],
      [-17, -217],
      [-49, -115],
      [-17, -203],
      [19, -95],
      [25, -123],
      [-50, -149],
      [-35, -121],
      [-51, -203],
      [-95, -93],
      [-50, -156],
      [-124, -188],
      [-83, -134],
      [7, 0],
      [-50, -169],
      [-22, -163],
      [85, -150],
      [105, -118],
      [38, -177],
      [-25, -264],
      [-97, -182],
      [-16, -176],
      [-70, -196],
      [17, -156],
      [-43, -176],
      [19, -61],
      [18, -170],
      [30, -151],
    ],
    [
      [4462, 17338],
      [-64, -7],
      [-135, -46],
      [-128, 16],
      [-107, 9],
      [-95, -26],
      [-187, 44],
      [-88, -39],
      [-121, -5],
      [-55, -33],
      [-87, -12],
      [-46, 75],
      [-45, 117],
      [-39, 89],
      [-147, 70],
      [-57, 218],
      [150, 140],
      [149, 93],
      [143, 65],
      [163, 126],
      [97, 141],
      [136, 154],
      [178, 201],
      [64, 223],
      [23, 183],
      [63, 155],
      [122, 11],
      [174, -30],
      [176, 51],
      [190, 126],
      [177, 119],
      [155, 72],
      [90, 148],
      [76, 87],
      [130, 174],
      [42, 108],
      [37, 183],
      [-12, 95],
      [-72, 123],
      [-79, 151],
      [-90, 206],
      [-31, 176],
      [129, 87],
      [47, -8],
      [155, 11],
      [175, -3],
      [128, 66],
      [110, 113],
      [-166, 173],
      [23, 176],
      [-23, 224],
      [-11, 177],
      [-37, 170],
      [-18, 157],
      [23, 142],
      [39, 346],
      [-10, 230],
      [-16, 218],
      [24, 258],
      [-16, 285],
      [-1, 312],
      [-15, 374],
      [-106, 117],
      [-72, 103],
      [-106, 97],
      [17, 224],
      [82, 94],
      [70, 88],
    ],
    [
      [1073, 17387],
      [282, 541],
      [16, 581],
      [0, 23],
      [-248, 52],
      [-359, -130],
      [-267, 134],
      [-130, 240],
      [-8, 361],
      [156, 527],
      [155, 433],
      [210, 519],
      [112, 277],
      [236, 438],
      [256, 430],
      [222, 404],
      [187, 351],
      [194, 370],
      [256, 431],
      [216, 438],
      [310, 470],
      [178, 194],
      [57, 217],
      [344, 28],
      [450, -76],
      [510, -36],
      [260, 241],
      [166, 303],
      [91, 284],
    ],
    [
      [4925, 25432],
      [53, 1],
      [154, -9],
      [295, -66],
      [289, -32],
      [21, -6],
    ],
    [
      [9143, 3037],
      [-5, 8],
      [5, 0],
      [0, -8],
    ],
    [
      [10810, 3413],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-105, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [9320, 5418],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [9887, 6359],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [16, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [11855, 6783],
      [0, 18],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [26, 0],
    ],
    [
      [11913, 6834],
      [-19, -19],
      [-39, -32],
    ],
    [
      [12138, 7073],
      [-8, -10],
    ],
    [
      [12130, 7063],
      [0, 8],
      [8, 2],
    ],
    [
      [11344, 10108],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -106],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [6, 0],
    ],
    [
      [12589, 8266],
      [1, -3],
      [109, -321],
      [-132, -222],
      [-111, -222],
      [-157, -160],
      [-161, -262],
    ],
    [
      [12138, 7076],
      [0, 20],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-17, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [73, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -10],
    ],
    [
      [12089, 7012],
      [-8, -6],
    ],
    [
      [12081, 7006],
      [-144, -148],
    ],
    [
      [11937, 6858],
      [-50, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -37],
    ],
    [
      [11830, 6764],
      [-86, -69],
      [-65, -284],
      [26, -502],
      [-57, -190],
      [82, -354],
      [159, -526],
      [54, -421],
      [81, -436],
      [55, -286],
      [77, -253],
      [119, -538],
      [108, -382],
      [104, -253],
      [141, -424],
      [135, -402],
      [181, -459],
      [145, -158],
      [337, -393],
      [107, -82],
      [4, 0],
      [50, -33],
    ],
    [
      [13587, 319],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-146, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-137, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-81, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-73, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-90, 0],
      [0, 8],
      [-20, 0],
      [-36, 0],
      [0, -5],
      [0, -3],
      [-8, 0],
      [0, 3],
      [0, 5],
      [-89, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-307, 0],
      [0, 9],
      [-106, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-106, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-65, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 148],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [16, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [57, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [81, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 66],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [7, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [17, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 41],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-89, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-65, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-7, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 40],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -73],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-40, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -82],
      [-81, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 197],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 82],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-16, 0],
      [0, 107],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [56, 0],
      [0, 9],
      [57, 0],
      [0, 8],
      [178, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [146, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [-57, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [25, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -74],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-97, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-64, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [80, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [57, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 57],
      [-9, 0],
      [0, 33],
      [-7, 0],
      [0, 25],
      [-17, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -57],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-72, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-90, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 147],
      [-8, 0],
      [0, 139],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [97, 0],
      [0, 7],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 114],
      [8, 0],
      [0, 42],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 1],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 73],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 42],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 40],
      [9, 0],
      [0, 42],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [146, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [113, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 58],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, 32],
      [9, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-97, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [64, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-106, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-105, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 50],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -50],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -90],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 148],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-130, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -114],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-25, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -16],
      [81, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -82],
      [-8, 0],
      [0, -164],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-148, 74],
      [-274, 87],
      [-434, 231],
      [-446, 321],
      [-406, 265],
      [-304, 338],
      [-122, 383],
      [-148, 431],
      [-127, 478],
      [-59, 490],
      [-110, 308],
      [-57, 122],
    ],
    [
      [10321, 11337],
      [85, -23],
      [140, -84],
      [239, -187],
      [124, -260],
      [232, -214],
      [77, -192],
      [72, -137],
      [54, -132],
    ],
    [
      [31923, 4045],
      [1, -10],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 2],
    ],
    [
      [30895, 4633],
      [0, -9],
      [25, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [32, 0],
    ],
    [
      [31757, 4436],
      [-28, 0],
      [0, -41],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [5, 0],
      [3, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-5, 0],
      [-27, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-33, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-81, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-97, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 7],
      [32, 1],
    ],
    [
      [31008, 4641],
      [0, -3],
      [46, -11],
      [260, -86],
      [229, 23],
      [127, -70],
      [87, -58],
    ],
    [
      [31996, 3992],
      [29, -20],
      [65, -130],
      [126, -91],
      [58, -143],
      [59, -123],
      [91, -172],
      [12, -116],
      [24, -81],
      [18, -63],
    ],
    [
      [32478, 3053],
      [-61, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-56, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -17],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-194, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-72, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-56, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-81, 0],
      [0, -3],
      [0, -5],
      [-40, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-129, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-194, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 5],
    ],
    [
      [27617, 2976],
      [30, -6],
      [12, 31],
      [31, 85],
    ],
    [
      [27690, 3086],
      [3, 8],
    ],
    [
      [27693, 3094],
      [13, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-11, 0],
    ],
    [
      [27735, 3266],
      [10, 80],
      [10, 42],
    ],
    [
      [27755, 3388],
      [-3, 9],
      [11, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [9, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 31],
    ],
    [
      [27827, 3689],
      [47, 109],
      [-6, 73],
    ],
    [
      [27868, 3871],
      [0, 33],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -40],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [33, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [80, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, -1],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 16],
      [41, 0],
      [0, 16],
      [-17, 0],
      [0, 25],
      [-32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, 16],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-24, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [-24, 0],
      [0, -49],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, 49],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 1],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 7],
      [49, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [48, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [64, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [64, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [73, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, -1],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 14],
    ],
    [
      [29560, 4968],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [17, 0],
    ],
    [
      [29041, 5574],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [121, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [56, 0],
    ],
    [
      [30563, 5099],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -32],
      [0, -17],
      [9, 0],
      [0, -40],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 1],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [32, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [73, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
    ],
    [
      [27508, 5762],
      [-1, 16],
    ],
    [
      [27507, 5778],
      [5, 0],
      [0, -16],
      [-4, 0],
    ],
    [
      [30070, 5803],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, -1],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 1],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [97, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [41, 0],
    ],
    [
      [29551, 5901],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-49, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [24, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [29705, 6081],
      [0, -8],
      [17, 0],
      [0, -33],
      [-17, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [28305, 6114],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -57],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [27608, 6335],
      [1, -49],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -74],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
    ],
    [
      [27504, 5926],
      [15, 92],
      [82, 73],
      [-31, 123],
      [38, 121],
    ],
    [
      [27608, 6335],
      [4, 14],
      [-2, 92],
    ],
    [
      [27610, 6441],
      [7, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
    ],
    [
      [28337, 6449],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [16, 0],
    ],
    [
      [29228, 6589],
      [0, -9],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -82],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-89, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -148],
      [8, 0],
      [0, -57],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 7],
      [-24, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 49],
      [9, 0],
      [0, 66],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 17],
      [-40, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -41],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [-16, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-48, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-33, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [29179, 6662],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [40, 0],
    ],
    [
      [27924, 6736],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -50],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-49, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 106],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [56, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [27698, 6818],
      [0, -25],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -115],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 40],
      [8, 0],
      [-1, 9],
      [-7, 0],
      [0, 3],
      [0, 46],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [27576, 6701],
      [-79, 30],
      [-162, -51],
      [-182, -59],
      [-168, -108],
    ],
    [
      [26985, 6513],
      [0, 10],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [32, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 65],
      [9, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -18],
    ],
    [
      [26617, 6662],
      [-2, 9],
      [-37, 198],
      [-21, 153],
    ],
    [
      [26557, 7022],
      [7, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [7, 0],
      [1, -17],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 21],
      [0, 4],
      [-8, 0],
      [0, 2],
      [0, 6],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [25, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 4],
      [0, 12],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 73],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-4, 0],
    ],
    [
      [27269, 7194],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [26588, 7382],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [18, 0],
      [6, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -25],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [16, 0],
      [0, 106],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [30317, 6613],
      [-13, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-89, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [-9, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -50],
      [-9, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -98],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 59],
    ],
    [
      [28046, 7310],
      [18, -12],
      [100, -35],
      [183, 44],
      [169, 45],
      [60, -8],
      [175, 11],
      [115, 18],
      [33, -7],
      [94, -9],
      [168, 11],
      [142, -3],
      [134, -2],
      [174, -16],
      [175, 10],
      [28, 13],
      [68, 53],
      [60, 27],
      [129, 25],
      [229, 23],
      [142, -3],
    ],
    [
      [30442, 7495],
      [5, -7],
      [-34, -80],
      [-8, -34],
      [-29, -122],
      [-54, -53],
      [-68, -33],
      [-94, -5],
      [-95, -39],
      [-8, -102],
      [52, -110],
      [59, -82],
      [100, -70],
      [39, -82],
      [10, -63],
    ],
    [
      [28013, 7331],
      [0, -6],
      [9, 0],
      [0, -74],
      [-9, 0],
      [0, -24],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 90],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-88, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 17],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-48, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-5, 0],
    ],
    [
      [26705, 7407],
      [92, 90],
      [55, 114],
      [89, 94],
      [111, 108],
    ],
    [
      [27052, 7813],
      [113, 10],
      [155, -3],
      [93, -42],
      [79, -90],
      [120, -83],
      [160, -98],
      [126, -104],
      [115, -72],
    ],
    [
      [15077, 9797],
      [-36, -49],
      [-137, -113],
      [-113, 50],
      [-100, 89],
      [-248, 52],
      [-288, 46],
      [-219, 221],
      [-152, 152],
      [-274, 100],
      [-207, 85],
      [-114, -5],
      [-24, 26],
    ],
    [
      [13165, 10451],
      [25, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 98],
      [8, 0],
      [0, 16],
      [32, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 42],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 123],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -112],
      [0, -68],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [81, 0],
      [0, -9],
      [57, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
    ],
    [
      [12073, 10394],
      [-61, 4],
      [-166, 152],
      [-5, 108],
      [3, 170],
      [3, 183],
      [-24, 198],
      [-91, 178],
      [-9, 219],
    ],
    [
      [11723, 11606],
      [18, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [1, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [4, 0],
      [4, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [65, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [48, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -9],
      [48, 0],
      [0, 9],
      [9, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, -1],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-13, 0],
      [-12, 0],
      [2, 8],
      [-18, 1],
      [0, 21],
      [0, 11],
      [-8, 0],
      [0, 98],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -6],
      [0, -60],
      [-8, 0],
      [0, -57],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-65, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 32],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 6],
      [-8, 2],
      [0, 8],
      [-12, 0],
      [-20, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-72, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 6],
      [0, 2],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 156],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 66],
      [-24, 0],
      [0, -8],
      [-65, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, -98],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -122],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
    ],
    [
      [12194, 13356],
      [0, -65],
      [9, 0],
      [0, -57],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [57, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [64, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [10, 2],
    ],
    [
      [12763, 13293],
      [8, -9],
      [152, -180],
      [315, -5],
      [256, -5],
      [215, 4],
      [281, -100],
      [113, -43],
      [26, -7],
    ],
    [
      [14129, 12948],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -11],
    ],
    [
      [13975, 12724],
      [-104, -23],
      [-210, -78],
      [-75, -94],
      [-62, -100],
      [-77, -148],
      [72, -110],
      [19, -75],
      [126, -124],
      [100, -77],
      [120, -29],
      [18, -170],
      [73, -35],
      [132, -193],
      [-116, -73],
      [-47, -35],
    ],
    [
      [13944, 11360],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 115],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -40],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-105, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 1],
      [0, 7],
    ],
    [
      [11725, 12129],
      [2, 0],
      [-2, 59],
      [-37, 252],
      [4, 217],
      [-57, 238],
      [-63, 253],
      [-27, 102],
    ],
    [
      [11545, 13250],
      [96, -2],
      [160, -57],
      [128, -15],
      [88, 39],
      [170, 126],
      [7, 15],
    ],
    [
      [12729, 13331],
      [0, -7],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 107],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [1, 15],
    ],
    [
      [12220, 13405],
      [50, 97],
      [156, 79],
      [166, -98],
      [137, -152],
    ],
    [
      [24549, 3036],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [25, 0],
    ],
    [
      [27617, 2976],
      [0, 3],
      [-9, 0],
      [1, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [17, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
    ],
    [
      [27690, 3086],
      [0, 8],
      [3, 0],
    ],
    [
      [24573, 3233],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
    ],
    [
      [27735, 3266],
      [-5, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [-8, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [17, 0],
    ],
    [
      [27228, 3536],
      [0, -66],
      [-8, 0],
      [0, -65],
      [-16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [8, 0],
    ],
    [
      [24160, 3847],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [41, 0],
      [0, 9],
      [57, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -74],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-65, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-57, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -98],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -57],
      [17, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [49, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [25, 0],
    ],
    [
      [27827, 3689],
      [0, 35],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 57],
      [8, 0],
    ],
    [
      [24119, 3896],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 115],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 42],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
    ],
    [
      [24119, 3896],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-49, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-16, -1],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 1],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [24, 0],
      [0, 7],
      [9, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [40, 0],
      [0, 16],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 42],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [105, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 41],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [56, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [48, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [113, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -98],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -57],
      [-8, -1],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [73, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -74],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -114],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -66],
      [0, -98],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -33],
      [9, 0],
      [0, -74],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-57, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [-33, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-97, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-97, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-81, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-72, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-89, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-81, 0],
      [0, 3],
      [0, 5],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-57, 0],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 35],
      [0, 22],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 24],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-25, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [4, 0],
      [4, 0],
      [0, -6],
      [0, -2],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [32, 0],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [186, 0],
      [0, -9],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [25, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-89, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [9, 0],
      [0, 41],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -49],
      [16, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-3, 0],
      [-29, 0],
      [0, 16],
      [-64, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-15, 0],
      [-10, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [27, 0],
      [22, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 33],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -98],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, 33],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [89, 0],
      [0, 9],
      [73, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [56, 0],
      [0, -17],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-64, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -17],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 74],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [105, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-113, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
    ],
    [
      [22120, 5140],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, -1],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-33, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [-1, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [33, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [7, 0],
      [0, 25],
      [9, 0],
      [0, 65],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [24, 0],
    ],
    [
      [22241, 5189],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [24, 0],
    ],
    [
      [24832, 5615],
      [0, -9],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -33],
      [7, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [40, 0],
      [0, -7],
      [8, 0],
      [0, 7],
      [24, 0],
      [0, -7],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [98, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [40, 0],
      [0, 7],
      [73, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [9, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [89, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [32, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [113, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, -1],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-57, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 66],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, -1],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 106],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-121, 0],
      [0, -8],
      [-114, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-48, 0],
      [0, 58],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-113, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 49],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-105, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [32, 0],
    ],
    [
      [27471, 5631],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -74],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -74],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -147],
      [8, 0],
      [0, -98],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, 24],
      [-9, 0],
      [0, 41],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [27508, 5762],
      [-4, 0],
      [0, -33],
      [-17, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 40],
      [9, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [12, 0],
    ],
    [
      [26548, 5811],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [16, 0],
    ],
    [
      [26248, 5876],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -115],
      [8, 0],
      [0, -57],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [40, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [-16, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-24, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-65, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-25, 1],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 73],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 24],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [25, 0],
      [0, 9],
      [73, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [26054, 6065],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 90],
      [73, 0],
    ],
    [
      [26135, 6155],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, -16],
      [-17, 0],
    ],
    [
      [26945, 6171],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [65, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -131],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [26224, 6196],
      [0, -9],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-48, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [26006, 6245],
      [0, -8],
      [32, 0],
      [0, -41],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [33, 0],
    ],
    [
      [26548, 5811],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [-25, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -65],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 107],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 25],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -25],
      [16, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -98],
      [8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
    ],
    [
      [26726, 6269],
      [0, -24],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [16, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [20015, 6327],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [98, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [16, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -36],
      [0, -54],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-4, 0],
      [-4, 0],
      [2, 8],
      [-51, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-24, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-17, 0],
      [0, 82],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [25, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [-1, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 65],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [32, 0],
      [1, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
    ],
    [
      [25997, 6392],
      [0, -98],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [-9, 0],
    ],
    [
      [20355, 6556],
      [0, -17],
      [24, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -139],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-90, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [33, 0],
      [0, 17],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
    ],
    [
      [20476, 6589],
      [322, -49],
      [302, 8],
    ],
    [
      [21100, 6548],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [113, 0],
      [0, 8],
      [98, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [98, 0],
      [0, -8],
      [72, 0],
      [1, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -17],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -82],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-17, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 1],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -98],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [-1, -8],
      [-15, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-32, 0],
      [0, -16],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 9],
      [-6, -1],
      [-2, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 12],
      [0, 13],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [65, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -65],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 50],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 74],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [32, 0],
    ],
    [
      [19926, 6711],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [27504, 5926],
      [-9, 0],
      [0, 16],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-64, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 6],
    ],
    [
      [27576, 6701],
      [0, -31],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [1, -8],
    ],
    [
      [25973, 6850],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [26232, 6850],
      [0, -8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-137, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 115],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 32],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [49, 0],
      [0, 8],
      [64, 0],
    ],
    [
      [25860, 6883],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-57, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [25909, 6924],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [33, 0],
    ],
    [
      [18921, 2637],
      [116, 42],
      [353, 45],
      [-64, 87],
      [-224, 255],
      [-97, 233],
      [18, 278],
      [70, 189],
      [65, 304],
      [33, 339],
      [-43, 286],
      [-186, 173],
      [-112, 144],
      [-137, 233],
      [-52, 83],
      [-97, 273],
      [26, 366],
      [26, 346],
      [105, 229],
      [87, 426],
      [14, 112],
    ],
    [
      [18822, 7080],
      [19, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -74],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -65],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -115],
      [8, 0],
      [0, -82],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -17],
      [7, 0],
      [0, -24],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -81],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [1, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-97, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-7, 0],
      [0, 17],
      [-9, 0],
      [0, 49],
      [9, 0],
      [0, 8],
      [7, 0],
      [0, 32],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, -1],
      [0, -7],
      [33, 0],
      [0, -33],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [56, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [-1, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [1, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -42],
      [0, -7],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [-1, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, 16],
      [-56, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-9, 2],
    ],
    [
      [21893, 6631],
      [232, 63],
      [213, 98],
    ],
    [
      [22338, 6792],
      [71, 33],
      [171, 153],
      [345, 130],
      [109, 86],
    ],
    [
      [23034, 7194],
      [9, 0],
      [0, -57],
      [8, 0],
      [0, -90],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-64, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 10],
    ],
    [
      [26617, 6662],
      [-4, 0],
      [0, 9],
      [-16, -1],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [24, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
    ],
    [
      [26111, 7325],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [18836, 7194],
      [18, 147],
      [100, 331],
      [17, 40],
    ],
    [
      [18971, 7712],
      [164, -71],
      [19, -34],
      [100, -104],
      [105, -142],
    ],
    [
      [19359, 7361],
      [0, -11],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-16, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [129, 0],
      [0, 8],
      [8, 0],
      [1, 9],
      [15, 0],
      [0, -9],
      [9, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -11],
      [0, -6],
      [6, 0],
      [2, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-49, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-13, 0],
    ],
    [
      [25601, 8070],
      [16, -5],
    ],
    [
      [25617, 8065],
      [0, -12],
      [24, 0],
      [0, 6],
    ],
    [
      [25641, 8059],
      [22, -7],
      [235, 10],
      [282, -53],
      [147, -43],
      [113, -49],
      [135, 25],
      [117, 127],
      [75, 107],
      [41, 13],
      [25, -109],
      [67, -42],
      [11, -122],
      [-8, -75],
      [134, -29],
      [15, 1],
    ],
    [
      [26705, 7407],
      [-19, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -42],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -33],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [16, 0],
      [0, 82],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -32],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [24, 0],
      [0, -9],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -90],
      [9, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, -17],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, -24],
      [33, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-48, 0],
      [0, -16],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-16, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [57, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -49],
      [16, 0],
      [0, -8],
      [16, -1],
      [0, -7],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-81, 0],
      [0, 7],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-17, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 81],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 98],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [7, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [7, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-114, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 17],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 3],
      [0, 6],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -6],
      [0, -3],
      [8, 1],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
    ],
    [
      [25269, 8151],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-72, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -40],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -16],
      [-7, 0],
      [-1, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [1, -9],
      [7, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-7, 0],
      [-1, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-7, 0],
      [-1, -16],
      [-8, 0],
      [0, -90],
      [8, 0],
      [1, -25],
      [7, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 81],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-33, 0],
      [0, 16],
      [-40, 0],
      [0, 17],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [24, 0],
      [0, 49],
      [-16, 0],
      [0, 16],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [57, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
    ],
    [
      [24751, 8356],
      [28, -51],
      [288, -86],
      [187, -65],
      [15, -3],
    ],
    [
      [24718, 8422],
      [8, -14],
    ],
    [
      [24726, 8408],
      [0, -44],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-56, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [56, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -42],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-16, 0],
      [0, -32],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -25],
      [57, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [1, 8],
    ],
    [
      [23416, 7890],
      [34, 112],
      [89, 121],
      [2, 4],
    ],
    [
      [23541, 8127],
      [28, 0],
      [0, 8],
      [97, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [25, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 49],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 131],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [29, 0],
      [19, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -35],
      [0, -14],
      [9, 1],
      [-1, -17],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [10, 1],
      [-2, -17],
      [10, 1],
      [-2, -9],
      [6, 0],
      [10, 0],
      [0, -19],
      [0, -6],
      [4, 0],
      [4, 0],
      [0, -8],
    ],
    [
      [24395, 8706],
      [47, -25],
      [34, -18],
      [56, -25],
    ],
    [
      [24532, 8638],
      [0, -12],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -139],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [113, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 31],
    ],
    [
      [24249, 8776],
      [0, -2],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [9, 0],
      [0, -4],
      [0, -4],
      [7, 0],
      [9, 0],
      [0, -5],
      [0, -3],
      [6, 0],
      [26, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -6],
      [0, -44],
      [-8, 0],
      [0, -16],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-113, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 74],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-19, 0],
    ],
    [
      [23550, 8160],
      [61, 213],
      [83, 154],
      [109, 107],
      [171, 160],
      [275, -18],
    ],
    [
      [17100, 958],
      [0, -17],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [32, 0],
    ],
    [
      [16299, 2807],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [138, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-97, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 57],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
    ],
    [
      [15271, 3397],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -123],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [7, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [24, 0],
      [0, -9],
      [57, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -50],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -7],
      [33, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -42],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -4],
      [0, -4],
      [-55, 0],
      [-90, 0],
      [0, -9],
      [-98, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-34, 0],
      [-6, 0],
      [0, 4],
      [0, 4],
      [-8, 0],
      [-49, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-57, 0],
      [0, 7],
      [-16, 0],
      [0, 10],
      [-16, -1],
      [0, 5],
      [0, 3],
      [-19, 0],
      [-30, 0],
      [0, 8],
      [-24, 0],
      [0, 5],
      [0, 3],
      [-8, 0],
      [0, 9],
      [-43, 0],
      [-14, 0],
      [0, 3],
      [0, 5],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-48, 0],
      [0, 7],
      [-65, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-113, 0],
      [0, -8],
      [-9, 0],
      [0, -2],
      [0, -6],
      [-8, 0],
      [0, 8],
      [-8, 1],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-81, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [57, 0],
      [0, -8],
      [73, 0],
      [0, 17],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [12874, 4853],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [81, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [65, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, -17],
      [9, 0],
      [0, -139],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-73, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 57],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [56, 0],
    ],
    [
      [13409, 4952],
      [0, -8],
      [57, 0],
      [0, -9],
      [64, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-72, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [32, 0],
      [0, -9],
      [65, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 98],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [65, 0],
    ],
    [
      [16331, 5737],
      [0, -32],
      [8, 0],
      [0, -41],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 122],
      [56, 0],
    ],
    [
      [11830, 6764],
      [0, -12],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -81],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -74],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -58],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -91],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, -1],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-57, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [48, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -123],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -172],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -14],
      [0, -3],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -6],
      [0, -2],
      [4, 0],
      [3, 0],
      [1, -8],
      [9, 0],
      [0, -9],
      [11, 0],
      [45, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [-1, -8],
      [9, 0],
      [0, -11],
      [2, -4],
      [14, -1],
      [0, -16],
      [8, 0],
      [-1, -10],
      [9, 1],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -114],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-81, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-64, 0],
    ],
    [
      [12081, 7006],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-31, 0],
    ],
    [
      [12089, 7012],
      [0, -6],
      [-8, 0],
    ],
    [
      [18921, 2637],
      [1, -10],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [97, 0],
      [0, -8],
      [25, 0],
      [0, 18],
      [0, 15],
      [-10, 0],
      [2, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 3],
      [0, 6],
      [-3, 0],
      [-5, 0],
      [0, 8],
      [-8, 0],
      [1, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [2, 18],
      [-10, -1],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 1],
      [0, 3],
      [0, 12],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 33],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-16, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 1],
      [0, 65],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [-41, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 41],
      [8, 0],
      [0, 115],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [49, 0],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [24, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -7],
      [24, 0],
      [0, -17],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [81, 0],
      [0, 9],
      [33, 0],
      [0, -9],
      [48, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [13, 0],
    ],
    [
      [16437, 7522],
      [0, -9],
      [56, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -17],
      [56, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -16],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [49, 0],
      [0, -16],
      [48, 0],
      [0, -17],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -238],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -115],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -237],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -50],
      [9, 0],
      [0, -73],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [9, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-129, 0],
      [0, -8],
      [-146, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-57, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [25, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [65, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
    ],
    [
      [13651, 7677],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -106],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [33, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [41, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -65],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-49, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -155],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-16, 0],
      [0, -99],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -42],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, -1],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [0, -17],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -37],
      [0, -4],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -13],
      [0, -45],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [10, 0],
      [-1, -8],
      [3, 0],
      [5, 0],
      [0, -13],
      [0, -4],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -66],
      [-4, 0],
      [-4, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [-1, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [2, -8],
      [7, 0],
      [0, -8],
      [8, 0],
      [0, -30],
      [0, -60],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-4, 0],
      [-4, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, -1],
      [0, -7],
      [-4, 0],
      [-4, 0],
      [0, -7],
      [-8, -1],
      [0, -6],
      [0, -3],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -7],
      [0, -9],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-15, 0],
      [-10, 0],
      [0, -8],
      [-49, 0],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [-7, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 12],
      [0, 13],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-130, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -73],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 66],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [25, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 140],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 7],
      [8, 0],
      [0, 42],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 139],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [32, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, -1],
      [0, -16],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 41],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 7],
      [24, 1],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 65],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 73],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 90],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -24],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -131],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [56, 0],
      [0, -82],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -57],
      [-9, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-48, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 147],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 107],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 148],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -81],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -148],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -99],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [65, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [-9, 0],
      [0, -57],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, -1],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -25],
      [24, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -16],
      [-32, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -74],
      [9, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -41],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 17],
      [-41, 0],
      [0, -9],
      [-121, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 40],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-25, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [-17, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-49, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 172],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 156],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 16],
      [48, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [40, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [18040, 7800],
      [0, -25],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, 17],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, -9],
      [32, 0],
      [25, 0],
      [0, -7],
      [24, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [17, 0],
    ],
    [
      [19011, 7808],
      [-40, -96],
    ],
    [
      [18836, 7194],
      [-11, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-17, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 50],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 49],
      [-9, 0],
      [0, 98],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, -7],
      [32, 0],
      [0, -9],
      [81, 0],
      [0, 9],
      [25, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, -8],
    ],
    [
      [11913, 6834],
      [55, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [-2, 26],
      [10, -1],
      [0, 16],
    ],
    [
      [12138, 7073],
      [0, 3],
    ],
    [
      [12589, 8266],
      [10, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, -16],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-97, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 31],
    ],
    [
      [12980, 9109],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -99],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-64, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-121, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [49, 0],
    ],
    [
      [19019, 7832],
      [-8, 1],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -115],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-121, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-65, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, 17],
      [32, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 139],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-49, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [4, 0],
    ],
    [
      [18521, 9150],
      [116, -298],
      [202, -364],
      [129, -355],
      [63, -279],
      [-10, -22],
      [-2, 0],
    ],
    [
      [13239, 9224],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-97, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-121, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-32, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 99],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [17772, 9322],
      [0, -8],
      [17, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [-16, 0],
      [0, -24],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-81, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-89, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 66],
      [-8, 0],
      [0, 106],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [97, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [18189, 9502],
      [4, -5],
    ],
    [
      [18193, 9497],
      [0, -11],
      [9, 0],
      [-1, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-48, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -16],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 123],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [8, 0],
      [0, 33],
      [-8, 0],
      [-9, 0],
      [0, 41],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [40, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-16, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [48, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -66],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [43, 0],
      [22, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [10, 1],
      [-2, -9],
      [9, 0],
      [-1, -9],
      [8, 0],
      [0, -16],
      [12, 0],
    ],
    [
      [17351, 9707],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [40, 0],
    ],
    [
      [18015, 9756],
      [0, -8],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [-8, 0],
      [0, -9],
      [-56, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 3],
      [0, 5],
      [16, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [16769, 9920],
      [0, -9],
      [72, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -81],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-130, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-16, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [106, 0],
      [0, 8],
      [72, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 82],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [65, 0],
    ],
    [
      [17772, 9944],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -6],
      [0, -10],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 9],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [17408, 10051],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
    ],
    [
      [17505, 10009],
      [40, -19],
      [122, -28],
    ],
    [
      [17667, 9962],
      [0, -42],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-97, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
    ],
    [
      [18056, 10484],
      [-46, -54],
      [-76, -41],
    ],
    [
      [17934, 10389],
      [0, 5],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
    ],
    [
      [17853, 10362],
      [-120, -22],
      [-135, -4],
      [-74, 15],
      [-81, -13],
      [-89, -107],
      [34, -123],
    ],
    [
      [17388, 10108],
      [-37, 0],
      [0, 24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -114],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, -16],
      [8, 0],
      [0, -107],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 139],
      [-8, 0],
      [0, 139],
      [8, 0],
      [0, 50],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 90],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [122, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [21, 0],
      [20, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
    ],
    [
      [15077, 9797],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-7, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -7],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-81, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 42],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-97, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [33, 0],
      [0, 9],
      [97, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [49, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-33, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-32, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 66],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 106],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [113, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [97, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [97, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [122, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 33],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [56, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -66],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [49, 0],
      [0, 8],
      [7, 0],
    ],
    [
      [18088, 10518],
      [0, 7],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-14, 0],
      [-35, 0],
      [0, 57],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-57, 0],
      [0, -49],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-41, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-57, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-56, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 156],
      [-8, 0],
      [0, 52],
    ],
    [
      [12073, 10394],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, 25],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [40, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-33, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-72, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-6, 0],
      [-19, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 115],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 59],
      [0, 23],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 66],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 98],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [14, 0],
    ],
    [
      [14307, 11777],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 82],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
    ],
    [
      [15457, 12170],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [40, 0],
    ],
    [
      [15627, 12228],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [15389, 12547],
      [11, 6],
    ],
    [
      [15400, 12553],
      [0, -6],
      [-11, 0],
    ],
    [
      [15659, 12616],
      [59, 6],
      [103, 44],
    ],
    [
      [15821, 12666],
      [0, -21],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-7, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 37],
    ],
    [
      [16218, 12262],
      [0, 6],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, -1],
    ],
    [
      [16186, 12702],
      [62, -28],
    ],
    [
      [13975, 12724],
      [0, -22],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [56, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 16],
      [73, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-105, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-7, 0],
    ],
    [
      [15862, 12680],
      [79, 20],
      [109, 38],
      [103, -26],
    ],
    [
      [16153, 12712],
      [-8, -2],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-16, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-4, 0],
      [-4, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [7, 0],
      [0, 32],
      [9, 0],
      [0, 9],
      [8, 2],
    ],
    [
      [15318, 12540],
      [66, 7],
    ],
    [
      [15384, 12547],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-56, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, 9],
      [65, 0],
      [0, 8],
      [80, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [89, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -131],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [1, -17],
      [-8, 2],
      [-1, -34],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -188],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -40],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -33],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-105, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-82, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-72, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-10, 0],
      [-6, 0],
      [0, 16],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [-1, 15],
      [-7, 2],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-6, 0],
      [-2, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, -1],
      [0, 4],
      [0, 13],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-12, 0],
      [-13, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-64, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 42],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 14],
      [0, 19],
      [9, 0],
      [0, 32],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [16, 0],
      [2, 18],
    ],
    [
      [14220, 12892],
      [123, -152],
      [5, -6],
      [161, -64],
      [200, -99],
      [235, -58],
      [208, 10],
      [166, 17],
    ],
    [
      [11725, 12129],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 65],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [49, 0],
      [0, 7],
      [49, 0],
      [0, 5],
    ],
    [
      [11029, 13165],
      [19, -1],
      [175, 44],
      [250, 43],
      [72, -1],
    ],
    [
      [9725, 13791],
      [35, -22],
      [92, -83],
      [106, -137],
      [80, -56],
      [123, -129],
    ],
    [
      [10161, 13364],
      [1, -7],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-122, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-64, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 66],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-40, 0],
      [0, -82],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [89, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [81, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [7, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -50],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, -9],
      [-113, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [65, 0],
      [0, 9],
      [56, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -196],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
    ],
    [
      [9230, 13701],
      [9, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
    ],
    [
      [9215, 14435],
      [45, 8],
      [188, 11],
      [121, -36],
      [44, -137],
      [-21, -88],
      [23, -210],
      [-7, -61],
      [59, -97],
      [16, -10],
    ],
    [
      [9683, 13815],
      [-6, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -15],
    ],
    [
      [24213, 10865],
      [-126, -29],
      [-176, -58],
      [-69, -142],
      [-50, -175],
      [-59, -109],
    ],
    [
      [23733, 10352],
      [-37, 8],
      [-74, 8],
      [-45, 18],
    ],
    [
      [23577, 10386],
      [0, 57],
      [-8, 0],
      [0, 90],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [9, 0],
      [0, 24],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-56, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 57],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [80, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [41, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -8],
      [-8, 0],
    ],
    [
      [23528, 10411],
      [-6, 7],
      [-20, 0],
      [-155, 3],
      [-147, 23],
      [-34, 0],
      [-27, 7],
      [-93, 29],
      [-127, 77],
      [-112, 83],
      [-138, 234],
      [-66, 82],
      [-227, 72],
      [-113, 70],
      [-73, 69],
      [-67, 48],
      [-59, 90],
      [-53, 62],
      [8, 68],
      [-172, 159],
      [-6, 27],
      [-5, 156],
      [-19, 75],
      [2, 102],
      [-65, 123],
      [-119, 124],
      [-87, 43],
      [-67, 21],
      [-99, 104],
      [-181, 37],
      [-39, 62],
      [-143, 69],
    ],
    [
      [20823, 13505],
      [93, 12],
      [190, 58],
      [128, 66],
      [110, 113],
      [169, 99],
      [123, 121],
      [69, 87],
      [135, 65],
      [135, 25],
      [95, 66],
      [56, 68],
    ],
    [
      [15101, 13136],
      [0, -28],
      [59, -117],
      [18, -88],
      [39, -96],
      [19, -61],
      [32, -89],
      [26, -89],
      [27, -21],
      [-3, -7],
    ],
    [
      [14220, 12892],
      [14, -1],
      [0, 8],
      [25, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [65, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [64, 0],
      [0, -41],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, -7],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
    ],
    [
      [11029, 13165],
      [0, 4],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -26],
    ],
    [
      [10292, 13611],
      [0, -41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
    ],
    [
      [10503, 13733],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -40],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -40],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -4],
      [0, -12],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 65],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 123],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [25, 0],
    ],
    [
      [9725, 13791],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [32, 0],
      [0, -7],
      [32, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -139],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [-16, 0],
      [0, -17],
      [-26, -1],
    ],
    [
      [12763, 13293],
      [-2, 15],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [81, 0],
      [0, -9],
      [73, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [65, 0],
      [0, -8],
      [121, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
    ],
    [
      [10975, 27286],
      [101, -180],
      [211, -697],
      [316, -427],
      [372, -312],
      [287, -210],
    ],
    [
      [12262, 25460],
      [-106, -177],
      [-138, -228],
      [2, -285],
      [111, -219],
      [77, -212],
      [43, -245],
      [104, -226],
      [45, -157],
      [-44, -203],
      [-64, -237],
      [-64, -216],
      [-77, -182],
      [-118, -242],
      [-171, -207],
      [-145, -209],
      [-224, -165],
      [-159, -222],
      [-75, -93],
      [-130, -114],
      [-132, -289],
      [-126, -297],
      [-11, -264],
      [178, -214],
      [186, -186],
      [211, -235],
      [226, -153],
      [247, -113],
      [287, -127],
      [253, -188],
      [125, -178],
      [62, -273],
      [2, -313],
      [69, -292],
      [-206, -316],
      [-8, -495],
      [-31, -244],
      [7, -14],
      [28, -299],
      [26, -496],
      [226, -574],
      [171, -227],
      [285, -236],
      [312, -270],
      [347, -203],
      [280, -114],
      [262, 3],
      [7, -7],
      [135, 11],
      [-2, -8],
    ],
    [
      [14545, 15010],
      [-27, -134],
      [-37, -169],
      [78, -164],
      [-9, -136],
      [119, -111],
      [181, -50],
      [121, -30],
      [159, -111],
      [-69, -141],
      [3, -218],
      [-9, -128],
      [24, -225],
      [24, -149],
      [0, -42],
    ],
    [
      [15103, 13202],
      [-51, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 49],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -82],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-97, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-64, 0],
      [0, 8],
      [-146, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -58],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -10],
    ],
    [
      [12220, 13405],
      [-9, 1],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-65, 0],
      [0, 9],
      [-56, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 42],
      [8, 0],
      [0, 32],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -115],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 66],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 91],
      [-8, 0],
      [0, 40],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -50],
      [-8, 0],
      [0, -16],
      [-41, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-114, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [-48, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 74],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 49],
      [-9, 0],
      [0, 66],
      [9, 0],
      [0, 41],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-7, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 25],
      [7, 0],
      [0, 24],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [7, 0],
      [0, -24],
      [9, 0],
      [0, -66],
      [-9, 0],
      [0, -16],
      [-7, 0],
      [0, -25],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 16],
      [-113, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -98],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-10, 0],
    ],
    [
      [9413, 24623],
      [-16, 90],
      [-31, 129],
      [-99, 158],
      [-64, 164],
      [-99, 111],
      [-2, 15],
      [-22, 182],
      [29, 135],
      [17, 210],
      [35, 122],
      [-9, 251],
      [37, 224],
      [37, 196],
      [69, 155],
      [103, 114],
      [28, 87],
      [69, 87],
      [102, 53],
      [101, 19],
      [142, 24],
      [195, 17],
      [255, -4],
      [7, 0],
      [162, 45],
      [155, 24],
      [235, 3],
      [126, 52],
    ],
    [
      [18099, 24746],
      [58, -11],
      [261, -80],
      [584, -119],
      [439, 88],
      [284, 63],
      [189, 99],
      [366, 123],
      [431, 26],
      [244, 111],
      [491, -1],
      [512, 18],
      [342, -74],
      [5, -1],
    ],
    [
      [22305, 24988],
      [3, -157],
      [32, -95],
      [-159, -263],
      [-3, -169],
      [-90, -155],
      [-56, -134],
      [-49, -163],
      [-117, -160],
      [-76, -108],
      [-88, -66],
      [-75, -47],
      [-48, -67],
      [-37, -216],
      [-104, -182],
      [-2, -101],
      [83, -280],
      [-29, -169],
      [-36, -115],
      [29, -327],
      [-30, -182],
      [17, -204],
      [-106, -331],
      [4, -150],
      [-17, -196],
      [12, -143],
      [-32, -264],
      [-31, -271],
      [-22, -102],
      [-30, -183],
      [-84, -209],
      [39, -129],
      [11, -143],
      [12, -95],
      [38, -116],
      [37, -239],
      [25, -102],
      [-1, -68],
      [19, -95],
      [125, -145],
      [132, -151],
      [249, 9],
      [73, -42],
      [141, 4],
      [136, 73],
      [163, 65],
      [108, 52],
      [244, 98],
      [183, 146],
      [176, 51],
      [182, 24],
      [135, 26],
    ],
    [
      [23391, 19002],
      [-242, -260],
      [-321, -695],
      [-121, -419],
      [-23, -73],
      [-182, -583],
      [-28, -188],
    ],
    [
      [20325, 16031],
      [-240, 182],
      [-312, 223],
      [-352, 250],
      [-241, 93],
      [-462, 103],
      [-321, 114],
      [-159, 165],
      [9, 136],
      [-51, 141],
    ],
    [
      [18196, 17438],
      [-52, 146],
      [71, 257],
      [110, 141],
      [137, 119],
      [10, 204],
      [17, 223],
      [-185, 207],
      [-171, 254],
      [-203, 337],
      [-217, 309],
      [-144, 226],
      [-126, 111],
      [-137, 233],
      [-171, 268],
      [-122, 369],
      [-148, 403],
      [-69, 334],
      [-189, 363],
      [-183, 295],
      [-142, 396],
      [-235, 445],
      [-202, 391],
      [-121, 396],
      [-111, 225],
      [-76, 321],
      [-28, 333],
      [3, 183],
      [86, 142],
    ],
    [
      [15598, 25069],
      [183, 5],
      [645, -65],
      [364, 62],
      [616, -188],
      [693, -137],
    ],
    [
      [25601, 8070],
      [16, 0],
      [0, -5],
    ],
    [
      [24718, 8422],
      [8, 0],
      [0, -14],
    ],
    [
      [24395, 8706],
      [0, 27],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -103],
    ],
    [
      [24459, 9283],
      [0, -18],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
    ],
    [
      [24386, 9036],
      [4, 131],
      [42, 108],
      [27, 8],
    ],
    [
      [25641, 8059],
      [0, 11],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [56, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [41, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-24, 0],
      [0, -16],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [-1, -9],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
    ],
    [
      [24751, 8356],
      [0, 4],
      [0, 62],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 2],
      [0, 14],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 98],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 25],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 7],
    ],
    [
      [24508, 9296],
      [99, 24],
      [108, 25],
      [128, -2],
      [82, 53],
      [8, 115],
      [49, 87],
      [-19, 130],
      [-38, 95],
      [-22, 15],
    ],
    [
      [24903, 9838],
      [26, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, -9],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 17],
      [-32, 0],
      [0, 7],
      [-8, 0],
      [0, -7],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
    ],
    [
      [24702, 9879],
      [-38, 24],
      [62, 114],
      [42, 67],
      [1, 92],
    ],
    [
      [27646, 10641],
      [72, -207],
      [-38, -257],
      [79, -131],
      [-51, -236],
      [3, -218],
      [65, -143],
      [-96, -141],
      [5, -68],
      [12, -82],
      [-101, -66],
      [-69, -101],
      [-90, -107],
      [-56, -162],
      [-77, -182],
      [5, -122],
      [-58, -230],
      [-90, -222],
      [-97, -141],
      [-12, -12],
    ],
    [
      [18196, 17438],
      [-1347, -684],
      [238, -1332],
      [-1074, 121],
      [-1432, 0],
      [0, -485],
      [-36, -48],
    ],
    [
      [12262, 25460],
      [358, -263],
      [511, -56],
      [496, -138],
      [551, -3],
      [687, 50],
      [733, 19],
    ],
    [
      [32442, 6106],
      [-27, -2],
      [-113, 83],
      [-13, 41],
      [1, 41],
    ],
    [
      [32290, 6269],
      [55, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
    ],
    [
      [33146, 12586],
      [200, -396],
      [93, -532],
      [206, -568],
      [-35, -516],
      [-188, -384],
      [-104, -644],
      [-91, -367],
      [-32, -127],
      [78, -620],
      [-2, -537],
      [24, -598],
      [176, -418],
      [-239, -152],
      [-14, -234],
    ],
    [
      [33218, 6493],
      [-10, -2],
      [-209, -44],
      [-102, -39],
      [-41, -81],
      [-55, -81],
      [-76, -93],
      [-122, -53],
      [-72, 3],
    ],
    [
      [32531, 6103],
      [0, 11],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-17, 0],
    ],
    [
      [32255, 6359],
      [-4, 5],
      [23, 177],
      [9, 149],
      [-5, 95],
      [36, 101],
      [-26, 69],
      [22, 115],
      [-66, 96],
      [-187, 64],
      [-107, 36],
      [-242, 4],
      [-154, 43],
      [-148, -11],
      [-134, 30],
      [-72, 103],
      [-100, 90],
      [-181, 44],
      [-188, 10],
      [-115, -59],
      [-135, -25],
      [-39, 0],
    ],
    [
      [28046, 7310],
      [0, 15],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -19],
    ],
    [
      [32330, 13799],
      [99, 59],
      [-65, -88],
      [98, -83],
      [442, -551],
      [221, -507],
      [21, -43],
    ],
    [
      [15659, 12616],
      [0, 4],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [7, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-7, 0],
      [0, -25],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -3],
    ],
    [
      [15862, 12680],
      [0, 6],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -15],
    ],
    [
      [16186, 12702],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [6, 2],
    ],
    [
      [15748, 13087],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -98],
      [-40, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [15389, 12547],
      [-5, 0],
    ],
    [
      [15101, 13136],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [9, 0],
      [0, -7],
      [105, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -2],
    ],
    [
      [16048, 13169],
      [0, -9],
      [57, 0],
      [0, -7],
      [40, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [56, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [16, 0],
    ],
    [
      [20152, 13467],
      [0, 37],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 41],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [56, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -50],
      [-8, 1],
    ],
    [
      [20007, 13453],
      [-16, 2],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-81, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-138, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 81],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -66],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [105, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [1, -8],
      [32, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [66, 0],
    ],
    [
      [17198, 12979],
      [0, 18],
      [-9, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [49, 0],
      [0, 17],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -15],
    ],
    [
      [16361, 12931],
      [-5, 0],
      [0, 66],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, -9],
      [-56, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [2, 9],
    ],
    [
      [8309, 39696],
      [-200, -330],
      [-29, -544],
      [-258, -1261],
      [-742, -3629],
      [-70, -169],
      [2, -306],
      [248, -79],
      [462, -130],
      [452, 46],
      [445, 95],
      [344, 62],
      [392, 115],
      [316, -59],
      [522, -132],
      [159, -179],
      [75, -403],
      [135, -389],
      [-59, -720],
      [-73, -332],
      [-50, -651],
      [-62, -502],
      [-285, -539],
      [-153, -324],
      [-74, -454],
      [124, -655],
      [244, -310],
      [347, -149],
      [112, -66],
      [162, -95],
      [180, -321],
    ],
    [
      [4925, 25432],
      [58, 182],
      [40, 421],
      [259, 152],
      [345, 123],
      [122, 100],
      [9, 156],
      [-151, 193],
      [-44, 211],
      [79, 305],
      [89, 87],
      [95, 52],
      [142, 86],
      [211, 126],
      [205, 160],
      [288, 348],
      [160, 357],
      [54, 448],
      [-66, 477],
      [-198, 262],
      [-100, 470],
      [-359, 279],
      [-373, 189],
      [-529, 207],
      [-471, -26],
      [-493, -31],
      [-12, -1],
      [-407, -177],
      [-362, 74],
      [-126, 62],
      [-27, 43],
      [-92, 158],
      [-85, 131],
      [-34, 59],
      [-32, 106],
      [-5, 40],
      [14, 13],
      [90, 155],
      [266, 247],
      [163, 92],
      [400, 170],
      [310, 42],
      [292, 213],
      [224, 98],
      [119, 317],
      [20, 408],
      [-43, 205],
      [4, 278],
      [-55, 355],
      [-49, 259],
      [26, 428],
      [66, 277],
      [161, 419],
      [142, 248],
      [24, 41],
      [80, 45],
      [286, 160],
      [211, 188],
      [199, 178],
      [29, 16],
      [161, 90],
      [263, 430],
      [154, 405],
      [52, 265],
      [95, 474],
      [180, 371],
      [61, 406],
      [-88, 416],
      [-96, 301],
      [-102, 342],
      [-83, 280],
      [-143, 349],
      [76, 175],
      [33, -9],
      [15, -5],
      [190, -336],
      [318, -271],
      [402, -136],
      [345, 37],
      [39, -16],
      [343, 15],
    ],
    [
      [23416, 7890],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [73, 0],
      [0, -8],
      [126, 0],
    ],
    [
      [23034, 8602],
      [0, -16],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [22338, 6792],
      [-45, 307],
      [1, 74],
      [29, 122],
      [-113, 83],
      [-80, 56],
      [-107, 56],
      [-80, 42],
      [15, 95],
      [-59, 103],
      [-18, 102],
      [-11, 163],
      [-4, 163],
      [50, 169],
      [28, 115],
      [137, 154],
      [56, 87],
      [34, 54],
      [45, 20],
    ],
    [
      [22216, 8757],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -33],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -49],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [17, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -41],
      [8, 0],
      [0, -49],
    ],
    [
      [23438, 9379],
      [10, 4],
    ],
    [
      [23448, 9383],
      [0, -4],
      [-10, 0],
    ],
    [
      [24508, 9296],
      [0, 34],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -15],
    ],
    [
      [24386, 9036],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -6],
    ],
    [
      [23550, 8160],
      [-135, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 24],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 139],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 74],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 147],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [73, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 21],
      [0, 11],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [16, 0],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [106, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -12],
      [0, -4],
      [8, 0],
      [0, -8],
      [17, 1],
      [-1, -9],
      [15, 0],
    ],
    [
      [24702, 9879],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 40],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-9, 0],
      [0, -90],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 4],
    ],
    [
      [23998, 9711],
      [44, 26],
      [-84, 157],
      [-113, 131],
      [-85, 124],
      [-37, 184],
      [10, 19],
    ],
    [
      [20476, 6589],
      [33, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [40, 0],
      [0, -9],
      [33, 0],
      [0, -7],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
    ],
    [
      [19011, 7808],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [48, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [48, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -57],
      [-9, 0],
      [0, -49],
      [-7, 0],
      [0, -58],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -46],
    ],
    [
      [21577, 8446],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [7, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -32],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 7],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [48, 0],
    ],
    [
      [19076, 8479],
      [0, -8],
      [57, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [122, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-32, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 6],
      [0, 43],
      [7, 0],
      [0, 58],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 81],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [33, 0],
    ],
    [
      [19999, 8479],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -90],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -189],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -7],
      [17, 0],
      [0, 7],
      [16, 0],
      [0, -7],
      [24, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [1, -15],
      [6, -3],
      [1, -15],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 26],
      [0, 7],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 42],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 42],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [41, 0],
    ],
    [
      [21893, 6631],
      [0, 7],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 17],
      [7, 0],
      [0, 8],
      [-7, 0],
      [0, 139],
      [7, 0],
      [0, 33],
      [-7, 0],
      [0, 33],
      [7, 0],
      [0, 16],
      [-7, 0],
      [0, 49],
      [7, 0],
      [0, 58],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 41],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 49],
      [-7, 0],
      [0, 9],
      [7, 0],
      [0, 16],
      [-7, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 98],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 33],
      [7, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [32, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [7, 0],
    ],
    [
      [18521, 9150],
      [29, 0],
      [0, 17],
      [40, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 17],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 2],
      [0, 6],
    ],
    [
      [18582, 9281],
      [0, -8],
      [8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 16],
      [8, 0],
    ],
    [
      [21108, 9371],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-57, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 155],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [20978, 9429],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -155],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-25, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-49, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 90],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [81, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [16, 0],
    ],
    [
      [18189, 9502],
      [4, 0],
      [0, -5],
    ],
    [
      [22557, 9543],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -66],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 16],
      [25, 0],
    ],
    [
      [19950, 9559],
      [0, -8],
      [-13, 0],
    ],
    [
      [20744, 9641],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [25, 0],
    ],
    [
      [20525, 9821],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -33],
      [9, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
    ],
    [
      [18153, 9960],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -32],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-56, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-15, 0],
      [-1, -9],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 50],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, 19],
      [0, 38],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [25, 0],
      [0, 16],
      [24, 0],
    ],
    [
      [17505, 10009],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -42],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -7],
    ],
    [
      [17845, 10271],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [17408, 10051],
      [0, 12],
      [0, 28],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-4, 0],
    ],
    [
      [17853, 10362],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-97, 0],
      [0, -40],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -6],
      [0, -2],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-16, 0],
    ],
    [
      [23998, 9711],
      [0, 4],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-105, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -29],
    ],
    [
      [23438, 9379],
      [-15, 0],
      [0, -5],
      [0, -3],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-64, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -5],
      [0, -28],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 81],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 57],
      [8, 0],
      [0, 73],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, -16],
      [33, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [153, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [202, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 24],
    ],
    [
      [19999, 8479],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-64, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [-1, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 49],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 123],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [65, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -106],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [-1, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-24, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-97, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-72, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 33],
      [-9, 0],
      [0, 82],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 3],
    ],
    [
      [19900, 9528],
      [10, -1],
      [0, -33],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -50],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -114],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -139],
      [8, 0],
      [0, -98],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -11],
      [0, -5],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -32],
      [7, 0],
      [0, -33],
      [9, 0],
      [0, -41],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-64, 0],
      [0, 8],
      [-146, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-113, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 15],
      [0, 10],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -17],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-48, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 197],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 106],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 98],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
    ],
    [
      [18056, 10484],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -9],
      [-6, 0],
      [-19, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 3],
    ],
    [
      [18129, 10484],
      [-9, 0],
      [0, 5],
    ],
    [
      [18115, 10493],
      [-11, 0],
      [-1, 8],
    ],
    [
      [18103, 10501],
      [-7, 0],
      [-1, 6],
    ],
    [
      [20881, 10681],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -123],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [130, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-146, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
    ],
    [
      [20886, 11016],
      [3, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [97, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 66],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-113, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 156],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
    ],
    [
      [23528, 10411],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-98, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-72, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -50],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-25, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 90],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-33, 0],
      [0, 16],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 7],
      [16, 0],
      [0, 50],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 156],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 65],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -16],
      [-25, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-122, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-7, 0],
    ],
    [
      [25430, 22115],
      [-289, -424],
      [-141, -371],
      [-244, -513],
      [-253, -281],
      [-282, -355],
      [-247, -329],
      [-175, -405],
      [-109, -107],
      [-206, -227],
      [-93, -101],
    ],
    [
      [22305, 24988],
      [162, -35],
      [428, -171],
      [269, -18],
      [334, -149],
      [584, -91],
      [433, 121],
      [407, 225],
      [224, 118],
      [146, -12],
      [257, -22],
      [129, -390],
      [181, -431],
      [-36, -584],
      [62, -742],
      [-223, -465],
      [-232, -227],
    ],
    [
      [31907, 4076],
      [-16, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [5, 0],
      [20, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
    ],
    [
      [31996, 3992],
      [0, 76],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, -50],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -106],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 115],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 74],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -20],
      [0, -5],
      [-3, 0],
      [-5, 0],
      [0, -6],
      [0, -10],
      [-8, 0],
      [0, -17],
      [-10, 2],
      [2, -26],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, -1],
      [0, -16],
      [-9, 0],
      [0, -4],
      [0, -12],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -82],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -73],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [12, 0],
      [4, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -33],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -81],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-65, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-97, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-121, 0],
      [0, 8],
      [-81, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-178, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-98, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-154, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-12, 0],
    ],
    [
      [34158, 4626],
      [8, -8],
      [16, -2],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-51, 0],
      [-30, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -58],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-16, -1],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 1],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 82],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-17, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-33, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 17],
      [-32, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [9, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [194, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -7],
      [57, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, -16],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [6, 0],
      [2, -4],
      [0, -3],
    ],
    [
      [31187, 4919],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -50],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [41, 0],
    ],
    [
      [31008, 4641],
      [1, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [56, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, 16],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [40, 0],
      [0, 17],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [73, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -24],
      [-5, 0],
    ],
    [
      [30985, 5844],
      [0, -9],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
    ],
    [
      [33503, 5861],
      [5, -9],
      [-6, 0],
      [1, 9],
    ],
    [
      [30329, 6024],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
    ],
    [
      [31106, 5917],
      [-16, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
    ],
    [
      [31325, 6204],
      [0, -8],
      [40, 0],
      [0, -9],
      [16, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-81, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [32442, 6106],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -81],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [41, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-65, 0],
      [0, 7],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, -9],
      [-80, 0],
      [0, -7],
      [-9, 0],
      [0, 7],
      [-16, 0],
      [0, -7],
      [-40, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, -1],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-49, 0],
      [0, 7],
      [-48, 1],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
    ],
    [
      [30563, 5099],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [3, 0],
      [13, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [64, 0],
      [0, -8],
      [9, 0],
      [0, -57],
      [-9, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [40, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [48, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 81],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [25, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [89, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [73, 0],
      [0, 9],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [154, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, -1],
      [0, -8],
      [2, 0],
    ],
    [
      [33503, 5861],
      [-9, -1],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-49, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 63],
    ],
    [
      [33218, 6493],
      [-4, -72],
      [289, -560],
    ],
    [
      [32255, 6359],
      [-8, 0],
      [0, 8],
      [-64, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [-9, 0],
      [0, 57],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 66],
      [8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [56, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-82, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [97, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [9, 0],
      [0, -32],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 24],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-56, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, -17],
      [24, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-122, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [-15, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-4, 0],
    ],
  ],
  transform: {
    scale: [0.000034311817456127695, 0.00003393973134052803],
    translate: [6.383670692856729, 4.347082921720922],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Oyigbo",
            id: "RV001",
            nameAbbr: "Oyigbo",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.96955897893,
            Shape_Area: 0.02778531094,
          },
        },
        {
          arcs: [
            [[6, 7, 8, 9, 10]],
            [[11]],
            [[12]],
            [[13]],
            [[14]],
            [[15]],
            [[16, 17, 18, 19, 20, 21, 22, 23, 24, 25]],
            [[26, 27]],
            [[28, 29]],
            [[30, 31, 32, 33, 34, 35, 36, 37]],
            [[38, 39]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Port Harcourt",
            id: "RV002",
            nameAbbr: "Port Harcourt",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.58424203417,
            Shape_Area: 0.01325090937,
          },
        },
        {
          arcs: [[40, 41, 42, 43, -3]],
          type: "Polygon",
          properties: {
            name: "Tai",
            id: "RV003",
            nameAbbr: "Tai",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.40710185425,
            Shape_Area: 0.00825325013,
          },
        },
        {
          arcs: [[44, 45, 46, 47, 48, 49, 50, 51]],
          type: "Polygon",
          properties: {
            name: "Abua/Odual",
            id: "RV004",
            nameAbbr: "Abua/Odual",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.26853143479,
            Shape_Area: 0.05469266951,
          },
        },
        {
          arcs: [[52, 53, -52, 54]],
          type: "Polygon",
          properties: {
            name: "Ahoada East",
            id: "RV005",
            nameAbbr: "Ahoada East",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.02855918005,
            Shape_Area: 0.02875056897,
          },
        },
        {
          arcs: [[-51, 55, 56, -55]],
          type: "Polygon",
          properties: {
            name: "Ahoada West",
            id: "RV006",
            nameAbbr: "Ahoada West",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.04359534763,
            Shape_Area: 0.03579515945,
          },
        },
        {
          arcs: [
            [[57]],
            [[58]],
            [[59]],
            [[60]],
            [[61, 62]],
            [[63, 64]],
            [[65, 66, 67, 68, 69, 70, 71, 72, -49, 73]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Akuku-Toru",
            id: "RV007",
            nameAbbr: "Akuku-Toru",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.58321236061,
            Shape_Area: 0.03946264693,
          },
        },
        {
          arcs: [
            [[74]],
            [[75]],
            [[76, 77]],
            [[78, 79, 80, 81, 82, 83, 84, 85, 86]],
            [[87]],
            [[88]],
            [[89]],
            [[90, 91]],
            [[92]],
            [[93]],
            [[94]],
            [[95]],
            [[96, 97]],
            [[98, 99]],
            [[100]],
            [[101]],
            [[102]],
            [[103]],
            [[104]],
            [[105, 106]],
            [[107, 108]],
            [[109]],
            [[110]],
            [[111, 112, 113]],
            [[114, 115, 116]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Andoni",
            id: "RV008",
            nameAbbr: "Andoni",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.21916507801,
            Shape_Area: 0.01530548134,
          },
        },
        {
          arcs: [
            [[117, 118]],
            [[119, 120]],
            [[121, 122, 123, 124, 125, 126, 127]],
            [[128, 129]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Asari-Toru",
            id: "RV009",
            nameAbbr: "Asari-Toru",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.11114220779,
            Shape_Area: 0.00745487004,
          },
        },
        {
          arcs: [
            [[130]],
            [[-81, 131]],
            [[132, -82]],
            [[133]],
            [[-84, 134]],
            [[135]],
            [[136]],
            [[137, -86]],
            [[138]],
            [[139]],
            [[140]],
            [[141]],
            [[142]],
            [[143]],
            [[-91, 144]],
            [[145]],
            [[146]],
            [[147]],
            [[148]],
            [[149]],
            [[150]],
            [[151]],
            [[152]],
            [[153]],
            [[154]],
            [[155]],
            [[156]],
            [[157, 158]],
            [[159]],
            [[-99, -98, 160, -106, 161]],
            [[162]],
            [[163]],
            [[164]],
            [[165]],
            [[166, 167]],
            [[168, 169, 170]],
            [[-108, 171]],
            [[172]],
            [[173, 174, 175]],
            [[176, 177, 178, -116, 179]],
            [[180, 181]],
            [[182, 183, 184, 185]],
            [[186, 187]],
            [[188, 189]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Bonny",
            id: "RV010",
            nameAbbr: "Bonny",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 7.16980508933,
            Shape_Area: 0.04179827146,
          },
        },
        {
          arcs: [
            [[190]],
            [[191]],
            [[192]],
            [[193]],
            [[194]],
            [[195]],
            [[196, -72]],
            [[197, -70]],
            [[-69, 198]],
            [[-167, 199]],
            [[200]],
            [[201]],
            [[202]],
            [[203, -174, 204]],
            [[-63, 205, -64, 206, -67, 207]],
            [[208]],
            [[209, 210]],
            [[211]],
            [[212]],
            [[213, 214]],
            [[215]],
            [[216]],
            [[217]],
            [[218]],
            [[219, 220, 221]],
            [[222, 223, 224, 225]],
            [[-118, 226]],
            [[227, -22]],
            [[-120, 228]],
            [[229]],
            [[230]],
            [[231]],
            [[232, 233]],
            [[234, 235]],
            [[236, 237, -24]],
            [[-125, 238]],
            [[239, 240]],
            [[241, 242, 243]],
            [[-127, 244, 245]],
            [[246, 247, -74, -48, 248]],
            [[-46, 249, 250]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Degema",
            id: "RV011",
            nameAbbr: "Degema",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 9.08749272354,
            Shape_Area: 0.06661126631,
          },
        },
        {
          arcs: [[251, 252, 253, 254, -31, 255, -4, -44]],
          type: "Polygon",
          properties: {
            name: "Eleme",
            id: "RV012",
            nameAbbr: "Eleme",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.60782219542,
            Shape_Area: 0.01009849027,
          },
        },
        {
          arcs: [
            [[256, -244, 257]],
            [[-128, -246, 258]],
            [[259]],
            [[260]],
            [[-247, 261]],
            [[-123, 262]],
            [[263, 264, 265, 266, -130, 267, -250, -45, -54, 268]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Emuoha",
            id: "RV013",
            nameAbbr: "Emuoha",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.26958954243,
            Shape_Area: 0.06065141446,
          },
        },
        {
          arcs: [[269, 270, 271, -6, 272, 273, 274]],
          type: "Polygon",
          properties: {
            name: "Etche",
            id: "RV014",
            nameAbbr: "Etche",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.09130234218,
            Shape_Area: 0.04618998616,
          },
        },
        {
          arcs: [
            [[-177, 275]],
            [[-183, 276]],
            [[-187, 277]],
            [[278, 279]],
            [[-179, 280, -182, 281, 282, 283, 284, -42, 285]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Gokana",
            id: "RV015",
            nameAbbr: "Gokana",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.59274679385,
            Shape_Area: 0.00737245184,
          },
        },
        {
          arcs: [[-274, 286, -265, 287]],
          type: "Polygon",
          properties: {
            name: "Ikwerre",
            id: "RV016",
            nameAbbr: "Ikwerre",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.0602429449,
            Shape_Area: 0.05466150993,
          },
        },
        {
          arcs: [
            [[288, 289]],
            [[290, 291, 292, 293, -113, 294, -117, -286, -41, -2, 295]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Khana",
            id: "RV017",
            nameAbbr: "Khana",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.96371684989,
            Shape_Area: 0.04222256575,
          },
        },
        {
          arcs: [
            [[-235, 296]],
            [[-240, 297]],
            [[-25, -238, 298]],
            [[299]],
            [[-233, 300, -242, -257, 301]],
            [[302]],
            [
              [
                -256, -38, 303, -30, 304, -40, 305, -28, 306, -266, -287, -273,
                -5,
              ],
            ],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Obio/Akpor",
            id: "RV018",
            nameAbbr: "Obio/Akpor",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.20463906812,
            Shape_Area: 0.02235980562,
          },
        },
        {
          arcs: [[307, -269, -53, -57, 308]],
          type: "Polygon",
          properties: {
            name: "Ogba/Egbema/Ndoni",
            id: "RV019",
            nameAbbr: "Ogba/Egbema/Ndoni",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.66315445565,
            Shape_Area: 0.05687145488,
          },
        },
        {
          arcs: [
            [[-185, 309]],
            [[310]],
            [[-170, 311, 312]],
            [[313, 314]],
            [[-283, 315, -280, 316, -190, 317]],
            [[-285, 318, 319, -252, -43]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Ogu/Bolo",
            id: "RV020",
            nameAbbr: "Ogu/Bolo",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.75980421419,
            Shape_Area: 0.00421512063,
          },
        },
        {
          arcs: [
            [[-158, 320]],
            [[-175, -204, 321]],
            [[322]],
            [[323]],
            [[324]],
            [[-312, -169, 325]],
            [[-211, 326]],
            [[327]],
            [[328]],
            [[329]],
            [[-214, 330]],
            [[331]],
            [[332, -8]],
            [[333]],
            [[-7, 334]],
            [[335]],
            [[-221, 336]],
            [[337]],
            [[338, -225, 339]],
            [[340, -314, 341, -253, -320]],
            [[342, -10, 343]],
            [[344, -223]],
            [[345, -17]],
            [[-19, 346]],
            [[347, -20]],
            [[-36, 348]],
            [[-34, 349]],
            [[350, -32, -255]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Okrika",
            id: "RV021",
            nameAbbr: "Okrika",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.23837389656,
            Shape_Area: 0.01586483157,
          },
        },
        {
          arcs: [[351, -271, 352]],
          type: "Polygon",
          properties: {
            name: "Omumma",
            id: "RV022",
            nameAbbr: "Omumma",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.65158687903,
            Shape_Area: 0.02453245663,
          },
        },
        {
          arcs: [
            [[353]],
            [[-79, 354]],
            [[355]],
            [[356]],
            [[-78, 357]],
            [[358]],
            [[359]],
            [[360]],
            [[361]],
            [[362]],
            [[-289, 363, 364]],
            [[365, -292, 366]],
            [[367, -114, -294]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Opobo/Nkoro",
            id: "RV023",
            nameAbbr: "Opobo/Nkoro",
            parent: "Rivers",
            parentAbbr: "RV",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.10202130693,
            Shape_Area: 0.01159273705,
          },
        },
      ],
    },
  },
};

const bayelsa = {
  type: "Topology",
  arcs: [
    [
      [31006, 1129],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [17616, 2447],
      [0, -8],
      [33, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [16, 0],
    ],
    [
      [25825, 2537],
      [0, -16],
      [24, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -40],
      [-154, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [24, 0],
    ],
    [
      [20960, 2545],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -50],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -7],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [40, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [32, 0],
      [0, 7],
      [73, 0],
      [0, 9],
      [48, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [25, 0],
      [0, -9],
      [64, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [89, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [41, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [64, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [32, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -115],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -47],
      [0, -141],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-32, 0],
      [-1, -8],
      [-48, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-186, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-113, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-138, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-138, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-259, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-56, 0],
      [0, -9],
      [-154, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-113, 0],
      [0, -8],
      [-138, 0],
      [0, -8],
      [-56, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 106],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 58],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 107],
      [-7, 0],
      [-1, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 42],
      [8, 0],
      [0, 16],
      [8, 0],
      [1, 16],
      [7, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
    ],
    [
      [17665, 2807],
      [0, -16],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [-32, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [40, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
    ],
    [
      [17559, 2938],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [25485, 3159],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -58],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -90],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [29833, 3129],
      [153, 68],
      [119, -104],
      [115, 46],
      [89, 87],
      [196, 85],
      [40, 21],
    ],
    [
      [30545, 3332],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-4, 0],
      [-20, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-48, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-219, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 90],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 3],
    ],
    [
      [28367, 3413],
      [133, -211],
      [281, -93],
      [134, 5],
      [282, -19],
      [262, -18],
      [155, 5],
      [170, 36],
    ],
    [
      [29784, 3118],
      [8, 1],
      [0, -42],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -57],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-56, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 50],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
    ],
    [
      [22474, 3568],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -107],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
    ],
    [
      [25396, 3568],
      [0, -40],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-56, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [26578, 3525],
      [16, 11],
    ],
    [
      [26594, 3536],
      [8, 8],
    ],
    [
      [26602, 3544],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, -9],
      [16, 0],
      [0, -81],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -114],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-81, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-57, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -57],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 106],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 14],
    ],
    [
      [31416, 4117],
      [11, 5],
    ],
    [
      [31427, 4122],
      [0, -5],
      [-11, 0],
    ],
    [
      [13773, 4125],
      [59, -95],
      [67, 0],
      [40, -160],
      [184, -7],
      [106, -50],
      [13, -47],
      [109, 45],
      [360, -155],
      [279, -195],
      [306, -189],
      [241, -85],
      [267, -114],
      [434, -204],
      [417, -7],
      [195, 30],
      [104, 3],
      [68, 24],
      [29, 93],
    ],
    [
      [17051, 3012],
      [7, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -65],
      [-9, 0],
      [0, -65],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -82],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-65, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 7],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-17, 0],
      [1, 8],
      [-24, 0],
      [0, 3],
      [0, 5],
      [-8, 0],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 81],
      [24, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [35, 0],
    ],
    [
      [19551, 4338],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -89],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -115],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -99],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -82],
      [9, 0],
      [0, -65],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-56, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -33],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [82, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [24, 0],
    ],
    [
      [22004, 4452],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -139],
      [8, 0],
      [0, -106],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-25, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-162, 0],
      [0, -8],
      [-56, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [-1, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 24],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [73, 0],
    ],
    [
      [27533, 4608],
      [0, -8],
      [41, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -73],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -131],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-64, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -82],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-98, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 40],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -114],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 81],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 7],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [121, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [25, 0],
      [0, 7],
      [24, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [105, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [64, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 90],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [40, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [6, -1],
      [2, -2],
      [0, -5],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-105, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-105, 0],
      [0, -8],
      [-106, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-56, 0],
      [0, -9],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-105, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-73, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-40, 0],
      [0, -9],
      [-113, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-90, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-121, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-138, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-88, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-56, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-121, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-106, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-56, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, -9],
      [-56, 0],
      [0, -8],
      [-187, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-154, 0],
      [0, -8],
      [-64, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 35],
      [0, 6],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [186, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -107],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [105, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [105, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 16],
      [-194, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [122, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [82, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [64, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [89, 0],
      [0, -8],
      [16, 0],
      [0, -57],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-57, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 123],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [57, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [40, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-41, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 82],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [17, 0],
      [-2, 26],
      [10, -1],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 7],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [21988, 4763],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [17, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
    ],
    [
      [37864, 4763],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-138, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 8],
      [7, 0],
      [0, 33],
      [-7, 0],
      [0, 8],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 58],
      [9, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
    ],
    [
      [30699, 3416],
      [8, 5],
    ],
    [
      [30707, 3421],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [24, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [33, 0],
    ],
    [
      [30958, 3626],
      [8, 8],
    ],
    [
      [30966, 3634],
      [8, 8],
    ],
    [
      [30974, 3642],
      [6, 8],
    ],
    [
      [30980, 3650],
      [10, 0],
      [1, 10],
      [7, -2],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, -1],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 26],
    ],
    [
      [31193, 3914],
      [8, 6],
    ],
    [
      [31201, 3920],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 123],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [1, -7],
      [7, -1],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [-1, 16],
      [17, 0],
      [0, 10],
    ],
    [
      [31492, 4151],
      [9, 4],
      [87, 40],
      [332, 137],
      [188, -10],
      [107, 5],
      [134, -57],
      [67, 6],
      [7, 20],
      [201, -44],
      [270, 36],
      [108, 59],
      [109, 93],
      [135, -2],
      [101, 32],
      [15, 116],
      [177, 139],
      [104, 169],
    ],
    [
      [33643, 4894],
      [19, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -417],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [90, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [9, 0],
      [0, 9],
      [153, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [97, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -40],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-90, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-64, 0],
      [0, -9],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-80, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, -9],
      [-56, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-138, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-138, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-138, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-88, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [81, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [73, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [56, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [73, 0],
      [0, -33],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-113, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-64, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-105, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-105, 0],
      [0, -8],
      [-105, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-89, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-17, 0],
      [0, -9],
      [-32, 0],
      [0, -7],
      [-32, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-57, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [-16, 0],
      [0, -5],
      [0, -3],
      [-16, -2],
      [0, -6],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-50, 1],
      [1, -10],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [-16, 0],
      [0, -5],
      [0, -3],
      [-8, 1],
      [0, -9],
      [-24, 1],
      [0, -9],
      [-28, 0],
      [-21, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 3],
      [0, 22],
      [17, 0],
      [7, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [-1, 10],
      [10, -2],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [105, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [16, 0],
      [0, 172],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 148],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 11],
    ],
    [
      [19818, 5680],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [40, 0],
    ],
    [
      [19211, 5868],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -156],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-73, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-121, 0],
      [0, -9],
      [-97, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [89, 0],
      [0, -8],
      [64, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -123],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -156],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-64, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-121, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 106],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 139],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 73],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -25],
      [32, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-40, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [16, 0],
      [0, 17],
      [65, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 131],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -57],
      [16, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-114, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -114],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [-2, 8],
    ],
    [
      [17088, 3126],
      [30, 95],
      [-106, 137],
      [-22, 293],
      [72, 311],
      [85, 283],
      [140, 324],
      [193, 295],
      [85, 284],
      [44, 237],
      [211, 118],
      [164, 161],
      [350, 14],
      [250, 111],
      [397, 34],
      [202, 24],
      [28, 21],
    ],
    [
      [35070, 6417],
      [0, -9],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [7, 0],
      [0, -9],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -73],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -99],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -164],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-64, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 148],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 114],
      [8, 0],
      [0, 123],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [73, 0],
      [0, 9],
      [56, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 1],
      [0, -9],
      [8, 0],
      [0, -16],
      [6, -1],
      [3, -7],
      [7, -1],
      [-2, -7],
      [10, -1],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [80, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [25, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [89, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [122, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
    ],
    [
      [34062, 6122],
      [-4, 8],
    ],
    [
      [34058, 6130],
      [-57, 128],
      [-62, 314],
      [-26, 54],
      [-103, 274],
      [-58, 150],
      [-93, 117],
      [-59, 110],
      [-37, 136],
      [-92, 151],
      [-44, 92],
    ],
    [
      [33427, 7656],
      [0, 62],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [74, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [97, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [130, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -50],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -114],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -49],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-88, 0],
      [0, 8],
      [-82, 0],
      [0, 9],
      [-64, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -81],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-13, 0],
    ],
    [
      [21648, 8082],
      [0, -4],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -123],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-138, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -131],
      [8, -1],
      [0, -139],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [41, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -131],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -115],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -16],
      [33, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-146, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [-16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-56, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-7, 0],
      [-1, -8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [41, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 139],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 139],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 106],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 41],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 7],
      [-41, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
    ],
    [
      [19260, 5901],
      [5, 5],
      [144, 174],
      [13, 7],
      [199, 221],
      [199, 227],
      [172, 248],
      [165, 262],
      [172, 242],
      [191, 160],
      [307, 286],
      [218, 146],
      [189, 91],
      [352, 96],
      [19, 0],
      [43, 16],
    ],
    [
      [36139, 8422],
      [0, -8],
      [105, 0],
      [0, -66],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-121, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [65, 0],
    ],
    [
      [35678, 8455],
      [0, -9],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [-1, -8],
      [-40, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [21680, 8094],
      [115, 43],
      [366, 170],
      [333, 267],
      [133, 128],
    ],
    [
      [22627, 8702],
      [8, -2],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -115],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -115],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -131],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-72, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 74],
      [-9, 0],
      [0, 7],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 42],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 114],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [48, 0],
      [0, -9],
      [65, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [25, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 123],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 131],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 114],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
    ],
    [
      [22663, 8738],
      [72, -243],
      [37, -205],
      [158, -199],
      [71, -239],
      [131, -179],
      [51, -204],
      [-45, -257],
      [18, -184],
      [17, -150],
      [10, -224],
      [-56, -135],
      [-35, -101],
      [-43, -156],
      [-29, -142],
      [-9, -149],
      [3, -204],
      [104, -259],
      [86, -83],
      [140, -64],
      [92, -123],
      [90, -247],
      [45, -156],
      [104, -199],
      [145, -165],
      [233, -181],
      [349, -60],
      [58, -130],
      [60, -28],
      [106, -77],
      [148, 11],
      [209, 94],
    ],
    [
      [24983, 4100],
      [8, 2],
      [0, -18],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -180],
      [8, 0],
      [0, -65],
      [9, 0],
      [0, -164],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-90, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 107],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-7, 0],
      [0, 8],
      [7, 0],
      [0, 49],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [73, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [113, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -98],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 123],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 57],
      [105, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [48, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-7, 0],
      [-1, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [1, 7],
      [16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [7, 0],
      [0, 16],
      [9, 0],
      [0, 49],
      [-9, 0],
      [0, 25],
      [-7, 0],
      [-1, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 66],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -74],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [24, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-25, 0],
      [0, -25],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 57],
      [32, 0],
      [0, 17],
      [-48, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 65],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-40, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 139],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [162, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -16],
      [56, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [40, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 24],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 196],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 50],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 156],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [33, 0],
      [0, -9],
      [65, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 115],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 131],
      [-8, 0],
      [0, 106],
      [8, 0],
      [0, 123],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 90],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 90],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 1],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 98],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -81],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -65],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -114],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -81],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -180],
      [25, 0],
      [0, -196],
      [8, 0],
      [0, -42],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, -1],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-194, 0],
      [0, 8],
      [-113, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 1],
      [0, 73],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 172],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 90],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 213],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 139],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [89, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [-1, 34],
    ],
    [
      [22659, 8734],
      [4, 4],
    ],
    [
      [36706, 9633],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -91],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -115],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [7, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -33],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-48, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-113, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [17, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 139],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 98],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 57],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [73, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [73, 0],
    ],
    [
      [35670, 10992],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [129, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [25, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -114],
      [9, 0],
      [0, -74],
      [8, 0],
      [0, -98],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 74],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 49],
      [9, 0],
      [0, 25],
      [7, 0],
      [0, 32],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 33],
      [-7, 0],
      [0, 33],
      [-9, 0],
      [0, 82],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 107],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 90],
      [-9, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
    ],
    [
      [35152, 11835],
      [0, -8],
      [32, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [33, 0],
    ],
    [
      [38916, 9927],
      [-8, 1],
      [0, -8],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -85],
      [0, -5],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -173],
      [-8, 0],
      [0, -81],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -123],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -42],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -114],
      [-8, 0],
      [0, -148],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -50],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -114],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -26],
      [0, -6],
      [4, 0],
      [4, -8],
      [-8, -1],
      [0, -8],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -123],
      [8, 0],
      [0, -98],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -131],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-7, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 107],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 33],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 40],
      [8, 0],
      [0, 115],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-25, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 115],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 24],
      [-57, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, -25],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-105, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 58],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [9, 0],
      [0, 40],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [49, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [7, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-64, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -82],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-7, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -106],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-7, 0],
      [0, -32],
      [-9, 0],
      [0, -50],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [7, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-89, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 65],
      [9, 0],
      [0, 57],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 57],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [114, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [97, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [121, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -98],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-72, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [89, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -57],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [56, 0],
      [0, 9],
      [41, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 131],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -74],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -115],
      [8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [162, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [65, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -57],
      [8, 0],
      [0, -58],
      [16, 0],
      [0, -24],
      [9, 0],
      [0, -58],
      [8, 0],
      [0, -98],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -156],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-56, 0],
      [0, -9],
      [-259, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-72, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-40, 0],
      [0, -7],
      [-49, 0],
      [0, 7],
      [-8, 0],
      [0, -7],
      [-25, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -18],
      [0, -7],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -7],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 2],
      [0, 6],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [-1, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [1, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -57],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [57, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [97, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [80, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -114],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-56, 0],
      [0, 9],
      [-41, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-49, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-130, 0],
      [0, -8],
      [-73, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-98, 0],
      [0, -8],
      [-64, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -16],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -114],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 57],
      [9, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [1, 32],
      [8, 0],
      [0, 42],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 98],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 114],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 99],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 147],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 180],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 197],
      [-8, 0],
      [0, 114],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [-1, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 147],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [41, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-32, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [122, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-17, 0],
      [0, 58],
      [-16, 0],
      [0, -115],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -32],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -8],
      [-121, 0],
      [0, -8],
      [-41, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-89, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -74],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -24],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-48, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [24, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [64, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -17],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-219, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [178, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [16, 0],
      [0, 16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -44],
    ],
    [
      [33411, 7690],
      [-57, 121],
      [-46, 89],
      [-44, 156],
      [-131, 281],
      [-143, 240],
      [-55, -33],
      [-120, 43],
      [-133, 104],
      [-151, 240],
      [-133, 91],
      [-65, 110],
      [-163, 362],
      [-38, 130],
      [2, 108],
      [-149, 349],
      [-84, 253],
      [-51, 123],
      [-67, 55],
      [-45, 89],
      [-20, 7],
      [-42, 45],
    ],
    [
      [31676, 10653],
      [9, 17],
      [-24, 204],
      [-104, 233],
      [-153, 70],
      [-134, 37],
      [-146, 111],
      [-213, 146],
      [-17, 204],
      [-43, 211],
      [-5, 116],
      [77, 215],
      [150, 134],
      [140, 316],
      [84, 230],
      [116, 194],
    ],
    [
      [31413, 13091],
      [257, -65],
      [271, 124],
      [2810, 1087],
      [532, 25],
      [403, -34],
      [405, -367],
      [190, -406],
      [57, -122],
      [110, -308],
      [59, -490],
      [127, -478],
      [148, -431],
      [122, -383],
      [304, -338],
      [406, -265],
      [446, -321],
      [433, -231],
      [275, -87],
      [148, -74],
    ],
    [
      [8821, 14978],
      [-28, -80],
      [-136, -106],
      [-268, 52],
      [-148, 36],
      [-148, -24],
      [-122, -73],
      [-102, -66],
      [-164, -153],
      [-56, -135],
      [-232, -200],
      [-22, -169],
      [-109, -87],
      [-35, -40],
      [-89, -114],
      [-189, -78],
      [-173, -23],
    ],
    [
      [6800, 13718],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -98],
      [0, -91],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-73, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 41],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [41, 0],
      [0, -65],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-88, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 73],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-18, 0],
      [1, 8],
      [-8, 0],
      [-8, 0],
      [2, 10],
      [-10, -2],
      [0, 9],
      [-8, 0],
      [1, 17],
      [-9, -1],
      [0, 16],
      [-8, 0],
      [1, 9],
      [-26, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [2, 0],
      [6, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-2, 0],
      [-6, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -35],
      [0, -96],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -57],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [1, -8],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -7],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [121, 0],
      [0, -9],
      [33, 0],
      [0, 9],
      [186, 0],
      [0, 8],
      [105, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [24, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [105, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -139],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [73, 0],
      [0, 8],
      [48, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -33],
      [-8, 0],
      [0, -115],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [21, 0],
    ],
    [
      [1757, 19946],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 41],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
    ],
    [
      [17862, 19321],
      [-47, -233],
      [-104, -208],
      [-125, -249],
      [-192, -262],
      [-340, -218],
      [-198, -207],
      [-204, -153],
      [-197, -105],
      [-196, -78],
      [-163, -113],
      [-230, -84],
      [-170, -78],
      [-277, -125],
      [-190, -92],
      [-312, -150],
      [-251, -166],
      [-297, -76],
      [-379, -150],
      [-291, -131],
      [-366, -197],
      [-304, -49],
      [-317, -110],
      [-277, -110],
    ],
    [
      [12435, 15977],
      [0, 7],
      [-97, 0],
      [0, 17],
      [-16, 0],
      [0, -41],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -6],
      [0, -2],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-138, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 34],
      [0, 23],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 42],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 115],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 49],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-129, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -114],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -65],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-267, 0],
      [0, 9],
      [-130, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-72, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-105, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -29],
      [0, -4],
      [-9, 2],
      [0, -26],
      [-8, 0],
      [0, -131],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -65],
      [-8, 0],
      [0, -221],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-178, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, -9],
      [-32, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 74],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-17, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 90],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [1, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [138, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [98, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [64, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 98],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [122, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 33],
      [122, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [57, 0],
      [0, 9],
      [16, 0],
      [0, 32],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-73, 0],
      [0, -7],
      [-65, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-25, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-80, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -16],
      [48, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -123],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-154, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 188],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 40],
      [-40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [7, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -106],
      [-8, 0],
      [0, -50],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-16, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -7],
      [-73, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-7, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 115],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, -57],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 65],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 99],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [57, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 106],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 98],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 65],
      [16, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 98],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -122],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-32, 0],
      [0, -57],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -41],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 24],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [-1, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [56, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 139],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-19, 0],
      [-5, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 221],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [3, 0],
      [5, 0],
      [0, 107],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-25, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-4, 0],
      [-4, 0],
      [1, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [1, 41],
      [-10, 0],
      [0, 32],
      [-8, 0],
      [0, 18],
      [0, 23],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-4, 0],
      [-4, 0],
      [0, 12],
      [0, 78],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 41],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-7, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, -1],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-6, 0],
      [-2, 0],
      [0, 8],
      [0, 17],
      [-8, 0],
      [0, 2],
      [0, 14],
      [-6, 1],
      [-2, 6],
      [0, 18],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [57, 0],
      [0, -9],
      [25, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [72, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [40, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [40, 0],
      [0, -9],
      [33, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [7, 0],
      [0, -32],
      [9, 0],
      [0, -42],
      [-9, 0],
      [0, -32],
      [-7, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [14, 0],
      [157, -131],
      [76, -65],
      [205, -282],
      [69, -74],
      [94, -101],
      [81, -87],
      [306, -264],
      [193, -78],
      [500, -301],
      [454, -212],
      [220, -200],
      [509, -91],
      [327, -182],
      [256, 29],
      [24, -6],
      [318, -88],
      [272, -256],
      [266, -168],
      [326, -230],
      [402, -109],
      [178, -275],
      [292, -249],
      [302, -26],
      [276, -5],
      [223, 105],
      [292, 118],
      [49, -11],
      [132, -27],
      [260, -140],
      [111, 56],
      [242, 122],
      [258, 138],
      [191, 153],
      [319, 225],
      [49, 10],
      [323, 59],
      [89, 100],
      [158, 201],
      [292, 199],
      [37, 20],
      [187, 99],
      [330, 35],
      [212, 60],
      [147, 42],
      [177, 140],
      [232, 187],
      [170, 112],
      [249, 57],
      [443, -75],
      [126, -132],
      [248, -58],
      [168, 10],
      [217, 71],
      [292, 192],
      [36, 28],
      [94, 72],
      [231, 180],
      [10, 3],
      [99, 36],
      [26, -15],
      [102, 12],
      [167, 75],
      [9, 4],
      [155, 58],
      [116, 66],
      [14, 29],
      [48, 99],
      [45, 57],
      [100, 125],
      [48, 80],
      [77, 162],
      [23, 176],
      [62, 108],
      [40, 48],
      [22, 26],
      [174, 364],
      [43, 189],
      [117, 155],
      [237, 84],
      [133, -104],
      [120, -84],
      [146, -47],
    ],
    [
      [17036, 26237],
      [-44, -182],
      [99, -138],
      [-23, -169],
      [10, -190],
      [-14, -61],
      [-70, -196],
      [64, -157],
      [-57, -210],
      [-57, -189],
      [-44, -223],
      [-43, -223],
      [-71, -210],
      [90, -252],
      [-7, 0],
      [-9, -143],
      [36, -285],
      [51, -171],
      [43, -279],
      [70, -212],
      [52, -157],
      [19, -34],
      [57, -259],
      [97, -212],
      [103, -267],
      [17, -197],
      [12, -129],
      [72, -124],
      [112, -117],
      [72, -137],
      [56, -300],
      [139, -104],
      [-7, -27],
      [-2, -122],
      [63, -272],
      [-26, -387],
      [28, -326],
      [-50, -197],
      [-12, -58],
    ],
    [
      [22095, 19514],
      [-23, 45],
      [-177, 308],
      [159, 276],
      [77, 182],
      [58, 257],
      [25, 305],
      [-90, 239],
      [11, 108],
    ],
    [
      [22135, 21234],
      [66, 88],
      [145, 242],
      [317, 497],
      [165, 214],
      [201, 397],
      [207, 316],
      [358, 496],
      [103, 147],
      [118, 236],
      [181, 397],
      [218, 194],
      [163, 119],
      [245, 166],
      [138, 181],
      [37, 243],
      [99, 298],
      [52, 290],
      [40, 387],
      [281, 348],
      [152, 276],
      [274, 321],
      [211, 166],
      [271, 151],
      [197, 92],
      [425, 74],
      [263, 43],
      [444, 27],
      [504, 11],
      [443, -41],
      [288, -66],
      [309, -60],
      [334, -110],
    ],
    [
      [29384, 27374],
      [73, -80],
      [71, -137],
      [120, -117],
      [137, -213],
      [134, -84],
      [72, -69],
      [57, -246],
      [37, -217],
      [-31, -251],
      [-86, -331],
      [-98, -236],
      [-132, -256],
      [-179, -248],
      [-388, -312],
      [-361, -273],
      [-278, -165],
      [-230, -111],
      [-210, -92],
      [-310, -35],
      [-250, -84],
      [-230, -118],
      [-94, -379],
      [-31, -298],
      [-32, -291],
      [-91, -203],
      [-236, -63],
      [-272, 256],
      [-211, 227],
      [-249, -16],
      [-57, -182],
      [-118, -249],
      [-30, -210],
      [-103, -107],
      [-209, -58],
      [-63, -135],
      [154, -50],
      [215, -17],
      [133, -43],
      [11, -204],
      [-49, -87],
      [-62, -101],
      [-138, -201],
      [-89, -135],
      [-97, -140],
      [-62, -88],
      [-90, -161],
      [-109, -134],
      [-102, -73],
      [-154, 57],
      [-108, -39],
      [-69, -141],
      [-56, -108],
      [-263, -50],
      [-244, -104],
      [-204, -146],
      [-178, -180],
      [-210, -139],
      [-97, -162],
      [-238, -179],
      [-278, -124],
      [-336, -8],
      [-160, 78],
      [-180, 104],
      [-214, 92],
      [-137, 6],
    ],
    [
      [30699, 3416],
      [0, 5],
      [8, 0],
    ],
    [
      [26578, 3525],
      [0, 11],
      [16, 0],
    ],
    [
      [26594, 3536],
      [0, 8],
      [8, 0],
    ],
    [
      [30958, 3626],
      [0, 8],
      [8, 0],
    ],
    [
      [30966, 3634],
      [0, 8],
      [8, 0],
    ],
    [
      [30974, 3642],
      [0, 8],
      [6, 0],
    ],
    [
      [31193, 3914],
      [0, 6],
      [8, 0],
    ],
    [
      [26724, 3920],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
    ],
    [
      [25550, 5132],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -66],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 74],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [24, 0],
    ],
    [
      [27784, 6008],
      [0, -9],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -65],
      [9, 0],
      [0, -58],
      [8, 0],
      [0, -131],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-56, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-48, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-17, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 73],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 49],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 106],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [33, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [64, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
    ],
    [
      [28068, 6065],
      [0, -8],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -98],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [9, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 42],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
    ],
    [
      [34062, 6122],
      [-3, 0],
      [-1, 8],
    ],
    [
      [23744, 9707],
      [0, -33],
      [9, 0],
      [0, -65],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [178, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [-1, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [1, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -115],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -40],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -25],
      [-7, 0],
      [-1, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [1, -16],
      [7, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -7],
      [129, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -148],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-73, 0],
      [0, -8],
      [-73, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 106],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 98],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 1],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 25],
      [9, 0],
      [0, 33],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [16, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 106],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [24, 0],
    ],
    [
      [22663, 8738],
      [36, 36],
      [281, 307],
      [197, 126],
      [191, 207],
      [244, 498],
      [76, 286],
    ],
    [
      [23688, 10198],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -139],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -25],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -65],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -49],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -115],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -24],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, -1],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -139],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -49],
      [9, 0],
      [0, -42],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -106],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -57],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [113, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [130, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
    ],
    [
      [34058, 6130],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-57, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-72, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-32, 0],
      [0, 7],
      [-40, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 58],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 106],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 42],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 81],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-105, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [57, 0],
      [0, 16],
      [-73, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [105, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -213],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 82],
      [-16, 0],
      [0, -91],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -163],
      [8, 0],
      [0, -99],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [98, 0],
      [0, -9],
      [97, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -115],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [6, 0],
    ],
    [
      [31492, 4151],
      [0, 7],
      [9, 0],
      [-1, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 197],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 90],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [41, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -90],
      [8, 0],
      [0, -74],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -131],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -123],
      [9, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -50],
      [-8, 0],
      [0, -65],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -40],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -11],
    ],
    [
      [31416, 4117],
      [-21, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 107],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 73],
      [8, 1],
      [0, 8],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-121, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 24],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 74],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 57],
      [-16, 0],
      [0, -65],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -25],
      [9, 0],
      [0, -90],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -66],
      [9, 0],
      [0, -65],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -81],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -106],
      [8, 0],
      [0, -131],
      [8, 0],
      [0, -57],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -115],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -114],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -81],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [1, -25],
      [-9, 0],
      [0, -7],
    ],
    [
      [29833, 3129],
      [0, 6],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -58],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
    ],
    [
      [28367, 3413],
      [0, 41],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 164],
      [9, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-41, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 24],
      [-17, 0],
      [0, 33],
      [9, 0],
      [0, 147],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 81],
      [8, 0],
      [0, 131],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 74],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 98],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [82, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 65],
      [-9, 0],
      [0, 58],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 50],
      [9, 0],
      [0, 73],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 74],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
    ],
    [
      [27784, 9657],
      [19, 0],
      [479, 87],
      [298, 137],
      [310, 84],
      [287, 280],
      [172, 234],
      [299, 199],
      [77, 202],
      [290, 56],
      [174, -84],
      [192, -159],
      [206, -140],
      [194, -85],
      [213, -85],
      [323, -39],
      [236, 77],
      [69, 121],
      [54, 111],
    ],
    [
      [33411, 7690],
      [0, -136],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-97, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [113, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 69],
    ],
    [
      [27752, 9658],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [89, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -16],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -50],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -204],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 16],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 156],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 65],
      [-16, 0],
      [0, -73],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -172],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -33],
      [41, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-203, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -74],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-81, 0],
      [0, 8],
      [-57, 0],
      [0, 32],
      [-8, 0],
      [0, 91],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 73],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 115],
      [-16, 0],
      [0, -123],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -115],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [41, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -81],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 7],
      [-40, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 172],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 123],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, -24],
      [41, 0],
      [0, -115],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -172],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -66],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -17],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -25],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, -1],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -123],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 90],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 33],
      [-9, 0],
      [0, 17],
      [9, 0],
      [0, 40],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 33],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 65],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-73, 0],
      [0, -9],
      [-24, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [32, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -66],
      [8, 0],
      [0, -40],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [-25, 0],
      [0, -17],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 155],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 1],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 156],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 107],
      [9, 0],
      [0, 32],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [81, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 98],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -74],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [130, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [24, 0],
      [0, 9],
      [17, 0],
      [0, 40],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [9, 0],
      [0, 74],
      [-9, 0],
      [0, 57],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 254],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -74],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -16],
      [49, 0],
      [0, -25],
      [8, 0],
      [0, -74],
      [8, 0],
      [0, -81],
      [8, 0],
      [0, -42],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-98, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -42],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -82],
      [-65, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 139],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 33],
      [9, 0],
      [0, 82],
      [-17, 0],
      [0, -74],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -123],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-40, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 24],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -41],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -106],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -73],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -131],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-97, 0],
      [0, -16],
      [89, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -57],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -107],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -81],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -66],
      [8, 0],
      [0, -65],
      [9, 0],
      [0, -66],
      [8, 0],
      [0, -139],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -9],
      [0, -15],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 6],
      [0, 11],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-49, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, -9],
      [-73, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [96, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 42],
      [-8, 0],
      [0, 32],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-64, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 65],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-41, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 148],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 98],
      [8, 1],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 74],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-129, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 50],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 65],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [80, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -50],
      [-8, 0],
      [0, -171],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-97, 0],
      [0, 131],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 25],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-19, 0],
    ],
    [
      [23693, 10214],
      [23, 85],
    ],
    [
      [23716, 10299],
      [353, 33],
      [188, -44],
      [313, -182],
      [399, 190],
      [123, 100],
      [2, 149],
      [-24, 150],
      [2, 136],
      [268, 341],
      [84, 217],
      [22, 108],
      [265, 212],
      [193, -146],
      [138, -219],
      [69, -273],
      [-117, -188],
      [407, -156],
      [227, -99],
      [-8, -136],
      [95, -355],
      [110, -225],
      [167, -91],
      [167, -78],
      [355, -81],
      [238, -4],
    ],
    [
      [27784, 9657],
      [-16, 1],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
    ],
    [
      [23716, 10299],
      [17, 66],
      [5, 326],
      [13, 339],
      [-118, 185],
      [-51, 212],
      [-83, 280],
      [-29, 278],
      [8, 523],
      [181, 343],
      [17, 224],
      [93, 324],
      [52, 346],
      [-63, 299],
      [49, 488],
      [-24, 204],
      [33, 380],
      [88, 447],
      [180, 322],
      [364, 496],
      [34, 22],
    ],
    [
      [24482, 16403],
      [95, 58],
      [203, 57],
      [122, 80],
      [250, 70],
      [188, -3],
      [269, 23],
      [157, 126],
      [37, 203],
      [36, 176],
      [146, 255],
      [123, 181],
      [200, 262],
      [333, 218],
      [412, 163],
      [95, 46],
      [54, 6],
      [162, 38],
      [222, 36],
      [258, 152],
      [348, 327],
      [158, 194],
      [233, 254],
      [311, 137],
      [326, 151],
      [197, 180],
      [288, 368],
      [314, 232],
      [184, 167],
      [189, 85],
      [305, 110],
      [26, 13],
    ],
    [
      [30723, 20768],
      [-16, -581],
      [-281, -541],
      [-3, -5],
      [-74, -434],
      [-228, -349],
      [-295, -362],
      [-281, -288],
      [-91, -277],
      [-24, -611],
      [-28, -496],
      [-49, -597],
      [68, -369],
      [235, -398],
      [261, -127],
      [333, -216],
      [327, -142],
      [236, -11],
      [222, 31],
      [178, -221],
      [-199, -697],
      [109, -328],
      [58, -599],
      [232, -59],
    ],
    [
      [22135, 21234],
      [-99, -134],
      [-210, -111],
      [-284, -84],
      [-296, -1],
      [-432, 292],
      [-37, 211],
      [-58, 157],
      [-336, 413],
      [-157, 309],
      [-123, 287],
      [-452, 320],
      [-106, 70],
      [-149, -66],
      [-387, -224],
      [-12, -339],
      [96, -273],
      [52, -110],
      [92, -137],
      [275, -59],
      [240, -140],
      [134, -43],
      [146, -111],
      [51, -144],
      [104, -226],
      [39, -102],
      [-3, -143],
      [34, -435],
      [-19, -318],
      [-39, -353],
      [0, -407],
      [-67, -406],
      [-54, -427],
      [-85, -304],
      [-148, -412],
      [37, -184],
      [-63, -195],
      [77, -185],
      [-14, -489],
      [-65, -270],
      [-84, -236],
      [-124, -148],
      [-177, -180],
      [-304, -62],
      [-302, 38],
      [-215, 24],
      [-340, 135],
      [-550, 125],
      [-69, 266],
      [22, 170],
      [86, 263],
      [57, 243],
      [37, 237],
      [87, 352],
      [92, 297],
      [-35, 326],
      [44, 251],
      [-3, 244],
      [-44, 157],
      [-11, 129],
      [-92, 178],
      [-30, 51],
    ],
    [
      [17036, 26237],
      [54, -18],
      [199, -193],
      [152, -180],
      [-13, 21],
      [30, 0],
      [172, -4],
      [82, -300],
      [160, -139],
      [153, -98],
      [70, -252],
      [200, -126],
      [68, -11],
      [119, -19],
      [270, -12],
      [232, -153],
      [310, 8],
      [384, 91],
      [123, 29],
      [231, 146],
      [41, 19],
      [47, 40],
      [130, 127],
      [91, 237],
      [69, 100],
      [161, 392],
      [82, 53],
      [310, 42],
      [169, 25],
      [107, -14],
      [275, -34],
      [260, -115],
      [61, -27],
      [59, 3],
      [177, 7],
      [217, 98],
      [176, 45],
      [250, 84],
      [57, 18],
      [65, 21],
      [183, 112],
      [68, 40],
      [48, 40],
      [130, 140],
      [75, 37],
      [60, 29],
      [142, 4],
      [169, 45],
      [129, 72],
      [284, 197],
      [42, 29],
      [-4, 129],
      [4, 61],
      [21, 278],
      [-90, 247],
      [-27, 21],
      [-28, 367],
      [114, 365],
      [55, 88],
      [102, 25],
      [66, 0],
      [297, 1],
      [51, -15],
      [177, -51],
      [76, -102],
      [108, -145],
      [188, -38],
      [102, 12],
      [107, 12],
      [378, 75],
      [182, 44],
      [224, 105],
      [55, 74],
      [62, 94],
      [75, 26],
      [13, 27],
      [57, 190],
      [58, 230],
      [41, 67],
      [77, 196],
      [28, 62],
      [70, 160],
      [76, 101],
      [139, 297],
      [29, 115],
      [69, 114],
      [97, 202],
      [263, 30],
      [343, -33],
      [210, 98],
      [230, 105],
      [243, 36],
      [127, -43],
      [242, -72],
      [187, -50],
      [115, 18],
      [115, 18],
      [363, 1],
      [214, -85],
      [282, 2],
      [258, 138],
      [352, 103],
      [117, 28],
      [248, 60],
      [122, 60],
      [277, 117],
      [346, 157],
      [23, 14],
      [208, 125],
      [244, 125],
      [197, 119],
      [109, 80],
      [198, 173],
      [34, 20],
      [224, 132],
      [-43, 239],
      [-16, 231],
      [-48, 143],
      [-49, 144],
      [-58, 137],
      [-92, 192],
      [-50, 82],
      [-8, 12],
      [126, -61],
      [363, -74],
      [406, 176],
      [12, 1],
    ],
    [
      [33649, 33025],
      [-1, -79],
      [31, -171],
      [5, -102],
      [44, -191],
      [113, -103],
      [105, -179],
      [84, -171],
      [37, -204],
      [-10, -204],
      [-70, -216],
      [-150, -133],
      [-159, -214],
      [-77, -189],
      [-110, -209],
      [-104, -195],
      [-179, -234],
      [-126, -297],
      [-110, -168],
      [-97, -161],
      [-54, -60],
      [-56, -87],
      [-150, -195],
      [-157, -113],
      [-116, -113],
      [-190, -119],
      [-137, -154],
      [-250, -97],
      [-321, -273],
      [-205, -193],
      [-205, -235],
      [-344, -95],
      [-400, -191],
      [-312, -151],
      [-284, -103],
      [-297, -56],
      [-13, 4],
    ],
    [
      [16717, 3601],
      [0, -8],
      [17, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
    ],
    [
      [14289, 4886],
      [0, -24],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
    ],
    [
      [9399, 7538],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [57, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 1],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [24, 0],
    ],
    [
      [11682, 12350],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -16],
      [48, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-162, 0],
      [0, -9],
      [-49, 0],
      [0, 9],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [113, 0],
    ],
    [
      [8821, 14978],
      [3, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [49, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 49],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 156],
      [-8, 0],
      [0, 123],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 7],
      [0, 9],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [33, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [57, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [57, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [210, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [97, 0],
      [0, -8],
      [121, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [121, 0],
      [0, 8],
      [34, 0],
      [31, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [-1, 18],
      [17, -2],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 3],
      [0, 6],
      [9, 0],
      [7, 0],
      [0, 4],
      [0, 12],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, -17],
      [8, 0],
      [0, -19],
      [0, -30],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-32, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-7, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-33, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -24],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-65, 0],
      [0, 9],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-32, 0],
      [-1, -8],
      [-64, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-81, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-137, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 106],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 90],
      [0, 140],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [32, 0],
      [0, -9],
      [0, -48],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [16, 0],
      [0, -49],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [138, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [7, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-113, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 57],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 58],
    ],
    [
      [22095, 19514],
      [55, -106],
      [-56, -169],
      [-85, -222],
      [31, -225],
      [324, -318],
      [279, -222],
      [247, -113],
      [327, -162],
      [214, -51],
      [300, -106],
      [212, -228],
      [171, -214],
      [-2, -149],
      [111, -192],
      [-29, -149],
      [111, -205],
      [85, -131],
      [92, -149],
    ],
    [
      [23693, 10214],
      [-5, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
    ],
    [
      [22659, 8734],
      [-7, -1],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [113, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [8, 0],
      [0, 90],
      [-65, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -50],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [25, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-113, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -65],
      [-9, 0],
      [0, -17],
      [9, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -41],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -15],
    ],
    [
      [21680, 8094],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 7],
      [40, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [40, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 98],
      [-8, 0],
      [0, 131],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 7],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -49],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -148],
      [8, 0],
      [0, -82],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -40],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -131],
      [8, 0],
      [0, -94],
    ],
    [
      [19260, 5901],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 49],
      [8, 1],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 98],
      [9, 0],
      [0, 82],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-32, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -98],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -41],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-9, 0],
      [0, -25],
      [-16, 0],
      [0, -57],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [9, 0],
      [0, -49],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [8, 0],
    ],
    [
      [17088, 3126],
      [-14, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 81],
      [8, 0],
      [0, 50],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 66],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 131],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 41],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-32, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, -1],
      [0, -16],
      [8, 0],
      [1, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -99],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [-1, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -49],
      [-8, 0],
      [0, -106],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -41],
      [-9, 0],
      [0, -17],
      [17, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [17, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [1, -8],
    ],
    [
      [13773, 4125],
      [38, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [17, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [64, 0],
      [0, 17],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-57, 0],
      [0, -9],
      [-48, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [24, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-8, 0],
      [0, 40],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [81, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [49, 0],
      [0, 9],
      [32, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 24],
      [8, 1],
      [0, 8],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -57],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [81, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [25, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 74],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 49],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-98, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 24],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 16],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [81, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -66],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [89, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-88, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-89, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-40, 0],
      [0, 17],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 24],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [64, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [170, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [40, 0],
      [0, 17],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 122],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-80, 0],
      [0, 8],
      [-65, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [57, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 115],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [9, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-25, 0],
      [0, 9],
      [-64, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -115],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-57, 0],
      [0, 16],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -50],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [24, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-113, 0],
      [0, 8],
      [-57, 0],
      [0, 9],
      [-33, 0],
      [0, 7],
      [-48, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-105, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -24],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-72, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -17],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 123],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-17, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-32, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -82],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-6, 0],
      [-26, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-121, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 22],
      [0, 43],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [73, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [56, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [17, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 33],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 1],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [73, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [122, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-98, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-82, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -123],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-24, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [24, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 40],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-113, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 90],
      [8, 0],
      [0, 16],
      [48, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [89, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [41, 0],
      [0, 16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 65],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 58],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 73],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [64, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [56, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-114, 0],
      [0, 8],
      [-8, 0],
      [0, 82],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 1],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 58],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -147],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-73, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -58],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -16],
      [121, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -41],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -106],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-97, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-81, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-10, 0],
      [-39, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [18, 2],
      [-1, -10],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [33, 1],
      [-1, -10],
      [10, 2],
      [-2, -10],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [11, 0],
      [13, 0],
      [-1, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [9, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [24, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 7],
      [32, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-34, 0],
      [-7, 0],
      [-1, 7],
      [-39, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [130, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 3],
      [0, 5],
      [8, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 1],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [64, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [130, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [89, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [81, 0],
      [0, 8],
      [40, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 65],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 106],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 65],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 123],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 41],
      [16, 0],
      [0, 17],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [7, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 24],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 16],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-7, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -139],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-9, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -66],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -65],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -98],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-121, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [202, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 90],
      [-8, 0],
      [0, 73],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [-32, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -73],
      [9, 0],
      [0, -74],
      [-9, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-210, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -50],
      [8, 0],
      [0, -49],
      [-41, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-122, 0],
      [0, -8],
      [-56, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-113, 0],
      [0, 9],
      [-129, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [16, 0],
      [1, 9],
      [15, -1],
      [0, 8],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -106],
      [8, 0],
      [0, -4],
      [0, -4],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [26, 0],
      [79, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 66],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [89, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [48, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [81, 0],
      [0, -9],
      [161, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 58],
      [9, 0],
      [0, 24],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -33],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-145, 0],
      [0, 8],
      [-146, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-129, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [-9, 0],
      [0, -73],
      [9, 0],
      [0, -66],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-85, 0],
      [-4, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [-8, 0],
      [0, 82],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 89],
      [-8, 0],
      [0, 42],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 90],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [73, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 65],
      [33, 0],
      [0, 8],
      [73, 0],
      [0, -65],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [56, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [57, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 25],
      [32, 0],
      [0, -9],
      [25, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [40, 0],
      [0, 17],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-56, 0],
      [0, -33],
      [-8, 0],
      [0, -81],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 50],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 82],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -16],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -82],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [113, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [89, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 180],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 42],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 32],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [65, 0],
      [0, -9],
      [73, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, -57],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -24],
      [-9, 0],
      [0, -57],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [40, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [41, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 41],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 58],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 131],
      [-8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [40, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [48, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 25],
      [-89, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-73, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -90],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -90],
      [-8, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-7, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 25],
      [9, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 115],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-64, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 107],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 57],
      [17, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 50],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [73, 0],
      [0, 9],
      [154, 0],
      [0, -9],
      [72, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [202, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 73],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [113, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 49],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [40, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -16],
      [48, 0],
      [0, -16],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [32, 0],
      [0, -16],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 73],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [73, 0],
      [0, 9],
      [48, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [41, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-129, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-89, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 49],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-41, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -24],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -41],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-33, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [-8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -73],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -122],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -25],
      [-24, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 24],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [16, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -7],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -148],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-56, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-18, 0],
      [1, 8],
      [-16, 0],
      [0, 8],
      [-7, 0],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [-1, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-9, 1],
      [1, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 4],
      [-2, 4],
      [-6, 0],
      [0, 8],
      [0, 9],
      [-6, 0],
      [-2, 0],
      [0, 8],
      [-8, 0],
      [1, 9],
      [-9, -1],
      [0, 16],
      [-8, 0],
      [1, 10],
      [-9, -2],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 74],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 82],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [3, 0],
      [5, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -40],
      [9, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -10],
      [0, -7],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [32, 0],
      [0, -9],
      [41, 0],
      [0, -8],
      [24, 0],
      [0, -41],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [-8, 0],
      [0, -115],
      [-8, 0],
      [0, -98],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -49],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [33, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [162, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [81, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [56, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [82, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [64, 0],
      [0, 9],
      [316, 0],
      [0, -9],
      [49, 0],
      [0, -8],
      [65, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [17, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [56, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -131],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 90],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [24, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 24],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [7, 0],
      [0, 16],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 65],
      [9, 0],
      [0, 58],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-9, 0],
      [0, 82],
      [9, 0],
      [0, 74],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [24, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [33, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [49, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [56, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [57, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [105, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [25, 0],
      [0, 24],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-130, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-98, 0],
      [0, -8],
      [-24, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 74],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 41],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, 9],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [57, 0],
      [0, 8],
      [32, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 49],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [40, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [65, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [89, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 10],
      [17, -2],
      [-1, 10],
      [17, -2],
      [-1, 10],
      [17, -2],
      [-1, 10],
      [9, -1],
      [1, 6],
      [7, 2],
      [0, 8],
      [13, 0],
      [12, 0],
      [0, 8],
      [5, 0],
      [11, 0],
      [0, 7],
      [5, 2],
      [11, 0],
      [0, 5],
      [0, 3],
      [6, 0],
      [10, 0],
      [0, 5],
      [0, 3],
      [6, 0],
      [10, 0],
      [0, 5],
      [0, 3],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [51, 0],
      [22, 0],
      [0, 9],
    ],
    [
      [33649, 33025],
      [493, 31],
      [472, 26],
      [528, -206],
      [374, -190],
      [359, -278],
      [100, -471],
      [198, -262],
      [66, -477],
      [-54, -447],
      [-161, -358],
      [-288, -348],
      [-204, -160],
      [-211, -126],
      [-142, -85],
      [-96, -53],
      [-88, -87],
      [-79, -305],
      [43, -211],
      [152, -193],
      [-9, -156],
      [-123, -100],
      [-345, -123],
      [-258, -152],
      [-40, -421],
      [-59, -182],
      [-90, -284],
      [-167, -303],
      [-259, -240],
      [-511, 35],
      [-450, 76],
      [-343, -28],
      [-57, -216],
      [-178, -195],
      [-311, -470],
      [-215, -438],
      [-256, -431],
      [-194, -370],
      [-188, -350],
      [-221, -404],
      [-256, -431],
      [-236, -438],
      [-112, -277],
      [-210, -519],
      [-155, -433],
      [-157, -527],
      [8, -361],
      [131, -240],
      [267, -134],
      [359, 130],
      [248, -52],
      [-1, -23],
    ],
  ],
  transform: {
    scale: [0.000034311820800655556, 0.000033939398453273083],
    translate: [5.376529790482817, 4.270418],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [
            [[0]],
            [[1]],
            [[2]],
            [[3]],
            [[4]],
            [[5]],
            [[6]],
            [[7, 8]],
            [[9, 10]],
            [[11]],
            [[12]],
            [[13, 14, 15]],
            [[16, 17]],
            [[18, 19]],
            [[20]],
            [[21]],
            [[22]],
            [[23]],
            [[24]],
            [[25, 26, 27, 28, 29, 30, 31, 32, 33, 34]],
            [[35]],
            [[36, 37]],
            [[38]],
            [[39, 40, 41]],
            [[42, 43]],
            [[44]],
            [[45]],
            [[46, 47]],
            [[48, 49, 50]],
            [[51]],
            [[52]],
            [[53]],
            [[54, 55, 56, 57]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Brass",
            id: "BY001",
            nameAbbr: "Brass",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 13.6569699991,
            Shape_Area: 0.13890797279,
          },
        },
        {
          arcs: [[[58, 59]], [[60]], [[61, 62, 63]]],
          type: "MultiPolygon",
          properties: {
            name: "Ekeremor",
            id: "BY002",
            nameAbbr: "Ekeremor",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.32156102103,
            Shape_Area: 0.14642246096,
          },
        },
        {
          arcs: [[64, 65, 66]],
          type: "Polygon",
          properties: {
            name: "Kolokuma/Opokuma",
            id: "BY003",
            nameAbbr: "Kolokuma/Opokuma",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.94100207152,
            Shape_Area: 0.03606164024,
          },
        },
        {
          arcs: [
            [[-26, 67]],
            [[-14, 68]],
            [[69, -15]],
            [[-28, 70]],
            [[71, -29]],
            [[72, -30]],
            [[-32, 73]],
            [[74]],
            [[75]],
            [[76]],
            [[77]],
            [[-40, 78]],
            [[79]],
            [[-49, 80, 81]],
            [[82, -34, 83, -17, 84, -8, 85, -10, 86, 87, -56, 88, -41]],
            [[89, 90, 91]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Nembe",
            id: "BY004",
            nameAbbr: "Nembe",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 6.35819676987,
            Shape_Area: 0.07051080325,
          },
        },
        {
          arcs: [[-57, -88, 92, -92, 93, 94, 95]],
          type: "Polygon",
          properties: {
            name: "Ogbia",
            id: "BY005",
            nameAbbr: "Ogbia",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.31167102887,
            Shape_Area: 0.06113448601,
          },
        },
        {
          arcs: [[-66, 96, -64, 97, 98]],
          type: "Polygon",
          properties: {
            name: "Sagbama",
            id: "BY006",
            nameAbbr: "Sagbama",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.27909677743,
            Shape_Area: 0.07262603959,
          },
        },
        {
          arcs: [
            [[99]],
            [[100]],
            [[101]],
            [[102]],
            [[-59, 103]],
            [
              [
                -65, 104, -94, -91, 105, -81, -51, 106, -47, 107, -44, 108, -38,
                109, -19, 110, -62, -97,
              ],
            ],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Southern Ijaw",
            id: "BY007",
            nameAbbr: "Southern Ijaw",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 9.05034735354,
            Shape_Area: 0.20956516795,
          },
        },
        {
          arcs: [[-95, -105, -67, -99, 111]],
          type: "Polygon",
          properties: {
            name: "Yenegoa",
            id: "BY008",
            nameAbbr: "Yenegoa",
            parent: "Bayelsa",
            parentAbbr: "BY",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.83513216392,
            Shape_Area: 0.06070040994,
          },
        },
      ],
    },
  },
};

const ebonyi = {
  type: "Topology",
  arcs: [
    [
      [18586, 18830],
      [26, -165],
      [-17, -176],
      [123, -314],
      [65, -144],
      [185, -234],
      [147, -37],
      [220, -64],
      [257, 43],
      [297, 69],
      [303, 36],
      [204, 132],
      [345, 130],
      [254, -52],
      [357, 35],
      [162, 51],
      [230, 64],
      [194, -78],
      [539, 100],
      [144, 160],
      [297, 63],
      [249, 36],
      [295, -86],
      [207, -72],
      [248, -38],
      [221, -58],
      [506, 127],
      [152, 77],
    ],
    [
      [24796, 18435],
      [199, -220],
      [278, -291],
      [170, -302],
      [-11, -227],
      [0, -439],
      [-118, -236],
      [-241, -363],
      [-308, -341],
      [-241, -308],
      [-151, -215],
      [-280, -267],
      [-261, -302],
      [-227, -288],
      [-333, -198],
      [-26, -45],
    ],
    [
      [23246, 14393],
      [-3, 11],
      [-90, 259],
      [4, 224],
      [-51, 219],
      [-44, 184],
      [-146, 90],
      [-153, 105],
      [-154, 50],
      [-230, -78],
      [-244, -138],
      [-157, -167],
      [-265, -158],
      [-273, -240],
      [-512, -80],
      [-242, 38],
      [-337, -103],
      [-335, -313],
      [-339, -150],
      [-352, -184],
      [-277, -77],
      [-496, 63],
      [-174, 84],
      [-236, 47],
    ],
    [
      [18140, 14079],
      [-139, 28],
      [-283, -36],
      [-251, -118],
      [-171, -174],
      [-237, -97],
      [-215, -10],
      [-153, 84],
      [-228, 72],
      [-174, 57],
      [-274, 72],
      [-134, 16],
      [-609, 201],
      [-193, 112],
      [-112, 131],
      [-126, 104],
      [-53, 89],
      [-5, 81],
      [-12, 82],
      [10, 182],
    ],
    [
      [14781, 14955],
      [9, 171],
      [104, 215],
      [57, 176],
      [65, 257],
      [156, 105],
      [154, 399],
      [-23, 258],
      [33, 373],
      [-21, 339],
      [14, 48],
      [3, 169],
      [1, 109],
      [-110, 246],
      [-66, 83],
      [-57, 178],
      [-59, 18],
    ],
    [
      [15041, 18099],
      [142, 150],
      [96, 107],
      [196, 85],
      [323, 28],
      [256, 2],
      [262, 23],
      [289, -46],
      [187, -37],
      [207, -71],
      [141, -57],
      [255, -32],
      [163, 106],
      [63, 169],
      [232, 234],
      [231, 152],
      [230, 84],
      [248, -72],
      [19, -95],
      [5, 1],
    ],
    [
      [11267, 2344],
      [-181, -190],
      [-166, -303],
      [-180, -282],
      [-19, -23],
    ],
    [
      [10721, 1546],
      [-154, 94],
      [-79, 83],
      [-69, 314],
      [-176, 335],
      [-65, 171],
      [-10, 211],
      [-51, 150],
      [-25, 136],
      [3, 204],
      [-24, 177],
      [-32, 102],
      [-85, 178],
      [-43, 238],
      [-90, 219],
      [-90, 253],
      [36, 162],
      [-84, 219],
      [-55, 347],
      [-25, 143],
      [-19, 82],
      [-11, 102],
      [-25, 109],
      [-12, 129],
      [-12, 75],
      [-18, 116],
      [-31, 170],
      [-11, 157],
      [-18, 149],
      [29, 142],
      [22, 68],
      [41, 94],
      [-50, 198],
      [42, 121],
      [1, 82],
      [12, 103],
    ],
    [
      [9543, 7179],
      [4, 32],
      [-7, 0],
      [34, 27],
      [97, 141],
      [115, 86],
      [244, 111],
      [141, -16],
      [248, -11],
      [235, -78],
      [336, 28],
      [216, 64],
      [137, 99],
      [56, 162],
      [10, 91],
    ],
    [
      [11409, 7915],
      [99, -4],
      [-16, -122],
      [107, -29],
      [54, 6],
      [149, 31],
      [128, 46],
      [128, 31],
      [243, 44],
      [229, 30],
      [175, 10],
      [122, 5],
      [24, 0],
    ],
    [
      [12851, 7963],
      [42, -62],
      [106, -152],
      [118, -172],
      [71, -198],
      [43, -272],
      [17, -205],
      [-19, -339],
      [-84, -155],
      [4, -184],
      [-89, -134],
      [-103, -121],
      [-152, -208],
      [-142, -93],
      [-131, -147],
      [-315, -389],
      [-98, -229],
      [-91, -250],
      [-1, -14],
      [31, -150],
      [-130, -161],
      [-54, -6],
      [-196, -71],
      [-35, -94],
      [-178, -161],
      [-96, -107],
      [5, -149],
      [18, -143],
      [-25, -252],
      [41, -381],
      [130, -342],
      [-55, -67],
      [-89, -59],
      [-116, -141],
      [-11, -11],
    ],
    [
      [10721, 1546],
      [-193, -232],
      [-91, -175],
      [-92, -304],
      [-5, -334],
      [-5, -264],
      [-70, -223],
      [-7, -14],
      [-631, 99],
      [-419, 361],
      [-646, 405],
      [-445, 348],
      [-328, 495],
      [-73, 545],
      [-32, 497],
      [-280, 603],
      [-305, 284],
      [-349, 33],
      [-269, 5],
      [-478, 35],
      [-699, 12],
      [-874, 29],
      [-87, 5],
      [-248, 15],
    ],
    [
      [4095, 3771],
      [52, 43],
      [252, 172],
      [198, 201],
      [44, 189],
      [63, 189],
      [68, 46],
      [123, 107],
      [156, 126],
      [116, 80],
      [164, 153],
      [149, 534],
      [77, 182],
      [125, 249],
      [132, 242],
      [61, 122],
    ],
    [
      [5875, 6406],
      [163, 30],
      [128, 18],
      [319, 171],
      [115, 66],
      [117, 127],
      [130, 161],
      [163, 113],
      [183, 105],
      [156, 59],
      [208, -4],
      [188, -58],
      [173, -104],
      [94, 19],
      [41, 26],
      [35, 81],
      [15, 122],
      [29, 128],
      [76, 128],
      [82, 80],
      [62, 74],
      [108, 11],
      [98, -151],
      [86, -116],
      [121, -16],
      [107, 5],
      [134, -9],
      [74, -29],
      [127, -29],
      [87, -36],
      [99, -89],
      [54, -22],
      [96, -88],
    ],
    [
      [15041, 18099],
      [-75, 24],
      [-212, 221],
      [-127, 64],
      [-147, 63],
      [-33, 40],
    ],
    [
      [14447, 18511],
      [-26, 29],
      [-6, 54],
      [1, 62],
      [15, 54],
      [20, 47],
      [55, 60],
      [-21, 354],
      [-25, 102],
      [16, 162],
      [82, 114],
      [56, 122],
      [35, 101],
      [77, 168],
      [-7, 1],
      [143, 520],
      [232, 613],
      [291, 586],
      [252, 152],
      [8, 523],
      [205, 648],
      [143, 534],
      [169, 465],
      [213, 289],
      [70, 555],
      [113, 372],
      [-33, 448],
      [-16, 252],
      [23, 210],
      [-35, 306],
      [-16, 278],
      [-2, 272],
      [-154, 492],
      [-78, 592],
      [-65, 537],
      [-156, 743],
      [-193, 400],
    ],
    [
      [15833, 29728],
      [650, -56],
      [903, 440],
      [325, 572],
      [134, 210],
    ],
    [
      [17845, 30894],
      [33, -57],
      [109, -368],
      [223, -371],
      [256, -391],
      [55, -313],
      [50, -246],
      [107, -470],
      [-39, -312],
      [88, -368],
      [18, -163],
      [44, -204],
      [89, -287],
      [-57, -203],
      [-45, -277],
      [-25, -319],
      [-86, -352],
      [13, -441],
      [-12, -319],
      [-17, -224],
      [2, -326],
      [-73, -318],
      [-119, -289],
      [-83, -202],
      [15, -300],
      [-65, -277],
      [-3, -183],
      [-16, -169],
      [-4, -238],
      [76, -721],
      [-83, -576],
      [51, -150],
      [119, -545],
      [38, -136],
      [18, -123],
      [180, -567],
      [-58, -202],
      [-9, -183],
      [-8, -75],
      [10, -197],
      [-35, -81],
      [-42, -121],
      [12, -82],
      [-1, -95],
      [4, -149],
      [11, -74],
    ],
    [
      [14781, 14955],
      [-236, -36],
      [-237, -63],
      [-477, -33],
      [-298, -90],
      [-101, -32],
      [-169, -38],
      [-43, -176],
      [-64, -229],
      [-44, -203],
      [-3, -197],
      [44, -157],
      [-3, -224],
      [-30, -169],
      [-48, -101],
      [-191, -153],
      [-324, -96],
      [-181, 23],
      [-262, 45],
      [-113, 70],
      [63, 135],
      [-65, 130],
      [-5, 122],
      [-46, 82],
      [-79, 90],
      [-202, 3],
      [-190, -85],
      [-139, 111],
      [-118, 186],
      [-200, 139],
      [-152, 152],
      [-180, 44],
      [-294, 141],
      [-125, -12],
    ],
    [
      [10269, 14334],
      [11, 54],
      [-17, 191],
      [-5, 122],
      [-98, 151],
      [-85, 124],
      [34, 24],
    ],
    [
      [10109, 15000],
      [67, 49],
      [326, 184],
      [76, 94],
      [77, 188],
      [62, 115],
      [56, 128],
      [30, 224],
      [66, 318],
      [89, 147],
      [40, 346],
      [93, 345],
      [56, 168],
      [105, 229],
      [18, 285],
      [-7, 0],
      [-8, 313],
      [86, 338],
      [71, 270],
      [105, 376],
    ],
    [
      [11517, 19117],
      [97, 42],
      [239, 213],
      [190, 132],
      [209, 65],
      [297, 22],
      [168, 10],
      [194, -64],
      [315, -40],
      [274, -93],
      [134, -63],
      [171, -213],
      [165, -200],
      [126, -131],
      [158, -159],
      [87, -76],
      [73, -56],
      [33, 5],
    ],
    [
      [18140, 14079],
      [39, -159],
      [-38, -264],
      [-58, -277],
      [-85, -318],
      [-125, -194],
      [-47, -54],
      [-125, -208],
      [-179, -282],
      [-246, -213],
      [-135, -79],
      [-157, -93],
      [-216, -85],
      [-169, -71],
      [-216, -38],
      [-249, -9],
      [-181, 44],
      [-241, 58],
      [-282, 6],
      [-189, -38],
      [-89, -94],
      [12, -109],
      [100, -49],
      [139, -111],
      [67, -55],
      [93, -36],
      [99, -144],
      [-11, -278],
      [-205, -146],
      [-149, -93],
      [-165, -200],
      [-236, -57],
      [-148, -38],
      [-149, -39],
      [-237, -104],
      [-216, -58],
      [-129, -59],
      [-45, -325],
      [-13, -4],
    ],
    [
      [13959, 9806],
      [-27, 39],
      [-53, 48],
      [-79, 117],
      [-125, 165],
      [-112, 151],
      [-181, 10],
      [-181, 24],
      [-174, 71],
      [-241, 24],
      [-156, -38],
      [-147, 16],
      [-183, -105],
      [-191, -126],
      [-243, -71],
      [-163, -85],
      [-283, -50],
      [-101, 2],
      [-114, 23],
      [-132, 138],
      [-33, 55],
      [-233, 133],
      [-126, 524],
      [77, 189],
      [131, 195],
      [-10, 224],
      [1, 502],
      [49, 115],
      [-34, 360],
      [-4, 177],
      [-25, 163],
      [-150, 241],
      [-87, 49],
      [-140, 56],
      [-167, 57],
      [-138, 254],
      [-32, 68],
      [-53, 76],
      [-46, 55],
      [-24, 136],
      [35, 115],
      [36, 142],
      [90, 148],
      [68, 94],
      [11, 47],
    ],
    [
      [23246, 14393],
      [-243, -399],
      [-338, -552],
      [-249, -376],
      [-210, -112],
      [-33, 7],
      [-196, -425],
      [-179, -275],
      [67, -437],
      [-149, -480],
      [35, -320],
      [-307, -708],
      [-13, -387],
      [-397, -435],
      [-262, 52],
      [-212, 187],
      [-225, 235],
      [-328, 128],
      [-404, -54],
      [-317, -15],
      [-163, -72],
      [-92, -325],
      [4, -156],
      [-43, -142],
      [-232, -227],
      [-380, -211],
      [-116, -73],
      [-164, -147],
      [-143, -154],
      [-211, -104],
      [-54, -27],
      [-332, -137],
      [-86, 11],
      [-95, 12],
      [-238, -125],
      [-123, -161],
      [-195, -424],
      [-37, -190],
      [-178, -167],
      [-101, -46],
      [-141, 57],
      [-138, 172],
      [-124, 268],
      [-118, 205],
      [-84, 240],
      [-76, 280],
      [-37, 170],
      [-79, 151],
      [-164, 268],
      [-98, 185],
      [-206, 147],
      [-195, -17],
      [-235, 45],
      [-263, -30],
      [-216, -26],
    ],
    [
      [14133, 9277],
      [-34, 83],
      [-31, 136],
      [-66, 103],
      [-17, 170],
      [-26, 37],
    ],
    [
      [8798, 27695],
      [-78, -432],
      [305, -732],
      [1021, -969],
      [869, -783],
      [446, -190],
    ],
    [
      [11361, 24589],
      [19, -274],
      [-25, -318],
      [32, -503],
      [2, -340],
      [68, -374],
      [95, -328],
      [-13, -407],
      [109, -321],
      [16, -278],
      [22, -279],
      [-92, -331],
      [32, -517],
      [-67, -413],
      [-43, -556],
      [7, 0],
      [-4, -224],
      [-2, -9],
    ],
    [
      [10109, 15000],
      [-93, 79],
      [-128, 29],
      [-67, 21],
      [-53, 15],
      [-60, 55],
      [-207, 44],
      [-248, 59],
      [-240, 133],
      [-166, 132],
      [-92, 137],
      [-234, 86],
      [-295, 73],
      [-160, 84],
      [-409, 54],
      [-375, 75],
      [-586, -45],
      [-437, -40],
      [-493, -113],
      [-353, -102],
    ],
    [
      [5413, 15776],
      [-107, 788],
      [-509, 512],
      [9, 583],
      [344, 911],
      [260, 287],
      [356, 829],
      [331, 884],
      [-25, 564],
      [7, 468],
      [5, 699],
      [16, 1039],
      [-174, 451],
      [-133, 97],
      [-77, 646],
      [-59, 497],
      [-40, 422],
      [-174, 492],
      [-132, 178],
      [-122, 355],
      [-55, 320],
      [-103, 199],
      [-21, 41],
      [-134, 444],
      [45, 304],
      [476, -110],
      [746, -12],
      [381, -88],
      [458, 26],
      [457, -42],
      [517, -36],
      [505, 73],
      [337, 98],
    ],
    [
      [4578, 6742],
      [181, 1],
      [276, 2],
      [254, -106],
      [166, -98],
      [200, -92],
      [181, -50],
      [39, 7],
    ],
    [
      [4095, 3771],
      [-143, 8],
      [-670, 128],
      [-815, -34],
      [-592, 44],
      [-752, 67],
      [-659, 66],
      [-266, 295],
      [-177, 195],
      [-21, 347],
      [43, 378],
      [4, 36],
      [22, 517],
      [188, 431],
      [21, 483],
      [-69, 735],
      [-18, 571],
      [30, 169],
      [4, 123],
      [184, 63],
      [505, 59],
      [379, 157],
      [386, 183],
      [256, 16],
      [339, -209],
      [353, -224],
      [243, -377],
      [362, -41],
      [516, -545],
      [422, -516],
      [82, -35],
      [-24, -102],
      [80, -22],
      [169, 4],
      [57, 1],
      [37, -16],
      [7, 16],
    ],
    [
      [17845, 30894],
      [377, 590],
      [160, 46],
      [428, 121],
      [1016, 9],
      [197, 561],
      [285, 539],
      [824, -619],
      [167, -1369],
      [231, -657],
      [419, -782],
      [379, -301],
      [-23, -140],
      [54, -375],
      [34, -429],
      [168, -431],
      [258, -317],
      [264, -304],
      [202, -384],
      [212, -241],
      [246, -195],
      [452, -347],
      [339, -265],
      [234, -85],
      [169, -329],
      [24, -246],
      [43, -238],
      [126, -132],
      [314, -134],
      [98, -151],
      [66, -117],
      [-108, 2],
      [-23, -204],
      [184, -247],
      [-103, -162],
      [2, -313],
      [246, -174],
      [49, -307],
      [141, -410],
      [152, -200],
      [220, -132],
      [280, -141],
      [56, -287],
      [57, -211],
      [-92, -312],
      [-119, -256],
      [-179, -241],
      [-250, -85],
      [-153, 105],
      [-250, -50],
      [-379, -150],
      [-307, -253],
      [-232, -227],
      [-240, -295],
      [-48, -434],
      [56, -327],
      [202, -398],
      [26, -28],
    ],
    [
      [4578, 6742],
      [299, 671],
      [24, 197],
      [-145, 627],
      [-253, 568],
      [-59, 519],
      [-12, 100],
      [-3, 638],
      [89, 121],
      [631, 722],
      [240, 205],
    ],
    [
      [5389, 11110],
      [18, -6],
      [308, -59],
      [227, -99],
      [369, -54],
      [262, -11],
      [343, -20],
      [281, -93],
      [369, -47],
      [281, -107],
      [313, -134],
      [389, -115],
      [359, -210],
      [273, -140],
      [302, -101],
      [226, -99],
      [300, -188],
      [481, -212],
      [219, -146],
      [65, -151],
      [139, -179],
      [-15, -67],
      [45, -123],
      [186, -132],
      [185, -214],
      [-30, -183],
      [138, -192],
      [-13, -113],
    ],
    [
      [11361, 24589],
      [389, -165],
      [664, 281],
      [467, 978],
      [213, 1539],
      [215, 853],
      [28, 890],
      [131, 637],
      [1625, 189],
      [740, -63],
    ],
    [
      [14133, 9277],
      [-27, -4],
      [-162, -24],
      [-135, -32],
      [-204, -132],
      [-257, -125],
      [-102, -32],
      [-101, -5],
      [-128, -32],
      [-129, -66],
      [-150, -120],
      [-42, -80],
      [-41, -61],
      [-8, -115],
      [4, -109],
      [-1, -102],
      [66, -110],
      [99, -110],
      [36, -55],
    ],
    [
      [5389, 11110],
      [298, 254],
      [376, 822],
      [-64, 605],
      [-208, 479],
      [-38, 544],
      [17, 638],
      [-111, 599],
      [-63, 300],
      [-182, 411],
      [-1, 14],
    ],
  ],
  transform: {
    scale: [0.00003431137245557513, 0.00003393964169080164],
    translate: [7.525858587554634, 5.69032585930786],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Abakaliki",
            id: "EB001",
            nameAbbr: "Abakaliki",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03842267891,
            Shape_Area: 0.04383635166,
          },
        },
        {
          arcs: [[6, 7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Afikpo North",
            id: "EB002",
            nameAbbr: "Afikpo North",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.59469233986,
            Shape_Area: 0.01623474159,
          },
        },
        {
          arcs: [[-8, 11, 12, 13]],
          type: "Polygon",
          properties: {
            name: "Afikpo South",
            id: "EB003",
            nameAbbr: "Afikpo South",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.8114513857,
            Shape_Area: 0.02695475962,
          },
        },
        {
          arcs: [[-6, 14, 15, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Ebonyi",
            id: "EB004",
            nameAbbr: "Ebonyi",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.07956223578,
            Shape_Area: 0.03720341629,
          },
        },
        {
          arcs: [[-15, -5, 18, 19, 20, 21]],
          type: "Polygon",
          properties: {
            name: "Ezza North",
            id: "EB005",
            nameAbbr: "Ezza North",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.66962189808,
            Shape_Area: 0.0249521086,
          },
        },
        {
          arcs: [[22, 23, -19, -4]],
          type: "Polygon",
          properties: {
            name: "Ezza South",
            id: "EB006",
            nameAbbr: "Ezza South",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.91372538328,
            Shape_Area: 0.02678732304,
          },
        },
        {
          arcs: [[24, 25, -23, -3]],
          type: "Polygon",
          properties: {
            name: "Ikwo",
            id: "EB007",
            nameAbbr: "Ikwo",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03445755841,
            Shape_Area: 0.0377054457,
          },
        },
        {
          arcs: [[26, 27, -21, 28, 29]],
          type: "Polygon",
          properties: {
            name: "Ishielu",
            id: "EB008",
            nameAbbr: "Ishielu",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.22931672178,
            Shape_Area: 0.07455021413,
          },
        },
        {
          arcs: [[30, -13, 31]],
          type: "Polygon",
          properties: {
            name: "Ivo",
            id: "EB009",
            nameAbbr: "Ivo",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.62241441669,
            Shape_Area: 0.02353129438,
          },
        },
        {
          arcs: [[-1, -18, 32]],
          type: "Polygon",
          properties: {
            name: "Izzi",
            id: "EB010",
            nameAbbr: "Izzi",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.42536575162,
            Shape_Area: 0.0891623702,
          },
        },
        {
          arcs: [[-14, -31, 33, 34, -9]],
          type: "Polygon",
          properties: {
            name: "Ohaozara",
            id: "EB011",
            nameAbbr: "Ohaozara",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.7009750243,
            Shape_Area: 0.02452577362,
          },
        },
        {
          arcs: [[-22, -28, 35, -16]],
          type: "Polygon",
          properties: {
            name: "Ohaukwu",
            id: "EB012",
            nameAbbr: "Ohaukwu",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.00300986565,
            Shape_Area: 0.04378273684,
          },
        },
        {
          arcs: [[-26, 36, -10, -35, 37, -29, -20, -24]],
          type: "Polygon",
          properties: {
            name: "Oniocha",
            id: "EB013",
            nameAbbr: "Oniocha",
            parent: "Ebonyi",
            parentAbbr: "EB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.05171526375,
            Shape_Area: 0.03650464408,
          },
        },
      ],
    },
  },
};

const benue = {
  type: "Topology",
  arcs: [
    [
      [16833, 7557],
      [-741, 63],
      [-1624, -189],
      [-132, -637],
      [-27, -889],
      [-216, -853],
      [-212, -1540],
      [-467, -977],
      [-664, -281],
      [-389, 165],
      [-446, 189],
      [-869, 783],
      [-1021, 970],
      [-305, 732],
      [78, 431],
      [63, 348],
      [443, 808],
      [267, 730],
      [302, 1171],
      [-88, 1238],
      [-217, 933],
    ],
    [
      [10568, 10752],
      [169, 63],
      [425, 75],
      [378, 61],
      [350, 35],
      [374, 265],
      [23, 169],
      [-37, 225],
      [-109, 341],
      [-124, 267],
      [-111, 158],
      [-105, 158],
      [-178, 220],
      [-151, 214],
      [-85, 164],
      [-24, 177],
      [38, 298],
      [133, 296],
      [119, 324],
      [187, 316],
      [192, 262],
      [306, 205],
      [231, 152],
      [447, 216],
      [279, 165],
      [615, 214],
      [277, 97],
      [296, 42],
      [376, -33],
      [363, -40],
      [388, -95],
      [315, -60],
      [282, -39],
      [169, -29],
    ],
    [
      [16376, 15635],
      [92, -16],
      [632, -31],
      [247, -79],
    ],
    [
      [17347, 15509],
      [92, -165],
      [22, -285],
      [-4, -258],
      [11, -64],
    ],
    [
      [17468, 14737],
      [38, -229],
      [36, -286],
      [91, -605],
      [142, -349],
      [98, -592],
      [150, -336],
      [135, -396],
      [-81, -433],
      [55, -300],
      [55, -333],
      [89, -348],
      [218, -268],
      [280, -120],
      [105, -152],
      [194, -85],
      [211, -268],
      [98, -278],
    ],
    [
      [19382, 9359],
      [-161, -46],
      [-376, -589],
      [-135, -211],
      [-325, -572],
      [-902, -440],
      [-650, 56],
    ],
    [
      [16763, 43243],
      [694, -218],
      [686, -427],
      [973, -581],
      [661, -331],
      [1538, -176],
      [262, -83],
    ],
    [
      [21577, 41427],
      [-140, -142],
      [-251, -152],
      [-191, -214],
      [-158, -188],
      [-244, -138],
      [-198, -180],
      [-137, -127],
      [-358, -156],
      [-210, -72],
      [-289, 19],
      [-325, -157],
      [-319, -205],
      [-219, -248],
      [-286, -232],
      [-192, -187],
      [-166, -330],
      [-344, -436],
      [-125, -255],
      [-233, -302],
      [-207, -309],
      [-158, -194],
      [-90, -188],
      [-80, -235],
    ],
    [
      [16657, 36799],
      [-12, -35],
      [-408, 81],
      [-414, 211],
      [-488, 137],
      [-530, 118],
      [-416, 7],
      [-476, 124],
      [-513, 260],
      [-503, 117],
      [-394, 142],
      [-406, 225],
      [-339, 270],
      [-495, 192],
      [-353, 155],
      [-549, 139],
      [-270, -50],
      [-255, 25],
      [-890, -154],
      [-426, -129],
      [-439, -149],
      [-390, -107],
    ],
    [
      [7691, 38378],
      [-33, 322],
      [-342, 877],
      [-558, 485],
      [-714, 753],
      [-373, 700],
      [-512, 831],
      [-159, 961],
      [58, 1107],
      [-157, 717],
      [34, 869],
      [121, 477],
      [364, -16],
      [1079, -284],
      [1558, -591],
      [1294, -287],
      [1706, -247],
      [928, -23],
      [1374, -323],
      [553, -322],
      [275, -107],
      [41, 54],
      [126, -77],
      [520, -308],
      [649, -242],
      [1043, -399],
      [197, -62],
    ],
    [
      [16657, 36799],
      [2, -8],
      [42, -313],
      [-3, -245],
      [95, -334],
      [60, -463],
      [92, -558],
      [112, -432],
    ],
    [
      [17057, 34446],
      [-293, -332],
      [-353, -238],
      [-191, -174],
      [-457, -392],
      [-300, -294],
      [-289, -389],
      [-436, -352],
      [-421, -271],
      [-556, -310],
      [-443, -345],
      [-475, -304],
      [-481, -209],
      [-334, -273],
      [-306, -253],
      [-523, -337],
      [-245, -186],
      [-367, -231],
      [-786, -408],
      [-215, -109],
    ],
    [
      [9586, 29039],
      [-473, 25],
      [-598, 500],
      [-157, 702],
      [-221, 881],
      [-206, 1044],
      [-44, 1006],
      [-139, 1002],
      [-80, 1109],
      [-8, 116],
      [94, 1229],
      [9, 1013],
      [-72, 712],
    ],
    [
      [45018, 35762],
      [132, 1],
      [185, 45],
      [280, 25],
      [453, -17],
      [77, -148],
      [261, -160],
      [297, -224],
      [227, -83],
      [145, -158],
      [221, -276],
      [318, -294],
      [215, -318],
      [180, -198],
      [235, -264],
      [105, -262],
      [70, -253],
      [147, -302],
      [166, -178],
      [83, -80],
      [234, -90],
      [240, 22],
      [171, -24],
      [275, 8],
      [480, -43],
      [336, -9],
      [246, 33],
      [406, -112],
      [542, -104],
      [221, -120],
      [290, -329],
      [228, -146],
      [243, -327],
      [346, -333],
      [-15, -179],
    ],
    [
      [53058, 30865],
      [-29, -347],
      [43, -246],
      [4, -442],
      [-19, -185],
      [3, -361],
      [-175, -368],
      [-95, -162],
      [-128, -278],
      [-162, -319],
      [-148, -326],
      [-81, -200],
      [-101, -533],
      [37, -561],
      [146, -906],
      [118, -478],
      [200, -164],
      [181, -570],
      [123, -443],
      [97, -470],
      [89, -50],
      [32, -583],
      [-193, -271],
      [-214, -180],
      [-261, -201],
      [-220, -243],
      [-184, -108],
      [-178, -192],
      [57, -378],
      [152, -323],
      [146, -112],
      [103, -73],
      [222, -298],
      [241, -150],
      [71, -32],
    ],
    [
      [52935, 20312],
      [-22, -22],
      [-171, -227],
      [-192, -243],
      [-151, -191],
      [-199, -214],
      [-337, -187],
      [-234, -68],
      [-260, -253],
      [-295, -276],
      [-385, -196],
      [-303, -100],
      [-316, -73],
      [-330, -214],
      [-324, -31],
      [-151, -36],
      [-220, -82],
      [-427, -113],
      [-351, 9],
      [-255, 9],
      [-166, 76],
      [-275, -113],
      [-504, 319],
      [-166, 90],
      [-151, 97],
      [-290, 133],
      [-200, 116],
      [-276, 296],
      [-359, 305],
      [-63, 53],
      [-159, 161],
      [-96, 37],
      [-153, 485],
      [-5, 85],
    ],
    [
      [45149, 19944],
      [-10, 182],
      [82, 208],
      [192, 201],
      [130, 176],
      [191, 320],
      [172, 150],
      [219, 349],
      [212, 272],
      [144, 170],
      [192, 200],
      [199, 194],
      [164, 347],
      [350, 271],
      [109, 281],
      [158, 177],
      [246, 352],
      [75, 144],
      [117, 176],
      [68, 193],
      [233, 259],
      [54, 207],
      [178, 214],
      [181, 323],
      [89, 335],
      [149, 384],
      [49, 294],
      [123, 343],
      [110, 300],
      [8, 294],
      [-62, 50],
      [-69, 57],
      [-146, -181],
      [-280, -292],
      [-206, -215],
      [-201, -152],
      [-129, -48],
      [-139, -216],
      [-318, 325],
      [-82, 400],
      [-110, 100],
      [-139, 169],
      [-104, 478],
      [-71, 141],
      [-83, 442],
      [-237, 367],
      [5, 448],
      [-127, 310],
      [-59, 197],
      [-177, 57],
      [-170, 395],
      [-208, -174],
      [-296, 234],
      [-313, 4],
      [-320, 51],
      [-309, -179],
      [-377, 193],
      [-260, 115],
      [-408, -38],
      [-225, -49],
      [-153, -32],
      [-223, -57],
      [-1, -160],
      [-620, -78],
      [-653, -29],
      [-693, -36],
      [-76, 12],
    ],
    [
      [41294, 29869],
      [63, 409],
      [42, 562],
      [-98, 199],
      [-59, 511],
      [69, 487],
      [112, 283],
      [107, 385],
      [94, 433],
      [-84, 212],
      [35, 488],
      [22, 509],
      [149, 507],
      [304, 538],
      [285, 606],
      [228, 349],
      [11, 54],
    ],
    [
      [42574, 36401],
      [197, -98],
      [222, -371],
      [281, -262],
      [31, -28],
      [144, -233],
      [251, -83],
      [-1, 114],
      [62, -35],
      [179, -68],
      [171, -60],
      [206, -7],
      [123, 23],
      [232, 140],
      [184, 138],
      [150, 177],
      [12, 14],
    ],
    [
      [45149, 19944],
      [-209, 39],
      [-285, 2019],
      [-923, 674],
      [-224, 164],
      [-193, 32],
      [-43, 3],
      [-641, 56],
      [-411, 0],
      [-404, -41],
      [-500, -195],
      [-359, -170],
      [-477, -358],
      [-469, -373],
      [-459, -474],
      [-412, -556],
      [-356, -402],
      [-355, -353],
      [-191, -180],
      [-315, -348],
      [-259, -199],
      [-291, -117],
      [-182, -92],
      [-56, 6],
    ],
    [
      [37135, 19079],
      [-401, 49],
      [-717, 182],
      [-413, 231],
      [-168, 383],
      [-155, 370],
      [-342, 481],
      [-387, 237],
      [-511, 368],
      [-234, 554],
      [-35, 341],
      [-136, 701],
      [-528, 634],
      [61, 447],
      [-56, 279],
      [14, 20],
      [23, 190],
      [-167, 465],
      [40, 392],
      [-102, 362],
      [-5, 509],
      [-128, 417],
      [-98, 185],
      [11, 233],
    ],
    [
      [32701, 27109],
      [81, 64],
      [-17, 170],
      [-7, 0],
      [265, 627],
      [401, 665],
      [361, 740],
      [389, 734],
      [482, 739],
      [407, 651],
      [484, 386],
      [393, 251],
      [375, 319],
      [110, 114],
      [258, 192],
      [388, 272],
      [150, 154],
      [-40, 150],
    ],
    [
      [37181, 33337],
      [204, -4],
      [457, -429],
      [132, -606],
      [-150, -541],
      [473, -660],
      [109, -348],
      [227, -519],
      [216, -378],
      [536, -63],
      [316, -60],
      [417, 27],
      [238, 152],
      [404, 81],
      [268, -79],
      [266, -41],
    ],
    [
      [43669, 47379],
      [1652, -1138],
      [3579, -2879],
    ],
    [
      [48900, 43362],
      [-150, -14],
      [-174, 11],
      [-312, -68],
      [-238, 34],
      [-197, 78],
      [-153, 50],
      [-133, 28],
      [-379, -50],
      [-226, -10],
      [-186, 18],
      [-598, -124],
      [-79, 0],
      [-213, -80],
      [-118, -147],
      [-199, -143],
      [-172, -144],
      [-198, -205],
      [-178, -169],
      [-317, -309],
      [-26, -135],
      [-204, -149],
      [-19, -185],
      [-131, -222],
      [27, -151],
      [-138, -202],
      [-131, -265],
      [15, -194],
      [121, -201],
      [96, -348],
      [141, -221],
      [56, -265],
      [61, -178],
      [124, -580],
      [82, -254],
      [59, -648],
      [-26, -79],
      [105, -223],
      [199, -490],
      [-25, -314],
      [92, -336],
      [-25, -303],
      [2, -280],
      [-117, -133],
    ],
    [
      [42574, 36401],
      [-70, 35],
      [-302, 66],
      [-336, -7],
      [-437, -20],
      [-519, -86],
      [-538, 23],
      [-353, 209],
      [-454, 185],
      [-227, 65],
      [-491, 42],
      [-276, -49],
      [-331, -83],
      [-319, -185],
      [-204, -166],
      [-191, -194],
      [-138, -181],
      [-180, -356],
      [-11, -32],
    ],
    [
      [37197, 35667],
      [-84, 13],
      [-329, 19],
      [-342, 60],
      [-335, 60],
      [-422, 62],
      [-565, 16],
      [-74, -19],
      [-144, -120],
      [-371, -102],
      [-42, -18],
    ],
    [
      [34489, 35638],
      [79, 221],
      [44, 244],
      [59, 304],
      [45, 312],
      [-88, 355],
      [-23, 217],
      [-52, 171],
      [-57, 591],
      [-108, 437],
      [-50, 211],
      [-45, 116],
      [-72, 164],
      [-97, 240],
      [-190, 254],
      [-6, 102],
      [-44, 164],
      [-52, 116],
      [-105, 144],
      [-158, 207],
      [-178, 206],
      [-246, 195],
      [-267, 160],
      [-265, 209],
      [-334, 168],
      [-239, 167],
      [-407, 197],
      [-415, 89],
      [-390, 20],
      [-464, -39],
      [-445, -40],
      [-235, -23],
      [-324, -56],
      [-480, -175],
      [-325, -144],
      [-529, -255],
      [-339, -232],
      [-300, -260],
      [-78, -92],
    ],
    [
      [27309, 40303],
      [-243, 639],
      [-237, 738],
      [-358, 754],
      [-247, 548],
      [-133, 560],
      [-209, 404],
      [-220, 507],
      [-192, 656],
      [-282, 474],
      [-83, 668],
      [49, 529],
      [96, 522],
      [263, 485],
      [120, 351],
      [280, 281],
      [303, 389],
      [556, 310],
      [469, 257],
      [468, 257],
      [367, 218],
      [648, 131],
      [546, 86],
      [29, 0],
      [644, 2],
      [517, -57],
      [745, -155],
      [461, -198],
      [741, -346],
      [476, -158],
      [608, -207],
      [563, -180],
      [729, -251],
      [676, -242],
      [495, -179],
      [362, -74],
      [1040, -222],
      [850, -273],
      [980, -166],
      [980, -146],
      [1318, -50],
      [855, 67],
      [809, 47],
      [200, -9],
      [2, 329],
      [319, -220],
    ],
    [
      [32701, 27109],
      [-266, -208],
      [-282, -403],
      [-468, -623],
      [-614, -567],
      [-589, -703],
      [-500, -582],
      [-422, -699],
      [-172, -282],
      [-194, -370],
      [-166, -696],
      [-50, -1038],
      [-255, -817],
      [-76, -902],
      [-127, -833],
      [-138, -608],
      [-13, -27],
      [-61, -441],
      [-115, -459],
      [-165, -235],
      [-182, -425],
      [-102, -100],
      [-59, -140],
    ],
    [
      [27685, 15951],
      [-169, 165],
      [-478, 381],
      [-67, 42],
      [-174, 94],
    ],
    [
      [26797, 16633],
      [-226, 123],
      [-133, 91],
      [-118, 158],
      [-226, 174],
      [-300, 161],
      [-237, -71],
      [-320, 155],
      [-252, 181],
      [-192, 186],
      [-319, 243],
      [-307, 128],
      [-388, 122],
      [-220, 119],
      [-158, 247],
      [-320, 175],
      [-361, 136],
      [-113, 27],
    ],
    [
      [22607, 18988],
      [-2, 294],
      [30, 237],
      [-186, 560],
      [-226, 594],
      [-110, 247],
      [-446, 679],
      [-412, 286],
      [-457, 456],
      [-201, 485],
      [-315, 433],
      [-425, 360],
      [-350, 373],
      [-186, 587],
      [-154, 458],
      [-215, 451],
      [-108, 362],
      [-177, 295],
      [-62, 307],
      [-35, 373],
      [-18, 83],
    ],
    [
      [18552, 26908],
      [441, 256],
      [489, 291],
      [176, 119],
      [427, 183],
      [257, 118],
      [266, 233],
      [0, 6],
      [129, 107],
      [219, 247],
      [260, 234],
      [239, 253],
      [399, 530],
      [112, 317],
      [189, 479],
      [128, 398],
      [367, 673],
      [398, 489],
      [571, 411],
      [288, 314],
      [402, 373],
      [194, 343],
      [58, 257],
      [77, 236],
      [101, 406],
      [220, 309],
      [414, 237],
      [341, 286],
      [419, 135],
      [399, 197],
      [82, 21],
    ],
    [
      [26614, 35366],
      [216, -352],
      [328, -481],
      [211, -288],
      [257, -337],
      [349, -434],
      [367, -203],
      [305, -223],
      [300, -141],
      [375, -87],
      [469, -117],
      [289, -53],
      [659, 57],
      [399, 95],
      [284, 131],
      [379, 156],
      [553, 113],
      [313, 246],
      [198, 180],
      [130, 154],
      [274, 294],
      [529, 303],
      [408, 658],
      [111, 249],
      [159, 317],
      [13, 35],
    ],
    [
      [37197, 35667],
      [-137, -400],
      [-115, -412],
      [34, -442],
      [93, -504],
      [73, -436],
      [36, -136],
    ],
    [
      [21577, 41427],
      [669, -212],
      [506, 67],
      [824, -593],
      [736, -739],
      [696, -223],
      [506, -301],
      [429, -130],
      [58, -26],
    ],
    [
      [26001, 39270],
      [61, -122],
      [71, -225],
      [51, -130],
      [-15, -142],
      [26, -428],
      [-30, -597],
      [74, -850],
      [126, -546],
      [170, -735],
      [79, -129],
    ],
    [
      [18552, 26908],
      [-32, 143],
      [-166, 518],
      [-185, 614],
      [-79, 545],
      [-178, 621],
      [-69, 761],
      [-153, 519],
      [-244, 683],
      [-103, 762],
      [-123, 654],
      [-72, 585],
      [-42, 700],
      [-47, 428],
      [-2, 5],
    ],
    [
      [69485, 24728],
      [-30, -45],
      [-77, -94],
      [-76, -95],
      [-125, -204],
      [-118, -78],
      [-194, -149],
      [-153, -108],
      [-223, -147],
      [-251, -138],
      [-168, -57],
      [-217, -92],
      [-125, -147],
      [-182, -100],
      [-294, -73],
      [-174, -88],
      [-225, -34],
      [-104, -130],
      [-195, -137],
      [-182, -68],
      [-420, -158],
      [-238, -72],
      [-715, -131],
      [-301, -86],
      [-462, -143],
      [-434, -138],
      [-665, -198],
      [-377, -174],
      [-231, -146],
      [-370, -211],
      [-426, -227],
      [-279, -267],
      [-376, -262],
      [-188, -186],
      [-201, -248],
      [-275, -963],
      [-143, -876],
      [-258, -720],
      [-212, -573],
      [-275, -495],
      [-281, -241],
      [-62, -7],
      [-225, 460],
      [-468, 499],
      [-261, 222],
      [-302, 510],
      [-353, 287],
      [-302, 124],
      [-220, -151],
      [-158, 66],
    ],
    [
      [56894, 18239],
      [-437, 181],
      [-405, 18],
      [-458, 5],
      [-292, 77],
      [-495, 192],
      [-47, 124],
      [-533, 191],
      [-18, 230],
      [-512, 313],
      [-229, 252],
      [-223, 195],
      [-256, 270],
      [-54, 25],
    ],
    [
      [53058, 30865],
      [208, 73],
      [212, 79],
      [75, -23],
      [226, 72],
      [83, -11],
      [307, 160],
      [294, 143],
      [212, 42],
      [185, 106],
      [231, 184],
      [123, 91],
      [82, 64],
      [137, 72],
      [45, 245],
      [274, 144],
      [88, 44],
      [178, 119],
      [184, 188],
      [163, 126],
      [184, 181],
      [198, 172],
      [171, 187],
      [164, 63],
      [192, 73],
      [198, 155],
      [51, 42],
    ],
    [
      [57523, 33656],
      [205, -148],
      [257, -256],
      [218, -452],
      [328, -429],
      [382, -395],
      [190, -351],
      [200, -120],
      [106, -208],
      [209, -252],
      [208, -245],
      [113, -233],
      [181, -256],
      [168, -286],
      [168, -299],
      [112, -202],
      [175, -231],
      [161, -317],
      [167, -249],
      [182, -225],
      [156, -291],
      [86, 44],
      [194, 2],
      [295, -41],
      [126, 103],
      [261, 183],
      [245, 115],
      [144, 158],
      [300, 156],
      [355, 232],
      [170, 200],
      [278, 242],
      [294, 156],
      [307, 260],
      [238, 196],
      [250, 232],
      [332, 221],
      [118, 203],
      [176, 223],
      [172, 262],
      [207, 203],
      [178, 292],
      [158, 163],
      [417, 290],
      [189, 242],
      [163, 200],
      [476, 379],
      [136, 159],
      [375, 256],
      [155, 200],
      [146, 133],
      [105, 80],
      [20, 12],
    ],
    [
      [68475, 33967],
      [98, -73],
      [672, -615],
      [315, -313],
      [673, -678],
      [-65, -310],
      [551, -646],
      [-124, -491],
      [259, -393],
      [4, -455],
      [-72, -569],
      [-124, -303],
      [-79, -662],
      [-233, -626],
      [-240, -688],
      [-297, -497],
      [-163, -606],
      [-187, -380],
      [13, -862],
      [9, -72],
    ],
    [
      [42525, 10304],
      [-360, 218],
      [-451, 329],
      [-207, 152],
      [-693, 447],
      [-606, 398],
      [-374, 169],
      [-726, 455],
      [-569, 234],
      [-630, 153],
      [-811, 191],
      [-548, 227],
      [-416, 61],
      [-202, -17],
      [-377, -13],
      [-466, -94],
      [-74, -26],
      [-548, -161],
      [-481, -195],
      [-513, -121],
      [-716, -572],
      [30, -343],
    ],
    [
      [32787, 11796],
      [-684, 249],
      [-327, 141],
      [-626, 391],
      [-339, 210],
      [-495, 191],
      [-377, 380],
      [-186, 166],
      [-123, 294],
      [-197, 289],
      [-222, 377],
      [-109, 328],
      [-318, 311],
      [-506, 328],
      [-484, 395],
      [-109, 105],
    ],
    [
      [37135, 19079],
      [113, -218],
      [241, -472],
      [494, -613],
      [589, -580],
      [472, -334],
      [359, -278],
      [352, -278],
      [366, -196],
      [261, -72],
      [201, -65],
      [179, -73],
    ],
    [
      [40762, 15900],
      [-13, -45],
      [98, -178],
      [226, -187],
      [310, -332],
      [244, -323],
      [244, -289],
      [172, -186],
      [208, -418],
      [136, -362],
      [76, -253],
      [77, -232],
      [76, -320],
      [70, -232],
      [72, -144],
      [117, -240],
      [-9, -156],
      [-98, -215],
      [-65, -277],
      [-132, -263],
      [-73, -393],
      [18, -122],
      [41, -347],
      [-32, -82],
    ],
    [
      [62763, 2374],
      [-11, -1],
      [-20, -3],
      [-126, -13],
      [-26, -3],
      [-154, -122],
      [-26, 17],
      [-256, 165],
      [-146, 7],
      [-109, 5],
      [-95, 29],
      [-106, 31],
      [-116, 88],
      [-18, 14],
      [-67, -4],
      [-61, -3],
      [-19, -20],
      [-41, -42],
      [-126, -393],
      [-96, -120],
      [-84, -104],
      [1, -250],
      [-22, -91],
      [-31, -120],
      [41, -71],
      [34, -57],
      [18, -293],
      [2, -39],
      [2, -28],
      [-49, -53],
      [-50, -55],
      [-1, 0],
      [-124, -73],
      [-380, -223],
      [-34, -20],
      [-690, -404],
      [-177, -53],
      [-244, -72],
      [-337, 14],
      [-85, 3],
      [-133, 26],
      [-266, 53],
      [-390, 76],
      [-412, 81],
      [-108, -25],
      [-10, 70],
      [-89, 263],
      [-207, 404],
      [-315, 367],
      [-209, 343],
      [-299, 396],
      [-236, 364],
      [-212, 234],
      [-110, 118],
      [-281, 307],
      [-237, 330],
      [-245, 399],
      [-152, 300],
      [-565, 435],
      [-265, 361],
      [-442, 441],
      [-155, 174],
      [-279, 346],
      [-265, 355],
      [-276, 202],
      [-357, 412],
      [-322, 60],
      [-381, 284],
      [-279, 31],
      [-415, 37],
      [-130, -52],
      [-128, 40],
      [-272, 47],
      [-230, 1],
      [-476, -58],
      [-187, -21],
      [-144, -51],
      [-418, -171],
      [-155, -51],
      [-213, -69],
      [-223, -53],
      [-339, -89],
      [-335, -82],
    ],
    [
      [47772, 6652],
      [-3, 20],
      [24, 157],
      [-32, 221],
      [-12, 132],
      [2, 171],
      [55, 250],
      [29, 453],
      [83, 223],
      [61, 204],
      [26, 259],
      [68, 248],
      [61, 198],
      [70, 288],
      [19, 260],
      [119, 259],
      [41, 275],
      [177, 297],
      [155, 250],
      [55, 277],
      [55, 229],
      [54, 270],
      [147, 191],
      [7, 1],
      [69, 243],
      [148, 254],
      [106, 265],
      [65, 44],
    ],
    [
      [49421, 12591],
      [106, 71],
      [233, -14],
      [304, -76],
      [268, -77],
      [317, 102],
      [331, 38],
      [296, 79],
      [296, 98],
      [366, 179],
      [344, 369],
      [268, 182],
      [260, 224],
      [252, 280],
      [415, 315],
      [239, 294],
      [288, 260],
      [232, 222],
      [168, 255],
      [464, 396],
      [245, 244],
      [316, 375],
      [189, 199],
      [198, 165],
      [140, 140],
      [280, 444],
      [175, 184],
      [302, 325],
      [181, 375],
    ],
    [
      [69485, 24728],
      [65, -489],
      [31, -414],
      [8, -113],
      [82, -481],
      [-71, -382],
      [-194, -367],
      [-243, -314],
      [-485, -415],
      [-387, -216],
      [-90, -50],
      [-491, -240],
      [-401, -365],
      [-527, -475],
      [-298, -322],
      [-34, -72],
      [-360, -312],
      [-327, -508],
      [-242, -351],
      [-264, -525],
      [-313, -558],
      [-299, -629],
      [-112, -360],
      [-240, -1239],
      [-297, -1578],
      [-90, -885],
      [-156, -1193],
      [-211, -572],
      [289, -347],
      [-157, -516],
      [80, -421],
      [-39, -574],
      [18, -1003],
      [-7, -270],
      [39, -678],
      [-49, -314],
      [-19, -617],
      [-36, -292],
      [-89, -443],
      [-130, -261],
      [7, -524],
      [-68, -180],
      [-236, -577],
      [-67, -194],
      [-124, -480],
      [-24, -171],
      [7, -297],
      [-146, -438],
      [-47, -144],
      [22, -188],
    ],
    [
      [48900, 43362],
      [54, -43],
      [-406, -367],
      [-259, -579],
      [-190, -156],
      [-134, -370],
      [-263, -281],
      [-192, -292],
      [-280, -398],
      [-113, -346],
      [391, -210],
      [504, -62],
      [40, 53],
      [328, 197],
      [296, 130],
      [641, 246],
      [527, 302],
      [553, 146],
      [407, 142],
      [89, 32],
      [361, 65],
      [577, 70],
      [512, 30],
      [684, 155],
      [638, -10],
      [746, 145],
      [542, -35],
      [266, -4],
    ],
    [
      [55219, 41922],
      [23, -188],
      [-5, -299],
      [-25, -291],
      [-25, -253],
      [-127, -464],
      [119, -282],
      [-162, -307],
      [-25, -352],
      [91, -156],
      [-86, -405],
      [92, -348],
      [-164, -170],
      [94, -491],
      [-58, -506],
      [-100, -305],
      [197, -579],
      [107, -511],
      [201, -237],
      [215, -300],
      [318, -313],
      [305, -300],
      [228, -295],
      [278, -348],
      [340, -354],
      [429, -180],
      [44, -32],
    ],
    [
      [26001, 39270],
      [824, -376],
      [533, 521],
      [44, 645],
      [-93, 243],
    ],
    [
      [26797, 16633],
      [-6, -30],
      [-24, -244],
      [-106, -304],
      [22, -278],
      [18, -164],
      [-36, -121],
      [-15, -115],
      [-123, -535],
      [-142, -58],
      [-224, -146],
      [-178, -207],
      [-172, -208],
      [-262, -396],
      [-763, -679],
      [-772, 67],
      [-584, 92],
      [-457, 1],
      [-448, 157],
      [-442, 130],
      [-197, 241],
      [-232, 228],
      [-295, 46],
      [-358, -62],
      [-450, 21],
      [-409, 48],
      [-388, 122],
      [-629, 208],
      [-447, 157],
      [-396, 88],
      [-423, 48],
      [-391, -3],
    ],
    [
      [17347, 15509],
      [41, 20],
      [304, 69],
      [189, 85],
      [606, 78],
      [688, 110],
      [446, 149],
      [838, 257],
      [516, 337],
      [385, 489],
      [181, 398],
      [366, 591],
      [209, 458],
      [260, 308],
      [231, 125],
      [0, 5],
    ],
    [
      [7744, 12984],
      [-4, 1],
      [-477, -393],
      [-562, -609],
      [-988, -350],
      [-569, 300],
      [-230, 121],
      [-575, 303],
      [-559, 438],
      [-1130, 876],
      [-740, 814],
      [-559, 703],
      [-11, 14],
      [-50, 62],
      [-448, 1021],
      [-338, 1168],
      [-504, 906],
      [64, 254],
      [21, -4],
      [34, 13],
      [27, 20],
      [27, 13],
      [7, -7],
      [3, 20],
      [32, 164],
      [42, 19],
      [392, -279],
      [662, -698],
      [539, -403],
      [389, -463],
      [385, 48],
      [638, -500],
      [827, -1],
      [213, 1111],
      [-51, 994],
      [448, 685],
      [963, 452],
      [58, 28],
    ],
    [
      [5720, 19825],
      [49, -207],
      [385, -272],
      [352, -291],
      [218, -228],
      [266, -188],
      [231, -262],
      [225, -255],
      [306, -222],
      [46, -48],
      [151, -207],
      [36, -706],
      [-6, -346],
      [-40, -394],
      [-141, -418],
      [-363, -442],
      [-353, -184],
      [-467, -209],
      [5, -102],
      [59, -96],
      [481, -205],
      [415, -123],
      [328, -80],
      [110, -260],
      [110, -280],
      [77, -185],
      [-43, -182],
      [-117, -175],
      [-190, -146],
      [-106, -128],
    ],
    [
      [14000, 18571],
      [-291, 252],
      [-237, 316],
      [-558, 417],
      [-700, 359],
      [-528, 172],
      [-428, 150],
      [-7, 6],
      [-301, 53],
      [-360, 189],
      [-186, 146],
      [-356, 20],
      [-399, 285],
      [-348, 81],
      [5, 312],
      [-410, 14],
      [-233, 153],
      [0, -7],
      [-40, 36],
    ],
    [
      [8623, 21525],
      [665, 779],
      [308, 736],
      [321, 1157],
      [107, 333],
      [176, 546],
      [100, 773],
      [74, 829],
    ],
    [
      [10374, 26678],
      [246, -117],
      [328, -53],
      [195, -37],
      [401, -136],
      [421, -157],
      [381, -108],
      [281, -107],
      [363, -13],
      [229, 30],
      [437, 27],
      [315, -60],
      [200, -105],
      [227, -120],
      [126, -137],
      [129, -295],
      [-4, -251],
      [236, -384],
      [370, -400],
      [425, -353],
      [172, -146],
      [101, -36],
      [6, 0],
      [279, -215],
      [407, -190],
      [438, 26],
      [282, -25],
      [283, 90],
      [255, -38],
      [46, -82],
      [45, -144],
      [57, -204],
      [-4, -265],
      [-165, -208],
      [16, -299],
      [137, -233],
      [14, -380],
      [-79, -338],
      [-106, -284],
      [-288, -368],
      [-584, -391],
      [-481, -229],
      [-355, -286],
      [-561, -214],
      [-242, -17],
      [-326, -164],
      [-331, -103],
      [-128, -25],
      [-231, -165],
      [-205, -207],
      [-132, -191],
    ],
    [
      [32787, 11796],
      [33, -372],
      [296, -820],
      [4, -606],
      [-422, -298],
      [-624, -322],
      [-429, -292],
      [-1473, -396],
      [-1809, 65],
      [-970, 323],
      [-905, -216],
      [-639, -832],
      [-458, -413],
      [-654, -519],
      [-1317, -311],
      [-93, 74],
      [-379, 302],
      [-418, 782],
      [-232, 656],
      [-166, 1369],
      [-825, 619],
      [-284, -538],
      [-198, -561],
      [-1016, -10],
      [-427, -121],
    ],
    [
      [14000, 18571],
      [101, -87],
      [185, -207],
      [206, -126],
      [456, -503],
      [189, -343],
      [65, -157],
      [11, -177],
      [150, -267],
      [222, -391],
      [258, -296],
      [292, -250],
      [213, -99],
      [28, -33],
    ],
    [
      [10568, 10752],
      [-117, 500],
      [-670, 1018],
      [-718, 549],
      [-1319, 165],
    ],
    [
      [5720, 19825],
      [1406, 668],
      [604, 336],
      [838, 631],
      [55, 65],
    ],
    [
      [10374, 26678],
      [24, 263],
      [219, 615],
      [581, 575],
      [168, 860],
      [-696, 223],
      [-999, -180],
      [-85, 5],
    ],
    [
      [55219, 41922],
      [357, -5],
      [570, -18],
      [769, -143],
      [624, -22],
      [720, -4],
      [957, -201],
      [725, -190],
      [793, -120],
      [387, -103],
      [663, -222],
      [513, -430],
      [500, -367],
      [285, -370],
      [432, -474],
      [326, -298],
      [362, -387],
      [271, -319],
      [190, -401],
      [431, -361],
      [446, -662],
      [390, -434],
      [363, -506],
      [470, -440],
      [93, -87],
      [379, -388],
      [493, -446],
      [226, -168],
      [521, -389],
    ],
    [
      [49421, 12591],
      [10, 52],
      [-45, 200],
      [5, 218],
      [-33, 243],
      [-42, 446],
      [-181, 440],
      [-28, 189],
      [-98, 284],
      [-97, 93],
      [-324, 153],
      [-208, 243],
      [-227, 178],
      [-263, 128],
      [-165, 19],
      [-193, 26],
      [-310, 12],
      [-158, -8],
      [-235, 44],
      [-268, -56],
      [-193, 39],
      [-193, 11],
      [-337, 4],
      [-172, -16],
      [-200, -18],
      [-200, 46],
      [-206, -32],
      [-186, -17],
      [-193, -10],
      [-310, 5],
      [-309, -38],
      [-311, -9],
      [-26, -2],
      [-366, 129],
      [-152, -41],
      [-1, 63],
      [-934, 64],
      [-175, -11],
      [-315, 46],
      [-153, 112],
      [-247, 112],
      [-316, -42],
      [-136, -59],
      [-168, 69],
    ],
    [
      [47772, 6652],
      [-40, -9],
      [-215, -20],
      [-376, -136],
      [-425, -39],
      [-194, -16],
      [-513, -149],
      [-252, -54],
      [-280, -6],
      [-280, 6],
      [-460, 5],
      [-193, 52],
      [-115, 14],
      [-301, 31],
      [-434, 106],
      [-86, 90],
      [-254, 192],
      [-228, 143],
      [9, 93],
      [26, 386],
      [149, 61],
      [-46, 287],
      [52, 312],
      [53, 359],
      [46, 360],
      [3, 64],
      [17, 350],
      [-73, 477],
      [-191, 303],
      [-27, 15],
      [-226, 132],
      [-293, 181],
      [-100, 62],
    ],
  ],
  transform: {
    scale: [0.00003431231906870541, 0.0000339399311670378],
    translate: [7.4915413006143465, 6.442783214125214],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Ado",
            id: "BN001",
            nameAbbr: "Ado",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.36396235914,
            Shape_Area: 0.085263058,
          },
        },
        {
          arcs: [[6, 7, 8, 9]],
          type: "Polygon",
          properties: {
            name: "Agatu",
            id: "BN002",
            nameAbbr: "Agatu",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.47295449335,
            Shape_Area: 0.10211861655,
          },
        },
        {
          arcs: [[10, 11, 12, -9]],
          type: "Polygon",
          properties: {
            name: "Apa",
            id: "BN003",
            nameAbbr: "Apa",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.0703105998,
            Shape_Area: 0.07291530331,
          },
        },
        {
          arcs: [[13, 14, 15, 16, 17, 18]],
          type: "Polygon",
          properties: {
            name: "Bukuru",
            id: "BN004",
            nameAbbr: "Bukuru",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.13120341009,
            Shape_Area: 0.12970599437,
          },
        },
        {
          arcs: [[-17, 19, 20, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Gboko",
            id: "BN005",
            nameAbbr: "Gboko",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.93508457272,
            Shape_Area: 0.15723812377,
          },
        },
        {
          arcs: [[23, 24, -19, 25, 26, 27, 28]],
          type: "Polygon",
          properties: {
            name: "Guma",
            id: "BN006",
            nameAbbr: "Guma",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.39093466957,
            Shape_Area: 0.2299616931,
          },
        },
        {
          arcs: [[29, 30, 31, 32, 33, 34, -27, 35, -22]],
          type: "Polygon",
          properties: {
            name: "Gwer East",
            id: "BN007",
            nameAbbr: "Gwer East",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.16849902199,
            Shape_Area: 0.20225785372,
          },
        },
        {
          arcs: [[36, 37, -34, 38, -11, -8]],
          type: "Polygon",
          properties: {
            name: "Gwer West",
            id: "BN008",
            nameAbbr: "Gwer West",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.3103095708,
            Shape_Area: 0.10165022234,
          },
        },
        {
          arcs: [[39, 40, -15, 41, 42, 43]],
          type: "Polygon",
          properties: {
            name: "Katsina-Ala",
            id: "BN009",
            nameAbbr: "Katsina-Ala",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.27455233794,
            Shape_Area: 0.2202283142,
          },
        },
        {
          arcs: [[44, 45, -30, -21, 46, 47]],
          type: "Polygon",
          properties: {
            name: "Konshisha",
            id: "BN010",
            nameAbbr: "Konshisha",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.77813370414,
            Shape_Area: 0.12572966037,
          },
        },
        {
          arcs: [[48, 49, 50, -40, 51]],
          type: "Polygon",
          properties: {
            name: "Kwande",
            id: "BN011",
            nameAbbr: "Kwande",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.7019471954,
            Shape_Area: 0.25068538135,
          },
        },
        {
          arcs: [[52, 53, -42, -14, -25]],
          type: "Polygon",
          properties: {
            name: "Logo",
            id: "BN012",
            nameAbbr: "Logo",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.67128692091,
            Shape_Area: 0.11291867371,
          },
        },
        {
          arcs: [[-35, -38, 54, -28]],
          type: "Polygon",
          properties: {
            name: "Makurdi",
            id: "BN013",
            nameAbbr: "Makurdi",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.98042406648,
            Shape_Area: 0.06838130681,
          },
        },
        {
          arcs: [[55, -4, 56, -32]],
          type: "Polygon",
          properties: {
            name: "Obi",
            id: "BN014",
            nameAbbr: "Obi",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.83800992071,
            Shape_Area: 0.03415138876,
          },
        },
        {
          arcs: [[57, 58]],
          type: "Polygon",
          properties: {
            name: "Ogbadigbo",
            id: "BN015",
            nameAbbr: "Ogbadigbo",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.04868578785,
            Shape_Area: 0.04206784344,
          },
        },
        {
          arcs: [[59, 60, 61]],
          type: "Polygon",
          properties: {
            name: "Ohimini",
            id: "BN016",
            nameAbbr: "Ohimini",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.96386891677,
            Shape_Area: 0.05155982986,
          },
        },
        {
          arcs: [[62, -5, -56, -31, -46]],
          type: "Polygon",
          properties: {
            name: "Oju",
            id: "BN017",
            nameAbbr: "Oju",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.56834441281,
            Shape_Area: 0.08957237239,
          },
        },
        {
          arcs: [[-60, 63, -2, 64, -59, 65]],
          type: "Polygon",
          properties: {
            name: "Okpokwu",
            id: "BN018",
            nameAbbr: "Okpokwu",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.29117691258,
            Shape_Area: 0.05923328386,
          },
        },
        {
          arcs: [[-33, -57, -3, -64, -62, 66, -12, -39]],
          type: "Polygon",
          properties: {
            name: "Oturkpo",
            id: "BN019",
            nameAbbr: "Oturkpo",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.9951581044,
            Shape_Area: 0.10910702865,
          },
        },
        {
          arcs: [[-18, -23, -36, -26]],
          type: "Polygon",
          properties: {
            name: "Tarka",
            id: "BN020",
            nameAbbr: "Tarka",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.75049394706,
            Shape_Area: 0.03140391677,
          },
        },
        {
          arcs: [[-54, 67, -43]],
          type: "Polygon",
          properties: {
            name: "Ukum",
            id: "BN021",
            nameAbbr: "Ukum",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.43631062189,
            Shape_Area: 0.12366345332,
          },
        },
        {
          arcs: [[-41, -51, 68, -47, -20, -16]],
          type: "Polygon",
          properties: {
            name: "Ushongo",
            id: "BN022",
            nameAbbr: "Ushongo",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.76746592817,
            Shape_Area: 0.10334805858,
          },
        },
        {
          arcs: [[-50, 69, -48, -69]],
          type: "Polygon",
          properties: {
            name: "Vandeikya",
            id: "BN023",
            nameAbbr: "Vandeikya",
            parent: "Benue",
            parentAbbr: "BN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.10387753216,
            Shape_Area: 0.06244687322,
          },
        },
      ],
    },
  },
};

const jigawa = {
  type: "Topology",
  arcs: [
    [
      [56424, 42792],
      [247, -45],
      [525, -58],
      [403, 21],
      [176, 14],
      [354, -5],
      [360, 8],
      [433, -59],
      [403, 35],
      [-1, 7],
      [546, -25],
      [373, 54],
      [380, 48],
      [451, 68],
      [317, 14],
      [220, -13],
      [328, -91],
      [146, -139],
      [263, -52],
      [144, -3],
    ],
    [
      [62492, 42571],
      [-160, -587],
      [-283, -1341],
      [-459, -1341],
      [-244, -569],
    ],
    [
      [61346, 38733],
      [-421, 328],
      [-103, 152],
      [-337, 112],
      [-123, 119],
      [-237, 105],
      [-88, 100],
      [-59, 79],
      [-252, 357],
      [-148, 205],
      [-148, 212],
      [-132, 125],
      [-162, 212],
      [-213, 258],
      [-236, 79],
      [-383, 264],
      [-130, 92],
      [-406, 98],
      [-236, 66],
      [-496, 31],
      [-179, 72],
      [-514, -94],
      [-384, -147],
      [-505, -334],
      [-268, -252],
      [-120, -240],
      [-437, -266],
      [-189, -273],
      [-96, -338],
      [-344, -300],
      [-7, -225],
      [144, -292],
      [-401, -293],
      [-306, -147],
      [-428, -120],
      [-246, -54],
      [-252, -81],
      [-148, -7],
      [-233, -34],
      [-119, -34],
      [-182, -33],
      [-212, -21],
      [-409, -48],
      [-362, 39],
      [-271, 72],
      [-173, 8],
    ],
    [
      [50395, 38285],
      [-179, 196],
      [-167, 132],
      [-214, 40],
      [-390, 45],
      [-266, -81],
      [-295, -60],
      [-247, -41],
      [-232, -41],
      [-345, -21],
      [-214, 26],
      [-256, 46],
      [-200, 85],
      [-216, 126],
      [-211, 158],
      [-43, 33],
      [-312, 257],
      [-137, 66],
      [-144, 84],
    ],
    [
      [46327, 39335],
      [79, 188],
      [36, 199],
      [145, 120],
      [211, 20],
      [209, -112],
      [209, -105],
      [186, -105],
      [237, 120],
      [286, 133],
      [411, 194],
      [321, 147],
      [538, 201],
      [237, 106],
      [241, -32],
      [219, 7],
      [204, 48],
      [280, 93],
      [209, 94],
      [438, 234],
      [639, 373],
      [338, 273],
      [353, 220],
      [355, 207],
      [340, 200],
      [264, 154],
    ],
    [
      [53312, 42312],
      [55, 33],
      [25, 92],
      [224, 300],
      [118, 93],
      [270, 186],
      [156, -19],
      [87, -79],
      [243, -86],
      [185, 193],
      [172, 167],
      [212, 75],
    ],
    [
      [55059, 43267],
      [165, 59],
      [186, -46],
      [242, -72],
      [323, -145],
      [81, -113],
      [110, -105],
      [185, -40],
      [73, -13],
    ],
    [
      [25191, 48105],
      [-225, 0],
      [-397, -201],
      [-174, -88],
      [-285, -384],
      [-571, -1443],
      [-761, -674],
      [-571, -192],
      [-381, -288],
      [-95, -193],
      [95, -961],
      [-285, -578],
      [-856, -96],
      [-1237, 0],
      [-291, -126],
    ],
    [
      [19157, 42881],
      [-122, 201],
      [-237, 217],
      [-346, 87],
      [-424, 250],
      [-359, 256],
      [-247, 105],
      [-183, 79],
      [-375, 294],
      [-158, 351],
      [-69, 278],
      [45, 401],
      [-35, 312],
      [-28, 312],
      [27, 523],
      [-35, 346],
      [28, 170],
      [-22, 251],
      [-122, 175],
      [-319, 98],
      [-366, 22],
      [-418, 97],
      [-364, 117],
      [-332, 145],
      [-73, 13],
    ],
    [
      [14623, 47981],
      [199, 164],
      [106, 97],
      [166, 219],
      [40, 48],
      [205, 152],
      [126, 77],
      [112, 56],
      [124, 49],
      [132, 42],
      [144, 43],
      [224, 78],
      [196, 36],
      [165, 43],
      [98, 22],
      [152, 77],
      [92, 35],
      [46, 41],
      [54, 137],
      [-31, 67],
      [-6, 82],
      [-32, 94],
      [-51, 67],
      [-110, 47],
      [-31, 114],
      [-85, 20],
      [-39, 40],
      [-12, 95],
      [41, 129],
      [80, 62],
      [139, 145],
      [73, 48],
      [165, 145],
      [165, 97],
      [285, 296],
      [93, 83],
      [153, 144],
      [131, 57],
      [105, 170],
    ],
    [
      [18037, 51399],
      [277, -55],
      [505, 40],
      [425, 40],
      [539, 130],
      [262, 51],
      [125, 76],
      [434, 148],
      [480, 163],
      [158, 97],
      [520, 231],
      [250, 98],
      [172, 179],
      [285, 221],
      [284, 174],
      [100, 137],
      [429, 325],
      [232, 166],
      [66, 83],
      [37, 285],
      [114, 212],
      [174, 261],
      [41, 143],
      [13, 27],
      [2, 136],
      [41, 82],
      [133, 172],
      [139, 178],
      [73, 42],
      [217, 91],
      [133, 57],
      [32, 13],
      [263, 106],
      [210, 97],
      [11, 5],
      [6, 329],
      [2, 73],
      [139, 96],
      [24, 17],
      [33, 23],
      [236, 28],
    ],
    [
      [25653, 56176],
      [-132, -291],
      [20, -240],
      [13, -272],
      [-31, -320],
      [-14, -293],
      [76, -373],
      [58, -239],
      [70, -100],
      [179, -284],
      [111, -444],
      [64, -295],
      [149, -312],
      [81, -205],
      [24, -189],
      [79, -282],
      [-13, -195],
      [31, -190],
      [-14, -244],
      [-85, -228],
      [-135, -464],
      [-44, -286],
      [-203, -374],
      [-83, -152],
      [-30, -76],
      [-134, -179],
      [-82, -104],
      [-55, -174],
      [-95, -383],
      [-34, -188],
      [-112, -166],
      [-41, -223],
      [-28, -184],
      [10, -68],
      [-62, -54],
    ],
    [
      [64305, 55032],
      [83, -529],
      [81, -325],
      [112, -390],
      [17, -93],
      [-230, -184],
    ],
    [
      [64368, 53511],
      [-53, -43],
      [-483, -154],
      [-251, -113],
      [-493, -55],
      [-434, -148],
      [-395, -273],
      [-465, -253],
      [-509, -241],
      [-391, -173],
      [-239, -41],
      [-494, -22],
      [-204, -259],
      [-255, -445],
      [-72, -57],
    ],
    [
      [59630, 51234],
      [-238, -189],
      [-316, -306],
      [-707, -228],
      [-245, -80],
      [-336, 131],
      [-256, 264],
      [-509, 211],
      [-583, -354],
      [-395, -452],
      [-550, -287],
      [-526, -373],
      [-631, -155],
      [-443, -87],
      [-649, -281],
      [-469, -134],
      [-999, 36],
      [-679, 11],
      [-353, 205],
      [-355, 270],
      [-95, 22],
    ],
    [
      [50296, 49458],
      [-46, 197],
      [-459, 853],
      [-433, 611],
      [-444, 851],
      [-313, 749],
      [-327, 735],
      [-333, 736],
      [-91, 421],
      [14, 222],
      [14, 49],
    ],
    [
      [47878, 54882],
      [1356, -94],
      [844, 334],
      [636, 509],
      [635, 940],
      [352, 922],
      [1154, 554],
      [1349, 658],
      [1487, 292],
      [1328, 639],
      [1356, 449],
      [1626, 469],
      [752, -423],
      [287, -162],
      [1004, -2231],
      [326, -2035],
      [688, -1178],
      [527, 333],
      [667, 155],
      [53, 19],
    ],
    [
      [44665, 22682],
      [129, -61],
      [1176, 19],
    ],
    [
      [45970, 22640],
      [-140, -173],
      [-207, -207],
      [-131, -472],
      [-140, -319],
      [1, -578],
      [-75, -352],
      [22, -538],
      [-75, -372],
      [-58, -445],
      [-35, -180],
      [-153, -379],
      [-43, -173],
      [-87, -406],
      [68, -319],
      [67, -245],
      [20, -153],
      [23, -371],
      [-142, -94],
      [-156, -94],
      [-98, -167],
      [251, -377],
      [83, -6],
      [113, -46],
      [-11, -266],
      [-75, -146],
      [-130, -386],
      [-10, -233],
      [68, -371],
      [-136, -340],
      [43, -259],
      [24, -139],
      [59, -411],
      [-92, -194],
      [1, -538],
      [-50, -279],
      [-47, -348],
    ],
    [
      [44722, 12264],
      [-30, -224],
      [-71, -226],
      [109, -491],
      [106, -384],
      [22, -593],
      [147, -499],
      [-106, -264],
      [-51, -387],
      [29, -137],
    ],
    [
      [44877, 9059],
      [-511, -35],
      [-162, -11],
      [-1470, 98],
      [-272, 749],
      [-570, 973],
      [-1082, 346],
      [-1577, -688],
      [-479, 83],
      [-1545, 462],
      [-1474, 588],
      [-1773, 306],
      [-1049, 211],
      [-473, -89],
      [104, 276],
      [-141, 777],
      [206, 808],
      [305, 937],
      [351, 478],
      [984, 897],
      [924, 744],
      [451, 459],
    ],
    [
      [35624, 17428],
      [98, -51],
      [334, -97],
      [384, -31],
      [428, 109],
      [275, 135],
      [267, 187],
      [274, 321],
      [217, 208],
      [144, 140],
      [420, 288],
      [323, 241],
      [165, 121],
      [41, 41],
    ],
    [
      [38994, 19040],
      [317, 320],
      [363, 301],
      [292, 228],
      [110, 73],
      [263, 274],
      [225, 101],
      [573, 256],
      [477, 276],
      [234, 300],
      [294, 215],
      [389, 221],
      [404, 142],
      [74, 14],
      [574, 117],
      [273, 121],
      [143, 93],
      [237, 128],
      [64, 73],
      [200, 194],
      [121, 153],
      [44, 42],
    ],
    [
      [45970, 22640],
      [54, 0],
      [998, 484],
      [993, 97],
      [299, 29],
      [855, -235],
      [186, -318],
      [242, -729],
      [260, -1027],
      [164, -896],
      [80, -1128],
      [-376, -705],
      [-47, -87],
      [-501, -899],
      [-258, -659],
      [69, -298],
      [187, -517],
      [410, -384],
    ],
    [
      [49585, 15368],
      [-165, -66],
      [-130, -27],
      [-382, -95],
      [-310, -2],
      [-231, -81],
      [-222, -114],
      [-439, -194],
      [-380, -82],
      [-276, -35],
      [-221, -15],
      [-180, -87],
      [-136, -181],
      [-290, -181],
      [-103, -452],
      [-77, -193],
      [-109, -253],
      [-91, -173],
      [-435, -361],
      [-276, -241],
      [-410, -271],
    ],
    [
      [38134, 28212],
      [-59, -244],
      [-87, -366],
      [-87, -359],
      [-116, -479],
      [-146, -433],
      [-197, -260],
      [-50, -346],
      [-73, -273],
      [-57, -326],
      [-182, -513],
      [-72, -512],
      [-72, -405],
      [71, -372],
      [18, -312],
      [90, -385],
      [153, -364],
      [-93, -207],
      [-55, -239],
      [82, -391],
      [201, -125],
      [288, -25],
      [245, -158],
      [374, -356],
      [261, -324],
      [200, -338],
      [55, -491],
      [8, -352],
      [160, -217],
    ],
    [
      [35624, 17428],
      [639, 651],
      [-416, 542],
      [-793, 197],
      [-194, 49],
      [-546, 434],
      [-787, 806],
      [-307, 556],
      [-387, 735],
      [-236, 935],
      [-515, 465],
      [-960, -94],
      [-283, -7],
      [-1088, -26],
      [-50, 59],
      [-92, 917],
      [232, 982],
      [846, 1071],
      [736, 1128],
      [69, 727],
      [23, 232],
      [499, 867],
      [-93, 1149],
      [-52, 171],
    ],
    [
      [31869, 29974],
      [103, 88],
      [279, 241],
      [315, 341],
      [256, 274],
      [169, 167],
      [308, 307],
      [36, 67],
      [220, 194],
      [132, 134],
      [169, 194],
      [104, 145],
    ],
    [
      [33960, 32126],
      [389, -175],
      [472, -230],
      [398, -197],
      [126, -72],
      [368, -170],
      [141, -132],
      [296, -550],
      [252, -344],
      [141, -198],
      [-6, -365],
      [81, -80],
      [112, -305],
      [126, -364],
      [60, -226],
      [355, -336],
      [37, -60],
      [155, -132],
      [228, -71],
      [236, 41],
      [207, 52],
    ],
    [
      [47224, 47483],
      [0, -725],
      [-261, -1054],
      [-1043, -528],
      [-1044, -527],
      [-782, -791],
      [0, -264],
      [-783, -791],
      [-261, -528],
      [-521, -263],
      [-299, -303],
    ],
    [
      [42230, 41709],
      [-10, 8],
      [-60, 119],
      [-100, 39],
      [-219, -14],
      [-308, -80],
      [-544, -28],
      [-227, 25],
      [-324, -61],
      [-295, -36],
    ],
    [
      [40143, 41681],
      [-36, -4],
      [-183, -28],
      [-287, -140],
      [-174, -206],
      [-806, 329],
      [-185, -7],
      [-549, -22],
      [-374, 125],
      [-193, 32],
      [-294, 111],
      [-146, 40],
      [-375, 408],
    ],
    [
      [36541, 42319],
      [64, 132],
      [165, 601],
      [180, 830],
      [99, 875],
      [55, 458],
      [-28, 446],
      [-98, 345],
      [-115, 334],
    ],
    [
      [36863, 46340],
      [114, 161],
      [55, 249],
      [-63, 315],
      [28, 188],
      [228, 48],
      [277, -44],
      [228, -1],
      [256, 68],
      [332, 101],
      [242, 117],
      [242, 166],
      [180, 140],
      [138, 191],
      [104, 136],
      [96, 150],
      [6, 7],
    ],
    [
      [39326, 48332],
      [133, -95],
      [436, -203],
      [478, -127],
      [305, 38],
      [159, -19],
      [97, -52],
      [90, -10],
      [595, -103],
      [263, 235],
      [470, 353],
      [346, 240],
      [366, 134],
      [519, -101],
      [825, -480],
      [180, -97],
      [554, -457],
      [90, 178],
      [316, -156],
      [295, -158],
      [234, -26],
      [421, -105],
      [393, -144],
      [190, 245],
      [143, 61],
    ],
    [
      [40143, 41681],
      [-176, -283],
      [-137, -220],
      [-124, -172],
      [-322, -260],
      [-209, -240],
      [-358, -253],
      [-429, -213],
      [-261, -141],
      [-715, -228],
      [-569, -194],
      [-461, -122],
      [-208, -67],
      [-808, -150],
      [-953, -78],
      [-504, -76],
      [-733, 14],
      [-279, 64],
      [-293, 78],
      [-271, 99],
      [-277, 120],
      [-158, 54],
      [-318, 204],
      [-361, 234],
      [-227, 140],
      [-128, 66],
      [-205, 118],
      [-130, -10],
      [-354, -18],
      [-216, -1],
      [-540, -12],
      [-390, -51],
      [-334, -61],
      [-417, -32],
      [-101, 7],
    ],
    [
      [28177, 39997],
      [-285, 21],
      [-394, 37],
      [-191, 81],
      [-156, 73],
      [-308, 35],
      [-300, 20],
      [-344, -14],
      [-619, -71],
      [-358, -71],
      [-121, -23],
      [-230, -156],
      [-191, -96],
      [-249, -50],
      [-226, -46],
      [-571, -193],
      [-524, -75],
    ],
    [
      [23110, 39469],
      [-396, 124],
      [-457, 24],
      [-495, 51],
      [-481, 118],
      [-426, 93],
      [-399, -16],
      [-304, -9],
      [-255, 128],
      [-134, 68],
      [-134, 176],
      [-345, 476],
      [77, 314],
      [173, 343],
      [15, 286],
      [-126, 484],
      [-129, 327],
      [-53, 286],
      [-84, 139],
    ],
    [
      [25191, 48105],
      [26, 0],
      [7, 0],
      [-7, -28],
      [-47, -196],
      [47, -153],
      [59, -192],
      [200, -333],
      [22, -253],
      [178, -298],
      [112, -121],
      [249, -82],
      [156, -80],
      [188, -186],
      [247, -153],
      [399, -113],
      [307, -77],
      [402, -39],
      [484, 35],
      [404, 51],
      [303, 19],
      [244, -49],
      [179, -19],
      [549, -198],
      [329, -100],
      [520, -490],
      [90, -401],
      [227, -146],
      [450, -265],
      [180, -250],
      [250, -287],
      [91, -59],
      [244, -196],
      [566, -45],
      [290, 12],
      [305, 33],
      [422, -125],
      [331, -115],
      [497, -51],
      [356, -12],
      [265, -43],
      [291, -191],
      [320, -213],
      [132, -39],
      [292, -128],
      [194, -210],
    ],
    [
      [36863, 46340],
      [-31, 89],
      [-423, 195],
      [-415, -91],
      [-173, 27],
      [-450, 308],
      [-472, 510],
      [-312, 568],
      [-271, 322],
      [-229, 398],
      [-153, 214],
      [-160, 514],
      [290, 429],
      [353, 316],
      [141, 196],
    ],
    [
      [34558, 50335],
      [149, -31],
      [263, 283],
      [442, 348],
      [360, 239],
      [380, 140],
      [650, 7],
      [278, -114],
      [492, -211],
      [332, -283],
      [333, -506],
      [209, -550],
      [174, -731],
      [201, -299],
      [485, -281],
      [20, -14],
    ],
    [
      [68955, 54770],
      [694, 208],
      [869, -414],
      [321, -492],
      [719, -1097],
      [360, -1762],
      [37, -420],
      [97, -1124],
      [335, -2082],
      [272, -1219],
      [139, -137],
      [-623, -415],
      [-702, -407],
      [-689, -566],
      [-937, -553],
      [-744, -414],
      [-500, -280],
      [-477, -333],
      [-536, -274],
      [-56, 650],
      [-392, 768],
      [-465, 383],
      [-756, -16],
      [-884, -242],
    ],
    [
      [65037, 44532],
      [-107, 265],
      [-234, 450],
      [17, 372],
      [76, 484],
      [132, 524],
      [-162, 418],
      [-116, 337],
      [-123, 504],
      [-190, 423],
      [34, 273],
      [300, 577],
      [445, 678],
      [141, 478],
      [-104, 364],
      [-76, 398],
      [-83, 603],
      [-112, 404],
      [-110, 577],
      [-2, 285],
      [-137, 304],
      [-198, 219],
      [-60, 42],
    ],
    [
      [64305, 55032],
      [877, 309],
      [1161, 389],
      [1235, -580],
      [1111, -460],
      [266, 80],
    ],
    [
      [53618, 11331],
      [155, -235],
      [977, -1290],
      [1410, -868],
      [1482, -127],
      [687, -106],
      [836, -130],
      [2693, -595],
      [1616, -151],
      [610, -57],
      [1953, -56],
      [440, -617],
      [49, -69],
      [-520, -1282],
      [-869, -652],
      [-680, -364],
      [-882, -265],
      [-504, -101],
      [-514, -102],
      [-1190, -639],
      [-721, -896],
      [-20, -849],
      [316, -888],
      [208, -724],
      [-876, -268],
      [-1158, 293],
      [-875, 636],
      [-737, 84],
      [-764, -229],
      [-1870, -427],
      [-1093, 182],
      [-1593, 378],
      [-635, 640],
      [-378, 964],
      [2, 72],
      [19, 907],
      [310, 1324],
      [57, 1388],
      [-252, 1010],
      [-672, 1443],
      [-585, 857],
      [-536, 142],
      [-1007, -289],
      [-676, -136],
      [-1831, -103],
      [-1123, -77],
    ],
    [
      [49585, 15368],
      [147, -138],
      [556, 89],
      [1253, 352],
      [220, -1206],
      [497, -1175],
      [783, -1077],
      [577, -882],
    ],
    [
      [7448, 50541],
      [-47, -30],
      [-95, -83],
      [-114, -171],
      [-73, -124],
      [-60, -68],
      [-115, -117],
      [-40, -69],
      [-47, -47],
      [-50, -19],
    ],
    [
      [6807, 49813],
      [-45, -17],
      [-77, 122],
      [-76, 60],
      [-41, 13],
      [-82, -8],
      [-68, -21],
      [-88, -28],
      [-136, -56],
      [-183, -91],
      [-68, -69],
      [-74, -62],
      [-156, -90],
      [-81, -49],
      [-75, -41],
      [-81, -28],
      [-314, -52],
      [-27, 0],
      [-137, 12],
      [-55, 6],
      [-109, 5],
      [-116, 6],
      [-14, 0],
      [-92, 277],
      [-77, 251],
      [-171, 80],
      [-137, 120],
      [-96, 81],
      [-151, 141],
      [-150, 52],
      [-89, 67],
      [-110, 67],
      [-62, 101],
      [-89, 74],
      [-102, 13],
      [-116, 5],
      [-95, 20],
      [-109, -8],
      [-61, 6],
      [-61, 6],
      [-7, 6],
      [-210, -84],
      [-74, -102],
      [-114, -76],
      [-88, -63],
      [-88, -55],
      [-107, -103],
      [-109, -35],
      [-103, 80],
      [-75, 60],
      [-49, 102],
      [-28, 88],
      [-69, 108],
      [-55, 88],
      [-157, 73],
      [-14, 47],
      [-42, 74],
      [-163, 19],
      [-68, 33],
      [-109, 19],
      [-149, -42],
      [-102, -42],
      [-434, -19],
      [-68, -27],
      [-61, -1],
      [-96, 19],
      [-54, 13],
      [-27, 13],
      [-103, 6],
      [-61, 3],
    ],
    [
      [52, 50980],
      [-5, 122],
      [-47, 625],
      [34, 660],
      [65, 266],
      [77, 484],
      [71, 300],
      [137, 539],
      [-45, 482],
      [1, 353],
      [0, 137],
      [38, 286],
      [216, 139],
      [746, 150],
      [814, 77],
      [516, 47],
      [611, 81],
      [171, 24],
      [643, 87],
      [821, 152],
      [570, 26],
      [463, -103],
      [552, -165],
      [422, -56],
      [443, -57],
      [388, -70],
      [59, -3],
    ],
    [
      [7813, 55563],
      [-100, -265],
      [-91, -402],
      [-45, -239],
      [-52, -231],
      [9, -252],
      [99, -257],
      [-11, -143],
      [17, -251],
      [-74, -75],
      [32, -367],
      [39, -326],
      [-29, -360],
      [-9, -381],
      [34, -427],
      [-53, -170],
      [-3, -259],
      [-45, -211],
      [30, -142],
      [-60, -123],
      [-53, -103],
      [0, -38],
    ],
    [
      [55059, 43267],
      [-88, 244],
      [15, 185],
      [-137, 291],
      [155, 259],
      [-83, 385],
      [200, 146],
      [334, 201],
      [287, 87],
      [385, -92],
      [323, -165],
      [224, -158],
      [90, -121],
    ],
    [
      [56764, 44529],
      [169, -230],
      [39, -92],
      [73, -99],
      [38, -86],
      [25, -127],
      [-26, -53],
      [-11, -86],
      [-103, -120],
      [-75, -86],
      [-95, -126],
      [-97, -60],
      [-69, -54],
      [-124, -99],
      [-38, -127],
      [16, -66],
      [-68, -86],
      [-12, -73],
      [18, -67],
    ],
    [
      [49728, 33885],
      [-28, -113],
      [42, -630],
      [18, -577],
      [-35, -457],
      [49, -484],
      [6, -285],
      [-52, -206],
      [36, -299],
      [156, -271],
      [63, -111],
    ],
    [
      [49983, 30452],
      [-89, -8],
      [-483, -101],
      [-162, -67],
      [-411, -121],
      [-304, -94],
      [-291, -54],
      [-340, -134],
      [-474, -153],
      [-355, -68],
      [-488, -167],
      [-474, -148],
      [-332, -173],
      [-325, -121],
      [-220, -34],
      [-362, -74],
      [-215, 39],
      [-161, 112],
      [-38, 159],
      [-90, 192],
      [-96, 166],
      [40, 106],
      [-110, 126],
      [-117, 105],
      [-108, 53],
      [-254, 145],
      [-246, 5],
      [-189, 39],
      [-273, -108],
      [-151, -87],
      [-295, -87],
      [-195, -41],
      [-332, -48],
      [-144, -21],
      [-440, -62],
      [-339, -95],
      [-253, -21],
      [-272, -187],
      [-243, -201],
      [-301, -167],
      [-187, -101],
      [-350, -195],
      [-250, -121],
      [-249, -108],
      [-133, -54],
      [-316, -135],
      [-316, -101],
      [-116, -30],
    ],
    [
      [33960, 32126],
      [6, 8],
      [131, 154],
      [198, 327],
      [51, 166],
      [65, 160],
      [-38, 352],
      [-23, 166],
      [29, 166],
      [22, 126],
      [117, 214],
      [109, 167],
      [132, 313],
      [52, 173],
      [-69, 205],
      [-12, 16],
    ],
    [
      [34730, 34839],
      [64, 31],
      [51, 34],
      [154, 159],
      [0, 54],
      [20, 48],
      [2, 12],
      [14, 87],
      [51, 60],
      [22, 20],
      [0, 6],
      [46, 36],
      [64, 58],
      [140, 107],
      [88, 74],
      [132, 114],
      [257, 68],
      [159, 19],
      [38, 15],
      [131, -6],
      [403, 109],
      [145, -13],
      [109, -19],
      [158, 47],
      [188, 14],
      [159, -87],
      [30, 2],
      [178, -67],
      [111, 42],
      [94, 27],
      [193, 187],
      [57, 53],
      [214, 207],
      [157, 140],
      [266, 101],
      [225, -45],
      [261, -19],
      [272, -18],
      [65, -40],
      [187, -59],
      [185, -6],
      [206, 67],
      [79, 47],
      [216, 126],
      [134, 80],
      [23, 13],
    ],
    [
      [40478, 36724],
      [-29, -46],
      [-246, -180],
      [-65, -23],
      [45, -43],
      [97, -152],
      [186, -339],
      [358, -64],
      [236, -39],
      [143, -19],
      [450, -19],
      [191, 47],
      [54, -145],
      [114, -305],
      [102, -86],
      [193, -12],
      [249, -13],
      [335, 21],
      [327, 34],
      [167, -132],
      [182, -145],
      [108, -33],
      [461, -217],
      [187, -53],
      [180, -65],
      [265, -59],
      [221, 27],
      [298, 74],
      [299, 28],
      [271, -6],
      [178, 7],
      [208, -52],
      [330, -112],
      [152, -72],
      [215, -39],
      [373, -152],
      [62, -185],
      [-103, -146],
      [38, -391],
      [166, -119],
      [300, 1],
      [305, 47],
      [350, 101],
      [267, 67],
      [351, 88],
      [323, 80],
      [356, 2],
    ],
    [
      [61346, 38733],
      [-474, -1104],
      [-688, -2383],
      [-441, -1261],
      [268, -881],
      [7, -586],
      [5, -468],
      [-558, -440],
      [-582, -161],
      [-1297, -575],
      [-1054, -441],
      [-1330, -436],
      [-1865, -530],
      [-390, -157],
    ],
    [
      [52947, 29310],
      [-424, 440],
      [-167, 118],
      [-352, 178],
      [-231, 146],
      [-289, 184],
      [-127, 6],
      [-228, 20],
      [-285, -2],
      [-208, 20],
      [-286, 52],
      [-171, -1],
      [-196, -19],
    ],
    [
      [49728, 33885],
      [35, 146],
      [117, 312],
      [57, 219],
      [-3, 324],
      [-69, 391],
      [-96, 345],
      [-64, 232],
      [-82, 371],
      [179, 352],
      [157, 180],
      [239, 252],
      [301, 340],
      [93, 199],
      [97, 279],
      [-90, 178],
      [-142, 212],
      [-62, 68],
    ],
    [
      [46327, 39335],
      [-151, 88],
      [-255, 231],
      [-203, 158],
      [-137, 86],
      [-369, 251],
      [-228, 65],
      [-209, 132],
      [-220, -1],
      [-113, 7],
      [-277, 32],
      [-201, 86],
      [-215, 92],
      [-148, -1],
      [-289, -47],
      [-155, 6],
      [-235, 45],
      [-87, 47],
      [-111, -47],
      [-69, -47],
      [-96, -54],
    ],
    [
      [42559, 40464],
      [-5, 531],
      [59, 173],
      [-117, 324],
      [-266, 217],
    ],
    [
      [47224, 47483],
      [185, 80],
      [323, 306],
      [86, 869],
      [92, 650],
      [-72, 465],
      [-597, 1079],
      [-369, 918],
      [-153, 437],
      [-305, 540],
      [-459, 851],
      [-160, 493],
      [-56, 385],
      [-35, 161],
      [-298, 533],
      [-106, 193],
    ],
    [
      [45300, 55443],
      [265, 239],
      [845, -549],
      [1080, -224],
      [388, -27],
    ],
    [
      [50296, 49458],
      [52, -221],
      [-123, -372],
      [-197, -471],
      [-258, -565],
      [-28, -902],
      [34, -822],
      [62, -855],
      [61, -815],
      [-226, -439],
      [-385, -339],
      [44, -530],
      [260, -165],
      [311, 14],
      [500, 75],
      [464, 81],
      [322, -138],
      [385, -79],
      [678, 36],
      [655, -363],
      [347, -244],
      [58, -32],
    ],
    [
      [14623, 47981],
      [-168, 31],
      [-620, 121],
      [-353, 77],
      [-175, 59],
      [-473, 163],
      [-308, 4],
      [-399, -43],
      [-30, -3],
      [-443, -67],
      [-328, -17],
      [-266, -37],
      [-203, -84],
      [-381, -161],
      [-379, -188],
      [-319, -181],
      [-15, -7],
    ],
    [
      [9763, 47648],
      [-105, 166],
      [-56, 128],
      [-118, 148],
      [-146, 162],
      [-97, 80],
      [-152, 127],
      [-111, 94],
      [-210, 141],
      [-23, -15],
      [-49, 13],
      [-33, -14],
      [-82, -21],
      [-157, -50],
      [-135, -56],
      [-232, -43],
      [-129, -22],
      [-192, -2],
      [-177, 4],
      [-171, 5],
      [-130, -2],
      [-157, 32],
      [-151, -2],
      [-205, 52],
      [-97, 81],
      [-84, 168],
      [-23, 136],
      [-1, 109],
      [59, 136],
      [60, 137],
      [60, 96],
      [45, 177],
      [13, 88],
      [30, 112],
    ],
    [
      [7448, 50541],
      [41, 25],
      [61, 14],
      [75, 1],
      [75, 28],
      [129, 49],
      [129, 70],
      [68, 35],
      [156, 70],
      [109, 28],
      [142, 56],
      [62, -19],
      [220, -72],
      [103, -87],
      [116, 1],
      [109, 62],
      [29, 26],
      [207, 181],
      [27, 34],
      [20, 28],
      [108, 117],
      [6, 20],
      [26, 89],
      [33, 68],
      [68, 69],
      [87, 89],
      [122, 104],
      [87, 69],
      [48, 21],
      [102, 69],
      [134, 137],
      [67, 130],
      [54, 55],
      [88, 56],
      [183, 97],
      [191, 43],
      [164, -25],
      [123, -32],
      [151, -66],
      [131, -101],
      [83, -46],
      [124, -53],
      [90, -108],
      [152, -73],
      [129, -18],
      [126, -182],
      [-26, -89],
      [-60, -110],
      [-13, -20],
      [-7, -20],
      [-47, -89],
      [-33, -48],
      [-100, -164],
      [-67, -69],
      [-73, -198],
      [-6, -41],
      [-12, -143],
      [-6, -27],
      [-67, -123],
      [-13, -82],
      [-11, -109],
      [-19, -129],
      [22, -108],
      [15, -123],
      [0, -34],
      [105, -168],
      [96, -19],
      [123, -6],
      [96, 1],
      [130, -25],
      [157, -12],
      [219, 23],
      [238, 17],
      [130, 29],
      [211, 63],
      [108, 70],
      [128, 117],
      [33, 82],
      [122, 62],
      [136, 50],
      [177, 49],
      [245, 85],
      [244, 118],
      [237, 85],
      [263, 105],
      [138, 36],
      [152, 63],
      [203, 57],
      [151, 63],
      [198, 57],
      [157, 50],
      [224, 58],
      [118, 49],
      [224, 132],
      [87, 109],
      [46, 55],
      [47, 76],
      [67, 109],
      [54, 171],
      [48, 88],
      [-6, 109],
      [-14, 100],
    ],
    [
      [16572, 51982],
      [150, 115],
      [125, 77],
      [138, 56],
      [231, 118],
      [92, 56],
      [77, -155],
      [36, -190],
      [77, -115],
      [90, -134],
      [25, -81],
      [-1, -55],
      [415, -273],
      [10, -2],
    ],
    [
      [65037, 44532],
      [-804, -281],
      [-789, -321],
      [-514, -287],
      [-28, -20],
      [-304, -664],
      [-106, -388],
    ],
    [
      [56764, 44529],
      [170, 188],
      [216, 538],
      [222, 379],
      [288, 936],
      [177, 438],
      [145, 338],
      [190, 485],
      [192, -39],
      [222, -105],
      [202, -132],
      [390, -237],
      [485, 87],
      [306, 161],
      [360, 259],
      [327, 399],
      [29, 186],
      [115, 173],
      [-20, 417],
      [-431, 204],
      [16, 153],
      [-97, 590],
      [54, 332],
      [-95, 291],
      [-218, 404],
      [-297, 238],
      [-82, 22],
    ],
    [
      [52947, 29310],
      [-1564, -625],
      [-1416, -748],
      [-1161, -680],
      [-1549, -230],
      [-842, 17],
      [-509, -2],
      [-357, -361],
      [-511, -686],
      [-651, -1079],
      [-285, -1362],
      [59, -637],
      [504, -235],
    ],
    [
      [34558, 50335],
      [80, 110],
      [117, 428],
      [-264, 671],
      [-202, 424],
      [-98, 582],
      [255, 869],
      [401, 252],
      [359, 496],
      [158, 309],
      [-37, 737],
      [-8, 145],
    ],
    [
      [35319, 55358],
      [43, 3],
      [71, 5],
      [386, 40],
      [159, 17],
      [210, 77],
      [349, 8],
      [164, 28],
      [320, 54],
      [7, 2],
      [229, 83],
      [5, 2],
      [3, 0],
      [20, -2],
      [323, -16],
      [263, -11],
      [7, -1],
      [92, -11],
      [166, 0],
      [10, -1],
      [30, 0],
      [238, -4],
      [159, -65],
      [752, -122],
      [795, -129],
      [462, -34],
      [211, -14],
      [540, 10],
      [41, 1],
      [14, -1],
      [153, -11],
      [242, -18],
      [39, -2],
      [3, -1],
      [202, -36],
      [4, 0],
      [531, 3],
      [22, 1],
      [233, 16],
      [6, -1],
      [192, -42],
      [79, -17],
      [465, -6],
      [226, -2],
      [92, -2],
      [28, -2],
      [551, -32],
      [386, -23],
      [458, 341],
    ],
    [
      [40478, 36724],
      [273, 147],
      [43, 312],
      [152, 193],
      [88, 193],
      [12, 60],
      [69, 145],
      [163, 319],
      [54, 153],
      [51, 115],
      [92, 164],
      [84, 233],
      [24, 52],
      [136, 246],
      [14, 40],
      [149, 339],
      [162, 272],
      [355, 399],
      [161, 266],
      [-1, 92],
    ],
    [
      [31869, 29974],
      [-686, 2249],
      [-335, 948],
      [-588, -250],
      [-692, -822],
      [-1089, -662],
      [-857, -85],
      [-645, 117],
      [-6, 1204],
      [-268, 675],
      [-1032, -134],
      [-446, 16],
      [1, -56],
      [-90, -27],
      [-229, -20],
      [-21, -31],
      [-89, -48],
      [-122, -110],
      [-347, -267],
      [2, 427],
      [1, 55],
      [-260, 59],
      [-349, 99],
      [-168, 183],
      [99, 382],
      [151, 424],
      [396, 439],
      [158, 210],
      [40, 2626],
      [-67, 141],
      [-144, 741],
      [-170, 496],
      [-231, 250],
      [-201, 127],
      [-349, 100],
      [-126, 39],
    ],
    [
      [28177, 39997],
      [41, -125],
      [84, -359],
      [132, -444],
      [363, -630],
      [192, -334],
      [270, -405],
      [220, -377],
      [389, -270],
      [443, -292],
      [310, -51],
      [302, -131],
      [383, -130],
      [574, 17],
      [272, 42],
      [531, -283],
      [310, -190],
      [281, -185],
      [472, -262],
      [325, -211],
      [390, -197],
      [269, -341],
    ],
    [
      [9763, 47648],
      [-582, -278],
      [-311, -208],
      [-244, -173],
      [-263, -254],
      [-99, -192],
      [-12, -143],
      [-39, -170],
      [-73, -185],
      [-25, -149],
      [-18, -171],
      [-24, -204],
      [-93, -205],
      [-92, -246],
      [-53, -123],
      [-187, -349],
      [-45, -163],
      [-80, -144],
      [-73, -151],
      [-87, -171],
      [-53, -43],
      [-115, -94],
      [-77, 101],
      [-242, 221],
      [-187, 202],
      [-59, 319],
      [58, 252],
      [-30, 176],
      [-16, 170],
      [-141, 284],
      [-251, 425],
      [-232, 446],
      [-295, 609],
      [-217, 378],
      [-334, 458],
      [-161, 284],
      [-21, 51],
      [-177, 443],
      [-169, 378],
      [-238, 406],
      [-232, 154],
      [-158, 175],
      [-400, -86],
      [-374, 43],
      [-429, 16],
      [-591, 28],
      [-531, 55],
      [-327, 85],
      [-470, 138],
      [-553, 211],
      [-275, 338],
      [-14, 388],
    ],
    [
      [25653, 56176],
      [70, 9],
      [96, 11],
      [419, -48],
      [73, -8],
      [9, -1],
      [253, -10],
      [268, -11],
      [645, -27],
      [168, -6],
      [17, 0],
      [8, 0],
      [350, 13],
      [46, -14],
      [183, -56],
      [352, 41],
      [66, -4],
      [168, -11],
      [13, -2],
      [165, -16],
      [637, -21],
      [259, -9],
      [3, -1],
      [148, -49],
      [101, 38],
      [8, 3],
      [376, -5],
      [7, -2],
      [146, -34],
      [39, -10],
      [7, -3],
      [76, -41],
      [275, -83],
      [362, 4],
      [285, 4],
      [563, 7],
      [3, 0],
      [161, -43],
      [112, -73],
      [193, 7],
      [188, 7],
      [14, 1],
      [8, -1],
      [222, -35],
      [3, -2],
      [201, -116],
      [645, -138],
      [221, -89],
      [115, 29],
      [398, -56],
      [388, 24],
      [133, 9],
    ],
    [
      [12943, 55207],
      [2, -9],
      [101, -359],
      [15, -88],
      [135, -366],
      [150, -521],
      [155, -352],
      [31, -224],
      [36, -129],
      [160, -249],
      [160, -189],
      [165, -120],
      [159, -100],
      [7, -7],
      [172, -120],
      [207, -107],
      [338, -227],
      [206, -100],
      [372, -131],
      [202, -58],
      [359, -56],
      [72, 1],
      [158, 76],
      [218, 173],
      [49, 37],
    ],
    [
      [7813, 55563],
      [199, -8],
      [435, 25],
      [395, 25],
      [382, 25],
      [388, 113],
      [65, 10],
      [248, 35],
      [328, 17],
      [293, 17],
      [41, 14],
      [582, -88],
      [272, 105],
      [313, 85],
      [157, -4],
      [102, -6],
      [246, 10],
      [96, -26],
      [0, 6],
      [366, -294],
      [181, -277],
      [41, -140],
    ],
  ],
  transform: {
    scale: [0.00003431252075782177, 0.000033939685701617186],
    translate: [8.1116067491378, 10.933444474170473],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5, 6]],
          type: "Polygon",
          properties: {
            name: "Auyo",
            id: "JG001",
            nameAbbr: "Auyo",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.38810786124,
            Shape_Area: 0.04142910659,
          },
        },
        {
          arcs: [[7, 8, 9, 10, 11]],
          type: "Polygon",
          properties: {
            name: "Babura",
            id: "JG002",
            nameAbbr: "Babura",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.40795678389,
            Shape_Area: 0.08650668185,
          },
        },
        {
          arcs: [[12, 13, 14, 15, 16]],
          type: "Polygon",
          properties: {
            name: "Biriniwa",
            id: "JG003",
            nameAbbr: "Biriniwa",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.57735448026,
            Shape_Area: 0.13167348315,
          },
        },
        {
          arcs: [[17, 18, 19, 20, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Birnin Kudu",
            id: "JG004",
            nameAbbr: "Birnin Kudu",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.61456284739,
            Shape_Area: 0.11849701233,
          },
        },
        {
          arcs: [[-19, 23, 24]],
          type: "Polygon",
          properties: {
            name: "Buji",
            id: "JG005",
            nameAbbr: "Buji",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.98865716281,
            Shape_Area: 0.04509725466,
          },
        },
        {
          arcs: [[25, -22, 26, 27, 28]],
          type: "Polygon",
          properties: {
            name: "Dutse",
            id: "JG006",
            nameAbbr: "Dutse",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.41694115109,
            Shape_Area: 0.08563528297,
          },
        },
        {
          arcs: [[29, 30, 31, 32, 33, 34]],
          type: "Polygon",
          properties: {
            name: "Gagarawa",
            id: "JG007",
            nameAbbr: "Gagarawa",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.06869734292,
            Shape_Area: 0.05998197734,
          },
        },
        {
          arcs: [[-32, 35, 36, 37, -8, 38]],
          type: "Polygon",
          properties: {
            name: "Garki",
            id: "JG008",
            nameAbbr: "Garki",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.78573017801,
            Shape_Area: 0.10745609476,
          },
        },
        {
          arcs: [[-34, 39, 40]],
          type: "Polygon",
          properties: {
            name: "Gumel",
            id: "JG009",
            nameAbbr: "Gumel",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.56035928893,
            Shape_Area: 0.01973167436,
          },
        },
        {
          arcs: [[41, 42, -13, 43]],
          type: "Polygon",
          properties: {
            name: "Guri",
            id: "JG010",
            nameAbbr: "Guri",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25075209089,
            Shape_Area: 0.08903381678,
          },
        },
        {
          arcs: [[44, -20, -25, 45]],
          type: "Polygon",
          properties: {
            name: "Gwaram",
            id: "JG011",
            nameAbbr: "Gwaram",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.22261664912,
            Shape_Area: 0.16701982664,
          },
        },
        {
          arcs: [[46, 47, 48, 49]],
          type: "Polygon",
          properties: {
            name: "Gwiwa",
            id: "JG012",
            nameAbbr: "Gwiwa",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.89725223478,
            Shape_Area: 0.04666420238,
          },
        },
        {
          arcs: [[-7, 50, 51]],
          type: "Polygon",
          properties: {
            name: "Hadejia",
            id: "JG013",
            nameAbbr: "Hadejia",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.24683000021,
            Shape_Area: 0.00392819013,
          },
        },
        {
          arcs: [[52, 53, -29, 54, 55, 56]],
          type: "Polygon",
          properties: {
            name: "Jahun",
            id: "JG014",
            nameAbbr: "Jahun",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.51886794209,
            Shape_Area: 0.09939652175,
          },
        },
        {
          arcs: [[57, 58, -53, 59, -3]],
          type: "Polygon",
          properties: {
            name: "Kafin Hausa",
            id: "JG015",
            nameAbbr: "Kafin Hausa",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.37803494317,
            Shape_Area: 0.11591247558,
          },
        },
        {
          arcs: [[-5, 60, 61, -30, 62, 63, -16, 64]],
          type: "Polygon",
          properties: {
            name: "Kaugama",
            id: "JG016",
            nameAbbr: "Kaugama",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.72460976079,
            Shape_Area: 0.07108200244,
          },
        },
        {
          arcs: [[65, 66, -47, 67, 68, -10]],
          type: "Polygon",
          properties: {
            name: "Kazaure",
            id: "JG017",
            nameAbbr: "Kazaure",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.10778557959,
            Shape_Area: 0.03042295467,
          },
        },
        {
          arcs: [[69, -1, -52, 70, -14, -43]],
          type: "Polygon",
          properties: {
            name: "Kiri Kasamma",
            id: "JG018",
            nameAbbr: "Kiri Kasamma",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.26224976125,
            Shape_Area: 0.06699062064,
          },
        },
        {
          arcs: [[71, -23, -26, -54, -59]],
          type: "Polygon",
          properties: {
            name: "Kiyawa",
            id: "JG019",
            nameAbbr: "Kiyawa",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.58921063345,
            Shape_Area: 0.09145372779,
          },
        },
        {
          arcs: [[-63, -35, -41, 72, 73]],
          type: "Polygon",
          properties: {
            name: "Maigatari",
            id: "JG020",
            nameAbbr: "Maigatari",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.37801224435,
            Shape_Area: 0.09108475937,
          },
        },
        {
          arcs: [[-51, -6, -65, -15, -71]],
          type: "Polygon",
          properties: {
            name: "Malam Maduri",
            id: "JG021",
            nameAbbr: "Malam Maduri",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.27876955935,
            Shape_Area: 0.06522307473,
          },
        },
        {
          arcs: [[-4, -60, -57, 74, -61]],
          type: "Polygon",
          properties: {
            name: "Miga",
            id: "JG022",
            nameAbbr: "Miga",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.01707531777,
            Shape_Area: 0.05114406008,
          },
        },
        {
          arcs: [[-55, -28, 75, -37, 76]],
          type: "Polygon",
          properties: {
            name: "Ringim",
            id: "JG023",
            nameAbbr: "Ringim",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.39212492562,
            Shape_Area: 0.0721588358,
          },
        },
        {
          arcs: [[-67, 77, -48]],
          type: "Polygon",
          properties: {
            name: "Roni",
            id: "JG024",
            nameAbbr: "Roni",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.00812599231,
            Shape_Area: 0.01970351236,
          },
        },
        {
          arcs: [[-73, -40, -33, -39, -12, 78]],
          type: "Polygon",
          properties: {
            name: "Sule Tankarkar",
            id: "JG025",
            nameAbbr: "Sule Tankarkar",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.64151381045,
            Shape_Area: 0.12335653825,
          },
        },
        {
          arcs: [[-62, -75, -56, -77, -36, -31]],
          type: "Polygon",
          properties: {
            name: "Taura",
            id: "JG026",
            nameAbbr: "Taura",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25587726823,
            Shape_Area: 0.05616403966,
          },
        },
        {
          arcs: [[79, -68, -50, 80]],
          type: "Polygon",
          properties: {
            name: "Yankwashi",
            id: "JG027",
            nameAbbr: "Yankwashi",
            parent: "Jigawa",
            parentAbbr: "JG",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.02515174476,
            Shape_Area: 0.0371514045,
          },
        },
      ],
    },
  },
};

const yobe = {
  type: "Topology",
  arcs: [
    [
      [44461, 55849],
      [-78, 27],
      [-93, 7],
      [-336, -101],
      [-296, -7],
      [-590, 84],
      [-455, 71],
      [-285, 65],
      [-289, -20],
      [-242, 45],
      [-294, 125],
      [-446, 456],
      [-307, 317],
      [-302, 596],
      [-239, 397],
      [-411, 483],
      [-332, 423],
      [-104, 172],
      [-186, 271],
      [-271, 291],
      [-214, 278],
      [-247, 430],
      [-98, 444],
      [-5, 365],
      [94, 822],
      [105, 485],
      [389, 671],
      [325, 445],
      [113, 231],
    ],
    [
      [39367, 63722],
      [281, -143],
      [84, -212],
      [415, -615],
      [416, -377],
      [605, -349],
      [635, -416],
      [604, -535],
      [548, -562],
      [289, -642],
      [214, -470],
      [84, -431],
      [-19, -517],
      [137, -517],
      [285, -516],
      [182, -404],
      [193, -265],
      [77, -198],
      [67, -358],
      [22, -232],
      [-25, -114],
    ],
    [
      [38921, 65230],
      [-89, -16],
      [-237, 92],
      [-471, 131],
      [-384, 72],
      [-236, 85],
      [-262, 12],
      [-190, -20],
      [-368, -2],
      [-36, 27],
      [-407, -94],
      [-232, -54],
      [-456, -114],
      [-357, -121],
      [-427, -127],
      [-294, -108],
      [-194, -126],
      [-434, -128],
      [-797, -294],
      [-343, -107],
      [-261, -34],
      [-617, -380],
      [-571, -274],
      [-448, -161],
      [-363, -127],
      [-587, -221],
      [-752, -162],
      [-565, -253],
      [-457, -307],
      [-294, -313],
      [-665, -652],
      [-575, -380],
      [-162, -181],
    ],
    [
      [26390, 60893],
      [-37, 420],
      [-360, 1762],
      [-719, 1097],
    ],
    [
      [25274, 64172],
      [60, 74],
      [470, 553],
      [521, 512],
      [624, 168],
      [425, 207],
      [995, 281],
      [772, 215],
      [463, 88],
      [338, 81],
      [404, -52],
      [222, -72],
      [532, 174],
      [497, 141],
      [580, -5],
      [408, 88],
      [330, 54],
      [332, 34],
      [212, -12],
      [445, 21],
      [495, -5],
      [403, 15],
      [396, 8],
      [452, 8],
      [320, -39],
      [178, -59],
      [72, -19],
      [191, -6],
      [332, 1],
      [291, -32],
      [361, -12],
      [411, -19],
      [275, 34],
      [338, 15],
      [297, 21],
      [395, 41],
      [327, 160],
      [231, 67],
      [154, 67],
      [155, 19],
    ],
    [
      [39978, 66987],
      [6, -185],
      [87, -298],
      [24, -324],
      [-214, -399],
      [-446, -187],
      [-496, 38],
      [-64, 6],
      [38, -311],
      [8, -97],
    ],
    [
      [59695, 65398],
      [281, -378],
      [458, -950],
      [198, -505],
      [88, -700],
      [404, -1099],
      [48, -883],
      [137, -816],
      [34, -876],
      [-120, -636],
      [-95, -325],
      [-172, -255],
      [-164, -330],
      [-144, -222],
      [-344, -423],
      [-345, -320],
      [-620, -690],
      [-282, -281],
      [-195, -365],
    ],
    [
      [58862, 55344],
      [-262, 59],
      [-326, 39],
      [-141, 287],
      [-396, 13],
      [-262, -146],
      [-437, -136],
      [-381, -76],
      [-214, -106],
      [-359, -273],
      [-284, -172],
      [-133, 145],
      [-146, 144],
      [-7, 14],
      [-307, 215],
      [-285, -23],
      [-770, -91],
      [-339, -69],
      [-326, -172],
      [-353, -29],
      [-403, -21],
      [-263, -119],
      [-345, -293],
      [-826, 32],
      [-368, -15],
      [-900, -333],
      [-755, -179],
      [-671, -323],
      [-865, -326],
      [-1124, -167],
    ],
    [
      [46614, 53223],
      [-224, 254],
      [-316, 480],
      [-559, 652],
      [-434, 653],
      [-491, 542],
      [-129, 45],
    ],
    [
      [39367, 63722],
      [73, 148],
      [-17, 325],
      [-233, 443],
      [-202, 325],
      [-63, 218],
      [-4, 49],
    ],
    [
      [39978, 66987],
      [-4, 101],
      [34, 67],
    ],
    [
      [40008, 67155],
      [93, 185],
      [276, 200],
      [359, 512],
      [408, 299],
      [638, 394],
      [426, 167],
      [765, 175],
      [1251, 37],
      [1411, 105],
      [811, 69],
      [850, 168],
      [1247, 369],
      [762, 288],
      [412, 279],
    ],
    [
      [49717, 70402],
      [367, -97],
      [1047, -23],
      [1449, -168],
      [631, -270],
      [730, 155],
      [1220, 342],
      [1187, 24],
      [1187, -400],
      [845, -554],
      [549, -363],
      [648, -820],
      [32, -331],
      [-61, -538],
      [-127, -663],
      [-100, -193],
      [-112, -478],
      [375, -477],
      [111, -150],
    ],
    [
      [76333, 39158],
      [41, -122],
      [415, -2331],
      [-650, -1251],
      [-909, -1818],
      [-664, -1217],
      [70, -909],
      [28, -16],
    ],
    [
      [74664, 31494],
      [-367, -265],
      [-511, -317],
      [-457, -204],
      [-393, -306],
      [-548, -67],
      [-160, 50],
      [-493, -34],
      [-449, -346],
      [-805, -90],
      [-881, -191],
      [-1067, -317],
      [-991, -223],
      [-937, -183],
      [-756, -50],
      [-818, -246],
      [-843, -530],
      [-1067, -297],
      [-657, -370],
      [-362, -199],
    ],
    [
      [62102, 27309],
      [-47, 916],
      [-139, 931],
      [253, 2206],
      [-6, 1485],
      [-52, 1343],
      [-428, 1416],
      [-264, 912],
      [-975, 1408],
      [-851, 986],
      [-945, 787],
      [-157, 128],
    ],
    [
      [58491, 39827],
      [168, 17],
      [669, 275],
      [547, 634],
      [546, 113],
      [439, 139],
      [804, 200],
      [546, 43],
      [439, -137],
      [632, -298],
      [581, -238],
      [389, -83],
      [887, -109],
      [782, 376],
      [720, 233],
      [195, 52],
      [823, 388],
      [574, 392],
      [249, -4],
      [724, -92],
      [807, -181],
      [543, -96],
      [513, -7],
      [722, 125],
      [1150, 350],
      [492, 115],
      [833, 96],
      [812, 36],
      [562, 94],
      [495, 75],
    ],
    [
      [76134, 42335],
      [-81, -501],
      [-64, -1640],
      [344, -1036],
    ],
    [
      [47971, 31709],
      [112, -480],
      [-53, -147],
      [269, -428],
      [338, -632],
      [258, -586],
      [111, -548],
      [-73, -512],
      [-30, -586],
      [90, -828],
      [-4, -400],
      [-30, -522],
      [170, -538],
      [346, -642],
      [442, -515],
      [428, -501],
      [560, -638],
      [35, -21],
      [84, -35],
      [272, -133],
      [377, -208],
      [334, -66],
      [362, -100],
      [487, -75],
      [506, 13],
      [104, 32],
      [298, 84],
      [368, 137],
      [493, -13],
      [188, -78],
      [248, -164],
    ],
    [
      [55061, 22579],
      [54, -1053],
      [518, -1364],
      [334, -1035],
      [-7, -582],
    ],
    [
      [55960, 18545],
      [-5, -434],
      [-437, -997],
      [-383, -747],
      [-845, -2056],
      [-289, -301],
      [-199, -349],
      [-135, -473],
      [-71, -112],
    ],
    [
      [53596, 13076],
      [-487, 439],
      [-1121, 1242],
      [-871, 1098],
      [-625, 931],
      [-744, 979],
      [-1203, 987],
      [-594, 408],
      [-951, 760],
      [-1249, 791],
      [-1026, 604],
      [-1400, 474],
      [-876, 87],
      [-1028, -5],
      [-984, -282],
      [-981, -428],
      [-428, -416],
      [-689, 90],
      [-739, 86],
      [-478, 311],
      [-290, 408],
      [43, 920],
      [164, 614],
      [199, 747],
      [57, 901],
      [499, 1280],
      [86, 560],
    ],
    [
      [37880, 26662],
      [207, 151],
      [580, 434],
      [435, 347],
      [625, 619],
      [492, 745],
      [57, 94],
    ],
    [
      [40276, 29052],
      [193, -50],
      [385, 41],
      [741, 134],
      [764, 103],
      [502, -147],
      [280, 44],
      [407, 12],
      [462, 90],
      [501, 218],
      [331, 259],
      [276, 220],
      [184, 235],
      [112, 363],
      [203, 367],
      [134, 264],
      [257, 156],
      [230, 61],
      [289, -31],
      [314, 64],
      [336, 78],
      [181, 40],
      [7, 7],
      [238, 54],
      [368, 75],
    ],
    [
      [62102, 27309],
      [2, -40],
      [-717, -681],
      [-459, -679],
      [-485, -1084],
      [-749, -986],
      [-801, -625],
      [-740, -335],
      [-709, -4],
      [-591, 97],
      [-738, 200],
      [-630, -188],
      [-428, -319],
      [4, -86],
    ],
    [
      [47971, 31709],
      [-36, 154],
      [-2, 710],
      [23, 542],
      [5, 415],
      [63, 317],
      [91, 645],
      [180, 467],
      [-253, 815],
      [81, 419],
      [-266, 289],
      [-236, -17],
      [-277, -125],
      [-811, -83],
      [-562, -12],
      [-360, -144],
      [-429, -177],
      [-443, -245],
      [-576, 56],
      [-201, 78],
    ],
    [
      [43962, 35813],
      [-217, 84],
      [-362, 182],
      [-398, 216],
      [-292, 174],
      [-482, 340],
      [-272, 194],
      [-378, 487],
      [-252, 255],
      [-595, 707],
      [-674, 1164],
      [-269, 716],
      [-95, 626],
      [-31, 489],
      [-20, 788],
      [523, 698],
      [260, 553],
      [74, 106],
    ],
    [
      [40482, 43592],
      [84, 122],
      [124, 114],
      [179, 221],
      [365, 348],
      [262, 335],
      [254, 356],
      [301, 600],
      [324, 341],
      [240, 411],
      [88, 365],
      [177, 527],
      [177, 404],
      [302, 485],
      [268, 342],
      [275, 471],
      [186, 187],
      [316, 559],
      [649, 439],
      [252, 647],
      [336, 545],
      [337, 470],
      [510, 549],
      [316, 396],
      [105, 40],
    ],
    [
      [46909, 52866],
      [315, -514],
      [380, -691],
      [240, -635],
      [326, -860],
      [100, -476],
      [235, -716],
      [176, -485],
      [575, -707],
      [621, -484],
      [599, -321],
      [1021, -130],
      [517, -408],
      [163, -457],
      [625, -1068],
      [160, -939],
      [94, -646],
      [132, -959],
      [154, -206],
      [432, -250],
      [932, -177],
      [793, -188],
      [1120, -609],
      [775, -430],
      [982, -591],
      [115, -92],
    ],
    [
      [82018, 61520],
      [10, -166],
      [-869, -1969],
      [-555, -717],
      [-558, -722],
      [-740, -825],
      [-1016, -756],
      [-1188, -890],
      [-383, -482],
      [-201, -801],
    ],
    [
      [76518, 54192],
      [-221, -129],
      [-266, -18],
      [-320, -141],
      [-137, -163],
      [-199, -235],
      [-524, -451],
      [-152, -82],
      [-238, -74],
      [-292, -120],
      [-21, 6],
      [-444, 42],
      [-162, 37],
      [-353, 66],
      [-414, -19],
      [-308, -14],
      [-630, -103],
      [-783, -187],
      [-284, -195],
      [-1121, -440],
      [-597, -236],
      [-876, 318],
      [-1250, -57],
      [-513, 22],
      [-641, 253],
      [-659, -4],
      [-861, -35],
      [-353, -69],
      [-839, -124],
      [-1201, 38],
      [-751, 79],
      [-1230, 248],
      [-753, 439],
      [-497, 577],
      [-163, 552],
      [146, 901],
      [251, 470],
    ],
    [
      [59695, 65398],
      [457, 332],
      [441, 442],
      [1234, 213],
      [1149, 445],
      [728, 192],
      [727, 160],
      [339, 205],
      [31, 549],
      [-149, 464],
      [253, 519],
      [519, 162],
      [734, 329],
      [286, -208],
      [522, -171],
      [578, -347],
      [996, -408],
      [965, -96],
      [923, 34],
      [916, 75],
      [742, 166],
      [846, 164],
      [554, 26],
      [852, 340],
      [817, 212],
      [193, 181],
      [714, 193],
      [518, 338],
      [623, 317],
      [498, 237],
      [582, 202],
      [599, 132],
    ],
    [
      [78882, 70797],
      [-104, -526],
      [-338, -1210],
      [-163, -1518],
      [313, -1056],
      [269, -744],
      [679, -851],
      [949, -733],
      [790, -697],
      [523, -361],
      [179, -823],
      [39, -758],
    ],
    [
      [78730, 27295],
      [-79, -168],
      [-943, -1078],
      [-1194, -999],
      [-924, -1580],
      [-630, -1191],
      [-1363, -1574],
      [-419, -1343],
      [18, -1396],
      [356, -1951],
      [-114, -1381],
      [-13, -20],
      [-969, -1430],
      [-1464, -1003],
      [-190, -96],
    ],
    [
      [70802, 12085],
      [-39, 59],
      [-441, 453],
      [-157, 477],
      [-224, 281],
      [-513, 658],
      [-686, 634],
      [-729, 857],
      [-1102, 626],
      [-327, 19],
      [-241, -214],
      [-130, -235],
      [-128, -486],
      [-243, -99],
      [-781, 452],
      [-169, 274],
      [-730, 871],
      [-615, 510],
      [-573, 456],
      [-765, 72],
      [-780, 215],
      [-855, 113],
      [-966, 48],
      [-1181, 44],
      [-1565, 260],
      [-251, 51],
      [-514, 75],
      [-137, -11],
    ],
    [
      [74664, 31494],
      [272, -151],
      [1339, 163],
      [973, -68],
      [854, -832],
      [619, -924],
      [394, -1565],
      [-385, -822],
    ],
    [
      [70802, 12085],
      [-1221, -616],
      [-1775, -429],
      [-1059, -398],
      [-1311, -293],
      [-1136, -303],
      [-1251, -904],
      [-809, -1330],
      [-288, -1304],
      [96, -2422],
      [34, -1525],
      [-355, -518],
      [-456, -664],
      [-791, -951],
      [-1165, -255],
      [-269, -68],
      [-417, -105],
      [-1105, 36],
      [-1667, 65],
      [-43, 72],
      [-98, 167],
      [-75, 726],
      [-64, 871],
      [-14, 195],
      [-212, 1243],
      [54, 1077],
      [171, 1150],
      [271, 1807],
      [71, 1544],
      [-144, 1453],
      [-174, 836],
      [-797, 662],
      [-603, 626],
      [-332, 300],
      [-176, -103],
      [-311, -199],
      [-255, -174],
      [102, 149],
      [368, 573],
    ],
    [
      [46614, 53223],
      [266, -309],
      [29, -48],
    ],
    [
      [40482, 43592],
      [-61, 9],
      [-201, -45],
      [-477, -305],
      [-574, -304],
      [-490, -278],
      [-325, -212],
      [-234, -288],
      [-288, -587],
      [-1195, -736],
      [-240, -451],
      [-577, -840],
      [-571, -731],
      [-524, -583],
      [-378, -442],
      [-144, -256],
    ],
    [
      [34203, 37543],
      [-234, 675],
      [-145, 905],
      [49, 908],
      [-447, 1269],
      [-154, 1265],
      [17, 1554],
      [-116, 1603],
      [-651, 1063],
      [-835, 1118],
      [86, 849],
      [-121, 1810],
      [-138, 872],
      [-100, 632],
      [-539, 1371],
      [-896, 832],
      [-1117, 865],
      [-1067, 640],
      [-562, 556],
      [-139, 138],
      [-272, 1219],
      [-335, 2081],
      [-97, 1125],
    ],
    [
      [25274, 64172],
      [-321, 491],
      [-869, 415],
      [-694, -209],
    ],
    [
      [23390, 64869],
      [199, 194],
      [228, 167],
      [153, 86],
      [224, 81],
      [344, 93],
      [527, 95],
      [203, 60],
      [344, 293],
      [170, 220],
      [-84, 192],
      [-336, 330],
      [-130, 99],
      [-386, 370],
      [-350, 304],
      [-520, 569],
      [-394, 389],
      [-195, 563],
      [48, 505],
      [216, 339],
      [378, 326],
      [441, 372],
      [394, 224],
    ],
    [
      [24864, 70740],
      [30, 17],
      [558, -5],
      [847, 56],
      [723, -31],
      [623, -31],
      [529, 35],
      [480, 28],
      [390, -25],
      [1004, 229],
      [146, 318],
      [-307, 291],
      [-427, 85],
      [-653, -135],
      [-332, -8],
      [-240, 192],
      [-62, 391],
      [84, 265],
      [218, 246],
      [601, 467],
      [500, 286],
      [595, 188],
      [790, 76],
      [1117, 236],
      [1171, 83],
      [580, -469],
      [709, -31],
      [204, -867],
      [44, -511],
      [-427, -572],
      [-268, -697],
      [213, -238],
      [120, -211],
      [652, -283],
      [504, -38],
      [770, 15],
      [788, 129],
      [288, 67],
      [621, -190],
      [990, -447],
      [601, -449],
      [217, -808],
      [24, -511],
      [4, -590],
      [125, -138],
    ],
    [
      [15600, 72023],
      [4, -161],
      [7, -161],
      [-111, -240],
      [-20, -201],
      [-7, -174],
      [-75, -527],
      [-210, -329],
    ],
    [
      [15188, 70230],
      [-752, 424],
      [-1626, -469],
      [-1356, -449],
      [-1328, -639],
      [-1487, -292],
      [-1349, -659],
      [-1154, -554],
      [-352, -921],
      [-635, -941],
      [-636, -508],
      [-844, -335],
      [-1356, 95],
      [-388, 26],
      [-1080, 224],
      [-845, 549],
      [394, 356],
      [200, 268],
      [431, 407],
      [1537, 1579],
      [202, 166],
      [201, 255],
      [396, 413],
      [115, 57],
      [261, 330],
      [253, 377],
      [135, 117],
      [472, 767],
      [407, 556],
      [660, 615],
      [96, 1],
      [145, 137],
      [258, 243],
      [426, 332],
      [336, 311],
      [826, 916],
      [1108, 1231],
      [31, 68],
      [53, 114],
      [57, -3],
      [46, 82],
      [176, 125],
      [147, 199],
      [101, 61],
      [334, 201],
      [132, 80],
      [9, 5],
      [495, 246],
      [182, 186],
      [291, 189],
      [331, 215],
      [8, 4],
      [703, 314],
      [431, 194],
      [19, 8],
      [514, 217],
      [133, 57],
      [74, 69],
      [279, 118],
      [299, 127],
      [6, 3],
      [463, 223],
      [974, 424],
      [8, 3],
      [347, 133],
      [4, 1],
      [839, 42],
    ],
    [
      [16345, 78920],
      [-3, -11],
      [-262, -780],
      [-127, -441],
      [9, -919],
      [-124, -825],
      [-330, -1041],
      [-5, -1289],
      [-75, -632],
      [127, -910],
      [14, -49],
      [31, 0],
    ],
    [
      [37880, 26662],
      [55, 361],
      [-37, 1385],
      [-483, 1514],
      [-784, 1851],
      [-672, 1808],
      [-388, 874],
      [-245, 389],
      [-296, 467],
      [-480, 1229],
      [-347, 1003],
    ],
    [
      [43962, 35813],
      [-97, -230],
      [-200, -200],
      [-331, -274],
      [-207, -126],
      [-539, -372],
      [-324, -341],
      [-547, -142],
      [-302, -464],
      [-81, -399],
      [-331, -321],
      [-364, -497],
      [-358, -483],
      [-164, -276],
      [415, -841],
      [115, -483],
      [-71, -814],
      [-300, -498],
    ],
    [
      [23390, 64869],
      [-266, -79],
      [-1111, 460],
      [-1234, 579],
      [-1162, -388],
      [-877, -310],
      [-53, -18],
      [-667, -155],
      [-527, -333],
      [-688, 1178],
      [-326, 2034],
      [-1004, 2232],
      [-287, 161],
    ],
    [
      [15600, 72023],
      [245, -2],
      [221, 313],
      [214, 307],
      [158, 635],
      [394, 363],
      [616, 321],
      [581, 204],
      [823, 203],
      [907, -170],
      [658, -280],
      [1147, -519],
      [1061, -461],
      [633, -800],
      [927, -899],
      [679, -498],
    ],
    [
      [76518, 54192],
      [-57, -229],
      [395, -1038],
      [417, -754],
      [49, -582],
      [-50, -945],
      [-24, -1181],
      [-165, -980],
      [-136, -1293],
      [-279, -1555],
      [-291, -1806],
      [-243, -1494],
    ],
    [
      [79020, 73251],
      [-12, -187],
      [20, -788],
      [-49, -990],
      [-97, -489],
    ],
    [
      [49717, 70402],
      [336, 227],
      [319, 850],
      [-48, 643],
      [-635, 820],
      [-766, 753],
      [-885, 681],
      [-535, 614],
      [-386, 569],
      [-493, 602],
      [109, 564],
      [73, 378],
      [-65, 511],
      [-173, 304],
      [-269, 463],
      [-125, 371],
      [-214, 736],
      [-108, 271],
      [-200, 503],
      [-149, 458],
      [-11, 151],
      [654, 1148],
    ],
    [
      [46146, 82019],
      [89, 0],
      [234, -3],
      [164, -2],
      [256, -1],
      [181, -1],
      [107, -8],
      [61, -4],
      [15, -1],
      [170, -36],
      [0, 27],
      [5, 0],
      [673, -5],
      [27, 0],
      [1069, -30],
      [85, -3],
      [45, -1],
      [250, -18],
      [566, -40],
      [533, -84],
      [194, -31],
      [398, -62],
      [83, -11],
      [404, -52],
      [308, -44],
      [75, -11],
      [8, -2],
      [62, -31],
      [66, -31],
      [147, -26],
      [185, -28],
      [78, 11],
      [79, 11],
      [926, -119],
      [7, 1],
      [132, 7],
      [4, -27],
      [497, -120],
      [12, -3],
      [1487, -669],
      [133, -76],
      [152, -81],
      [14, -13],
      [17, -11],
      [241, -59],
      [461, -196],
      [294, -128],
      [57, -25],
      [418, -105],
      [267, -68],
      [131, -104],
      [171, -36],
      [8, -2],
      [131, -42],
      [280, -90],
      [284, -89],
      [6, -2],
      [752, -164],
      [195, -42],
      [310, -66],
      [143, -65],
      [213, -7],
      [316, -67],
      [67, -14],
      [8, -1],
      [213, -19],
      [157, -100],
      [259, 0],
      [658, -125],
      [43, -9],
      [11, -3],
      [186, -49],
      [109, -32],
      [1419, -300],
      [176, -37],
      [214, -74],
      [52, -18],
      [0, -36],
      [132, -65],
      [453, -226],
      [153, -76],
      [20, -12],
      [41, -22],
      [35, -19],
      [58, -31],
      [75, -40],
      [314, -169],
      [7, -5],
      [162, -124],
      [463, -355],
      [207, -163],
      [355, -271],
      [167, -129],
      [177, -132],
      [127, -101],
      [5, -4],
      [96, -73],
      [56, -35],
      [17, -10],
      [71, -41],
      [18, -14],
      [9, -8],
      [483, -440],
      [52, -115],
      [40, -90],
      [359, -308],
      [406, -295],
      [131, -32],
      [55, -95],
      [764, -433],
      [894, -542],
      [288, -92],
      [116, 55],
      [144, -19],
      [198, -12],
      [46, 289],
      [17, 18],
      [80, 78],
      [14, -15],
      [50, -52],
      [72, -76],
      [82, -6],
      [206, -68],
      [54, 48],
      [-62, 210],
      [54, 88],
      [199, -13],
      [123, -142],
      [48, -34],
      [199, 47],
      [82, -94],
      [123, -68],
      [82, 163],
      [212, 82],
      [-240, 257],
      [-65, 141],
      [-4, 8],
      [48, 142],
      [149, -115],
      [9, -6],
      [129, -21],
      [185, 55],
      [233, 14],
      [130, -68],
      [109, -162],
      [220, -27],
      [342, -183],
      [103, -13],
      [129, 176],
      [34, 15],
      [55, 26],
      [459, -6],
      [93, -56],
      [146, -87],
      [86, -107],
      [65, -82],
      [2, -5],
      [73, -225],
      [-13, -197],
      [68, -163],
      [83, -67],
      [273, -27],
      [41, 34],
      [-13, 33],
      [-164, 41],
      [-55, 75],
      [191, 169],
      [212, 102],
      [89, 21],
      [287, -14],
      [116, -54],
      [158, -142],
      [164, -75],
      [27, -20],
      [192, -27],
      [144, -54],
      [-55, -156],
      [35, -101],
      [82, -75],
      [82, 41],
      [34, 325],
      [75, 68],
      [120, -99],
      [133, -111],
      [96, -27],
      [185, 68],
      [-1, 217],
      [21, 61],
      [96, -13],
      [89, -82],
      [20, -61],
      [-20, -189],
      [48, -55],
      [226, -20],
      [241, 25],
    ],
    [
      [16345, 78920],
      [192, 9],
      [11, 1],
      [80, 3],
      [141, 7],
      [213, 44],
      [334, -22],
      [550, 34],
      [241, 14],
      [128, 7],
      [180, 11],
      [489, 28],
      [189, 7],
      [460, 18],
      [955, 50],
      [196, 10],
      [205, 10],
      [300, 12],
      [15, 1],
      [301, 32],
      [104, 11],
      [412, 46],
      [17, 2],
      [12, 1],
      [18, 2],
      [156, -10],
      [6, -1],
      [359, 44],
      [30, 1],
      [424, 21],
      [226, 105],
      [290, 135],
      [8, 4],
      [1279, 483],
      [221, 81],
      [303, 89],
      [9, 2],
      [434, 211],
      [17, 9],
      [231, 75],
      [65, 22],
      [423, 162],
      [402, 128],
      [240, 104],
      [4, 2],
      [231, 95],
      [544, 201],
      [561, 201],
      [343, 68],
      [80, 15],
      [1020, 35],
      [35, 1],
      [129, 4],
      [705, 40],
      [560, 14],
      [56, 2],
      [14, 0],
      [649, 45],
      [397, 28],
      [68, 5],
      [815, 40],
      [181, 9],
      [345, 13],
      [268, 7],
      [329, 43],
      [602, -5],
      [14, 0],
      [544, 50],
      [212, 1],
      [120, 2],
      [27, 0],
      [272, 24],
      [49, 4],
      [169, 11],
      [1375, 77],
      [6, 0],
      [17, 1],
      [235, 45],
      [5, 1],
      [814, -11],
      [331, -4],
      [5, 0],
      [1, -27],
      [252, 27],
      [734, 18],
      [12, 4],
      [171, 47],
      [33, -14],
      [70, -34],
      [814, 15],
      [7, 0],
      [1042, 17],
      [45, 4],
      [60, 6],
      [74, 2],
      [136, 15],
      [51, 2],
      [10, 0],
      [262, -10],
      [81, -4],
      [266, -16],
      [11, 0],
      [4, 0],
      [22, 3],
      [153, 20],
      [9, 1],
      [41, 5],
      [21, -4],
      [68, -14],
      [97, -20],
      [702, 16],
      [17, 0],
      [205, 17],
      [0, -25],
      [0, -4],
      [1321, -14],
      [12, -1],
    ],
  ],
  transform: {
    scale: [0.00003431245521896306, 0.000033939566928344644],
    translate: [9.675055256465324, 10.590674916400918],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[[0, 1]], [[2, 3, 4, 5]]],
          type: "MultiPolygon",
          properties: {
            name: "Bade",
            id: "YB001",
            nameAbbr: "Bade",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.96226523599,
            Shape_Area: 0.06775917382,
          },
        },
        {
          arcs: [[6, 7, 8, -2, 9, -6, 10, 11, 12]],
          type: "Polygon",
          properties: {
            name: "Borsari",
            id: "YB002",
            nameAbbr: "Borsari",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.28126051226,
            Shape_Area: 0.32249464249,
          },
        },
        {
          arcs: [[13, 14, 15, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Damaturu",
            id: "YB003",
            nameAbbr: "Damaturu",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.9624703419,
            Shape_Area: 0.20249914017,
          },
        },
        {
          arcs: [[18, 19, 20, 21, 22, 23]],
          type: "Polygon",
          properties: {
            name: "Fika",
            id: "YB004",
            nameAbbr: "Fika",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.12388173495,
            Shape_Area: 0.17469877896,
          },
        },
        {
          arcs: [[24, -19, 25, 26, 27, 28, -16]],
          type: "Polygon",
          properties: {
            name: "Fune",
            id: "YB005",
            nameAbbr: "Fune",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.94921328597,
            Shape_Area: 0.41275831615,
          },
        },
        {
          arcs: [[29, 30, -7, 31, 32]],
          type: "Polygon",
          properties: {
            name: "Geidam",
            id: "YB006",
            nameAbbr: "Geidam",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.5491349499,
            Shape_Area: 0.35674136229,
          },
        },
        {
          arcs: [[33, 34, -20, -25, -15, 35]],
          type: "Polygon",
          properties: {
            name: "Gujba",
            id: "YB007",
            nameAbbr: "Gujba",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.4361207086,
            Shape_Area: 0.27644242079,
          },
        },
        {
          arcs: [[36, -21, -35]],
          type: "Polygon",
          properties: {
            name: "Gulani",
            id: "YB008",
            nameAbbr: "Gulani",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.12713965698,
            Shape_Area: 0.19181889292,
          },
        },
        {
          arcs: [[-10, -1, -9, 37, -28, 38, 39, -3]],
          type: "Polygon",
          properties: {
            name: "Jakusko",
            id: "YB009",
            nameAbbr: "Jakusko",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.64961591323,
            Shape_Area: 0.32370045271,
          },
        },
        {
          arcs: [[-11, -5, 40, 41, 42]],
          type: "Polygon",
          properties: {
            name: "Karasuwa",
            id: "YB010",
            nameAbbr: "Karasuwa",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.82568935588,
            Shape_Area: 0.09637741632,
          },
        },
        {
          arcs: [[43, 44, 45]],
          type: "Polygon",
          properties: {
            name: "Machina",
            id: "YB011",
            nameAbbr: "Machina",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.63878934008,
            Shape_Area: 0.10325571002,
          },
        },
        {
          arcs: [[46, -39, -27, 47, -23]],
          type: "Polygon",
          properties: {
            name: "Nangere",
            id: "YB012",
            nameAbbr: "Nangere",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.45132055807,
            Shape_Area: 0.0824592542,
          },
        },
        {
          arcs: [[-42, 48, -44, 49]],
          type: "Polygon",
          properties: {
            name: "Nguru",
            id: "YB013",
            nameAbbr: "Nguru",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.19563168379,
            Shape_Area: 0.07857741226,
          },
        },
        {
          arcs: [[-24, -48, -26]],
          type: "Polygon",
          properties: {
            name: "Potiskum",
            id: "YB014",
            nameAbbr: "Potiskum",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.9064546497,
            Shape_Area: 0.04573443021,
          },
        },
        {
          arcs: [[50, -17, -29, -38, -8, -31]],
          type: "Polygon",
          properties: {
            name: "Tarmuwa",
            id: "YB015",
            nameAbbr: "Tarmuwa",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.8979348737,
            Shape_Area: 0.37273320814,
          },
        },
        {
          arcs: [[51, -32, -13, 52, 53]],
          type: "Polygon",
          properties: {
            name: "Yunusari",
            id: "YB016",
            nameAbbr: "Yunusari",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.14643317623,
            Shape_Area: 0.32220198888,
          },
        },
        {
          arcs: [[-53, -12, -43, -50, -46, 54]],
          type: "Polygon",
          properties: {
            name: "Yusufari",
            id: "YB017",
            nameAbbr: "Yusufari",
            parent: "Yobe",
            parentAbbr: "YB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.41060223574,
            Shape_Area: 0.35650015849,
          },
        },
      ],
    },
  },
};

const borno = {
  type: "Topology",
  arcs: [
    [
      [68333, 37507],
      [-56, -116],
      [-40, -84],
      [-189, -780],
      [-70, -250],
      [-18, -61],
      [-259, -318],
      [-218, -264],
      [-22, -26],
      [-129, -74],
      [-348, -425],
      [-63, -298],
      [-36, -427],
      [-35, -109],
      [-51, -41],
      [-58, -46],
      [-85, -42],
      [-10, -5],
      [-29, -292],
      [-56, -80],
      [-67, -97],
      [-178, -257],
      [-41, -59],
      [-422, -242],
      [-138, -65],
      [-46, -22],
      [-81, -122],
      [-2, -14],
      [-14, -223],
      [122, -292],
      [10, -14],
      [37, -47],
      [-41, -109],
      [-41, -33],
      [-265, -67],
      [-68, -71],
      [-164, -172],
      [-72, -145],
      [-100, -200],
      [-52, -103],
      [-28, -55],
      [-23, -45],
      [-100, -78],
      [-29, -23],
      [-177, -6],
      [-164, -236],
      [-93, -73],
      [-2, -1],
      [-5, -4],
      [-131, -77],
      [-202, -238],
      [-187, -222],
      [-28, -60],
      [-41, -88],
      [-48, -217],
      [-63, -197],
      [-6, -20],
      [-143, -128],
      [-136, -81],
      [-62, -135],
      [8, -150],
      [4, -94],
      [-35, -127],
      [-20, -70],
      [-46, -41],
      [-117, -107],
      [-48, -54],
      [-197, -54],
      [-95, 21],
      [-148, -25],
      [-8, -1],
      [-190, -29],
      [-677, -466],
      [-474, -151],
      [-554, -86],
      [-111, -18],
      [-264, -265],
      [-102, -88],
      [-27, -285],
      [-38, -400],
      [-23, -136],
      [-837, -193],
      [-855, -291],
      [-952, -331],
      [-1820, -139],
      [-1159, 284],
      [-606, 255],
      [-290, 344],
      [-688, -94],
    ],
    [
      [52901, 26640],
      [-257, 423],
      [-116, 274],
      [-180, 370],
      [-110, 702],
      [97, 175],
      [214, 282],
      [296, 634],
      [138, 242],
      [256, 547],
      [117, 501],
      [71, 610],
      [-109, 477],
      [-22, 204],
      [-30, 252],
      [-75, 537],
      [-122, 485],
      [-159, 634],
      [-121, 293],
      [-188, 791],
      [-109, 586],
      [83, 753],
      [5, 196],
      [79, 331],
    ],
    [
      [52659, 36939],
      [9, 35],
      [191, 343],
      [197, 547],
      [203, 547],
      [115, 345],
      [274, 491],
      [163, 452],
      [93, 393],
      [-30, 252],
      [211, 478],
      [212, 472],
      [376, 646],
      [165, 276],
      [66, 373],
      [118, 126],
    ],
    [
      [55022, 42715],
      [249, -44],
      [838, -346],
      [1086, -207],
      [1484, -384],
      [2045, -820],
      [1501, -744],
      [833, -618],
      [774, -441],
      [586, -662],
      [420, -775],
      [352, -461],
      [834, -211],
      [634, -25],
      [835, 340],
      [840, 190],
    ],
    [
      [32033, 12344],
      [-95, -46],
      [-810, -33],
      [-754, -31],
      [-384, -1257],
      [-1150, -1231],
      [-1156, -532],
      [-1622, -517],
      [-1619, -97],
      [-1341, -135],
      [-185, -64],
      [-1000, -345],
      [-842, -842],
    ],
    [
      [21075, 7214],
      [-33, 35],
      [-454, 238],
      [-356, 73],
      [-138, -25],
      [-550, 90],
      [-486, -155],
      [-728, -219],
      [-526, -243],
      [-263, -111],
      [-534, -141],
      [-132, -25],
      [-397, 108],
      [-363, 135],
      [-712, 316],
      [-623, 506],
      [-512, 477],
      [-323, 399],
      [-267, 323],
      [-174, 30],
      [-153, -4],
      [-511, 252],
    ],
    [
      [12840, 9273],
      [208, 174],
      [130, 181],
      [489, 475],
      [231, 648],
      [158, 969],
      [-29, 869],
      [-99, 980],
      [-208, 723],
      [-493, 694],
      [-372, 359],
      [-290, 96],
    ],
    [
      [12565, 15441],
      [170, 144],
      [302, 457],
      [177, 370],
      [183, 480],
      [247, 437],
      [472, 821],
      [416, 157],
      [327, -67],
      [350, -257],
      [547, -538],
      [442, -482],
      [540, -396],
      [491, -551],
      [613, -105],
      [25, 278],
      [431, 740],
      [369, -40],
      [397, -876],
      [367, -616],
      [310, -365],
      [523, -178],
      [470, 284],
      [346, 185],
      [414, 353],
      [490, 400],
      [647, 655],
      [538, 446],
      [671, 391],
      [589, 262],
      [310, 253],
      [497, 391],
    ],
    [
      [25236, 18474],
      [35, -33],
      [406, -278],
      [567, -423],
      [633, -85],
      [509, -76],
      [431, -54],
      [483, -341],
      [552, -294],
      [627, -159],
      [343, -318],
      [158, -471],
      [347, -807],
      [447, -890],
      [295, -405],
      [190, -302],
      [396, -672],
      [84, -117],
      [294, -405],
    ],
    [
      [46359, 62724],
      [166, -341],
      [401, -704],
      [352, -547],
      [338, -574],
      [420, -433],
      [444, 75],
      [342, -134],
      [147, -158],
      [206, -31],
    ],
    [
      [49175, 59877],
      [221, -416],
      [189, -1006],
      [-13, -908],
      [26, -705],
      [512, -625],
      [1264, -770],
      [157, -492],
      [345, -488],
      [-86, -460],
      [-489, -529],
      [-413, -475],
      [-322, -141],
    ],
    [
      [50566, 52862],
      [-114, 19],
      [-362, 12],
      [-327, 46],
      [-361, -62],
      [-423, -116],
      [-158, -248],
      [-75, -149],
      [17, -285],
      [197, -329],
      [281, -331],
      [162, -226],
      [113, -118],
      [328, -174],
      [175, -160],
      [329, -263],
      [197, -275],
      [224, -132],
      [45, -320],
      [-199, -323],
      [-88, -250],
      [-89, -141],
      [-276, -213],
      [-216, -24],
      [-284, -29],
      [-292, -16],
      [-258, 24],
      [-257, 52],
      [-300, 100],
      [-120, 172],
      [-93, 273],
      [-58, 238],
      [-168, 221],
      [-148, 185],
      [-91, 117],
      [-71, 76],
      [-245, 167],
      [-208, -31],
      [-208, -58],
      [-242, -84],
      [-242, -152],
      [-242, -173],
      [-256, -105],
      [-312, -131],
      [-235, -118],
      [-187, -126],
      [-179, -120],
      [-229, -132],
      [-270, -84],
      [-181, -17],
      [-326, -56],
      [-222, -51],
      [-174, -18],
      [-292, 19],
      [-188, 50],
      [-293, 187],
      [-133, 117],
      [-232, 309],
      [34, 148],
      [18, 352],
      [-78, 218],
      [199, 221],
      [163, 473],
      [112, -124],
      [256, 179],
      [220, 289],
      [128, 486],
      [164, 289],
      [14, 107],
    ],
    [
      [43940, 52244],
      [166, 12],
      [562, 81],
      [570, -117],
      [355, 15],
      [528, -34],
      [307, -201],
      [140, -158],
      [106, -219],
      [209, -24],
      [352, 301],
      [110, 72],
      [143, 365],
      [-45, 441],
      [-63, 89],
      [-156, 341],
      [-79, 300],
      [-94, 442],
      [-134, 280],
      [-163, 409],
      [-135, 415],
      [-343, 262],
      [-377, 223],
      [-398, 249],
      [-328, 283],
      [-183, 240],
      [-196, 247],
      [-259, 193],
      [-12, 11],
    ],
    [
      [44523, 56762],
      [-2, 10],
      [-164, 504],
      [-73, 401],
      [-73, 469],
      [72, 568],
      [-94, 530],
      [219, 323],
      [-61, 671],
      [40, 237],
      [31, 474],
      [164, 337],
      [121, 445],
      [184, 445],
      [95, 311],
      [221, 98],
      [236, 92],
      [256, 64],
      [307, -65],
      [145, 45],
      [212, 3],
    ],
    [
      [32436, 53482],
      [222, -289],
      [134, -1073],
      [47, -706],
      [128, -408],
      [-139, -751],
      [-685, -322],
      [-630, -228],
      [-301, -599],
      [50, -1092],
      [217, -1102],
      [196, -1006],
      [238, -1040],
      [210, -1054],
      [235, -762],
      [449, -563],
      [-1, -779],
      [-145, -913],
      [54, -638],
      [208, -730],
      [240, -479],
      [256, -561],
      [353, -576],
      [340, -766],
      [33, -122],
    ],
    [
      [34145, 36923],
      [-350, -206],
      [-629, -370],
      [-359, -246],
      [-583, -92],
      [-829, 217],
      [-651, 418],
      [-638, 628],
      [-349, 100],
      [-582, 727],
      [-914, 501],
      [-734, 567],
      [-977, 515],
      [-684, 457],
      [-669, 281],
      [-613, 212],
      [-540, 632],
      [-170, 368],
      [-525, 611],
      [-487, 936],
      [-441, 372],
      [-425, 230],
      [-219, 57],
    ],
    [
      [21777, 43838],
      [385, 822],
      [-395, 1564],
      [-618, 924],
      [-854, 833],
      [-974, 68],
      [-1339, -164],
      [-272, 151],
      [-28, 16],
      [-70, 909],
      [664, 1218],
      [910, 1817],
      [649, 1252],
      [-415, 2331],
      [-40, 122],
    ],
    [
      [19380, 55701],
      [643, 127],
      [1464, 223],
      [1457, 128],
      [1890, -68],
      [1751, -79],
      [968, -210],
      [801, -371],
      [840, 123],
      [982, -292],
      [1115, -544],
      [820, -832],
      [325, -424],
    ],
    [
      [86618, 51172],
      [-586, 157],
      [-642, 181],
      [-303, 107],
      [-393, 190],
      [-552, 172],
      [-896, 417],
      [-703, 324],
      [-435, 259],
      [-289, 168],
      [-765, 414],
      [-263, 24],
      [-181, -21],
    ],
    [
      [80610, 53564],
      [9, 38],
      [193, 452],
      [-34, 673],
      [31, 1345],
      [144, 1050],
      [23, 468],
      [110, 922],
      [-47, 633],
      [-39, 390],
    ],
    [
      [81000, 59535],
      [-7, 72],
      [268, 349],
      [291, 899],
      [352, 965],
      [87, 1188],
      [50, 630],
      [153, 697],
      [96, 922],
      [-7, 788],
      [-258, 853],
      [-94, 484],
      [-80, 375],
      [-131, 633],
      [-49, 304],
    ],
    [
      [81671, 68694],
      [27, 3],
      [80, 10],
      [184, -48],
      [69, -49],
      [67, -47],
      [113, -313],
      [55, -7],
      [103, 155],
      [130, 108],
      [188, -1],
      [31, -1],
      [129, -82],
      [137, -230],
      [4, -8],
      [116, 75],
      [49, 32],
      [56, 29],
      [47, 25],
      [45, -31],
      [36, -24],
      [-56, -210],
      [34, -48],
      [148, -36],
      [22, -5],
      [61, -88],
      [81, -117],
      [111, -56],
      [107, -54],
      [381, -50],
      [162, -333],
      [-1, -75],
      [-41, -34],
      [-250, 21],
      [-23, 2],
      [-14, -61],
      [88, -109],
      [6, -102],
      [-53, -101],
      [-43, -82],
      [9, -11],
      [45, -57],
      [253, 26],
      [94, -75],
      [88, -143],
      [273, -29],
      [251, -179],
      [9, -11],
      [52, -63],
      [-2, -143],
      [-29, -104],
      [-27, -92],
      [34, -120],
      [40, -145],
      [87, -184],
      [108, 95],
      [77, 67],
      [41, -14],
      [-36, -183],
      [-27, -136],
      [-42, -81],
      [-123, -77],
      [-69, -43],
      [-19, -59],
      [-23, -70],
      [191, -69],
      [30, -7],
      [426, -98],
      [75, -96],
      [82, -7],
      [326, -29],
      [120, -24],
      [159, -33],
      [-14, -135],
      [-2, -3],
      [-26, -64],
      [163, -158],
      [235, -341],
      [-184, -127],
      [-55, -33],
      [-14, -55],
      [36, -24],
      [5, -3],
      [347, -43],
      [109, -49],
      [33, -136],
      [211, -62],
      [157, 6],
      [-20, 163],
      [76, 67],
      [143, 40],
      [41, -42],
      [20, 2],
      [48, 5],
      [19, 107],
      [189, -73],
      [239, 237],
      [102, -145],
      [103, -146],
      [-48, -197],
      [286, -142],
      [129, -265],
      [34, -171],
      [21, -107],
      [-41, -143],
      [-607, -460],
      [-88, -169],
      [-4, -264],
      [-3, -164],
      [-55, -250],
      [-261, -340],
      [-5, -6],
      [-51, -39],
      [-30, -22],
      [-27, -115],
      [68, -258],
      [55, -74],
      [231, -306],
      [0, -15],
      [-7, -168],
      [-170, -142],
      [-362, -109],
      [-279, -291],
      [5, -87],
      [10, -155],
      [-80, -202],
      [14, -106],
      [24, -186],
      [60, -98],
      [62, -99],
      [127, -239],
      [141, -319],
      [276, -463],
      [6, -102],
      [74, -143],
      [197, -117],
      [34, -70],
      [60, -120],
      [-21, -82],
      [-93, -87],
      [-79, -74],
      [-158, -182],
      [-189, -334],
      [-32, -58],
      [0, -62],
      [-1, -107],
      [73, -265],
      [60, -129],
      [-21, -68],
      [-75, -108],
      [-78, -36],
      [-66, -31],
      [-78, -77],
      [-25, -24],
      [-110, -182],
      [-49, 56],
      [-143, -308],
      [-30, -32],
      [-378, -402],
      [-62, -156],
      [3, -25],
      [11, -110],
      [136, -150],
      [102, -40],
      [-130, -202],
      [-115, -178],
      [14, -61],
      [-14, -183],
      [143, -407],
      [-116, -352],
      [-20, -353],
      [130, -420],
      [-69, -183],
      [-189, -239],
    ],
    [
      [44523, 56762],
      [99, -473],
      [57, -170],
      [-1, -671],
      [59, -550],
      [-8, -664],
      [-136, -344],
      [-128, -486],
      [-267, -532],
      [-226, -369],
      [-32, -259],
    ],
    [
      [50566, 52862],
      [-308, -134],
      [-131, -94],
      [-393, -319],
      [-80, -427],
      [218, -200],
      [384, -203],
      [632, -17],
      [716, 90],
      [492, 162],
      [637, 269],
      [292, 15],
      [432, -115],
      [752, -80],
      [634, -187],
      [1042, 11],
      [737, 90],
      [799, 1],
      [611, 79],
      [658, 227],
      [346, 191],
      [534, 223],
      [401, 238],
      [415, 143],
      [724, -39],
      [861, 67],
      [551, -232],
      [656, -262],
      [517, -205],
      [371, -291],
      [363, -230],
      [162, -159],
      [157, -192],
    ],
    [
      [64748, 51282],
      [-98, -203],
      [-517, -474],
      [-281, -518],
      [-172, -201],
      [-188, -636],
      [101, -416],
      [-449, -427],
      [-398, -483],
      [-497, -399],
      [-155, -500],
      [-22, -727],
      [39, -496],
      [40, -503],
      [179, -560],
      [232, -309],
      [-192, -357],
      [-289, -267],
      [-311, -172],
      [-495, 171],
      [-383, 13],
      [-1056, -78],
      [-241, 499],
      [-620, 207],
      [-334, 32],
      [-661, 58],
      [-541, -215],
      [-504, -338],
      [-603, -181],
      [-360, -157],
      [-421, -408],
      [-351, -341],
      [-14, -6],
      [-164, -175],
    ],
    [
      [52659, 36939],
      [-110, 57],
      [-300, 107],
      [-112, 70],
      [-202, 98],
      [-264, 18],
      [-279, 45],
      [-292, -43],
      [-482, 354],
      [-356, 169],
      [-420, 189],
      [-624, 649],
      [-554, 525],
      [-197, 268],
      [-483, 381],
      [-371, 298],
      [-190, 254],
      [-343, 203],
      [-378, 284],
      [-462, 381],
      [-425, 149],
      [-237, 25],
      [-772, 39],
      [-430, -109],
      [-436, -244],
      [-39, -312],
      [-114, -446],
      [-65, -420],
      [-188, -744],
      [-204, -567],
      [-275, -302],
      [-436, -271],
      [-518, -325],
      [-305, -117],
      [-414, -333],
      [-366, -259],
      [-166, -86],
      [-487, 35],
      [-360, -164],
      [-360, -137],
      [-451, -169],
      [-222, -92],
      [-80, -325],
      [-295, -463],
      [-260, -404],
      [-55, -87],
      [-157, -262],
      [-158, -324],
      [-179, -167],
      [-82, -134],
      [-305, -118],
      [-303, 446],
      [-344, 345],
      [-427, 367],
      [-685, 398],
      [-455, 401],
      [-378, 244],
      [-446, 95],
      [-191, 425],
      [-25, 89],
    ],
    [
      [32436, 53482],
      [95, 18],
      [201, 44],
      [388, 137],
      [346, 124],
      [485, 169],
      [758, -159],
      [523, -211],
      [796, 252],
      [445, 76],
      [687, 31],
      [1193, 186],
      [754, 436],
      [1163, 451],
      [1272, 789],
      [-51, 245],
      [-290, 661],
      [-69, 862],
      [-99, 1046],
      [236, 816],
      [274, 457],
      [385, 578],
      [330, 449],
      [307, 728],
      [-145, 653],
      [469, 427],
      [693, 234],
      [643, 377],
      [511, 285],
      [581, 385],
      [104, 70],
    ],
    [
      [45421, 64098],
      [369, -233],
      [346, -683],
      [223, -458],
    ],
    [
      [63121, 79247],
      [-96, -17],
      [-285, -30],
      [-361, -15],
      [-534, -33],
      [-611, -161],
      [-943, -142],
      [-542, 41],
      [-512, -311],
      [-538, -386],
      [-197, -239],
    ],
    [
      [58502, 77954],
      [-205, 137],
      [-7, 13],
      [-281, 364],
      [-363, 276],
      [-443, 637],
      [-621, 327],
      [-476, 427],
      [-377, 270],
      [-140, 171],
      [-99, 158],
      [-330, 405],
      [-322, 370],
      [-364, 365],
      [-365, 357],
      [-408, 698],
      [-442, 569],
      [-296, 567],
      [-267, 349],
      [-205, 369],
      [-114, 374],
      [-95, 531],
      [32, 372],
      [-135, 388],
      [-123, 612],
      [-118, 842],
      [-37, 333],
      [-257, 878],
      [-186, 599],
      [-301, 1130],
      [-267, 492],
      [70, 442],
      [-165, 156],
      [-260, 26],
      [-139, 39],
    ],
    [
      [50396, 91997],
      [77, 228],
      [194, 738],
      [353, 839],
      [326, 851],
      [460, 775],
      [539, 703],
      [555, 320],
      [671, 137],
      [218, 23],
      [326, 1],
    ],
    [
      [54115, 96612],
      [238, -403],
      [335, -526],
      [297, -426],
      [173, -194],
      [267, -303],
      [367, -710],
      [195, 4],
      [84, -137],
      [120, -185],
      [161, -226],
      [239, -308],
      [182, -199],
      [351, -385],
      [341, -141],
      [153, 39],
      [476, -475],
      [333, 30],
      [188, -105],
      [182, -124],
      [399, -332],
      [473, -95],
      [382, -32],
      [445, -115],
      [299, -4],
      [223, -105],
      [122, -402],
      [-271, 4],
      [-95, -385],
      [-172, -126],
      [-84, 83],
      [13, 101],
      [-23, 299],
      [-83, -27],
      [-21, -60],
      [-207, -180],
      [-125, 83],
      [-77, 35],
      [-145, -100],
      [182, -206],
      [78, -177],
      [215, -3],
      [78, -130],
      [127, -232],
      [36, -204],
      [-27, -88],
      [10, -373],
      [105, -157],
      [527, 135],
      [298, 97],
      [242, 85],
      [312, 90],
      [451, 136],
      [172, 235],
      [199, 234],
      [165, 228],
      [255, 261],
      [296, 260],
      [284, 179],
      [304, 226],
      [241, 200],
      [235, 214],
      [96, 120],
      [380, 232],
      [374, 83],
      [-5, -217],
      [-116, -175],
      [-386, -428],
      [71, -211],
      [305, 91],
      [85, -273],
      [-46, -175],
      [42, -164],
      [71, -150],
      [120, -178],
      [-6, -156],
      [-152, -99],
      [-99, 137],
      [-77, 177],
      [-78, 218],
      [-64, 110],
      [-173, -113],
      [-228, -112],
      [-60, -250],
      [-6, -102],
      [-200, -220],
      [-187, -79],
      [-326, -43],
      [-69, -13],
      [-368, -130],
      [37, -238],
      [106, -198],
      [114, -334],
      [56, -109],
      [-138, -147],
      [-124, -154],
      [-186, -153],
      [-110, -73],
      [-124, -216],
      [-170, -343],
      [-89, -222],
      [-89, -209],
      [-89, -100],
      [-171, -323],
      [-143, -331],
      [50, -210],
      [140, -145],
      [166, 18],
      [159, 113],
      [166, 86],
      [153, -9],
      [-47, -196],
      [60, -482],
      [-45, -460],
      [-178, -357],
      [-96, -182],
      [76, 33],
      [327, -52],
      [-53, -250],
      [-108, -385],
      [-249, -180],
      [-294, -538],
      [190, -267],
      [242, 58],
      [499, 142],
      [134, -253],
      [-124, -154],
      [-269, -165],
      [-333, -97],
      [-270, -24],
      [-242, -213],
      [77, -83],
      [153, 39],
      [131, 59],
      [432, -149],
      [133, -96],
      [-62, -122],
      [-102, -228],
      [98, -70],
      [319, -18],
      [243, -3],
      [-19, -129],
      [-331, -266],
      [-202, 9],
      [-160, -11],
      [-263, -152],
      [58, -198],
      [180, 39],
      [402, 69],
      [58, -279],
      [-227, -207],
      [-22, 54],
      [-84, 117],
      [-319, -23],
      [-159, -86],
      [-13, -196],
      [126, -151],
      [271, 44],
      [76, 12],
      [-81, -297],
      [-150, -282],
      [-165, -201],
      [104, -2],
      [152, 3],
    ],
    [
      [12840, 9273],
      [-524, -439],
      [-974, -630],
      [-690, -553],
      [-558, -575],
      [-518, -372],
      [-595, -350],
      [-249, -105],
      [-104, -26],
      [-215, -43],
      [-444, -102],
      [-271, -30],
      [-335, 101],
      [-216, 125],
      [-71, 76],
    ],
    [
      [7076, 6350],
      [84, -1],
      [223, -17],
      [283, 151],
      [429, 211],
      [214, 173],
      [303, 376],
      [-23, 163],
      [-142, 403],
      [-81, 409],
      [-53, 523],
      [-66, 402],
      [-217, 961],
      [-150, 444],
      [-321, 881],
      [-224, 948],
      [-364, 1045],
      [-156, 1034],
      [-51, 1047],
      [264, 817],
      [288, 390],
      [509, 589],
      [22, 16],
    ],
    [
      [7847, 17315],
      [6, -9],
      [118, -16],
      [84, -69],
      [482, -252],
      [531, -267],
      [679, -452],
      [544, -260],
      [697, -201],
      [941, -266],
      [467, -150],
      [63, -22],
      [106, 90],
    ],
    [
      [55725, 66045],
      [521, -669],
      [429, -630],
      [1090, -808],
      [671, -525],
      [455, -481],
      [357, -283],
      [258, -174],
      [469, -313],
      [112, -76],
      [56, -62],
      [251, -113],
      [508, 33],
      [458, -1],
      [230, 17],
      [201, 11],
      [230, -52],
      [63, -7],
      [55, -8],
      [263, 172],
      [146, 18],
      [221, 160],
      [241, 281],
      [302, 315],
      [186, 261],
      [206, 262],
      [290, 260],
      [260, 363],
      [145, 147],
      [333, 110],
      [296, -446],
      [314, -134],
      [357, -196],
      [271, -65],
      [716, 36],
      [343, -243],
      [188, -105],
      [553, -355],
      [120, -179],
      [183, -315],
      [92, -212],
      [43, -96],
      [50, -130],
      [197, -247],
      [62, -55],
    ],
    [
      [68517, 61511],
      [-221, -51],
      [-199, -289],
      [-344, -429],
      [-343, -450],
      [-298, -185],
      [-193, -167],
      [-150, -331],
      [-21, -706],
      [104, -769],
      [146, -803],
      [174, -777],
      [-84, -624],
      [-203, -635],
      [-91, -691],
      [-69, -814],
      [-100, -515],
      [-468, -488],
      [-283, -288],
      [155, -226],
      [-74, -223],
      [-104, -87],
      [-199, -275],
      [-228, -119],
      [-347, -96],
      [-291, -111],
      [-38, -80],
    ],
    [
      [49175, 59877],
      [44, -7],
      [97, 19],
      [173, 106],
      [116, 216],
      [358, 442],
      [123, 1171],
      [-14, 868],
      [-189, 972],
      [-266, 1183],
      [-138, 707],
      [-193, 694],
      [-317, 561],
      [-124, 645],
      [17, 597],
      [0, 7],
    ],
    [
      [48862, 68058],
      [340, 49],
      [810, -622],
      [819, -818],
      [438, -813],
      [252, -275],
      [334, -12],
      [771, -72],
      [985, 169],
      [817, 301],
      [285, 77],
      [409, 41],
      [432, -189],
      [153, 25],
      [18, 126],
    ],
    [
      [37769, 69573],
      [99, -70],
      [680, -769],
      [908, -617],
      [1000, -814],
      [1221, -634],
      [676, -396],
      [826, -676],
      [936, -739],
      [447, -284],
      [747, -404],
      [112, -72],
    ],
    [
      [19380, 55701],
      [-344, 1035],
      [63, 1640],
      [82, 502],
      [243, 1493],
      [290, 1806],
      [280, 1555],
      [135, 1293],
      [166, 980],
      [24, 1181],
      [49, 945],
      [-48, 582],
      [-417, 755],
      [-395, 1038],
      [57, 229],
      [201, 800],
      [382, 483],
      [1189, 889],
      [1015, 756],
      [741, 825],
      [558, 722],
    ],
    [
      [23651, 75210],
      [858, -306],
      [1044, -820],
      [653, -914],
      [742, -532],
      [1367, -321],
      [1202, -4],
      [1457, -678],
      [353, -663],
      [686, -633],
      [826, 90],
      [1314, -229],
      [660, 4],
      [1126, -64],
      [813, -11],
      [767, -377],
      [250, -179],
    ],
    [
      [50396, 91997],
      [-49, -142],
      [-171, -177],
      [-933, -696],
      [-720, -485],
      [-1277, -686],
      [-210, -41],
      [-715, -76],
      [-1258, 53],
    ],
    [
      [45063, 89747],
      [-358, 597],
      [-69, 594],
      [-554, 578],
      [-537, 422],
      [-957, 641],
      [-422, 786],
      [-512, 673],
      [-873, 840],
      [-671, 573],
      [-599, 301],
      [-468, 434],
      [-968, 469],
      [-357, 379],
      [-983, 658],
      [-71, 178],
      [-239, 343],
      [-348, 242],
      [-440, 353],
      [-384, 236],
      [-204, 234],
    ],
    [
      [35049, 99278],
      [61, 168],
      [-68, 379],
      [-76, 75],
      [-235, 25],
      [-141, 16],
      [-28, 61],
      [48, 68],
      [315, 20],
      [62, 128],
      [69, 7],
      [14, -11],
      [54, -43],
      [14, 0],
      [89, 0],
      [20, 81],
      [-95, 183],
      [68, 135],
      [117, 34],
      [97, -74],
      [81, -61],
      [23, -3],
      [169, -18],
      [198, -54],
      [28, 68],
      [-14, 67],
      [-157, 163],
      [-28, 81],
      [48, 163],
      [95, 198],
      [22, 46],
      [41, 68],
      [-34, 67],
      [-89, 6],
      [-130, 8],
      [-76, 7],
      [-54, 54],
      [64, 85],
      [18, 24],
      [116, 54],
      [69, 122],
      [100, -42],
      [38, -16],
      [74, -31],
      [254, -196],
      [116, -7],
      [41, 95],
      [-13, 122],
      [-108, 119],
      [-63, 70],
      [-28, 123],
      [35, 203],
      [96, 61],
      [421, -119],
      [29, -219],
      [254, -12],
      [144, -121],
      [520, 64],
      [190, 300],
      [37, 34],
      [174, 163],
      [267, 83],
      [211, 198],
      [118, -155],
      [75, -184],
      [11, -27],
      [12, 27],
      [140, 334],
      [123, 177],
      [103, -47],
      [48, -128],
      [302, 8],
      [260, 117],
      [178, 7],
      [96, -87],
      [96, 0],
      [123, 82],
      [415, 504],
      [116, 35],
      [337, -195],
      [144, -33],
      [-21, 135],
      [-30, 89],
      [76, 278],
      [48, 33],
      [164, -95],
      [83, 27],
      [212, 203],
      [308, 6],
      [124, -20],
      [48, -28],
      [13, -61],
      [-192, -257],
      [-110, 1],
      [-116, -116],
      [-14, -115],
      [143, -115],
      [227, -48],
      [102, -61],
      [103, 27],
      [116, -170],
      [82, 47],
      [207, 427],
      [102, 96],
      [42, 39],
      [506, -461],
      [130, -21],
      [0, 74],
      [-61, 95],
      [34, 75],
      [62, 33],
      [-178, 231],
      [49, 102],
      [164, -14],
      [96, 0],
      [171, 26],
      [192, 210],
      [76, 14],
      [48, -82],
      [-158, -243],
      [27, -204],
      [-97, -345],
      [34, -95],
      [185, 20],
      [164, -82],
      [83, 7],
      [41, 34],
      [-41, 108],
      [110, 108],
      [34, 14],
      [-6, 129],
      [75, 60],
      [405, 122],
      [136, -8],
      [76, -61],
      [149, -447],
      [96, -123],
      [110, -14],
      [17, 15],
      [24, 20],
      [-5, 73],
      [-2, 35],
      [-34, 108],
      [42, 109],
      [240, 121],
      [27, 224],
      [145, 474],
      [19, 15],
      [23, 19],
      [82, -157],
      [48, 14],
      [30, 132],
      [11, 51],
      [236, 56],
      [73, 18],
      [158, 88],
      [102, 155],
      [28, 102],
      [-20, 101],
      [-82, 48],
      [-158, -13],
      [-20, 54],
      [47, 173],
      [34, 129],
      [9, 30],
      [89, 34],
      [34, 13],
      [114, -56],
      [235, -114],
      [89, 27],
      [69, 88],
      [-7, 101],
      [213, 135],
      [28, 48],
      [-89, 135],
      [-213, -53],
      [-11, 158],
      [-8, 120],
      [-55, 95],
      [-117, 102],
      [48, 60],
      [213, 81],
      [124, 135],
      [103, 258],
      [145, 135],
      [158, 74],
      [-7, 41],
      [-117, 102],
      [97, 128],
      [254, 121],
      [295, 230],
      [151, -41],
      [55, 183],
      [-48, 190],
      [-27, 61],
      [48, 47],
      [199, 13],
      [69, 190],
      [144, 54],
      [35, 189],
      [89, 48],
      [391, 12],
      [103, 54],
      [28, 41],
      [88, 350],
      [68, 102],
      [233, 1],
      [136, 129],
      [153, -48],
      [68, -236],
      [-54, -250],
      [4, -435],
      [23, -346],
      [105, -403],
      [3, -5],
      [76, -165],
      [66, -524],
      [7, 14],
      [30, -279],
      [-114, -487],
      [-179, -235],
      [-100, -677],
      [226, -560],
      [148, -219],
      [442, -665],
      [344, -568],
      [252, -357],
      [233, -546],
      [-24, -495],
      [-54, -250],
      [-18, -428],
      [-213, -227],
      [54, 182],
      [12, 319],
      [65, 508],
      [-76, -39],
      [-220, -215],
      [-192, -363],
      [-40, -258],
      [-5, -251],
      [-60, -386],
      [654, -737],
      [467, -450],
      [483, -242],
      [301, 32],
      [374, -77],
      [-136, -182],
      [-173, -127],
      [132, -252],
      [176, -24],
      [191, -67],
      [182, -287],
      [90, -241],
      [62, -267],
      [91, -154],
    ],
    [
      [52016, 22954],
      [-14, -190],
      [-414, -1930],
      [-573, -1710],
      [-455, -985],
      [-821, -1693],
      [-476, -2065],
      [-73, -518],
      [-117, -843],
      [-31, -226],
      [-539, -575],
      [-999, -113],
      [-1069, 106],
      [-1117, 481],
      [-1145, 543],
      [-351, 413],
      [-986, 1050],
      [-1213, 734],
      [-1352, 485],
      [-1841, 302],
      [-2169, 70],
      [-1183, -694],
      [-991, -1017],
      [-1043, -1742],
      [-1011, -493],
    ],
    [
      [25236, 18474],
      [28, 22],
      [530, 562],
      [536, 711],
      [591, 690],
      [662, 601],
      [555, 215],
      [686, 207],
      [341, 110],
    ],
    [
      [29165, 21592],
      [27, -41],
      [331, -529],
      [577, -763],
      [824, -386],
      [675, -18],
      [533, 223],
      [367, 136],
      [487, -69],
      [1045, -247],
      [1064, 30],
      [348, -59],
      [808, -237],
      [724, -114],
      [779, 63],
      [861, 88],
      [950, 195],
      [543, -42],
      [458, 26],
      [424, 7],
      [751, -189],
      [428, -89],
      [468, -76],
      [118, 141],
      [193, 364],
      [145, 358],
      [131, 609],
      [55, 747],
      [-36, 449],
      [-76, 647],
      [74, 318],
      [215, 81],
    ],
    [
      [43456, 23215],
      [166, 62],
      [750, 158],
      [652, 30],
      [400, 48],
      [690, 15],
      [475, -28],
      [214, -31],
      [710, -93],
      [532, -22],
      [503, -50],
      [331, -26],
      [420, -47],
      [497, -56],
      [813, -130],
      [166, -2],
      [703, -93],
      [200, 64],
      [239, 37],
      [71, -76],
      [28, -21],
    ],
    [
      [64748, 51282],
      [96, -118],
      [274, -418],
      [357, -223],
      [873, -394],
      [718, -195],
      [597, -23],
      [598, 85],
      [994, -16],
      [946, -56],
      [620, -186],
      [599, -91],
      [175, -138],
      [751, -60],
      [595, 310],
      [620, 621],
      [403, 823],
      [476, 440],
      [733, 423],
      [818, 205],
      [590, 180],
      [972, 121],
      [1115, 376],
      [600, 234],
      [696, 220],
      [414, 136],
      [232, 26],
    ],
    [
      [86618, 51172],
      [-42, -53],
      [-32, -95],
      [-2, -6],
      [-52, -50],
      [-119, -113],
      [-258, -108],
      [-218, -210],
      [-183, -258],
      [-274, -121],
      [-94, -42],
      [-66, -59],
      [-56, -49],
      [-7, -67],
      [-20, -191],
      [94, -157],
      [226, -379],
      [272, -271],
      [612, -285],
      [334, -244],
      [51, -78],
      [37, -57],
      [58, -54],
      [153, -143],
      [0, -34],
      [61, -27],
      [218, -95],
      [191, -129],
      [50, -121],
      [38, -89],
      [40, -51],
      [117, -146],
      [16, -128],
      [18, -143],
      [108, -346],
      [14, -305],
      [34, -54],
      [20, -34],
      [-53, -184],
      [-151, -521],
      [-63, -566],
      [-12, -99],
      [33, -250],
      [29, -224],
      [-40, -189],
      [-35, -163],
      [-238, -231],
      [-354, -210],
      [-293, -68],
      [-666, -264],
      [-224, -47],
      [-936, -426],
      [-377, -171],
      [-101, -47],
      [-402, -184],
      [-217, -53],
      [-89, -21],
      [-313, -21],
      [-190, -13],
      [-564, -102],
      [-377, -176],
      [-29, -14],
      [-43, -20],
      [-18, -25],
      [-70, -97],
      [-132, -461],
      [-37, -129],
      [-164, -298],
      [-67, -122],
      [-313, -319],
      [-65, -18],
      [-179, -50],
      [-305, -105],
      [-362, -126],
      [-380, -224],
      [-340, -163],
      [-43, -11],
      [-188, -50],
      [-578, -122],
      [-252, -92],
      [-175, -65],
      [-225, -154],
      [-170, -117],
      [-550, -190],
      [-532, -318],
      [-228, -137],
      [-557, -326],
      [-225, -190],
      [-130, -226],
      [-147, -256],
      [-252, -292],
      [-379, -474],
      [-100, -60],
      [-151, -90],
      [-102, -88],
      [-43, -11],
      [-330, -78],
      [-281, -133],
      [-386, -182],
      [-172, -81],
      [-10, -5],
      [-41, -12],
      [-9, -2],
      [-20, -5],
      [-318, -90],
      [-135, -75],
      [-7, -1],
      [-6, -1],
      [-13, -2],
      [-46, -7],
      [-44, -7],
      [-19, -3],
      [-60, -10],
      [-64, -10],
      [-35, -6],
      [0, 0],
      [-5, -1],
      [-194, -25],
      [-12, -1],
      [-1, -1],
      [-4, 0],
      [-8, 0],
      [-101, 2],
      [-238, 4],
      [-1, 0],
      [-19, 4],
      [-219, 45],
      [-345, 235],
      [-327, 264],
      [-73, 118],
      [-70, 112],
      [-144, 143],
      [-156, 155],
      [-163, 88],
      [-537, 107],
      [-66, 24],
      [-220, 77],
      [-290, 0],
      [-76, 0],
      [-420, 327],
      [-84, 65],
      [-238, 40],
      [-170, -74],
      [-95, -42],
      [-71, -55],
      [-105, -80],
      [-12, -24],
    ],
    [
      [7076, 6350],
      [-270, -98],
      [-296, -266],
      [-194, -133],
      [-324, -280],
      [-296, -281],
      [-80, -440],
      [-239, -134],
    ],
    [
      [5377, 4718],
      [-327, 442],
      [-936, 483],
      [-1052, 289],
      [-677, 330],
      [-863, 856],
      [-1141, 1655],
      [-381, 1337],
      [616, 1008],
      [858, 1284],
      [962, 1268],
      [293, 1421],
      [-341, 1015],
      [-296, 542],
      [270, 68],
      [1165, 255],
      [791, 951],
      [455, 664],
    ],
    [
      [4773, 18586],
      [416, -94],
      [493, 7],
      [304, 131],
      [429, 204],
      [201, 99],
      [309, 382],
      [185, 363],
      [519, 277],
      [389, 22],
      [161, -233],
      [2, -223],
      [-54, -230],
      [-177, -364],
      [-137, -256],
      [-108, -317],
      [-33, -319],
      [24, -278],
      [108, -369],
      [43, -73],
    ],
    [
      [28297, 22954],
      [285, -69],
      [222, -731],
      [361, -562],
    ],
    [
      [4773, 18586],
      [355, 517],
      [-33, 1526],
      [-96, 2421],
      [288, 1305],
      [809, 1330],
      [1250, 904],
      [1137, 303],
      [1310, 293],
      [1060, 398],
      [1774, 429],
      [1222, 616],
      [190, 96],
      [1464, 1002],
      [968, 1431],
    ],
    [
      [16471, 31157],
      [963, -530],
      [704, -389],
      [565, -273],
      [893, -426],
      [760, -370],
      [932, -851],
      [1063, -635],
      [679, -465],
      [519, -518],
      [616, -499],
      [546, -389],
      [420, -407],
      [333, -658],
      [549, -667],
      [464, -605],
      [483, -354],
      [361, -33],
      [251, 23],
      [445, -89],
      [280, -68],
    ],
    [
      [28297, 22954],
      [137, 144],
      [524, 453],
      [702, 770],
      [529, 718],
      [503, 501],
      [594, 398],
      [747, 396],
      [698, 430],
      [554, 283],
      [741, 383],
      [853, 197],
      [596, 214],
      [972, 155],
      [436, 142],
      [777, 151],
      [953, -15],
      [1058, -92],
      [524, -273],
      [252, -296],
      [723, -718],
      [576, -682],
      [445, -693],
      [458, -774],
      [319, -664],
      [346, -624],
      [142, -243],
    ],
    [
      [52901, 26640],
      [-767, -106],
      [-117, -1106],
      [82, -1333],
      [-75, -1027],
      [-8, -114],
    ],
    [
      [16471, 31157],
      [14, 19],
      [114, 1382],
      [-356, 1950],
      [-19, 1397],
      [420, 1343],
      [1363, 1573],
      [629, 1191],
      [925, 1580],
      [1194, 1000],
      [943, 1078],
      [79, 168],
    ],
    [
      [68517, 61511],
      [299, 69],
      [369, -40],
      [348, -25],
      [382, 14],
      [430, 115],
      [324, 294],
      [74, 236],
      [184, 466],
      [52, 413],
      [24, 65],
    ],
    [
      [71003, 63118],
      [185, -136],
      [657, -458],
      [930, -1272],
      [757, -704],
      [273, -962],
      [215, -771],
      [353, -522],
      [462, -421],
      [704, -222],
      [923, 169],
      [869, 814],
      [407, 374],
      [487, -15],
      [803, 280],
      [298, 137],
      [327, -52],
      [550, -22],
      [486, 26],
      [173, 119],
      [138, 55],
    ],
    [
      [41179, 81937],
      [155, -337],
      [165, -659],
      [11, -488],
      [-45, -502],
      [-296, -1181],
      [-248, -1061],
      [-320, -877],
      [-210, -560],
      [-143, -445],
      [-224, -634],
      [-230, -729],
      [-257, -884],
      [-245, -640],
      [-315, -559],
      [-465, -867],
      [-70, -47],
      [-226, -261],
      [-117, -188],
      [-205, -472],
      [-18, -305],
      [-94, -425],
      [-5, -224],
      [-8, -19],
    ],
    [
      [23651, 75210],
      [555, 717],
      [869, 1969],
      [-10, 166],
    ],
    [
      [25065, 78062],
      [708, 330],
      [1043, 475],
      [845, 534],
      [1099, 509],
      [850, 622],
      [674, 357],
      [914, 563],
      [498, 689],
      [523, 809],
      [467, 442],
      [861, 793],
      [571, 523],
      [594, 780],
      [477, 650],
      [860, 831],
      [184, 273],
      [500, 875],
      [168, 304],
    ],
    [
      [36901, 88421],
      [412, -496],
      [827, -1189],
      [622, -801],
      [308, -558],
      [180, -533],
      [44, -689],
      [-9, -560],
      [6, -565],
      [61, -544],
      [193, -351],
      [376, -223],
      [502, -190],
      [458, 89],
      [298, 126],
    ],
    [
      [58502, 77954],
      [-106, -129],
      [-80, -426],
      [-303, -314],
      [-448, -441],
      [-393, -395],
      [-421, -305],
      [-333, -36],
      [-692, -295],
      [-720, -336],
      [-644, -282],
      [-723, 166],
      [-23, -9],
    ],
    [
      [53616, 75152],
      [-97, 201],
      [-377, 242],
      [-342, 154],
      [-328, 256],
      [-533, 556],
      [-216, 173],
      [-224, 125],
      [-353, -69],
      [-582, -209],
      [-654, 179],
      [-703, 119],
      [-78, 211],
      [-238, 274],
      [-616, 619],
      [-142, 294],
      [-191, 504],
      [-375, 792],
      [-160, 822],
      [-239, 370],
      [-438, 87],
      [-439, 190],
      [-362, 25],
      [-58, 1262],
      [-88, 482],
      [-267, 486],
      [-689, 43],
      [-209, 166],
      [-474, 142],
      [-625, 30],
      [-492, -170],
      [-498, -209],
      [-180, -161],
      [-391, -529],
      [-613, -602],
      [-166, -70],
    ],
    [
      [36901, 88421],
      [250, 450],
      [779, 830],
      [455, 319],
      [1075, 134],
      [222, 64],
      [222, 78],
      [1024, -47],
      [1195, -46],
      [2153, -444],
      [597, -5],
      [190, -7],
    ],
    [
      [74275, 71737],
      [10, -24],
      [28, -64],
      [17, -40],
      [1, -2],
      [-17, -152],
      [-14, -120],
      [55, -167],
      [260, 66],
      [27, 7],
      [137, -15],
      [81, -102],
      [-38, -55],
      [-24, -33],
      [-32, -21],
      [-77, -53],
      [95, -163],
      [19, -102],
      [-95, -13],
      [-69, 18],
      [-88, 24],
      [-37, -126],
      [-5, -16],
      [-109, -20],
      [-37, -3],
      [-66, -4],
      [-82, -6],
      [-10, -47],
      [-11, -48],
      [13, -15],
      [21, -27],
    ],
    [
      [74228, 70414],
      [-259, -535],
      [-265, -729],
      [-262, -933],
      [-251, -750],
      [-321, -620],
      [-350, -538],
      [-478, -950],
      [-300, -592],
      [-287, -628],
      [-252, -498],
      [-200, -523],
    ],
    [
      [55725, 66045],
      [67, 463],
      [-165, 693],
      [32, 1166],
      [374, 1018],
      [351, 476],
      [581, 243],
      [494, -41],
      [541, 46],
      [306, -38],
      [181, -23],
      [312, 23],
      [639, 86],
      [380, 205],
      [725, 491],
      [1087, 506],
      [386, 273],
      [920, 474],
      [-178, 532],
      [-48, 780],
      [35, 921],
      [197, 567],
      [175, 641],
      [142, 473],
      [135, 466],
      [337, 415],
      [383, 401],
    ],
    [
      [64114, 77302],
      [31, -47],
      [93, -334],
      [-53, -297],
      [-61, -237],
      [160, 11],
      [202, -63],
      [126, -83],
      [180, 58],
      [251, -180],
      [115, -442],
      [44, -245],
      [286, -241],
      [250, -4],
      [111, 100],
      [227, 234],
      [173, 113],
      [216, -91],
      [92, -205],
      [72, -279],
      [64, -204],
      [105, -205],
      [141, -171],
      [132, -56],
      [300, -106],
      [150, -504],
      [167, -71],
      [445, -60],
      [216, -64],
      [189, -166],
      [23, -352],
      [-40, -162],
      [107, -307],
      [210, -159],
      [-136, -405],
      [-40, -155],
      [175, -165],
      [160, -131],
      [181, 11],
      [175, -179],
      [167, -91],
      [167, 18],
      [235, 153],
      [263, 64],
      [250, 10],
      [334, -46],
      [356, -88],
      [15, -129],
      [-40, -203],
      [-117, -120],
      [14, -34],
      [390, -27],
      [13, 13],
      [171, 310],
      [132, 32],
      [132, 11],
      [258, -4],
      [333, -5],
      [62, 74],
      [34, 47],
      [215, 105],
      [69, 26],
      [312, 124],
      [278, 23],
      [146, 11],
      [215, 10],
      [375, 1],
      [143, -7],
    ],
    [
      [25065, 78062],
      [-39, 758],
      [-179, 824],
      [-523, 360],
      [-790, 697],
      [-949, 733],
      [-679, 851],
      [-270, 744],
      [-312, 1057],
      [163, 1517],
      [338, 1210],
      [104, 526],
      [97, 489],
      [49, 990],
      [-20, 788],
      [11, 187],
      [293, 30],
      [164, -14],
      [-48, -95],
      [130, 102],
      [62, 41],
      [82, -7],
      [239, -81],
      [452, -142],
      [267, 14],
      [294, 6],
      [246, -67],
      [281, -183],
      [170, -28],
      [110, 69],
      [41, 117],
      [20, 59],
      [-96, 305],
      [48, 74],
      [96, 14],
      [105, -10],
      [237, -24],
      [28, 27],
      [-14, 95],
      [-137, 34],
      [-34, 54],
      [184, 109],
      [-70, 73],
      [-60, 62],
      [-20, 102],
      [29, 51],
      [32, 54],
      [23, 41],
      [53, 91],
      [-27, 42],
      [-7, 12],
      [-7, 11],
      [-14, 23],
      [-3, 4],
      [-11, 22],
      [-20, 36],
      [-23, 43],
      [-17, 32],
      [-22, 41],
      [-9, 16],
      [-5, 10],
      [0, 24],
      [0, 9],
      [12, 25],
      [19, 39],
      [18, 36],
      [33, 68],
      [34, 70],
      [82, 87],
      [12, 12],
      [187, 199],
      [225, 122],
      [192, 197],
      [219, -108],
      [171, 20],
      [144, -20],
      [27, -170],
      [48, -34],
      [82, 34],
      [62, 145],
      [47, 113],
      [33, 35],
      [77, 87],
      [96, 27],
      [164, 101],
      [-137, 109],
      [-13, 70],
      [-21, 120],
      [13, 244],
      [23, 58],
      [39, 98],
      [280, 345],
      [-92, 127],
      [-17, 23],
      [-40, -16],
      [-29, -12],
      [-25, -27],
      [-146, -156],
      [-144, -13],
      [-136, 54],
      [-96, 101],
      [-103, 233],
      [9, 47],
      [12, 60],
      [-41, 149],
      [14, 18],
      [35, -2],
      [83, -4],
      [188, -345],
      [89, -6],
      [81, 82],
      [1, 9],
      [16, 445],
      [25, 22],
      [91, 81],
      [314, 104],
      [122, 150],
      [-100, 101],
      [-80, 80],
      [-7, 81],
      [9, 10],
      [242, 277],
      [44, 109],
      [30, 74],
      [39, 267],
      [13, 93],
      [61, 55],
      [303, -201],
      [26, 122],
      [-104, 250],
      [13, 54],
      [247, -59],
      [342, 23],
      [254, -141],
      [233, 15],
      [225, 218],
      [130, 353],
      [58, 157],
      [33, 33],
      [52, 53],
      [56, 13],
      [12, 1],
      [44, 3],
      [1, -189],
      [28, -118],
      [21, -89],
      [-13, -31],
      [75, -67],
      [124, -7],
      [69, 30],
      [68, 31],
      [116, -27],
      [96, -177],
      [34, -142],
      [41, -21],
      [117, -6],
      [81, 40],
      [70, 90],
      [153, 197],
      [147, 188],
      [137, 298],
      [-96, 244],
      [40, 74],
      [29, 55],
      [151, -27],
      [164, 33],
      [164, -149],
      [144, -122],
      [96, 14],
      [40, 21],
      [76, 40],
      [-27, 67],
      [-199, 163],
      [11, 36],
      [10, 32],
      [465, 60],
      [227, 75],
      [32, 32],
      [77, 76],
      [27, 109],
      [-129, 88],
      [-28, 95],
      [82, 156],
      [130, 135],
      [247, 258],
      [4, -5],
      [37, -43],
      [240, -7],
      [274, 68],
      [239, 108],
      [41, 14],
      [158, 88],
      [199, 223],
      [34, 88],
      [-34, 95],
      [82, 143],
      [14, 26],
      [82, 7],
      [151, 102],
      [349, 218],
      [54, 34],
      [8, 5],
      [2, 5],
      [39, 97],
      [-55, 122],
      [89, 142],
      [44, 23],
      [37, 19],
      [8, 5],
      [18, -40],
      [30, -68],
      [-12, -65],
      [-8, -44],
      [48, -88],
      [109, 34],
      [8, 22],
    ],
    [
      [48862, 68058],
      [23, 582],
      [-118, 822],
      [42, 833],
      [284, 905],
      [418, 685],
      [282, 443],
      [715, 905],
      [428, 225],
      [636, 404],
      [435, 353],
      [455, 448],
      [664, 295],
      [490, 194],
    ],
    [
      [63121, 79247],
      [279, 4],
      [174, -104],
      [-151, -235],
      [-291, -84],
      [-387, -225],
      [140, -117],
      [278, 30],
      [514, 6],
      [-269, -348],
      [-346, -91],
      [-361, -96],
      [-151, -154],
      [209, -57],
      [347, 42],
      [409, -19],
      [16, -210],
      [264, 16],
      [196, -118],
      [123, -185],
    ],
    [
      [74228, 70414],
      [40, -51],
      [49, -63],
      [-173, -201],
      [-13, -15],
      [6, -81],
      [307, -63],
      [143, -69],
      [311, -490],
      [286, -184],
      [189, -144],
      [75, -95],
      [67, -225],
      [20, -13],
      [109, -68],
      [96, 9],
      [88, 10],
      [201, 54],
      [339, 92],
      [136, -49],
      [322, 107],
      [19, 3],
      [15, 3],
      [246, 40],
      [150, -22],
      [129, -89],
      [120, -247],
      [102, -208],
      [141, 144],
      [3, 4],
      [-126, 223],
      [-36, 63],
      [42, 67],
      [6, 4],
      [103, 63],
      [2, -4],
      [12, -23],
      [127, -299],
      [41, -36],
      [21, -19],
      [44, 3],
      [85, 3],
      [162, 111],
      [65, 44],
      [287, 222],
      [25, 4],
      [139, 22],
      [490, -268],
      [115, -34],
      [14, 1],
      [164, 18],
      [100, -1],
      [187, -1],
      [176, -70],
      [137, -54],
      [106, 3],
      [91, 2],
      [568, 160],
      [150, -15],
      [293, -104],
      [232, 94],
      [98, 12],
    ],
    [
      [21075, 7214],
      [-281, -281],
      [-817, -1074],
      [-977, -1132],
      [-1120, -1476],
      [-636, -1138],
      [-798, -897],
      [-823, -503],
      [-991, -365],
      [-1159, -260],
      [-458, -6],
      [-797, -80],
      [-16, -2],
      [-1788, 191],
      [-1196, 267],
      [-51, 11],
      [-873, 422],
      [-916, 605],
      [-855, 754],
      [-55, 116],
      [-449, 951],
      [-511, 1224],
      [-131, 177],
    ],
  ],
  transform: {
    scale: [0.00003431222886975173, 0.00003393989157126353],
    translate: [11.629266794503879, 10.029215975373976],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Gwoza",
            id: "BR001",
            nameAbbr: "Gwoza",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.87619930012,
            Shape_Area: 0.20052370933,
          },
        },
        {
          arcs: [[4, 5, 6, 7, 8]],
          type: "Polygon",
          properties: {
            name: "Hawul",
            id: "BR002",
            nameAbbr: "Hawul",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.96661950407,
            Shape_Area: 0.17098695923,
          },
        },
        {
          arcs: [[9, 10, 11, 12, 13]],
          type: "Polygon",
          properties: {
            name: "Jere",
            id: "BR003",
            nameAbbr: "Jere",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.67748513405,
            Shape_Area: 0.07222749022,
          },
        },
        {
          arcs: [[14, 15, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Kaga",
            id: "BR004",
            nameAbbr: "Kaga",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.16771556457,
            Shape_Area: 0.22903739647,
          },
        },
        {
          arcs: [[18, 19, 20, 21]],
          type: "Polygon",
          properties: {
            name: "Kala/Balge",
            id: "BR005",
            nameAbbr: "Kala/Balge",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.71762808809,
            Shape_Area: 0.10266432829,
          },
        },
        {
          arcs: [[-14, 22, -12, 23, 24, -3, 25, -15, 26, 27]],
          type: "Polygon",
          properties: {
            name: "Konduga",
            id: "BR006",
            nameAbbr: "Konduga",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.68000671047,
            Shape_Area: 0.48631048127,
          },
        },
        {
          arcs: [[28, 29, 30, 31]],
          type: "Polygon",
          properties: {
            name: "Kukawa",
            id: "BR007",
            nameAbbr: "Kukawa",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.48847953173,
            Shape_Area: 0.17319602304,
          },
        },
        {
          arcs: [[-7, 32, 33, 34]],
          type: "Polygon",
          properties: {
            name: "Kwaya Kusar",
            id: "BR008",
            nameAbbr: "Kwaya Kusar",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.08176924966,
            Shape_Area: 0.06052414585,
          },
        },
        {
          arcs: [[35, 36, -24, -11, 37, 38]],
          type: "Polygon",
          properties: {
            name: "Mafa",
            id: "BR009",
            nameAbbr: "Mafa",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.45864869429,
            Shape_Area: 0.24259694351,
          },
        },
        {
          arcs: [[39, -27, -18, 40, 41]],
          type: "Polygon",
          properties: {
            name: "Magumeri",
            id: "BR010",
            nameAbbr: "Magumeri",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.77745972286,
            Shape_Area: 0.42474729118,
          },
        },
        {
          arcs: [[-23, -13]],
          type: "Polygon",
          properties: {
            name: "Maiduguri",
            id: "BR011",
            nameAbbr: "Maiduguri",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.49537243318,
            Shape_Area: 0.01084487517,
          },
        },
        {
          arcs: [[-31, 42, 43, 44]],
          type: "Polygon",
          properties: {
            name: "Abadam",
            id: "BR012",
            nameAbbr: "Abadam",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.63558420016,
            Shape_Area: 0.2020476589,
          },
        },
        {
          arcs: [[45, -9, 46, 47, 48]],
          type: "Polygon",
          properties: {
            name: "Askira/Uba",
            id: "BR013",
            nameAbbr: "Askira/Uba",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.49403713501,
            Shape_Area: 0.18093012662,
          },
        },
        {
          arcs: [[-4, -25, 49, -19, 50]],
          type: "Polygon",
          properties: {
            name: "Bama",
            id: "BR014",
            nameAbbr: "Bama",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.01595746301,
            Shape_Area: 0.37034461306,
          },
        },
        {
          arcs: [[-34, 51, 52, 53]],
          type: "Polygon",
          properties: {
            name: "Bayo",
            id: "BR015",
            nameAbbr: "Bayo",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.38693390465,
            Shape_Area: 0.08765201704,
          },
        },
        {
          arcs: [[54, -47, -8, -35, -54, 55, 56]],
          type: "Polygon",
          properties: {
            name: "Biu",
            id: "BR016",
            nameAbbr: "Biu",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.43610860604,
            Shape_Area: 0.27390908673,
          },
        },
        {
          arcs: [[-55, 57, -48]],
          type: "Polygon",
          properties: {
            name: "Chibok",
            id: "BR017",
            nameAbbr: "Chibok",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.35665727918,
            Shape_Area: 0.11520444463,
          },
        },
        {
          arcs: [[-26, -2, 58, -49, -58, -57, 59, -16]],
          type: "Polygon",
          properties: {
            name: "Damboa",
            id: "BR018",
            nameAbbr: "Damboa",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.8814786552,
            Shape_Area: 0.53458979254,
          },
        },
        {
          arcs: [[-20, -50, -37, 60, 61]],
          type: "Polygon",
          properties: {
            name: "Dikwa",
            id: "BR019",
            nameAbbr: "Dikwa",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.80949494987,
            Shape_Area: 0.14807069504,
          },
        },
        {
          arcs: [[62, -42, 63, 64, 65]],
          type: "Polygon",
          properties: {
            name: "Gubio",
            id: "BR020",
            nameAbbr: "Gubio",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.95898328308,
            Shape_Area: 0.2075732776,
          },
        },
        {
          arcs: [[66, 67, -66, 68, -43, -30]],
          type: "Polygon",
          properties: {
            name: "Guzamala",
            id: "BR021",
            nameAbbr: "Guzamala",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.23135121306,
            Shape_Area: 0.19190254195,
          },
        },
        {
          arcs: [[69, 70, -61, -36, 71, 72]],
          type: "Polygon",
          properties: {
            name: "Marte",
            id: "BR022",
            nameAbbr: "Marte",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.16862486674,
            Shape_Area: 0.18903147027,
          },
        },
        {
          arcs: [[-44, -69, -65, 73]],
          type: "Polygon",
          properties: {
            name: "Mobbar",
            id: "BR023",
            nameAbbr: "Mobbar",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.71511657005,
            Shape_Area: 0.26937668794,
          },
        },
        {
          arcs: [[-72, -39, 74, -67, -29, 75]],
          type: "Polygon",
          properties: {
            name: "Monguno",
            id: "BR024",
            nameAbbr: "Monguno",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.74963726243,
            Shape_Area: 0.12550128706,
          },
        },
        {
          arcs: [[-21, -62, -71, 76]],
          type: "Polygon",
          properties: {
            name: "Ngala",
            id: "BR025",
            nameAbbr: "Ngala",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.40266684923,
            Shape_Area: 0.10995375867,
          },
        },
        {
          arcs: [[-75, -38, -10, -28, -40, -63, -68]],
          type: "Polygon",
          properties: {
            name: "Nganzai",
            id: "BR026",
            nameAbbr: "Nganzai",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.21887740149,
            Shape_Area: 0.20729658523,
          },
        },
        {
          arcs: [[-6, 77, -52, -33]],
          type: "Polygon",
          properties: {
            name: "Shani",
            id: "BR027",
            nameAbbr: "Shani",
            parent: "Borno",
            parentAbbr: "BR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.36093008623,
            Shape_Area: 0.09839347166,
          },
        },
      ],
    },
  },
};

const adamawa = {
  type: "Topology",
  arcs: [
    [
      [28786, 61008],
      [-67, -120],
      [-86, -169],
      [-92, -168],
      [-87, -182],
      [-43, -115],
      [-30, -95],
      [46, -124],
      [45, -177],
      [232, -141],
      [142, -138],
      [285, -237],
      [364, -429],
      [259, -181],
      [258, -216],
      [304, -312],
      [346, -333],
      [77, -260],
      [223, -242],
      [71, -260],
      [303, -407],
      [287, -462],
      [342, -550],
      [220, -352],
      [67, -62],
      [413, -451],
      [20, -75],
    ],
    [
      [32685, 54750],
      [-197, -24],
      [-401, -129],
      [-511, -284],
      [-532, -386],
      [-500, -435],
      [-288, -253],
      [-2, -350],
      [-47, 37],
      [-24, -19],
    ],
    [
      [30183, 52907],
      [-89, -67],
      [-432, 27],
    ],
    [
      [29662, 52867],
      [-16, 1],
      [-203, -58],
      [-823, 198],
      [-406, 116],
      [-1055, -91],
      [-450, -333],
      [-969, -405],
      [-923, -686],
      [-642, -309],
      [-949, -549],
      [-590, -65],
      [-66, 23],
    ],
    [
      [22570, 50709],
      [-317, 113],
      [-541, 363],
      [-913, 227],
      [-1039, -179],
      [-1495, -145],
      [-945, -153],
      [-974, -45],
      [-1005, 256],
      [-1782, -54],
      [-814, 129],
      [-293, 55],
    ],
    [
      [12452, 51276],
      [109, 519],
      [-294, 672],
      [-457, 722],
      [-105, 246],
    ],
    [
      [11705, 53435],
      [121, 59],
      [146, 32],
      [94, 6],
      [272, 220],
      [7, 0],
      [381, 259],
      [32, 60],
      [234, 453],
      [533, 773],
      [15, 21],
      [330, 634],
      [117, 148],
      [238, 533],
      [257, 350],
      [232, 159],
      [347, 165],
      [703, 601],
      [615, 317],
      [411, 0],
      [619, -3],
      [548, 5],
      [321, -46],
      [761, -46],
      [43, -1],
      [85, -22],
      [767, 56],
      [269, 43],
      [351, 110],
      [394, 89],
      [576, 386],
      [154, 52],
      [169, 323],
      [171, 481],
      [-11, 714],
      [62, 353],
      [46, 693],
      [115, 529],
      [68, 570],
      [47, 585],
      [49, 625],
      [185, 527],
      [24, 336],
    ],
    [
      [22603, 64584],
      [398, 126],
      [723, -19],
      [696, 84],
      [933, 216],
      [421, 20],
      [716, 29],
      [566, 51],
      [366, 89],
      [386, 48],
      [20, 5],
    ],
    [
      [27828, 65233],
      [42, -81],
      [324, -256],
      [324, -985],
      [-63, -692],
      [-186, -826],
      [273, -644],
      [-25, -612],
      [218, -113],
      [51, -16],
    ],
    [
      [52326, 62348],
      [166, -64],
      [259, -73],
      [379, -223],
    ],
    [
      [53130, 61988],
      [-111, -166],
      [-37, -10],
      [-91, -24],
      [-324, 93],
      [-40, -48],
      [-88, -109],
      [-200, -194],
      [-136, -132],
      [-196, -51],
      [-20, -4],
      [-121, -82],
      [-135, -28],
      [-116, 27],
      [-195, 46],
      [-222, -56],
      [-108, -27],
      [-108, -75],
      [-220, -25],
      [-164, -18],
      [-54, -68],
      [-432, 12],
      [-338, 45],
      [-173, -82],
      [-217, -103],
      [-232, 23],
      [-350, 35],
      [-9, -3],
      [-327, -118],
      [-2, 0],
      [-20, -7],
      [-251, 25],
      [-31, 2],
      [-71, 5],
      [-101, -142],
      [-709, -439],
      [-480, -440],
      [-115, -34],
      [-250, -20],
      [-182, -67],
      [-106, -7],
      [-110, -6],
      [-71, -82],
      [-65, -74],
      [-20, -20],
      [-232, -228],
      [-153, -151],
      [-281, -144],
      [-206, -106],
      [-87, -93],
      [-155, -164],
      [-143, -305],
      [-232, -154],
      [-126, -83],
      [-418, -134],
      [-326, -180],
      [-322, -179],
      [-158, -93],
      [-72, -42],
      [-216, -81],
      [5, -20],
      [-266, -205],
      [31, -80],
      [45, -116],
      [-47, -210],
      [-12, -55],
      [28, -175],
      [37, -20],
      [179, -94],
      [748, -13],
      [150, -2],
      [156, -101],
      [75, -94],
      [6, -70],
      [37, -397],
      [-93, -312],
      [51, -474],
      [-44, -630],
      [10, -508],
      [69, -142],
      [5, -66],
      [31, -408],
      [-30, -528],
      [-99, -95],
      [-243, -233],
      [-59, -298],
      [-14, -88],
      [-5, -34],
      [4, -24],
      [10, -44],
      [-13, -129],
      [-187, -360],
      [-16, -61],
      [-30, -108],
      [30, -95],
      [53, -169],
      [-133, -387],
      [-31, -291],
      [40, -88],
      [90, -67],
      [73, -54],
      [16, -38],
      [73, -178],
      [-40, -156],
      [123, -202],
      [15, -138],
      [7, -59],
      [-127, -170],
      [-67, -169],
      [3, -13],
      [53, -272],
      [-25, -305],
      [-120, -298],
      [-103, -149],
      [-90, -130],
      [-35, -196],
      [-37, -204],
      [116, -230],
      [42, -189],
      [-19, -203],
      [-46, -90],
      [-54, -107],
      [-296, -439],
      [-145, -214],
      [-139, -333],
      [-168, -325],
      [-62, -122],
      [-43, -83],
      [-128, -194],
      [-239, -363],
      [-369, -260],
      [-495, -484],
      [-29, -10],
      [-92, -31],
      [-261, -306],
      [-4, -474],
      [81, -77],
      [144, -137],
      [87, -82],
      [12, -95],
      [10, -75],
      [-140, -258],
      [-49, -113],
    ],
    [
      [40656, 42087],
      [-129, -19],
      [-440, -192],
      [-306, -172],
      [-223, -198],
      [-299, -104],
      [-155, -96],
      [-82, -54],
      [-170, -165],
      [-176, -61],
      [-217, -104],
      [-142, -41],
      [-217, -117],
      [-190, -96],
      [-115, -96],
      [-61, -7],
      [-230, -75],
      [-217, -117],
      [-176, -96],
      [-163, -117],
      [-122, -55],
      [-95, -41],
      [-95, -82],
      [-176, -48],
      [-237, -56],
      [-278, -103],
      [-420, -206],
      [-304, -117],
      [-385, -57],
      [-269, 13],
      [-315, -192],
      [-294, -42],
      [-251, -56],
      [-128, -123],
      [-141, -130],
      [-189, -171],
      [-118, 95],
      [-2, 34],
      [-106, -130],
      [-197, -8],
      [-216, 74],
      [-190, 6],
      [-101, 34],
      [-165, 47],
      [-146, 108],
      [-224, 87],
      [-174, 108],
      [-144, 40],
      [-111, 108],
      [-307, 67],
      [-124, 88],
      [-261, 264],
      [-182, 122],
      [-251, 216],
      [-211, 115],
      [-149, 162],
      [-289, 128],
      [-201, 54],
      [-258, 87],
      [-175, -28],
      [-99, -137],
      [-137, -68],
      [-301, -63],
      [-73, -96],
      [41, -101],
      [-125, -35],
      [-148, -8],
      [-138, 75],
      [-192, 39],
      [-162, 129],
      [-197, 128],
      [-256, 176],
      [-164, 156],
      [-480, 296],
      [-163, 172],
    ],
    [
      [26353, 41235],
      [-24, 25],
      [-302, 386],
      [-96, 88],
      [-131, 346],
      [-46, 224],
      [-106, -14],
      [-214, 326],
      [-287, -42],
      [4, 204],
      [-275, 121],
      [-122, 462],
      [-231, 244],
      [-28, 272],
      [-59, 176],
      [-4, 211],
      [-130, 339],
      [-53, 204],
      [-179, 339],
      [-167, 217],
      [18, 218],
      [-85, 129],
      [-32, 217],
      [-31, 191],
      [-107, 299],
      [18, 122],
      [-98, 92],
      [-1474, 4],
      [-176, -36],
      [-111, 33],
      [-228, -191],
      [-149, 26],
      [-237, -55],
      [-164, 33],
      [-156, -137],
      [-203, -35],
      [-133, -21],
      [-198, 26],
      [-221, 47],
      [-59, 190],
      [-32, 125],
      [-11, 45],
      [58, 204],
      [-15, 102],
      [1040, -1],
      [15, 226],
      [125, 386],
      [107, 441],
      [104, 278],
      [171, 433],
      [239, 371],
      [416, 790],
      [137, 584],
      [139, 210],
    ],
    [
      [29662, 52867],
      [141, -220],
      [179, -426],
      [317, -400],
      [401, -634],
      [538, -718],
      [442, -567],
      [475, -710],
      [398, -456],
      [526, -261],
      [317, -54],
      [877, -137],
      [664, -28],
      [461, -19],
      [968, 72],
      [555, 79],
      [564, 113],
      [208, 555],
      [355, 416],
      [241, 848],
      [174, 590],
      [66, 461],
      [-37, 239],
      [-102, 451],
      [-84, 383],
      [-40, 83],
    ],
    [
      [38266, 52527],
      [70, 135],
      [184, 595],
      [-498, 840],
      [-123, 696],
      [55, 687],
      [242, 921],
      [221, 936],
      [91, 924],
      [15, 830],
      [-5, 851],
      [109, 624],
      [426, 905],
      [466, 788],
      [396, 633],
      [463, 611],
      [158, 260],
    ],
    [
      [40536, 63763],
      [176, 292],
      [421, 333],
      [356, 770],
      [2, 925],
      [65, 973],
      [31, 421],
      [119, 250],
      [90, 148],
      [368, -156],
      [290, -332],
      [346, -340],
      [719, -530],
      [1109, -518],
      [1183, -640],
      [1185, -417],
      [971, -643],
      [529, -452],
      [482, -349],
      [260, 42],
      [790, -238],
      [675, -203],
      [590, -440],
      [343, 89],
      [434, -300],
      [256, -100],
    ],
    [
      [21769, 29591],
      [52, 7],
      [318, 56],
      [199, 35],
      [773, -23],
      [324, 159],
      [303, 165],
      [329, 71],
      [186, 89],
      [261, 63],
      [246, 49],
      [417, 30],
      [329, 30],
      [233, 15],
      [322, 49],
      [197, -19],
      [417, -11],
      [586, -105],
      [211, -67],
      [142, -81],
      [130, -49],
    ],
    [
      [27744, 30054],
      [-80, -58],
      [-316, -16],
      [-405, 115],
      [-148, 43],
      [-295, -9],
      [-222, -110],
      [-461, -322],
      [0, -6],
      [0, -28],
      [-187, -110],
      [-301, -118],
      [-123, 9],
      [-147, 10],
      [-329, -57],
      [-84, -73],
      [-83, -71],
      [-61, -87],
      [-111, -158],
      [-165, -610],
      [-11, -41],
      [-160, -935],
      [4, -90],
      [12, -235],
      [42, -173],
      [9, -36],
      [67, -278],
      [227, -586],
      [118, -351],
      [217, -1054],
      [146, -1010],
    ],
    [
      [24897, 23609],
      [-168, 48],
      [-320, 94],
      [-226, -29],
      [-327, 18],
      [-131, -41],
      [-179, -97],
      [-377, -36],
      [-173, -165],
      [-132, -130],
      [-235, -226],
      [-297, -179],
      [-242, -172],
      [-458, -57],
      [-286, 46],
      [-242, -117],
      [-372, -234],
      [-274, -16],
      [-641, 78],
      [-301, 5],
      [-638, 241],
      [-102, 61],
      [-122, 47],
      [-170, 40],
      [-326, 161],
      [-475, 276],
      [-371, 52],
      [-475, 283],
      [-370, 93],
      [-292, 182],
      [-185, 60],
      [-249, -29],
      [-632, 439],
      [-103, 54],
      [-415, 215],
      [-258, 203],
      [-261, 67],
      [-292, 148],
      [-124, 53],
      [-525, 263],
      [-210, 53],
      [-481, 208],
      [-437, 188],
      [-319, 73],
      [-223, 33],
      [-582, 221],
      [-168, 67],
      [-358, 216],
      [-223, 230],
      [-312, 114],
      [-110, 60],
      [-333, 73],
      [-221, 183],
      [-329, 182],
      [-69, 170],
      [-134, 162],
      [-177, 136],
      [-66, 131],
    ],
    [
      [8379, 27808],
      [366, 464],
      [641, 1013],
      [408, 561],
      [787, 1020],
      [845, 809],
      [886, 836],
      [361, 247],
      [790, 584],
      [203, 982],
      [-69, 435],
      [-91, 572],
      [-38, 798],
    ],
    [
      [13468, 36129],
      [57, -21],
      [391, -147],
      [268, -122],
      [264, 2],
      [286, -32],
      [513, 9],
      [113, 29],
      [135, 0],
      [400, -25],
      [532, 85],
      [295, 97],
      [275, 84],
      [286, -19],
      [663, -194],
      [507, -194],
      [353, -277],
      [248, -326],
      [294, -598],
      [-14, -381],
      [-114, -334],
      [-53, -485],
      [-118, -443],
      [-115, -689],
      [-374, -111],
      [-285, -179],
      [-154, -443],
      [75, -75],
      [302, -625],
      [357, -188],
      [349, -713],
      [346, -107],
      [309, -73],
      [480, -59],
      [471, -17],
      [414, -5],
      [313, 9],
      [232, 29],
    ],
    [
      [45093, 75373],
      [-262, 51],
      [-129, 30],
      [-184, 51],
      [-191, 23],
      [-260, 33],
      [-253, 11],
      [-585, 220],
      [-400, 231],
      [-676, 243],
      [-924, 199],
      [-917, 83],
      [-1082, 45],
      [-987, 24],
      [-572, -38],
      [-1000, -4],
      [-958, -39],
      [-1503, 60],
      [-1269, 48],
      [-1428, -24],
      [-1192, -68],
      [-994, -127],
      [-751, -1],
      [-504, -32],
      [-319, 10],
    ],
    [
      [27753, 76402],
      [44, 205],
      [393, 680],
      [173, 867],
      [-152, 451],
      [-14, 477],
      [506, 949],
      [172, 955],
      [116, 182],
      [385, 496],
      [432, 672],
      [37, 495],
      [94, 400],
      [-24, 346],
      [-101, 430],
      [-219, 555],
    ],
    [
      [29595, 84562],
      [185, 63],
      [1341, 135],
      [1619, 97],
      [1622, 517],
      [1156, 532],
      [1150, 1231],
      [384, 1257],
      [754, 31],
    ],
    [
      [37806, 88425],
      [18, -29],
      [546, -879],
      [662, -724],
      [627, -861],
      [634, -846],
      [814, -938],
      [531, -600],
      [517, -668],
      [544, -798],
      [572, -988],
      [545, -852],
      [296, -1222],
      [41, -701],
      [303, -759],
      [255, -725],
      [166, -91],
      [96, -805],
      [111, -479],
      [9, -87],
    ],
    [
      [38266, 52527],
      [-49, -95],
      [-803, -224],
      [-167, -85],
      [-965, 30],
      [-381, 67],
      [-445, 77],
      [-277, 25],
      [-150, 95],
      [-14, 24],
      [-145, 200],
    ],
    [
      [34870, 52641],
      [-7, 10],
      [-53, 100],
      [-12, -11],
      [-18, 25],
      [-14, 14],
      [-35, 64],
      [-48, 87],
      [-131, 71],
      [-70, 131],
      [-111, 369],
      [-112, 370],
      [-62, 253],
      [-148, 602],
      [11, 565],
      [-82, 63],
      [-183, -194],
      [-105, -134],
      [-120, -114],
      [-370, -328],
      [-174, -37],
      [-68, 171],
      [-218, 38],
      [-55, -6],
    ],
    [
      [28786, 61008],
      [272, -85],
      [207, 64],
      [422, 313],
      [378, 238],
      [934, 500],
      [815, 830],
      [818, 468],
      [790, 374],
      [547, -37],
      [925, -479],
      [881, -752],
      [420, -293],
      [449, -281],
      [377, 150],
      [572, 439],
      [791, 510],
      [1002, 424],
      [803, 340],
      [347, 32],
    ],
    [
      [18896, 76162],
      [-8, -118],
      [328, -861],
      [58, -1046],
      [-6, -944],
      [172, -1307],
      [81, -891],
      [420, -1639],
      [56, -742],
      [22, -605],
      [-7, -768],
      [30, -578],
      [120, -533],
      [169, -560],
      [478, -280],
      [-54, -529],
      [31, -164],
      [3, -63],
    ],
    [
      [20789, 64534],
      [-256, 47],
      [-898, 177],
      [-639, 167],
      [-38, 10],
    ],
    [
      [18958, 64935],
      [-679, 179],
      [-1315, 436],
      [-865, 381],
      [-1240, 237],
      [-1315, 490],
      [-699, 290],
      [-192, 142],
    ],
    [
      [12653, 67090],
      [1596, 1026],
      [1053, 827],
      [518, 1099],
      [-99, 551],
      [-147, 1198],
      [41, 1637],
      [137, 1872],
      [96, 970],
      [48, 348],
      [1195, -267],
      [1789, -191],
      [16, 2],
    ],
    [
      [55751, 89181],
      [45, -44],
      [82, -124],
      [172, -418],
      [28, -41],
      [69, -409],
      [-173, -840],
      [-200, -751],
      [-148, -685],
      [-39, -509],
      [-206, -759],
      [-452, -741],
      [-450, -638],
      [-526, -645],
      [-137, -38],
      [-229, -1010],
      [55, -634],
      [-53, -365],
    ],
    [
      [53589, 80530],
      [-6, -42],
      [-273, -710],
      [-263, -962],
      [-314, -600],
      [-520, -774],
      [-329, -811],
      [-239, -595],
      [-128, 159],
      [-139, -229],
      [-104, -49],
    ],
    [
      [51274, 75917],
      [-212, -102],
      [-137, -65],
      [-445, -26],
      [-502, -270],
      [-377, -191],
      [-591, -309],
      [-611, -268],
      [-544, -351],
      [-679, -213],
      [-601, 18],
      [-442, 165],
      [-305, 271],
      [-658, 522],
      [-61, 97],
      [-16, 178],
    ],
    [
      [37806, 88425],
      [810, 33],
      [95, 46],
      [1011, 493],
      [1043, 1742],
      [991, 1017],
      [1183, 694],
      [2169, -69],
      [1841, -303],
      [1352, -485],
      [1213, -734],
      [986, -1049],
      [352, -414],
      [1144, -543],
      [1117, -480],
      [1069, -107],
      [1000, 113],
      [538, 575],
      [31, 227],
    ],
    [
      [21769, 29591],
      [76, 116],
      [-87, 408],
      [103, 273],
      [48, 239],
      [81, 95],
      [-67, 266],
      [108, 150],
      [37, 144],
      [-9, 224],
      [61, 89],
      [-9, 232],
      [55, 218],
      [139, -67],
      [-195, 414],
      [152, 314],
      [147, 594],
      [-112, 319],
      [182, 281],
      [-87, 387],
      [248, 213],
      [45, 314],
      [-74, 251],
      [-47, 279],
      [25, 450],
      [-50, 184],
      [-64, 176],
      [-58, 204],
      [75, 184],
      [-22, 156],
      [13, 300],
      [185, 103],
      [-137, 60],
      [93, 110],
      [94, 102],
      [-12, 102],
      [35, 136],
      [22, 266],
      [184, 388],
      [66, 96],
      [-11, 210],
      [85, 137],
      [307, 334],
      [7, 143],
      [229, 362],
      [63, 130],
      [289, 21],
      [109, 212],
      [329, 321],
      [124, 68],
      [199, 96],
      [132, 49],
      [578, 288],
      [102, 75],
      [-16, 184],
      [364, 314],
      [203, 42],
      [122, -33],
      [125, -79],
    ],
    [
      [40656, 42087],
      [-71, -164],
      [-86, -198],
      [-39, -237],
      [5, -28],
      [30, -182],
      [67, -152],
      [64, -145],
      [135, -175],
      [227, -588],
      [16, -121],
      [54, -393],
      [1, -74],
      [-75, -166],
      [-151, -337],
      [-144, -176],
      [-163, -198],
      [-201, -150],
      [-310, -29],
      [-110, 56],
      [-45, 24],
      [-116, 18],
      [-221, 34],
      [-296, -22],
      [-195, -63],
      [-135, -34],
      [-74, -22],
      [-93, -27],
      [-188, -109],
      [-48, -76],
      [-132, -210],
      [-63, -163],
      [-30, -74],
      [9, -161],
      [22, -381],
      [-17, -474],
      [-85, -278],
      [-463, -348],
      [-86, -136],
      [-158, -455],
      [4, 0],
      [22, 0],
      [-136, -124],
      [-44, -40],
      [-2, -2],
      [-67, -121],
      [-158, -135],
      [-222, -9],
      [-227, 63],
      [-110, 30],
      [-67, -8],
      [-242, -28],
      [-371, -338],
      [-71, -65],
      [-22, -17],
      [-138, -107],
      [-88, -69],
      [-229, -178],
      [-35, -28],
      [-56, -43],
      [-66, -51],
      [-83, -65],
      [-20, -15],
      [-58, -46],
      [-6, -4],
      [-15, -12],
      [-16, -12],
      [-47, -38],
      [-35, -28],
      [-62, -50],
      [-54, -44],
      [-61, -48],
      [-26, -21],
      [-40, -32],
      [-57, -22],
      [-84, -33],
      [-74, -1],
      [-55, 0],
      [-9, 0],
      [-46, 0],
      [-8, 0],
      [-57, -1],
      [-38, 22],
      [-53, 30],
      [-37, 21],
      [-45, 25],
      [-44, 26],
      [-28, 15],
      [-21, 13],
      [-4, 2],
      [-16, 4],
      [-61, 17],
      [-51, 15],
      [-18, 4],
      [-31, 9],
      [-6, 2],
      [-37, 10],
      [-25, 7],
      [-39, 11],
      [-5, 2],
      [-18, 7],
      [-42, 15],
      [-25, 9],
      [-14, 5],
      [-14, 5],
      [-9, 4],
      [-17, 6],
      [-24, 9],
      [-15, 5],
      [-27, 10],
      [-35, 13],
      [-1, 0],
      [-27, 10],
      [-10, 4],
      [-15, 5],
      [-35, 13],
      [-46, 17],
      [-43, 16],
      [-33, 12],
      [-22, 8],
      [-49, 18],
      [-55, 20],
      [-61, 22],
      [-26, 10],
      [-11, 4],
      [-9, 3],
      [-11, 2],
      [-14, 3],
      [-5, 0],
      [-10, 2],
      [-6, 1],
      [-2, 1],
      [-5, 0],
      [-8, 2],
      [-10, 2],
      [-24, 4],
      [-5, 1],
      [-15, 2],
      [-6, 1],
      [-9, 2],
      [-26, 5],
      [-16, 2],
      [-7, 2],
      [-15, 2],
      [-37, 7],
      [-31, 5],
      [-34, 6],
      [-14, 4],
      [-1, 0],
      [-1, 1],
      [-20, 6],
      [-402, 120],
      [-338, 100],
      [-323, 39],
      [-135, -29],
      [-115, -96],
      [-72, -61],
      [-120, -156],
      [-6, -122],
      [-24, -130],
      [-14, -74],
      [15, -297],
      [-44, -95],
      [-41, -89],
      [-113, -126],
      [-21, -24],
      [-9, -1],
      [-72, -13],
      [-149, -131],
      [-38, -33],
      [-58, -85],
      [-95, -139],
      [-118, -353],
      [-1, 0],
      [-26, 0],
      [-5, -30],
      [-72, -404],
      [-44, -339],
      [-119, -319],
      [-326, -441],
      [-147, -199],
      [-17, -33],
      [-102, -203],
      [-15, -28],
      [-38, -76],
      [-187, -130],
      [-235, -35],
      [-59, -2],
      [-324, -8],
      [-7, 1],
      [-22, 0],
      [0, 0],
      [-37, 2],
      [-63, 4],
      [-208, 11],
      [-141, -35],
      [-288, -179],
      [-146, -177],
      [-10, -7],
      [-4, -3],
    ],
    [
      [6762, 57764],
      [-530, 705],
      [-592, 1159],
      [-458, 850],
      [-575, 933],
      [-638, 1132],
      [-303, 736],
      [1205, 106],
      [1714, 321],
      [1584, 676],
      [1295, 857],
      [1685, 967],
      [1447, 848],
      [57, 36],
    ],
    [
      [18958, 64935],
      [88, -283],
      [58, -511],
      [27, -558],
      [45, -872],
      [-15, -537],
      [-51, -149],
      [52, -490],
      [-107, -632],
      [-66, -407],
      [-350, -477],
      [-251, -105],
      [-509, 42],
      [-456, 89],
      [-570, 240],
      [-880, 232],
      [-797, 40],
      [-1130, 11],
      [-861, -210],
      [-484, -27],
      [-947, -182],
      [-612, -269],
      [-798, -375],
      [-209, -133],
      [-535, -420],
      [-621, -24],
      [-36, -129],
      [-297, -294],
      [-704, -111],
      [-403, -116],
      [-159, -141],
      [-127, -72],
      [-424, -259],
      [-67, -42],
    ],
    [
      [63850, 95887],
      [-571, 330],
      [-958, 532],
      [-855, 531],
      [-842, 470],
      [-586, 377],
      [-875, 640],
      [-469, 348],
    ],
    [
      [58694, 99115],
      [9, 113],
      [74, 1027],
      [-82, 1334],
      [117, 1106],
      [767, 105],
      [688, 95],
      [290, -345],
      [607, -255],
      [1158, -284],
      [1820, 139],
      [952, 331],
      [855, 292],
      [837, 192],
      [-50, -298],
      [0, -119],
      [1, -254],
      [8, -192],
      [12, -287],
      [2, -49],
      [-44, -170],
      [-43, -163],
      [-16, -27],
      [-166, -285],
      [-202, -516],
      [-81, -123],
      [-236, -359],
      [-126, -201],
      [-36, -58],
      [-162, -122],
      [-88, -7],
      [-271, -258],
      [-94, -157],
      [-61, -203],
      [-1, -1],
      [-141, -88],
      [-3, -28],
      [-10, -94],
      [-230, -412],
      [-40, -70],
      [-249, -313],
      [-108, -278],
      [-71, -375],
      [-88, -466],
      [-84, -365],
      [-36, -158],
      [-98, -290],
      [-17, -49],
      [-9, -21],
      [-98, -222],
    ],
    [
      [52326, 62348],
      [-22, 252],
      [47, 537],
      [-47, 845],
      [90, 713],
      [62, 802],
      [146, 917],
      [70, 856],
      [20, 667],
      [-286, 747],
      [-252, 774],
      [-214, 494],
      [-288, 591],
      [-31, 354],
      [0, 599],
      [-90, 649],
      [-104, 492],
      [-56, 573],
      [-70, 525],
      [-83, 573],
      [113, 407],
      [-30, 361],
      [112, 331],
      [-78, 329],
      [-60, 178],
      [-1, 3],
    ],
    [
      [53589, 80530],
      [448, -493],
      [458, -669],
      [112, -403],
      [311, -340],
      [394, -286],
      [213, -175],
    ],
    [
      [55525, 78164],
      [-78, -120],
      [-249, -377],
      [-222, -299],
      [-268, -584],
      [-184, -167],
      [-536, -487],
      [-37, -101],
      [-97, -266],
      [8, -119],
      [7, -111],
      [55, -234],
      [246, -402],
      [85, -58],
      [35, -23],
      [49, -33],
      [197, -134],
      [116, -115],
      [23, -95],
      [11, -47],
      [-19, -156],
      [-94, -238],
      [-6, -23],
      [-120, -452],
      [-172, -312],
      [-150, -272],
      [-127, -116],
      [-179, -86],
      [-287, -140],
      [-97, -95],
      [-132, -129],
      [-47, -89],
      [-66, -292],
      [27, -74],
      [35, -101],
      [35, -237],
      [-47, -129],
      [109, -67],
      [75, -156],
      [29, -195],
      [34, -238],
      [82, -101],
      [162, -40],
      [307, -183],
      [100, -60],
      [250, 8],
      [183, -188],
      [265, -162],
      [251, -222],
      [177, -277],
      [-5, -154],
      [39, -76],
      [12, -22],
      [-76, -98],
      [-161, -206],
      [-197, -461],
      [-56, -48],
      [-249, -215],
      [-95, -105],
      [-101, -112],
      [-82, -216],
      [20, -224],
      [-116, -501],
      [33, -339],
      [-47, -62],
      [-95, -127],
      [-62, -163],
      [-48, -257],
      [127, -522],
      [-17, -49],
      [-23, -66],
      [-197, -122],
      [-13, -14],
      [-149, -168],
      [-81, -169],
      [-1, -1],
      [-27, -108],
      [122, -224],
      [33, -142],
      [-48, -142],
      [-94, -149],
      [48, -69],
      [19, -26],
      [9, -68],
      [4, -34],
      [-14, -63],
      [-27, -113],
      [135, -224],
      [54, -60],
      [13, -14],
      [-46, -155],
      [-36, -116],
      [-78, -105],
      [-84, -112],
      [-50, -97],
      [-17, -32],
      [-38, -423],
      [-38, -35],
      [-151, -142],
      [-66, -213],
      [-27, -86],
      [-52, -78],
      [-38, -55],
    ],
    [
      [13468, 36129],
      [-5, 94],
      [24, 838],
      [289, 820],
      [420, 738],
      [292, 362],
      [589, 1162],
      [386, 874],
      [420, 704],
      [427, 757],
      [329, 886],
      [141, 830],
      [-122, 857],
      [-739, 1350],
      [-115, 250],
      [-99, 214],
      [-48, 23],
      [-398, 107],
      [-578, 155],
      [-205, 25],
      [-718, 387],
      [-477, 446],
      [-236, 220],
      [-540, 725],
      [-83, 450],
      [-10, 82],
      [-13, 232],
      [-33, 626],
      [-20, 429],
      [106, 504],
    ],
    [
      [61817, 88044],
      [-10, 15],
      [-684, 645],
      [-169, 242],
      [-444, 21],
      [-689, 121],
      [-710, 54],
      [-723, 46],
      [-524, 139],
      [-503, 151],
      [-559, 206],
      [-324, 202],
      [-610, 137],
    ],
    [
      [55868, 90023],
      [73, 518],
      [476, 2066],
      [821, 1692],
      [455, 985],
      [573, 1710],
      [414, 1930],
      [14, 191],
    ],
    [
      [63850, 95887],
      [-19, -44],
      [-15, -32],
      [-60, -83],
      [-72, -101],
      [-30, -40],
      [-209, -218],
      [-459, -401],
      [-237, -164],
      [-95, -88],
      [-9, -7],
      [-78, -55],
      [-229, -515],
      [-154, -732],
      [-32, -157],
      [-80, -285],
      [-12, -147],
      [-8, -97],
      [21, -177],
      [118, -337],
      [26, -75],
      [15, -333],
      [-6, -250],
      [165, -528],
      [40, -39],
      [182, -178],
      [139, -280],
      [34, -349],
      [-10, -125],
      [-26, -329],
      [-267, -326],
      [-48, -58],
      [-13, -11],
      [-214, -193],
      [-160, -144],
      [-56, -51],
      [-66, -237],
      [-34, -123],
      [-46, -169],
      [-25, -315],
      [-4, -50],
    ],
    [
      [60657, 82886],
      [-125, 22],
      [-534, 10],
      [-613, 174],
      [-164, -4],
      [-876, -86],
      [-472, -114],
      [-275, -63],
      [-119, -270],
      [-395, -579],
      [-401, -503],
      [-370, -762],
      [-140, -359],
      [-380, -1000],
      [42, -661],
      [2, -147],
    ],
    [
      [55837, 78544],
      [-5, -3],
      [-9, -4],
      [-77, -83],
      [-105, -114],
      [-116, -176],
    ],
    [
      [55751, 89181],
      [117, 842],
    ],
    [
      [61817, 88044],
      [-1, -14],
      [-15, -197],
      [56, -522],
      [9, -307],
      [6, -235],
      [-350, -503],
      [-107, -285],
      [-12, -434],
      [125, -657],
      [-32, -333],
      [-20, -202],
      [-161, -509],
      [-72, -143],
      [-135, -265],
      [-236, -278],
      [-47, -81],
      [-48, -82],
      [-76, -71],
      [-44, -40],
    ],
    [
      [60657, 82886],
      [-250, -229],
      [-263, -429],
      [-46, -135],
      [19, -179],
      [16, -146],
      [28, -299],
      [-6, -164],
      [-6, -161],
      [-127, -339],
      [-38, -37],
      [-117, -113],
      [-218, -136],
      [-154, -96],
      [-150, -71],
      [-79, -38],
      [-23, -29],
      [-119, -155],
      [-167, -421],
      [-108, -320],
      [-66, -196],
      [-195, -108],
      [-318, -2],
      [-407, -78],
      [-100, -19],
      [-134, -68],
      [-406, -205],
      [-124, -15],
      [-120, -13],
      [-441, 27],
      [-283, 17],
      [-112, -50],
      [-283, -125],
      [-17, -8],
      [-6, -2],
    ],
    [
      [11705, 53435],
      [-329, 774],
      [-636, 1255],
      [-652, 977],
      [-982, 608],
      [-849, 272],
      [-739, 181],
      [-158, 18],
      [-128, 14],
      [-316, 26],
      [-154, 204],
    ],
    [
      [20789, 64534],
      [429, -80],
      [368, -20],
      [864, 102],
      [153, 48],
    ],
    [
      [18896, 76162],
      [796, 80],
      [459, 7],
      [1159, 260],
      [991, 364],
      [823, 503],
      [798, 898],
      [636, 1137],
      [1120, 1477],
      [977, 1132],
      [817, 1073],
      [281, 281],
      [842, 842],
      [1000, 346],
    ],
    [
      [27753, 76402],
      [-94, -438],
      [-393, -938],
      [48, -1123],
      [-159, -1098],
      [-263, -1002],
      [-476, -1453],
      [-90, -937],
      [172, -1192],
      [255, -875],
      [262, -514],
      [813, -1599],
    ],
    [
      [24897, 23609],
      [90, -619],
      [15, -468],
      [21, -635],
      [-12, -200],
      [-17, -294],
      [-22, -23],
      [-18, -18],
      [-321, -226],
      [-177, -529],
      [-286, -341],
      [-206, -245],
      [-318, -503],
      [-90, -301],
      [-66, -222],
      [110, -391],
      [63, -153],
      [40, -97],
      [34, -235],
      [45, -312],
      [-44, -346],
      [-24, -304],
      [143, -317],
      [43, -277],
      [-13, -88],
      [62, -94],
      [-65, -343],
      [-98, -206],
      [-167, -352],
      [-342, -291],
      [-67, -406],
      [-134, -176],
      [-115, -51],
      [-6, -3],
      [-163, -147],
      [-139, -124],
      [-160, -203],
      [-111, -257],
      [-77, -182],
      [-107, -104],
      [-257, -511],
      [-154, -125],
      [-174, -140],
      [-187, -209],
      [-74, -83],
      [-191, -137],
      [-358, -257],
      [-508, -516],
      [-80, -189],
      [-299, -699],
      [-111, -107],
      [-177, -171],
      [-152, -251],
      [-8, -14],
      [-12, -163],
      [-13, -182],
      [-100, -81],
      [-68, -55],
      [-32, -467],
      [-35, -63],
      [-71, -126],
      [-288, -245],
      [-61, -121],
      [-72, -144],
      [-8, -48],
      [-44, -269],
      [-53, -314],
      [-33, -201],
      [1, -229],
      [1, -238],
      [-59, -339],
      [-113, -257],
      [-117, -68],
      [-24, -14],
      [-174, -210],
      [-6, -13],
      [-87, -177],
      [149, -365],
      [55, -1060],
      [6, -121],
      [33, -639],
      [44, -386],
      [40, -344],
      [95, -413],
      [40, -84],
      [43, -92],
      [11, -22],
      [115, -247],
      [-334, -197],
      [-64, -38],
      [-261, -153],
      [-33, -20],
      [-84, -49],
      [-161, 13],
      [-203, -119],
      [-54, -31],
      [-185, -109],
      [-617, -36],
      [-115, -33],
      [-193, -56],
      [-281, -211],
      [-253, -300],
      [-188, -223],
      [-326, -516],
      [-245, -242],
      [-576, 415],
      [26, 743],
      [-229, 741],
      [-393, 781],
      [-293, 673],
      [-103, 1089],
      [-74, 593],
      [-351, 822],
      [83, 818],
      [95, 723],
      [97, 1064],
      [-492, 958],
      [-1009, 1233],
      [-451, 440],
      [-645, 663],
      [-940, 723],
      [-812, 880],
      [-363, 611],
      [-717, 533],
      [-671, 207],
      [-771, -183],
      [-322, -445],
      [-265, -383],
      [-139, -315],
      [-320, -363],
      [-287, -397],
      [-339, -316],
      [-149, -151],
      [-429, -316],
      [-363, -159],
      [-283, 229],
      [-990, 934],
      [-328, 659],
      [-1041, 1568],
      [-195, 550],
      [-180, 770],
      [-546, 1271],
      [-269, 605],
      [79, 152],
      [310, 593],
      [856, 1056],
      [1089, 1214],
      [901, 735],
      [892, 790],
      [1330, 1584],
      [518, 507],
      [540, 508],
      [576, 768],
      [897, 1137],
      [391, 496],
    ],
    [
      [34870, 52641],
      [-59, -53],
      [-122, -114],
      [-155, -72],
      [11, -164],
      [-752, -157],
      [-101, -6],
      [-298, -192],
      [-339, -49],
      [-439, -67],
      [-159, -18],
      [-441, 124],
      [-137, -18],
      [0, 7],
      [-251, 92],
      [-363, 122],
      [-223, 188],
      [-289, 162],
      [-177, 139],
      [-150, 221],
      [-243, 121],
    ],
  ],
  transform: {
    scale: [0.00003431214278968331, 0.000033939728989192384],
    translate: [11.400132539805623, 7.444363078129079],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5, 6, 7, 8]],
          type: "Polygon",
          properties: {
            name: "Demsa",
            id: "AD001",
            nameAbbr: "Demsa",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.07323569771,
            Shape_Area: 0.18767714791,
          },
        },
        {
          arcs: [[9, 10, 11, 12, -4, 13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Fufore",
            id: "AD002",
            nameAbbr: "Fufore",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.92068785752,
            Shape_Area: 0.34984777057,
          },
        },
        {
          arcs: [[16, 17, 18, 19, 20]],
          type: "Polygon",
          properties: {
            name: "Ganye",
            id: "AD003",
            nameAbbr: "Ganye",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.02465386596,
            Shape_Area: 0.14996911224,
          },
        },
        {
          arcs: [[21, 22, 23, 24]],
          type: "Polygon",
          properties: {
            name: "Girie",
            id: "AD004",
            nameAbbr: "Girie",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.76082359479,
            Shape_Area: 0.1533054016,
          },
        },
        {
          arcs: [[25, 26, -1, 27, -15]],
          type: "Polygon",
          properties: {
            name: "Gombi",
            id: "AD005",
            nameAbbr: "Gombi",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.43577049904,
            Shape_Area: 0.08890012977,
          },
        },
        {
          arcs: [[28, 29, 30, 31]],
          type: "Polygon",
          properties: {
            name: "Guyuk",
            id: "AD006",
            nameAbbr: "Guyuk",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.18445843168,
            Shape_Area: 0.05744355707,
          },
        },
        {
          arcs: [[32, 33, 34, -25, 35]],
          type: "Polygon",
          properties: {
            name: "Hong",
            id: "AD007",
            nameAbbr: "Hong",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.00220273438,
            Shape_Area: 0.22459903824,
          },
        },
        {
          arcs: [[-17, 36, -12, 37]],
          type: "Polygon",
          properties: {
            name: "Jada",
            id: "AD008",
            nameAbbr: "Jada",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.04866039064,
            Shape_Area: 0.14530152347,
          },
        },
        {
          arcs: [[38, -31, 39]],
          type: "Polygon",
          properties: {
            name: "Lamurde",
            id: "AD009",
            nameAbbr: "Lamurde",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.39427534095,
            Shape_Area: 0.09571951616,
          },
        },
        {
          arcs: [[40, 41]],
          type: "Polygon",
          properties: {
            name: "Madagali",
            id: "AD010",
            nameAbbr: "Madagali",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.88783935061,
            Shape_Area: 0.04106446431,
          },
        },
        {
          arcs: [[-10, 42, -34, 43, 44]],
          type: "Polygon",
          properties: {
            name: "Maiha",
            id: "AD011",
            nameAbbr: "Maiha",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.47050986719,
            Shape_Area: 0.04357455461,
          },
        },
        {
          arcs: [[-13, -37, -21, 45, -5]],
          type: "Polygon",
          properties: {
            name: "Mayo-Belwa",
            id: "AD012",
            nameAbbr: "Mayo-Belwa",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.52644550866,
            Shape_Area: 0.17809955859,
          },
        },
        {
          arcs: [[46, 47, -41, 48]],
          type: "Polygon",
          properties: {
            name: "Michika",
            id: "AD013",
            nameAbbr: "Michika",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.06264463962,
            Shape_Area: 0.05672805844,
          },
        },
        {
          arcs: [[49, 50, -44, -33, 51, -47, 52]],
          type: "Polygon",
          properties: {
            name: "Mubi North",
            id: "AD014",
            nameAbbr: "Mubi North",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.1518914758,
            Shape_Area: 0.05655503805,
          },
        },
        {
          arcs: [[-50, 53]],
          type: "Polygon",
          properties: {
            name: "Mubi South",
            id: "AD015",
            nameAbbr: "Mubi South",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.52524165277,
            Shape_Area: 0.01524959001,
          },
        },
        {
          arcs: [[-7, 54, -40, -30, 55]],
          type: "Polygon",
          properties: {
            name: "Numan",
            id: "AD016",
            nameAbbr: "Numan",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.6023759142,
            Shape_Area: 0.06668318311,
          },
        },
        {
          arcs: [[-56, -29, 56, -23, 57, -8]],
          type: "Polygon",
          properties: {
            name: "Shelleng",
            id: "AD017",
            nameAbbr: "Shelleng",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.84047264573,
            Shape_Area: 0.1318150274,
          },
        },
        {
          arcs: [[-35, -43, -16, -28, -9, -58, -22]],
          type: "Polygon",
          properties: {
            name: "Song",
            id: "AD018",
            nameAbbr: "Song",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.91218009967,
            Shape_Area: 0.35495858036,
          },
        },
        {
          arcs: [[58, -19]],
          type: "Polygon",
          properties: {
            name: "Teungo",
            id: "AD019",
            nameAbbr: "Teungo",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.08178494296,
            Shape_Area: 0.37797420933,
          },
        },
        {
          arcs: [[59, -2, -27]],
          type: "Polygon",
          properties: {
            name: "Yola North",
            id: "AD020",
            nameAbbr: "Yola North",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.45970819071,
            Shape_Area: 0.01147183287,
          },
        },
        {
          arcs: [[-60, -26, -14, -3]],
          type: "Polygon",
          properties: {
            name: "Yola South",
            id: "AD021",
            nameAbbr: "Yola South",
            parent: "Adamawa",
            parentAbbr: "AD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.81941634819,
            Shape_Area: 0.03033320283,
          },
        },
      ],
    },
  },
};

const niger = {
  type: "Topology",
  arcs: [
    [
      [84246, 30786],
      [160, -42],
      [329, -301],
      [45, -25],
      [208, -115],
      [8, -12],
      [11, -16],
    ],
    [
      [85007, 30275],
      [-22, 10],
      [-4, 2],
      [-6, 3],
      [1, -4],
      [56, -152],
      [42, -143],
      [39, -134],
      [164, -433],
      [72, -149],
      [100, -208],
      [-40, -288],
      [-26, -189],
      [-77, -301],
      [-19, -73],
      [-26, -119],
      [-93, -426],
      [3, -402],
      [2, -175],
      [14, -91],
      [20, -134],
      [20, -135],
      [86, -157],
      [10, -18],
      [-5, -32],
      [-10, -70],
      [52, -139],
      [48, -128],
      [14, -37],
      [143, -197],
      [43, -59],
      [99, -186],
      [182, -340],
      [84, -104],
      [58, -72],
      [31, -39],
      [-67, -188],
      [-3, -9],
      [-25, -69],
      [5, -164],
      [9, -271],
      [134, -143],
      [72, -78],
      [41, -121],
      [13, -37],
      [76, -222],
      [8, -26],
      [39, -67],
      [41, -70],
      [6, 0],
      [3, 0],
      [100, -213],
      [55, -61],
      [163, -180],
      [43, -201],
      [13, -58],
      [-9, -149],
      [23, -259],
      [-41, -58],
      [-18, -25],
      [-3, -4],
      [-6, -366],
      [-42, -87],
      [-56, -115],
      [-56, -169],
      [-137, -147],
      [-4, -40],
      [-12, -123],
      [14, -62],
      [11, -47],
      [53, -156],
      [21, -62],
      [16, -48],
      [10, -35],
      [41, -149],
      [9, -44],
      [11, -52],
      [11, -54],
      [118, -213],
      [-25, -326],
      [-9, -128],
      [51, -107],
      [19, -42],
      [53, -111],
      [50, -105],
      [35, -73],
      [44, -78],
      [61, -107],
      [39, -143],
      [11, -42],
      [13, -47],
      [5, -10],
      [54, -93],
      [3, -3],
      [3, -4],
      [-2, -32],
      [-5, -53],
      [-1, -16],
      [-1, -4],
      [-55, -118],
      [-3, -19],
      [-2, -9],
      [-9, -53],
      [-91, -189],
      [-151, -214],
      [-16, -18],
      [-34, -40],
      [-26, -29],
      [-50, -70],
      [-101, -138],
      [-52, -155],
      [-25, -74],
      [-70, -118],
      [-61, -104],
      [-95, -157],
      [-46, -74],
      [-128, -125],
      [-30, -19],
      [-162, -104],
      [-106, -68],
      [-259, -179],
      [-145, -113],
      [-43, -34],
      [-50, -39],
      [-288, -265],
      [-60, -55],
      [-118, -254],
      [-7, -15],
      [-51, -90],
      [-102, -179],
      [-3, -26],
      [-32, -223],
      [-10, -70],
      [92, -245],
      [11, -28],
      [16, -34],
      [101, -219],
      [97, -257],
      [19, -51],
      [18, -54],
      [78, -239],
      [81, -423],
      [41, -347],
      [69, -292],
      [7, -27],
      [13, -55],
      [-71, -281],
      [-1, -4],
      [-194, -322],
      [-103, -168],
      [-219, -254],
      [-220, -302],
      [-50, -49],
      [-114, -111],
      [-39, -94],
      [-3, -7],
      [-42, -101],
      [-30, -69],
      [-30, -66],
      [-3, -7],
      [-15, -134],
      [-16, -137],
      [-94, -91],
      [-48, -48],
      [-70, -68],
      [-145, -68],
      [-44, -20],
      [-21, -10],
      [-60, -8],
      [-7, -1],
      [-148, -21],
      [-125, -7],
      [-70, -3],
      [-103, 8],
      [-99, 9],
      [-235, 38],
      [-199, 20],
      [-123, 12],
      [-207, 479],
      [-69, 23],
      [-185, 63],
      [-195, -10],
      [-7, 0],
      [-141, 26],
      [-141, 26],
      [-10, -3],
      [-6, -2],
      [-52, -16],
    ],
    [
      [81548, 11504],
      [-50, 153],
      [-43, 133],
      [-161, 190],
      [-90, 106],
      [-69, 104],
      [-129, 192],
      [-41, 63],
      [-133, 203],
    ],
    [
      [80832, 12648],
      [11, 17],
      [60, 94],
      [56, 146],
      [21, 57],
      [-69, 313],
      [-19, 102],
      [-49, 252],
      [-128, 376],
      [-82, 334],
      [-7, 14],
      [-78, 178],
      [-24, 177],
      [-2, 8],
      [-61, 237],
      [-191, 234],
      [-28, -11],
      [-114, -48],
      [-85, 151],
      [5, 23],
      [9, 38],
      [-13, 85],
      [-11, 72],
      [-221, 44],
      [-82, 129],
      [-76, 118],
      [-63, 239],
      [-16, 91],
      [-15, 86],
      [-104, 233],
      [-16, 265],
      [-139, 348],
      [-107, 267],
      [-11, 140],
      [-5, 57],
      [7, 94],
      [9, 103],
      [23, 70],
      [12, 38],
      [-33, 161],
      [-23, 111],
      [7, 80],
      [18, 191],
      [8, 38],
      [21, 98],
      [-46, 238],
      [-16, 82],
      [-61, 143],
      [-43, 103],
      [-387, 143],
      [-48, -19],
      [-88, -34],
      [-101, -25],
      [-249, -2],
      [-92, 1],
      [-62, 1],
      [-38, -2],
      [-57, -3],
      [-219, 167],
      [22, 122],
      [-94, 189],
      [-55, 112],
      [89, 114],
      [118, 150],
      [67, 84],
      [45, 75],
      [52, 86],
      [-15, 378],
      [-4, 118],
      [1, 97],
      [2, 114],
      [-38, 157],
      [-161, 444],
      [30, 64],
      [5, 10],
      [-36, 294],
      [-12, 93],
      [10, 25],
      [26, 70],
      [21, 61],
      [-90, 266],
      [-15, 86],
      [-16, 91],
      [-191, 261],
      [3, 27],
      [5, 41],
      [8, 94],
      [1, 8],
      [-9, 67],
      [-10, 69],
      [7, 31],
      [15, 70],
      [-2, 59],
      [-2, 84],
      [-41, 169],
      [-28, 105],
      [-15, 107],
      [-10, 63],
      [1, 7],
      [11, 196],
      [22, 375],
      [163, 533],
      [231, 317],
      [158, 218],
      [172, 86],
      [12, 6],
      [134, 67],
      [193, 40],
      [53, 11],
      [149, 65],
      [50, 21],
      [124, 54],
      [32, 206],
      [21, 128],
      [-15, 63],
      [-8, 35],
      [-28, 125],
      [-68, 123],
      [-8, 15],
      [-36, 65],
      [-106, 257],
      [-175, 304],
      [-99, 190],
      [-70, 134],
      [-79, 251],
      [-49, 156],
      [-22, 172],
      [-24, 201],
      [153, 294],
      [26, 21],
      [142, 110],
      [170, 131],
      [91, 280],
      [74, 143],
      [30, 59],
      [23, 44],
      [16, 17],
      [91, 93],
      [145, 172],
      [97, 117],
      [143, 107],
      [65, 49],
      [97, 72],
      [106, 82],
      [122, 94],
      [48, 38],
      [227, -45],
      [274, -106],
      [374, 168],
      [9, 13],
      [78, 111],
      [127, 177],
      [63, 83],
      [78, 103],
      [37, 15],
      [196, 79],
      [201, 81],
      [307, -4],
      [148, -2],
      [119, -1],
      [634, -26],
      [327, -5],
      [71, -1],
      [303, -5],
      [288, 30],
      [249, 25],
      [12, -3],
      [329, -87],
      [14, -4],
    ],
    [
      [33325, 73952],
      [-31, -151],
      [-176, -960],
      [-202, -572],
      [-423, -457],
      [-563, -523],
      [-447, -439],
      [-382, -395],
      [-358, -269],
      [-555, -213],
      [-510, -163],
      [-414, -140],
      [-365, -163],
      [-356, -222],
      [-344, -255],
      [-325, -319],
    ],
    [
      [27874, 68711],
      [-382, 86],
      [-499, 54],
      [-315, 104],
      [-371, -19],
      [-393, 54],
      [-161, 32],
      [-204, -63],
      [-227, -31],
      [-258, -13],
      [-222, 82],
      [-393, 53],
      [-331, 23],
      [-469, 5],
      [-389, 111],
      [-332, -78],
      [-257, -139],
      [-265, -44],
      [-136, 18],
      [-1, 82],
      [36, 327],
      [254, 343],
      [208, 268],
      [241, 731],
      [122, 566],
      [171, 920],
      [398, 746],
      [51, 280],
      [57, 347],
      [106, 723],
      [-36, 673],
      [-178, 569],
      [-219, 603],
      [-157, 991],
      [-8, 626],
      [-204, 991],
      [180, 1287],
      [-264, 841],
      [-310, 806],
      [-322, 669],
      [-229, 270],
      [-96, 455],
      [26, 30],
    ],
    [
      [22096, 83090],
      [498, 78],
      [291, 105],
      [565, 109],
      [556, 74],
      [803, 51],
      [578, -14],
      [397, -145],
      [581, -170],
      [677, -183],
      [629, -258],
      [184, -38],
      [446, -288],
      [331, -295],
      [508, -280],
      [639, -462],
      [556, -313],
      [378, -288],
      [487, -300],
      [357, -234],
      [255, -222],
      [446, 85],
      [299, -38],
      [377, -107],
      [373, -81],
      [348, -201],
      [258, -113],
      [241, -345],
      [-14, -443],
      [-19, -557],
      [-62, -908],
      [-63, -413],
      [-151, -549],
      [-234, -858],
      [-178, -994],
      [-97, -485],
      [-11, -58],
    ],
    [
      [68656, 25413],
      [64, 98],
      [133, 308],
      [227, 383],
      [-26, 118],
      [-33, 146],
      [72, 260],
      [56, 43],
      [208, 164],
      [104, 56],
      [194, 104],
      [114, 43],
      [144, 55],
      [347, 79],
      [86, -22],
      [202, -50],
      [131, -114],
      [189, -310],
      [356, -83],
    ],
    [
      [71224, 26691],
      [1, -51],
      [1, -32],
      [-38, -84],
      [-55, -121],
      [-46, -123],
      [-79, -232],
      [-13, -41],
      [-100, -157],
      [-60, -123],
      [-127, -213],
      [-162, -165],
      [-89, -28],
      [-115, -35],
      [-118, -18],
      [-127, -19],
      [-239, -23],
      [-256, -21],
      [-51, -4],
      [-205, 39],
      [-175, 16],
      [-78, 8],
      [-345, 85],
      [-25, 6],
      [-17, 14],
      [-50, 44],
    ],
    [
      [20749, 83088],
      [155, -42],
      [982, 11],
      [210, 33],
    ],
    [
      [27874, 68711],
      [-128, -127],
      [-412, -496],
      [-511, -877],
      [-175, -657],
      [-8, -443],
      [-106, -382],
      [-27, -993],
      [78, -617],
      [-25, -543],
      [129, -318],
      [138, -416],
      [263, -404],
      [388, -291],
      [340, -248],
      [350, -300],
      [300, -415],
      [-44, -544],
      [42, -447],
      [-134, -866],
      [-218, -500],
      [-121, -455],
      [-147, -589],
      [-52, -459],
      [65, -618],
      [190, -267],
      [190, -80],
    ],
    [
      [28239, 56359],
      [-29, -82],
      [69, -374],
      [451, -601],
      [84, -469],
      [63, -381],
      [8, -442],
      [-121, -499],
      [-16, -640],
      [97, -748],
      [98, -468],
      [316, -1059],
      [441, -1208],
      [167, -795],
      [179, -774],
      [175, -529],
      [158, -158],
    ],
    [
      [30379, 47132],
      [-15, -154],
      [150, -679],
      [-41, -695],
      [-96, -688],
      [-197, -485],
      [-237, -472],
      [-328, -494],
      [-230, -478],
      [-180, -594],
      [-230, -486],
      [-438, -399],
      [-335, -480],
      [-209, -390],
      [-125, -430],
      [-78, -423],
      [33, -387],
      [149, -563],
      [228, -502],
      [212, -290],
      [295, -405],
      [270, -331],
      [127, -285],
      [-46, -436],
      [-6, -13],
    ],
    [
      [29052, 36573],
      [-34, 30],
      [-19, 17],
      [-813, -36],
      [-1123, -209],
      [-742, -82],
      [-885, -50],
      [-613, 184],
      [-279, 610],
      [-25, 207],
      [-109, 921],
      [30, 967],
      [-3, 365],
      [-2, 262],
      [-20, 353],
      [-33, 599],
      [133, 995],
      [35, 350],
      [46, 441],
      [16, 50],
      [114, 360],
      [-9, 830],
      [-121, 557],
      [-485, 227],
      [-802, -83],
      [-140, 69],
      [-304, 152],
      [-635, 524],
      [-624, 682],
      [-667, 742],
      [-207, 283],
      [-197, 268],
      [-195, 275],
      [-176, 249],
      [-200, 195],
      [-376, 365],
      [-206, 431],
      [-154, 325],
      [-238, 291],
      [-338, 414],
      [-158, 145],
      [-245, 225],
      [-640, 663],
      [-243, 306],
      [-261, 330],
      [-371, 397],
      [-149, 159],
      [-351, 406],
      [-407, 548],
      [-511, 616],
      [-381, 444],
      [-323, 473],
      [-345, 386],
      [-238, 245],
      [-259, 266],
      [-459, 496],
      [-25, 27],
      [-436, 404],
      [-122, 113],
      [-19, 17],
      [-388, 359],
      [-251, 460],
      [-62, 31],
      [-294, 148],
      [-383, 56],
      [-217, 32],
      [-514, 117],
      [-231, 52],
      [-115, -7],
      [-476, -28],
      [-843, -125],
      [-234, -13],
      [-405, -22],
      [-189, 25],
      [-329, 44],
      [-33, -1],
      [-70, -2],
      [-904, -29],
      [-477, 28],
      [-496, -53],
      [-762, 18],
      [-905, -72],
      [-964, -188],
      [-461, -92],
      [-203, -79],
      [93, 358],
      [1, 4],
      [82, 209],
      [-6, 197],
      [89, 406],
      [-26, 250],
      [-162, 163],
      [-500, 9],
      [-263, 157],
      [-674, 727],
      [-74, 54],
      [-237, 171],
      [-431, 590],
      [-161, 340],
      [-221, 644],
      [-13, 95],
      [357, 933],
      [196, 514],
      [328, 832],
      [25, 38],
      [131, 199],
      [151, 230],
      [5, 7],
      [35, 53],
      [97, 246],
      [125, 235],
      [22, 147],
      [-6, 62],
      [-50, 504],
      [-33, 79],
      [6, 13],
      [29, 54],
      [140, 251],
      [246, 221],
      [373, 140],
      [191, 79],
      [605, 381],
      [44, 27],
      [135, -48],
      [82, -28],
      [621, -328],
      [412, -259],
      [561, -280],
      [318, -171],
      [250, -82],
      [182, -149],
      [304, -158],
      [343, 984],
      [53, 182],
      [30, 99],
      [45, 204],
      [-33, 883],
      [0, 3],
      [20, 73],
      [130, 449],
      [253, 730],
      [150, 352],
      [13, 30],
      [82, 185],
      [85, 230],
      [163, 406],
      [484, 594],
      [76, 325],
      [5, 42],
      [4, 21],
      [0, 15],
      [12, 98],
      [-20, 224],
      [-15, 285],
      [-31, 566],
      [-18, 225],
      [5, 1],
      [-28, 522],
      [-30, 37],
      [-31, 64],
      [-22, 215],
      [-24, 253],
      [-12, 176],
      [-87, 353],
      [-229, 218],
      [-595, 321],
      [-209, 166],
      [-233, 186],
      [-356, 535],
      [-4, 6],
      [-102, 278],
      [-120, 326],
      [-148, 310],
      [-8, 18],
      [-152, 217],
      [-125, 185],
      [-93, 186],
      [-46, 88],
      [-24, 47],
      [-31, 58],
      [-49, 55],
      [-63, 69],
      [-78, 260],
      [0, 14],
      [-10, 360],
      [0, 4],
      [10, 31],
      [168, 542],
      [224, 634],
      [75, 240],
      [104, 337],
      [19, 233],
      [41, 385],
      [1, 345],
      [-107, 590],
      [-10, 65],
      [-2, 58],
      [-6, 157],
      [-216, 1087],
      [-235, 418],
      [-360, 179],
      [-143, 71],
      [-207, 63],
      [-51, 16],
      [-186, 19],
      [-60, 34],
      [10, 305],
      [0, 4],
      [1, 11],
      [26, 46],
      [97, 169],
      [67, 385],
      [-1, 16],
      [-21, 301],
      [-78, 249],
      [-1, 4],
      [-33, 346],
      [134, 566],
      [107, 350],
      [-45, 187],
      [-161, 72],
      [-83, 38],
      [-140, 4],
      [-25, 6],
      [446, 238],
      [436, -2],
      [464, -42],
      [459, -124],
      [117, -101],
      [613, -347],
      [624, -237],
      [571, -341],
      [792, -508],
      [689, -421],
      [1017, -498],
      [776, -406],
      [1023, -397],
      [617, -272],
      [875, -133],
      [942, -43],
      [1081, 203],
      [867, 3],
      [891, 133],
      [499, -97],
      [1021, 128],
      [429, 52],
      [785, -25],
      [806, -65],
      [187, -50],
    ],
    [
      [88680, 39642],
      [-84, -47],
      [-197, -70],
      [-512, -150],
      [-837, 112],
      [-511, -95],
      [-750, -76],
      [-708, -158],
      [-515, -339],
      [-408, -604],
      [-124, -463],
      [-11, -653],
      [16, -625],
      [-150, -519],
      [-166, -57],
    ],
    [
      [83723, 35898],
      [-79, -27],
      [-123, 12],
      [-244, -98],
      [-643, 40],
      [-440, -82],
      [-303, -56],
      [-504, -54],
      [-272, -104],
      [-171, -30],
      [-98, -152],
      [-115, -177],
      [-127, -120],
      [-197, -189],
      [-203, 11],
      [-98, 5],
      [-159, 81],
      [-219, 112],
      [-275, 85],
      [-224, -91],
      [-417, 9],
      [-296, 138],
      [-116, 54],
      [-6, 2],
      [-288, 56],
      [-381, -113],
      [-35, -4],
      [-402, -42],
      [-152, 168],
      [9, 320],
      [162, 236],
      [12, 17],
      [26, 39],
      [95, 146],
      [65, 77],
      [136, 163],
      [1, 177],
      [1, 177],
      [-34, 187],
      [-3, 16],
      [-207, 147],
      [-227, 161],
      [-70, 67],
      [-104, 101],
      [-119, 209],
      [-125, 209],
      [-240, 25],
      [-233, 65],
      [-183, -84],
      [-150, 73],
      [-124, 142],
      [-31, 23],
      [-119, 91],
      [-50, 45],
      [-52, 47],
    ],
    [
      [75572, 38208],
      [39, 57],
      [402, 512],
    ],
    [
      [76013, 38777],
      [7, 10],
      [194, 294],
      [55, 626],
      [-317, 358],
      [-297, 547],
      [-191, 672],
      [-121, 529],
      [-96, 774],
      [141, 805],
      [254, 350],
      [347, 636],
      [122, 709],
      [18, 949],
    ],
    [
      [76129, 46036],
      [115, -2],
      [585, -137],
      [252, -18],
      [708, -162],
      [578, -14],
      [402, -84],
      [278, 71],
      [495, 87],
      [427, 73],
      [321, -113],
      [136, 2],
      [299, -51],
      [483, -56],
      [350, -146],
      [298, 214],
      [299, 79],
      [182, 192],
      [256, 207],
      [109, 69],
      [208, 241],
      [168, 240],
      [189, 111],
      [48, 34],
      [148, 138],
      [237, 145],
      [230, 125],
      [353, -315],
      [296, -187],
      [211, 71],
      [437, 19],
      [741, -243],
      [501, -157],
      [241, -202],
      [91, -107],
      [168, -338],
      [120, -257],
      [85, -251],
      [100, -311],
      [334, -397],
      [213, -93],
      [295, -119],
      [172, -93],
      [96, -46],
      [297, -242],
      [359, -281],
      [260, -527],
      [49, -47],
      [32, -353],
      [66, -326],
      [37, -190],
      [-22, -149],
      [8, -401],
      [-12, -380],
      [-62, -694],
      [-280, -411],
      [-304, -201],
      [-140, -165],
      [8, -246],
    ],
    [
      [84180, 40595],
      [240, -52],
      [191, 3],
      [307, 31],
      [252, -18],
      [315, -23],
      [356, -44],
      [369, -2],
      [235, 241],
      [27, 75],
      [148, 110],
      [146, 308],
      [-32, 380],
      [-59, 326],
      [-174, 229],
      [-223, 303],
      [-104, 128],
      [109, 49],
      [-48, -1],
      [-111, 115],
      [-247, 65],
      [-383, 70],
      [-396, -66],
      [-434, -222],
      [-147, -206],
      [-160, -308],
      [-138, -341],
      [9, -225],
      [-222, -247],
      [-146, -280],
      [79, -272],
      [241, -126],
    ],
    [
      [63716, 24448],
      [-50, -90],
      [-20, -36],
      [-39, -73],
      [2, -76],
      [3, -65],
      [110, -183],
      [44, -72],
      [-16, 0],
      [29, -35],
      [15, -18],
      [14, -17],
      [38, -64],
      [17, -25],
      [16, 0],
      [11, -10],
      [24, -29],
      [156, -183],
      [64, -74],
      [4, -5],
      [9, -15],
      [109, -177],
      [89, -131],
      [12, -18],
      [57, -85],
      [150, -253],
      [119, -156],
      [39, -51],
      [80, -76],
      [121, -81],
      [19, -13],
      [6, -3],
      [123, -274],
      [0, -7],
      [-110, -134],
      [12, -41],
      [1, -4],
      [13, -43],
      [55, -112],
      [6, -12],
      [37, -75],
      [-191, -139],
      [-212, -200],
      [-19, -24],
      [-138, -164],
      [-258, -131],
      [-223, -105],
      [-12, 3],
      [-4, 1],
      [-11, 3],
      [-95, -52],
      [-29, -34],
      [-20, -23],
      [-95, -111],
      [-14, -27],
      [-622, -240],
      [-131, -23],
      [-44, -8],
      [-135, 13],
      [-33, 3],
      [-244, -91],
      [-30, -23],
      [-65, -49],
      [-111, 6],
      [-70, 4],
      [-140, 110],
      [-252, 188],
      [-61, 64],
      [-52, 53],
      [-74, 75],
      [-7, 7],
      [-77, 77],
      [-101, 29],
      [-70, 44],
      [-43, 26],
      [-33, 14],
      [-100, 43],
      [-140, 26],
      [-99, 19],
      [-110, 22],
      [-257, -91],
      [-261, 86],
      [-76, 10],
      [-111, 13],
      [-237, -43],
      [-237, -102],
      [-20, -9],
      [-139, 97],
      [-132, 179],
      [-25, 11],
      [-115, 53],
      [-16, 10],
      [-13, 8],
      [-131, 86],
      [-78, 165],
      [-201, 71],
      [-178, 173],
      [-105, 205],
      [-66, 62],
      [-121, -37],
      [-68, -21],
      [-355, 54],
      [-141, 30],
      [-17, -1],
      [-47, -1],
      [-104, -2],
      [-102, -66],
      [-6, 5],
      [-18, 18],
      [-142, 136],
      [-77, 123],
      [-20, 32],
      [-41, 64],
      [-145, 166],
      [-20, 6],
      [-15, 4],
      [-38, 11],
      [-88, 41],
      [-79, 37],
      [-295, 46],
      [-48, 18],
      [-35, 13],
      [-104, 40],
      [-88, 15],
      [-87, -26],
      [-47, 37],
      [-21, 17],
      [-72, 57],
      [-118, 207],
      [-6, 11],
      [-13, 22],
      [-27, 90],
      [-18, 60],
      [-6, 32],
      [-25, 132],
      [-81, 178],
      [-12, 26],
      [-63, 138],
      [1, 3],
      [2, 9],
      [2, 6],
      [0, 4],
      [2, 9],
      [7, 33],
      [2, 10],
      [13, 61],
      [-1, 4],
      [-197, 0],
      [-36, 21],
      [-16, 9],
      [-14, 9],
      [-50, 38],
      [-578, 446],
      [-146, 98],
      [-30, 20],
      [-71, 48],
      [-46, 31],
      [-519, 335],
      [-89, 57],
      [-513, 141],
      [-58, 16],
      [-157, 4],
      [-472, 13],
      [-117, 102],
      [-16, 14],
      [-92, 81],
      [-35, 51],
      [-10, 14],
      [-58, 84],
      [-88, 447],
      [374, 221],
      [55, 32],
      [146, 87],
      [157, 6],
      [83, 4],
      [408, 17],
      [621, 41],
      [535, 84],
      [80, 12],
      [222, 35],
      [39, 14],
      [76, 27],
      [184, 64],
      [44, 300],
      [4, 27],
      [8, 55],
      [-71, 131],
      [-20, 37],
      [-49, 89],
      [-159, 223],
      [-64, 101],
    ],
    [
      [55005, 27539],
      [94, -11],
      [112, -14],
      [593, 6],
      [171, -11],
      [340, 51],
      [306, 119],
      [166, 27],
      [59, 9],
      [241, 60],
      [194, 47],
      [87, 26],
      [76, 23],
      [194, 127],
      [144, 95],
      [304, 241],
      [358, 324],
      [325, 146],
      [257, 180],
      [384, 77],
      [118, 23],
      [428, -9],
      [199, -229],
      [9, -122],
      [23, -313],
      [77, -325],
      [44, -190],
      [110, -101],
      [22, -23],
      [26, -24],
      [164, -87],
      [306, -72],
      [341, -111],
      [225, -44],
      [143, -27],
      [107, 13],
      [185, 24],
      [188, -17],
      [145, -13],
      [260, -209],
      [151, -141],
      [23, -156],
      [-50, -470],
      [-477, -563],
      [-261, -245],
      [-136, -127],
      [15, -98],
      [36, -234],
      [-107, -515],
      [-198, -65],
      [-262, -142],
      [-34, -305],
      [350, -291],
      [94, -78],
      [317, -71],
      [417, 28],
      [124, 121],
      [79, 78],
      [38, 37],
      [250, 208],
      [248, 141],
      [194, 59],
      [169, 52],
      [75, 7],
      [131, 13],
    ],
    [
      [75572, 38208],
      [-336, -491],
      [-384, -504],
      [-45, -59],
      [-200, -383],
      [-17, -205],
      [-5, -64],
      [-8, -92],
      [6, -113],
      [6, -104],
      [13, -245],
      [-78, -300],
      [-125, -437],
      [-58, -20],
      [-382, -134],
      [-317, -191],
      [-108, -66],
      [-3, -4],
      [-51, -78],
      [-67, -102],
      [35, -75],
      [32, -68],
      [72, -155],
      [277, -351],
      [14, -516],
      [-74, -134],
      [-106, -195],
      [246, -331],
      [4, -6],
      [428, -348],
      [-315, -425],
      [-51, -114],
      [-108, -235],
      [108, -174],
      [276, -447],
      [467, -205],
      [665, -149],
      [234, -85],
      [87, -52],
      [298, -175],
      [225, -368],
      [6, -10],
      [12, -23],
      [233, -450],
      [-99, -410],
      [-43, -176],
      [-413, -154],
      [-97, -36],
      [-387, -120],
      [-93, -87],
      [-62, -57],
      [-62, -127],
      [-71, -147],
      [-45, -225],
      [-54, -105],
      [-22, -45],
      [-77, -151],
      [-96, -222],
      [-43, -99],
      [-260, -424],
      [-363, -392],
      [-56, -7],
      [-10, -2],
      [-166, -22],
    ],
    [
      [73959, 26517],
      [-90, 42],
      [-162, 74],
      [-37, 17],
      [-224, 63],
      [-174, 48],
      [-99, 40],
      [-182, 73],
      [-295, 37],
      [-73, 38],
      [-91, 48],
      [-120, 128],
      [-144, 155],
      [-26, 143],
      [-26, 142],
      [-81, 40],
      [-86, 44],
      [-18, 9],
      [-9, 4],
      [-136, 69],
      [-94, 27],
      [-63, 18],
      [-51, -36],
      [-34, -25],
      [-58, -42],
      [20, -236],
      [5, -56],
      [6, -46],
      [3, -21],
      [21, -157],
      [-20, -115],
      [-2, -10],
      [-3, -18],
      [-61, -78],
      [-80, -101],
      [-23, -12],
      [-30, -17],
      [-62, -33],
      [-129, -83],
      [-7, 1],
    ],
    [
      [68656, 25413],
      [-47, -73],
      [-76, -117],
      [-316, -336],
      [-68, -94],
      [-264, -369],
      [-55, -59],
      [-106, -114],
      [-114, -78],
      [-335, -226],
      [-17, -11],
      [-76, -46],
      [-36, -22],
      [-25, -15],
      [-99, -60],
      [-40, -35],
      [-83, -73],
      [-10, -9],
      [-187, -157],
      [-171, -160],
      [-171, -153],
      [-225, -200],
      [-232, -207],
      [-266, -246],
      [-23, -23],
      [-46, -45],
    ],
    [
      [65568, 22485],
      [-6, 3],
      [-26, 19],
      [-103, 77],
      [-17, 13],
      [-244, 303],
      [-164, 261],
      [-107, 145],
      [-31, 41],
      [-129, 182],
      [-22, 31],
      [-19, 37],
      [-49, 95],
      [-30, 59],
      [-40, 47],
      [-48, 55],
      [-8, 0],
      [1, 84],
      [1, 164],
      [1, 95],
      [10, 386],
      [-10, 119],
      [-17, 213],
      [14, 255],
      [15, 276],
      [11, 211],
      [37, 330],
      [6, 50],
      [13, 117],
      [9, 69],
      [1, 9],
      [28, 222],
      [65, 266],
      [57, 198],
      [27, 90],
      [1, 5],
      [4, 19],
      [29, 137],
      [14, 70],
      [37, 176],
      [31, 273],
      [-19, 47],
      [-4, 10],
      [-32, 78],
      [4, 15],
      [2, 6],
      [8, 73],
      [23, 213],
      [-2, 224],
      [139, 315],
      [13, 136],
      [-23, 100],
      [-10, 44],
      [-6, 30],
      [-11, 50],
      [38, 104],
      [16, 43],
      [65, 174],
      [-5, 82],
      [-4, 88],
      [-70, 186],
      [-7, 18],
      [-7, 19],
      [36, 484],
      [137, 484],
      [-46, 585],
      [94, 197],
      [98, 206],
      [40, 207],
      [25, 127],
      [158, 485],
      [39, 150],
      [4, 7],
      [210, 363],
      [31, 292],
      [7, 15],
      [153, 293],
      [34, 86],
      [26, 64],
      [6, 14],
      [141, 172],
      [74, 89],
      [88, 106],
      [118, 141],
      [29, 35],
      [93, 198],
      [-26, 77],
      [-24, 72],
      [-28, 108],
      [-5, 62],
      [-1, 7],
      [-2, 27],
      [-35, 156],
      [151, 464],
      [8, 103],
      [16, 203],
      [8, 22],
      [71, 211],
      [-11, 121],
      [-3, 38],
      [-9, 92],
      [80, 219],
      [3, 61],
      [8, 163],
      [35, 177],
      [42, 211],
      [160, 288],
      [67, 123],
      [-5, 90],
      [-2, 24],
      [-1, 16],
      [-4, 44],
      [-11, 132],
      [72, 293],
    ],
    [
      [67167, 38146],
      [19, 17],
      [108, 94],
      [242, 288],
      [14, 154],
      [14, 145],
      [15, 157],
      [58, 89],
      [116, 178],
      [150, 164],
      [78, 84],
      [168, 127],
      [41, 32],
      [136, 49],
      [60, 16],
      [271, 75],
      [226, 32],
      [86, 13],
      [99, 10],
      [193, 20],
      [401, 25],
      [171, 2],
      [101, 1],
      [129, -46],
      [113, -22],
      [153, -30],
      [27, -7],
      [6, -2],
      [15, -4],
      [3, 0],
      [10, 0],
      [185, -120],
      [273, -133],
      [183, 63],
      [101, 35],
      [107, 114],
      [55, 58],
      [251, 23],
      [428, 52],
      [421, -23],
      [338, 167],
      [130, 155],
      [78, 93],
      [56, 55],
      [125, 123],
      [41, 3],
      [68, 5],
      [55, -36],
      [55, -37],
      [239, -107],
      [123, -141],
      [137, -121],
      [76, -67],
      [101, -75],
      [152, -113],
      [158, -188],
      [131, -156],
      [403, -172],
      [142, -15],
      [96, -10],
      [278, 24],
      [84, 0],
      [141, 2],
      [102, -44],
      [21, -9],
      [144, -230],
      [7, -9],
      [138, -196],
    ],
    [
      [107307, 37792],
      [-470, -202],
      [-606, -373],
      [-261, -439],
      [-46, -1096],
      [251, -352],
      [443, -296],
      [268, -127],
      [427, -248],
      [211, -291],
      [-125, -389],
      [-77, -175],
    ],
    [
      [107322, 33804],
      [-12, -3],
      [-219, -111],
      [-299, 18],
      [-218, 38],
      [-346, -200],
      [-202, -247],
      [-59, -307],
      [-11, -327],
      [-15, -170],
      [80, -224],
      [33, -293],
      [44, -211],
      [52, -292],
      [-35, -89],
      [-129, -123],
      [-293, 3],
      [-239, -22],
      [-317, -98],
      [-248, -71],
      [-5, -6],
    ],
    [
      [104884, 31069],
      [-61, 47],
      [-222, 66],
      [-175, 278],
      [-90, 264],
      [-186, 176],
      [-170, 134],
      [-185, -22],
      [-252, -152],
      [-151, -83],
      [-223, -165],
      [-167, -158],
      [-125, -301],
      [26, -313],
      [62, -326],
      [26, -320],
      [111, -217],
      [39, -299],
      [-15, -300],
      [48, -368],
      [-4, -415],
      [3, -293],
      [-9, -293],
      [-31, -354],
      [-79, -180],
    ],
    [
      [103054, 27475],
      [-72, 90],
      [-377, 269],
      [-428, 337],
      [-477, 465],
      [-310, 386],
      [-395, 269],
      [-375, 309],
      [-599, 458],
      [-252, 325],
      [-466, 164],
      [-364, 134],
      [-462, -97],
      [-651, -34],
      [-288, 41],
      [-302, -87],
      [-423, -16],
      [-540, -16],
      [-337, 46],
      [-269, 26],
      [-288, -56],
      [-639, -24],
      [-503, 17],
      [-281, -15],
      [-207, -8],
      [-349, -50],
      [-309, -36],
      [-330, -16],
      [-274, -56],
      [-162, -178],
      [-9, -294],
      [-58, -191],
      [-6, -430],
      [21, -825],
      [20, -710],
      [1, -38],
    ],
    [
      [92294, 27634],
      [-35, 24],
      [-126, 143],
      [-71, 108],
      [-43, 96],
      [-92, 109],
      [-31, 93],
      [-12, 36],
      [-15, 68],
      [-1, 75],
      [5, 55],
      [-8, 95],
      [17, 178],
      [-4, 34],
      [-12, 82],
      [-17, 204],
      [-24, 177],
      [14, 53],
      [31, 125],
      [32, 137],
      [19, 82],
      [8, 111],
      [4, 39],
      [-18, 177],
      [-8, 109],
      [-7, -6],
      [22, 87],
      [4, 15],
      [3, 164],
      [-35, 88],
      [-22, 62],
      [-34, 104],
      [-32, 100],
      [-5, 6],
      [-58, 69],
      [-3, 164],
      [-30, 156],
      [-12, 29],
      [-67, 162],
      [-65, 150],
      [14, 115],
      [4, 35],
      [-141, 157],
      [-5, 5],
      [-127, 291],
      [-3, 5],
      [-138, 183],
      [-73, 128],
      [-55, 95],
      [-62, 257],
      [-16, 224],
      [0, 51],
      [2, 119],
      [2, 115],
      [40, 116],
      [45, 35],
      [61, 48],
      [79, 55],
      [20, 14],
      [113, 21],
      [84, 16],
      [131, -39],
      [188, -72],
      [83, -37],
      [112, -49],
      [189, -72],
      [155, -148],
      [145, -162],
      [153, -195],
      [129, -220],
      [24, -36],
      [111, -168],
      [133, -187],
      [22, -30],
      [203, -217],
      [208, -81],
      [114, 56],
      [125, 61],
      [60, 110],
      [2, 4],
      [44, 85],
      [32, 123],
      [-22, 95],
      [-6, 50],
      [-18, 141],
      [-34, 81],
      [-23, 55],
      [-6, 41],
      [-10, 75],
      [9, 226],
      [142, 171],
      [212, 42],
      [328, 152],
      [14, 46],
      [18, 57],
      [12, 136],
      [-65, 143],
      [-77, 75],
      [-7, 7],
      [-11, 16],
      [-52, 78],
      [-139, 41],
      [-75, 102],
      [-9, 13],
      [-77, 82],
      [-85, 115],
      [-18, 218],
      [-12, 125],
      [-4, 46],
      [-25, 225],
      [-1, 73],
    ],
    [
      [93732, 34932],
      [453, 66],
      [242, -80],
      [41, -7],
      [380, -73],
      [563, 17],
      [262, -26],
      [153, -108],
      [231, -224],
      [190, -210],
      [214, -94],
      [418, 91],
      [328, 172],
      [257, 247],
      [339, 255],
      [353, 261],
      [428, 303],
      [467, 50],
      [297, -73],
      [247, -140],
      [329, 75],
      [160, 260],
      [333, 196],
      [190, 38],
      [550, 228],
      [856, 180],
      [261, 73],
      [151, 267],
      [-145, 247],
      [-230, 49],
      [-391, 39],
      [-203, 138],
      [-57, 134],
      [34, 196],
      [253, 168],
      [402, 96],
      [465, 125],
      [160, 111],
      [177, 11],
      [456, -18],
      [224, 39],
      [385, 143],
      [376, -14],
      [184, 29],
      [311, 220],
      [549, 94],
      [357, -188],
      [649, -314],
      [430, -64],
      [496, -125],
    ],
    [
      [83723, 35898],
      [88, -243],
      [53, -193],
      [112, -410],
      [111, -291],
      [87, -230],
      [73, -597],
      [-8, -128],
      [-18, -293],
      [34, -401],
      [-6, -455],
      [-148, -347],
      [-15, -35],
      [-52, -48],
      [-213, -193],
      [-74, -158],
      [101, -277],
      [84, -83],
      [71, -71],
      [226, -241],
      [115, -216],
      [-12, -45],
      [-23, -85],
      [-63, -72],
    ],
    [
      [80832, 12648],
      [-17, 25],
      [-2, 3],
      [-8, 11],
      [-2, 4],
      [-145, 214],
      [-105, 185],
      [-284, 311],
      [-391, 353],
      [-259, 222],
      [-257, 331],
      [-466, 321],
      [-454, 239],
      [-419, 313],
      [-434, 197],
      [-314, 121],
      [-342, 95],
      [-380, 54],
      [-50, 7],
      [-443, 22],
      [-444, 62],
      [-241, 65],
      [-388, 149],
      [-227, 79],
      [-408, 143],
      [-417, 0],
      [-256, 18],
    ],
    [
      [73679, 16192],
      [136, 185],
      [153, 330],
      [165, 242],
      [158, 221],
      [172, 215],
      [137, 201],
      [-46, 68],
      [-91, 145],
      [-73, 123],
      [-38, 89],
      [-137, 274],
      [-25, 123],
      [-198, 274],
      [-32, 62],
      [-144, 213],
      [-191, 268],
      [-156, 342],
      [-90, 239],
      [16, 197],
      [11, 285],
      [6, 325],
      [150, 175],
      [44, 169],
      [76, 175],
      [-123, 294],
      [42, 87],
      [110, 128],
      [-135, 382],
      [-170, 315],
      [-45, 130],
      [-90, 259],
      [-132, 186],
      [-78, 151],
      [-65, 157],
      [-77, 205],
      [63, 148],
      [-2, 101],
      [-88, 0],
      [-1, 6],
      [10, 172],
      [9, 184],
      [74, 396],
      [10, 105],
      [5, 48],
      [8, 85],
      [107, 117],
      [200, 218],
      [84, 91],
      [209, 191],
      [216, 198],
      [20, 18],
      [228, 322],
      [7, 129],
      [4, 61],
      [-8, 18],
      [-77, 178],
      [-51, 125],
      [-7, 17],
      [-48, 116],
      [6, 18],
      [1, 4],
      [6, 19],
      [55, 6],
    ],
    [
      [61777, 59951],
      [-12, -5],
      [-790, -212],
      [-628, -149],
      [-947, -159],
      [-699, 204],
      [-873, -29],
      [-940, -152],
      [-555, -87],
      [-473, -128],
      [-844, -308],
      [-608, -60],
      [-420, -11],
      [-273, 236],
      [-287, 337],
      [-287, 399],
      [-567, 546],
      [-306, 221],
      [-582, -26],
      [-575, -94],
      [-473, -134],
      [-520, -74],
      [-374, 188],
      [-201, 86],
      [-344, 194],
      [-333, 78],
      [-263, -23],
      [-367, 3],
      [-307, 58],
      [-333, 24],
      [-338, 228],
      [-551, 321],
      [-235, 86],
      [-427, -93],
      [-408, -18],
    ],
    [
      [45637, 61398],
      [787, 845],
      [404, 691],
      [262, 452],
      [1318, 477],
      [686, -136],
      [929, -86],
      [844, 417],
      [287, 779],
      [127, 981],
      [-561, 1022],
      [-201, 1012],
      [-88, 1054],
      [89, 1123],
      [575, 748],
      [342, 568],
      [98, 797],
      [133, 594],
      [-20, 498],
    ],
    [
      [51648, 73234],
      [167, 102],
      [486, 60],
      [447, 106],
      [613, 224],
      [294, -37],
      [557, 5],
      [529, -103],
      [574, -178],
      [612, -218],
      [339, -78],
      [657, 524],
      [291, 200],
      [199, 125],
      [375, -337],
      [261, -521],
      [400, -479],
      [207, -447],
      [160, -557],
      [99, -550],
      [119, -557],
      [309, -528],
      [375, -262],
      [431, -540],
      [220, -515],
      [153, -489],
      [19, -626],
      [-51, -450],
      [117, -1020],
      [55, -1061],
      [18, -524],
      [180, -563],
      [235, -597],
      [140, -502],
      [114, -816],
      [126, -516],
      [93, -510],
      [132, -488],
      [77, -560],
    ],
    [
      [92294, 27634],
      [4, -118],
      [10, -281],
      [-35, -395],
      [-21, -704],
      [12, -436],
      [5, -184],
      [-19, -458],
      [-11, -87],
      [-37, -295],
      [-30, -676],
      [10, -146],
      [7, -97],
      [7, -96],
      [-38, 1],
      [53, -190],
      [2, -60],
      [2, -130],
      [13, 0],
      [5, 0],
      [-6, -42],
      [-2, -12],
      [-12, -55],
      [90, -280],
      [178, -13339],
      [8, -21],
      [5, -13],
      [6, -56],
      [11, -108],
      [-26, -40],
      [-8, -14],
      [-144, -187],
      [-2, -8],
      [-27, -157],
      [-8, -52],
      [-1, -58],
      [-1, -44],
      [-2, -89],
      [446, -1130],
      [327, -827],
      [146, -370],
      [265, -98],
      [3, -1],
      [68, -20],
      [25, -7],
      [61, -17],
      [64, -20],
      [115, -37],
      [68, -22],
      [80, 17],
      [184, 40],
      [316, 1],
      [280, 42],
      [-21, -243],
      [-91, -215],
      [-7, -15],
      [-14, -49],
      [-93, -330],
      [-105, -151],
      [-53, -77],
      [-46, -149],
      [-96, -311],
      [-186, -107],
      [-99, -58],
      [-171, -135],
      [-47, -38],
      [-211, -112],
      [-5, -2],
      [-69, -30],
      [-100, 63],
      [-75, -27],
      [-292, -164],
      [-197, -119],
      [-230, -98],
      [-106, -298],
      [0, -291],
      [0, -130],
      [27, -146],
      [47, -263],
      [2, -194],
      [1, -85],
      [-79, -16],
      [-137, -28],
      [-132, 186],
      [-51, 35],
      [-282, 195],
      [-36, -50],
      [-53, -71],
      [-91, -195],
      [-4, -11],
      [-121, -266],
      [-124, -188],
      [-118, -222],
      [-115, -288],
      [-11, -30],
      [-17, -120],
      [-51, -354],
      [-46, -342],
      [-15, -119],
      [-106, -291],
      [-56, -142],
      [-55, -67],
      [-103, -100],
      [-90, 37],
      [-170, 70],
      [-199, 3],
      [-306, 5],
      [-86, 52],
      [-367, 221],
      [-500, 583],
      [-9, 10],
      [-317, 339],
      [-449, 545],
      [-283, 419],
      [-314, 543],
      [-323, 420],
      [-284, 338],
      [-212, 250],
      [-39, 47],
      [-336, 420],
      [-291, 404],
      [-45, 64],
      [-210, 168],
      [-129, 103],
      [-384, 367],
      [-244, 317],
      [-84, 112],
      [-2, 3],
      [-226, 203],
      [-320, 175],
      [-429, 247],
      [-364, 209],
      [-177, 120],
      [-169, 117],
      [-27, 21],
      [-259, 201],
      [-75, 355],
      [-174, 479],
      [-116, 351],
      [-45, 134],
      [-135, 342],
      [-74, 271],
      [-79, 296],
      [-166, 588],
      [-148, 383],
      [-97, 253],
      [-94, 457],
      [-44, 105],
      [-66, 155],
      [-192, 187],
      [0, 7],
      [-1, 4],
      [-2, 4],
    ],
    [
      [85007, 30275],
      [313, -144],
      [645, 35],
      [356, 19],
      [209, 12],
      [289, 15],
      [599, 65],
      [410, 44],
      [237, -128],
      [295, -157],
      [251, -60],
      [290, -69],
      [298, 201],
      [369, 243],
      [391, 378],
      [113, 56],
      [125, 63],
      [277, 214],
      [232, 200],
      [55, 211],
      [-29, 238],
      [-50, 176],
      [-24, 50],
      [-34, 71],
      [-48, 251],
      [-56, 209],
      [-7, 208],
      [-2, 91],
      [71, 138],
      [70, 136],
      [26, 72],
      [21, 58],
      [42, 124],
      [54, 162],
      [78, 88],
      [141, 160],
      [174, 180],
      [78, 81],
      [330, 133],
      [419, 81],
      [460, 169],
      [361, 52],
      [270, 82],
      [151, 46],
      [72, 74],
      [138, 145],
      [230, 179],
      [35, 5],
    ],
    [
      [73679, 16192],
      [-128, 10],
      [-328, 60],
      [-241, 92],
      [-315, 101],
      [-424, -7],
      [-390, 0],
      [-498, 16],
      [-356, 33],
      [-573, -65],
      [-553, -99],
      [-365, -82],
      [-382, 61],
      [-250, -84],
      [2, -3],
      [4, -4],
      [-8, -3],
      [-141, -49],
      [-87, -12],
      [-258, -145],
      [-14, 0],
      [-276, -23],
      [-364, -54],
      [-203, -58],
      [-317, -2],
      [-631, 32],
      [-497, 70],
      [-296, 25],
      [-249, -2],
      [-329, 33],
      [-402, 115],
      [-462, 164],
      [-447, 212],
      [-127, 67],
    ],
    [
      [63774, 16591],
      [58, 283],
      [8, 221],
      [4, 105],
      [39, 58],
      [78, 116],
      [-16, 171],
      [-7, 74],
      [42, 105],
      [49, 124],
      [-3, 251],
      [56, 88],
      [68, 57],
      [115, 96],
      [-12, 58],
      [-44, 201],
      [191, 187],
      [-45, 109],
      [35, 67],
      [34, 68],
      [52, 43],
      [99, 83],
      [183, 153],
      [37, 81],
      [33, 74],
      [28, 74],
      [51, 74],
      [101, 148],
      [191, 173],
      [68, 53],
      [109, 107],
      [-22, 120],
      [-9, 50],
      [150, 133],
      [182, 149],
      [111, 91],
      [230, 97],
      [144, 161],
      [75, 100],
      [122, 19],
      [6, 16],
      [71, 172],
      [-31, 157],
      [-51, 157],
      [-94, 226],
      [-17, 41],
      [-186, 146],
      [-31, 47],
      [-152, 227],
      [-24, 30],
      [-102, 129],
      [-150, 103],
      [-30, 21],
    ],
    [
      [58202, 43736],
      [34, -67],
      [197, -120],
      [307, -317],
      [442, -192],
      [313, -255],
      [449, -335],
      [512, -389],
      [400, -309],
      [352, -323],
      [246, -521],
      [397, -608],
      [94, -400],
      [280, -487],
      [106, -326],
      [301, -527],
      [285, -425],
      [256, -250],
      [125, -175],
      [309, -119],
      [250, 152],
      [195, 220],
      [133, 240],
      [129, 69],
      [163, 29],
      [183, 117],
      [288, -112],
      [186, -100],
      [273, -147],
      [172, -168],
      [240, -235],
      [299, -18],
      [423, 93],
    ],
    [
      [66541, 37731],
      [-39, -374],
      [-42, -303],
      [-9, -65],
      [-51, -259],
      [-167, -342],
      [-167, -281],
      [-99, -266],
      [-32, -150],
      [-79, -219],
      [-96, -332],
      [-75, -261],
      [-48, -176],
      [-4, -15],
      [-141, -206],
      [-132, -389],
      [44, -278],
      [-52, -236],
      [-26, -119],
      [-25, -171],
      [-125, -429],
      [-68, -137],
      [-78, -158],
      [-132, -382],
      [-180, -396],
      [-98, -321],
      [-28, -127],
      [-24, -112],
      [-29, -222],
      [-16, -112],
      [-84, -368],
      [-57, -375],
      [-10, -106],
      [-27, -275],
      [16, -245],
      [57, -278],
      [101, -475],
      [5, -551],
      [-85, -341],
      [-68, -244],
      [-30, -104],
      [-111, -436],
      [-87, -287],
      [-77, -253],
      [-106, -252],
      [-46, -217],
      [-38, -179],
      [-25, -134],
      [-27, -145],
      [-27, -198],
      [-23, -170],
      [-58, -341],
      [0, -6],
      [19, -398],
      [-11, -38],
      [-8, -29],
    ],
    [
      [55005, 27539],
      [-70, 112],
      [-5, 7],
      [-57, 91],
      [-134, 325],
      [-1, 118],
      [-3, 208],
      [-1, 48],
      [2, 33],
      [9, 254],
      [5, 114],
      [18, 67],
      [47, 171],
      [71, 260],
      [130, 464],
      [46, 708],
      [27, 255],
      [1, 7],
      [1, 11],
      [13, 129],
      [-6, 85],
      [-1, 14],
      [-1, 22],
      [-17, 233],
      [-47, 154],
      [-28, 92],
      [-18, 59],
      [-297, 323],
      [-160, 61],
      [-101, 38],
      [-61, 38],
      [-181, 113],
      [-93, 58],
      [-85, 52],
      [-182, 121],
      [-55, 37],
      [-31, 20],
      [-95, 63],
      [-28, 24],
      [-30, 25],
      [-83, 70],
      [-104, 89],
      [-306, 320],
      [-29, 30],
      [-42, 53],
      [-177, 224],
      [-395, 541],
      [-118, 161],
      [35, 223],
      [14, 89],
      [23, 144],
      [2, 12],
      [49, 241],
      [174, 326],
      [33, 64],
      [6, 8],
      [333, 492],
      [10, 14],
      [-6, 111],
      [-11, 256],
      [-50, 346],
      [-173, 253],
      [-53, 78],
      [-125, 124],
      [-257, 253],
      [-220, 148],
      [-386, 260],
      [-125, 75],
      [-61, 37],
      [-181, 109],
      [-44, 46],
      [-243, 257],
      [-39, 41],
      [-82, 86],
      [-336, 351],
      [-154, 193],
      [-80, 99],
      [-53, 65],
      [-56, 42],
      [-63, 47],
      [-187, 139],
      [-253, 181],
      [-9, 6],
      [-10, 8],
      [-27, 19],
      [-188, 68],
      [-272, 98],
      [-11, 7],
    ],
    [
      [49257, 39457],
      [45, 177],
      [-128, 407],
      [46, 232],
      [-94, 509],
      [32, 395],
      [130, 757],
      [180, 397],
      [102, 681],
      [-17, 545],
      [89, 627],
      [141, 206],
      [235, 186],
      [338, 58],
      [426, 65],
      [642, 204],
      [1076, 194],
      [477, 32],
      [900, 275],
      [479, 175],
      [588, 163],
      [366, -37],
      [503, -287],
      [348, -377],
      [774, -386],
      [83, -108],
      [354, -316],
      [395, -356],
      [347, -248],
      [102, 56],
      [-14, 53],
    ],
    [
      [45637, 61398],
      [-39, -41],
      [-227, -261],
      [-1034, -787],
      [-755, -552],
      [-1256, -332],
      [-1186, -380],
      [-784, -314],
      [-1317, -776],
      [-43, -314],
      [-157, -410],
      [-125, -913],
      [-71, -817],
      [-115, -1083],
      [-8, -960],
      [-57, -722],
      [-408, -569],
      [-750, -198],
      [-710, 299],
      [-562, 484],
      [-778, 414],
      [-410, -79],
      [-367, -221],
      [-679, -442],
      [-663, -517],
      [-532, -578],
      [-878, -804],
      [-434, -644],
      [-231, -548],
      [-363, -772],
      [-223, -547],
      [-48, -375],
      [-48, -507],
    ],
    [
      [28239, 56359],
      [288, -120],
      [468, -43],
      [606, -62],
      [618, -49],
      [339, -64],
      [263, 227],
      [77, 674],
      [42, 675],
      [38, 858],
      [80, 552],
      [325, 242],
      [636, 128],
      [582, 81],
      [738, 239],
      [518, 216],
      [695, 272],
      [535, 32],
      [537, -28],
      [520, 120],
      [592, 299],
      [651, 442],
      [269, 316],
      [254, 356],
      [310, 330],
      [130, 723],
      [-196, 584],
      [-155, 563],
      [-538, 853],
      [-341, 575],
      [-427, 452],
      [-422, 479],
      [-418, 677],
      [-174, 655],
      [212, 645],
      [-5, 720],
      [-99, 835],
      [19, 807],
      [88, 692],
      [-86, 842],
      [104, 620],
      [424, 351],
      [295, 133],
      [436, 68],
      [428, 28],
      [451, -11],
      [375, -154],
      [563, -184],
      [536, 73],
      [431, 240],
      [595, 356],
      [831, 396],
      [599, 251],
      [856, 144],
      [882, 298],
    ],
    [
      [43614, 74763],
      [129, 5],
      [440, 25],
      [97, -81],
      [244, -181],
      [481, -23],
      [464, 202],
      [330, -44],
      [319, -147],
      [236, -120],
      [619, -416],
      [477, -146],
      [307, 85],
      [250, -113],
      [281, 9],
      [175, 213],
      [298, 235],
      [42, 306],
      [112, 274],
      [202, 192],
      [285, -167],
      [299, -215],
      [272, 139],
      [198, 430],
      [307, 112],
      [478, 155],
      [480, 143],
    ],
    [
      [51436, 75635],
      [-47, -493],
      [-26, -497],
      [168, -563],
      [109, -625],
      [8, -223],
    ],
    [
      [75259, 78431],
      [-27, -75],
      [14, -143],
      [-241, -342],
      [-395, -841],
      [-395, -549],
      [-388, -582],
      [48, -919],
      [-220, -560],
      [-14, -776],
      [363, -567],
      [315, -554],
      [67, -693],
      [-352, -943],
      [42, -993],
      [74, -802],
      [-382, -501],
      [-485, -345],
      [-154, -1090],
      [29, -185],
    ],
    [
      [73158, 66971],
      [-399, -139],
      [-992, -378],
      [-410, -439],
      [-123, -655],
      [-75, -831],
      [-39, -1122],
      [211, -903],
      [80, -740],
      [121, -768],
      [-434, -862],
      [-295, -1248],
      [-94, -946],
      [-106, -947],
      [-7, 0],
      [117, -550],
      [-12, -674],
      [-97, -381],
      [-240, -486],
      [-186, -258],
    ],
    [
      [70178, 54644],
      [-70, 71],
      [-361, 446],
      [-373, 349],
      [-334, 337],
      [-477, 437],
      [-613, 497],
      [-511, 355],
      [-590, 143],
      [-490, 369],
      [-565, 415],
      [-714, 367],
      [-436, 417],
      [-511, 375],
      [-217, 114],
      [-204, 65],
      [-163, 87],
      [-585, 225],
      [-665, 211],
      [-324, 113],
      [-198, -86],
    ],
    [
      [51436, 75635],
      [51, 15],
      [36, 11],
      [81, 187],
      [52, 86],
      [28, 98],
      [70, 162],
      [6, 103],
      [128, 443],
      [-144, 613],
      [-69, 481],
      [-92, 1061],
      [39, 139],
      [201, 387],
      [38, 280],
      [156, 376],
      [228, 1050],
      [216, 888],
      [150, 290],
      [570, 540],
      [229, 217],
      [21, 10],
      [417, 374],
      [136, 75],
    ],
    [
      [53984, 83521],
      [57, -742],
      [83, -802],
      [331, -406],
      [443, -179],
      [53, 82],
      [268, -11],
      [743, -40],
      [713, -14],
      [616, 41],
      [138, 42],
      [433, -23],
      [689, 96],
      [629, 27],
      [743, 123],
      [771, 321],
      [693, 306],
      [606, 218],
      [693, 361],
      [357, 214],
      [481, 162],
      [856, 315],
      [568, 257],
      [242, 84],
      [430, 148],
      [577, 74],
      [475, -2],
      [798, 124],
      [287, 445],
      [314, 588],
      [418, 249],
      [511, 475],
      [281, 411],
      [398, 161],
      [488, -63],
      [387, -105],
      [640, -516],
      [62, -49],
      [321, -467],
      [377, -547],
      [308, -379],
      [492, -628],
      [611, -1042],
      [509, -975],
      [672, -1103],
      [387, -1003],
      [359, -923],
      [-33, -395],
    ],
    [
      [70178, 54644],
      [-155, -215],
      [50, -748],
      [-240, -860],
      [-389, -1024],
      [-504, -618],
      [-73, -142],
    ],
    [
      [68867, 51037],
      [-462, -170],
      [-448, -141],
      [-312, -139],
      [-338, -296],
      [-215, -220],
      [-275, -398],
      [-308, -459],
      [-220, -302],
      [-207, -410],
      [-126, -273],
      [-294, -466],
      [-112, -267],
      [-184, -539],
      [-136, -525],
      [-368, -603],
      [-50, -320],
      [-152, -369],
      [-174, -288],
      [-284, -214],
      [-435, -106],
      [-378, 213],
      [-508, 267],
      [-425, 118],
      [-571, 157],
      [-568, -61],
      [-250, -23],
      [-270, -37],
      [-351, -106],
      [-569, -67],
      [-568, -33],
      [-419, -46],
      [-562, 28],
      [-651, 109],
      [88, -40],
      [137, -155],
      [22, -224],
      [77, -367],
      [131, -250],
      [70, -279],
    ],
    [
      [49257, 39457],
      [-60, -241],
      [-2, -6],
      [-14, -54],
      [-14, -39],
      [-4, -13],
      [-168, -460],
      [-510, -366],
      [-100, -58],
      [-214, -123],
      [-383, -221],
      [-47, -6],
      [-13, -1],
      [-677, -300],
      [-3, -1],
      [-82, -34],
      [-472, -195],
      [-459, -180],
      [-149, -59],
      [-297, -117],
      [-231, -54],
      [-98, -23],
      [-313, -72],
      [-817, -247],
      [-459, -213],
      [-104, -48],
      [-307, -142],
      [-547, -402],
      [-119, -88],
      [-108, -74],
      [-619, -430],
      [-469, -223],
      [-172, -83],
      [-111, -48],
      [-699, -300],
      [-394, -172],
      [-50, -21],
      [-453, -197],
      [-212, -66],
      [-48, -15],
      [-193, -60],
      [-1036, 24],
      [-916, 222],
      [-829, 319],
      [-470, 458],
      [-30, 71],
      [-170, 403],
      [-38, 411],
      [-8, 89],
      [-6, 65],
      [42, 409],
      [21, 204],
      [30, 127],
      [120, 494],
      [191, 744],
      [-81, 428],
      [-2, 13],
      [-58, 306],
      [-89, 346],
      [-2, 7],
      [-98, 381],
      [-538, 341],
      [-640, -115],
      [-880, -91],
      [-531, 417],
      [-512, 355],
      [-488, 628],
      [-367, 418],
      [-110, -17],
      [-141, -23],
      [-163, -26],
      [-605, -1040],
      [-94, -291],
      [-73, -228],
      [-19, -62],
      [-167, -639],
      [-56, -213],
      [-1, -31],
      [-8, -208],
      [-23, -571],
      [-105, -453],
      [-28, -120],
      [-398, -753],
      [-4, -9],
      [-63, -125],
      [-219, -440],
    ],
    [
      [29404, 36230],
      [-6, 25],
      [-4, 15],
      [-342, 303],
    ],
    [
      [66541, 37731],
      [4, 1],
      [249, 227],
      [365, 180],
      [8, 7],
    ],
    [
      [63774, 16591],
      [-113, 60],
      [-86, 216],
      [-31, 78],
      [-20, 26],
      [-303, 387],
      [7, -13],
      [-68, 53],
      [-65, 51],
      [-97, 78],
      [-103, 81],
      [-86, 57],
      [-194, 98],
      [-1044, 779],
      [-266, 175],
      [-286, 195],
      [-213, 161],
      [-26, 20],
      [-481, 257],
      [-387, 207],
      [-102, 46],
      [-332, 151],
      [-473, 314],
      [-259, 188],
      [-287, 206],
      [-53, 38],
      [-35, 17],
      [-118, 54],
      [-233, 156],
      [-47, 32],
      [-59, 40],
      [-294, 197],
      [-423, 271],
      [-92, 59],
      [-31, 19],
      [-231, 177],
      [-168, 129],
      [-393, 245],
      [-527, 268],
      [-306, 249],
      [-178, 123],
      [-34, 24],
      [-299, -131],
      [-159, 132],
      [-147, 98],
      [-146, 97],
      [-377, -14],
      [-228, 65],
      [-181, 51],
      [-258, 167],
      [-261, 169],
      [-350, 26],
      [-333, 114],
      [-229, 79],
      [-141, -73],
      [-124, -65],
      [-354, 144],
      [-307, 125],
      [-130, 15],
      [-159, 18],
      [-297, -56],
      [-165, -28],
      [-58, -9],
      [-50, -24],
      [-478, -225],
      [-255, -33],
      [-123, -15],
      [-83, -31],
      [-357, -132],
      [-229, 62],
      [-180, 47],
      [-79, 83],
      [-128, 23],
      [-248, 45],
      [-194, 71],
      [-5, 6],
      [-94, 98],
      [-234, 99],
      [-85, 172],
      [-2, 4],
      [-31, 63],
      [-28, 55],
      [11, 0],
      [7, 0],
      [-151, 183],
      [-59, 70],
      [-108, 129],
      [-95, 130],
      [-248, 337],
      [-83, 112],
      [-313, 387],
      [-256, 181],
      [-17, 12],
      [-128, 91],
      [-150, 106],
      [-262, 331],
      [-193, 244],
      [-4, 5],
      [-180, 235],
      [-26, 33],
      [-256, 335],
      [-70, 55],
      [-415, 325],
      [-182, 143],
      [-931, 405],
      [-61, 28],
      [-788, 357],
      [-571, 321],
      [-639, 313],
      [-59, 1],
      [-353, 7],
      [-58, 1],
      [-158, -24],
      [-23, -3],
      [-142, -22],
      [-261, -39],
      [-36, 0],
      [-14, 0],
      [-32, 0],
      [-132, -59],
      [-232, -102],
      [-41, -17],
      [-131, -56],
      [-334, -143],
      [-263, -110],
      [-203, -85],
      [-303, -139],
      [-268, -97],
      [-250, -92],
      [-117, -42],
      [-91, -36],
      [-338, -133],
      [-104, -40],
      [-285, -86],
      [-178, -54],
      [-145, -44],
      [-322, -119],
      [-151, -55],
      [-64, -27],
      [-359, -149],
      [-192, -34],
      [-84, -16],
      [-317, -47],
      [-102, 28],
      [-111, 29],
      [-139, 38],
      [-203, 115],
      [-70, 40],
      [-69, 39],
      [-300, 236],
      [-238, 187],
      [-10, 8],
      [-84, 353],
      [-8, 76],
      [-50, 468],
      [-63, 565],
      [-23, 209],
      [-9, 83],
      [-5, 75],
      [-6, 87],
      [-6, 87],
      [-14, 213],
      [-19, 92],
      [-12, 54],
      [-12, 54],
      [-34, 160],
      [-73, 279],
      [-19, 77],
      [-61, 235],
      [-54, 79],
      [-19, 28],
      [-201, 299],
      [-270, 139],
      [-125, 65],
      [-109, 56],
      [-285, 10],
      [-167, 5],
      [-333, 12],
      [-244, 59],
      [-208, 51],
      [-193, 46],
      [-105, 63],
      [-327, 194],
      [-119, 71],
      [-490, 404],
      [-226, 197],
      [-56, 49],
      [-189, 165],
      [-303, 471],
      [-6, 8],
      [-31, 126],
      [-135, 540],
      [-7, 398],
      [0, 32],
      [-3, 196],
      [8, 95],
      [4, 42],
      [19, 237],
      [69, 620],
      [-139, 521],
      [-15, 56],
      [-40, 150],
    ],
    [
      [97161, 55113],
      [538, -530],
      [969, -442],
      [761, -337],
      [736, -200],
      [671, -195],
      [230, 23],
      [838, 93],
      [1092, 7],
      [804, -91],
      [1020, -47],
      [852, 40],
      [550, 62],
      [89, 10],
      [516, -58],
      [69, 1],
      [317, -40],
      [328, -277],
      [153, -477],
      [-42, -731],
      [129, -640],
      [-10, -1017],
      [222, -879],
      [214, -952],
      [46, -715],
      [0, -744],
      [-122, -435],
      [-174, -281],
      [-814, -179],
      [-706, -14],
      [-418, -199],
      [-3, -4],
    ],
    [
      [106016, 45865],
      [-23, 4],
      [-386, 25],
      [-255, 53],
      [-315, -84],
      [-434, 32],
      [-192, -35],
      [-283, 46],
      [-233, -63],
      [-357, -43],
      [-347, -173],
      [-345, -316],
      [10, -184],
      [40, -348],
      [118, -442],
      [362, -196],
      [317, -87],
      [339, -107],
      [153, -102],
      [51, -129],
      [31, -184],
      [-30, -260],
      [-85, -225],
      [33, -467],
      [-117, -314],
      [-441, -509],
      [-572, -115],
      [-219, -43],
      [-345, 94],
      [-477, 93],
      [-478, 208],
      [-246, 333],
      [-220, 333],
      [-587, 168],
      [-202, -234],
      [-9, -286],
      [-160, -267],
      [-145, -383],
      [-42, -315],
      [-38, -198],
      [-59, -198],
      [-133, -233],
      [-126, -287],
      [-257, -199],
      [-138, -377],
      [-133, -253],
      [-300, -172],
      [-145, 40],
      [-231, 217],
      [-299, 210],
      [-363, -84],
      [-24, -225],
      [-37, -246],
      [-214, -309],
      [-301, -97],
      [-475, 32],
      [-586, 44],
      [-755, -173],
      [-460, -101],
      [-610, 297],
      [-124, 421],
    ],
    [
      [94117, 39452],
      [-14, 46],
      [-31, 135],
      [27, 510],
      [-88, 733],
      [-81, 1269],
      [-136, 996],
      [81, 701],
      [215, 892],
      [194, 282],
      [334, 85],
      [303, 147],
      [-46, 414],
      [-110, 603],
      [-270, 832],
      [-16, 712],
      [54, 612],
      [-18, 605],
      [-117, 963],
      [67, 537],
      [61, 14],
      [132, 349],
      [286, 561],
      [9, 292],
      [-267, 99],
      [-422, -100],
      [-296, 193],
      [-182, 277],
      [-203, 358],
      [31, 225],
      [210, 90],
      [571, 197],
      [367, 161],
      [-3, 218],
      [-23, 217],
      [92, 266],
      [132, 301],
      [174, 267],
      [465, 468],
      [360, 120],
      [356, -64],
      [580, 27],
      [266, 51],
    ],
    [
      [106016, 45865],
      [-134, -296],
      [83, -431],
      [531, -361],
      [519, -185],
      [808, -186],
      [377, -273],
      [47, -272],
      [-124, -498],
      [-201, -234],
      [-114, -423],
      [536, -376],
      [499, -153],
      [379, -235],
      [232, -352],
      [39, -537],
      [-439, -569],
      [-317, -214],
      [-429, -372],
      [-268, -343],
      [-126, -539],
      [-157, -778],
      [-370, -412],
      [-80, -34],
    ],
    [
      [88680, 39642],
      [15, 9],
      [220, -188],
      [274, -57],
      [404, -76],
      [443, -144],
      [246, -221],
      [327, 39],
      [424, 352],
      [424, 345],
      [740, 91],
      [340, 4],
      [217, 71],
      [301, -23],
      [261, -17],
      [241, -119],
      [187, -140],
      [175, -134],
      [137, -18],
      [61, 36],
    ],
    [
      [75514, 49983],
      [-179, -231],
      [-139, -315],
      [-422, -596],
      [-330, -317],
      [-33, -68],
      [-67, -76],
      [-404, -385],
      [-792, -260],
      [-1114, -19],
      [-1098, 97],
      [-1016, -59],
      [-570, 266],
      [-173, 216],
      [-208, 222],
      [-254, 141],
      [-121, 386],
      [63, 334],
      [141, 233],
      [-171, 495],
      [-13, 496],
      [253, 494],
    ],
    [
      [73158, 66971],
      [71, -460],
      [525, -498],
      [910, -446],
      [345, -812],
      [231, -848],
      [673, -279],
      [663, 416],
      [698, 865],
      [622, 966],
      [637, 761],
      [578, 714],
      [724, 443],
      [689, 552],
      [641, 571],
      [485, 434],
      [94, 117],
      [671, 354],
      [487, 352],
      [985, 290],
      [674, 123],
      [697, -61],
      [484, -471],
      [138, -638],
      [104, -185],
    ],
    [
      [85984, 69231],
      [-308, -402],
      [-384, -1004],
      [79, -387],
      [158, -822],
      [-27, -694],
      [6, -510],
      [141, -536],
      [-15, -490],
      [-342, -486],
      [-160, -267],
      [-348, -508],
      [-430, -521],
      [-502, -686],
      [-863, -662],
      [-404, -365],
      [-809, -580],
      [-491, -462],
      [-287, -492],
      [-131, -566],
      [75, -598],
      [4, -401],
      [90, -224],
      [323, -275],
      [959, -119],
      [849, 23],
      [668, -218],
      [631, -503],
      [310, -466],
      [435, -628],
      [222, -447],
      [-112, -300],
      [371, -452],
      [116, -645],
      [-98, -382],
      [209, -501],
      [46, -339],
      [-503, -203],
      [-745, -212],
      [-392, -243],
      [-138, -416],
      [92, -394],
      [93, -366],
      [11, -347],
      [-362, -521],
      [-568, -339],
      [-1302, -252],
      [-799, 420],
      [-575, 361],
      [-596, 483],
      [-642, 313],
      [-698, -124],
      [-449, 2],
      [-537, 42],
      [-809, 80],
      [-335, 153],
      [-353, -18],
      [-425, -276],
      [-222, -220],
      [-197, -256],
    ],
    [
      [49948, 92664],
      [65, -29],
      [490, -108],
      [349, -181],
      [444, -567],
      [225, -835],
      [107, -940],
      [5, -700],
      [125, -584],
      [382, -595],
      [599, -423],
      [616, -287],
      [552, -451],
      [222, -835],
      [-29, -1049],
      [-142, -1213],
      [24, -308],
      [2, -38],
    ],
    [
      [43614, 74763],
      [54, 18],
      [474, 699],
      [30, 974],
      [-548, 641],
      [-694, 386],
      [-597, 380],
      [-232, 683],
      [296, 689],
      [611, 473],
      [418, 212],
      [117, 501],
      [-166, 1023],
      [-44, 804],
      [-164, 660],
      [-154, 601],
      [-366, 345],
      [-146, 32],
      [-84, 19],
      [-186, 41],
      [-387, -105],
      [-225, 432],
      [-271, 515],
      [-522, 93],
      [-836, -28],
      [-1164, 181],
      [-894, 346],
      [-811, 357],
      [-840, 328],
      [-1041, 504],
      [-666, 442],
      [-410, 593],
      [-78, 721],
      [211, 519],
      [437, 454],
      [378, 215],
      [311, 121],
      [359, 139],
      [756, 492],
      [479, 250],
      [766, 241],
      [461, 175],
      [589, 211],
      [271, 132],
      [623, 192],
      [1088, 84],
      [532, 244],
      [925, 198],
      [534, 145],
      [762, 77],
      [643, 190],
      [701, 114],
      [783, 118],
      [503, 123],
      [5, 1],
      [679, 47],
      [569, 74],
      [544, -26],
      [528, 19],
      [639, -8],
      [468, -56],
      [316, -144],
    ],
    [
      [76129, 46036],
      [4, 207],
      [320, 1194],
      [277, 819],
      [-144, 822],
      [-339, 622],
      [-628, 211],
      [-105, 72],
    ],
    [
      [85984, 69231],
      [243, -430],
      [908, 234],
      [1129, 543],
      [697, 286],
      [860, 282],
      [767, 8],
      [1104, -274],
      [301, 466],
      [-318, 1126],
      [309, 311],
      [250, 253],
      [856, -66],
      [688, -108],
      [680, -61],
      [288, -241],
      [227, -903],
      [21, -768],
      [124, -801],
      [-15, -552],
      [653, -67],
      [466, 270],
      [593, 48],
      [761, -209],
      [252, -446],
      [182, -310],
      [499, -1034],
      [-24, -1211],
      [-249, -798],
      [-284, -670],
      [-103, -484],
      [707, -263],
      [1050, -314],
      [515, -205],
      [811, -384],
      [709, -441],
      [-143, -933],
      [-483, -841],
      [-714, -567],
      [-815, -363],
      [-467, -297],
      [-400, -699],
      [-761, -790],
      [-1022, -645],
      [-429, -583],
      [484, -932],
      [270, -255],
    ],
    [
      [104884, 31069],
      [-96, -158],
      [-15, -293],
      [-11, -340],
      [2, -423],
      [48, -251],
      [31, -218],
      [-14, -272],
      [-15, -402],
      [67, -224],
      [34, -293],
      [31, -449],
      [-10, -198],
      [3, -183],
      [12, -41],
    ],
    [
      [104951, 27324],
      [-228, -343],
      [-188, -315],
      [-22, -6],
      [-105, -26],
      [-59, -16],
      [-5, -1],
      [-15, -4],
      [-4, -1],
      [-229, -59],
      [-294, 100],
      [-239, 229],
      [-318, 352],
      [-191, 241],
    ],
    [
      [107322, 33804],
      [-75, -167],
      [-199, -758],
      [-203, -376],
      [26, -89],
      [176, -18],
      [261, 84],
      [385, 119],
      [335, 98],
      [298, -38],
      [187, -39],
      [29, -34],
      [68, -85],
      [-59, -4],
      [-142, -117],
      [-155, -273],
      [-122, -233],
      [-302, -513],
      [-236, -186],
      [-122, -233],
      [-306, -479],
      [-267, -432],
      [-231, -329],
      [-267, -316],
      [-321, -527],
      [-388, -521],
      [-137, -158],
      [-253, -288],
      [-334, -541],
      [-17, -27],
    ],
  ],
  transform: {
    scale: [0.00003431251477375007, 0.00003393971568254238],
    translate: [3.5841752498262585, 8.194405410939412],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Agaie",
            id: "NI001",
            nameAbbr: "Agaie",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.80609317561,
            Shape_Area: 0.1530130298,
          },
        },
        {
          arcs: [[4, 5, 6]],
          type: "Polygon",
          properties: {
            name: "Agwara",
            id: "NI002",
            nameAbbr: "Agwara",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.6149142409,
            Shape_Area: 0.14828589187,
          },
        },
        {
          arcs: [[7, 8]],
          type: "Polygon",
          properties: {
            name: "Bida",
            id: "NI003",
            nameAbbr: "Bida",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.2532351447,
            Shape_Area: 0.00419675196,
          },
        },
        {
          arcs: [[9, -6, 10, 11, 12, 13]],
          type: "Polygon",
          properties: {
            name: "Borgu",
            id: "NI004",
            nameAbbr: "Borgu",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 5.2889326412,
            Shape_Area: 0.85142920751,
          },
        },
        {
          arcs: [[14, 15, 16, 17, 18], [19]],
          type: "Polygon",
          properties: {
            name: "Bosso",
            id: "NI005",
            nameAbbr: "Bosso",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.91386137699,
            Shape_Area: 0.13040554159,
          },
        },
        {
          arcs: [[-20]],
          type: "Polygon",
          properties: {
            name: "Chanchaga",
            id: "NI006",
            nameAbbr: "Chanchaga",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.29985208389,
            Shape_Area: 0.00604964101,
          },
        },
        {
          arcs: [[20, 21]],
          type: "Polygon",
          properties: {
            name: "Edati",
            id: "NI007",
            nameAbbr: "Edati",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.34652299461,
            Shape_Area: 0.06416379245,
          },
        },
        {
          arcs: [[-17, 22, 23, -8, 24, 25, 26]],
          type: "Polygon",
          properties: {
            name: "Gbako",
            id: "NI008",
            nameAbbr: "Gbako",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.92592621883,
            Shape_Area: 0.14791330466,
          },
        },
        {
          arcs: [[27, 28, 29, 30, 31, 32]],
          type: "Polygon",
          properties: {
            name: "Gurara",
            id: "NI009",
            nameAbbr: "Gurara",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.13025065886,
            Shape_Area: 0.09354126564,
          },
        },
        {
          arcs: [[33, -4, 34, 35, -23, -16]],
          type: "Polygon",
          properties: {
            name: "Katcha",
            id: "NI010",
            nameAbbr: "Katcha",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.71012276956,
            Shape_Area: 0.14193502888,
          },
        },
        {
          arcs: [[36, 37, 38]],
          type: "Polygon",
          properties: {
            name: "Kontagora",
            id: "NI011",
            nameAbbr: "Kontagora",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.87465292404,
            Shape_Area: 0.16624591958,
          },
        },
        {
          arcs: [[39, -2, 40, -32]],
          type: "Polygon",
          properties: {
            name: "Lapai",
            id: "NI012",
            nameAbbr: "Lapai",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.31523815341,
            Shape_Area: 0.24893306096,
          },
        },
        {
          arcs: [[[-36, 41, 42, -25, -9, -24]], [[43, 44, -22, 45, 46]]],
          type: "MultiPolygon",
          properties: {
            name: "Lavun",
            id: "NI013",
            nameAbbr: "Lavun",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.84076306559,
            Shape_Area: 0.32382025746,
          },
        },
        {
          arcs: [[-38, 47, -12, 48, 49, 50]],
          type: "Polygon",
          properties: {
            name: "Magama",
            id: "NI014",
            nameAbbr: "Magama",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.27378242678,
            Shape_Area: 0.30956124586,
          },
        },
        {
          arcs: [[51, 52, 53, -39, -51, 54, 55]],
          type: "Polygon",
          properties: {
            name: "Mariga",
            id: "NI015",
            nameAbbr: "Mariga",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.36038223657,
            Shape_Area: 0.47511866053,
          },
        },
        {
          arcs: [[-54, 56, 57, -47, 58, 59, -13, -48, -37]],
          type: "Polygon",
          properties: {
            name: "Mashegu",
            id: "NI016",
            nameAbbr: "Mashegu",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.45549449099,
            Shape_Area: 0.74825704306,
          },
        },
        {
          arcs: [[-46, -21, -45, 60, -26, -43, 61, -59]],
          type: "Polygon",
          properties: {
            name: "Mokwa",
            id: "NI017",
            nameAbbr: "Mokwa",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 5.2718746212,
            Shape_Area: 0.32663017299,
          },
        },
        {
          arcs: [[62, 63, 64]],
          type: "Polygon",
          properties: {
            name: "Muya",
            id: "NI018",
            nameAbbr: "Muya",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.09036077207,
            Shape_Area: 0.18591862389,
          },
        },
        {
          arcs: [[65, -33, -41, -1, -34, -15, 66, -64]],
          type: "Polygon",
          properties: {
            name: "Paikoro",
            id: "NI019",
            nameAbbr: "Paikoro",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.81659053821,
            Shape_Area: 0.1816456192,
          },
        },
        {
          arcs: [[67, -57, -53, 68, 69]],
          type: "Polygon",
          properties: {
            name: "Rafi",
            id: "NI020",
            nameAbbr: "Rafi",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.8120812161,
            Shape_Area: 0.28832490232,
          },
        },
        {
          arcs: [[70, -55, -50, 71]],
          type: "Polygon",
          properties: {
            name: "Rijau",
            id: "NI021",
            nameAbbr: "Rijau",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.32588247649,
            Shape_Area: 0.25053960544,
          },
        },
        {
          arcs: [[-65, -67, -19, 72, -70, 73]],
          type: "Polygon",
          properties: {
            name: "Shiroro",
            id: "NI022",
            nameAbbr: "Shiroro",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.16301002178,
            Shape_Area: 0.43337263005,
          },
        },
        {
          arcs: [[-30, 74, 75]],
          type: "Polygon",
          properties: {
            name: "Suleja",
            id: "NI023",
            nameAbbr: "Suleja",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.44454652626,
            Shape_Area: 0.00999275697,
          },
        },
        {
          arcs: [[76, -75, -29]],
          type: "Polygon",
          properties: {
            name: "Tafa",
            id: "NI024",
            nameAbbr: "Tafa",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.62299150083,
            Shape_Area: 0.01139544196,
          },
        },
        {
          arcs: [[-73, -18, -27, -61, -44, -58, -68]],
          type: "Polygon",
          properties: {
            name: "Wushishi",
            id: "NI025",
            nameAbbr: "Wushishi",
            parent: "Niger",
            parentAbbr: "NI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.15836076126,
            Shape_Area: 0.15474300719,
          },
        },
      ],
    },
  },
};

const kebbi = {
  type: "Topology",
  arcs: [
    [
      [32259, 60485],
      [-160, 33],
      [-451, 35],
      [-408, -38],
      [-355, -11],
      [-178, 59],
      [-186, 127],
      [-268, 154],
      [-284, 241],
      [-404, 132],
      [-444, 35],
      [-436, -52],
      [-476, -74],
      [-492, 35],
      [-242, 215],
      [-389, 57],
      [-805, 494],
      [-307, 426],
      [-42, 60],
      [-7, 41],
      [-77, 101],
      [-237, 378],
      [-87, 299],
      [-64, 176],
      [-88, 183],
    ],
    [
      [25372, 63591],
      [17, 7],
      [154, 205],
      [99, 172],
      [53, 170],
      [70, 280],
      [52, 212],
      [98, 253],
      [152, 301],
      [168, 185],
      [79, 205],
      [302, 310],
      [148, 158],
      [263, 180],
      [325, 181],
      [324, 214],
      [366, 147],
      [258, 72],
      [532, 5],
      [384, -138],
      [343, -166],
      [22, -109],
      [133, -175],
      [10, -47],
    ],
    [
      [29724, 66213],
      [100, -442],
      [71, -707],
      [101, -760],
      [220, -590],
      [354, -411],
      [373, -322],
      [681, -400],
      [529, -232],
      [168, -43],
    ],
    [
      [32321, 62306],
      [-28, -664],
      [-17, -176],
      [-17, -981],
    ],
    [
      [31890, 88461],
      [-209, -284],
      [-379, -616],
      [-505, -870],
      [-578, -619],
      [-666, -614],
      [-638, -660],
      [-297, -643],
      [-489, -591],
      [-596, -694],
      [-292, -520],
      [-395, -944],
      [-257, -594],
      [-439, -346],
      [-308, -316],
      [-50, -43],
    ],
    [
      [25792, 80107],
      [-462, -399],
      [-323, -337],
      [-225, -900],
      [-214, -241],
      [-193, -295],
      [-324, -269],
      [-374, -59],
      [-408, -79],
      [-481, -196],
      [-244, -132],
      [-221, -275],
      [-235, -220],
      [-62, -402],
      [-249, -697],
      [-366, -590],
      [-274, -384],
      [-241, -248],
      [-338, -214],
      [-210, -146],
      [-214, -240],
      [-147, -235],
    ],
    [
      [19987, 73549],
      [-46, -74],
      [-107, -171],
      [-193, -329],
      [-64, -225],
      [-189, -614],
      [-325, -161],
      [-283, -234],
      [-207, -234],
      [-352, -201],
      [-191, 11],
      [-162, -138],
      [-152, -281],
      [-188, -247],
      [-323, -316],
      [-519, -360],
      [-365, -215],
      [-256, -228],
      [-375, -467],
      [-268, -275],
    ],
    [
      [15422, 68790],
      [-221, -329],
      [-299, -507],
      [-178, -369],
      [-69, -389],
      [-100, -185],
      [-329, -350],
      [-297, -194],
      [-766, -315],
      [-599, -62],
      [-384, 118],
      [-334, 37],
      [-303, -282],
      [-103, -376],
      [-26, -109],
      [-16, -292],
      [182, -304],
      [204, -468],
      [6, -789],
      [-170, -124],
    ],
    [
      [11620, 63501],
      [-208, -152],
      [-495, -224],
      [-383, -385],
      [-322, -310],
      [-261, -329],
      [-253, -391],
      [-332, -664],
      [-170, -465],
      [-36, -279],
      [0, -442],
      [-117, -410],
      [-44, -232],
      [36, -142],
      [-47, -113],
    ],
    [
      [8988, 58963],
      [-12, -31],
      [-265, -77],
      [-332, -120],
      [-374, -113],
      [-495, -169],
      [-278, -146],
      [-230, -105],
      [-344, -256],
      [-298, -119],
      [-528, -264],
      [-262, -58],
      [-1094, 193],
      [34, -129],
    ],
    [
      [4510, 57569],
      [-16, 18],
      [-162, 565],
      [-63, 219],
      [-342, 1188],
      [101, 688],
      [1, 9],
      [3, 127],
      [16, 534],
      [7, 63],
      [6, 55],
      [49, 433],
      [6, 49],
      [23, 201],
      [-21, 186],
      [-11, 101],
      [1, 5],
      [82, 288],
      [63, 224],
      [200, 705],
      [14, 48],
      [238, 743],
      [-66, 388],
      [-26, 1101],
      [-1, 24],
      [-21, 432],
      [-110, 906],
      [-2, 17],
      [2, 581],
      [0, 124],
      [-62, 1064],
      [-9, 142],
      [-1, 102],
      [-4, 318],
      [-80, 608],
      [-2, 15],
      [-30, 699],
      [0, 10],
      [-16, 111],
      [-41, 292],
      [34, 481],
      [878, 752],
      [51, 43],
      [391, 335],
      [28, 24],
      [206, 195],
      [13, 13],
      [92, 52],
      [85, 47],
      [220, 220],
      [20, 19],
      [275, 289],
      [65, 64],
      [84, 65],
      [279, 218],
      [472, 392],
      [588, 508],
      [51, 44],
      [600, 501],
      [692, 582],
      [19, 12],
      [151, 98],
      [301, 198],
      [15, 11],
      [57, 45],
      [47, 38],
      [80, 62],
      [623, 479],
      [34, 32],
      [13, 15],
      [18, 19],
      [79, 88],
      [125, 78],
      [80, 47],
      [75, 50],
      [13, 9],
      [108, 80],
      [781, 582],
      [594, 443],
      [5, 3],
      [139, 188],
      [4, 6],
      [193, 167],
      [89, 77],
      [661, 1061],
      [74, 136],
      [612, 1021],
      [52, 86],
      [193, 321],
      [102, 168],
      [79, 132],
      [17, 27],
      [86, 128],
      [347, 426],
      [5, 6],
      [18, 30],
      [35, 61],
      [122, 206],
      [71, 131],
      [104, 183],
      [555, 746],
      [54, 26],
      [85, 133],
      [227, 356],
      [230, 352],
      [756, 1089],
      [159, 631],
      [5, 26],
      [109, 534],
      [234, 1133],
      [71, 308],
      [136, 533],
      [57, 209],
      [54, 349],
      [75, 417],
      [76, 347],
      [1, 6],
      [-7, 193],
      [-32, 918],
      [-1, 15],
      [8, 214],
      [16, 475],
      [0, 81],
      [2206, 57],
      [7, 0],
      [20, 15],
      [385, 11],
      [498, -21],
      [557, -287],
      [321, -57],
      [713, 219],
      [500, 312],
      [427, 154],
      [538, 54],
      [357, -145],
      [245, -413],
      [534, -579],
      [716, -461],
      [784, -386],
      [498, -511],
      [327, 10],
      [826, -425],
      [692, -278],
      [487, -124],
      [650, -156],
      [802, -249],
      [351, -116],
    ],
    [
      [38219, 75762],
      [-118, -783],
    ],
    [
      [38101, 74979],
      [-130, -17],
      [-415, -66],
      [-592, -109],
      [-699, -179],
      [-605, -163],
      [-469, -114],
      [-543, -156],
      [-537, -108],
      [-414, -128],
      [-468, -195],
      [-413, -202],
      [-359, -188],
      [-493, -264],
      [-386, -182],
      [-318, -235],
      [-277, -159],
      [-283, -208],
      [-195, -159],
      [-340, -119],
      [-332, -126],
      [-217, -98],
      [-101, -76],
      [-250, -112],
      [-48, -99],
    ],
    [
      [29217, 71517],
      [-549, -112],
      [-291, -139],
      [-400, -182],
      [-488, -223],
      [-400, -127],
      [-599, -75],
      [-327, -58],
      [-367, -52],
      [-526, 69],
      [-389, -18],
      [-328, 3],
      [-327, -4],
      [-650, 129],
      [-379, -127],
      [-521, 123],
      [-313, -51],
      [-290, 269],
      [-163, 392],
      [10, 280],
      [-109, 420],
      [-161, 250],
      [-239, 508],
      [-408, 342],
      [-304, 268],
      [-650, 129],
      [-62, 18],
    ],
    [
      [25792, 80107],
      [463, -681],
      [575, -571],
      [378, -200],
      [178, -86],
      [1117, 108],
      [550, 196],
      [1152, -408],
      [226, -100],
      [235, -160],
      [175, -304],
      [142, -386],
      [88, -449],
      [682, 8],
      [640, 110],
      [454, -240],
      [418, -125],
      [283, -241],
      [230, -345],
      [397, -118],
      [524, -354],
      [384, -139],
      [463, 33],
      [947, 99],
      [599, 62],
      [839, -25],
      [288, -29],
    ],
    [
      [38276, 80730],
      [46, -76],
      [165, -577],
      [305, -758],
      [-125, -581],
      [-15, -74],
      [54, -898],
      [-194, -730],
      [-238, -907],
      [-55, -367],
    ],
    [
      [31890, 88461],
      [368, -121],
      [731, -508],
      [655, -455],
      [534, -185],
      [909, -132],
      [484, 60],
      [373, 140],
      [296, 262],
      [178, 383],
      [302, 371],
      [193, 254],
      [33, 109],
      [154, 261],
      [32, 54],
      [282, 289],
      [203, 111],
      [341, 31],
      [315, -98],
      [196, -311],
      [50, -203],
      [45, -124],
      [91, -256],
      [-186, -356],
      [-63, -368],
      [-145, -307],
      [-112, -260],
      [-140, -179],
      [-118, -362],
      [5, -340],
      [39, -782],
      [-295, -337],
      [-131, -811],
      [-241, -241],
      [-72, -688],
      [-71, -695],
      [-56, -347],
      [105, -217],
      [42, -88],
      [304, -235],
      [215, -235],
      [208, -229],
      [78, -149],
      [255, -437],
    ],
    [
      [2774, 46481],
      [539, -756],
      [585, 99],
      [402, 318],
      [112, 167],
      [420, -328],
      [202, -157],
      [354, -404],
      [388, -411],
      [320, -411],
      [268, -147],
      [342, -16],
      [346, -405],
      [422, -376],
      [466, -117],
      [528, -205],
      [296, -173],
      [517, -355],
      [186, -147],
      [399, -214],
      [247, -85],
      [133, -61],
    ],
    [
      [10246, 42297],
      [711, -324],
      [300, -10],
      [568, -103],
      [340, 65],
      [336, -111],
      [495, -226],
      [337, -194],
      [381, -383],
      [125, -196],
      [446, -144],
      [570, 135],
      [352, 229],
      [194, 254],
      [52, 130],
      [72, 225],
      [392, 665],
      [646, 592],
      [368, -2],
      [323, -126],
      [412, -206],
      [281, -92],
      [480, 312],
      [482, 604],
      [576, 639],
      [234, 370],
      [460, 223],
      [499, -55],
      [199, -122],
    ],
    [
      [20877, 44446],
      [324, -199],
      [402, -431],
      [225, -487],
      [445, -505],
      [503, -396],
      [367, -322],
      [28, -41],
      [986, -764],
      [1080, -579],
      [505, -491],
      [286, -378],
      [304, -309],
      [260, -447],
      [-11, -653],
      [-428, -583],
      [-382, -447],
      [-354, -487],
      [-169, -539],
      [-230, -609],
      [-701, -518],
      [-590, -184],
      [-364, -317],
      [-400, -610],
      [-22, -354],
      [483, 74],
      [287, -38],
      [383, -91],
      [470, -348],
      [339, -364],
      [212, -58],
      [254, -120],
      [330, -125],
      [493, -110],
      [339, 85],
      [89, 15],
      [371, -200],
      [256, -176],
    ],
    [
      [27247, 32340],
      [-8, -12],
      [-4, -177],
      [-517, -550],
      [-175, -152],
      [-384, -290],
      [-98, -260],
      [-118, -361],
      [-91, -260],
      [-64, -280],
      [-31, -225],
      [-276, -268],
      [-432, -264],
      [-393, -181],
      [-310, -249],
      [-422, -460],
      [-186, -363],
      [-192, -397],
      [-217, -506],
      [-11, -18],
    ],
    [
      [23318, 27067],
      [-187, 50],
      [-806, 65],
      [-785, 26],
      [-429, -53],
      [-1021, -127],
      [-499, 96],
      [-891, -133],
      [-867, -3],
      [-1081, -203],
      [-942, 44],
      [-875, 133],
      [-618, 272],
      [-1022, 396],
      [-777, 406],
      [-1017, 499],
      [-688, 420],
      [-792, 508],
      [-571, 341],
      [-624, 238],
      [-613, 346],
      [-117, 101],
      [-459, 124],
      [-464, 43],
      [-437, 1],
      [-445, -238],
      [25, -6],
      [-125, 6],
      [-247, -1],
      [-235, 1],
      [-1444, 1211],
      [-436, 362],
      [-41, 34],
      [-530, 453],
      [-804, 689],
      [-383, 329],
      [-390, 357],
      [-12, 11],
      [-68, 62],
      [-212, 148],
      [-10, 7],
      [-1379, 1169],
      [135, 826],
      [3, 17],
      [79, 372],
      [108, 505],
      [194, 865],
      [75, 335],
      [159, 711],
      [20, 88],
      [381, 1709],
      [394, 2031],
      [6, 26],
      [16, 78],
      [18, 81],
      [186, 873],
      [125, 585],
      [3, 16],
      [116, 387],
      [19, 60],
      [2, 6],
      [160, 351],
      [196, 427],
      [4, 8],
      [202, 328],
      [33, 53],
      [1, 3],
      [114, 404],
      [25, 85],
    ],
    [
      [29217, 71517],
      [158, 32],
      [371, -186],
      [226, -99],
      [496, -287],
      [248, -161],
      [181, -297],
      [154, -692],
      [231, -358],
      [344, -241],
      [227, -113],
      [461, -294],
      [218, -406],
      [90, -536],
      [-383, -399],
      [-352, -134],
      [-484, -46],
      [-232, -16],
      [-367, -66],
      [-250, -152],
      [-416, -447],
      [-322, -338],
      [-92, -68],
    ],
    [
      [25372, 63591],
      [-363, -127],
      [-427, -148],
      [-204, -63],
      [-54, -21],
      [-61, -28],
      [-169, -98],
      [-189, -110],
      [-239, -92],
    ],
    [
      [23666, 62904],
      [-146, 284],
      [-454, 261],
      [-455, 300],
      [-171, 132],
    ],
    [
      [22440, 63881],
      [-257, 197],
      [-315, 561],
      [-382, 438],
      [-379, 302],
      [-500, 137],
      [-496, -122],
      [-444, 36],
      [-616, 129],
      [-761, 284],
      [-584, 245],
      [-502, 294],
      [-205, 480],
      [-362, 459],
      [-168, 297],
      [-395, 452],
      [-528, 606],
      [-124, 114],
    ],
    [
      [14530, 54190],
      [-327, 455],
      [-227, 555],
      [-273, 487],
      [-325, 336],
      [-318, 248],
      [-365, 234],
      [-512, 35],
      [-1014, 302],
      [-411, 124],
      [-213, 107],
      [-363, 70],
      [-391, 213],
      [-243, 249],
      [-184, 447],
      [-163, 393],
      [-221, 222],
      [8, 296],
    ],
    [
      [11620, 63501],
      [166, -121],
      [492, -1],
      [757, -66],
      [704, -60],
      [601, -81],
      [343, -78],
      [736, -910],
      [277, -712],
      [656, -516],
      [780, -209],
      [548, -163],
      [534, -545],
      [123, -462],
      [311, -785],
      [1, -400],
    ],
    [
      [18649, 58392],
      [0, -83],
      [69, -537],
      [125, -598],
    ],
    [
      [18843, 57174],
      [-135, -83],
      [-392, -222],
      [-418, -297],
      [-512, -421],
      [-389, -413],
      [-540, -401],
      [-567, -367],
      [-515, -257],
      [-276, -201],
      [-569, -322],
    ],
    [
      [14530, 54190],
      [119, -166],
      [128, -318],
      [264, -766],
      [192, -508],
      [149, -897],
      [248, -1051],
      [202, -706],
      [366, -744],
      [193, -603],
      [148, -761],
      [64, -632],
      [-43, -320],
      [8, -537],
      [-40, -511],
      [-2, -347],
      [-103, -409],
      [-116, -390],
      [-212, -472],
      [7, 1],
      [-200, -275],
      [-310, -262],
      [-657, -273],
      [-1032, -331],
      [-1040, -217],
      [-811, -43],
      [-546, 28],
      [-639, -164],
      [-621, -219],
    ],
    [
      [2774, 46481],
      [11, 39],
      [172, 353],
      [76, 154],
      [2, 4],
      [10, 11],
      [133, 137],
      [35, 35],
      [77, 80],
      [20, 20],
      [16, 16],
      [29, 25],
      [305, 240],
      [274, 215],
      [6, 5],
      [39, 31],
      [346, 311],
      [259, 233],
      [392, 315],
      [93, 74],
      [268, 213],
      [8, 149],
      [-12, 45],
      [-222, 869],
      [-7, 142],
      [-155, 326],
      [-118, 80],
      [-332, 227],
      [-11, 7],
      [-212, 137],
      [-242, 157],
      [-212, 230],
      [-12, 14],
      [-120, 384],
      [-3, 11],
      [-33, 413],
      [-5, 52],
      [-31, 429],
      [-42, 571],
      [-31, 416],
      [-1, 8],
      [0, 12],
      [45, 217],
      [4, 21],
      [148, 108],
      [20, 15],
      [9, 6],
      [22, 22],
      [216, 235],
      [59, 65],
      [284, 310],
      [57, 62],
      [415, 432],
      [150, 156],
      [15, 31],
      [48, 104],
      [-28, 402],
      [0, 4],
      [-63, 205],
      [-110, 354],
      [-129, 412],
      [-11, 34],
      [-151, 573],
      [-34, 130],
    ],
    [
      [70149, 44192],
      [121, -145],
      [177, -305],
      [94, -230],
      [-6, -1158],
      [-235, -833],
      [-281, -853],
      [-164, -805],
      [-283, -554],
      [-2, -565],
      [1099, -533],
      [716, -299],
      [721, -441],
      [521, -635],
      [387, -778],
      [263, -814],
      [222, -1829],
      [163, -917],
      [-9, -551],
      [58, -510],
      [55, -156],
      [-8, -709],
      [5, -651],
      [-640, 516],
      [-387, 105],
      [-488, 63],
      [-398, -160],
      [-281, -411],
      [-511, -475],
      [-418, -249],
      [-313, -589],
      [-288, -445],
      [-798, -124],
      [-475, 2],
      [-577, -74],
      [-430, -148],
    ],
    [
      [67759, 27932],
      [-369, 610],
      [-355, 329],
      [-522, 192],
      [-445, -11],
      [-300, 548],
      [-445, 880],
      [-346, 99],
      [-624, 306],
      [-54, 347],
      [89, 654],
      [-146, 788],
      [-117, 632],
      [-335, 296],
      [-533, 192],
      [-535, -25],
      [-393, 43],
      [-474, 9],
      [-765, 490],
      [-584, 368],
      [-467, 364],
      [-609, 246],
      [-540, 239],
      [-639, 355],
      [-520, 233],
      [-681, 429],
      [-278, 216],
      [-144, 151],
      [-307, 395],
      [-584, 533],
      [-90, 82],
      [-246, 120],
      [-193, 691],
      [-77, 124],
    ],
    [
      [55131, 38857],
      [-186, 299],
      [-108, 504],
      [-64, 512],
      [68, 526],
      [207, 539],
      [203, 439],
      [232, 554],
      [168, 378],
      [299, 490],
      [281, 510],
      [171, 445],
      [191, 459],
      [300, 523],
      [238, 409],
      [222, 314],
      [154, 268],
    ],
    [
      [57507, 46026],
      [215, -31],
      [651, -286],
      [298, -328],
      [240, -240],
      [527, -278],
      [626, -29],
      [507, 124],
      [712, 69],
      [731, 194],
      [491, -88],
      [772, -24],
      [524, -124],
      [569, 73],
      [601, 203],
      [576, 380],
      [1034, -153],
      [1246, -138],
      [443, -186],
      [906, -338],
      [700, -307],
      [273, -327],
    ],
    [
      [55304, 47782],
      [-79, -575],
      [-153, -728],
      [-117, -640],
      [-78, -592],
      [-98, -355],
      [-71, -718],
      [-174, -511],
      [-380, -600],
      [-360, -328],
      [-591, -439],
      [-760, -302],
      [-688, 39],
      [-325, 162],
      [-367, 214],
      [-401, 125],
      [-404, -178],
      [-599, -202],
      [-423, 79],
      [-428, 104],
      [-250, 44],
    ],
    [
      [48558, 42381],
      [-104, 18],
      [-350, 105],
      [-290, 255],
      [-216, 419],
      [-202, 317],
      [-279, 467],
      [-272, 507],
      [-83, 590],
      [-254, 678],
      [-263, 793],
      [-32, 193],
    ],
    [
      [46213, 46723],
      [78, -36],
      [616, -231],
      [229, -331],
      [575, -259],
      [1058, 278],
      [746, 172],
      [382, -23],
      [800, 262],
      [141, 627],
      [245, 459],
      [500, 291],
      [373, 73],
      [426, -303],
      [452, -130],
      [532, -14],
      [733, 116],
      [640, 150],
      [328, 36],
      [205, -56],
      [32, -22],
    ],
    [
      [44516, 46698],
      [404, 224],
      [857, 4],
      [436, -203],
    ],
    [
      [48558, 42381],
      [-59, -92],
      [2, -231],
      [16, -178],
      [50, -156],
      [196, -83],
      [140, 34],
      [96, 31],
      [133, 39],
      [96, 51],
      [85, 57],
      [214, 17],
      [286, -51],
      [283, -238],
      [-15, -192],
      [83, -254],
      [-68, -241],
      [-219, -269],
      [-228, -176],
      [-37, -115],
      [-79, -222],
      [75, -267],
      [-240, -441],
      [-112, -402],
      [-111, -349],
      [-107, -548],
      [-123, -488],
      [-10, -483],
      [-136, -398],
    ],
    [
      [48769, 36736],
      [-503, -123],
      [-783, -118],
      [-701, -113],
      [-643, -190],
      [-762, -78],
      [-534, -145],
      [-925, -197],
      [-532, -245],
      [-1088, -84],
      [-623, -191],
      [-271, -133],
      [-589, -211],
      [-461, -175],
      [-765, -240],
      [-480, -251],
      [-756, -492],
      [-359, -139],
    ],
    [
      [37994, 33611],
      [66, 150],
      [195, 206],
      [188, 186],
      [64, 320],
      [30, 259],
      [-58, 251],
      [-38, 299],
      [2, 333],
      [-25, 367],
      [242, 262],
      [91, 266],
      [18, 197],
      [-87, 428],
      [-208, 229],
      [-153, 257],
      [-220, 147],
      [12, 177],
      [-79, 257],
      [-90, 115],
      [-117, 114],
      [-180, 202],
      [-232, 93],
      [-296, 200],
      [-356, 186],
      [-378, 234],
      [-399, 342],
      [-359, 384],
      [-387, 349],
      [-416, 424],
      [-373, 343],
      [-302, 119],
      [-250, 276],
      [-67, -83],
      [-242, 256],
      [-350, 146],
      [-259, -30],
      [-287, 30],
      [-204, -8],
    ],
    [
      [32490, 41894],
      [-225, -10],
      [-265, -126],
      [-373, -133],
      [-292, -44],
      [-199, 31],
      [-143, 60],
      [-92, 224],
      [-46, 271],
      [57, 314],
      [77, 320],
      [34, 111],
    ],
    [
      [31023, 42912],
      [26, -28],
      [324, -221],
      [34, -20],
      [363, -64],
      [465, -76],
      [484, 5],
      [352, 161],
      [450, 475],
      [366, 609],
      [362, 406],
      [403, 392],
      [534, 299],
      [589, 313],
      [835, 268],
      [671, 300],
      [862, 276],
      [635, 354],
      [638, 225],
      [467, 270],
      [419, 250],
      [420, 127],
      [376, -70],
      [322, -119],
      [165, -154],
      [404, -131],
      [568, 258],
      [394, 79],
      [83, -107],
      [121, -414],
      [154, -297],
      [443, -36],
      [654, 395],
      [110, 61],
    ],
    [
      [38101, 74979],
      [-7, -43],
      [21, -953],
      [435, -1240],
      [354, -1343],
      [70, -972],
      [-7, -939],
      [-35, -803],
      [-94, -532],
      [-47, -504],
      [-84, -355],
      [-219, -377],
      [-477, -19],
      [-365, 255],
      [-572, 367],
      [-557, 334],
      [-629, 88],
      [-633, -96],
      [-728, -130],
      [-556, -184],
      [-576, -237],
      [-336, -392],
      [-268, -302],
      [-167, -649],
      [-170, -532],
      [-134, -587],
      [66, -278],
      [-19, -1014],
      [-43, -1185],
      [-3, -51],
    ],
    [
      [32259, 60485],
      [-5, -306],
      [135, -794],
      [-67, -552],
      [5, -803],
      [35, -452],
      [51, -676],
      [173, -638],
      [54, -829],
      [33, -401],
      [-47, -967],
      [-186, -1227],
      [-303, -725],
      [-181, -634],
      [-68, -435],
    ],
    [
      [31888, 51046],
      [-349, 28],
      [-596, 198],
      [-729, 366],
      [-892, 411],
      [-583, 191],
      [-470, 382],
      [-525, 42],
      [-599, -89],
      [-908, 963],
      [-300, 888],
      [-334, 921],
      [296, 221],
      [630, 293],
      [179, 342],
      [-242, 664],
      [-768, 733],
      [4, 626],
      [-280, 929],
      [-473, 627],
      [-227, 583],
      [-329, 561],
      [-290, 663],
      [-90, 598],
      [-226, 481],
      [-121, 236],
    ],
    [
      [22440, 63881],
      [-121, -196],
      [-125, -294],
      [-98, -280],
      [-73, -185],
      [-106, -151],
      [-182, -158],
      [-270, -180],
      [-146, -274],
      [-120, -226],
      [-136, -423],
      [-52, -185],
      [-12, -102],
      [-121, -151],
      [-203, -63],
      [-169, -105],
      [-38, -190],
      [-169, -111],
      [-271, -105],
      [-319, -113],
      [-190, -111],
      [-93, -110],
      [-24, -252],
      [-15, -327],
      [-161, -206],
      [32, -319],
      [-24, -184],
      [-47, -82],
      [-52, -150],
      [-40, -76],
      [-148, -97],
      [-298, -83],
    ],
    [
      [29986, 45372],
      [-49, -235],
      [-87, -539],
      [192, -494],
      [480, -661],
      [501, -531],
    ],
    [
      [32490, 41894],
      [-67, -76],
      [-140, -240],
      [-59, -150],
      [-64, -307],
      [10, -184],
      [87, -326],
      [33, -380],
      [28, -530],
      [148, -332],
      [53, -313],
      [25, -305],
      [-113, -212],
      [-173, -261],
      [-120, -226],
      [-276, -228],
      [-329, -330],
      [-88, -22],
      [-61, -61],
      [-134, -138],
      [-256, -221],
      [-242, -234],
      [-161, -185],
      [-86, -158],
      [-92, -253],
      [-92, -232],
      [-99, -198],
      [-71, -287],
      [-187, -240],
      [80, -319],
      [-50, -320],
      [52, -306],
      [231, -358],
      [-23, -266],
      [114, -332],
      [106, -325],
      [154, -264],
      [166, -236],
      [-50, -293],
      [98, -196],
      [31, -319],
      [38, -306],
      [-59, -103],
      [-20, -88],
      [-5, -123],
      [-65, -131],
    ],
    [
      [30762, 30980],
      [-221, 196],
      [-330, 187],
      [-369, 145],
      [-397, 165],
      [-520, 157],
      [-370, 64],
      [-560, 110],
      [-49, 40],
      [-411, 97],
      [-288, 199],
    ],
    [
      [20877, 44446],
      [21, 16],
      [352, 140],
      [305, 91],
      [298, 161],
      [209, 145],
      [203, 111],
      [326, 126],
      [440, 202],
      [45, 23],
    ],
    [
      [23076, 45461],
      [429, 214],
      [331, 167],
      [450, 33],
      [449, 66],
      [256, 248],
      [181, 186],
      [388, 93],
      [430, -63],
      [272, 23],
      [108, 103],
      [268, -167],
      [250, -255],
      [175, -305],
      [325, -315],
      [430, -2],
      [409, 38],
      [390, -111],
      [499, -35],
      [607, 0],
      [263, -7],
    ],
    [
      [31888, 51046],
      [-94, -608],
      [-169, -962],
      [-470, -944],
      [-356, -847],
      [-403, -849],
      [-338, -1113],
      [-72, -351],
    ],
    [
      [23076, 45461],
      [-11, 19],
      [-50, 149],
      [-30, 129],
      [-29, 101],
      [-67, 394],
      [-45, 694],
      [-13, 891],
      [25, 1089],
      [114, 518],
      [-32, 803],
      [-187, 1113],
      [-125, 999],
      [-222, 1181],
      [-190, 849],
      [-340, 792],
      [-332, 357],
      [-545, 361],
      [-547, 136],
      [-453, 179],
      [-358, 220],
      [-406, 240],
      [-382, 459],
      [-8, 40],
    ],
    [
      [46183, 18742],
      [-882, -297],
      [-856, -145],
      [-599, -250],
      [-831, -397],
      [-595, -356],
      [-431, -239],
      [-536, -73],
      [-563, 184],
      [-375, 153],
      [-451, 11],
      [-428, -28],
      [-436, -67],
      [-295, -134],
      [-424, -351],
      [-104, -620],
      [86, -842],
      [-88, -692],
      [-19, -807],
      [99, -834],
      [5, -721],
      [-212, -645],
      [174, -655],
      [418, -677],
      [422, -479],
      [427, -452],
      [341, -575],
      [538, -853],
      [155, -563],
      [196, -584],
      [-130, -723],
      [-310, -329],
      [-254, -357],
      [-269, -316],
      [-651, -442],
      [-592, -298],
      [-520, -121],
      [-537, 29],
      [-535, -33],
      [-695, -272],
      [-518, -216],
      [-738, -239],
      [-582, -80],
      [-636, -129],
      [-326, -241],
      [-79, -553],
      [-38, -858],
      [-42, -674],
      [-77, -675],
      [-263, -227],
      [-339, 65],
      [-618, 48],
      [-606, 62],
      [-468, 44],
      [-288, 119],
      [-190, 80],
      [-190, 267],
      [-66, 618],
      [53, 459],
      [147, 589],
      [121, 455],
      [218, 500],
      [134, 866],
      [-42, 447],
      [44, 545],
      [-300, 415],
      [-350, 299],
      [-340, 248],
      [-388, 292],
      [-263, 403],
      [-138, 417],
      [-129, 317],
      [25, 543],
      [-78, 618],
      [27, 992],
      [106, 382],
      [8, 443],
      [175, 657],
      [511, 878],
      [412, 496],
      [128, 126],
      [325, 319],
      [344, 255],
      [356, 222],
      [365, 164],
      [414, 139],
      [510, 164],
      [555, 212],
      [358, 269],
      [381, 396],
      [448, 438],
      [563, 524],
      [423, 457],
      [202, 571],
      [176, 961],
      [31, 150],
    ],
    [
      [35894, 17931],
      [94, -43],
      [142, -174],
      [20, -9],
      [170, -219],
      [272, -79],
      [269, -13],
      [110, 74],
      [32, -2],
      [277, 160],
      [53, 87],
      [47, 50],
      [102, 164],
      [17, 258],
      [67, 195],
      [43, 247],
      [-21, 112],
      [7, 541],
      [49, 366],
      [5, 529],
      [47, 597],
      [136, 503],
      [106, 396],
      [127, 164],
      [177, 174],
      [245, 138],
      [294, 225],
      [83, 44],
      [21, 36],
      [25, 19],
      [3, 28],
      [148, 251],
      [80, 506],
      [98, 216],
      [77, 308],
      [27, 200],
      [135, 185],
      [455, 346],
      [369, 236],
      [403, 185],
      [317, 62],
      [131, -7],
      [76, 55],
    ],
    [
      [41229, 25042],
      [203, 144],
      [279, 206],
      [344, 248],
      [266, 198],
      [125, 99],
      [300, 134],
      [737, 604],
      [678, 542],
      [547, 292],
      [106, 141],
      [150, 109],
      [108, 104],
    ],
    [
      [45072, 27863],
      [146, -32],
      [366, -345],
      [154, -601],
      [164, -659],
      [44, -804],
      [166, -1023],
      [-117, -502],
      [-418, -212],
      [-611, -473],
      [-296, -689],
      [232, -682],
      [597, -380],
      [694, -387],
      [548, -641],
      [-30, -974],
      [-474, -699],
      [-54, -18],
    ],
    [
      [67759, 27932],
      [-242, -83],
      [-568, -258],
      [-856, -315],
      [-481, -161],
      [-357, -215],
      [-693, -361],
      [-606, -217],
      [-693, -307],
      [-771, -321],
      [-742, -123],
      [-630, -27],
      [-689, -96],
      [-432, 23],
      [-139, -42],
      [-616, -40],
      [-713, 13],
      [-742, 40],
      [-269, 11],
      [-53, -82],
      [-443, 179],
      [-330, 406],
      [-84, 802],
      [-56, 742],
      [-3, 38],
      [-24, 309],
      [142, 1213],
      [29, 1048],
      [-222, 836],
      [-552, 450],
      [-616, 287],
      [-599, 423],
      [-382, 595],
      [-125, 584],
      [-5, 701],
      [-107, 940],
      [-225, 835],
      [-444, 566],
      [-349, 181],
      [-490, 108],
      [-65, 30],
    ],
    [
      [52517, 36644],
      [272, 251],
      [310, 320],
      [280, 238],
      [299, 352],
      [403, 304],
      [288, 259],
      [478, 301],
      [284, 188],
    ],
    [
      [37994, 33611],
      [-311, -120],
      [-378, -215],
      [-437, -455],
      [-211, -519],
      [78, -720],
      [410, -594],
      [666, -441],
      [1041, -505],
      [840, -328],
      [811, -357],
      [894, -345],
      [1164, -181],
      [836, 28],
      [522, -94],
      [271, -515],
      [225, -432],
      [387, 105],
      [186, -41],
      [84, -19],
    ],
    [
      [41229, 25042],
      [-235, 176],
      [-491, 95],
      [-532, 145],
      [-789, 166],
      [-749, -50],
      [-269, 423],
      [-608, 406],
      [-509, -163],
      [-595, 169],
      [-343, 165],
      [-453, 288],
      [-464, 559],
      [-870, 425],
      [-199, 664],
      [-319, 405],
      [-509, 368],
      [-417, 655],
      [-411, 220],
      [-812, 167],
      [-410, 104],
      [-333, 418],
      [-150, 133],
    ],
    [
      [35894, 17931],
      [11, 58],
      [97, 486],
      [178, 993],
      [234, 858],
      [151, 549],
      [63, 413],
      [62, 909],
      [19, 556],
      [14, 444],
      [-241, 345],
      [-258, 112],
      [-348, 201],
      [-373, 81],
      [-378, 107],
      [-298, 38],
      [-446, -84],
      [-255, 221],
      [-357, 234],
      [-487, 300],
      [-378, 288],
      [-556, 314],
      [-639, 461],
      [-508, 280],
      [-331, 296],
      [-446, 287],
      [-184, 39],
      [-629, 258],
      [-677, 182],
      [-581, 170],
      [-397, 145],
      [-578, 14],
      [-803, -50],
      [-557, -75],
      [-564, -108],
      [-291, -106],
      [-498, -77],
      [-210, -33],
      [-982, -11],
      [-155, 41],
    ],
    [
      [56756, 46050],
      [256, 2],
      [96, 0],
      [202, 2],
      [197, -28],
    ],
    [
      [52517, 36644],
      [-316, 143],
      [-468, 56],
      [-639, 8],
      [-528, -19],
      [-544, 26],
      [-569, -74],
      [-679, -46],
      [-5, -2],
    ],
    [
      [55304, 47782],
      [272, -187],
      [247, -491],
      [178, -612],
      [368, -445],
      [387, 3],
    ],
  ],
  transform: {
    scale: [0.00003431232667521777, 0.000033939773861068855],
    translate: [3.4960337547027627, 10.095730107384108],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Aleiro",
            id: "KB001",
            nameAbbr: "Aleiro",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.73812383742,
            Shape_Area: 0.0303336469,
          },
        },
        {
          arcs: [[4, 5, 6, 7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Arewa Dandi",
            id: "KB002",
            nameAbbr: "Arewa Dandi",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.41793153163,
            Shape_Area: 0.41056629005,
          },
        },
        {
          arcs: [[11, 12, 13, -6, 14]],
          type: "Polygon",
          properties: {
            name: "Argungu",
            id: "KB003",
            nameAbbr: "Argungu",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.55548453115,
            Shape_Area: 0.1001088488,
          },
        },
        {
          arcs: [[15, -15, -5, 16]],
          type: "Polygon",
          properties: {
            name: "Augie",
            id: "KB004",
            nameAbbr: "Augie",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.62242232262,
            Shape_Area: 0.12609734981,
          },
        },
        {
          arcs: [[17, 18, 19, 20, 21]],
          type: "Polygon",
          properties: {
            name: "Bagudo",
            id: "KB005",
            nameAbbr: "Bagudo",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.97101038513,
            Shape_Area: 0.40575210481,
          },
        },
        {
          arcs: [[22, -2, 23, 24, 25, -7, -14]],
          type: "Polygon",
          properties: {
            name: "Birnin-Kebbi",
            id: "KB006",
            nameAbbr: "Birnin-Kebbi",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.56704008443,
            Shape_Area: 0.10381826363,
          },
        },
        {
          arcs: [[26, -9, 27, 28, 29]],
          type: "Polygon",
          properties: {
            name: "Bunza",
            id: "KB007",
            nameAbbr: "Bunza",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.01627011443,
            Shape_Area: 0.06690223242,
          },
        },
        {
          arcs: [[30, -18, 31, -10, -27]],
          type: "Polygon",
          properties: {
            name: "Dandi",
            id: "KB008",
            nameAbbr: "Dandi",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.80247066381,
            Shape_Area: 0.18260228614,
          },
        },
        {
          arcs: [[[32, 33, 34, 35]], [[36, 37, 38]]],
          type: "MultiPolygon",
          properties: {
            name: "Danko Wasagu",
            id: "KB009",
            nameAbbr: "Danko Wasagu",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.08501117558,
            Shape_Area: 0.28402849391,
          },
        },
        {
          arcs: [[39, -38, 40, 41, 42, 43, 44]],
          type: "Polygon",
          properties: {
            name: "Fakai",
            id: "KB010",
            nameAbbr: "Fakai",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.94226142833,
            Shape_Area: 0.16170865394,
          },
        },
        {
          arcs: [[-3, -23, -13, 45]],
          type: "Polygon",
          properties: {
            name: "Gwandu",
            id: "KB011",
            nameAbbr: "Gwandu",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.458255212,
            Shape_Area: 0.07093104168,
          },
        },
        {
          arcs: [[46, 47, -24, -1]],
          type: "Polygon",
          properties: {
            name: "Jega",
            id: "KB012",
            nameAbbr: "Jega",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.26645660807,
            Shape_Area: 0.07479613343,
          },
        },
        {
          arcs: [[48, -28, -8, -26]],
          type: "Polygon",
          properties: {
            name: "Kalgo",
            id: "KB013",
            nameAbbr: "Kalgo",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.17026581864,
            Shape_Area: 0.05767745559,
          },
        },
        {
          arcs: [[49, -44, 50, 51, -20, 52, 53]],
          type: "Polygon",
          properties: {
            name: "Koko/Besse",
            id: "KB014",
            nameAbbr: "Koko/Besse",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.75986909011,
            Shape_Area: 0.10805246928,
          },
        },
        {
          arcs: [[54, -54, 55, -29, -49, -25, -48]],
          type: "Polygon",
          properties: {
            name: "Maiyama",
            id: "KB015",
            nameAbbr: "Maiyama",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.86926355945,
            Shape_Area: 0.12254180598,
          },
        },
        {
          arcs: [[56, 57, 58, 59]],
          type: "Polygon",
          properties: {
            name: "Ngaski",
            id: "KB016",
            nameAbbr: "Ngaski",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.80158737727,
            Shape_Area: 0.21355806433,
          },
        },
        {
          arcs: [[60, 61, -34]],
          type: "Polygon",
          properties: {
            name: "Sakaba",
            id: "KB017",
            nameAbbr: "Sakaba",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.58189151737,
            Shape_Area: 0.11206582917,
          },
        },
        {
          arcs: [[62, -59, 63, -51, -43]],
          type: "Polygon",
          properties: {
            name: "Shanga",
            id: "KB018",
            nameAbbr: "Shanga",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.86329383055,
            Shape_Area: 0.12386947498,
          },
        },
        {
          arcs: [[-19, -31, -30, -56, -53]],
          type: "Polygon",
          properties: {
            name: "Suru",
            id: "KB019",
            nameAbbr: "Suru",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.75374780414,
            Shape_Area: 0.1064383538,
          },
        },
        {
          arcs: [[-58, 64, -21, -52, -64]],
          type: "Polygon",
          properties: {
            name: "Yauri",
            id: "KB020",
            nameAbbr: "Yauri",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.86635472688,
            Shape_Area: 0.07852192912,
          },
        },
        {
          arcs: [[65, -35, -62, 66, -41, -37, 67]],
          type: "Polygon",
          properties: {
            name: "Zuru",
            id: "KB021",
            nameAbbr: "Zuru",
            parent: "Kebbi",
            parentAbbr: "KB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.26193425429,
            Shape_Area: 0.04317934841,
          },
        },
      ],
    },
  },
};

const sokoto = {
  type: "Topology",
  arcs: [
    [
      [27427, 47818],
      [-8, -13],
      [-155, -145],
      [-126, -219],
      [-140, -178],
      [-451, -434],
      [-121, -69],
      [-373, -202],
      [-357, -249],
      [-487, -285],
      [-250, -186],
      [-317, -195],
      [-107, -41],
    ],
    [
      [24535, 45602],
      [-307, -120],
      [-271, -112],
      [-265, -37],
      [-259, -17],
      [-177, -56],
      [-148, -104],
      [-521, 144],
      [-213, 92],
      [-151, 73],
      [-178, 73],
      [-131, 59],
      [-124, 94],
      [-151, 87],
      [-76, 39],
      [-41, 34],
      [-180, 148],
      [-48, 26],
      [-103, 60],
      [-206, 52],
      [-110, 60],
      [-138, 94],
      [-122, -16],
      [-130, -8],
      [-149, -36],
      [-149, -63],
      [-31, -21],
    ],
    [
      [20156, 46147],
      [-44, 123],
      [-51, 204],
      [-196, 310],
      [-315, 99],
      [-340, -31],
      [-203, -111],
      [-283, -289],
      [-31, -54],
    ],
    [
      [18693, 46398],
      [-12, 79],
      [-160, 215],
      [-79, 278],
      [-31, 238],
      [58, 205],
      [-24, 271],
      [-10, 184],
      [-10, 183],
      [98, 294],
      [58, 225],
      [-101, 386],
      [-137, 495],
      [-84, 121],
      [121, 580],
      [70, 307],
      [101, 504],
      [-57, 217],
      [-40, 345],
    ],
    [
      [18454, 51525],
      [42, -26],
      [211, -17],
      [172, -53],
      [302, -105],
      [274, -119],
      [276, -180],
      [239, -45],
      [178, -32],
      [320, -10],
      [409, 32],
      [497, 101],
      [475, 101],
      [571, 177],
      [325, 126],
      [374, 79],
      [164, 23],
      [211, -18],
      [173, -161],
      [295, -520],
      [18, -299],
      [122, -407],
      [215, -235],
      [485, -497],
      [216, -290],
      [275, -126],
      [342, -105],
      [394, -281],
      [254, -140],
      [366, -288],
      [364, -158],
      [207, -134],
      [207, -100],
    ],
    [
      [32571, 41371],
      [138, -78],
      [103, -47],
      [130, -25],
      [124, -47],
      [144, -52],
      [103, -107],
      [161, -223],
      [70, -108],
      [264, -364],
      [202, -270],
      [84, -155],
      [93, -217],
      [167, -249],
      [112, -162],
      [216, -330],
      [161, -237],
      [126, -229],
      [62, -469],
      [-64, -273],
      [-4, -217],
      [-10, -218],
      [53, -394],
      [6, -346],
      [-65, -232],
      [225, -460],
      [60, -285],
      [64, -230],
      [149, -345],
      [97, -135],
      [92, -183],
      [149, -358],
      [135, -406],
      [50, -180],
    ],
    [
      [35968, 33740],
      [-33, -11],
      [-455, -134],
      [-387, -107],
      [-198, -43],
      [-361, 9],
      [-403, 23],
      [-164, 18],
      [-83, 6],
    ],
    [
      [33884, 33501],
      [-169, 11],
      [-425, 104],
      [-261, 126],
      [-297, 242],
      [-347, 383],
      [-139, 162],
      [-326, 369],
      [-28, 27],
      [-173, 168],
      [-82, 27],
      [-263, 262],
      [-90, 33],
      [-150, 11],
      [-274, 113],
      [-193, 93],
      [-205, 58],
      [-247, 106],
      [-295, 133],
      [-187, 174],
      [-97, 81],
      [-256, 214],
      [-173, 182],
      [-55, 47],
      [-69, 35],
    ],
    [
      [29083, 36662],
      [7, 5],
      [134, 137],
      [85, 226],
      [60, 143],
      [65, 171],
      [91, 293],
      [72, 198],
      [13, 463],
      [16, 299],
      [31, 245],
      [-60, 319],
      [-149, 393],
      [-65, 210],
      [-78, 217],
      [-173, 195],
      [-186, 120],
      [-448, 267],
      [-268, 126],
      [-294, 64],
      [-260, 45],
      [-308, 44],
      [-288, 132],
    ],
    [
      [27080, 40974],
      [129, 29],
      [183, 50],
      [224, 77],
      [346, 181],
      [270, 132],
      [468, 183],
      [258, 112],
      [379, 167],
      [297, 201],
      [182, 158],
      [196, 132],
      [155, 77],
      [150, 15],
      [143, 15],
      [220, -78],
      [158, -108],
      [70, -114],
      [98, -176],
      [152, -141],
      [153, -195],
      [112, -176],
      [77, -87],
      [103, -94],
      [90, -74],
      [159, -127],
      [69, -26],
      [136, 21],
      [136, 43],
      [223, 91],
      [155, 109],
    ],
    [
      [40488, 43234],
      [23, -74],
      [72, -284],
      [38, -258],
      [74, -380],
      [45, -258],
      [123, -440],
      [92, -217],
      [163, -372],
      [251, -364],
      [144, -100],
      [299, -357],
      [147, -257],
      [195, -283],
      [163, -325],
      [197, -419],
      [147, -250],
      [111, -148],
      [125, -162],
      [118, -121],
      [152, -120],
      [276, -221],
      [256, -208],
      [260, -92],
      [213, -72],
      [432, -145],
      [138, -86],
      [222, -236],
      [214, -195],
      [62, -67],
      [223, -289],
      [92, -183],
      [196, -399],
      [56, -81],
      [181, -202],
      [84, -189],
      [168, -290],
      [68, -54],
      [138, -66],
      [161, -163],
    ],
    [
      [46607, 34807],
      [-311, -88],
      [-208, -200],
      [-63, -113],
    ],
    [
      [46025, 34406],
      [-166, 142],
      [-265, 10],
      [-211, -43],
      [-354, -31],
      [-251, -99],
      [-386, -154],
      [-218, -57],
      [-364, -276],
      [-447, -182],
      [-433, -230],
      [-595, -381],
      [-494, -237],
      [-284, -160],
      [-535, -251],
      [-155, -111],
      [-393, -167],
      [-322, 84],
      [-194, 195],
      [-356, 84],
      [-315, 112],
      [-234, 92],
      [-309, 133],
      [-288, 92],
      [-398, 206],
      [-290, 173],
      [-402, 50],
      [-282, 105],
      [-253, 85],
      [-215, 175],
      [-116, 46],
      [-280, 10],
      [-252, -81],
    ],
    [
      [32571, 41371],
      [102, 71],
      [235, 234],
      [86, 164],
      [186, 301],
      [107, 179],
      [19, 41],
    ],
    [
      [33306, 42361],
      [60, 109],
      [40, 109],
      [257, 139],
      [40, 21],
      [594, 262],
    ],
    [
      [34297, 43001],
      [9, 4],
      [41, -6],
      [647, 102],
      [13, 14],
      [557, 184],
      [62, -40],
      [124, -108],
      [98, -182],
      [63, -68],
      [179, -140],
      [117, -46],
      [199, -66],
      [184, -5],
      [116, -19],
      [123, -32],
      [212, -31],
      [681, 123],
      [26, 28],
      [265, 112],
      [40, 21],
      [61, 61],
      [54, 28],
      [278, 92],
      [7, 7],
      [67, 48],
      [48, 28],
      [20, 21],
      [170, 9],
      [48, -34],
      [124, -73],
      [165, -73],
      [96, -26],
      [151, -53],
      [41, -20],
      [164, -45],
      [124, -26],
      [61, -6],
      [103, -12],
      [75, 0],
      [169, 98],
      [0, 7],
      [154, 137],
      [0, 27],
      [149, 91],
      [67, 69],
      [39, 33],
    ],
    [
      [42448, 59864],
      [-49, -83],
      [-169, -91],
      [-224, -125],
      [-94, -76],
      [-155, -158],
      [-108, -62],
      [-189, -105],
      [-215, -186],
      [-141, -131],
      [-156, -124],
      [-188, -179],
      [-209, -145],
      [-216, -173],
      [-249, -193],
      [-250, -160],
      [-270, -200],
      [-169, -97],
      [-196, -118],
      [-149, -56],
      [-169, -77],
      [-246, -30],
      [-308, 78],
      [-206, 126],
      [-333, 316],
      [-69, 108],
      [-91, 148],
      [-16, 129],
      [0, 7],
      [112, 267],
      [50, 320],
      [28, 367],
      [50, 294],
      [78, 272],
      [43, 300],
      [-17, 217],
      [-65, 272],
      [-155, 263],
      [-228, 215],
      [-207, 154],
      [-232, 24],
      [-198, 39],
      [-238, -78],
      [-196, -111],
      [-162, -111],
      [-230, -118],
      [-183, -104],
      [-141, -151],
      [-53, -124],
      [-111, -266],
      [-180, -281],
      [-192, -370],
      [-19, -129],
      [-45, -157],
      [-119, -266],
      [-52, -137],
      [-106, -205],
      [-128, -97],
      [-338, -215],
      [-325, -174],
      [-182, -118],
      [-225, -63],
      [-347, -45],
      [-218, -3],
      [-320, -17],
      [-240, 51],
      [-240, 120],
      [-118, 93],
      [-317, 228],
      [-7, 13],
      [0, -6],
      [-89, 46],
      [-69, 54],
      [-90, 73],
      [-111, 128],
      [-124, 81],
      [-125, 141],
      [-174, 198],
    ],
    [
      [31059, 59091],
      [-64, 227],
      [-19, 299],
      [-6, 388],
      [-128, 345],
      [-135, 400],
      [-31, 224],
      [-5, 346],
      [-76, 537],
      [-6, 387],
      [-88, 380],
      [20, 490],
      [123, 389],
      [84, 293],
      [129, 505],
      [129, 518],
      [2, 170],
    ],
    [
      [30988, 64989],
      [338, 14],
      [912, 19],
      [820, 23],
      [249, 7],
      [6, 7],
      [110, 131],
      [429, 512],
      [521, 622],
      [317, 377],
      [635, 758],
      [127, 151],
      [171, 188],
      [111, 122],
      [32, 29],
      [14, 14],
      [952, 276],
      [185, 53],
      [4, 1],
      [499, 145],
      [955, 261],
      [139, 100],
      [165, 39],
      [129, -22],
      [798, 229],
      [234, 51],
      [285, 79],
      [8, 2],
      [636, 174],
      [285, -122],
    ],
    [
      [41054, 69229],
      [-44, -88],
      [-2, -5],
      [-67, -105],
      [-120, -185],
      [-120, -158],
      [-57, -320],
      [-84, -266],
      [-91, -287],
      [11, -333],
      [-26, -510],
      [-113, -199],
      [-70, -327],
      [-84, -321],
      [53, -733],
      [3, -224],
      [126, -686],
      [-2, -279],
      [171, -501],
      [73, -278],
      [242, -704],
      [46, -299],
      [184, -446],
      [233, -501],
      [113, -277],
      [135, -318],
      [177, -460],
      [76, -40],
      [291, -282],
      [152, -148],
      [144, -66],
      [44, -19],
    ],
    [
      [52566, 60524],
      [33, -105],
      [-65, -144],
      [-74, -102],
      [-17, -191],
      [-26, -96],
      [-33, -95],
      [45, -272],
      [133, -236],
      [246, -466],
      [122, -352],
      [326, -404],
      [245, -405],
      [86, -244],
      [152, -134],
      [207, -175],
      [70, -101],
      [139, -175],
      [194, -215],
      [136, -420],
      [171, -461],
      [127, -311],
      [106, -182],
      [215, -277],
      [92, -87],
    ],
    [
      [55196, 54874],
      [-28, -75],
      [-24, -224],
      [-57, -253],
      [-160, -287],
      [-225, -391],
      [-201, -233],
      [-344, -262],
      [-316, -358],
      [-181, -186],
      [-140, -199],
      [-160, -273],
      [-55, -389],
      [-56, -388],
      [-37, -694],
      [89, -427],
      [-15, -334],
      [204, -412],
      [271, -384],
      [298, -282],
      [201, -222],
      [381, -390],
      [333, -377],
      [373, -329],
      [269, -234],
      [199, -100],
      [144, -66],
      [30, -204],
      [-57, -327],
      [-85, -225],
      [-179, -356],
      [-119, -273],
      [-37, -252],
      [5, -415],
      [3, -244],
      [-7, -201],
    ],
    [
      [55513, 44608],
      [-10, 10],
      [-69, 67],
      [-117, 101],
      [-56, 53],
      [-152, 169],
      [-131, 168],
      [-146, 175],
      [-96, 114],
      [-309, 160],
      [-7, 0],
      [-123, 52],
      [-137, 80],
      [-144, 80],
      [-192, 86],
      [-315, 44],
      [-205, 25],
      [-184, 25],
      [-186, 66],
      [-302, 132],
      [-226, 79],
      [-117, 73],
      [-223, 297],
      [-130, 73],
      [-201, 181],
      [-334, 404],
      [-222, 263],
      [-248, 187],
      [-160, 195],
      [-99, 190],
      [-337, 200],
      [-102, -1],
      [-307, -25],
      [-353, -65],
      [-490, -101],
      [-449, -80],
      [-286, -38],
      [-387, -93],
      [-286, -17],
      [-178, 5],
      [-89, 26],
      [-36, 122],
      [-30, 177],
      [-17, 237],
      [-26, 327],
      [18, 224],
      [10, 177],
      [5, 123],
      [-189, 364],
      [-63, 102],
      [-37, 142],
      [-21, 81],
      [-30, 156],
      [-44, 217],
      [-36, 143],
      [-3, 14],
    ],
    [
      [46909, 50574],
      [252, -283],
      [151, -73],
      [201, -147],
      [247, -113],
      [178, -18],
      [177, 2],
      [293, 17],
      [204, 23],
      [257, 105],
      [251, 152],
      [316, 290],
      [147, 199],
      [98, 259],
      [44, 273],
      [5, 129],
      [-140, 216],
      [-173, 188],
      [-236, 263],
      [-214, 119],
      [-371, 173],
      [-218, 31],
      [-281, 72],
      [-383, 43],
      [-293, 3],
      [-226, 45],
      [-232, -23],
      [-246, 38],
      [-198, 38],
      [-238, -44],
      [-258, -77],
      [-278, -92],
      [-360, -100],
      [-286, -51],
      [-320, -58],
      [-299, -24],
      [-334, -11],
      [-456, -80],
      [-212, 45],
      [-192, 25],
      [-416, 29],
      [-165, 39],
      [-299, -31],
      [-123, -1],
      [-157, -9],
      [-34, 13],
      [-14, 7],
      [-96, 87],
      [-145, 121],
      [-125, 121],
      [-181, 222],
      [-50, 176],
      [-162, 297],
      [-140, 257],
      [-2, 102],
      [-147, 257],
      [-1, 88],
      [-43, 108],
      [-156, 216],
    ],
    [
      [40830, 54227],
      [22, 102],
      [5, 123],
      [-11, 231],
      [-3, 204],
      [52, 184],
      [46, 75],
      [134, 185],
      [114, 145],
      [215, 193],
      [337, 248],
      [217, 146],
      [153, 199],
      [187, 308],
      [126, 219],
      [246, 384],
      [146, 301],
      [1, 340],
      [10, 292],
      [-18, 279],
      [-46, 305],
      [-46, 333],
      [10, 238],
      [-30, 217],
      [11, 150],
      [32, 96],
      [61, 82],
      [22, 45],
    ],
    [
      [42823, 59851],
      [140, 32],
      [258, 71],
      [488, 189],
      [319, 126],
      [353, 106],
      [231, 112],
      [257, 98],
      [346, 113],
      [150, -5],
      [117, -46],
      [22, -54],
      [155, -345],
      [175, -291],
      [7, -33],
      [86, -292],
      [80, -284],
      [137, -502],
      [144, -488],
      [93, -305],
      [96, -481],
      [122, -359],
      [57, -210],
      [65, -224],
      [101, -345],
      [48, -47],
      [96, -40],
      [104, -67],
      [143, -19],
      [253, -3],
      [257, 98],
      [80, 144],
      [85, 225],
      [130, 437],
      [94, 579],
      [123, 416],
      [69, 402],
      [102, 436],
      [198, 445],
      [123, 443],
      [89, 416],
      [256, 173],
      [88, 49],
      [205, 22],
      [137, -52],
      [225, -25],
      [103, -19],
      [151, -46],
      [295, -112],
      [118, -148],
      [254, -119],
      [233, -72],
      [211, 23],
      [225, 36],
      [237, 71],
      [536, 177],
      [265, 98],
      [211, 169],
    ],
    [
      [18693, 46398],
      [-155, -262],
      [-32, -109],
      [-194, -254],
      [-301, -371],
      [-179, -383],
      [-296, -262],
      [-373, -140],
      [-483, -60],
      [-909, 132],
      [-535, 185],
      [-655, 455],
      [-731, 509],
      [-367, 121],
      [-352, 116],
      [-802, 249],
      [-650, 156],
      [-486, 123],
      [-693, 278],
      [-825, 426],
      [-327, -11],
      [-499, 512],
      [-783, 385],
      [-717, 462],
      [-534, 579],
      [-245, 412],
      [-356, 145],
      [-538, -53],
      [-427, -155],
      [-501, -312],
      [-712, -219],
      [-322, 58],
      [-556, 286],
      [-499, 21],
      [-384, -11],
      [-20, -14],
      [-8, -1],
      [-2205, -57],
      [-3, 0],
      [4, 3204],
      [-12, 443],
      [2, 79],
      [4, 144],
      [16, 576],
      [0, 15],
      [12, 333],
      [-8, 448],
      [-2, 153],
      [-13, 914],
      [-3, 244],
      [-7, 83],
      [-31, 359],
      [-1, 9],
      [11, 89],
      [47, 380],
      [16, 125],
      [8, 62],
      [320, 6],
      [142, 0],
      [605, 11],
      [174, 3],
      [8, -1],
      [252, -47],
      [446, 77],
      [4, -38],
      [212, 158],
      [124, 100],
      [125, 100],
      [6, 5],
      [1015, 920],
      [37, 33],
      [1272, 1109],
      [335, 281],
      [469, 421],
      [15, 4],
      [86, 23],
      [4, 4],
      [584, 579],
      [146, 141],
      [22, 21],
      [25, 20],
      [432, 363],
      [985, 836],
      [3, 3],
      [82, 69],
      [218, 219],
      [6, 6],
      [299, 249],
      [414, 339],
      [192, 170],
      [263, 225],
      [177, 162],
      [85, 75],
      [52, 51],
      [109, 100],
      [388, 44],
      [2, 3],
      [54, 82],
      [3, 5],
      [675, 128],
      [554, 105],
      [786, 143],
      [26, 5],
      [133, 77],
      [126, 11],
      [255, 23],
      [443, 87],
      [25, 6],
      [28, 4],
      [133, 19],
      [154, 35],
      [17, 4],
      [54, 12],
      [527, 115],
      [39, 4],
      [275, 76],
      [567, 97],
      [786, 128],
      [287, 54],
      [174, 29],
      [308, 61],
      [824, 161],
      [37, 7],
      [364, 54],
      [44, 5],
      [212, 73],
      [16, 6],
      [52, 11],
      [34, 6],
      [575, 108],
      [176, 32],
      [993, 183],
      [900, 174],
      [276, 53],
      [247, 49],
    ],
    [
      [21396, 66070],
      [-184, -395],
      [-63, -320],
      [-160, -240],
      [-182, -159],
      [-154, -158],
      [-113, -239],
      [47, -401],
      [39, -768],
      [-195, -635],
      [-224, -465],
      [-232, -485],
      [-145, -287],
      [-114, -580],
      [-49, -374],
      [-11, -599],
      [-74, -579],
      [-13, -462],
      [-7, -415],
      [41, -448],
      [23, -197],
      [-45, -606],
      [117, -522],
      [-157, -430],
      [-88, -525],
      [-126, -661],
      [-171, -437],
      [-209, -567],
      [-177, -478],
      [-74, -525],
      [-35, -388],
      [-150, -423],
      [-88, -504],
      [31, -273],
    ],
    [
      [42448, 59864],
      [66, -28],
      [136, -12],
      [123, 16],
      [50, 11],
    ],
    [
      [40830, 54227],
      [-16, -82],
      [-73, -143],
      [-52, -178],
      [-112, -280],
      [-140, -199],
      [-154, -199],
      [-351, -222],
      [-230, -97],
      [-326, -134],
      [-61, -48],
      [-216, -118],
      [-162, -104],
      [-142, -83],
      [-122, -63],
      [-142, -70],
      [-258, -105],
      [-237, -111],
      [-194, -200],
      [-100, -164],
      [-114, -158],
      [-189, -138],
      [-182, -118],
      [-155, -131],
      [-132, -301],
      [-12, -88],
      [-64, -239],
      [-62, -79],
    ],
    [
      [36832, 50375],
      [-88, 70],
      [-179, 121],
      [-173, 107],
      [-178, 72],
      [-205, 38],
      [-69, 47],
      [-167, 168],
      [-136, -1],
      [-68, 6],
      [-103, 12],
      [-124, 73],
      [-69, 74],
      [-85, 217],
      [-78, 155],
      [-99, 271],
      [-72, 251],
      [-119, 202],
      [-162, 277],
      [-124, 128],
      [-102, 12],
      [-132, 135],
      [-151, 66],
      [-247, 106],
      [-118, 73],
      [-109, -15],
      [-189, -77],
      [-170, -70],
      [-156, -56],
      [-168, -138],
      [-155, -131],
      [-121, -83],
      [-203, -145],
      [-202, -173],
      [-297, -221],
      [-141, -124],
      [-215, -193],
      [-122, -76],
      [-237, -118],
      [-101, -70],
      [-108, -96],
      [-142, -117],
    ],
    [
      [30948, 51152],
      [-30, 230],
      [-128, 339],
      [-121, 359],
      [63, 300],
      [-27, 428],
      [-59, 298],
      [115, 518],
      [3, 252],
      [75, 443],
      [152, 280],
      [32, 205],
      [113, 171],
      [152, 335],
      [98, 246],
      [82, 477],
      [-110, 454],
      [7, 435],
      [-34, 456],
      [-79, 298],
      [-111, 522],
      [-30, 224],
      [-23, 564],
      [-29, 105],
    ],
    [
      [41054, 69229],
      [116, -50],
      [142, -60],
      [1115, -476],
      [662, -326],
      [614, -230],
      [3084, -1347],
      [81, -37],
      [17, -7],
      [149, -67],
      [340, -153],
      [1365, -504],
      [1305, -435],
      [1098, -398],
      [96, -35],
      [921, -302],
      [1905, -647],
      [239, -57],
      [484, -195],
      [539, -183],
      [40, -15],
    ],
    [
      [55366, 63705],
      [-100, -133],
      [-227, -350],
      [-201, -227],
      [-207, -281],
      [-71, -239],
      [-181, -219],
      [-291, -140],
      [-209, -159],
      [-107, -151],
      [-414, -555],
      [-216, -180],
      [-256, -241],
      [-93, -123],
      [-227, -183],
    ],
    [
      [73765, 54086],
      [166, -186],
      [183, -167],
      [164, -150],
      [28, -25],
      [13, -13],
      [3, -3],
      [83, -161],
      [131, -255],
      [415, -636],
      [300, -519],
      [147, -253],
      [79, -137],
      [340, -648],
      [228, -322],
      [14, -26],
      [223, -409],
      [169, -309],
      [400, -691],
      [143, -244],
      [2, -4],
      [6, -9],
      [19, -34],
      [84, -143],
      [31, -53],
      [-23, -18],
      [-3, -2],
      [-386, -288],
      [-460, -459],
      [-554, -444],
      [-579, -313],
      [-725, -485],
      [-608, -380],
      [-463, -267],
      [-317, -147],
      [-209, -97],
      [-441, -180],
      [-404, 79],
      [-863, 56],
      [-1008, 182],
      [-410, 79],
      [-722, -16],
      [-650, 180],
      [-419, 39],
      [-369, 160],
      [-526, 146],
      [-349, 452],
      [-305, 336],
      [-148, 170],
      [-378, 101],
      [-156, -8],
      [-149, 100],
      [-383, 124],
      [-327, 37],
      [-498, 69],
      [-443, -5],
      [-597, -68],
      [-478, 56],
      [-76, -375],
      [-111, -342],
      [60, -455],
      [-126, -722],
      [-25, -661],
      [-287, -438],
      [-55, -443],
      [-13, -5],
      [-224, -100],
      [-242, 269],
      [-430, 91],
      [-343, 213],
      [-253, 99],
      [-436, 16],
      [-361, -5],
      [-517, -12],
      [-474, -135],
      [-645, -178],
      [-589, -211],
      [-576, -204],
      [-686, -552],
      [-309, -296],
      [-51, -328],
      [14, -163],
    ],
    [
      [56031, 42941],
      [-71, 64],
      [-75, 34],
      [-41, -1],
      [-27, 20],
      [-110, 87],
      [-77, 156],
      [-31, 203],
      [-1, 109],
      [-54, 455],
      [-3, 245],
      [-30, 230],
      [2, 65],
    ],
    [
      [55196, 54874],
      [275, -261],
      [284, -290],
      [248, -180],
      [352, -214],
      [226, -99],
      [299, -330],
      [535, -224],
      [323, -167],
      [472, -103],
      [370, -104],
      [350, -98],
      [177, 9],
      [422, -8],
      [353, 72],
      [307, 3],
      [170, -52],
      [288, -132],
      [143, 29],
      [230, 84],
      [343, -200],
      [198, -79],
      [192, -52],
      [284, 92],
      [388, 45],
      [319, 59],
      [415, 45],
      [333, -9],
      [374, 45],
      [27, 1],
      [88, 14],
      [104, -155],
      [323, -207],
      [186, -181],
      [275, -173],
      [220, -168],
      [219, -58],
      [266, -10],
      [285, 30],
      [245, -31],
      [375, -16],
      [246, -85],
      [437, -70],
      [462, 33],
      [429, 26],
      [414, 39],
      [638, 132],
      [493, 84],
      [341, 50],
      [483, 123],
      [550, 94],
      [252, 171],
      [477, 122],
      [212, 130],
      [324, 214],
      [292, 206],
      [269, 251],
      [475, 374],
      [281, 252],
      [211, 139],
    ],
    [
      [26108, 4195],
      [-109, -61],
      [-655, -395],
      [-443, 36],
      [-154, 297],
      [-121, 414],
      [-83, 107],
      [-394, -79],
      [-568, -258],
      [-403, 131],
      [-166, 155],
      [-321, 118],
      [-376, 71],
      [-421, -128],
      [-419, -249],
      [-467, -271],
      [-637, -225],
      [-636, -354],
      [-862, -276],
      [-671, -300],
      [-834, -268],
      [-589, -313],
      [-534, -298],
      [-404, -393],
      [-362, -406],
      [-366, -609],
      [-449, -475],
      [-353, -161],
      [-484, -5],
      [-465, 76],
      [-362, 64],
      [-35, 20],
      [-323, 221],
      [-27, 28],
      [-500, 531],
      [-481, 661],
      [-191, 494],
      [87, 539],
      [48, 236],
      [73, 350],
      [337, 1113],
      [404, 849],
      [355, 847],
      [470, 945],
      [170, 961],
      [94, 608],
      [67, 435],
      [182, 635],
      [302, 724],
      [186, 1227],
      [47, 967],
      [-33, 401],
      [-54, 829],
      [-173, 638],
      [-51, 676],
    ],
    [
      [13954, 15075],
      [341, 208],
      [344, 214],
      [331, 222],
      [331, 181],
      [380, 167],
      [251, 119],
      [352, 120],
      [7, 0],
      [34, 7],
      [286, 10],
      [211, 37],
      [136, 63],
      [170, 43],
      [231, 22],
      [382, 25],
      [217, 71],
      [354, 38],
      [631, 259],
      [148, 97],
      [418, 311],
      [303, 290],
      [369, 358],
      [88, 76],
      [120, 198],
      [100, 110],
      [106, 239],
      [59, 150],
      [137, 390],
      [59, 130],
      [296, 323],
      [47, 14],
      [387, 107],
      [88, 28],
      [491, 26],
      [103, -39],
      [308, -24],
      [388, 11],
      [123, -39],
      [173, -100],
      [415, 19],
      [281, -38],
      [463, 6],
      [103, -47],
      [164, -11],
      [361, 52],
      [218, 29],
      [61, 22],
      [420, 195],
      [66, 143],
      [248, 241],
      [176, 111],
      [203, 105],
      [98, 28],
    ],
    [
      [26531, 20392],
      [-115, -516],
      [-44, -688],
      [-9, -1171],
      [88, -1312],
      [-45, -1075],
      [30, -1095],
      [-107, -1070],
      [-7, -1735],
      [98, -1074],
      [-24, -1170],
      [-64, -1273],
      [-133, -804],
      [46, -863],
      [-22, -892],
      [-68, -1021],
      [-47, -438],
    ],
    [
      [36832, 50375],
      [-45, -58],
      [-68, -22],
      [-285, -105],
      [-183, -50],
      [-150, -83],
      [-155, -70],
      [-251, -84],
      [-74, -83],
      [-40, -55],
      [-25, -136],
      [-5, -136],
      [64, -190],
      [114, -284],
      [29, -136],
      [16, -183],
      [17, -183],
      [10, -238],
      [-10, -191],
      [16, -170],
      [14, -40],
      [67, -367],
      [79, -264],
      [86, -237],
      [125, -202],
      [85, -169],
      [77, -115],
      [125, -148],
      [117, -73],
      [117, -54],
      [150, -45],
      [157, 67],
    ],
    [
      [37006, 46271],
      [7, -92],
      [-46, -123],
      [-24, -191],
      [-19, -68],
      [-11, -177],
      [22, -102],
      [30, -183],
      [-5, -109],
      [42, -88],
      [62, -33],
      [149, 49],
      [217, 105],
      [210, 111],
      [298, 140],
      [203, 83],
      [170, 57],
      [347, 65],
      [190, 37],
      [34, 0],
      [110, -67],
      [241, -167],
      [145, -73],
      [359, -315],
      [8, -48],
      [107, -332],
      [105, -182],
      [126, -223],
      [79, -223],
      [100, -305],
      [141, -304],
      [64, -210],
      [21, -69],
    ],
    [
      [34297, 43001],
      [-74, 78],
      [-55, 81],
      [-43, 94],
      [-28, 75],
      [-22, 81],
      [-48, 33],
      [-14, 41],
      [-50, 102],
      [-49, 94],
      [-36, 95],
      [-7, 54],
      [13, 21],
      [33, 54],
      [48, 42],
      [80, 55],
      [95, 56],
      [75, 28],
      [87, 55],
      [62, 28],
      [95, 42],
      [25, 109],
      [19, 96],
      [13, 41],
      [-1, 68],
      [-8, 74],
      [-35, 41],
      [-28, 47],
      [-28, 75],
      [-49, 60],
      [-56, 108],
      [-35, 41],
      [-41, 40],
      [-35, 40],
      [-39, 75],
    ],
    [
      [34161, 45125],
      [-3, 6],
      [-2, 82],
      [-7, 27],
      [0, 34],
      [-15, 47],
      [19, 137],
      [-22, 101],
      [-36, 122],
      [-28, 48],
      [-83, 73],
      [-123, 13],
      [-7, 0],
      [-157, 11],
      [-122, -28],
      [-191, 4],
      [-232, -23],
      [-184, -2],
      [-156, -77],
      [-238, -44],
      [-287, 11],
      [-6, 0],
    ],
    [
      [32281, 45667],
      [17, 197],
      [-11, 285],
      [-79, 258],
      [-133, 223],
      [-139, 182],
      [-172, 52],
      [-198, 18],
      [-253, 78],
      [-295, 79],
      [-171, 38],
      [-205, 52],
      [-227, 99],
      [-220, 107],
      [-206, 133],
      [-77, 101],
      [-83, 115],
      [-251, 317],
      [-101, 180],
    ],
    [
      [29477, 48181],
      [63, 78],
      [296, 316],
      [70, 307],
      [50, 313],
      [225, 424],
      [31, 191],
      [233, 377],
      [161, 151],
      [81, 83],
      [126, 253],
      [152, 349],
      [-17, 129],
    ],
    [
      [56031, 42941],
      [35, -428],
      [130, -549],
      [130, -523],
      [58, -733],
      [-67, -654],
      [117, -550],
      [22, -646],
      [-4, -687],
      [-31, -239],
      [81, -937],
      [-30, -82],
      [-56, -151],
      [-633, -463],
      [-853, -350],
      [-649, -300],
      [-400, -120],
      [-639, -56],
      [-538, -33],
      [-346, -45],
      [-708, -15],
      [-1637, -223],
      [-851, 3],
      [-889, -133],
      [-945, -106],
      [-659, -96],
      [-62, -18],
    ],
    [
      [37006, 46271],
      [6, 3],
      [245, 57],
      [325, 113],
      [428, 154],
      [291, 140],
      [400, 161],
      [495, 149],
      [488, 250],
      [357, 256],
      [454, 230],
      [425, 257],
      [283, 234],
      [317, 269],
      [311, 201],
      [297, 187],
      [433, 236],
      [256, 214],
      [262, 241],
      [216, 132],
      [182, 179],
      [316, 289],
      [162, 91],
      [312, 153],
      [399, 182],
      [237, 132],
      [250, 152],
      [122, 70],
      [237, 98],
      [183, 63],
      [163, 36],
      [137, -5],
      [185, -73],
      [179, -134],
      [206, -92],
      [97, -47],
      [151, -86],
      [76, -95],
      [20, -94],
    ],
    [
      [55366, 63705],
      [451, -174],
      [987, -346],
      [235, -82],
      [484, -285],
      [658, -386],
      [415, -256],
      [159, -82],
      [168, -85],
      [220, 52],
      [109, 43],
      [6, 2],
      [383, 149],
      [54, 22],
      [39, 11],
      [446, 120],
      [55, 11],
      [42, 22],
      [23, 10],
      [43, 19],
      [64, 26],
      [22, 10],
      [43, 24],
      [89, 42],
      [5, 0],
      [543, 133],
      [400, 29],
      [132, 8],
      [537, 26],
      [216, -56],
      [12, -3],
      [200, 4],
      [220, 1],
      [457, -161],
      [39, -15],
      [65, -10],
      [38, -23],
      [19, -6],
      [133, -80],
      [17, -10],
      [137, -22],
      [336, -167],
      [29, -14],
      [506, -251],
      [1957, -1072],
      [96, -53],
      [130, -25],
      [61, -11],
      [185, -145],
      [175, -138],
      [513, -515],
      [1392, -1360],
      [1918, -1856],
      [203, -206],
      [216, -166],
      [411, -422],
      [1249, -1281],
      [554, -503],
      [103, -116],
    ],
    [
      [35486, 20905],
      [-504, -94],
      [-1243, 142],
      [-1165, -88],
      [-111, -8],
    ],
    [
      [32463, 20857],
      [-23, 68],
      [-140, 291],
      [-149, 324],
      [43, 307],
      [13, 95],
      [39, 102],
      [33, 83],
      [-10, 231],
      [5, 95],
      [19, 54],
      [80, 171],
      [46, 62],
      [46, 171],
      [-31, 176],
      [-49, 108],
      [-35, 34],
      [-119, 264],
      [-28, 33],
      [-56, 108],
      [-133, 196],
      [-194, 161],
      [-206, 161],
      [-139, 154],
      [-317, 153],
      [-177, 18],
      [-186, 86],
      [-207, 202],
      [-228, 181],
      [-152, 100],
      [-241, 154],
      [-248, 214],
      [-222, 222],
      [-277, 275],
      [-160, 182],
      [-78, 217],
      [-80, 325],
      [-237, 337],
      [-271, 344],
      [-100, 277],
      [-155, 311],
      [-71, 156],
      [-126, 257],
      [-126, 182],
      [-331, 220],
      [-329, 125],
      [-349, 78],
      [-232, -23],
      [-278, -78],
      [-128, -104],
      [-148, -124],
      [-142, -104],
      [-134, -171],
      [-106, -165],
      [-51, -232],
      [-31, -231],
      [-125, -260],
      [24, -231],
      [136, -896],
      [3, -176],
      [-120, -226],
      [-176, -29],
      [-178, 24],
      [-362, 23],
      [-273, -3],
      [-240, 92],
      [-309, 112],
      [-206, 114],
      [-118, 121],
      [-97, 127],
      [-71, 163],
      [-36, 129],
      [85, 252],
      [58, 212],
      [91, 293],
      [30, 300],
      [42, 367],
      [69, 348],
      [86, 225],
      [4, 136],
      [-116, 502],
      [-54, 360],
      [-14, 209],
    ],
    [
      [23690, 29978],
      [215, 65],
      [189, 84],
      [203, 111],
      [115, 77],
      [244, 139],
      [222, 179],
      [155, 145],
      [135, 89],
      [182, 118],
      [229, 180],
      [147, 192],
      [160, 240],
      [93, 164],
      [80, 151],
      [146, 226],
      [271, 166],
      [305, 140],
      [339, 65],
      [355, -16],
      [301, -51],
      [342, -64],
      [409, 19],
      [300, 58],
      [258, 71],
      [87, 103],
      [92, 218],
      [72, 185],
      [105, 246],
      [9, 360],
      [-140, 203],
      [-166, 154],
      [-255, 201],
      [-131, 101],
      [-278, 282],
      [-92, 209],
      [-45, 306],
      [-38, 244],
      [29, 334],
      [82, 449],
      [113, 199],
      [155, 124],
      [142, 77],
      [122, 42],
      [135, 99],
    ],
    [
      [33884, 33501],
      [64, -67],
      [83, -115],
      [112, -196],
      [168, -236],
      [155, -311],
      [163, -406],
      [30, -231],
      [54, -359],
      [5, -354],
      [-31, -191],
      [10, -231],
      [-120, -185],
      [-56, -300],
      [-193, -328],
      [-56, -395],
      [-1, -374],
      [-29, -320],
      [88, -373],
      [125, -196],
      [263, -248],
      [133, -182],
      [117, -108],
      [148, -297],
      [82, -427],
      [-49, -382],
      [-49, -368],
      [-70, -340],
      [47, -374],
      [183, -861],
      [16, -156],
      [59, -299],
      [31, -224],
      [18, -306],
      [45, -237],
      [-15, -374],
      [-17, -177],
      [26, -374],
      [-23, -292],
      [-45, -219],
      [67, -298],
      [59, -346],
      [-17, -225],
      [22, -314],
    ],
    [
      [24535, 45602],
      [-7, -82],
      [-13, -69],
      [51, -169],
      [56, -115],
      [139, -154],
      [62, -47],
      [152, -107],
      [193, -202],
      [56, -61],
      [146, -202],
      [140, -216],
      [77, -148],
      [97, -122],
      [57, -108],
      [44, -190],
      [17, -224],
      [8, -108],
      [-4, -143],
      [2, -143],
      [-5, -136],
      [-9, -320],
      [-38, -157],
      [-72, -225],
      [-38, -163],
      [106, -264],
      [42, -75],
      [78, -182],
      [134, -298],
      [38, -184],
    ],
    [
      [26044, 40988],
      [36, -175],
      [-46, -123],
      [-120, -192],
      [-100, -178],
      [-44, -198],
      [-133, -233],
      [-133, -219],
      [-107, -157],
      [-182, -166],
      [-195, -172],
      [-134, -138],
      [-141, -124],
      [-163, -97],
      [-224, -50],
      [-416, -5],
      [-259, -24],
      [-171, 25],
      [-130, 26],
      [-61, 6],
      [-151, 33],
      [-232, 10],
      [-205, 18],
      [-177, -2],
      [-259, -10],
      [-293, -24],
      [-237, -64],
      [-340, -126],
      [-380, -141],
      [-285, -64],
      [-272, -37],
      [-415, -60],
      [-206, -99],
    ],
    [
      [19869, 38228],
      [-256, 437],
      [-77, 148],
      [-208, 229],
      [-215, 236],
      [-304, 235],
      [-42, 87],
      [-106, 217],
      [56, 348],
      [71, 694],
      [72, 688],
      [242, 241],
      [131, 812],
      [295, 336],
      [-39, 782],
      [-6, 341],
      [118, 361],
      [140, 179],
      [112, 260],
      [146, 308],
      [62, 368],
      [186, 356],
      [-91, 256],
    ],
    [
      [34161, 45125],
      [-30, -48],
      [-26, -55],
      [-47, -75],
      [-46, -83],
      [-33, -68],
      [-27, -61],
      [-19, -55],
      [-19, -89],
      [14, -54],
      [63, -60],
      [21, -68],
      [-47, -62],
      [-74, -69],
      [-40, -34],
      [-61, -62],
      [-128, -70],
      [-88, -41],
      [-143, -29],
      [-156, -36],
      [-157, -43],
      [-149, -9],
      [-184, -42],
      [-150, -9],
      [-160, 13],
    ],
    [
      [32475, 43916],
      [29, 67],
      [128, 144],
      [52, 117],
      [-273, 520],
      [-30, 169],
      [-70, 101],
      [-28, 68],
      [-31, 183],
      [-37, 211],
      [66, 164],
      [0, 7],
    ],
    [
      [33306, 42361],
      [-84, 203],
      [-57, 121],
      [-77, 149],
      [-49, 61],
      [-7, 13],
      [-48, 61],
      [-42, 60],
      [-35, 61],
      [-63, 115],
      [-29, 54],
      [-356, 533],
      [-7, 68],
      [23, 56],
    ],
    [
      [32463, 20857],
      [-829, -57],
      [-1552, 206],
      [-1634, 294],
      [-294, 52],
      [-254, 78],
      [-321, 71],
      [-336, 78],
      [-270, -173],
      [-162, -104],
      [-184, -458],
      [-70, -334],
      [-26, -118],
    ],
    [
      [13954, 15075],
      [-34, 452],
      [-6, 803],
      [67, 552],
      [-134, 795],
      [4, 305],
      [17, 981],
      [18, 177],
      [28, 663],
      [2, 52],
      [43, 1184],
      [19, 1014],
      [-66, 278],
      [135, 587],
      [169, 532],
      [168, 649],
      [268, 302],
      [335, 392],
      [576, 238],
      [557, 183],
      [727, 131],
      [633, 96],
      [629, -88],
      [557, -334],
      [572, -368],
      [365, -254],
      [478, 19],
      [219, 377],
      [83, 354],
      [47, 505],
      [94, 531],
      [36, 803],
      [6, 939],
      [-69, 973],
      [-354, 1343],
      [-435, 1240],
      [-22, 952],
      [7, 43],
      [118, 783],
      [55, 367],
      [239, 908],
      [193, 730],
      [-54, 897],
      [16, 74],
    ],
    [
      [20260, 36235],
      [189, -86],
      [267, -51],
      [376, -71],
      [254, -112],
      [241, -126],
      [254, -133],
      [269, -201],
      [83, -40],
      [103, -94],
      [83, -67],
      [77, -101],
      [111, -135],
      [132, -195],
      [252, -419],
      [46, -326],
      [52, -230],
      [121, -353],
      [13, -394],
      [4, -292],
      [25, -306],
      [74, -386],
      [3, -170],
      [46, -347],
      [74, -346],
      [202, -316],
      [53, -292],
      [-8, -374],
      [31, -245],
      [3, -49],
    ],
    [
      [29477, 48181],
      [-37, -46],
      [21, -68],
      [-229, -159],
      [-237, -152],
      [-330, -242],
      [-321, 3],
      [-225, -10],
      [-357, 193],
      [-268, 85],
      [-67, 33],
    ],
    [
      [21396, 66070],
      [3, 6],
      [255, 49],
      [3, -2],
      [36, -47],
      [120, -163],
      [21, -19],
      [614, -585],
      [12, -11],
      [327, -172],
      [1007, -63],
      [883, -82],
      [82, -5],
      [194, -13],
      [94, -6],
      [14, -2],
      [84, -10],
      [279, -32],
      [55, -7],
      [232, 16],
      [42, 3],
      [107, 17],
      [329, 52],
      [116, 19],
      [4, 0],
      [409, 26],
      [75, 1],
      [68, 8],
      [105, 11],
      [52, 3],
      [40, 2],
      [327, 44],
      [542, 72],
      [104, 14],
      [34, 5],
      [313, 28],
      [135, 13],
      [662, -87],
      [4, 1],
      [182, 30],
      [280, -89],
      [95, -30],
      [486, -59],
      [207, 46],
      [239, -29],
      [130, -42],
      [190, 8],
    ],
    [
      [41937, 22205],
      [-56, -191],
      [-333, -228],
      [-827, -568],
      [-754, -90],
      [-627, 47],
      [-1495, -201],
      [-601, 135],
      [-1135, -88],
      [-623, -116],
    ],
    [
      [46025, 34406],
      [-196, -353],
      [-359, -609],
      [-487, -754],
      [-455, -598],
      [-341, -419],
      [-634, -966],
      [-403, -393],
      [-483, -481],
      [-163, -63],
      [-176, -77],
      [-269, -194],
      [-527, -353],
      [-310, -208],
      [-113, -232],
      [131, -604],
      [7, -511],
      [230, -425],
      [174, -726],
      [272, -1017],
      [45, -775],
      [261, -1215],
      [-147, -730],
      [-145, -498],
    ],
    [
      [27080, 40974],
      [-7, 0],
      [-259, -17],
      [-306, -37],
      [-240, 44],
      [-177, 5],
      [-47, 19],
    ],
    [
      [20260, 36235],
      [124, 581],
      [-304, 759],
      [-166, 576],
      [-45, 77],
    ],
  ],
  transform: {
    scale: [0.000034312373649311334, 0.000033939554242823153],
    translate: [4.127642399848414, 11.538267973705974],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4]],
          type: "Polygon",
          properties: {
            name: "Binji",
            id: "SK001",
            nameAbbr: "Binji",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.91628266859,
            Shape_Area: 0.04384795011,
          },
        },
        {
          arcs: [[5, 6, 7, 8, 9]],
          type: "Polygon",
          properties: {
            name: "Bodinga",
            id: "SK002",
            nameAbbr: "Bodinga",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.00412230497,
            Shape_Area: 0.04603038936,
          },
        },
        {
          arcs: [[10, 11, 12, -6, 13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Dange-Shuni",
            id: "SK003",
            nameAbbr: "Dange-Shuni",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.39681373044,
            Shape_Area: 0.09935926306,
          },
        },
        {
          arcs: [[16, 17, 18, 19]],
          type: "Polygon",
          properties: {
            name: "Gada",
            id: "SK004",
            nameAbbr: "Gada",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.55355421671,
            Shape_Area: 0.09937772853,
          },
        },
        {
          arcs: [[20, 21, 22, 23, 24, 25]],
          type: "Polygon",
          properties: {
            name: "Goronyo",
            id: "SK005",
            nameAbbr: "Goronyo",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.32829761905,
            Shape_Area: 0.13730143794,
          },
        },
        {
          arcs: [[-4, 26, 27]],
          type: "Polygon",
          properties: {
            name: "Gudu",
            id: "SK006",
            nameAbbr: "Gudu",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.51703680048,
            Shape_Area: 0.33306649863,
          },
        },
        {
          arcs: [[28, -25, 29, 30, 31, -17]],
          type: "Polygon",
          properties: {
            name: "Gwadabawa",
            id: "SK007",
            nameAbbr: "Gwadabawa",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.57943314514,
            Shape_Area: 0.08395848879,
          },
        },
        {
          arcs: [[-26, -29, -20, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Illela",
            id: "SK008",
            nameAbbr: "Illela",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.59222867949,
            Shape_Area: 0.10808255411,
          },
        },
        {
          arcs: [[34, 35, -22, 36]],
          type: "Polygon",
          properties: {
            name: "Isa",
            id: "SK009",
            nameAbbr: "Isa",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.30804251141,
            Shape_Area: 0.17304253051,
          },
        },
        {
          arcs: [[37, 38, 39]],
          type: "Polygon",
          properties: {
            name: "Kebbe",
            id: "SK010",
            nameAbbr: "Kebbe",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.12908047832,
            Shape_Area: 0.22795577681,
          },
        },
        {
          arcs: [[40, 41, -16, 42, 43, 44, 45, -31]],
          type: "Polygon",
          properties: {
            name: "Kware",
            id: "SK011",
            nameAbbr: "Kware",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.34362920852,
            Shape_Area: 0.05836178413,
          },
        },
        {
          arcs: [[-36, 46, -11, -42, 47, -23]],
          type: "Polygon",
          properties: {
            name: "Rabah",
            id: "SK012",
            nameAbbr: "Rabah",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.02363099512,
            Shape_Area: 0.22035611067,
          },
        },
        {
          arcs: [[-37, -21, -34, 48]],
          type: "Polygon",
          properties: {
            name: "Sabon Birni",
            id: "SK013",
            nameAbbr: "Sabon Birni",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.8240994207,
            Shape_Area: 0.18645860627,
          },
        },
        {
          arcs: [[49, 50, 51, -8, 52]],
          type: "Polygon",
          properties: {
            name: "Shagari",
            id: "SK014",
            nameAbbr: "Shagari",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.84198670334,
            Shape_Area: 0.10742400588,
          },
        },
        {
          arcs: [[53, 54, 55, -2]],
          type: "Polygon",
          properties: {
            name: "Silame",
            id: "SK015",
            nameAbbr: "Silame",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.89623617105,
            Shape_Area: 0.05070340254,
          },
        },
        {
          arcs: [[56, 57, -44]],
          type: "Polygon",
          properties: {
            name: "Sokoto North",
            id: "SK016",
            nameAbbr: "Sokoto North",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.23498989188,
            Shape_Area: 0.00328624347,
          },
        },
        {
          arcs: [[-15, 58, -57, -43]],
          type: "Polygon",
          properties: {
            name: "Sokoto South",
            id: "SK017",
            nameAbbr: "Sokoto South",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.27995667451,
            Shape_Area: 0.00251894655,
          },
        },
        {
          arcs: [[59, -39, 60, 61, -51]],
          type: "Polygon",
          properties: {
            name: "Tambuwal",
            id: "SK018",
            nameAbbr: "Tambuwal",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.63876378873,
            Shape_Area: 0.18276977547,
          },
        },
        {
          arcs: [[-18, -32, -46, 62, -5, -28, 63]],
          type: "Polygon",
          properties: {
            name: "Tangazar",
            id: "SK019",
            nameAbbr: "Tangazar",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.91804164529,
            Shape_Area: 0.20465495466,
          },
        },
        {
          arcs: [[64, -53, -7, -13, 65]],
          type: "Polygon",
          properties: {
            name: "Tureta",
            id: "SK020",
            nameAbbr: "Tureta",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.62852137857,
            Shape_Area: 0.10710773272,
          },
        },
        {
          arcs: [[-58, -59, -14, -10, 66, -54, -1, -63, -45]],
          type: "Polygon",
          properties: {
            name: "Wamakko",
            id: "SK021",
            nameAbbr: "Wamakko",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.95633166013,
            Shape_Area: 0.04745472375,
          },
        },
        {
          arcs: [[-48, -41, -30, -24]],
          type: "Polygon",
          properties: {
            name: "Wurno",
            id: "SK022",
            nameAbbr: "Wurno",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.27939750305,
            Shape_Area: 0.05334833996,
          },
        },
        {
          arcs: [[-9, -52, -62, 67, -55, -67]],
          type: "Polygon",
          properties: {
            name: "Yabo",
            id: "SK023",
            nameAbbr: "Yabo",
            parent: "Sokoto",
            parentAbbr: "SK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.23030181823,
            Shape_Area: 0.06874346017,
          },
        },
      ],
    },
  },
};

const zamfara = {
  type: "Topology",
  arcs: [
    [
      [34018, 38769],
      [22, -833],
      [-6, -797],
      [-78, -960],
      [188, -870],
      [427, -778],
      [465, -595],
      [365, -439],
      [430, -418],
      [281, -419],
      [154, -658],
      [258, -692],
      [319, -568],
      [251, -317],
      [181, -128],
      [226, -235],
      [137, -19],
      [263, 2],
      [234, -59],
      [-16, -190],
      [-5, -30],
    ],
    [
      [38114, 29766],
      [-32, -160],
    ],
    [
      [38082, 29606],
      [-69, -349],
      [-461, -617],
      [-448, -543],
      [-527, -638],
      [-578, -550],
      [-310, -534],
      [-305, -650],
      [-518, -502],
      [-198, -532],
      [-43, -647],
      [-126, -131],
      [-102, -199],
      [-800, -511],
      [-584, -20],
      [-574, 124],
      [-676, 387],
      [-501, 185],
      [-997, -64],
      [-799, 19],
      [-1259, -40],
      [-907, 19],
      [-540, -196],
      [-589, -94],
      [-482, -11],
    ],
    [
      [25689, 23512],
      [20, 517],
      [172, 1036],
      [69, 1097],
      [1, 1001],
      [321, 1221],
      [-46, 1334],
      [8, 674],
      [64, 239],
      [-41, 1436],
      [-102, 693],
      [-193, 1350],
      [-13, 938],
      [-206, 829],
      [-394, 417],
      [-557, 164],
      [-373, 81],
      [-386, 54],
      [-253, 364],
      [-75, 267],
      [-58, 513],
      [-62, 823],
      [48, 763],
      [29, 622],
      [72, 612],
      [93, 646],
      [213, 684],
      [123, 614],
      [263, 515],
      [32, 119],
    ],
    [
      [24458, 43135],
      [200, 25],
      [620, 117],
      [587, 214],
      [525, -28],
      [883, 236],
      [352, 142],
      [165, 38],
    ],
    [
      [27790, 43879],
      [163, -120],
      [65, -513],
      [169, -533],
      [210, -618],
      [-22, -457],
      [439, -686],
      [30, -309],
      [66, -321],
      [166, -745],
      [65, -354],
      [333, -406],
      [516, -94],
      [616, -92],
      [624, 26],
      [797, -19],
      [537, -63],
      [716, -61],
      [451, 127],
      [287, 128],
    ],
    [
      [33197, 51826],
      [-346, -265],
      [-549, -470],
      [-521, -535],
      [-356, -374],
      [-419, -530],
      [-709, -1218],
      [-502, -407],
      [-270, -278],
      [-311, -214],
      [-295, -265],
      [-481, -368],
      [-279, -205],
      [-238, -257],
      [-190, -320],
      [-87, -248],
      [31, -1625],
      [216, -346],
      [-101, -22],
    ],
    [
      [24458, 43135],
      [-217, -28],
      [-579, -49],
      [-346, -17],
      [-420, -139],
      [-469, -109],
      [-300, -108],
      [-530, -59],
      [-585, -48],
      [-455, 3],
      [-442, -47],
      [-558, -13],
      [-572, 55],
      [-653, -8],
      [-605, -20],
      [-762, -44],
      [-564, -67],
      [-573, -129],
    ],
    [
      [15828, 42308],
      [146, 498],
      [147, 729],
      [-262, 1215],
      [-44, 775],
      [-273, 1017],
      [-173, 726],
      [-230, 426],
      [-7, 510],
      [-131, 604],
      [113, 232],
      [310, 208],
      [526, 353],
      [270, 194],
      [176, 77],
      [162, 63],
      [484, 482],
      [403, 392],
      [633, 967],
      [342, 419],
      [455, 597],
      [486, 754],
      [359, 610],
      [197, 352],
      [62, 113],
      [209, 200],
      [310, 88],
      [63, 18],
      [659, 96],
      [944, 107],
      [890, 133],
      [851, -4],
      [1637, 223],
      [708, 15],
      [346, 45],
      [537, 34],
      [639, 55],
      [400, 120],
      [650, 300],
      [852, 350],
      [634, 464],
      [55, 150],
    ],
    [
      [30363, 57015],
      [38, -40],
      [438, -669],
      [257, -793],
      [251, -550],
      [326, -671],
      [317, -335],
      [670, -826],
      [366, -630],
      [78, -207],
      [254, -345],
      [-161, -123],
    ],
    [
      [59301, 51911],
      [109, -761],
      [286, -1121],
      [96, -1006],
      [84, -1218],
      [112, -414],
      [510, -1084],
      [-67, -1028],
      [-69, -362],
    ],
    [
      [60362, 44917],
      [-333, -91],
      [-1108, -345],
      [-568, -135],
      [-793, 135],
      [-603, 15],
      [-440, 9],
      [-859, -111],
      [-459, -86],
      [-622, -122],
      [-717, -184],
      [-622, -74],
      [-420, -11],
      [-236, -105],
      [-88, 13],
      [75, -87],
      [-161, -9],
    ],
    [
      [52408, 43729],
      [-1, 55],
      [-110, 114],
      [-170, 128],
      [-231, 127],
      [-242, 167],
      [-232, 230],
      [-221, 161],
      [-232, 208],
      [-378, 160],
      [-319, 146],
      [-273, 120],
      [-287, 78],
      [-275, 18],
      [-290, 344],
      [-343, 269],
      [-236, 412],
      [-242, 433],
      [-99, 421],
      [-92, 401],
      [-136, 509],
      [-12, 510],
      [-76, 598],
      [18, 313],
      [203, 411],
      [212, 165],
      [109, 294],
      [346, -58],
      [465, 386],
      [304, 160],
      [301, -11],
      [62, 26],
    ],
    [
      [49931, 51024],
      [100, -99],
      [118, -26],
      [151, 2],
      [125, 49],
      [441, 140],
      [237, 98],
      [334, -58],
      [50, -136],
      [-83, -279],
      [-249, -445],
      [41, -327],
      [137, 2],
      [139, 29],
      [601, 332],
      [292, 269],
      [267, 350],
      [153, 131],
      [277, 125],
      [376, 174],
      [389, 99],
      [288, 51],
      [230, 9],
      [245, 159],
      [607, -137],
      [637, 116],
      [375, 78],
      [484, -42],
      [245, 172],
      [258, 139],
      [839, 90],
      [675, 184],
      [534, 32],
    ],
    [
      [59244, 52305],
      [57, -394],
    ],
    [
      [25689, 23512],
      [-17, -444],
      [51, -905],
      [-90, -371],
    ],
    [
      [25633, 21792],
      [-273, 327],
      [-700, 306],
      [-906, 339],
      [-443, 186],
      [-1246, 138],
      [-1034, 153],
      [-576, -380],
      [-601, -203],
      [-569, -74],
      [-524, 124],
      [-772, 25],
      [-492, 88],
      [-730, -195],
      [-712, -68],
      [-508, -124],
      [-625, 29],
      [-527, 278],
      [-240, 239],
      [-298, 329],
      [-651, 285],
      [-215, 31],
      [-197, 29],
      [-202, -2],
      [-96, 0],
      [-256, -2],
    ],
    [
      [12240, 23650],
      [43, 64],
      [75, 671],
      [-1, 529],
      [56, 247],
      [230, 646],
      [154, 616],
      [173, 742],
      [98, 620],
      [74, 783],
      [48, 637],
      [115, 1790],
      [110, 1281],
      [-118, 1090],
      [181, 275],
      [251, 50],
      [286, -10],
      [349, 129],
      [400, 245],
      [402, 422],
      [157, 669],
      [132, 736],
      [-13, 952],
      [143, 532],
      [134, 654],
      [117, 907],
      [-90, 1060],
      [-134, 869],
      [-85, 741],
      [-87, 291],
    ],
    [
      [15440, 41888],
      [332, 228],
      [56, 192],
    ],
    [
      [52408, 43729],
      [8, -231],
      [9, -368],
      [-30, -517],
      [-22, -586],
      [-10, -483],
      [86, -564],
      [44, -381],
      [84, -469],
      [72, -435],
      [10, -402],
      [50, -380],
      [-64, -443],
      [-79, -300],
      [-222, -214],
      [-216, -84],
      [-324, -125],
      [-257, 4],
      [-447, 30],
      [-401, 132],
      [-312, 71],
      [-503, 275],
      [-517, 389],
      [-186, 332],
      [-269, 603],
      [-258, 508],
      [-184, 393],
      [-112, 352],
      [-359, 521],
      [-179, 345],
      [-370, 207],
      [-481, 233],
      [-402, -147],
      [-254, -329],
      [-319, -323],
      [-182, -342],
      [-119, -484],
      [-91, -777],
      [-100, -607],
      [-127, -586],
      [-16, -565],
      [-26, -436],
      [78, -462],
      [-98, -423],
      [-170, -410],
      [-273, -261],
      [-179, -179],
      [-71, -341],
      [61, -312],
      [132, -380],
      [79, -476],
      [72, -557],
      [-104, -594],
      [-152, -423],
      [-280, -507],
      [-334, -568],
      [-45, -402],
      [-254, -411],
      [-322, -350],
      [-255, -234],
      [-268, -377],
      [-52, -273],
      [-58, -450],
      [-99, -300],
      [-60, -123],
      [-7, 0],
      [-160, -206],
      [-25, -239],
      [58, -523],
      [-122, -528],
    ],
    [
      [42425, 27237],
      [-343, 251],
      [-88, -22],
      [-326, 93],
      [-245, 140],
      [-252, 181],
      [-259, 257],
      [-237, 58],
      [-252, 114],
      [-189, 79],
      [-143, 101],
      [-147, 189],
      [-338, 187],
      [-229, 45],
      [-175, 101],
      [-130, 113],
      [-11, 9],
      [-118, 86],
      [-299, 153],
      [-312, 195],
      [-250, 39],
    ],
    [
      [38114, 29766],
      [52, 78],
      [15, 149],
      [77, 321],
      [39, 395],
      [180, 166],
      [118, 62],
      [215, -86],
      [234, -141],
      [338, 256],
      [189, 328],
      [217, 519],
      [188, 295],
      [62, 171],
      [115, 219],
      [140, 593],
      [10, 293],
      [32, 347],
      [37, 736],
      [18, 687],
      [-39, 483],
      [121, 654],
      [-174, 182],
      [71, 321],
      [-63, 149],
      [-133, 332],
      [-121, 230],
      [-293, 548],
      [-159, 318],
      [-183, 366],
      [-149, 258],
      [75, 409],
      [56, 320],
      [71, 239],
      [244, 520],
      [161, 219],
      [298, 235],
      [258, 138],
      [497, 244],
      [269, 281],
      [277, 275],
      [99, 212],
      [109, 389],
      [-135, 278],
      [34, 361],
      [132, 267],
      [220, 343],
      [-20, 360],
      [-97, 394],
      [-135, 244],
      [-378, 350],
      [-121, 244],
      [-13, 327],
      [83, 174],
    ],
    [
      [41252, 45818],
      [97, 86],
      [343, 412],
      [288, 404],
      [84, 253],
      [108, 117],
      [197, 465],
      [125, 113],
      [72, 38],
      [7, -27],
      [-4, 29],
    ],
    [
      [42569, 47708],
      [236, 82],
      [253, 57],
      [336, 10],
      [413, -57],
      [660, -41],
      [384, -282],
      [243, -175],
      [330, -344],
      [114, -264],
      [198, -332],
      [180, -222],
      [291, -270],
      [195, -120],
      [277, -296],
      [308, -440],
      [242, -412],
      [219, -236],
      [274, -133],
      [287, -52],
      [448, 175],
      [447, 72],
      [539, 142],
      [302, 17],
      [207, -134],
      [378, -153],
      [509, -138],
      [233, -188],
      [352, -132],
      [204, -66],
      [292, -72],
      [488, 25],
    ],
    [
      [12240, 23650],
      [-387, -3],
      [-368, 445],
      [-179, 612],
      [-246, 490],
      [-272, 188],
      [-32, 22],
      [-205, 56],
      [-328, -36],
      [-640, -151],
      [-733, -115],
      [-532, 14],
      [-452, 129],
      [-426, 303],
      [-373, -72],
      [-500, -292],
      [-246, -458],
      [-141, -628],
      [-799, -261],
      [-382, 23],
      [-746, -172],
      [-1058, -278],
      [-575, 259],
      [-229, 330],
      [-616, 231],
      [-78, 37],
      [-436, 202],
      [-857, -3],
      [-404, -224],
      [47, 437],
      [68, 1021],
      [22, 892],
      [-46, 863],
      [132, 805],
      [65, 1272],
      [23, 1171],
      [-98, 1074],
      [8, 1735],
      [107, 1069],
      [-30, 1095],
      [44, 1076],
      [-88, 1312],
      [10, 1170],
      [44, 688],
      [115, 516],
      [26, 118],
      [70, 334],
      [184, 458],
      [162, 104],
      [270, 173],
      [335, -77],
      [322, -71],
      [253, -79],
      [294, -51],
      [1635, -294],
      [1551, -207],
      [830, 57],
      [110, 8],
      [1165, 88],
      [1243, -142],
      [504, 94],
      [623, 117],
      [1136, 88],
      [601, -136],
      [1494, 201],
      [627, -47],
      [754, 91],
      [828, 567],
    ],
    [
      [61483, 42201],
      [71, -219],
      [93, -625],
      [161, -584],
      [-36, -481],
      [-23, -302],
      [212, -556],
      [235, -603],
      [-3, -585],
      [-124, -498],
      [-199, -539],
      [-139, -478],
      [-51, -314],
      [-6, -16],
    ],
    [
      [61674, 36401],
      [-701, 362],
      [-1011, 534],
      [-825, 400],
      [-1065, 255],
      [-929, 154],
      [-988, 45],
      [-1043, 37],
      [-1048, -208],
      [-1127, -440],
      [-781, -559],
      [-1085, -732],
      [-928, -792],
      [-467, -815],
      [-520, -965],
      [-293, -1466],
      [-115, -934],
      [-32, -1110],
      [-287, -1357],
      [149, -924],
      [102, -911],
      [161, -612],
      [587, -640],
      [788, -340],
      [668, -415],
      [1051, -295],
      [764, 178],
      [1099, -376],
      [730, -195],
    ],
    [
      [54528, 24280],
      [-11, -28],
      [-219, -506],
      [-126, -403],
      [32, -557],
      [-199, -553],
      [-42, -654],
      [71, -502],
      [342, -411],
      [559, -484],
      [91, -455],
      [87, -693],
      [248, -582],
      [-135, -736],
      [-161, -683],
      [-45, -694],
      [-250, -751],
      [34, -617],
      [2, -29],
      [-45, -447],
      [-475, 13],
      [-436, -542],
      [-227, -197],
    ],
    [
      [53623, 13769],
      [-225, 342],
      [-147, 270],
      [-228, 222],
      [-242, 508],
      [5, 157],
      [-543, 198],
      [-205, 290],
      [-159, 379],
      [6, 61],
      [-502, 199],
      [-271, 52],
      [-287, 371],
      [-402, 288],
      [-260, 446],
      [-336, 466],
      [-292, 207],
      [-437, 479],
      [-356, 485],
      [-370, 554],
      [-50, 360],
      [-115, 530],
      [-215, 420],
      [-121, 373],
      [-387, 180],
      [-488, 22],
      [-352, 92],
      [-261, 399],
      [-234, 426],
      [-286, 318],
      [-206, 263],
      [-388, 255],
      [-535, 83],
      [-482, 138],
      [-305, 58],
      [-387, 78],
      [-474, 29],
      [-270, -84],
      [-528, 43],
      [-598, 164],
      [-230, 79],
      [-340, 160],
      [-426, -45],
      [-300, 263],
      [-272, 154],
      [45, 313],
      [167, 267],
      [417, 331],
      [411, 276],
      [429, 488],
      [370, 344],
      [228, 281],
      [214, 343],
      [22, 93],
    ],
    [
      [60362, 44917],
      [-54, -279],
      [461, -901],
      [385, -752],
      [164, -277],
      [165, -507],
    ],
    [
      [42569, 47708],
      [-4, 26],
      [-25, 217],
      [-58, 170],
      [-134, 189],
      [-268, 386],
      [-268, 378],
      [5, 422],
      [-142, 659],
      [37, 539],
      [-132, 482],
      [-97, 754],
      [126, 607],
      [186, 336],
      [301, 425],
      [135, 238],
    ],
    [
      [42231, 53536],
      [80, 36],
      [163, 104],
      [247, 2],
      [225, 50],
      [270, -86],
      [246, 78],
      [462, -105],
      [499, -233],
      [681, -14],
      [666, 6],
      [468, -36],
      [877, -291],
      [755, -306],
      [68, 28],
      [176, -196],
      [234, -385],
      [129, -312],
      [160, -74],
      [235, -99],
      [251, -202],
      [347, -58],
      [303, -262],
      [158, -157],
    ],
    [
      [36045, 64539],
      [268, -298],
      [187, -229],
      [214, -311],
      [119, -278],
      [300, -422],
      [522, -516],
      [335, -334],
      [535, -370],
      [382, -140],
      [596, 3],
      [396, 19],
      [434, 147],
      [350, 123],
      [639, 104],
      [400, 112],
      [334, -109],
      [227, -101],
      [0, -6],
      [-2, -298],
      [-109, -322],
      [-328, -328],
      [-247, -177],
      [-161, -225],
    ],
    [
      [41436, 60583],
      [-114, -158],
      [-129, -225],
      [90, -471],
      [114, -246],
      [56, -427],
      [-172, -193],
      [-356, -368],
      [-212, -249],
      [-192, -346],
      [-68, -116],
      [292, -51],
      [204, -76],
      [276, 0],
      [320, 9],
      [411, -198],
      [232, -140],
      [248, -200],
      [92, -167],
      [-25, -153],
      [-270, -271],
      [-207, -136],
      [-329, -56],
      [-468, -215],
      [-263, -118],
      [-541, -324],
      [-186, -208],
      [-251, -270],
      [67, -314],
      [179, -203],
      [236, -54],
      [315, 35],
      [202, 30],
      [372, 38],
      [299, 81],
      [440, -150],
      [237, -195],
      [172, -373],
      [-78, -246],
      [-147, -233],
      [-51, -90],
    ],
    [
      [41252, 45818],
      [-261, -230],
      [-75, -15],
      [-41, 0],
      [-102, -35],
      [-344, 3],
      [-499, 220],
      [-625, 382],
      [-887, 400],
      [-1181, 349],
      [-607, 110],
      [-436, -16],
      [-370, -150],
      [-201, -98],
    ],
    [
      [35623, 46738],
      [-145, 87],
      [-288, 145],
      [-392, 60],
      [-351, 287],
      [-267, 449],
      [-47, 481],
      [249, 375],
      [108, 561],
      [149, 370],
      [-99, 571],
      [-95, 385],
      [-251, 397],
      [-465, 520],
      [-412, 316],
      [-120, 84],
    ],
    [
      [30363, 57015],
      [30, 82],
      [-80, 938],
      [30, 238],
      [5, 687],
      [-23, 646],
      [-116, 550],
      [66, 654],
      [-58, 734],
      [-129, 522],
      [-130, 549],
      [-35, 428],
      [-14, 164],
      [50, 327],
      [309, 296],
      [687, 552],
      [576, 204],
      [589, 211],
      [644, 178],
      [475, 135],
      [517, 12],
      [360, 5],
      [436, -16],
      [253, -99],
      [344, -213],
      [430, -91],
      [242, -269],
      [224, 100],
    ],
    [
      [53623, 13769],
      [-441, -381],
      [-751, -444],
      [-183, -138],
      [-358, -249],
      [-402, -535],
      [-449, -665],
      [-356, -935],
      [-172, -955],
      [-126, -715],
      [-365, -787],
      [-346, -731],
      [-807, -866],
      [-626, -592],
      [-1097, -712],
      [-2217, -289],
      [-1407, -22],
      [-1220, 150],
      [-1111, 137],
      [-1069, -127],
      [-994, -92],
      [-594, -225],
      [-438, -249],
      [-491, -414],
      [-295, -411],
      [-375, -501],
      [-343, -235],
      [-538, -407],
      [-295, -363],
      [-254, -479],
      [-267, -507],
      [0, -27],
      [-201, -247],
      [-208, -213],
      [-121, -117],
      [-254, -214],
      [-349, -146],
      [-476, -66],
      [-315, 10],
      [33, 395],
      [-359, 923],
      [-387, 1003],
      [-672, 1103],
      [-509, 975],
      [-611, 1043],
      [-492, 627],
      [-308, 379],
      [-377, 547],
      [-321, 467],
      [-62, 49],
      [-5, 651],
      [8, 708],
      [-56, 156],
      [-58, 510],
      [9, 552],
      [-162, 917],
      [-222, 1828],
      [-263, 814],
      [-388, 779],
      [-521, 634],
      [-720, 442],
      [-716, 299],
      [-1099, 533],
      [2, 565],
      [283, 554],
      [163, 804],
      [282, 854],
      [235, 833],
      [6, 1157],
      [-94, 231],
      [-177, 304],
      [-121, 146],
    ],
    [
      [34018, 38769],
      [-3, 106],
      [223, 595],
      [174, 941],
      [-31, 891],
      [229, 1010],
      [277, 942],
      [471, 1251],
      [348, 507],
      [415, 229],
      [508, 365],
      [-137, 326],
      [-197, 263],
      [-603, 502],
      [-69, 41],
    ],
    [
      [46910, 65988],
      [1, -8],
      [71, -234],
      [97, -749],
      [1, -238],
      [-67, -366],
      [38, -408],
      [3, -331],
      [-65, -313],
      [36, -309],
      [195, -262],
      [192, -189],
      [181, -156],
      [304, -180],
      [223, -570],
      [-88, -416],
      [10, -510],
      [37, -497],
      [-158, -403],
      [-276, -302],
      [-291, -228],
      [-414, -235],
      [-213, -9],
      [-496, 43],
      [-287, -57],
      [-322, -51],
      [-235, 93],
      [-340, 361],
      [-322, 368],
      [-268, 165],
      [-325, 24],
      [-378, -171],
      [-463, 17],
      [-230, -77],
      [-228, -183],
      [-159, -41],
      [-260, -66],
      [-245, -25],
      [-117, 67],
      [-144, 270],
      [-115, 87],
      [-193, 169],
      [-200, 136],
      [61, 254],
      [-25, 125],
    ],
    [
      [36045, 64539],
      [12, 5],
      [55, 443],
      [287, 438],
      [25, 661],
      [127, 722],
      [-61, 455],
      [111, 342],
      [77, 375],
      [477, -56],
      [598, 68],
      [442, 6],
      [498, -70],
      [327, -37],
      [383, -124],
      [150, -99],
      [155, 7],
      [378, -100],
      [149, -171],
      [304, -336],
      [349, -452],
      [527, -146],
      [369, -160],
      [418, -39],
      [650, -180],
      [722, 16],
      [410, -79],
      [1008, -182],
      [863, -56],
      [404, -79],
      [441, 180],
      [210, 97],
    ],
    [
      [61674, 36401],
      [-220, -605],
      [-361, -596],
      [-267, -486],
      [355, -370],
      [238, -195],
      [326, -132],
      [334, -187],
      [529, -225],
      [519, -491],
      [265, -215],
      [477, -369],
      [205, -243],
      [342, -425],
      [237, -487],
      [-1, -318],
      [0, -111],
      [-147, -212],
      [-209, -227],
      [-162, -124],
      [-373, -194],
      [-429, 36],
      [-720, 312],
      [-420, 29],
      [-79, -355],
      [-38, -347],
      [-83, -538],
      [-59, -259],
      [-223, -139],
      [-66, -137],
      [-445, -222],
      [-154, -294],
      [-471, -359],
      [-282, -214],
      [-660, -497],
      [-196, -2],
      [-157, 189],
      [-246, 222],
      [-927, -51],
      [-413, 9],
      [-466, -73],
      [-433, -12],
      [-345, -38],
      [-529, 104],
      [-378, -113],
      [-330, -283],
      [-132, -335],
      [-69, -769],
      [-102, -770],
      [-381, -1003],
    ],
    [
      [59467, 63207],
      [-4, -25],
      [-123, -642],
      [-5, -1],
      [-107, -591],
      [-159, -920],
      [-32, -436],
      [-29, -592],
      [-79, -906],
      [-139, -940],
      [169, -931],
      [134, -657],
      [18, -90],
      [146, -1203],
      [20, -973],
      [88, -918],
      [-142, -934],
      [21, -143],
    ],
    [
      [46910, 65988],
      [316, 147],
      [464, 267],
      [607, 380],
      [725, 486],
      [579, 312],
      [554, 444],
      [460, 459],
      [386, 288],
      [3, 2],
      [23, 18],
      [207, -353],
      [35, -59],
      [34, -59],
      [22, -36],
      [5, -10],
      [24, -40],
      [67, -115],
      [37, -63],
      [3, -8],
      [14, -36],
      [9, -22],
      [3, -9],
      [2, -5],
      [16, -41],
      [13, -33],
      [15, -37],
      [8, -21],
      [37, -55],
      [43, -64],
      [33, -48],
      [334, -498],
      [23, -35],
      [101, -164],
      [102, -166],
      [103, -167],
      [2, -3],
      [73, -84],
      [75, -89],
      [109, -128],
      [97, -113],
      [97, -115],
      [99, -115],
      [13, -16],
      [55, -65],
      [4, -4],
      [328, -385],
      [1096, -1286],
      [79, -136],
      [384, -386],
      [254, -383],
      [423, -467],
      [177, -37],
      [364, 83],
      [867, -7],
      [46, -1],
      [33, 0],
      [289, -3],
      [311, -2],
      [11, 0],
      [9, 0],
      [108, 6],
      [176, 9],
      [197, 42],
      [163, 35],
      [271, 57],
      [53, 11],
      [19, 5],
      [4, 0],
      [74, 16],
      [27, 6],
      [23, 5],
      [14, 3],
      [13, 2],
      [49, 8],
      [51, 9],
      [55, 9],
      [49, 9],
      [35, 6],
      [44, 7],
      [30, 5],
      [27, 4],
      [50, 6],
      [44, 5],
      [56, 6],
      [56, 7],
      [56, 7],
      [30, 3],
      [51, 6],
      [5, 0],
      [3, 1],
      [22, 7],
    ],
  ],
  transform: {
    scale: [0.00003431252690578663, 0.000033939884669427776],
    translate: [5.023483343948367, 10.855990581116583],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Anka",
            id: "ZM001",
            nameAbbr: "Anka",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.10464029208,
            Shape_Area: 0.19959841603,
          },
        },
        {
          arcs: [[6, -5, 7, 8, 9]],
          type: "Polygon",
          properties: {
            name: "Bakura",
            id: "ZM002",
            nameAbbr: "Bakura",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.84836697196,
            Shape_Area: 0.1960385677,
          },
        },
        {
          arcs: [[10, 11, 12, 13, 14]],
          type: "Polygon",
          properties: {
            name: "Birnin-Magaji/Kiyaw",
            id: "ZM003",
            nameAbbr: "Birnin-Magaji/Kiyaw",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.31203876743,
            Shape_Area: 0.0921549036,
          },
        },
        {
          arcs: [[15, 16, 17, 18, -8, -4]],
          type: "Polygon",
          properties: {
            name: "Bukkuyum",
            id: "ZM004",
            nameAbbr: "Bukkuyum",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.25643205715,
            Shape_Area: 0.25862760883,
          },
        },
        {
          arcs: [[19, 20, -2, 21, 22, 23]],
          type: "Polygon",
          properties: {
            name: "Bungudu",
            id: "ZM005",
            nameAbbr: "Bungudu",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.33416030877,
            Shape_Area: 0.14299934431,
          },
        },
        {
          arcs: [[24, -18]],
          type: "Polygon",
          properties: {
            name: "Gummi",
            id: "ZM006",
            nameAbbr: "Gummi",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.27615990265,
            Shape_Area: 0.26985695758,
          },
        },
        {
          arcs: [[25, 26, 27, 28, -20, -12, 29]],
          type: "Polygon",
          properties: {
            name: "Gusau",
            id: "ZM007",
            nameAbbr: "Gusau",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.76188809192,
            Shape_Area: 0.25314462835,
          },
        },
        {
          arcs: [[-13, -24, 30, 31]],
          type: "Polygon",
          properties: {
            name: "Kaura Namoda",
            id: "ZM008",
            nameAbbr: "Kaura Namoda",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.28627602326,
            Shape_Area: 0.05616168687,
          },
        },
        {
          arcs: [[32, 33, -31, -23, 34, 35, -10, 36]],
          type: "Polygon",
          properties: {
            name: "Maradun",
            id: "ZM009",
            nameAbbr: "Maradun",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.14279904334,
            Shape_Area: 0.19331356434,
          },
        },
        {
          arcs: [[[-29, 37, -16, -3, -21]], [[-22, -1, 38, -35]]],
          type: "MultiPolygon",
          properties: {
            name: "Maru",
            id: "ZM010",
            nameAbbr: "Maru",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.87954373131,
            Shape_Area: 0.64336146292,
          },
        },
        {
          arcs: [[39, -33, 40]],
          type: "Polygon",
          properties: {
            name: "Shinkafi",
            id: "ZM011",
            nameAbbr: "Shinkafi",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.31581838988,
            Shape_Area: 0.07639355057,
          },
        },
        {
          arcs: [[-6, -7, -36, -39]],
          type: "Polygon",
          properties: {
            name: "Talata-Mafara",
            id: "ZM012",
            nameAbbr: "Talata-Mafara",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.23615044121,
            Shape_Area: 0.08410270536,
          },
        },
        {
          arcs: [[-27, 41]],
          type: "Polygon",
          properties: {
            name: "Tsafe",
            id: "ZM013",
            nameAbbr: "Tsafe",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.74301686991,
            Shape_Area: 0.17029238007,
          },
        },
        {
          arcs: [[42, -14, -32, -34, -40, 43]],
          type: "Polygon",
          properties: {
            name: "Zurmi",
            id: "ZM014",
            nameAbbr: "Zurmi",
            parent: "Zamfara",
            parentAbbr: "ZM",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.56687398343,
            Shape_Area: 0.23081924228,
          },
        },
      ],
    },
  },
};

const kaduna = {
  type: "Topology",
  arcs: [
    [
      [32742, 62739],
      [-207, -431],
      [-151, -437],
      [-230, -730],
      [-130, -573],
      [-48, -613],
      [-263, -1553],
      [-117, -505],
      [-260, -445],
      [-239, -554],
      [-301, -438],
      [-243, -207],
      [-308, -384],
      [-125, -410],
      [118, -624],
      [149, -420],
      [224, -364],
      [210, -412],
      [58, -168],
    ],
    [
      [30879, 53471],
      [120, -354],
      [157, -460],
      [-8, -415],
      [112, -685],
      [157, -474],
      [127, -298],
      [284, -336],
      [90, -94],
      [77, -129],
      [-39, -122],
      [-28, -23],
    ],
    [
      [31928, 50081],
      [-150, 61],
      [-382, -11],
      [-423, -94],
      [-1304, 19],
      [-977, -32],
      [-694, -123],
      [-1318, 508],
      [-1125, 1028],
      [-1141, 627],
      [-966, 738],
      [-876, 576],
      [-443, 151],
      [-408, 9],
      [-295, -363],
      [-122, -682],
      [-177, -662],
      [-231, -621],
      [-696, -389],
      [-409, -514],
      [-186, -445],
      [-150, -634],
      [-146, -294],
      [-456, -535],
      [21, -89],
      [-80, -150],
      [61, -106],
    ],
    [
      [18855, 48054],
      [-308, -312],
      [317, -1125],
      [-301, -466],
      [-1103, 273],
      [-768, -8],
      [-860, -281],
      [-697, -287],
      [-1129, -543],
      [-908, -234],
      [-242, 431],
      [-105, 184],
      [-138, 638],
      [-484, 471],
      [-697, 61],
      [-674, -123],
      [-985, -290],
      [-487, -352],
      [-670, -354],
      [-95, -117],
      [-485, -433],
      [-641, -572],
      [-688, -552],
      [-725, -443],
      [-577, -713],
      [-638, -762],
      [-622, -966],
      [-698, -865],
      [-663, -415],
      [-673, 278],
      [-231, 848],
      [-345, 812],
      [-910, 446],
      [-525, 498],
      [-71, 461],
      [-29, 184],
      [154, 1090],
      [485, 346],
      [382, 500],
      [-74, 802],
      [-42, 993],
      [352, 943],
      [-66, 693],
      [-316, 554],
      [-363, 568],
      [14, 775],
      [220, 561],
      [-48, 918],
      [389, 582],
      [394, 549],
      [395, 841],
      [241, 343],
      [-13, 142],
      [26, 75],
      [315, -10],
      [476, 66],
      [349, 147],
      [255, 213],
      [120, 117],
      [209, 213],
      [201, 247],
      [-1, 27],
      [267, 507],
      [254, 479],
      [295, 364],
      [538, 407],
      [344, 235],
      [375, 500],
      [294, 412],
      [491, 413],
      [438, 250],
      [594, 224],
      [995, 92],
      [1068, 127],
      [1112, -137],
      [1219, -150],
      [1408, 22],
      [2217, 289],
      [1096, 713],
      [627, 591],
      [807, 866],
      [345, 732],
      [365, 786],
      [127, 716],
      [172, 954],
      [356, 936],
      [448, 665],
      [402, 535],
      [358, 248],
      [183, 138],
      [751, 444],
      [441, 382],
      [227, 197],
      [437, 542],
      [474, -14],
      [393, -11],
      [712, -231],
      [972, -173],
      [913, 85],
      [640, -422],
      [372, -819],
      [-110, -504],
      [-419, -243],
      [-485, -482],
      [-456, -535],
      [-183, -621],
      [395, -152],
      [858, -148],
      [1173, -388],
      [566, -219],
      [432, -389],
      [494, -383],
      [373, -588],
      [699, -659],
      [824, -106],
      [447, 175],
      [337, 228],
      [216, 138],
    ],
    [
      [31928, 50081],
      [111, -44],
      [-58, -273],
      [237, -317],
      [-125, -341],
      [333, -397],
      [508, -185],
      [447, -219],
      [380, -315],
      [251, -398],
      [-292, -615],
      [-250, -697],
      [37, -658],
      [288, -954],
      [-91, -450],
      [-306, -371],
      [245, -683],
      [-92, -524],
      [-292, -750],
      [140, -800],
      [259, -635],
      [357, -206],
      [653, 382],
      [273, 344],
      [-47, 331],
      [157, 505],
      [23, 286],
      [-117, 514],
      [265, 262],
      [246, 295],
      [351, 256],
      [751, -78],
      [386, -9],
      [285, -165],
      [525, -367],
      [68, -178],
    ],
    [
      [37834, 42627],
      [71, -187],
      [345, -586],
      [456, -198],
      [466, 169],
      [135, 253],
      [97, 395],
      [100, 172],
      [84, 408],
      [64, 297],
    ],
    [
      [39652, 43350],
      [6, 30],
      [114, 178],
      [185, 166],
      [283, 105],
      [183, 46],
    ],
    [
      [40423, 43875],
      [506, 126],
      [470, 187],
      [650, 175],
      [582, 147],
      [752, 284],
      [683, 249],
      [389, 194],
      [633, 331],
      [734, 141],
      [637, 106],
      [1109, 320],
      [593, -139],
      [342, -500],
      [138, -409],
      [-74, -408],
      [777, -450],
      [355, -145],
      [125, -196],
    ],
    [
      [49824, 43888],
      [22, -34],
      [-51, -273],
      [-178, -240],
      [-95, -430],
      [-270, -370],
      [-512, -672],
      [-157, -431],
      [-139, -205],
      [-376, -412],
      [-365, -446],
      [-423, -481],
      [-431, -405],
      [-684, -409],
      [-768, -449],
      [-538, -397],
      [-533, -376],
      [-494, -551],
      [-285, -128],
      [348, -837],
      [256, -401],
      [344, -703],
      [138, -502],
      [300, -345],
      [102, -241],
    ],
    [
      [45035, 34150],
      [-375, -68],
      [-609, 43],
      [-393, -45],
      [-562, 8],
      [-380, -177],
      [-234, -228],
      [7, -291],
      [-79, -380],
      [-449, -172],
      [-155, 58],
      [-424, -117],
      [-469, -152],
      [-428, 59],
      [-483, 78],
      [-701, -122],
      [-520, -160],
      [-510, -337],
      [-388, -221],
      [-186, -376],
      [-103, -390],
      [-665, -147],
      [-257, 156],
      [-364, -43],
      [-396, -180],
      [-475, -324],
      [-745, -277],
      [-268, -29],
      [-494, -44],
      [-219, -97],
      [-387, -254],
      [-230, -154],
    ],
    [
      [33094, 29767],
      [-550, -62],
      [-853, -41],
      [-1020, 47],
      [-803, 92],
      [-1093, -8],
      [-838, -93],
      [-229, -23],
      [-671, 195],
      [-737, 201],
      [-761, 336],
      [-968, 442],
      [-539, 530],
      [-270, 255],
      [-484, 933],
      [429, 583],
      [1023, 644],
      [761, 791],
      [400, 698],
      [467, 297],
      [814, 364],
      [715, 566],
      [482, 842],
      [144, 932],
      [-710, 441],
      [-811, 384],
      [-515, 205],
      [-1049, 314],
      [-707, 264],
      [102, 483],
      [285, 670],
      [248, 798],
      [24, 1211],
      [-499, 1034],
      [-181, 311],
      [-253, 445],
      [-761, 209],
      [-592, -48],
      [-466, -270],
      [-653, 68],
      [14, 551],
      [-123, 801],
      [-22, 769],
      [-227, 902],
      [-288, 242],
      [-680, 60],
      [-687, 108],
      [-857, 66],
      [-250, -252],
    ],
    [
      [44036, 62809],
      [101, -80],
      [399, -29],
      [261, -51],
      [169, -66],
      [0, -7],
      [91, -53],
      [142, -643],
      [-41, -537],
      [1, -394],
      [-157, -491],
      [-204, -567],
      [-136, -382],
      [-105, -517],
      [5, -34],
    ],
    [
      [44562, 58958],
      [-568, -8],
      [-911, -100],
      [-418, 42],
      [-694, -23],
      [-299, -146],
      [-814, 99],
      [-1048, 225],
      [-379, 193],
      [-506, 103],
      [-561, 14],
      [-448, -162],
      [-235, -343],
      [-220, -328],
      [-24, -279],
      [4, -245],
      [-69, -463],
      [-69, -477],
      [-360, -596],
      [-188, -240],
      [-241, -377],
      [-396, -473],
      [-341, -555],
      [-310, -289],
      [-377, -392],
      [-434, -223],
      [-108, -69],
      [-572, -143],
      [-519, -40],
      [-670, 87],
      [-751, -36],
      [-675, -89],
      [-482, -154],
    ],
    [
      [32742, 62739],
      [270, 173],
      [384, 324],
      [266, 574],
      [-43, 184],
      [-85, 298],
      [-359, 567],
      [-365, 527],
      [-163, 542],
      [47, 185],
      [58, 232],
      [270, 193],
      [562, 203],
      [793, 118],
      [918, -52],
      [559, -191],
      [66, -28],
      [678, -297],
      [660, 41],
      [270, 255],
      [43, 293],
      [-45, 360],
      [158, 505],
      [198, 533],
      [207, 356],
      [217, 203],
      [349, 327],
      [464, 399],
      [412, 290],
      [654, 130],
      [747, -229],
      [960, -267],
      [982, -369],
      [501, -212],
      [477, -355],
      [491, -456],
      [454, -742],
      [243, -119],
    ],
    [
      [45040, 67234],
      [-3, -457],
      [-30, -292],
      [-26, -36],
    ],
    [
      [44981, 66449],
      [-101, -136],
      [-353, -221],
      [-516, -170],
      [-99, -742],
      [-169, -620],
      [-122, -607],
      [39, -849],
      [376, -295],
    ],
    [
      [44562, 58958],
      [104, -617],
      [184, -405],
      [512, -333],
      [645, -182],
      [947, -116],
      [896, -2],
      [740, 10],
      [498, 68],
      [431, 107],
      [144, 35],
      [97, 63],
    ],
    [
      [49760, 57586],
      [134, 88],
      [192, -367],
      [186, -402],
      [149, -258],
      [-102, -444],
      [-75, -458],
      [-234, -738],
      [-321, -534],
      [-388, -221],
      [-189, -206],
      [-119, -246],
      [-20, -69],
      [2, -471],
      [311, -441],
      [344, -455],
      [442, -469],
      [484, -488],
      [219, -604],
      [321, -724],
      [199, -658],
      [684, -405],
      [752, -118],
      [455, -64],
      [364, -57],
      [36, -14],
      [479, -64],
      [474, -43],
    ],
    [
      [54539, 48656],
      [-11, -96],
      [180, -877],
      [553, -1118],
      [758, -1267],
      [80, -156],
    ],
    [
      [56099, 45142],
      [-83, -21],
      [-443, -65],
      [-432, 50],
      [-367, 99],
      [-280, -105],
      [-192, -151],
      [-99, -137],
      [-309, -78],
      [-342, 127],
      [-359, 30],
      [-308, -173],
      [-278, -281],
      [-244, -241],
      [-183, -233],
      [-212, -186],
      [-914, -185],
      [-192, 66],
      [12, 225],
      [-253, 189],
      [-378, 17],
      [-419, -201],
    ],
    [
      [40423, 43875],
      [1, 25],
      [-25, 101],
      [-24, 109],
      [-6, 67],
      [-37, 129],
      [-12, 108],
      [-19, 75],
      [-5, 54],
      [46, 55],
      [54, 116],
      [-17, 197],
      [-157, 11],
      [-51, 61],
      [-12, 102],
      [-3, 231],
      [190, 29],
      [36, 259],
      [-29, 230],
      [-144, -15],
      [8, 156],
      [132, 36],
      [119, 110],
      [0, 41],
      [14, 54],
      [14, 48],
      [-49, 244],
      [14, 61],
      [-30, 190],
      [41, 130],
      [68, 211],
      [-50, 156],
      [-52, 19],
      [-32, 81],
      [-18, 102],
      [49, 184],
      [54, 143],
      [-17, 184],
      [-5, 142],
      [-130, 32],
      [-347, 23],
      [-182, 52],
      [-131, 18],
      [-360, -24],
      [-124, 5],
      [-192, -146],
      [24, -183],
      [-16, -224],
      [2, -285],
      [-22, -157],
      [-46, -75],
      [-60, -42],
      [-27, -102],
      [90, -114],
      [44, -122],
      [3, -203],
      [-54, -185],
      [-87, -143],
      [-94, -151],
      [-80, -96],
      [-20, -68],
      [-22, -170],
      [29, -224],
      [30, -71],
    ],
    [
      [38697, 45456],
      [-51, -79],
      [-42, -197],
      [-48, -157],
      [-69, -205],
      [-48, -191],
      [-87, -164],
      [-69, -239],
      [-100, -157],
      [-115, -246],
      [-22, -170],
      [-29, -211],
      [-15, -163],
      [-3, -177],
      [-100, -137],
      [-2, -170],
      [-21, -95],
      [-42, -71],
    ],
    [
      [61472, 67905],
      [729, -181],
      [800, -335],
      [711, -740],
      [431, -766],
      [515, -431],
      [719, -260],
      [804, 62],
      [504, -50],
      [1153, -276],
      [546, -403],
      [688, -532],
      [682, -736],
      [7, -4],
      [609, -405],
      [112, -146],
    ],
    [
      [70482, 62702],
      [-179, -59],
      [-758, -102],
      [-511, -5],
      [-539, 42],
      [-660, 212],
      [-416, 453],
      [-312, 344],
      [-420, 371],
      [-239, 454],
      [-198, 67],
      [-232, -336],
      [115, -503],
      [236, -380],
      [270, -338],
      [205, -400],
      [42, -360],
      [-81, -382],
      [-345, -283],
      [-612, -25],
      [-650, 307],
      [-734, 96],
      [-875, 210],
      [-1021, 215],
      [-678, 1],
      [-713, -81],
      [-493, -182],
    ],
    [
      [60684, 62038],
      [-124, 81],
      [-277, 225],
      [-278, 177],
      [-217, 171],
      [-439, 167],
      [-501, 287],
      [-444, 207],
      [-372, 380],
      [-389, 230],
      [-346, 155],
      [-326, 148],
      [-383, 216],
      [-285, 196],
      [-201, 164],
    ],
    [
      [56102, 64842],
      [88, 7],
      [523, -32],
      [619, 4],
      [666, 65],
      [472, 126],
      [410, 180],
      [134, 164],
      [101, 171],
      [-31, 150],
      [-260, 340],
      [-104, 40],
      [-335, 688],
      [-174, 115],
      [-121, 197],
      [-142, 265],
      [-157, 299],
      [-107, 204],
      [-239, 333],
      [-260, 279],
      [-370, 305],
      [-212, 420],
      [-249, 452],
      [-249, 445],
      [-315, 417],
      [-171, 432],
      [-210, 432],
      [-157, 440],
      [-190, 472],
      [-170, 163],
    ],
    [
      [54892, 72415],
      [984, 541],
      [560, -2],
      [219, -1],
      [632, 266],
      [722, 220],
      [564, 96],
      [575, -87],
      [534, -384],
      [148, -586],
      [-312, -650],
      [-379, -705],
      [-300, -534],
      [10, -573],
      [238, -633],
      [492, -557],
      [751, -460],
      [908, -397],
      [234, -64],
    ],
    [
      [55365, 8757],
      [-3, 64],
      [-161, 418],
      [-238, 157],
      [-64, 42],
      [-230, 173],
      [-97, 70],
      [-105, 76],
      [-127, 229],
      [-204, 214],
      [-203, 187],
      [-39, 41],
      [-70, 73],
      [-237, 125],
      [-131, 26],
      [-115, 23],
    ],
    [
      [53341, 10675],
      [-12, 90],
      [-99, 236],
      [-166, 581],
      [-69, 637],
      [111, 273],
      [153, 294],
      [190, 220],
      [291, 161],
      [228, 362],
      [261, 215],
      [316, 256],
      [365, 256],
      [361, 168],
      [277, 378],
      [142, 192],
      [132, 287],
      [42, 441],
      [-2, 41],
    ],
    [
      [55862, 15763],
      [315, 100],
      [651, 132],
      [625, 70],
      [471, 258],
      [2, 381],
      [90, 259],
      [182, 260],
      [163, 193],
      [193, 145],
      [346, 195],
      [261, 215],
      [57, -227],
    ],
    [
      [59218, 17744],
      [112, -443],
      [45, -549],
      [305, -504],
      [621, -920],
      [453, -550],
      [157, -128],
    ],
    [
      [60911, 14650],
      [-159, -21],
      [-666, -309],
      [-849, -331],
      [-352, -230],
      [-551, -422],
      [-400, -481],
      [-240, -438],
      [-249, -390],
      [-22, -414],
      [-16, -584],
      [-79, -422],
      [-419, -563],
      [-377, -345],
      [-390, -345],
      [-425, -386],
      [-318, -195],
      [-34, -17],
    ],
    [
      [71472, 15480],
      [37, -454],
      [228, -376],
      [314, -557],
      [334, -490],
      [42, -79],
    ],
    [
      [72427, 13524],
      [-397, -103],
      [-440, -129],
      [-287, -181],
      [-497, -368],
      [-323, -487],
      [-455, -366],
      [-359, -393],
      [-257, -295],
      [-271, -269],
      [-132, -674],
      [-36, -692],
      [-279, -588],
      [-666, -207],
      [-377, -176],
      [-98, -625],
      [135, -595],
      [59, -534],
      [-927, 52],
      [-349, 185],
      [-334, 164],
      [-797, -237],
      [-563, -165],
      [-382, 62],
      [-624, 270],
      [-515, -13],
      [-355, -79],
      [-97, -321],
      [105, -474],
      [-51, -293],
      [-281, -214],
      [-614, -429],
      [-282, -554],
      [-307, -534],
      [-345, -446],
      [-359, -290],
      [-328, -575],
      [-341, -609],
      [-349, -697],
      [-126, -444],
      [-183, -417],
      [7, -305],
      [65, -83],
    ],
    [
      [59415, 396],
      [-16, -12],
      [-75, -50],
      [-160, -109],
      [-207, -70],
      [-107, -28],
      [-5, -1],
      [-84, 0],
      [-140, 0],
      [-146, 29],
      [-173, 66],
      [-100, 114],
      [-126, 141],
      [-88, 101],
      [-20, 265],
      [-83, 33],
      [-5, 47],
      [-36, 143],
      [-42, 135],
      [-24, 373],
      [37, 490],
      [-9, 475],
      [70, 552],
      [34, 720],
      [26, 599],
      [-13, 284],
      [-16, 335],
      [-10, 230],
      [-29, 209],
      [-44, 320],
      [-88, 185],
      [-102, 213],
      [-295, 363],
      [-11, 5],
      [-199, 88],
      [-194, 163],
      [-77, 64],
      [-76, 69],
      [-160, 145],
      [-65, 53],
      [-139, 45],
      [-182, 59],
      [-341, 117],
      [-138, 51],
      [-197, 73],
      [-205, 221],
      [-29, 200],
      [-6, 44],
      [8, 77],
      [19, 181],
      [23, 373],
      [-5, 181],
    ],
    [
      [60911, 14650],
      [502, 70],
      [1083, 115],
      [665, 131],
      [232, 295],
      [98, 402],
      [86, 375],
      [183, 166],
      [224, 206],
      [215, 146],
      [364, 161],
      [120, -24],
    ],
    [
      [64683, 16693],
      [110, -21],
      [271, -248],
      [217, -391],
      [240, -473],
      [333, -675],
      [424, -613],
      [106, -175],
      [305, -173],
      [425, 12],
      [402, 182],
      [396, 168],
      [362, 72],
      [427, 142],
      [281, 187],
      [754, 243],
      [768, 256],
      [487, 184],
      [481, 110],
    ],
    [
      [45035, 34150],
      [83, -193],
      [227, -325],
      [428, -698],
      [228, -427],
      [526, -444],
      [326, -440],
      [388, -514],
      [465, -459],
      [345, -420],
      [670, -265],
      [338, -86],
      [406, 86],
      [472, 134],
      [406, 85],
      [359, 85],
      [400, 154],
      [260, 111],
      [279, 180],
      [280, 200],
      [572, 216],
      [699, 150],
      [645, 251],
      [593, 196],
      [751, 130],
      [705, 129],
      [505, 114],
      [352, -99],
      [100, -118],
    ],
    [
      [56843, 31883],
      [165, -192],
      [-81, -416],
      [-394, -467],
      [-301, -439],
      [-395, -718],
      [-582, -1129],
      [-410, -1195],
      [-290, -1011],
      [18, -401],
      [204, -590],
      [72, -408],
      [243, -665],
      [144, -591],
      [138, -503],
      [84, -577],
      [-9, -606],
      [-48, -552],
      [225, -567],
      [520, -427],
      [452, -34],
      [501, -12],
      [536, -243],
      [486, -237],
      [530, -488],
      [319, -531],
      [171, -500],
      [95, -338],
      [1, -251],
      [-19, -51],
    ],
    [
      [55862, 15763],
      [-445, -142],
      [-669, -241],
      [-495, -177],
      [-512, 169],
      [-168, -16],
      [-153, 140],
      [-354, 517],
      [-88, 331],
      [-242, 295],
      [-356, 275],
      [-703, 443],
      [-384, 541],
      [-232, 93],
      [-544, 267],
      [-330, 562],
      [-258, 345],
      [-238, 297],
      [-272, 100],
      [-646, -395],
      [-473, -365],
      [-207, -165],
      [-180, -138],
      [-294, -391],
      [-360, -337],
      [-400, -412],
      [-214, -383],
      [-201, -390],
      [-101, -314],
      [-526, -339],
      [-432, 125],
      [-445, 3],
      [-452, -79],
      [-339, 78],
      [-472, -120],
      [-392, -72],
      [-452, 57],
      [-617, 233],
      [-225, 154],
      [-492, 43],
      [-405, 139],
      [-598, 124],
      [-283, 120],
      [-246, 86],
      [-252, 86],
      [-482, 125],
      [-123, -14],
      [-224, -200],
      [-270, -363],
      [-318, -126],
      [-406, -51],
      [-455, -45],
      [-396, -112],
      [-458, -236],
      [-284, -152],
      [-535, -312],
      [-370, -173],
      [-355, -17],
      [-254, 52],
    ],
    [
      [34785, 15286],
      [126, 539],
      [269, 343],
      [429, 372],
      [316, 214],
      [440, 569],
      [-40, 538],
      [-232, 352],
      [-378, 235],
      [-499, 152],
      [-537, 376],
      [114, 424],
      [202, 233],
      [124, 498],
      [-47, 272],
      [-377, 273],
      [-809, 186],
      [-518, 186],
      [-531, 360],
      [-83, 431],
      [134, 296],
      [2, 5],
      [418, 198],
      [707, 14],
      [814, 180],
      [173, 280],
      [122, 435],
      [0, 744],
      [-46, 716],
      [-213, 951],
      [-222, 879],
      [9, 1017],
      [-128, 641],
      [42, 730],
      [-153, 477],
      [-328, 278],
      [-317, 39],
      [-69, -1],
      [-517, 59],
      [-88, -10],
    ],
    [
      [39652, 43350],
      [-20, 9],
      [-131, 19],
      [-150, 18],
      [-110, 60],
      [-32, 95],
      [-18, 88],
      [-105, 26],
      [-70, 94],
      [-57, 149],
      [66, 48],
      [112, 35],
      [-24, 170],
      [-25, 135],
      [55, 150],
      [-90, 108],
      [73, 103],
      [75, 191],
      [125, 69],
      [9, 157],
      [-28, 339],
      [8, 136],
      [-63, 121],
      [-111, 74],
      [-104, 32],
      [-124, 12],
      [-67, -123],
      [-107, -144],
      [-42, -65],
    ],
    [
      [53341, 10675],
      [-38, 8],
      [-204, -3],
      [-118, -36],
      [-151, -124],
      [-27, -32],
      [-190, -236],
      [-174, -157],
      [-100, -91],
      [-18, -175],
      [-9, -97],
      [-261, -214],
      [-74, -150],
      [-65, -131],
      [-30, -85],
      [-86, -242],
      [-88, -177],
      [-58, -117],
      [-12, -91],
      [-28, -208],
      [-189, -145],
      [-100, -77],
      [-32, -78],
      [-73, -181],
      [-233, -207],
      [-150, -138],
      [-165, -125],
      [-147, -43],
      [-98, -10],
      [-218, -22],
      [-84, 13],
      [-114, 18],
      [-42, 27],
      [-30, 20],
      [-222, 125],
      [-182, 181],
      [-106, 58],
      [-124, 67],
      [-83, 18],
      [-37, 8],
      [-139, -18],
      [-43, -5],
      [-174, -71],
      [-274, -24],
      [-186, 78],
      [-6, 7],
      [-38, 47],
      [-65, 60],
      [-86, 32],
      [-192, 72],
      [-104, 119],
      [-13, 15],
      [-40, 156],
      [-149, 106],
      [-50, 34],
      [-167, 93],
      [-51, 28],
      [-213, 32],
      [-95, 13],
      [-131, 19],
      [-146, -131],
      [-147, -77],
      [-122, -55],
      [-91, -42],
      [-73, 0],
      [-126, 19],
      [-64, 58],
      [-55, 50],
      [-47, 31],
      [-33, 22],
      [-53, 75],
      [-79, 135],
      [-39, 143],
      [-4, 52],
      [-9, 138],
      [-33, 211],
      [-65, 183],
      [-133, 162],
      [-232, 66],
      [-44, -5],
      [-76, -10],
      [-166, -131],
      [-44, -36],
      [-149, -122],
      [-92, -63],
      [-108, -75],
      [-61, -45],
      [-13, -10],
      [-100, -69],
      [-28, -22],
      [-132, -150],
      [-293, -132],
      [-33, 11],
      [-25, 8],
      [-387, 127],
      [-52, 115],
      [-192, 188],
      [-59, 259],
      [-118, 339],
      [-79, 265],
      [-192, 304],
      [-311, 168],
      [-193, 38],
      [-259, -91],
      [-240, -165],
      [-221, -411],
      [-288, -472],
      [-113, -219],
      [-228, -336],
      [-253, -295],
      [-280, -105],
      [-312, 93],
      [-404, 309],
      [-286, 58],
      [-551, 29],
      [-671, 62],
      [-439, 16],
      [-458, -93],
      [-453, -45],
      [-717, 27],
      [-393, -126],
      [-93, -83],
      [-266, 12],
      [-61, -4],
      [-68, 84],
      [-28, 34],
      [-188, 39],
      [-297, 38],
      [-335, -98],
      [-385, -119],
      [-261, -84],
      [-176, 18],
      [-26, 89],
      [203, 376],
      [198, 758],
      [75, 167],
      [78, 175],
      [124, 389],
      [-211, 291],
      [-427, 248],
      [-268, 127],
      [-443, 296],
      [-250, 352],
      [45, 1097],
      [262, 438],
      [606, 373],
      [469, 202],
      [81, 35],
      [370, 411],
      [156, 778],
    ],
    [
      [56099, 45142],
      [407, -800],
      [42, -715],
      [136, -1013],
      [-37, -892],
      [-10, -912],
      [3, -1035],
      [-96, -661],
      [-62, -620],
      [870, -231],
      [862, -277],
      [770, -374],
      [443, -432],
      [602, -940],
      [468, -854],
      [496, -778],
      [374, -1160],
      [7, -16],
    ],
    [
      [61374, 33432],
      [-632, -167],
      [-646, -224],
      [-498, -141],
      [-733, -300],
      [-326, -228],
      [-506, -127],
      [-672, -190],
      [-518, -172],
    ],
    [
      [73533, 21052],
      [-322, -289],
      [-335, -229],
      [-116, -422],
      [99, -494],
      [-169, -538],
      [-437, -299],
      [-395, -304],
      [-86, -558],
      [73, -548],
      [111, -1131],
      [-492, -665],
      [8, -95],
    ],
    [
      [64683, 16693],
      [14, 19],
      [81, 130],
      [177, 185],
      [244, 269],
      [112, 409],
      [202, 423],
      [168, 349],
      [307, 541],
      [298, 357],
      [384, 229],
      [427, 107],
      [760, 51],
      [1003, -1],
      [572, 7],
      [634, -134],
      [426, 66],
      [50, 232],
      [-77, 311],
      [-170, 467],
      [-114, 352],
      [4, 224],
      [75, -24],
    ],
    [
      [70260, 21262],
      [622, -205],
      [652, -148],
      [843, 4],
      [795, 101],
      [361, 38],
    ],
    [
      [67384, 44878],
      [-113, -162],
      [-121, -498],
      [-162, -478],
      [-282, -860],
      [-296, -1052],
      [-88, -327],
      [-362, -841],
      [-342, -732],
      [-63, -960],
      [-170, -934],
      [339, -483],
      [376, -300],
      [495, -515],
      [785, -552],
      [326, -280],
      [523, -284],
      [398, -794],
      [329, -606],
      [335, -796],
      [183, -459],
      [-47, -489],
      [66, -331],
      [-98, -450],
      [-57, -645],
      [-194, -580],
      [-157, -592],
      [-13, -917],
      [5, -638],
      [285, 25],
      [385, 236],
      [322, 316],
      [298, 120],
      [501, -42],
      [432, 54],
      [391, 242],
      [351, 147],
      [280, -146],
      [356, -172],
      [291, -499],
      [239, -561],
      [139, -440],
      [536, -169],
      [200, -224],
    ],
    [
      [73985, 27210],
      [68, -758],
      [-40, -660],
      [-44, -531],
      [-55, -781],
      [-25, -572],
      [-128, -538],
      [-35, -605],
      [112, -452],
      [134, -541],
      [-198, -504],
      [-241, -216],
    ],
    [
      [70260, 21262],
      [-111, 98],
      [-74, 149],
      [35, 428],
      [85, 355],
      [165, 240],
      [65, 320],
      [21, 367],
      [3, 421],
      [-42, 455],
      [-242, 398],
      [-154, 324],
      [-175, 229],
      [-289, 323],
      [-206, 581],
      [-246, 513],
      [-53, 536],
      [-209, 440],
      [-230, 512],
      [-165, 561],
      [-341, 321],
      [-406, 150],
      [-539, 93],
      [-593, 263],
      [-622, 92],
      [-884, 96],
      [-652, 146],
      [-824, 422],
      [-630, 771],
      [-685, 743],
      [-480, 910],
      [-408, 913],
    ],
    [
      [54539, 48656],
      [354, -11],
      [462, 66],
      [397, 119],
      [177, 117],
      [-2, 82],
      [160, 160],
    ],
    [
      [56087, 49189],
      [-8, -63],
      [234, 36],
      [902, 62],
      [521, 148],
      [859, -265],
      [951, -284],
      [962, -209],
      [1765, -705],
      [912, -1169],
      [636, -525],
      [796, -537],
      [1134, -472],
      [1017, -100],
      [616, -228],
    ],
    [
      [70482, 62702],
      [318, -415],
      [442, -854],
      [349, -813],
      [469, -792],
      [111, -530],
      [11, -715],
      [57, -578],
      [-235, -615],
      [-241, -533],
      [-236, -942],
      [-202, -676],
      [-36, -695],
      [176, -910],
      [150, -870],
      [242, -1067],
      [-97, -811],
      [-341, -521],
      [-572, -230],
      [-791, -48],
      [-306, -173],
      [-261, -370],
      [116, -401],
    ],
    [
      [69605, 49143],
      [-204, -56],
      [-605, -203],
      [-227, -152],
      [-626, -401],
      [-215, -499],
      [-69, -654],
      [-114, -383],
      [58, -462],
      [111, -680],
      [-142, -505],
      [-188, -270],
    ],
    [
      [56087, 49189],
      [53, 401],
      [228, 499],
      [279, 779],
      [212, 199],
      [1005, 1221],
      [1339, 843],
      [679, 469],
      [1244, 774],
      [640, 666],
      [583, 537],
      [720, 667],
      [240, 615],
      [-129, 462],
      [-72, 251],
      [-352, 269],
      [-335, 154],
      [-461, 173],
      [-479, 193],
      [-228, 189],
      [-191, 311],
      [-345, 528],
      [-407, 760],
      [-85, 816],
      [-10, 633],
      [451, 433],
      [18, 7],
    ],
    [
      [51186, 69630],
      [37, -179],
      [73, -896],
      [456, -571],
      [189, -547],
      [52, -455],
      [-39, -490],
      [129, -636],
      [-1, -503],
      [226, -235],
      [7, -468],
      [74, -40],
    ],
    [
      [52389, 64610],
      [-102, -89],
      [-218, -125],
      [-224, -133],
      [-303, -133],
      [-224, -138],
      [-198, -159],
      [-270, -119],
      [-173, -145],
      [-158, -152],
      [-278, -221],
      [-252, -214],
      [-186, -192],
      [-244, -139],
      [-244, -146],
      [-66, -42],
      [-5, -3],
    ],
    [
      [49244, 62460],
      [6, 71],
      [9, 177],
      [-22, 292],
      [23, 224],
      [-23, 251],
      [-23, 251],
      [-119, 352],
      [-243, 431],
      [-165, 385],
      [-529, 47],
      [-384, 124],
      [-413, -39],
      [-310, -154],
      [-492, -156],
      [-354, -59],
      [-285, 207],
      [-150, 277],
      [-243, 310],
      [-113, 243],
      [-93, 278],
      [-126, 284],
      [-36, 108],
      [-117, 81],
      [-61, 4],
    ],
    [
      [45040, 67234],
      [500, -247],
      [531, 149],
      [460, 278],
      [1101, 421],
      [549, 204],
      [293, 411],
      [106, 552],
      [367, 521],
      [98, -5],
      [700, -32],
      [753, 23],
      [688, 121],
    ],
    [
      [77170, 45649],
      [30, -48],
      [40, -379],
      [-266, -968],
      [-55, -469],
      [-229, -560],
      [-377, -582],
      [-384, -536],
      [-118, -300],
      [131, -344],
      [506, -399],
      [368, -132],
      [438, -293],
      [333, -363],
      [326, -369],
      [259, -248],
      [273, -298],
      [-174, -136],
      [-40, -666],
      [-60, -701],
      [-259, -676],
      [-620, -735],
      [-1030, -346],
      [-896, -657],
      [-475, -848],
      [-274, -785],
      [-253, -710],
      [-86, -681],
      [-19, -598],
      [275, -655],
      [365, -675],
      [236, -711],
      [120, -392],
      [1, -558],
      [-540, -557],
      [-587, -374],
      [-159, -519],
      [15, -171],
    ],
    [
      [69605, 49143],
      [42, -143],
      [124, -434],
      [503, -506],
      [532, -90],
      [670, 8],
      [805, -76],
      [1299, -299],
      [225, -452],
      [402, -455],
      [301, -389],
      [188, -160],
      [567, -331],
      [1411, -304],
      [496, 137],
    ],
    [
      [56102, 64842],
      [-407, -31],
      [-727, -65],
      [-1135, -56],
      [-718, 140],
      [-321, -52],
      [-237, -118],
      [-132, -70],
      [-36, 20],
    ],
    [
      [51186, 69630],
      [26, 4],
      [435, 190],
      [331, 296],
      [656, 586],
      [563, 544],
      [417, 372],
      [706, 424],
      [429, 291],
      [143, 78],
    ],
    [
      [49244, 62460],
      [-9, -92],
      [6, 0],
      [-165, -138],
      [-145, -104],
      [248, -30],
      [26, -42],
    ],
    [
      [49205, 62054],
      [-21, -13],
      [-210, -91],
      [-119, -83],
      [-382, -182],
      [-257, -125],
      [-231, -166],
      [-198, -105],
      [-191, -131],
      [-178, -111],
      [-113, -90],
      [-59, 12],
      [-58, 48],
      [-19, 19],
      [-64, 129],
      [-160, 235],
      [-160, 283],
      [-310, 227],
      [-271, 282],
      [-221, 133],
      [-149, 86],
      [-391, 158],
      [-228, 65],
      [-423, 144],
      [-228, 38],
      [-353, -5],
      [-164, -2],
      [-11, 0],
    ],
    [
      [74800, 6041],
      [-269, -4],
      [-244, -262],
      [-266, -540],
      [-254, -452],
      [-110, -401],
      [-339, -711],
      [-279, -554],
      [-445, -617],
      [-416, -672],
      [-219, -437],
      [-260, -493],
      [-215, -336],
      [-209, -182],
      [-178, -156],
      [-299, -98],
      [-75, -24],
      [-386, -75],
      [-125, -9],
      [-59, -4],
      [-208, -14],
      [-285, 15],
      [-108, 6],
      [-376, 68],
      [-23, 37],
      [-11, 0],
      [-4, 5],
      [-8, 14],
      [-35, 90],
      [-53, 139],
      [-60, 167],
      [-12, 35],
      [-60, 236],
      [-17, 176],
      [-28, 29],
      [-247, 265],
      [-172, 217],
      [-40, 51],
      [-337, 279],
      [-296, 321],
      [-149, 214],
      [-206, 295],
      [-275, 308],
      [-178, 275],
      [-149, 194],
      [-119, 86],
      [-316, 260],
      [-128, 8],
      [-211, 13],
      [-330, -39],
      [-256, 2],
      [-312, 43],
      [-325, 28],
      [-109, 27],
      [-127, 31],
      [-188, 103],
      [-63, 35],
      [-73, 59],
      [-137, 114],
      [-3, 2],
      [-111, 145],
      [-127, 134],
      [-138, 38],
      [-91, 26],
      [-221, 30],
      [-70, 4],
      [-109, 7],
      [-211, -104],
      [-16, -8],
      [-36, -26],
      [-160, -119],
      [-185, -206],
      [-172, -325],
      [-9, -17],
      [-170, -403],
      [-31, -74],
      [-36, -84],
      [-171, -178],
      [-218, -241],
      [-60, -74],
      [-164, -201],
      [-200, -208],
      [-103, -108],
      [-89, -118],
      [-176, -232],
      [-231, -295],
      [-204, -206],
      [-124, -155],
      [-41, -51],
      [-177, -178],
      [-82, -50],
      [-218, -132],
      [-288, -214],
      [-265, -189],
    ],
    [
      [72427, 13524],
      [166, -311],
      [311, -361],
      [144, -316],
      [268, -328],
      [242, -355],
      [416, -469],
      [282, -307],
      [504, -351],
      [302, -219],
      [158, -276],
      [134, -234],
      [175, -479],
      [43, -181],
      [62, -265],
      [45, -562],
      [-92, -605],
      [-225, -539],
      [-47, -69],
      [-93, -164],
      [-188, -322],
      [-85, -211],
      [-112, -335],
      [-37, -224],
    ],
    [
      [49760, 57586],
      [11, 19],
      [-133, 306],
      [-54, 332],
      [-19, 34],
      [5, 380],
      [162, 342],
      [143, 430],
      [-47, 407],
      [-38, 583],
      [9, 639],
      [-164, 459],
      [-153, 304],
      [-130, 5],
      [-147, 228],
    ],
  ],
  transform: {
    scale: [0.000034312178026576987, 0.00003393958311861351],
    translate: [6.0934091461601785, 8.999791196277197],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Birnin Gwari",
            id: "KD001",
            nameAbbr: "Birnin Gwari",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.93811182372,
            Shape_Area: 0.51726916603,
          },
        },
        {
          arcs: [[4, 5, 6, 7, 8, 9, 10, -3]],
          type: "Polygon",
          properties: {
            name: "Chikun",
            id: "KD002",
            nameAbbr: "Chikun",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.93947958218,
            Shape_Area: 0.40117016397,
          },
        },
        {
          arcs: [[11, 12, -1, 13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Giwa",
            id: "KD003",
            nameAbbr: "Giwa",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.94609885662,
            Shape_Area: 0.1613792644,
          },
        },
        {
          arcs: [[16, 17, 18, 19, -8, 20, 21, -5, -2, -13]],
          type: "Polygon",
          properties: {
            name: "Igabi",
            id: "KD004",
            nameAbbr: "Igabi",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.1658778505,
            Shape_Area: 0.28878040125,
          },
        },
        {
          arcs: [[22, 23, 24, 25, 26]],
          type: "Polygon",
          properties: {
            name: "Ikara",
            id: "KD005",
            nameAbbr: "Ikara",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.85404806854,
            Shape_Area: 0.07444459582,
          },
        },
        {
          arcs: [[27, 28, 29, 30, 31]],
          type: "Polygon",
          properties: {
            name: "Jaba",
            id: "KD006",
            nameAbbr: "Jaba",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.89211555086,
            Shape_Area: 0.0352481033,
          },
        },
        {
          arcs: [[32, 33, 34, -32, 35, 36]],
          type: "Polygon",
          properties: {
            name: "Jema'a",
            id: "KD007",
            nameAbbr: "Jema'a",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.97002467481,
            Shape_Area: 0.14034193823,
          },
        },
        {
          arcs: [[37, 38, -30, 39, 40, -10]],
          type: "Polygon",
          properties: {
            name: "Kachia",
            id: "KD008",
            nameAbbr: "Kachia",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.27857475016,
            Shape_Area: 0.37954954333,
          },
        },
        {
          arcs: [[-7, 41, -21]],
          type: "Polygon",
          properties: {
            name: "Kaduna North",
            id: "KD009",
            nameAbbr: "Kaduna North",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.48239748334,
            Shape_Area: 0.00722328298,
          },
        },
        {
          arcs: [[-22, -42, -6]],
          type: "Polygon",
          properties: {
            name: "Kaduna South",
            id: "KD010",
            nameAbbr: "Kaduna South",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.35128651556,
            Shape_Area: 0.00494416036,
          },
        },
        {
          arcs: [[-29, 42, -40]],
          type: "Polygon",
          properties: {
            name: "Kagarko",
            id: "KD011",
            nameAbbr: "Kagarko",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.25545431572,
            Shape_Area: 0.18325060474,
          },
        },
        {
          arcs: [[43, 44, -38, -9, -20]],
          type: "Polygon",
          properties: {
            name: "Kajuru",
            id: "KD012",
            nameAbbr: "Kajuru",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.82970364763,
            Shape_Area: 0.18363225976,
          },
        },
        {
          arcs: [[45, -37, 46, 47]],
          type: "Polygon",
          properties: {
            name: "Kaura",
            id: "KD013",
            nameAbbr: "Kaura",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.94437913301,
            Shape_Area: 0.041732337,
          },
        },
        {
          arcs: [[48, 49, -48, 50, -44, -19, 51, 52]],
          type: "Polygon",
          properties: {
            name: "Kauru",
            id: "KD014",
            nameAbbr: "Kauru",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.88307148343,
            Shape_Area: 0.22515814692,
          },
        },
        {
          arcs: [[53, 54, -53, 55, -24]],
          type: "Polygon",
          properties: {
            name: "Kubau",
            id: "KD015",
            nameAbbr: "Kubau",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.25354041397,
            Shape_Area: 0.19995140161,
          },
        },
        {
          arcs: [[56, 57, 58, -15, 59]],
          type: "Polygon",
          properties: {
            name: "Kudan",
            id: "KD016",
            nameAbbr: "Kudan",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.84435311501,
            Shape_Area: 0.03263455053,
          },
        },
        {
          arcs: [[60, -49, -55, 61]],
          type: "Polygon",
          properties: {
            name: "Lere",
            id: "KD017",
            nameAbbr: "Lere",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.12269248131,
            Shape_Area: 0.1911838181,
          },
        },
        {
          arcs: [[62, -57, 63, -26]],
          type: "Polygon",
          properties: {
            name: "Makarfi",
            id: "KD018",
            nameAbbr: "Makarfi",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.86290176647,
            Shape_Area: 0.04279684694,
          },
        },
        {
          arcs: [[64, 65, -16, -59]],
          type: "Polygon",
          properties: {
            name: "Sabon Gari",
            id: "KD019",
            nameAbbr: "Sabon Gari",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.64249381962,
            Shape_Area: 0.02017289776,
          },
        },
        {
          arcs: [[66, -34, 67]],
          type: "Polygon",
          properties: {
            name: "Sanga",
            id: "KD020",
            nameAbbr: "Sanga",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.84202069016,
            Shape_Area: 0.10843913014,
          },
        },
        {
          arcs: [[-25, -56, -52, -18, 68, -65, -58, -63]],
          type: "Polygon",
          properties: {
            name: "Soba",
            id: "KD021",
            nameAbbr: "Soba",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.74762628376,
            Shape_Area: 0.17791871533,
          },
        },
        {
          arcs: [[-47, -36, -31, -39, -45, -51]],
          type: "Polygon",
          properties: {
            name: "ZangonKa",
            id: "KD022",
            nameAbbr: "ZangonKa",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.93252169491,
            Shape_Area: 0.21618823366,
          },
        },
        {
          arcs: [[-69, -17, -12, -66]],
          type: "Polygon",
          properties: {
            name: "Zaria",
            id: "KD023",
            nameAbbr: "Zaria",
            parent: "Kaduna",
            parentAbbr: "KD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.74774956195,
            Shape_Area: 0.02500005757,
          },
        },
      ],
    },
  },
};

const katsina = {
  type: "Topology",
  arcs: [
    [
      [42931, 55601],
      [-27, -10],
      [-74, -130],
      [-202, -131],
      [-142, -70],
      [-108, -35],
      [-290, -228],
      [-94, -89],
      [-210, -125],
      [-39, -170],
      [15, -115],
      [30, -231],
      [63, -197],
      [22, -203],
      [23, -157],
      [-12, -217],
      [0, -21],
      [-12, -149],
      [-93, -165],
      [-223, -158],
      [-243, -146],
      [-162, -90],
      [-175, -118],
      [-216, -138],
      [-351, -235],
      [-318, -181],
      [-74, -68],
      [-447, -87],
      [-353, -72],
      [-151, -8],
    ],
    [
      [39068, 51857],
      [-67, 108],
      [-56, 155],
      [-57, 265],
      [-23, 170],
      [-131, 250],
      [-90, 162],
      [-112, 236],
      [-110, 142],
      [-146, 352],
      [-84, 175],
      [-118, 291],
      [-139, 217],
      [-212, 201],
      [-99, 298],
      [-23, 170],
      [-25, 346],
      [4, 218],
      [-15, 115],
      [-2, 143],
      [-64, 149],
      [-59, 298],
      [-70, 163],
      [-44, 15],
    ],
    [
      [37326, 56496],
      [9, 59],
      [-215, 249],
      [-172, 141],
      [-66, 79],
    ],
    [
      [36882, 57024],
      [83, 193],
      [149, 49],
      [225, 44],
      [170, 42],
      [161, 138],
      [182, 165],
      [251, 126],
      [130, 35],
      [196, 97],
      [109, 63],
      [115, 69],
      [128, 131],
      [156, 69],
      [95, 42],
      [68, 1],
      [158, -107],
      [226, -31],
      [172, -87],
      [206, -147],
      [432, -376],
      [241, -249],
      [205, -154],
      [227, -249],
      [157, -86],
      [144, -80],
      [170, -53],
      [170, 2],
      [380, 66],
      [312, 64],
      [163, 16],
      [204, -52],
      [112, -339],
      [36, -156],
      [108, -529],
      [8, -140],
    ],
    [
      [39068, 51857],
      [64, -102],
      [97, -203],
      [16, -190],
      [70, -196],
      [14, -54],
      [35, -116],
      [56, -128],
      [14, -61],
      [28, -108],
      [28, -171],
    ],
    [
      [39490, 50528],
      [-171, -24],
      [-611, -81],
      [-516, -47],
      [-815, -77],
      [-746, -150],
      [-216, -139],
      [-37, -286],
      [-1, -137],
    ],
    [
      [36377, 49587],
      [-366, 58],
      [-225, 113],
      [-266, 66],
      [-133, 37],
    ],
    [
      [35387, 49861],
      [-7, 30],
      [-48, 40],
      [-54, 13],
      [-95, 13],
      [-69, 6],
      [-61, 27],
      [-41, 60],
      [-42, 54],
      [-48, 81],
      [-90, 108],
      [-21, 61],
      [-2, 217],
      [2, 279],
      [47, 96],
      [66, 150],
      [52, 184],
      [80, 178],
      [94, 130],
      [120, 151],
      [39, 211],
      [65, 184],
      [54, 110],
      [53, 68],
      [11, 191],
      [40, 89],
      [-28, 88],
      [-56, 47],
      [-83, 148],
      [-29, 75],
      [-44, 244],
      [-43, 136],
      [-15, 129],
      [-263, 269],
      [-57, 155],
      [-57, 183],
      [-14, 41],
      [-78, 251],
      [-56, 115],
      [-63, 121],
      [-77, 94],
      [-48, 47],
      [-85, 190],
      [-62, 54],
      [-90, 107],
      [-83, 102],
      [-175, 276],
      [-56, 115],
      [-2, 157],
      [-7, 41],
    ],
    [
      [33961, 55777],
      [27, 20],
      [89, -19],
      [130, -26],
      [207, -181],
      [222, -290],
      [229, -243],
      [172, -93],
      [139, 281],
      [222, 220],
      [215, 274],
      [148, 151],
      [263, 227],
      [218, 23],
      [185, -18],
      [388, 59],
      [252, 57],
      [231, 91],
      [28, 186],
    ],
    [
      [21694, 39779],
      [-7, -8],
      [-155, -145],
      [-153, -266],
      [-161, -199],
      [-127, -246],
      [-84, -328],
      [-51, -272],
      [-72, -205],
      [-37, -306],
      [-24, -252],
      [85, -264],
      [126, -216],
      [79, -319],
      [107, -312],
      [9, -184],
    ],
    [
      [21229, 36257],
      [-65, -44],
      [-388, -18],
      [-275, 127],
      [-7, 2],
      [-82, -3],
      [-301, 85],
      [-50, 31],
      [-60, 15],
      [-391, 173],
      [-329, 126],
      [-347, -45],
      [-32, -79],
      [8, -193],
      [-12, -129],
      [66, -306],
      [84, -217],
      [17, -279],
      [-9, -272],
      [-148, -158],
      [-106, -287],
      [12, -387],
      [53, -367],
      [65, -319],
      [-17, -211],
      [-18, -56],
      [5, -46],
      [-26, -148],
    ],
    [
      [18876, 33252],
      [-128, -45],
      [-116, -35],
      [-224, -57],
      [-157, -8],
      [-211, -43],
      [-184, 4],
      [-130, -21],
      [-115, -49],
      [-205, 4],
      [-75, 27],
      [-123, 12],
      [-82, 13],
      [-70, 169],
      [-90, 88],
      [-43, 122],
      [-56, 128],
      [-91, 285],
      [-62, 224],
      [19, 75],
      [-82, 156],
      [-57, 43],
    ],
    [
      [16594, 34344],
      [-143, 444],
      [24, 381],
      [45, 368],
      [-10, 320],
      [105, 491],
      [274, 431],
      [77, 566],
      [56, 681],
      [-59, 577],
      [-303, 582],
      [-241, 562],
      [-314, 459],
      [-113, 529],
      [-212, 325],
      [-92, 488],
      [-37, 354],
      [-124, 412],
    ],
    [
      [15527, 42314],
      [76, 33],
      [250, 64],
      [256, 57],
      [291, 72],
      [148, 76],
      [258, -4],
      [196, -32],
      [177, -59],
      [203, -66],
      [211, -72],
      [156, -59],
      [122, -26],
      [176, 29],
      [250, 57],
      [216, -4],
      [292, -58],
      [265, -72],
      [284, 17],
      [317, 112],
      [297, 126],
      [371, 188],
      [155, 69],
      [250, 92],
      [209, 84],
      [108, 69],
      [242, 206],
      [74, 22],
      [94, 75],
      [81, 83],
      [94, 42],
      [15, 5],
    ],
    [
      [21661, 43440],
      [14, -256],
      [62, -156],
      [9, -224],
      [50, -299],
      [70, -257],
      [90, -244],
      [118, -379],
      [16, -367],
      [17, -218],
      [30, -197],
      [-17, -292],
      [-84, -375],
      [-147, -199],
      [-195, -198],
    ],
    [
      [12544, 19781],
      [-51, -131],
      [-113, -246],
      [-125, -314],
      [-146, -315],
      [-32, -177],
      [56, -108],
      [163, 42],
      [151, -87],
      [193, -79],
      [171, -73],
      [159, -107],
      [165, -162],
      [121, -427],
      [92, -421],
      [25, -455],
      [-8, -483],
      [30, -320],
      [-50, -429],
      [-23, -367],
      [128, -502],
      [245, -712],
      [76, -175],
    ],
    [
      [13771, 13733],
      [-129, 25],
      [-122, 5],
      [-224, -56],
      [-822, 18],
      [-205, 52],
      [-251, -44],
      [-441, -79],
      [-114, -49],
      [-178, 52],
      [-244, -63],
      [-224, -23],
      [-165, 202],
      [-129, 33],
      [6, 95],
      [-109, -22],
      [-132, -389],
      [-67, -48],
      [-134, -158],
      [-61, -55],
      [-182, -152],
      [-349, 37],
      [-195, -152],
      [-327, -112],
      [-115, -55],
      [-277, -187],
      [-74, -62],
      [-394, -154],
      [-203, -118],
      [-238, -86],
    ],
    [
      [7672, 12188],
      [-14, -5],
      [-68, -8],
      [-157, -1],
      [-259, -10],
      [-203, -84],
      [-183, -124],
      [-86, -205],
      [-186, -349],
      [78, -230],
      [-153, -288],
      [-74, -137],
      [-46, -116],
      [-32, -170],
      [-108, -69],
      [-53, -15],
    ],
    [
      [6128, 10377],
      [-103, -28],
      [-108, -110],
      [-135, -90],
      [-122, -83],
      [-307, -17],
      [-164, -15],
      [-365, -188],
      [-417, 16],
      [-492, 49],
      [-222, -186],
      [-203, -151],
      [-210, -146],
      [-305, -119],
      [-297, -268],
      [-289, -337],
      [-92, -259],
      [-92, -232],
      [-266, -44],
      [-221, -329],
      [-294, 24],
      [-250, -179],
      [-182, -132],
      [-150, -88],
    ],
    [
      [842, 7465],
      [-35, 617],
      [250, 751],
      [45, 694],
      [162, 682],
      [134, 737],
      [-248, 582],
      [-86, 693],
      [-92, 454],
      [-559, 484],
      [-342, 411],
      [-71, 503],
      [42, 654],
      [199, 553],
      [-31, 557],
      [125, 403],
      [220, 506],
      [10, 28],
      [381, 1003],
      [102, 770],
      [69, 769],
      [133, 335],
      [329, 282],
      [378, 114],
      [529, -104],
      [345, 38],
      [433, 12],
      [467, 73],
      [413, -10],
      [927, 51],
      [245, -221],
      [157, -189],
      [196, 2],
      [660, 497],
      [283, 214],
      [471, 359],
      [153, 294],
      [445, 222],
      [67, 137],
      [222, 139],
      [59, 259],
      [84, 538],
      [38, 347],
      [78, 355],
      [420, -29],
      [720, -313],
      [429, -36],
      [373, 195],
      [162, 124],
      [209, 227],
      [147, 212],
      [0, 111],
    ],
    [
      [10689, 23551],
      [33, -2],
      [294, -57],
      [396, -500],
      [168, -345],
      [196, -345],
      [237, -446],
      [261, -596],
      [101, -387],
      [127, -379],
      [19, -361],
      [64, -244],
      [-41, -108],
    ],
    [
      [13771, 13733],
      [35, -82],
      [113, -359],
      [153, -393],
      [100, -476],
      [-89, -552],
      [9, -258],
      [17, -272],
      [104, -196],
      [133, -366],
      [241, -378],
      [143, -53],
      [89, -33],
      [165, -25],
    ],
    [
      [14984, 10290],
      [-62, -96],
      [-87, -63],
      [-135, -69],
      [23, -258],
      [-81, -56],
      [-80, -171],
      [-47, -61],
      [49, -143],
      [-12, -183],
      [112, -278],
      [89, -33],
      [55, -129],
      [69, -54],
      [9, -224],
      [49, -122],
      [136, -80],
      [76, -115],
      [221, -297],
      [83, -121],
      [29, -211],
      [-25, -170],
      [32, -510],
      [37, -207],
    ],
    [
      [15524, 6639],
      [-216, -203],
      [-207, -356],
      [-198, -533],
      [-158, -505],
      [45, -360],
      [-43, -293],
      [-271, -254],
      [-659, -42],
      [-679, 297],
    ],
    [
      [13138, 4390],
      [1, 186],
      [141, 226],
      [-12, 462],
      [-49, 156],
      [-266, 154],
      [-212, 93],
      [-158, 141],
      [-199, 215],
      [-79, 408],
      [-195, 433],
      [-134, 468],
      [-18, 408],
      [-29, 251],
      [-85, 312],
      [-45, 367],
      [-214, 338],
      [-140, 359],
      [-207, 317],
      [-186, 223],
      [-220, 263],
      [-119, 311],
      [-123, 176],
      [-125, 168],
      [-151, 210],
      [-238, -17],
      [-224, -49],
      [-415, 104],
      [-129, -42],
      [-115, -70],
      [-245, 93],
      [-164, 73],
      [-192, 141],
      [-125, 148],
      [-160, 189],
      [-49, 95],
      [-40, 109],
      [-146, 99],
      [-97, 74],
      [-193, 148],
      [-110, 58],
    ],
    [
      [36089, 45756],
      [15, -389],
      [275, -337],
      [552, -211],
      [471, -138],
      [327, -85],
      [530, -55],
      [592, -28],
      [428, -16],
      [374, -44],
      [400, 87],
      [158, -176],
      [233, -153],
      [237, -406],
      [169, -378],
      [178, -443],
      [-124, 1],
      [-239, -23],
      [-416, 9],
      [-505, -61],
      [-394, -65],
      [-502, -26],
      [-312, -71],
      [-290, -174],
      [-234, -363],
      [-117, -525],
      [-140, -266],
      [-113, -287],
      [-206, -390],
      [-201, -322],
      [-185, -526],
      [-137, -498],
      [-103, -505],
      [-104, -525],
      [-222, -213],
      [-147, -185],
      [-426, -277],
      [-391, -215],
      [-97, -42],
    ],
    [
      [35423, 37435],
      [-60, 54],
      [-69, 122],
      [-110, 162],
      [-49, 149],
      [-64, 196],
      [-111, 210],
      [-90, 155],
      [-2, 266],
      [-90, 115],
      [-7, -1],
      [-57, 265],
      [-28, 136],
      [-44, 278],
      [-117, 162],
      [25, 184],
      [-55, 115],
      [-226, 134],
      [-76, 121],
      [-111, 176],
      [-9, 265],
      [-44, 258],
      [25, 211],
      [-49, 136],
      [-22, 156],
      [-8, 184],
      [-85, 251],
      [-70, 189],
      [-97, 183],
      [-14, 74],
      [-55, 88],
      [-104, 169],
      [-89, 88],
      [-171, 73],
      [-136, 53],
      [-76, 94],
      [-157, 94],
      [-163, 18],
      [-136, -1],
      [-170, -22],
      [-169, -22],
      [-156, -43],
      [-88, -15],
      [-143, -42],
      [-189, -131],
      [-34, -1],
      [-21, 50],
    ],
    [
      [31652, 42791],
      [-14, 32],
      [-7, 88],
      [-29, 142],
      [-104, 169],
      [-84, 265],
      [-63, 176],
      [-37, 285],
      [-34, 27],
      [-96, 115],
      [-36, 156],
      [-124, 196],
      [-77, 162],
      [-152, 182],
      [-186, 114],
      [-157, 46],
      [-266, -24],
      [-293, -23],
      [-206, 45],
      [-201, 291],
      [5, 143],
      [18, 149],
      [38, 232],
      [119, 287],
      [46, 110],
      [26, 150],
      [58, 252],
      [11, 204],
      [19, 109],
      [11, 204],
      [-29, 129],
      [71, 314],
      [3, 211],
      [125, 348],
      [114, 219],
      [181, 220],
      [123, 15],
      [136, -13],
      [158, -79],
      [166, -121],
      [179, -141],
      [159, -162],
      [124, -94],
      [138, -127],
      [104, -142],
      [104, -115],
      [69, -40],
      [16, -12],
    ],
    [
      [31778, 47485],
      [46, -35],
      [77, -176],
      [139, -127],
      [246, -66],
      [226, -31],
      [177, 1],
      [244, 58],
      [141, 83],
      [182, 124],
      [162, 192],
      [189, 145],
      [100, 158],
      [148, 144],
      [100, 131],
      [202, 212],
      [162, 111],
      [256, 180],
      [223, 98],
      [210, 131],
      [121, 110],
      [59, 184],
      [33, 123],
      [45, 191],
      [5, 157],
      [19, 150],
      [-62, 135],
      [81, 14],
      [78, -21],
    ],
    [
      [36377, 49587],
      [-1, -353],
      [46, -482],
      [-137, -539],
      [-72, -300],
      [-77, -484],
      [-65, -266],
      [-34, -660],
      [48, -626],
      [4, -121],
    ],
    [
      [19844, 55983],
      [-11, -36],
      [-6, -88],
      [0, -96],
      [8, -95],
      [8, -163],
      [21, -102],
      [21, -33],
      [28, -82],
      [14, -122],
      [14, -61],
      [-6, -34],
      [-7, -41],
      [-20, -68],
      [-6, -41],
      [-6, -123],
      [-27, -41],
      [-53, -89],
      [-47, -89],
      [-5, -12],
    ],
    [
      [19764, 54567],
      [-2, 5],
      [-13, 48],
      [-115, -35],
      [-128, -56],
      [-142, -104],
      [-417, -276],
      [-236, -241],
      [-383, -248],
      [-270, -194],
      [-499, -168],
      [-203, -63],
      [-229, -132],
      [-337, -194],
      [-297, -180],
      [-343, -269],
      [-229, -159],
      [-297, -57],
      [-157, 121],
      [-232, 208],
      [-205, 216],
      [-150, 107],
      [-216, -16],
      [-204, 93],
      [-149, -37],
    ],
    [
      [14311, 52936],
      [-129, 65],
      [-360, 140],
      [-226, 256],
      [-314, 426],
      [-50, 285],
      [-132, 435],
      [-211, 120],
      [-490, 336],
      [-265, 126],
      [-252, 134],
      [-353, 187],
      [-293, 154],
      [-259, 93],
      [-233, 167],
      [-85, 20],
      [-221, 79],
      [-145, -56],
      [-146, -110],
      [-83, -246],
      [-62, -212],
      [117, -482],
      [155, -161],
      [145, -251],
      [112, -353],
      [164, -291],
      [90, -217],
      [185, -263],
      [193, -393],
      [260, -372],
      [71, -401],
      [62, -210],
      [-11, -273],
      [-66, -286],
      [-107, -124],
      [-345, -17],
      [-312, 58],
      [-257, -16],
      [-258, 93],
      [-341, 316],
      [-356, 446],
      [-177, 162],
      [-157, 128],
      [-211, 161],
      [-183, 32],
      [-281, 65],
      [-196, 12],
      [-270, -85],
      [-219, -172],
      [-128, -246],
      [-23, -246],
      [-130, -348],
      [-141, -212],
      [-98, -417],
      [-161, -246],
      [-206, -213],
      [-116, -253],
      [-81, -171],
      [-19, 0],
      [-103, -308],
      [-122, -246],
      [-345, -282],
      [-300, 112],
      [-224, -90],
      [-192, -111],
      [-243, -78],
      [-46, -25],
    ],
    [
      [5131, 49061],
      [-134, 656],
      [-170, 931],
      [139, 940],
      [80, 906],
      [28, 593],
      [33, 435],
      [158, 921],
      [108, 590],
      [5, 1],
      [122, 642],
      [4, 26],
      [41, 13],
      [24, 7],
      [16, 6],
      [5, 1],
      [177, 56],
      [186, 60],
      [143, 46],
      [54, 17],
      [98, 26],
      [63, 17],
      [163, 44],
      [93, 25],
      [4, 1],
      [46, 17],
      [484, 176],
      [175, 63],
      [169, 35],
      [144, 30],
      [27, 5],
      [179, 86],
      [265, 184],
      [188, 196],
      [167, 175],
      [58, 61],
      [218, 228],
      [174, 182],
      [232, 303],
      [11, 14],
      [161, 181],
      [161, 93],
      [299, 333],
      [33, 11],
      [55, 19],
      [7, 49],
      [5, 32],
      [153, 167],
      [51, 27],
      [70, 37],
      [7, 8],
      [172, 201],
      [472, -1],
      [14, -2],
      [745, -80],
      [58, -5],
      [1076, -104],
      [36, -4],
      [618, -93],
      [343, -25],
      [709, -46],
      [22, -1],
      [268, 3],
      [114, 1],
      [37, 5],
      [21, 2],
      [77, 12],
      [33, 5],
      [66, 11],
      [170, 25],
      [160, 25],
      [659, 229],
      [80, 27],
      [6, 2],
      [591, 328],
      [69, 42],
      [122, 37],
      [38, 20],
      [97, 52],
      [412, 297],
      [64, 41],
      [299, 122],
      [3, 2],
      [262, 184],
      [171, 71],
      [68, 20],
      [15, 15],
      [23, 22],
      [46, 44],
      [37, 38],
      [251, 146],
      [719, 419],
    ],
    [
      [19353, 60820],
      [72, -537],
      [-19, -68],
      [-40, -177],
      [-53, -49],
      [-60, -150],
      [-60, -143],
      [-114, -158],
      [-134, -131],
      [-74, -75],
      [-189, -70],
      [-53, -144],
      [-114, -171],
      [-26, -143],
      [-73, -164],
      [-107, -130],
      [-128, -124],
      [-47, -48],
      [49, -136],
      [76, -210],
      [15, -149],
      [89, -203],
      [145, -271],
      [0, -21],
      [7, -6],
      [61, -95],
      [28, -102],
      [110, -128],
      [102, -155],
      [123, -135],
      [82, -94],
      [151, -162],
      [102, -121],
      [82, -68],
      [48, -13],
      [135, -59],
      [177, -108],
      [103, -107],
      [25, -12],
    ],
    [
      [28637, 20283],
      [-38, -258],
      [-151, -389],
      [135, -393],
      [291, -336],
      [131, -549],
      [144, -569],
      [127, -251],
      [237, -343],
      [133, -237],
      [43, -176],
      [49, -101],
      [100, -271],
      [17, -258],
      [21, -41],
      [50, -135],
      [-20, -32],
      [-54, -85],
      [-121, -103],
      [-252, -51],
      [-226, 32],
      [-484, -81],
      [-382, -52],
      [-185, 66],
      [-277, -187],
      [-251, -152],
      [-257, -180],
      [-365, -276],
      [-306, -99],
      [-178, 25],
      [-408, -87],
      [-157, -35],
      [-299, -92],
      [-190, -98],
      [-231, -98],
      [-135, -130],
      [-94, -137],
      [-86, -144],
      [-190, -111],
      [-196, -193],
      [-156, -49],
      [-92, -233],
      [160, -243],
      [-30, -306],
      [-72, -259],
      [-179, -342],
      [-254, -411],
      [-107, -164],
      [-147, -240],
      [-26, -95],
      [113, -271],
      [4, -258],
      [84, -196],
      [30, -156],
      [89, -502],
      [45, -313],
      [63, -108],
      [79, -311],
      [71, -203],
      [9, -102],
      [175, -165],
    ],
    [
      [24441, 8749],
      [-220, -67],
      [-259, -78],
      [-163, -43],
      [-176, -83],
      [-220, 65],
      [-553, 41],
      [-655, -89],
      [-332, -188],
      [-289, 181],
      [-336, 91],
      [-432, 144],
      [-267, 62],
    ],
    [
      [20539, 8785],
      [-9, 98],
      [-223, 338],
      [-149, 406],
      [-46, 346],
      [-141, 365],
      [97, 375],
      [52, 191],
      [187, 322],
      [107, 110],
      [183, 145],
      [99, 212],
      [-111, 162],
      [-166, 134],
      [-152, 147],
      [-207, 154],
      [31, 205],
      [-270, 384],
      [-213, 256],
      [-262, 283],
      [-64, 265],
      [56, 517],
      [-92, 428],
      [97, 389],
      [38, 334],
      [92, 307],
      [-71, 284],
      [9, 108],
    ],
    [
      [19411, 16050],
      [15, 185],
      [79, 192],
      [296, 350],
      [133, 267],
      [19, 109],
      [275, 336],
      [21, 0],
      [54, -6],
      [183, 22],
      [157, -93],
      [319, 30],
      [217, 98],
      [379, 201],
      [208, 220],
      [256, 153],
      [346, 78],
      [386, 154],
      [256, 159],
      [230, 132],
      [241, 316],
      [235, 261],
      [106, 280],
      [115, 117],
      [111, 388],
      [155, 159],
      [169, 83],
      [202, 159],
      [202, 179],
      [256, 186],
      [324, 174],
      [223, 172],
      [177, 71],
      [259, 71],
      [495, 243],
      [62, -12],
      [265, 71],
      [305, 146],
      [456, 101],
      [217, 124],
      [578, 164],
      [115, 45],
    ],
    [
      [28508, 22135],
      [36, -84],
      [219, -514],
      [-88, -532],
      [18, -333],
      [-56, -389],
    ],
    [
      [28499, 58621],
      [-27, -31],
      [-240, -383],
      [-181, -274],
      [-208, -234],
      [-40, -21],
      [-148, -205],
      [-107, -137],
      [-235, -200],
      [-85, -402],
      [-317, -208],
      [-350, -262],
      [-102, 115],
      [-123, 148],
      [-91, 13],
    ],
    [
      [26245, 56540],
      [-85, 12],
      [-258, 17],
      [-250, 18],
      [-435, 138],
      [-258, 65],
      [-87, -15],
      [-115, -42],
      [-61, 6],
      [-218, -163],
    ],
    [
      [24478, 56576],
      [-30, -23],
      [-120, -90],
      [-114, -116],
      [-160, -152],
      [-128, -89],
      [-107, -70],
      [-217, -115],
    ],
    [
      [23602, 55921],
      [-58, 214],
      [-74, 163],
      [-135, 128],
      [-115, 203],
      [-103, 114],
      [-117, 230],
      [-123, 128],
      [-171, 230],
      [-177, 127],
      [-271, 79],
      [-171, 127],
      [-169, 46],
      [-109, 33],
      [-163, 12],
      [-135, -42],
      [-250, -64],
      [-175, -90],
      [-101, -110],
      [-120, -151],
      [-60, -103],
      [-87, -184],
      [-101, -76],
      [-135, -76],
      [-74, -69],
      [-94, -96],
      [-161, -118],
      [-135, -144],
      [-46, -102],
      [-60, -151],
      [-53, -150],
      [-15, -46],
    ],
    [
      [19353, 60820],
      [291, 172],
      [325, 191],
      [309, 182],
      [237, 141],
      [163, 53],
      [278, 206],
      [4, 4],
      [230, 109],
      [185, 113],
      [146, 89],
      [5, 3],
      [148, 105],
      [83, 2],
      [39, 74],
      [254, 102],
      [4, 1],
      [197, 159],
      [44, 36],
      [123, 81],
      [44, 28],
      [110, 34],
      [26, 68],
      [82, -11],
      [207, 160],
      [28, 22],
      [946, 588],
      [107, 67],
      [328, 204],
      [40, 25],
      [278, 188],
      [76, 45],
      [565, 336],
      [16, 10],
      [116, 69],
      [66, -5],
      [16, 95],
      [115, 28],
      [126, 87],
      [314, 217],
      [80, 55],
      [28, 19],
      [180, 117],
      [49, 31],
      [18, 12],
      [7, 5],
      [9, 5],
      [69, 38],
      [244, 132],
      [285, 156],
      [83, 45],
      [3, 2],
      [329, 127],
      [273, 28],
      [234, -69],
      [220, -22],
      [235, -82],
      [758, -36],
    ],
    [
      [29128, 65461],
      [-3, -164],
      [-15, -247],
      [35, -265],
      [20, -170],
      [-13, -190],
      [88, -204],
      [-7, -353],
      [102, -231],
      [73, -169],
      [124, -230],
      [97, -189],
      [95, -74],
      [76, -121],
      [75, -196],
      [0, -7],
      [50, -231],
      [36, -346],
      [16, -278],
      [-65, -341],
      [-38, -354],
      [-133, -233],
      [-222, -295],
      [-118, -477],
      [-166, -328],
      [-146, -348],
      [-187, -315],
      [-228, -288],
      [-175, -196],
    ],
    [
      [16971, 28809],
      [29, -185],
      [-20, -28],
      [133, -223],
      [274, -112],
      [273, 3],
      [300, 71],
      [218, -11],
      [453, -186],
      [221, -236],
      [428, -328],
      [125, -203],
      [316, -296],
      [37, -279],
      [330, -390],
      [-125, -390],
      [-119, -361],
      [-276, -269],
      [-209, -145],
      [-256, -200],
      [-283, -180],
      [-385, -181],
      [-433, -229],
      [-439, -243],
      [-209, -145],
      [226, -188],
      [102, 21],
      [211, -59],
      [141, 104],
      [299, 24],
      [89, 1],
      [170, -80],
      [218, -32],
      [273, -153],
      [395, -37],
      [284, 105],
      [223, 98],
      [142, 110],
      [115, 63],
      [162, 70],
      [244, 19],
    ],
    [
      [20648, 24129],
      [157, 12],
      [196, 77],
      [176, 57],
      [369, -255],
      [89, -6],
      [186, -270],
      [-45, -252],
      [-159, -342],
      [-268, -391],
      [-195, -233],
      [-125, -362],
      [-316, -323],
      [-303, -316],
      [-321, -439],
      [-335, -446],
      [-558, -577],
      [-315, -446],
      [-423, -488],
      [-112, -375],
      [-157, -539],
      [-90, -525],
      [-125, -382],
      [-78, -300],
      [-31, -341],
      [18, -150],
    ],
    [
      [17883, 16517],
      [-44, 28],
      [-131, 195],
      [-309, 371],
      [-131, 121],
      [-103, 149],
      [-117, 155],
      [-199, 161],
      [-342, 228],
      [-205, 127],
      [-62, 47],
      [-104, 209],
      [-1, 96],
      [-63, 196],
      [-77, 251],
      [-133, 318],
      [-125, 305],
      [-104, 203],
      [-132, 189],
      [-245, 99],
      [-273, 147],
      [-265, -64],
      [-428, -79],
      [-321, 10],
      [-368, -31],
      [-346, -99],
      [-293, -44],
      [-418, -24],
    ],
    [
      [10689, 23551],
      [1, 318],
      [-237, 487],
      [-342, 425],
      [-205, 243],
      [-476, 369],
      [-266, 215],
      [-518, 491],
      [-530, 225],
      [-333, 187],
      [-326, 132],
      [-239, 195],
      [-354, 370],
      [267, 486],
      [360, 596],
      [220, 605],
      [6, 16],
      [52, 314],
      [138, 478],
      [199, 539],
      [125, 498],
      [2, 585],
      [-235, 603],
      [-211, 556],
      [22, 302],
    ],
    [
      [7809, 32786],
      [111, -42],
      [323, -120],
      [279, -160],
      [361, -295],
      [130, -128],
      [245, -236],
      [198, -256],
      [44, -373],
      [118, -435],
      [173, -399],
      [218, -290],
      [361, -275],
      [320, -146],
      [297, 44],
      [161, 192],
      [630, -68],
      [190, -80],
      [332, -3],
      [339, -57],
      [184, -141],
      [351, 133],
      [291, -72],
      [232, -188],
      [272, -160],
      [367, -336],
      [193, -169],
      [226, -79],
      [157, 29],
      [122, 69],
      [328, -30],
      [403, -23],
      [292, 105],
      [334, 4],
      [272, 30],
      [137, 2],
      [171, -19],
      [0, -5],
    ],
    [
      [30996, 42930],
      [-14, -23],
      [-47, -55],
      [-33, -48],
      [-53, -109],
      [-20, -68],
      [-46, -144],
      [-26, -95],
      [-53, -170],
      [35, -102],
      [21, -88],
      [1, -34],
      [2, -204],
      [29, -190],
      [15, -129],
      [42, -122],
      [23, -190],
      [22, -163],
      [8, -95],
      [15, -143],
      [14, -68],
      [-7, -13],
      [-60, -116],
      [-13, -21],
      [-12, -102],
      [-81, -116],
      [-68, -28],
      [-114, -56],
      [-116, -42],
      [-108, -49],
      [-115, -42],
      [-155, -63],
      [-170, -43],
      [-156, -35],
      [-115, -22],
      [-136, -43],
      [-74, -48],
      [-60, -62],
      [-13, -95],
      [-13, -48],
      [-18, -231],
      [-26, -75],
      [-52, -239],
      [29, -142],
      [21, -129],
      [-17, -265],
      [36, -211],
      [14, -54],
      [90, -210],
      [70, -142],
      [48, -88],
      [70, -128],
      [70, -183],
      [49, -156],
      [69, -155],
      [85, -251],
      [42, -162],
      [57, -210],
      [36, -204],
      [43, -197],
      [36, -196],
      [10, -265],
      [29, -218],
      [9, -62],
    ],
    [
      [30145, 35473],
      [-256, -145],
      [-440, -229],
      [-290, -188],
      [-149, -69],
      [-67, -69],
      [-94, -96],
      [-114, -117],
      [-50, -41],
      [-254, -207],
      [-363, -365],
      [-86, -171],
      [-38, -286],
      [-2, -12],
    ],
    [
      [27942, 33478],
      [-141, 24],
      [-225, 73],
      [-150, 39],
      [-273, 147],
      [-191, 127],
      [-145, 155],
      [-137, 148],
      [-28, 13],
      [-61, 13],
      [-52, -31],
    ],
    [
      [26539, 34186],
      [-2, 37],
      [-96, 101],
      [-213, 121],
      [-239, 119],
      [-286, 86],
      [-293, 85],
      [-186, 74],
      [-175, 45],
      [-52, 23],
      [-166, 22],
      [-157, 25],
      [-255, 283],
      [-222, 284],
      [-229, 296],
      [-179, 114],
      [-133, 244],
      [-206, 317],
      [-66, 68],
      [-26, 15],
      [-179, 77],
      [-369, 37],
      [-410, 16],
      [-68, -2],
      [-231, -62],
      [-251, -132],
      [-422, -86],
      [-199, -136],
    ],
    [
      [21694, 39779],
      [14, -8],
      [273, -65],
      [261, -58],
      [225, -58],
      [206, -80],
      [180, -195],
      [73, -353],
      [-17, -224],
      [7, -27],
      [2, -170],
      [15, -88],
      [64, -156],
      [145, -128],
      [157, -45],
      [177, 56],
      [204, 50],
      [135, 62],
      [74, 90],
      [135, 110],
      [115, 151],
      [18, 102],
      [6, 102],
      [5, 102],
      [20, 68],
      [20, 75],
      [-29, 109],
      [-1, 34],
      [-1, 129],
      [26, 88],
      [-29, 109],
      [-35, 67],
      [-24, 211],
      [60, 157],
      [60, 110],
      [86, 191],
      [73, 164],
      [53, 116],
      [67, 96],
      [135, 124],
      [155, 137],
      [60, 83],
      [-1, 102],
      [17, 224],
      [-15, 136],
      [-22, 129],
      [-1, 61],
      [-9, 122],
      [6, 75],
      [19, 123],
      [47, 62],
      [100, 130],
      [61, 89],
      [93, 157],
      [53, 130],
      [53, 110],
      [74, 123],
      [87, 62],
      [142, 97],
      [109, 55],
      [176, 104],
      [115, 69],
      [210, 91],
      [184, 57],
      [149, 83],
      [134, 144],
      [81, 83],
      [46, 116],
      [46, 177],
      [59, 116],
      [60, 164],
      [46, 143],
      [12, 75],
      [4, 6],
    ],
    [
      [26999, 44432],
      [11, -80],
      [55, -27],
      [171, -73],
      [130, -5],
      [14, 7],
      [171, -59],
      [192, -39],
      [89, -13],
      [185, -59],
      [150, -53],
      [157, 23],
      [177, 22],
      [267, 10],
      [232, -38],
      [280, -59],
      [205, -11],
      [164, 9],
      [156, 49],
      [104, -100],
      [83, -108],
      [97, -88],
      [97, -114],
      [111, -176],
      [76, -114],
      [35, -82],
      [103, -121],
      [109, -39],
      [150, -33],
      [130, -66],
      [27, -27],
      [69, -38],
    ],
    [
      [17883, 16517],
      [114, -73],
      [179, -208],
      [218, -127],
      [212, -148],
      [164, -59],
      [299, -11],
      [183, 91],
      [142, 62],
      [17, 6],
    ],
    [
      [20539, 8785],
      [-234, 55],
      [-555, 136],
      [-301, 71],
      [-288, 58],
      [-404, 104],
      [-261, 99],
      [-175, 317],
      [-89, 543],
      [58, 307],
      [-17, 340],
      [44, 286],
      [-64, 306],
      [-166, 311],
      [-281, 207],
      [-496, -73],
      [-433, -161],
      [-371, -188],
      [-271, -145],
      [-363, -351],
      [-276, -242],
      [-283, -268],
      [-329, -207],
    ],
    [
      [19707, 47473],
      [-8, -15],
      [-94, -110],
      [-19, -157],
      [-73, -177],
      [14, -114],
    ],
    [
      [19527, 46900],
      [-13, -49],
      [-60, -76],
      [-101, -96],
      [-95, -22],
      [-196, -29],
      [-95, -21],
      [-128, -56],
      [-149, 46],
      [-129, 80],
      [-89, 46],
      [-20, 21],
      [-15, 135],
      [-28, 102],
      [-14, 129],
      [-28, 88],
      [46, 218],
      [19, 157],
      [87, 75],
      [80, 158],
      [148, 117],
      [127, 260],
      [86, 218],
      [88, 124],
      [160, 205],
      [94, 110],
      [74, 117],
      [113, 239],
      [161, 178],
      [120, 192],
      [121, 212],
      [154, 145],
      [107, 191],
      [140, 185],
      [88, 165],
      [120, 150],
      [53, 246],
      [33, 204],
      [-7, 191],
      [-33, 74],
      [-54, 149],
      [-175, 189],
      [-187, -9],
      [-202, -63],
      [-134, -49],
      [-183, -36],
      [-176, -16],
      [-155, 5],
      [-231, -9],
      [-209, -9],
      [-169, -29],
      [-115, -55],
      [-196, -91],
      [-229, -111],
      [-216, -125],
      [-134, -110],
      [-209, -145],
      [-141, -90],
      [-230, -70],
      [-142, -36],
      [-142, -49],
      [-128, -42],
      [-116, 13],
      [-237, 65],
      [-320, 146],
      [-285, 86],
      [-94, -1],
      [-231, 79],
      [-129, -1],
      [-203, 39],
      [-149, 12],
      [-244, -17],
      [-88, 27],
      [-156, 26],
      [-126, -23],
    ],
    [
      [14411, 50979],
      [-86, 111],
      [-8, 259],
      [-64, 305],
      [19, 218],
      [59, 246],
      [32, 170],
      [59, 348],
      [-36, 156],
      [47, 83],
      [-122, 61],
    ],
    [
      [19764, 54567],
      [40, -110],
      [42, -142],
      [137, -203],
      [130, -135],
      [177, -100],
      [102, -87],
      [163, -53],
      [177, -161],
      [683, -75],
      [13, 75],
      [87, 123],
      [221, 139],
      [74, 89],
      [120, 144],
      [147, 315],
      [228, 158],
      [60, 253],
      [161, 131],
      [174, 239],
      [214, 254],
      [195, 125],
      [86, 96],
      [228, 152],
      [121, 97],
      [58, 30],
    ],
    [
      [24478, 56576],
      [-9, -43],
      [-74, -96],
      [-73, -144],
      [-61, -123],
      [-39, -279],
      [1, -95],
      [-54, -103],
      [42, -101],
      [61, -68],
      [109, -59],
      [163, -19],
      [94, -13],
      [102, -26],
      [122, -5],
      [95, -6],
      [88, -12],
      [204, -66],
      [95, -13],
      [115, -19],
      [108, -19],
      [75, -87],
      [62, -68],
      [28, -156],
      [14, -88],
      [-39, -184],
      [-66, -144],
      [-87, -157],
      [-40, -95],
      [-121, -117],
      [-141, -138],
      [-87, -69],
      [-14, -7],
      [-101, -83],
      [-141, -83],
      [-128, -103],
      [-128, -110],
      [-148, -138],
      [-215, -172],
      [-263, -139],
      [-161, -104],
      [-216, -152],
      [-181, -165],
      [-229, -166],
      [-162, -131],
      [-154, -144],
      [-202, -159],
      [-135, -111],
      [-141, -110],
      [-188, -206],
      [-194, -220],
      [-128, -151],
      [-147, -226],
      [-215, -240],
      [-154, -206],
      [-261, -322],
      [-147, -274],
      [-147, -198],
      [-114, -253],
      [-166, -376],
      [-161, -226],
      [-192, -451],
      [-127, -260],
      [-45, -225],
      [-67, -211],
      [-100, -151],
      [-81, -15],
      [-105, -203],
    ],
    [
      [14411, 50979],
      [154, -200],
      [191, -148],
      [217, -65],
      [197, -87],
      [224, -59],
      [121, 22],
      [373, -65],
      [233, -331],
      [125, -312],
      [-19, -232],
      [36, -217],
      [16, -327],
      [-242, -295],
      [-18, -306],
      [-85, -280],
      [-93, -280],
      [-383, -371],
      [24, -402],
      [125, -441],
      [71, -449],
      [-194, -274],
      [-176, -70],
      [-128, -76],
      [-148, -83],
      [-161, -124],
      [-41, -73],
    ],
    [
      [14830, 45434],
      [-13, -2],
      [-95, -15],
      [-216, 18],
      [-129, 33],
      [-190, 46],
      [-176, -36],
      [-149, -42],
      [-263, -78],
      [-176, -42],
      [-230, -50],
      [-175, -56],
      [-95, -35],
      [-154, -118],
      [-176, -63],
      [-209, -97],
      [-155, -97],
      [-236, -111],
      [-189, -77],
      [-270, -71],
      [-149, 6],
      [-170, 52],
      [-386, 51],
      [-244, 45],
      [-197, 53],
      [-305, 45],
      [-625, 177],
      [-156, 39],
      [-258, 100],
      [-264, 72],
      [-271, 18],
      [-217, -29],
      [-250, -57],
      [-182, -43],
      [-122, -42],
      [-6, -7],
      [-257, -145],
      [-209, -57],
      [-155, -29],
      [-170, -15],
      [-142, -22],
      [-264, 11],
      [-128, -15],
      [-155, -49],
      [-122, -49],
      [-142, -62],
      [-196, -64],
      [-142, -35],
      [-81, -14],
      [-121, -36],
      [-110, -35],
    ],
    [
      [5338, 44405],
      [-57, 394],
      [-20, 143],
      [142, 934],
      [-89, 918],
      [-20, 973],
      [-145, 1203],
      [-18, 91],
    ],
    [
      [61258, 50788],
      [-1, -73],
      [-6, -329],
      [-11, -5],
      [-211, -98],
      [-263, -105],
      [-32, -14],
      [-132, -56],
      [-217, -91],
      [-73, -42],
      [-140, -178],
      [-133, -172],
      [-41, -82],
      [-1, -136],
      [-14, -27],
      [-41, -143],
      [-174, -261],
      [-114, -212],
      [-36, -286],
      [-67, -82],
      [-231, -166],
      [-430, -325],
      [-100, -137],
      [-284, -174],
      [-284, -221],
      [-173, -179],
      [-250, -98],
      [-520, -231],
      [-158, -97],
      [-480, -163],
      [-433, -148],
      [-126, -76],
      [-262, -51],
      [-538, -130],
      [-426, -40],
      [-504, -40],
      [-278, 55],
      [-10, 2],
      [-414, 273],
      [0, 55],
      [-25, 81],
      [-89, 134],
      [-77, 115],
      [-37, 189],
      [-76, 156],
      [-93, -56],
      [-230, -118],
      [-138, -56],
      [-126, -77],
      [-150, -115],
      [-48, -37],
      [-219, -173],
      [-157, -76],
      [-73, -1],
      [-358, 56],
      [-202, 58],
      [-373, 131],
      [-206, 100],
      [-338, 227],
      [-206, 106],
      [-173, 121],
      [-7, 6],
      [-158, 101],
      [-166, 120],
      [-159, 189],
      [-161, 249],
      [-36, 129],
      [-30, 224],
      [-155, 352],
      [-151, 521],
      [-135, 366],
      [-15, 88],
      [-100, 359],
      [-3, 8],
    ],
    [
      [48980, 49982],
      [257, 186],
      [285, 186],
      [264, 180],
      [265, 106],
      [339, 99],
      [258, 159],
      [122, 90],
      [154, 172],
      [251, 152],
      [211, 64],
      [259, 3],
      [240, -17],
      [193, -168],
      [395, 73],
      [307, 52],
      [354, 51],
      [238, 78],
      [106, 225],
      [74, 137],
      [-18, 278],
      [12, 123],
      [-63, 135],
      [-77, 155],
      [-98, 176],
      [-118, 120],
      [-64, 177],
      [13, 68],
      [73, 75],
      [-49, 90],
    ],
    [
      [53163, 53207],
      [25, -2],
      [104, -8],
      [179, -77],
      [150, 23],
      [315, -54],
      [182, -71],
      [288, -112],
      [537, -210],
      [358, -74],
      [139, -1],
      [113, 0],
      [80, -43],
      [114, -62],
      [542, -134],
      [90, -22],
      [80, -38],
      [356, -170],
      [63, 3],
      [106, 7],
      [645, -227],
      [11, -4],
      [227, -83],
      [294, -1],
      [236, -151],
      [58, -37],
      [46, -29],
      [1169, -376],
      [344, -81],
      [152, -85],
      [275, -83],
      [220, -56],
      [387, -203],
      [164, 10],
      [46, 32],
    ],
    [
      [31652, 42791],
      [-23, -6],
      [-143, 26],
      [-88, 6],
      [-143, 32],
      [-28, 14],
      [-122, 19],
      [-34, 6],
      [-75, 42],
    ],
    [
      [26999, 44432],
      [-4, 28],
      [-64, 183],
      [-8, 68],
      [-83, 87],
      [-167, 216],
      [-77, 183],
      [-116, 19],
      [-172, 114],
      [-233, 51],
      [-109, 6],
      [-225, -23],
      [-198, 11],
      [-124, 33],
      [-150, 60],
      [-71, 155],
      [-22, 116],
      [12, 95],
      [-49, 169],
      [33, 109],
      [-41, 136],
    ],
    [
      [25131, 46248],
      [33, 89],
      [80, 157],
      [107, 137],
      [188, 172],
      [88, 49],
      [88, 55],
      [59, 130],
      [101, 103],
      [128, 103],
      [107, 165],
      [19, 102],
      [7, 68],
      [-15, 109],
      [-14, 149],
      [-22, 163],
      [-1, 204],
      [-22, 149],
      [-22, 143],
      [-28, 210],
      [-9, 197],
      [-22, 204],
      [74, 89],
      [-14, 68],
      [81, 103],
      [19, 61],
      [14, 36],
    ],
    [
      [26155, 49463],
      [61, -21],
      [230, 56],
      [202, 64],
      [256, 125],
      [270, 160],
      [391, 154],
      [122, 35],
      [351, 92],
      [149, 50],
      [205, 16],
      [286, 16],
      [136, 29],
      [233, -11],
      [68, -26],
      [158, -53],
      [41, 0],
      [116, -32],
      [41, 21],
      [81, 35],
      [136, 55],
      [176, 111],
      [68, 35],
      [47, 21],
      [82, 28],
      [102, -6],
      [124, -80],
      [104, -121],
      [132, -175],
      [83, -81],
      [179, -120],
      [193, -155],
      [193, -120],
      [192, -79],
      [178, -60],
      [130, 2],
      [76, -53],
      [8, -68],
      [103, -61],
      [97, -121],
      [98, -209],
      [-94, -70],
      [-38, -245],
      [-12, -102],
      [-19, -129],
      [-47, -75],
      [-45, -164],
      [-13, -116],
      [-5, -88],
      [2, -123],
      [0, -6],
      [2, -143],
      [1, -129],
      [-7, -41],
    ],
    [
      [21661, 43440],
      [-1, 22],
      [-64, 367],
      [13, 13],
      [34, 75],
      [-8, 102],
      [-14, 89],
      [-49, 169],
      [-149, -29],
      [-74, 6],
      [-115, -15],
      [-156, -29],
      [-128, -1],
      [-116, 134],
      [-110, 162],
      [-111, 257],
      [-70, 299],
      [-151, 318],
      [-137, 270],
      [-97, 217],
      [-69, 209],
      [-63, 163],
      [-68, 155],
      [-83, 149],
      [-89, 169],
      [-55, 88],
      [-69, 121],
      [-95, 128],
      [-27, -54],
      [-6, -68],
      [-7, -26],
    ],
    [
      [19707, 47473],
      [107, -34],
      [298, -58],
      [190, -18],
      [101, 28],
      [203, 16],
      [81, 28],
      [128, 56],
      [150, -46],
      [102, -67],
      [122, -87],
      [122, -33],
      [136, -39],
      [95, 21],
      [101, 2],
      [135, 42],
      [142, 70],
      [135, 76],
      [169, 36],
      [102, -33],
      [115, -87],
      [123, -107],
      [95, -61],
      [116, -39],
      [88, 1],
      [108, -33],
      [61, -47],
      [102, -46],
      [68, 1],
      [163, -5],
      [115, -26],
      [115, 1],
      [88, 1],
      [136, -73],
      [95, -54],
      [82, -121],
      [103, -87],
      [20, -20],
      [109, -108],
      [41, -33],
      [190, -87],
      [75, -60],
      [109, -53],
      [115, -19],
      [258, -52],
      [81, 15],
      [34, 14],
    ],
    [
      [13138, 4390],
      [-65, 29],
      [-559, 191],
      [-918, 51],
      [-794, -117],
      [-561, -204],
      [-270, -193],
      [-58, -231],
    ],
    [
      [9913, 3916],
      [-138, 147],
      [-121, 455],
      [4, 299],
      [-23, 278],
      [-153, 366],
      [-220, 168],
      [-212, 141],
      [-252, 126],
      [-130, 39],
      [-240, 188],
      [-262, 378],
      [-38, 374],
      [-117, 203],
      [-139, 264],
      [-207, 249],
      [-280, 188],
      [-157, 46],
      [-123, 39],
      [-212, 209],
      [-132, 257],
      [-152, 182],
      [-134, 332],
      [-92, 203],
      [-21, 47],
      [-35, 88],
      [-147, 278],
      [-29, 115],
      [13, 68],
      [26, 109],
      [12, 82],
      [53, 130],
      [19, 102],
      [-29, 163],
      [-63, 88],
      [-54, 60],
    ],
    [
      [24441, 8749],
      [18, -17],
      [112, -155],
      [242, -214],
      [95, -6],
      [138, -53],
      [193, -127],
      [263, -241],
      [185, -100],
      [173, -107],
      [166, -161],
      [129, -121],
      [109, -126],
      [-98, 4],
      [-367, -521],
      [-106, -551],
      [-293, -412],
      [-550, -204],
      [-1100, -421],
      [-461, -277],
      [-530, -149],
      [-501, 246],
      [-242, 119],
      [-454, 742],
      [-491, 457],
      [-477, 355],
      [-501, 211],
      [-982, 369],
      [-960, 268],
      [-747, 229],
      [-654, -130],
      [-412, -291],
      [-465, -399],
      [-349, -327],
    ],
    [
      [18876, 33252],
      [-82, -459],
      [48, -496],
      [-24, -232],
      [26, -455],
      [54, -442],
      [99, -318],
      [72, -217],
      [146, -230],
      [110, -74],
      [173, -222],
      [17, -48],
    ],
    [
      [19515, 30059],
      [-275, 11],
      [-217, -84],
      [-162, -138],
      [-224, -111],
      [-237, -159],
      [-318, -174],
      [-156, -83],
      [-229, -241],
      [-278, -152],
      [-121, -97],
      [-136, -29],
      [-191, 7],
    ],
    [
      [7809, 32786],
      [37, 481],
      [-162, 584],
      [-92, 625],
      [-71, 219],
    ],
    [
      [7521, 34695],
      [251, 68],
      [399, 140],
      [202, 132],
      [168, 151],
      [208, 159],
      [113, 267],
      [114, 171],
      [328, 133],
      [329, 50],
      [644, 0],
      [567, -62],
      [596, -48],
      [630, -20],
      [706, -251],
      [325, 17],
      [502, -205],
      [192, -243],
      [320, -296],
      [394, -220],
      [347, -194],
      [231, -45],
      [427, -152],
      [168, 118],
      [196, 111],
      [237, 9],
      [264, -52],
      [204, -140],
      [42, -33],
      [-22, 54],
      [-9, 30],
    ],
    [
      [15527, 42314],
      [-132, 367],
      [-118, 318],
      [-74, 320],
      [-134, 407],
      [-95, 155],
      [-121, 94],
      [-101, 353],
      [-23, 259],
      [46, 204],
      [-64, 428],
      [119, 215],
    ],
    [
      [35423, 37435],
      [-377, -167],
      [-314, -145],
      [-125, -57],
      [-529, -156],
      [-711, -218],
      [-575, -204],
      [-631, -177],
      [-501, -121],
      [-386, -133],
      [-264, -105],
      [-311, -160],
      [-182, -145],
      [-372, -174],
    ],
    [
      [44134, 57980],
      [-9, -15],
      [-190, -307],
      [3, -240],
      [15, -197],
      [51, -271],
      [9, -238],
      [50, -183],
      [37, -292],
      [-39, -157],
      [-12, -150],
      [84, -298],
      [36, -176],
      [30, -258],
      [-3, -76],
    ],
    [
      [44196, 55122],
      [-139, 47],
      [-197, 18],
      [-103, 60],
      [-34, 0],
      [-156, -9],
      [-116, -14],
      [-238, -3],
      [-239, 174],
      [-43, 197],
      [0, 9],
    ],
    [
      [36882, 57024],
      [-142, 170],
      [-188, 250],
      [-117, 87],
      [-159, 120],
      [-158, 135],
      [-77, 128],
      [-139, 202],
      [-25, 265],
      [4, 299],
      [-107, 258],
      [-66, 427],
      [-148, 352],
      [-219, 521],
      [-135, 386],
      [-186, 372],
      [-42, 128],
      [-151, 305],
      [-76, 257],
      [-132, 413],
      [-153, 461],
      [-151, 297],
      [11, 300],
      [18, 299],
      [126, 362],
      [25, 285],
      [57, 496],
    ],
    [
      [34552, 64599],
      [45, -11],
      [7, 0],
      [46, -4],
      [105, -7],
      [193, -78],
      [125, -51],
      [32, -10],
      [222, -70],
      [246, -77],
      [55, -18],
      [184, -58],
      [82, -25],
      [385, -187],
      [17, -8],
      [128, -62],
      [45, -22],
      [39, -18],
      [267, -121],
      [247, -113],
      [7, -4],
      [314, -225],
      [3, -2],
      [46, -72],
      [6, -2],
      [11, -4],
      [170, -64],
      [289, -307],
      [167, -164],
      [32, -30],
      [95, -89],
      [183, -167],
      [12, -12],
      [156, -95],
      [3, -4],
      [87, -97],
      [46, -27],
      [307, -176],
      [7, -2],
      [592, -155],
      [78, -20],
      [264, -70],
      [142, -37],
      [204, -54],
      [165, -172],
      [20, -21],
      [4, -6],
      [21, -24],
      [244, -284],
      [38, -44],
      [427, -502],
      [3, -3],
      [181, -134],
      [7, -3],
      [394, -175],
      [3, -1],
      [53, -101],
      [14, -25],
      [22, -40],
      [16, -27],
      [71, -125],
      [63, -111],
      [71, -84],
      [2, -3],
      [248, -282],
      [31, -36],
      [576, -374],
      [122, -80],
      [129, -87],
      [278, -492],
      [315, -257],
      [132, -153],
      [200, -91],
      [1, -34],
      [40, -29],
    ],
    [
      [27857, 24273],
      [-91, -294],
      [11, -292],
      [180, -141],
      [218, -480],
      [129, -454],
      [204, -477],
    ],
    [
      [20648, 24129],
      [-34, 17],
      [-150, 121],
      [-85, 305],
      [-42, 115],
      [5, 197],
      [59, 137],
      [134, 260],
      [196, 63],
      [359, 113],
      [325, 85],
      [286, -10],
      [190, -5],
      [409, -179],
      [252, 30],
      [632, -7],
      [440, 127],
      [400, 66],
      [366, 153],
      [278, -10],
      [415, -77],
      [198, -19],
      [206, -269],
      [9, -157],
      [16, -217],
      [9, -286],
      [-18, -170],
      [88, -40],
      [382, -105],
      [258, 85],
      [279, -79],
      [756, -142],
      [353, 25],
      [238, 17],
    ],
    [
      [26155, 49463],
      [-68, 25],
      [-102, 33],
      [-55, 108],
      [-22, 231],
      [-7, 0],
      [-22, 156],
      [53, 157],
      [13, 95],
      [-22, 231],
      [-2, 279],
      [51, 360],
      [37, 456],
      [31, 409],
      [4, 367],
      [78, 374],
      [72, 341],
      [-37, 408],
      [64, 401],
      [52, 307],
      [83, 592],
      [11, 340],
      [-29, 218],
      [-21, 183],
      [-2, 258],
      [-23, 285],
      [-2, 225],
      [-45, 238],
    ],
    [
      [28499, 58621],
      [14, -30],
      [43, -265],
      [21, -163],
      [-19, -116],
      [55, -196],
      [110, -169],
      [172, -277],
      [29, -298],
      [57, -421],
      [91, -251],
      [185, -358],
      [90, -278],
      [118, -359],
      [246, -255],
      [295, -452],
      [232, -345],
      [186, -405],
      [209, -392],
      [140, -264],
      [159, -209],
      [163, 83],
      [155, 145],
      [70, 354],
      [134, 206],
      [230, 186],
      [247, -133],
      [295, -133],
      [287, -31],
      [255, -153],
      [164, -25],
      [244, 152],
      [284, 139],
      [37, 327],
      [191, 505],
      [22, 422],
      [11, 210],
      [98, 294],
      [142, 111],
    ],
    [
      [29128, 65461],
      [10, 0],
      [200, -84],
      [288, 14],
      [361, -58],
      [4, 0],
      [37, -2],
      [154, -9],
      [5, -2],
      [161, -34],
      [518, -49],
      [375, -36],
      [13, -2],
      [261, -42],
      [543, -90],
      [289, -48],
      [46, -7],
      [399, -66],
      [188, -32],
      [106, -18],
      [365, -64],
      [3, -2],
      [86, -51],
      [223, -16],
      [99, -72],
      [159, -15],
      [5, -1],
      [111, 23],
      [139, -34],
      [219, -51],
      [57, -14],
    ],
    [
      [26539, 34186],
      [-29, -17],
      [-88, -83],
      [-195, -193],
      [-188, -206],
      [-188, -213],
      [-207, -267],
      [-140, -342],
      [-241, -322],
      [-167, -240],
      [-67, -164],
      [-71, -307],
      [-4, -265],
      [-378, -270],
      [-432, -242],
      [-236, -227],
      [-292, -4],
      [-136, 60],
      [-325, -99],
      [-353, -58],
      [-238, -43],
      [-461, -80],
      [-345, -133],
      [-442, -46],
      [-332, -146],
      [-255, -309],
      [-204, -63],
      [-306, -58],
      [-295, 92],
      [-268, 113],
      [-141, 5],
    ],
    [
      [28051, 25820],
      [-20, -70],
      [16, -238],
      [45, -421],
      [-71, -287],
      [-164, -531],
    ],
    [
      [27942, 33478],
      [-54, -471],
      [-35, -586],
      [47, -564],
      [138, -876],
      [7, -700],
      [60, -510],
      [25, -469],
      [-14, -633],
      [-17, -306],
      [-9, -415],
      [19, -537],
      [-50, -402],
      [42, -142],
      [84, -217],
      [-10, -388],
      [-124, -442],
    ],
    [
      [9913, 3916],
      [-47, -185],
      [162, -543],
      [366, -526],
      [359, -568],
      [85, -298],
      [42, -183],
      [-266, -575],
      [-383, -324],
      [-270, -172],
      [-217, -139],
      [-337, -228],
      [-446, -175],
      [-824, 107],
      [-699, 659],
      [-374, 588],
      [-493, 382],
      [-432, 390],
      [-566, 218],
      [-1173, 389],
      [-858, 147],
      [-396, 152],
      [183, 621],
      [457, 536],
      [485, 481],
      [419, 243],
      [110, 505],
      [-372, 819],
      [-640, 421],
      [-914, -84],
      [-971, 173],
      [-712, 230],
      [-393, 11],
      [45, 448],
      [-1, 29],
    ],
    [
      [7521, 34695],
      [-165, 507],
      [-165, 277],
      [-385, 752],
      [-461, 901],
      [54, 279],
      [70, 362],
      [66, 1028],
      [-510, 1084],
      [-111, 414],
      [-84, 1218],
      [-96, 1006],
      [-287, 1121],
      [-109, 761],
    ],
    [
      [44196, 55122],
      [18, -6],
      [143, -73],
      [214, -283],
      [198, -120],
      [200, -229],
      [69, -149],
      [168, -412],
      [42, -183],
      [70, -231],
      [68, -557],
      [-19, -170],
      [-85, -361],
      [24, -319],
      [45, -319],
      [22, -183],
      [-18, -198],
      [100, -277],
      [105, -216],
      [70, -122],
      [84, -186],
    ],
    [
      [45714, 50528],
      [-65, -9],
      [-388, -113],
      [-382, -25],
      [-395, -25],
      [-435, -25],
      [-198, 8],
      [-60, 3],
      [-388, 70],
      [-442, 57],
      [-422, 56],
      [-552, 164],
      [-463, 104],
      [-571, -26],
      [-820, -152],
      [-643, -87],
    ],
    [
      [48980, 49982],
      [-40, 141],
      [-182, 277],
      [-366, 294],
      [0, -6],
      [-96, 26],
      [-245, -10],
      [-103, 6],
      [-157, 4],
      [-313, -85],
      [-272, -105],
      [-582, 88],
      [-40, -14],
      [-293, -17],
      [-328, -17],
      [-249, -36],
    ],
    [
      [44134, 57980],
      [61, -46],
      [101, -76],
      [125, -94],
      [36, -27],
      [6, -4],
      [86, -89],
      [71, -74],
      [77, -81],
      [27, -27],
      [61, -63],
      [62, -65],
      [51, -53],
      [177, 11],
      [123, 39],
      [133, 42],
      [101, 33],
      [90, 29],
      [96, 30],
      [48, 16],
      [135, 37],
      [156, 43],
      [158, 43],
      [102, 28],
      [107, 29],
      [124, 34],
      [162, 7],
      [349, 17],
      [373, -58],
      [6, -2],
      [111, -33],
      [372, -111],
      [252, -103],
      [76, -31],
      [107, -44],
      [6, -3],
      [9, -4],
      [174, -92],
      [47, -25],
      [95, -50],
      [70, -37],
      [172, -97],
      [71, -40],
      [71, -41],
      [96, -54],
      [143, -81],
      [36, -20],
      [99, -68],
      [69, -47],
      [56, -39],
      [64, -43],
      [80, -56],
      [48, -33],
      [60, -40],
      [61, -43],
      [44, -30],
      [23, -15],
      [124, -114],
      [80, -74],
      [13, -11],
      [2, -3],
      [27, -28],
      [64, -66],
      [75, -76],
      [89, -32],
      [83, -87],
      [131, -138],
      [34, -36],
      [87, -91],
      [44, -47],
      [78, -82],
      [110, -117],
      [56, -72],
      [70, -89],
      [77, -98],
      [86, -109],
      [14, -19],
      [41, -78],
      [21, -40],
      [59, -115],
      [33, -64],
      [24, -46],
      [37, -72],
      [28, -54],
      [133, -160],
      [4, -151],
      [1, -45],
      [2, -89],
      [37, -76],
      [70, -145],
      [380, -175],
      [172, -63],
      [124, -46],
      [4, -3],
      [109, -77],
      [75, -52],
      [-1, 27],
      [78, -35],
      [70, -32],
      [81, -36],
      [86, -39],
      [46, -21],
      [12, -6],
      [45, -24],
      [114, -60],
      [84, -6],
    ],
  ],
  transform: {
    scale: [0.000034312364081064016, 0.000033939624651121574],
    translate: [6.875079347394387, 11.110751348853348],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Daura",
            id: "KT001",
            nameAbbr: "Daura",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.6809733192,
            Shape_Area: 0.02613923893,
          },
        },
        {
          arcs: [[4, 5, 6, 7, 8, -2]],
          type: "Polygon",
          properties: {
            name: "Dutsi",
            id: "KT002",
            nameAbbr: "Dutsi",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.76447695672,
            Shape_Area: 0.02395138616,
          },
        },
        {
          arcs: [[9, 10, 11, 12, 13, 14]],
          type: "Polygon",
          properties: {
            name: "Dutsinma",
            id: "KT003",
            nameAbbr: "Dutsinma",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.08722206212,
            Shape_Area: 0.04529153664,
          },
        },
        {
          arcs: [[15, 16, 17, 18, 19, 20]],
          type: "Polygon",
          properties: {
            name: "Faskari",
            id: "KT004",
            nameAbbr: "Faskari",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.80237188498,
            Shape_Area: 0.14050895666,
          },
        },
        {
          arcs: [[21, 22, 23, 24, -17]],
          type: "Polygon",
          properties: {
            name: "Funtua",
            id: "KT005",
            nameAbbr: "Funtua",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03281247991,
            Shape_Area: 0.03979423485,
          },
        },
        {
          arcs: [[25, 26, 27, 28, -7, 29]],
          type: "Polygon",
          properties: {
            name: "Ingawa",
            id: "KT006",
            nameAbbr: "Ingawa",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.45292197487,
            Shape_Area: 0.06345776735,
          },
        },
        {
          arcs: [[30, 31, 32, 33, 34]],
          type: "Polygon",
          properties: {
            name: "Jibia",
            id: "KT007",
            nameAbbr: "Jibia",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.90392246707,
            Shape_Area: 0.09122461513,
          },
        },
        {
          arcs: [[35, 36, 37, 38, 39]],
          type: "Polygon",
          properties: {
            name: "Kafur",
            id: "KT008",
            nameAbbr: "Kafur",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.44130640163,
            Shape_Area: 0.08516459658,
          },
        },
        {
          arcs: [[40, 41, 42, 43, -35, 44, 45]],
          type: "Polygon",
          properties: {
            name: "Kaita",
            id: "KT009",
            nameAbbr: "Kaita",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25479630978,
            Shape_Area: 0.08074339811,
          },
        },
        {
          arcs: [[46, 47, 48, -21, 49, 50]],
          type: "Polygon",
          properties: {
            name: "Kankara",
            id: "KT010",
            nameAbbr: "Kankara",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.91696077005,
            Shape_Area: 0.11870893628,
          },
        },
        {
          arcs: [[51, 52, 53, 54, -10, 55, 56]],
          type: "Polygon",
          properties: {
            name: "Kankiya",
            id: "KT011",
            nameAbbr: "Kankiya",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.33309564714,
            Shape_Area: 0.07071479692,
          },
        },
        {
          arcs: [[57, -38, 58, -22, -16, -49]],
          type: "Polygon",
          properties: {
            name: "Bakori",
            id: "KT012",
            nameAbbr: "Bakori",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.24369299113,
            Shape_Area: 0.05523938312,
          },
        },
        {
          arcs: [[59, 60, 61, -32, 62, -43, 63]],
          type: "Polygon",
          properties: {
            name: "Batagarawa",
            id: "KT013",
            nameAbbr: "Batagarawa",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.42530181879,
            Shape_Area: 0.03674367967,
          },
        },
        {
          arcs: [[64, 65, 66, -33, -62]],
          type: "Polygon",
          properties: {
            name: "Batsari",
            id: "KT014",
            nameAbbr: "Batsari",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.48845065315,
            Shape_Area: 0.08896261976,
          },
        },
        {
          arcs: [[67, 68, 69]],
          type: "Polygon",
          properties: {
            name: "Baure",
            id: "KT015",
            nameAbbr: "Baure",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.11665890807,
            Shape_Area: 0.06102601704,
          },
        },
        {
          arcs: [[-28, 70, -57, 71, 72, 73]],
          type: "Polygon",
          properties: {
            name: "Bindawa",
            id: "KT016",
            nameAbbr: "Bindawa",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.0040049924,
            Shape_Area: 0.03262865872,
          },
        },
        {
          arcs: [[-72, -56, -15, 74, -60, 75]],
          type: "Polygon",
          properties: {
            name: "Charanchi",
            id: "KT017",
            nameAbbr: "Charanchi",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.9928328119,
            Shape_Area: 0.03845507498,
          },
        },
        {
          arcs: [[76, 77, -18, -25]],
          type: "Polygon",
          properties: {
            name: "Dandume",
            id: "KT018",
            nameAbbr: "Dandume",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.86027771999,
            Shape_Area: 0.03537524114,
          },
        },
        {
          arcs: [[78, -23, -59, -37]],
          type: "Polygon",
          properties: {
            name: "Danja",
            id: "KT019",
            nameAbbr: "Danja",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.10254579094,
            Shape_Area: 0.03846894405,
          },
        },
        {
          arcs: [[-12, 79, 80, -51, 81, 82]],
          type: "Polygon",
          properties: {
            name: "Danmusa",
            id: "KT020",
            nameAbbr: "Danmusa",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.13260039448,
            Shape_Area: 0.0657821681,
          },
        },
        {
          arcs: [[-31, -44, -63]],
          type: "Polygon",
          properties: {
            name: "Katsina",
            id: "KT021",
            nameAbbr: "Katsina",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.42609558202,
            Shape_Area: 0.0120753145,
          },
        },
        {
          arcs: [[-75, -14, 83, -65, -61]],
          type: "Polygon",
          properties: {
            name: "Kurfi",
            id: "KT022",
            nameAbbr: "Kurfi",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.20760992681,
            Shape_Area: 0.04626107153,
          },
        },
        {
          arcs: [[84, -52, -71, -27]],
          type: "Polygon",
          properties: {
            name: "Kusada",
            id: "KT023",
            nameAbbr: "Kusada",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.78072855466,
            Shape_Area: 0.03307502014,
          },
        },
        {
          arcs: [[85, 86, -4, 87, 88]],
          type: "Polygon",
          properties: {
            name: "Mai'Adua",
            id: "KT024",
            nameAbbr: "Mai'Adua",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.11915891514,
            Shape_Area: 0.04790078956,
          },
        },
        {
          arcs: [[89, -39, -58, -48, 90]],
          type: "Polygon",
          properties: {
            name: "Malumfashi",
            id: "KT025",
            nameAbbr: "Malumfashi",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.21217011172,
            Shape_Area: 0.05471781355,
          },
        },
        {
          arcs: [[-8, -29, -74, 91, -41, 92]],
          type: "Polygon",
          properties: {
            name: "Mani",
            id: "KT026",
            nameAbbr: "Mani",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.45768281822,
            Shape_Area: 0.06436903088,
          },
        },
        {
          arcs: [[-88, -3, -9, -93, -46, 93]],
          type: "Polygon",
          properties: {
            name: "Mashi",
            id: "KT027",
            nameAbbr: "Mashi",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.28923411025,
            Shape_Area: 0.07562934047,
          },
        },
        {
          arcs: [[-55, 94, -80, -11]],
          type: "Polygon",
          properties: {
            name: "Matazu",
            id: "KT028",
            nameAbbr: "Matazu",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.85112553549,
            Shape_Area: 0.04280891518,
          },
        },
        {
          arcs: [[95, -91, -47, -81, -95, -54, 96]],
          type: "Polygon",
          properties: {
            name: "Musawa",
            id: "KT029",
            nameAbbr: "Musawa",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.52034505402,
            Shape_Area: 0.07135155366,
          },
        },
        {
          arcs: [[-92, -73, -76, -64, -42]],
          type: "Polygon",
          properties: {
            name: "Rimi",
            id: "KT030",
            nameAbbr: "Rimi",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.07543502545,
            Shape_Area: 0.03926764005,
          },
        },
        {
          arcs: [[97, -19, -78]],
          type: "Polygon",
          properties: {
            name: "Sabuwa",
            id: "KT031",
            nameAbbr: "Sabuwa",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.1888174194,
            Shape_Area: 0.05490472921,
          },
        },
        {
          arcs: [[-84, -13, -83, 98, -66]],
          type: "Polygon",
          properties: {
            name: "Safana",
            id: "KT032",
            nameAbbr: "Safana",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.43668728298,
            Shape_Area: 0.11209215403,
          },
        },
        {
          arcs: [[99, 100, -5, -1, -87]],
          type: "Polygon",
          properties: {
            name: "Sandamu",
            id: "KT033",
            nameAbbr: "Sandamu",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.70092245483,
            Shape_Area: 0.02387926576,
          },
        },
        {
          arcs: [[-69, 101, -100, -86, 102]],
          type: "Polygon",
          properties: {
            name: "Zango",
            id: "KT034",
            nameAbbr: "Zango",
            parent: "Katsina",
            parentAbbr: "KT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.01932646998,
            Shape_Area: 0.05377814986,
          },
        },
      ],
    },
  },
};

const kano = {
  type: "Topology",
  arcs: [
    [
      [44228, 41436],
      [53, -171],
      [93, -1149],
      [-500, -867],
      [-22, -232],
    ],
    [
      [43852, 39017],
      [-10, -1],
      [-168, -9],
      [-175, -23],
      [-525, -27],
      [-190, -3],
      [-427, -39],
      [-295, -4],
      [-296, -4],
      [-310, 1],
      [-217, 51],
      [-152, -3],
      [-93, -21],
      [-289, -38],
      [-173, -68],
      [-245, -111],
      [-302, -170],
      [-143, -116],
      [-108, -154],
      [-129, -75],
      [-108, 11],
      [-296, 16],
      [-246, 36],
      [-368, -38],
      [-224, 17],
      [-137, 24],
      [-325, 135],
      [-87, -1],
      [-72, -8],
      [-238, -16],
      [-39, 34],
      [-404, 352],
      [-105, 239],
      [-291, 303],
      [0, 78],
      [-8, 10],
      [-139, 141],
      [-192, 128],
      [-166, 60],
      [-166, 52],
      [-193, 12],
      [-87, -69],
      [-99, -82],
      [-114, -97],
      [-162, -108],
    ],
    [
      [35339, 39432],
      [-24, -16],
      [-113, -29],
      [-78, 20],
      [-83, 54],
      [-80, 115],
      [-81, 189],
      [-184, 230],
      [-59, 142],
      [-152, 135],
      [-71, 54],
      [-137, -63],
      [-101, -55],
      [-132, -76],
      [-135, -117],
      [-117, -144],
      [-159, -124],
      [-173, -152],
      [-83, -61],
      [-38, -79],
    ],
    [
      [33339, 39455],
      [10, 140],
      [11, 435],
      [47, 307],
      [-21, 299],
      [-5, 265],
      [21, 389],
      [30, 347],
      [23, 95],
      [-121, 27],
      [-46, 16],
    ],
    [
      [33288, 41775],
      [169, -34],
      [22, 87],
      [72, 96],
      [130, 144],
      [120, 90],
      [185, 151],
      [176, 77],
      [141, 21],
      [242, 57],
      [135, 253],
      [100, 171],
      [81, 137],
      [79, 117],
      [94, 41],
      [94, 165],
      [110, 103],
      [118, 158],
      [99, 21],
      [229, 70],
      [114, -53],
      [54, 109],
      [50, 130],
      [130, 35],
      [181, 63],
      [141, 28],
      [333, 121],
    ],
    [
      [36687, 44133],
      [348, 267],
      [121, 110],
      [89, 49],
      [21, 30],
      [230, 20],
      [89, 27],
      [0, 56],
      [445, -16],
      [1033, 135],
      [267, -676],
      [6, -1204],
      [645, -117],
      [857, 85],
      [1089, 662],
      [693, 822],
      [587, 250],
      [335, -948],
      [686, -2249],
    ],
    [
      [47414, 30280],
      [-765, -242],
      [-1903, 193],
      [-190, 577],
      [-286, 96],
      [-856, -289],
      [-190, 578],
      [-952, 0],
      [-1807, -1732],
      [-1047, 0],
      [-1308, 265],
    ],
    [
      [38110, 29726],
      [-24, 216],
      [-695, 176],
      [-1, -92],
      [-62, 21],
      [-67, 20],
      [-63, 82],
      [-222, 25],
      [-1, 100],
      [-72, 13],
      [-119, 40],
      [-206, 121],
      [-119, 53],
      [-199, 120],
      [-173, 114],
      [-99, 67],
      [-106, 88],
      [-152, 127],
      [-146, 156],
      [-146, 107],
      [-80, 54],
    ],
    [
      [35358, 31334],
      [41, 109],
      [40, 102],
      [54, 117],
      [40, 102],
      [0, 129],
      [-32, 156],
      [53, 69],
      [40, 123],
      [80, 69],
      [27, 48],
      [53, 82],
      [74, 89],
      [80, 41],
      [80, 35],
      [73, 55],
      [67, 103],
      [27, 137],
      [33, 61],
      [-39, 109],
      [-6, 33],
      [-20, 129],
      [22, 29],
    ],
    [
      [36145, 33261],
      [85, 109],
      [66, 41],
      [74, 62],
      [53, 21],
      [93, 15],
      [139, -26],
      [133, -6],
      [113, -26],
      [32, -18],
      [68, -38],
      [6, -12],
      [111, -249],
      [135, -229],
      [152, 29],
      [11, 2],
      [299, 49],
      [274, 44],
      [295, 83],
      [217, -23],
      [461, 126],
      [303, 84],
      [216, 57],
      [260, -23],
      [448, -54],
      [325, 5],
      [476, 13],
      [462, -26],
      [332, 38],
      [374, 145],
      [288, 131],
      [316, 125],
      [230, 163],
      [206, 286],
    ],
    [
      [43198, 34159],
      [284, 7],
      [959, 94],
      [515, -465],
      [236, -935],
      [388, -735],
      [307, -556],
      [786, -806],
      [546, -434],
      [195, -49],
    ],
    [
      [15408, 45667],
      [114, -167],
      [51, -720],
      [134, -412],
      [59, -454],
      [-68, -204],
      [-135, -287],
      [-107, -178],
      [-14, -68],
      [-43, -45],
    ],
    [
      [15399, 43132],
      [-308, -320],
      [-422, -236],
      [-304, -174],
      [-572, -204],
      [-286, -126],
      [-252, -44],
      [-68, -35],
      [-40, -34],
      [-48, -35],
      [-40, -48],
      [0, -17],
    ],
    [
      [13059, 41859],
      [-60, 11],
    ],
    [
      [12999, 41870],
      [-1, 26],
      [-201, 140],
      [-110, 60],
      [-170, 4],
      [-185, 5],
      [-117, 60],
      [-146, 188],
      [-91, 176],
      [22, 360],
      [-31, 230],
      [-23, 231],
      [-136, 386],
      [-45, 332],
      [-477, 368],
      [-287, 24],
      [-730, -64],
      [-770, -97],
      [-362, 15],
      [-125, 101],
      [-42, 570],
      [-24, 258],
      [-7, 482],
      [42, 429],
      [87, 585],
      [-20, 78],
    ],
    [
      [9050, 46817],
      [54, 4],
      [180, -167],
      [175, 144],
      [188, 227],
      [167, 253],
      [257, 207],
      [309, -146],
      [254, -71],
      [240, -72],
      [211, 77],
      [197, 98],
      [269, 255],
      [119, 313],
      [30, 245],
      [226, 24],
      [176, 97],
      [67, 76],
      [-10, 92],
    ],
    [
      [12159, 48473],
      [303, -55],
      [322, -71],
      [255, -119],
      [357, -104],
      [426, -213],
      [205, -111],
    ],
    [
      [14027, 47800],
      [24, -130],
      [121, -372],
      [196, -317],
      [166, -671],
      [171, 2],
      [186, -161],
      [293, -397],
      [164, 2],
      [60, -89],
    ],
    [
      [20729, 29935],
      [22, -39],
      [27, -107],
      [22, -81],
      [30, -73],
      [-26, -48],
      [81, -221],
      [18, -54],
      [-9, -102],
      [-23, -162],
      [16, -95],
      [0, -47],
      [53, -161],
      [38, -121],
      [177, -138],
      [121, -4],
      [77, -155],
      [-18, -109],
      [-47, -69],
      [-54, -102],
      [-61, -123],
      [-84, -76],
      [-16, -191],
      [-22, -82],
      [-12, -102],
      [-8, -47],
      [-19, -130],
      [-89, -171],
      [-105, -144],
      [-180, -172],
      [-95, -62],
      [-6, -225],
      [7, -108],
      [84, -387],
      [-10, -191],
      [-1, -7],
      [113, -239],
    ],
    [
      [20730, 25590],
      [-4, -25],
      [-90, -185],
      [-52, -75],
      [-104, -117],
      [-48, -89],
      [-77, -205],
      [-59, -61],
      [-162, -172],
      [-149, -165],
      [-110, -117],
      [-116, -117],
      [-97, -116],
      [5, -7],
      [-70, -48],
      [-101, -76],
      [-109, -103],
      [-191, -145],
      [-171, -104],
      [-177, -124],
      [-133, -89],
      [-158, -97],
      [-127, -90],
      [-147, -117],
      [-78, -96],
      [-112, -151],
      [-61, -50],
    ],
    [
      [18032, 22849],
      [-110, 49],
      [-239, 15],
      [-258, 8],
      [-385, -42],
      [-300, 8],
      [-214, 2],
      [-432, 132],
      [-143, 85],
      [-223, 104],
      [-221, 96],
      [-158, 93],
      [-91, 67],
      [-119, 157],
      [-85, 129],
      [-127, 183],
      [-51, 170],
      [-58, 143],
      [-89, 333],
      [-151, 368],
      [-157, 340],
      [-129, 285],
      [-83, 409],
      [-46, 259],
      [14, 354],
      [10, 198],
      [48, 424],
      [61, 429],
      [103, 397],
      [108, 478],
      [78, 252],
      [83, 369],
      [32, 157],
      [-52, 191],
      [-23, 150],
      [29, 273],
      [0, 20],
      [16, 253],
      [14, 375],
      [80, 252],
      [14, 48],
      [116, 382],
      [134, 246],
      [82, 191],
      [127, 260],
      [76, 279],
      [50, 340],
      [81, 137],
      [128, 314],
      [101, 198],
      [185, 139],
      [156, 409],
      [153, 172],
      [93, 76],
      [67, 136],
      [59, 35],
      [126, 90],
      [56, 48],
    ],
    [
      [16568, 34314],
      [17, 14],
      [99, 76],
      [79, 49],
      [53, 28],
      [46, 0],
      [101, -195],
      [34, -13],
      [166, -40],
      [69, -7],
      [70, -54],
      [5, -6],
    ],
    [
      [17307, 34166],
      [51, -55],
      [91, -109],
      [-13, -40],
      [-3, -219],
      [-11, -129],
      [23, -157],
      [64, -95],
      [56, -54],
      [63, -95],
      [30, -102],
      [25, -232],
      [9, -150],
      [23, -150],
      [57, -95],
      [71, -95],
      [36, -116],
      [28, -41],
      [44, -122],
      [22, -61],
      [55, -48],
      [50, -68],
      [49, -34],
      [84, -94],
      [90, -68],
      [84, -81],
      [125, -61],
      [113, -129],
      [78, -136],
      [85, -156],
      [71, -88],
      [0, -21],
      [70, -95],
      [23, -95],
      [37, -150],
      [63, -68],
      [90, -13],
      [49, -34],
      [55, -33],
      [112, -75],
      [117, -33],
      [48, -13],
      [152, -33],
      [90, -34],
      [181, -118],
      [76, 2],
      [73, 22],
      [87, -5],
      [41, -40],
      [140, -71],
      [79, -12],
      [83, -39],
      [141, -125],
      [12, -20],
      [15, -41],
      [38, -7],
    ],
    [
      [21139, 52874],
      [16, -1],
      [307, -71],
      [249, -390],
      [21, -407],
      [331, -165],
      [291, -308],
      [219, -221],
      [64, -94],
      [62, -271],
      [-24, -333],
      [-11, -292],
      [4, -154],
    ],
    [
      [22668, 50167],
      [1, -9],
      [-128, -232],
      [-159, -165],
      [-68, -205],
      [-127, -144],
      [-8, -156],
      [-101, -178],
      [-338, 158],
      [-205, 289],
      [-232, 255],
      [-244, -105],
      [-298, -235],
      [-239, -261],
      [-219, -241],
      [-252, -275],
      [-193, -233],
      [-461, -237],
      [-139, -145],
      [-114, -185],
      [-53, -68],
      [-186, -138],
      [-235, -4],
      [-385, 56],
      [-401, -121],
      [-311, -242],
      [-363, -249],
      [-106, -110],
      [-207, -172],
      [-195, -193],
      [-416, -53],
      [-400, 294],
      [-457, 409],
      [-434, 239],
      [-335, 78],
      [-41, 6],
      [-481, 144],
      [-111, 61],
    ],
    [
      [12159, 48473],
      [-21, 192],
      [-265, 391],
      [-210, 371],
      [31, 232],
      [-172, 555],
      [-189, 357],
      [-74, 346],
      [-14, 34],
      [6, 88],
      [-127, 264],
      [-99, 256],
      [-127, 332],
      [-29, 176],
      [-233, 208],
      [-57, 251],
      [80, 124],
      [188, 206],
      [243, 186],
      [182, 111],
      [-9, 21],
    ],
    [
      [11263, 53174],
      [111, 28],
      [169, 22],
      [238, -38],
      [459, -103],
      [397, -63],
      [371, -166],
      [462, -259],
      [405, -206],
      [281, -23],
      [542, -164],
      [260, -31],
      [351, -172],
      [213, -106],
      [399, -246],
      [446, -104],
      [288, -64],
      [144, -59],
      [281, -99],
      [373, -240],
      [213, -147],
      [276, -207],
      [303, -132],
      [294, -37],
      [168, 178],
      [18, 204],
      [244, 160],
      [327, 24],
      [429, 134],
      [443, 230],
      [324, 283],
      [291, 242],
      [228, 403],
      [128, 458],
    ],
    [
      [27122, 34898],
      [39, -71],
      [21, -61],
      [70, -190],
      [76, -183],
      [51, -197],
      [115, -258],
      [71, -271],
      [-2, -272],
      [20, -205],
      [103, -244],
      [69, -81],
      [104, -114],
      [119, -60],
      [102, -34],
      [104, -5],
      [131, 55],
      [76, 42],
      [70, 55],
      [41, 82],
      [57, 164],
      [3, 54],
      [45, 280],
      [17, 89],
      [-6, 244],
      [30, 218],
      [101, 301],
      [94, 273],
      [58, 177],
      [91, 205],
      [70, 178],
      [124, 158],
      [150, 178],
      [145, 83],
      [136, 22],
      [158, -12],
      [107, -60],
      [55, -109],
      [121, -142],
      [80, -101],
      [68, -101],
      [84, -156],
    ],
    [
      [30290, 34829],
      [-74, -123],
      [15, -184],
      [43, -353],
      [-3, -286],
      [-2, -143],
      [2, -39],
    ],
    [
      [30271, 33701],
      [9, -226],
      [17, -252],
      [48, -258],
      [2, -184],
      [-61, -130],
      [-60, -327],
      [0, -7],
      [85, -237],
      [-77, -178],
      [-32, -288],
    ],
    [
      [30202, 31614],
      [-52, -33],
      [-86, -28],
      [-52, 61],
      [-33, -34],
      [-137, -56],
      [-95, -55],
      [-168, -70],
      [-222, -29],
      [-60, 19],
      [-66, 27],
      [-77, 47],
      [-42, 6],
      [-98, 0],
      [-161, -36],
      [-117, -21],
      [-25, -21],
      [-105, -15],
      [-194, 26],
      [-98, -2],
      [-141, -7],
      [-111, -22],
      [-93, -21],
      [-129, -29],
      [-192, -42],
      [-162, -56],
      [-59, -14],
    ],
    [
      [27427, 31209],
      [-59, -15],
      [-68, -14],
      [-62, -14],
      [-31, -14],
      [-191, -36],
      [-117, -21],
      [-196, -9],
      [-91, 6],
      [-166, -8],
      [-148, -29],
      [-131, -56],
      [-45, -41],
      [-155, -29],
      [-55, -14],
      [-145, -69],
      [-22, -82],
      [-32, -157],
      [-53, -75],
      [-69, -28],
      [-13, -14],
      [-10, -75],
      [-77, -62],
      [-55, -123],
      [-35, -96],
      [-1, -13],
      [-110, -110],
      [-14, -34],
      [-162, -56],
      [-69, -35],
      [-68, -14],
      [-6, 0],
      [-32, 74],
      [-33, 197],
      [-20, 75],
      [-6, 7],
      [-2, 75],
      [29, 88],
      [1, 28],
      [-13, 108],
      [-16, 35],
      [-54, 26],
      [-15, 61],
      [-6, 0],
      [-27, 184],
      [9, 47],
      [-222, -36],
      [-57, -34],
      [-44, -14],
      [-80, -28],
      [-26, -14],
      [-92, -22],
      [-113, -41],
      [-152, -104],
      [-215, -138],
      [-205, -84],
      [-213, -97],
      [-196, -111],
      [-199, -76],
      [-148, -29],
      [-195, 12],
      [-66, 22],
    ],
    [
      [22563, 30083],
      [-5, 18],
      [-19, 122],
      [-25, 190],
      [-19, 162],
      [-4, 223],
      [-8, -7],
      [-4, 74],
      [-111, 201],
      [-16, 94],
      [-74, 181],
      [18, 95],
      [-145, 145],
      [-84, 93],
      [-103, 153],
      [-30, 175],
      [74, 117],
      [-188, 97],
      [-23, 34],
      [-247, 150],
      [-224, 110],
      [-181, 199],
      [15, 362],
      [107, 164],
      [21, -33],
      [-15, 61],
      [106, 212],
      [39, 143],
      [66, 151],
      [238, 138],
      [30, 8],
    ],
    [
      [21752, 33915],
      [121, 34],
      [355, 66],
      [208, 32],
      [193, 71],
      [93, 104],
      [45, 81],
      [139, 186],
      [75, 110],
      [20, 88],
      [104, 185],
      [138, 191],
      [182, 180],
      [156, 139],
      [182, 132],
      [165, 84],
      [230, 100],
      [243, 126],
      [416, 165],
      [161, 57],
      [278, -28],
      [245, -89],
      [152, -139],
      [116, -133],
      [50, -94],
      [123, -101],
      [134, -128],
      [62, -204],
      [74, -108],
      [213, -250],
      [138, -53],
      [120, -46],
      [104, 7],
      [143, 49],
      [39, 48],
      [33, 41],
      [120, 80],
    ],
    [
      [21644, 42146],
      [-31, 158],
      [76, 273],
      [101, 184],
      [126, 145],
      [47, 116],
      [126, 130],
      [166, 159],
      [126, 131],
      [270, 112],
      [165, 97],
      [346, 91],
      [138, 1],
    ],
    [
      [23300, 43743],
      [-80, -164],
      [-121, -164],
      [38, -184],
      [16, -102],
      [173, -88],
      [-55, -410],
      [380, -65],
      [73, -6],
    ],
    [
      [23724, 42560],
      [-31, -28],
      [-335, -118],
      [-240, -70],
      [-487, -37],
      [-206, -35],
      [-261, -36],
      [-428, -72],
      [-92, -18],
    ],
    [
      [32256, 51444],
      [-226, -138],
      [-243, -220],
      [-382, -166],
      [-382, -207],
      [-384, -57],
      [-406, -9],
      [-239, -110],
      [-455, 72],
    ],
    [
      [29539, 50609],
      [-112, 135],
      [-253, 265],
      [-312, 161],
      [-451, 243],
      [-203, 169],
      [-375, 195],
      [-224, -119],
      [-62, -211],
      [-109, -341],
      [55, -333],
      [-50, -280],
      [-264, -247],
      [-305, -220],
      [-94, -144],
      [-263, -85],
      [-321, -32],
      [-178, -104],
      [-155, 38],
    ],
    [
      [25863, 49699],
      [-112, 26],
      [-120, 318],
      [-57, 630],
      [-158, 412],
      [-166, 324],
      [-405, 483],
      [-410, 652],
      [-294, 512],
      [-355, 355],
      [-405, 83],
      [-165, -97],
      [-114, -239],
      [-211, -126],
      [-183, 11],
      [-42, 305],
      [66, 510],
      [81, 694],
      [231, 1062],
      [66, 571],
      [264, 153],
      [322, 106],
      [473, 156],
      [445, 488],
      [72, 469],
      [-485, 414],
      [-301, 506],
      [-121, 332],
      [264, 172],
      [183, 98],
      [119, 300],
      [118, 308],
      [23, 168],
    ],
    [
      [24486, 59855],
      [399, 43],
      [308, -3],
      [473, -164],
      [175, -59],
      [353, -76],
      [619, -122],
      [169, -30],
      [73, -14],
      [332, -145],
      [364, -117],
      [418, -97],
      [365, -22],
      [320, -98],
      [122, -174],
      [22, -252],
      [-28, -170],
      [35, -346],
      [-27, -523],
      [28, -312],
      [35, -312],
      [-45, -401],
      [68, -278],
      [159, -351],
      [375, -294],
      [183, -79],
      [247, -105],
      [359, -256],
      [424, -250],
      [346, -87],
      [237, -217],
      [122, -201],
      [84, -139],
      [53, -286],
      [129, -327],
      [126, -484],
      [-15, -286],
      [-173, -343],
      [-77, -314],
      [345, -476],
      [134, -176],
      [134, -68],
    ],
    [
      [27122, 34898],
      [-28, 51],
      [-6, 6],
      [-107, 394],
      [-189, 372],
      [-140, 584],
      [-264, 325],
      [-436, 301],
      [-561, 137],
      [-285, 69],
      [-319, 67],
      [-290, 48],
      [-272, 41],
      [-179, 140],
      [-194, 94],
      [-58, 123],
      [-223, 189],
      [-126, 116],
      [38, 163],
      [-67, 7],
    ],
    [
      [23416, 38125],
      [18, 89],
      [190, 124],
      [259, 124],
      [356, 111],
      [164, 76],
      [-47, 51],
      [45, 4],
      [241, 1],
      [86, 29],
    ],
    [
      [24728, 38734],
      [140, 48],
      [236, 3],
      [263, 60],
      [324, 54],
      [294, 80],
      [274, 242],
      [310, 331],
      [95, 441],
      [64, 373],
      [3, 40],
    ],
    [
      [26731, 40406],
      [8, -5],
      [474, -328],
      [220, -185],
      [203, -239],
      [395, -261],
      [377, -235],
      [286, -133],
      [190, -230],
      [238, -236],
      [302, -405],
      [215, -338],
      [357, -303],
      [789, -327],
      [823, -148],
      [505, -172],
      [193, -46],
      [25, -29],
    ],
    [
      [32331, 36786],
      [-65, -155],
      [-146, -226],
      [-105, -124],
      [-136, -144],
      [-125, -185],
      [-143, -151],
      [-133, -212],
      [-157, -84],
      [-99, -28],
      [-169, -83],
      [-213, -97],
      [-147, -110],
      [-126, -70],
      [-170, -110],
      [-107, -178],
    ],
    [
      [23566, 49634],
      [-75, -143],
      [-71, -402],
      [-18, -299],
      [-31, -421],
      [20, -407],
      [-82, -259],
      [48, -366],
      [-69, -204],
      [10, -217],
      [-22, -224],
      [-2, -170],
      [31, -88],
      [20, 0],
      [-11, -95],
    ],
    [
      [23314, 46339],
      [-4, -41],
      [-396, -222],
      [-265, -221],
      [-271, -214],
      [-304, -215],
      [-81, -245],
      [-260, -309],
      [-107, -199],
      [-165, -124],
      [-62, -184],
      [-94, -151],
      [-185, -199],
      [-55, -164],
      [-165, -90],
      [-139, -145],
      [4, -203],
      [44, -74],
      [32, -109],
      [11, -94],
      [32, -81],
      [51, -136],
      [5, -81],
      [0, -41],
      [12, -81],
      [25, -47],
      [66, -360],
      [-28, -116],
    ],
    [
      [21015, 42193],
      [-59, -61],
      [-27, -6],
      [-43, 73],
      [-176, 52],
      [-317, 295],
      [-188, 112],
      [-175, 141],
      [-96, 154],
      [-285, 220],
      [-129, 141],
      [-350, 213],
      [-292, 233],
      [-304, 220],
      [-123, 66],
      [-266, 173],
      [-363, 260],
      [-213, 248],
      [-285, 152],
      [-350, 267],
      [-442, 224],
      [-443, 130],
      [-681, 167],
    ],
    [
      [22668, 50167],
      [210, 8],
      [170, -5],
      [164, 9],
      [181, -133],
      [77, -135],
      [76, -168],
      [20, -109],
    ],
    [
      [30710, 137],
      [-496, -137],
      [-1411, 304],
      [-567, 331],
      [-188, 160],
      [-301, 389],
      [-402, 456],
      [-225, 451],
      [-1299, 300],
      [-805, 76],
      [-670, -9],
      [-532, 91],
      [-503, 506],
      [-124, 434],
      [-42, 143],
      [-116, 400],
      [261, 370],
      [306, 173],
      [791, 49],
      [573, 230],
      [340, 520],
      [97, 812],
      [-242, 1066],
      [-150, 870],
      [-176, 911],
      [36, 694],
      [202, 676],
      [236, 942],
      [241, 533],
      [235, 615],
      [-57, 579],
      [-11, 714],
      [-111, 530],
      [-469, 792],
      [-349, 814],
      [-442, 853],
      [-318, 416],
      [-112, 145],
      [-609, 405],
    ],
    [
      [23301, 17741],
      [5, 8],
      [253, 166],
      [174, 172],
      [320, 337],
      [233, 131],
      [173, 131],
      [213, 220],
      [281, 221],
      [213, 267],
      [153, 124],
      [214, 206],
      [300, 166],
      [379, 242],
      [413, 195],
      [233, -11],
      [218, -182],
      [119, -257],
      [32, -231],
      [32, -272],
      [-7, -95],
      [46, -299],
      [98, -462],
      [52, -299],
      [-1, -299],
      [-8, -252],
      [7, -129],
      [-1, -163],
      [-7, -239],
      [-14, -156],
      [-8, -225],
      [72, -224],
      [53, -87],
      [60, -116],
      [185, -188],
      [325, -235],
      [345, -167],
      [99, -148],
      [92, -272],
      [66, -285],
      [24, -551],
      [-1, -435],
      [125, -203],
      [219, -236],
      [298, -235],
      [106, -26],
      [146, -12],
      [173, -39],
      [152, -230],
      [39, -251],
      [238, -284],
      [126, -128],
      [76, -81],
    ],
    [
      [30434, 12323],
      [-28, -242],
      [72, -1844],
      [114, -1396],
      [212, -934],
      [524, -765],
      [639, -595],
      [146, -152],
      [510, -530],
      [362, -782],
      [37, -942],
      [-231, -947],
      [-396, -868],
      [-544, -849],
      [-324, -419],
      [-353, -657],
      [-338, -229],
      [-126, -35],
    ],
    [
      [25939, 41895],
      [-178, -5],
      [-271, -147],
    ],
    [
      [25490, 41743],
      [-22, 2],
      [-473, -64],
      [-207, 12],
      [-181, 122],
      [16, 245],
      [-144, 0],
      [-35, 436],
      [18, 41],
    ],
    [
      [24462, 42537],
      [114, 246],
      [162, 137],
      [121, 206],
      [272, 172],
      [3, 5],
    ],
    [
      [25134, 43303],
      [321, -71],
      [150, -8],
    ],
    [
      [25605, 43224],
      [108, -148],
      [230, -162],
      [11, -259],
      [78, -115],
      [18, -287],
      [-101, -96],
      [8, -27],
      [-33, -123],
      [15, -112],
    ],
    [
      [32256, 51444],
      [255, -128],
      [305, 9],
      [398, 16],
      [426, -93],
      [481, -118],
      [495, -51],
      [457, -24],
      [396, -124],
      [126, -39],
      [349, -99],
      [201, -128],
      [231, -250],
      [170, -496],
      [144, -741],
      [67, -141],
      [-40, -2626],
      [-158, -210],
      [-396, -439],
      [-151, -424],
      [-99, -382],
      [168, -183],
      [349, -99],
      [260, -59],
      [-1, -55],
      [-2, -427],
    ],
    [
      [33288, 41775],
      [-14, 4],
      [-99, 37],
      [-39, 9],
      [-109, 12],
      [-115, 20],
      [-114, 46],
    ],
    [
      [32798, 41903],
      [-91, 122],
      [-85, 122],
      [-97, 257],
      [-54, 252],
      [23, 218],
      [67, 96],
      [204, 63],
      [369, -187],
      [-87, 196],
      [-168, 291],
      [-163, 632],
      [-171, 461],
      [-241, 406],
      [-164, 19],
      [-194, 32],
      [-212, 400],
      [-252, 299],
      [-206, 306],
      [25, 500],
      [-70, 310],
      [-226, 218],
      [-626, 169],
      [-245, -66],
      [-179, -43],
      [-220, 40],
      [-160, 67],
      [-57, 109],
    ],
    [
      [29518, 47192],
      [-62, 388],
      [25, 144],
      [-28, 402],
      [98, 307],
      [-61, 689],
      [-91, 476],
      [49, 328],
      [91, 683],
    ],
    [
      [38110, 29726],
      [166, -1515],
      [-901, 53],
      [-110, 7],
    ],
    [
      [37265, 28271],
      [-56, 155],
      [-26, 109],
      [-80, 115],
      [-109, 170],
      [-43, 66],
      [-132, 163],
      [-139, 141],
      [-106, 142],
      [-112, 114],
      [-200, 128],
      [-185, 168],
      [-153, 100],
      [-152, 87],
      [-120, -7],
      [-159, -29],
      [-226, 4],
      [-233, -63],
      [-173, -70],
      [-180, -104],
      [-213, -77],
      [-219, -63],
      [-160, -118],
      [-201, -246],
      [-120, -233],
      [-127, -253],
      [91, -326],
      [-33, -157],
      [-41, -197],
      [6, -164],
      [-127, -144],
      [-100, -69],
      [-133, -76],
      [-146, -124],
      [-74, -211],
      [-134, -260],
      [-120, -212],
      [-101, -267],
      [-102, -511],
      [-1, -231],
      [-107, -138],
      [-166, -123],
      [-154, -206],
      [-86, -69],
      [-220, 45],
      [-173, -49],
      [-139, -49],
      [-87, -42],
      [-80, -191],
      [-147, -131],
      [-87, -89],
      [-14, -12],
    ],
    [
      [31166, 24667],
      [-85, 140],
      [-86, 163],
      [-72, 305],
      [-152, 284],
      [-211, 270],
      [-179, 332],
      [-91, 312],
      [-79, 197],
      [-87, 53],
      [-7, 172],
    ],
    [
      [30117, 26895],
      [-11, 243],
      [20, 150],
      [14, 95],
      [0, 7],
      [74, 130],
      [40, 266],
      [1, 245],
      [1, 292],
      [28, 415],
      [-12, 273],
      [-25, 346],
      [-25, 429],
      [-25, 537],
      [-17, 653],
      [-2, 428],
      [24, 210],
    ],
    [
      [30271, 33701],
      [3, 5],
      [54, 96],
      [116, 116],
      [133, 90],
      [100, 49],
      [123, -94],
      [100, -81],
      [86, -115],
      [57, -74],
      [70, -54],
      [98, 8],
      [87, 21],
      [50, 7],
      [108, -33],
      [102, -149],
      [93, -80],
      [106, -101],
      [79, -47],
      [119, -128],
      [113, -156],
      [53, -33],
      [27, 0],
      [86, -47],
      [119, 8],
      [127, 36],
      [193, 22],
      [72, -13],
      [80, -33],
      [73, -88],
      [73, -81],
      [53, -74],
      [66, -108],
      [59, -109],
      [53, -88],
      [40, -33],
      [79, -108],
      [47, 82],
      [34, 116],
      [0, 122],
      [34, 150],
      [40, 110],
      [101, 164],
      [20, 14],
      [79, 14],
      [73, -33],
      [66, -176],
      [66, -129],
      [26, -163],
      [13, -88],
      [26, -129],
      [53, -75],
      [79, -155],
      [33, -109],
      [-7, -41],
      [39, -217],
      [27, -143],
      [-1, -136],
      [26, -176],
      [39, -129],
      [27, -68],
      [59, -13],
      [133, -40],
      [153, -5],
      [87, 55],
      [120, 104],
      [186, 131],
      [167, 42],
      [192, 23],
    ],
    [
      [22563, 30083],
      [-72, 24],
      [-75, -21],
      [-57, -41],
      [-110, -124],
      [-116, -70],
      [-218, -24],
      [-164, 9],
      [-178, 91],
      [-131, 18],
      [-150, 30],
      [-182, 17],
      [-131, 17],
      [-122, -36],
      [-104, -43],
      [-24, 5],
    ],
    [
      [17307, 34166],
      [70, 6],
      [144, 42],
      [61, 55],
      [75, 35],
      [130, 14],
      [0, 7],
      [55, 14],
      [144, 35],
      [178, 69],
      [129, 56],
      [109, 48],
      [185, 42],
      [192, 63],
      [162, 151],
      [150, 83],
      [156, 151],
      [74, 75],
      [162, 165],
      [6, 38],
    ],
    [
      [19489, 35315],
      [62, 3],
      [234, -39],
      [83, -13],
      [125, -27],
      [83, -20],
      [83, -40],
      [69, -34],
      [71, -122],
      [29, -48],
      [-31, -184],
      [1, -55],
      [38, -218],
      [70, -61],
      [138, -53],
      [84, -54],
      [143, 62],
      [143, 56],
      [206, 21],
      [132, -47],
      [105, -74],
      [49, -88],
      [84, -89],
      [70, -60],
      [112, -75],
      [57, -102],
      [23, -39],
    ],
    [
      [43852, 39017],
      [-70, -727],
      [-736, -1128],
      [-845, -1071],
      [-233, -982],
      [92, -917],
      [51, -60],
      [1087, 27],
    ],
    [
      [36145, 33261],
      [-42, 60],
      [-52, 81],
      [-80, 47],
      [-73, 26],
      [-87, 34],
      [-125, 250],
      [-112, 148],
      [-153, 196],
      [-92, 156],
      [-66, 135],
      [-106, 183],
      [-52, 115],
      [-73, 47],
      [-106, 176],
      [-99, 155],
      [-112, 135],
      [-27, 54],
      [-106, 149],
      [-86, 54],
      [-146, 127],
      [-145, 257],
      [-106, 142],
      [-106, 156],
      [-165, 229],
      [21, 171],
      [46, 82],
      [7, 0],
      [81, 198],
      [80, 89],
      [100, 124],
      [107, 137],
      [67, 184],
      [100, 165],
      [133, 123],
      [187, 193],
      [167, 103],
      [147, 117],
      [73, 144],
      [107, 205],
      [87, 130],
      [54, 69],
      [93, 82],
      [107, 97],
      [27, 150],
      [-13, 67],
      [6, 1],
      [54, 55],
      [-20, 81],
      [-46, 95],
      [-47, 54],
      [-92, 46],
      [-73, 47],
      [-49, 50],
    ],
    [
      [32798, 41903],
      [-207, 19],
      [-399, -31],
      [-266, -71],
      [-289, -159],
      [-259, -152],
      [-156, -192],
      [-232, -105],
      [-455, 71],
      [-335, 242],
      [-103, 251],
      [-428, 301],
      [-304, 129],
      [-455, 17],
      [-495, -152],
      [-513, -193],
      [-244, -167],
      [-83, -165],
      [-392, -103],
      [-386, 107],
      [-365, 119],
      [-249, 108],
      [-133, 122],
      [-111, -4],
    ],
    [
      [25605, 43224],
      [77, -4],
      [144, 41],
      [334, 159],
      [246, 104],
      [190, 131],
      [363, 118],
      [266, 124],
      [277, 268],
      [-46, 279],
      [57, 233],
      [-25, 245],
      [25, 176],
    ],
    [
      [27513, 45098],
      [67, 77],
      [24, 225],
      [140, 226],
      [223, 206],
      [524, 324],
      [291, 268],
      [203, 205],
      [196, 172],
      [189, 192],
      [148, 199],
    ],
    [
      [21754, 41318],
      [-32, 294],
      [-5, 76],
    ],
    [
      [21717, 41688],
      [-11, 190],
      [-50, 203],
      [-12, 65],
    ],
    [
      [23724, 42560],
      [93, -7],
      [204, -9],
    ],
    [
      [24021, 42544],
      [-50, -154],
      [-79, -198],
      [-33, -116],
      [-83, -203],
    ],
    [
      [23776, 41873],
      [-9, -23],
      [-396, -166],
      [-514, -119],
      [-630, -147],
      [-336, -64],
      [-137, -36],
    ],
    [
      [9839, 40891],
      [99, 16],
      [164, -59],
      [205, -4],
      [138, -87],
      [69, -74],
      [171, 16],
      [171, -52],
      [226, -11],
      [295, -78],
      [185, -18],
      [68, -20],
      [178, -38],
      [130, 15],
      [137, -66],
      [35, -48],
      [76, -87],
      [43, -122],
      [-26, -95],
      [-74, -76],
      [-47, -75],
      [-40, -62],
      [-53, -68],
      [-88, -76],
      [-164, -43],
      [-177, -23],
      [-102, -48],
      [-54, -42],
      [-10, -56],
    ],
    [
      [11394, 39510],
      [-65, 62],
      [-27, 6],
      [-48, -21],
      [-101, -109],
      [-33, -103],
      [-20, -48],
      [-73, -75],
      [-67, -96],
      [21, -75],
      [-67, -55],
      [-114, -157],
      [1, -89],
      [-95, -69],
      [-136, -42],
      [-367, -93],
      [-232, -84],
      [-114, -104],
      [-102, -62],
      [-197, -36],
      [-259, -58],
      [-169, -90],
      [-34, -55],
      [-148, -151],
      [-88, -97],
      [-101, -89],
      [-190, -70],
      [-245, -65],
      [-260, 11],
      [-176, -70],
      [-528, -367],
      [-67, -109],
      [-141, -158],
      [-236, -207],
      [-86, -178],
      [-251, -152],
      [-280, -4],
      [-178, 5],
      [-205, 65],
      [-220, 72],
      [-131, 94],
      [-194, 174],
      [-317, 193],
      [-96, 15],
    ],
    [
      [4958, 36969],
      [56, 389],
      [-18, 333],
      [88, 532],
      [-219, 514],
      [-36, 84],
      [-203, 477],
      [-130, 454],
      [-218, 480],
      [-180, 141],
      [-11, 292],
      [91, 294],
      [164, 532],
      [71, 286],
      [-45, 421],
      [-16, 238],
      [20, 70],
    ],
    [
      [4372, 42506],
      [94, 19],
      [219, -79],
      [423, -49],
      [83, -81],
      [111, -100],
      [207, -195],
      [154, -249],
      [76, -94],
      [215, -161],
      [151, -59],
      [96, -73],
      [86, -251],
      [42, -108],
      [154, -222],
      [81, 28],
      [211, 84],
      [279, 78],
      [333, 120],
      [380, 133],
      [245, 85],
      [272, 112],
      [286, 51],
      [198, -18],
      [97, -60],
      [21, -20],
      [185, -73],
      [147, -235],
      [76, -68],
      [89, -53],
      [235, -133],
      [135, 43],
      [86, 13],
    ],
    [
      [17665, 37835],
      [8, -3],
      [-89, -124],
      [-5, -7],
      [24, -6],
      [22, -6],
      [-179, -193],
      [-358, -290],
      [-172, -179],
      [-92, -257],
      [-135, -58],
      [-186, -193],
      [-131, -49],
      [-46, -179],
      [-21, -11],
    ],
    [
      [16305, 36280],
      [-47, 32],
      [-91, 40],
      [-91, 33],
      [-6, -1],
      [-85, 26],
      [-85, -14],
      [-72, -28],
      [-308, 23],
      [-151, -50],
      [-138, -63],
      [-131, -29],
      [-92, -8],
      [-13, -7],
      [-20, -21],
      [-40, -55],
      [-47, -48],
      [-144, -56],
      [-119, -22],
      [-116, 73],
      [-84, 87],
      [-109, 141],
      [-71, 94],
      [7, 55],
      [-12, 67],
      [-38, 95],
      [-19, 54],
      [-170, 11],
      [-143, 19],
      [-144, 25],
      [-137, 45],
      [-17, 7],
    ],
    [
      [13572, 36805],
      [-87, 33],
      [-52, -1],
      [-79, 12],
      [-130, 73],
      [-58, 61],
      [-103, 73],
      [-52, 74],
      [-103, 127],
      [-51, 95],
      [-37, 148],
      [80, 110],
      [1, 34],
      [-6, 41],
      [-31, 162],
      [-39, 34],
      [-91, 46],
      [-129, 113],
      [-137, 6],
      [-85, 46],
      [-117, 59],
      [-130, 46],
      [-182, 72],
      [-136, 93],
      [-123, 135],
      [-92, 46],
      [-111, 127],
      [-99, 223],
      [-50, 170],
      [-43, 149],
      [0, 6],
      [39, 144],
      [40, 68],
      [15, 80],
    ],
    [
      [9839, 40891],
      [64, 77],
      [153, 246],
      [210, 166],
      [189, 138],
      [211, 77],
      [382, -2],
      [521, -109],
      [315, -57],
      [281, -65],
      [129, 22],
      [150, 16],
      [170, 70],
      [102, 62],
      [129, 70],
      [162, 124],
      [-8, 144],
    ],
    [
      [13059, 41859],
      [1, -31],
      [14, -47],
      [27, -13],
      [70, -81],
      [164, -25],
      [110, -33],
      [130, -12],
      [176, -119],
      [270, -363],
      [109, -135],
      [166, -309],
      [13, -41],
      [45, -60],
      [102, -216],
      [128, -155],
      [247, -132],
      [111, -19],
      [52, -40],
      [236, 78],
      [279, 262],
      [119, 130],
      [139, 90],
      [255, 38],
      [339, -125],
      [142, -160],
      [108, -236],
      [117, -569],
      [80, -338],
      [-63, -293],
      [-118, -504],
      [-54, -150],
      [292, -118],
      [164, -25],
      [104, -53],
      [150, -25],
      [201, -126],
      [181, -69],
    ],
    [
      [23776, 41873],
      [4, -2],
      [176, -197],
      [2, -498],
      [-141, -453],
    ],
    [
      [23817, 40723],
      [-16, -4],
      [-343, -57],
      [-383, -84],
      [-199, -43],
      [-254, -22],
      [-250, 94],
      [-195, 135],
      [-120, 163],
      [-86, 239],
      [-208, 94],
      [-9, 80],
    ],
    [
      [13572, 36805],
      [-75, -28],
      [-191, -77],
      [-66, -49],
      [-131, -49],
      [-106, -63],
      [-112, -76],
      [-139, -83],
      [-251, -167],
      [-336, -194],
      [-118, -56],
      [-139, -118],
      [-192, -172],
      [-244, -105],
      [-112, -56],
      [-241, 38],
      [-73, -29],
      [-125, -56],
      [-94, -218],
      [-50, -272],
      [-63, -347],
      [0, -421],
      [-48, -164],
      [-76, -286],
      [11, -163],
      [-3, -264],
      [-47, -82],
      [-94, -158],
      [-212, -213],
      [-152, -91],
      [-230, -77],
      [-229, 17],
      [-130, 80],
      [-135, 100],
      [-221, 187],
      [-65, 6],
      [-72, -49],
      [-67, -82],
      [-178, -98],
      [-23, -251],
      [-41, -130],
      [-67, -123],
      [-41, -102],
      [-40, -96],
      [-113, -90],
      [-152, -42],
    ],
    [
      [8289, 32036],
      [-22, 183],
      [-52, 53],
      [-46, 6],
      [-59, -28],
      [-112, -28],
      [-125, -29],
      [-223, -57],
      [-124, -43],
      [-95, -35],
      [-212, 25],
      [-191, 11],
      [-315, 37],
      [-165, 72],
      [-97, 94],
      [-21, 27],
      [-70, 101],
      [-70, 122],
      [-63, 84],
    ],
    [
      [6227, 32631],
      [20, 31],
      [-50, 135],
      [-21, 41],
      [-17, 258],
      [-100, 271],
      [-49, 101],
      [-43, 176],
      [-133, 237],
      [-237, 344],
      [-126, 250],
      [-145, 569],
      [-131, 549],
      [-291, 337],
      [-135, 392],
      [152, 389],
      [37, 258],
    ],
    [
      [30117, 26895],
      [-86, -254],
      [-127, -151],
      [-160, -111],
      [-113, -83],
      [-127, -178],
      [-80, -82],
      [-107, -198],
      [-33, -14],
      [-187, -145],
      [-200, -227],
      [-240, -159],
      [-259, -91],
      [-320, -187],
      [-180, -151],
      [-200, -158],
      [-260, -153],
      [-113, -130],
      [-227, -132],
      [-167, -90],
      [-266, -145],
      [-140, -124],
      [-207, -152],
      [-166, -110],
      [-186, -77],
      [-94, -137],
      [-184, -165],
    ],
    [
      [25688, 23291],
      [4, 41],
      [14, 197],
      [8, 150],
      [7, 95],
      [-6, 102],
      [20, 157],
      [-44, 81],
      [-48, 143],
      [-74, 94],
      [-101, 67],
      [-82, 54],
      [-71, 54],
      [-61, 122],
      [10, 81],
      [68, 124],
      [98, 123],
      [155, 158],
      [124, 158],
      [100, 150],
      [-14, 320],
      [-61, 347],
      [-72, 142],
      [-52, 54],
      [-100, 203],
      [-44, 197],
      [-77, 176],
      [-49, 109],
      [26, 272],
      [-5, 136],
      [30, 218],
      [48, 226],
      [63, 157],
      [53, 191],
      [63, 150],
      [196, 131],
      [157, 70],
      [262, 111],
      [215, 138],
      [247, 281],
      [152, 227],
      [59, 184],
      [8, 149],
      [0, 225],
      [-20, 333],
      [-4, 150],
      [-14, 204],
      [15, 157],
      [91, 123],
      [127, 83],
      [134, 110],
      [79, 110],
      [105, 83],
    ],
    [
      [16305, 36280],
      [16, -11],
      [15, -10],
      [-24, -25],
      [-16, -16],
      [-6, -69],
      [-1, -20],
      [18, -108],
      [12, -102],
      [24, -121],
      [70, -162],
      [25, -102],
      [37, -149],
      [11, -122],
      [12, -95],
      [57, -135],
      [25, -94],
      [12, -75],
      [38, -121],
      [-7, -7],
      [-14, -48],
      [11, -176],
      [-35, -130],
      [-17, -68],
    ],
    [
      [18032, 22849],
      [51, -32],
      [6, 0],
      [-28, -75],
      [-79, -102],
      [-38, -28],
      [-57, -35],
      [-70, -61],
      [-119, -56],
      [-202, -51],
      [-219, -65],
      [-450, -64],
      [-209, 23],
      [-167, 30],
      [-129, 153],
      [-389, 32],
      [-208, -31],
      [-538, -52],
      [-175, -41],
    ],
    [
      [15012, 22394],
      [-234, 63],
      [-908, 398],
      [-751, 459],
      [-492, 557],
      [-238, 633],
      [-10, 573],
      [300, 535],
      [379, 705],
      [312, 650],
      [-148, 586],
      [-534, 383],
      [-575, 88],
      [-564, -96],
      [-722, -220],
      [-632, -267],
      [-219, 1],
    ],
    [
      [9976, 27442],
      [-13, 89],
      [68, 218],
      [28, 136],
      [-20, 428],
      [-62, 270],
      [-57, 156],
      [-189, 52],
      [-78, 33],
      [-188, 92],
      [-85, 39],
      [-155, 114],
      [-174, 221],
      [-148, 195],
      [-51, 101],
      [-50, 122],
      [-24, 203],
      [-95, 202],
      [-16, 265],
      [-50, 189],
      [-83, 148],
      [-78, 40],
      [-144, 32],
      [-64, 53],
      [-95, 223],
      [1, 88],
      [2, 136],
      [28, 184],
      [15, 143],
      [35, 177],
      [56, 238],
      [-1, 7],
    ],
    [
      [20904, 41734],
      [44, -23],
      [181, 0],
      [203, -68],
      [136, 45],
      [135, 0],
      [114, 0],
    ],
    [
      [23817, 40723],
      [368, 87],
      [94, 137],
      [240, 8],
      [262, -19],
      [355, 132],
      [231, 165],
      [198, 69],
      [70, 26],
    ],
    [
      [25635, 41328],
      [173, -120],
      [264, -182],
      [286, -201],
      [197, -300],
      [176, -119],
    ],
    [
      [24728, 38734],
      [-95, 87],
      [-152, 61],
      [-173, 67],
      [-172, -1],
      [-195, 121],
      [-221, 40],
      [-130, -42],
      [-158, -8],
      [-270, 87],
      [-102, -55],
      [-165, -15],
      [-151, -21],
      [-220, -8],
      [-254, 19],
      [-152, 5],
      [-214, 33],
      [-111, 47],
      [-104, 40],
      [-131, 54],
      [-188, 115],
      [-35, 27],
      [-56, 54],
      [-49, 41],
      [-146, 94],
      [-155, 170],
      [-139, 101],
      [-55, 27],
      [-57, 75],
      [-14, 27],
      [-107, 203],
      [20, 14],
      [21, 136],
      [-38, 40],
      [-92, 12],
      [-124, 39],
      [-6, 1],
      [-62, 4],
      [-4, -5],
      [-4, -2],
    ],
    [
      [20268, 40418],
      [-60, 96],
      [47, 48],
      [74, 184],
      [60, 48],
      [87, 144],
      [141, 246],
      [86, 83],
      [74, 191],
      [94, 178],
      [33, 98],
    ],
    [
      [17349, 59670],
      [21, -51],
      [161, -284],
      [334, -458],
      [217, -378],
      [295, -609],
      [231, -446],
      [252, -425],
      [141, -284],
      [16, -169],
      [29, -177],
      [-58, -252],
      [59, -319],
      [188, -202],
      [242, -221],
      [76, -101],
      [116, 94],
    ],
    [
      [19669, 55388],
      [51, -379],
      [-20, -48],
      [120, -257],
      [99, -257],
      [147, -256],
      [160, -209],
      [228, -140],
      [311, -261],
      [283, -356],
      [108, -291],
      [-17, -60],
    ],
    [
      [11263, 53174],
      [-102, 222],
      [-10, 306],
      [-98, 107],
    ],
    [
      [11053, 53809],
      [314, 145],
      [378, 167],
      [96, 42],
      [392, 215],
      [425, 277],
      [147, 185],
      [223, 214],
      [103, 524],
      [103, 505],
      [138, 498],
      [185, 526],
      [200, 322],
      [207, 390],
      [112, 287],
      [140, 267],
      [117, 525],
      [234, 363],
      [290, 173],
      [312, 71],
      [503, 26],
      [393, 66],
      [505, 60],
      [417, -9],
      [238, 23],
      [124, -1],
    ],
    [
      [19489, 35315],
      [12, 85],
      [52, 198],
      [59, 130],
      [84, 321],
      [126, 280],
      [80, 144],
      [107, 137],
      [197, 172],
      [134, 157],
      [188, 301],
      [127, 172],
      [230, 199],
      [306, 200],
      [274, 70],
      [193, 15],
      [233, 21],
      [97, -19],
      [206, -13],
      [164, 49],
      [384, 71],
      [137, 41],
      [206, 55],
      [7, 2],
      [96, 13],
      [164, 15],
      [64, -6],
    ],
    [
      [17665, 37835],
      [8, 4],
      [52, 21],
      [99, 48],
      [67, 103],
      [79, 35],
      [119, 76],
      [138, 50],
      [31, 8],
      [82, 129],
      [100, 151],
      [187, 247],
      [145, 137],
      [167, 227],
      [106, 62],
      [172, 186],
      [199, 172],
      [165, 91],
      [178, 118],
      [152, 124],
      [179, 186],
      [47, 41],
      [72, 42],
      [67, 55],
      [33, 95],
      [-17, 136],
      [-24, 39],
    ],
    [
      [25863, 49699],
      [-178, -56],
      [-205, -145],
      [-256, -11],
      [-64, 122],
      [-3, 210],
      [-43, 176],
      [-4, 271],
      [-83, 101],
      [-25, 122],
      [-63, 121],
      [-157, 5],
      [-126, -111],
      [-185, -104],
      [-93, -150],
      [-54, -110],
      [-40, -75],
      [-3, -170],
      [-209, 18],
      [-210, -17],
      [-191, -145],
      [-102, -111],
      [-3, -6],
    ],
    [
      [19669, 55388],
      [53, 44],
      [87, 171],
      [73, 150],
      [80, 144],
      [45, 163],
      [187, 349],
      [52, 123],
      [93, 246],
      [92, 205],
      [25, 205],
      [18, 170],
      [25, 150],
      [73, 184],
      [39, 170],
      [11, 143],
      [100, 192],
      [263, 254],
      [243, 173],
      [312, 208],
      [581, 278],
      [16, 8],
      [318, 180],
      [380, 188],
      [380, 161],
      [204, 84],
      [266, 37],
      [328, 17],
      [443, 67],
      [30, 3],
    ],
    [
      [27513, 45098],
      [-41, -47],
      [-96, -14],
      [-83, 13],
      [-241, 40],
      [-255, 18],
      [-343, -36],
      [-316, -43],
      [-596, -153],
      [-253, -63],
      [-374, 120],
      [-105, 122],
      [-139, 102],
      [-197, 258],
      [-62, 367],
      [-400, 200],
      [-246, 173],
      [-391, 165],
      [-61, 19],
    ],
    [
      [24462, 42537],
      [-239, -2],
      [-202, 9],
    ],
    [
      [23300, 43743],
      [158, 1],
      [132, -88],
      [153, -53],
      [78, -170],
      [105, -81],
      [270, -121],
      [247, 49],
      [397, 77],
      [256, -46],
      [38, -8],
    ],
    [
      [25688, 23291],
      [-169, -151],
      [-339, -31],
      [-192, -49],
      [-260, -57],
      [-268, 4],
      [-308, 65],
      [-209, 100],
      [-258, 86],
      [-213, 148],
      [-175, 148],
      [-160, 107],
      [-183, 121],
      [-171, 121],
      [-176, 25],
      [-113, 40],
      [-110, 20],
      [-103, 115],
      [-18, 6],
      [-2, -27],
      [-48, 20],
      [-102, 19],
      [-51, 75],
      [-89, 53],
      [-89, 61],
      [-36, 13],
      [-187, 53],
      [-119, 53],
      [-17, 20],
      [-103, 142],
      [-180, 67],
      [-58, 162],
      [-94, 204],
      [-74, 94],
      [-152, 135],
      [-22, 47],
      [-107, 285],
      [-3, 5],
    ],
    [
      [15399, 43132],
      [356, -66],
      [249, 23],
      [359, -69],
      [219, -208],
      [492, -421],
      [192, -329],
      [264, -302],
      [347, -464],
      [293, -552],
      [270, -397],
      [731, -92],
      [648, 2],
      [449, 161],
    ],
    [
      [9976, 27442],
      [-560, 3],
      [-984, -541],
      [-143, -79],
      [-429, -291],
      [-706, -423],
      [-417, -373],
      [-563, -544],
      [-656, -586],
      [-331, -296],
      [-435, -189],
      [-26, -5],
      [-689, -120],
      [-752, -24],
      [-700, 33],
      [-109, 126],
      [-129, 121],
      [-166, 161],
      [-173, 107],
      [-185, 100],
      [-263, 241],
      [-193, 127],
      [-138, 53],
      [-95, 6],
      [-242, 214],
      [-112, 156],
      [-18, 17],
      [-175, 164],
      [-9, 102],
      [-71, 203],
      [-79, 312],
      [-63, 108],
      [-45, 312],
      [-89, 502],
      [-30, 156],
      [-84, 196],
      [-4, 258],
      [-113, 271],
      [26, 95],
      [147, 240],
      [107, 164],
      [254, 411],
      [179, 342],
      [72, 259],
      [30, 307],
      [-160, 243],
      [92, 232],
      [157, 49],
      [195, 193],
      [190, 111],
      [86, 144],
      [94, 137],
      [135, 131],
      [231, 97],
      [190, 98],
      [299, 92],
      [157, 35],
      [408, 87],
      [178, -25],
      [306, 99],
      [365, 276],
      [257, 180],
      [251, 152],
      [277, 187],
      [185, -66],
      [382, 52],
      [484, 81],
      [226, -31],
      [252, 50],
      [121, 104],
      [54, 85],
    ],
    [
      [4372, 42506],
      [124, 442],
      [10, 388],
      [-84, 217],
      [-42, 142],
      [50, 402],
      [-19, 537],
      [9, 415],
      [18, 306],
      [13, 633],
      [-25, 469],
      [-60, 510],
      [-7, 700],
      [-138, 876],
      [-47, 564],
      [35, 586],
      [55, 471],
      [1, 12],
      [38, 287],
      [87, 170],
      [363, 365],
      [253, 207],
    ],
    [
      [5006, 51205],
      [105, -215],
      [57, -245],
      [192, -140],
      [27, -20],
      [628, -897],
      [119, -346],
      [200, -215],
      [178, -236],
      [669, -257],
      [249, -406],
      [248, -282],
      [389, -152],
      [528, -252],
      [340, -390],
      [42, -47],
      [73, -288],
    ],
    [
      [37265, 28271],
      [3, -7],
      [0, -41],
      [-1, -82],
      [106, -96],
      [-3, -353],
      [-64, 35],
      [-60, -1],
      [-73, -35],
      [-67, -82],
      [-86, -69],
      [-9, -11],
      [-78, -119],
      [-41, -191],
      [-67, -69],
      [-66, -48],
      [-1, -163],
      [-14, -130],
      [126, -182],
      [39, -122],
      [34, -47],
      [85, -131],
      [20, -32],
      [99, -155],
      [106, -54],
      [80, 17],
      [3, 4],
      [20, 54],
      [44, 121],
      [101, 221],
      [129, 182],
      [65, 87],
      [116, 76],
      [122, 75],
      [80, 14],
      [72, 8],
      [80, -79],
      [-7, -120],
      [-21, -173],
      [1, -193],
      [15, -166],
      [-7, -200],
      [-28, -147],
      [52, -226],
      [43, -86],
      [217, -83],
      [305, -188],
      [289, -96],
      [167, -24],
      [173, 37],
      [116, -112],
      [22, -66],
      [131, -138],
      [175, -264],
      [1, -273],
      [116, -204],
      [87, -212],
      [168, -190],
      [145, -92],
      [87, -72],
      [51, -152],
      [87, -105],
      [1, -326],
      [32, -53],
    ],
    [
      [40583, 22942],
      [-249, -44],
      [-1168, -737],
      [-316, -518],
      [-628, -516],
      [-526, -515],
      [-267, -179],
      [-236, -158],
      [-82, -220],
      [0, -11],
      [-492, -358],
      [-636, -810],
      [-613, -1122],
      [-807, -1010],
      [-484, -502],
      [-958, -239],
      [-1274, 74],
      [-254, -107],
    ],
    [
      [31593, 15970],
      [-25, 120],
      [-99, 190],
      [-100, 141],
      [-71, 354],
      [-86, 237],
      [-198, 202],
      [-159, 270],
      [-112, 299],
      [-72, 162],
      [-133, 115],
      [-198, 256],
      [-266, 161],
      [-178, 161],
      [-13, 211],
      [-278, 174],
      [-133, 121],
      [-152, 135],
      [-172, 236],
      [-132, 387],
      [41, 327],
      [28, 258],
      [120, 213],
      [54, 170],
      [101, 219],
      [180, 179],
      [120, 157],
      [246, 139],
      [213, 138],
      [240, 213],
      [114, 172],
      [127, 246],
      [94, 205],
      [61, 293],
      [80, 239],
      [88, 191],
      [134, 410],
      [68, 341],
      [54, 347],
      [21, 252],
      [-34, 56],
    ],
    [
      [47414, 30280],
      [792, -197],
      [416, -543],
      [-639, -650],
      [-451, -459],
      [-924, -744],
      [-984, -898],
      [-351, -477],
      [-305, -937],
      [-206, -808],
      [142, -777],
      [-105, -276],
      [-68, -13],
      [-989, -361],
      [-36, -7],
      [-513, -287],
      [-990, -354],
      [-536, 46],
      [-537, 498],
      [-547, -94],
    ],
    [
      [25490, 41743],
      [-8, -5],
      [-45, -184],
      [77, -75],
      [99, -135],
      [22, -16],
    ],
    [
      [21015, 42193],
      [-12, -53],
      [-22, -129],
      [-34, -116],
      [-7, -55],
      [-36, -106],
    ],
    [
      [5006, 51205],
      [50, 41],
      [114, 117],
      [94, 96],
      [68, 69],
      [148, 70],
      [291, 187],
      [439, 229],
      [257, 146],
      [371, 174],
      [182, 144],
      [311, 160],
      [264, 105],
      [386, 133],
      [502, 122],
      [630, 176],
      [575, 204],
      [711, 219],
      [529, 155],
      [125, 57],
    ],
    [
      [31593, 15970],
      [-225, -94],
      [-402, -780],
      [-351, -1365],
      [-181, -1408],
    ],
    [
      [23301, 17741],
      [-7, 5],
      [-682, 735],
      [-688, 532],
      [-546, 404],
      [-1153, 276],
      [-504, 50],
      [-804, -62],
      [-720, 259],
      [-514, 431],
      [-431, 766],
      [-711, 741],
      [-800, 335],
      [-729, 181],
    ],
    [
      [33339, 39455],
      [-10, -133],
      [59, -285],
      [-9, -273],
      [-234, -403],
      [-160, -138],
      [-189, -104],
      [-134, -110],
      [-87, -266],
      [-73, -457],
      [-124, -389],
      [-47, -111],
    ],
  ],
  transform: {
    scale: [0.00003431183868740975, 0.00003393952586433137],
    translate: [7.687559433473481, 10.5444334990075],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Ajingi",
            id: "KN001",
            nameAbbr: "Ajingi",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.17113723995,
            Shape_Area: 0.05737088814,
          },
        },
        {
          arcs: [[6, 7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Albasu",
            id: "KN002",
            nameAbbr: "Albasu",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.05930363441,
            Shape_Area: 0.03802708347,
          },
        },
        {
          arcs: [[11, 12, 13, 14, 15, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Bagwai",
            id: "KN003",
            nameAbbr: "Bagwai",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.77780723333,
            Shape_Area: 0.03174744098,
          },
        },
        {
          arcs: [[18, 19, 20, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Bebeji",
            id: "KN004",
            nameAbbr: "Bebeji",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03772083911,
            Shape_Area: 0.06263911053,
          },
        },
        {
          arcs: [[23, 24, -17, 25, 26]],
          type: "Polygon",
          properties: {
            name: "Bichi",
            id: "KN005",
            nameAbbr: "Bichi",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.15329779631,
            Shape_Area: 0.05048839222,
          },
        },
        {
          arcs: [[27, 28, 29, 30, 31, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Bunkure",
            id: "KN006",
            nameAbbr: "Bunkure",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.07928943522,
            Shape_Area: 0.03884451855,
          },
        },
        {
          arcs: [[34, 35, 36]],
          type: "Polygon",
          properties: {
            name: "Dala",
            id: "KN007",
            nameAbbr: "Dala",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.21948322986,
            Shape_Area: 0.00206243006,
          },
        },
        {
          arcs: [[37, 38, 39, 40]],
          type: "Polygon",
          properties: {
            name: "Dambatta",
            id: "KN008",
            nameAbbr: "Dambatta",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.23555637465,
            Shape_Area: 0.06624984352,
          },
        },
        {
          arcs: [[-28, 41, 42, 43, 44, 45]],
          type: "Polygon",
          properties: {
            name: "Dawakin Kudu",
            id: "KN009",
            nameAbbr: "Dawakin Kudu",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.92071557913,
            Shape_Area: 0.0290667228,
          },
        },
        {
          arcs: [[46, 47, 48, -18, -25, 49]],
          type: "Polygon",
          properties: {
            name: "Dawakin Tofa",
            id: "KN010",
            nameAbbr: "Dawakin Tofa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.04786999912,
            Shape_Area: 0.0371495199,
          },
        },
        {
          arcs: [[50, 51, 52]],
          type: "Polygon",
          properties: {
            name: "Doguwa",
            id: "KN011",
            nameAbbr: "Doguwa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.82383146034,
            Shape_Area: 0.11907176139,
          },
        },
        {
          arcs: [[53, 54, 55, 56, 57]],
          type: "Polygon",
          properties: {
            name: "Fagge",
            id: "KN012",
            nameAbbr: "Fagge",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.18331266871,
            Shape_Area: 0.00228687427,
          },
        },
        {
          arcs: [[58, -5, 59, 60, 61, -38]],
          type: "Polygon",
          properties: {
            name: "Gabasawa",
            id: "KN013",
            nameAbbr: "Gabasawa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.06484094684,
            Shape_Area: 0.05460987543,
          },
        },
        {
          arcs: [[-8, 62, 63, 64, 65, -30, 66]],
          type: "Polygon",
          properties: {
            name: "Garko",
            id: "KN014",
            nameAbbr: "Garko",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.15819541398,
            Shape_Area: 0.03785832959,
          },
        },
        {
          arcs: [[-33, 67, -23, 68, 69]],
          type: "Polygon",
          properties: {
            name: "Garum Mallam",
            id: "KN015",
            nameAbbr: "Garum Mallam",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.63318395344,
            Shape_Area: 0.01986132682,
          },
        },
        {
          arcs: [[70, -10, 71, -2]],
          type: "Polygon",
          properties: {
            name: "Gaya",
            id: "KN016",
            nameAbbr: "Gaya",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.08403121898,
            Shape_Area: 0.05118424302,
          },
        },
        {
          arcs: [[72, -58, 73, 74, -61]],
          type: "Polygon",
          properties: {
            name: "Gezawa",
            id: "KN017",
            nameAbbr: "Gezawa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.80856706171,
            Shape_Area: 0.03017637887,
          },
        },
        {
          arcs: [[75, 76, -37, 77, 78, 79]],
          type: "Polygon",
          properties: {
            name: "Gwale",
            id: "KN018",
            nameAbbr: "Gwale",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.20860078128,
            Shape_Area: 0.00196848927,
          },
        },
        {
          arcs: [[80, 81, 82, 83]],
          type: "Polygon",
          properties: {
            name: "Gwarzo",
            id: "KN019",
            nameAbbr: "Gwarzo",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.82975277119,
            Shape_Area: 0.03002381926,
          },
        },
        {
          arcs: [[84, 85, 86, -81, 87, -14, 88]],
          type: "Polygon",
          properties: {
            name: "Kabo",
            id: "KN020",
            nameAbbr: "Kabo",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.85681571146,
            Shape_Area: 0.02598031847,
          },
        },
        {
          arcs: [[-80, 89, 90]],
          type: "Polygon",
          properties: {
            name: "Kano Municipal",
            id: "KN021",
            nameAbbr: "Kano Municipal",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.19960332527,
            Shape_Area: 0.00219063899,
          },
        },
        {
          arcs: [[91, 92, 93, -82, -87]],
          type: "Polygon",
          properties: {
            name: "Karaye",
            id: "KN022",
            nameAbbr: "Karaye",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.94926296367,
            Shape_Area: 0.03717833332,
          },
        },
        {
          arcs: [[-66, 94, 95, -31]],
          type: "Polygon",
          properties: {
            name: "Kibiya",
            id: "KN023",
            nameAbbr: "Kibiya",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.77881260552,
            Shape_Area: 0.030860808,
          },
        },
        {
          arcs: [[96, -21, 97, 98, 99, -92, -86]],
          type: "Polygon",
          properties: {
            name: "Kiru",
            id: "KN024",
            nameAbbr: "Kiru",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.62800379624,
            Shape_Area: 0.06861384397,
          },
        },
        {
          arcs: [[100, -76, -91, 101, 102, -44, 103, 104]],
          type: "Polygon",
          properties: {
            name: "Kumbotso",
            id: "KN025",
            nameAbbr: "Kumbotso",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.57785462996,
            Shape_Area: 0.01345717959,
          },
        },
        {
          arcs: [[105, 106, -27, 107, 108]],
          type: "Polygon",
          properties: {
            name: "Kunchi",
            id: "KN026",
            nameAbbr: "Kunchi",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03486081559,
            Shape_Area: 0.05852122574,
          },
        },
        {
          arcs: [[-34, -70, 109, -42]],
          type: "Polygon",
          properties: {
            name: "Kura",
            id: "KN027",
            nameAbbr: "Kura",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.7149275797,
            Shape_Area: 0.01990945874,
          },
        },
        {
          arcs: [[-43, -110, -69, -22, -97, -85, 110, -104]],
          type: "Polygon",
          properties: {
            name: "Madobi",
            id: "KN028",
            nameAbbr: "Madobi",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.81505761475,
            Shape_Area: 0.02213648859,
          },
        },
        {
          arcs: [[111, -50, -24, -107, 112, -40]],
          type: "Polygon",
          properties: {
            name: "Makoda",
            id: "KN029",
            nameAbbr: "Makoda",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.11989002528,
            Shape_Area: 0.03342173879,
          },
        },
        {
          arcs: [[-75, 113, -47, -112, -39, -62]],
          type: "Polygon",
          properties: {
            name: "Minjibir",
            id: "KN030",
            nameAbbr: "Minjibir",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.81745224592,
            Shape_Area: 0.03436410129,
          },
        },
        {
          arcs: [[-56, 114, -78, -36, 115]],
          type: "Polygon",
          properties: {
            name: "Nassarawa",
            id: "KN031",
            nameAbbr: "Nassarawa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.19079263532,
            Shape_Area: 0.00157264276,
          },
        },
        {
          arcs: [[116, -19, -68, -32, -96]],
          type: "Polygon",
          properties: {
            name: "Rano",
            id: "KN032",
            nameAbbr: "Rano",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.98763539378,
            Shape_Area: 0.03902206515,
          },
        },
        {
          arcs: [[117, -111, -89, -13]],
          type: "Polygon",
          properties: {
            name: "Rimin Gado",
            id: "KN033",
            nameAbbr: "Rimin Gado",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.71052188651,
            Shape_Area: 0.01737244392,
          },
        },
        {
          arcs: [[-100, 118, -93]],
          type: "Polygon",
          properties: {
            name: "Rogo",
            id: "KN034",
            nameAbbr: "Rogo",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03918255835,
            Shape_Area: 0.06722933313,
          },
        },
        {
          arcs: [[-88, -84, 119, 120, -15]],
          type: "Polygon",
          properties: {
            name: "Shanono",
            id: "KN035",
            nameAbbr: "Shanono",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.15631578097,
            Shape_Area: 0.05180722857,
          },
        },
        {
          arcs: [[121, 122, 123, -64]],
          type: "Polygon",
          properties: {
            name: "Sumaila",
            id: "KN036",
            nameAbbr: "Sumaila",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.43616438786,
            Shape_Area: 0.09822416184,
          },
        },
        {
          arcs: [[124, -122, -63, -7]],
          type: "Polygon",
          properties: {
            name: "Takai",
            id: "KN037",
            nameAbbr: "Takai",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25142310446,
            Shape_Area: 0.06486974465,
          },
        },
        {
          arcs: [[-115, -55, 125, -102, -90, -79]],
          type: "Polygon",
          properties: {
            name: "Tarauni",
            id: "KN038",
            nameAbbr: "Tarauni",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.22841497081,
            Shape_Area: 0.00195338581,
          },
        },
        {
          arcs: [[126, -105, -118, -12, -49]],
          type: "Polygon",
          properties: {
            name: "Tofa",
            id: "KN039",
            nameAbbr: "Tofa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.60604806401,
            Shape_Area: 0.01684257158,
          },
        },
        {
          arcs: [[-121, 127, -108, -26, -16]],
          type: "Polygon",
          properties: {
            name: "Tsanyawa",
            id: "KN040",
            nameAbbr: "Tsanyawa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.797861834,
            Shape_Area: 0.03415954067,
          },
        },
        {
          arcs: [[128, -52, 129, -98, -20, -117, -95, -65, -124]],
          type: "Polygon",
          properties: {
            name: "Tundun Wada",
            id: "KN041",
            nameAbbr: "Tundun Wada",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.0534275647,
            Shape_Area: 0.09339020186,
          },
        },
        {
          arcs: [[-74, -57, -116, -35, -77, -101, -127, -48, -114]],
          type: "Polygon",
          properties: {
            name: "Ungogo",
            id: "KN042",
            nameAbbr: "Ungogo",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.69072295074,
            Shape_Area: 0.01550133493,
          },
        },
        {
          arcs: [[-60, -4, 130, -45, -103, -126, -54, -73]],
          type: "Polygon",
          properties: {
            name: "Warawa",
            id: "KN043",
            nameAbbr: "Warawa",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.78903559932,
            Shape_Area: 0.02854037466,
          },
        },
        {
          arcs: [[-72, -9, -67, -29, -46, -131, -3]],
          type: "Polygon",
          properties: {
            name: "Wudil",
            id: "KN044",
            nameAbbr: "Wudil",
            parent: "Kano",
            parentAbbr: "KN",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.96501913361,
            Shape_Area: 0.02772398299,
          },
        },
      ],
    },
  },
};

const akwaibom = {
  type: "Topology",
  arcs: [
    [
      [9556, 12138],
      [-158, 260],
      [-75, 307],
      [-122, -60],
      [-72, 117],
      [37, 230],
      [36, 163],
      [9, 142],
      [-18, 130],
      [-58, 136],
      [-78, 171],
      [-52, 117],
      [-47, 21],
      [-72, 96],
      [-470, 56],
      [-19, 61],
      [-66, 83],
      [-144, 246],
      [-159, 132],
      [-34, 35],
      [-461, 32],
    ],
    [
      [7533, 14613],
      [47, 118],
      [90, 147],
      [122, 100],
      [117, 107],
      [121, 52],
      [102, 39],
      [135, 52],
      [141, -23],
      [107, -49],
      [94, -2],
      [101, 26],
      [29, 121],
      [22, 122],
      [22, 108],
      [15, 102],
      [-12, 88],
      [-59, 96],
      [-174, 37],
      [-188, 10],
      [-141, 16],
      [-114, -4],
      [-243, -23],
      [-204, -133],
      [-211, -159],
      [-224, -132],
      [-177, -167],
      [-48, -80],
    ],
    [
      [7003, 15182],
      [-67, 42],
      [-121, 29],
      [-85, 137],
      [-138, 158],
      [-66, 90],
      [-87, 62],
      [-105, 125],
      [-105, 157],
      [-98, 165],
      [-33, 89],
      [-46, 69],
      [-66, 62],
      [-34, 127],
    ],
    [
      [5952, 16494],
      [84, 62],
      [224, 132],
      [156, 99],
      [197, 132],
      [109, 73],
      [116, 100],
      [116, 87],
      [231, 152],
      [163, 140],
      [103, 100],
      [148, 4],
      [168, -10],
      [188, 4],
      [216, 37],
      [216, 57],
      [156, 126],
      [90, 121],
      [258, 179],
      [178, 201],
      [117, 174],
      [136, 79],
      [67, 31],
    ],
    [
      [9389, 18574],
      [1, -5],
      [83, -273],
      [117, -219],
      [60, -106],
    ],
    [
      [9650, 17971],
      [45, -79],
      [111, -185],
      [143, -335],
      [130, -254],
      [-86, -311],
      [28, -367],
      [57, -232],
      [84, -232],
      [119, -111],
      [52, -95],
      [43, -273],
      [3, -217],
      [0, -448],
      [-158, -221],
      [-68, -413],
      [-63, -169],
      [-45, -271],
      [-31, -271],
      [-70, -202],
      [-73, -305],
      [-31, -291],
      [-139, -310],
      [-125, -208],
      [-20, -33],
    ],
    [
      [4316, 98],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [25, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [16, 0],
    ],
    [
      [2721, 1008],
      [-8, 8],
      [8, -1],
      [0, -7],
    ],
    [
      [6259, 1465],
      [0, -8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, -9],
      [124, 0],
      [21, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 2],
      [0, -10],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [49, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [106, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [33, 0],
      [0, 8],
      [121, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [81, 0],
      [0, 9],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [49, 0],
      [0, -9],
      [97, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [65, 0],
      [0, 8],
      [122, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [32, 0],
      [0, 9],
      [73, 0],
      [0, -9],
      [24, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [34, 0],
      [23, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-20, 0],
      [-158, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-105, 0],
      [0, -9],
      [-57, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -4],
      [0, -4],
      [-33, 1],
      [0, -9],
      [-64, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-49, 0],
      [-15, 0],
      [0, -4],
      [0, -4],
      [-9, 0],
      [1, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-38, 0],
      [-27, 0],
      [0, 11],
      [-8, -2],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, -1],
      [1, 10],
      [-25, -2],
      [1, 8],
      [-34, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 7],
      [0, 9],
      [-9, 0],
      [1, 25],
      [-16, 0],
      [0, 16],
      [-17, 0],
      [0, 25],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 8],
      [32, 0],
      [0, -8],
      [33, 0],
      [0, -9],
      [57, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [121, 0],
    ],
    [
      [6355, 1571],
      [-7, 0],
      [0, -16],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-80, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-57, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-9, 0],
      [0, 57],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 49],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 7],
    ],
    [
      [5328, 1644],
      [32, 8],
      [250, 71],
      [385, 81],
      [263, 63],
      [155, 32],
      [95, 12],
      [-35, -46],
    ],
    [
      [6473, 1865],
      [-20, -28],
      [-35, -54],
      [-35, -81],
      [-35, -80],
      [7, -51],
    ],
    [
      [5304, 1639],
      [0, -10],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -82],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-49, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-129, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [40, 0],
      [0, 8],
      [129, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [73, 0],
      [0, 8],
      [49, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [1, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [17, 0],
      [0, -16],
      [-81, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [89, 0],
      [0, -9],
      [81, 0],
      [0, -8],
      [97, 0],
      [0, 8],
      [89, 0],
      [0, 9],
      [48, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [64, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [113, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -63],
      [0, -11],
      [-11, 0],
      [-5, 0],
      [0, -5],
      [0, -3],
      [-8, 0],
      [0, -6],
      [0, -10],
      [-9, 1],
      [1, -9],
      [-9, 1],
      [1, -10],
      [-17, 1],
      [1, -9],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -16],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [-1, -8],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-17, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, 50],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-14, 0],
      [-35, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -16],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-146, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [0, 9],
      [-8, 0],
      [0, 3],
      [0, 4],
      [8, 1],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 66],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-25, 0],
      [0, 16],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-7, 0],
      [0, 50],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [97, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 33],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [105, 0],
      [0, -8],
      [41, 0],
      [0, -8],
      [5, 0],
      [3, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [64, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [25, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-48, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
    ],
    [
      [3879, 1465],
      [87, 34],
      [63, 108],
      [123, 140],
      [-32, 116],
      [75, 74],
      [128, 25],
      [141, -16],
      [66, -69],
      [67, -83],
      [173, -64],
      [100, -42],
      [160, -85],
      [169, 11],
      [105, 25],
    ],
    [
      [10302, 2351],
      [96, -202],
      [84, -171],
      [176, -419],
      [4, -12],
    ],
    [
      [10662, 1547],
      [-7, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-106, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-55, 0],
      [-58, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-122, 0],
      [0, -8],
      [-97, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-154, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-10, 0],
      [-47, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-122, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-97, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-97, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 41],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [24, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-121, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-48, 0],
      [0, 16],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-122, 0],
      [0, -9],
      [-162, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
    ],
    [
      [6615, 1383],
      [227, -15],
      [89, 114],
      [170, 113],
      [136, 106],
      [183, 106],
      [257, 90],
      [230, 85],
      [0, -7],
      [55, 87],
      [136, 86],
      [142, 72],
      [190, 45],
      [141, 11],
      [175, 44],
      [209, 17],
      [161, 4],
      [168, 4],
      [141, -16],
      [122, 12],
      [107, 5],
      [209, 3],
      [114, -2],
      [128, -2],
      [197, 6],
    ],
    [
      [16249, 6370],
      [57, -78],
      [-15, -122],
      [-36, -163],
      [-112, -249],
      [-84, -223],
      [-134, -350],
      [-79, -325],
      [-311, -538],
      [118, -192],
      [112, -131],
      [134, -56],
      [115, -382],
      [-66, -213],
    ],
    [
      [15948, 3348],
      [-45, -10],
      [-432, -94],
      [-378, -68],
      [-392, -129],
      [-225, -180],
      [-453, -196],
      [-219, -213],
      [-332, -157],
      [-183, -99],
      [-189, -99],
      [-197, -98],
      [-254, 119],
      [-72, 76],
      [-86, 11],
    ],
    [
      [12491, 2211],
      [80, 85],
      [95, 25],
      [55, 54],
      [63, 175],
      [16, 149],
      [-98, 172],
      [-139, 165],
      [-132, 158],
      [-78, 144],
      [49, 108],
      [48, 101],
      [123, 100],
      [143, 133],
      [71, 196],
      [45, 298],
      [-98, 178],
      [-166, 145],
      [-127, 23],
      [-129, 355],
      [-137, 267],
      [-110, 253],
      [-64, 198],
      [32, 312],
      [-44, 238],
      [37, 163],
      [-11, 183],
      [50, 189],
      [55, 88],
      [13, 122],
    ],
    [
      [12133, 6988],
      [9, 6],
      [153, -118],
      [240, -119],
      [212, -181],
      [248, -99],
      [310, 70],
      [348, -81],
      [342, -101],
      [617, -92],
      [423, -28],
      [215, 24],
      [291, 63],
      [390, 20],
      [318, 18],
    ],
    [
      [18322, 5978],
      [23, -7],
      [203, 58],
      [121, 25],
      [308, -73],
      [220, -153],
      [191, -241],
      [173, -91],
      [100, -70],
      [98, -185],
      [44, -126],
    ],
    [
      [19803, 5115],
      [13, -38],
      [-2, -142],
      [-111, -216],
      [-70, -216],
      [-25, -278],
      [-24, -237],
      [-50, -203],
      [-9, -51],
    ],
    [
      [19525, 3734],
      [-174, -13],
      [-155, -32],
      [-726, 20],
      [-169, -99],
      [-593, -85],
      [-258, -125],
      [-733, 6],
      [-578, -17],
      [-191, -41],
    ],
    [
      [16249, 6370],
      [52, 3],
      [196, 85],
      [238, 132],
      [220, 28],
    ],
    [
      [16955, 6618],
      [34, -80],
      [159, -159],
      [134, -29],
      [180, -85],
      [194, -92],
      [214, -85],
      [214, -44],
      [238, -66],
    ],
    [
      [4037, 20304],
      [102, -16],
      [135, -9],
      [228, -31],
      [201, -3],
      [168, -30],
      [154, -50],
      [114, 5],
      [54, 12],
      [152, -145],
      [127, -77],
      [254, -106],
      [174, -78],
      [207, -10],
      [154, -64],
      [276, -18],
      [167, -16],
      [249, 9],
      [155, -3],
      [120, -76],
      [165, -159],
      [167, -98],
      [155, 24],
      [89, 94],
      [73, -29],
      [142, 11],
      [182, 79],
      [123, 99],
      [89, 74],
      [45, 44],
    ],
    [
      [8458, 19737],
      [61, -87],
      [107, -56],
      [195, -17],
      [127, -29],
      [175, -24],
      [93, -42],
      [120, -90],
      [71, -178],
      [-15, -75],
      [13, -7],
      [-23, -223],
      [7, -335],
    ],
    [
      [5952, 16494],
      [-229, -170],
      [-357, -55],
      [-161, 30],
      [-161, 9],
      [-119, 118],
      [-66, 96],
      [-11, 143],
      [-93, 62],
      [-93, 56],
      [-48, 1],
      [-54, -47],
      [-28, -60],
      [-149, -39],
      [-60, 15],
      [-15, 11],
    ],
    [
      [4308, 16664],
      [9, 10],
      [96, 100],
      [61, 40],
      [28, 60],
      [48, 101],
      [69, 81],
      [21, 67],
      [-20, 41],
      [-80, 22],
      [-80, 1],
      [-121, 23],
      [-222, -10],
      [-109, -53],
      [-128, -18],
      [-115, -45],
      [-236, -57],
      [-148, -38],
      [-81, -13],
      [-108, 23],
      [-147, 16],
      [-67, 62],
      [-52, 96],
      [-92, 90],
      [-107, 35],
      [-174, 99],
      [-153, 63],
      [-80, 76],
      [-145, 145],
      [-240, 120],
      [-117, 10],
    ],
    [
      [1818, 17811],
      [107, 258],
      [-207, 561],
      [-104, 627],
      [-10, 640],
      [2, 55],
    ],
    [
      [1606, 19952],
      [155, 24],
      [171, 167],
      [236, 77],
      [223, 24],
      [114, 25],
      [75, 53],
      [102, 66],
      [216, 44],
      [114, 25],
      [156, 18],
      [107, 5],
      [127, -29],
      [234, -113],
      [201, -51],
      [94, 32],
      [106, -15],
    ],
    [
      [7003, 15182],
      [-166, -283],
      [-166, -268],
      [-180, -289],
      [-239, -275],
      [-118, -187],
      [-198, -221],
      [-163, -113],
      [-79, 90],
      [-86, 83],
      [-128, 49],
      [-160, 85],
      [-200, 64],
      [-215, 38],
      [-215, -30],
      [-303, -2],
      [-249, -23],
      [-276, -36],
      [-190, -78],
      [-311, -124],
      [-358, -129],
      [-305, -131],
      [-307, -246],
      [-183, -139],
      [-239, -234],
      [-254, -301],
      [-280, -274],
      [-97, -181],
      [-7, 7],
      [-95, -87],
      [-213, -295],
      [-206, -275],
      [-19, -19],
    ],
    [
      [798, 11358],
      [-252, 441],
      [-267, 562],
      [-195, 377],
      [139, 297],
      [163, 444],
    ],
    [
      [386, 13479],
      [128, 1],
      [202, -3],
      [215, 16],
      [196, 44],
      [182, 52],
      [156, 44],
      [217, 146],
      [90, 135],
      [69, 148],
      [124, 140],
      [103, 148],
      [83, 168],
      [171, 201],
      [15, 61],
      [76, 127],
      [212, 221],
      [151, 221],
      [158, 181],
      [144, 208],
      [300, 225],
      [178, 208],
      [190, 112],
      [183, 126],
      [130, 99],
      [69, 128],
      [62, 74],
      [40, 13],
      [78, -59],
    ],
    [
      [12133, 6988],
      [-140, -86],
      [-82, -87],
      [-297, -63],
      [-182, -37],
      [-196, -51],
      [-149, -59],
      [-118, 138],
      [-183, -85],
      [-31, 170],
      [-157, -120],
      [78, 223],
      [-230, -98],
      [-239, -186],
      [-31, 157],
      [-114, 12],
    ],
    [
      [10062, 6816],
      [112, 246],
      [-125, 226],
      [33, 359],
      [-152, 173],
      [-92, 103],
      [-36, 299],
      [-59, 69],
      [-77, 267],
      [-23, 190],
      [-79, 164],
      [-71, 130],
      [38, 312],
      [50, 176],
      [131, 160],
      [135, 46],
      [61, 60],
      [57, 189],
      [2, 135],
      [50, 183],
      [3, 163],
      [22, 149],
      [-37, 157],
      [8, 142],
      [-44, 150],
      [-65, 164],
      [-74, 8],
      [-65, 117],
      [-39, 96],
      [49, 128],
      [16, 176],
      [96, 80],
      [115, 59],
      [69, 94],
      [5, 25],
    ],
    [
      [10076, 12011],
      [56, 1],
      [162, 38],
      [41, 53],
      [56, 142],
      [49, 142],
      [44, 189],
      [30, 196],
      [1, 95],
      [-24, 157],
      [18, 278],
      [52, 339],
      [24, 203],
      [24, 271],
      [18, 244],
      [117, 168],
      [135, 79],
    ],
    [
      [10879, 14606],
      [5, -95],
      [60, -83],
      [8, -305],
      [71, -192],
      [30, -204],
      [-42, -141],
      [83, -287],
      [-9, -156],
      [123, -253],
      [-4, -231],
      [98, -199],
      [85, -184],
      [25, -76],
      [80, -55],
      [119, -111],
      [32, -150],
      [51, -130],
      [-16, -156],
      [5, -142],
      [-36, -95],
      [4, -210],
      [3, -190],
      [23, -265],
      [51, -198],
      [3, -218],
      [23, -292],
      [3, -204],
      [64, -204],
      [4, -80],
    ],
    [
      [11825, 9500],
      [6, -124],
      [70, -219],
      [52, -129],
      [18, -157],
      [10, -183],
      [9, -279],
      [-23, -196],
      [-76, -135],
      [-4, -251],
      [71, -185],
      [50, -238],
      [38, -123],
      [45, -109],
      [45, -150],
      [-3, -34],
    ],
    [
      [25009, 2635],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [57, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
    ],
    [
      [23315, 3544],
      [-14, 0],
      [0, 3],
    ],
    [
      [23301, 3547],
      [14, -3],
    ],
    [
      [23236, 3560],
      [16, -1],
      [0, -7],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, 9],
      [65, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [73, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -7],
      [0, -26],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -90],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-89, 0],
      [0, 16],
      [-8, 0],
      [0, 106],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, -16],
      [33, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -106],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-32, 0],
      [0, -24],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [138, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 16],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 98],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [48, 0],
      [0, -9],
      [33, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [25, 0],
      [0, -8],
      [40, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [41, 0],
      [0, 8],
      [24, 0],
      [0, 6],
      [0, 3],
      [8, 0],
      [0, -4],
      [0, -70],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -41],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-24, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-32, 0],
      [0, 9],
      [-25, 0],
      [0, -25],
      [25, 0],
      [0, -41],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -57],
      [8, 0],
      [0, -27],
      [0, -71],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -18],
      [0, -6],
      [-8, 0],
      [0, -8],
      [-4, 0],
      [-4, 0],
      [0, -5],
      [0, -12],
      [-9, 1],
      [1, -25],
      [-8, 0],
      [0, -33],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-138, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-89, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-56, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-33, 0],
      [0, 7],
      [-4, 1],
      [-36, 0],
      [0, 2],
      [0, 6],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-65, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, 9],
      [-49, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-72, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-162, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-73, 0],
      [0, 8],
      [-57, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-64, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-65, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-122, 0],
      [0, 8],
      [-105, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-73, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-33, 0],
      [0, -8],
      [-146, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-40, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-56, 0],
      [0, -5],
      [0, -3],
      [-33, 0],
      [0, 6],
      [0, 2],
      [-24, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-137, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-73, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-113, 0],
      [-1, -8],
      [-40, 0],
      [0, 8],
      [-97, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-57, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-89, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-80, 0],
      [0, -8],
      [-227, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-162, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-97, 0],
      [0, -9],
      [-146, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-81, 0],
      [0, -8],
      [-114, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-56, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-32, 0],
      [-1, 8],
      [-16, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-89, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-137, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-105, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-105, 0],
      [0, -7],
      [-8, 0],
      [0, 7],
      [-25, 0],
      [0, -7],
      [-56, 0],
      [0, 7],
      [-25, 0],
      [0, -7],
      [-56, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-44, 0],
      [-29, 0],
      [0, 8],
      [-81, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [-49, 0],
      [0, -9],
      [-41, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-65, 1],
      [0, -9],
      [-21, 0],
      [-43, 0],
      [0, 8],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [1, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -9],
      [-73, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-81, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-24, 0],
      [0, -5],
      [0, -4],
      [-65, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 4],
      [0, 5],
      [-17, 0],
      [0, -5],
      [0, -4],
      [-24, 0],
      [0, 5],
      [0, 4],
      [-32, 0],
      [0, -4],
      [0, -5],
      [-16, 0],
      [0, -8],
      [-106, 0],
      [0, -8],
      [-97, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-98, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [-89, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-40, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-8, 0],
      [-40, 0],
      [0, 9],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [7, 0],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 25],
      [-57, 0],
      [0, -9],
      [-24, 0],
      [-1, -8],
      [-15, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-40, 0],
      [0, 9],
      [-41, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-130, 0],
      [0, -9],
      [-49, 0],
      [0, -5],
      [0, -3],
      [-24, 2],
      [0, -10],
      [-24, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-73, 1],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 1],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-48, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-89, 0],
      [0, -9],
      [-56, 0],
      [0, -8],
      [-73, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-81, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-40, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-48, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-57, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-33, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-49, 0],
      [0, -9],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, -9],
      [-65, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-40, 0],
      [0, -9],
      [-25, 0],
      [0, 9],
      [-48, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-114, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [-1, 6],
      [-7, 2],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, -1],
      [0, -7],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-48, 0],
      [0, -8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-57, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-82, 0],
    ],
    [
      [10302, 2351],
      [79, 3],
      [86, -90],
      [58, -137],
      [25, -123],
      [88, 26],
      [242, 43],
      [149, 45],
      [190, 79],
      [343, 21],
      [229, 16],
      [208, 4],
      [249, 2],
      [243, -29],
    ],
    [
      [19525, 3734],
      [42, 3],
      [234, -31],
      [87, -35],
      [221, -65],
      [248, -39],
      [336, -32],
      [350, 14],
      [203, 71],
      [348, -74],
      [604, -85],
      [337, 35],
      [169, 65],
      [484, 12],
      [48, -13],
    ],
    [
      [14981, 14643],
      [0, -5],
      [19, -89],
      [19, -68],
      [39, -62],
      [51, -150],
      [107, -70],
      [46, -55],
      [17, -177],
      [59, -130],
      [103, -266],
      [31, -191],
      [-43, -169],
      [70, -252],
      [92, -138],
      [22, -258],
      [24, -184],
      [2, -7],
    ],
    [
      [15639, 12372],
      [30, -115],
      [3, -245],
      [-37, -183],
      [-56, -148],
      [-71, -202],
      [-68, -67],
      [-61, -47],
      [-68, -80],
      [-64, -196],
      [-56, -135],
      [3, -203],
      [-34, -41],
      [-34, -53],
      [-75, -60],
      [-62, -81],
      [-117, -87],
    ],
    [
      [14872, 10429],
      [-25, 36],
      [-67, 35],
      [-114, 9],
      [-216, -78],
      [-163, -72],
      [-149, -106],
      [-13, -4],
    ],
    [
      [14125, 10249],
      [-123, -35],
      [-59, 62],
      [-87, 43],
      [-44, 150],
      [-200, 146],
      [-58, 157],
      [-78, 178],
      [-69, 266],
      [-151, 247],
      [-51, 164],
      [-98, 184],
      [4, 245],
      [-90, 246],
      [-60, 76],
      [-131, 212],
      [-58, 110],
      [-31, 211],
      [-119, 138],
      [-50, 218],
      [17, 190],
      [-39, 116],
      [-46, 48],
      [-80, 56],
      [-107, 62],
      [-38, 103],
      [-65, 103],
      [-229, 31],
      [-91, 192],
      [30, 176],
      [-13, 61],
      [-10, 177],
      [-19, 109],
      [-78, 130],
      [-51, 177],
      [-59, 83],
      [-100, 76],
      [-106, 90],
      [-18, 24],
    ],
    [
      [11470, 15271],
      [133, 42],
      [368, -108],
      [369, -67],
      [322, -19],
      [345, 102],
      [176, 52],
      [182, 51],
      [207, -85],
      [106, -124],
      [240, -79],
      [429, -102],
      [167, -91],
      [132, -118],
      [247, -113],
      [88, 31],
    ],
    [
      [15523, 23305],
      [-7, -14],
      [-190, -119],
      [-97, -201],
      [-65, -230],
      [-190, -119],
      [-131, -215],
      [11, -116],
      [53, -69],
      [32, -102],
      [-55, -101],
      [-116, -134],
      [-156, -78],
      [-163, -66],
      [-35, -94],
      [-49, -115],
      [-68, -80],
      [-28, -47],
      [-83, -141],
      [-82, -101],
      [-123, -86],
      [-75, -73],
      [-21, -81],
      [-76, -135],
      [17, -190],
      [-39, -298],
      [17, -231],
      [-157, -167],
      [-116, -114],
      [-131, -181],
      [-157, -119],
      [-190, -140],
      [-170, -139],
      [-96, -87],
      [-69, -87],
      [-71, -270],
      [-200, -309],
      [-171, -160],
      [-55, -108],
      [24, -190],
      [-83, -128],
      [-190, -119],
      [-136, -79],
      [-2, -129],
      [-63, -155],
      [-49, -114],
      [-28, -102],
      [-77, -182],
      [-124, -174],
      [-49, -108],
      [-52, -298],
      [-34, -57],
    ],
    [
      [11358, 16353],
      [-235, 48],
      [-254, 93],
      [-72, 150],
      [-113, 70],
    ],
    [
      [10684, 16714],
      [77, 189],
      [51, 250],
      [31, 251],
      [-3, 210],
      [4, 285],
      [25, 271],
      [41, 455],
      [-44, 224],
      [-11, 523],
      [-16, 279],
      [32, 305],
      [-56, 266],
      [0, 421],
      [17, 271],
      [66, 291],
      [-66, 137],
      [-30, 231],
      [-30, 211],
      [-50, 218],
      [-20, 394],
      [16, 210],
      [-45, 110],
      [-91, 185],
      [-66, 109],
      [-25, 109],
      [-105, 131],
      [-93, 83],
      [-106, 118],
      [-86, 96],
      [-51, 171],
      [-24, 129],
      [2, 156],
      [43, 163],
      [33, 98],
    ],
    [
      [10104, 24264],
      [116, -6],
      [90, 148],
      [68, 80],
      [42, 81],
      [15, 74],
      [49, 122],
      [76, 161],
      [123, 134],
      [69, 80],
      [82, 80],
      [144, 181],
      [48, 88],
      [96, 93],
      [209, 51],
      [183, 91],
      [142, 52],
      [109, 53],
      [75, 60],
      [108, 32],
      [121, 25],
      [18, 1],
    ],
    [
      [12087, 25945],
      [-21, -179],
      [-345, -599],
      [76, -280],
      [233, -575],
      [395, -150],
      [125, -559],
      [243, -365],
      [444, -34],
      [501, 201],
      [432, 54],
      [868, 47],
      [315, -122],
      [170, -79],
    ],
    [
      [386, 13479],
      [13, 35],
      [-12, 558],
      [-277, 317],
      [-110, 294],
      [7, 408],
      [220, 309],
      [-41, 368],
      [511, 446],
      [202, 91],
      [205, 93],
      [267, 294],
      [236, 424],
      [129, 494],
      [82, 201],
    ],
    [
      [10684, 16714],
      [-46, 28],
      [-113, 117],
      [-10, 204],
      [-11, 150],
      [-71, 198],
      [-3, 190],
      [-65, 157],
      [-117, 247],
      [-107, 63],
      [-80, 35],
      [-81, -12],
      [-121, -32],
      [-47, -13],
      [-27, -6],
      [-55, -20],
      [-80, -49],
    ],
    [
      [8458, 19737],
      [-70, 99],
      [-125, 178],
      [-73, 90],
      [-76, 245],
      [29, 142],
      [-158, 227],
      [-91, 185],
      [-42, 320],
      [-77, 232],
      [-71, 178],
      [-111, 205],
      [-111, 219],
      [-65, 137],
      [-186, 99],
      [21, 108],
      [3, 176],
      [-12, 96],
      [-50, 231],
      [-118, 192],
      [-86, 70],
      [-93, 56],
      [-27, 7],
      [-70, 28],
    ],
    [
      [6799, 23257],
      [-110, 43],
      [1, 81],
      [-51, 198],
      [-58, 137],
      [-141, 30],
      [-121, -12],
      [-174, 71],
      [-73, 62],
      [-66, 56],
      [-119, 138],
      [-62, 67],
    ],
    [
      [5825, 24128],
      [57, 14],
      [262, 9],
      [114, -29],
      [114, -22],
      [34, 6],
      [262, -11],
      [189, 44],
      [127, 5],
      [163, 65],
      [115, 79],
      [271, 118],
      [176, 71],
      [116, 114],
      [123, 86],
      [156, 126],
      [163, 99],
      [149, 45],
      [162, 32],
      [168, 10],
      [222, -10],
      [181, -51],
      [261, -59],
      [145, -131],
      [113, -111],
      [105, -165],
      [119, -103],
      [160, -91],
      [52, -4],
    ],
    [
      [5328, 1644],
      [0, 18],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 33],
      [81, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [1, -8],
      [73, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [32, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [41, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 24],
      [17, 0],
      [0, 33],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [17, 0],
      [0, 9],
      [32, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [32, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [16, 0],
      [0, 9],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-41, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, -1],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -32],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-97, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -23],
    ],
    [
      [3879, 1465],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-105, 0],
      [0, -9],
      [-41, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -41],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-41, 0],
      [0, 9],
      [-97, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-82, 0],
      [0, 9],
      [-56, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 16],
      [8, 0],
      [0, 49],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 41],
      [8, 0],
      [0, 66],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [64, 0],
      [0, -9],
      [33, 0],
      [0, -8],
      [48, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [49, 0],
      [0, -8],
      [56, 0],
      [0, -8],
      [49, 0],
      [0, -9],
      [97, 0],
      [0, -7],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, -9],
      [40, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -16],
      [9, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -7],
      [25, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [24, 0],
      [0, -9],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 57],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 7],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [65, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [33, 0],
      [0, 8],
      [81, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 7],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-106, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-32, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 91],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [24, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [48, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-25, 0],
      [0, -8],
      [-32, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-25, 0],
      [0, -9],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [33, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 57],
      [41, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [24, 0],
      [0, 8],
      [41, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -33],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -73],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -42],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -33],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-24, 0],
      [0, -7],
      [-8, 0],
      [0, 7],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 25],
      [-8, 0],
      [0, 41],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-32, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, -1],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -9],
      [-7, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-89, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-48, 0],
      [0, 7],
      [-40, 1],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-106, 0],
      [0, 9],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-56, 0],
      [0, -8],
      [-33, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, -1],
      [0, -7],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -7],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-9, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-9, 0],
      [0, -9],
      [-2, 0],
      [-5, 9],
      [-289, 561],
      [4, 71],
      [14, 234],
      [239, 153],
      [-175, 417],
      [-25, 599],
      [2, 537],
      [-78, 620],
      [32, 126],
    ],
    [
      [1782, 5569],
      [78, -21],
      [382, -88],
      [442, -55],
      [376, -20],
      [464, -15],
      [235, -4],
      [319, 157],
      [414, 258],
      [361, 265],
      [285, 159],
      [298, 171],
      [245, 206],
      [134, 203],
    ],
    [
      [5815, 6785],
      [24, -2],
      [215, -24],
      [189, 11],
      [140, -51],
      [105, -164],
      [-15, -61],
      [12, -109],
      [40, -1],
      [107, -76],
      [33, -14],
      [41, 33],
      [48, 60],
      [170, 126],
      [92, -137],
      [6, -82],
      [72, -96],
      [81, 39],
      [138, 236],
      [126, -132],
      [11, -135],
      [11, -157],
      [-42, -121],
      [-104, -216],
      [-151, -167],
      [-97, -161],
      [-129, -127],
      [-31, -203],
      [67, -35],
      [12, -123],
      [215, 10],
      [159, -152],
      [152, -138],
      [126, -118],
      [187, -125],
      [126, -91],
      [71, -177],
      [45, -137],
      [-113, -290],
      [-44, -250],
      [-118, -249],
      [-199, -255],
      [-178, -201],
      [-245, -192],
      [-254, -295],
      [-232, -227],
      [-211, -144],
    ],
    [
      [4037, 20304],
      [29, 26],
      [204, 126],
      [136, 100],
      [178, 173],
      [123, 174],
      [103, 141],
      [77, 203],
      [57, 162],
      [84, 202],
      [36, 183],
      [44, 223],
      [23, 183],
      [43, 142],
      [-5, 115],
      [62, 108],
      [143, 86],
      [108, 38],
      [188, -17],
      [121, 26],
      [48, 33],
      [35, 108],
      [8, 68],
      [95, 52],
      [121, 12],
      [81, -22],
      [101, 18],
      [14, 14],
      [20, 34],
      [22, 74],
      [28, 94],
      [102, 46],
      [114, -9],
      [141, 18],
      [78, 19],
    ],
    [
      [12665, 26383],
      [-158, -13],
      [-243, -37],
      [-166, -296],
      [-11, -92],
    ],
    [
      [5825, 24128],
      [-63, 70],
      [-99, 138],
      [-11, 156],
      [-59, 103],
      [-59, 69],
      [-46, 55],
      [-127, 50],
      [-59, 89],
      [-80, 83],
      [-6, 7],
    ],
    [
      [5216, 24948],
      [235, 334],
      [143, 500],
      [185, 663],
      [-234, 59],
      [-420, -115],
      [-397, -21],
      [-151, 213],
      [68, 482],
      [236, 413],
      [76, 132],
      [109, 515],
      [240, 302],
      [65, 243],
      [72, 346],
      [12, 326],
      [-83, 667],
      [-178, 683],
      [20, 64],
      [115, 369],
      [439, 94],
      [384, 14],
      [315, -39],
      [718, -142],
      [409, -68],
      [474, -226],
      [318, -332],
      [243, -371],
      [111, -634],
      [162, -370],
      [386, -319],
      [297, -304],
      [300, -216],
      [313, -182],
      [388, -143],
      [537, -111],
      [273, -148],
      [353, -271],
      [265, -222],
      [378, -374],
      [198, -241],
      [75, -135],
    ],
    [
      [13582, 17675],
      [-32, -5],
      [-229, -51],
      [17, -204],
      [-7, -6],
      [-88, -39],
      [-48, -34],
      [-80, -19],
      [-178, -166],
      [-245, -159],
      [-156, -113],
      [-177, -98],
      [-250, -118],
      [-232, -173],
      [-264, -159],
      [-235, 18],
      [-20, 4],
    ],
    [
      [15523, 23305],
      [391, -182],
      [551, -451],
      [582, -187],
      [326, -257],
      [148, -417],
      [38, -585],
      [-176, -493],
      [-33, -816],
      [-86, -345],
      [-29, -649],
    ],
    [
      [17235, 18923],
      [-89, -66],
      [-148, -38],
      [-122, -38],
      [-149, -39],
      [-188, -10],
      [-101, -25],
      [-197, -99],
      [-175, -45],
      [-162, -37],
      [-148, -5],
      [-190, -98],
      [-101, -19],
      [-189, -10],
      [-188, -24],
      [-114, 9],
      [-149, -11],
      [-107, 8],
      [-188, -24],
      [-143, -45],
      [-121, -32],
      [-135, -52],
      [-102, -73],
      [-143, -112],
      [-95, -53],
      [-83, -107],
      [-96, -101],
      [-28, -81],
      [-2, -21],
    ],
    [
      [25260, 4960],
      [0, -9],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -17],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -65],
      [8, 0],
      [0, -41],
      [8, 0],
      [0, -25],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -33],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [25, 0],
      [0, -8],
      [16, 0],
      [0, -74],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -66],
      [-8, 0],
      [0, -24],
      [-9, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-9, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-17, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-65, 0],
      [0, -9],
      [-8, 0],
      [0, 9],
      [-49, 0],
      [0, 8],
      [-40, 0],
      [0, 8],
      [-48, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 58],
      [8, 0],
      [0, 32],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 32],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 25],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-6, 0],
      [-1, 32],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 8],
      [8, 0],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [25, 0],
      [0, 9],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [48, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [64, 0],
      [0, 8],
      [57, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [17, 0],
      [0, 25],
      [16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 24],
      [-9, 0],
      [0, 50],
      [-8, 0],
      [0, 49],
      [-5, 0],
      [-3, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 10],
      [0, 6],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 25],
      [8, 0],
      [0, 32],
      [8, 0],
      [0, 17],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [9, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [32, 0],
      [0, 16],
      [41, 0],
      [0, 9],
      [8, 0],
    ],
    [
      [25122, 6113],
      [0, -7],
      [-8, 0],
      [0, -74],
      [-8, 0],
      [0, -131],
      [8, 0],
      [0, -49],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [1, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, -9],
      [-24, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 49],
      [-8, 0],
      [0, 57],
      [-9, 0],
      [0, 41],
      [-113, 0],
      [0, -65],
      [-8, 0],
      [0, -41],
      [-8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -82],
      [8, 0],
      [0, -57],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [16, 0],
      [0, -8],
      [32, 0],
      [0, -8],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-7, 0],
      [-9, 0],
      [0, -8],
      [-9, 0],
      [0, -13],
      [-1, -3],
      [-15, -1],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -21],
      [0, -12],
      [-9, 2],
      [1, -10],
      [-9, 0],
      [-7, 0],
      [0, -8],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-9, 0],
      [0, -24],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-7, 0],
      [-10, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -32],
      [0, -8],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [8, 0],
      [0, -33],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-49, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-49, 0],
      [0, -8],
      [-40, -1],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -155],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -41],
      [5, 0],
      [3, 0],
      [0, -24],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-15, 0],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-9, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-25, 0],
      [0, -16],
      [-16, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -11],
      [0, -55],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -16],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-16, 0],
      [-1, -14],
      [-6, -3],
      [-1, -7],
      [-11, 0],
      [-4, -2],
      [-1, -6],
      [-9, 0],
      [-7, 0],
      [0, -4],
      [0, -4],
      [-8, 0],
      [-41, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-49, 0],
      [0, -8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-34, 0],
    ],
    [
      [23301, 3547],
      [0, 5],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 66],
      [8, 0],
      [0, 24],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 82],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-73, 0],
      [0, 8],
      [-97, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-90, 0],
      [0, -8],
      [-64, 0],
      [0, -8],
      [-146, 0],
      [0, 8],
      [-16, 0],
      [0, -24],
      [170, 0],
      [0, 8],
      [73, 0],
      [0, -8],
      [178, 0],
      [0, -9],
      [73, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [24, 0],
      [0, -8],
      [25, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -41],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-16, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -25],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -41],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
    ],
    [
      [19803, 5115],
      [35, 30],
      [115, 45],
      [54, 13],
      [14, 40],
      [62, 108],
      [136, 100],
      [162, 37],
      [236, 44],
      [243, 43],
      [168, 25],
      [136, 45],
      [6, 0],
      [257, 84],
      [109, 106],
      [123, 107],
      [-10, 211],
      [55, 107],
      [83, 134],
      [36, 149],
      [56, 94],
      [21, 88],
      [-12, 75],
      [-74, 28],
      [-79, 36],
      [-148, 23],
      [-46, 48],
      [122, 73],
      [155, 38],
      [162, 38],
      [64, 27],
    ],
    [
      [22044, 7111],
      [105, 45],
      [89, 113],
      [177, 120],
      [256, 50],
      [141, -57],
      [108, 73],
      [216, 50],
      [52, -109],
      [228, -31],
      [63, 101],
      [177, 180],
      [149, 31],
      [74, 19],
      [210, 105],
      [56, 94],
      [48, 115],
      [57, 162],
      [32, -29],
    ],
    [
      [24282, 8143],
      [25, -91],
      [7, -422],
      [-31, -203],
      [57, -293],
      [500, -937],
      [259, -74],
      [0, -3],
      [23, -7],
    ],
    [
      [9556, 12138],
      [104, -172],
      [263, 43],
      [153, 2],
    ],
    [
      [10062, 6816],
      [-14, -30],
      [-92, -284],
      [-31, -264],
      [145, -193],
      [159, -131],
      [126, -118],
      [146, -152],
      [30, -197],
      [-57, -203],
      [-165, -221],
      [-173, -276],
      [-212, -254],
      [-55, -74],
      [-36, -142],
      [-36, -148],
      [-36, -183],
      [11, -143],
      [149, -369],
      [35, -292],
      [117, -247],
      [124, -226],
      [90, -287],
      [15, -31],
    ],
    [
      [6615, 1383],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-41, 0],
      [0, 8],
      [-53, 0],
      [-68, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 5],
      [0, 20],
      [8, 0],
      [0, 24],
      [9, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [178, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [17, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [24, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [40, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-48, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-24, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-9, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-170, 0],
      [0, -8],
      [-9, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [-1, -17],
    ],
    [
      [5815, 6785],
      [101, 153],
      [164, 181],
      [218, 186],
      [249, 430],
      [234, 329],
      [309, 408],
      [263, 471],
      [195, 398],
      [130, 180],
      [104, 169],
      [205, 213],
      [218, 180],
      [310, 483],
      [281, 308],
      [207, 315],
      [185, 228],
      [179, 248],
      [78, 291],
      [111, 182],
    ],
    [
      [17895, 11633],
      [9, -12],
      [-1, -54],
      [13, -82],
      [-42, -88],
      [-55, -80],
      [-15, -102],
      [-28, -74],
      [-28, -54],
      [-48, -80],
      [-103, -101],
      [-217, -152],
      [-116, -100],
      [-62, -87],
      [5, -122],
      [-15, -68],
      [-15, -88],
      [19, -61],
      [6, -75],
      [53, -49],
      [38, -143],
      [46, -62],
      [98, -158],
      [-1, -88],
      [98, -171],
      [171, -248],
      [-84, -195],
      [-110, -148],
      [-172, -227],
      [-117, -161],
      [-59, -359],
      [-130, -140],
      [-170, -93],
      [-150, -126],
      [-124, -80],
    ],
    [
      [16589, 7705],
      [-64, 56],
      [-179, 166],
      [-140, 98],
      [-220, 91],
      [-124, 267],
      [-137, 254],
      [-86, 83],
      [-145, 152],
      [-146, 165],
      [-109, 287],
      [-98, 185],
      [-59, 110],
      [-25, 116],
      [-44, 191],
      [17, 196],
      [-98, 219],
      [-60, 88],
    ],
    [
      [15639, 12372],
      [45, 0],
      [88, 19],
      [141, -17],
      [121, 12],
      [81, 12],
      [88, 19],
      [87, 5],
      [162, 18],
      [141, -23],
      [280, -154],
      [107, 12],
      [128, -2],
      [174, -85],
      [113, -43],
      [114, -42],
      [191, -201],
      [195, -269],
    ],
    [
      [14125, 10249],
      [-1, -10],
      [-56, -141],
      [-16, -115],
      [-29, -183],
      [-95, -53],
      [-328, 87],
      [-256, -43],
      [-203, -30],
      [-100, 8],
      [-256, 5],
      [-215, -44],
      [-263, -37],
      [-169, -64],
      [-210, -72],
      [-103, -57],
    ],
    [
      [10879, 14606],
      [30, 156],
      [62, 121],
      [62, 80],
      [-5, 150],
      [42, 81],
      [15, 53],
      [148, 66],
      [188, -58],
      [49, 16],
    ],
    [
      [16589, 7705],
      [55, -48],
      [47, -41],
      [91, -185],
      [37, -157],
      [11, -218],
      [11, -108],
      [99, -104],
      [-9, -169],
      [24, -57],
    ],
    [
      [1606, 19952],
      [19, 454],
      [96, 508],
      [74, 427],
      [89, 536],
      [116, 466],
      [113, 359],
      [64, 230],
      [17, 244],
      [-116, 315],
      [-197, 255],
      [-134, 252],
      [-56, 105],
      [33, 359],
      [207, 323],
      [312, 144],
      [693, 43],
      [262, -39],
      [375, -122],
      [558, -10],
      [390, -13],
      [182, -23],
      [250, 70],
      [155, 18],
      [102, 86],
      [6, 9],
    ],
    [
      [22092, 11053],
      [-28, -13],
      [-142, -52],
      [-89, -39],
      [-88, -52],
      [-122, -100],
      [-190, -99],
      [-109, -73],
      [-183, -98],
      [-22, -102],
      [-16, -135],
      [12, -109],
      [-62, -128],
      [-8, -75],
      [18, -95],
      [-22, -142],
      [39, -82],
      [24, -157],
      [26, -89],
      [-2, -95],
      [96, -70],
    ],
    [
      [21224, 9248],
      [-219, -70],
      [-243, -77],
      [-170, -120],
      [-130, -120],
      [-9, -176],
      [-144, -147],
      [-284, -77],
      [-235, -29],
      [-399, -102],
      [-144, -174],
      [-217, -139],
      [-82, -114],
      [-91, -195],
      [-3, -177],
      [-30, -169],
      [-43, -196],
      [-63, -176],
      [-37, -169],
      [-50, -209],
      [-96, -94],
      [-56, -175],
      [-125, -216],
      [-32, -149],
    ],
    [
      [17895, 11633],
      [23, 1],
      [127, -43],
      [66, -55],
      [81, -8],
      [175, -3],
      [116, 140],
      [22, 129],
      [9, 129],
      [8, 47],
      [62, 114],
      [21, 61],
      [43, 155],
      [69, 94],
      [48, 74],
      [75, 81],
      [69, 73],
      [130, 174],
      [76, 81],
      [142, 92],
      [109, 80],
      [83, 114],
      [169, 126],
      [42, 60],
      [75, 74],
    ],
    [
      [19735, 13423],
      [477, -515],
      [411, -395],
      [520, -328],
      [72, -83],
      [443, -511],
      [416, -517],
      [18, -21],
    ],
    [
      [24927, 8291],
      [1, -8],
      [-8, 0],
      [0, -17],
      [10, 0],
      [-2, -33],
      [5, 0],
      [3, 0],
      [0, -26],
      [0, -23],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -9],
      [-17, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-16, 0],
      [0, -33],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -33],
      [-8, 0],
      [0, -49],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -73],
      [8, 0],
      [0, -58],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [8, 0],
      [0, -40],
      [8, 0],
      [0, -50],
      [8, 0],
      [0, -32],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-16, 0],
      [0, -8],
      [-65, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -41],
      [33, 1],
      [-1, -9],
      [8, 0],
      [0, -8],
      [7, 0],
      [9, 0],
      [0, -9],
      [9, 0],
      [0, -32],
      [-9, 0],
      [0, -25],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -5],
      [0, -4],
      [-8, 2],
      [0, -10],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [16, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [16, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [17, 0],
      [0, 8],
      [3, 0],
      [5, 1],
      [0, -9],
      [32, 0],
      [0, -9],
      [24, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [8, 0],
      [0, -24],
      [9, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [17, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [14, 0],
      [2, 0],
      [0, -24],
      [8, 1],
      [0, -67],
      [8, 0],
      [0, -49],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -49],
      [8, 0],
      [0, -57],
      [-8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -74],
      [8, 0],
      [0, -24],
      [5, 0],
      [3, 0],
      [0, -33],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -17],
      [-8, 0],
      [0, -24],
      [-8, 0],
      [0, -131],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -58],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -65],
      [-8, 0],
      [0, -58],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -9],
      [-8, 0],
      [0, -33],
    ],
    [
      [24282, 8143],
      [-90, 339],
      [-165, 312],
      [-56, 14],
      [-122, -11],
      [-121, -12],
      [-208, 17],
      [-108, -45],
      [-129, -46],
      [-161, 17],
      [-66, 42],
      [-161, 36],
      [-128, -25],
      [-102, -53],
      [-156, -71],
      [-87, 35],
      [-92, 76],
      [-107, 56],
      [-160, 91],
      [-173, 119],
      [-193, 132],
      [-240, 93],
      [-229, -10],
      [-4, -1],
    ],
    [
      [22092, 11053],
      [392, -441],
      [436, -497],
      [663, -616],
      [118, -118],
      [315, -141],
      [127, -92],
      [147, -110],
      [360, -265],
      [169, -220],
      [105, -228],
      [3, -34],
    ],
    [
      [1782, 5569],
      [91, 368],
      [104, 644],
      [188, 384],
      [35, 516],
      [-206, 568],
      [-93, 531],
      [-200, 397],
    ],
    [
      [1701, 8977],
      [370, 118],
      [346, 164],
      [230, 104],
      [203, 99],
      [257, 83],
      [203, 106],
      [183, 71],
      [149, 86],
      [109, 86],
      [224, 125],
      [244, 118],
      [169, 79],
      [102, 73],
      [129, 86],
      [190, 105],
      [138, 188],
      [197, 160],
      [186, 261],
      [138, 188],
      [96, 148],
      [159, 255],
      [73, 399],
      [-10, 184],
      [-11, 156],
      [78, 230],
      [190, 166],
      [116, 46],
      [210, 145],
      [246, 261],
      [252, 192],
      [178, 174],
      [119, 317],
      [166, 248],
      [111, 236],
      [76, 142],
      [16, 37],
    ],
    [
      [22044, 7111],
      [-124, 42],
      [-127, -12],
      [-193, 133],
      [-120, 76],
      [-32, 76],
      [-66, 123],
      [57, 203],
      [90, 161],
      [62, 108],
      [55, 66],
      [22, 75],
      [9, 176],
      [15, 88],
      [-51, 184],
      [-38, 110],
      [-86, 103],
      [-25, 129],
      [-117, 213],
      [-134, 70],
      [-17, 13],
    ],
    [
      [1701, 8977],
      [-21, 42],
      [-221, 507],
      [-442, 552],
      [-98, 82],
      [65, 89],
      [29, 40],
      [55, 53],
      [-56, 252],
      [-86, 539],
      [-128, 225],
    ],
    [
      [14981, 14643],
      [40, 14],
      [285, 111],
      [156, 85],
      [218, 173],
      [204, 166],
      [219, 207],
      [-23, 252],
      [-26, 41],
      [-108, 15],
      [-127, 36],
      [-169, -30],
      [-31, 163],
      [-125, 172],
      [-91, 185],
      [-44, 150],
      [-4, 204],
      [-57, 231],
      [-146, 139],
      [10, 183],
      [-219, 173],
      [-86, 83],
      [-146, 105],
      [-153, 84],
      [-256, 24],
      [-99, 77],
      [-370, -14],
      [-129, -25],
      [8, 47],
      [-130, -19],
    ],
    [
      [17235, 18923],
      [-3, -57],
      [38, -551],
      [146, -608],
      [133, -505],
      [198, -663],
      [292, -671],
      [334, -556],
      [317, -407],
      [521, -682],
      [433, -701],
      [91, -99],
    ],
  ],
  transform: {
    scale: [0.00003431138565136495, 0.000033939474965378715],
    translate: [7.462605233327279, 4.469585153567154],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Abak",
            id: "AI001",
            nameAbbr: "Abak",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.70630380086,
            Shape_Area: 0.01440958476,
          },
        },
        {
          arcs: [
            [[6]],
            [[7]],
            [[8]],
            [[9, 10, 11]],
            [[12, 13]],
            [[14, 15, 16]],
          ],
          type: "MultiPolygon",
          properties: {
            name: "Eastern Obolo",
            id: "AI002",
            nameAbbr: "Eastern Obolo",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.32541738507,
            Shape_Area: 0.0078213218,
          },
        },
        {
          arcs: [[17, 18, 19, 20]],
          type: "Polygon",
          properties: {
            name: "Eket",
            id: "AI003",
            nameAbbr: "Eket",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.58418046092,
            Shape_Area: 0.01534520946,
          },
        },
        {
          arcs: [[21, 22, 23, -18, 24, 25]],
          type: "Polygon",
          properties: {
            name: "Esit Eket",
            id: "AI004",
            nameAbbr: "Esit Eket",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.43370129228,
            Shape_Area: 0.01132754162,
          },
        },
        {
          arcs: [[26, 27, -4, 28, 29, 30, 31]],
          type: "Polygon",
          properties: {
            name: "Essien Udim",
            id: "AI005",
            nameAbbr: "Essien Udim",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.73246302854,
            Shape_Area: 0.02427940377,
          },
        },
        {
          arcs: [[-3, 32, 33, 34, -29]],
          type: "Polygon",
          properties: {
            name: "Etim Ekpo",
            id: "AI006",
            nameAbbr: "Etim Ekpo",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.67333588795,
            Shape_Area: 0.01571178981,
          },
        },
        {
          arcs: [[35, 36, 37, 38, 39]],
          type: "Polygon",
          properties: {
            name: "Etinan",
            id: "AI007",
            nameAbbr: "Etinan",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.67118112657,
            Shape_Area: 0.01391880116,
          },
        },
        {
          arcs: [[[40]], [[41, 42]], [[43, -15, 44, -19, -24, 45]]],
          type: "MultiPolygon",
          properties: {
            name: "Ibeno",
            id: "AI008",
            nameAbbr: "Ibeno",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.30189191159,
            Shape_Area: 0.01711637866,
          },
        },
        {
          arcs: [[46, 47, 48, 49, 50]],
          type: "Polygon",
          properties: {
            name: "Ibesikpo Asutan",
            id: "AI009",
            nameAbbr: "Ibesikpo Asutan",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.52583834158,
            Shape_Area: 0.01426643776,
          },
        },
        {
          arcs: [[51, 52, 53, 54, 55]],
          type: "Polygon",
          properties: {
            name: "Ibiono Ibom",
            id: "AI010",
            nameAbbr: "Ibiono Ibom",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.89104504562,
            Shape_Area: 0.02568359497,
          },
        },
        {
          arcs: [[-35, 56, -30]],
          type: "Polygon",
          properties: {
            name: "Ika",
            id: "AI011",
            nameAbbr: "Ika",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.49117681677,
            Shape_Area: 0.01062591492,
          },
        },
        {
          arcs: [[57, -5, -28, 58, 59, 60, -54]],
          type: "Polygon",
          properties: {
            name: "Ikono",
            id: "AI012",
            nameAbbr: "Ikono",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.77496309466,
            Shape_Area: 0.0219076067,
          },
        },
        {
          arcs: [[-11, 61, -14, 62, 63, 64]],
          type: "Polygon",
          properties: {
            name: "Ikot Abasi",
            id: "AI013",
            nameAbbr: "Ikot Abasi",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.30944962871,
            Shape_Area: 0.02711023323,
          },
        },
        {
          arcs: [[-27, 65, -59]],
          type: "Polygon",
          properties: {
            name: "Ikot Ekpene",
            id: "AI014",
            nameAbbr: "Ikot Ekpene",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.4623456119,
            Shape_Area: 0.01084521737,
          },
        },
        {
          arcs: [[66, -55, -61, 67, 68]],
          type: "Polygon",
          properties: {
            name: "Ini",
            id: "AI015",
            nameAbbr: "Ini",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.91170631832,
            Shape_Area: 0.03844062502,
          },
        },
        {
          arcs: [[69, -52, 70, 71]],
          type: "Polygon",
          properties: {
            name: "Itu",
            id: "AI016",
            nameAbbr: "Itu",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.71767343977,
            Shape_Area: 0.02077864076,
          },
        },
        {
          arcs: [[[72]], [[73, -43, 74, -46, -23, 75, 76, 77]]],
          type: "MultiPolygon",
          properties: {
            name: "Mbo",
            id: "AI017",
            nameAbbr: "Mbo",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.03081303838,
            Shape_Area: 0.01975543188,
          },
        },
        {
          arcs: [[78, -37, 79, -17, 80, -12, -65, 81]],
          type: "Polygon",
          properties: {
            name: "Mkpat Enin",
            id: "AI018",
            nameAbbr: "Mkpat Enin",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.14278183795,
            Shape_Area: 0.02813406946,
          },
        },
        {
          arcs: [[82, 83, -48, 84]],
          type: "Polygon",
          properties: {
            name: "Nsit Atai",
            id: "AI019",
            nameAbbr: "Nsit Atai",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.44193284506,
            Shape_Area: 0.01069207893,
          },
        },
        {
          arcs: [[-50, 85, -39, 86]],
          type: "Polygon",
          properties: {
            name: "Nsit Ibom",
            id: "AI020",
            nameAbbr: "Nsit Ibom",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.52298771354,
            Shape_Area: 0.00973826329,
          },
        },
        {
          arcs: [[87, -25, -21, -40, -86, -49, -84]],
          type: "Polygon",
          properties: {
            name: "Nsit Ubium",
            id: "AI021",
            nameAbbr: "Nsit Ubium",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.54043631215,
            Shape_Area: 0.01657578059,
          },
        },
        {
          arcs: [[-68, -60, -66, -32, 88]],
          type: "Polygon",
          properties: {
            name: "Obot Akara",
            id: "AI022",
            nameAbbr: "Obot Akara",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.62220715284,
            Shape_Area: 0.01889381913,
          },
        },
        {
          arcs: [[89, 90, -26, -88, -83, 91, 92]],
          type: "Polygon",
          properties: {
            name: "Okobo",
            id: "AI023",
            nameAbbr: "Okobo",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.72290965576,
            Shape_Area: 0.02234027355,
          },
        },
        {
          arcs: [[-45, -80, -36, -20]],
          type: "Polygon",
          properties: {
            name: "Onna",
            id: "AI024",
            nameAbbr: "Onna",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.5453864758,
            Shape_Area: 0.0123001932,
          },
        },
        {
          arcs: [[93, -78, 94, -90, 95]],
          type: "Polygon",
          properties: {
            name: "Oron",
            id: "AI025",
            nameAbbr: "Oron",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.54460375465,
            Shape_Area: 0.0066281313,
          },
        },
        {
          arcs: [[-82, -64, 96, 97, -1]],
          type: "Polygon",
          properties: {
            name: "Oruk Anam",
            id: "AI026",
            nameAbbr: "Oruk Anam",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.92607334093,
            Shape_Area: 0.0381747835,
          },
        },
        {
          arcs: [[-77, 98, -95]],
          type: "Polygon",
          properties: {
            name: "Udung Uko",
            id: "AI027",
            nameAbbr: "Udung Uko",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.32136435712,
            Shape_Area: 0.00436100087,
          },
        },
        {
          arcs: [[-98, 99, -33, -2]],
          type: "Polygon",
          properties: {
            name: "Ukanafun",
            id: "AI028",
            nameAbbr: "Ukanafun",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.80676532773,
            Shape_Area: 0.02269509663,
          },
        },
        {
          arcs: [[-92, -85, -47, 100, -72, 101]],
          type: "Polygon",
          properties: {
            name: "Uruan",
            id: "AI029",
            nameAbbr: "Uruan",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.80496322621,
            Shape_Area: 0.02366945442,
          },
        },
        {
          arcs: [[-76, -22, -91, -99]],
          type: "Polygon",
          properties: {
            name: "Urue Offong/Oruko",
            id: "AI030",
            nameAbbr: "Urue Offong/Oruko",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.46213263284,
            Shape_Area: 0.01036040662,
          },
        },
        {
          arcs: [[-101, -51, -87, -38, -79, -6, -58, -53, -70]],
          type: "Polygon",
          properties: {
            name: "Uyo",
            id: "AI031",
            nameAbbr: "Uyo",
            parent: "Akwa Ibom",
            parentAbbr: "AI",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.87839275006,
            Shape_Area: 0.01529384591,
          },
        },
      ],
    },
  },
};

const nasarawa = {
  type: "Topology",
  arcs: [
    [
      [45562, 36460],
      [0, -9],
      [-32, -1154],
      [-15, -923],
      [155, -1224],
      [-28, -336],
    ],
    [
      [45642, 32814],
      [-392, 112],
      [-1980, 170],
      [-994, -135],
      [-475, -64],
      [-789, -95],
      [-860, 14],
      [-281, -75],
      [-502, -135],
      [-450, -70],
      [-258, -40],
      [-846, -126],
    ],
    [
      [37815, 32370],
      [-1, 16],
      [-1, 36],
      [-166, 587],
      [-457, 422],
      [-496, 497],
      [-280, 574],
      [-91, 186],
      [-77, 219],
      [-206, 587],
      [-31, 534],
      [-16, 281],
      [-23, 104],
      [-13, 57],
      [15, 49],
      [21, 53],
      [9, 22],
      [10, 24],
      [28, 72],
      [-98, 182],
      [-22, 41],
      [-137, 269],
      [-21, 41],
      [-20, 49],
      [-59, 140],
      [-5, 15],
    ],
    [
      [35678, 37427],
      [176, 178],
      [41, 51],
      [125, 155],
      [203, 206],
      [232, 295],
      [175, 232],
      [90, 118],
      [103, 107],
      [199, 209],
      [165, 201],
      [60, 74],
      [217, 241],
      [172, 178],
      [35, 84],
      [32, 74],
      [170, 403],
      [9, 17],
      [172, 325],
      [184, 206],
      [160, 119],
      [36, 26],
      [17, 8],
      [211, 104],
      [108, -7],
      [70, -4],
      [222, -30],
      [91, -26],
      [138, -39],
      [127, -133],
      [111, -145],
      [2, -2],
      [138, -114],
      [73, -59],
      [62, -35],
      [189, -103],
      [127, -31],
      [109, -27],
      [325, -28],
      [312, -43],
      [255, -2],
      [331, 39],
      [211, -13],
      [128, -9],
      [316, -259],
      [119, -86],
      [148, -194],
      [178, -275],
      [275, -308],
      [206, -295],
      [149, -214],
      [296, -321],
      [337, -279],
      [41, -51],
      [171, -217],
      [248, -265],
      [27, -29],
      [17, -176],
      [60, -236],
      [13, -35],
      [60, -167],
      [53, -139],
      [34, -91],
      [9, -13],
      [3, -5],
      [11, 0],
      [24, -37],
      [376, -68],
      [108, -6],
      [284, -15],
      [208, 14],
    ],
    [
      [67591, 20594],
      [663, 208],
      [1376, 349],
      [1679, 489],
      [1144, -527],
      [1407, -667],
      [2324, -859],
      [1186, -978],
      [689, -1272],
      [-166, -272],
      [-188, -278],
      [-147, -248],
      [-153, -276],
      [-180, -305],
      [-205, -202],
      [-177, -164],
      [-185, -137],
      [-159, -83],
      [-423, -73],
      [-521, -97],
      [-395, 112],
      [-736, 69],
      [-502, 191],
      [-492, 82],
      [-153, 195],
      [-550, -70],
      [-166, -39],
      [-183, 248],
      [-288, 259],
      [-346, 101],
      [-263, 150],
      [-206, 176],
      [-543, -28],
      [-74, 60],
      [-259, -35],
      [-603, -409],
      [-231, -418],
      [-125, -203],
      [-271, -391],
      [-71, -345],
      [-17, -365],
      [-72, -283],
      [-184, -362],
      [473, -354],
      [334, -294],
      [276, -308],
      [211, -254],
      [140, -236],
      [328, -389],
      [33, -149],
      [10, -302],
      [20, -191],
      [68, -368],
      [33, -357],
      [-255, -307],
      [53, -121],
      [42, -194],
      [-34, -29],
      [115, -286],
      [166, -382],
      [128, -262],
      [10, -292],
      [-61, -269],
      [-305, -254],
      [-317, -135],
      [-209, -192],
      [-137, -78],
      [-575, -318],
      [-292, -242],
      [-251, -198],
      [-150, -119],
      [-286, -199],
      [-321, -197],
      [-473, -269],
      [-388, -267],
      [-218, -160],
      [-328, -203],
      [-314, -204],
      [-389, -255],
      [-330, -134],
      [-258, -214],
      [-168, -404],
      [-59, -54],
      [-79, 13],
      [-11, -1],
      [-3579, 2878],
      [-1652, 1139],
    ],
    [
      [59826, 8486],
      [-9, 3391],
      [-59, 26],
      [-229, 302],
      [-47, 63],
      [-520, 300],
      [-359, 278],
      [-195, 363],
      [-96, 335],
      [-13, 455],
      [176, 485],
      [203, 527],
      [52, 241],
    ],
    [
      [58730, 15252],
      [431, 117],
      [180, 98],
      [210, 115],
      [92, 50],
      [154, -16],
      [10, -2],
      [0, -18],
      [434, 119],
      [190, 962],
      [0, 481],
      [0, 577],
      [-95, 577],
      [-537, 407],
      [-178, 135],
      [-49, -17],
      [-55, -19],
    ],
    [
      [59517, 18818],
      [-322, 456],
      [-96, 673],
      [0, 193],
      [190, 673],
      [300, 757],
      [59, 150],
      [103, -54],
      [41, -23],
    ],
    [
      [59792, 21643],
      [1343, -780],
      [1722, -615],
      [552, 105],
      [1152, -15],
      [2151, -20],
      [879, 276],
    ],
    [
      [45271, 14374],
      [-99, -171],
      [37, -618],
      [165, -594],
      [163, -756],
      [-73, -786],
      [-8, -275],
    ],
    [
      [45456, 11174],
      [-29, 0],
      [-546, -86],
      [-648, -132],
      [-367, -218],
      [-468, -257],
      [-469, -257],
      [-556, -310],
      [-303, -389],
      [-280, -281],
      [-120, -351],
      [-263, -485],
      [-96, -522],
      [-49, -529],
      [84, -667],
      [281, -474],
      [192, -656],
      [220, -507],
      [209, -405],
      [133, -559],
      [247, -548],
      [358, -754],
      [237, -738],
      [243, -639],
      [93, -244],
      [-44, -645],
      [-533, -521],
      [-824, 376],
      [-58, 27],
      [-429, 129],
      [-506, 301],
      [-696, 223],
      [-736, 740],
      [-824, 592],
      [-506, -66],
      [-669, 212],
      [-262, 83],
      [-1538, 176],
      [-661, 331],
      [-973, 581],
      [-686, 426],
      [-694, 219],
    ],
    [
      [32920, 4350],
      [21, 49],
      [3, 1032],
      [-163, 1204],
      [-279, 215],
      [-187, 479],
      [3, 570],
      [183, 947],
      [416, 380],
      [505, 494],
      [952, 642],
      [594, 567],
      [436, 379],
      [206, 662],
      [-48, 796],
      [-39, 937],
      [236, 872],
      [-345, 685],
      [-620, 363],
      [-940, 91],
      [-753, -28],
      [-662, 236],
      [48, 481],
      [219, 234],
      [595, 607],
      [935, 45],
      [956, 86],
      [736, 218],
      [746, 367],
      [237, 560],
      [-37, 605],
      [-587, 1578],
      [-368, 902],
      [-329, 902],
      [-36, 369],
    ],
    [
      [35554, 22876],
      [507, 37],
      [411, 88],
      [866, -490],
      [312, -304],
      [386, -196],
      [756, -237],
      [938, -179],
      [882, -362],
      [1000, -112],
      [1368, -207],
      [434, -218],
      [585, -397],
      [559, -790],
      [387, -590],
      [91, -358],
    ],
    [
      [45036, 18561],
      [314, -1238],
      [5, -923],
      [-6, -828],
      [114, -865],
      [-192, -333],
    ],
    [
      [33332, 41139],
      [-25, -20],
      [-308, -269],
      [-124, -76],
      [-130, -63],
      [-14, -7],
      [-27, 12],
      [-68, 28],
      [-276, -276],
      [-558, -231],
      [-81, -61],
      [-57, -43],
      [-124, -138],
      [-80, -89],
      [-197, -213],
      [-135, -273],
      [-54, -156],
      [-62, -178],
      [-232, -282],
      [-254, -187],
      [-179, -160],
      [-136, -122],
      [-224, 30],
      [-232, 32],
      [-337, -134],
      [-84, -88],
      [-87, -91],
      [-186, -239],
      [-65, -83],
      [-104, -196],
      [-151, -283],
      [-89, -107],
      [-122, -147],
      [-76, -141],
      [-78, -146],
      [-157, -118],
      [-25, -52],
      [16, 0],
      [-51, -74],
      [-44, -93],
      [-30, 0],
      [-674, -709],
      [-432, -516],
      [-550, -357],
      [-623, -274],
      [-563, -316],
      [-156, -493],
      [-24, -414],
    ],
    [
      [25063, 33326],
      [-13, -231],
      [-331, -496],
      [-470, -399],
      [-471, -821],
      [-141, -446],
      [564, -892],
      [368, -135],
      [379, -237],
      [584, -58],
      [241, -45],
      [412, 122],
      [402, 340],
      [333, 639],
      [503, 304],
      [498, 39],
      [228, 72],
    ],
    [
      [28149, 31082],
      [137, 43],
      [192, -173],
      [133, -939],
      [22, -733],
      [-154, -765],
      [-23, -631],
      [-65, -698],
      [73, -429],
      [140, -539],
      [4, -183],
      [-45, -143],
    ],
    [
      [28563, 25892],
      [-1195, 402],
      [-449, 62],
      [-772, 81],
      [-766, 488],
      [-498, 382],
      [-1004, 242],
      [-919, 158],
      [-568, -174],
      [-1241, 164],
      [-1072, 629],
      [-942, 757],
      [-745, 501],
      [-531, 416],
      [-490, 491],
      [-298, 290],
    ],
    [
      [17073, 30781],
      [24, 63],
      [26, 414],
      [63, 101],
      [77, 182],
      [144, 202],
      [101, 460],
      [145, 222],
      [59, 291],
      [44, 257],
      [141, 413],
      [161, 364],
      [85, 712],
      [-71, 579],
      [51, 155],
      [111, 339],
      [-19, 828],
      [-5, 24],
      [-1, 12],
      [11, 32],
      [3, 25],
      [13, 115],
      [20, 0],
      [12, 0],
      [35, 102],
      [14, 388],
      [-65, 255],
      [-28, 105],
      [-31, 122],
      [-52, 246],
      [-66, 311],
      [3, 75],
      [20, 592],
      [-34, 332],
      [-6, 59],
      [-18, 167],
      [40, 251],
      [46, 283],
      [23, 148],
      [-12, 251],
      [-14, 281],
      [-11, 217],
      [169, 593],
      [194, 451],
      [29, 130],
      [120, 532],
      [248, 663],
      [154, 470],
      [81, 247],
      [221, 258],
      [26, 31],
      [158, 10],
      [327, 21],
      [35, 50],
      [166, 245],
      [109, 573],
      [-15, 45],
      [-45, 138],
      [-18, 395],
      [13, 10],
      [60, 45],
      [109, 75],
      [91, 63],
      [149, 122],
      [44, 36],
      [167, 131],
      [76, 10],
      [44, 5],
      [232, -66],
      [132, -162],
      [66, -183],
      [33, -211],
      [9, -138],
      [3, -52],
      [39, -143],
      [80, -136],
      [53, -74],
      [33, -22],
      [46, -31],
      [56, -51],
      [63, -57],
      [127, -19],
      [73, 0],
      [91, 42],
      [122, 55],
      [146, 77],
      [147, 131],
      [131, -19],
      [95, -13],
      [212, -32],
      [51, -29],
      [168, -92],
      [49, -35],
      [150, -105],
      [39, -156],
      [14, -15],
      [103, -119],
      [193, -72],
      [85, -32],
      [66, -60],
      [38, -47],
      [6, -7],
      [185, -79],
      [274, 25],
      [174, 70],
      [43, 6],
      [140, 17],
      [37, -8],
      [83, -17],
      [124, -68],
      [105, -57],
      [182, -181],
      [223, -125],
      [30, -20],
      [41, -27],
      [115, -18],
      [84, -13],
      [218, 22],
      [98, 10],
      [146, 43],
      [165, 125],
      [151, 138],
      [233, 207],
      [73, 181],
      [32, 78],
      [100, 77],
      [188, 144],
      [28, 208],
      [12, 92],
      [58, 117],
      [88, 177],
      [86, 242],
      [30, 85],
      [66, 131],
      [74, 150],
      [261, 214],
      [9, 97],
      [17, 174],
      [101, 92],
      [173, 157],
      [191, 235],
      [26, 33],
      [152, 124],
      [118, 36],
      [204, 3],
      [38, -8],
      [115, -23],
      [131, -26],
      [236, -125],
      [70, -73],
      [39, -41],
      [203, -187],
      [205, -214],
      [126, -229],
      [106, -76],
      [96, -70],
      [231, -173],
      [64, -42],
      [238, -157],
      [161, -418],
      [2, -64],
      [6, -181],
      [-24, -373],
      [-19, -181],
      [-8, -77],
      [7, -44],
      [29, -200],
      [204, -221],
      [198, -73],
      [137, -51],
      [342, -117],
      [182, -59],
      [138, -45],
      [65, -53],
      [160, -145],
      [76, -69],
      [77, -65],
      [195, -162],
      [198, -88],
      [12, -5],
      [294, -363],
      [103, -213],
      [88, -185],
      [44, -320],
      [28, -209],
      [11, -230],
      [15, -335],
    ],
    [
      [59826, 8486],
      [-319, 219],
      [-2, -329],
      [-200, 9],
      [-809, -47],
      [-855, -67],
      [-1318, 50],
      [-980, 146],
      [-980, 167],
      [-850, 272],
      [-1039, 222],
      [-363, 75],
      [-495, 178],
      [-675, 243],
      [-730, 250],
      [-563, 180],
      [-608, 208],
      [-476, 157],
      [-741, 346],
      [-461, 198],
      [-744, 156],
      [-518, 57],
      [-644, -2],
    ],
    [
      [45271, 14374],
      [824, -92],
      [421, -102],
      [605, 370],
      [613, -323],
      [356, -20],
      [817, 217],
      [426, 149],
      [571, -37],
      [562, -138],
      [617, -120],
      [293, 253],
      [538, 18],
      [638, -38],
      [404, 20],
      [470, 352],
      [288, 382],
      [450, -41],
      [681, 110],
      [524, -16],
      [563, -70],
      [751, -142],
      [869, 127],
      [480, 108],
      [609, -112],
      [89, 23],
    ],
    [
      [25063, 33326],
      [252, -79],
      [523, -77],
      [360, -135],
      [710, -175],
      [536, -166],
      [298, -242],
      [285, -249],
      [195, -452],
      [-20, -386],
      [-53, -283],
    ],
    [
      [34824, 36842],
      [265, 189],
      [288, 214],
      [219, 132],
      [82, 50],
    ],
    [
      [37815, 32370],
      [32, -770],
      [-73, -1200],
      [65, -993],
      [-219, -1082],
      [-115, -693],
    ],
    [
      [37505, 27632],
      [-19, -113],
      [-246, -323],
      [-235, -307],
      [-444, -854],
      [-452, -964],
      [-228, -810],
      [-383, -802],
      [56, -583],
    ],
    [
      [35554, 22876],
      [-247, -18],
      [-749, 251],
      [-753, 454],
      [-612, 397],
      [-652, 419],
      [-733, 379],
      [-1538, 549],
      [-629, 160],
      [-762, 319],
      [-316, 106],
    ],
    [
      [33332, 41139],
      [14, -285],
      [-26, -598],
      [-35, -720],
      [-70, -552],
      [9, -475],
      [-36, -490],
      [24, -373],
      [42, -136],
      [36, -142],
      [5, -47],
      [83, -33],
      [20, -265],
      [88, -101],
      [125, -141],
      [101, -114],
      [172, -66],
      [147, -29],
      [140, 0],
      [84, 0],
      [4, 1],
      [107, 27],
      [208, 71],
      [160, 109],
      [75, 50],
      [15, 12],
    ],
    [
      [61728, 36811],
      [69, -94],
      [2, -40],
      [284, -155],
      [251, -401],
      [-23, -412],
      [-279, -542],
      [-68, -430],
      [-181, -358],
      [-275, -440],
      [-370, -477],
      [-254, -213],
      [-195, -412],
      [-307, -318],
      [-191, -287],
      [-426, -287],
      [-67, -45],
      [-43, -9],
      [0, 7],
      [-145, -65],
      [-249, 31],
      [-229, -57],
      [-211, -105],
      [-136, -107],
      [-150, -126],
      [-191, -160],
      [-151, -195],
      [-91, -215],
      [-63, -163],
      [-110, -168],
      [-9, -122],
      [-104, -182],
      [-184, -180],
      [-16, -129],
      [-130, -140],
      [-130, -127],
      [-189, -58],
      [-149, -72],
      [-132, -250],
      [-51, -230],
      [-31, -257],
      [-85, -284],
      [100, -29],
      [-85, -264],
      [52, -144],
      [169, -349],
      [83, -321],
      [157, -241],
      [132, -186],
      [184, -295],
      [92, -104],
      [233, -140],
      [186, -186],
      [108, -369],
      [44, -218],
      [22, -327],
      [3, -218],
      [56, -266],
      [-36, -196],
      [-68, -407],
      [-50, -183],
      [-21, -475],
      [-48, -489],
      [118, -246],
      [334, -530],
      [226, -207],
      [334, -142],
      [280, -121],
      [266, -127],
      [62, -29],
      [26, -13],
      [114, -8],
      [0, -64],
    ],
    [
      [59517, 18818],
      [-93, -32],
      [-538, -38],
      [-913, 77],
      [-671, 65],
      [-795, -74],
      [-573, -92],
      [-779, 27],
      [-676, 208],
      [-298, 270],
      [-698, 501],
      [-503, 518],
      [-846, 469],
      [-723, 182],
      [-907, 56],
      [-887, -12],
      [-755, -95],
      [-325, -117],
      [-273, -308],
      [68, -340],
      [155, -404],
      [-89, -514],
      [-692, -395],
      [-689, -213],
      [-760, 0],
      [-705, 25],
      [-516, -21],
    ],
    [
      [37505, 27632],
      [142, -122],
      [256, -371],
      [-7, -428],
      [-93, -779],
      [-18, -1147],
      [-92, -711],
      [310, -793],
      [585, 37],
      [294, 280],
      [211, 167],
      [147, 364],
      [84, 236],
      [335, 347],
      [246, 200],
      [446, 161],
      [853, -21],
      [880, -56],
      [639, -11],
      [622, 248],
      [483, 365],
      [255, 396],
      [482, 250],
      [1055, -39],
      [535, 269],
      [1467, 50],
      [824, -190],
      [1011, 199],
      [464, 441],
      [567, 519],
      [563, 765],
      [106, 731],
      [-31, 585],
      [-294, 554],
      [-801, 313],
      [-630, 139],
      [-280, 141],
      [-97, 239],
      [-180, 136],
    ],
    [
      [48844, 31096],
      [197, 88],
      [669, 233],
      [836, 128],
      [798, -129],
      [894, -1],
      [969, 71],
      [448, 230],
      [1014, 811],
      [507, 575],
      [462, 698],
      [394, 659],
      [324, 965],
      [342, 762],
      [127, 339],
      [5, 14],
      [2, 34],
      [22, 381],
    ],
    [
      [56854, 36954],
      [167, -24],
      [499, -114],
      [333, -22],
      [317, -2],
      [524, 56],
      [374, -34],
      [526, -19],
      [-1, 110],
      [86, 56],
      [99, 35],
      [125, 45],
      [206, 122],
      [354, 178],
      [435, 93],
      [491, -268],
      [328, -340],
      [11, -15],
    ],
    [
      [32920, 4350],
      [-197, 61],
      [-1043, 399],
      [-649, 242],
      [-520, 309],
      [-126, 76],
      [-41, -53],
      [-275, 106],
      [-553, 323],
      [-1374, 322],
      [-928, 23],
      [-1706, 247],
      [-1294, 287],
      [-1558, 591],
      [-1079, 284],
      [-364, 16],
      [-1377, 62],
      [-1263, 144],
      [-988, 65],
      [-939, 233],
      [-745, 88],
      [-848, -20],
      [-802, -121],
      [-122, -19],
      [-647, -64],
      [-318, -37],
    ],
    [
      [13164, 7914],
      [1, 109],
      [-14, 374],
      [-55, 313],
      [-76, 334],
      [-104, 172],
      [-211, 282],
      [-64, 204],
      [-35, 354],
      [-63, 626],
      [-10, 238],
      [34, 467],
      [74, 440],
      [163, 473],
      [228, 417],
      [189, 472],
      [225, 580],
      [146, 343],
      [72, 291],
      [126, 263],
      [318, 178],
      [-135, 382],
      [119, 297],
      [-42, 313],
      [33, 332],
      [92, 297],
      [85, 277],
      [24, 237],
      [39, 373],
      [-29, 286],
      [-47, 421],
      [-101, 369],
      [-20, 435],
      [163, 554],
      [314, 279],
      [267, 335],
      [309, 348],
      [259, 287],
      [227, 275],
      [241, 342],
      [168, 479],
      [81, 379],
      [114, 412],
      [152, 269],
      [47, 393],
      [146, 303],
      [75, 515],
      [12, 305],
      [-51, 612],
      [-29, 286],
      [-210, 295],
      [-186, 139],
      [-93, 76],
      [-476, 110],
      [-95, -34],
    ],
    [
      [15561, 25822],
      [123, 417],
      [143, 542],
      [147, 357],
      [64, 183],
      [373, 170],
      [71, 692],
      [125, 202],
      [85, 284],
      [102, 501],
      [53, 359],
      [19, 340],
      [60, 400],
      [51, 257],
      [96, 255],
    ],
    [
      [45642, 32814],
      [-29, -349],
      [-62, -875],
      [756, -665],
      [715, -256],
      [822, 74],
      [757, 245],
      [243, 108],
    ],
    [
      [13164, 7914],
      [-592, -69],
      [-1062, -342],
      [-1033, -241],
      [-966, -235],
      [-810, -149],
      [-690, -199],
      [-696, -212],
      [-582, -221],
      [-631, -322],
      [-528, -215],
      [-374, -198],
      [-568, -215],
      [-332, -130],
      [-557, -316],
      [-643, -282],
      [-724, -252],
      [-611, -330],
      [-650, -627],
      [-286, -274],
      [-61, -22],
      [-400, -147],
      [-325, 264],
      [-43, 701],
      [125, 1072],
      [399, 1006],
      [760, 795],
      [18, 700],
      [39, 788],
      [-42, 735],
      [237, 934],
      [0, -13],
      [79, 318],
      [116, 541],
      [122, 454],
      [-99, 572],
      [88, 447],
      [1, 496],
      [-274, 501],
      [61, 455],
      [-33, 456],
      [-409, 524],
      [-74, 14],
      [-102, 356],
      [-219, 201],
      [-226, 167],
      [-210, 323],
      [-43, 252],
      [-131, 227],
      [0, 407],
      [262, 390],
      [220, 343],
      [244, 526],
      [118, 610],
      [164, 629],
      [4, 15],
      [152, 16],
      [694, 77],
      [414, 203],
      [431, 27],
      [801, 27],
      [804, 203],
      [620, 78],
      [553, 93],
      [769, 102],
      [643, 213],
      [271, 152],
      [454, 203],
      [385, 102],
      [608, 112],
      [715, 151],
      [474, 141],
      [225, 207],
      [399, 149],
      [428, 224],
      [428, 244],
      [353, 205],
      [408, 285],
      [487, 128],
      [549, 282],
      [314, 246],
      [166, 297],
      [316, 443],
      [262, 376],
      [261, 362],
      [459, 462],
      [283, 498],
      [7, -1],
      [200, 283],
      [33, 110],
    ],
    [
      [45562, 36460],
      [60, 4],
      [124, 9],
      [386, 75],
      [75, 24],
      [299, 98],
      [178, 156],
      [209, 182],
      [215, 336],
      [260, 493],
      [220, 437],
      [416, 671],
      [444, 618],
      [280, 554],
      [339, 711],
      [110, 401],
      [253, 452],
      [267, 540],
      [243, 262],
      [270, 4],
      [2, -5],
      [134, -264],
      [394, -366],
      [265, -145],
      [333, -56],
      [364, 67],
      [444, -60],
      [347, -96],
      [347, -137],
      [287, -172],
      [130, -283],
      [85, -500],
      [43, -467],
      [-68, -388],
      [105, -479],
      [166, -357],
      [193, -363],
      [314, -531],
      [235, -362],
      [481, -236],
      [180, -31],
      [356, -198],
      [691, -9],
      [616, -64],
      [200, -31],
    ],
  ],
  transform: {
    scale: [0.00003431227617417584, 0.000033939679636384404],
    translate: [6.9371555598226005, 7.762828470202471],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3]],
          type: "Polygon",
          properties: {
            name: "Akwanga",
            id: "NS001",
            nameAbbr: "Akwanga",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.0634808316,
            Shape_Area: 0.06944337648,
          },
        },
        {
          arcs: [[4, 5, 6, 7, 8]],
          type: "Polygon",
          properties: {
            name: "Awe",
            id: "NS002",
            nameAbbr: "Awe",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.3580152503,
            Shape_Area: 0.21475157901,
          },
        },
        {
          arcs: [[9, 10, 11, 12, 13]],
          type: "Polygon",
          properties: {
            name: "Doma",
            id: "NS003",
            nameAbbr: "Doma",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.39773447641,
            Shape_Area: 0.21287379698,
          },
        },
        {
          arcs: [[14, 15, 16, 17, 18]],
          type: "Polygon",
          properties: {
            name: "Karu",
            id: "NS004",
            nameAbbr: "Karu",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.58017112584,
            Shape_Area: 0.21761840411,
          },
        },
        {
          arcs: [[-6, 19, -10, 20]],
          type: "Polygon",
          properties: {
            name: "Keana",
            id: "NS005",
            nameAbbr: "Keana",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.37492185756,
            Shape_Area: 0.08623104828,
          },
        },
        {
          arcs: [[21, -16]],
          type: "Polygon",
          properties: {
            name: "Keffi",
            id: "NS006",
            nameAbbr: "Keffi",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.44353367717,
            Shape_Area: 0.01294810424,
          },
        },
        {
          arcs: [[22, -3, 23, 24, 25, -17, -22, -15, 26]],
          type: "Polygon",
          properties: {
            name: "Kokona",
            id: "NS007",
            nameAbbr: "Kokona",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.78467240789,
            Shape_Area: 0.14686460778,
          },
        },
        {
          arcs: [[27, -8, 28, -13, -25, 29, 30, 31]],
          type: "Polygon",
          properties: {
            name: "Lafia",
            id: "NS008",
            nameAbbr: "Lafia",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.30041492075,
            Shape_Area: 0.22775654315,
          },
        },
        {
          arcs: [[-26, -12, 32, 33, 34, -18]],
          type: "Polygon",
          properties: {
            name: "Nasarawa",
            id: "NS009",
            nameAbbr: "Nasarawa",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.16084636899,
            Shape_Area: 0.47461927908,
          },
        },
        {
          arcs: [[35, -30, -24, -2]],
          type: "Polygon",
          properties: {
            name: "Nasarawa Egon",
            id: "NS010",
            nameAbbr: "Nasarawa Egon",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.42077243621,
            Shape_Area: 0.09708604674,
          },
        },
        {
          arcs: [[-7, -21, -14, -29]],
          type: "Polygon",
          properties: {
            name: "Obi",
            id: "NS011",
            nameAbbr: "Obi",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.39084856408,
            Shape_Area: 0.08110422858,
          },
        },
        {
          arcs: [[36, -34]],
          type: "Polygon",
          properties: {
            name: "Toto",
            id: "NS012",
            nameAbbr: "Toto",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.33092910131,
            Shape_Area: 0.2237359473,
          },
        },
        {
          arcs: [[-31, -36, -1, 37]],
          type: "Polygon",
          properties: {
            name: "Wamba",
            id: "NS013",
            nameAbbr: "Wamba",
            parent: "Nasarawa",
            parentAbbr: "NS",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.29251494204,
            Shape_Area: 0.0969207651,
          },
        },
      ],
    },
  },
};

const taraba = {
  type: "Topology",
  arcs: [
    [
      [63367, 73768],
      [103, -273],
      [366, -662],
      [277, -366],
      [288, -515],
      [342, -359],
      [327, -528],
      [309, -217],
      [801, -447],
      [414, -256],
      [683, -509],
      [340, -245],
      [805, -580],
      [347, -144],
      [363, -163],
      [377, -406],
      [284, -249],
      [351, -263],
      [549, -343],
      [230, -53],
      [291, -62],
    ],
    [
      [71214, 67128],
      [58, -99],
      [226, -489],
      [71, -375],
      [303, -611],
      [105, -333],
      [342, -543],
      [383, -495],
      [302, -414],
      [41, -326],
      [190, -258],
    ],
    [
      [73235, 63185],
      [-122, -8],
      [-457, 18],
      [-483, -51],
      [-539, -57],
      [-680, -127],
      [-479, -139],
      [-605, -45],
      [-602, -92],
      [-380, -50],
      [-457, -146],
      [-489, -249],
      [-357, -111],
      [-391, -146],
      [-169, -151],
      [46, -109],
      [-889, -108],
      [-429, -105],
      [-347, -194],
      [-219, -151],
      [-467, -160],
      [-333, -50],
      [-527, -113],
      [-513, -25],
      [-477, 220],
      [-491, 227],
      [-402, 276],
      [-845, 602],
      [-578, 382],
      [-229, 194],
    ],
    [
      [60325, 62717],
      [-349, 297],
      [-694, 468],
      [-666, 259],
      [-460, 263],
      [-452, 377],
      [-201, 469],
      [-395, 514],
      [-464, 222],
      [-893, 573],
      [-530, 430],
      [-847, 331],
      [-697, 401],
      [-83, 255],
      [-521, 511],
      [-112, 187],
    ],
    [
      [52961, 68274],
      [228, 402],
      [418, 904],
      [252, 867],
      [115, 704],
      [119, 427],
      [355, 862],
      [297, 727],
      [136, 596],
      [3, 394],
      [33, 94],
      [180, 0],
      [0, 49],
      [140, 247],
      [241, 426],
      [761, 481],
      [380, 288],
      [762, 193],
      [722, 244],
      [11, 18],
      [322, 294],
      [490, 423],
      [412, 241],
    ],
    [
      [59338, 77155],
      [23, -85],
      [33, -262],
      [-13, -296],
      [43, -165],
      [256, -408],
      [130, -262],
      [376, -388],
      [213, -318],
      [208, -207],
      [209, -162],
      [87, -67],
      [320, -195],
      [54, -46],
      [-36, -1],
      [91, -53],
      [137, -25],
      [356, -226],
      [146, -85],
      [372, -89],
      [353, -35],
      [373, -21],
      [298, 9],
    ],
    [
      [80392, 62512],
      [70, -435],
      [-204, -982],
      [-790, -584],
      [-361, -248],
      [-886, -836],
      [-845, -809],
      [-787, -1019],
      [-407, -562],
      [-641, -1013],
      [-367, -464],
      [-391, -495],
      [-897, -1138],
      [-576, -767],
      [-539, -508],
      [-519, -508],
      [-1330, -1584],
      [-892, -790],
      [-901, -735],
      [-1089, -1214],
      [-855, -1055],
      [-310, -593],
    ],
    [
      [66875, 46173],
      [-170, -31],
      [-575, -10],
      [-674, -93],
      [-539, -4],
      [-597, -11],
      [-676, -18],
      [-729, -196],
      [-662, -236],
      [-569, -235],
      [-497, -249],
      [-452, -317],
      [-383, -179],
      [-376, -228],
      [-435, -291],
      [-508, -328],
      [-303, -268],
      [-285, -330],
      [-652, -459],
      [-231, -412],
      [-342, -514],
      [-304, -457],
      [-79, -394],
      [-114, -374],
      [-50, -93],
      [-243, -216],
      [-189, -314],
      [-422, -355],
      [-262, -42],
      [-433, -201],
      [-608, -304],
      [-298, -134],
      [-368, -227],
      [-39, -254],
      [-98, -296],
      [24, -161],
      [112, -319],
      [-39, -239],
      [-61, -240],
      [-117, -339],
      [-567, -289],
      [-550, -421],
      [-393, -277],
      [-9, -77],
      [-108, -59],
      [-289, -163],
      [-278, -119],
      [-317, -366],
      [-193, -187],
    ],
    [
      [50928, 34847],
      [-114, -18],
      [-102, -45],
      [-294, -183],
      [-200, -138],
      [-112, -102],
      [-80, -51],
      [-98, 5],
      [-50, 19],
      [-108, 53],
      [-87, 47],
      [-163, 30],
      [-223, 8],
      [-204, 16],
      [-225, -77],
      [-194, -117],
      [-224, -195],
      [-140, -201],
      [-180, -19],
      [-104, -155],
      [-141, -103],
      [-144, 19],
      [-92, -56],
      [-277, -98],
      [-305, -126],
      [-447, -209],
      [-515, -52],
      [-214, -77],
      [-277, -85],
      [-248, -125],
      [-7, 0],
      [-164, -63],
      [-240, -132],
      [-237, -2],
      [-255, -125],
      [-255, -112],
      [10, -123],
      [-77, -62],
      [-155, -104],
      [-226, -118],
      [-283, -153],
      [-240, -146],
      [-219, -125],
      [-269, -152],
      [-134, -70],
      [-269, -132],
      [7, 0],
      [-241, -112],
      [-262, -125],
      [-377, -145],
      [-237, -38],
      [-194, 19],
      [-214, -80],
      [-329, -107],
      [-305, 100],
      [-151, -13],
      [-240, 103],
      [-293, 164],
      [-255, 116],
      [-155, 150],
      [-294, 235],
      [-99, 223],
      [-231, 137],
      [-131, 150],
      [-55, 11],
      [-117, 221],
      [-267, 327],
      [-178, 317],
      [-274, 390],
      [-136, 404],
      [-184, 416],
      [-129, 333],
      [-88, 208],
      [-131, 199],
      [-88, 215],
      [-74, 265],
      [-165, 169],
      [57, 284],
      [43, 124],
      [381, 174],
      [249, 78],
      [334, 372],
      [334, 351],
      [243, 235],
      [49, 164],
      [106, 288],
      [-47, 300],
      [237, 384],
      [77, 213],
      [14, 41],
      [77, 205],
      [111, 124],
      [-40, 388],
      [14, 89],
      [-116, 381],
      [-111, 169],
      [-138, 128],
      [-97, 81],
      [-110, 176],
      [-21, 170],
      [50, 287],
      [91, 431],
      [-138, 399],
      [-340, 639],
      [-203, 569],
      [28, 197],
    ],
    [
      [37593, 42886],
      [349, 80],
      [477, 212],
      [566, 256],
      [260, 99],
      [176, -42],
      [326, 421],
      [389, 375],
      [427, 480],
      [455, 349],
      [208, 265],
      [287, 315],
      [402, 320],
      [220, 278],
      [370, 479],
      [346, 172],
      [160, 366],
      [455, 349],
      [226, 300],
      [316, 310],
      [247, 293],
      [303, 309],
      [449, 412],
      [225, 230],
      [446, 370],
      [411, 230],
      [232, 168],
      [369, 152],
      [552, 581],
      [437, 259],
      [401, 257],
      [314, 261],
      [299, 81],
      [292, 412],
      [514, 267],
      [253, 193],
      [60, 204],
      [341, 73],
      [33, 54],
      [376, 222],
      [133, 103],
      [231, 179],
      [99, 56],
      [302, 227],
      [202, 64],
      [424, 486],
      [138, 144],
      [450, 439],
      [573, 467],
      [327, 289],
      [92, 265],
      [118, -5],
      [395, 384],
      [380, 255],
      [550, 373],
      [277, 153],
      [522, 352],
      [552, 340],
      [587, 259],
      [652, 273],
      [388, 141],
      [163, 320],
      [185, 212],
      [169, 225],
      [434, 446],
      [186, 198],
      [180, 388],
      [41, 270],
      [181, 266],
      [88, 440],
      [187, 401],
      [145, 367],
      [248, 375],
      [119, 197],
      [45, 70],
    ],
    [
      [73235, 63185],
      [460, 30],
      [522, 160],
      [71, 184],
      [367, 91],
      [390, 43],
      [377, 29],
      [493, -38],
      [358, -32],
      [512, 17],
      [352, -66],
      [453, -93],
      [242, -182],
      [187, -33],
      [289, -107],
      [442, -188],
      [478, -175],
      [453, -113],
      [412, -154],
      [273, -39],
      [26, -7],
    ],
    [
      [50928, 34847],
      [-134, -130],
      [-287, -99],
      [-368, -340],
      [-302, -267],
      [-287, -295],
      [-380, -382],
      [-474, -424],
      [-456, -380],
      [-407, -325],
      [-379, -297],
      [-301, -276],
      [-437, -311],
      [-412, -400],
      [-279, -269],
      [-244, -255],
      [-309, -263],
      [-399, -331],
      [-295, -270],
      [-415, -283],
      [-311, -413],
      [-237, -263],
      [-394, -358],
      [-227, -158],
      [-613, -510],
      [-178, -313],
      [-188, -277],
      [-283, -169],
      [-268, -217],
      [-218, -177],
      [-91, -85],
      [-318, -164],
      [-126, -340],
      [-83, 137],
      [-425, -222],
      [-314, -295],
      [-139, -161],
      [-203, -155],
      [-275, -419],
      [-71, -277],
      [-121, -250],
      [-127, -333],
      [48, -138],
      [-78, -284],
      [-313, -585],
      [-445, 6],
      [-469, 82],
      [-502, -16],
      [-341, -130],
      [-455, -146],
      [-293, -53],
      [-321, 166],
      [-341, 132],
      [-298, 126],
      [-155, 128],
      [-172, 246],
      [-166, 293],
      [-93, -29],
      [-42, -58],
    ],
    [
      [34717, 23571],
      [-31, 105],
      [-131, 308],
      [21, 242],
      [-81, 309],
      [-25, 351],
      [-35, 227],
      [34, 311],
      [79, 222],
      [84, 332],
      [21, 249],
      [-22, 262],
      [20, 303],
      [-152, 274],
      [111, 188],
      [1, 110],
      [-172, 189],
      [-40, 295],
      [-20, 282],
      [43, 214],
      [-96, 232],
      [-227, 189],
      [-234, 160],
      [-207, 99],
      [-338, 97],
      [-235, 92],
      [-311, 69],
      [-310, 173],
      [-268, 270],
      [-331, 69],
      [-151, 231],
      [-117, 122],
      [-131, 121],
      [-103, 95],
      [-92, 92],
      [-3, 5],
      [-250, 109],
      [-89, 280],
      [-192, 183],
      [-249, 174],
      [-284, 152],
      [-290, 235],
      [-145, 108],
      [-339, 233],
      [-339, 255],
      [-276, 222],
      [-439, 94],
      [-127, 66],
      [-188, 140],
      [-244, 171],
      [-234, 123],
      [-340, 66],
      [-289, 67],
      [-319, 67],
      [-302, -49],
      [-241, 41],
      [-183, 49],
      [-266, 55],
      [-211, 35],
      [-363, -24],
      [-254, 7],
      [-183, 43],
      [-463, 8],
      [-386, -11],
      [-447, -18],
      [-85, -57],
      [-344, 7],
      [-407, -45],
      [-470, -109],
      [-225, 12],
      [-266, -213],
      [-182, -57],
      [-115, -62],
      [-203, -74],
      [-190, -144],
      [-12, -1],
    ],
    [
      [20837, 32598],
      [-226, 168],
      [-493, 446],
      [-379, 388],
      [-93, 87],
    ],
    [
      [19646, 33687],
      [10, 6],
      [129, 110],
      [287, 241],
      [376, 290],
      [568, 414],
      [232, 220],
      [349, 306],
      [294, 322],
      [370, 185],
      [418, 271],
      [448, 381],
      [430, 230],
      [398, 345],
      [446, 306],
      [481, 334],
      [163, 120],
      [211, 163],
      [673, 550],
      [258, 130],
      [425, 749],
      [719, 470],
      [238, 223],
      [311, 333],
      [290, 331],
      [464, 495],
      [716, 530],
      [355, 341],
      [396, 386],
      [562, 450],
      [643, 465],
      [253, 485],
      [732, 561],
      [225, 464],
      [247, 275],
      [115, 316],
      [215, 271],
      [81, 287],
      [383, 320],
      [95, 301],
      [289, 309],
      [318, 261],
      [431, 273],
      [255, 113],
      [583, 160],
      [330, 26],
      [324, 19],
      [353, 13],
      [208, 36],
    ],
    [
      [36743, 47874],
      [-47, -168],
      [-121, -206],
      [131, -550],
      [-71, -196],
      [-86, -253],
      [323, -772],
      [53, -206],
      [104, -169],
      [68, -102],
      [26, -172],
      [51, -345],
      [74, -428],
      [79, -406],
      [93, -330],
      [50, -469],
      [75, -227],
      [48, 11],
    ],
    [
      [81829, 27752],
      [-352, -349],
      [-114, -112],
      [-66, -66],
      [-18, -18],
      [-18, -35],
      [-89, -176],
      [-117, -51],
      [-24, -10],
      [-1148, -1291],
      [-380, -495],
      [-185, -302],
      [-15, -24],
      [-5, -12],
      [-115, -269],
      [-73, -173],
      [-121, -143],
      [-65, -251],
      [-119, -460],
      [-232, -678],
      [-27, -248],
      [-38, -341],
      [5, -101],
      [24, -508],
      [39, -268],
      [30, -213],
      [10, -16],
      [65, -105],
      [3, -53],
      [1, -10],
      [47, -669],
      [20, -277],
      [-3, -2],
      [128, -589],
      [46, -207],
      [236, -718],
      [22, -66],
      [190, -356],
      [158, -296],
      [8, -13],
      [110, -207],
      [41, -34],
      [66, -54],
      [55, -46],
      [41, -244],
      [-1, -5],
      [-27, -153],
      [-5, -31],
      [-64, -126],
      [-12, -23],
      [-17, -33],
      [-155, -172],
      [-1, 0],
      [-119, -46],
      [-88, -34],
      [-1, 0],
      [-3, -3],
      [-58, -57],
      [-37, -35],
      [-630, -57],
      [-6, 0],
      [-864, 184],
      [-45, 10],
      [-94, 20],
      [-262, 8],
      [-36, -1],
      [-75, -57],
      [-191, -146],
      [-16, -13],
      [-68, -8],
      [-62, -12],
      [-39, -8],
      [-2, 1],
      [-3, 1],
      [-242, 92],
      [-104, -61],
      [-56, -34],
      [-59, 0],
      [-337, -2],
      [-61, -86],
      [-26, -36],
      [0, -5],
      [0, -44],
      [2, -526],
      [1, -78],
    ],
    [
      [75987, 15620],
      [-692, 692],
      [-150, 212],
      [-230, 252],
      [-43, 279],
      [-117, 262],
      [-108, 164],
      [-242, 84],
      [-317, 229],
      [-140, -11],
      [-194, 57],
      [-202, 85],
      [-188, 100],
      [-215, 21],
      [-140, 38],
      [-262, 63],
      [-180, -75],
      [-220, 0],
      [-461, -69],
      [-187, -33],
      [-306, -135],
      [-279, -85],
      [-293, -127],
      [-274, -50],
      [-346, -129],
      [-406, -131],
      [-327, -86],
      [-426, -145],
      [-432, -68],
      [-366, -45],
      [-368, -119],
      [-387, -79],
      [-316, -242],
      [-690, 212],
      [-575, 152],
      [-603, 411],
      [-159, 258],
      [-38, 116],
      [-444, 412],
      [-420, 563],
      [-153, 231],
      [-368, 420],
      [-630, 145],
      [-283, 502],
      [-188, 197],
      [-95, 54],
      [-14, 6],
      [-475, 276],
      [-168, 176],
      [-382, 112],
      [-61, 54],
      [-255, 565],
      [-109, 286],
      [-71, 204],
      [86, 424],
      [9, 362],
      [41, 240],
      [42, 301],
      [31, 164],
      [39, 210],
      [89, 366],
      [-83, 299],
      [-3, 244],
      [-124, 297],
      [-103, 256],
      [-11, 336],
      [-16, 244],
      [-84, 340],
      [-82, 137],
      [-255, 140],
      [-221, 42],
      [-382, 59],
      [-267, -1],
      [-167, -67],
      [-321, -10],
      [-166, -95],
      [-119, -109],
      [-251, -224],
      [-253, -209],
      [-191, -179],
      [-314, -160],
      [-458, -161],
      [-307, -160],
      [-182, -132],
      [-244, 162],
      [-81, 95],
      [-20, 1],
    ],
    [
      [56624, 25093],
      [-168, 202],
      [-368, 420],
      [-97, 231],
      [-133, 204],
      [-13, 293],
      [-325, 496],
      [-70, 232],
      [-128, 415],
      [41, 246],
      [-223, 463],
      [-19, 165],
      [4, 169],
      [-93, 549],
      [77, 289],
      [-104, 326],
      [-58, 466],
      [23, 302],
      [-149, 346],
      [-138, 290],
      [-157, 59],
      [-128, 458],
      [-162, 129],
      [38, 162],
      [-294, 321],
      [-127, 137],
      [-302, 229],
      [-256, 182],
      [-314, 314],
      [-274, 237],
      [-285, 195],
      [-95, 180],
      [-176, 226],
      [-136, 150],
      [-259, 224],
      [-150, 157],
      [-184, 142],
      [-201, 85],
      [-99, 26],
      [-87, 53],
      [-107, -16],
    ],
    [
      [66875, 46173],
      [-80, -153],
      [269, -605],
      [546, -1271],
      [180, -769],
      [195, -551],
      [1041, -1568],
      [328, -659],
      [990, -934],
      [283, -229],
      [363, 159],
      [429, 317],
      [149, 150],
      [340, 316],
      [286, 397],
      [320, 364],
      [139, 314],
      [266, 384],
      [322, 445],
      [770, 182],
      [671, -206],
      [717, -534],
      [364, -610],
      [811, -881],
      [940, -723],
      [645, -663],
      [451, -440],
      [1009, -1233],
      [492, -958],
      [-97, -1063],
      [-94, -724],
      [-84, -818],
      [351, -822],
      [74, -592],
      [103, -1090],
      [293, -673],
      [393, -781],
      [229, -741],
      [-26, -743],
      [576, -415],
    ],
    [
      [36743, 47874],
      [19, 70],
      [-205, 568],
      [12, 83],
      [-142, 404],
      [-19, 103],
      [-165, 230],
      [-203, 304],
      [-181, 174],
      [-279, 316],
      [-243, 330],
      [-223, 290],
      [-167, 142],
      [-320, 123],
      [-328, 130],
      [-317, 61],
      [-427, 274],
      [-308, 130],
      [-229, -125],
      [-400, -179],
      [-475, -167],
      [-281, -155],
      [-234, -7],
      [-664, -298],
      [-365, -74],
      [-202, 15],
      [-240, -13],
      [-311, -101],
      [-446, 92],
      [-271, -84],
      [-380, -179],
      [-194, -5],
      [-122, 17],
      [-67, 5],
      [-432, 85],
      [-63, 54],
      [-370, 192],
      [-44, 89],
      [-525, 369],
      [-79, 109],
      [-706, 525],
      [-44, 82],
      [-90, 74],
      [-89, 40],
      [-90, 74],
      [-358, 292],
    ],
    [
      [25476, 52333],
      [489, 389],
      [670, 599],
      [398, 409],
      [540, 665],
      [512, 699],
      [243, 398],
      [355, 694],
      [168, 703],
      [151, 367],
      [270, 621],
      [387, 402],
      [677, 550],
      [233, 182],
      [383, 555],
      [476, 495],
      [270, 231],
      [152, 270],
      [62, 176],
      [23, 259],
      [96, 274],
      [172, 361],
      [410, 375],
      [814, 735],
      [340, 485],
      [486, 463],
      [261, 178],
      [637, 94],
      [814, 68],
      [1209, 233],
      [791, 184],
    ],
    [
      [37965, 64447],
      [88, 20],
      [1107, 152],
      [718, 203],
      [385, 268],
      [522, 167],
      [1053, 56],
      [904, 272],
      [725, 121],
      [1341, -18],
      [602, -125],
      [740, -157],
      [1030, -13],
      [1276, 97],
      [817, 155],
      [693, 207],
      [1136, 530],
      [721, 349],
      [520, 494],
      [398, 661],
      [220, 388],
    ],
    [
      [25476, 52333],
      [-93, -75],
      [-368, -339],
      [-409, -478],
      [-323, -119],
      [-687, -429],
      [-559, -830],
      [-154, -724],
      [-53, -335],
      [-327, -536],
      [-466, -763],
      [-433, -614],
      [-433, -536],
      [-475, -495],
      [-433, -282],
      [-413, -35],
      [-529, -292],
      [-365, -34],
      [-871, 321],
      [-187, 58],
      [-387, 192],
      [-669, 71],
      [-415, 150],
      [-317, 8],
      [-332, 125],
      [-895, -72],
      [-284, 119],
      [-574, 2],
      [-447, -77],
      [-921, -237],
      [-783, -227],
      [-587, -214],
      [-541, -344],
      [-465, -261],
      [-492, -311],
      [-561, -337],
      [-879, -219],
      [-651, -115],
      [-888, 36],
      [-381, 117],
      [-690, -14],
      [-7, 7],
      [-176, 77],
    ],
    [
      [5556, 44272],
      [250, 197],
      [292, 243],
      [575, 317],
      [137, 79],
      [210, 191],
      [316, 136],
      [305, 254],
      [61, 269],
      [-10, 292],
      [-128, 261],
      [-166, 382],
      [-115, 286],
      [34, 29],
      [-42, 195],
      [-52, 121],
      [254, 306],
      [-33, 357],
      [-68, 369],
      [-20, 191],
      [-10, 301],
      [-33, 150],
      [-327, 388],
      [-141, 236],
      [-211, 255],
      [-276, 307],
      [-334, 295],
      [-473, 353],
      [184, 363],
      [72, 283],
      [17, 364],
      [71, 345],
      [271, 392],
      [125, 203],
      [231, 418],
      [603, 409],
      [259, 34],
      [74, -59],
      [543, 28],
      [206, -177],
      [264, -150],
      [345, -100],
      [288, -259],
      [183, -249],
      [167, 39],
      [549, 70],
      [153, -194],
      [492, -82],
      [502, -192],
      [736, -68],
      [395, -113],
      [521, 97],
      [424, 74],
      [158, 82],
      [185, 137],
      [177, 165],
      [205, 201],
      [180, 306],
      [153, 276],
      [147, 247],
      [188, 279],
      [166, 271],
      [8, -15],
      [1802, -105],
      [754, 124],
      [1182, 135],
      [411, 91],
      [1666, 386],
      [1146, 564],
      [799, 937],
      [1649, 1660],
      [1167, 1032],
      [1667, 914],
      [1120, 579],
      [557, 287],
      [989, 1340],
      [1246, 1369],
      [592, 1357],
      [374, 627],
      [1174, 681],
      [1696, 355],
    ],
    [
      [34784, 66790],
      [53, -82],
      [178, -290],
      [399, -377],
      [247, -249],
      [432, -302],
      [309, 23],
      [401, -208],
      [203, -208],
      [420, -370],
      [149, -46],
      [230, -161],
      [160, -73],
    ],
    [
      [66406, 73040],
      [21, -26],
      [248, -374],
      [191, -293],
      [276, -448],
      [270, -272],
      [179, -234],
      [245, -421],
      [268, -265],
      [343, -366],
      [203, -172],
      [134, -126],
      [256, -279],
      [214, -220],
      [359, -378],
      [154, -207],
      [279, -198],
      [169, -215],
      [393, -290],
      [241, -304],
      [114, -326],
      [179, -373],
      [72, -125],
    ],
    [
      [63367, 73768],
      [168, 5],
      [417, -8],
      [194, -57],
      [413, 46],
      [283, 18],
      [458, 14],
      [125, -5],
      [250, -111],
      [421, -256],
      [310, -374],
    ],
    [
      [57135, 90867],
      [37, -17],
      [244, -85],
      [636, -192],
      [42, -1],
      [294, -31],
      [428, -6],
      [1410, 184],
      [869, 49],
      [498, 48],
      [1262, 31],
      [1387, -67],
      [1666, 24],
      [734, 1],
      [820, 2],
      [1622, 57],
      [1270, 158],
      [107, 9],
      [303, -736],
      [638, -1131],
      [575, -933],
      [459, -851],
      [591, -1159],
      [530, -705],
      [154, -204],
      [316, -26],
      [128, -14],
    ],
    [
      [74155, 85272],
      [-127, -88],
      [-92, -114],
      [-595, -208],
      [-487, -203],
      [-603, -222],
      [-364, -347],
      [-221, -215],
      [-399, -306],
      [-619, -392],
      [-231, -241],
      [-785, -398],
      [-184, -127],
      [-819, -376],
      [-226, -174],
      [-661, -474],
      [-471, -300],
      [-349, -266],
      [-489, -644],
      [-608, -361],
      [-207, 1],
      [-501, -471],
      [-543, -210],
      [-973, -386],
      [-739, -491],
      [-931, -104],
      [-593, -272],
      [-957, -228],
      [-583, -319],
      [-428, -163],
      [-32, -18],
    ],
    [
      [34784, 66790],
      [29, 6],
      [1830, 402],
      [928, 232],
      [895, -54],
      [1880, 803],
      [2082, 1062],
      [1771, 911],
      [839, 1459],
      [41, 910],
      [-148, 1309],
      [6, 402],
      [-199, -1],
      [-39, 284],
      [-107, 779],
      [263, 632],
      [-116, 1101],
      [-275, 796],
      [-104, 951],
      [-433, 792],
      [-651, 1031],
      [-39, 872],
      [-319, 1946],
      [174, 712],
      [66, 1218],
      [-24, 240],
      [-76, 775],
      [-194, 827],
      [-336, 673],
      [-149, 254],
      [-54, 108],
      [263, 249],
      [1087, 601],
      [809, 403],
      [649, -3],
      [1107, -293],
      [998, -73],
      [1072, 531],
      [811, 360],
      [1178, 578],
      [1090, 420],
      [837, 401],
      [799, -32],
      [1049, -246],
      [1184, -234],
      [1107, -91],
      [526, 7],
      [244, 67],
    ],
    [
      [56624, 25093],
      [46, -56],
      [162, -429],
      [-14, -567],
      [167, -449],
      [244, -455],
      [-63, -301],
      [-75, -254],
      [-23, -382],
      [-25, -151],
      [-18, -171],
      [2, -471],
      [-76, -547],
      [-177, -494],
      [-19, -498],
      [-74, -172],
      [-167, -643],
      [-247, -508],
      [-208, -610],
      [-452, -421],
      [-225, -426],
      [-312, -304],
      [-256, -281],
      [-332, -284],
      [-271, -310],
      [-333, -319],
      [-297, -254],
      [-191, -165],
      [-205, -170],
      [-285, -397],
      [-303, -295],
      [-198, -231],
      [-163, -189],
      [-232, -218],
      [-276, -281],
      [-182, -134],
      [-71, -84],
      [-251, -228],
      [-611, -267],
    ],
    [
      [50613, 12677],
      [-1, 4],
      [-2, 6],
      [-69, 83],
      [-21, 26],
      [-3, 2],
      [-7, 9],
      [-1, 1],
      [-96, 48],
      [-488, 242],
      [-134, 32],
      [-6, 5],
      [-169, 134],
      [-6, 5],
      [-33, 45],
      [-3, 4],
      [-112, 156],
      [-48, 65],
      [-4, 2],
      [-28, 10],
      [-21, 8],
      [-99, -48],
      [-2, -1],
      [-85, -38],
      [-4, 0],
      [-106, 17],
      [-136, 148],
      [-51, 22],
      [-1, 1],
      [-53, 20],
      [-165, -27],
      [-119, -113],
      [-7, -7],
      [-4, -3],
      [-2, -9],
      [-37, -174],
      [-73, -30],
      [-1, 0],
      [-41, -16],
      [-161, 269],
      [-302, 182],
      [-114, 203],
      [-220, 368],
      [-130, 61],
      [-146, -8],
      [-3, 0],
      [-33, -2],
      [-173, -11],
      [-253, 194],
      [-117, 93],
      [-14, 24],
      [-109, 200],
      [-5, 11],
      [-56, 105],
      [-4, 4],
      [-1, 0],
      [-145, 133],
      [-3, 0],
      [-123, 16],
      [-51, 6],
      [-87, 11],
      [-15, 1],
      [-19, 0],
      [-67, 2],
      [-116, 238],
      [1, 5],
      [13, 372],
      [-66, 104],
      [-2, 2],
      [-341, 174],
      [-246, 125],
      [-3, 0],
      [-66, -178],
      [-121, -39],
      [-524, 161],
      [-8, 0],
      [-158, 8],
      [-872, 752],
      [224, 656],
      [0, 0],
      [6, 17],
      [8, 154],
      [0, 1],
      [7, 87],
      [-178, 416],
      [-2, 139],
      [-1, 116],
      [-2, 84],
      [-1, 95],
      [-2, 146],
      [-70, 79],
      [-138, 157],
      [-148, 51],
      [-87, 30],
      [-135, 210],
      [-201, 94],
      [-74, -109],
      [-58, -366],
      [-41, -263],
      [-50, -598],
      [-37, -440],
      [-17, -194],
      [33, -128],
      [14, -55],
      [-24, -70],
      [-9, -25],
      [-94, -14],
      [36, -629],
      [-80, -386],
      [82, -359],
      [-168, -736],
      [-104, -462],
      [-9, -73],
      [0, -4],
      [-44, -357],
      [-47, -158],
      [-6, -18],
      [-168, -176],
      [-12, -14],
      [-13, -51],
      [-40, -172],
      [-46, -643],
      [-131, -110],
      [-129, -107],
      [-99, -165],
      [-55, -93],
      [-409, -89],
      [-46, -23],
      [-7, -3],
      [0, 0],
      [-170, -84],
      [-138, -67],
      [-13, -6],
      [-108, -109],
      [0, -3],
      [-52, -302],
      [-1, 0],
      [-78, -48],
      [-211, -1],
      [-8, 0],
      [-4, 0],
      [-423, 439],
      [-18, 18],
      [-444, -34],
      [-162, -13],
      [-420, -34],
      [-150, -53],
      [-1, 0],
      [-2, 0],
      [-898, -37],
      [-111, -4],
      [-164, -7],
      [-5, 0],
      [-545, -98],
      [-77, -3],
      [-439, -13],
      [-18, -1],
      [-8, -2],
      [-235, -64],
      [-8, -2],
      [-5, -1],
      [-573, -55],
      [-146, -14],
      [-706, -68],
      [-730, -70],
      [-166, -15],
      [-1033, -99],
      [-236, -23],
      [-4, 0],
      [-102, -9],
      [-124, 102],
      [-6, 5],
      [-258, 313],
      [-185, 226],
      [-667, 815],
      [-4, 4],
      [8, 156],
      [3, 45],
      [56, -11],
      [85, -16],
      [0, 9],
      [1, 109],
      [-7, 99],
      [0, 4],
      [0, 1],
      [46, 118],
      [-5, 6],
      [104, 311],
      [-68, 108],
      [-26, 418],
      [-2, 29],
      [-30, 6],
      [-49, 10],
      [-55, 11],
      [-17, 28],
      [-78, 127],
      [0, 48],
      [0, 67],
      [-74, 129],
      [26, 75],
      [34, 94],
      [194, 217],
      [-15, 62],
      [-6, 26],
      [-7, 7],
      [-27, 27],
      [-172, 9],
      [-76, 4],
      [-18, 18],
      [-22, 22],
      [-6, 37],
      [-15, 92],
      [53, 257],
      [-65, 33],
      [-1, 0],
      [-65, 35],
      [-96, 49],
      [-44, 5],
      [-131, 25],
      [-11, 2],
    ],
    [
      [30509, 15825],
      [-167, 754],
      [258, 318],
      [266, 212],
      [352, 238],
      [253, 138],
      [315, 246],
      [155, 101],
      [134, 85],
      [98, 49],
      [197, 142],
      [219, 103],
      [223, 191],
      [251, 226],
      [160, 169],
      [75, 120],
      [21, 73],
      [4, 13],
      [54, 133],
      [117, 160],
      [108, 242],
      [20, 273],
      [20, 286],
      [117, 385],
      [69, 307],
      [77, 313],
      [42, 496],
      [-2, 293],
      [35, 260],
      [21, 254],
      [79, 213],
      [98, 68],
      [58, 221],
      [31, 45],
    ],
    [
      [34267, 22952],
      [51, 74],
      [130, 216],
      [153, 170],
      [116, 159],
    ],
    [
      [79247, 79028],
      [-106, -504],
      [20, -429],
      [33, -625],
    ],
    [
      [79194, 77470],
      [-69, -14],
      [-403, -123],
      [-132, -52],
      [-177, -79],
      [-620, 126],
      [-324, 5],
      [-432, -129],
      [-568, -114],
      [-618, -248],
      [-816, -402],
      [-391, -70],
      [-252, -64],
      [-456, -601],
      [-152, -123],
      [-251, -81],
      [-213, 8],
      [-332, 147],
      [-319, 57],
      [-800, -284],
      [-992, -131],
      [-639, -134],
      [-729, -381],
      [-155, -105],
      [198, 2],
      [-480, -323],
      [-85, -57],
      [-2581, -1260],
    ],
    [
      [74155, 85272],
      [158, -17],
      [739, -182],
      [849, -272],
      [982, -608],
      [652, -976],
      [636, -1256],
      [329, -774],
      [105, -246],
      [457, -722],
      [294, -671],
      [-109, -520],
    ],
    [
      [75987, 15620],
      [0, -4],
      [-96, -11],
      [-35, -4],
      [-68, -7],
      [-3, -1],
      [-46, -6],
      [-118, -44],
      [-69, -26],
      [-58, -22],
      [-43, -16],
      [-274, -218],
      [-139, -326],
      [-261, -292],
      [-277, -91],
      [-26, -9],
      [-31, -10],
      [7, -190],
      [-165, -6],
      [-244, -9],
      [-191, -42],
      [-117, -27],
      [-104, -71],
      [-76, -51],
      [-112, -190],
      [-61, -102],
      [-9, -267],
      [-9, -268],
      [-64, -157],
      [-22, -53],
      [-78, -692],
      [-136, -230],
      [-24, -41],
      [-56, -12],
      [-338, -68],
      [-208, -42],
      [-115, -23],
      [-280, -164],
      [-124, -196],
      [-129, -205],
      [-106, -413],
      [-46, -52],
      [-201, -227],
      [-20, -104],
      [-19, -99],
      [17, -144],
      [11, -93],
      [200, 10],
      [242, 12],
      [114, -67],
      [61, -85],
      [41, -57],
      [38, -135],
      [63, -223],
      [-25, -211],
      [-8, -73],
      [-25, -198],
      [156, -19],
      [260, -33],
      [-5, -237],
      [55, -111],
      [40, -78],
      [-287, -126],
      [-182, -79],
      [-108, -177],
      [-125, -203],
      [-39, -108],
      [-21, -56],
      [48, -141],
      [62, -56],
      [86, -79],
      [250, -446],
      [21, -316],
      [0, -9],
      [-23, -66],
      [-36, -104],
      [-64, -33],
      [-371, -192],
      [-236, -45],
      [-18, -3],
      [-214, -259],
      [-20, -147],
      [-6, -43],
      [-78, -216],
      [-54, -150],
      [169, -372],
      [-51, -88],
      [-82, -143],
      [54, -229],
      [-12, -46],
      [-27, -97],
      [-41, -61],
      [-79, -115],
      [-67, -9],
      [-40, -6],
      [-207, -136],
      [-5, -26],
      [-21, -116],
      [-228, -218],
      [0, -1],
      [-12, -24],
      [-194, -355],
      [62, -264],
      [-51, -114],
      [-68, -151],
      [-282, -28],
      [-160, -150],
      [-11, 1],
      [-210, 12],
      [-194, -204],
      [-4, 0],
      [-210, -8],
      [-235, 94],
      [-83, 67],
      [-301, 243],
      [-170, -158],
      [-110, -101],
      [-328, -76],
      [-21, 0],
      [-30, 1],
      [-143, 3],
      [-60, 1],
      [-100, -196],
      [-120, -109],
      [216, -419],
      [-55, -546],
      [-28, -274],
      [-207, -238],
      [2, -4],
      [180, -246],
      [-481, 6],
      [-330, 4],
      [-378, -80],
      [-378, -80],
      [-382, 73],
      [-195, -102],
      [-65, -312],
      [48, -190],
      [-33, -122],
      [-47, -27],
      [-644, 85],
      [-161, 143],
      [-13, 12],
      [-148, 40],
      [-151, 147],
      [-132, 129],
      [-477, 322],
      [-56, 38],
      [-25, 17],
      [-57, 25],
      [-204, 89],
      [-212, -12],
      [-59, -3],
      [-4, 0],
      [-70, -74],
      [-250, -266],
      [-198, 9],
      [-70, 3],
      [-121, -86],
      [-77, -50],
      [-84, 38],
      [-26, 15],
      [-16, 21],
      [-97, 132],
      [-12, 17],
      [-81, 112],
      [-3, 1],
      [-1, 0],
      [-257, 79],
      [-43, 13],
      [-115, 35],
      [-126, -5],
      [-223, -10],
      [-165, 29],
      [-91, 15],
      [-126, 22],
      [-55, 28],
      [-214, 106],
      [-32, 55],
      [-21, 36],
      [-13, 21],
      [-1, 3],
      [-350, 344],
      [-249, 121],
      [-274, -22],
      [-167, -143],
      [-81, -68],
      [-172, -353],
      [-154, -109],
      [-64, 0],
      [-141, 3],
      [-53, 0],
      [-37, 1],
      [-39, 0],
      [-9, 0],
      [-98, -7],
      [-189, -14],
      [0, -14],
      [1, -13],
      [0, -2],
      [-328, 83],
      [-29, 151],
      [-24, 127],
      [-1, 9],
      [2, 49],
      [17, 503],
      [12, 326],
      [0, 19],
      [0, 1],
      [1, 436],
      [2, 677],
      [2, 857],
      [0, 11],
      [137, 739],
      [20, 103],
      [0, 2],
      [-27, 146],
      [0, 1],
      [-262, 264],
      [-80, 80],
      [-162, 163],
      [-6, 7],
      [-283, 412],
      [-51, 33],
      [-110, 69],
      [-101, -7],
      [-120, -95],
      [-161, 47],
      [-62, 10],
      [-334, 57],
      [-87, 88],
      [-187, -48],
      [-288, -319],
      [-295, -225],
      [-242, -185],
      [-32, -24],
      [-179, -186],
      [-77, -77],
      [-37, 21],
      [-36, 21],
      [-39, 23],
      [-82, -58],
      [-1, 0],
      [-134, 9],
      [-11, 1],
      [-94, 7],
      [-4, 6],
      [-71, 96],
      [-92, -7],
      [-2, -1],
      [-99, -73],
      [-273, 80],
      [-324, 94],
      [-6, 1],
      [0, 0],
      [-14, 3],
      [-20, 3],
      [-1, -2],
      [-76, -212],
      [-11, 9],
      [-778, 606],
      [-5, 4],
      [-23, 30],
      [0, 0],
      [-46, 64],
      [-9, 52],
      [-145, 828],
      [-1, 3],
      [0, 2],
      [81, 395],
      [-16, 81],
      [-27, 169],
      [-9, 13],
      [-76, 119],
      [-3, 4],
      [-41, 70],
      [-5, 3],
      [-1, 0],
      [-76, 49],
      [-31, 19],
      [-74, -81],
      [-4, -5],
      [-66, -225],
      [-57, 11],
      [-46, 8],
      [-63, 69],
      [-2, 2],
      [-136, 152],
      [-337, 773],
      [-20, 46],
      [-572, 385],
      [-140, -109],
      [-5, 0],
      [-92, 6],
      [-157, 135],
      [-1, 0],
      [-154, 0],
      [-46, 70],
      [-91, 140],
      [-4, 7],
      [-258, 253],
      [-18, 159],
      [-202, 375],
      [0, 41],
      [0, 18],
      [5, 7],
      [74, 100],
      [-32, 44],
      [-104, 139],
      [62, 176],
      [16, 11],
      [3, 2],
      [123, 77],
      [-1, 5],
      [-7, 64],
      [-5, 40],
      [-17, 154],
      [1, 2],
      [1, 3],
      [281, 710],
      [357, 902],
      [0, 0],
      [-1, 7],
      [-29, 113],
    ],
    [
      [34267, 22952],
      [-233, -6],
      [-432, 49],
      [-359, 13],
      [-220, -60],
      [-388, 27],
      [-118, -135],
      [-461, 76],
      [-371, -68],
      [-286, -5],
      [-228, -71],
      [-162, -70],
      [-356, -94],
      [-323, -8],
      [-207, -117],
      [-270, -5],
      [-27, -17],
      [-69, -33],
      [-159, -57],
      [-173, -44],
      [-200, -55],
      [-277, -74],
      [-310, 16],
      [-263, 7],
      [-276, -74],
      [-373, -55],
      [-283, 70],
      [-283, 26],
      [-214, -3],
      [-200, -30],
      [-186, -55],
      [-173, -50],
      [-76, -1],
      [-125, -29],
      [-110, 14],
      [-89, 1],
      [-221, 34],
      [-214, 96],
      [-124, 53],
      [-234, 98],
      [-297, 8],
      [-97, 20],
      [-435, -25],
      [-442, -13],
      [-207, 21],
      [-386, -103],
      [-408, -59],
      [-387, -79],
      [-71, -19],
    ],
    [
      [22464, 22067],
      [-31, 414],
      [-64, 489],
      [-10, 72],
      [-12, 862],
      [186, 380],
      [163, 606],
      [297, 497],
      [240, 688],
      [233, 626],
      [79, 662],
      [124, 303],
      [72, 569],
      [-4, 455],
      [-259, 393],
      [125, 491],
      [-551, 646],
      [64, 310],
      [-673, 678],
      [-315, 312],
      [-672, 616],
      [-98, 73],
      [-521, 389],
    ],
    [
      [30509, 15825],
      [-4, 1],
      [-47, -46],
      [-125, -121],
      [-253, -246],
      [-40, -39],
      [-3, -3],
      [-57, -57],
      [-46, -118],
      [-10, -24],
      [-80, -68],
      [-231, -195],
      [-2, -2],
      [-114, -135],
      [-23, -27],
      [-77, -91],
      [-6, -5],
      [-298, -256],
      [-208, -179],
      [-261, -225],
      [-19, -14],
      [-264, -203],
      [-22, -17],
      [-4, -6],
      [-84, -129],
      [-1, 0],
      [-4, -7],
      [-72, -31],
      [-21, -9],
      [-18, -7],
      [-2, -3],
      [-487, -476],
      [-29, -22],
      [-366, -290],
      [-57, -45],
      [-402, -320],
      [-127, -101],
      [-2, -4],
      [-288, -506],
      [-15, -26],
      [-261, -57],
      [-89, -76],
      [-798, -682],
      [-384, -641],
      [-81, -135],
      [-49, -83],
      [-10, -16],
      [-155, -102],
      [-121, -80],
      [0, 0],
      [-4, -4],
      [-351, -352],
      [-262, -261],
      [-40, -41],
      [-5, -2],
      [0, 0],
      [-115, -60],
      [-36, -37],
      [-134, -143],
      [-285, -302],
      [-45, -53],
      [-123, -167],
      [-2, -3],
      [-36, -50],
      [-66, -92],
      [-64, -89],
      [-2, -3],
      [-51, -56],
      [-182, -200],
      [-98, -108],
      [-32, 14],
      [-263, 112],
      [-5, 1],
      [-37, 25],
      [-193, 127],
      [-99, 66],
      [-1032, 44],
      [-6, 0],
      [0, 0],
      [-160, 38],
      [-2, 0],
      [-132, 140],
      [-19, 19],
      [0, 0],
      [-111, 44],
      [-26, -78],
      [-20, -57],
      [-3, -2],
      [-51, -25],
      [-4, -2],
      [-54, 40],
      [-170, -13],
      [0, 1],
      [-3, 9],
      [-31, 92],
      [-43, 11],
      [-111, 29],
      [-139, 110],
      [-31, 24],
      [-18, 14],
      [-155, -277],
      [-18, -79],
      [-82, -357],
      [-162, -289],
      [-6, -10],
      [153, -153],
      [26, -93],
      [-29, -95],
      [-10, -36],
      [-150, -155],
      [-111, -116],
      [-44, -84],
      [-30, -58],
      [-26, -48],
      [-36, -484],
      [-2, -31],
      [-8, -18],
      [-131, -321],
      [132, -288],
      [16, -36],
      [-18, -17],
      [-115, -112],
      [105, -348],
      [50, -166],
      [-58, -43],
      [-61, -45],
      [-48, -35],
      [7, -15],
      [54, -113],
      [-14, -141],
      [-3, -28],
      [-9, -89],
      [-11, -15],
      [-35, -46],
      [-79, -13],
      [-89, -14],
      [-67, -88],
      [-52, -251],
      [-133, -319],
      [5, -145],
      [2, -51],
      [-231, -436],
      [-75, -141],
      [-204, -935],
      [-20, -34],
      [-154, -271],
      [19, -72],
      [2, -9],
      [-182, -812],
      [-46, -207],
      [-35, -153],
      [-14, -62],
      [-66, -88],
      [-54, 6],
      [-114, -102],
      [-40, 4],
      [-107, 9],
      [-95, 142],
      [-80, 7],
      [-100, -129],
      [-193, 59],
      [-34, 10],
      [-14, 4],
      [-281, -122],
      [-14, 8],
      [-67, 39],
      [-12, 24],
      [-96, 206],
      [-135, 108],
      [-161, 243],
      [-53, -204],
      [-75, -8],
      [-77, -8],
      [-22, 189],
      [47, 143],
      [146, 439],
      [-7, 297],
      [24, 171],
      [124, 480],
      [67, 194],
      [237, 577],
      [67, 180],
      [-7, 524],
      [130, 261],
      [89, 443],
      [36, 292],
      [19, 617],
      [49, 314],
      [-39, 678],
      [7, 270],
      [-18, 1003],
      [39, 574],
      [-79, 421],
      [156, 516],
      [-288, 347],
      [210, 572],
      [156, 1193],
      [90, 885],
      [297, 1578],
      [241, 1239],
      [111, 360],
      [299, 629],
      [313, 558],
      [264, 525],
      [243, 351],
      [326, 508],
      [360, 312],
      [34, 72],
      [298, 322],
      [527, 475],
      [401, 365],
      [491, 240],
      [90, 50],
      [388, 216],
      [484, 415],
      [243, 314],
      [194, 367],
      [71, 382],
      [-81, 481],
      [-9, 113],
    ],
    [
      [19646, 33687],
      [-470, 440],
      [-363, 506],
      [-389, 434],
      [-447, 662],
      [-431, 361],
      [-189, 401],
      [-272, 319],
      [-362, 387],
      [-326, 298],
      [-431, 474],
      [-286, 370],
      [-499, 367],
      [-514, 430],
      [-663, 222],
      [-387, 103],
      [-793, 120],
      [-725, 190],
      [-956, 201],
      [-720, 4],
      [-625, 22],
      [-769, 143],
      [-569, 18],
      [-358, 5],
      [-266, 4],
      [-542, 35],
      [-746, -145],
      [-638, 10],
      [-684, -155],
      [-512, -30],
      [-577, -70],
      [-360, -65],
      [-90, -32],
      [-407, -142],
      [-553, -146],
      [-527, -302],
      [-640, -246],
      [-296, -130],
      [-328, -197],
      [-41, -53],
      [-504, 62],
      [-391, 210],
      [114, 346],
      [279, 398],
      [192, 292],
      [263, 281],
      [134, 370],
      [190, 156],
      [259, 579],
      [407, 367],
      [-54, 43],
      [10, 1],
      [79, -12],
      [59, 53],
      [168, 405],
      [258, 214],
      [330, 134],
      [389, 255],
      [314, 204],
      [328, 203],
      [218, 160],
      [388, 267],
      [473, 269],
      [321, 197],
      [286, 199],
      [151, 119],
    ],
    [
      [80076, 75761],
      [-345, -371],
      [-74, -40],
      [-259, -248],
      [-585, -201],
      [-150, -150],
      [-224, -225],
      [-145, -112],
      [-1342, -8],
      [-171, -84],
      [-886, -322],
      [-312, -126],
      [-676, -219],
      [-402, -182],
      [-416, -181],
      [-201, -314],
      [203, -637],
      [445, -321],
      [482, -399],
      [171, -707],
      [367, -631],
      [277, -353],
      [451, -583],
      [65, -381],
      [154, -646],
      [299, -373],
      [177, -517],
      [505, -317],
      [128, -353],
      [347, -686],
      [174, -251],
      [329, -577],
      [282, -108],
      [359, -597],
      [189, -81],
      [285, -182],
      [483, -324],
      [203, -73],
    ],
    [
      [80263, 63881],
      [38, -797],
      [91, -572],
    ],
    [
      [79194, 77470],
      [13, -233],
      [10, -82],
      [83, -450],
      [540, -724],
      [236, -220],
    ],
    [
      [80076, 75761],
      [478, -447],
      [717, -387],
      [205, -24],
      [578, -155],
      [398, -107],
      [48, -24],
      [99, -214],
      [115, -250],
      [740, -1350],
      [121, -857],
      [-141, -830],
      [-328, -886],
      [-428, -757],
      [-420, -704],
      [-386, -874],
      [-589, -1161],
      [-292, -363],
      [-420, -738],
      [-288, -819],
      [-25, -838],
      [5, -95],
    ],
  ],
  transform: {
    scale: [0.00003431243269544228, 0.000033939737568342754],
    translate: [9.108225445225509, 6.502456387382388],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Ardo-Kola",
            id: "TR001",
            nameAbbr: "Ardo-Kola",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.92459343001,
            Shape_Area: 0.19789792229,
          },
        },
        {
          arcs: [[6, 7, 8, 9, -3, 10]],
          type: "Polygon",
          properties: {
            name: "Bali",
            id: "TR002",
            nameAbbr: "Bali",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.20046236155,
            Shape_Area: 0.69829018585,
          },
        },
        {
          arcs: [[-9, 11, 12, 13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Donga",
            id: "TR003",
            nameAbbr: "Donga",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.40915997606,
            Shape_Area: 0.28027404585,
          },
        },
        {
          arcs: [[16, 17, 18, -8, 19]],
          type: "Polygon",
          properties: {
            name: "Gashaka",
            id: "TR004",
            nameAbbr: "Gashaka",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.72605283584,
            Shape_Area: 0.67455937204,
          },
        },
        {
          arcs: [[-10, -16, 20, 21, 22, -4]],
          type: "Polygon",
          properties: {
            name: "Gassol",
            id: "TR005",
            nameAbbr: "Gassol",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.24522688229,
            Shape_Area: 0.48889140697,
          },
        },
        {
          arcs: [[-22, 23, 24, 25]],
          type: "Polygon",
          properties: {
            name: "Ibi",
            id: "TR006",
            nameAbbr: "Ibi",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.17850253089,
            Shape_Area: 0.23278980892,
          },
        },
        {
          arcs: [[26, -1, 27]],
          type: "Polygon",
          properties: {
            name: "Jalingo",
            id: "TR007",
            nameAbbr: "Jalingo",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.73817814347,
            Shape_Area: 0.01565568283,
          },
        },
        {
          arcs: [[28, 29, -5, -23, -26, 30]],
          type: "Polygon",
          properties: {
            name: "Karim Lamido",
            id: "TR008",
            nameAbbr: "Karim Lamido",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.92819763575,
            Shape_Area: 0.56551881929,
          },
        },
        {
          arcs: [[31, 32, 33, 34, -12, -19]],
          type: "Polygon",
          properties: {
            name: "Kurmi",
            id: "TR009",
            nameAbbr: "Kurmi",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.23610880493,
            Shape_Area: 0.40509898172,
          },
        },
        {
          arcs: [[35, 36, -28, -6, -30, 37]],
          type: "Polygon",
          properties: {
            name: "Lau",
            id: "TR010",
            nameAbbr: "Lau",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.75228238932,
            Shape_Area: 0.14887934624,
          },
        },
        {
          arcs: [[38, -32, -18]],
          type: "Polygon",
          properties: {
            name: "Sardauna",
            id: "TR011",
            nameAbbr: "Sardauna",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.33737788597,
            Shape_Area: 0.40191280481,
          },
        },
        {
          arcs: [[-35, 39, 40, -13]],
          type: "Polygon",
          properties: {
            name: "Takum",
            id: "TR012",
            nameAbbr: "Takum",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.55582658145,
            Shape_Area: 0.12676424882,
          },
        },
        {
          arcs: [[-34, 41, -40]],
          type: "Polygon",
          properties: {
            name: "Ussa",
            id: "TR013",
            nameAbbr: "Ussa",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.43183748315,
            Shape_Area: 0.20036941017,
          },
        },
        {
          arcs: [[-15, 42, -24, -21]],
          type: "Polygon",
          properties: {
            name: "Wukari",
            id: "TR014",
            nameAbbr: "Wukari",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.14845331718,
            Shape_Area: 0.31045061293,
          },
        },
        {
          arcs: [[43, 44, -11, -2, -27, -37, 45]],
          type: "Polygon",
          properties: {
            name: "Yorro",
            id: "TR015",
            nameAbbr: "Yorro",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.9208366155,
            Shape_Area: 0.10952260308,
          },
        },
        {
          arcs: [[-44, 46]],
          type: "Polygon",
          properties: {
            name: "Zing",
            id: "TR016",
            nameAbbr: "Zing",
            parent: "Taraba",
            parentAbbr: "TR",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.13150141317,
            Shape_Area: 0.07296949968,
          },
        },
      ],
    },
  },
};

const gombe = {
  type: "Topology",
  arcs: [
    [
      [19769, 21785],
      [98, -75],
      [110, -124],
      [158, -185],
      [650, -226],
      [377, -43],
    ],
    [
      [21162, 21132],
      [476, -57],
      [441, -318],
      [587, -1100],
      [520, -991],
      [335, -752],
      [257, -894],
      [808, -441],
      [774, -542],
      [1144, -398],
      [620, -404],
      [1095, -519],
      [1043, -839],
      [530, -334],
      [451, -253],
    ],
    [
      [30243, 13290],
      [-67, -86],
      [-448, -428],
      [-532, -672],
      [-953, -216],
      [-93, 198],
      [-878, 340],
      [-97, 246],
      [-500, 184],
      [-272, 242],
      [-276, 324],
      [-136, 50],
    ],
    [
      [25991, 13472],
      [-157, 56],
      [-386, -35],
      [-247, -2],
      [-482, -54],
      [-451, -27],
      [-267, 31],
      [-223, 64],
      [-239, 167],
      [-327, -22],
      [-792, -2],
      [-540, -66],
      [-488, 123],
      [-534, -87],
      [-452, -41],
      [-579, 63],
      [-586, -596],
      [-613, -405],
      [-544, -447],
      [-504, -550],
      [-749, -688],
      [-487, -646],
      [-545, -938],
      [-669, -638],
      [-777, -351],
      [-598, -679],
      [-229, 28],
    ],
    [
      [13526, 7730],
      [-89, 460],
      [-393, 876],
      [-935, 781],
      [-1215, 878],
      [-976, 718],
      [-1179, 965],
      [-1176, 666],
      [-964, 820],
      [-1058, 1249],
      [-153, 762],
      [616, 903],
      [936, 430],
      [1375, 249],
      [459, 49],
      [373, 790],
      [19, 41],
      [-187, 1347],
      [38, 1262],
      [127, 1505],
      [61, 1302],
      [-386, 1043],
      [-51, 107],
    ],
    [
      [8768, 24933],
      [435, 44],
      [781, -38],
      [896, -74],
      [847, -59],
      [877, 158],
      [969, 81],
      [485, 185],
    ],
    [
      [14058, 25230],
      [187, 71],
      [329, -100],
      [463, -338],
      [644, -348],
      [170, -389],
      [674, -661],
      [653, -456],
      [800, -276],
      [698, -261],
      [526, -251],
      [567, -436],
    ],
    [
      [38393, 15744],
      [55, -116],
      [855, -754],
      [916, -606],
      [873, -421],
      [51, -11],
      [-48, -349],
      [-96, -970],
      [-137, -1872],
      [-41, -1636],
      [147, -1198],
      [99, -552],
      [-518, -1099],
      [-1053, -827],
      [-1596, -1026],
      [-57, -36],
      [-1447, -848],
      [-1685, -967],
      [-1295, -857],
      [-1584, -676],
      [-1714, -320],
      [-1205, -107],
      [-107, -9],
      [-1270, -158],
      [-1622, -56],
      [-821, -2],
    ],
    [
      [25093, 271],
      [10, 211],
      [26, 245],
      [19, 264],
      [-20, 211],
      [40, 238],
      [130, 623],
      [47, 387],
      [100, 270],
      [125, 365],
      [16, 204],
      [409, 510],
      [258, 307],
    ],
    [
      [26253, 4106],
      [110, 129],
      [194, 290],
      [228, 207],
      [580, 304],
      [464, 149],
      [514, -28],
      [652, -187],
      [735, -127],
      [510, 33],
      [521, 122],
      [714, 213],
      [590, 209],
      [613, 221],
      [347, 287],
      [728, 839],
      [461, 713],
      [296, 614],
      [148, 535],
      [-631, 336],
      [-554, 280],
      [-188, 166],
      [-406, 496],
      [-484, 531],
      [-581, 797],
      [-264, 745],
      [-39, 422],
      [120, 386],
      [-18, 618],
      [-112, 545],
      [-25, 454],
    ],
    [
      [31476, 14405],
      [165, 121],
      [498, 244],
      [518, 250],
      [575, 86],
      [526, 134],
      [715, 335],
      [526, 168],
      [343, 77],
      [435, 13],
      [833, 14],
      [293, -25],
      [369, -108],
      [341, -59],
      [250, 30],
      [314, -46],
      [172, 106],
      [44, -1],
    ],
    [
      [25991, 13472],
      [-32, -231],
      [-586, -426],
      [-417, -245],
      [-430, -191],
      [-457, -305],
      [-494, -163],
      [-464, -475],
      [-310, -111],
      [-295, -247],
      [-155, -215],
      [-13, -143],
      [187, -200],
      [190, -594],
      [52, -619],
      [-211, -656],
      [-167, -317],
      [-38, -30],
    ],
    [
      [22351, 8304],
      [-331, -257],
      [-576, -365],
      [-208, -595],
      [-357, -511],
      [-312, -159],
      [-606, -541],
      [-578, -339],
      [-455, -109],
      [-239, -65],
      [-508, -123],
      [-636, -188],
      [-69, -190],
      [-469, -212],
      [-500, -252],
      [-236, -119],
      [-218, -85],
      [-919, -246],
      [-239, -30],
      [-623, -87],
      [-108, 2],
    ],
    [
      [14164, 3833],
      [-183, 404],
      [-69, 116],
      [-195, 1710],
      [-93, 1212],
      [-98, 455],
    ],
    [
      [18711, 33309],
      [-100, -361],
      [-217, 44],
      [-645, 50],
      [-637, -5],
      [-1198, 133],
      [-938, 148],
      [-267, -267],
      [-161, -452],
      [-351, -971],
      [-105, -832],
      [-218, -1123],
      [-113, -826],
      [154, -1039],
      [13, -1003],
      [-17, -726],
      [147, -849],
    ],
    [
      [8768, 24933],
      [-543, 1176],
      [-1064, 510],
      [-1483, 414],
      [-841, 310],
      [-418, 542],
      [-192, 1020],
      [-1, 1221],
      [-216, 1129],
      [-536, 801],
      [-1356, 616],
      [-1055, 408],
      [-111, 40],
      [-952, 347],
      [95, 650],
      [822, 1200],
      [34, 50],
      [843, 1135],
      [316, 1141],
      [533, 1335],
      [379, 1019],
      [127, 1008],
      [332, 1315],
      [646, 953],
      [678, 639],
      [889, 304],
      [954, 112],
      [701, 358],
      [944, 822],
      [508, 852],
      [704, 887],
      [916, 475],
      [965, 527],
      [964, 540],
      [491, 669],
      [34, 42],
    ],
    [
      [12875, 49500],
      [37, -76],
      [140, -310],
      [122, -422],
      [107, -395],
      [58, -238],
      [191, -497],
      [201, -762],
      [304, -649],
      [150, -558],
      [497, -1329],
      [145, -707],
      [284, -770],
      [338, -669],
      [16, -997],
      [122, -564],
      [375, -806],
      [338, -594],
      [541, -713],
      [1003, -339],
      [438, -115],
      [545, -354],
      [195, -91],
      [111, -71],
    ],
    [
      [19133, 37474],
      [203, -130],
      [30, -1065],
      [-81, -385],
      [-91, -724],
      [-43, -677],
      [-120, -622],
      [-194, -92],
      [-126, -470],
    ],
    [
      [30549, 31858],
      [-625, 108],
      [-804, -219],
      [-776, -226],
      [-632, -18],
      [-947, 237],
      [-652, -31],
      [-1158, -302],
      [-1207, -247],
      [-680, -24],
      [-684, 335],
      [-558, 272],
      [-712, 471],
      [-1025, 503],
      [-961, 251],
      [-384, 297],
      [-33, 44],
    ],
    [
      [19133, 37474],
      [207, 223],
      [519, 223],
      [651, 323],
      [1217, 640],
      [1071, 635],
      [698, 539],
      [593, 446],
      [926, 610],
      [749, 268],
      [789, 293],
      [1047, 182],
      [207, 221],
      [291, 198],
    ],
    [
      [28098, 42275],
      [255, 174],
      [310, 198],
      [177, 103],
      [332, -300],
      [603, -625],
      [797, -662],
      [173, -837],
      [144, -1452],
      [-71, -1545],
      [-270, -1806],
      [-171, -1150],
      [-54, -1077],
      [211, -1243],
      [15, -195],
    ],
    [
      [19769, 21785],
      [82, 54],
      [639, 466],
      [280, 111],
      [21, 7],
    ],
    [
      [20791, 22423],
      [77, -185],
      [84, -191],
      [182, -348],
      [49, -326],
      [-21, -217],
      [0, -24],
    ],
    [
      [26253, 4106],
      [-338, 170],
      [-393, 319],
      [-96, 443],
      [-269, 630],
      [-198, 261],
      [-386, 448],
      [-391, 359],
      [-370, 318],
      [-419, 360],
      [-180, 207],
      [-349, 298],
      [-450, 326],
      [-63, 59],
    ],
    [
      [30243, 13290],
      [279, 357],
      [383, 327],
      [421, 320],
      [150, 111],
    ],
    [
      [30549, 31858],
      [63, -871],
      [76, -726],
      [98, -167],
    ],
    [
      [30786, 30094],
      [-117, -77],
      [-353, -144],
      [-711, 247],
      [-359, -272],
      [-510, -434],
      [-328, -680],
      [-490, -433],
      [-436, -211],
      [-342, -599],
      [-310, -382],
      [-351, -367],
      [-692, -336],
      [-679, -397],
      [-638, -818],
      [-778, -627],
      [-750, -694],
      [-729, -613],
      [-511, -421],
      [-757, -362],
      [-154, -51],
    ],
    [
      [28567, 42997],
      [-367, -573],
      [-102, -149],
    ],
    [
      [12875, 49500],
      [554, 689],
      [196, 367],
      [113, -52],
      [236, 137],
      [26, 25],
      [428, 416],
      [981, 428],
      [984, 281],
      [1027, 6],
      [877, -87],
      [1400, -474],
      [1025, -605],
      [1250, -790],
      [951, -760],
      [593, -408],
      [1203, -987],
      [745, -980],
      [625, -931],
      [870, -1097],
      [1122, -1243],
      [486, -438],
    ],
    [
      [25093, 271],
      [-733, -2],
      [-1666, -23],
      [-1388, 66],
      [-1262, -31],
      [-497, -47],
      [-869, -49],
      [-1410, -185],
      [-428, 6],
      [-294, 31],
      [-42, 1],
      [-636, 192],
      [-244, 86],
      [-37, 16],
      [276, 75],
      [60, 449],
      [-795, 1004],
      [-371, 787],
      [-419, 802],
      [-174, 384],
    ],
    [
      [30786, 30094],
      [43, -73],
      [1667, -64],
      [1105, -36],
      [416, 105],
      [296, -542],
      [342, -1015],
      [-294, -1421],
      [-962, -1268],
      [-857, -1284],
      [-617, -1009],
      [381, -1337],
      [1141, -1655],
      [863, -855],
      [677, -330],
      [1052, -289],
      [936, -483],
      [327, -442],
      [131, -177],
      [511, -1224],
      [449, -951],
    ],
  ],
  transform: {
    scale: [0.00003431245489508168, 0.00003393947202121423],
    translate: [10.533840257194331, 9.575182216452216],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5, 6]],
          type: "Polygon",
          properties: {
            name: "Akko",
            id: "GB001",
            nameAbbr: "Akko",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.41051983805,
            Shape_Area: 0.23532594621,
          },
        },
        {
          arcs: [[7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            name: "Balanga",
            id: "GB002",
            nameAbbr: "Balanga",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.96019353522,
            Shape_Area: 0.14787756089,
          },
        },
        {
          arcs: [[11, 12, 13, -4]],
          type: "Polygon",
          properties: {
            name: "Billiri",
            id: "GB003",
            nameAbbr: "Billiri",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.23918413687,
            Shape_Area: 0.06889446738,
          },
        },
        {
          arcs: [[14, -6, 15, 16, 17]],
          type: "Polygon",
          properties: {
            name: "Dukku",
            id: "GB004",
            nameAbbr: "Dukku",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.46227234599,
            Shape_Area: 0.3039990553,
          },
        },
        {
          arcs: [[18, -18, 19, 20]],
          type: "Polygon",
          properties: {
            name: "Funakaye",
            id: "GB005",
            nameAbbr: "Funakaye",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.36296750107,
            Shape_Area: 0.11656358974,
          },
        },
        {
          arcs: [[-1, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Gombe",
            id: "GB006",
            nameAbbr: "Gombe",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.14292292172,
            Shape_Area: 0.00124897998,
          },
        },
        {
          arcs: [[23, -12, -3, 24, -10]],
          type: "Polygon",
          properties: {
            name: "Kaltungo",
            id: "GB007",
            nameAbbr: "Kaltungo",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.30397442651,
            Shape_Area: 0.08710464056,
          },
        },
        {
          arcs: [[-19, 25, 26, -22, -7, -15]],
          type: "Polygon",
          properties: {
            name: "Kwami",
            id: "GB008",
            nameAbbr: "Kwami",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.64190002349,
            Shape_Area: 0.13645086664,
          },
        },
        {
          arcs: [[27, -20, -17, 28]],
          type: "Polygon",
          properties: {
            name: "Nafada",
            id: "GB009",
            nameAbbr: "Nafada",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.55718145106,
            Shape_Area: 0.15622035588,
          },
        },
        {
          arcs: [[-9, 29, -13, -24]],
          type: "Polygon",
          properties: {
            name: "Shomgom",
            id: "GB010",
            nameAbbr: "Shomgom",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.14837598256,
            Shape_Area: 0.06990457468,
          },
        },
        {
          arcs: [[30, -11, -25, -2, -23, -27]],
          type: "Polygon",
          properties: {
            name: "Yamaltu",
            id: "GB011",
            nameAbbr: "Yamaltu",
            parent: "Gombe",
            parentAbbr: "GB",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.96588194519,
            Shape_Area: 0.17831733086,
          },
        },
      ],
    },
  },
};

const ondo = {
  type: "Topology",
  arcs: [
    [
      [47732, 48990],
      [-192, 17],
      [-180, 71],
      [-499, -73],
      [-196, -78],
      [-326, -144],
      [-337, -69],
      [-619, -64],
      [-525, -25],
      [-375, 88],
      [-451, -33],
      [-275, 25],
      [-532, -25],
      [-640, -97],
      [-213, -35],
    ],
    [
      [42372, 48548],
      [-428, -70],
      [-552, -105],
      [-518, -12],
      [-587, -99],
      [-485, -52],
      [-735, -130],
      [-546, -100],
      [-693, -28],
      [-408, 142],
      [-396, 0],
      [-430, 14],
      [-479, -23],
    ],
    [
      [36115, 48085],
      [31, 221],
      [75, 495],
      [68, 392],
      [36, 205],
      [71, 243],
      [190, 520],
      [20, 167],
    ],
    [
      [36606, 50328],
      [370, -169],
      [541, -213],
      [752, -54],
      [531, -36],
      [699, 15],
      [748, 110],
      [728, 109],
      [404, 48],
      [174, -51],
      [109, 46],
      [223, 78],
      [297, 110],
      [473, 134],
      [318, 117],
      [379, 136],
      [439, 142],
      [332, 178],
      [254, 308],
      [85, 263],
      [132, 296],
      [160, 337],
      [236, 410],
      [497, 440],
      [316, 395],
      [178, 151],
    ],
    [
      [45981, 53628],
      [52, -81],
      [305, -298],
      [170, -302],
      [338, -312],
      [386, -271],
      [213, -147],
      [292, -284],
      [350, -339],
      [279, -269],
      [232, -222],
      [139, -138],
      [-61, -47],
      [171, -323],
      [-57, -93],
      [-117, -202],
      [-145, -222],
      [-170, -133],
      [-178, -147],
      [-98, -284],
      [-125, -194],
      [-116, -141],
      [-49, -101],
      [-60, -88],
    ],
    [
      [47042, 43713],
      [-199, -23],
      [-362, 81],
      [-645, 24],
      [-454, 212],
      [-252, 214],
      [-500, 274],
      [-505, 341],
      [-220, 153],
      [-219, 153],
      [-421, 143],
      [-253, 140],
      [-140, 118],
      [-94, 147],
    ],
    [
      [42778, 45690],
      [-24, 39],
      [-77, 184],
      [-104, 280],
      [7, 394],
      [-107, 470],
      [-101, 409],
      [27, 441],
      [-6, 455],
      [-21, 186],
    ],
    [
      [47732, 48990],
      [-36, -53],
      [-96, -80],
      [-88, -60],
      [-142, -45],
      [-96, -100],
      [-141, -5],
      [-209, 11],
      [-215, -10],
      [-107, 56],
      [-61, 15],
      [-95, -67],
      [-77, -189],
      [-157, -160],
      [-198, -201],
      [-138, -201],
      [-65, -285],
      [-20, -373],
      [137, -308],
      [52, -90],
      [107, -22],
      [146, -159],
      [100, -29],
      [155, -16],
      [128, -2],
      [174, -30],
      [139, -132],
      [-24, -271],
      [-119, -277],
      [-136, -120],
      [-120, -331],
      [-13, -374],
      [57, -259],
      [156, -309],
      [110, -321],
      [42, -293],
      [200, -133],
      [-40, -54],
    ],
    [
      [42778, 45690],
      [-25, -16],
      [-177, -146],
      [-232, -200],
      [-151, -208],
      [-266, -260],
      [-226, -220],
      [-475, -284],
      [-400, -251],
      [-340, -198],
      [-353, -259],
      [-58, -263],
      [-334, -253],
      [-332, -143],
      [-366, -184],
      [-437, -40],
      [-431, -40],
      [-410, 41],
      [-63, 1],
    ],
    [
      [37702, 42767],
      [-280, 5],
      [-301, 80],
      [-389, 26],
      [-591, 78],
      [-402, 82],
      [-342, 33],
      [-469, 90],
      [-499, -67],
      [-502, 111],
      [-314, 148],
    ],
    [
      [33613, 43353],
      [100, 81],
      [268, 349],
      [481, 630],
      [339, 606],
      [345, 518],
      [300, 667],
      [280, 662],
      [224, 533],
      [150, 575],
      [15, 111],
    ],
    [
      [36606, 50328],
      [57, 457],
      [88, 461],
      [133, 304],
      [168, 411],
      [234, 302],
      [313, 260],
      [192, 207],
      [322, 362],
      [108, 125],
      [193, 223],
      [420, 163],
      [252, 165],
      [355, 361],
      [141, 345],
      [146, 323],
      [113, 311],
      [297, 97],
      [238, 125],
      [25, -82],
      [317, 42],
      [174, -98],
      [287, -134],
      [104, -199],
      [139, -159],
      [318, 103],
      [68, 40],
      [165, 235],
      [72, 284],
      [239, 234],
      [193, 282],
      [47, 101],
      [163, -23],
      [290, 56],
      [250, 64],
      [203, 65],
      [243, 50],
      [250, 29],
      [255, 23],
      [244, 112],
      [277, 42],
      [350, 8],
      [329, -33],
      [322, -67],
      [180, -111],
      [-111, -209],
      [-23, -190],
      [-51, -244],
      [-28, -475],
      [-98, -223],
      [-11, -319],
      [-12, -306],
      [330, -434],
      [105, -166],
    ],
    [
      [30038, 42052],
      [68, -207],
      [184, -275],
      [122, -362],
      [151, -200],
      [-46, -420],
      [-46, -311],
      [69, -320],
      [139, -166],
      [29, -258],
      [-12, -339],
      [-86, -305],
      [-10, -217],
      [51, -197],
      [138, -193],
      [114, -43],
      [50, -170],
      [4, -197],
      [9, -299],
      [30, -211],
      [7, 7],
      [6, -55],
      [199, -152],
      [430, 19],
      [331, 36],
      [350, 61],
      [290, 22],
      [118, -158],
      [-30, -176],
      [-123, -140],
      [-103, -155],
      [-51, -209],
      [-22, -136],
      [-70, -148],
      [-190, -139],
      [-236, -50],
      [-291, -124],
      [-127, -94],
    ],
    [
      [31484, 35771],
      [-42, 76],
      [-267, 100],
      [-180, 71],
      [-127, 63],
      [-361, 135],
      [-328, 108],
      [-508, 138],
      [-437, 34],
      [-139, 23],
    ],
    [
      [29095, 36519],
      [-136, 23],
      [-152, 145],
      [102, 79],
      [-5, 130],
      [-32, 102],
      [-112, 111],
      [-32, 109],
      [-287, 534],
      [-192, 187],
      [-212, 221],
      [-126, 97],
      [-272, 249],
      [-291, 256],
      [-325, 291],
      [-266, 228],
      [-493, 246],
      [-328, 74],
      [-233, 133],
      [-339, 298],
      [-371, 339],
      [-163, 287],
      [-155, 404],
      [-35, 319],
      [-93, 491],
      [-88, 409],
      [-126, 511],
      [-101, 423],
      [-132, 559],
      [-148, 437],
      [-5, 83],
    ],
    [
      [23947, 44294],
      [-22, 325],
      [27, 434],
      [41, 413],
      [28, 509],
      [-40, 450],
    ],
    [
      [23981, 46425],
      [70, -11],
      [383, -6],
      [445, 12],
      [794, -20],
      [531, 25],
      [526, 86],
      [411, 20],
      [337, 28],
      [75, 40],
      [162, -44],
      [319, -155],
      [409, -95],
      [125, -179],
      [32, -136],
      [310, -353],
      [203, -384],
      [-44, -237],
      [155, -376],
      [217, -337],
      [159, -567],
      [-74, -407],
      [228, -493],
      [233, -490],
      [4, -153],
      [18, -54],
      [29, -87],
    ],
    [
      [29095, 36519],
      [-178, -72],
      [-234, 72],
      [-341, 108],
      [-359, 230],
      [-61, 15],
      [-275, 18],
      [-383, 13],
      [-745, 88],
      [-685, 45],
      [-522, 111],
      [-234, 153],
      [-191, 235],
      [-458, 340],
      [-526, 342],
      [-479, 327],
      [-544, 451],
      [-385, 264],
      [-366, 258],
      [-290, 344],
      [-223, 343],
      [-229, 425],
      [-144, 385],
    ],
    [
      [21243, 41014],
      [-43, 114],
      [-147, 451],
      [45, 298],
      [199, 234],
      [170, 187],
      [232, 193],
      [212, 220],
      [340, 225],
      [293, 219],
      [238, 166],
      [427, 271],
      [320, 239],
      [212, 180],
      [124, 249],
      [82, 34],
    ],
    [
      [22232, 7976],
      [-1894, -4650],
    ],
    [
      [20338, 3326],
      [-137, 174],
      [-524, 837],
      [-421, 618],
      [-725, 922],
      [-486, 701],
      [-539, 743],
      [-308, 487],
      [-486, 755],
      [-158, 173],
      [-866, 965],
      [-626, 744],
      [-497, 470],
      [-428, 591],
      [-85, 558],
      [113, 318],
      [109, 120],
      [181, 350],
      [224, 159],
      [173, 316],
      [165, 235],
      [30, 155],
      [3, 170],
      [-11, 224],
      [-43, 239],
      [-97, 246],
      [-211, 241],
      [-3, 210],
      [-99, 165],
      [4, 197],
      [251, 172],
      [165, 221],
      [79, 291],
      [32, 352],
      [-23, 218],
      [38, 284],
      [65, 271],
      [-11, 152],
    ],
    [
      [15186, 17370],
      [164, 191],
      [272, 139],
      [363, 47],
      [308, -93],
      [367, -156],
      [244, -303],
      [8, -380],
      [142, -355],
      [135, -356],
      [110, -687],
      [142, -362],
      [201, -513],
      [253, -527],
      [270, -365],
      [557, -191],
    ],
    [
      [18722, 13459],
      [-14, -42],
      [-94, -359],
      [-230, -71],
      [-145, -262],
      [131, 208],
      [-62, -114],
      [-57, -169],
      [71, -219],
      [138, -213],
      [329, -26],
      [126, -138],
      [106, -70],
      [126, -138],
      [206, -119],
      [88, -36],
      [260, -147],
      [117, -219],
      [185, -187],
      [125, -220],
      [211, -269],
      [2, -285],
      [138, -220],
      [545, 4],
      [188, -51],
      [226, -133],
      [133, -83],
      [265, -277],
      [151, -275],
      [29, -251],
      [158, -241],
      [231, -296],
      [6, -21],
      [7, 7],
      [-71, -271],
      [-115, -280],
    ],
    [
      [31484, 35771],
      [207, -376],
      [120, -36],
      [127, -36],
      [207, -78],
      [139, -145],
      [100, -471],
      [24, -211],
      [103, -273],
      [77, -232],
      [10, -231],
      [-7, 0],
      [100, -56],
      [316, -419],
      [208, -486],
      [214, -431],
      [237, -317],
      [152, -206],
      [84, -198],
      [42, -286],
      [37, -238],
      [110, -260],
      [287, -134],
      [334, -94],
      [161, -50],
      [199, -19],
    ],
    [
      [35072, 30488],
      [-53, -113],
      [-22, -135],
      [4, -123],
      [130, -300],
      [106, -97],
      [25, -96],
      [-8, -108],
      [-152, -222],
      [-67, -33],
      [-61, -46],
      [-237, -91],
      [-185, -81],
    ],
    [
      [34552, 29043],
      [-90, 144],
      [-164, 282],
      [-292, 256],
      [-166, 139],
      [-402, 102],
      [-369, 61],
      [-358, -82],
      [-660, -43],
      [-533, -59],
      [-254, 4],
      [-304, 6],
      [-611, 92],
      [-653, -16],
      [-645, 45],
      [-661, -77],
      [-489, 110],
      [-302, 60],
      [-478, -46],
      [-595, -133],
      [-517, 29],
      [-482, 158],
      [-731, -266],
      [-306, -212],
      [-215, -370],
      [23, -273],
      [16, -251],
      [18, -123],
      [-99, -284],
      [-297, -96],
      [-152, -229],
      [-208, -411],
      [-93, -318],
      [-17, -197],
      [-56, -155],
      [-61, -19],
      [-425, -102],
      [-327, -259],
      [-105, -243],
      [-105, -250],
      [-184, -146],
      [-286, -233],
      [-220, -240],
    ],
    [
      [21697, 25398],
      [-384, 79],
      [-292, 99],
    ],
    [
      [21021, 25576],
      [-430, 144],
      [-366, 285],
      [-232, 608],
      [-311, 332],
      [-656, 574],
      [-291, 738],
      [-235, 846],
      [24, 679],
      [-158, 620],
      [-18, 36],
    ],
    [
      [18348, 30438],
      [95, 139],
      [17, 218],
      [-7, 0],
      [72, 277],
      [77, 236],
      [153, 323],
      [90, 569],
      [-5, 489],
      [74, 453],
      [-115, 342],
      [-43, 259],
      [-39, 496],
      [-79, 565],
      [-242, 398],
      [-110, 307],
      [75, 481],
      [100, 358],
      [101, 399],
      [363, 421],
      [450, 407],
      [274, 300],
      [-17, 652],
      [-117, 654],
      [-86, 518],
      [-113, 330],
    ],
    [
      [19316, 40029],
      [100, 98],
      [97, 175],
      [43, 168],
      [111, 243],
      [23, 176],
      [60, 273],
    ],
    [
      [19750, 41162],
      [52, -3],
      [342, -54],
      [370, 14],
      [369, -74],
      [360, -31],
    ],
    [
      [22052, 46568],
      [259, 33],
      [678, -114],
      [559, 4],
      [433, -66],
    ],
    [
      [19750, 41162],
      [13, 59],
      [22, 121],
      [24, 244],
      [38, 265],
      [68, 480],
      [115, 487],
      [67, 345],
      [-33, 476],
      [-74, 429],
      [1, 482],
      [-336, 413],
      [-242, 45],
      [-142, 335],
      [-361, 149],
      [-72, 26],
    ],
    [
      [18838, 45518],
      [-211, 406],
      [276, 56],
      [488, 175],
      [738, 320],
      [641, 139],
      [921, -91],
      [361, 45],
    ],
    [
      [18782, 205],
      [0, -17],
      [17, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [8, 0],
      [0, -16],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -32],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [24, 0],
    ],
    [
      [18604, 450],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -24],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -33],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [24, 0],
    ],
    [
      [18280, 925],
      [0, -8],
      [8, 0],
      [0, -17],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -25],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [16, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -25],
      [-8, 0],
      [0, -16],
      [16, 0],
      [0, -9],
      [16, 0],
      [0, -24],
      [-8, 0],
      [0, -25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-10, 1],
      [2, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 16],
      [48, 0],
    ],
    [
      [17058, 2357],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [16, 0],
      [0, -8],
      [16, 0],
      [0, -17],
      [16, 0],
      [0, -32],
      [8, 0],
      [0, -17],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [32, 0],
    ],
    [
      [14631, 17029],
      [215, 73],
      [224, 159],
      [-7, 0],
      [109, 93],
      [14, 16],
    ],
    [
      [20338, 3326],
      [-1355, -3326],
      [-6, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-24, 0],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-18, 0],
      [2, 8],
      [-8, 0],
      [0, 8],
      [-7, 1],
      [-3, 6],
      [-6, 1],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [1, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [1, 17],
      [-9, -1],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [2, 18],
      [-10, -1],
      [0, 17],
      [0, 8],
      [-6, 0],
      [-2, 0],
      [0, 8],
      [-8, 0],
      [1, 8],
      [-9, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 41],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [8, 0],
      [0, 17],
      [9, 0],
      [0, 16],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 41],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-25, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-73, 0],
      [0, -8],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-17, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [16, 0],
      [0, 9],
      [16, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [16, 0],
      [0, 41],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 24],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [1, -16],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -9],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [9, 0],
      [0, -9],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -16],
      [8, 0],
      [0, -8],
      [8, 0],
      [0, -17],
      [8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 24],
      [-32, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 25],
      [-8, 0],
      [0, 19],
      [0, 6],
      [-8, 0],
      [0, 8],
      [-7, 0],
      [-26, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [9, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-24, 1],
      [0, 7],
      [-9, 1],
      [0, 7],
      [-4, 0],
      [-12, 0],
      [0, 12],
      [0, 4],
      [-8, 2],
      [0, 23],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 2],
      [0, 15],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-14, 2],
      [-4, 6],
      [-14, 0],
      [-2, 16],
      [-6, 0],
      [0, 4],
      [0, 13],
      [-10, 0],
      [2, 8],
      [-10, 0],
      [1, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-18, 0],
      [-6, 0],
      [0, 6],
      [0, 10],
      [-8, 0],
      [0, 8],
      [-10, 0],
      [-7, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-3, 0],
      [-6, 0],
      [-1, 8],
      [-2, 0],
      [-21, 0],
      [1, 17],
      [-9, -1],
      [0, 9],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-11, 0],
      [-5, 0],
      [0, 4],
      [0, 4],
      [-5, 0],
      [-11, 0],
      [1, 10],
      [-9, -2],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-3, 0],
      [-5, 0],
      [-2, 6],
      [-6, 4],
      [0, 7],
      [-8, 0],
      [0, 2],
      [0, 6],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-25, 0],
      [0, 17],
      [-16, 0],
      [0, -9],
      [-8, 0],
      [0, 17],
      [-18, 0],
      [2, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 3],
      [0, 22],
      [-8, 0],
      [0, 24],
      [-25, 0],
      [0, 8],
      [-11, 0],
      [-5, 0],
      [0, 6],
      [0, 19],
      [-8, 0],
      [0, 8],
      [-14, 0],
      [-10, 0],
      [0, 10],
      [-16, -2],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 58],
      [-16, 0],
      [0, 8],
      [-33, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-16, 0],
      [0, 33],
      [8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 7],
      [-16, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 57],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-32, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 7],
      [-8, 1],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 32],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, -8],
      [-33, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 7],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 17],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [8, 0],
      [0, 8],
      [-8, 0],
      [0, 12],
      [0, 4],
      [8, 0],
      [0, 8],
      [8, 0],
      [0, 17],
      [8, 0],
      [0, 40],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -16],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [-1, -8],
      [-15, 0],
      [0, -9],
      [-8, 0],
      [0, -16],
      [-8, 0],
      [0, -8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-32, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-32, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-16, 0],
      [0, 17],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 16],
      [-7, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 25],
      [-8, 0],
      [0, 8],
      [8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, -9],
      [-8, 0],
      [0, -8],
      [-24, 0],
      [0, 8],
      [-9, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-32, 0],
      [0, -8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 24],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-25, 0],
      [0, 25],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 16],
      [-25, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 16],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 33],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 33],
      [9, 0],
      [0, 49],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, -8],
      [-25, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-56, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-56, 0],
      [0, 9],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-48, 0],
      [0, 9],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-7, 0],
      [0, 16],
      [-9, 0],
      [0, 9],
      [-40, 0],
      [0, 8],
      [-25, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-24, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-16, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [8, 0],
      [0, 24],
      [-24, 0],
      [0, -8],
      [-8, 0],
      [0, -8],
      [-32, 0],
      [0, 8],
      [-9, 0],
      [0, 8],
      [-8, 0],
      [0, 33],
      [-16, 0],
      [0, 8],
      [-8, 0],
      [0, -8],
      [-16, 0],
      [0, -8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 17],
      [-8, 0],
      [0, 16],
      [-8, 0],
      [0, 8],
      [-24, 0],
      [0, 9],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-17, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 8],
      [-8, 0],
      [0, 9],
      [-8, 0],
      [0, 24],
      [-8, 0],
      [0, 33],
      [-24, 0],
      [0, 8],
      [-24, 0],
      [0, 8],
      [-17, 0],
      [0, 5],
      [57, 2],
      [645, 27],
      [697, -222],
      [485, 59],
      [116, 66],
      [274, 757],
      [-100, 511],
      [-333, 190],
      [-540, 321],
      [-681, 318],
      [-619, -10],
      [-695, -110],
      [-461, 205],
      [268, 335],
      [354, 307],
      [466, 73],
      [666, -25],
      [523, 324],
      [365, 545],
      [406, 135],
      [183, 86],
      [560, 92],
      [229, 431],
      [0, 20],
      [108, 413],
      [-167, 479],
      [-137, 322],
      [-93, 899],
      [115, 467],
      [2, 564],
      [-20, 428],
      [7, 394],
      [-97, 274],
      [-304, 372],
      [-299, -11],
    ],
    [
      [6109, 22760],
      [42, 112],
      [75, 107],
      [157, 120],
      [109, 107],
      [95, 66],
      [69, 60],
      [141, 11],
      [86, -95],
    ],
    [
      [6883, 23248],
      [19, -22],
      [107, -43],
      [140, -118],
      [112, -124],
      [97, -232],
      [71, -232],
      [-31, -237],
      [11, -150],
      [-112, -249],
      [-132, -270],
      [-35, -87],
      [-9, -163],
      [-76, -141],
      [-31, -237],
      [17, -177],
      [-25, -305],
      [-4, -245],
      [66, -89],
      [-23, -176],
      [22, -299],
      [118, -233],
      [144, -233],
      [144, -220],
      [5, -115],
      [242, -399],
      [271, -282],
      [299, -270],
      [286, -155],
      [28, -394],
      [396, -434],
      [298, -304],
      [256, -378],
      [607, -275],
      [343, -13],
      [443, -62],
      [403, -34],
      [342, -46],
      [443, -1],
      [345, 103],
      [196, 78],
      [204, 118],
      [230, 105],
      [338, 83],
      [371, 82],
      [363, 55],
      [130, 119],
      [191, 160],
      [90, 161],
      [38, 161],
    ],
    [
      [15173, 39232],
      [-40, 253],
      [-61, 443],
      [-42, 721],
      [163, 494],
      [111, 229],
      [-4, 136],
      [21, 530],
      [51, 223],
      [23, 54],
      [89, 203],
      [282, 1239],
      [394, 272],
      [210, 44],
      [812, 278],
      [209, 57],
      [629, 173],
      [633, 71],
      [265, 131],
      [-63, 701],
      [-17, 34],
    ],
    [
      [19316, 40029],
      [-98, -96],
      [-198, -159],
      [-371, -116],
      [-298, -138],
      [-400, -163],
      [-277, -83],
      [-174, 44],
      [-322, 53],
      [-268, 52],
      [-410, -27],
      [-896, -80],
      [-431, -84],
    ],
    [
      [14631, 17029],
      [-15, -5],
      [-208, 38],
      [-153, 84],
      [-186, 152],
      [-197, 336],
      [-45, 517],
      [100, 385],
      [242, 397],
      [202, 438],
      [244, 552],
      [236, 499],
      [54, 399],
      [26, 380],
      [11, 317],
    ],
    [
      [14942, 21518],
      [2, 36],
      [47, 440],
      [112, 684],
      [45, 339],
      [208, 363],
      [320, 218],
      [391, 75],
      [592, 51],
      [613, 37],
      [389, -13],
      [766, -54],
      [307, -101],
      [105, -192],
      [165, -165],
      [352, 95],
      [109, 155],
      [83, 134],
      [78, 216],
      [104, 236],
      [160, 296],
      [205, 200],
      [239, 247],
      [321, 314],
      [225, 220],
      [117, 147],
      [24, 80],
    ],
    [
      [21697, 25398],
      [-136, -148],
      [-230, -459],
      [-12, -360],
      [-46, -332],
      [52, -157],
      [-57, -196],
      [107, -9],
      [-299, -192],
      [-79, -277],
      [77, -274],
      [-26, -319],
      [-66, -379],
      [14, -381],
      [207, -112],
      [180, -71],
      [194, -119],
      [224, -283],
      [81, -396],
      [231, -269],
      [119, -138],
      [175, -410],
      [97, -281],
      [-94, -406],
      [-74, -413],
      [-218, -574],
      [-173, -358],
      [-309, -334],
      [-252, -200],
      [-260, -281],
      [-55, -509],
      [-49, -536],
      [-161, -391],
      [-133, -345],
      [-258, -111],
      [-589, -275],
      [-275, 79],
      [-264, -104],
      [-72, -264],
      [-202, -30],
      [-214, -323],
      [-33, -387],
      [-27, -407],
      [-70, -208],
    ],
    [
      [14942, 21518],
      [-19, 16],
      [-209, -24],
      [-80, 8],
      [-208, 31],
      [-243, -44],
      [-357, -27],
      [-309, -2],
      [-392, 292],
      [-158, 207],
      [-172, 172],
      [-244, 323],
      [-192, 173],
      [-290, 358],
      [-249, 392],
      [-177, 308],
      [-196, 357],
      [-292, 256],
      [-135, 348],
      [-75, 328],
      [-297, 364],
      [-260, 148],
      [-261, 79],
      [-282, 32],
      [-227, 58],
      [-254, 113],
      [-139, 124],
      [-418, -47],
      [-214, 85],
      [-207, 58],
      [-252, -179],
      [-287, -294],
      [-160, -329],
      [-170, -568],
      [-107, -399],
      [-221, -356],
      [-158, -173],
      [-105, -284],
      [-43, -174],
    ],
    [
      [6109, 22760],
      [-71, -3],
      [-461, -230],
      [-295, -321],
      [-97, -223],
      [-119, -242],
      [-267, -295],
      [-489, -324],
      [-178, -181],
      [-169, -24],
      [-120, 43],
      [-128, 2],
      [0, -14],
      [-237, -63],
      [-208, -4],
      [-221, 79],
      [-350, 20],
      [-396, 27],
      [-316, 73],
      [-435, 123],
      [-195, 4],
      [-349, 60],
      [-505, 403],
      [-266, 195],
      [-176, 336],
      [-38, 248],
      [-23, 154],
      [99, 325],
      [-18, 530],
      [-35, 368],
      [112, 242],
      [160, 351],
      [400, 626],
      [201, 343],
      [377, 449],
      [154, 330],
      [348, 327],
      [442, 332],
      [411, 415],
      [506, 487],
      [407, 245],
      [562, 214],
      [430, 380],
      [353, 225],
      [323, 396],
      [162, 259],
    ],
    [
      [5394, 29447],
      [596, -197],
      [619, -4],
      [264, 97],
      [258, 145],
      [401, 292],
      [285, 178],
      [350, 8],
      [502, -138],
      [469, -62],
      [393, 136],
      [811, 285],
      [581, 193],
      [672, 362],
      [361, 320],
      [314, 314],
      [652, 396],
      [643, 274],
      [561, 215],
      [683, 191],
      [592, 24],
      [458, 81],
      [16, -5],
    ],
    [
      [15875, 32552],
      [500, -147],
      [385, -291],
      [233, -160],
      [265, -188],
      [441, -619],
      [338, -318],
      [213, -119],
      [25, -130],
      [73, -142],
    ],
    [
      [15875, 32552],
      [143, 240],
      [109, 554],
      [202, 459],
      [53, 304],
      [100, 426],
      [137, 534],
      [109, 521],
      [-65, 544],
      [-252, 249],
      [-649, 161],
      [-305, -118],
      [-261, 73],
      [-128, 96],
    ],
    [
      [15068, 36595],
      [39, 43],
      [60, 338],
      [-55, 334],
      [-54, 443],
      [45, 305],
      [146, 698],
      [-76, 476],
    ],
    [
      [5394, 29447],
      [31, 50],
      [90, 556],
      [220, 683],
      [64, 679],
      [-16, 251],
      [55, 60],
      [86, 373],
      [119, 263],
      [-43, 653],
      [84, 341],
      [42, -3],
      [314, 266],
      [269, 43],
      [360, 191],
      [13, 8],
      [259, 171],
      [221, 309],
      [222, 458],
      [44, 210],
      [59, 325],
      [156, 487],
      [311, 573],
      [342, 347],
      [574, 527],
      [388, 279],
      [258, 145],
      [8, 68],
      [75, 73],
      [373, 198],
      [83, 14],
      [403, 66],
      [605, -71],
      [461, -185],
      [44, -198],
      [194, -99],
      [482, -110],
      [571, -499],
      [412, -354],
      [386, -204],
      [490, -62],
      [365, 48],
      [200, 218],
    ],
    [
      [47042, 43713],
      [-167, -227],
      [-72, -312],
      [-100, -365],
      [-59, -298],
      [-83, -203],
      [-152, -221],
      [-284, -90],
      [-68, -54],
      [-210, -98],
      [-157, -140],
      [-122, -52],
      [-170, -66],
      [-191, -214],
      [-197, -125],
      [-131, -141],
      [-47, -62],
      [-35, -45],
      [-117, -155],
      [-109, -127],
      [-112, -250],
      [-69, -93],
      [-43, -149],
      [24, -191],
      [-23, -190],
      [126, -111],
      [-56, -169],
      [-124, -154],
      [-97, -141],
      [-110, -189],
      [-43, -182],
      [-36, -143],
      [-72, -250],
      [-111, -236],
      [-124, -202],
      [-132, -208],
      [-98, -250],
      [-125, -242],
      [-51, -258],
      [-78, -250],
      [-84, -209],
      [-48, -40],
      [-109, -107],
      [-77, -216],
      [3, -170],
      [108, -43],
      [37, -184],
      [-83, -128],
      [-92, -277],
      [-174, 10],
      [0, 20],
      [-259, -179],
      [-137, -133],
      [-142, -66],
      [-165, -201],
      [-178, -208],
      [-35, -81],
      [-42, -135],
      [-164, -167],
      [-6, -319],
      [-97, -216],
      [-51, -264],
      [-78, -230],
      [-38, -258],
      [2, -285],
      [127, -98],
      [-22, -101],
      [-36, -136],
      [-92, -297],
      [112, -124],
      [106, -111],
      [199, -187],
      [138, -186],
      [78, -219],
      [-3, -169],
      [-7, 0],
      [-102, -100],
      [-48, -47],
      [-7, 0],
      [-114, -12],
      [-137, -86],
      [-5, -333],
      [70, -225],
      [-70, -217],
      [-146, 132],
      [-415, 109],
      [-117, -120],
      [-154, -419],
      [-1, -7],
      [-75, -488],
      [-18, -305],
      [-80, -352],
      [-32, -340],
      [-88, -447],
      [-134, -392],
      [-209, -438],
      [-109, -174],
      [-22, -35],
      [-147, -384],
      [-91, -189],
      [-602, -207],
      [-378, -96],
      [-279, -185],
      [-381, 170],
      [-349, 508],
      [-212, 194],
      [-215, 31],
      [-416, 28],
      [-449, -264],
      [-343, -416],
      [-258, -199],
      [-196, -24],
      [-143, -127],
      [-193, -255],
      [-192, -221],
      [-350, -14],
      [-116, 328],
      [-243, 392],
      [-47, 402],
      [-101, 396],
      [-270, 385],
      [-95, 389],
      [-116, 308],
      [144, 174],
      [273, 233],
      [300, 240],
      [172, 255],
      [57, 169],
      [-107, 172],
    ],
    [
      [35072, 30488],
      [72, 157],
      [88, 453],
      [154, 391],
      [128, 432],
      [-15, 327],
      [-34, 401],
      [24, 617],
      [149, 507],
      [128, 18],
      [278, 131],
      [358, 102],
      [377, 62],
      [199, 275],
      [-66, 435],
      [-43, 293],
      [99, 283],
      [138, 202],
      [-26, 102],
      [63, 148],
      [111, 182],
      [177, 166],
      [42, 95],
      [116, 113],
      [150, 99],
      [269, 57],
      [74, -49],
      [457, 47],
      [244, 111],
      [151, 174],
      [-370, 413],
      [-70, 212],
      [136, 161],
      [86, 249],
      [191, 187],
      [89, 107],
      [61, 54],
      [231, 125],
      [287, 327],
      [300, 240],
      [319, 164],
      [150, 194],
      [-199, 119],
      [-438, -40],
      [-326, 196],
      [-69, 320],
      [-68, 375],
      [66, 365],
      [-235, 4],
      [-422, 48],
      [-45, 177],
      [-36, 266],
      [-63, 245],
      [-187, 105],
      [-230, 323],
      [-60, 449],
      [-337, 386],
      [7, 177],
    ],
    [
      [30038, 42052],
      [489, -106],
      [583, -173],
      [739, 361],
      [383, 261],
      [25, 17],
      [285, 138],
      [728, 524],
      [343, 279],
    ],
  ],
  transform: {
    scale: [0.000034311906816380304, 0.00003393957923308157],
    translate: [4.352344355323371, 5.85625037927238],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4]],
          type: "Polygon",
          properties: {
            name: "Akoko North-East",
            id: "OD001",
            nameAbbr: "Akoko North-East",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.07511863135,
            Shape_Area: 0.03512808144,
          },
        },
        {
          arcs: [[5, 6, -1, 7]],
          type: "Polygon",
          properties: {
            name: "Akoko South-East",
            id: "OD002",
            nameAbbr: "Akoko South-East",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.71269287381,
            Shape_Area: 0.018971002,
          },
        },
        {
          arcs: [[8, 9, 10, -2, -7]],
          type: "Polygon",
          properties: {
            name: "Akoko South-West",
            id: "OD003",
            nameAbbr: "Akoko South-West",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.85488783315,
            Shape_Area: 0.04137220365,
          },
        },
        {
          arcs: [[-4, 11]],
          type: "Polygon",
          properties: {
            name: "Akoko North-West",
            id: "OD004",
            nameAbbr: "Akoko North-West",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.92316993599,
            Shape_Area: 0.04498315155,
          },
        },
        {
          arcs: [[12, 13, 14, 15, 16]],
          type: "Polygon",
          properties: {
            name: "Akure North",
            id: "OD005",
            nameAbbr: "Akure North",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.12404250817,
            Shape_Area: 0.05549383068,
          },
        },
        {
          arcs: [[17, 18, -15]],
          type: "Polygon",
          properties: {
            name: "Akure South",
            id: "OD006",
            nameAbbr: "Akure South",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.83222778413,
            Shape_Area: 0.02688473929,
          },
        },
        {
          arcs: [[19, 20, 21, 22]],
          type: "Polygon",
          properties: {
            name: "Ese-Odo",
            id: "OD007",
            nameAbbr: "Ese-Odo",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25262700633,
            Shape_Area: 0.05864585872,
          },
        },
        {
          arcs: [[-18, -14, 23, 24, 25, 26, 27, 28, 29, 30]],
          type: "Polygon",
          properties: {
            name: "Idanre",
            id: "OD008",
            nameAbbr: "Idanre",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.94313001853,
            Shape_Area: 0.15472679875,
          },
        },
        {
          arcs: [[31, -16, -19, -31, 32, 33]],
          type: "Polygon",
          properties: {
            name: "Ifedore",
            id: "OD009",
            nameAbbr: "Ifedore",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.65917227332,
            Shape_Area: 0.0200836717,
          },
        },
        {
          arcs: [[[34]], [[35]], [[36]], [[37]], [[38, -21, 39, 40, 41]]],
          type: "MultiPolygon",
          properties: {
            name: "Ilaje",
            id: "OD010",
            nameAbbr: "Ilaje",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.00351135553,
            Shape_Area: 0.11983541643,
          },
        },
        {
          arcs: [[42, -33, -30, 43]],
          type: "Polygon",
          properties: {
            name: "Ile-Oluji/Okeigbo",
            id: "OD011",
            nameAbbr: "Ile-Oluji/Okeigbo",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.6606196158,
            Shape_Area: 0.02623036561,
          },
        },
        {
          arcs: [[-22, -39, 44, 45, -27, 46]],
          type: "Polygon",
          properties: {
            name: "Irele",
            id: "OD012",
            nameAbbr: "Irele",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.25607388503,
            Shape_Area: 0.066358443,
          },
        },
        {
          arcs: [[-46, 47, -41, 48, 49, 50, -28]],
          type: "Polygon",
          properties: {
            name: "Odigbo",
            id: "OD013",
            nameAbbr: "Odigbo",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.04145621297,
            Shape_Area: 0.15362712506,
          },
        },
        {
          arcs: [[-45, -42, -48]],
          type: "Polygon",
          properties: {
            name: "Okitipupa",
            id: "OD014",
            nameAbbr: "Okitipupa",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.05806189559,
            Shape_Area: 0.06306831043,
          },
        },
        {
          arcs: [[-51, 51, 52, -44, -29]],
          type: "Polygon",
          properties: {
            name: "Ondo East",
            id: "OD015",
            nameAbbr: "Ondo East",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.8940933041,
            Shape_Area: 0.02766955873,
          },
        },
        {
          arcs: [[-52, -50, 53]],
          type: "Polygon",
          properties: {
            name: "Ondo West",
            id: "OD016",
            nameAbbr: "Ondo West",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.12910843093,
            Shape_Area: 0.06908346838,
          },
        },
        {
          arcs: [[54, -25, 55, -9, -6]],
          type: "Polygon",
          properties: {
            name: "Ose",
            id: "OD017",
            nameAbbr: "Ose",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.13178536383,
            Shape_Area: 0.12580124995,
          },
        },
        {
          arcs: [[-56, -24, -13, 56, -10]],
          type: "Polygon",
          properties: {
            name: "Owo",
            id: "OD018",
            nameAbbr: "Owo",
            parent: "Ondo",
            parentAbbr: "OD",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.41696232531,
            Shape_Area: 0.08065165379,
          },
        },
      ],
    },
  },
};

const bauchi = {
  type: "Topology",
  arcs: [
    [
      [62748, 20710],
      [-373, -789],
      [-459, -49],
      [-1375, -249],
      [-936, -430],
      [-616, -903],
      [153, -762],
      [1058, -1249],
      [965, -820],
      [1175, -666],
      [1180, -965],
      [975, -718],
      [1215, -878],
      [935, -781],
      [393, -876],
      [89, -460],
      [98, -455],
      [94, -1212],
      [194, -1710],
      [69, -116],
      [184, -404],
      [173, -384],
      [420, -802],
      [370, -787],
      [796, -1004],
      [-61, -449],
      [-275, -75],
      [-245, -67],
      [-525, -6],
      [-1108, 90],
      [-1183, 234],
      [-1049, 246],
      [-800, 32],
      [-836, -401],
      [-1091, -420],
      [-1178, -578],
      [-811, -360],
      [-1071, -531],
      [-999, 73],
      [-1106, 293],
      [-650, 3],
      [-809, -403],
      [-1087, -601],
      [-263, -248],
      [-71, -73],
      [-877, 524],
      [-815, 489],
      [-1230, 678],
      [-951, 618],
      [-931, 625],
      [-1026, 666],
      [-1321, 637],
      [-970, 387],
      [-992, 340],
      [-1093, 497],
      [-837, 379],
      [-972, 598],
      [-1029, 585],
      [-1156, 505],
      [-1202, 642],
      [-1210, 241],
      [-1376, 685],
      [-152, 79],
    ],
    [
      [36167, 9175],
      [199, 837],
      [402, 906],
      [250, 1301],
      [357, 1435],
      [419, 1028],
      [463, 802],
      [836, 607],
      [938, 18],
      [726, -2],
      [1507, -127],
      [851, -112],
      [1039, -284],
      [646, -119],
      [850, -119],
      [593, 47],
      [313, 191],
      [28, 1003],
      [-95, 1140],
      [417, 1262],
      [358, 1350],
      [-89, 1509],
      [15, 713],
      [105, 937],
      [3, 418],
    ],
    [
      [47298, 23916],
      [156, 16],
      [732, 12],
      [797, -105],
      [810, -227],
      [910, -643],
      [726, -871],
      [894, -914],
      [825, -737],
      [472, -298],
      [579, -224],
      [623, 33],
      [637, 32],
      [889, -601],
      [620, -327],
      [821, 227],
      [1472, 444],
      [777, 480],
      [256, 106],
      [430, 178],
      [373, 105],
      [479, 14],
      [709, 53],
      [463, 41],
    ],
    [
      [45763, 28931],
      [-25, -184],
      [137, -287],
      [238, -668],
      [487, -950],
      [337, -588],
      [293, -696],
      [135, -769],
      [-67, -781],
      [0, -92],
    ],
    [
      [36167, 9175],
      [-1271, 660],
      [-287, 162],
      [-203, 114],
    ],
    [
      [34406, 10111],
      [-39, 167],
      [-46, 232],
      [-93, 164],
      [-798, 881],
      [-844, 779],
      [-1162, 893],
      [-1151, 709],
      [-1379, 1222],
      [-1033, 1040],
      [-545, 673],
      [-3, 558],
      [-78, 273],
      [-11, 503],
      [204, 412],
      [226, 513],
      [62, 157],
      [175, 818],
      [-11, 517],
      [-9, 97],
      [-35, 134],
      [-142, 233],
      [-201, 425],
      [-182, 210],
    ],
    [
      [27311, 21721],
      [-38, 141],
      [-42, 159],
      [10, 636],
      [344, 858],
      [82, 658],
      [-23, 842],
      [-92, 1160],
      [-108, 963],
      [-148, 1208],
      [-122, 900],
      [-87, 683],
      [-163, 461],
      [-326, 447],
      [-32, 41],
    ],
    [
      [26566, 30878],
      [722, 212],
      [1185, -161],
      [1352, -92],
      [1362, -264],
      [1071, -375],
      [909, -389],
      [230, -456],
      [269, -543],
      [385, -625],
      [219, -685],
      [239, -437],
      [189, -415],
      [321, -351],
      [442, -200],
      [158, -7],
      [532, 26],
      [1135, 244],
      [971, 538],
      [1070, 424],
      [625, 237],
      [832, 236],
      [981, 234],
      [553, 171],
      [265, 58],
      [807, 73],
      [222, 66],
      [463, -39],
      [256, -10],
      [244, 73],
      [108, 46],
      [481, 185],
      [72, 47],
      [447, 178],
      [80, 54],
    ],
    [
      [32544, 4843],
      [-223, -89],
      [-631, -141],
      [-546, -726],
      [-309, -745],
      [-505, -989],
      [-228, -67],
      [-1114, -331],
      [-723, -270],
      [-671, 3],
      [-511, -26],
      [-1352, -145],
      [-1389, -43],
      [-803, -65],
      [-1451, 354],
      [-451, 42],
      [-581, 261],
    ],
    [
      [21056, 1866],
      [195, 287],
      [102, 566],
      [384, 539],
      [558, 749],
      [403, 757],
      [207, 661],
      [377, 682],
      [230, 306],
      [535, 339],
      [705, -64],
      [673, -64],
      [836, 105],
      [1131, 238],
      [768, -174],
      [1177, -88],
      [1039, -409],
      [381, -175],
      [449, -210],
      [513, -198],
      [394, -182],
      [196, -342],
      [210, -309],
      [25, -37],
    ],
    [
      [66453, 58968],
      [-86, -561],
      [-499, -1279],
      [-57, -901],
      [-199, -747],
    ],
    [
      [65612, 55480],
      [-30, 1],
      [-269, 76],
      [-377, 209],
      [-585, 401],
      [-227, 116],
      [-371, 241],
      [-386, 468],
      [-260, 350],
      [-361, 101],
      [-534, -87],
      [-625, 15],
      [-285, 45],
      [-291, -77],
      [-62, -46],
    ],
    [
      [60949, 57293],
      [-57, 176],
      [-101, 511],
      [-66, 503],
      [-121, 409],
      [-195, 91],
      [-444, -27],
      [-208, -51],
      [-452, 189],
      [-217, 159],
      [-258, 208],
      [-252, 275],
      [-167, 43],
      [-202, 146],
      [-35, 75],
      [-175, 158],
      [-94, 20],
    ],
    [
      [57905, 60178],
      [58, 239],
      [-358, 534],
      [-253, 439],
      [-274, 479],
      [-239, 390],
      [-273, 350],
      [-80, 469],
      [4, 387],
      [275, 403],
      [106, 111],
    ],
    [
      [56871, 63979],
      [156, 164],
      [498, 318],
      [705, 424],
      [553, 298],
      [553, 236],
      [464, 183],
      [402, 185],
      [698, 308],
      [548, 135],
      [513, 114],
      [381, 83],
      [450, 184],
      [512, 237],
      [207, 92],
      [332, 191],
      [56, 19],
    ],
    [
      [63899, 67150],
      [245, -389],
      [388, -875],
      [672, -1808],
      [784, -1850],
      [483, -1514],
      [37, -1385],
      [-55, -361],
    ],
    [
      [65612, 55480],
      [-164, -615],
      [-43, -919],
      [291, -408],
      [477, -312],
      [739, -86],
      [689, -89],
      [-26, -26],
      [-235, -136],
      [-114, 52],
      [-195, -367],
      [-554, -689],
      [-34, -42],
      [-491, -669],
      [-965, -540],
      [-964, -528],
      [-917, -475],
      [-704, -886],
      [-507, -852],
      [-944, -822],
      [-701, -358],
      [-955, -112],
      [-888, -304],
      [-679, -639],
      [-645, -953],
      [-333, -1315],
      [-126, -1009],
      [-379, -1018],
      [-533, -1335],
      [-316, -1141],
      [-843, -1135],
      [-35, -50],
    ],
    [
      [54518, 37702],
      [-76, -3],
      [-654, -24],
      [-414, -2],
      [-304, 160],
      [-449, 339],
      [-357, 283],
      [-253, 336],
      [-71, 191],
      [7, 305],
      [279, 370],
      [246, 145],
      [535, 115],
      [443, -148],
      [481, -95],
      [358, 8],
      [470, 116],
      [126, 79],
      [238, 92],
      [203, 92],
      [274, 132],
      [182, 194],
      [21, 345],
      [-119, 198],
      [-175, 145],
      [-301, 38],
      [-316, -117],
      [-364, -151],
      [-266, -105],
      [-617, -303],
      [-505, -155],
      [-599, -87],
      [-602, 130],
      [-438, 94],
      [-639, 217],
      [-338, 211],
      [-325, 162],
      [-491, 223],
      [-494, 254],
      [-241, 122],
      [-337, 129],
      [-399, 227],
      [-337, 305],
      [-325, 224],
      [-333, 218],
      [-83, 153],
      [-322, 312],
      [-208, 349],
      [-22, 309],
      [-61, 599],
      [-57, 545],
      [-43, 517],
      [-19, 293],
      [-63, 347],
      [-174, 396],
      [-111, 240],
    ],
    [
      [46079, 46771],
      [504, 101],
      [882, 265],
      [680, 364],
      [869, 652],
      [520, 1282],
      [-49, 69],
    ],
    [
      [49485, 49504],
      [441, 204],
      [700, 200],
      [582, 175],
      [651, 11],
      [441, 367],
      [778, 57],
      [679, 452],
      [442, 258],
      [770, 165],
      [329, 172],
      [469, 278],
      [297, 179],
      [-39, 686],
      [-95, 714],
      [25, 319],
      [178, 364],
      [203, 702],
      [122, 494],
      [149, 507],
      [513, 149],
      [610, 59],
      [610, 86],
      [540, 209],
      [283, 193],
      [644, 221],
      [368, 90],
      [298, 104],
      [303, 247],
      [173, 127],
    ],
    [
      [20281, 11912],
      [-131, -5],
      [19, 82],
      [-196, 314],
      [-169, 600],
      [-163, 226],
      [-6, 9],
      [-243, 380],
      [-132, 169],
      [-73, 772],
      [249, 632],
      [281, 787],
      [299, 779],
      [552, 838],
      [308, 320],
      [321, 222],
      [76, 43],
      [551, 221],
      [403, 142],
      [328, 30],
      [295, 129],
    ],
    [
      [22850, 18602],
      [23, -17],
      [616, -179],
      [459, -322],
      [529, -344],
      [812, -433],
      [288, -220],
      [142, -218],
      [112, -294],
      [-5, -292],
      [-92, -88],
      [-293, -40],
      [-194, 42],
      [-113, -88],
      [15, -306],
      [203, -301],
      [46, -163],
      [-91, -374],
      [-171, -197],
      [-64, -102],
      [335, -253],
      [709, -38],
      [495, 52],
      [83, -375],
      [-127, -435],
      [-99, -456],
      [-126, -408],
      [-74, -251],
      [-152, -326],
      [-145, -258],
      [-353, -264],
      [-111, -26],
      [-471, 118],
      [-560, 167],
      [-416, 50],
      [-590, 29],
      [-639, 3],
      [-578, -44],
      [-641, -79],
      [-382, -25],
      [-284, 97],
      [-416, 9],
      [-230, -40],
      [-19, -1],
    ],
    [
      [60087, 83739],
      [138, -871],
      [121, -1810],
      [-86, -849],
      [835, -1118],
      [651, -1064],
      [116, -1603],
      [-17, -1554],
      [155, -1264],
      [446, -1269],
      [-49, -909],
      [145, -905],
      [234, -674],
      [347, -1004],
      [481, -1228],
      [295, -467],
    ],
    [
      [56871, 63979],
      [-703, -163],
      [-207, -153],
      [-290, -159],
      [-271, -30],
      [-214, -78],
      [-462, 563],
      [-50, 116],
      [-189, 329],
      [-245, 221],
      [-218, 294],
      [-85, 334],
      [-474, 272],
      [-531, 550],
      [-203, 193],
      [-322, 351],
      [-378, 467],
      [-142, 498],
      [-419, 264],
      [-183, 369],
      [-12, 11],
    ],
    [
      [51273, 68228],
      [67, 171],
      [254, 390],
      [282, 295],
      [131, 174],
      [395, 110],
      [698, 390],
      [-322, 392],
      [-439, 176],
      [-700, -17],
      [-669, 28],
      [-26, 8],
    ],
    [
      [50944, 70345],
      [-308, 714],
      [54, 511],
      [4, 1001],
      [-436, 1033],
      [-235, 702],
      [-130, 543],
      [220, 637],
      [380, 744],
      [186, 830],
      [-134, 636],
      [-547, 760],
      [-383, 258],
      [-663, -75],
      [-565, -228],
      [-398, 59],
      [-281, 390],
      [347, 644],
      [612, 778],
      [552, 871],
      [490, 817],
      [640, 778],
      [909, 567],
      [433, 180],
      [582, 135],
      [329, 120],
      [441, 128],
      [328, 140],
      [271, 160],
      [186, 167],
      [187, 119],
      [257, -72],
      [110, -351],
      [165, -317],
      [426, -244],
      [808, 142],
      [660, 366],
      [658, 228],
      [444, 41],
      [213, -5],
      [344, -145],
      [440, -65],
      [1042, -302],
      [382, -5],
      [123, 4],
    ],
    [
      [54518, 37702],
      [-821, -1200],
      [-96, -650],
      [952, -347],
    ],
    [
      [54553, 35505],
      [-608, -492],
      [-358, -349],
      [-351, -363],
      [-324, -254],
      [-552, -530],
      [-474, -443],
      [-750, 253],
      [-1142, -163],
      [-1030, -266],
      [-721, -393],
      [-334, -308],
      [-681, -712],
      [-418, -497],
      [-640, -1044],
      [-372, -749],
      [-35, -264],
    ],
    [
      [26566, 30878],
      [-579, 745],
      [-319, 750],
      [-189, 997],
      [-64, 1341],
      [262, 1203],
      [219, 1013],
      [146, 747],
      [303, 793],
      [162, 536],
      [149, 351],
    ],
    [
      [26656, 39354],
      [107, 252],
      [244, 725],
      [41, 605],
      [162, 910],
      [56, 1278],
      [46, 850],
      [17, 428],
      [-103, 546],
      [9, 401],
      [-37, 360],
    ],
    [
      [27198, 45709],
      [16, 1],
      [375, -106],
      [550, -204],
      [588, -149],
      [641, 20],
      [847, 18],
      [639, -61],
      [535, 48],
      [590, -34],
      [618, -54],
      [647, -55],
      [361, -106],
      [355, -45],
      [221, 16],
    ],
    [
      [34181, 44998],
      [-2, -72],
      [378, -964],
      [635, -640],
      [1593, -378],
      [1093, -182],
      [1870, 427],
      [764, 229],
      [737, -84],
      [875, -636],
      [1158, -293],
      [876, 268],
      [-208, 724],
      [-316, 888],
      [20, 849],
      [721, 896],
      [1190, 639],
      [514, 102],
    ],
    [
      [48935, 59527],
      [-151, 202],
      [-154, 91],
      [-396, 60],
      [-659, -418],
      [-105, -453],
      [62, -327],
      [413, -271],
      [195, -566],
      [13, -611],
      [-93, -847],
      [-127, -664],
      [-105, -426],
      [-190, -357],
      [-168, -452],
      [-353, -1359],
      [-154, -507],
      [-198, -805],
      [-148, -670],
      [-50, -556],
      [-85, -357],
    ],
    [
      [46482, 50234],
      [-1616, 151],
      [-2693, 595],
      [-836, 130],
    ],
    [
      [41337, 51110],
      [315, 828],
      [474, 1114],
      [471, 1011],
      [274, 736],
      [260, 621],
      [533, 494],
      [392, 205],
      [469, 230],
      [477, 421],
      [259, 329],
      [197, 696],
      [-307, 677],
      [-349, 73],
      [-168, 213],
      [-41, 577],
      [-216, 702],
      [15, 543],
      [-84, 442],
      [15, 475],
      [1, 11],
    ],
    [
      [44324, 61508],
      [202, -14],
      [175, 86],
      [273, 37],
      [336, 42],
      [413, 35],
      [707, 51],
      [479, 0],
      [661, -125],
      [416, -33],
      [549, -8],
      [466, -231],
      [59, -354],
      [-38, -637],
      [-24, -542],
      [-63, -288],
    ],
    [
      [51273, 68228],
      [-70, -179],
      [-400, -389],
      [-428, -312],
      [-470, -231],
      [-257, 38],
      [-154, 84],
      [-501, 224],
      [-759, 296],
      [-212, 81],
      [-674, 65],
      [-301, -101],
      [-632, -121],
      [-377, -148],
      [-403, -233],
      [-445, -254],
      [-704, -320],
      [-637, 4],
      [-537, 211],
      [-481, 237],
      [-640, 124],
      [-723, 256],
      [-638, 283],
      [-571, 164],
      [-509, -15],
      [-475, -221],
      [-679, -546],
      [-444, -240],
      [-291, -169],
    ],
    [
      [37861, 66816],
      [-18, -11],
      [-227, -41],
      [-402, 92],
      [-306, -15],
      [-470, -54],
      [-762, -16],
      [-679, 71],
      [-742, -3],
      [-799, -9],
      [-724, -122],
      [-397, -121],
      [-699, -294],
      [-375, -386],
      [-238, -281],
    ],
    [
      [31023, 65626],
      [-993, -97],
      [-999, -483],
      [-53, -1],
      [-1176, -19],
      [-129, 61],
      [-504, 235],
      [-59, 637],
      [285, 1362],
      [651, 1079],
      [511, 686],
      [357, 361],
      [509, 2],
      [842, -17],
      [1549, 230],
      [1161, 681],
      [1416, 747],
      [1564, 625],
      [390, 157],
      [1865, 530],
      [1330, 436],
      [1053, 441],
      [1298, 575],
      [582, 161],
      [558, 440],
      [-5, 468],
    ],
    [
      [43026, 74923],
      [306, -149],
      [245, -119],
      [381, -429],
      [208, -298],
      [222, -536],
      [304, -669],
      [123, -550],
      [216, -537],
      [278, -317],
      [321, -78],
      [272, -119],
      [489, -31],
      [732, -91],
      [879, -63],
      [782, -130],
      [603, -58],
      [747, -156],
      [810, -248],
    ],
    [
      [37861, 66816],
      [40, -38],
      [687, -415],
      [554, -210],
      [333, -224],
      [771, -336],
      [692, -296],
      [504, -224],
      [213, -245],
      [-110, -491],
      [-288, -491],
      [-469, -386],
      [-379, -254],
      [-600, -287],
      [-425, -127],
      [-422, 58],
      [-489, 171],
      [-424, 144],
      [-493, 72],
      [-337, -273],
      [-277, -346],
      [-122, -279],
      [-267, -465],
      [-278, -306],
      [-462, -386],
      [-585, -394],
      [-512, -148],
      [-512, -82],
      [-412, -23],
      [-195, 0],
      [-412, 17],
      [-452, 65],
    ],
    [
      [32733, 60617],
      [376, 705],
      [-80, 1128],
      [-164, 896],
      [-260, 1027],
      [-242, 729],
      [-186, 318],
      [-855, 235],
      [-299, -29],
    ],
    [
      [57905, 60178],
      [-50, -208],
      [-118, -72],
      [-213, -221],
      [-318, -260],
      [-422, -177],
      [-388, -117],
      [-298, -77],
      [-423, -41],
      [-437, -89],
      [-720, -254],
      [-721, -85],
      [-507, -74],
      [-845, -110],
      [-791, -42],
      [-493, 0],
      [-493, -47],
      [-422, -137],
      [-404, 108],
      [-454, 441],
      [-121, 463],
      [-329, 344],
      [-3, 4],
    ],
    [
      [44324, 61508],
      [-57, 4],
      [-315, 133],
      [-335, 324],
      [-490, 109],
      [-147, 43],
      [-322, -77],
      [-288, -457],
      [-154, -290],
      [-323, -464],
      [-287, 66],
      [-434, 169],
      [-433, 189],
      [-336, 372],
      [-626, 249],
      [-602, 98],
      [-351, -227],
      [-324, -498],
      [-239, -426],
      [-205, -405],
      [-167, -166],
      [-466, -486],
      [-426, -575],
      [20, -407],
      [-124, -522],
      [-47, -332],
      [136, -539],
      [131, -369],
      [143, -484],
      [179, -444],
      [195, -295],
      [-102, -264],
      [-145, -283],
      [3, -381],
      [-451, -266],
      [-37, -115],
      [-25, -367],
      [-224, -337],
      [-23, -52],
    ],
    [
      [36626, 53736],
      [-578, 882],
      [-782, 1077],
      [-497, 1175],
      [-220, 1206],
      [-1253, -352],
      [-556, -89],
      [-147, 138],
      [-410, 384],
      [-188, 517],
      [-69, 298],
      [259, 659],
      [501, 899],
      [47, 87],
    ],
    [
      [62370, 27318],
      [50, -107],
      [386, -1044],
      [-60, -1301],
      [-128, -1505],
      [-38, -1262],
      [188, -1347],
      [-20, -42],
    ],
    [
      [54553, 35505],
      [111, -40],
      [1055, -408],
      [1357, -616],
      [536, -801],
      [215, -1129],
      [2, -1221],
      [192, -1020],
      [417, -542],
      [842, -310],
      [1482, -414],
      [1065, -510],
      [543, -1176],
    ],
    [
      [49485, 49504],
      [-440, 617],
      [-1953, 56],
      [-610, 57],
    ],
    [
      [27373, 51429],
      [53, -340],
      [17, -170],
      [-18, -204],
      [-80, -394],
      [-100, -359],
      [-202, -495],
      [-371, -737],
      [-136, -686],
      [167, -539],
      [304, -779],
      [87, -273],
      [88, -579],
      [16, -165],
    ],
    [
      [26656, 39354],
      [-63, -5],
      [-257, 3],
      [-265, -73],
      [-402, -124],
      [-424, -145],
      [-411, -193],
      [-452, -131],
      [-546, -171],
      [-805, -229],
      [-561, -178],
      [-603, -211],
      [-1015, -142],
      [-898, -17],
      [-842, -97],
      [-1099, -55],
      [-611, 4],
      [-627, -44],
      [-708, -344],
      [-178, -210],
      [-1166, -329],
      [-620, -106],
      [-1245, -82],
      [-1461, -156],
      [-1705, 187],
      [-485, 445],
      [-293, 595],
      [-383, 323],
      [-207, 56],
      [-377, 102],
      [0, -5],
      [-85, 28],
      [-598, 345],
      [-824, 225],
      [-568, 148],
      [-745, -66],
      [-447, -115],
      [-442, -278],
      [-308, -283],
      [-444, -217],
      [-469, -306],
      [-255, -165],
    ],
    [
      [2762, 37338],
      [-147, 152],
      [-638, 595],
      [-524, 766],
      [-213, 933],
      [-114, 1396],
      [-72, 1844],
      [29, 243],
      [181, 1407],
      [351, 1365],
      [401, 780],
      [225, 94],
      [255, 108],
      [1274, -74],
      [957, 238],
      [484, 503],
      [807, 1009],
      [613, 1122],
      [637, 810],
      [492, 358],
      [0, 11],
      [81, 220],
      [236, 158],
      [268, 180],
      [526, 514],
      [627, 516],
      [316, 518],
      [1169, 737],
      [249, 44],
      [546, 94],
      [537, -498],
      [536, -46],
      [990, 354],
      [513, 287],
      [36, 7],
      [990, 361],
      [67, 13],
      [474, 89],
      [1048, -211],
      [1774, -306],
      [1474, -588],
      [1545, -462],
      [479, -83],
      [1577, 688],
      [1082, -346],
      [570, -973],
      [271, -749],
      [1471, -98],
      [161, 11],
    ],
    [
      [41337, 51110],
      [-687, 106],
      [-1482, 127],
      [-1410, 868],
      [-978, 1290],
      [-154, 235],
    ],
    [
      [34406, 10111],
      [-290, 163],
      [-586, -291],
      [-842, -552],
      [-139, -678],
      [459, -427],
      [545, -681],
      [486, -808],
      [130, -824],
      [-239, -785],
      [-736, -126],
      [-650, -259],
    ],
    [
      [21056, 1866],
      [-95, 42],
      [-868, 43],
      [-35, 1],
      [-881, 58],
      [-473, 23],
      [-715, 250],
      [-374, 309],
      [-153, 137],
      [-207, 186],
      [-163, 355],
      [-221, 526],
      [-246, 635],
      [-148, 410],
      [-161, 273],
      [-260, 274],
      [-370, 337],
      [-505, 214],
      [-721, 250],
      [-620, 202],
      [-284, 404],
      [-5, 463],
      [47, 334],
      [76, 197],
      [66, 169],
      [342, 723],
      [371, 551],
      [127, 226],
    ],
    [
      [14580, 9458],
      [465, -132],
      [175, 88],
      [324, 108],
      [452, -8],
      [742, -10],
      [630, 121],
      [511, 169],
      [205, 47],
      [380, 236],
      [600, 467],
      [346, 291],
      [325, 284],
      [270, 299],
      [53, 94],
      [223, 400],
    ],
    [
      [22850, 18602],
      [298, 129],
      [43, 33],
      [330, 145],
      [430, 244],
      [430, 174],
      [1021, 350],
      [427, -33],
      [130, 20],
      [38, 5],
      [183, 79],
      [170, 113],
      [-164, 186],
      [-247, 214],
      [-179, 297],
      [-42, 67],
      [-116, 158],
      [-44, 282],
      [80, 305],
      [502, 149],
      [357, 241],
      [111, 25],
      [113, 6],
      [42, -24],
      [205, 42],
      [92, 9],
      [205, -34],
      [28, -43],
      [18, -20],
    ],
    [
      [14580, 9458],
      [139, 245],
      [52, 260],
      [57, 293],
      [-129, 977],
      [-28, 703],
      [34, 1124],
      [-124, 699],
      [-333, 776],
      [-405, 551],
      [-441, 354],
      [-499, 99],
      [-703, -82],
      [-993, -212],
      [-1112, -249],
      [-1350, -174],
      [-574, -162],
      [-172, -48],
      [-261, -107],
      [10, 1094],
      [31, 3513],
      [-22, 88],
      [-60, 657],
      [60, 489],
      [-77, 744],
      [-9, 489],
      [-135, 594],
      [-147, 438],
      [-321, 564],
      [-393, 652],
      [-349, 559],
      [-164, 182],
      [-560, 244],
      [-749, 174],
      [-719, 45],
      [-779, -10],
      [-535, -81],
      [-186, -147],
      [-274, 297],
      [-258, 249],
      [-327, 369],
      [-333, 362],
      [-437, 294],
      [-369, 131],
      [-505, 400],
      [-131, 344],
      [117, 300],
      [385, 535],
      [376, 582],
      [229, 560],
      [56, 470],
      [265, 967],
      [-40, 379],
      [-29, 48],
      [125, 35],
      [338, 229],
      [353, 657],
      [325, 419],
      [544, 849],
      [395, 868],
      [231, 947],
      [-37, 942],
      [-362, 782],
      [-509, 530],
    ],
    [
      [27373, 51429],
      [512, 35],
      [1123, 77],
      [1831, 103],
      [675, 136],
      [1008, 289],
      [536, -142],
      [584, -857],
      [673, -1443],
      [252, -1010],
      [-57, -1388],
      [-311, -1324],
      [-18, -907],
    ],
    [
      [43026, 74923],
      [-7, 586],
      [-268, 881],
      [441, 1261],
      [688, 2383],
      [474, 1104],
      [244, 569],
      [459, 1341],
      [283, 1341],
      [160, 587],
      [106, 388],
      [304, 664],
      [28, 21],
      [514, 286],
      [789, 321],
      [804, 281],
      [884, 242],
      [756, 16],
      [465, -383],
      [392, -768],
      [56, -649],
      [536, 273],
      [477, 333],
      [500, 280],
      [744, 414],
      [937, 554],
      [689, 565],
      [702, 407],
      [623, 415],
      [562, -556],
      [1067, -640],
      [1117, -865],
      [896, -832],
      [539, -1371],
      [100, -633],
    ],
  ],
  transform: {
    scale: [0.00003431211687690682, 0.00003393960377055281],
    translate: [8.69466430089436, 9.494235682009682],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2]],
          type: "Polygon",
          properties: {
            name: "Alkaleri",
            id: "BC001",
            nameAbbr: "Alkaleri",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.50260525857,
            Shape_Area: 0.50353436168,
          },
        },
        {
          arcs: [[3, -2, 4, 5, 6, 7]],
          type: "Polygon",
          properties: {
            name: "Bauchi",
            id: "BC002",
            nameAbbr: "Bauchi",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.6515026787,
            Shape_Area: 0.32682107741,
          },
        },
        {
          arcs: [[8, 9]],
          type: "Polygon",
          properties: {
            name: "Bogoro",
            id: "BC003",
            nameAbbr: "Bogoro",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.9662893851,
            Shape_Area: 0.0536459472,
          },
        },
        {
          arcs: [[10, 11, 12, 13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Damban",
            id: "BC004",
            nameAbbr: "Damban",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.18160108327,
            Shape_Area: 0.08159313417,
          },
        },
        {
          arcs: [[16, 17, 18, 19, -12]],
          type: "Polygon",
          properties: {
            name: "Darazo",
            id: "BC005",
            nameAbbr: "Darazo",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.51074974634,
            Shape_Area: 0.20592569914,
          },
        },
        {
          arcs: [[20, 21]],
          type: "Polygon",
          properties: {
            name: "Dass",
            id: "BC006",
            nameAbbr: "Dass",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.86700639655,
            Shape_Area: 0.04341391549,
          },
        },
        {
          arcs: [[22, -15, 23, 24, 25]],
          type: "Polygon",
          properties: {
            name: "Gamawa",
            id: "BC007",
            nameAbbr: "Gamawa",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.22302540097,
            Shape_Area: 0.24647661638,
          },
        },
        {
          arcs: [[26, 27, -8, 28, 29, 30, 31, -18]],
          type: "Polygon",
          properties: {
            name: "Gamjuwa",
            id: "BC008",
            nameAbbr: "Gamjuwa",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.36487941117,
            Shape_Area: 0.43672702202,
          },
        },
        {
          arcs: [[32, 33, 34, 35]],
          type: "Polygon",
          properties: {
            name: "Giade",
            id: "BC009",
            nameAbbr: "Giade",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.20969247669,
            Shape_Area: 0.05415453981,
          },
        },
        {
          arcs: [[36, 37, 38, 39, -25]],
          type: "Polygon",
          properties: {
            name: "Itas/Gadau",
            id: "BC010",
            nameAbbr: "Itas/Gadau",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.1031120398,
            Shape_Area: 0.12773364384,
          },
        },
        {
          arcs: [[40, 41, -38]],
          type: "Polygon",
          properties: {
            name: "Jama'are",
            id: "BC011",
            nameAbbr: "Jama'are",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.98763660322,
            Shape_Area: 0.04537896438,
          },
        },
        {
          arcs: [[-14, 42, -36, 43, 44, -41, -37, -24]],
          type: "Polygon",
          properties: {
            name: "Katagum",
            id: "BC012",
            nameAbbr: "Katagum",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.9282579331,
            Shape_Area: 0.1611885313,
          },
        },
        {
          arcs: [[45, -3, -4, -28, 46]],
          type: "Polygon",
          properties: {
            name: "Kirfi",
            id: "BC013",
            nameAbbr: "Kirfi",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.84724922767,
            Shape_Area: 0.20775374437,
          },
        },
        {
          arcs: [[-20, 47, -33, -43, -13]],
          type: "Polygon",
          properties: {
            name: "Misau",
            id: "BC014",
            nameAbbr: "Misau",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.55224856506,
            Shape_Area: 0.10037616398,
          },
        },
        {
          arcs: [[48, -30, 49, 50]],
          type: "Polygon",
          properties: {
            name: "Ningi",
            id: "BC015",
            nameAbbr: "Ningi",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.68645622479,
            Shape_Area: 0.42805704184,
          },
        },
        {
          arcs: [[51, -44, -35]],
          type: "Polygon",
          properties: {
            name: "Shira",
            id: "BC016",
            nameAbbr: "Shira",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.15721319543,
            Shape_Area: 0.0749557364,
          },
        },
        {
          arcs: [[-6, 52, -10, 53, 54, -22, 55]],
          type: "Polygon",
          properties: {
            name: "Tafawa-Balewa",
            id: "BC017",
            nameAbbr: "Tafawa-Balewa",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.78855330983,
            Shape_Area: 0.17960287501,
          },
        },
        {
          arcs: [[-29, -7, -56, -21, -55, 56, -50]],
          type: "Polygon",
          properties: {
            name: "Toro",
            id: "BC018",
            nameAbbr: "Toro",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.70107950705,
            Shape_Area: 0.58474848527,
          },
        },
        {
          arcs: [[-31, -49, 57]],
          type: "Polygon",
          properties: {
            name: "Warji",
            id: "BC019",
            nameAbbr: "Warji",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.89312587835,
            Shape_Area: 0.05363602928,
          },
        },
        {
          arcs: [[-26, -40, 58]],
          type: "Polygon",
          properties: {
            name: "Zaki",
            id: "BC020",
            nameAbbr: "Zaki",
            parent: "Bauchi",
            parentAbbr: "BC",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.313428628,
            Shape_Area: 0.13995645004,
          },
        },
      ],
    },
  },
};

const ekiti = {
  type: "Topology",
  arcs: [
    [
      [13953, 11137],
      [-59, -73],
      [-170, -99],
      [-191, -187],
      [-192, -234],
      [-57, -209],
      [-31, -244],
      [-30, -177],
      [159, -138],
      [88, -29],
      [134, -15],
      [81, 32],
      [268, -86],
      [153, -64],
      [127, -22],
      [-17, -237],
      [-137, -188],
      [-103, -127],
      [-191, -174],
      [-177, -139],
      [-43, -163],
      [56, -286],
      [-106, -303],
      [-72, -291],
      [-171, -167],
      [-171, -180],
      [-170, -133],
      [-136, -93],
      [-23, -12],
    ],
    [
      [12772, 7099],
      [-199, 9],
      [-510, 9],
      [-553, -72],
      [-159, 61],
    ],
    [
      [11351, 7106],
      [-195, 74],
      [-368, 135],
      [-413, 238],
      [-452, 334],
      [-233, 133],
      [-507, 233],
      [-286, 195],
      [-434, 184],
      [-51, 26],
    ],
    [
      [8412, 8658],
      [-402, 212],
      [-226, 160],
      [-288, 114],
      [-387, 183],
      [-214, 85],
      [-201, 17],
      [-261, 52],
      [-123, -86],
      [-326, 189],
    ],
    [
      [5984, 9584],
      [54, 33],
      [177, 139],
      [129, 100],
      [164, 153],
      [244, 84],
      [82, 73],
      [163, 120],
      [197, 159],
      [157, 86],
      [295, 2],
      [223, 44],
      [331, 103],
      [311, 96],
      [249, 43],
      [292, 145],
      [231, 186],
      [203, 105],
      [244, 104],
      [229, 10],
      [210, 119],
      [184, 139],
      [221, -31],
      [466, 94],
      [302, 8],
      [336, -46],
      [469, -76],
      [544, -77],
      [375, -54],
      [274, -114],
      [276, -11],
      [167, -77],
      [170, -106],
    ],
    [
      [1329, 7744],
      [-47, 19],
      [-100, 16],
      [-10, 2],
    ],
    [
      [1172, 7781],
      [-220, 522],
      [-240, 588],
      [-385, 775],
      [-196, 1166],
      [-131, 1103],
      [177, 575],
      [173, 475],
      [81, 220],
      [131, 624],
      [-204, 744],
      [-39, 128],
    ],
    [
      [319, 14701],
      [72, -32],
      [386, -224],
      [66, -68],
      [400, -238],
      [153, -77],
      [74, -42],
      [300, -142],
      [73, -35],
      [445, -387],
      [126, -91],
      [27, -7],
      [335, -488],
      [62, -286],
      [42, -320],
      [80, -456],
      [-24, -278],
      [-59, -338],
      [-117, -141],
      [-18, -291],
      [-86, -318],
      [-296, -429],
      [-225, -194],
      [-52, -325],
      [-29, -148],
      [-147, -371],
      [-152, -235],
      [-112, -304],
      [-138, -215],
      [-131, -208],
      [-44, -223],
      [-1, -46],
    ],
    [
      [25591, 14494],
      [186, -101],
      [287, -147],
      [287, -40],
      [-47, -100],
      [-193, -283],
      [-239, -233],
      [-72, -285],
      [-165, -235],
      [-68, -39],
      [-318, -104],
      [-139, 159],
      [-104, 199],
      [-287, 134],
      [-174, 98],
      [-317, -42],
      [-25, 82],
      [-238, -125],
      [-297, -97],
      [-113, -310],
      [-146, -324],
      [-141, -344],
      [-355, -361],
      [-252, -166],
      [-420, -162],
      [-193, -224],
    ],
    [
      [22048, 11444],
      [-207, 27],
      [-202, -3],
      [-208, 10],
      [-209, 17],
      [-168, -4],
      [-276, -9],
      [-200, 72],
      [-269, 18],
      [-106, 43],
      [-67, 48],
      [-13, 34],
      [-132, 152],
      [-151, 227],
      [-206, 139],
      [-152, 209],
    ],
    [
      [19482, 12424],
      [192, 164],
      [96, 161],
      [329, 395],
      [331, 116],
      [184, 92],
      [137, 208],
      [220, 302],
      [139, 242],
      [91, 250],
      [114, 425],
      [13, 374],
      [68, 66],
      [44, 183],
      [40, 26],
      [29, 88],
      [14, 41],
      [84, 229],
      [63, 121],
      [164, 167],
      [38, 47],
    ],
    [
      [21872, 16121],
      [70, 11],
      [250, 56],
      [80, -42],
      [173, -132],
      [298, -284],
      [126, -138],
      [272, -249],
      [235, -4],
      [295, -87],
      [407, -211],
      [280, -147],
      [196, 17],
      [396, -89],
      [307, -148],
      [334, -180],
    ],
    [
      [8412, 8658],
      [-64, -86],
      [-178, -187],
      [-137, -160],
      [-70, -203],
      [-114, -358],
      [24, -197],
      [-120, -330],
      [-152, -276],
      [-79, -318],
      [-198, -173],
      [-271, -118],
      [-218, -206],
      [-267, -301],
      [-287, -308],
      [-165, -187],
      [-191, -180],
      [-46, -274],
    ],
    [
      [5879, 4796],
      [-361, -46],
      [-921, 91],
      [-641, -138],
      [-739, -321],
      [-487, -175],
      [-276, -55],
      [-59, 115],
      [-333, 693],
      [-152, 573],
      [-101, 838],
      [-142, 390],
      [-307, 576],
      [-188, 444],
    ],
    [
      [1329, 7744],
      [154, -65],
      [0, 6],
      [160, -64],
      [194, -91],
      [186, -71],
      [154, -37],
      [316, -66],
      [281, -53],
      [343, 42],
      [202, -24],
      [303, 29],
      [367, 258],
      [205, 214],
      [153, 316],
      [295, 335],
      [161, 425],
      [113, 358],
      [197, 519],
      [33, 420],
      [16, 63],
    ],
    [
      [5162, 10258],
      [66, -23],
      [225, -201],
      [93, -76],
      [173, -132],
      [205, -207],
      [60, -35],
    ],
    [
      [319, 14701],
      [-140, 460],
      [58, 665],
      [188, 401],
      [49, 105],
      [220, 743],
      [55, 105],
    ],
    [
      [749, 17180],
      [138, -89],
      [145, -158],
      [80, -63],
      [112, -131],
      [130, -273],
      [-35, -108],
      [184, -241],
      [86, -124],
      [110, -280],
      [115, -335],
      [197, -282],
      [104, -225],
      [66, -76],
      [91, -192],
      [211, -268],
      [165, -200],
      [191, -261],
      [146, -91],
      [319, -236],
      [253, -133],
      [267, -154],
      [272, -209],
      [159, -159],
      [218, -241],
      [330, -420],
      [190, -268],
      [277, -324],
      [185, -146],
    ],
    [
      [5455, 11493],
      [-62, -209],
      [-72, -263],
      [-60, -373],
      [-99, -390],
    ],
    [
      [20116, 7420],
      [-68, -391],
      [-75, -495],
      [-31, -222],
      [-15, -110],
      [-150, -576],
      [-224, -533],
      [-280, -661],
      [-300, -668],
      [-345, -517],
      [-339, -606],
      [-481, -631],
      [-268, -349],
      [-100, -81],
      [-343, -278],
      [-728, -525],
      [-285, -137],
      [-25, -17],
    ],
    [
      [16059, 623],
      [-8, 302],
      [-180, 540],
      [-193, 498],
      [-62, 307],
      [-247, 595],
      [-101, 368],
      [-107, 477],
      [-21, 347],
      [54, 420],
      [35, 515],
      [212, 221],
      [205, 200],
      [616, 308],
      [277, 491],
      [199, 207],
      [185, 241],
      [245, 234],
      [72, 250],
      [112, 263],
      [31, 312],
      [11, 34],
    ],
    [
      [17394, 7753],
      [24, -8],
      [647, -317],
      [288, -73],
      [369, 1],
      [228, -58],
      [437, -8],
      [311, 97],
      [215, 37],
      [27, -7],
      [176, 3],
    ],
    [
      [22048, 11444],
      [-108, -125],
      [-322, -361],
      [-192, -208],
      [-314, -259],
      [-233, -302],
      [-168, -412],
      [-133, -304],
      [-88, -460],
      [-57, -458],
      [-20, -166],
      [-190, -520],
      [-72, -244],
      [-35, -205],
    ],
    [
      [17394, 7753],
      [-197, 70],
      [-284, -83],
      [-303, -63],
      [-183, -78],
      [-62, -80],
      [-227, 65],
      [-248, 51],
      [-278, -131],
      [-385, -142],
      [-608, -146],
      [-341, 107],
      [-163, -71],
      [-378, -89],
      [-180, 71],
      [-276, -50],
      [-244, -97],
      [-265, 12],
    ],
    [
      [13953, 11137],
      [126, 155],
      [157, 153],
      [112, 229],
      [70, 182],
      [64, 250],
      [96, 73],
      [57, 112],
    ],
    [
      [14635, 12291],
      [69, -202],
      [145, -193],
      [146, -104],
      [119, -111],
      [134, -36],
      [100, -29],
      [146, -124],
      [100, -70],
      [185, -180],
      [201, -92],
      [255, -17],
      [134, -3],
      [181, -71],
      [154, -36],
      [263, 63],
      [149, 52],
      [182, 71],
      [194, -71],
      [87, -22],
      [181, -37],
      [61, 13],
      [143, 99],
      [231, 200],
      [55, 33],
      [259, 240],
      [298, 131],
      [210, 84],
      [165, 235],
      [117, 174],
      [156, 113],
      [27, 23],
    ],
    [
      [6827, 19390],
      [38, -93],
      [47, -68],
      [272, -195],
      [53, -35],
      [259, -188],
      [207, -92],
      [180, -84],
      [148, -23],
      [228, -72],
      [413, -197],
      [93, -76],
      [93, -70],
      [53, -35],
      [166, -138],
      [100, -70],
      [180, -98],
      [233, -140],
      [74, -35],
      [239, -120],
    ],
    [
      [9903, 17561],
      [-2, -183],
      [-65, -229],
      [-100, -359],
      [178, -267],
      [75, -314],
      [-3, -231],
      [71, -150],
      [20, -435],
      [-4, -238],
      [-59, -385],
      [-68, -73],
    ],
    [
      [9946, 14697],
      [-186, -202],
      [-391, -68],
      [-249, -50],
      [-457, 42],
      [-290, -56],
      [-216, -37],
      [-436, 28],
      [-456, 109],
      [-330, -69],
      [-534, -187],
      [-244, -105],
      [-252, -113],
    ],
    [
      [5905, 13989],
      [72, 184],
      [74, 440],
      [64, 244],
      [41, 474],
      [-70, 639],
      [-160, 506],
      [-226, 547],
      [-110, 287],
      [-101, 409],
      [-194, 471],
      [-171, 248],
      [-217, 329],
      [-33, 25],
    ],
    [
      [4874, 18792],
      [20, 3],
      [55, 46],
      [61, 74],
      [103, 114],
      [272, 171],
      [108, 53],
      [130, 127],
      [224, 132],
      [122, 59],
      [148, -23],
      [54, 26],
      [353, -156],
      [114, -49],
      [73, -35],
      [60, -49],
      [49, 95],
      [7, 10],
    ],
    [
      [749, 17180],
      [174, 333],
      [72, 115],
      [135, 215],
      [444, 393],
      [321, 321],
      [557, 303],
      [467, 142],
      [517, 371],
      [69, 66],
    ],
    [
      [3505, 19439],
      [128, -80],
      [388, -122],
      [273, -147],
      [281, -79],
      [299, -219],
    ],
    [
      [5905, 13989],
      [-41, -106],
      [-67, -420],
      [0, -421],
      [-28, -535],
      [-121, -358],
      [-128, -439],
      [-65, -217],
    ],
    [
      [11541, 4782],
      [-161, 44],
      [-75, -39],
      [-337, -28],
      [-411, -21],
      [-526, -86],
      [-532, -25],
      [-794, 21],
      [-444, -13],
      [-383, 7],
      [-70, 10],
      [-434, 66],
      [-558, -4],
      [-678, 114],
      [-259, -32],
    ],
    [
      [11351, 7106],
      [-2, -11],
      [68, -428],
      [-83, -128],
      [-2, -129],
      [85, -151],
      [103, -253],
      [77, -225],
      [35, -347],
      [-51, -237],
      [-25, -346],
      [-15, -69],
    ],
    [
      [18324, 23442],
      [-34, -29],
      [-318, -110],
      [-162, -58],
      [36, -246],
      [-47, -447],
      [-22, -496],
      [6, -517],
      [15, -326],
      [112, -118],
      [147, -98],
      [60, 13],
      [143, 113],
      [110, 141],
      [400, 204],
      [191, 98],
      [81, 40],
      [182, 65],
      [359, 116],
      [297, 42],
      [202, 38],
      [310, 28],
      [93, 11],
      [258, 31],
      [209, 37],
      [189, 10],
      [242, 16],
      [195, -37],
      [462, -116],
      [264, -331],
      [48, -389],
      [-180, -262],
      [-270, -124],
      [-360, -178],
      [-302, -354],
      [-202, -446],
      [-211, -159],
      [-178, -208],
      [-186, -262],
      [-40, -346],
      [1, -394],
      [227, -554],
      [38, -545],
      [109, -314],
      [250, -365],
      [278, -290],
      [341, -169],
      [154, -44],
      [51, 8],
    ],
    [
      [14635, 12291],
      [-111, 324],
      [-13, 61],
      [-107, 423],
      [-7, 57],
    ],
    [
      [14397, 13156],
      [-29, 229],
      [-42, 299],
      [18, 278],
      [44, 244],
      [66, 345],
      [123, 106],
      [123, 175],
      [108, 385],
      [124, 208],
      [-18, 157],
      [-90, 212],
      [-61, 21],
      [-14, 387],
      [-85, 137],
      [-36, 245],
      [90, 142],
      [9, 190],
      [51, 243],
      [29, 101],
      [156, 106],
      [-69, 341],
      [17, 190],
      [35, 101],
      [10, 170],
      [121, 52],
      [96, 80],
      [84, 188],
      [-70, 293],
      [-152, 173],
      [-146, 124],
      [-78, 144],
      [-187, 105],
      [42, 68],
      [50, 182],
      [48, 53],
      [36, 122],
      [-4, 184],
      [-48, 381],
      [-119, 117],
      [-270, 364],
      [-164, 227],
      [-104, 233],
      [-33, 88],
      [-64, 151],
      [-311, 345],
      [-179, 145],
      [-60, 29],
      [-15, 1],
    ],
    [
      [13429, 22017],
      [113, 248],
      [-2, 319],
      [138, 182],
      [271, 137],
      [210, 126],
      [481, 189],
      [560, 119],
      [412, 61],
      [731, -114],
      [655, 97],
      [391, 48],
      [141, 18],
      [122, 25],
      [27, 6],
      [88, 19],
      [133, -77],
      [7, 0],
      [155, 11],
      [195, 23],
      [67, -12],
    ],
    [
      [10564, 20578],
      [4, -122],
      [-45, -346],
      [-146, -282],
      [-33, -346],
      [-104, -209],
      [90, -286],
      [-39, -312],
      [-34, -447],
      [-3, -170],
      [-57, -210],
      [-109, -93],
      [-184, -187],
      [-1, -7],
    ],
    [
      [6827, 19390],
      [48, 70],
      [203, 119],
      [157, 112],
      [250, 98],
      [156, 52],
      [137, 208],
      [218, 186],
      [258, 138],
      [198, 180],
      [130, 141],
      [70, 161],
      [29, 99],
    ],
    [
      [8681, 20954],
      [8, -13],
      [99, -151],
      [200, -119],
      [247, -113],
      [464, 19],
      [297, 36],
      [337, 55],
      [231, -90],
    ],
    [
      [9946, 14697],
      [26, -1],
      [208, -31],
      [360, -176],
      [175, 4],
      [273, -175],
      [160, -77],
      [206, -112],
      [247, -161],
      [166, -105],
      [159, -124],
      [213, -167],
      [173, -105],
      [174, -78],
      [186, -84],
      [148, -37],
      [208, -30],
      [219, -153],
      [189, 58],
      [168, -24],
      [147, -29],
      [134, -30],
      [115, -2],
      [87, 12],
      [122, 32],
      [88, 39],
      [100, 15],
    ],
    [
      [16059, 623],
      [-383, -262],
      [-740, -361],
      [-582, 173],
      [-490, 107],
      [-28, 86],
      [-18, 54],
      [-5, 153],
      [-232, 490],
      [-228, 494],
      [74, 406],
      [-159, 567],
      [-217, 337],
      [-156, 376],
      [45, 238],
      [-203, 384],
      [-311, 352],
      [-31, 136],
      [-125, 179],
      [-409, 95],
      [-320, 155],
    ],
    [
      [8368, 21439],
      [118, -168],
      [195, -317],
    ],
    [
      [3505, 19439],
      [355, 342],
      [188, 452],
      [105, 623],
      [249, 839],
      [176, 520],
      [-7, 78],
      [194, 81],
      [122, -198],
      [115, -369],
      [50, -225],
      [126, -111],
      [362, -122],
      [322, -39],
      [298, 110],
      [298, 131],
      [306, 178],
      [332, 151],
      [190, 98],
      [362, -33],
      [449, -178],
      [152, -159],
      [119, -169],
    ],
    [
      [10564, 20578],
      [371, -144],
      [477, -63],
      [438, 81],
      [326, 144],
      [155, 51],
      [188, -16],
      [255, -59],
      [166, -187],
      [148, 460],
      [339, 565],
      [-137, 302],
      [139, 305],
    ],
  ],
  transform: {
    scale: [0.0000343114490891808, 0.0000339399263908776],
    translate: [4.907282632061653, 7.273993378215378],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4]],
          type: "Polygon",
          properties: {
            name: "Ado-Ekiti",
            id: "EK001",
            nameAbbr: "Ado-Ekiti",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.7462543563,
            Shape_Area: 0.02637817951,
          },
        },
        {
          arcs: [[5, 6, 7]],
          type: "Polygon",
          properties: {
            name: "Efon",
            id: "EK002",
            nameAbbr: "Efon",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.54802966152,
            Shape_Area: 0.01456580637,
          },
        },
        {
          arcs: [[8, 9, 10, 11]],
          type: "Polygon",
          properties: {
            name: "Ekiti East",
            id: "EK003",
            nameAbbr: "Ekiti East",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.64900307383,
            Shape_Area: 0.01797686091,
          },
        },
        {
          arcs: [[-4, 12, 13, -6, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Ekiti South-West",
            id: "EK004",
            nameAbbr: "Ekiti South-West",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.75046653748,
            Shape_Area: 0.02505133051,
          },
        },
        {
          arcs: [[-8, 16, 17, 18, -15]],
          type: "Polygon",
          properties: {
            name: "Ekiti West",
            id: "EK005",
            nameAbbr: "Ekiti West",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.88436645034,
            Shape_Area: 0.02260642336,
          },
        },
        {
          arcs: [[19, 20, 21]],
          type: "Polygon",
          properties: {
            name: "Emure/Ise/Orun",
            id: "EK006",
            nameAbbr: "Emure/Ise/Orun",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.65568030223,
            Shape_Area: 0.02343122091,
          },
        },
        {
          arcs: [[22, -22, 23, -1, 24, 25, -10]],
          type: "Polygon",
          properties: {
            name: "Gboyin",
            id: "EK007",
            nameAbbr: "Gboyin",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.96582834647,
            Shape_Area: 0.03429014527,
          },
        },
        {
          arcs: [[26, 27, 28, 29, 30]],
          type: "Polygon",
          properties: {
            name: "Ido/Osi",
            id: "EK008",
            nameAbbr: "Ido/Osi",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.62623613659,
            Shape_Area: 0.02142380642,
          },
        },
        {
          arcs: [[-18, 31, 32, -30, 33]],
          type: "Polygon",
          properties: {
            name: "Ijero",
            id: "EK009",
            nameAbbr: "Ijero",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.69689454691,
            Shape_Area: 0.02838676826,
          },
        },
        {
          arcs: [[34, -13, -3, 35]],
          type: "Polygon",
          properties: {
            name: "Ikere",
            id: "EK010",
            nameAbbr: "Ikere",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.55675419974,
            Shape_Area: 0.01665933898,
          },
        },
        {
          arcs: [[36, -11, -26, 37, 38, 39]],
          type: "Polygon",
          properties: {
            name: "Ikole",
            id: "EK011",
            nameAbbr: "Ikole",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.42368990863,
            Shape_Area: 0.08208292079,
          },
        },
        {
          arcs: [[40, -27, 41, 42]],
          type: "Polygon",
          properties: {
            name: "Ilejemeje",
            id: "EK012",
            nameAbbr: "Ilejemeje",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.39049396876,
            Shape_Area: 0.00817417398,
          },
        },
        {
          arcs: [[-38, -25, -5, -16, -19, -34, -29, 43]],
          type: "Polygon",
          properties: {
            name: "Irepodun/Ifelodun",
            id: "EK013",
            nameAbbr: "Irepodun/Ifelodun",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.85401311589,
            Shape_Area: 0.0310231421,
          },
        },
        {
          arcs: [[44, -36, -2, -24, -21]],
          type: "Polygon",
          properties: {
            name: "Ise/Orun",
            id: "EK014",
            nameAbbr: "Ise/Orun",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.84561977974,
            Shape_Area: 0.0274206457,
          },
        },
        {
          arcs: [[45, -42, -31, -33, 46]],
          type: "Polygon",
          properties: {
            name: "Moba",
            id: "EK015",
            nameAbbr: "Moba",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.50801652261,
            Shape_Area: 0.01144662513,
          },
        },
        {
          arcs: [[-44, -28, -41, 47, -39]],
          type: "Polygon",
          properties: {
            name: "Oye",
            id: "EK016",
            nameAbbr: "Oye",
            parent: "Ekiti",
            parentAbbr: "EK",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.87514494947,
            Shape_Area: 0.03828252715,
          },
        },
      ],
    },
  },
};

const kwara = {
  type: "Topology",
  arcs: [
    [
      [52104, 17257],
      [-62, -80],
      [-69, -101],
      [117, -253],
      [-63, -155],
      [-30, -204],
      [-16, -155],
      [6, -41],
      [66, -90],
      [145, -192],
      [165, -145],
      [47, -15],
      [206, -132],
      [159, -153],
      [-54, -60],
      [-103, -79],
      [-115, -73],
      [-59, -305],
      [-61, -46],
      [-48, -67],
      [23, -238],
      [31, -177],
      [55, -313],
      [85, -206],
      [51, -143],
      [50, -245],
      [19, -75],
      [166, -146],
      [152, -159],
      [78, -123],
      [126, -165],
      [125, -118],
      [101, -8],
      [269, -5],
      [176, 51],
      [175, 18],
      [222, 44],
      [196, 44],
      [189, 37],
      [101, 26],
      [95, -15],
      [5, 29],
      [8, 52],
      [15, -9],
      [19, -12],
      [15, -8],
      [32, -17],
    ],
    [
      [54914, 13030],
      [44, -23],
      [16, 12],
      [1, -21],
    ],
    [
      [54975, 12998],
      [4, -74],
      [113, -83],
      [186, -166],
      [137, -220],
      [346, -257],
      [250, -310],
      [265, -229],
      [298, -297],
      [364, -413],
      [20, -25],
      [11, -14],
    ],
    [
      [56969, 10910],
      [-121, -82],
      [-76, -141],
      [-112, -290],
      [-143, -106],
      [-116, -127],
      [-186, -228],
      [-386, -177],
      [-42, -108],
      [-91, -215],
      [-269, -471],
      [-186, -275],
      [-125, -263],
      [-87, -365],
      [-244, -97],
      [-310, -97],
      [-71, -202],
      [-63, -156],
      [-31, -237],
      [-138, -262],
      [57, -164],
      [65, -205],
      [118, -192],
      [185, -193],
      [252, -215],
      [198, -221],
      [55, -360],
      [-152, -228],
      [-270, -98],
      [-96, -66],
      [-230, -132],
      [-299, -185],
      [-278, -144],
      [-170, -140],
      [-257, -91],
      [-149, -58],
      [-264, -84],
      [-317, -103],
      [-250, -57],
      [-330, -55],
      [-325, -104],
      [-68, -37],
    ],
    [
      [51647, 3879],
      [-72, 201],
      [-170, 349],
      [-123, 294],
      [-168, 377],
      [-170, 778],
      [-18, 537],
      [-365, 312],
      [-15, 381],
      [-385, 320],
      [-292, 216],
      [-481, 205],
      [-274, 134],
      [-199, 194],
      [-131, 199],
      [-395, 537],
      [-200, 167],
      [-230, 357],
      [-181, 431],
      [-38, 49],
      [-213, 268],
      [-187, 479],
      [-116, 308],
      [-136, 397],
      [-134, 444],
      [-96, 695],
      [52, 305],
      [-78, 178],
      [-34, 388],
      [-156, 370],
      [-102, 375],
      [-291, 270],
      [-219, 235],
      [-167, 472],
      [-7, 7],
      [-244, 263],
      [-116, 321],
      [-136, 336],
      [-253, 650],
      [-169, 329],
      [-163, 349],
      [-281, 528],
      [-115, 404],
      [-181, 451],
      [-256, 358],
      [-280, 188],
      [-243, 358],
      [-167, 475],
      [-33, 93],
      [-136, 342],
      [-79, 504],
      [-88, 395],
    ],
    [
      [42916, 21452],
      [11, -5],
      [148, 24],
      [175, 4],
      [161, -30],
      [173, -98],
      [283, 36],
      [195, -3],
      [221, -24],
      [243, 36],
      [282, -4],
      [343, -34],
      [221, -17],
      [276, 22],
      [268, -45],
      [255, -72],
      [221, -4],
      [263, 23],
      [328, -60],
      [256, -5],
      [302, -18],
      [228, -18],
      [428, -123],
      [302, -100],
      [240, -106],
      [251, -262],
      [139, -125],
      [133, -124],
      [206, -166],
      [253, -100],
      [226, -173],
      [173, -139],
      [367, -163],
      [198, -207],
      [264, -303],
      [245, -276],
      [119, -110],
      [282, -419],
      [-8, -95],
      [93, -83],
      [126, -138],
      [131, -165],
      [38, -130],
      [6, -61],
      [78, -165],
      [6, -47],
      [39, -123],
    ],
    [
      [32615, 64242],
      [-15, -331],
      [-20, -538],
      [-5, -476],
      [-1, -82],
      [21, -491],
      [19, -461],
      [6, -64],
      [67, -826],
      [-160, -1240],
      [49, -354],
      [42, -298],
      [5, -870],
      [-13, -710],
      [-14, -849],
      [89, -563],
      [-131, -2601],
      [-52, -313],
      [-6, -47],
      [-59, -531],
      [1, -173],
      [2, -296],
      [-797, -16616],
    ],
    [
      [31643, 35512],
      [-57, -31],
      [-114, -87],
      [-95, -99],
      [-359, -188],
      [-321, -159],
      [-254, -150],
      [-187, -210],
      [-134, -197],
      [-51, -185],
      [-17, -284],
      [-181, -319],
      [-223, -561],
      [3, -780],
      [-12, -472],
      [-88, -215],
      [-127, -309],
      [-261, -329],
      [-428, -339],
      [29, 0],
      [-134, -102],
      [-141, -107],
      [-409, -299],
      [-79, -49],
      [-150, -93],
      [-128, -78],
      [-105, -65],
      [-187, -113],
      [-84, -52],
      [-201, -98],
      [-525, -256],
      [-447, -163],
      [-413, -135],
      [-71, -53],
      [-12, -10],
      [-325, -243],
      [-392, -102],
      [-373, -150],
      [-131, -60],
      [-241, -110],
      [-293, -246],
      [-271, -105],
      [-414, -196],
      [-272, -215],
      [-48, -38],
      [-542, -202],
      [-102, -70],
      [-156, -109],
      [-286, -212],
      [-170, -86],
      [-169, -85],
      [-226, -227],
      [-50, -43],
      [-286, -240],
      [-5, -4],
      [-246, -163],
      [-33, -22],
      [-326, -219],
      [-400, -143],
      [-128, 22],
      [-6, 1],
      [-55, -101],
      [-56, -101],
      [-59, -41],
      [-123, -87],
      [-348, -243],
      [-482, -244],
      [-293, -232],
      [-169, -47],
      [-270, -75],
      [-333, -164],
      [-441, -224],
      [-399, -156],
      [-315, -254],
      [-39, -32],
      [-514, -127],
      [-191, -187],
      [-40, -18],
      [-584, -257],
      [-71, -14],
      [-57, -11],
      [-310, -49],
      [-110, 123],
      [-75, 84],
      [-27, 62],
      [-38, 89],
      [-112, -27],
      [-98, -24],
      [-272, 256],
      [-156, 275],
      [-329, 94],
      [-355, 81],
      [-258, -125],
      [-94, -46],
      [-163, -79],
      [-326, 39],
      [-10, 1],
      [-190, -99],
      [-396, -333],
      [-228, -129],
      [-132, -75],
      [-170, -167],
      [-69, -73],
      [-171, -181],
      [-346, -198],
      [-199, -293],
      [-84, -124],
      [-124, -97],
      [-250, -195],
      [-65, -19],
      [-280, -77],
      [-204, -119],
      [-1, -5],
      [-56, -178],
      [-297, -257],
      [-3, -3],
      [-305, -158],
      [-230, -75],
      [-244, -80],
      [-144, -194],
      [-39, -30],
      [-438, -336],
      [-383, -367],
      [-361, -263],
      [-13, -9],
      [-25, -99],
      [-25, -98],
      [-353, -196],
      [-61, -34],
      [0, -50],
      [2, -195],
      [-91, -223],
      [-230, -112],
      [-10, -6],
      [-11, -7],
      [-121, -59],
      [-96, -46],
      [-34, -20],
      [-57, 8],
      [-117, 16],
      [-12, -61],
      [-17, -88],
      [-58, 1],
      [-144, 2],
      [-303, 19],
      [-283, -65],
      [-102, -24],
      [-53, -3],
      [-304, -18],
      [-150, -50],
      [-140, -47],
      [-318, -128],
      [-69, -28],
      [-479, -73],
      [-241, 60],
      [-201, 49],
      [-107, 22],
      [-176, -58],
      [-289, -8],
      [-346, -157],
      [-302, -389],
      [-349, -382],
      [-225, -231],
      [-280, -159],
      [-3, 2],
      [4, 142],
      [136, 264],
      [128, 432],
      [-173, 329],
      [-81, 153],
      [-25, 162],
      [1, 52],
      [2, 36],
      [24, 645],
      [2, 146],
      [2, 142],
      [-3, 213],
      [-17, 244],
      [-13, 200],
      [-74, 169],
      [-146, 89],
      [-75, 43],
      [-1, 4],
      [-39, 122],
      [54, 175],
      [197, 172],
      [-141, 334],
      [79, 268],
      [1, 4],
      [65, 135],
      [118, 250],
      [-15, 34],
      [-41, 98],
      [-66, 149],
      [-30, 205],
      [27, 543],
      [-59, 258],
      [-85, 114],
      [-211, 284],
      [-134, 374],
      [-3, 8],
      [-23, 66],
      [52, 158],
      [2, 5],
      [45, 56],
      [169, 207],
      [2, 6],
      [48, 203],
      [27, 28],
      [135, 139],
      [190, 46],
      [126, 29],
      [118, 67],
      [37, 21],
      [68, 128],
      [-131, 289],
      [-1, 3],
      [-9, 117],
      [-12, 189],
      [52, 367],
      [35, 264],
      [-52, 102],
      [-70, 135],
      [-1, 3],
      [4, 115],
      [28, 41],
      [296, 66],
      [37, 193],
      [121, 619],
      [-93, 294],
      [-253, -55],
      [-44, 14],
      [-55, 18],
      [-1, 22],
      [-5, 94],
      [48, 128],
      [76, 72],
      [48, 37],
      [132, 33],
      [6, 5],
      [55, 49],
      [43, 104],
      [36, 94],
      [90, 205],
      [4, 9],
      [46, 106],
      [64, 157],
      [42, 102],
      [-105, 159],
      [-204, 65],
      [-3, 1],
      [48, 53],
      [69, 73],
      [312, 205],
      [89, 182],
      [-43, 114],
      [-1, 2],
      [-125, 120],
      [-26, 126],
      [154, 84],
      [29, 57],
      [5, 10],
      [-5, 9],
      [-47, 77],
      [-204, 30],
      [-58, 96],
      [134, 242],
      [-39, 69],
      [-6, 1],
      [-255, 58],
      [-8, 95],
      [5, 2],
      [53, 27],
      [254, 128],
      [3, 6],
      [39, 95],
      [-22, 65],
      [-3, 9],
      [-205, 51],
      [-17, 4],
      [-20, 33],
      [-17, 37],
      [5, 3],
      [233, 159],
      [0, 4],
      [5, 63],
      [7, 98],
      [97, 225],
      [-205, 198],
      [-3, 2],
      [-21, 68],
      [75, 327],
      [380, -8],
      [188, -4],
      [124, 3],
      [10, 0],
      [76, -7],
      [74, 0],
      [66, 3],
      [727, -12],
      [498, 6],
      [384, -68],
      [337, 74],
      [38, 116],
      [43, 129],
      [278, 213],
      [135, 104],
      [308, 71],
      [172, 38],
      [187, 23],
      [250, 35],
      [246, 31],
      [66, 6],
      [175, 24],
      [214, 27],
      [51, 6],
      [88, -7],
      [224, -378],
      [42, -15],
      [98, -36],
      [444, -161],
      [296, 47],
      [404, 0],
      [193, 50],
      [5, 1],
      [77, 16],
      [36, 13],
      [135, 51],
      [112, 42],
      [228, 87],
      [10, 1],
      [60, 7],
      [148, 14],
      [78, 29],
      [125, 44],
      [22, 10],
      [32, 12],
      [21, 13],
      [34, 16],
      [86, 58],
      [471, 143],
      [324, 98],
      [135, 110],
      [86, 612],
      [29, 216],
      [1, 4],
      [36, 88],
      [231, 570],
      [154, 294],
      [237, 435],
      [56, 102],
      [119, 210],
      [87, 140],
      [-43, 636],
      [-7, 267],
      [-2, 60],
      [9, 397],
      [30, 106],
      [91, 300],
      [155, 203],
      [533, 398],
      [211, 290],
      [31, 253],
      [-39, 158],
      [-92, 378],
      [-3, 11],
      [-51, 347],
      [0, 5],
      [-12, 521],
      [0, 7],
      [36, 441],
      [5, 51],
      [1, 19],
      [19, 234],
      [-52, 319],
      [-1, 6],
      [-537, 1483],
      [-69, 414],
      [149, 666],
      [1, 4],
      [374, 638],
      [8, 16],
      [62, 92],
      [101, 144],
      [67, 39],
      [181, 100],
      [351, 143],
      [162, 64],
      [45, 18],
      [19, 8],
      [34, 14],
      [52, 21],
      [6, 5],
      [246, 236],
      [230, 516],
      [153, 350],
      [239, 276],
      [2, 5],
      [152, 248],
      [153, 248],
      [20, 23],
      [143, 124],
      [3, 2],
      [31, 57],
      [185, 310],
      [287, 410],
      [5, 7],
      [102, 206],
      [5, 11],
      [51, 588],
      [9, 102],
      [13, 189],
      [157, 264],
      [23, 40],
      [42, 67],
      [89, 75],
      [142, 120],
      [4, 3],
      [49, 16],
      [207, 64],
      [347, 9],
      [238, -66],
      [266, -67],
      [115, 4],
      [40, 2],
      [41, 30],
      [44, 30],
      [129, 119],
      [140, 122],
      [86, 75],
      [149, 129],
      [11, 9],
      [222, 169],
      [159, 129],
      [2, 3],
      [15, 42],
      [8, 42],
      [15, 68],
      [63, 202],
      [-7, 29],
      [-44, 194],
      [-4, 6],
      [-296, 464],
      [-39, 145],
      [-297, 592],
      [-124, 453],
      [-26, 455],
      [0, 5],
      [178, 633],
      [307, 344],
      [200, 220],
      [12, 12],
      [271, 227],
      [192, 73],
      [221, 80],
      [15, 6],
      [224, 81],
      [321, 130],
      [50, 18],
      [483, 179],
      [460, 169],
      [334, 112],
      [403, 132],
      [206, 67],
      [534, 60],
      [188, -41],
      [502, -103],
      [12, -2],
      [358, -28],
      [174, 76],
      [155, 26],
      [194, 32],
      [5, 4],
      [12, 2],
      [107, 82],
      [156, 117],
      [8, 10],
      [251, 290],
      [14, 53],
      [87, 35],
      [326, 382],
      [3, 3],
      [56, 134],
      [-1, 482],
      [200, 278],
      [346, 287],
      [374, 312],
      [191, 344],
      [179, 646],
      [4, 15],
      [81, 466],
      [2, 10],
      [-3, 118],
      [-6, 273],
      [-2, 97],
      [1, 10],
      [21, 185],
      [1, 5],
      [5, 50],
      [-9, 217],
      [-21, 495],
      [-1, 17],
      [7, 210],
      [11, 279],
      [117, 390],
      [115, 181],
      [119, 181],
      [100, 93],
      [45, 44],
      [19, 18],
      [7, 4],
      [18, 9],
      [178, 87],
      [120, 36],
      [180, 114],
      [330, 43],
      [4, 0],
      [9, 1],
      [86, 75],
      [46, 36],
      [2, 5],
      [96, 283],
      [56, 169],
      [26, 100],
      [18, 71],
      [10, 37],
      [3, 12],
      [203, 79],
      [461, 93],
      [965, 188],
      [904, 72],
      [762, -19],
      [496, 54],
      [477, -29],
      [904, 29],
    ],
    [
      [82045, 28074],
      [-32, -19],
      [-347, -231],
      [-262, -369],
      [-266, -254],
      [-320, -286],
      [-125, -195],
      [-260, -328],
      [-43, -128],
      [-29, -203],
      [-74, -373],
      [-70, -596],
      [-86, -372],
      [102, -714],
      [41, -408],
      [-89, -508],
      [-155, -418],
      [-99, -304],
      [67, -456],
      [-68, -474],
      [99, -518],
      [94, -450],
      [101, -422],
      [269, -799],
      [238, -303],
      [104, -233],
      [35, -306],
      [105, -164],
      [186, -133],
      [139, -151],
      [213, -527],
      [82, -368],
      [85, -169],
    ],
    [
      [81680, 16895],
      [-243, -103],
      [-368, -319],
      [-403, -354],
      [-475, -236],
      [-255, -424],
      [-648, -584],
    ],
    [
      [79288, 14875],
      [-1, 9],
      [-79, 490],
      [-12, 496],
      [-138, 654],
      [0, 428],
      [-61, 374],
      [-79, 524],
      [-47, 442],
      [-112, 525],
      [-67, 483],
      [-86, 511],
      [-65, 544],
      [-72, 531],
      [-55, 360],
      [-346, 210],
      [-451, 388],
      [-280, 141],
      [-383, 20],
      [-197, -133],
      [-530, -323],
      [-224, -166],
      [-287, -321],
      [-343, -374],
      [-315, -368],
      [-192, -269],
      [-199, -248],
      [-164, -139],
      [-264, -152],
      [-246, 208],
      [-446, 266],
      [-293, 174],
      [-628, 269],
      [-333, 189],
      [-446, 252],
      [-375, 122],
      [-281, 73],
      [-347, 189],
      [-434, 184],
      [-259, 154],
      [-311, 338],
      [-324, 365],
      [-211, 234],
      [-178, 221],
      [-210, 316],
      [-111, 225],
      [-250, 338],
      [-203, 336],
      [-137, 240],
      [-166, 172],
      [-426, 218],
      [-433, 279],
      [-280, 120],
      [-201, 98],
      [-546, 281],
      [-134, 43],
      [-134, 71],
      [-542, 138],
      [-260, 147],
      [-355, 54],
      [-346, 229],
      [-299, 243],
      [-291, 264],
      [-231, 309],
      [-90, 225],
      [-76, 294],
      [-50, 265],
      [-73, 436],
      [79, 325],
      [46, 331],
      [44, 271],
      [10, 217],
      [78, 196],
      [97, 209],
      [44, 264],
      [64, 161],
      [105, 257],
      [82, 154],
      [-37, 0],
      [-128, 98],
      [-24, 26],
      [-59, 62],
      [-66, 101],
      [-62, 70],
      [-22, 25],
      [-35, 60],
      [-28, 48],
      [-21, 55],
      [-72, 132],
      [-35, 64],
      [-62, 204],
      [-86, 218],
      [-8, 113],
      [-6, 83],
      [-14, 196],
      [22, 176],
    ],
    [
      [63189, 32207],
      [27, 176],
      [8, 50],
      [-23, 191],
      [-8, 82],
      [-7, 78],
      [-115, 347],
      [6, 55],
      [20, 207],
      [4, 312],
      [-6, 174],
      [-4, 112],
      [39, 220],
      [20, 114],
      [-20, 164],
    ],
    [
      [63130, 34489],
      [251, 91],
      [267, 98],
      [304, 139],
      [202, 85],
      [263, 110],
      [334, 143],
      [131, 55],
      [41, 18],
      [232, 102],
      [132, 58],
      [32, 1],
      [14, 0],
      [36, 0],
      [261, 39],
      [143, 21],
      [22, 4],
      [159, 23],
      [57, -1],
      [353, -6],
      [59, -1],
      [639, -314],
      [571, -320],
      [789, -358],
      [60, -27],
      [931, -405],
      [182, -144],
      [415, -324],
      [70, -56],
      [256, -334],
      [26, -33],
      [180, -235],
      [4, -5],
      [193, -244],
      [263, -331],
      [149, -106],
      [128, -91],
      [18, -12],
      [256, -182],
      [312, -386],
      [83, -113],
      [248, -336],
      [96, -130],
      [107, -130],
      [59, -70],
      [151, -182],
      [-7, 0],
      [-10, 0],
      [27, -55],
      [31, -64],
      [2, -4],
      [85, -171],
      [234, -99],
      [94, -98],
      [5, -6],
      [194, -71],
      [249, -45],
      [127, -23],
      [79, -83],
      [180, -48],
      [229, -61],
      [357, 132],
      [83, 30],
      [124, 16],
      [254, 32],
      [478, 226],
      [50, 24],
      [58, 9],
      [165, 27],
      [297, 57],
      [159, -18],
      [130, -15],
      [307, -125],
      [355, -144],
      [124, 64],
      [140, 74],
      [229, -79],
      [333, -114],
      [350, -27],
      [261, -168],
      [258, -167],
      [181, -51],
      [228, -65],
      [377, 14],
      [147, -98],
      [146, -97],
      [160, -132],
      [298, 131],
      [34, -24],
      [179, -123],
      [305, -250],
      [527, -267],
      [394, -245],
      [168, -129],
      [230, -177],
      [31, -19],
    ],
    [
      [81824, 3071],
      [-67, 13],
      [-195, -24],
      [-155, -11],
      [-7, 0],
      [-133, 77],
      [-88, -19],
      [-27, -6],
      [-121, -25],
      [-142, -18],
      [-391, -48],
      [-655, -97],
      [-731, 114],
      [-411, -61],
      [-561, -119],
      [-481, -189],
      [-210, -126],
      [-271, -137],
      [-138, -182],
      [2, -319],
      [-113, -248],
      [-138, -305],
      [136, -302],
      [-338, -565],
      [-149, -460],
      [-165, 187],
      [-255, 59],
      [-188, 16],
      [-156, -51],
      [-325, -144],
      [-439, -81],
      [-476, 63],
      [-371, 144],
      [-231, 90],
      [-338, -55],
      [-296, -36],
      [-465, -19],
      [-247, 113],
      [-200, 119],
      [-98, 151],
      [-8, 13],
      [-196, 317],
      [-118, 168],
    ],
    [
      [71868, 1068],
      [4, 5],
      [67, 372],
      [-144, 234],
      [21, 74],
      [-269, 391],
      [-50, 205],
      [-104, 260],
      [-45, 163],
      [-4, 150],
      [-12, 102],
      [90, 141],
      [69, 73],
      [109, 121],
      [218, 193],
      [28, 20],
      [224, 193],
      [129, 93],
      [218, 132],
      [224, 145],
      [143, 93],
      [163, 133],
      [135, 52],
      [476, 290],
      [352, 185],
      [358, 68],
      [396, 7],
      [471, -22],
      [533, 161],
      [455, 291],
      [522, 249],
      [272, 165],
      [392, 156],
      [456, -76],
      [562, 249],
      [486, 100],
      [452, 114],
      [233, 9],
    ],
    [
      [79498, 6359],
      [54, -240],
      [426, -286],
      [708, -270],
      [579, -445],
      [528, -220],
      [191, -275],
      [36, -286],
      [158, -581],
      [-101, -467],
      [-253, -218],
    ],
    [
      [79288, 14875],
      [-137, -123],
      [-706, -783],
      [-262, -431],
      [-340, -640],
      [-477, -786],
      [-474, -577],
      [-362, -381],
      [-171, -194],
      [-82, -468],
      [206, -360],
    ],
    [
      [76483, 10132],
      [-313, -151],
      [-557, -370],
      [-206, -282],
      [-180, -282],
      [-137, -201],
      [-164, -167],
      [-25, -305],
      [-124, -175],
      [-103, -80],
      [-135, -79],
      [-230, -97],
      [-257, -112],
      [-303, -8],
      [-343, 13],
      [-257, -98],
    ],
    [
      [73149, 7738],
      [-101, 15],
      [-471, -52],
      [-302, 18],
      [-284, -90],
      [-253, 133],
      [-83, 280],
      [-140, 512],
      [26, 386],
      [67, 379],
      [137, 616],
      [-127, 436],
      [-217, 330],
      [-243, 310],
      [-351, 359],
      [-582, 193],
      [-422, 55],
      [-351, -28],
      [-639, -91],
      [-509, -270],
      [-368, -312],
      [-521, -222],
      [-633, -57],
      [-476, 103],
      [-697, 161],
      [-395, 81],
      [-655, 215],
      [-650, 147],
      [-548, 213],
      [-84, 30],
    ],
    [
      [63277, 11588],
      [-371, 127],
      [-356, -27],
      [-452, -54],
      [-421, -237],
      [-448, 110],
      [-253, -254],
      [-189, -472],
      [-47, -400],
      [-74, -467],
      [-47, -413],
      [104, -233],
      [130, -267],
      [88, -361],
      [-7, -414],
      [-118, -276],
      [-98, -237],
      [-104, -167],
      [-288, -348],
      [-177, -154],
      [-75, -66],
      [-218, -160],
      [-183, -132],
      [-165, -180],
      [-62, -128],
      [-14, -20],
      [-90, -196],
      [-65, -117],
    ],
    [
      [59277, 6045],
      [-5, 20],
      [-6, 17],
      [-197, 282],
      [-22, 292],
      [-263, 378],
      [-39, 523],
      [-267, 521],
      [-207, 492],
      [-307, 542],
      [-183, 336],
      [-150, 335],
      [-168, 396],
      [-189, 357],
      [-305, 374],
    ],
    [
      [54975, 12998],
      [92, -48],
      [141, -23],
      [93, -36],
      [201, -44],
      [415, -129],
      [261, -59],
      [248, -65],
      [290, 63],
      [433, -232],
      [378, -312],
      [543, -159],
      [562, -145],
      [398, 95],
      [212, 221],
      [167, 329],
      [147, 371],
      [38, 305],
      [-83, 232],
      [-109, 369],
      [-243, 377],
      [-204, 221],
      [-214, 78],
      [-247, 113],
      [-299, 229],
      [-315, 94],
      [-225, 160],
      [-211, 289],
      [-191, 221],
      [-17, 190],
      [164, 155],
    ],
    [
      [57400, 15858],
      [6, 5],
      [206, 261],
      [203, 112],
      [159, 235],
      [232, 206],
      [156, 127],
      [346, 150],
      [433, 176],
      [518, 52],
      [351, 75],
      [310, 43],
      [627, 84],
      [561, 214],
      [789, 157],
      [634, 111],
      [542, 296],
      [442, 278],
      [515, 269],
      [393, 190],
      [197, 119],
      [305, 138],
      [374, 292],
      [308, 341],
      [170, 139],
      [83, 128],
      [31, 251],
      [-38, 157],
      [-212, 173],
      [-268, 38],
      [-129, -52],
      [-175, 10],
      [-266, 134],
      [-162, 3],
      [-95, -60],
      [-254, 100],
      [-200, 111],
      [-147, 50],
      [-180, 85],
      [-207, 99],
      [-114, -12],
      [-104, -154],
      [-279, 188],
      [-260, 147],
      [-224, 234],
      [-224, 303],
      [-117, 267],
      [-52, 123],
      [-1, 12],
      [-1, 5],
    ],
    [
      [62582, 22268],
      [16, 23],
      [-20, 435],
      [-168, 397],
      [-116, 294],
      [-251, 303],
      [-165, 206],
      [-245, 249],
      [-269, 385],
      [-196, 376],
      [-122, 342],
      [-69, 286],
      [-135, 417],
      [-33, 448],
      [27, 421],
      [-112, 545],
      [87, 446],
      [136, 521],
      [45, 284],
      [101, 412],
      [219, 214],
      [126, 324],
      [258, 199],
      [280, 280],
      [111, 189],
      [240, 69],
      [132, 363],
      [51, 56],
      [-14, 19],
      [6, 116],
      [15, 52],
      [20, 78],
      [67, 153],
      [16, 39],
      [-2, 51],
      [-5, 92],
      [80, 294],
      [62, 140],
      [8, 17],
      [101, 157],
      [14, 10],
      [204, 141],
      [107, 96],
    ],
    [
      [57400, 15858],
      [-324, -17],
      [-2176, -281],
      [-128, -46],
      [-234, 65],
      [-101, 22],
      [-21, -20],
      [-41, -47],
      [-69, -127],
      [-76, -121],
      [-36, -156],
      [86, -124],
      [106, -90],
      [127, -15],
      [174, -44],
      [221, -65],
      [174, -30],
      [182, -3],
      [94, 32],
      [294, -147],
      [87, 5],
      [254, -93],
      [127, -97],
      [38, -123],
      [-3, -210],
      [-16, -109],
      [-62, -121],
      [-69, -128],
      [-68, -66],
      [-55, -40],
      [-110, -134],
      [-122, -106],
      [-143, -66],
      [-88, -80],
      [-105, -102],
    ],
    [
      [55317, 13174],
      [-113, -23],
      [-132, 138],
      [-227, 106],
      [-113, 63],
      [-152, 179],
      [-146, 90],
      [-53, 90],
      [-125, 165],
      [-83, 232],
      [-78, 185],
      [-71, 191],
      [-32, 116],
      [-25, 96],
      [-32, 136],
      [-11, 109],
      [1, 61],
      [8, 101],
      [-77, 185],
      [-51, 198],
      [-146, 152],
      [-98, 164],
      [-65, 158],
      [-150, 274],
      [-117, 260],
      [-57, 198],
      [-10, 231],
      [-96, 273],
      [37, 189],
      [2, 48],
    ],
    [
      [53105, 17539],
      [139, -3],
      [362, -67],
      [348, -115],
      [341, -128],
      [421, -143],
      [470, -28],
      [33, -28],
      [268, -113],
      [113, -56],
      [281, -100],
      [915, 480],
      [250, 104],
      [202, 4],
      [202, 23],
      [362, 340],
      [151, 188],
      [164, 173],
      [232, 227],
      [287, 274],
      [218, 145],
      [198, 214],
      [340, 218],
      [231, 173],
      [219, 254],
      [219, 254],
      [157, 154],
      [334, 266],
      [225, 186],
      [293, 239],
      [359, 205],
      [247, 253],
      [117, 216],
      [137, 167],
      [90, 141],
      [152, 255],
      [272, 165],
      [128, 192],
    ],
    [
      [54914, 13030],
      [0, 10],
      [94, 6],
      [81, 5],
      [47, 13],
      [61, 46],
      [115, 59],
      [5, 5],
    ],
    [
      [52104, 17257],
      [13, 13],
      [76, 87],
      [137, 195],
      [196, 37],
      [201, -57],
      [249, 9],
      [129, -2],
    ],
    [
      [72009, 6070],
      [-9, -16],
      [-242, -356],
      [-19, -387],
      [-147, -357],
      [-328, -320],
      [-333, -212],
      [-321, -340],
      [-404, -428],
      [-476, -318],
      [-523, -391],
      [-526, -507],
      [-228, -356],
      [-188, -79],
    ],
    [
      [68265, 2003],
      [-193, -81],
      [-16, 195],
      [-167, 64],
      [-787, -381],
      [-688, 48],
      [-91, 6],
      [-709, -117],
      [-155, 3],
      [-373, 645],
      [-58, 178],
      [-91, 178],
      [-174, 500],
      [-380, 203],
      [-464, 15],
      [-181, 58],
      [-203, -51],
      [-479, -74],
      [-616, -268],
      [-326, -185],
      [-609, -227],
      [-660, -2],
      [-467, 212],
      [-119, -32],
      [4, -91],
      [29, -292],
      [6, -32],
      [-59, 108],
      [-30, 197],
      [1, 97],
    ],
    [
      [60210, 2877],
      [2, 127],
      [-149, 335],
      [-108, 389],
      [-96, 280],
      [-46, 516],
      [-129, 336],
      [-142, 355],
      [-135, 410],
      [-130, 420],
    ],
    [
      [63277, 11588],
      [-24, -75],
      [-59, -305],
      [-64, -216],
      [-45, -312],
      [14, -353],
      [-3, -237],
      [-17, -190],
      [49, -286],
      [29, -326],
      [114, -396],
      [36, -265],
      [300, -583],
      [70, -266],
      [236, -391],
      [232, -207],
      [199, -167],
      [231, -241],
      [219, -174],
      [267, -161],
      [285, -228],
      [400, -238],
      [214, -58],
      [307, -155],
      [127, -49],
      [407, -136],
      [280, -161],
      [321, -101],
      [141, -43],
      [314, -114],
      [354, -128],
      [288, -60],
      [128, -8],
      [181, -31],
      [121, -2],
      [142, 39],
      [155, 31],
      [252, 172],
      [179, 289],
      [144, 194],
      [213, 282],
      [340, 252],
      [197, 85],
      [331, 144],
      [198, 139],
      [108, 66],
      [74, -15],
      [173, -91],
      [7, -14],
      [153, -111],
      [199, -126],
      [67, -42],
      [59, -76],
      [87, -69],
      [2, -4],
    ],
    [
      [73149, 7738],
      [-224, -132],
      [-168, -397],
      [-287, -328],
      [-181, -350],
      [-280, -461],
    ],
    [
      [53992, 43321],
      [342, -303],
      [4, -16],
      [6, -24],
      [40, -150],
      [15, -57],
      [139, -520],
      [-68, -620],
      [-20, -238],
      [-4, -42],
      [-8, -95],
      [3, -196],
      [1, -31],
      [6, -399],
      [135, -540],
      [32, -125],
      [6, -9],
      [302, -471],
      [189, -165],
      [56, -49],
      [226, -196],
      [490, -404],
      [120, -71],
      [326, -194],
      [106, -63],
      [192, -47],
      [208, -50],
      [244, -60],
      [333, -11],
      [167, -5],
      [285, -10],
      [109, -56],
      [125, -65],
      [270, -140],
      [201, -298],
      [19, -28],
      [54, -79],
      [61, -235],
      [20, -77],
      [72, -279],
      [34, -160],
      [12, -55],
      [12, -53],
      [19, -92],
      [14, -213],
      [6, -88],
      [6, -86],
      [5, -76],
      [9, -82],
      [23, -209],
      [63, -565],
      [50, -468],
      [9, -76],
      [84, -353],
      [9, -8],
    ],
    [
      [59151, 34319],
      [-85, -126],
      [-17, -25],
      [-54, -81],
      [-114, -261],
      [-76, -174],
      [-298, -582],
      [-402, -285],
      [-98, -69],
      [-16, -6],
      [-726, -235],
      [-54, -18],
      [-261, -34],
      [-571, -74],
      [-377, -49],
      [-541, 16],
      [-131, 4],
      [-22, 4],
      [-733, 124],
      [-454, -32],
      [-612, -7],
      [-139, -22],
      [-270, -43],
      [-429, -68],
      [-82, -73],
      [-31, -27],
      [-84, -75],
      [-1166, -412],
      [-701, -271],
      [-21, -9],
      [-357, -148],
      [-531, -221],
      [-218, -106],
      [-164, -80],
      [-133, -63],
      [-432, -192],
      [180, 0],
      [-45, -33],
      [-66, -48],
      [-14, -10],
      [-108, -76],
      [-253, -240],
      [-264, -132],
      [-18, -16],
      [-78, -70],
      [-89, -82],
      [-20, -18],
      [-462, -284],
      [-101, -46],
      [-25, -17],
      [-213, -142],
      [-106, -53],
      [-196, -97],
    ],
    [
      [46873, 29235],
      [27, 44],
      [3, 4],
      [-2, 285],
      [-76, 328],
      [-145, 152],
      [-219, 221],
      [-201, -2],
      [-48, 0],
      [-368, -279],
      [-333, -212],
      [-69, -35],
      [-304, -156],
      [-258, -172],
      [-27, -6],
      [-142, -32],
      [-451, -13],
      [-128, -4],
      [-75, 5],
      [-221, 14],
      [-268, 86],
      [-375, 101],
      [-42, 13],
      [-192, 59],
      [-335, 115],
      [-153, 32],
      [-169, 35],
      [-268, 72],
      [-81, -3],
      [-141, -7],
      [-297, -56],
      [-130, 62],
      [-317, 150],
      [-240, 154],
      [-269, 115],
      [-12, 5],
      [-109, 161],
      [-22, 32],
      [-13, 22],
      [-18, 31],
      [-10, 1],
      [-113, 16],
      [-161, 26],
      [-113, 18],
      [-212, 41],
      [-52, 11],
      [-71, 21],
      [-452, 136],
      [-146, 43],
      [-363, 108],
      [-416, 160],
      [-65, 25],
      [-253, 167],
      [-254, 52],
      [-200, 120],
      [-186, 161],
      [-136, 109],
      [-263, 207],
      [-313, 181],
      [-254, 208],
      [-90, 79],
      [-87, 76],
      [-135, 155],
      [-112, 129],
      [-123, 150],
      [-103, 127],
      [-323, 406],
      [-15, 141],
      [-16, 151],
      [-125, 419],
      [-8, 38],
      [-91, 422],
      [-420, 344],
      [-171, 80],
      [-113, 53],
      [-263, 175],
      [-26, 20],
      [-294, 221],
      [-336, 135],
      [-298, 135],
      [-213, -31],
      [-241, 50],
      [-267, -31],
      [-69, -8],
      [-59, -7],
      [-110, -12],
      [-177, -73],
      [-250, -104],
      [-61, -34],
      [-113, -61],
    ],
    [
      [32615, 64242],
      [70, 3],
      [33, 0],
      [329, -43],
      [189, -25],
      [405, 22],
      [235, 12],
      [842, 126],
      [476, 27],
      [115, 7],
      [231, -52],
      [514, -116],
      [217, -32],
      [383, -56],
      [294, -149],
      [62, -31],
      [251, -459],
      [388, -359],
      [19, -17],
      [122, -114],
      [436, -404],
      [25, -26],
      [459, -496],
      [259, -267],
      [238, -244],
      [346, -386],
      [322, -473],
      [381, -445],
      [511, -616],
      [407, -548],
      [352, -406],
      [148, -159],
      [371, -396],
      [262, -330],
      [242, -307],
      [640, -662],
      [245, -226],
      [158, -144],
      [338, -415],
      [238, -291],
      [155, -324],
      [205, -431],
      [376, -366],
      [200, -194],
      [176, -249],
      [195, -275],
      [197, -269],
      [207, -282],
      [667, -743],
      [624, -681],
      [635, -525],
      [305, -151],
      [139, -69],
      [802, 82],
      [485, -226],
      [121, -557],
      [9, -831],
      [-114, -360],
      [-16, -49],
      [-45, -441],
      [-36, -350],
      [-132, -995],
      [33, -599],
      [19, -354],
      [2, -262],
      [3, -364],
      [-30, -967],
      [109, -921],
      [25, -208],
      [279, -609],
      [613, -184],
      [885, 50],
      [742, 82],
      [1123, 209],
      [813, 36],
      [19, -17],
      [34, -30],
    ],
    [
      [42916, 21452],
      [-31, 144],
      [-86, 552],
      [-385, 748],
      [109, 378],
      [-18, 266],
      [-16, 265],
      [78, 210],
      [105, 318],
      [102, 426],
      [125, 270],
      [27, 6],
      [268, 322],
      [279, 240],
      [406, 163],
      [167, 758],
      [267, 281],
      [252, 186],
      [483, 284],
      [407, 217],
      [348, 300],
      [243, 478],
      [389, 361],
      [261, 328],
      [177, 282],
    ],
    [
      [59151, 34319],
      [238, -187],
      [300, -236],
      [69, -39],
      [70, -40],
      [203, -116],
      [139, -37],
      [111, -30],
      [102, -28],
      [317, 48],
      [84, 15],
      [192, 35],
      [359, 149],
      [65, 26],
      [150, 56],
      [322, 119],
      [145, 44],
      [178, 53],
      [285, 86],
      [104, 41],
      [338, 133],
      [91, 36],
      [117, 42],
    ],
    [
      [58832, 4989],
      [87, -48],
      [-13, 61],
      [166, -173],
      [25, -102],
      [72, -144],
      [44, -130],
      [-22, -135],
      [-83, -148],
      [-238, -145],
      [-330, -69],
      [-271, -125],
      [-363, 7],
      [-363, -21],
      [-276, -2],
      [-266, 174],
      [-309, 19],
      [-209, -31],
      [-242, 11],
      [-396, 55],
      [-77, 212],
      [-146, 124],
      [-171, 193],
      [-12, 136],
      [55, 67],
      [122, 45],
      [155, 59],
      [271, 117],
      [173, 276],
      [185, 255],
      [151, 187],
      [171, 174],
      [104, 181],
      [118, 222],
      [110, 189],
      [205, 193],
      [171, 201],
      [182, 30],
      [41, 54],
      [142, 25],
      [128, 25],
      [207, -78],
      [39, -89],
      [32, -110],
      [18, -149],
      [-2, -136],
      [-2, -136],
      [6, -47],
      [11, -102],
      [86, -131],
      [18, -95],
      [-15, -81],
      [27, -42],
      [5, -88],
      [59, -89],
      [6, -75],
      [86, -90],
      [32, -89],
      [6, -54],
      [120, -70],
      [6, -54],
      [13, -21],
      [13, -7],
      [19, -34],
      [14, -7],
      [105, -145],
    ],
    [
      [71868, 1068],
      [-119, 169],
      [-152, 159],
      [-448, 178],
      [-363, 33],
      [-190, -98],
      [-332, -151],
      [-305, -178],
      [-299, -131],
      [-297, -110],
      [-323, 39],
      [-361, 122],
      [-126, 111],
      [-51, 225],
      [-115, 369],
      [-122, 198],
    ],
    [
      [76483, 10132],
      [108, -189],
      [405, -748],
      [188, -459],
      [461, -613],
      [465, -388],
      [160, -112],
      [47, -48],
      [-29, -74],
      [64, -239],
      [265, -250],
      [126, -124],
      [746, -489],
      [9, -40],
    ],
    [
      [60210, 2877],
      [-170, -44],
      [-663, -234],
      [-388, -292],
      [-616, -234],
      [-681, -84],
      [-546, -65],
      [-720, 6],
      [-382, 135],
      [-914, 23],
      [-674, -77],
      [-678, 121],
      [-526, -106],
      [-231, 316],
      [-182, 23],
      [-121, -11],
      [-174, 50],
      [-67, 15],
      [-135, -45],
      [-119, 118],
      [-125, 199],
      [-92, 131],
      [-59, 130],
      [-38, 130],
      [-141, 457],
      [-121, 340],
    ],
    [
      [88715, 23339],
      [126, -67],
      [448, -212],
      [461, -164],
      [402, -116],
      [329, -33],
      [249, 3],
      [296, -26],
      [497, -69],
      [632, -32],
      [316, 2],
      [203, 58],
      [364, 54],
      [276, 23],
      [14, 0],
      [258, 144],
      [87, 13],
      [141, 49],
      [8, 3],
      [-4, 4],
      [-2, 2],
      [250, 85],
      [383, -61],
      [364, 82],
      [553, 99],
      [574, 65],
      [356, -33],
      [497, -16],
      [390, 0],
      [425, 6],
      [314, -100],
      [241, -93],
      [329, -60],
      [127, -9],
      [256, -18],
      [417, 0],
      [408, -143],
      [228, -79],
      [387, -149],
      [242, -66],
      [443, -62],
      [444, -21],
      [49, -7],
      [-92, -135],
      [-139, -276],
      [-256, -444],
      [-288, -308],
      [-245, -179],
      [-138, -243],
      [-88, -433],
      [-177, -575],
      [-240, -676],
      [-119, -297],
      [-100, -358],
      [-134, -426],
      [-101, -372],
      [-129, -555],
      [-63, -550],
      [32, -537],
      [-105, -624],
      [-39, -359],
      [-181, -371],
      [58, -633],
      [-166, -249],
      [-81, -46],
      [-159, -663],
      [-13, -27],
      [-230, -452],
      [-93, -345],
      [-628, -118],
      [-324, -83],
      [-471, -40],
      [-39, 5],
      [-83, 11],
      [-555, 159],
      [-228, 59],
      [-201, 57],
      [-383, 34],
      [-416, 62],
      [-705, 134],
      [-200, 99],
      [-160, 104],
      [-719, 26],
      [-303, 26],
      [-822, -108],
      [-255, 31],
      [-319, 258],
      [-222, 17],
      [-209, 17],
      [-343, 6],
      [-572, -31],
      [-384, -27],
      [-445, -47],
      [-469, 103],
      [-433, 259],
      [-395, 150],
      [-403, 48],
      [-464, -6],
      [-394, 238],
      [-811, 204],
      [-381, 109],
      [-380, 208],
      [-81, 44],
      [-340, 162],
      [-495, 165],
      [-433, 293],
      [-452, 355],
      [-326, 209],
      [-245, 270],
      [-304, 352],
      [-57, 225],
      [-277, 372],
      [-43, 211],
      [-154, 84],
      [-182, -64],
      [-22, -9],
    ],
    [
      [82045, 28074],
      [92, -59],
      [423, -271],
      [294, -197],
      [59, -40],
      [47, -32],
      [233, -157],
      [118, -54],
      [36, -16],
      [52, -38],
      [287, -206],
      [259, -188],
      [473, -314],
      [332, -151],
      [102, -47],
      [387, -207],
      [481, -256],
      [26, -20],
      [213, -161],
      [286, -195],
      [267, -175],
      [1043, -779],
      [194, -99],
      [87, -56],
      [102, -82],
      [97, -77],
      [65, -51],
      [68, -54],
      [-7, 14],
      [303, -388],
      [21, -25],
      [30, -78],
      [86, -217],
      [114, -59],
    ],
  ],
  transform: {
    scale: [0.00003431249366510944, 0.00003393994524571958],
    translate: [2.728417185152186, 7.965382427540428],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Asa",
            id: "KW001",
            nameAbbr: "Asa",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.78661259376,
            Shape_Area: 0.12838346233,
          },
        },
        {
          arcs: [[6, 7]],
          type: "Polygon",
          properties: {
            name: "Baruten",
            id: "KW002",
            nameAbbr: "Baruten",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 4.91311876142,
            Shape_Area: 0.84886627137,
          },
        },
        {
          arcs: [[8, 9, 10, 11, 12]],
          type: "Polygon",
          properties: {
            name: "Edu",
            id: "KW003",
            nameAbbr: "Edu",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.42212040902,
            Shape_Area: 0.20656505271,
          },
        },
        {
          arcs: [[13, 14, 15]],
          type: "Polygon",
          properties: {
            name: "Ekiti",
            id: "KW004",
            nameAbbr: "Ekiti",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.98225775367,
            Shape_Area: 0.04469485926,
          },
        },
        {
          arcs: [[16, 17, 18, 19, 20, -3, 21, 22, 23, -11]],
          type: "Polygon",
          properties: {
            name: "Ifelodun",
            id: "KW005",
            nameAbbr: "Ifelodun",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.66861309141,
            Shape_Area: 0.28429678377,
          },
        },
        {
          arcs: [[24, 25, 26, -23]],
          type: "Polygon",
          properties: {
            name: "Ilorin East",
            id: "KW006",
            nameAbbr: "Ilorin East",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.34906531993,
            Shape_Area: 0.03553892875,
          },
        },
        {
          arcs: [[-2, 27, -25, -22]],
          type: "Polygon",
          properties: {
            name: "Ilorin South",
            id: "KW007",
            nameAbbr: "Ilorin South",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.59886587636,
            Shape_Area: 0.01360065084,
          },
        },
        {
          arcs: [[-26, -28, -1, 28]],
          type: "Polygon",
          properties: {
            name: "Ilorin West",
            id: "KW008",
            nameAbbr: "Ilorin West",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.4752810829,
            Shape_Area: 0.00804653199,
          },
        },
        {
          arcs: [[29, 30, 31, -20, 32]],
          type: "Polygon",
          properties: {
            name: "Irepodun",
            id: "KW009",
            nameAbbr: "Irepodun",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.42897706774,
            Shape_Area: 0.06084642051,
          },
        },
        {
          arcs: [[33, -33, -19]],
          type: "Polygon",
          properties: {
            name: "Isin",
            id: "KW010",
            nameAbbr: "Isin",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.00563951604,
            Shape_Area: 0.05173688259,
          },
        },
        {
          arcs: [[34, 35, 36, -7, 37]],
          type: "Polygon",
          properties: {
            name: "Kaiama",
            id: "KW011",
            nameAbbr: "Kaiama",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 3.72016264969,
            Shape_Area: 0.6467688677,
          },
        },
        {
          arcs: [[-24, -27, -29, -6, 38, -36, 39, -12]],
          type: "Polygon",
          properties: {
            name: "Moro",
            id: "KW012",
            nameAbbr: "Moro",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.2587727667,
            Shape_Area: 0.26058691567,
          },
        },
        {
          arcs: [[40]],
          type: "Polygon",
          properties: {
            name: "Offa",
            id: "KW013",
            nameAbbr: "Offa",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.38491731782,
            Shape_Area: 0.00795412021,
          },
        },
        {
          arcs: [[-15, 41, -30, -34, -18, 42]],
          type: "Polygon",
          properties: {
            name: "Oke-Ero",
            id: "KW014",
            nameAbbr: "Oke-Ero",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.12839386186,
            Shape_Area: 0.03423360161,
          },
        },
        {
          arcs: [[-32, 43, -4, -21], [-41]],
          type: "Polygon",
          properties: {
            name: "Oyun",
            id: "KW015",
            nameAbbr: "Oyun",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.39511667892,
            Shape_Area: 0.0390519962,
          },
        },
        {
          arcs: [[44, -9, 45]],
          type: "Polygon",
          properties: {
            name: "Pategi",
            id: "KW016",
            nameAbbr: "Pategi",
            parent: "Kwara",
            parentAbbr: "KW",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.15231147022,
            Shape_Area: 0.24141070056,
          },
        },
      ],
    },
  },
};

const plateau = {
  type: "Topology",
  arcs: [
    [
      [14868, 39723],
      [259, -2],
      [307, -295],
      [-23, -517],
      [354, -663],
      [-42, -449],
      [-389, -589],
      [-213, -184],
      [-393, -313],
      [-444, -432],
      [-426, -326],
      [-342, -618],
      [-222, -742],
      [-86, -677],
      [-270, -617],
      [-207, -643],
      [-41, -127],
      [-221, -683],
    ],
    [
      [12469, 31846],
      [-111, -93],
      [1, -42],
      [-445, -144],
      [-448, -95],
      [-369, -176],
      [-758, -73],
      [-628, -51],
      [-566, -50],
      [-289, -59],
      [-671, -275],
      [-265, -242],
      [-519, -300],
      [-161, -301],
      [-787, -372],
      [-390, -176],
      [-224, -234],
      [-357, -114],
      [-557, -138],
      [-418, -190],
      [-399, -155],
    ],
    [
      [4108, 28566],
      [-43, 181],
      [-175, 479],
      [-134, 235],
    ],
    [
      [3756, 29461],
      [444, 170],
      [423, 292],
      [245, 180],
      [300, 236],
      [533, 286],
      [555, 592],
      [245, 574],
      [207, 417],
      [289, 385],
      [290, 377],
      [87, 490],
      [244, 661],
      [316, 426],
      [456, 407],
      [306, 216],
      [226, 471],
      [50, 327],
      [-48, 697],
      [-102, 622],
      [-417, 495],
      [-134, 161],
      [-5, 29],
    ],
    [
      [8266, 37972],
      [521, 74],
      [384, 515],
      [318, 744],
      [337, 799],
      [73, 151],
      [369, 202],
      [215, 329],
      [189, 247],
      [100, 199],
      [23, 590],
      [182, 627],
      [208, 688],
      [487, 571],
      [211, 116],
    ],
    [
      [11883, 43824],
      [9, -92],
      [-2, -238],
      [-73, -170],
      [174, -452],
      [56, -406],
      [60, -263],
      [-34, -374],
      [11, -196],
      [-11, -204],
      [5, -210],
      [51, -143],
      [21, -59],
      [124, -317],
      [138, -364],
      [141, -153],
      [78, -86],
      [1, -96],
      [107, -18],
      [530, -262],
      [27, -21],
      [460, -98],
      [666, 124],
      [446, -3],
    ],
    [
      [12095, 49397],
      [-303, 42],
      [-519, 32],
      [-488, -143],
      [-473, -150],
      [-627, -119],
      [-508, -157],
      [-375, -284],
      [-256, -310],
      [-329, -134],
      [-449, -400],
      [-430, -272],
      [-189, -220],
      [-101, -490],
      [-250, -669],
      [-164, -816],
      [-214, -769],
      [-169, -566],
      [-16, -105],
    ],
    [
      [6235, 43867],
      [-64, -412],
      [-13, -421],
      [-59, -489],
      [-130, -449],
      [-120, -226],
      [-92, -279],
      [-292, -216],
      [-192, -125],
      [-308, -113],
      [-289, -73],
      [-542, -191],
      [-267, -120],
      [-399, -108],
      [-239, -140],
      [-329, -103],
    ],
    [
      [2900, 40402],
      [-104, -33],
      [-406, -54],
      [-321, -34],
    ],
    [
      [2069, 40281],
      [241, 216],
      [198, 505],
      [-134, 540],
      [-112, 453],
      [35, 604],
      [128, 539],
      [25, 571],
      [55, 782],
      [44, 530],
      [40, 660],
      [-67, 759],
      [-16, 171],
      [159, 518],
      [587, 375],
      [540, 557],
      [-1, 557],
      [-120, 393],
      [-236, 710],
      [-365, 675],
      [-275, 656],
      [20, 598],
      [85, 680],
      [253, 710],
      [274, 785],
      [476, 849],
      [895, 656],
      [1030, 347],
      [620, 734],
      [259, 677],
      [60, 700],
      [40, 667],
      [174, 136],
      [186, 147],
      [536, 81],
      [778, 10],
      [719, -45],
      [749, -174],
      [561, -245],
      [163, -181],
      [350, -559],
      [392, -652],
      [321, -564],
      [147, -438],
      [135, -595],
      [10, -488],
      [76, -745],
      [-60, -489],
      [61, -656],
      [21, -88],
      [-31, -3513],
    ],
    [
      [15812, 20761],
      [-190, -183],
      [-290, -238],
      [-357, -479],
      [-121, -266],
    ],
    [
      [14854, 19595],
      [-10, 15],
      [-328, 339],
      [-491, 268],
      [-435, -92],
      [-355, -179],
      [-206, -122],
      [-124, -44],
      [-100, -36],
      [-86, -56],
      [1, -109],
      [-526, 18],
      [-373, 35],
      [-524, -56],
      [-318, 1],
      [-332, 22],
      [-499, 114],
      [-168, 25],
      [-199, 30],
      [-617, 65],
      [-690, 9],
      [-356, 197],
      [-181, 31],
      [-481, 237],
      [-234, 362],
      [-314, 530],
      [-194, 363],
      [-165, 357],
      [-106, 480],
      [69, 387],
      [-43, 467],
      [-86, 501],
      [-130, 283],
      [-286, 171],
      [-348, 137],
      [-347, 96],
      [-443, 61],
      [-365, -67],
      [-333, 55],
      [-265, 145],
      [-394, 367],
      [-133, 263],
      [-3, 6],
      [37, 224],
      [112, 334],
      [85, 212],
      [188, 321],
      [93, 165],
      [47, 68],
      [225, 540],
      [92, 604],
      [-45, 563],
      [-62, 264],
    ],
    [
      [12469, 31846],
      [134, 24],
      [47, -38],
      [93, -75],
      [359, -288],
      [250, -306],
      [190, -348],
      [173, -448],
      [250, -599],
      [206, -674],
      [239, -560],
      [325, -696],
      [455, -1037],
      [141, -585],
      [133, -849],
      [149, -619],
      [23, -672],
      [221, -847],
      [62, -1162],
      [98, -736],
      [-152, -135],
      [-12, 99],
      [-10, -118],
      [-143, -127],
      [112, -289],
    ],
    [
      [11883, 43824],
      [-17, 179],
      [37, 278],
      [-95, 116],
    ],
    [
      [11808, 44397],
      [43, 53],
      [133, 273],
      [121, 261],
      [-20, 3318],
    ],
    [
      [12085, 48302],
      [261, 108],
      [172, 48],
      [574, 161],
      [1351, 175],
      [1111, 249],
      [993, 212],
      [703, 82],
      [499, -99],
      [441, -354],
      [405, -552],
      [334, -776],
      [123, -699],
      [-34, -1124],
      [28, -702],
      [129, -978],
      [-57, -292],
      [-51, -260],
      [-139, -245],
      [-128, -226],
      [-370, -551],
      [-342, -723],
      [-66, -169],
    ],
    [
      [18022, 41587],
      [-60, -14],
      [-380, -38],
      [-567, -221],
      [-451, -194],
      [-335, -147],
      [-364, -243],
      [-331, -283],
      [-332, -332],
      [-325, -379],
      [-9, -13],
    ],
    [
      [12095, 49397],
      [-10, -1095],
    ],
    [
      [11808, 44397],
      [-126, -153],
      [-341, -216],
      [-232, -160],
      [-445, -231],
      [-218, -153],
      [-204, -145],
      [-253, -147],
      [-503, -89],
      [-462, -1],
      [-254, -92],
      [-399, -116],
      [-322, -127],
      [-386, -20],
      [-335, 198],
      [-89, 290],
      [-137, 303],
      [-225, 207],
      [-382, 102],
      [-260, 20],
    ],
    [
      [8266, 37972],
      [-560, -79],
      [-461, -89],
      [-357, -107],
      [-59, -212],
      [-444, -231],
      [-405, 197],
      [-449, 407],
      [-301, 198],
      [-872, 94],
      [-451, 115],
      [-736, 205],
      [-189, 505],
      [-20, 658],
      [-204, 566],
      [142, 203],
    ],
    [
      [58725, 33870],
      [55, -108],
      [148, -254],
      [336, -673],
      [194, -827],
      [76, -775],
    ],
    [
      [59534, 31233],
      [-342, -10],
      [-456, -68],
      [-621, 3],
      [-527, -95],
      [-417, -265],
      [-843, 156],
      [-390, 53],
      [-726, 45],
      [-936, 389],
      [-820, 592],
      [-191, 221],
      [-724, 520],
      [-528, 429],
      [-630, 307],
      [-857, 114],
      [-471, -177],
      [-1023, -210],
      [-403, -239],
      [-502, 41],
      [-277, 133],
      [-486, 108],
      [-800, -158],
      [-712, -718],
      [-324, -582],
      [-155, -461],
      [-93, -504],
      [-652, -469],
      [-419, -499],
      [-275, -454],
      [-823, -597],
      [-744, -584],
      [-277, -378],
      [137, -465],
      [310, -297],
      [-56, -361],
      [-374, -316],
      [-1070, -212],
      [-384, -241],
      [-446, -147],
    ],
    [
      [40207, 25837],
      [-73, 17],
      [-474, 27],
      [-583, 129],
      [-379, 210],
      [-345, 174],
      [-275, 290],
      [-278, 358],
      [-375, 263],
      [-697, 241],
      [-763, 214],
      [-541, -28],
      [-627, -65],
      [-364, -60],
      [-226, 103],
    ],
    [
      [34207, 27710],
      [81, 82],
      [219, 382],
      [260, 347],
      [286, 436],
      [262, 358],
      [98, 606],
      [10, 539],
      [55, 599],
      [54, 449],
      [74, 668],
      [121, 502],
      [-46, 431],
      [-255, 650],
      [-40, 158],
      [-369, 523],
      [-67, 184],
      [-112, 281],
      [-146, 432],
      [-66, 212],
      [-177, 335],
    ],
    [
      [34449, 35884],
      [228, 67],
      [505, 989],
      [309, 744],
      [546, 727],
      [631, 141],
      [223, 89],
      [650, 259],
      [736, 126],
      [239, 785],
      [-130, 824],
      [-486, 808],
      [-545, 681],
      [-459, 427],
      [139, 678],
      [843, 552],
      [585, 291],
      [290, -163],
      [203, -114],
      [287, -162],
      [1271, -660],
      [153, -80],
      [1375, -685],
      [1210, -241],
      [1202, -641],
      [1156, -506],
      [1029, -584],
      [972, -598],
      [837, -379],
      [1093, -497],
      [992, -340],
      [970, -387],
      [1321, -637],
      [1026, -666],
      [930, -625],
      [952, -618],
      [1229, -678],
      [816, -489],
      [877, -524],
      [71, 72],
    ],
    [
      [34207, 27710],
      [-212, -218],
      [-136, -156],
      [-315, -190],
      [-101, -157],
      [-168, -225],
      [-529, -995],
      [-257, -539],
      [-128, -329],
    ],
    [
      [32361, 24901],
      [-24, 16],
      [-591, 365],
      [-402, 575],
      [-336, 561],
      [-371, 500],
      [-671, 345],
      [-468, -17],
      [-713, -823],
      [-333, -532],
      [-486, -614],
      [-415, -222],
      [-698, -87],
      [-290, 483],
      [-284, 853],
      [-261, 713],
      [-300, 637],
      [-166, 492],
      [-268, 407],
      [-39, 305],
      [-47, 289],
      [138, 220],
      [139, 407],
      [121, 646],
      [93, 612],
      [-70, 640],
      [-283, 506],
      [-203, 383],
      [-531, 576],
      [-120, 525],
      [-21, 279],
      [-48, 798],
      [3, 314],
      [8, 410],
      [17, 285],
    ],
    [
      [24441, 35748],
      [867, -42],
      [95, -43],
      [581, -260],
      [451, -43],
      [1451, -353],
      [803, 65],
      [1389, 43],
      [1352, 145],
      [511, 26],
      [672, -3],
      [722, 270],
      [1114, 331],
    ],
    [
      [40207, 25837],
      [-249, -83],
      [-491, -260],
      [-529, -552],
      [116, -574],
      [241, -392],
      [29, -369],
      [-344, -670],
      [-295, -563],
      [-238, -433],
      [-371, -657],
      [-539, -355],
      [-447, -478],
      [-358, -181],
      [-60, -176],
      [-82, -242],
      [216, 0],
      [189, -437],
      [360, -840],
      [76, -500],
      [-10, -432],
      [-181, -633],
      [-6, -586],
      [132, -903],
      [69, -583],
      [229, -928],
      [238, -669],
      [146, -526],
      [72, -175],
    ],
    [
      [38120, 12640],
      [-258, 16],
      [-349, 18],
      [-272, 89],
      [-496, 69],
      [-307, 5],
      [-530, 68],
      [-517, 82],
      [-402, 14],
      [-178, -76],
      [-203, -39],
    ],
    [
      [34608, 12886],
      [131, 190],
      [5, 222],
      [21, 286],
      [-48, 402],
      [3, 633],
      [32, 808],
      [-79, 359],
      [-2, 5],
    ],
    [
      [34671, 15791],
      [49, 11],
      [415, 565],
      [314, 374],
      [402, 565],
      [167, 333],
      [158, 361],
      [47, 301],
      [-41, 252],
      [-174, 361],
      [-202, 471],
      [-146, 367],
      [16, 130],
      [-171, 6],
      [-10, 6],
      [-271, 115],
      [-252, 20],
      [-234, 48],
      [-31, 30],
      [-151, 145],
      [-111, 135],
      [-251, 191],
      [-128, 159],
      [-287, 316],
      [-122, 255],
      [-169, 305],
      [-12, 116],
      [-314, 261],
      [-12, 78],
      [-570, 760],
      [-239, 212],
      [-360, 192],
      [-153, 86],
    ],
    [
      [31828, 23318],
      [72, 34],
      [191, 289],
      [128, 787],
      [80, 299],
      [62, 174],
    ],
    [
      [44556, 6421],
      [-1120, -578],
      [-1667, -914],
      [-1167, -1032],
      [-1648, -1660],
      [-800, -937],
      [-1146, -564],
      [-1666, -386],
    ],
    [
      [35342, 350],
      [-26, 144],
      [-60, 122],
      [-141, 230],
      [-280, 371],
      [-202, 161],
      [-343, 418],
      [-268, 249],
      [-234, 474],
      [-188, 723],
      [-467, 711],
      [-406, 564],
      [-254, 624],
      [-32, 451],
      [-367, 592],
      [-224, 589],
      [-104, 616],
      [-100, 469],
      [-2, 911],
      [84, 636],
      [45, 628],
      [122, 499],
      [268, 622],
      [364, 529],
      [287, 275],
      [483, 179],
      [244, 2],
      [611, 295],
      [291, 213],
      [165, 239],
    ],
    [
      [38120, 12640],
      [316, -775],
      [176, -365],
      [333, -561],
      [564, -946],
      [131, -121],
      [335, -304],
      [559, -569],
      [178, -9],
      [321, -116],
      [355, -94],
      [671, -433],
      [108, 17],
      [950, -516],
      [7, 1],
      [251, 7],
      [148, -156],
      [211, -98],
      [96, -102],
      [547, -792],
      [39, -76],
      [74, -81],
      [66, -130],
    ],
    [
      [20325, 22095],
      [22, -352],
      [111, -398],
      [-279, -457],
      [-669, -341],
      [-408, -373],
      [-544, -463],
      [-432, -6],
      [-1116, -56],
      [-177, -12],
      [0, 5],
      [-21, 369],
      [-144, 453],
      [-378, 386],
      [-265, 0],
      [-116, -22],
      [-33, -6],
      [-64, -61],
    ],
    [
      [18022, 41587],
      [-77, -198],
      [-46, -333],
      [4, -463],
      [285, -404],
      [620, -202],
      [721, -250],
      [504, -215],
      [370, -336],
      [260, -274],
      [162, -274],
      [147, -409],
      [247, -635],
      [220, -526],
      [163, -355],
      [207, -186],
    ],
    [
      [21809, 36527],
      [-342, -181],
      [-318, -297],
      [-3, -538],
      [161, -839],
      [143, -716],
      [227, -560],
      [-23, -490],
      [-231, -1040],
      [-219, -589],
      [-247, -825],
      [-240, -456],
      [-257, -597],
      [-134, -573],
      [0, -703],
      [59, -761],
      [76, -354],
      [-148, -386],
      [79, -634],
      [80, -27],
      [-144, -466],
      [117, -522],
      [5, -426],
      [-61, -414],
      [53, -600],
      [-22, -267],
      [-1, -413],
      [-94, -758],
    ],
    [
      [34671, 15791],
      [-253, -49],
      [-57, 45],
      [-162, 136],
      [-328, 68],
      [-137, 41],
      [-326, 245],
      [-236, 248],
      [-379, 403],
      [-337, 328],
      [-345, 360],
      [-570, 367],
      [-275, 32],
      [-301, 9],
      [-511, 224],
      [-533, 13],
      [-722, -40],
      [-570, 56],
      [-694, -286],
      [-684, -170],
      [-628, -357],
      [-555, -132],
      [-479, -239],
      [-382, 435],
      [-241, 409],
      [-367, 645],
      [-77, 679],
      [-188, 178],
      [-401, -50],
      [-575, -53],
      [-524, 57],
      [-401, 110],
      [-264, 233],
      [-46, 41],
      [-114, 214],
      [-24, -17],
      [-4, 13],
      [104, 279],
      [61, 213],
      [78, 139],
      [171, 272],
      [284, 452],
      [36, 73],
      [93, 146],
      [59, 153],
      [77, 286],
      [38, 140],
      [26, 164],
    ],
    [
      [23008, 22304],
      [316, -187],
      [246, -262],
      [392, -329],
      [693, -379],
      [196, -175],
      [223, -342],
      [34, -180],
      [161, -202],
      [163, 33],
      [222, 218],
      [91, 286],
      [86, 366],
      [120, 360],
      [179, 145],
      [190, 232],
      [326, -102],
      [347, -270],
      [83, -107],
      [278, -316],
      [251, 52],
      [115, 39],
      [453, 463],
      [272, 305],
      [118, 99],
      [392, 51],
      [241, -409],
      [-44, -106],
      [400, 283],
      [329, 198],
      [514, 290],
      [328, 195],
      [516, 419],
      [253, 129],
      [336, 217],
    ],
    [
      [23008, 22304],
      [-119, 70],
      [-570, 38],
      [-354, 49],
      [-136, -2],
    ],
    [
      [21829, 22459],
      [-214, -2],
      [-282, -5],
      [-467, -96],
      [-505, -223],
      [-36, -38],
    ],
    [
      [21809, 36527],
      [153, -137],
      [375, -309],
      [714, -250],
      [473, -23],
      [881, -58],
      [36, -2],
    ],
    [
      [20717, 3378],
      [-878, -276],
      [-2151, 20],
      [-1152, 14],
      [-552, -105],
      [-1723, 615],
      [-1343, 780],
      [0, 64],
      [-113, 8],
      [-27, 14],
      [-61, 29],
      [-266, 127],
      [-281, 120],
      [-334, 142],
      [-225, 208],
      [-335, 529],
      [-117, 247],
      [48, 488],
      [21, 476],
      [50, 182],
      [67, 407],
      [37, 196],
      [-57, 267],
      [-3, 217],
      [-22, 327],
      [-43, 218],
      [-109, 369],
      [-185, 187],
      [-233, 140],
      [-93, 103],
      [-184, 296],
      [-131, 185],
      [-158, 241],
      [-82, 321],
      [-170, 350],
      [-51, 143],
      [85, 264],
      [-101, 29],
      [85, 284],
      [31, 258],
      [51, 230],
      [132, 249],
      [149, 72],
      [190, 58],
      [130, 127],
      [129, 140],
      [16, 129],
      [185, 181],
      [103, 181],
      [9, 123],
      [110, 168],
      [64, 162],
      [90, 216],
      [152, 194],
      [190, 160],
      [151, 127],
      [136, 106],
      [210, 105],
      [230, 58],
      [248, -32],
      [146, 66],
      [0, -8],
      [43, 9],
      [67, 46],
      [426, 287],
      [191, 287],
      [307, 318],
      [195, 412],
      [254, 213],
      [370, 476],
      [275, 440],
      [180, 358],
      [68, 430],
      [279, 542],
      [23, 412],
      [-251, 402],
      [-284, 155],
      [-2, 39],
      [-69, 95],
    ],
    [
      [21829, 22459],
      [-7, -44],
      [6, -206],
      [-5, -161],
      [11, -60],
      [20, -213],
      [-55, -220],
      [-24, -326],
      [-66, -340],
      [-116, -446],
      [9, -24],
      [-28, -37],
      [37, -298],
      [75, -357],
      [28, -110],
      [-5, -16],
      [-70, -215],
      [-301, -239],
      [-22, -266],
      [213, -332],
      [311, -539],
      [124, -670],
      [97, -448],
      [-16, -760],
      [117, -360],
      [236, -662],
      [415, -507],
      [276, -198],
      [642, -511],
      [167, -402],
      [-219, -474],
      [-347, 88],
      [-371, 146],
      [-310, -33],
      [-40, -631],
      [17, -513],
      [191, -946],
      [-303, -495],
      [113, -1308],
      [-179, -525],
      [-430, -970],
      [-452, -815],
      [-686, -1545],
      [-200, -1007],
      [-41, -735],
      [76, -351],
    ],
    [
      [3756, 29461],
      [-158, 276],
      [-302, 219],
      [-504, 351],
      [-282, 307],
      [-416, 468],
      [-241, 355],
      [-269, 328],
      [-144, 316],
      [-311, 362],
      [-166, 311],
      [-42, 78],
      [-334, 490],
      [-314, 558],
      [-228, 376],
      [-37, 453],
      [-8, 95],
      [492, 666],
      [-111, 1130],
      [-73, 549],
      [87, 557],
      [394, 305],
      [437, 299],
      [169, 538],
      [-99, 493],
      [117, 423],
      [334, 229],
      [322, 288],
    ],
    [
      [35342, 350],
      [-411, -91],
      [-1182, -135],
      [-754, -124],
      [-1801, 105],
      [-9, 15],
      [-689, 1272],
      [-1185, 978],
      [-2324, 859],
      [-1407, 668],
      [-1144, 526],
      [-1679, -489],
      [-1376, -348],
      [-664, -208],
    ],
    [
      [59534, 31233],
      [24, -240],
      [-66, -1218],
      [-174, -712],
      [319, -1946],
      [39, -872],
      [651, -1031],
      [434, -792],
      [103, -951],
      [275, -796],
      [116, -1101],
      [-263, -632],
      [107, -779],
      [39, -284],
      [199, 1],
      [-6, -402],
      [148, -1309],
      [-41, -910],
      [-839, -1459],
      [-1771, -911],
      [-2082, -1062],
      [-1880, -803],
      [-895, 54],
      [-928, -232],
      [-1830, -402],
      [-29, -6],
      [-1696, -355],
      [-1174, -681],
      [-373, -628],
      [-593, -1356],
      [-1246, -1369],
      [-989, -1340],
      [-557, -288],
    ],
  ],
  transform: {
    scale: [0.00003431249959038342, 0.00003393949685540567],
    translate: [8.545491606980663, 8.347157958283958],
  },
  objects: {
    LGAs: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5]],
          type: "Polygon",
          properties: {
            name: "Barkin Ladi",
            id: "PT001",
            nameAbbr: "Barkin Ladi",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.50846382936,
            Shape_Area: 0.07021073282,
          },
        },
        {
          arcs: [[6, 7, 8, 9]],
          type: "Polygon",
          properties: {
            name: "Bassa",
            id: "PT002",
            nameAbbr: "Bassa",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.688719153,
            Shape_Area: 0.12149833667,
          },
        },
        {
          arcs: [[10, 11, -2, 12]],
          type: "Polygon",
          properties: {
            name: "Bokkos",
            id: "PT003",
            nameAbbr: "Bokkos",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.43415890209,
            Shape_Area: 0.12556256846,
          },
        },
        {
          arcs: [[-6, 13, 14, 15, 16]],
          type: "Polygon",
          properties: {
            name: "Jos East",
            id: "PT004",
            nameAbbr: "Jos East",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.98439047274,
            Shape_Area: 0.06588363815,
          },
        },
        {
          arcs: [[-7, 17, -15, 18]],
          type: "Polygon",
          properties: {
            name: "Jos North",
            id: "PT005",
            nameAbbr: "Jos North",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.71011164851,
            Shape_Area: 0.0331140019,
          },
        },
        {
          arcs: [[19, -8, -19, -14, -5]],
          type: "Polygon",
          properties: {
            name: "Jos South",
            id: "PT006",
            nameAbbr: "Jos South",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 0.94444035345,
            Shape_Area: 0.0372341802,
          },
        },
        {
          arcs: [[20, 21, 22, 23, 24]],
          type: "Polygon",
          properties: {
            name: "Kanam",
            id: "PT007",
            nameAbbr: "Kanam",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.65954290914,
            Shape_Area: 0.2475947129,
          },
        },
        {
          arcs: [[25, 26, 27, -24]],
          type: "Polygon",
          properties: {
            name: "Kanke",
            id: "PT008",
            nameAbbr: "Kanke",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.4203691009,
            Shape_Area: 0.10485759608,
          },
        },
        {
          arcs: [[28, 29, 30, 31, 32, -26, -23]],
          type: "Polygon",
          properties: {
            name: "Langtang North",
            id: "PT009",
            nameAbbr: "Langtang North",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.49514496598,
            Shape_Area: 0.06587323084,
          },
        },
        {
          arcs: [[33, 34, -30, 35]],
          type: "Polygon",
          properties: {
            name: "Langtang South",
            id: "PT010",
            nameAbbr: "Langtang South",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.3430006953,
            Shape_Area: 0.11132959366,
          },
        },
        {
          arcs: [[36, -13, -1, -17, 37, 38]],
          type: "Polygon",
          properties: {
            name: "Mangu",
            id: "PT011",
            nameAbbr: "Mangu",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.86406078568,
            Shape_Area: 0.14463063652,
          },
        },
        {
          arcs: [[39, 40, -32]],
          type: "Polygon",
          properties: {
            name: "Mikang",
            id: "PT012",
            nameAbbr: "Mikang",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.38553195763,
            Shape_Area: 0.06120545218,
          },
        },
        {
          arcs: [[-33, -41, 41, 42, -39, 43, -27]],
          type: "Polygon",
          properties: {
            name: "Pankshin",
            id: "PT013",
            nameAbbr: "Pankshin",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.84117755719,
            Shape_Area: 0.10595422813,
          },
        },
        {
          arcs: [[44, -11, -37, -43, 45]],
          type: "Polygon",
          properties: {
            name: "Qua'an Pan",
            id: "PT014",
            nameAbbr: "Qua'an Pan",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.03444671551,
            Shape_Area: 0.19487607468,
          },
        },
        {
          arcs: [[-4, 46, -9, -20]],
          type: "Polygon",
          properties: {
            name: "Riyom",
            id: "PT015",
            nameAbbr: "Riyom",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 1.11180306476,
            Shape_Area: 0.06613560973,
          },
        },
        {
          arcs: [[-31, -35, 47, -46, -42, -40]],
          type: "Polygon",
          properties: {
            name: "Shendam",
            id: "PT016",
            nameAbbr: "Shendam",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.58194132242,
            Shape_Area: 0.20467273413,
          },
        },
        {
          arcs: [[48, -36, -29, -22]],
          type: "Polygon",
          properties: {
            name: "Wase",
            id: "PT017",
            nameAbbr: "Wase",
            parent: "Plateau",
            parentAbbr: "PT",
            grandParent: "Nigeria",
            grandParentAbbr: "NG",
            Shape_Leng: 2.92500731415,
            Shape_Area: 0.50850933685,
          },
        },
      ],
    },
  },
};
export {
  nigeria,
  abia,
  adamawa,
  akwaibom,
  anambra,
  bauchi,
  bayelsa,
  benue,
  borno,
  crossriver,
  delta,
  ebonyi,
  edo,
  ekiti,
  enugu,
  fct,
  gombe,
  imo,
  jigawa,
  kaduna,
  kano,
  katsina,
  kebbi,
  kogi,
  kwara,
  lagos,
  nasarawa,
  niger,
  ogun,
  ondo,
  osun,
  oyo,
  plateau,
  rivers,
  sokoto,
  taraba,
  yobe,
  zamfara,
};
