import React from "react";
import { Box } from "@mui/material";
import Card from "../charts/Card";
import { cardbar11111, chartbar, temp, info, card } from "../styles/styles";

const Info = () => {
  return (
    <Box sx={info}>
      <Box sx={cardbar11111}>
        <Box sx={temp} />
        <Box sx={temp} />
        <Box sx={temp} />
        <Card sub />
      </Box>
      <Box sx={cardbar11111}>
        <Box sx={temp} />
        <Box sx={temp} />
        <Box sx={temp} />
        <Box sx={temp} />
      </Box>
      <Box sx={chartbar}>
        <Box sx={temp} />
        <Box sx={temp} />
      </Box>
    </Box>
  );
};

export default Info;
