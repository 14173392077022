import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, List, ListItem, Popper, ClickAwayListener, Paper } from "@mui/material";
import { popperBackground } from "../styles/styles";

const SingleSelectDropdown = ({
  id,
  label,
  options,
  placeholder,
  onChange,
  selectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const popperWidth = useCallback(() => {
    return anchorEl.current ? anchorEl.current.clientWidth : undefined;
  }, []);

  return (
    <Box 
      sx={{
        position: 'relative',
        display: 'inline-block',
        width: '80%',
        margin: 'auto',
        paddingLeft: '1em',
        paddingRight: '1em',
        zIndex: 1201,
      }}
    >
      <Typography variant="body2" component="label" htmlFor={id}>{label}</Typography>
      <Box
        sx={{ position: 'relative', zIndex: 1201 }}
        ref={anchorEl}
      >
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid #006400',
            borderRadius: '5px',
            padding: '8px',
            cursor: 'pointer',
            zIndex: 1201,
          }} 
          onClick={handleToggle}
        >
          <Typography 
            sx={{
              fontSize: '0.8em',
              fontWeight: '300',
              color: '#000',
            }}          
          >
            {selectedValue || placeholder}
          </Typography>
          <Typography
            sx={{
              color: '#000',
            }}
          >
            &#9662;
          </Typography>
        </Box>
        <Popper open={isOpen} anchorEl={anchorEl.current} placement="bottom-start" sx={{ zIndex: 1300, width: popperWidth() }}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper sx={popperBackground}>
              <List sx={{ padding: 0 }}>
                {options.map((option) => (
                  <ListItem key={option} button onClick={() => handleOptionClick(option)} sx={{ padding: '4px 8px' }}>
                    <Typography sx={{ fontSize: '0.875rem' }}>{option}</Typography>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Box>
  );
};

export default SingleSelectDropdown;
