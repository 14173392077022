import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthContext } from "../contexts/AuthContext.jsx";
import axios from "axios";
import { prodApi } from "../data/api.js";
import { input } from "../styles/styles.js";

const loginUrl = prodApi.login;
const userUrl = prodApi.user;

const LoginModal = () => {
  const { loggedIn, setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      // Send login request to the backend
      const tokenResponse = await axios.post(loginUrl, { username, password });
      const { token } = tokenResponse.data;

      // Store the token in local storage or a cookie
      localStorage.setItem("token", token);

      // Get User Info with Token
      const userResponse = await axios.get(userUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: "Include",
      });

      // Store user data in local storage
      localStorage.setItem("user", JSON.stringify(userResponse.data));

      // User Information Storage
      setIsLoggedIn(userResponse.data);

      // Set success message
      setSuccess("Welcome! You have successfully logged in.");

      // Delay setting loggedIn to true
      setTimeout(() => setLoggedIn(true), 2000);

      // Clear the success message after 3 seconds
      setTimeout(() => setSuccess(""), 2000);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid username or password.");
      } else {
        setError("An error occurred. Please try again later.");
      }
      console.error("Login error:", error);

      // Clear the error message after 5 seconds
      setTimeout(() => setError(""), 5000);
    }
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (loggedIn) {
    return null; // If user is logged in, do not show the modal
  }

  return (
    <Box
      // Login Film (Background)
      sx={{
        background: `linear-gradient(
          to right,
          rgba(0, 0, 0, 0.5),
          rgba(248, 248, 255, 0.5)
        )`,
        backdropFilter: "blur(10px)",
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0%",
        left: "0%",
        zIndex: 1300,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        placeItems: "center",
      }}
    >
      <Box
        // Login Form
        sx={{
          width: "400px",
          height: "auto",
          margin: "auto",
          background: `linear-gradient(
            to right bottom,
            rgba(248, 248, 255, 0.2),
            rgba(248, 248, 255, 0.05)
          )`,
          borderRadius: "0.25rem",
          border: "solid 1.25px white",
          filter: "none",
          zIndex: 1300,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            height: "80%",
            margin: "auto",
            zIndex: 1300,
          }}
        >
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              margin: "auto",
              color: "white",
              fontSize: "2em",
              mt: 5,
              mb: 2,
            }}
          >
            LOGIN
          </Box>
          <form className="loginform" onSubmit={handleLogin}>
            <TextField
              type="email"
              label="Username"
              value={username}
              onChange={handleChangeUsername}
              fullWidth
              margin="normal"
              size="small"
              sx={input}
            />
            <TextField
              type={showPassword ? "text" : "password"}
              label="Password"
              value={password}
              onChange={handleChangePassword}
              fullWidth
              margin="normal"
              size="small"
              sx={input}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      edge="end"
                      sx={{ color: "white" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              component="button"
              type="submit"
              sx={{
                mt: 2,
                mb: 5,
                px: 4,
                py: 1,
                height: "50px",
                backgroundColor: "#006400",
                color: "white",
                borderRadius: "0.25em",
                cursor: "pointer",
                textAlign: "center",
                border: "none",
                "&:hover": {
                  backgroundColor: "#008900",
                },
              }}
            >
              ENTER
            </Box>
            {error && (
              <Snackbar
                open={!!error}
                autoHideDuration={5000}
                onClose={() => setError("")}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <SnackbarContent
                  message={error}
                  sx={{ backgroundColor: "red", color: "white", mt: 1 }}
                />
              </Snackbar>
            )}
            {success && (
              <Snackbar
                open={!!success}
                autoHideDuration={5000}
                onClose={() => setSuccess("")}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <SnackbarContent
                  message={success}
                  sx={{ backgroundColor: "green", color: "white", mt: 1 }}
                />
              </Snackbar>
            )}
          </form>
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <img src="../../images/logos/nascp.png" alt="logo" width="50%" />
      </Box>
    </Box>
  );
};

export default LoginModal;
