const localApi = {
  login: "http://localhost:6334/nascp/login",
  usersData: "http://localhost:6334/nascp/users",
};

const prodApi = {
  login: "https://data.6334group.com/nascp/login",
  usersData: "https://data.6334group.com/nascp/users",
  user: "https://data.6334group.com/nascp/user",
  facilitiesData: "https://data.6334group.com/nascp/facilities",
  testsData: "https://data.6334group.com/nascp/tests",
  fundersData: "https://data.6334group.com/nascp/funders",
  mentorshipData: "https://data.6334group.com/nascp/mentorship",
  mentorsData: "https://data.6334group.com/nascp/mentors",
  hivCasesData: "https://data.6334group.com/nascp/hivcases",
  hivRetentionData: "https://data.6334group.com/nascp/hivretention",
  hivViralLoadData: "https://data.6334group.com/nascp/hivviralload",
  visitationData: "https://data.6334group.com/nascp/visitation",
};

// const setAuthToken = (token) => {
//   if (token) {
//     api.defaults.headers.common["authorization"] = `Bearer ${token}`;
//   } else {
//     delete api.defaults.headers.common["authorization"];
//   }
// };

export { localApi, prodApi };
