import React from 'react'
import { Box } from '@mui/material'
import { map } from '../styles/styles'

const Map = () => {
  return (
    <Box sx={map}>
      Map
    </Box>
  )
}

export default Map