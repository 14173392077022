import React from "react";
import { Box, Typography } from "@mui/material";
import { card, value, subtitle } from "../styles/styles";

const Card = ({ valueData, subtitleData }) => {
  return (
    <Box
      sx={card}
    >
      <Box
        sx={value}
      >
        {valueData}
      </Box>
      <Box
        sx={subtitle}
      >
        {subtitleData}
      </Box>
    </Box>
  );
};

export default Card;
