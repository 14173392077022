import React, { createContext, useReducer } from "react";

const initialState = {
  // Tests
  testsData: [],
  filteredTestsData: [],
  filteredTestsProgramData: [],

  // Facilities
  facilitiesData: [],
  filteredFacilitiesData: [],

  // Funders
  fundersData: [],
  filteredFundersData: [],

  // Funders
  mentorsData: [],
  filteredMentorsData: [],

  // Mentorship
  mentorshipData: [],
  filteredMentorshipData: [],

  // HIV Cases
  hivCasesData: [],
  filteredHivCasesData: [],

  // HIV Retention
  hivRetentionData: [],
  filteredHivRetentionData: [],

  // HIV Viral Load
  hivViralLoadData: [],
  filteredHivViralLoadData: [],

  // Visitation
  visitationData: [],
  filteredVisitationData: [],
};

export const DataContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    // Tests Data
    case "TESTS_DATA":
      return {
        ...state,
        testsData: action.payload,
      };

    // Tests Filtered Data
    case "UPDATE_FILTERED_TESTS_DATA":
      return {
        ...state,
        filteredTestsData: action.payload,
      };
    case "UPDATE_FILTERED_TESTS_PROGRAM_DATA":
      return {
        ...state,
        filteredTestsProgramData: action.payload,
      };

    // Facilities Data
    case "FACILITIES_DATA":
      return {
        ...state,
        facilitiesData: action.payload,
      };

    // Facilities Filtered Data
    case "UPDATE_FILTERED_FACILITIES_DATA":
      return {
        ...state,
        filteredFacilitiesData: action.payload,
      };

    // Funders Data
    case "FUNDERS_DATA":
      return {
        ...state,
        fundersData: action.payload,
      };

    // Funders Filtered Data
    case "UPDATE_FILTERED_FUNDERS_DATA":
      return {
        ...state,
        filteredFundersData: action.payload,
      };

    // Mentorship Data
    case "MENTORSHIP_DATA":
      return {
        ...state,
        mentorshipData: action.payload,
      };

    // Mentorship Filtered Data
    case "UPDATE_FILTERED_MENTORSHIP_DATA":
      return {
        ...state,
        filteredMentorshipData: action.payload,
      };

    // Mentors Data
    case "MENTORS_DATA":
      return {
        ...state,
        mentorsData: action.payload,
      };

    // Mentors Filtered Data
    case "UPDATE_FILTERED_MENTORS_DATA":
      return {
        ...state,
        filteredMentorsData: action.payload,
      };

    // HIV Cases Data
    case "HIV_CASES_DATA":
      return {
        ...state,
        hivCasesData: action.payload,
      };

    // HIV Cases Filtered Data
    case "UPDATE_FILTERED_HIV_CASES_DATA":
      return {
        ...state,
        filteredHivCasesData: action.payload,
      };

    // HIV Retention Data
    case "HIV_RETENTION_DATA":
      return {
        ...state,
        hivRetentionData: action.payload,
      };

    // HIV Retention Filtered Data
    case "UPDATE_FILTERED_HIV_RETENTION_DATA":
      return {
        ...state,
        filteredHivRetentionData: action.payload,
      };

    // HIV Viral Load Data
    case "HIV_VIRAL_LOAD_DATA":
      return {
        ...state,
        hivViralLoadData: action.payload,
      };

    // HIV Viral Load Filtered Data
    case "UPDATE_FILTERED_HIV_VIRAL_LOAD_DATA":
      return {
        ...state,
        filteredHivViralLoadData: action.payload,
      };

    // Visitation Data
    case "VISITATION_DATA":
      return {
        ...state,
        visitationData: action.payload,
      };

    // Visitation Filtered Data
    case "UPDATE_FILTERED_VISITATION_DATA":
      return {
        ...state,
        filteredVisitationData: action.payload,
      };

    default:
      return state;
  }
};

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};
