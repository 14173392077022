import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";

const Guage = ({
  margin = 5,
  cornerRadius,
  textOffset = 1,
  guageTarget,
  total = Math.PI * 2,
  guageValue,
  guageLevel = (guageValue / guageTarget) * total,
  guageLevelText = `${((guageValue / guageTarget) * 100).toFixed(2)}%`,
  filteredSalesData,
  filteredSalesTarget,
  sx,
  innerRadiusMultiplier = 0.6,
}) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleResize = () => {
    if (guageLevel && svgRef.current) {
      const parentElement = parentRef.current;
      const containerWidth = parentElement.clientWidth;
      const containerHeight = parentElement.clientHeight;

      // Define the chart dimensions based on the container size
      const width = containerWidth;
      const height = containerHeight;
      const radius = Math.min(width, height) / 2;

      // Clear the SVG
      const svg = d3
        .select(svgRef.current)
        .attr("width", width)
        .attr("height", height);

      svg.selectAll("*").remove();

      const chart = svg
        .append("g")
        .attr("class", "gauge")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      const fullArc = d3
        .arc()
        .innerRadius(radius * innerRadiusMultiplier)
        .outerRadius(radius)
        .startAngle(0)
        .endAngle(total);

      const levelArc = d3
        .arc()
        .innerRadius(radius * innerRadiusMultiplier + margin / 2)
        .outerRadius(radius - margin / 2)
        .cornerRadius(cornerRadius)
        .startAngle(0)
        .endAngle(guageLevel);

      chart
        .append("path")
        .attr("class", "full-arc")
        .attr("d", fullArc)
        .attr("fill", "rgba(0,0,0,0.15)");

      chart
        .append("path")
        .attr("class", "half-arc")
        .attr("d", levelArc)
        .attr("fill", "#006400")
        .on("mouseover", (event, d) => {
          const tooltip = d3.select(tooltipRef.current);

          // X and Y Positions
          const [x, y] = d3.pointer(event);
          tooltip
            .style("left", `${x}px`)
            .style("top", `${y}px`)
            .style("display", "block")
            .html(`Level: ${guageLevelText}`);
        })
        .on("mousemove", (event) => {
          const tooltip = d3.select(tooltipRef.current);
          const [x, y] = d3.pointer(event);
          tooltip.style("left", `${x}px`).style("top", `${y}px`);
        })
        .on("mouseout", () => {
          d3.select(tooltipRef.current).style("display", "none");
        });

      chart
        .append("svg:text")
        .attr("class", "middle-text")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .text(guageLevelText);

      chart
        .append("text")
        .attr("class", "left-text")
        .attr("text-anchor", "end")
        .attr("alignment-baseline", "middle")
        .text("")
        .attr("x", -radius * textOffset)
        .attr("y", 0);

      chart
        .append("text")
        .attr("class", "right-text")
        .attr("text-anchor", "start")
        .attr("alignment-baseline", "middle")
        .text("")
        .attr("x", radius * textOffset)
        .attr("y", 0);

      // Animate the gauge when the data changes
      chart
        .selectAll(".half-arc")
        .transition()
        .duration(500)
        .attrTween("d", function (d) {
          const interpolate = d3.interpolate(
            { endAngle: 0 },
            { endAngle: guageLevel }
          );
          return function (t) {
            return levelArc(interpolate(t));
          };
        });
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [margin, guageLevel]);

  return (
    <Box sx={sx}>
      <svg
        ref={parentRef}
        style={{
          height: "80px",
          width: "90%",
          margin: "auto",
          display: "grid",
          placeItems: "center",
        }}
      >
        <g
          ref={svgRef}
          style={{
            cursor: "pointer",
          }}
        />
      </svg>
      <Box
        ref={tooltipRef}
        style={{
          position: "absolute",
          pointerEvents: "none",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: "5px",
          borderRadius: "0.25em",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
          display: "none",
          color: "black",
          fontSize: "12px",
          fontFamily: "Arial",
        }}
      />
    </Box>
  );
};

export default Guage;
