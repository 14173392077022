import React, { useState, useRef, useCallback } from "react";
import { Box, Checkbox, Typography, List, ListItem, Popper, ClickAwayListener, Paper } from "@mui/material";
import { popperBackground } from "../styles/styles";

const MultiselectDropdown = ({
  id = 'id',
  label = 'label',
  options = [2021, 2022, 2023, 2024],
  placeholder = 'placeholder',
  onChange,
  selectedValues = [2024],
  maxDisplayedItems = 3,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    const updatedSelectedValues = selectedValues.includes(option)
      ? selectedValues.filter((value) => value !== option)
      : [...selectedValues, option];

    onChange(updatedSelectedValues);
  };

  const getSelectedText = () => {
    if (selectedValues.length === 1) {
      return selectedValues[0];
    } else if (
      selectedValues.length > 1 &&
      selectedValues.length <= maxDisplayedItems
    ) {
      return selectedValues.join(", ");
    } else if (selectedValues.length > maxDisplayedItems) {
      return `${selectedValues.length} items selected`;
    }
    return "";
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const popperWidth = useCallback(() => {
    return anchorEl.current ? anchorEl.current.clientWidth : undefined;
  }, []);

  return (
    <Box 
      sx={{
        position: 'relative',
        display: 'inline-block',
        width: '80%',
        margin: 'auto',
        paddingLeft: '1em',
        paddingRight: '1em',
        zIndex: 1201,
      }}
    >
      <Typography variant="body2" component="label" htmlFor={id}>{label}</Typography>
      <Box 
        sx={{
          position: 'relative',
          zIndex: 1201,
        }}
        ref={anchorEl}
      >
        <Box
         sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px solid #006400',
          borderRadius: '5px',
          padding: '8px',
          cursor: 'pointer',
          zIndex: 1201,
         }}
         onClick={handleToggle}
        >
          <Typography
            sx={{
              fontSize: '0.8em',
              fontWeight: '300',
              color: '#000',
            }}
          >
            {getSelectedText() || placeholder}
          </Typography>
          <Typography
            sx={{
              color: '#000'
            }}
          >
            &#9662;
          </Typography>
        </Box>
        <Popper open={isOpen} anchorEl={anchorEl.current} placement="bottom-start" sx={{ zIndex: 1300, width: popperWidth() }}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper sx={popperBackground}>
              <List sx={{ padding: 0 }}>
                {options.map((option) => (
                  <ListItem key={option} button onClick={() => handleOptionClick(option)} sx={{ padding: '4px 8px' }}>
                    <Checkbox
                      checked={selectedValues.includes(option)}
                      readOnly
                      sx={{ 
                        marginRight: '8px', 
                        padding: 0,
                        color: '#006400', // Default color
                        '&.Mui-checked': {
                          color: '#006400', // Checked color
                        }
                      }}
                    />
                    <Typography sx={{ fontSize: '0.875rem' }}>{option}</Typography>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Box>
  );
};

export default MultiselectDropdown;
