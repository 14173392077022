import React from 'react'
import { Box } from '@mui/material'
import Header from '../global/Header'
import SlicerBar from '../components/SlicerBar'
import Map from '../components/Map'
import Info from '../components/Info'

const logo = '../../images/logos/nascp.png'
const menuItems = [
  {path: '/', label: 'Home'},
  {path: '/national', label: 'National'},
  {path: '/states', label: 'States'}
]

const States = () => {
  return (
    <>
    <Header
     logo={logo}
     menuItems={menuItems}
    />
    <SlicerBar/>
    <Box
      sx={{
        height: '600px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <Map/>
      <Info/>
    </Box>
    </>
  )
}

export default States